import React, { useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import stateOrganizationsSignal from 'signals/StateOrganizations.signal';
import pullOrganizationNameFromAccount from 'libs/pullOrganizationNameFromAccount';
import {
  fetchAndSetStateOrganizationsData,
  showOrganizationsOrganizationDetailModal,
} from './StateOrganizations.helpers';
import StateOrganizationDetailsModal from './components/StateOrganizationDetailsModal';

const StateOrganizations = () => {
  useEffect(() => {
    fetchAndSetStateOrganizationsData();
  }, []);

  return (
    <>
      <h4>Organizations</h4>
      <Card className="p-3 mb-4">
        <Row>
          <Col className="px-32">
            <Table className="mb-8">
              <thead>
                <tr className="font-weight-bold">
                  <th>Organization</th>
                  <th>Type</th>
                  <th>Account ID</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {stateOrganizationsSignal.value.accounts.map((account, idx) => (
                  <React.Fragment key={idx.toString()}>
                    <tr>
                      <td className="align-middle text-nowrap">{pullOrganizationNameFromAccount(account)}</td>
                      <td className="align-middle text-nowrap">{account.portalType}</td>
                      <td className="align-middle text-nowrap">{account.id}</td>
                      <td className="align-middle text-nowrap">{account.createdDate}</td>
                      <td className="align-middle text-nowrap">
                        <Button type="submit" className="btn btn-dark btn-sm" onClick={() => showOrganizationsOrganizationDetailModal(account)}>
                          Details
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <StateOrganizationDetailsModal />
      </Card>
    </>
  );
};

export default StateOrganizations;
