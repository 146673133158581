import Signal from 'libs/signals';

const applicationComplianceSignal = Signal({
  currentComplianceItem: 1,
  totalComplianceItems: 10,
  isApplicationDetailsView: false,
  complianceChecklist: {
    startUpCosts: null,
    workingCapital: null,
    franchiseFees: null,
    acquisitionOfEquipment: null,
    inventory: null,
    servicesUsedInTheProduction: null,
    manufacturing: null,
    deliveryOfBusinessGoodsOrServices: null,

    borrowerAssurance: null,

    constructionOfANewBuilding: null,
    renovationOfAnExistingBuilding: null,
  },
});

export default applicationComplianceSignal;
