import { Signal } from '@preact/signals-react';
import loaderSignal from 'signals/Loader.signal';
import applicationDetailSignal, {
  ApplicationDetailType,
} from '../../ApplicationDetail/ApplicationDetail.signal';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import { getNotesRoute, refreshListAndModal } from '../Approvable.helpers';

export const handleSendNoteSubmission = async (
  content: string
): Promise<Signal<unknown>> => {
  loaderSignal.update({ isContentLoading: true, message: 'Sending note...' });
  try {
    const { id, modelType, ventureCapitalist } = applicationDetailSignal.value;
    await api.post({
      path: getNotesRoute(modelType, id),
      body: {
        ...(modelType === 'application'
          ? { data: { content } }
          : { programRequestId: id, content }),
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await refreshListAndModal(id!, modelType, ventureCapitalist);

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully added note',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleApplicationSendBackNoteChange = (
  e: Record<string, string>
): Signal<ApplicationDetailType> => {
  const { value } = e;
  return applicationDetailSignal.update({ sendBackNoteBody: value });
};

export const handleApplicationStatusDropdownChange = (
  e: React.ChangeEvent<HTMLSelectElement>
): Signal<ApplicationDetailType> => {
  const { value } = e.target;
  return applicationDetailSignal.update({ newApplicationStatus: value });
};
