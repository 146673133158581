import loaderSignal from "../../../../signals/Loader.signal";
import api from "../../../../api/api";
import alertSignal from "../../../../signals/Alert.signal";
import $businessApplication from "./BusinessApplication.signals";

export const fetchAndSetApplication = async (applicationId) => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const application = await api.get({
      path: `/applications/${applicationId}`,
      options: {
        where: {
          id: applicationId
        },
        include: {
          technicalAssistance: true,
          loan: {
            include: {
              payments: true,
            },
          },
          fundingProgramMembership: {
            include: {
              programRequest: true,
              lender: true,
              ventureCapitalFirm: true,
              fundingProgram: {
                include: {
                  referenceProgram: true,
                },
              },
            },
          },
        },
      },
    });

    return $businessApplication.update(application);

  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};
