import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { checkmarkIcon } from './Icons';

const LargeCheckBox = ({
  id,
  size,
  label,
  checked,
  textColor = 'text-white',
  disabled = false,
  name = '',
  className = '',
  centeringClass = 'text-center',
  onClick,
}) => (
  <div className={className} id={id}>
    <div className={centeringClass}>
      {label && <p className="mb-8">{label}</p>}
      <Button
        disabled={disabled}
        size={size}
        className="rounded"
        variant={checked ? 'primary' : `outline-primary ${textColor}`}
        onClick={onClick}
        name={name}
      >
        <FontAwesomeIcon
          visibility={checked ? "" : "hidden"}
          style={{ pointerEvents: 'none' }}
          onClick={onClick}
          icon={checkmarkIcon}
        />
      </Button>
    </div>
  </div>
);

export default LargeCheckBox;
