import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BackButton = ({ to = null, onClick = null, hidden = false, buttonText, buttonClassName }) => {
  if (to) {
    return (
      <Link
        to={to}
        className={`text-muted ${buttonClassName}`}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </Link>
    );
  }
  if (onClick) {
    return (
      <Button
        variant="link"
        className={`text-muted px-0 ${buttonClassName}`}
        hidden={hidden}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> {buttonText}
      </Button>
    );
  }
};

export default BackButton;
