import BackButton from 'components/global/BackButton';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import CapitalCallPortion from 'components/global/CapitalCallPortionDetail';
import $capitalCallPortion from 'components/global/CapitalCallPortionDetail/capitalCallPortion.signal';
import { useHistory } from 'react-router-dom';

const BusinessCapitalCallPortion = () => {
  const history = useHistory();
  const { capitalCallPortion } = $capitalCallPortion.value;
  const labels = [
    '',
    capitalCallPortion?.capitalCall?.fund?.fundName,
    'Capital Call',
  ];

  return (
    <>
      <BackButton onClick={() => history.goBack()} buttonText="Back" />
      <BreadCrumbWrapper labels={labels}>
        <CapitalCallPortion />
      </BreadCrumbWrapper>
    </>
  );
};

export default BusinessCapitalCallPortion;
