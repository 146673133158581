import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import $appSettings from 'signals/AppSettings.signal';
import businessFundsSignal from 'signals/BusinessFunds.signal';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';

export const fetchAndSetFunds = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const [funds, fundRequests] = await Promise.all([
      api.get({
        path: '/funds',
        options: {
          where: {
            vcPortfolioCompanies: {
              some: {
                businessId:
                  userAccountSignal.value.userData.account.business.id,
              },
            },
          },
          include: {
            documents: true,
            vcPortfolioCompanies: {
              include: {
                fundApplication: {
                  include: {
                    documents: {
                      orderBy: {
                        createdDate: 'desc',
                      },
                    },
                  },
                },
              },
            },
            fundingProgramMembership: {
              include: {
                ventureCapitalFirm: true,
              },
            },
          },
        },
      }),
      api.get({
        path: '/fundRequests',
        options: {
          where: {
            businessId: userAccountSignal.value.userData.account.business.id,
          },
          include: {
            fund: {
              include: {
                fundingProgramMembership: {
                  include: {
                    ventureCapitalFirm: true,
                  },
                },
              },
            },
          },
        },
      }),
    ]);

    businessFundsSignal.update({ funds, fundRequests });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getFundStatus = (fund) => {
  const { FUND_DOCUMENT_TYPES, FUND_APPLICATION_DOCUMENT_STATUS } =
    $appSettings.value.constants;
  const portfolioCompany = fund.vcPortfolioCompanies.find(
    (vcpc) =>
      vcpc.businessId === userAccountSignal.value.userData.account.business.id
  );

  if (!portfolioCompany) {
    return 'INCOMPLETE';
  }

  const fundApplicationDocuments = portfolioCompany.fundApplication?.documents;
  const allDocumentsApproved = fund.documents
    ?.filter((fd) => fd.documentType === FUND_DOCUMENT_TYPES.required)
    .every((fundDoc) => {
      const matchingDocument = fundApplicationDocuments.find(
        (fad) => fad.fundDocumentId === fundDoc.id
      );

      if (
        matchingDocument &&
        matchingDocument.status === FUND_APPLICATION_DOCUMENT_STATUS.approved
      ) {
        return true;
      }

      return false;
    });

  if (allDocumentsApproved) {
    return 'APPROVED';
  }

  return 'INCOMPLETE';
};
