import { Button, Col, Modal, Row } from 'react-bootstrap';
import CloseModalButton from '../../Constant/CloseModalButton';
import ApprovalChainSelectForm from '../../ApprovalChainSelectForm';
import {
  handleApprovalChainTemplateChange,
  handleCapitalCallStatusSubmission,
  handleCloseCapitalCallApprovalChainModal,
  handleDisbursementDateChange,
} from '../CapitalCall.helpers';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import $capitalCall from '../CapitalCall.signals';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const CapitalCallApprovalChainSubmissionModal = () => {
  const { CAPITAL_CALL_STATUS } = $appSettings.value.constants;
  const { skippedSteps, capitalCall, newStatus, disbursementDate } =
    $capitalCall.value;
  const approvalChainTemplates =
    capitalCall.fund.fundingProgramMembership.fundingProgram
      .approvalChainTemplates;
  if (!approvalChainTemplates) {
    return null;
  }

  return (
    <ModalWrapper
      size="xl"
      centered
      show={
        $capitalCall.value.newStatus ===
        CAPITAL_CALL_STATUS.submittedForApproval
      }
      onHide={handleCloseCapitalCallApprovalChainModal}
    >
      <Modal.Header>
        <h4>Submit for Approval</h4>
        <CloseModalButton
          onCloseModal={handleCloseCapitalCallApprovalChainModal}
        />
      </Modal.Header>
      <Modal.Body>
        <p>
          Once the approval chain is configured, the capital call can undergo
          the approval process.
        </p>
        <ApprovalChainSelectForm
          onFieldChange={handleApprovalChainTemplateChange}
          amount={0}
          approvalChainTemplate={approvalChainTemplates[0]}
        />
        <Row>
          <Col lg={6}>
            <Field.DateSelect
              label="Disbursement Date"
              labelClassName="font-weight-bold mb-8"
              className="mt-16"
              value={disbursementDate}
              onChange={handleDisbursementDateChange}
            />
          </Col>
        </Row>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={handleCloseCapitalCallApprovalChainModal}
          >
            Dismiss
          </Button>
          <Button
            disabled={
              skippedSteps.length === approvalChainTemplates[0].steps.length ||
              !disbursementDate
            }
            onClick={() => {
              handleCapitalCallStatusSubmission(newStatus);
            }}
          >
            Submit
          </Button>
        </div>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default CapitalCallApprovalChainSubmissionModal;
