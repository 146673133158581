export const deadlineSelectOptions = [
  {
    value: 'QUARTERLY_SCHEDULE',
    label: 'Quarterly Schedule',
  },
  {
    value: 'ANNUAL_SCHEDULE',
    label: 'Annual Schedule',
  },
];

export const quarterlyReportDeadlines = [
  {
    id: '2022-3',
    reportingPeriod: 'July 1, 2022 to September 30, 2022',
    submissionDeadline: 'October 30, 2022',
  },
  {
    id: '2022-4',
    reportingPeriod: 'October 1, 2022 to December 31, 2022',
    submissionDeadline: 'January 30, 2022',
  },
  {
    id: '2023-1',
    reportingPeriod: 'January 1, 2023 to March 31, 2023',
    submissionDeadline: 'April 30, 2023',
  },
  {
    id: '2023-2',
    reportingPeriod: 'April 1, 2023 to June 30, 2023',
    submissionDeadline: 'July 30, 2023',
  },
  {
    id: '2023-3',
    reportingPeriod: 'July 1, 2023 to September 30, 2023',
    submissionDeadline: 'October 30, 2023',
  },
  {
    id: '2023-4',
    reportingPeriod: 'October 1, 2023 to December 31, 2023',
    submissionDeadline: 'January 30, 2024',
  },
  {
    id: '2024-1',
    reportingPeriod: 'January 1, 2024 to March 31, 2024',
    submissionDeadline: 'April 30, 2024',
  },
  {
    id: '2024-2',
    reportingPeriod: 'April 1, 2024 to June 30, 2024',
    submissionDeadline: 'July 30, 2024',
  },
  {
    id: '2024-3',
    reportingPeriod: 'July 1, 2024 to September 30, 2024',
    submissionDeadline: 'October 30, 2024',
  },
  {
    id: '2024-4',
    reportingPeriod: 'October 1, 2024 to December 31, 2024',
    submissionDeadline: 'January 30, 2025',
  },
  {
    id: '2025-1',
    reportingPeriod: 'January 1, 2025 to March 31, 2025',
    submissionDeadline: 'April 30, 2025',
  },
  {
    id: '2025-2',
    reportingPeriod: 'April 1, 2025 to June 30, 2025',
    submissionDeadline: 'July 30, 2025',
  },
  {
    id: '2025-3',
    reportingPeriod: 'July 1, 2025 to September 30, 2025',
    submissionDeadline: 'October 30, 2025',
  },
  {
    id: '2025-4',
    reportingPeriod: 'October 1, 2025 to December 31, 2025',
    submissionDeadline: 'January 30, 2026',
  },
  {
    id: '2026-1',
    reportingPeriod: 'January 1, 2026 to March 31, 2026',
    submissionDeadline: 'April 30, 2026',
  },
  {
    id: '2026-2',
    reportingPeriod: 'April 1, 2026 to June 30, 2026',
    submissionDeadline: 'July 30, 2026',
  },
  {
    id: '2026-3',
    reportingPeriod: 'July 1, 2026 to September 30, 2026',
    submissionDeadline: 'October 30, 2026',
  },
  {
    id: '2026-4',
    reportingPeriod: 'October 1, 2026 to December 31, 2026',
    submissionDeadline: 'January 30, 2027',
  },
  {
    id: '2027-1',
    reportingPeriod: 'January 1, 2027 to March 31, 2027',
    submissionDeadline: 'April 30, 2027',
  },
  {
    id: '2027-2',
    reportingPeriod: 'April 1, 2027 to June 30, 2027',
    submissionDeadline: 'July 30, 2027',
  },
  {
    id: '2027-3',
    reportingPeriod: 'July 1, 2027 to September 30, 2027',
    submissionDeadline: 'October 30, 2027',
  },
  {
    id: '2027-4',
    reportingPeriod: 'October 1, 2027 to December 31, 2027',
    submissionDeadline: 'January 30, 2028',
  },
];

export const annualReportDeadlines = [
  {
    reportingPeriod: 'January 1, 2022 to December 31, 2022',
    submissionDeadline: 'March 31, 2023',
  },
  {
    reportingPeriod: 'January 1, 2023 to December 31, 2023',
    submissionDeadline: 'March 31, 2024',
  },
  {
    reportingPeriod: 'January 1, 2024 to December 31, 2024',
    submissionDeadline: 'March 31, 2025',
  },
  {
    reportingPeriod: 'January 1, 2025 to December 31, 2025',
    submissionDeadline: 'March 31, 2026',
  },
  {
    reportingPeriod: 'January 1, 2026 to December 31, 2026',
    submissionDeadline: 'March 31, 2027',
  },
  {
    reportingPeriod: 'January 1, 2027 to December 31, 2027',
    submissionDeadline: 'March 31, 2028',
  },
];

export const generalPieChartOptions = {
  plugins: {
    legend: {
      position: 'bottom',
      onClick: null,
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      display: function (context) {
        return context.dataset.data[context.dataIndex] > 0;
      },
    },
  },
};

export const generalBarChartOptions = {
  plugins: {
    legend: {
      display: false,
      onClick: null,
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      display: function (context) {
        return context.dataset.data[context.dataIndex] > 0;
      },
    },
  },
};

export const ANNUAL_REPORTING_OPTIONS = {
  optionOne: {
    value: 'option1',
    label: 'Option 1',
  },
  optionTwo: {
    value: 'option2',
    label: 'Option 2',
  },
};

export const quarterlyReportUploadQuarterOptions = [
  { label: '1 - January 1 to March 31', value: 1 },
  { label: '2 - April 1 to June 30', value: 2 },
  { label: '3 - July 1 to September 30', value: 3 },
  { label: '4 - October 1 to December 31', value: 4 },
];

export const quarterlyReportUploadYearOptions = [
  { label: 2016, value: 2016 },
  { label: 2017, value: 2017 },
  { label: 2018, value: 2018 },
  { label: 2019, value: 2019 },
  { label: 2020, value: 2020 },
  { label: 2021, value: 2021 },
  { label: 2022, value: 2022 },
  { label: 2023, value: 2023 },
];
