import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import transactionSignal from 'signals/Transaction.signal';
import TransactionSignal from 'signals/Transaction.signal';
import {
  allTransactionMetrics,
  transactionLeverageMetrics,
} from 'api/mocks/edoTransactions.mock';
import api from 'api/api';
import userAccountSignal from 'signals/UserAccount.signal';
import { DateTime } from 'luxon';
import { enumCaseToTabCase } from 'libs/string';

export const fetchAndSetTransactionMetrics = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const applications = await api.get({
      path: '/applications',
      options: {
        where: {
          fundingProgramMembership: {
            is: {
              fundingProgram: {
                is: {
                  edoId: userAccountSignal.value.userData.account.edo.id,
                },
              },
            },
          },
        },
      },
    });

    if (applications.length > 0) {
      const {
        transactionLeverage,
        applicationData,
        monthToDate,
        yearToDate,
        groupByWeek30Days,
        groupByWeek90Days,
        groupByWeek12Months,
        statusCounts,
        edoMaximumLeverageRatio,
        actualMaximumLeverageRatio,
      } = await api.get({
        path: '/metrics',
        options: { dataset: 'transactions' },
      });

      transactionSignal.update({
        transactionLeverage,
        allTransactionMetrics: {
          ...allTransactionMetrics,
          edoMaximumLeverageRatio,
          actualMaximumLeverageRatio: actualMaximumLeverageRatio || 0,
          totalVolume: applicationData._sum.approvedAmount,
          avgTransaction: applicationData._avg.approvedAmount,
          monthToDate: {
            paymentVolume: monthToDate._sum.approvedAmount,
            avgTransactions: monthToDate._avg.approvedAmount,
            transactions: monthToDate._count.approvedDate,
          },
          yearToDate: {
            paymentVolume: yearToDate._sum.approvedAmount,
            avgTransactions: yearToDate._avg.approvedAmount,
            transactions: yearToDate._count.approvedDate,
          },
          charts: {
            ...allTransactionMetrics.charts,
            recentTransactionVolume: {
              thirtyDaysAgo: {
                labels: groupByWeek30Days.map(({ week }) =>
                  DateTime.fromISO(week).toFormat('MM/DD')
                ),
                datasets: [
                  {
                    label: '',
                    data: groupByWeek30Days.map(
                      ({ approvedamount }) => approvedamount
                    ),
                    backgroundColor: ['#5579D4'],
                  },
                ],
              },
              ninetyDaysAgo: {
                labels: groupByWeek90Days.map(({ week }) =>
                  DateTime.fromISO(week).toFormat('MM/DD')
                ),
                datasets: [
                  {
                    label: '',
                    data: groupByWeek90Days.map(
                      ({ approvedamount }) => approvedamount
                    ),
                    backgroundColor: ['#5579D4'],
                  },
                ],
              },
              twelveMonthsAgo: {
                labels: groupByWeek12Months.map(({ week }) =>
                  DateTime.fromISO(week).toFormat('MM/DD')
                ),
                datasets: [
                  {
                    label: '',
                    data: groupByWeek12Months.map(
                      ({ approvedamount }) => approvedamount
                    ),
                    backgroundColor: ['#5579D4'],
                  },
                ],
              },
            },
            transactionBreakDownDataSet: {
              thirtyDaysAgo: {
                labels: statusCounts.thirtyDaysAgo.map(({ status }) => enumCaseToTabCase(status)),
                datasets: [
                  {
                    label: 'Automated Compliance',
                    data: statusCounts.thirtyDaysAgo.map(
                      ({ _count }) => _count.status
                    ),
                    backgroundColor: [
                      '#5FA37E', // Green
                      '#DE4D64', // Orange
                      '#4070AA', // Blue
                      '#EE7811', // Red
                    ],
                  },
                ],
              },
              ninetyDaysAgo: {
                labels: statusCounts.ninetyDaysAgo.map(({ status }) => enumCaseToTabCase(status)),
                datasets: [
                  {
                    label: 'Automated Compliance',
                    data: statusCounts.ninetyDaysAgo.map(
                      ({ _count }) => _count.status
                    ),
                    backgroundColor: [
                      '#5FA37E', // Green
                      '#DE4D64', // Orange
                      '#4070AA', // Blue
                      '#EE7811', // Red
                    ],
                  },
                ],
              },
              twelveMonthsAgo: {
                labels: statusCounts.twelveMonthsAgo.map(
                  ({ status }) => enumCaseToTabCase(status)
                ),
                datasets: [
                  {
                    label: 'Automated Compliance',
                    data: statusCounts.twelveMonthsAgo.map(
                      ({ _count }) => _count.status
                    ),
                    backgroundColor: [
                      '#5FA37E', // Green
                      '#DE4D64', // Orange
                      '#4070AA', // Blue
                      '#EE7811', // Red
                    ],
                  },
                ],
              },
            },
          },
        },
        transactionLeverageMetrics: {
          ...transactionLeverageMetrics,
        },
      });
    }
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export default {
  fetchAndSetTransactionMetrics,
};

export const goBackToPlatformSelect = () => {
  TransactionSignal.update({
    form: 'selectPlatform',
    selectedPlatform: '',
  });
};
