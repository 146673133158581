import { useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import stateUsersSignal from 'signals/StateUsers.signal';
import UserActivationModal from 'components/global/UserActivationModal/UserActivationModal';
import AddAccountModal from '../../../../global/AddAccountModal/AddAccountModal';
import UserDetailsModal from './UserDetailsModal';
import UsersTableRow from './UsersTableRow';
import UserLogsModal from './UserLogsModal';
import { fetchAndSetStateUsers } from '../StateUsers.helpers';

const StateManageUsers = () => {
  const [showModals, setShowModals] = useState({
    addUserModal: false,
    userDetailsModal: false,
    userLogsModal: false,
  });

  const toggleModals = (modal) => {
    const newModalState = !showModals[modal];

    setShowModals((prev) => ({
      ...prev,
      [modal]: newModalState,
    }));
  };

  return (
    <>
      <Row className="justify-content-between">
        <Col className="d-flex justify-content-end">
          <Button
            size="sm"
            variant="primary"
            onClick={() => toggleModals('addUserModal')}
          >
            Add Account
          </Button>
        </Col>
      </Row>
      <div className="p-8">
        <Table responsive>
          <thead>
            <tr className="font-weight-bold">
              <th> </th>
              <th>Account ID</th>
              <th className="text-nowrap">User</th>
              <th className="text-nowrap">Status</th>
              <th className="text-nowrap">Last Signed In</th>
              <th className="text-nowrap">Created</th>
              <th className="text-nowrap">Organization Name</th>
              <th className="text-nowrap">Portal Type</th>
              <th className="text-nowrap">Role</th>
            </tr>
          </thead>
          <tbody>
            {stateUsersSignal.value.stateUsers?.map((user) => (
              <UsersTableRow
                key={user.id}
                user={user}
                onToggleModal={toggleModals}
              />
            ))}
          </tbody>
        </Table>
      </div>
      <AddAccountModal
        show={showModals.addUserModal}
        onToggleModal={() => toggleModals('addUserModal')}
      />
      <UserActivationModal onFetchAndSetUsers={fetchAndSetStateUsers} />
      <UserDetailsModal
        show={showModals.userDetailsModal}
        onToggleModal={() => toggleModals('userDetailsModal')}
      />
      <UserLogsModal
        show={showModals.userLogsModal}
        onToggleModal={() => toggleModals('userLogsModal')}
      />
    </>
  );
};

export default StateManageUsers;
