import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import userRequestsSignal from '../../UserRequests.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';

export const showUserApproveRequestModal = (selectedUserRequest) =>
  userRequestsSignal.update({ selectedUserRequest, action: 'approve' });
export const showUserDenyRequestModal = (selectedUserRequest) =>
  userRequestsSignal.update({ selectedUserRequest, action: 'deny' });
export const hideUserRequestModal = () =>
  userRequestsSignal.update({ selectedUserRequest: null, action: '' });

export const updateUserInvitePortal = async (e) => {
  const { selectedUserRequest } = userRequestsSignal.value;
  const user = {
    portal: e.target.value,
    id: selectedUserRequest?.id,
  };
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating user request...',
  });
  try {
    await api.patch({ path: '/userInvites', body: user });
  } catch (err) {
    alertSignal.update({ type: 'notification', message: err });
  } finally {
    loaderSignal.reset();
  }
};

export const sendOrDenyUserRequest = async () => {
  const { selectedUserRequest, userRequests, action } =
    userRequestsSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Updating user request...',
    });
    await api.patch({
      path: '/signUpRequests',
      body: { where: { id: selectedUserRequest.id }, action },
    });

    // update in case the user is in the Lender, VC, or Business tab (EDO only)
    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo
    ) {
      switch (selectedUserRequest.portal) {
        case PORTAL_TYPES.lender:
          const { lenderUserRequests } = userRequestsSignal.value;
          const lenderUserRequestsUpdated = lenderUserRequests.filter(
            (userReq) => userReq.id !== selectedUserRequest.id
          );
          userRequestsSignal.update({
            lenderUserRequests: [...lenderUserRequestsUpdated],
          });
          break;
        case PORTAL_TYPES.vc:
          const { vcUserRequests } = userRequestsSignal.value;
          const vcUserRequestsUpdated = vcUserRequests.filter(
            (userReq) => userReq.id !== selectedUserRequest.id
          );
          userRequestsSignal.update({
            vcUserRequests: [...vcUserRequestsUpdated],
          });
          break;
        case PORTAL_TYPES.business:
          const { businessUserRequests } = userRequestsSignal.value;
          const businessUserRequestsUpdated = businessUserRequests.filter(
            (userReq) => userReq.id !== selectedUserRequest.id
          );
          userRequestsSignal.update({
            businessUserRequests: [...businessUserRequestsUpdated],
          });
          break;
      }
    }

    // update on the regular users tab
    const userRequestsUpdated = userRequests.filter(
      (userReq) => userReq.id !== selectedUserRequest.id
    );
    userRequestsSignal.update({
      selectedUserRequest: null,
      userRequests: [...userRequestsUpdated],
    });

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully sent invitation.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: 'Unable to perform update.',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};
