import LazyWrapper from 'components/global/LazyWrapper';
import { useState, useEffect } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { setPortalType } from './StateMigrate.helpers';
import ApplicationMigrationForm from './components/ApplicationMigrationForm';
import PreviousMigratedApplications from './components/PreviousMigratedApplications';

const Migrate = ({ portalType = 'STATE' }) => {
  const [activeKey, setActiveKey] = useState('stateMigrateApplications');

  useEffect(() => {
    setPortalType(portalType);
  }, []);

  return (
    <Container>
      <Tabs
        defaultActiveKey="stateMigrateApplications"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
      >
        <Tab eventKey="stateMigrateApplications" title="Migrate Applications">
          <LazyWrapper visible={activeKey === 'stateMigrateApplications'}><ApplicationMigrationForm portalType={portalType} /></LazyWrapper>
        </Tab>
        <Tab eventKey="previousMigratedApplications" title="Previously Migrated Applications">
          <LazyWrapper visible={activeKey === 'previousMigratedApplications'}><PreviousMigratedApplications portalType={portalType} /></LazyWrapper>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Migrate;
