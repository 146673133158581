import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import * as ChartGeo from 'chartjs-chart-geo';

const ChartJsCountryMap = ({ statesData }) => {
  const [stateFeatures, setStateFeatures] = useState([]);

  useEffect(() => {
    fetch('https://unpkg.com/us-atlas/states-10m.json')
      .then((response) => response.json())
      .then((value) => {
        setStateFeatures(
          ChartGeo.topojson.feature(
            value,
            value.objects.states,
          ).features,
        );
      });
  }, []);

  return (
    <Chart
      type="choropleth"
      data={{
        labels: stateFeatures.map(
          (d) => d.properties.name,
        ),
        datasets: [
          {
            outline: stateFeatures,
            label: 'States',
            data: stateFeatures.map((d) => ({
              feature: d,
              value: statesData[d.properties.name] !== undefined ? statesData[d.properties.name] : 0,
            })),
          },
        ],
      }}
      options={{
        showOutline: false,
        showGraticule: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          color: {
            quantize: 5,
            legend: {
              position: 'bottom-left',
            },
          },
        },
      }}
    />
  );
};

export default ChartJsCountryMap;
