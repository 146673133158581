import { Button, Form, Table } from 'react-bootstrap';
import FilePreviewButton from 'components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import AttachButton from 'components/global/utils/FilesAndDocuments/AttachButton/AttachButton';

type Props = {
  handleFileRemoval: (index: number) => any;
  handleFilenameChange: (index: number, name: string) => any;
  handleAttachFile: (index: number, file: File) => any;
  handleInlineSave?: (index: number, bundle: any) => any;
  handleDocumentUploadInputFocusOut?: (
    e: React.FocusEvent<any>,
    index: number
  ) => any;
  error: (index: number) => string;
  title: string;
  fileBundles: Array<{
    id?: number;
    name: string;
    file?: File;
    filePath?: string;
  }>;
  handleAddEmptyFileSlot?: () => any;
  maxDocumentCount?: number;
  description?: string;
};

const AttachmentsWithTemplatesForm: React.FC<Props> = ({
  handleFileRemoval,
  handleFilenameChange,
  handleInlineSave,
  handleDocumentUploadInputFocusOut,
  handleAddEmptyFileSlot,
  handleAttachFile,
  error,
  title,
  fileBundles,
  maxDocumentCount,
  description,
}) => (
  <div>
    <div className="d-flex justify-content-between align-items-center">
      <div className="mb-8">
        <h5 className="mb-0">{title}</h5>
        {description && <p className="mb-0">{description}</p>}
      </div>
      {handleAddEmptyFileSlot && (
        <Button
          disabled={fileBundles.length >= (maxDocumentCount || 1000)}
          onClick={() => handleAddEmptyFileSlot()}
          variant="outline-primary"
          className="rounded"
          size="sm"
        >
          {fileBundles.length >= (maxDocumentCount || 1000)
            ? 'Limit Reached'
            : 'Add'}
        </Button>
      )}
    </div>
    <Table hidden={fileBundles.length === 0}>
      <thead>
        <tr className="font-weight-bold">
          <th>Document name</th>
          <th>View</th>
          <th>Remove</th>
          <th hidden={!handleInlineSave}>Save</th>
        </tr>
      </thead>
      <tbody>
        {fileBundles.map((fileBundle, index) => (
          <tr key={index}>
            <td>
              <Form.Group className="form-group">
                <Form.Control
                  disabled={!!fileBundle.id}
                  type="text"
                  className="form-control"
                  name={'foobar'}
                  placeholder="i.e. Application Form"
                  value={fileBundle.name}
                  onChange={(e) => handleFilenameChange(index, e.target.value)}
                  onBlur={(e) =>
                    handleDocumentUploadInputFocusOut &&
                    handleDocumentUploadInputFocusOut(e, index)
                  }
                  isInvalid={!!error(index)}
                />
                <Form.Control.Feedback type="invalid">
                  {error(index)}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
            <td>
              {fileBundle.file || fileBundle.filePath ? (
                <FilePreviewButton
                  render={(submitting, f) => (
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="rounded"
                      onClick={() =>
                        fileBundle.filePath
                          ? // @ts-ignore
                            f(fileBundle, 'fundingProgramDocuments')
                          : // @ts-ignore
                            f(fileBundle.file, '')
                      }
                    >
                      Preview
                    </Button>
                  )}
                />
              ) : fileBundle.id ? (
                <span>
                  <i>No file</i>
                </span>
              ) : (
                <AttachButton
                  handleAttachFile={(file) => handleAttachFile(index, file)}
                  text="Attach"
                  name="attach-btn"
                  id="attach-btn"
                />
              )}
            </td>
            <td>
              <Button
                size="sm"
                className="rounded"
                variant="outline-danger"
                onClick={() => handleFileRemoval(index)}
              >
                Remove
              </Button>
            </td>
            <td hidden={!handleInlineSave}>
              {fileBundle.id ? (
                <span>
                  <i>Already persisted</i>
                </span>
              ) : (
                <Button
                  disabled={!!error(index)}
                  size="sm"
                  className="rounded"
                  variant="outline-primary"
                  onClick={() =>
                    handleInlineSave && handleInlineSave(index, fileBundle)
                  }
                >
                  Save
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default AttachmentsWithTemplatesForm;
