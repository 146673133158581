import Signal from 'libs/signals';

const stateDashboardSignal = Signal({
  productBreakDownChart: {
    labels: [],
    datasets: [
      {
        label: 'Product Breakdown',
        data: [],
        backgroundColor: [
          '#5FA37E',
          '#EE7811',
        ],
      },
    ],
  },
  cases: {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: ['#5579D4'],
      },
    ],
  },
  geoCharts: { statesNumApplications: {} },
  alerts: [],
  users: [],
  notifications: [],
  programs: [],
});

export default stateDashboardSignal;
