import Signal from 'libs/signals';

const businessSearchResultsSignal = Signal({
  fundingProgramMemberships: [],
  businessFavorites: [],
  currentFundingProgramMembership: {},
  dropdownFilters: {},
  appliedFilters: {},
  sort: {},
  modalOpen: false,
});

export default businessSearchResultsSignal;
