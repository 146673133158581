import stateDashboardSignal from 'signals/StateDashboard.signal';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import { productBreakDownData, caseData, statesNumApplications, alerts, users, notifications, programs } from 'api/mocks/stateDashboard.mock';

export const updateProductBreakdownDataSet = (values) => { // eslint-disable-line
  stateDashboardSignal.update({
    ...stateDashboardSignal.value,
    state: {
      ...stateDashboardSignal.value.state,
      charts: {
        ...stateDashboardSignal.value.state.charts,
        productBreakDownDataSet: {
          ...stateDashboardSignal.value.state.charts.productBreakDownDataSet,
          datasets: [
            {
              ...stateDashboardSignal.value.state.charts.productBreakDownDataSet.datasets[0],
              data: [...values],
            },
          ],
        },
      },

    },
  }, 'DashboardSignal');
};

// Todo: Make these actual API Calls
const fetchCaseData = async () => caseData;
const fetchProductBreakdownData = async () => productBreakDownData;
const fetchStatesNumApplications = async () => statesNumApplications;
const fetchAlerts = async () => alerts;
const fetchUsers = async () => users;
const fetchNotifications = async () => notifications;
const fetchPrograms = async () => programs;

export const fetchAndSetDashboardData = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const [
      fetchedCaseData,
      fetchedProductBreakdownData,
      fetchedStatesNumApplications,
      fetchedAlerts,
      fetchedUsers,
      fetchedNotifications,
      fetchedPrograms,
    ] = await Promise.all([
      fetchCaseData(),
      fetchProductBreakdownData(),
      fetchStatesNumApplications(),
      fetchAlerts(),
      fetchUsers(),
      fetchNotifications(),
      fetchPrograms(),
    ]);

    stateDashboardSignal.update({
      cases: formatCaseData(fetchedCaseData),
      productBreakDownChart: formatBreakDownData(fetchedProductBreakdownData),
      geoCharts: {
        statesNumApplications: fetchedStatesNumApplications,
      },
      alerts: fetchedAlerts,
      users: fetchedUsers,
      notifications: fetchedNotifications,
      programs: fetchedPrograms,
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
  return true;
};

const formatBreakDownData = (productDataToFormat) => ({
  labels: productDataToFormat.map(({ title }) => title),
  datasets: [
    {
      label: 'Product Breakdown',
      data: productDataToFormat.map(({ count }) => count),
      backgroundColor: [
        '#5FA37E',
        '#EE7811',
      ],
    },
  ],
});

const formatCaseData = (caseDataToFormat) => ({
  labels: caseDataToFormat.map(({ date }) => date),
  datasets: [
    {
      label: '',
      data: caseDataToFormat.map(({ cases }) => cases),
      backgroundColor: ['#5579D4'],
    },
  ],
});

export const getPageNumbers = (totalNumOfPages, page) => {
  const pageNumbers = [];
  for (let i = 0; i < totalNumOfPages; i++) {
    if (Math.abs(page - i) <= 1) {
      pageNumbers.push(i);
    }
  }
  return pageNumbers;
};

export const generalDoughnutChartOptions = {
  plugins: {
    legend: { position: 'right' },
  },
};

export const generalDoughnutChartWhiteTxtOptions = {
  plugins: {
    legend: { position: 'right' },
  },
  color: '#FFFFFF',
};

export const lineGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

export const barGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

export const horizontalBarGraphOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};
