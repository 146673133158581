/* eslint-disable no-alert */
/* eslint-disable no-console */
import { Button, Col, Row, Alert as BSAlert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import alertSignal from 'signals/Alert.signal';

const Notification = ({ onCloseClick }) => (
  <div style={{ zIndex: 2000 }} className="container-fluid fixed-top">
    {alertSignal.value.notifications.map((notfication) => (
      <BSAlert
        key={notfication.id}
        variant={notfication.variant ?? 'warning'}
        className="mb-8"
      >
        <Row className="justify-content-end">
          <Col sm="4" className="text-center">
            {notfication.message}
          </Col>
          <Col sm="4" className="text-right">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => onCloseClick(notfication.id)}
              cursor="pointer"
            />
          </Col>
        </Row>
      </BSAlert>
    ))}
  </div>
);

const Modal = ({ onCloseClick }) => (
  <div
    className="modal fade show modal-background"
    style={{ display: 'block' }}
    role="dialog"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content rounded-0 border-dark">
        <div className="modal-header bg-danger text-white rounded-0">
          An Error has occured!
        </div>
        <div className="modal-body" style={{ whiteSpace: 'pre-line' }}>
          {alertSignal.value.message}
        </div>
        <div className="modal-footer">
          <Button className="btn btn-dark" onClick={onCloseClick} type="button">
            {alertSignal.value.overrideCloseMessage || 'Close'}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const Alert = () => {
  if (alertSignal.value.error) {
    console.error(alertSignal.value.error);
  }

  const handleCloseClick = () => {
    if (alertSignal.value.onClose) {
      alertSignal.value.onClose();
    }
    alertSignal.reset();
  };

  const handleNotificationCloseClick = (id) => {
    alertSignal.update({
      notifications: alertSignal.value.notifications.filter(
        (notification) => notification.id !== id
      ),
    });
  };

  if (
    !alertSignal.value.notifications.length &&
    alertSignal.value.type !== 'alert'
  ) {
    return null;
  }

  // handles critical alerts
  if (alertSignal.value.type === 'alert') {
    return <Modal onCloseClick={handleCloseClick} />;
  }

  if (alertSignal.value.notifications.length) {
    return (
      <Notification
        notifications={alertSignal.value.notifications}
        onCloseClick={handleNotificationCloseClick}
      />
    );
  }

  return <div />;
};

export default Alert;
