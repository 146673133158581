import userInvitesSignal from 'signals/UserInvites.signal';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import { fetchAndSetUserInvites } from '../../UserInvitations.helpers';

export const showUserInvitationModal = (selectedUserInvitation) =>
  userInvitesSignal.update({ selectedUserInvitation });
export const hideUserInvitationModal = () =>
  userInvitesSignal.update({ selectedUserInvitation: null });

export const copyInvitationLinkToClipboard = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.style.position = 'fixed'; // Ensures the element is positioned offscreen
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);

  return alertSignal.update({
    variant: 'success',
    type: 'notification',
    message: 'Successfully copied invitation link to clipboard.',
  });
};

export const updateUserInvitePortal = async (e) => {
  const { selected } = userInvitesSignal.value;
  const user = {
    portal: e.target.value,
    id: selected?.id,
  };
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating user invitation...',
  });
  try {
    await api.patch({ path: '/userInvites', body: user });
  } catch (err) {
    loaderSignal.update({ isAppLoading: false });
    alertSignal.update({ type: 'notification', message: err });
  } finally {
    loaderSignal.reset();
  }
};

export const resendUserInvitation = async () => {
  const { selectedUserInvitation } = userInvitesSignal.value;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Resending invite...',
  });
  try {
    await api.post({
      path: '/userInvites',
      body: {
        resend: true,
        data: {
          id: selectedUserInvitation.id,
          notes: selectedUserInvitation.notes,
        }
      },
    });
    userInvitesSignal.update({
      selectedUserInvitation: null,
    });
    await fetchAndSetUserInvites();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully resent invitation.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: 'Unable to fetch user Invites',
      error,
    });
  } finally {
    loaderSignal.reset();
  }

  // TODO:
  // this piece of code belongs to user approval and can be used accordingly
  // else {
  //   const approvedUser = {
  //     adminApprovedId: parseInt(userAccountSignal.value.userData.id, 10),
  //     status: 'APPROVED',
  //     portal: selected?.portal,
  //     id: selected?.id,
  //   };
  //   loaderSignal.update({ isContentLoading: true });
  //   try {
  //     await api.post({ path: '/userInvites', body: approvedUser });
  //     loaderSignal.update({ isContentLoading: false });
  //   } catch (err) {
  //     loaderSignal.update({ isContentLoading: false });
  //     alertSignal.update({ type: 'notification', message: 'Error approving user please try again' });
  //   }
  // }
};

export default {
  showUserInvitationModal,
  hideUserInvitationModal,
  resendUserInvitation,
};

export const handleReinviteUserNoteChange = (e) => {
  userInvitesSignal.update({
    selectedUserInvitation: {
      ...userInvitesSignal.value.selectedUserInvitation,
      notes: e.target.value,
    }
  })
};
