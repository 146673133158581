import Signal from 'libs/signals';

export default Signal({
  currentPortal: '',
  selectedMigratedData: {
    id: '',
    step: 1,
    year: '',
    state: '',
    program: { id: '', title: '' },
    ein: '',
    files: [],
  },
  STATE: {
    form: {
      id: '',
      step: 1,
      year: '',
      state: '',
      program: { id: '', title: '' },
      ein: '',
      files: [],
    },
    prevMigrations: [],
    filters: {
      year: '',
      state: '',
      program: { id: '', title: '' },
    },
  },
  EDO: {
    form: {
      id: '',
      step: 1,
      year: '',
      program: { id: '', title: '' },
      ein: '',
      files: [],
    },
    prevMigrations: [],
    filters: {
      year: '',
      program: { id: '', title: '' },
    },
  },
});
