import SimpleTable from "components/global/SimpleTable";
import fundDetailsSignal from "../fundDetails.signal";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { handleNewPortfolioCompanyClick } from "../fundDetails.helpers";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'name',
    title: 'Business Name',
  },
  {
    key: 'ein',
    title: 'EIN',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const PortfolioCompanies = () => {
  const history = useHistory();
  const { portfolioCompanies } = fundDetailsSignal.value;

  return (
    <div className="mt-16 px-40">
      <div className="d-flex flex-row justify-content-end mb-8">
        <Button onClick={handleNewPortfolioCompanyClick}>
          New Portfolio Company
        </Button>
      </div>
      <SimpleTable
        headers={HEADERS}
        rows={portfolioCompanies.map((pc) => ({
          id: pc.id,
          name: pc.business.name,
          ein: pc.business.ein,
          action: ({ key }) => (
            <td key={key} align="right">
              <Button
                className="rounded"
                variant="outline-dark"
                size="sm"
                onClick={() => {
                  history.push(
                    `${history.location.pathname}/applications/${pc.fundApplication.id}`
                  );
                }}
              >
                Review
              </Button>
            </td>
          ),
        }))}
      />
    </div>
  );
};

export default PortfolioCompanies;
