import Signal from 'libs/signals';

const notificationsSignal = Signal([]);
export const drawerNotificationsSignal = Signal([]);
export const notificationDrawerSignal = Signal({
  isOpen: false
});
export const notificationCursorSignal = Signal(null);
export const notificationIsPaginatingSignal = Signal(false);
export const notificationActionIsLoadingSignal = Signal({});

export const notificationScheduleSignal = Signal({
  id: null,
  instantEmailNotifications: false,
  secondsFromMidnight: '',
  mondayEnabled: false,
  tuesdayEnabled: false,
  wednesdayEnabled: false,
  thursdayEnabled: false,
  fridayEnabled: false,
  saturdayEnabled: false,
  sundayEnabled: false,
});

export default notificationsSignal;
