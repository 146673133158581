import { Tab, Tabs } from 'react-bootstrap';
import UserRequests from 'components/global/UserRequests';
import UserInvitations from 'components/global/UserInvitations';
import OrganizationsList from 'components/global/OrganizationsList/OrganizationsList';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import resetSearchParams from '../../../../utils/resetSearchParams';
import { useLocation } from 'react-router';
import EdoProgramRequests from '../EdoLenders/components/ProgramRequests/EdoProgramRequests';
import ProgramRequestDetailModal from '../../../global/ProgramRequestDetail/ProgramRequestDetailModal';
import { locationSearchToObject } from '../../../../libs/functions/global.functions';

const EdoOrganizationSummary = ({ portalType }) => {
  const { pathname, search } = useLocation();
  const tab = new URLSearchParams(search).get('tab');

  return (
    <BreadCrumbWrapper>
      <Tabs
        defaultActiveKey={tab || 'all'}
        mountOnEnter
        onSelect={(tab) => resetSearchParams({ tab })}
      >
        <Tab eventKey="all" title="All">
          <div className="bg-white p-32 border border-gray shadow-sm">
            <OrganizationsList
              refetchingFilter={({ search }) => {
                const { tab } = locationSearchToObject(search);
                return tab === 'all' || !tab;
              }}
              newPath={location.pathname}
            />
          </div>
        </Tab>
        {(pathname.includes('vc') || pathname.includes('lender')) && (
          <Tab eventKey="programRequests" title="Program Requests">
            <div className="bg-white p-32 border border-gray shadow-sm">
              <EdoProgramRequests
                refetchingFilter={({ search }) => {
                  const { tab } = locationSearchToObject(search);
                  return tab === 'programRequests';
                }}
              />
            </div>
          </Tab>
        )}
        <Tab eventKey="userRequests" title="Expressed Interest">
          <div className="bg-white p-32 border border-gray shadow-sm">
            <UserRequests
              refetchingFilter={({ search }) => {
                const { tab } = locationSearchToObject(search);
                return tab === 'userRequests';
              }}
            />
          </div>
        </Tab>
        <Tab eventKey="userInvitations" title="Invitations">
          <div className="bg-white p-32 border border-gray shadow-sm">
            <UserInvitations
              organizationType={portalType}
              refetchingFilter={({ search }) => {
                const { tab } = locationSearchToObject(search);
                return tab === 'userInvitations';
              }}
            />
          </div>
        </Tab>
      </Tabs>
      <ProgramRequestDetailModal />
    </BreadCrumbWrapper>
  );
};

export default EdoOrganizationSummary;
