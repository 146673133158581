import { Tab, Tabs } from 'react-bootstrap';
import { useEffect } from 'react';
import { fetchAndSetUserInvites } from 'components/global/UserInvitations/UserInvitations.helpers';
import { fetchAndSetUserRequests } from 'components/global/UserRequests/UserRequests.helpers';
import StateManageUsers from './components/StateManageUsers';
import UserInvitations from 'components/global/UserInvitations';
import UserRequests from 'components/global/UserRequests';

export const StateUsers = () => {
  useEffect(() => { 
      fetchAndSetUserInvites();
      fetchAndSetUserRequests();
  }, []);

  return (
    <>
      <h4>Users</h4>
      <Tabs>
        <Tab
          eventKey="manageUsers"
          title="Active Users"
        >
          <div className="bg-white p-32 border border-dark">
            <StateManageUsers />
          </div>
        </Tab>
        <Tab eventKey="manageInvites" title="Invitations">
          <div className="bg-white p-8 border border-dark">
            <UserInvitations />
          </div>
        </Tab>
        <Tab eventKey="manageRequests" title="Requests">
          <div className="bg-white p-8 border border-dark">
            <UserRequests />
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default StateUsers;
