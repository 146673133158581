import GeneralInformationTab from './components/GeneralInformationTab';
import BusinessApplicationProgress from './components/BusinessApplicationProgress';
import $businessApplication from './BusinessApplication.signals';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchAndSetApplication } from './BusinessApplication.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';

const BusinessApplication = () => {
  const application = $businessApplication.value;
  const location = useLocation();
  const slashSplitPathname = location.pathname.split('/');
  const applicationId = slashSplitPathname[slashSplitPathname.length - 1];

  useEffect(() => {
    fetchAndSetApplication(applicationId);
  }, [applicationId]);

  if (!application) {
    return null;
  }

  const fundingProgramMembership = application.fundingProgramMembership;
  const { lender, fundingProgram } = fundingProgramMembership;

  return (
    <BreadCrumbWrapper labels={['', fundingProgram?.name]}>
      <div className="bg-white shadow-sm border border-gray p-32">
        <h3>Application {application.id}</h3>
        <hr />
        <GeneralInformationTab
          fundingProgram={fundingProgram}
          lender={lender}
        />
        <BusinessApplicationProgress application={application} />
      </div>
    </BreadCrumbWrapper>
  );
};

export default BusinessApplication;
