import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkmarkIcon, warningIcon } from 'components/global/Constant/Icons';
import { useState } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { annualReportingSignal } from '../Reporting.signals';
import { useHistory } from 'react-router-dom';
import { dashCaseToTitleText } from 'libs/string';

const ActionButtons = ({ id, recordType }) => {
  const history = useHistory();
  const { pathname } = history.location;
  const { selectedTab } = annualReportingSignal.value;
  let buttonData = [];

  switch (selectedTab) {
    case 'Provider Data':
      buttonData = [
        {
          label: 'View Provider',
          path: `${pathname}/${recordType}/${id}`,
        },
      ];
      break;
    case 'Loan and Investment Info':
    case 'Transaction Terms Credit':
    case 'Transaction Terms Equity':
    case 'Loan Investment Performance':
    case 'Borrower Investee Data':
    case 'Transaction Data':
      buttonData = [
        {
          label: `View ${dashCaseToTitleText(recordType)}`,
          path: `${pathname}/${recordType}/${id}`,
        },
      ];
      break;
    default:
      break;
  }

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      {buttonData.map((bd, index) => (
        <Button
          key={bd.label}
          size="sm"
          variant="outline-dark"
          onClick={() => history.push(bd.path)}
          className={`text-nowrap ${
            buttonData.length > 1 && index < buttonData.length - 1 ? 'mr-8' : ''
          }`}
        >
          {bd.label}
        </Button>
      ))}
    </div>
  );
};

const ReportingTable = ({ data, headers }) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [toolTipText, setTooltipText] = useState('');
  const handleTooltipTextChange = (errors) => {
    const errorString = errors.reduce((acc, curr, index) => {
      if (index === 0) {
        return `${curr[0].toUpperCase()}${curr.slice(1)}`;
      }
      return `${acc}, ${curr[0].toUpperCase()}${curr.slice(1)}`;
    }, '');
    setTooltipText(errorString);
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {toolTipText}
    </Tooltip>
  );
  // this happens when there are no records - can handle more gracefully
  if (data.length === 0) {
    return null;
  }

  return (
    <div className="border border-dark p-16 mb-32 annual-report-container">
      <Table responsive bordered>
        <thead>
          <tr className="sticky-top">
            <th> </th>
            {headers.map((heading, index) => (
              <th className="text-nowrap" key={index}>
                {heading.label}
              </th>
            ))}
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const { id, recordType, ...rowData } = item;
            let containsErrors = false;
            for (const key in rowData) {
              if (!!rowData[key].errors.length) {
                containsErrors = true;
                break;
              }
            }
            return (
              <tr
                key={index}
                onMouseEnter={() => handleRowHover(index)}
                onMouseLeave={() => handleRowHover(null)}
                className="h-100"
              >
                <td
                  className={`sticky-column p-0 align-middle pointer ${
                    containsErrors ? 'bg-warning' : 'bg-success'
                  }`}
                >
                  <FontAwesomeIcon
                    className={`px-16 py-8 ${
                      containsErrors ? '' : 'text-white'
                    }`}
                    icon={containsErrors ? warningIcon : checkmarkIcon}
                  />
                </td>
                {headers.map((header, columnIndex) => {
                  const columnData = rowData[header.key];

                  return (
                    <td key={columnIndex} valign="middle">
                      <div className="d-flex align-items-center justify-content-between">
                        {typeof columnData.value === 'object' &&
                        columnData.value !== null
                          ? JSON.stringify(columnData.value)
                          : !!columnData.value
                          ? columnData.value
                          : '-'}
                        {!!columnData.errors.length && (
                          <div
                            onMouseOver={() =>
                              handleTooltipTextChange(columnData.errors)
                            }
                          >
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltip}
                            >
                              {({ ref, ...triggerHandler }) => (
                                <div ref={ref} {...triggerHandler}>
                                  <FontAwesomeIcon
                                    className="px-16 py-8 text-warning"
                                    icon={warningIcon}
                                  />
                                </div>
                              )}
                            </OverlayTrigger>
                          </div>
                        )}
                      </div>
                    </td>
                  );
                })}
                <td
                  className={`align-middle bg-white rounded-0 px-8 ${
                    hoveredRowIndex === index ? 'sticky-column-end' : ''
                  }`}
                >
                  <ActionButtons id={id} recordType={recordType} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ReportingTable;
