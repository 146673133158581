import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import $newFundSignal, {
  $newFundFundingProgramMembershipsSignal,
  $newFundFundingProgramsSignal,
} from './NewFund.signals';
import history from '../../../history';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import $appSettings from 'signals/AppSettings.signal';
import { einRegex, emailRegex, phoneRegex } from '../Constant/regularExpression';

const getRemainingFunds = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  let remainingFundsInProgram = 0;
  let fundingProgramMembership = null;
  if (
    userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.vc
  ) {
    const fundingProgramMemberships =
      $newFundFundingProgramMembershipsSignal.value.fundingProgramMemberships;
    const fundingProgramMembershipId = Number(
      $newFundSignal.value.fundingProgramMembershipId.value
    );

    fundingProgramMembership = fundingProgramMemberships.find((fpm) => fpm.id === fundingProgramMembershipId);
    if (fundingProgramMembership) {
      const approvedAmount = fundingProgramMembership.programRequest?.approvedAmount || 0;
      const amountInOtherFunds = fundingProgramMembership.funds
        .map((f) => f.maxFundSize)
        .reduce((acc, amount) => acc + amount, 0);
      remainingFundsInProgram = approvedAmount - amountInOtherFunds > 0 ? approvedAmount - amountInOtherFunds : 0;
    }
  }

  if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo) {
    const fundingPrograms = $newFundFundingProgramsSignal.value.fundingPrograms;
    const fundingProgramId = $newFundSignal.value.fundingProgramId.value;

    const fundingProgram = fundingPrograms.find(fp => fp.id === Number(fundingProgramId));
    if (fundingProgram) {
      remainingFundsInProgram = fundingProgram.stats.remaining;
    }
  }

  return remainingFundsInProgram;
}

export function validateForm(e) {
  const { name, value } = e.target;
  const { createNewVc } = $newFundSignal.value;
  const remainingFundsInProgram = getRemainingFunds();
  let errors = [];
  if (name === 'fundName') {
    if (!value) {
      errors.push('Cannot be empty.');
    }
  }

  if (name === 'fundingProgramMembershipId') {
    if (!value) {
      errors.push('Cannot be empty.');
    }
  }

  if (name === 'maxFundSize') {
    if (value === '') {
      errors.push('Cannot be empty.');
    }

    if (Number(value) > remainingFundsInProgram) {
      errors.push(
        `Cannot exceed available funds: ${remainingFundsInProgram}`
      );
    }
  }

  if (name === 'fundingProgramId') {
    if (!value) {
      errors.push('Cannot be empty.');
    }
  }

  if (name === 'ventureCapitalFirm') {
    if (!value && !createNewVc.value) {
      errors.push('Please select a VC from the drop down.');
    }
  }

  if (createNewVc.value) {
    if (name === 'vcName') {
      if (!value) {
        errors.push('Cannot be empty.');
      }
    }

    if (name === 'ein') {
      if (!value) {
        errors.push('Cannot be empty.');
      }

      if (!einRegex.test(value)) {
        errors.push('Please use format XX-XXXXXXX');
      }
    }

    if (name === 'firstName') {
      if (!value) {
        errors.push('Cannot be empty.');
      }
    }

    if (name === 'lastName') {
      if (!value) {
        errors.push('Cannot be empty.');
      }
    }

    if (name === 'email') {
      if (!value) {
        errors.push('Cannot be empty.');
      }

      if (!emailRegex.test(value)) {
        errors.push(
          'Please provide a valid email address. Email usernames should be at least 2 characters and begin with a letter. The only special characters allowed are ._+-. There cannot be more than one occurence of the top level domain - ex. .com'
        );
      }
    }

    if (name === 'phoneNumber') {
      if (!value) {
        errors.push('Cannot be empty.');
      }

      if (value && !phoneRegex.test(value)) {
        errors.push('Please provide a valid phone number.');
      }
    }
  }

  return $newFundSignal.update({
    [name]: {
      ...$newFundSignal.value[name],
      errors,
    }
  });
};

export const handleNewFundFormChange = (e) => {
  const { name, value } = e;


  $newFundSignal.update({
    [name]: {
      errors: [],
      dirty: true,
      value,
    },
  });

  if (name === 'fundingProgramMembershipId' || name === 'fundingProgramId') {
    const { maxFundSize } = $newFundSignal.value;
    const remainingFundsInProgram = getRemainingFunds();

    if (Number(maxFundSize.value) > remainingFundsInProgram) {
      $newFundSignal.update({
        maxFundSize: {
          ...maxFundSize,
          errors: [`Cannot exceed available funds: ${remainingFundsInProgram}`],
        },
      });
    } else {
      $newFundSignal.update({
        maxFundSize: {
          ...maxFundSize,
          errors: [],
        },
      });
    }
  }
};

export const fetchFundingProgramMemberships = async () => {
  try {
    const fundingProgramMemberships = await api.get({
      path: '/fundingProgramMemberships',
      options: {
        where: {
          ventureCapitalFirmId:
            userAccountSignal.value.userData.account.ventureCapitalFirm.id,
        },
        include: {
          fundingProgram: true,
          funds: true,
          programRequest: true,
        },
      },
    });

    return $newFundFundingProgramMembershipsSignal.update({
      fundingProgramMemberships,
    });
  } catch (error) {
  } finally {
  }
};

export const handleAddNewRequiredDocument = () =>
  $newFundSignal.update({
    requiredDocuments: {
      ...$newFundSignal.value.requiredDocuments,
      value: $newFundSignal.value.requiredDocuments.value.concat({
        name: '',
        file: null,
      }),
    },
  });

export const handleRemoveRequiredDocument = (index) =>
  $newFundSignal.update({
    requiredDocuments: {
      ...$newFundSignal.value.requiredDocuments,
      value: $newFundSignal.value.requiredDocuments.value.filter(
        (_, i) => i !== index
      ),
    },
  });

export const handleRequiredDocumentNameChange = (index, name) =>
  $newFundSignal.update({
    requiredDocuments: {
      ...$newFundSignal.value.requiredDocuments,
      value: $newFundSignal.value.requiredDocuments.value.map((b, i) =>
        index === i ? { ...b, name } : b
      ),
    },
  });

export const handleRequiredDocumentAttachFile = (index, file) =>
  $newFundSignal.update({
    requiredDocuments: {
      ...$newFundSignal.value.requiredDocuments,
      value: $newFundSignal.value.requiredDocuments.value.map((b, i) =>
        index === i ? { ...b, file } : b
      ),
    },
  });

export const handleAddNewSuggestedDocument = () =>
  $newFundSignal.update({
    suggestedDocuments: {
      ...$newFundSignal.value.suggestedDocuments,
      value: $newFundSignal.value.suggestedDocuments.value.concat({
        name: '',
        file: null,
      }),
    },
  });

export const handleRemoveSuggestedDocument = (index) =>
  $newFundSignal.update({
    suggestedDocuments: {
      ...$newFundSignal.value.suggestedDocuments,
      value: $newFundSignal.value.suggestedDocuments.value.filter(
        (_, i) => i !== index
      ),
    },
  });

export const handleSuggestedDocumentNameChange = (index, name) =>
  $newFundSignal.update({
    suggestedDocuments: {
      ...$newFundSignal.value.suggestedDocuments,
      value: $newFundSignal.value.suggestedDocuments.value.map((b, i) =>
        index === i ? { ...b, name } : b
      ),
    },
  });

export const handleSuggestedDocumentAttachFile = (index, file) =>
  $newFundSignal.update({
    suggestedDocuments: {
      ...$newFundSignal.value.suggestedDocuments,
      value: $newFundSignal.value.suggestedDocuments.value.map((b, i) =>
        index === i ? { ...b, file } : b
      ),
    },
  });

const buildAndValidateFundPayload = async () => {
  const {
    fundingProgramId,
    vcName,
    ein,
    firstName,
    lastName,
    email,
    fundName,
    maxFundSize,
    createNewVc,
    fundingProgramMembershipId,
    requiredDocuments,
    suggestedDocuments,
    ventureCapitalFirm,
    phoneNumber,
  } = $newFundSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const [requiredDocumentSignedUrls, suggestedDocumentSignedUrls] =
    await Promise.all([
      uploadFilesToStorage(
        requiredDocuments.value.filter((v) => !!v.file).map((v) => v.file),
        'fundDocument'
      ),
      uploadFilesToStorage(
        suggestedDocuments.value.filter((v) => !!v.file).map((v) => v.file),
        'fundDocument'
      ),
    ]);

  const body = {
    data: {
      fundName: fundName.value,
      maxFundSize: Number(maxFundSize.value),
      documents: {
        create: []
          .concat(
            requiredDocuments.value
              .filter((v) => !!v.file)
              .map((e, i) => ({
                fileType: e.file.type,
                filePath: requiredDocumentSignedUrls[i],
                name: e.name,
                documentType: 'REQUIRED',
              }))
          )
          .concat(
            requiredDocuments.value
              .filter((v) => !v.file)
              .map((e) => ({
                fileType: null,
                filePath: null,
                name: e.name,
                documentType: 'REQUIRED',
              }))
          )
          .concat(
            suggestedDocuments.value.map((e, i) => ({
              fileType: e.file.type,
              filePath: suggestedDocumentSignedUrls[i],
              name: e.name,
              documentType: 'SUGGESTED',
            }))
          )
          .concat(
            suggestedDocuments.value
              .filter((v) => !v.file)
              .map((e) => ({
                fileType: null,
                filePath: null,
                name: e.name,
                documentType: 'SUGGESTED',
              }))
          ),
      },
    }
  };

  if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.vc) {
    const { fundingProgramMemberships } = $newFundFundingProgramMembershipsSignal.value;
    const fundingProgramMembership = fundingProgramMemberships.find(fpm => fpm.id === Number(fundingProgramMembershipId.value));

    body.data.fundingProgramMembership = {
      connect: {
        id: Number(fundingProgramMembershipId.value),
      }
    };

    body.data.fundingProgram = {
      connect: {
        id: fundingProgramMembership?.fundingProgramId,
      },
    };
  }

  if (
    userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo
  ) {
    body.data.fundingProgram = {
      connect: {
        id: Number(fundingProgramId.value),
      },
    };
    body.data.fundingProgramMembership = {
      create: {
        fundingProgramId: Number(fundingProgramId.value),
        approvedAmount: Number(maxFundSize.value),
      }
    }

    if (createNewVc.value) {
      body.ventureCapitalFirm = {
        data: {
          name: vcName.value,
          ein: ein.value,
          contactName: `${firstName.value} ${lastName.value}`,
          email: email.value,
          phoneNumber: phoneNumber.value,
        },
      }
    } else {
      body.data.fundingProgramMembership.create.ventureCapitalFirmId = Number(ventureCapitalFirm.value?.id);
    }
  }

  const dataKeysToValidate = [];
  Object.keys(body.data).forEach(field => {
    let name = field;

    if (field === 'documents') {
      return;
    }
    if (field === 'fundingProgram') {
      if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.vc) {
        return; // do not need to validate this field if VC b/c it is pulled from the funding program membership
      }
      name = 'fundingProgramId';
    }
    if (field === 'fundingProgramMembership') {
      if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo) {
        name = 'ventureCapitalFirm'; //validate that there is a venture capital firm id
      } else {
        name = 'fundingProgramMembershipId';
      }
    }

    dataKeysToValidate.push(name);
  });

  if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo && createNewVc.value) {
    Object.keys(body.ventureCapitalFirm.data).forEach(field => {
      let name = field;
      if (field === 'name') {
        name = 'vcName';
      }

      if (field === 'contactName') {
        return dataKeysToValidate.push('firstName', 'lastName');
      }

      dataKeysToValidate.push(name);
    });
  }

  Object.keys($newFundSignal.value).forEach(field => {
    if (dataKeysToValidate.includes(field)) {
      return validateForm({ target: { name: field, value: $newFundSignal.value[field].value } });
    }
    $newFundSignal.update({
      [field]: {
        ...$newFundSignal.value[field],
        errors: [],
      }
    })
  });

  if (Object.values($newFundSignal.value).find(value => !!value.errors.length)) {
    throw new Error('Please review the errors in your form.');
  }

  return body;
};

export const createNewFund = async () => {
  loaderSignal.update({ isContentLoading: true, message: 'Creating fund...' });
  try {
    const body = await buildAndValidateFundPayload();

    const newFund = await api.post({
      path: '/funds',
      body,
    });

    $newFundSignal.reset();
    history.push(
      `/${history.location.pathname.split('/')[1]}/funds/${newFund.id}`
    );
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Fund has been successfully created',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      // error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetEdoNewFundData = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const fundingPrograms = await api.get({
      path: '/fundingPrograms',
      options: {
        where: {
          referenceProgram: {
            programTypeId: 2, //Equity
          },
        },
        include: {
          stats: true,
          memberships: {
            include: {
              ventureCapitalFirm: true,
              funds: true,
            },
          },
        },
      },
    });

    $newFundFundingProgramsSignal.update({ fundingPrograms });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const newFundCleanup = () => {
  $newFundFundingProgramsSignal.reset();
  $newFundFundingProgramMembershipsSignal.reset();
  $newFundSignal.reset();
};

export const handleVcSelect = (option) => {
  $newFundSignal.update({
    ventureCapitalFirm: {
      ...$newFundSignal.value.ventureCapitalFirm,
      value: option.ventureCapitalFirm,
      errors: [],
    },
  });
};

export const handleCreateNewVcChange = () => {
  const { createNewVc, ventureCapitalFirm } = $newFundSignal.value;

  $newFundSignal.update({
    createNewVc: {
      ...createNewVc,
      value: !createNewVc.value,
    },
    ventureCapitalFirm: {
      ...ventureCapitalFirm,
      errors: [],
    },
  });
};

export const getVcOptions = async () => {
  const { query } = $newFundSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  try {
    const vcs = await api.get({
      path: '/organizations',
      options: {
        take: 10,
        where: {
          type: PORTAL_TYPES.vc,
          OR: [
            {
              ein: {
                contains: query.value,
              },
            },
            {
              name: {
                contains: query.value,
                mode: 'insensitive',
              },
            },
          ],
        },
        include: {
          ventureCapitalFirm: true,
        },
      },
    });

    $newFundFundingProgramsSignal.update({ vcs });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  }
};

export const handleVcQueryChange = (e) => {
  const { value } = e;
  const { query, ventureCapitalFirm } = $newFundSignal.value;

  $newFundSignal.update({
    query: {
      ...query,
      value,
    },
    ventureCapitalFirm: {
      ...ventureCapitalFirm,
      value: null,
    },
  });
};

export const handleSearchVcFocus = () => {
  const { query, ventureCapitalFirm } = $newFundSignal.value;
  $newFundSignal.update({
    query: {
      ...query,
      value: ventureCapitalFirm.value?.name || query.value,
    },
  });
};

export const handleSearchVcBlur = () =>
  validateForm({
    target: {
      name: 'ventureCapitalFirm',
      value: $newFundSignal.value.ventureCapitalFirm.value,
    },
  });
