import { notificationDrawerSignal } from 'signals/Notification.signal';
import {
  NOTIFICATION_INFO,
  PORTAL_TYPE_BASE,
} from '../components/global/Constant/constants';

export const buildNotificationParams = (
  notification,
  history,
  portal,
  markRead
) => {
  const actionLocation = NOTIFICATION_INFO[notification.key]?.actionLocation;
  let notificationActionLocationPath = null;
  if (typeof actionLocation === 'string') {
    notificationActionLocationPath = `${PORTAL_TYPE_BASE[portal]}${actionLocation}`;
  } else if (typeof actionLocation === 'function') {
    notificationActionLocationPath = () =>
      `${PORTAL_TYPE_BASE[portal]}${actionLocation(
        notification.modelId,
        portal,
        notification
      )}`;
  }

  const notificationTitle = notification.title || '';
  const notificationMessage = notification.message || '';

  let endpointName = null;
  if (typeof notificationActionLocationPath === 'string') {
    endpointName = actionLocation.split('/')[1];
  } else if (typeof notificationActionLocationPath === 'function') {
    endpointName = 'function';
  }

  const actionTitle = NOTIFICATION_INFO[notification.key]?.actionTitle;
  const isRead =
    notification.alertInteractions.length !== 0 &&
    notification.alertInteractions[0].read;

  const handleNavigation = () => {
    if (typeof notificationActionLocationPath === 'string') {
      history.push(notificationActionLocationPath);
    } else if (typeof notificationActionLocationPath === 'function') {
      history.push(notificationActionLocationPath());
    }
    markRead(notification.id);
    notificationDrawerSignal.update({ isOpen: false });
  };

  return {
    actionTitle,
    endpointName,
    handleNavigation,
    isRead,
    notificationMessage,
    notificationTitle,
  };
};
