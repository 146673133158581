import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { warningIcon } from 'components/global/Constant/Icons';
import { businessSettingsDirtySignal } from 'signals/BusinessSettings.signal';

const BusinessUnsavedChangesAlert = () => {
  const businessFormDirty = businessSettingsDirtySignal.value;

  return (
    <Alert
      style={{
        visibility: Object.values(businessFormDirty).length ? '' : 'hidden',
      }}
      variant="warning"
      className="mb-0 ml-16"
    >
      <FontAwesomeIcon icon={warningIcon} /> You have unsaved changes. Unsaved
      changes will be lost when switching sections.
    </Alert>
  );
};

export default BusinessUnsavedChangesAlert;
