import Signal from 'libs/signals';

const stateBillingSignal = Signal({
  selectedAccount: {
    accountName: '',
    accountID: '',
    status: '',
    linkedPrograms: '',
    actions: '',
  },
  paymentAccounts: [],
});

export default stateBillingSignal;
