import api from "api/api";
import alertSignal from "signals/Alert.signal";
import platformsSignal, {
  editPlatformPaymentOptionsSignal,
  editPlatformTranchesSignal,
  FormTranch
} from "./Platforms.signal";
import { Signal } from "@preact/signals-react";
import { BankAccount, Platform, Tranch } from "types";
import userAccountSignal from "signals/UserAccount.signal";
import history from "../../../history";
import loaderSignal from "signals/Loader.signal";
import { getConfig } from "config/config";
import $appSettings from "signals/AppSettings.signal";

export const fetchAndSetPlatformData = async (): Promise<Signal<unknown>> => {
  loaderSignal.update({ isContentLoading: true });

  const hostname = window.location.hostname;
  let jurisdiction = 'Louisiana';

  if (hostname === 'ca.caat.io') {
    jurisdiction = 'California';
  }

  if (hostname === 'al.caat.io') {
    jurisdiction = 'Alabama';
  }

  try {
    const [platforms, bankAccounts, users, treasuryAllocation] =
      await Promise.all([
        api.get({
          path: '/platforms',
          options: {
            include: {
              stats: true,
              fundingPrograms: true,
              bankAccountPlatformLinks: true,
              tranches: true,
            },
          },
        }),
        api.get({
          path: '/bankAccounts',
          options: {
            where: {
              accountId: userAccountSignal.value.userData.account.id!,
            },
          },
        }),
        api.get({
          path: '/users',
          options: {
            where: {
              accountId: userAccountSignal.value.userData.account.id!,
            },
          },
        }),
        api.get({
          path: '/allocations',
          options: {
            where: {
              jurisdiction,
            },
          },
        }),
      ]);

    return platformsSignal.update({
      platforms,
      bankAccounts,
      users,
      treasuryAllocation: treasuryAllocation[0],
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showNewPlatformModal = (): Signal<unknown> =>
  platformsSignal.update({ createPlatformModalVisible: true });

export const hideNewPlatformModal = (): Signal<unknown> =>
  platformsSignal.update({
    createPlatformModalVisible: false,
    newPlatformName: '',
  });

export const updateNewPlatformName = (
  newPlatformName: string
): Signal<unknown> => platformsSignal.update({ newPlatformName });

export const updateNewPlatformType = (value: string): Signal<unknown> =>
  platformsSignal.update({ newPlatformType: value });

export const updateNewPlatformTargetTransactionLeverageRatio = (
  value: string
): Signal<unknown> =>
  platformsSignal.update({
    newPlatformTargetTransactionLeverageRatio: value.replace(/[^0-9.]/g, ''),
  });

export const submitNewPlatform = async (): Promise<Signal<unknown>> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting platform...',
  });
  try {
    await api.post({
      path: '/platforms',
      body: {
        data: {
          name: platformsSignal.value.newPlatformName,
          platformType: platformsSignal.value.newPlatformType,
          targetTransactionLeverageRatio: Number(
            platformsSignal.value.newPlatformTargetTransactionLeverageRatio
          ),
          // pass in empty value for now
          description: '',
        },
      },
    });

    await fetchAndSetPlatformData();
    hideNewPlatformModal();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully created a program',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showEditPlatformModal = (
  platformBeingEdited: Platform
): Signal<unknown> => {
  const { bankAccounts } = platformsSignal.value;
  const { caatForPaymentReconciliation, tranches, bankAccountPlatformLinks } =
    platformBeingEdited;
  editPlatformTranchesSignal.update({
    tranches,
  });

  editPlatformPaymentOptionsSignal.update({
    useCaatForPaymentReconciliation: caatForPaymentReconciliation,
    selectedBankAccounts: bankAccountPlatformLinks.map(
      (bapl) => bankAccounts.find((ba) => ba.id === bapl.bankAccountId)!
    ),
  });

  return platformsSignal.update({ platformBeingEdited });
};

export const hideEditPlatformModal = (): Signal<unknown> =>
  platformsSignal.update({
    platformBeingEdited: null,
  });

export const handleEditPlatformCaatAsReconciliationToolChange =
  (): Signal<unknown> =>
    editPlatformPaymentOptionsSignal.update({
      useCaatForPaymentReconciliation:
        !editPlatformPaymentOptionsSignal.value.useCaatForPaymentReconciliation,
      selectedBankAccounts: [],
    });

export const handleEditPlatformBankAccountCheckboxChange = (
  bankAccount: BankAccount
): Signal<unknown> => {
  const { selectedBankAccounts } = editPlatformPaymentOptionsSignal.value;
  if (selectedBankAccounts.map((ba) => ba.id).includes(bankAccount.id)) {
    return editPlatformPaymentOptionsSignal.update({
      selectedBankAccounts: selectedBankAccounts.filter(
        (ba) => ba.id !== bankAccount.id
      ),
    });
  } else {
    return editPlatformPaymentOptionsSignal.update({
      selectedBankAccounts: [...selectedBankAccounts, ...[bankAccount]],
    });
  }
};

export const handleGoToBilling = (): unknown =>
  history.push('/edo/organization?sourceBankAccounts=');

export const handleTrancheDateChange = (
  val: string,
  id: number
): Signal<unknown> => handleTrancheChange({ date: val }, id);

export const handleTrancheAmountChange = (
  val: string,
  id: number
): Signal<unknown> => handleTrancheChange({ amount: val }, id);

const handleTrancheChange = (
  payload: Partial<FormTranch>,
  id: number
): Signal<unknown> => {
  const { tranches } = editPlatformTranchesSignal.value;
  return editPlatformTranchesSignal.update({
    tranches: tranches.map((t) => {
      if (t.id === id) {
        return { ...t, ...payload } as Tranch;
      }

      return t as Tranch;
    }),
  });
};

export const handleNewTrancheChange = (
  name: string,
  value: string
): Signal<unknown> => {
  if (name === 'amount') {
    return editPlatformTranchesSignal.update({
      newTranche: {
        ...editPlatformTranchesSignal.value.newTranche,
        [name]: Number(value.replace(/\D/g, '')),
      },
    });
  } else {
    return editPlatformTranchesSignal.update({
      newTranche: {
        ...editPlatformTranchesSignal.value.newTranche,
        [name]: value,
      },
    });
  }
};

export const handleAddTranch = async (): Promise<unknown> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting tranche...',
  });
  try {
    const { platformBeingEdited } = platformsSignal.value;
    const { newTranche } = editPlatformTranchesSignal.value;
    if (newTranche.amount === '' || newTranche.date === '') {
      throw new Error('Please make sure the tranch is completely filled out.');
    }
    await api.post({
      path: '/tranches',
      body: {
        data: {
          ...newTranche,
          platformId: platformBeingEdited?.id,
          amount: Number(newTranche.amount),
          date: new Date(newTranche.date).toISOString().split('T')[0],
        },
      },
    });
    await fetchAndSetPlatformData();
    const platform = platformsSignal.value.platforms.find(
      (p) => p.id === platformsSignal.value.platformBeingEdited?.id
    );
    showEditPlatformModal(platform as Platform);
    editPlatformTranchesSignal.update({
      newTranche: { amount: '', date: '' },
    });
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      // @ts-ignore
      message: 'Successfully created new tranche',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleEditTrancheSubmit = async (): Promise<Signal<unknown>> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating tranche...',
  });
  try {
    const { tranches, editTrancheId } = editPlatformTranchesSignal.value;
    const tranche = tranches.find((t) => t.id === editTrancheId)!;
    if (tranche && (!tranche.amount || tranche.date === '')) {
      throw new Error('Please make sure the tranch is completely filled out.');
    }
    await api.patch({
      path: '/tranches',
      body: {
        where: {
          id: editTrancheId,
        },
        data: {
          amount: Number(tranche?.amount),
        },
      },
    });
    await fetchAndSetPlatformData();
    const platform = platformsSignal.value.platforms.find(
      (p) => p.id === platformsSignal.value.platformBeingEdited?.id
    );
    showEditPlatformModal(platform as Platform);
    editPlatformTranchesSignal.update({
      editTrancheId: null,
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated tranches.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleRemoveTrancheSubmit = async (): Promise<Signal<unknown>> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Removing tranche...',
  });
  try {
    const { trancheIdToRemove } = editPlatformTranchesSignal.value;
    await api.delete({
      path: '/tranches',
      body: {
        where: {
          id: trancheIdToRemove,
        },
      },
    });
    await fetchAndSetPlatformData();
    const platform = platformsSignal.value.platforms.find(
      (p) => p.id === platformsSignal.value.platformBeingEdited?.id
    );
    showEditPlatformModal(platform as Platform);
    editPlatformTranchesSignal.update({
      trancheIdToRemove: null,
      removeTrancheConfirmModalVisible: false,
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated tranches.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handlePlatformBankAccountLinksSubmission = async (): Promise<
  Signal<unknown>
> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'saving...',
  });
  try {
    const { selectedBankAccounts, useCaatForPaymentReconciliation } =
      editPlatformPaymentOptionsSignal.value;

    await api.patch({
      path: '/platforms',
      body: {
        where: {
          id: platformsSignal.value.platformBeingEdited?.id,
        },
        data: {
          caatForPaymentReconciliation: useCaatForPaymentReconciliation,
        },
      },
    });

    await api.post({
      path: '/bankAccountPlatformLinks',
      body: {
        where: {
          id: platformsSignal.value.platformBeingEdited?.id,
        },
        data: selectedBankAccounts.map((ba) => ({
          bankAccountId: ba.id,
          platformId: platformsSignal.value.platformBeingEdited?.id,
        })),
      },
    });

    await fetchAndSetPlatformData();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated the payment configuration.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const toggleEditTranche = (
  id: number | null = null
): Signal<unknown> => {
  const { platformBeingEdited } = platformsSignal.value;
  editPlatformTranchesSignal.update({ editTrancheId: id });
  return showEditPlatformModal(platformBeingEdited as Platform);
};

export const toggleRemoveTrancheConfirmModal = (
  id: number | null = null
): Signal<unknown> => {
  if (id !== null && id >= 0) {
    return editPlatformTranchesSignal.update({
      trancheIdToRemove: id,
      removeTrancheConfirmModalVisible: true,
    });
  }

  return editPlatformTranchesSignal.update({
    removeTrancheConfirmModalVisible: false,
  });
};

export const validateTrancheEditIsWithinGracePeriod = (
  createdDate: string
): boolean | number => {
  const { TRANCHE_GRACE_PERIODS } = $appSettings.value.constants;
  const config = getConfig('APP_CONFIG');
  const { appId } = config;
  const currentDate = new Date();
  const trancheCreatedDate = new Date(createdDate);
  const timeDifference = currentDate.getTime() - trancheCreatedDate.getTime();
  const gracePeriod =
    (TRANCHE_GRACE_PERIODS as Record<string, number>)[appId] ||
    TRANCHE_GRACE_PERIODS.dev;
  const gracePeriodInMilliseconds = gracePeriod * 60 * 60 * 1000;

  if (timeDifference >= gracePeriodInMilliseconds) {
    return false;
  }

  const remainingTimeInMilliseconds =
    gracePeriodInMilliseconds - timeDifference;
  return Math.ceil(remainingTimeInMilliseconds / 3600000);
};
