import { Form } from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import { handleSelectChange } from '../Field';

const Country = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
}) => (
  <div className={className}>
    <Form.Label className={labelClassName}>{label}</Form.Label>
    <CountryDropdown
      value={value}
      onChange={(updatedValue) => { handleSelectChange({ name, value: updatedValue }, onChange); }}
      classes={inputClassName}
    />
  </div>
);

export default Country;
