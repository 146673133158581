import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import BusinessWrapper from '../components/BusinessWrapper';
import {
  handleRemoveSavedOpportunityClickFromDetailView,
  handleSaveOpportunityClickFromDetailView,
} from '../BusinessSearchResults/BusinessSearchResults.helpers';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import capitalizeFirst from 'utils/capitalizeFirst';

const BusinessOrganizationDetails = () => {
  const { pathname } = useLocation();
  const segments = pathname.split('/');
  let organizationType = segments[segments.length - 2];
  const organizationId = segments[segments.length - 1];
  const path = organizationType === 'vc' ? '/ventureCapitalFirms' : '/lenders';
  const organizationIdKey =
    organizationType === 'vc' ? 'ventureCapitalFirmId' : 'lenderId';
  const { selectedOrganization } = organizationDetailsSignal.value;
  const isFavorite = Object.keys(selectedOrganization).length
    ? selectedOrganization.favoritedByBusinesses?.find(
        (fav) =>
          fav.businessId ===
          userAccountSignal.value.userData.account.business.id
      )
    : false;

    const labels = [
      capitalizeFirst(segments[2]),
      selectedOrganization?.name
    ];

    if (pathname.startsWith('/business/search')) {
      labels.splice(1, 0, 'Search Results');
    }

  useEffect(() => {
    fetchOrganization(organizationId, path);
  }, [organizationId]);

  return (
    <BusinessWrapper>
      <BreadCrumbWrapper labels={labels}>
        <Card>
          <Card.Body>
            <OrganizationDetails />
            <hr />
            <div className="text-center">
              <Button
                hidden={isFavorite}
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() =>
                  handleSaveOpportunityClickFromDetailView(
                    { [organizationIdKey]: Number(organizationId) },
                    organizationId,
                    path
                  )
                }
              >
                Save Opportunity
              </Button>
              <Button
                hidden={!isFavorite}
                style={{ minWidth: '280px' }}
                className="rounded"
                variant="secondary"
                size="lg"
                onClick={() =>
                  handleRemoveSavedOpportunityClickFromDetailView(
                    isFavorite.id,
                    organizationId,
                    path
                  )
                }
              >
                Unsave Opportunity
              </Button>
            </div>
          </Card.Body>
        </Card>
      </BreadCrumbWrapper>
    </BusinessWrapper>
  );
};

export default BusinessOrganizationDetails;
