import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import {Button, Col, Image, Modal, ProgressBar, Row} from 'react-bootstrap';

export const BasicFile = ({ file }) => (
  <>
    <Row>
      <Col><p>{file && file.name}</p></Col>
      <Col><Button variant="none" className="p-0 text-primary">View</Button></Col>
    </Row>
  </>
);

const File = ({ file, onFileCancel, uploadProgress = 0 }) => {
  const [imgData, setImgData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const setAndShowPreview = () => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(file);
    setShowPreview(true);
  };

  return (
    <>
      <Col><p>{file && file.name}</p></Col>
      <Col hidden={uploadProgress === 0}>
        <ProgressBar now={uploadProgress} />
      </Col>
      <Col xs={2}>
        <Button variant="none" className="p-0 text-primary" onClick={setAndShowPreview}>View</Button>
      </Col>
      <Col xs={2}>
        <Button variant="none" className="p-0 text-danger"><FontAwesomeIcon onClick={() => onFileCancel(file)} icon={faMinusCircle} /></Button>
      </Col>
      <Modal show={showPreview} onHide={() => setShowPreview(false)} fullscreen>
        <Modal.Header>
          <Modal.Title>{file && file.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <Image className="border border-secondary" width={450} src={imgData} alt="file" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPreview(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default File;
