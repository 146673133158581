import approvalChainTemplatingSignal, {
  APPROVAL_CHAIN_TEMPLATE_STEP_INIT_STATE,
} from './ApprovalChainTemplating.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import {
  fundingProgramsSignal,
  selectedFundingProgramSignal,
} from '../../../signals/Program.signal';
import { fetchAndSetPrograms } from '../../views/Edo/EdoPrograms/EdoPrograms.helpers';
import loaderSignal from 'signals/Loader.signal';
import { selectFundingProgramForEditing } from '../../views/Edo/EdoProgramDetail/EdoProgramDetail.helpers';
import { EDIT_PROGRAM_MODAL_EVENT_MAP } from '../Constant/tabEventKeys';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetApprovalChainUsers = async () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  loaderSignal.update({ isContentLoading: true });
  try {
    const users = await api.get({
      path: '/users',
      options: {
        where: {
          account: {
            portalType: PORTAL_TYPES.edo,
          },
        },
      },
    });
    return approvalChainTemplatingSignal.update({ users });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleUpdateThreshold = (amount) => {
  const thresholdsMatrix = approvalChainTemplatingSignal.value.thresholdsMatrix;
  try {
    if (thresholdsMatrix.length !== 2) {
      throw new Error(
        "Cannot update threshold value if threshold hasn't been specified"
      );
    }

    const [firstThresholdRow, secondThresholdRow] = thresholdsMatrix;
    firstThresholdRow[1] = amount;
    secondThresholdRow[0] = amount;
    return approvalChainTemplatingSignal.update({
      thresholdsMatrix: [firstThresholdRow, secondThresholdRow],
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const handleAddThreshold = (amount) => {
  const { thresholdsMatrix, approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  try {
    const index = thresholdsMatrix.findIndex(
      ([min, max]) => min < amount && amount < max
    );
    if (index < 0) {
      throw new Error(
        `There is no bounding threshold for amount: ${amount}  or amount is a boundary`
      );
    }

    const thresholdRow = thresholdsMatrix[index];
    const thresholdUpperBoundedByAmount = [thresholdRow[0], amount];
    const thresholdLowerBoundedByAmount = [amount, thresholdRow[1]];

    const thresholdsStart = thresholdsMatrix.slice(0, index);
    const thresholdsEnd = thresholdsMatrix.slice(index + 1);

    return approvalChainTemplatingSignal.update({
      thresholdsMatrix: [
        ...thresholdsStart,
        thresholdUpperBoundedByAmount,
        thresholdLowerBoundedByAmount,
        ...thresholdsEnd,
      ],
      approvalChainTemplateStepsMatrix: [
        ...approvalChainTemplateStepsMatrix.slice(0, index + 1),
        APPROVAL_CHAIN_TEMPLATE_STEP_INIT_STATE,
        ...approvalChainTemplateStepsMatrix.slice(index + 1),
      ],
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const handleAddApprovalChainApprover = (index) => {
  const { approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  return approvalChainTemplatingSignal.update({
    approvalChainTemplateStepsMatrix: approvalChainTemplateStepsMatrix.map(
      (tss, i) =>
        index === i ? tss.concat(APPROVAL_CHAIN_TEMPLATE_STEP_INIT_STATE) : tss
    ),
  });
};

export const handleRemoveApprovalChainApprover = (index, jndex) => {
  const { approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  return approvalChainTemplatingSignal.update({
    approvalChainTemplateStepsMatrix: approvalChainTemplateStepsMatrix.map(
      (tss, i) => {
        if (index === i) {
          return tss.filter((ts, j) => jndex !== j);
        }
        return tss;
      }
    ),
  });
};

export const getTakenPrimaryUserIds = () => {
  const { approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  return approvalChainTemplateStepsMatrix.reduce(
    (acc, tss) => acc.concat([...tss.map((ts) => ts.primaryApproverId)]),
    []
  );
};

export const handleApprovalChainBoardApprovalChange = (
  requiresBoardApproval,
  index
) => {
  const { approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  return approvalChainTemplatingSignal.update({
    approvalChainTemplateStepsMatrix: approvalChainTemplateStepsMatrix.map(
      (tss, i) => {
        if (index === i) {
          return [
            {
              ...APPROVAL_CHAIN_TEMPLATE_STEP_INIT_STATE[0],
              boardRepresentative: requiresBoardApproval,
            },
          ];
        }
        return tss;
      }
    ),
  });
};

const resetState = () => {
  const { users } = approvalChainTemplatingSignal.value;
  approvalChainTemplatingSignal.reset();
  return approvalChainTemplatingSignal.update({ users });
};

export const handleApprovalChainFieldChange = (key, index, jndex, value) => {
  const { approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  if (key === 'amount' || key === 'oneThresholdActive') {
    approvalChainTemplatingSignal.update({
      [key]: value,
    });

    if (key === 'oneThresholdActive' && value === 'false') {
      resetState();
    }

    return;
  }

  return approvalChainTemplatingSignal.update({
    approvalChainTemplateStepsMatrix: approvalChainTemplateStepsMatrix.map(
      (tss, i) => {
        if (index === i) {
          return tss.map((ts, j) => {
            if (j === jndex) {
              if (key === 'alternateApprovers') {
                return { ...ts, [key]: [value] };
              } else {
                return { ...ts, [key]: value };
              }
            }

            return ts;
          });
        }
        return tss;
      }
    ),
  });
};

export const handleApprovalChainSubmission = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating approval chain...',
  });
  try {
    const { approvalChainTemplateStepsMatrix, thresholdsMatrix } =
      approvalChainTemplatingSignal.value;

    const body = {
      data: {
        thresholds: thresholdsMatrix,
        fundingProgramId: selectedFundingProgramSignal.value.programInfo.id,
        steps: {
          create: [...approvalChainTemplateStepsMatrix]
            .map((tss, index) => {
              const thresholdRow = thresholdsMatrix[index];
              return tss.map((ts) => ({
                ...ts,
                min: thresholdRow[0],
                max: thresholdRow[1],
              }));
            })
            .flatMap((a) => a)
            .map((ts) => ({
              primaryApprover: { connect: { id: ts.primaryApproverId } },
              role: ts.role,
              min: ts.min,
              max: ts.max,
              boardRepresentative: ts.boardRepresentative,
              alternateApprovers: {
                createMany: {
                  data: ts.alternateApprovers.map((aa) => ({ userId: aa })),
                  skipDuplicates: true,
                },
              },
            }))
            .reverse(),
        },
      },
    };

    await api.post({ path: '/approvalChainTemplates', body });

    await fetchAndSetPrograms();
    const updatedSelectedFundingProgram = fundingProgramsSignal.value.find(
      (fundingProgram) =>
        fundingProgram.id === selectedFundingProgramSignal.value.programInfo.id
    );
    selectFundingProgramForEditing(
      updatedSelectedFundingProgram,
      EDIT_PROGRAM_MODAL_EVENT_MAP.approvalChain
    );

    resetState();
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully created new approval chain template.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const canSubmitApprovalChain = () => {
  const { approvalChainTemplateStepsMatrix } =
    approvalChainTemplatingSignal.value;

  return approvalChainTemplateStepsMatrix.every((tss) => {
    if (tss.length === 0) {
      return false;
    }

    return tss.every((ts) => {
      if (ts.boardRepresentative) {
        return !!ts.primaryApproverId;
      } else {
        return !!ts.primaryApproverId && !!ts.role;
      }
    });
  });
};
