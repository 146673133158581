import Signal from 'libs/signals';

export const programAccessSignal = Signal({
  selectedUser: null,
  fundingPrograms: [],
  programsInView: [],
  programsFilteredBySearch: [],
  page: 0,
});

export default {};
