import Signal from "libs/signals";
import { PORTFOLIO_COMPANY_MODAL_VIEWS } from "./addPortfolioCompany.helpers";
import { Fund } from "types";

type AddPortfolioCompanySignal = {
  modalVisible: boolean;
  newVcPortfolioCompanyBusinessEin: string;
  newVcPortfolioCompanyBusinessName: string;
  newVcPortfolioCompanyFundId: string;
  newVcPortfolioCompanyBusinessEmail: string;
  newVcPortfolioCompanyFirstName: string;
  newVcPortfolioCompanyLastName: string;
  currentView: string;
  existingBusinessId: number | null;
  newVcPortfolioCompanySediCertification: {
    isSediOwned: string;
    sediStatusByBusinessAddressInCDFIInvestmentArea: string;
    selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea: string;
    selfCertifiedSEDIFutureLocationInCDFIInvestmentArea: string;
    selfCertifiedSEDIDemographicsRelatedBusinessStatus: string[];
  };
  funds: Fund[];
  parentView: string | null;
};

const addPortfolioCompanySignal = Signal<AddPortfolioCompanySignal>({
  modalVisible: false,
  newVcPortfolioCompanyBusinessName: '',
  newVcPortfolioCompanyBusinessEin: '',
  newVcPortfolioCompanyFundId: '',
  existingBusinessId: null,
  newVcPortfolioCompanyBusinessEmail: '',
  newVcPortfolioCompanyFirstName: '',
  newVcPortfolioCompanyLastName: '',
  currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.EIN,
  newVcPortfolioCompanySediCertification: {
    isSediOwned: '',
    sediStatusByBusinessAddressInCDFIInvestmentArea: '',
    selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea: '',
    selfCertifiedSEDIFutureLocationInCDFIInvestmentArea: '',
    selfCertifiedSEDIDemographicsRelatedBusinessStatus: [''],
  },
  funds: [],
  parentView: null,
});

export default addPortfolioCompanySignal;
