import api from "api/api";
import userAccountSignal from "signals/UserAccount.signal";
import { Alert } from "types";
import pushNotificationsSignal from "./PushNotifications.signal";
import { Signal } from "@preact/signals-react";
import { fetchAndSetNotifications } from "../NotificationPage/NotificationPage.helpers";
import { fetchAndSetDrawerNotifications } from "../NotificationDrawer/NotificationDrawer.helpers";

function instantiateWsClient(url: string) {
  return new WebSocket(url);
}

function joinGroupNotifications(wsClient: WebSocket) {
  return wsClient.send(
    JSON.stringify({
      type: 'joinGroup',
      group: userAccountSignal.value.userData.account.uuid,
      ackId: 1,
    })
  );
}

let timeout: NodeJS.Timeout;

export const getNotificationsToken = async (): Promise<any> => {
  try {
    const { url } = await api.post({
      path: '/pubsub',
      body: {
        data: {
          hub: 'notifications',
        },
      },
    });

    const wsClient = instantiateWsClient(url);
    wsClient.onmessage = async (message) => {
      fetchAndSetNotifications(false);
      fetchAndSetDrawerNotifications(false);

      if (message.data) {
        const json: Alert = JSON.parse(message.data);
        pushNotificationsSignal.update({ notification: json });

        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
          const insideData = pushNotificationsSignal.value;
          if (insideData.notification?.id === json.id) {
            pushNotificationsSignal.update({ notification: null });
          }
        }, 12000);
      }
    };
    return joinGroupNotifications(wsClient);
  } catch (error) {}
};

export const dismissPushNotification = (): Signal<unknown> =>
  pushNotificationsSignal.update({ notification: null });
