import { Form } from "react-bootstrap";
import { handleInputChange } from "../Field";

const Checkbox = ({
  label,
  value,
  name,
  className,
  inputClassName,
  onChange,
  disabled = false,
  labelClassName,
}) => (
  <div className={className}>
    <Form.Group className={`form-group ${labelClassName}`}>
      <Form.Check
        disabled={disabled}
        label={label}
        className={inputClassName}
        name={name}
        checked={value}
        value={value}
        onChange={(e) => handleInputChange(e, onChange)}
      />
    </Form.Group>
  </div>
);

export default Checkbox;
