import { effect } from '@preact/signals-react';
import Signal from '../libs/signals';
import userAccountSignal from './UserAccount.signal';

effect(() => {
  const userAccountValue = userAccountSignal.value;
  if (userAccountValue.userData && userAccountValue.userData.accountId && globalProfileSignal.value.form === null) {
    globalProfileSignal.update({ form: userAccountValue.userData });
  }
});

const globalProfileSignal = Signal({ form: null, errors: {}, addressOptions: [], address: '', addressSearch: '' });

export default globalProfileSignal;
