import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const TextInput = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  disabled,
  onBlur,
  isInvalid,
  inputRef,
  style,
  onKeyDown,
}) => (
  <div className={className} style={style}>
    {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
    <div>
      <Form.Control
        ref={inputRef}
        disabled={disabled}
        name={name}
        value={value}
        className={inputClassName}
        onChange={(e) => handleInputChange(e, onChange)}
        onBlur={onBlur}
        isInvalid={isInvalid}
        onKeyDown={onKeyDown}
      />
    </div>
  </div>
);

export default TextInput;
