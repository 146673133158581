import { useEffect, useRef } from 'react';

const FileDropZoneWrapper = ({
  children,
  onUpload,
  numOfUploadsAllowed = 1,
  formats = ['jpeg', 'pdf', 'xlsx'],
  maxSizeMB = 15,
}) => {
  const drop = useRef(null);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.type = "file";
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    return handleFiles(files);
  };

  const handleFileSelection = (e) => {
    e.preventDefault();
    const { files } = e.target;
    return handleFiles(files);
  };

  const handleFiles = (files) => {
    const fileArr = [];

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileArr.push(files[i]);
      }
    } else {
      return;
    }

    const isInFormat = (file) =>
      !formats.some((format) =>
        file.name.toLowerCase().endsWith(format.toLowerCase())
      );
    const isSizeGreaterThanMax = (file) => file.size > maxSizeMB * 10 ** 6;

    if (files.length > numOfUploadsAllowed) {
      // Show error `Cannot upload more than ${numOfUploadsAllowed} files.`
    } else if (formats && fileArr.some(isInFormat)) {
      // Show error `The following file formats are acceptable: ${formats.join(', ')}`
    } else if (fileArr.some(isSizeGreaterThanMax)) {
      // Show error `Max file size allowed is ${maxSizeMB * 10 ** 6}`
    } else {
      onUpload(fileArr);
      handleReset();
    }
  };

  useEffect(() => {
    const dragOverListener = drop.current.addEventListener(
      'dragover',
      handleDragOver
    );
    const dropListener = drop.current.addEventListener('drop', handleDrop);

    return () => {
      drop.current?.removeEventListener('dragover', dragOverListener);
      drop.current?.removeEventListener('drop', dropListener);
    };
  }, []);

  return (
    <div
      ref={drop}
      onClick={() =>
        document.getElementById('file-uploader-hidden-input').click()
      }
    >
      <input
        ref={fileInputRef}
        type="file"
        hidden
        id="file-uploader-hidden-input"
        multiple={numOfUploadsAllowed > 1}
        onChange={handleFileSelection}
      />
      {children}
    </div>
  );
};

export default FileDropZoneWrapper;
