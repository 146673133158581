import {
  applicationsChartPresets,
  businessDemographicsBarGraphPresets,
  loanAmountPieChartPresets,
  submissionsLineGraphPresets
} from "components/global/ChartJS/chartPresetsAndStyles";
import Signal from "libs/signals";

export const dashboardSignal = Signal({
  step: 1,
  fundingPrograms: [],
  selectedFundingProgramId: '',
  selectedPlatformId: '',
  applications: [],
  funds: [],
  programRequests: [],
  portfolioCompanies: [],
  platforms: [],
  fundingProgram: null,
  applicationsMetrics: applicationsChartPresets,
  submissionsPerDayMetrics: submissionsLineGraphPresets,
  businessDemographicsMetrics: businessDemographicsBarGraphPresets,
  loanAmountMetrics: loanAmountPieChartPresets,
  fundMetrics: applicationsChartPresets,
  programRequestMetrics: applicationsChartPresets,
  vcPortfolioCompanyMetrics: applicationsChartPresets,
  portfolioCompanyDemographicsMetrics: businessDemographicsBarGraphPresets,
  programMetrics: applicationsChartPresets,
  allocationOfFundsMetrics: applicationsChartPresets,
});
