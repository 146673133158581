import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { getRows } from 'utils/pagination';
import SearchInput from 'components/global/SearchInput';
import PaginationButton from 'components/global/PaginationButton/PaginationButton';
import { handleSimpleProgramSearchChange } from 'utils/search';
import LargeCheckBox from '../../Constant/LargeCheckBox';
import billingSignal from '../Billing.signal';
import {
  handleBackToLinkedProgramsClick,
  handleBankAccountFundingProgramCheckboxClick,
  saveLinkedProgramAccessesChange,
} from '../Billing.helpers';

const BillingLinkBankAccountToFundingProgram = () => {
  const {
    linkedPrograms,
    selectedBankAccount,
    fundingPrograms,
    programsInView,
    programsFilteredBySearch,
  } = billingSignal.value;
  const [currentPage, setCurrentPage] = useState(0);
  const recordsPerPage = 10;
  const totalNumRecords = programsFilteredBySearch.length;
  const totalNumOfPages = Math.ceil(totalNumRecords / recordsPerPage);
  useEffect(() => {
    billingSignal.update({ programsFilteredBySearch: fundingPrograms });
  }, [fundingPrograms]);

  useEffect(() => {
    const newProgramsInView = getRows(
      programsFilteredBySearch,
      currentPage,
      recordsPerPage
    );
    billingSignal.update({ programsInView: newProgramsInView });
  }, [currentPage, programsFilteredBySearch]);

  if (!selectedBankAccount) {
    return null;
  }

  return (
    <Modal.Body>
      <SearchInput
        placeholder="Search Programs"
        onChange={(e) =>
          handleSimpleProgramSearchChange(e, setCurrentPage, billingSignal)
        }
      />
      <Table>
        <thead>
          <tr>
            <th>Program</th>
            <th>Platform</th>
            <th className="text-center">Linked</th>
          </tr>
        </thead>
        <tbody>
          {programsInView.map((fundingProgram) => {
            let disableSelectMessage = '';
            if (!fundingProgram.platform.caatForPaymentReconciliation) {
              disableSelectMessage = 'CAAT Payment Unavailable';
            }
            // We may need this if it turns out that lenders/vcs can only link one bank account to a program
            // else {
            //   const bankAccountProgramAccess = fundingProgram.bankAccountProgramAccesses.find(bapa => bapa.bankAccount.accountId === userAccountSignal.value.userData.accountId);
            //   if (userAccountSignal.value.userData.account.portalType !== PORTAL_TYPES.edo && bankAccountProgramAccess && bankAccountProgramAccess.bankAccountId !== selectedBankAccount?.id) {
            //     disableSelectMessage = `Linked to ${bankAccountProgramAccess.bankAccount.bankAccountName}`;
            //   }
            // }
            return (
              <tr key={fundingProgram.id}>
                <td>{fundingProgram.name}</td>
                <td>{fundingProgram.platform.name}</td>
                <td align="center">
                  {disableSelectMessage ? (
                    <p className="text-muted">{disableSelectMessage}</p>
                  ) : (
                    <LargeCheckBox
                      size="sm"
                      disabled={disableSelectMessage}
                      checked={
                        !!linkedPrograms.find(
                          (lp) => lp.id === fundingProgram.id
                        )
                      }
                      onClick={() =>
                        handleBankAccountFundingProgramCheckboxClick(
                          fundingProgram
                        )
                      }
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row className="text-center">
        <Col>
          <PaginationButton
            increment={false}
            onClick={() => setCurrentPage(currentPage - 1)}
            hidden={currentPage <= 0}
          />
        </Col>
        <Col>
          <p hidden={totalNumOfPages <= 0}>
            {currentPage + 1}/{totalNumOfPages}
          </p>
        </Col>
        <Col>
          <PaginationButton
            onClick={() => setCurrentPage(currentPage + 1)}
            hidden={currentPage >= totalNumOfPages - 1}
          />
        </Col>
      </Row>
      <Row className="mt-32 px-16 justify-content-end">
        <Button
          className="rounded mr-8"
          variant="secondary"
          onClick={handleBackToLinkedProgramsClick}
        >
          Cancel
        </Button>
        <Button
          className="rounded"
          onClick={() => {
            saveLinkedProgramAccessesChange();
          }}
        >
          Save
        </Button>
      </Row>
    </Modal.Body>
  );
};

export default BillingLinkBankAccountToFundingProgram;
