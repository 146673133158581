import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import TimePicker from 'react-bootstrap-time-picker';
import edoFundingDisbursementSignal, {
  edoFundingTransactionAddTransactionFormSignal,
} from './Disbursement.signals';
import {
  handleAddTransactionFormChange,
  handleChangeAttachmentName,
  handleRemoveAttachment,
  handleSubmitTransaction,
  hideAddManualTransactionModal,
} from './Disbursement.helpers';
import AttachmentListForm from 'components/global/AttachmentsListForm';
import AddAttachmentsButton from 'components/global/AddAttachmentsButton';

const DisbursementAddManualTransactionModal = () => {
  const { createTransactionModalOpen } = edoFundingDisbursementSignal.value;

  const { amount, date, time, memo, attachments } =
    edoFundingTransactionAddTransactionFormSignal.value;

  return (
    <ModalWrapper show={createTransactionModalOpen} centered size="xl">
      <ModalHeader>
        <ModalTitle>Add Manual Transaction</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="amount"
                placeholder="Enter the amount"
                value={amount}
                onChange={handleAddTransactionFormChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Memo
              </Form.Label>
              <Form.Control
                className="form-control"
                name="memo"
                placeholder={'Enter memo'}
                value={memo}
                onChange={handleAddTransactionFormChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Date
              </Form.Label>
              <Form.Control
                type="date"
                className="form-control"
                name="date"
                value={date}
                onChange={handleAddTransactionFormChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Time
              </Form.Label>
              <TimePicker
                onChange={(value) =>
                  handleAddTransactionFormChange({
                    target: { name: 'time', value },
                  })
                }
                value={time}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <h6 className="font-weight-bold text-secondary">Attachments</h6>
          </Col>
          <Col className="text-right">
            <AddAttachmentsButton
              multiple={true}
              onChange={(e) =>
                handleAddTransactionFormChange({
                  target: { value: e.target.files, name: 'attachments' },
                })
              }
            />
          </Col>
        </Row>
        <Row className="mt-16">
          <Col>
            <AttachmentListForm
              onChange={handleChangeAttachmentName}
              onRemove={handleRemoveAttachment}
              attachments={attachments}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideAddManualTransactionModal()}
        >
          Dismiss
        </Button>
        <Button
          className="rounded"
          disabled={[amount, date].some((e) => !e)}
          onClick={() => handleSubmitTransaction()}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default DisbursementAddManualTransactionModal;
