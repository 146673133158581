import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import globalProfileSignal from 'signals/GlobalProfile.signal';
import {
  getAddressOptions,
  handleAccountSettingsDetailsChange,
  handleAddressBlur,
  handleAddressSearchChange,
  handleAddressSearchFocus,
  handleSelectAddress,
  handleUpdateProfileSubmission,
  onInputBlur,
} from './Profile.helpers';
import loaderSignal from 'signals/Loader.signal';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import userAccountSignal from 'signals/UserAccount.signal';
import { useEffect } from 'react';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import Field from '../Field/Field';

const Profile = () => {
  const { form, errors, addressOptions, addressSearch } =
    globalProfileSignal.value;

  useEffect(
    () =>
      globalProfileSignal.update({
        form: userAccountSignal.value.userData,
        errors: {},
        addressSearch: '',
        addressOptions: [],
      }),
    []
  );

  useEffect(() => {
    getAddressOptions();
  }, [addressSearch]);

  return (
    <Card className="px-24 px-lg-48 py-32 rounded-0">
      <h3 className="text-center">Profile</h3>
      <hr />
      <Form>
        <Row className="mt-32">
          <Col sm={6} xl={4}>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold" htmlFor="firstName">
                First Name
              </Form.Label>
              <Form.Control
                required
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                aria-describedby="firstName"
                value={form?.firstName || ''}
                onChange={(e) => handleAccountSettingsDetailsChange(e)}
                onBlur={onInputBlur}
                isInvalid={errors.firstName?.length}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6} xl={4}>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold" htmlFor="lastName">
                Last Name
              </Form.Label>
              <Form.Control
                required
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                aria-describedby="lastName"
                value={form?.lastName || ''}
                onChange={(e) => handleAccountSettingsDetailsChange(e)}
                onBlur={onInputBlur}
                isInvalid={errors.lastName?.length}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6} xl={4}>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold" htmlFor="displayName">
                Display Name
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                id="displayName"
                name="displayName"
                aria-describedby="displayName"
                value={form?.displayName || ''}
                onChange={(e) => handleAccountSettingsDetailsChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} xl={4}>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold" htmlFor="email">
                Email
              </Form.Label>
              <Form.Control
                required
                type="text"
                className="form-control"
                id="email"
                name="email"
                disabled
                aria-describedby="email"
                value={form?.email || ''}
                onChange={(e) => handleAccountSettingsDetailsChange(e)}
                onBlur={onInputBlur}
                isInvalid={errors.email?.length}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6} xl={4}>
            <Form.Group>
              <Form.Label className="font-weight-bold" htmlFor="phoneNumber">
                Phone
              </Form.Label>
              <PhoneInput
                international={false}
                addInternationalOption={false}
                countries={['US']}
                id=""
                numberInputProps={{
                  className: `form-control${
                    errors.phoneNumber && ' border-danger'
                  }`,
                  name: 'phoneNumber',
                }}
                country="US"
                defaultCountry="US"
                className="border-danger"
                value={
                  form?.phoneNumber ? formatPhoneNumber(form?.phoneNumber) : ''
                }
                countryCallingCodeEditable={false}
                limitMaxLength
                onChange={(value) =>
                  handleAccountSettingsDetailsChange({
                    target: { name: 'phoneNumber', value },
                  })
                }
                onBlur={() =>
                  onInputBlur({
                    target: { name: 'phoneNumber', value: form.phoneNumber },
                  })
                }
              />
              <small
                className="text-danger"
                hidden={!errors.phoneNumber?.length}
              >
                {errors.phoneNumber}
              </small>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-32">
          <Col>
            <Form.Group>
              <Field.AutoComplete
                label="Full Address"
                labelClassName="font-weight-bold"
                options={addressOptions}
                optionFormatter={(option) => option.formattedAddress}
                onChange={(e) => handleAddressSearchChange(e.target.value)}
                onSelect={(e) => handleSelectAddress(e)}
                value={form?.address?.formattedAddress || addressSearch}
                name="address"
                isInvalid={errors.address}
                onBlur={handleAddressBlur}
                onFocus={handleAddressSearchFocus}
              />
              <small className="text-danger" hidden={!errors.address}>
                Please select a valid address from the drop down.
              </small>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row className="px-16 justify-content-end">
          <Button
            className="rounded"
            disabled={loaderSignal.value.isContentLoading}
            onClick={handleUpdateProfileSubmission}
          >
            Save
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default Profile;
