import Signal from "libs/signals";

const businessOnboardingSignal = Signal({
  showOnboarding: false,
  onboardingStep: 1,
  onboardingFields: {
    name: '',
    ein: '',
    naicsCode: '',
  },
  onboardingFieldErrors: {
    name: '',
    ein: '',
    naicsCode: '',
  },
});

export default businessOnboardingSignal;
