import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faDesktop, faExclamationTriangle, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import ChartJsCountryMap from 'components/global/ChartJS/ChartJsCountryMap';
import stateDashboardSignal from 'signals/StateDashboard.signal';
import {
  generalDoughnutChartOptions,
  fetchAndSetDashboardData,
} from './StateDashboard.helpers';
import StateApplicationStateTable from './components/StateApplicationStateTable';
import StateCasesMetrics from './components/StateCaseMetrics';

const Dashboard = () => {
  const init = useCallback(async () => {
    await fetchAndSetDashboardData();
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <Container className="p-16 bg-light">
      <Row className="mb-32">
        <Col md={3} className="mb-16">
          <h5>Product Breakdown</h5>
          <Card>
            <Card.Body>
              <Pie data={stateDashboardSignal.value.productBreakDownChart} options={generalDoughnutChartOptions} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="mb-16">
          <h5>Cases</h5>
          <Card>
            <Card.Body>
              <StateCasesMetrics low={8} intermediate={6} high={1} />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <h5>Alerts</h5>
          <Card>
            <Card.Body>
              <h3>{stateDashboardSignal.value.alerts.length} <FontAwesomeIcon icon={faExclamationTriangle} /> </h3>
              {
                stateDashboardSignal.value.alerts.map(alert => (
                  <Card className="mb-2 bg-warning" key={alert.title}>
                    <Card.Body>
                      <Card.Text>{alert.title} - &quot;{alert.description}&quot;</Card.Text>
                    </Card.Body>
                  </Card>
                ))
              }
              {
                stateDashboardSignal.value.alerts.length === 0
                && <p>No new alerts</p>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-32">
        {stateDashboardSignal.value.users?.map(user => (
          <Col md={4} lg={3} className="mb-2" key={user.name}>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <p className="text-right">{user.name}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p><span className="h3">{user.activeApplications}</span> active</p>
                  </Col>
                </Row>
                <Row>
                  {
                  user.alerts?.slice(0, 2).map((alert, i) => (
                    <Col key={i.toString()} xs={12}>
                      <Card className="mb-2" style={{ backgroundColor: '#F9E9E9' }}>
                        <Card.Body>
                          <Card.Text>{alert.title} - {alert.numItems} {alert.numItems === 1 ? 'item' : 'items'}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                }
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="mb-32">
        <Col className="mb-16">
          <h5><FontAwesomeIcon icon={faBell} /> Notifications</h5>
          {
            [].map(n => (
              <Card className="mb-2" key={n.title}>
                <Card.Body>
                  <Card.Text>{`${n?.title}` }{n?.description && ' - '}{n.description}</Card.Text>
                </Card.Body>
              </Card>
            ))
          }
          {
            stateDashboardSignal.value.notifications.length === 0
            && <p>No new notifications</p>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h5 className="mb-32"><FontAwesomeIcon icon={faFolderOpen} /> Programs </h5>
              <p>Active Programs</p>
              <h3 className="mb-64">5</h3>
              <Link to="/">Manage Programs</Link>
              <br />
              <br />
              <Link to="/">Deactivate Programs</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Row className="mb-32">
            <Col className="d-flex justify-content-between">
              <p>Sessions by state</p>
              <Button><FontAwesomeIcon icon={faDesktop} /> EDO Dashboards</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ChartJsCountryMap statesData={stateDashboardSignal.value.geoCharts.statesNumApplications} />
            </Col>
            <Col>
              <StateApplicationStateTable statesData={stateDashboardSignal.value.geoCharts.statesNumApplications} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
