import AdminMigrateImport from 'components/views/State/StateMigrate';
import $appSettings from 'signals/AppSettings.signal';

const EdoAdminMigrate = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;

  return <AdminMigrateImport portalType={PORTAL_TYPES.edo} />
};

export default EdoAdminMigrate;
