import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { quarterlyReportSignal } from 'components/global/Reporting/Reporting.signals';
import { quarterlyReportDeadlines } from '../reportConstants';
import {
  downloadQuarterlyReport,
  fetchAndSetQuarterlyReportData,
  reportingPeriodIsCurrentOrPast,
  showUploadQuarterlyReportModal,
} from '../Reporting.helpers';
import {
  AllocationTable,
  ContractedEntityTable,
  FOTTable,
  OtherRequiredInfoTable,
  ProgramInformationTable,
  SubmissionTable,
} from './QuarterlyReportTables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { warningIcon } from 'components/global/Constant/Icons';
import UploadQuarterlyReportModal from './UploadQuarterlyReportModal';
import reportingSignals from '../Reporting.signals';

const QuarterlyReport = () => {
  const { quarterlyReportId } = quarterlyReportSignal.value;
  const { selectedPlatform } = reportingSignals.value;

  return (
    <>
      <UploadQuarterlyReportModal />
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Form.Label className="mb-8 font-weight-bold text-center">
            Reporting Period
          </Form.Label>
          <Form.Select
            type="select"
            className="form-control"
            name="staticQuarterlyReportId"
            value={quarterlyReportId}
            onChange={(e) => fetchAndSetQuarterlyReportData(e)}
          >
            <option disabled value="">
              Select Option
            </option>
            {quarterlyReportDeadlines.map((qrd) => {
              const isCurrentOrPast = reportingPeriodIsCurrentOrPast(
                qrd.reportingPeriod
              );
              return (
                <option key={qrd.id} value={qrd.id} disabled={!isCurrentOrPast}>
                  {isCurrentOrPast === 'current'
                    ? `${qrd.reportingPeriod} (current)`
                    : qrd.reportingPeriod}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div>
          <Button
            className="rounded"
            onClick={() => showUploadQuarterlyReportModal()}
          >
            Upload Existing
          </Button>
        </div>
      </div>
      <hr />
      <Row className="align-items-center">
        <Col className="font-weight-bold">
          <div
            className={
              !selectedPlatform ||
              !quarterlyReportId ||
              !quarterlyReportSignal.value.quarterlyReport
                ? 'd-none'
                : ''
            }
          >
            <p className="mb-0">Black: Data from uploaded report</p>
            <p className="text-info mb-0">
              Blue: Data from calculated from the CAAT
            </p>
          </div>
        </Col>
        <Col>
          <div className="d-flex h-100 justify-content-end align-items-end">
            <Button
              hidden={
                !selectedPlatform ||
                !quarterlyReportId ||
                !quarterlyReportSignal.value.quarterlyReport
              }
              className="rounded"
              variant="outline-secondary"
              onClick={downloadQuarterlyReport}
            >
              Download this Report
            </Button>
          </div>
        </Col>
      </Row>
      <Row
        hidden={
          !quarterlyReportSignal.value.quarterlyReport ||
          !quarterlyReportSignal.value.quarterlyReport.id
        }
      >
        <Card className="my-32 rounded-0 border-secondary">
          <SubmissionTable />
        </Card>
        <Card className="mb-32 rounded-0 border-secondary">
          <p className="text-center mt-16">
            Program Information and Program Allocated Funds Expended, Obligated,
            or Transferred (EOT) - Excluding Administrative Costs
          </p>
          <AllocationTable />
          <ProgramInformationTable />
        </Card>
        <Card className="mb-32 rounded-0 border-secondary">
          <p className="text-center mt-16">
            Funds EOT For Administrative Costs - Excluding Program Allocated
            Funds EOT
          </p>
          <FOTTable />
        </Card>
        <Card className="mb-32 rounded-0 border-secondary">
          <p className="text-center mt-16">Other Required Info</p>
          <OtherRequiredInfoTable />
        </Card>
        <Card className="mb-32 rounded-0 border-secondary">
          <ContractedEntityTable />
        </Card>
      </Row>
      <div
        className="mt-32"
        hidden={quarterlyReportSignal.value.quarterlyReport}
      >
        <Alert variant="warning">
          <FontAwesomeIcon icon={warningIcon} /> We could not find a report for
          this reporting period. If you have uploaded a report for this period,
          please contact support.
        </Alert>
      </div>
    </>
  );
};

export default QuarterlyReport;
