import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import dateOptions from '../Constant/dateOptions';
import { caretDownIcon, caretRightIcon } from '../Constant/Icons';
import NestedDropDown from './NestedDropdown';

const DropDown = ({ dataObj, displayType }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div role="none" onClick={() => setVisible(!visible)}>
        <p className="mb-8">
          {dataObj.createdDate.toLocaleString('en-US', dateOptions.date)}&ensp;
          <FontAwesomeIcon size="lg" icon={visible ? caretDownIcon : caretRightIcon} />
        </p>
      </div>
      <Collapse in={visible}>
        <div>
          {dataObj.users.map(userObj => (
            <NestedDropDown
              key={userObj.id}
              userObj={userObj}
              displayType={displayType}
            />
          ))}
        </div>
      </Collapse>
    </>
  );
};

export default DropDown;
