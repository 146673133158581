import {
  Alert,
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import { useEffect, useState } from 'react';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import {
  SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS,
  SEDI_SELF_CERTIFIED,
} from 'components/global/Constant/businessCompanyInfoDropDowns';
import NoFundsAlert from '../../views/VC/VCDealFlows/components/NoFundsAlert';
import {
  handleBackToSearchClick,
  handleRegisterNewBusinessClick,
  handleSediDemographicsChange,
  handleSediInformationChange,
  handleUseExistingBusinessClick,
  hideNewVcPortfolioCompanyModal,
  PORTFOLIO_COMPANY_MODAL_PARENTS,
  PORTFOLIO_COMPANY_MODAL_VIEWS,
  searchForBusinessByEin,
  submitNewVcPortfolioCompany,
  updateNewVcPortfolioCompanyData,
  validEin,
  validEmail,
} from './addPortfolioCompany.helpers';
import addPortfolioCompanySignal from './addPortfolioCompany.signal';
import Field from '../Field/Field';

const SearchForEin = () => {
  const [touchedEin, setTouchedEin] = useState<boolean>(false);
  const { newVcPortfolioCompanyBusinessEin } = addPortfolioCompanySignal.value;

  return (
    <Row>
      <Col>
        <Alert>
          <p className="mb-0">Search for businesses in the CAAT Tool by EIN.</p>
          <p className="mb-0">
            If the business is not registered you will still be able add it to
            your portfolio.
          </p>
        </Alert>
        <Form.Group className="form-group">
          <Form.Label
            className="font-weight-bold text-secondary"
            htmlFor="newVcPortfolioCompanyBusinessEin"
          >
            Business EIN
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            id="newVcPortfolioCompanyBusinessEin"
            name="newVcPortfolioCompanyBusinessEin"
            placeholder="12-3456781"
            value={newVcPortfolioCompanyBusinessEin}
            onChange={(e) => updateNewVcPortfolioCompanyData(e)}
            onBlur={() => setTouchedEin(true)}
          />
          <small hidden={!(!validEin() && touchedEin)} className="text-danger">
            Please enter a valid EIN
          </small>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <div>
            <Button
              disabled={!newVcPortfolioCompanyBusinessEin || !validEin()}
              onClick={searchForBusinessByEin}
              className="rounded"
              size="sm"
            >
              Search for Business
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const NoBusinessFound = ({ resetEin }: { resetEin: boolean }) => {
  const { newVcPortfolioCompanyBusinessEin } = addPortfolioCompanySignal.value;
  return (
    <Row>
      <Col>
        <Alert variant="warning">
          We could not find a business with an EIN matching{' '}
          <b>{newVcPortfolioCompanyBusinessEin}</b> registered for the CAAT
          Tool.
          <br />
          Please register a new business or search for a different EIN.
        </Alert>
        <div className="d-flex flex-row justify-content-between">
          <Button
            className="mr-16 rounded"
            size="sm"
            variant="outline-secondary"
            onClick={handleBackToSearchClick}
          >
            Back to search
          </Button>
          <Button
            className="rounded"
            size="sm"
            variant="outline-primary"
            onClick={() => handleRegisterNewBusinessClick(resetEin)}
          >
            Register a new business
          </Button>
        </div>
      </Col>
    </Row>
  );
};

const ExistingBusinessInformation = ({ resetEin }: { resetEin: boolean }) => (
  <>
    <OrganizationDetails />
    <Row className="justify-content-between">
      <Col xs={12} lg={3}>
        <Button
          className="w-100"
          size="sm"
          variant="outline-secondary"
          onClick={handleBackToSearchClick}
        >
          Back to search
        </Button>
      </Col>
      <Col xs={12} lg={6}>
        <Row>
          <Col xs={12} lg>
            <Button
              className="mr-16 mt-8 mt-lg-0 w-100"
              size="sm"
              variant="outline-info"
              onClick={handleUseExistingBusinessClick}
            >
              Add this business
            </Button>
          </Col>
          <Col xs={12} lg className="col-auto">
            <Button
              className="mt-8 mt-lg-0 w-100"
              size="sm"
              variant="outline-primary"
              onClick={() => handleRegisterNewBusinessClick(resetEin)}
            >
              Register a new business
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

const SelectFund = () => {
  const { funds, newVcPortfolioCompanyFundId, parentView } =
    addPortfolioCompanySignal.value;

  const disabled =
    loaderSignal.value.isContentLoading || !newVcPortfolioCompanyFundId;

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold text-secondary"
              htmlFor="newVcPortfolioCompanyFundId"
            >
              Fund
            </Form.Label>
            <Form.Select
              required
              className="form-control"
              id="newBusinessFundId"
              name="newVcPortfolioCompanyFundId"
              value={newVcPortfolioCompanyFundId}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
              disabled={
                parentView === PORTFOLIO_COMPANY_MODAL_PARENTS.FUND_DETAILS
              }
            >
              <option value="">Select Fund</option>
              {funds.map((fund) => (
                <option key={fund.id} value={fund.id}>
                  {fund.fundName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a fund
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <Button
              className="mr-16 rounded"
              size="sm"
              variant="outline-secondary"
              onClick={handleBackToSearchClick}
            >
              Back to search
            </Button>
            <Button
              disabled={disabled}
              className="rounded"
              variant="outline-primary"
              size="sm"
              onClick={submitNewVcPortfolioCompany}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const NewBusinessInformation = () => {
  const [touchedEin, setTouchedEin] = useState<boolean>(false);
  const [touchedEmail, setTouchedEmail] = useState<boolean>(false);
  const {
    funds,
    newVcPortfolioCompanyBusinessEin,
    newVcPortfolioCompanyBusinessName,
    modalVisible,
    newVcPortfolioCompanyBusinessEmail,
    newVcPortfolioCompanyFirstName,
    newVcPortfolioCompanyLastName,
    newVcPortfolioCompanyFundId,
    parentView,
  } = addPortfolioCompanySignal.value;

  useEffect(() => {
    if (!modalVisible) {
      setTouchedEin(false);
      setTouchedEmail(false);
    }
  }, [modalVisible]);

  const disabled =
    loaderSignal.value.isContentLoading ||
    !newVcPortfolioCompanyBusinessEin ||
    !newVcPortfolioCompanyBusinessName ||
    !modalVisible ||
    !newVcPortfolioCompanyFundId ||
    !newVcPortfolioCompanyBusinessEmail ||
    !newVcPortfolioCompanyFirstName ||
    !newVcPortfolioCompanyLastName ||
    !validEin() ||
    !validEmail();

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold text-secondary"
              htmlFor="newVcPortfolioCompanyFundId"
            >
              Fund
            </Form.Label>
            <Form.Select
              required
              className="form-control"
              id="newBusinessFundId"
              name="newVcPortfolioCompanyFundId"
              value={newVcPortfolioCompanyFundId}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
              disabled={
                parentView === PORTFOLIO_COMPANY_MODAL_PARENTS.FUND_DETAILS
              }
            >
              <option value="">Select Fund</option>
              {funds.map((fund) => (
                <option key={fund.id} value={fund.id}>
                  {fund.fundName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a fund
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-16">
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold text-secondary"
              htmlFor="newVcPortfolioCompanyBusinessName"
            >
              Business Name
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="newVcPortfolioCompanyBusinessName"
              name="newVcPortfolioCompanyBusinessName"
              placeholder="Enter the business name"
              value={newVcPortfolioCompanyBusinessName}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold text-secondary"
              htmlFor="newVcPortfolioCompanyFirstName"
            >
              Contact First Name
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="newVcPortfolioCompanyFirstName"
              name="newVcPortfolioCompanyFirstName"
              placeholder="Enter the contact's first name"
              value={newVcPortfolioCompanyFirstName}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold text-secondary"
              htmlFor="newVcPortfolioCompanyLastName"
            >
              Contact Last Name
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="newVcPortfolioCompanyLastName"
              name="newVcPortfolioCompanyLastName"
              placeholder="Enter the contact's last name"
              value={newVcPortfolioCompanyLastName}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Row className="justify-content-between">
              <Col xs={12} lg>
                <Form.Label
                  className="font-weight-bold text-secondary"
                  htmlFor="newVcPortfolioCompanyBusinessEmail"
                >
                  Business Email
                </Form.Label>
              </Col>
              <Col className="col-auto">
                <small>
                  * An invite to the CAAT tool will automatically be sent to this
                  email address
                </small>
              </Col>
            </Row>
            <Form.Control
              type="text"
              className="form-control"
              id="newVcPortfolioCompanyBusinessEmail"
              name="newVcPortfolioCompanyBusinessEmail"
              placeholder="Enter the business email"
              value={newVcPortfolioCompanyBusinessEmail}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
              onBlur={() => setTouchedEmail(true)}
            />
            <small
              hidden={!(!validEmail() && touchedEmail)}
              className="text-danger"
            >
              Please enter a valid email address
            </small>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold text-secondary"
              htmlFor="newVcPortfolioCompanyBusinessEin"
            >
              Business EIN
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="newVcPortfolioCompanyBusinessEin"
              name="newVcPortfolioCompanyBusinessEin"
              placeholder="12-3456781"
              value={newVcPortfolioCompanyBusinessEin}
              onChange={(e) => updateNewVcPortfolioCompanyData(e)}
              onBlur={() => setTouchedEin(true)}
            />
            <small
              hidden={!(!validEin() && touchedEin)}
              className="text-danger"
            >
              Please enter a valid EIN
            </small>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button
              className="mr-16 rounded"
              size="sm"
              variant="outline-secondary"
              onClick={handleBackToSearchClick}
            >
              Back to search
            </Button>
            <Button
              disabled={disabled}
              className="rounded"
              variant="outline-primary"
              size="sm"
              onClick={() =>
                addPortfolioCompanySignal.update({
                  currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.SEDI_INFORMATION,
                })
              }
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const SediInformation = () => {
  const {
    isSediOwned,
    sediStatusByBusinessAddressInCDFIInvestmentArea,
    selfCertifiedSEDIDemographicsRelatedBusinessStatus,
    selfCertifiedSEDIFutureLocationInCDFIInvestmentArea,
    selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea,
  } = addPortfolioCompanySignal.value.newVcPortfolioCompanySediCertification;

  const disabled =
    loaderSignal.value.isContentLoading ||
    !isSediOwned ||
    !sediStatusByBusinessAddressInCDFIInvestmentArea ||
    !selfCertifiedSEDIDemographicsRelatedBusinessStatus ||
    !selfCertifiedSEDIFutureLocationInCDFIInvestmentArea ||
    !selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea;

  return (
    <>
      <Row className="mb-16">
        <Col xs={12} lg>
          <Form.Group className="mt-4">
            <Form.Label className="font-weight-bold" htmlFor="isSediOwned">
              Do this business self-certify as a SEDI Owned Business?{' '}
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={isSediOwned === 'yes'}
              label="Yes"
              name="isSediOwned"
              type="radio"
              id="isSediOwnedYes"
              value="yes"
              onChange={(e) => handleSediInformationChange(e)}
            />
            <Form.Check
              inline
              checked={isSediOwned === 'no'}
              label="No"
              name="isSediOwned"
              type="radio"
              id="isSediOwnedNo"
              value="no"
              onChange={(e) => handleSediInformationChange(e)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="sediStatusByBusinessAddressInCDFIInvestmentArea"
            >
              SEDI Status by Business Address in CDFI Investment Area
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={
                sediStatusByBusinessAddressInCDFIInvestmentArea === 'yes'
              }
              label="Yes"
              name="sediStatusByBusinessAddressInCDFIInvestmentArea"
              type="radio"
              id="sediStatusByBusinessAddressInCDFIInvestmentAreaYes"
              value="yes"
              onChange={(e) => handleSediInformationChange(e)}
            />
            <Form.Check
              inline
              checked={sediStatusByBusinessAddressInCDFIInvestmentArea === 'no'}
              label="No"
              name="sediStatusByBusinessAddressInCDFIInvestmentArea"
              type="radio"
              id="sediStatusByBusinessAddressInCDFIInvestmentAreaNo"
              value="no"
              onChange={(e) => handleSediInformationChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea"
              className="font-weight-bold"
            >
              Self-certified SEDI Owned and Controlled in CDFI Investment Area
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              id="selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea"
              name="selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea"
              value={selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea}
              onChange={(e) => handleSediInformationChange(e)}
            >
              <option value="">Select an option</option>
              {SEDI_SELF_CERTIFIED.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} lg>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="selfCertifiedSEDIFutureLocationInCDFIInvestmentArea"
              className="font-weight-bold"
            >
              Self-certified SEDI Future Location in CDFI Investment Area
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              id="selfCertifiedSEDIFutureLocationInCDFIInvestmentArea"
              name="selfCertifiedSEDIFutureLocationInCDFIInvestmentArea"
              value={selfCertifiedSEDIFutureLocationInCDFIInvestmentArea}
              onChange={(e) => handleSediInformationChange(e)}
            >
              <option value="">Select an option</option>
              {SEDI_SELF_CERTIFIED.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <p className="mb-8 font-weight-bold">
            Self-Certified SEDI Demographics-Related Business Status (select all
            that apply)
          </p>
          {Object.keys(SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS).map(
            (sediDemographic) => {
              const statusKey =
                sediDemographic as keyof typeof SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS;
              const statusValue =
                SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS[statusKey].value;

              return (
                // @ts-ignore
                <Field.Checkbox
                  key={statusValue}
                  label={statusValue}
                  name={statusValue}
                  value={
                    selfCertifiedSEDIDemographicsRelatedBusinessStatus.includes(
                      statusValue
                    )
                      ? 'checked'
                      : ''
                  }
                  onChange={handleSediDemographicsChange}
                />
              );
            }
          )}
        </Col>
      </Row>
      <div className="d-flex justify-content-between">
        <Button
          className="rounded"
          variant="outline-secondary"
          size="sm"
          onClick={() =>
            addPortfolioCompanySignal.update({
              currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.CREATE_BUSINESS_INFO,
            })
          }
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          className="rounded justify-content-end"
          size="sm"
          onClick={submitNewVcPortfolioCompany}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

const AddPortfolioCompanyModal = () => {
  const { modalVisible, funds } = addPortfolioCompanySignal.value;

  const modalBody = () => {
    const { currentView } = addPortfolioCompanySignal.value;

    if (!funds.length) {
      return <NoFundsAlert />;
    }

    switch (currentView) {
      case PORTFOLIO_COMPANY_MODAL_VIEWS.EIN:
        return <SearchForEin />;
      case PORTFOLIO_COMPANY_MODAL_VIEWS.NO_BUSINESS_FOUND:
        return <NoBusinessFound resetEin={false} />;
      case PORTFOLIO_COMPANY_MODAL_VIEWS.EXISTING_BUSINESS_INFO:
        return <ExistingBusinessInformation resetEin />;
      case PORTFOLIO_COMPANY_MODAL_VIEWS.CREATE_BUSINESS_INFO:
        return <NewBusinessInformation />;
      case PORTFOLIO_COMPANY_MODAL_VIEWS.SEDI_INFORMATION:
        return <SediInformation />;
      case PORTFOLIO_COMPANY_MODAL_VIEWS.FUND_INFORMATION:
        return <SelectFund />;
      default:
        return null;
    }
  };

  return (
    <ModalWrapper
      show={modalVisible}
      centered
      size="xl"
      backdropClassName={null}
      onExit={null}
      onExited={null}
      onHide={hideNewVcPortfolioCompanyModal}
    >
      <ModalHeader>
        <ModalTitle>New Portfolio Company</ModalTitle>
      </ModalHeader>
      <ModalBody className="px-40">{modalBody()}</ModalBody>
      <ModalFooter>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => hideNewVcPortfolioCompanyModal()}
        >
          Dismiss
        </Button>
        {/* <Button
          disabled={disabled}
          onClick={() => submitNewVcPortfolioCompany()}
        >
          Save
        </Button> */}
      </ModalFooter>
    </ModalWrapper>
  );
};

export default AddPortfolioCompanyModal;
