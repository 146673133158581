import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import platformsSignal from '../Platforms.signal';
import {
  hideNewPlatformModal,
  submitNewPlatform,
  updateNewPlatformName,
  updateNewPlatformTargetTransactionLeverageRatio,
  updateNewPlatformType,
} from '../Platforms.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import CloseModalButton from 'components/global/Constant/CloseModalButton';

const AddPlatformModal = () => {
  const {
    newPlatformTargetTransactionLeverageRatio,
    newPlatformName,
    createPlatformModalVisible,
    newPlatformType,
  } = platformsSignal.value;

  return (
    <ModalWrapper
      show={createPlatformModalVisible}
      centered
      size="xl"
      backdropClassName={null}
      onExit={null}
      onExited={null}
      onHide={hideNewPlatformModal}
    >
      <ModalHeader>
        <ModalTitle>New Platform</ModalTitle>
        <CloseModalButton onCloseModal={hideNewPlatformModal} />
      </ModalHeader>
      <ModalBody>
        <h5>Platform Info</h5>
        <Row>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Name
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter the platform name"
                value={newPlatformName}
                onChange={(e) => updateNewPlatformName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Platform Type
              </Form.Label>
              <Form.Select
                className="form-control"
                name="name"
                value={newPlatformType}
                onChange={(e) => updateNewPlatformType(e.target.value)}
              >
                <option disabled value="">
                  Select an option...
                </option>
                {window.location.hostname === 'ca.caat.io' ? (
                  <>
                    <option value="SSBCI">SSBCI</option>
                    <option value="TEBFP">TEBFP</option>
                    <option value="CALCAP">CALCAP</option>
                    <option value="CALReUSE">CALReUSE</option>
                    <option value="RRBP">RRBP</option>
                    <option value="IDBFP">IDBFP</option>
                  </>
                ) : (
                  <option value="SSBCI">SSBCI</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Target Transaction Leverage Ratio
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="targetTransactionLeverageRatio"
                placeholder="Enter the ratio"
                value={newPlatformTargetTransactionLeverageRatio}
                onChange={(e) =>
                  updateNewPlatformTargetTransactionLeverageRatio(
                    e.target.value
                  )
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={hideNewPlatformModal}
        >
          Dismiss
        </Button>
        <Button
          className="rounded"
          disabled={
            parseFloat(newPlatformTargetTransactionLeverageRatio) === 0.0 ||
            newPlatformName.length < 2 ||
            !newPlatformType ||
            loaderSignal.value.isContentLoading
          }
          onClick={submitNewPlatform}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default AddPlatformModal;
