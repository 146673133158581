import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { warningIcon } from "components/global/Constant/Icons";
import { Alert, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const NoFundingProgramsAlert = () => {
  const history = useHistory();
  
  return (
  <Alert variant="warning" className="d-flex flex-row align-items-center justify-content-between">
    <FontAwesomeIcon className="mr-16" size="2x" icon={warningIcon} />
    <p className="mb-0">You have not been approved for a funding program. You must be approved before creating a fund.</p>
    <div>
      <Button variant="outline-secondary" className="text-nowrap rounded" onClick={() => history.push('/vc/program-requests')}>Go to Programs</Button>
    </div>
  </Alert>
  );
};

export default NoFundingProgramsAlert;