import { Button, Modal } from 'react-bootstrap';
import billingSignal from 'components/global/Billing/Billing.signal';
import { handleDeleteBankAccount, handleHideBankAccountDelete } from '../Billing.helpers';
import loaderSignal from 'signals/Loader.signal';

const BillingDeleteBankAccountModal = () => (
  <Modal centered show={!!billingSignal.value.toDeleteBankAccount}>
    <Modal.Header className="border-0 bg-light">
      <Modal.Title>Delete Bank Account</Modal.Title>
    </Modal.Header>
    <Modal.Body className="bg-light">
      <p>{`Are you sure you want to delete ${billingSignal.value.toDeleteBankAccount?.bankAccountName}?`}</p>
    </Modal.Body>
    <Modal.Footer className="border-0 bg-light">
      <Button className="rounded" variant="secondary" onClick={() => handleHideBankAccountDelete()}>
        Cancel
      </Button>
      <Button disabled={loaderSignal.value.isContentLoading} className="rounded" variant="danger" onClick={() => { handleDeleteBankAccount(); }}>
        Delete Account
      </Button>
    </Modal.Footer>
  </Modal>
);

export default BillingDeleteBankAccountModal;
