import React, { useCallback, useEffect } from 'react';
import { createBankAccountsFromPlaidToken, getPlaidGeneratedToken } from 'components/global/Billing/Billing.helpers';
import { Button } from 'react-bootstrap';
import { usePlaidLink } from 'react-plaid-link';
import plaidSignal from 'signals/PlaidSignal.signal';

const OpenPlaidButton = () => {
  const { generatedToken } = plaidSignal.value;
  const { open, ready } = usePlaidLink({
    token: generatedToken,
    // eslint-disable-next-line camelcase
    onSuccess: async (public_token, metadata) => {
      // send public_token to server
      await createBankAccountsFromPlaidToken(public_token, metadata);
    },
  });

  const init = useCallback(async () => {
    await getPlaidGeneratedToken();
  });

  useEffect(() => {
    init();
  }, []);
  return (
    <Button className="rounded" disabled={!ready} onClick={() => { open(); }}>
      Connect Bank Account
    </Button>
  );
};

export default OpenPlaidButton;
