import Signal from '../libs/signals';

const portfolioApplicationsSignal = Signal({
  applications: [],
  bundle: {},
  dropdownFilters: {},
  appliedFilters: {},
  modalVisible: false,
  selectedApplication: null,
  newApplicationStatus: '',
  submittingToLender: false,
  submittingStatusUpdate: false,
  numWatchlist: 0,
  recordsPerPage: 20,
  totalPages: 1,
});

export const portfolioApplicationDocumentTypesSignal = Signal([]);

export const portfolioApplicationsNoteSignal = Signal('');

export default portfolioApplicationsSignal;
