import { useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AddUserModal from "components/global/UserManagement/components/AddUserModal/AddUserModal";
import {
  showAddInternalUserModal
} from "components/global/UserManagement/components/AddUserModal/AddUserModal.helpers";
import userManagementSignal from "./UserManagement.signal";
import {
  fetchAndSetUsersAndPrograms,
  handleUserSortChange,
  showUserManagementUserDetailModal
} from "./UserManagement.helpers";
import UserManagementDetailModal from "./components/UserManagementDetailModal";
import UserActivationModal from "../UserActivationModal/UserActivationModal";
import { fetchAndSetUserInvites } from "../UserInvitations/UserInvitations.helpers";
import SimpleTable from "../SimpleTable";
import { toggleActivationUserModal } from "../UserActivationModal/userActivationModal.helpers";
import AddAccountModal from "../AddAccountModal";
import userAccountSignal from "signals/UserAccount.signal";
import { showAddAccountModal } from "../AddAccountModal/AddAccountModal.helpers";
import capitalizeFirst from "utils/capitalizeFirst";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'email',
    title: 'Email',
  },
  {
    key: 'role',
    title: 'Role',
    sortable: true,
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'lastSeenAt',
    title: 'Last Signed In',
  },
  {
    key: 'createdDate',
    title: 'Created',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const UserManagement = () => {
  const { users, sort } = userManagementSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES, USER_STATUS } = $appSettings.value.constants;

  const init = useCallback(() => {
    fetchAndSetUsersAndPrograms();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Container fluid>
      <Row>
        <Col className="pl-0">
          <Row className="justify-content-end px-16 mb-16">
            <Button
              className="rounded mr-8"
              variant="outline-primary"
              onClick={() => showAddInternalUserModal()}
            >
              Invite User
            </Button>
            <Button
              className="rounded"
              variant="primary"
              onClick={() =>
                showAddAccountModal({
                  disablePortalSelect: true,
                  portal: PORTAL_TYPES.business,
                })
              }
            >
              Invite Business
            </Button>
          </Row>
          <SimpleTable
            headers={HEADERS}
            sort={sort}
            onSort={(sort) => {
              handleUserSortChange(sort);
              fetchAndSetUsersAndPrograms();
            }}
            onClick={(index) => {
              const user = users[index];
              showUserManagementUserDetailModal(user);
            }}
            rows={users.map((user) => ({
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              role: capitalizeFirst(user.role),
              status: capitalizeFirst(user.status),
              lastSeenAt: new Date(user.lastSeenAt).toLocaleDateString(),
              createdDate: new Date(user.createdDate).toLocaleDateString(),
              action: ({ key }) => (
                <td key={key} align="right">
                  {user.status === 'APPROVED' && (
                    <>
                      <Button
                        hidden={user.userStatus === USER_STATUS.active}
                        size="sm"
                        className="ml-8 mb-8 rounded"
                        variant="success"
                        onClick={() =>
                          toggleActivationUserModal({
                            user,
                            mode: 'activation',
                          })
                        }
                      >
                        Activate
                      </Button>
                      <Button
                        hidden={user.userStatus === USER_STATUS.inactive}
                        size="sm"
                        className="ml-8 mb-8 rounded"
                        variant="outline-danger"
                        onClick={() =>
                          toggleActivationUserModal({
                            user,
                            mode: 'deactivation',
                          })
                        }
                      >
                        Deactivate
                      </Button>
                    </>
                  )}
                </td>
              ),
            }))}
          />
          <hr />
        </Col>
      </Row>
      <AddAccountModal />
      <AddUserModal
        portal={portalType}
        onSubmitSuccess={() => fetchAndSetUserInvites()}
      />
      <UserActivationModal onFetchAndSetUsers={fetchAndSetUsersAndPrograms} />
      <UserManagementDetailModal />
    </Container>
  );
};

export default UserManagement;
