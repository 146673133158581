import { Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const EdoProgramBusinessDetail = () => {
  const { pathname } = useLocation();
  const organizationId = pathname.split('/')[pathname.split('/').length - 1];
  const fundingProgramMembershipId = pathname.split('/')[pathname.split('/').length - 3];
  const { selectedOrganization } = organizationDetailsSignal.value;
  const application = selectedOrganization?.applications?.find(app => app.fundingProgramMembershipId === Number(fundingProgramMembershipId));
  let labels = [];

  if (!!application) {
    const { fundingProgramMembership } = application;
    const { fundingProgram } = fundingProgramMembership;
    labels = [
        '',
        !!fundingProgram ? fundingProgram.name : '',
        !!fundingProgram ? `Businesses enrolled in ${fundingProgram.name}` : '',
        selectedOrganization.name,
    ];
  } else if (!!selectedOrganization?.vcPortfolioCompanies?.length) {
    const vcPortfolioCompany = selectedOrganization.vcPortfolioCompanies.find(pc => pc.businessId === selectedOrganization.id);
    const { fund } = vcPortfolioCompany;
    const { programRequest } = fund;
    const { fundingProgram } = programRequest;
    labels = [
      '',
      !!fundingProgram ? fundingProgram.name : '',
      !!fundingProgram ? `Funds in ${fundingProgram.name}` : '',
      !!fund ? `${fund.fundName} Businesses` : '',
      selectedOrganization.name,
    ];
  }

  const init = useCallback(async () => {
    await fetchOrganization(organizationId, '/businesses');
  });

  useEffect(() => {
    init();
  }, [organizationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card className="rounded-0">
        <Card.Body>
          <OrganizationDetails />
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default EdoProgramBusinessDetail;
