import { Button, OverlayTrigger } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SimpleTableFilter } from '../../../types';
import { useState } from 'react';
import Field from '../Field/Field';

type RendererProps = {
  name: string;
  filter: SimpleTableFilter;
  value: string;
  onChange: (eventLike: { name: string; value: string }) => any;
};

const baseUndefinedProps = {
  label: undefined,
  inputClassName: undefined,
  options: undefined,
  onBlur: undefined,
  placeholder: undefined,
  isInvalid: undefined,
  onSelect: undefined,
  onFocus: undefined,
  feedbackMessage: undefined,
  allowCents: undefined,
  disabled: false,
  inputRef: null,
  noDefaultOption: undefined,
  lineBreak: undefined,
};

const SimpleTableFilterRenderer: React.FC<RendererProps> = ({
  name,
  onChange,
  filter,
  value,
}) => {
  if (filter.type === 'select') {
    console.log('filter', filter);
    return (
      <>
        <div className="font-weight-bold mb-2">{filter.label}</div>
        <Field.Select
          {...baseUndefinedProps}
          name={name}
          inputClassName="form-control"
          labelClassName="font-weight-bold"
          className="mb-8"
          options={filter.options}
          onChange={onChange}
          value={value}
        />
      </>
    );
  }

  if (filter.type === 'integer') {
    return (
      <>
        <div className="font-weight-bold mb-2">{filter.label}</div>
        <Field.Integer
          {...baseUndefinedProps}
          name={name}
          labelClassName="font-weight-bold"
          className="mb-8"
          onChange={onChange}
          value={value}
        />
      </>
    );
  }

  if (filter.type === 'text') {
    return (
      <>
        <div className="font-weight-bold mb-2">{filter.label}</div>
        <Field.TextInput
          {...baseUndefinedProps}
          name={name}
          labelClassName="font-weight-bold"
          className="mb-8"
          onChange={onChange}
          value={value}
        />
      </>
    );
  }

  return null;
};

type Props = {
  filters: SimpleTableFilter[];
  filtersState?: Record<string, string>;
  onChange?: (eventLike: { name: string; value: string }) => any;
  onApply?: (f: Record<string, string>) => any;
  onClearFilterInputs?: () => any;
};

function cleanObject(obj: Record<string, string>): Record<string, string> {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, value.trim()])
  );
}

function buildNewPathname(
  pathname: string,
  params: Record<string, string>
): string {
  if (Object.keys(params).length === 0) {
    return pathname;
  }

  return `${pathname}?${new URLSearchParams(params)}`;
}

const SimpleTableFilterDropdown: React.FC<Props> = ({
  filtersState,
  filters,
  onApply,
  onChange,
  onClearFilterInputs,
}) => {
  const history = useHistory();
  const location = history.location;
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const [internalFiltersState, setInternalFiltersState] = useState<
    Record<string, string>
  >({});

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement={'bottom-end'}
      overlay={
        <div className="bg-white shadow-lg p-4">
          {filters.map((filter) => (
            <SimpleTableFilterRenderer
              key={filter.key}
              name={filter.key}
              filter={filter}
              value={
                filtersState
                  ? filtersState[filter.key]
                  : internalFiltersState[filter.key]
              }
              onChange={(eventLike) => {
                onChange
                  ? onChange(eventLike)
                  : setInternalFiltersState({
                      ...internalFiltersState,
                      [eventLike.name]: eventLike.value,
                    });
              }}
            />
          ))}
          <div className="d-flex">
            <Button
              variant="outline-secondary"
              className="rounded mt-16 flex-grow-1 mr-1"
              onClick={() => {
                // this closes the overlay
                // as it thinks an outside click occurred
                document.body.click();

                history.push(
                  buildNewPathname(
                    location.pathname,
                    cleanObject({
                      sort: searchParams.sort,
                      tab: searchParams.tab,
                    })
                  )
                );

                if (onApply) {
                  onApply({});
                }
                if (onClearFilterInputs) {
                  onClearFilterInputs();
                }
                if (!filtersState) {
                  setInternalFiltersState({});
                }
              }}
            >
              Clear
            </Button>
            <Button
              variant="outline-primary"
              className="rounded mt-16 flex-grow-1 ml-1"
              onClick={() => {
                // this closes the overlay
                // as it thinks an outside click occurred
                document.body.click();
                const cleanFilters = cleanObject(
                  filtersState || internalFiltersState
                );

                history.push(
                  buildNewPathname(location.pathname, {
                    ...cleanFilters,
                    ...cleanObject({
                      tab: searchParams.tab,
                      sort: searchParams.sort,
                    }),
                  })
                );
                if (onApply) {
                  onApply(cleanFilters);
                }
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <Button className="rounded" variant={'outline-secondary'}>
          Filters
        </Button>
      </div>
    </OverlayTrigger>
  );
};
export default SimpleTableFilterDropdown;
