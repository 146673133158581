import { Button, Modal } from "react-bootstrap";
import CloseModalButton from "components/global/Constant/CloseModalButton";
import AddAccountForm from "components/global/AddAccountModal/components/AddAccountForm";
import addAccountModalSignal from "./AddAccountModal.signal";
import { handleAddAccountSubmission, hideAddAccountModal } from "./AddAccountModal.helpers";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import loaderSignal from "signals/Loader.signal";

const AddAccountModal = () => (
  <ModalWrapper
    className="border border-dark rounded upper-modal"
    backdropClassName="upper-modal-backdrop"
    show={!!addAccountModalSignal.value.modalVisible}
    size="lg"
    centered
  >
    <Modal.Header className="pb-0">
      <h4>Add Account</h4>
      <CloseModalButton onCloseModal={hideAddAccountModal} />
    </Modal.Header>
    <Modal.Body className="p-16">
      <AddAccountForm />
    </Modal.Body>
    <Modal.Footer className="justify-content-end">
      <Button
        type="button"
        onClick={hideAddAccountModal}
        variant="outline-secondary"
        className="mr-16 rounded"
      >
        Cancel
      </Button>
      <Button
        className="rounded"
        id="add-account-modal-submit-btn"
        variant="primary"
        onClick={(e) => handleAddAccountSubmission(e)}
        disabled={
          loaderSignal.value.isContentLoading ||
          addAccountModalSignal.value.emailError ||
          addAccountModalSignal.value.email === '' ||
          addAccountModalSignal.value.portal === '' ||
          addAccountModalSignal.value.firstName === '' ||
          addAccountModalSignal.value.lastName === ''
        }
      >
        Submit
      </Button>
    </Modal.Footer>
  </ModalWrapper>
);

export default AddAccountModal;
