import { faCloudUploadAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import File from './File';

const FORMATS = {
  pdf: 'PDF',
  xlsx: 'Excel',
  jpg: 'JPG',
  jpeg: 'JPEG',
  csv: 'CSV',
  webp: 'WEBP',
  png: 'PNG',
};

const DragAndDrop = ({
  files = [],
  onFileCancel = undefined,
  uploadProgress = 1,
  maxSizeMB = 15,
  description = '',
  preview = true,
  formats = Object.keys(FORMATS),
}) => {
  const NUM_FILES_SHOW_BEFORE_OVERFLOW = 5;
  const [showAllFiles, setShowAllFiles] = useState(false);
  const [hover, setHover] = useState(false);

  const toggleShowFiles = () => () => setShowAllFiles((prev) => !prev);

  return (
    <Container>
      <Row className="mb-16">
        <Col>
          <Card
            style={{ cursor: 'pointer' }}
            className="text-center"
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <Card.Body>
              <FontAwesomeIcon
                icon={faCloudUploadAlt}
                className={`mb-32 h3${hover ? ' text-primary' : ''}`}
              />
              <Card.Text className="mb-16">
                Drag & Drop your files here
              </Card.Text>
              <Card.Text className="mb-16">or</Card.Text>
              <Card.Text
                style={{ textDecoration: hover ? 'underline' : '' }}
                className={hover ? 'text-primary' : ''}
              >
                Click to upload files
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Card.Text>
                Only {formats.map((f) => FORMATS[f]).join(', ')} formats with
                max file size of {maxSizeMB} MB
              </Card.Text>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row className="mb-16" hidden={files.length === 0 || preview === false}>
        <Container className="border border-secondary rounded p-8 mb-16">
          <Row>
            <Col>
              <p className="font-weight-bold">File Name</p>
            </Col>
            <Col className="font-weight-bold" hidden={uploadProgress === 0}>
              <p>Status</p>
            </Col>
          </Row>

          {files.length >= NUM_FILES_SHOW_BEFORE_OVERFLOW && !showAllFiles
            ? files
                .slice(0, NUM_FILES_SHOW_BEFORE_OVERFLOW)
                .map((file, i) => (
                  <File key={i} file={file} onFileCancel={onFileCancel} />
                ))
            : files.map((file, i) => (
                <Row key={file.name}>
                  <File key={i} file={file} onFileCancel={onFileCancel} />
                </Row>
              ))}
          <Row hidden={files.length <= NUM_FILES_SHOW_BEFORE_OVERFLOW}>
            <Col>
              {!showAllFiles ? (
                <p className="text-primary" onClick={toggleShowFiles}>
                  ...{files.length - NUM_FILES_SHOW_BEFORE_OVERFLOW} other
                  uploads <FontAwesomeIcon icon={faPlus} />
                </p>
              ) : (
                <p className="text-primary" onClick={toggleShowFiles}>
                  Hide
                </p>
              )}
            </Col>
          </Row>
          <Row hidden={!description}>
            <Col>
              <p>{description}</p>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default DragAndDrop;
