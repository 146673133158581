import { PORTFOLIO_COMPANY_MODAL_PARENTS } from 'components/global/AddPortfolioCompany/addPortfolioCompany.helpers';
import addPortfolioCompanySignal from 'components/global/AddPortfolioCompany/addPortfolioCompany.signal';
import BackButton from 'components/global/BackButton';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import FundDetails from 'components/global/FundDetails/FundDetails';
import { fetchAndSetFund } from 'components/global/FundDetails/fundDetails.helpers';
import fundDetailsSignal from 'components/global/FundDetails/fundDetails.signal';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const EdoFundDetail = () => {
  const history = useHistory();
  const { location } = history;
  const segments = location.pathname.split('/');
  const fundId = segments[segments.length - 1];
  const { name } = fundDetailsSignal.value;
  const labels = ['', name || 'Fund'];

  useEffect(() => {
    fetchAndSetFund(fundId);
    addPortfolioCompanySignal.update({
      parentView: PORTFOLIO_COMPANY_MODAL_PARENTS.FUND_DETAILS,
    });
  }, []);

  return (
    <>
      <BackButton onClick={() => history.goBack()} buttonText="Back" />
      <BreadCrumbWrapper labels={labels}>
        <div className="border border-gray shadow-sm p-24 bg-white">
          <FundDetails />
        </div>
      </BreadCrumbWrapper>
    </>
  );
};

export default EdoFundDetail;
