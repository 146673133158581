import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  createNewFund,
  fetchAndSetEdoNewFundData,
  fetchFundingProgramMemberships,
  getVcOptions,
  handleAddNewRequiredDocument,
  handleAddNewSuggestedDocument,
  handleCreateNewVcChange,
  handleNewFundFormChange,
  handleRemoveRequiredDocument,
  handleRemoveSuggestedDocument,
  handleRequiredDocumentAttachFile,
  handleRequiredDocumentNameChange,
  handleSearchVcBlur,
  handleSearchVcFocus,
  handleSuggestedDocumentAttachFile,
  handleSuggestedDocumentNameChange,
  handleVcQueryChange,
  handleVcSelect,
  newFundCleanup,
  validateForm,
} from './NewFund.helpers';
import { useEffect } from 'react';
import $newFundSignal, {
  $newFundFundingProgramMembershipsSignal,
  $newFundFundingProgramsSignal,
} from './NewFund.signals';
import AttachmentsWithTemplatesForm from 'components/global/AttachmentsWithTemplatesForm/AttachmentsWithTemplatesForm';
import Field from 'components/global/Field/Field';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';
import PhoneInput from 'react-phone-number-input';
import formatPhoneNumber from 'utils/formatPhoneNumber';

const VcInputs = () => {
  const { fundingProgramMemberships } =
    $newFundFundingProgramMembershipsSignal.value;
  const { fundingProgramMembershipId, fundName, maxFundSize } = $newFundSignal.value;

  return (
    <Row>
      <Col>
        <Field.Select
          label="Funding Program"
          inputClassName="w-100 form-control"
          labelClassName="font-weight-bold"
          name="fundingProgramMembershipId"
          lineBreak
          value={fundingProgramMembershipId.value}
          options={fundingProgramMemberships.map(
            (fundingProgramMembership) => ({
              value: fundingProgramMembership.id,
              label: fundingProgramMembership.fundingProgram.name,
            })
          )}
          onChange={handleNewFundFormChange}
          onBlur={validateForm}
        />
        <small className="text-danger">
          {fundingProgramMembershipId.errors.length > 0
            ? fundingProgramMembershipId.errors[0]
            : ''}
        </small>
      </Col>
      <Col>
        <Field.TextInput
          label="Fund Name"
          labelClassName="font-weight-bold"
          value={fundName.value}
          name="fundName"
          onChange={handleNewFundFormChange}
          onBlur={validateForm}
        />
        <small className="text-danger">
          {fundName.errors.length > 0 ? fundName.errors[0] : ''}
        </small>
      </Col>
      <Col>
        <Field.Integer
          label="Fund Size"
          labelClassName="font-weight-bold"
          value={maxFundSize.value}
          name="maxFundSize"
          onChange={handleNewFundFormChange}
          onBlur={validateForm}
        />
        <small className="text-danger">
          {maxFundSize.errors.length > 0 ? maxFundSize.errors[0] : ''}
        </small>
      </Col>
    </Row>
  );
};

const EdoInputs = () => {
  const { fundingPrograms, vcs } = $newFundFundingProgramsSignal.value;
  const {
    fundingProgramId,
    fundName,
    maxFundSize,
    ventureCapitalFirm,
    createNewVc,
    vcName,
    ein,
    email,
    firstName,
    lastName,
    query,
    phoneNumber,
  } = $newFundSignal.value;

  useEffect(() => {
    getVcOptions();
  }, [query.value]);

  return (
    <>
      <Row className="mb-40 h-100">
        <Col sm={12} lg={6}>
          <Field.Select
            label="Funding Program"
            inputClassName="w-100 form-control"
            labelClassName="font-weight-bold"
            name="fundingProgramId"
            lineBreak
            value={fundingProgramId.value}
            options={fundingPrograms.map((fundingProgram) => ({
              value: fundingProgram.id,
              label: fundingProgram.name,
            }))}
            onChange={handleNewFundFormChange}
            onBlur={validateForm}
          />
          <small className="text-danger">
            {fundingProgramId.errors.length > 0
              ? fundingProgramId.errors[0]
              : ''}
          </small>
        </Col>
        <Col sm={12} lg={3}>
          <Field.AutoComplete
            disabled={createNewVc.value}
            label="Search for a VC"
            labelClassName="font-weight-bold"
            inputClassName="form-control"
            name="ventureCapitalFirm"
            value={ventureCapitalFirm.value?.name || query.value}
            options={vcs}
            optionFormatter={(option) => option.ventureCapitalFirm.name}
            onChange={(e) => handleVcQueryChange(e.target)}
            onSelect={(e) => handleVcSelect(e)}
            onFocus={handleSearchVcFocus}
            onBlur={handleSearchVcBlur}
            lineBreak
          />
          <small className="text-danger">
            {ventureCapitalFirm.errors.length > 0
              ? ventureCapitalFirm.errors[0]
              : ''}
          </small>
        </Col>
        <Col sm={12} lg={1} className="d-flex flex-grow-1 align-items-center">
          <p className="mb-0 text-center">- or -</p>
        </Col>
        <Col className="d-flex flex-grow-1 align-items-center">
          <Field.Checkbox
            label="Add a VC to the CAAT"
            labelClassName="font-weight-bold mb-0"
            value={createNewVc.value}
            onChange={handleCreateNewVcChange}
          />
        </Col>
      </Row>
      <hr />
      {createNewVc.value && (
        <>
          <h5 className="mt-32">Add a VC to the CAAT</h5>
          <p>
            Please fill out the following information for the VC you want to add
            to the CAAT.
          </p>
          <Row>
            <Col lg={6} className="mb-16">
              <Field.TextInput
                label="VC Name"
                labelClassName="font-weight-bold"
                name="vcName"
                value={vcName.value}
                onChange={handleNewFundFormChange}
                onBlur={validateForm}
              />
              <small className="text-danger">
                {vcName.errors.length > 0 ? vcName.errors[0] : ''}
              </small>
            </Col>
            <Col lg={6} className="mb-16">
              <Field.TextInput
                label="EIN"
                labelClassName="font-weight-bold"
                name="ein"
                value={ein.value}
                onChange={handleNewFundFormChange}
                onBlur={validateForm}
              />
              <small className="text-danger">
                {ein.errors.length > 0 ? ein.errors[0] : ''}
              </small>
            </Col>
            <Col lg={6} className="mb-16">
              <Field.TextInput
                label="Contact First Name"
                labelClassName="font-weight-bold"
                name="firstName"
                value={firstName.value}
                onChange={handleNewFundFormChange}
                onBlur={validateForm}
              />
              <small className="text-danger">
                {firstName.errors.length > 0 ? firstName.errors[0] : ''}
              </small>
            </Col>
            <Col lg={6} className="mb-16">
              <Field.TextInput
                label="Contact Last Name"
                labelClassName="font-weight-bold"
                name="lastName"
                value={lastName.value}
                onChange={handleNewFundFormChange}
                onBlur={validateForm}
              />
              <small className="text-danger">
                {lastName.errors.length > 0 ? lastName.errors[0] : ''}
              </small>
            </Col>
            <Col lg={6} className="mb-16">
              <Field.TextInput
                label="Contact Email"
                labelClassName="font-weight-bold"
                name="email"
                value={email.value}
                onChange={handleNewFundFormChange}
                onBlur={validateForm}
              />
              <small className="text-danger">
                {email.errors.length > 0 ? email.errors[0] : ''}
              </small>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label className="font-weight-bold" htmlFor="phoneNumber">
                  Phone Number
                </Form.Label>
                <PhoneInput
                  id="phoneNumber"
                  numberInputProps={{
                    className: 'form-control',
                  }}
                  international={false}
                  addInternationalOption={false}
                  countries={['US']}
                  country="US"
                  defaultCountry="US"
                  value={
                    phoneNumber.value
                      ? formatPhoneNumber(phoneNumber.value)
                      : ''
                  }
                  countryCallingCodeEditable={false}
                  limitMaxLength
                  onChange={(value) =>
                    handleNewFundFormChange({ name: 'phoneNumber', value })
                  }
                  onBlur={() =>
                    validateForm({
                      target: { name: 'phoneNumber', value: phoneNumber.value },
                    })
                  }
                />
              </Form.Group>
              <small className="text-danger">
                {phoneNumber.errors.length > 0 ? phoneNumber.errors[0] : ''}
              </small>
            </Col>
          </Row>
          <hr />
        </>
      )}
      <h5 className="mt-32">Fund Information</h5>
      <Row className="mt-16">
        <Col>
          <Field.TextInput
            label="Fund Name"
            labelClassName="font-weight-bold"
            value={fundName.value}
            name="fundName"
            onChange={handleNewFundFormChange}
            onBlur={validateForm}
          />
          <small className="text-danger">
            {fundName.errors.length > 0 ? fundName.errors[0] : ''}
          </small>
        </Col>
        <Col>
          <Field.Integer
            label="Fund Size"
            labelClassName="font-weight-bold"
            value={maxFundSize.value}
            name="maxFundSize"
            onChange={handleNewFundFormChange}
            onBlur={validateForm}
          />
          <small className="text-danger">
            {maxFundSize.errors.length > 0 ? maxFundSize.errors[0] : ''}
          </small>
        </Col>
      </Row>
    </>
  );
};

const NewFund = () => {
  const {
    requiredDocuments,
    suggestedDocuments,
    fundName,
    fundingProgramMembershipId,
    maxFundSize,
    fundingProgramId,
  } = $newFundSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  useEffect(() => {
    if (portalType === PORTAL_TYPES.vc) {
      fetchFundingProgramMemberships();
    } else if (portalType === PORTAL_TYPES.edo) {
      fetchAndSetEdoNewFundData();
    }

    return () => newFundCleanup();
  }, []);

  useEffect(() => {
    $newFundSignal.update({
      fundingProgramMembershipId: {
        value: '',
        errors: [],
        dirty: false,
      },
      maxFundSize: {
        ...$newFundSignal.value.maxFundSize,
        errors: [],
      },
    });
  }, [fundingProgramId.value]);

  return (
    <Card className="rounded-0">
      <Card.Body>
        <h3>Create New Fund</h3>
        <hr />
        {portalType === PORTAL_TYPES.vc && <VcInputs />}
        {portalType === PORTAL_TYPES.edo && <EdoInputs />}
        <Row className="mt-32">
          <Col>
            <AttachmentsWithTemplatesForm
              handleFileRemoval={(index) => handleRemoveRequiredDocument(index)}
              handleFilenameChange={(index, name) =>
                handleRequiredDocumentNameChange(index, name)
              }
              handleAttachFile={(index, file) =>
                handleRequiredDocumentAttachFile(index, file)
              }
              handleAddEmptyFileSlot={() => handleAddNewRequiredDocument()}
              error={() => ''}
              title={'Required Documents'}
              fileBundles={requiredDocuments.value}
            />
          </Col>
        </Row>
        <Row className="mt-32">
          <Col>
            <AttachmentsWithTemplatesForm
              handleFileRemoval={(index) =>
                handleRemoveSuggestedDocument(index)
              }
              handleFilenameChange={(index, name) =>
                handleSuggestedDocumentNameChange(index, name)
              }
              handleAttachFile={(index, file) =>
                handleSuggestedDocumentAttachFile(index, file)
              }
              handleAddEmptyFileSlot={() => handleAddNewSuggestedDocument()}
              error={(index) =>
                !suggestedDocuments.value[index].name && 'Name cannot be empty'
              }
              title={'Suggested Documents'}
              fileBundles={suggestedDocuments.value}
            />
          </Col>
        </Row>
        <hr />
        <div className="text-right">
          <Button
            disabled={
              fundName.value.errors ||
              maxFundSize.value.errors ||
              fundingProgramMembershipId.value.errors
            }
            className="rounded"
            size="lg"
            onClick={createNewFund}
          >
            Submit
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default NewFund;
