import loader from '../../../assets/images/loader.svg';

const Loader = ({ message = '', width = '75', className = '' }) => (
  <div className={className}>
    <div className="d-flex justify-content-center">
      <img src={loader} width={width} alt="" />
    </div>
    {message && <div className="mt-16 text-center text-dark">{message}</div>}
  </div>
);

export default Loader;
