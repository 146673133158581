import { Button } from 'react-bootstrap';
import {
  fetchAndSetTechnicalAssistance,
  handleShowCreateTechnicalAssistanceModal,
} from './edoTechnicalAssistance.helpers';
import { useEffect } from 'react';
import edoTechnicalAssistanceSignal from './edoTechnicalAssistance.signal';
import PaginationItems from 'components/global/Pagination';
import { useLocation } from 'react-router-dom';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import TechnicalAssistancesTable from './components/TechnicalAssistancesTable';
import NewTechnicalAssistanceModal from './components/NewTechnicalAssistanceModal';

const EdoTechnicalAssistance = () => {
  const { totalPages } =
    edoTechnicalAssistanceSignal.value;
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const currentPage = searchParams.page ? Number(searchParams.page) : 1;

  useEffect(
    () => fetchAndSetTechnicalAssistance(currentPage),
    [location.search]
  );

  return (
    <BreadCrumbWrapper labels={['Technical Assistance']}>
      <NewTechnicalAssistanceModal />
      <div className="border border-solid border-gray p-24 bg-white shadow-sm">
        <h3 className="text-center mb-0">Technical Assistance</h3>
        <hr />
        <div className="d-flex flex-row justify-content-end mb-16">
          <Button
            className="rounded"
            onClick={handleShowCreateTechnicalAssistanceModal}
          >
            New Technical Assistance
          </Button>
        </div>
        <TechnicalAssistancesTable />
        {totalPages > 1 && (
          <PaginationItems
            className="justify-content-center mt-24"
            totalPages={totalPages}
          />
        )}
      </div>
    </BreadCrumbWrapper>
  );
};

export default EdoTechnicalAssistance;
