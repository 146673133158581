export const getConfig = (key) => {
  const productionAppIds = ['la', 'ca', 'demo', 'sandbox', 'al'];

  const appConfigMap = {
    al: {
      scopes:
        'https://caatauth.onmicrosoft.com/3fdd4c90-376c-4540-807e-badc575937f3/User.Read openid email profile',
      googleAnalyticsKey: 'G-3B3500F7KD',
    },
    sandbox: {
      scopes:
        'https://caatauth.onmicrosoft.com/3fdd4c90-376c-4540-807e-badc575937f3/User.Read openid email profile',
    },
    la: {
      scopes:
        'https://caatauth.onmicrosoft.com/3fdd4c90-376c-4540-807e-badc575937f3/User.Read openid email profile',
      googleAnalyticsKey: 'G-3HW6EX6C5N',
    },
    ca: {
      scopes:
        'https://caatauth.onmicrosoft.com/3fdd4c90-376c-4540-807e-badc575937f3/User.Read openid email profile',
      googleAnalyticsKey: 'G-KP6KL3JC7W',
    },
    demo: {
      scopes:
        'https://caatauth.onmicrosoft.com/3fdd4c90-376c-4540-807e-badc575937f3/User.Read openid email profile',
    },
    qa: {
      scopes:
        'https://MStreetXB2C.onmicrosoft.com/cf15b225-c449-49d2-b82f-6e5dd4d28f7d/User.Read openid email profile',
      googleAnalyticsKey: 'G-LN97ZVZYHC'
    },
    dev: {
      scopes:
        'https://MStreetXB2C.onmicrosoft.com/cf15b225-c449-49d2-b82f-6e5dd4d28f7d/User.Read openid email profile',
    },
  };

  const local = {
    BASE_URL: window.location.origin,
    REACT_APP_API_ENDPOINT:
      process.env.REACT_APP_LOCAL_API_HOSTNAME || 'http://localhost:7071/api',
    REACT_APP_MSAL_CLIENT_ID: 'cf15b225-c449-49d2-b82f-6e5dd4d28f7d',
    REACT_APP_MSAL_AUTHORITY:
      'https://mstreetxb2c.b2clogin.com/MStreetXB2C.onmicrosoft.com/B2C_1_MStreetXB2C_SignIn/',
    REACT_APP_MSAL_KNOWN_AUTHORITIES: 'mstreetxb2c.b2clogin.com',
    REACT_APP_BING_MAPS_KEY:
      'AhgQshv_EOUoohLv0uZCdyo7D0IvZEkoMry8wql6bgHqH7UTsT8pE5OtR9YSMBvS',
  };

  const development = {
    ...local,
    REACT_APP_API_ENDPOINT: 'https://caat-functions-qa.azurewebsites.net/api',
  };

  const qa = {
    ...development,
  };

  const production = {
    ...development,
    REACT_APP_API_ENDPOINT: 'https://caat-functions.azurewebsites.net/api',
    REACT_APP_MSAL_CLIENT_ID: '3fdd4c90-376c-4540-807e-badc575937f3',
    REACT_APP_MSAL_AUTHORITY:
      'https://caatauth.b2clogin.com/caatauth.onmicrosoft.com/B2C_1_caat_signin/',
    REACT_APP_MSAL_KNOWN_AUTHORITIES: 'caatauth.b2clogin.com',
  };

  const configSettings = {
    local,
    qa,
    development,
    production,
  };

  let appId = window.location.hostname.split('.')[0];
  const isProduction = productionAppIds.includes(appId);
  const isQa =
    appId === 'qa' ||
    (appId.includes('white-mushroom-038904010') && appId.includes('qa'));
  const isDev =
    appId === 'dev' ||
    (appId.includes('white-mushroom-038904010') &&
      appId.includes('development'));

  let env = 'local';
  if (isProduction) {
    env = 'production';
  } else if (isQa) {
    env = 'qa';
    appId = 'qa';
  } else if (isDev) {
    env = 'development';
    appId = 'dev';
  } else {
    appId = 'dev';
  }

  const config = configSettings[env] || configSettings.development;
  config.APP_CONFIG = appConfigMap[appId];
  config.APP_CONFIG.appId = appId;

  if (!key) {
    return config;
  }

  return config[key];
};
