import Signal from "libs/signals";

const $executiveDashboard = Signal({
  submissions: {
    query: 'week',
    loader: false,
    labels: [],
    filters: {
      applicationTally: true,
      programRequestTally: false,
      capitalCallTally: false,
      fundTally: false,
      vcTally: false,
      lenderTally: false,
      businessTally: false,
    },
    tallies: {
      applicationTally: [],
      programRequestTally: [],
      capitalCallTally: [],
      fundTally: [],
      vcTally: [],
      lenderTally: [],
      businessTally: [],
    },
  },
  lenderSignUps: {
    query: 'week',
    labels: [],
    loader: false,
    tallies: {
      invitationsSent: [],
      invitationsAccepted: [],
      expressedInterest: [],
    },
    filters: {
      invitationsSent: true,
      invitationsAccepted: false,
      expressedInterest: false,
    },
  },
  vcSignUps: {
    query: 'week',
    labels: [],
    loader: false,
    tallies: {
      invitationsSent: [],
      invitationsAccepted: [],
      expressedInterest: [],
    },
    filters: {
      invitationsSent: true,
      invitationsAccepted: false,
      expressedInterest: false,
    },
  },
  businessSignUps: {
    query: 'week',
    labels: [],
    loader: false,
    tallies: {
      invitationsSent: [],
      invitationsAccepted: [],
      expressedInterest: [],
    },
    filters: {
      invitationsSent: true,
      invitationsAccepted: false,
      expressedInterest: false,
    },
  },
});

export default $executiveDashboard;
