import { Button, Col, Form, Row } from 'react-bootstrap';
import applicationDetailSignal from '../ApplicationDetail.signal';
import {
  createOrUpdateApplicationToWatchlist,
  handleWatchListStatusDropdownChange,
  removeApplicationFromWatchlist,
} from '../ApplicationDetail.helpers';
import { WATCHLIST_STATUS } from 'components/global/Constant/constants';
import capitalizeFirst from 'utils/capitalizeFirst';

const ApplicationWatchlistTab = () => {
  const {
    watchableId,
    watchListStatus,
    newWatchListStatus,
    modelType,
    formErrors,
  } = applicationDetailSignal.value;
  const isApplication = modelType === 'application';

  return (
    <>
      <Row className="my-16" hidden={!isApplication}>
        <Col>
          <h5>
            Current Status:{' '}
            {(watchListStatus && capitalizeFirst(watchListStatus)) ||
              'This application is not on your watch list'}
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-between align-items-center">
        <Col hidden={!isApplication}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold">
              {!watchListStatus
                ? 'Select a Status and Add to Watch List'
                : 'Update Watch List Status'}
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              value={newWatchListStatus}
              name="newApplicationStatus"
              isInvalid={formErrors?.watchListStatusInvalid}
              onChange={handleWatchListStatusDropdownChange}
            >
              <option value="">Select New Status</option>
              <option value={WATCHLIST_STATUS.watching}>Watching</option>
              <option value={WATCHLIST_STATUS.nonPerforming}>
                Non-performing
              </option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select an option
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            className="rounded"
            hidden={!watchListStatus}
            disabled={
              watchListStatus === newWatchListStatus || !newWatchListStatus
            }
            onClick={createOrUpdateApplicationToWatchlist}
          >
            Save Status
          </Button>
        </Col>
        <Col hidden={isApplication}>
          <p className="my-0">
            {watchableId
              ? 'This application is on your watch list.'
              : 'This application is not on your watch list.'}
          </p>
        </Col>
        <Col className="text-right">
          {watchableId ? (
            <Button
              variant="outline-danger"
              className="mt-16 rounded"
              onClick={() => removeApplicationFromWatchlist(watchableId)}
            >
              Remove from Watch List
            </Button>
          ) : (
            <Button
              variant="outline-primary"
              className="mt-16 rounded"
              disabled={isApplication && !newWatchListStatus}
              onClick={() => createOrUpdateApplicationToWatchlist()}
            >
              Add to Watch List
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ApplicationWatchlistTab;
