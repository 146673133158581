import alertSignal from "signals/Alert.signal";
import api from "api/api";
import loaderSignal from "signals/Loader.signal";
import history from "../../../../history";
import edoPerformanceSignal from "./EdoPerformance.signals";

export const fetchAndSetPrograms = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const [platforms, fundingPrograms] = await Promise.all([
      api.get({
        path: '/platforms',
        options: {
          include: {
            stats: true,
            fundingPrograms: {
              include: {
                stats: true
              }
            }
          },
        },
      }),
      api.get({
        path: '/fundingPrograms',
        options: {
          where: xFormAppliedFiltersToWhereClause(),
          orderBy: xFormSortToOrderByClause(),
          include: {
            stats: true,
            memberships: {
              include: {
                applications: {
                  include: {
                    disbursements: {
                      include: {
                        transactions: true,
                      },
                    },
                  },
                },
              },
            },
            platform: true,
            referenceProgram: {
              include: {
                programType: true,
              },
            },
          },
        },
      }),
    ]);

    return edoPerformanceSignal.update({ fundingPrograms, platforms });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleEdoPerformanceFilterChange = (key, value) => {
  const dropdownFilters = edoPerformanceSignal.value.dropdownFilters;
  return edoPerformanceSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {};

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.platformName) {
    where.platform = {
      id: Number(params.platformName),
    };
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  return [{ [key]: dir }];
};

export const handlePerformanceClearFilters = () => {
  edoPerformanceSignal.update({ dropdownFilters: {} });
}

export const handleDownloadFundingProgramsPerformanceData = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    await api.get({
      path: '/fundingPrograms/stats/download',
      options: {
        where: xFormAppliedFiltersToWhereClause(),
        orderBy: xFormSortToOrderByClause(),
        include: {
          stats: true,
          referenceProgram: true,
          platform: true,
        },
      },
      isFileDownload: true,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};
