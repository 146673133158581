import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const Radio = ({
  label,
  name,
  value = [],
  onChange,
  className,
  labelClassName,
  inputClassName,
  options,
}) => {
  // const handleSelect = ({ label: optionLabel, value: optionVal }) => {
  //   let valueTmp = [...value];
  //   const exists = value.find(({ value: valueToFind }) => valueToFind === optionVal);
  //   if (exists) {
  //     valueTmp = valueTmp.filter(({ value: existingValue }) => existingValue !== optionVal);
  //   } else {
  //     valueTmp.push({ label: optionLabel, value: optionVal });
  //   }

  //   onChange({ name, value: valueTmp });
  // };
  const handleSelect = (e, newVal) => {
    const eTmp = { ...e };
    eTmp.target.value = newVal;
    handleInputChange(eTmp, onChange);
  };
  return (
    <Form.Group className={className}>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      {options.map((option, idx) => {
        // const isSelected = value.find(({ value: valueToFind }) => valueToFind === option.value);
        const isSelected = value === option.value;
        return (
          <div className="d-flex align-items-center" key={option.value}>
            <Form.Check
              key={`${name}-${idx}`}
              id={`${name}-${idx}`}
              name={name}
              type="radio"
              checked={isSelected}
              label={option.label}
              className={inputClassName}
              onChange={(e) => handleSelect(e, option.value)}
            />
          </div>
        );
      })}
    </Form.Group>
  );
};

export default Radio;
