import { Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const EdoVcDetail = () => {
  const { pathname } = useLocation();
  const segments = pathname.split('/')
  const organizationId = segments[segments.length - 1];
  const { selectedOrganization } = organizationDetailsSignal.value;
  const labels = [
    segments[2] === 'vc' ? 'Venture Capitalists' : '',
    !!selectedOrganization ? selectedOrganization.name : '',
  ];

  const init = useCallback(async () => {
    await fetchOrganization(organizationId, '/ventureCapitalFirms');
  });

  useEffect(() => {
    init();
  }, [organizationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card>
        <Card.Body>
          <OrganizationDetails />
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default EdoVcDetail;
