import api from 'api/api';
import ProgramSignal, {
  fundingProgramFormErrorsSignal,
  PROGRAM_INFO_INIT_STATE,
} from 'signals/Program.signal';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import {
  MAIN_PROGRAM_INPUTS,
  validateFileBundle,
  validateFundingProgramField,
} from 'libs/fundingProgram';
import {
  PROGRAM_KEYS,
} from 'components/global/Constant/constants';
import approvalChainTemplatingSignal from 'components/global/ApprovalChainTemplating/ApprovalChainTemplating.signal';
import { canSubmitApprovalChain } from 'components/global/ApprovalChainTemplating/ApprovalChainTemplating.helpers';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import $appSettings from 'signals/AppSettings.signal';

export const NUM_OF_STEPS = 4;

export const fetchAndSetAddProgramData = async () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  try {
    loaderSignal.update({ isContentLoading: true });
    const [programTypes, platforms, users] = await Promise.all([
      api.get({
        path: '/programtypes',
      }),
      api.get({
        path: '/platforms',
        options: {
          include: {
            stats: true,
          },
        },
      }),
      api.get({
        path: '/users',
        options: {
          where: {
            account: {
              portalType: PORTAL_TYPES.edo,
            },
          },
          include: {
            account: true,
          },
        },
      }),
    ]);
    return ProgramSignal.update({
      programTypes,
      platforms,
      administrators: users,
      users,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleInputChange = (e) => {
  const { name, value } = e.target;
  const { programInfo } = ProgramSignal.value;

  if (name === 'programTypeId') {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: Number(value),
        referenceProgramId: null,
      },
    });
  } else if (name === 'referenceProgramId') {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: Number(value),
        programClassification: '',
      },
    });
  } else if (name === 'platformId') {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: Number(value),
      },
    });
  } else if (name === 'programClassification') {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: value,
      },
    });
  } else {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: value,
      },
    });
  }
};

export const handleAttachFile = (filesIn, documentType, name, index = null) => {
  let fileBundle = [];
  if (Array.isArray(filesIn)) {
    fileBundle = filesIn.map((file) => ({
      name: '',
      documentType,
      file,
    }));
  } else {
    const file = {
      name: '',
      documentType,
      file: filesIn,
    };
    fileBundle = [...fileBundle, file];
  }

  if (index !== null && index > -1) {
    const newArr = [...ProgramSignal.value.programInfo[name]];
    newArr[index].file = filesIn;

    return ProgramSignal.update({
      programInfo: {
        ...ProgramSignal.value.programInfo,
        [name]: newArr,
      },
    });
  }

  ProgramSignal.update({
    programInfo: {
      ...ProgramSignal.value.programInfo,
      [name]: ProgramSignal.value.programInfo[name].concat(fileBundle),
    },
  });
};

export const handleAddProgramFileRemoval = (index, name) => {
  const files = ProgramSignal.value.programInfo[name].filter(
    (f, i) => i !== index
  );
  const file = ProgramSignal.value.programInfo[name][index];
  fundingProgramFormErrorsSignal.update({
    [`${file.documentType}${index}`]: false,
  });
  return ProgramSignal.update({
    programInfo: {
      ...ProgramSignal.value.programInfo,
      [name]: files,
    },
  });
};

export const handleAddProgramFileUpdate = (
  index,
  payload,
  name,
  documentType
) => {
  const uploadedFiles = ProgramSignal.value.programInfo[name].map((f, i) => {
    if (i === index) {
      // clear errors
      if (payload.name)
        fundingProgramFormErrorsSignal.update({
          [`${documentType}${index}`]: false,
        });
      if (payload.documentType)
        fundingProgramFormErrorsSignal.update({
          [`documentType${index}`]: false,
        });

      return { ...f, ...payload };
    }
    return f;
  });
  return ProgramSignal.update({
    programInfo: {
      ...ProgramSignal.value.programInfo,
      [name]: uploadedFiles,
    },
  });
};

export const handleAddProgramStepSubmission = async () => {
  const { programInfo } = ProgramSignal.value;
  const { step } = programInfo;

  if (step === 1 && !basicProgramInfoStepIsValid()) {
    return false;
  }

  if (step === 2 && !documentsAndTagsSectionIsValid()) {
    return false;
  }

  if (step === 3 && !canSubmitApprovalChain()) {
    return false;
  }

  if (step <= 2) {
    return nextStep();
  } else {
    return handleProgramSubmission();
  }
};

export const handleInputFocusOut = (e) => {
  const { programInfo, platforms } = ProgramSignal.value;
  const error = validateFundingProgramField(
    e.target.name,
    e.target.value,
    programInfo,
    platforms.find((p) => p.id === Number(programInfo.platformId))
  );

  return fundingProgramFormErrorsSignal.update({
    [e.target.name]: error,
  });
};

export const handleDocumentUploadInputFocusOut = (e, idx, documentTypeName) => {
  const { name } = e.target;
  const uploadedFiles = ProgramSignal.value.programInfo[documentTypeName];
  const file = uploadedFiles[idx];
  const error = validateFileBundle(name, file);
  fundingProgramFormErrorsSignal.update({
    [name]: error,
  });
};

export const handleProgramSubmission = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Creating Program...',
  });
  try {
    const { programTypes, programInfo } = ProgramSignal.value;
    const {
      programRequestTemplates,
      applicationTemplates,
      programRequestSuggestedDocuments,
      applicationSuggestedDocuments,
    } = programInfo;

    const uploadedFiles = [
      ...programRequestTemplates,
      ...applicationTemplates,
      ...programRequestSuggestedDocuments,
      ...applicationSuggestedDocuments,
    ];

    let body = {
      data: {
        amountAllocatedToProgram: programInfo.amountAllocatedToProgram,
        businessDocumentNames:
          programInfo.businessDocumentSubmittableTypesSelected,
        ceiling: programInfo.ceiling,
        eligibility: programInfo.eligibility,
        programRules: programInfo.programRules,
        fundsAlreadyAllocated: programInfo.fundsAlreadyAllocated,
        ineligibleBusinesses: programInfo.ineligibleBusinesses,
        lenderDocumentNames: programInfo.lenderDocumentSubmittableTypesSelected,
        name: programInfo.name,
        overview: programInfo.overview,
        programConfig: {},
        vcDocumentNames: programInfo.vcDocumentSubmittableTypesSelected,
        website: programInfo.website,
      },
    };

    const signedUrls = await uploadFilesToStorage(
      uploadedFiles
        .filter((bundle) => bundle.file)
        .map((bundle) => bundle.file),
      'fundingProgramDocument'
    );

    body.data.supportingDocuments = {
      create: uploadedFiles.map((file, index) => ({
        fileType: file.file ? file.file.type : null,
        filePath: file.file ? signedUrls[index] : null,
        documentType: file.documentType,
        name: file.name,
      })),
    };

    const { approvalChainTemplateStepsMatrix, thresholdsMatrix } =
      approvalChainTemplatingSignal.value;

    const approvalChainTemplateSteps = approvalChainTemplateStepsMatrix
      .map((tss, index) => {
        const min = thresholdsMatrix[index][0];
        const max = thresholdsMatrix[index][1];

        return tss.map((ts) => ({
          ...ts,
          min,
          max,
        }));
      })
      .flatMap((a) => a);

    const approvalChainFormatted = approvalChainTemplateSteps.map(
      ({
        primaryApproverId,
        boardRepresentative,
        role,
        alternateApprovers,
        min,
        max,
      }) => ({
        primaryApproverId,
        role,
        boardRepresentative,
        alternateApprovers: {
          create: alternateApprovers
            .map((aa) => ({ userId: aa }))
            .filter((aa) => aa.userId.length > 0),
        },
        min,
        max,
      })
    );

    body.data.approvalChainTemplates = {
      create: {
        thresholds: thresholdsMatrix,
        steps: {
          create: approvalChainFormatted,
        },
      },
    };

    body.data.platform = {
      connect: {
        id: Number(programInfo.platformId),
      },
    };

    body.data.referenceProgram = {
      connect: {
        id: Number(programInfo.referenceProgramId),
      },
    };

    body.data.administrator = {
      connect: {
        id: programInfo.administratorId,
      },
    };

    let programConfigKeys = [];

    const referenceProgramKey = programTypes
      .map((pt) => pt.referencePrograms)
      .flatMap((a) => a)
      .find((rp) => rp.id === Number(programInfo.referenceProgramId)).key;

    switch (referenceProgramKey) {
      case PROGRAM_KEYS.loanParticipation:
      case PROGRAM_KEYS.microLoan:
        programConfigKeys = ['programClassification'];
        break;
      case PROGRAM_KEYS.collateralSupport:
        programConfigKeys = [
          'maxCollateralSupportPercentage',
          'minCollateralSupportPercentage',
          'rebalancingFrequency',
        ];
        break;
      case PROGRAM_KEYS.loanGuaranty:
        programConfigKeys = [
          'minGuarantyPercentage',
          'maxGuarantyPercentage',
          'rebalancingFrequency',
          'fundsUnderCustodyAt',
        ];
        break;
      case PROGRAM_KEYS.capitalAccess:
        programConfigKeys = ['fundsUnderCustodyAt'];
        break;
      case PROGRAM_KEYS.fundInvestment:
        programConfigKeys = ['fundType'];
        break;
      default:
        programConfigKeys = [];
        break;
    }

    Object.entries(programInfo).forEach(([k, v]) => {
      if (programConfigKeys.includes(k)) {
        if (isNaN(Number(v))) {
          body.data.programConfig[k] = v;
        } else {
          body.data.programConfig[k] = Number(v);
        }
      }
    });

    const fundingProgram = await api.post({
      path: '/fundingPrograms',
      body,
    });
    ProgramSignal.update({
      fundingProgramId: fundingProgram.id,
      name: fundingProgram.name,
      programInfo: { ...PROGRAM_INFO_INIT_STATE, step: NUM_OF_STEPS },
    });
    approvalChainTemplatingSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully created program.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const nextStep = () => {
  const { programInfo } = ProgramSignal.value;
  if (programInfo.step < NUM_OF_STEPS + 1) {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        step: programInfo.step + 1,
      },
    });
  }
};

export const prevStep = () => {
  const { programInfo } = ProgramSignal.value;
  if (programInfo.step > 1) {
    ProgramSignal.update({
      programInfo: {
        ...programInfo,
        step: programInfo.step - 1,
      },
    });
  }
};

export const resetFormFlow = () => {
  ProgramSignal.update({
    programInfo: PROGRAM_INFO_INIT_STATE,
  });
  fundingProgramFormErrorsSignal.reset();
};

export const reset = () => {
  ProgramSignal.reset();
};

export const exitAndReset = (history) => {
  if (history.location.pathname.includes('state')) {
    history.push('/state');
  } else {
    history.push('/edo');
  }
  reset();
};

export const addDocumentToVcChoices = (e, value) => {
  const { programInfo } = ProgramSignal.value;
  return ProgramSignal.update({
    programInfo: {
      ...programInfo,
      vcDocumentSubmittableTypesSelected:
        programInfo.vcDocumentSubmittableTypesSelected.concat(value),
    },
  });
};

export const addDocumentToLenderChoices = (e, value) => {
  const { programInfo } = ProgramSignal.value;
  return ProgramSignal.update({
    programInfo: {
      ...programInfo,
      lenderDocumentSubmittableTypesSelected:
        programInfo.lenderDocumentSubmittableTypesSelected.concat(value),
    },
  });
};

export const addDocumentToBusinessChoices = (e, value) => {
  const { programInfo } = ProgramSignal.value;
  return ProgramSignal.update({
    programInfo: {
      ...programInfo,
      businessDocumentSubmittableTypesSelected:
        programInfo.businessDocumentSubmittableTypesSelected.concat(value),
    },
  });
};

export const removeVcDocumentChoice = (e, value) => {
  const { programInfo } = ProgramSignal.value;
  return ProgramSignal.update({
    programInfo: {
      ...programInfo,
      vcDocumentSubmittableTypesSelected:
        programInfo.vcDocumentSubmittableTypesSelected.filter(
          (d) => d !== value
        ),
    },
  });
};

export const removeLenderDocumentChoice = (e, value) => {
  const { programInfo } = ProgramSignal.value;
  return ProgramSignal.update({
    programInfo: {
      ...programInfo,
      lenderDocumentSubmittableTypesSelected:
        programInfo.lenderDocumentSubmittableTypesSelected.filter(
          (d) => d !== value
        ),
    },
  });
};

export const removeBusinessDocumentChoice = (e, value) => {
  const { programInfo } = ProgramSignal.value;
  return ProgramSignal.update({
    programInfo: {
      ...programInfo,
      businessDocumentSubmittableTypesSelected:
        programInfo.businessDocumentSubmittableTypesSelected.filter(
          (d) => d !== value
        ),
    },
  });
};

const basicProgramInfoStepIsValid = () => {
  const { programTypes, programInfo } = ProgramSignal.value;
  const referenceProgram = programTypes
    .map((pt) => pt.referencePrograms)
    .flatMap((a) => a)
    .find((rp) => rp.id === Number(programInfo.referenceProgramId));

  let inputKeys = [...MAIN_PROGRAM_INPUTS.general.basicProgramInfo];
  inputKeys = [
    ...inputKeys,
    ...(MAIN_PROGRAM_INPUTS[referenceProgram.key]?.basicProgramInfo || []),
  ];

  const selectedPlatform = ProgramSignal.value.platforms.find(
    (p) => p.id === Number(programInfo.platformId)
  );

  let valid = true;

  inputKeys.forEach((key) => {
    const error = validateFundingProgramField(
      key,
      programInfo[key],
      programInfo,
      selectedPlatform
    );

    if (error) {
      valid = false;
    }

    fundingProgramFormErrorsSignal.update({
      [key]: error,
    });
  });

  return valid;
};

export const documentsAndTagsSectionIsValid = () => {
  const {
    programRequestTemplates,
    applicationTemplates,
    programRequestSuggestedDocuments,
    applicationSuggestedDocuments,
  } = ProgramSignal.value.programInfo;

  if (programRequestTemplates.length !== 1) {
    alertSignal.update({
      type: 'notification',
      message: 'You must upload a program request template.',
    });
    return false;
  }

  if (applicationTemplates.length !== 1) {
    alertSignal.update({
      type: 'notification',
      message: 'You must upload an application template.',
    });
    return false;
  }

  let valid = true;

  [
    programRequestTemplates,
    applicationTemplates,
    programRequestSuggestedDocuments,
    applicationSuggestedDocuments,
  ].forEach((bundles) => {
    bundles.forEach((bundle, index) => {
      const key = `${bundle.documentType}${index}`;
      const error = validateFileBundle(key, bundle);
      if (error) {
        valid = false;
      }

      fundingProgramFormErrorsSignal.update({
        [key]: error,
      });
    });
  });

  return valid;
};

export const handleAddSuggestedDocument = (documentTypeName, documentType) => {
  ProgramSignal.update({
    programInfo: {
      ...ProgramSignal.value.programInfo,
      [documentTypeName]: [
        ...ProgramSignal.value.programInfo[documentTypeName],
        {
          name: '',
          documentType,
        },
      ],
    },
  });
};
