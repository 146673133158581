import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const Integer = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  disabled = false,
  onBlur,
  placeholder,
  isInvalid,
  inputRef,
}) => {
  const handleChange = (e) => {
    const eTmp = { ...e };
    eTmp.target.value = e.target.value.replace('.', '');
    handleInputChange(eTmp, onChange);
  };

  return (
    <Form.Group className={className}>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <div>
        <Form.Control
          ref={inputRef}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          type="number"
          value={value}
          id="inputInteger"
          className={inputClassName}
          onChange={handleChange}
          onBlur={onBlur}
          isInvalid={isInvalid}
        />
      </div>
    </Form.Group>
  );
};

export default Integer;
