import Signal from 'libs/signals';

const userInvitationsSignal = Signal({
  userInvitations: [],
  selectedUserInvitation: null,
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
});

export default userInvitationsSignal;
