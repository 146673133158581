import Signal from 'libs/signals';

const stateAddUserFormSignal = Signal({
  addEdoUser: {
    id: '',
    firstName: '',
    lastName: '',
    status: false,
    lastSignedIn: new Date(),
    created: new Date(),
    email: '',
    phone: '',
    address: {
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
    logs: [
      {
        date: new Date(),
        changes: [],
      },
    ],
    userPrograms: [],
  },
  addAdminUser: {
    id: Math.floor(Math.random() * 100),
    firstName: '',
    lastName: '',
    password: '',
    email: '',
    status: true,
    created: new Date(),
    lastSignedIn: new Date(),
    AdminType: {
      id: undefined,
      name: '',
    },
    accountTerritories: [],
    confirmPassword: '',
  },
  addEdoUserErrors: {
    email: false,
    phone: false,
  },
  addAdminUserErrors: {
    email: false,
    confirmPassword: false,
  },
});

export default stateAddUserFormSignal;
