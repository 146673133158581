import globalProfileSignal from 'signals/GlobalProfile.signal';
import { zipcodeRegex } from 'components/global/Constant/regularExpression';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import queryAddresses from 'utils/queryAddresses';

export const handleAccountSettingsDetailsChange = (e) => {
  const { name, value } = e.target;

  globalProfileSignal.update({
    form: {
      ...globalProfileSignal.value.form,
      [name]: value,
    },
    errors: {
      ...globalProfileSignal.value.errors,
      [name]: '',
    },
  });
};

export const handleClearAccountSettingsDetailsErrors = (name, value) => {
  if (
    name === 'zipCode' &&
    zipcodeRegex.test(globalProfileSignal.value.form.zipCode)
  ) {
    return globalProfileSignal.update({
      errors: {
        ...globalProfileSignal.value.errors,
        zipCode: '',
      },
    });
  }

  if (globalProfileSignal.value.form[value]) {
    return globalProfileSignal.update({
      errors: {
        ...globalProfileSignal.value.errors,
        zipCode: '',
      },
    });
  }
};

const handleCheckFormForErrorsBeforeSubmit = () => {
  const { form } = globalProfileSignal.value;
  const keys = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'address',
  ];
  keys.forEach((k) => handleSetProfileFormErrors(k, form[k]));
};

export const handleUpdateProfileSubmission = async () => {
  handleCheckFormForErrorsBeforeSubmit();

  if (Object.values(globalProfileSignal.value.errors).every((v) => !v)) {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Updating profile...',
    });
    try {
      const {
        id,
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        displayName,
      } = globalProfileSignal.value.form;

      const body = {
        id,
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        displayName,
      };

      const accountInfo = await api.patch({
        path: '/accountSettings',
        body,
      });

      userAccountSignal.update({
        userData: {
          ...userAccountSignal.value.userData,
          ...accountInfo,
        },
      });

      return alertSignal.update({
        variant: 'success',
        type: 'notification',
        message: 'Successfully updated profile.',
      });
    } catch (error) {
      return alertSignal.update({
        type: 'alert',
        error,
        message: 'Unable to update profile.',
      });
    } finally {
      loaderSignal.reset();
    }
  }

  return false;
};

export const handleSetProfileFormErrors = (name, value) => {
  if (name === 'address') {
    if (!Object.keys(globalProfileSignal.value.form?.address).length) {
      return globalProfileSignal.update({
        errors: {
          ...globalProfileSignal.value.errors,
          address: true,
        },
      });
    }
  } else if (!value || !value.length) {
    return globalProfileSignal.update({
      errors: {
        ...globalProfileSignal.value.errors,
        [name]: 'This field is required',
      },
    });
  } else if (
    name === 'phoneNumber' &&
    !validatePhoneNumber(globalProfileSignal.value.form.phoneNumber)
  ) {
    return globalProfileSignal.update({
      errors: {
        ...globalProfileSignal.value.errors,
        phoneNumber: 'Please enter a valid phone number',
      },
    });
  }
};

export const onInputBlur = (e) => {
  const { name, value } = e.target;
  handleSetProfileFormErrors(name, value);
};

export const handleAddressSearchChange = (input) => {
  globalProfileSignal.update({
    form: {
      ...globalProfileSignal.value.form,
      address: {},
    },
    addressSearch: input,
    errors: {
      ...globalProfileSignal.value.errors,
      address: false,
    },
  });
};

export const handleSelectAddress = (option) => {
  globalProfileSignal.update({
    form: {
      ...globalProfileSignal.value.form,
      address: option,
    },
    addressSearch: option.formattedAddress,
    errors: {
      ...globalProfileSignal.value.errors,
      address: false,
    },
  })
};

export const getAddressOptions = async () => {
  const bingAddresses = await queryAddresses(
    globalProfileSignal.value.addressSearch
  );
  globalProfileSignal.update({
    addressOptions: bingAddresses.map((ba) => ba),
  });
};

export const handleAddressBlur = () => {
  if (!Object.keys(globalProfileSignal.value.form?.address).length) {
    globalProfileSignal.update({
      errors: {
        ...globalProfileSignal.value.errors,
        address: true,
      },
    });
  }
};

export const handleAddressSearchFocus = () => {
  globalProfileSignal.update({
    addressSearch: globalProfileSignal.value.form?.address?.formattedAddress || globalProfileSignal.value.addressSearch
  });
};
