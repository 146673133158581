import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import FileDropZoneWrapper from 'components/global/FileDropZone';
import DragAndDrop from 'components/global/DragAndDrop/DragAndDrop';
import edoApplicationBulkImportSignal from './EdoApplicationBulkImport.signals';
import {
  handleAttachBulkImportFile,
  handleBulkImportSubmission,
  hideProcessBulkImportFileModal,
} from './EdoApplicationBulkImport.helpers';
import ComplianceChecklistItem from 'components/global/ApplicationCompliance/ComplianceChecklistItem';
import {
  COMPLIANCE_CHECK_DATA,
  handleComplianceItemChange,
  updateNumberOfComplianceItems,
} from 'components/global/ApplicationCompliance/ApplicationCompliance.helpers';
import PaginationButton from 'components/global/PaginationButton/PaginationButton';
import applicationComplianceSignal from 'components/global/ApplicationCompliance/ApplicationCompliance.signal';
import { validateAllComplianceItems } from 'components/global/SubmitApplications/SubmitApplications.helpers';
import { useEffect, useState } from 'react';
import alertSignal from 'signals/Alert.signal';
import Field from 'components/global/Field/Field';

const COMPLIANCE_ITEM_KEYS = [
  COMPLIANCE_CHECK_DATA.complianceItemOne,
  COMPLIANCE_CHECK_DATA.complianceItemTwo,
  COMPLIANCE_CHECK_DATA.complianceItemThree,
  COMPLIANCE_CHECK_DATA.complianceItemFour,
  COMPLIANCE_CHECK_DATA.complianceItemFive,
  COMPLIANCE_CHECK_DATA.complianceItemSix,
  COMPLIANCE_CHECK_DATA.complianceItemSeven,
  COMPLIANCE_CHECK_DATA.complianceItemEight,
  COMPLIANCE_CHECK_DATA.complianceItemNine,
  COMPLIANCE_CHECK_DATA.complianceItemTen,
];

function getComplianceItems(s) {
  if (s === 'ca') {
    return COMPLIANCE_ITEM_KEYS;
  }

  return COMPLIANCE_ITEM_KEYS.slice(0, 8);
}

const EdoApplicationBulkImportProcessFileModal = () => {
  const { importData, formModalVisible } = edoApplicationBulkImportSignal.value;
  const [overarchingTab, setOverarchingTab] = useState('compliance');
  const { currentComplianceItem, totalComplianceItems } =
    applicationComplianceSignal.value;
  const [complianceSelection, setComplianceSelection] = useState('la');
  const complianceItems = getComplianceItems(complianceSelection);
  const referenceProgramId = complianceSelection === 'ca' ? 4 : 0;
  const complianceChecklistIsValid =
    validateAllComplianceItems(referenceProgramId);

  useEffect(() => {
    updateNumberOfComplianceItems(referenceProgramId);
  }, [complianceSelection, referenceProgramId]);

  return (
    <ModalWrapper show={formModalVisible} centered size="xl">
      <ModalBody>
        <Tabs
          activeKey={overarchingTab}
          onSelect={(tab) => {
            if (tab === 'file' && !complianceChecklistIsValid) {
              return alertSignal.update({
                type: 'notification',
                message: 'Please finish the compliance section first',
                error: '',
              });
            }

            setOverarchingTab(tab);
          }}
        >
          <Tab key="compliance" eventKey="compliance" title="Compliance">
            <div className="p-8">
              <Row className="justify-content-between">
                <Col>
                  <h4>Compliance</h4>
                </Col>
                <Col className="col-auto">
                  <Field.Select
                    inputClassName="form-control"
                    className="justify-content-end"
                    onChange={({ value }) => setComplianceSelection(value)}
                    value={complianceSelection}
                    options={[
                      {
                        value: 'la',
                        label: 'MLP, LGP, CSP - Louisiana',
                      },
                      {
                        value: 'ca',
                        label: 'CalCap - California',
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Tabs
                activeKey={currentComplianceItem}
                title={null}
                className="mb-16"
              >
                {complianceItems.map((ci, i) => (
                  <Tab title={null} key={i} eventKey={i + 1}>
                    <ComplianceChecklistItem complianceItemData={ci} />
                  </Tab>
                ))}
              </Tabs>
              <Row className="text-center">
                <Col>
                  <PaginationButton
                    hidden={currentComplianceItem <= 1}
                    increment={false}
                    onClick={() => {
                      handleComplianceItemChange(-1);
                    }}
                  />
                </Col>
                <Col>
                  <PaginationButton
                    hidden={currentComplianceItem >= totalComplianceItems}
                    increment
                    onClick={() => {
                      handleComplianceItemChange(1);
                    }}
                  />

                  <PaginationButton
                    hidden={!(currentComplianceItem >= totalComplianceItems)}
                    increment
                    onClick={() => {
                      setOverarchingTab('file');
                    }}
                  />

                  {currentComplianceItem === totalComplianceItems && (
                    <small
                      className="text-danger"
                      hidden={complianceChecklistIsValid}
                    >
                      * All compliance items must be filled out before
                      proceeding.
                    </small>
                  )}
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab key="file" eventKey="file" title="Import File">
            <div className="p-8">
              <h4>Import File</h4>
              <p>
                We will parse the data in the CSV file and upload the
                applications in a background job.
              </p>
              <Row>
                <Col xs={{ span: 6, offset: 3 }}>
                  <FileDropZoneWrapper
                    formats={['csv']}
                    numOfUploadsAllowed={1}
                    maxSizeMB={100}
                    onUpload={(files) => {
                      files.forEach((file) => handleAttachBulkImportFile(file));
                    }}
                  >
                    <DragAndDrop
                      files={[]}
                      uploadProgress={0}
                      // onFileCancel={handleFileCancel}
                      maxSizeMB={100}
                      preview={false}
                      formats={['csv']}
                    />
                  </FileDropZoneWrapper>

                  {importData.length > 0 && (
                    <Table>
                      <thead>
                        <tr>
                          <th>Application Filepath</th>
                          <th>Other documents</th>
                          <th>Model</th>
                        </tr>
                      </thead>
                      <tbody>
                        {importData.map(
                          ([ocrFilepath, documentsFilepath, model], index) => (
                            <tr key={index}>
                              <td>{ocrFilepath}</td>
                              <td>{documentsFilepath}</td>
                              <td>{model}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  )}

                  <Button
                    className="rounded w-100"
                    disabled={!importData.length}
                    onClick={() =>
                      handleBulkImportSubmission().then(() =>
                        setOverarchingTab('compliance')
                      )
                    }
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Tab>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => {
            hideProcessBulkImportFileModal();
          }}
        >
          Dismiss
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default EdoApplicationBulkImportProcessFileModal;
