import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Form, Modal, Row, Table } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import dateOptions from 'components/global/Constant/dateOptions';
import AdminSettingsUsersSignal from 'signals/StateUsers.signal';
import stateAddNoteSignal from 'signals/StateAddNote.signal';
import LargeCheckBox from 'components/global/Constant/LargeCheckBox';
import userAccountSignal from 'signals/UserAccount.signal';
import { ABBREVIATED_STATES } from 'components/global/Constant/usStates';
import {
  cancelEditing,
  fetchAndSetStateUsers,
  handleAddNote,
  handleAddNoteFormChange,
  handleAdminRoleChange,
  handleResetUserDetailsErrors,
  handleSetUserDetailErrors,
  handleUserDetailsAddressChange,
  handleUserDetailsChange,
} from '../StateUsers.helpers';

const adminTypes = [
  {
    id: 1,
    name: 'Full Admin',
  },
  {
    id: 2,
    name: 'Account Admin',
  },
  {
    id: 3,
    name: 'User',
  },
];

const UserDetailsModal = ({ onToggleModal, show }) => {
  const { selectedUser, selectedUserErrors } = AdminSettingsUsersSignal.value;
  const addNoteForm = stateAddNoteSignal.value;
  const [editing, setEditing] = useState(false);
  const [addNote, setAddNote] = useState(false);

  const init = useCallback(async () => {
    await fetchAndSetStateUsers();
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!editing) {
      setAddNote(false);
    }
  }, [editing]);

  const handleUpdateUserSubmit = async (e) => {
    e.preventDefault();
    handleSetUserDetailErrors();

    if (!Object.values(AdminSettingsUsersSignal.value.selectedUserErrors).includes(true)) {
      // await updateUser();
      setEditing(false);
    }
  };

  return (
    <Modal
      centered
      size="xl"
      className="border border-dark rounded"
      show={show}
      onHide={onToggleModal}
      onExiting={() => setEditing(false)}
      onExited={() => handleResetUserDetailsErrors()}
    >
      <Modal.Header
        className="pb-0 pl-32 bg-light border-0"
        closeLabel="Close"
      >
        <h5>User Details</h5>
        <CloseModalButton onCloseModal={onToggleModal} />
      </Modal.Header>
      <Modal.Body className="bg-light rounded px-32">
        <Form id="userDetails" onSubmit={handleUpdateUserSubmit}>
          <Row>
            <Col lg={6}>
              <Row>
                <Col lg={8}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="title">Title</Form.Label>
                    <Form.Control
                      required
                      disabled={!editing}
                      className="form-control"
                      type="text"
                      id="title"
                      name="title"
                      value={selectedUser.title}
                      onChange={(e) => handleUserDetailsChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                    <Form.Control
                      required
                      disabled={!editing}
                      className="form-control"
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={selectedUser.firstName}
                      onChange={(e) => handleUserDetailsChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                    <Form.Control
                      required
                      disabled={!editing}
                      className="form-control"
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={selectedUser.lastName}
                      onChange={(e) => handleUserDetailsChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <Form.Control
                      required
                      disabled={!editing}
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      isInvalid={selectedUserErrors.email}
                      value={selectedUser.email}
                      onChange={(e) => handleUserDetailsChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">Enter a valid email address</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="phone">Phone</Form.Label>
                    <Form.Control
                      required
                      disabled={!editing}
                      className="form-control"
                      type="text"
                      id="phone"
                      name="phone"
                      isInvalid={selectedUserErrors.phone}
                      value={selectedUser.phone}
                      onChange={(e) => handleUserDetailsChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">Enter a valid 10-digit phone number</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="address">Address</Form.Label>
                    <Form.Control
                      disabled={!editing}
                      type="text"
                      className="form-control"
                      value={selectedUser.address}
                      id="address"
                      name="address"
                      onChange={(e) => handleUserDetailsAddressChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="city">City</Form.Label>
                    <Form.Control
                      disabled={!editing}
                      type="text"
                      className="form-control"
                      value={selectedUser.city}
                      id="city"
                      name="city"
                      onChange={(e) => handleUserDetailsAddressChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="state">State</Form.Label>
                    <br />
                    <Form.Select
                      required
                      disabled={!editing}
                      className="form-control"
                      type="text"
                      id="state"
                      name="state"
                      value={selectedUser.state}
                      onChange={(e) => handleUserDetailsAddressChange(e)}
                    >
                      {ABBREVIATED_STATES.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="zipcode">Zipcode</Form.Label>
                    <Form.Control
                      disabled={!editing}
                      type="text"
                      className="form-control"
                      isInvalid={selectedUserErrors.zipcode}
                      value={selectedUser.zipcode}
                      id="zipcode"
                      name="zipcode"
                      onChange={(e) => handleUserDetailsAddressChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">Enter a valid zipcode</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col>
                  <p className="mb-0">Role</p>
                  <Card className="rounded-0 mt-8 px-16 py-8">
                    <Table size="sm" borderless>
                      <thead>
                        <tr className="border-bottom">
                          <th style={{ width: '33%' }} className="text-center">Full Admin</th>
                          <th style={{ width: '33%' }} className="text-center">Account Admin</th>
                          <th style={{ width: '33%' }} className="text-center">User</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {adminTypes.map(type => (
                            <td key={type.id}>
                              <LargeCheckBox
                                checked={selectedUser.AdminType?.id === type.id}
                                // TODO: Figure out what AdminType is
                                disabled={!editing || userAccountSignal.value.userData.AdminType?.name !== 'Full Admin'}
                                size="sm"
                                onClick={() => handleAdminRoleChange(type.id)}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="mb-8 mt-8">Notes</p>
                  <Card
                    className={`overflow-auto rounded-0 px-16 py-8 ${!editing && 'text-muted'}`}
                    style={{ height: 150 }}
                  >
                    {selectedUser.notes.map(note => {
                      const date = new Date(note.date);
                      return (
                        <p className="mb-0" key={note.id}>
                          {date.toLocaleString('en-US', dateOptions.date)} - {note.note}
                        </p>
                      );
                    })}
                  </Card>
                  <Collapse in={addNote} onEntering={() => stateAddNoteSignal.reset()}>
                    <div>
                      <p className="mb-8 mt-8">New Note</p>
                      <Form.Control
                        style={{ resize: 'none' }}
                        className="form-control"
                        disabled={!editing}
                        as="textarea"
                        value={addNoteForm.note}
                        onChange={(e) => handleAddNoteFormChange(e)}
                      />
                    </div>
                  </Collapse>
                  {addNote &&
                    (
                      <Row className="justify-content-end pr-16">
                        <Button
                          variant="secondary"
                          className="rounded mt-8 mr-8"
                          onClick={() => setAddNote(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="rounded mt-8"
                          onClick={() => handleAddNote(setAddNote)}
                        >
                          Add Note
                        </Button>
                      </Row>
                    )}
                  {!addNote &&
                    (
                      <Row className="justify-content-end pr-16">
                        <Button
                          disabled={!editing}
                          className="rounded mt-8"
                          onClick={() => setAddNote(true)}
                        >
                          New Note
                        </Button>
                      </Row>
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="px-16 justify-content-end">
            {!editing &&
              (
                <>
                  <Button
                    className="rounded mr-8"
                    variant="secondary"
                    onClick={onToggleModal}
                  >
                    Close
                  </Button>
                  <Button
                    className="rounded"
                    onClick={() => setEditing(true)}
                  >
                    Edit
                  </Button>
                </>
              )}
            {editing &&
              (
                <>
                  <Button
                    className="rounded mr-8"
                    variant="secondary"
                    onClick={() => cancelEditing(selectedUser.id, setEditing)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="rounded"
                    type="submit"
                    form="userDetails"
                  >
                    Save
                  </Button>
                </>
              )}
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserDetailsModal;
