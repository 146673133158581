import { businessSettingsSignal } from '../../../../../signals/BusinessSettings.signal';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import {
  handleBusinessFormChange,
  handleSediDemographicsChange,
} from '../BusinessSettings.helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { infoIcon } from '../../../../global/Constant/Icons';
import {
  SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS,
  SEDI_SELF_CERTIFIED,
} from '../../../../global/Constant/businessCompanyInfoDropDowns';
import ModalWrapper from '../../../../global/ModalWrapper/ModalWrapper';
import { useState } from 'react';
import BusinessUnsavedChangesAlert from './BusinessUnsavedChangesAlert';
import Field from 'components/global/Field/Field';
import CloseModalButton from 'components/global/Constant/CloseModalButton';

const BusinessMinorityStatus = () => {
  const businessForm = businessSettingsSignal.value;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Row className="align-items-center px-16">
        <h4 className="mb-0">SEDI Certification</h4>
        <BusinessUnsavedChangesAlert />
      </Row>
      <hr />
      <Row className="mb-16">
        <Col sm={6}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="sediCertification.isSediOwned"
            >
              Do you self-certify as a SEDI Owned Business?{' '}
              <FontAwesomeIcon
                className="text-info"
                icon={infoIcon}
                onClick={() => setShowModal(true)}
              />
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={businessForm['sediCertification.isSediOwned'] === 'yes'}
              label="Yes"
              name="sediCertification.isSediOwned"
              type="radio"
              id="sediCertification.isSediOwnedYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm['sediCertification.isSediOwned'] === 'no'}
              label="No"
              name="sediCertification.isSediOwned"
              type="radio"
              id="sediCertification.isSediOwnedNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea"
            >
              SEDI Status by Business Address in CDFI Investment Area
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={
                businessForm[
                  'sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea'
                ] === 'yes'
              }
              label="Yes"
              name="sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea"
              type="radio"
              id="sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={
                businessForm[
                  'sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea'
                ] === 'no'
              }
              label="No"
              name="sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea"
              type="radio"
              id="sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="sediCertification.selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea"
              className="font-weight-bold"
            >
              Self-certified SEDI Owned and Controlled in CDFI Investment Area
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              type="text"
              id="sediCertification.selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea"
              name="sediCertification.selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea"
              value={
                businessForm[
                  'sediCertification.selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea'
                ]
              }
              onChange={(e) => handleBusinessFormChange(e)}
            >
              <option value="">Select an option</option>
              {SEDI_SELF_CERTIFIED.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="sediCertification.selfCertifiedSEDIFutureLocationInCDFIInvestmentArea"
              className="font-weight-bold"
            >
              Self-certified SEDI Future Location in CDFI Investment Area
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              type="text"
              id="sediCertification.selfCertifiedSEDIFutureLocationInCDFIInvestmentArea"
              name="sediCertification.selfCertifiedSEDIFutureLocationInCDFIInvestmentArea"
              value={
                businessForm[
                  'sediCertification.selfCertifiedSEDIFutureLocationInCDFIInvestmentArea'
                ]
              }
              onChange={(e) => handleBusinessFormChange(e)}
            >
              <option value="">Select an option</option>
              {SEDI_SELF_CERTIFIED.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={12}>
          <p className="mb-8 font-weight-bold">
            Self-Certified SEDI Demographics-Related Business Status (select all
            that apply)
          </p>
          {Object.keys(SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS).map(
            (sediDemographic) => (
              <Field.Checkbox
                key={
                  SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS[sediDemographic]
                    .value
                }
                label={
                  SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS[sediDemographic]
                    .value
                }
                name={
                  SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS[sediDemographic]
                    .value
                }
                value={
                  businessForm[
                    'sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus'
                  ] &&
                  businessForm[
                    'sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus'
                  ].includes(
                    SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS[sediDemographic]
                      .value
                  )
                }
                onChange={handleSediDemographicsChange}
              />
            )
          )}
        </Col>
      </Row>
      <h4 className="mt-48">Minority Status</h4>
      <hr />
      <Row>
        <Col sm={6} xl={3}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="minorityStatus.isMinorityOwned"
            >
              Is business minority owned?
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={businessForm['minorityStatus.isMinorityOwned'] === 'yes'}
              formNoValidate
              label="Yes"
              name="minorityStatus.isMinorityOwned"
              type="radio"
              id="minorityStatus.isMinorityOwnedYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm['minorityStatus.isMinorityOwned'] === 'no'}
              formNoValidate
              label="No"
              name="minorityStatus.isMinorityOwned"
              type="radio"
              id="minorityStatus.isMinorityOwnedNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={
                businessForm['minorityStatus.isMinorityOwned'] === 'decline'
              }
              formNoValidate
              label="Decline to Answer"
              name="minorityStatus.isMinorityOwned"
              type="radio"
              id="minorityStatus.isMinorityOwnedDecline"
              value="decline"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="minorityStatus.isWomanOwned"
            >
              Is business woman owned?
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={businessForm['minorityStatus.isWomanOwned'] === 'yes'}
              label="Yes"
              name="minorityStatus.isWomanOwned"
              type="radio"
              id="minorityStatus.isWomanOwnedYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm['minorityStatus.isWomanOwned'] === 'no'}
              label="No"
              name="minorityStatus.isWomanOwned"
              type="radio"
              id="minorityStatus.isWomanOwnedNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={
                businessForm['minorityStatus.isWomanOwned'] === 'decline'
              }
              label="Decline to Answer"
              name="minorityStatus.isWomanOwned"
              type="radio"
              id="minorityStatus.isWomanOwnedDecline"
              value="decline"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="minorityStatus.isVeteranOwned"
            >
              Is business veteran owned?
            </Form.Label>
            <br />
            <Form.Check
              inline
              label="Yes"
              checked={businessForm['minorityStatus.isVeteranOwned'] === 'yes'}
              name="minorityStatus.isVeteranOwned"
              type="radio"
              id="minorityStatus.isVeteranOwnedYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm['minorityStatus.isVeteranOwned'] === 'no'}
              label="No"
              name="minorityStatus.isVeteranOwned"
              type="radio"
              id="minorityStatus.isVeteranOwnedNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={
                businessForm['minorityStatus.isVeteranOwned'] === 'decline'
              }
              label="Decline to Answer"
              name="minorityStatus.isVeteranOwned"
              type="radio"
              id="minorityStatus.isVeteranOwnedDecline"
              value="decline"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>

      <ModalWrapper
        className="border border-dark rounded"
        size="xl"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="pb-0 bg-light justify-content-end border-0">
          <CloseModalButton
            onCloseModal={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="bg-light rounded pt-0">
          <p>
            Please read the following and certify whether you are SEDI-Owned.
          </p>
          <p className="mb-0">
            Socially and Economically Disadvantaged Individuals (SEDI)-Owned
            Business
            <br />
            1. business enterprises that certify that they are owned and
            controlled by individuals who have had their access to credit on
            reasonable terms diminished as compared to others in comparable
            economic circumstances, due to their:
            <br />
            (1) membership of a group that has been subjected to racial or
            ethnic prejudice or cultural bias within American society;
            <br />
            (2) gender;
            <br />
            (3) veteran status;
            <br />
            (4) limited English proficiency
            <br />
            (5) physical handicap;
            <br />
            (6) long-term residence in an environment isolated from the
            mainstream of American society;
            <br />
            (7) membership of a federally or state recognized Indian Tribe;
            <br />
            (8) long-term residence in a rural community
            <br />
            (9) residence in a U.S. territory;
            <br />
            (10) residence in a community undergoing economic transitions
            (including communities impacted by the shift towards a net-zero
            economy or deindustrialization); or
            <br />
            (11) membership of another &quot;underserved community&quot; as
            defined in U.S. Executive Order 13985;
          </p>
          <p className="ml-5">
            2. business enterprises that certify that they are owned and
            controlled by individuls whose residences are in CDFI Investment
            Areas, as defined in prevailing federal guidelines issued by the
            U.S. Treasury;
            <br />
            3. business enterprises that certify that they will operate a
            location in a CDFI Investment Area, as defined in prevailing federal
            guidelines issued by the U.S. Treasury; or
            <br />
            4. business enterprises that are located in CDFI Investment Areas,
            as defined in prevailing federal guidelines issued by the U.S.
            Treasury.
          </p>
        </Modal.Body>
      </ModalWrapper>
    </>
  );
};

export default BusinessMinorityStatus;
