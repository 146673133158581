import { Alert, Table } from 'react-bootstrap';
import ApprovalChainTemplating from 'components/global/ApprovalChainTemplating/ApprovalChainTemplating';
import { selectedFundingProgramSignal } from 'signals/Program.signal';
import $appSettings from 'signals/AppSettings.signal';

const EditProgramApprovalChainTemplatingTab = () => {
  const { APPROVAL_CHAIN_AMOUNT_BOUNDS } = $appSettings.value.constants;
  const { programInfo } = selectedFundingProgramSignal.value;
  const approvalChainTemplate = programInfo?.approvalChainTemplates[0];
  const thresholds = approvalChainTemplate?.thresholds || [];

  const templateSteps = approvalChainTemplate?.steps || [];
  const templateStepsGroupedByThreshold = thresholds.map(([min, max]) =>
    templateSteps.filter((ts) => ts.min === min && ts.max === max)
  );

  return (
    <div>
      {programInfo && programInfo.approvalChainTemplates.length < 1 ? (
        <Alert variant="info">
          <p className="text-center my-0 py-0">
            There are no approval chain templates for this program.
          </p>
        </Alert>
      ) : (
        <div className="mb-32 text-secondary">
          <h5 className="mb-16">Approval Chain Template</h5>
          {templateStepsGroupedByThreshold.map((tss, index) => {
            const min = tss[0].min;
            const max = tss[0].max;

            let message = `Loans between ${min} and ${max}`;

            if (templateStepsGroupedByThreshold.length === 1) {
              message =
                'This chain will approve every loan regardless of the amount.';
            } else {
              if (min === APPROVAL_CHAIN_AMOUNT_BOUNDS.min) {
                message = `Loans up to ${max - 1}`;
              }

              if (max === APPROVAL_CHAIN_AMOUNT_BOUNDS.max) {
                message = `Loans from ${min} an on`;
              }
            }

            const handledByTheBoard = tss.some((ts) => ts.boardRepresentative);

            return (
              <div key={index}>
                <p>
                  <strong>{message}</strong>
                </p>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      {handledByTheBoard ? (
                        <th>Board Representative</th>
                      ) : (
                        <>
                          <th>Primary Approver</th>
                          <th>Role</th>
                          <th>Alternate Approver</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tss.map((step, index) => (
                      <tr key={step.id}>
                        <td>{index + 1}</td>
                        <td>
                          {step.primaryApprover.firstName}{' '}
                          {step.primaryApprover.lastName}
                        </td>

                        {!handledByTheBoard && <td>{step.role}</td>}

                        {!handledByTheBoard && (
                          <td>
                            {step.alternateApprovers[0] ? (
                              `${step.alternateApprovers[0].user.firstName} ${step.alternateApprovers[0].user.lastName}`
                            ) : (
                              <i>No alternate</i>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            );
          })}
        </div>
      )}
      <h5 className="text-center mb-16 mt-32 text-secondary">
        Update Approval Chain Template
      </h5>
      <ApprovalChainTemplating />
    </div>
  );
};

export default EditProgramApprovalChainTemplatingTab;
