import { Button, Modal } from 'react-bootstrap';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import $systemUseNotification from './systemUseNotification.signal';
import { handleSystemAgreementClick } from './systemUseNotification.helpers';
import AlertSignal from 'signals/Alert.signal';

const SystemUseNotification = () => {
  const { show } = $systemUseNotification.value;

  return (
    <ModalWrapper show={show && AlertSignal.value.type !== 'alert'} centered>
      <Modal.Header>
        <h5>This system is for authorized use only.</h5>
      </Modal.Header>
      <Modal.Body>
        <p>By accessing this system, you agree to the following:</p>
        <p>
          All activity may be monitored and recorded. Unauthorized access or use
          is prohibited. Misuse of the system may result in disciplinary action.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSystemAgreementClick}>
          Agree
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default SystemUseNotification;
