import { Signal } from '@preact/signals-react';
import loaderSignal from '../../../../signals/Loader.signal';
import applicationDetailSignal, {
  ApplicationDetailType,
} from '../../ApplicationDetail/ApplicationDetail.signal';
import { uploadFilesToStorage } from '../../../../libs/functions/global.functions';
import api from '../../../../api/api';
import alertSignal from '../../../../signals/Alert.signal';
import {
  getModelRoute,
  getNotesRoute,
  refreshListAndModal,
} from '../Approvable.helpers';

export const showSendBackApplicationModal = (): Signal<ApplicationDetailType> =>
  applicationDetailSignal.update({ sendBackVisible: true });

export const hideSendBackApplicationModal = (): Signal<ApplicationDetailType> =>
  applicationDetailSignal.update({ sendBackVisible: false });

export const sendApplicationToOppositeParty = async (): Promise<
  Signal<unknown>
> => {
  loaderSignal.update({
    isContentLoading: true,
    message: `Sending back...`,
  });
  try {
    const {
      id,
      modelType,
      sendBackAttachments,
      sendBackNoteBody: content,
    } = applicationDetailSignal.value;

    let signedUrls: string[] = [];

    if (sendBackAttachments.length > 0) {
      const model =
        modelType === 'application'
          ? 'applicationDocument'
          : 'programRequestDocument';

      signedUrls = await uploadFilesToStorage(
        sendBackAttachments.map((a) => a.file),
        model
      );
    }

    const attachments = sendBackAttachments.map((a, index) => ({
      fileType: a.file.type,
      name: a.name,
      filePath: signedUrls[index],
    }));

    if (content !== '') {
      await api.post({
        path: getNotesRoute(modelType, id),
        body: {
          ...(modelType === 'application'
            ? { data: { content, attachments } }
            : { programRequestId: id, content, attachments }),
        },
      });
    } else {
      return alertSignal.update({
        type: 'notification',
        // @ts-ignore
        message: 'Note cannot be blank.',
      });
    }

    const body = {
      where: {
        id: id,
      },
      data: {
        action: 'sendBack',
      },
    };
    await api.patch({
      path: getModelRoute(modelType),
      body,
    });
    await refreshListAndModal(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({
      sendBackNoteBody: '',
      sendBackVisible: false,
      sendBackAttachments: [],
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully sent application.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleAddSendBackModalAttachments = (
  e: React.ChangeEvent<HTMLInputElement>
): Signal<ApplicationDetailType> => {
  const files: File[] = Array.from(e.target.files!);
  const { sendBackAttachments } = applicationDetailSignal.value;

  return applicationDetailSignal.update({
    sendBackAttachments: [
      ...sendBackAttachments,
      ...files.map((file) => ({
        file,
        name: file.name.split('.')[0],
        signedUrl: null,
      })),
    ],
  });
};

export const handleRemoveSendBackModalAttachment = (
  index: number
): Signal<ApplicationDetailType> => {
  const { sendBackAttachments } = applicationDetailSignal.value;
  return applicationDetailSignal.update({
    sendBackAttachments: sendBackAttachments.filter((_, i) => i !== index),
  });
};

export const handleChangeSendBackModalAttachmentName = (
  index: number,
  name: string
): Signal<ApplicationDetailType> => {
  const { sendBackAttachments } = applicationDetailSignal.value;
  return applicationDetailSignal.update({
    sendBackAttachments: sendBackAttachments.map((a, i) => {
      if (i === index) {
        return { ...a, name };
      }

      return a;
    }),
  });
};
