import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import approvalChainTemplatingSignal from './ApprovalChainTemplating.signal';
import { useEffect, useState } from 'react';
import {
  canSubmitApprovalChain,
  fetchAndSetApprovalChainUsers,
  getTakenPrimaryUserIds,
  handleAddApprovalChainApprover,
  handleAddThreshold,
  handleApprovalChainBoardApprovalChange,
  handleApprovalChainFieldChange,
  handleApprovalChainSubmission,
  handleRemoveApprovalChainApprover,
  handleUpdateThreshold,
} from './ApprovalChainTemplating.helpers';
import loaderSignal from 'signals/Loader.signal';
import Field from '../Field/Field';

const ApprovalChainThreshold = ({ handledByBoard, index, templateSteps }) => (
  <>
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>
            {handledByBoard ? 'Board Representative' : 'Primary Approver'}
          </th>
          {!handledByBoard && <th>Role</th>}
          {!handledByBoard && <th>Alternate Approver</th>}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {templateSteps.map((approvalChainTemplateStep, jndex) => (
          <ApprovalChainTemplateStep
            handledByBoard={handledByBoard}
            key={jndex}
            index={index}
            jndex={jndex}
            templateStep={approvalChainTemplateStep}
            templateSteps={templateSteps}
          />
        ))}
      </tbody>
    </Table>
    <div className="text-right">
      <Button
        className="rounded"
        hidden={handledByBoard}
        size="sm"
        variant="outline-info"
        onClick={() => handleAddApprovalChainApprover(index)}
      >
        Approver +
      </Button>
    </div>
    {index === 0 && <hr />}
  </>
);

const buildOption = (user) => ({
  value: user.id,
  label: `${user.firstName} ${user.lastName} - ${user.email}`,
});

const buildPrimaryUserOptions = ({ userId }) => {
  const userIdsAlreadyPresent = getTakenPrimaryUserIds();
  return approvalChainTemplatingSignal.value.users
    .filter(
      (user) => userId === user.id || !userIdsAlreadyPresent.includes(user.id)
    )
    .map((user) => buildOption(user));
};

const buildAlternateUserOptions = ({ userId }) =>
  approvalChainTemplatingSignal.value.users
    .filter((user) => userId !== user.id)
    .map((user) => buildOption(user));

const ApprovalChainTemplateStep = ({
  templateSteps,
  index,
  jndex,
  templateStep,
  handledByBoard,
}) => {
  const [primaryApproverTouched, setPrimaryApproverTouched] = useState(false);
  const [roleTouched, setRoleTouched] = useState(false);

  useEffect(() => {
    if (templateStep.primaryApproverId === '') {
      setPrimaryApproverTouched(false);
    }

    if (templateStep.role === '') {
      setRoleTouched(false);
    }
  }, [templateStep.primaryApproverId, templateStep.role]);

  return (
    <tr key={jndex}>
      <td>{jndex + 1}</td>
      <td>
        <Field.Select
          labelClassName="d-none"
          inputClassName="form-control rounded"
          name="primaryApproverId"
          value={templateStep.primaryApproverId}
          options={buildPrimaryUserOptions({
            userId: templateStep.primaryApproverId || undefined,
          })}
          onChange={({ value }) => {
            setPrimaryApproverTouched(true);
            handleApprovalChainFieldChange(
              'primaryApproverId',
              index,
              jndex,
              value
            );
          }}
        />
        <small
          className="text-danger"
          hidden={
            !(primaryApproverTouched && templateStep.primaryApproverId === '')
          }
        >
          Please select an option
        </small>
      </td>
      {!handledByBoard && (
        <td>
          <Form.Group>
            <Field.TextInput
              labelClassName="d-none"
              inputClassName="rounded"
              name="role"
              placeholder="Enter role"
              value={templateStep.role}
              onChange={({ value }) => {
                setRoleTouched(true);
                handleApprovalChainFieldChange('role', index, jndex, value);
              }}
            />
            <small
              className="text-danger"
              hidden={!(roleTouched && templateStep.role === '')}
            >
              Please fill out this field
            </small>
          </Form.Group>
        </td>
      )}

      {!handledByBoard && (
        <td>
          <Field.Select
            options={buildAlternateUserOptions({
              userId: templateStep.primaryApproverId || undefined,
            })}
            inputClassName="form-control"
            labelClassName="d-none"
            name="alternateApprovers"
            value={templateStep.alternateApprovers[0] || ''}
            onChange={({ value }) =>
              handleApprovalChainFieldChange(
                'alternateApprovers',
                index,
                jndex,
                value
              )
            }
          />
        </td>
      )}
      <td>
        <Button
          className="rounded"
          size="sm"
          variant="outline-secondary"
          hidden={templateSteps.length === 1}
          onClick={() => handleRemoveApprovalChainApprover(index, jndex)}
        >
          Remove
        </Button>
      </td>
    </tr>
  );
};

const ApprovalChainTemplating = ({ creatingProgram }) => {
  const {
    approvalChainTemplateStepsMatrix,
    users,
    thresholdsMatrix,
    oneThresholdActive,
    amount,
  } = approvalChainTemplatingSignal.value;
  const { isContentLoading } = loaderSignal.value;

  useEffect(() => {
    fetchAndSetApprovalChainUsers();
  }, []);

  return (
    <>
      <div
        style={{ width: '720px' }}
        className="d-flex container align-items-center justify-content-center bg-light rounded-lg shadow-sm py-4"
      >
        <Field.Select
          labelClassName="d-none"
          inputClassName="form-control rounded mr-4"
          name="primaryApproverId"
          value={oneThresholdActive}
          options={[
            {
              value: 'false',
              label: 'No threshold',
            },
            {
              value: 'true',
              label: 'One threshold',
            },
          ]}
          onChange={({ value }) => {
            handleApprovalChainFieldChange(
              'oneThresholdActive',
              null,
              null,
              value
            );
          }}
        />
        <Field.Integer
          labelClassName="d-none"
          disabled={oneThresholdActive === 'false'}
          label="Threshold amount"
          placeholder={'Enter threshold amount'}
          value={amount}
          onChange={(e) =>
            handleApprovalChainFieldChange('amount', null, null, e.value)
          }
        />
        {thresholdsMatrix.length === 1 ? (
          <Button
            size={'sm'}
            variant={'outline-primary'}
            className="rounded ml-2"
            disabled={isNaN(parseInt(amount, 10))}
            onClick={() => handleAddThreshold(parseInt(amount, 10))}
          >
            Start
          </Button>
        ) : (
          <Button
            size={'sm'}
            variant={'outline-primary'}
            className="rounded"
            disabled={isNaN(parseInt(amount, 10))}
            onClick={() => handleUpdateThreshold(parseInt(amount, 10))}
          >
            Update
          </Button>
        )}
      </div>
      <Row className="text-secondary">
        <Col>
          {thresholdsMatrix.map((threshold, index) => {
            const firstThreshold = index === 0;
            const lastThreshold = thresholdsMatrix.length - 1 === index;
            const handledByBoard = approvalChainTemplateStepsMatrix[index].some(
              (ts) => !!ts.boardRepresentative
            );

            let message = `Amounts greater than or equal to ${threshold[0]} and less than ${threshold[1]}`;

            if (thresholdsMatrix.length === 1) {
              message =
                'This roster will approve every loan independent of the amount.';
            } else {
              if (firstThreshold) {
                message = `Amounts less than ${threshold[1]}`;
              }

              if (lastThreshold) {
                message = `Amounts greater than or equal to ${threshold[0]}`;
              }
            }

            return (
              <div key={index}>
                <div className="d-flex justify-content-between align-items-center my-24">
                  <div>
                    <strong>
                      <i>{message}</i>
                    </strong>
                  </div>
                  {lastThreshold && (
                    <div className="d-flex">
                      <Field.YesNo
                        value={handledByBoard}
                        label="Handled by board"
                        onChange={(e) =>
                          handleApprovalChainBoardApprovalChange(e.value, index)
                        }
                      />
                    </div>
                  )}
                </div>
                <ApprovalChainThreshold
                  index={index}
                  users={users}
                  templateSteps={approvalChainTemplateStepsMatrix[index]}
                  handledByBoard={handledByBoard}
                />
              </div>
            );
          })}

          <div hidden={creatingProgram} className="text-center">
            <Button
              className="rounded"
              onClick={() => handleApprovalChainSubmission()}
              disabled={!canSubmitApprovalChain() || isContentLoading}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ApprovalChainTemplating;
