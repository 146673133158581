import Signal from 'libs/signals';

const edoFundingDisbursementSignal = Signal({
  record: null,
  createTransactionModalOpen: false,
});

export const edoFundingTransactionAddTransactionFormSignal = Signal({
  amount: '',
  date: undefined,
  time: 0,
  memo: '',
  attachments: [],
});

export default edoFundingDisbursementSignal;
