/* eslint-disable no-unused-vars */
import { Button, Form } from 'react-bootstrap';
import { handleSelectChange } from '../Field';

const MultiSelect = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  selectedVariant = 'dark',
  unselectedVariant = 'outline-dark',
  direction = 'horizontal',
  options,
}) => {
  const handleSelect = ({ label: optionLabel, value: optionVal }) => {
    let valueTmp = [...value];
    const exists = value.find(({ value: valueToFind }) => valueToFind === optionVal);
    if (exists) {
      valueTmp = valueTmp.filter(({ value: existingValue }) => existingValue !== optionVal);
    } else {
      valueTmp.push({ label: optionLabel, value: optionVal });
    }

    handleSelectChange({ name, value: valueTmp }, onChange);
  };

  return (
    <Form.Group className={className}>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <div className={`${direction === 'horizontal' && 'd-flex flex-row'} ${direction === 'vertical' && 'd-flex flex-column'}`}>
        {options.map((option) => {
          const isSelected = value.find(({ value: valueToFind }) => valueToFind === option.value);
          const variant = isSelected ? selectedVariant : unselectedVariant;
          return (
            <Button
              key={option.value}
              role="button"
              variant={variant}
              onClick={() => handleSelect({ label: option.label, value: option.value })}
              className={`${inputClassName}`}
            >
              {option.label}
            </Button>
          );
        })}
      </div>
    </Form.Group>
  );
};

export default MultiSelect;
