import {
  SECURITY_TYPE,
  SSBCI_OWNERSHIP_PERCENTAGE,
  STAGES_OF_INVESTMENT,
  TYPE_OF_VALUATION_CAP,
} from 'components/global/Constant/constants';
import { Col, Row } from 'react-bootstrap';
import { CapitalCallPortion } from 'types';
import Field from '../Field/Field';

type TransactionTerms = CapitalCallPortion['transactionTerms'];

type Props = {
  transactionTerms: Required<TransactionTerms>;
  onChange: (e: { name: keyof TransactionTerms; value: string }) => any;
};

const TransactionTermsForm: React.FC<Props> = ({
  transactionTerms,
  onChange,
}) => {
  const {
    otherSecurityTypeOffered,
    ssbciOwnershipPercentage,
    conversionDiscount,
    securityType,
    typeOfValuationCap,
    valuationCap,
    stageOfInvestment,
    note,
  } = transactionTerms;
  return (
    <div>
      <div className="p-24">
        <Field.Select
          label="Stage of Investment"
          labelClassName="font-weight-bold text-muted"
          inputClassName="form-control"
          value={stageOfInvestment}
          name="stageOfInvestment"
          options={Object.values(STAGES_OF_INVESTMENT).map((stage) => ({
            label: stage,
            value: stage,
          }))}
          onChange={(e: any) => onChange(e)}
          className={undefined}
          onBlur={undefined}
          disabled={false}
          inputRef={null}
          noDefaultOption={undefined}
          lineBreak={undefined}
        />
        <Row className="mt-16">
          <Col xs={12} lg>
            <Field.Select
              label="Security Type"
              labelClassName="font-weight-bold text-muted"
              inputClassName="form-control"
              value={securityType}
              name="securityType"
              options={Object.values(SECURITY_TYPE).map((type) => ({
                label: type,
                value: type,
              }))}
              onChange={(e: any) => onChange(e)}
              className={undefined}
              onBlur={undefined}
              disabled={false}
              inputRef={null}
              noDefaultOption={undefined}
              lineBreak={undefined}
            />
          </Col>
          <Col xs={12} lg>
            {securityType === SECURITY_TYPE.other && (
              <Field.TextInput
                labelClassName="font-weight-bold text-muted"
                label="Other Security Offered"
                value={otherSecurityTypeOffered}
                name="otherSecurityTypeOffered"
                onChange={(e: any) => onChange(e)}
                className="mt-16 mt-lg-0"
                disabled={false}
                inputRef={null}
                isInvalid={undefined}
                onBlur={undefined}
                inputClassName={undefined}
              />
            )}
          </Col>
        </Row>
        {(securityType === SECURITY_TYPE.commonStock ||
          securityType === SECURITY_TYPE.preferredStock) && (
          <Field.Select
            className="mt-16"
            label="SSBCI Ownership Percentage"
            labelClassName="font-weight-bold text-muted"
            inputClassName="form-control"
            value={ssbciOwnershipPercentage}
            name="ssbciOwnershipPercentage"
            options={Object.values(SSBCI_OWNERSHIP_PERCENTAGE).map(
              (option) => ({
                label: option,
                value: option,
              })
            )}
            onBlur={undefined}
            onChange={(e: any) => onChange(e)}
            disabled={false}
            inputRef={null}
            noDefaultOption={undefined}
            lineBreak={undefined}
          />
        )}
        {(securityType === SECURITY_TYPE.convertibleDebt ||
          securityType === SECURITY_TYPE.standardAgreement) && (
          <>
            <Field.Integer
              className="mt-16"
              label="Conversion Discount"
              labelClassName="font-weight-bold text-muted"
              value={conversionDiscount}
              name="conversionDiscount"
              inputClassName={undefined}
              onBlur={undefined}
              placeholder={undefined}
              isInvalid={undefined}
              onChange={(e: any) => onChange(e)}
              inputRef={undefined}
              disabled={false}
            />
            <Row className="mt-16">
              <Col xs={12} lg>
                <Field.Currency
                  label="Valuation Cap"
                  labelClassName="font-weight-bold text-muted"
                  value={valuationCap}
                  name="valuationCap"
                  className={undefined}
                  inputClassName={undefined}
                  onBlur={undefined}
                  isInvalid={undefined}
                  feedbackMessage={undefined}
                  allowCents={undefined}
                  onChange={(e: any) => onChange(e)}
                  disabled={false}
                  inputRef={null}
                />
              </Col>
              <Col xs={12} lg className="mt-16 mt-lg-0">
                <Field.Select
                  label="Type of Valuation Cap"
                  labelClassName="font-weight-bold text-muted"
                  inputClassName="form-control"
                  value={typeOfValuationCap}
                  name="typeOfValuationCap"
                  options={Object.values(TYPE_OF_VALUATION_CAP).map(
                    (option) => ({
                      label: option,
                      value: option,
                    })
                  )}
                  className={undefined}
                  onBlur={undefined}
                  onChange={(e: any) => onChange(e)}
                  disabled={false}
                  inputRef={null}
                  noDefaultOption={undefined}
                  lineBreak={undefined}
                />
              </Col>
            </Row>
          </>
        )}
        <Field.TextArea
          label="Note"
          labelClassName="font-weight-bold text-muted"
          name="note"
          value={note}
          onChange={(e: any) => onChange(e)}
          disabled={false}
          inputClassName={undefined}
          className="mt-16"
        />
      </div>
    </div>
  );
};

export default TransactionTermsForm;
