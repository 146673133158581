import api from 'api/api';
import { stateSelections, tempDB } from 'api/mocks/stateMigrate.mock';
import mockApiCall from 'api/mocks/api.mock';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import { formatEIN } from 'utils/formatTxt';
import $appSettings from 'signals/AppSettings.signal';

const NUM_OF_STEPS = 2;

export const fetchAndSetPrograms = async (setPrograms) => {
  const { currentPortal } = AdminMigrateSignal.value;
  loaderSignal.update({ isContentLoading: true });
  try {
    const { data } = await api.apiFetch({
      path: '/programs',
      method: 'GET',
    });
    setPrograms([{ id: '', title: '', programTypeId: '' }, ...data.filter(u => u.title !== null)]);
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        form: {
          ...AdminMigrateSignal.value[currentPortal].form,
          programs: [...data.filter(u => u.title !== null)],
        },
      },
    });
  } catch (e) {
    alertSignal.update({
      message: 'Failed to retrieve Programs. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetStates = async (setStates) => {
  loaderSignal.update({ isContentLoading: true });
  try {
    // const { data } = await api.apiFetch({
    //   path: '/states',
    //   method: 'GET',
    // });

    const data = await mockApiCall(stateSelections);
    setStates(['', ...data]);
  } catch (e) {
    alertSignal.update({
      message: 'Failed to retrieve States. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetYears = async (setYears) => {
  loaderSignal.update({ isContentLoading: true });
  try {
    // const { data } = await api.apiFetch({
    //   path: '/years',
    //   method: 'GET',
    // });

    const data = await mockApiCall(['2023']);
    setYears(['', ...data]);
  } catch (e) {
    alertSignal.update({
      message: 'Failed to retrieve States. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetPrevMigratedApplications = async (setPrevMigrations) => {
  loaderSignal.update({ isContentLoading: true });
  const { currentPortal } = AdminMigrateSignal.value;

  try {
    const data = await mockApiCall(tempDB);
    setPrevMigrations([...data]);
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        prevMigrations: [...data],
      },
    });
  } catch (e) {
    alertSignal.update({
      message: 'Something went wrong trying to upload the applications. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleInputChange = (e) => {
  const { name, value } = e.target;
  const { currentPortal } = AdminMigrateSignal.value;

  if (name === 'ein') {
    const formattedEIN = formatEIN(value);
    if (!formattedEIN) return;
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        form: {
          ...AdminMigrateSignal.value[currentPortal].form,
          [name]: formattedEIN,
        },
      },
    });
  } else {
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        form: {
          ...AdminMigrateSignal.value[currentPortal].form,
          [name]: value,
        },
      },
    });
  }
};

export const handleFilterInputChange = (e) => {
  const { name, value } = e.target;
  const { currentPortal } = AdminMigrateSignal.value;

  if (name === 'program') {
    const { selectedIndex } = e.target;
    const selectedText = e.target[selectedIndex].text;
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        filters: {
          ...AdminMigrateSignal.value[currentPortal].filters,
          [name]: { id: value, title: selectedText },
        },
      },
    });
  } else {
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        filters: {
          ...AdminMigrateSignal.value[currentPortal].filters,
          [name]: value,
        },
      },
    });
  }
};

export const handleSelection = async (e, programs, setProgramType) => {
  const { name, value, selectedIndex } = e.target;
  const selectedText = e.target[selectedIndex].text;
  const { currentPortal } = AdminMigrateSignal.value;

  const program = programs.find(prg => value === prg.id);
  let programType = {};
  if (program !== undefined) {
    // const { data } = await api.apiFetch({
    //   path: '/programtypes',
    //   method: 'GET',
    //   options: {
    //     where: { id: Number(program.programTypeId) },
    //   },
    // });
    const { data } = await api.apiFetch({
      path: '/programtypes',
      method: 'GET',
    });

    programType = { ...data.find(pt => pt.id === program.programTypeId) };
    setProgramType(programType.name);
  }

  AdminMigrateSignal.update({
    [currentPortal]: {
      ...AdminMigrateSignal.value[currentPortal],
      form: {
        ...AdminMigrateSignal.value[currentPortal].form,
        [name]: { id: value, title: selectedText },
      },
    },
  });
};

export const handleFileCancel = (file) => {
  const { currentPortal } = AdminMigrateSignal.value;
  const { form: { files } } = AdminMigrateSignal.value[currentPortal];
  const idx = files.findIndex(f => f === file);
  const arrCopy = [...files];
  arrCopy.splice(idx, 1);

  AdminMigrateSignal.update({
    [currentPortal]: {
      ...AdminMigrateSignal.value[currentPortal],
      form: {
        ...AdminMigrateSignal.value[currentPortal].form,
        files: [...arrCopy],
      },
    },
  });
};

export const handleUpload = (filesIn) => {
  const { currentPortal } = AdminMigrateSignal.value;
  const { form: { files } } = AdminMigrateSignal.value[currentPortal];

  AdminMigrateSignal.update({
    [currentPortal]: {
      ...AdminMigrateSignal.value[currentPortal],
      form: {
        ...AdminMigrateSignal.value[currentPortal].form,
        files: [...files, ...filesIn],
      },
    },
  });
};

export const handleSearchPrevMigrations = async () => {
  const { currentPortal } = AdminMigrateSignal.value;
  const { filters: { state, program, year } } = AdminMigrateSignal.value[currentPortal];
  const { PORTAL_TYPES } = $appSettings.value.constants;
  loaderSignal.update({ isContentLoading: true });

  try {
    const data = await mockApiCall(tempDB.filter(obj => {
      if (currentPortal === PORTAL_TYPES.state) {
        return obj.state === state && obj.program.id === program.id && obj.year === year;
      }
      return obj.program.id === program.id && obj.year === year;
    }));
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        prevMigrations: [...data],
      },
    });
  } catch (e) {
    alertSignal.update({
      message: 'Failed to retrieve States. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSelectMigratedApplication = (objId) => {
  const { currentPortal } = AdminMigrateSignal.value;
  const selectedData = AdminMigrateSignal.value[currentPortal].prevMigrations.find(obj => obj.id === objId);

  AdminMigrateSignal.update({
    selectedMigratedData: {
      ...selectedData,
    },
  });
};

export const handleClearSelectMigratedApplication = () => {
  AdminMigrateSignal.update({
    selectedMigratedData: {
      id: '',
      step: 1,
      year: '',
      state: '',
      programId: '',
      programTitle: '',
      ein: '',
      files: [],
    },
  });
};

export const handleDeleteMigration = (migrationObj) => { // eslint-disable-line
};

export const handleApplicationsSubmit = async (e) => {
  e.preventDefault();
  loaderSignal.update({ isContentLoading: true });
  const generateId = () => `${Date.now()}`;
  const { currentPortal } = AdminMigrateSignal.value;

  try {
    const data = await mockApiCall(AdminMigrateSignal.value[currentPortal].form);
    const id = generateId();
    data.id = `${id}`;
    tempDB.push(data);
    nextStep();
  } catch (err) {
    alertSignal.update({
      message: 'Something went wrong trying to upload the applications. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const prevStep = () => {
  const { currentPortal } = AdminMigrateSignal.value;
  const { step } = AdminMigrateSignal.value[currentPortal].form;
  if (step > 1) {
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        form: {
          ...AdminMigrateSignal.value[currentPortal].form,
          step: step - 1,
        },
      },
    });
  }
};

export const nextStep = () => {
  const { currentPortal } = AdminMigrateSignal.value;
  const { step } = AdminMigrateSignal.value[currentPortal].form;
  if (step < NUM_OF_STEPS + 1) {
    AdminMigrateSignal.update({
      [currentPortal]: {
        ...AdminMigrateSignal.value[currentPortal],
        form: {
          ...AdminMigrateSignal.value[currentPortal].form,
          step: step + 1,
        },
      },
    });
  }
};

export const setPortalType = (portalType) => {
  AdminMigrateSignal.update({ currentPortal: portalType });
};

export const reset = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;

  AdminMigrateSignal.update({
    [PORTAL_TYPES.state]: {
      form: {
        step: 1,
        year: '',
        state: '',
        program: { id: '', tilte: '' },
        ein: '',
        files: [],
      },
      prevMigrations: [],
      filters: {
        year: '',
        state: '',
        program: { id: '', title: '' },
      },
    },
    [PORTAL_TYPES.edo]: {
      form: {
        step: 1,
        year: '',
        state: '',
        program: { id: '', tilte: '' },
        ein: '',
        files: [],
      },
      prevMigrations: [],
      filters: {
        year: '',
        program: { id: '', title: '' },
      },
    },
  });
};

export const exitAndReset = (history) => {
  history.push('/state');
  reset();
};
