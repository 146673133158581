import Signal from 'libs/signals';

const stateUsersSignal = Signal({
  stateUsers: [],
  selectedUser: {
    id: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    title: '',
    address: {
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
    status: false,
    lastSignedIn: new Date(),
    created: new Date(),
    accountTerritories: [],
    userType: {
      id: '',
      name: '',
    },
    notes: [],
    lastSavedBy: {
      name: '',
      date: new Date(),
    },
    logs: [],
  },
  selectedUserErrors: {
    phone: false,
    email: false,
    zipcode: false,
  },
  accountDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
  },
  accountDetailsErrors: {
    email: false,
    phone: false,
    zipcode: false,
  },
});

export default stateUsersSignal;
