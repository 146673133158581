import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import ledgerSignal, { ledgerAddReimbursementSignal } from './Ledger.signals';
import {
  handleAddReimbursementFormChange,
  handleSubmitReimbursement,
  hideAddReimbursementModal,
} from './Ledger.helpers';
import CloseModalButton from '../Constant/CloseModalButton';

const LedgerAddRecycledFundsModal = () => {
  const { createReimbursementModalOpen } = ledgerSignal.value;

  const { amount, dueDate } = ledgerAddReimbursementSignal.value;

  return (
    <ModalWrapper show={createReimbursementModalOpen} centered size="xl" onHide={hideAddReimbursementModal}>
      <ModalHeader>
        <ModalTitle>Add Recycled Funds</ModalTitle>
        <CloseModalButton onCloseModal={hideAddReimbursementModal} />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="amount"
                placeholder="Enter the amount"
                value={amount}
                onChange={handleAddReimbursementFormChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Due Date
              </Form.Label>
              <Form.Control
                type="date"
                value={dueDate}
                name="dueDate"
                onChange={handleAddReimbursementFormChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideAddReimbursementModal()}
        >
          Dismiss
        </Button>
        <Button
          className="rounded"
          disabled={[amount, dueDate].some((e) => !e)}
          onClick={() => handleSubmitReimbursement()}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default LedgerAddRecycledFundsModal;
