import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Modal } from 'react-bootstrap';
import edoTechnicalAssistanceSignal from '../edoTechnicalAssistance.signal';
import {
  TECHNICAL_ASSISTANCE_MODALS,
  deleteTechnicalAssistance,
  handleCloseTechnicalAssistanceModal,
  handleTechnicalAssistanceSaveClick,
} from '../edoTechnicalAssistance.helpers';
import TechnicalAssistanceInputs from './TechnicalAssistanceInputs';

const NewTechnicalAssistanceModal = () => {
  const { modalType, deleteTechnicalAssistancePromptVisible } =
    edoTechnicalAssistanceSignal.value;

  return (
    <ModalWrapper
      centered
      show={modalType === TECHNICAL_ASSISTANCE_MODALS.create || modalType === TECHNICAL_ASSISTANCE_MODALS.edit}
      size="lg"
      onHide={handleCloseTechnicalAssistanceModal}
    >
      <Modal.Header>
        <h5>New Technical Assistance</h5>
        <CloseModalButton onCloseModal={handleCloseTechnicalAssistanceModal} />
      </Modal.Header>
      <Modal.Body>
        <TechnicalAssistanceInputs />

        {deleteTechnicalAssistancePromptVisible ? (
          <div>
            <p className="mb-8 mt-24">
              Are you sure you want to delete this technical assistance? If it has been sent to business owners, they will no longer be able to view it.
            </p>
            <div className="mt-24 d-flex flex-row justify-content-end">
              <Button
                variant="outline-secondary"
                className="rounded mr-8"
                onClick={() =>
                  edoTechnicalAssistanceSignal.update({
                    deleteTechnicalAssistancePromptVisible: false,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                className="rounded"
                variant="danger"
                onClick={deleteTechnicalAssistance}
              >
                Confirm
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-24 d-flex flex-row justify-content-end">
            <Button
              hidden={modalType === TECHNICAL_ASSISTANCE_MODALS.create}
              variant="outline-danger"
              className="rounded mr-8"
              onClick={() =>
                edoTechnicalAssistanceSignal.update({
                  deleteTechnicalAssistancePromptVisible: true,
                })
              }
            >
              Delete
            </Button>
            <Button
              className="rounded"
              onClick={handleTechnicalAssistanceSaveClick}
            >
              Save
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={handleCloseTechnicalAssistanceModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default NewTechnicalAssistanceModal;
