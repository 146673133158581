import { INDUSTRY_TYPES_BY_NAICS_CODE } from "components/global/Constant/constants";

export function getIndustryType(naicsCode) {
  if (!naicsCode) {
    return 'Unknown';
  }

  const codePrefix = naicsCode.substring(0, 2);
  const industryTypes = INDUSTRY_TYPES_BY_NAICS_CODE;

  if (industryTypes.hasOwnProperty(codePrefix)) {
    return industryTypes[codePrefix];
  } else {
    return 'Unknown';
  }
};