import { Button } from "react-bootstrap";
import { useEffect } from "react";
import SimpleTable from "components/global/SimpleTable";
import capitalizeFirst from "utils/capitalizeFirst";
import HTMLRenderer from "components/global/HTMLRenderer";
import { ensureHttpsInHrefs } from "libs/string";
import PaginationItems from "components/global/Pagination";
import { useLocation } from "react-router-dom";
import edoTooltipsSignal from "./edoTooltips.signal";
import { fetchAndSetTooltips, handleShowCreateTooltipsModal, handleShowEditTooltipsModal } from "./edoTooltips.helpers";
import TooltipsModal from "./components/TooltipsModal";
import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";

const HEADERS = [
  {
    key: 'title',
    title: 'Title',
  },
  {
    key: 'supportingText',
    title: 'Supporting Text',
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const EdoTooltips = () => {
  const { tooltips, totalPages } = edoTooltipsSignal.value;
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const currentPage = searchParams.page ? Number(searchParams.page) : 1;

  useEffect(() => fetchAndSetTooltips(currentPage), [location.search]);

  return (
    <BreadCrumbWrapper>
      <TooltipsModal />
      <div className="border border-solid border-gray p-24 bg-white shadow-sm">
        <h3 className="text-center">Tooltips</h3>
        <hr />
        <div className="d-flex flex-row justify-content-end mb-16">
          <Button className="rounded" onClick={handleShowCreateTooltipsModal}>
            New Tooltip
          </Button>
        </div>
        <SimpleTable
          headers={HEADERS}
          rows={tooltips.map((tooltip) => ({
            title: tooltip.title,
            supportingText: (key) => (
              <td key={key}>
                <HTMLRenderer
                  className="mb-0"
                  htmlString={ensureHttpsInHrefs(tooltip.supportingText)}
                  htmlStringClassName="mb-0"
                  maxLength={50}
                />
              </td>
            ),
            type: capitalizeFirst(tooltip.type),
            action: (key) => (
              <td key={key} align="right">
                <Button
                  className="rounded"
                  size="sm"
                  variant="outline-dark"
                  onClick={() => handleShowEditTooltipsModal(tooltip)}
                >
                  Details
                </Button>
              </td>
            ),
          }))}
        />
        {totalPages > 1 && (
          <PaginationItems
            className="justify-content-center mt-24"
            totalPages={totalPages}
          />
        )}
      </div>
    </BreadCrumbWrapper>
  );
};

export default EdoTooltips;
