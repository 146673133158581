export const paymentAccounts = [
  {
    id: 1,
    accountName: 'Bank of America',
    accountNumber: '123456789098',
    status: 'Pending',
    actions: 'Pending Verification',
    linkedPrograms: [
      {
        id: 1,
        name: 'Program 1',
      },
      {
        id: 2,
        name: 'Program 2',
      },
      {
        id: 3,
        name: 'Program 3',
      },
    ],
  },
];

export default paymentAccounts;
