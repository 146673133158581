import Signal from 'libs/signals';

const linkAccountFormSignal = Signal({
  bankInfo: {
    id: Math.floor(Math.random() * 100),
    accountName: '',
    firstName: '',
    lastName: '',
    routingNumber: '',
    accountNumber: '',
    actions: 'Pending Verification',
    status: 'Pending',
    linkedPrograms: [],
  },
  creditCardInfo: {
    id: Math.floor(Math.random() * 100),
    accountName: '',
    nameOnCard: '',
    accountNumber: '',
    securityCode: '',
    expirationDate: '',
    actions: 'Pending Verification',
    status: 'Pending',
    linkedPrograms: [],
  },
});

export default linkAccountFormSignal;
