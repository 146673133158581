import { Fragment, useEffect } from 'react';
import applicationDetailSignal from '../../ApplicationDetail.signal';
import {
  handleApprovedInputFieldChange,
  mapOcrValuesToConfirmStatusInputValues,
} from '../../ApplicationDetail.helpers';
import dateOptions from 'components/global/Constant/dateOptions';
import { SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS } from 'libs/applications';
import { FieldComponent } from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const ApplicationConfirmStatusApplicationFields = () => {
  const {
    fundingProgramStats,
    fundingProgram,
    newApplicationStatus,
    id,
    approvedInputFieldsErrors,
    approvedInputFieldsDirty,
    requestedAmount,
    status,
    approvalChainConfig,
  } = applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;


  const {
    referenceProgram: { key },
  } = fundingProgram;

  useEffect(() => {
    mapOcrValuesToConfirmStatusInputValues();
  }, [id]);

  const hideFields =
    newApplicationStatus === APPLICATION_STATUS.submittedForApproval ||
    (status === APPLICATION_STATUS.approved && !approvalChainConfig);

  return (
    <>
      <p hidden={hideFields}>
        Please confirm you want to update the application status to{' '}
        <b>{newApplicationStatus.toLowerCase().replace(/_/g, ' ')}</b>
      </p>
      <p className="mt-16 mb-8">
        <b>Requested Amount</b>
      </p>
      <p>
        {requestedAmount?.toLocaleString(
          'en-US',
          dateOptions.currencyUSNoDecimal
        ) || '-'}
      </p>
      <p className="mt-16 mb-8">
        <b>Remaining Funds</b>
      </p>
      <p>
        {fundingProgramStats?.remaining.toLocaleString(
          'en-US',
          dateOptions.currencyUSNoDecimal
        ) || '-'}
      </p>
      {hideFields &&
        SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS[key]?.map((field) => (
          <Fragment key={field.name}>
            <FieldComponent
              label={field.label}
              labelClassName="my-8 font-weight-bold"
              className={field.className}
              inputClassName={field.inputClassName}
              variant={field.variant}
              name={field.name}
              value={
                // we're allowing this field to be non-negative for now
                field.name === 'borrowerEquity'
                  ? field.value ||
                    applicationDetailSignal.value.approvedInputFields[
                      field.name
                    ]
                  : field.value ||
                    applicationDetailSignal.value.approvedInputFields[
                      field.name
                    ] ||
                    ''
              }
              type={field.type}
              disabled={field.disabled}
              onChange={(e) =>
                handleApprovedInputFieldChange(e, field.isCurrency)
              }
              onClick={field.onClick}
              onBlur={field.onBlur}
              isInvalid={approvedInputFieldsErrors[field.name]}
            />
            <small className="text-danger">
              {approvedInputFieldsDirty[field.name] &&
                approvedInputFieldsErrors[field.name]}
            </small>
          </Fragment>
        ))}
    </>
  );
};

export default ApplicationConfirmStatusApplicationFields;
