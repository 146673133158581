import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import prevNextTabButtonsSignal from 'signals/prevNextTabButtons.signal';
import BasicProgramInfo from '../EdoAddProgram/components/BasicProgramInfo';
import {
  fetchAndSetEnrolledOrganizations,
  fetchAndSetProgram,
  handleAddSuggestedDocument,
  handleDocumentUploadInputFocusOut,
  handleEditProgramFileRemoval,
  handleEditProgramFileUpdate,
  handleInputChange,
  handleInputFocusOut,
  submitNewFundingProgramDocuments,
  updateFundingProgramBasicInfo,
} from './EdoProgramDetail.helpers';
import ProgramSignal, {
  fundingProgramFormErrorsSignal,
  selectedFundingProgramSignal,
} from 'signals/Program.signal';
import loaderSignal from 'signals/Loader.signal';
import EdoAddProgramDocumentForm from '../EdoAddProgram/components/EdoAddProgramDocumentForm';
import EditProgramApprovalChainTemplatingTab from '../EdoPrograms/components/EditProgramApprovalChainTemplatingTab';
import { useLocation } from 'react-router';
import { useCallback, useEffect } from 'react';
import { EDIT_PROGRAM_MODAL_EVENT_MAP } from '../../../global/Constant/tabEventKeys';
import EnrolledOrganizationsList from './components/EnrolledOrganizationsList';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';

const EdoProgramDetail = ({ modalView, hideBreadCrumbs }) => {
  const { pathname } = useLocation();
  const { programInfo } = selectedFundingProgramSignal.value;
  const { activeKey } = prevNextTabButtonsSignal.value;
  const fundingProgramId = pathname.split('/')[pathname.split('/').length - 1];

  const init = useCallback(async () => {
    await fetchAndSetProgram(fundingProgramId);
    await fetchAndSetEnrolledOrganizations();
  }, []);

  useEffect(() => {
    if (pathname !== '/edo/programs') {
      init();
    } else {
      fetchAndSetEnrolledOrganizations();
    }

    return () => {
      if (pathname !== '/edo/programs') {
        selectedFundingProgramSignal.reset();
        fundingProgramFormErrorsSignal.reset();
      }
    };
  }, [pathname]);

  return (
    <BreadCrumbWrapper
      hideBreadCrumbs={hideBreadCrumbs}
      labels={['', programInfo?.name]}
    >
      <Row className="align-items-center">
        <Col>
          <Tabs
            mountOnEnter
            activeKey={activeKey}
            onSelect={(e) => prevNextTabButtonsSignal.update({ activeKey: e })}
          >
            <Tab
              eventKey={EDIT_PROGRAM_MODAL_EVENT_MAP.basicInfo}
              title="Basic Info"
            >
              <div
                className={
                  pathname === '/edo/programs'
                    ? 'border-0'
                    : 'border border-gray shadow-sm bg-white p-32'
                }
              >
                <BasicProgramInfo
                  handleInputChange={handleInputChange}
                  programInfo={programInfo || {}}
                  programTypes={ProgramSignal.value.programTypes}
                  administrators={[]}
                  platforms={[]}
                  handleInputFocusOut={handleInputFocusOut}
                />
                <Row className="justify-content-center mt-16">
                  <Button
                    className="rounded"
                    onClick={() => updateFundingProgramBasicInfo()}
                    disabled={loaderSignal.value.isContentLoading}
                  >
                    Save
                  </Button>
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey={EDIT_PROGRAM_MODAL_EVENT_MAP.applicationDocuments}
              title="Application Documents"
            >
              <div
                className={
                  pathname === '/edo/programs'
                    ? 'border-0'
                    : 'border border-gray shadow-sm bg-white p-32'
                }
              >
                <EdoAddProgramDocumentForm
                  programInfo={programInfo || {}}
                  supportingDocuments={programInfo?.supportingDocuments || []}
                  handleFileChange={handleEditProgramFileUpdate}
                  handleFileRemoval={handleEditProgramFileRemoval}
                  programTypes={ProgramSignal.value.programTypes || []}
                  handleDocumentUploadInputFocusOut={
                    handleDocumentUploadInputFocusOut
                  }
                  handleAddSuggestedDocument={handleAddSuggestedDocument}
                  handleInlineSave={submitNewFundingProgramDocuments}
                />
              </div>
            </Tab>
            <Tab
              eventKey={EDIT_PROGRAM_MODAL_EVENT_MAP.approvalChain}
              title="Approval Chain"
            >
              <div
                className={
                  pathname === '/edo/programs'
                    ? 'border-0'
                    : 'border border-gray shadow-sm bg-white p-32'
                }
              >
                <EditProgramApprovalChainTemplatingTab />
              </div>
            </Tab>
            <Tab
              eventKey={EDIT_PROGRAM_MODAL_EVENT_MAP.enrolledOrganizations}
              title="Enrolled Organizations"
            >
              <div
                className={
                  pathname === '/edo/programs'
                    ? 'border-0'
                    : 'border border-gray shadow-sm bg-white p-32'
                }
              >
                <EnrolledOrganizationsList modalView={modalView} />
              </div>
            </Tab>
            {/*<Tab eventKey="bankAccounts" title="Bank Accounts">*/}
            {/*  <EditProgramBankAccountAccess />*/}
            {/*</Tab>*/}
            {/* <Tab eventKey="userAccess" title="User Access">
                  <EditUserAccess />
                </Tab> */}

            {/* TODO: edit program approval chain needs to be updated with the new flow */}
            {/* <Tab eventKey="approvalChain" title="Approval Chain">
                  <EditProgramApprovalChain />
                  <Row className="justify-content-center">
                    <Button
                      onClick={updateFundingProgramApprovalChain}
                      disabled={
                        !approvalChainIsInvalid(programInfo) ||
                        updatingProgramSignal.value
                      }
                    >
                      Save
                    </Button>
                  </Row>
                </Tab> */}
          </Tabs>
        </Col>
      </Row>
    </BreadCrumbWrapper>
  );
};

export default EdoProgramDetail;
