import ApplicationDetail from 'components/global/ApplicationDetail/ApplicationDetail';
import { useCallback, useEffect } from 'react';
import { fetchApplication } from 'components/global/ApplicationDetail/ApplicationDetail.helpers';
import { useLocation } from 'react-router-dom';
import BreadCrumbWrapper from '../../../global/BreadCrumbs/BreadCrumbs';
import applicationDetailSignal from 'components/global/ApplicationDetail/ApplicationDetail.signal';

const LenderPortfolioDetail = () => {
  const { pathname } = useLocation();
  const applicationId = pathname.split('/')[pathname.split('/').length - 1];
  const { business } = applicationDetailSignal.value;
  const labels = ['', !!business ? business.name : ''];

  const init = useCallback(async () => {
    await fetchApplication(applicationId);
  });

  useEffect(() => {
    init();
  }, [applicationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <ApplicationDetail />
    </BreadCrumbWrapper>
  );
};

export default LenderPortfolioDetail;
