import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import submitApplicationSignal, {
  applicationDraftSignal,
} from 'signals/SubmitApplication.signal';
import {
  handleAttachAdditionalFile,
  handleAttachFile,
  handleCancelAdditionalFile,
  handleChangeAdditionalFileName,
  handleDownloadDocument,
  handleFileCancel,
  nextStep,
  prevStep,
} from '../SubmitApplications.helpers';
import FilePreviewButton from '../../utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { isFileTypeValidForPreview } from '../../../../utils/checkFileTypeForPreview';
import FileDropZoneWrapper from '../../FileDropZone';
import { APPLICATION_FORMATS } from '../../Constant/fileFormats';
import DragAndDrop from '../../DragAndDrop/DragAndDrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadIcon } from 'components/global/Constant/Icons';
import $appSettings from 'signals/AppSettings.signal';

export const SubmitApplicationAdditionalDocuments = () => {
  const { filesMap, fundingProgram, additionalFilesList } =
    submitApplicationSignal.value;
  const { DOCUMENT_TYPES } = $appSettings.value.constants;
  const { selectedDraft } = applicationDraftSignal.value;
  const { supportingDocuments } = fundingProgram;
  const suggestedDocuments = supportingDocuments.filter(
    (doc) => doc.documentType === DOCUMENT_TYPES.applicationSuggested
  );

  return (
    <>
      <Row className="mb-32">
        <Col>
          <h4>Application Supplementary Documentation</h4>
        </Col>
      </Row>
      <Row className="mt-32">
        <Col>
          <h5>Suggested documents</h5>
          <p hidden={suggestedDocuments.length === 0}>
            The EDO recommends these documents be uploaded to expedite the
            process.
          </p>
          <p hidden={suggestedDocuments.length > 0}>
            The EDO is not asking for other documents besides its application
            form.
          </p>
          <Table hidden={suggestedDocuments.length <= 0}>
            <thead>
              <tr className="font-weight-bold">
                <th style={{ width: '25%' }}>Document</th>
                <th style={{ width: '25%' }}>Attach</th>
                <th style={{ width: '25%' }}>File</th>
                <th style={{ width: '25%' }}>Download</th>
              </tr>
            </thead>
            {suggestedDocuments.map((doc, index) => (
              <tbody key={index}>
                <tr>
                  <td>{doc.name}</td>
                  <td>
                    <input
                      type="file"
                      hidden
                      name={doc.id}
                      id={`file-uploader-hidden-input-${doc.id}`}
                      onChange={(e) =>
                        handleAttachFile(e.target.name, e.target.files[0])
                      }
                    />
                    <Button
                      size="sm"
                      className="rounded"
                      variant="outline-primary"
                      onClick={() =>
                        document
                          .getElementById(
                            `file-uploader-hidden-input-${doc.id}`
                          )
                          .click()
                      }
                    >
                      {filesMap[doc.id] ? 'Replace' : 'Attach'}
                    </Button>
                  </td>
                  <td>
                    {filesMap[doc.id] ? (
                      <>
                        <FilePreviewButton
                          render={(submitting, f) => (
                            <Button
                              hidden={
                                !isFileTypeValidForPreview(
                                  filesMap[doc.id]?.fileType
                                )
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-dark"
                              onClick={() =>
                                f(
                                  filesMap[doc.id].filePath
                                    ? {
                                        ...filesMap[doc.id],
                                        parentId: selectedDraft.id,
                                      }
                                    : filesMap[doc.id].file,
                                  'applicationDocuments'
                                )
                              }
                            >
                              Preview
                            </Button>
                          )}
                        />
                        <Button
                          size="sm"
                          className="rounded ml-2"
                          variant="outline-secondary"
                          onClick={() => handleFileCancel(doc.id)}
                        >
                          Remove
                        </Button>
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {doc.filePath ? (
                      <Button
                        size="sm"
                        variant="outline-info"
                        className="rounded"
                        onClick={() => handleDownloadDocument(doc.id)}
                      >
                        Download Template{' '}
                        <FontAwesomeIcon icon={downloadIcon} />
                      </Button>
                    ) : (
                      <p>Template not provided</p>
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Additional documents</h5>
          <p>Add any documents that will support your application here.</p>
          <FileDropZoneWrapper
            formats={APPLICATION_FORMATS}
            numOfUploadsAllowed={20}
            maxSizeMB={100}
            onUpload={(files) =>
              files.forEach((file) => handleAttachAdditionalFile(file))
            }
          >
            <DragAndDrop
              files={additionalFilesList}
              uploadProgress={0}
              // onFileCancel={handleFileCancel}
              maxSizeMB={100}
              preview={false}
            />
          </FileDropZoneWrapper>
          <Table>
            <thead>
              <tr className="font-weight-bold">
                <th>Document</th>
                <th>Attach</th>
                <th>File</th>
              </tr>
            </thead>
            {additionalFilesList.map((fileBundle, index) => (
              <tbody key={index}>
                <tr>
                  <td>
                    <Form.Control
                      value={fileBundle.name}
                      onChange={(e) =>
                        handleChangeAdditionalFileName(e.target.value, index)
                      }
                    />
                  </td>
                  <td>-</td>
                  <td>
                    <>
                      <FilePreviewButton
                        render={(submitting, f) => (
                          <Button
                            hidden={
                              !isFileTypeValidForPreview(fileBundle.fileType)
                            }
                            size="sm"
                            className="rounded"
                            variant="outline-dark"
                            onClick={() =>
                              f(
                                fileBundle.filePath
                                  ? {
                                      ...fileBundle,
                                      parentId: selectedDraft.id,
                                    }
                                  : fileBundle.file,
                                'applicationDocuments'
                              )
                            }
                          >
                            Preview
                          </Button>
                        )}
                      />
                      <Button
                        size="sm"
                        className="rounded ml-2"
                        variant="outline-secondary"
                        onClick={() => handleCancelAdditionalFile(index)}
                      >
                        Remove
                      </Button>
                    </>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-info font-weight-bold text-center">
            {submitApplicationSignal.value.backingUpAdditionalFiles
              ? 'Backing up files...'
              : ''}
          </p>
        </Col>
      </Row>
      <Row className="my-64">
        <Col className="text-center">
          <Button className="rounded" onClick={prevStep}>Prev Page</Button>
        </Col>
        <Col className="text-center">
          <Button className="rounded" onClick={nextStep}>Next Page</Button>
        </Col>
      </Row>
    </>
  );
};

export default SubmitApplicationAdditionalDocuments;
