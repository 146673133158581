import Signal from 'libs/signals';
import {
  ApplicationApprovalChainConfig,
  ApplicationApprovalChainConfirmation,
  ApplicationChainblock,
  ApplicationDocument,
  ApplicationNote,
  ApprovalChainTemplate,
  Business,
  Disbursement,
  FundingProgram,
  Lender,
  ProgramRequestDocument,
  ProgramRequestLog,
  ProgramRequestNote,
  TechnicalAssistance,
  User,
} from 'types';

export type ApplicationDetailType = {
  id: number | null;
  visible: boolean;
  ventureCapitalist: boolean;
  lender: Pick<Lender, 'name' | 'contactName' | 'phoneNumber' | 'email' | 'id'> | null;
  business: Partial<
    Pick<Business, 'name' | 'contactName' | 'phoneNumber' | 'id'> & { email: string }
  > | null;
  status: string;
  watchListStatus: string | null;
  documents: Array<ApplicationDocument | ProgramRequestDocument>;
  fundingProgramStats: FundingProgram['stats'] | null;
  fundingProgram: FundingProgram | null;
  logs: ProgramRequestLog[];
  notes: ApplicationNote[] | ProgramRequestNote[];
  sendBackVisible: boolean;
  newApplicationStatus: string;
  newWatchListStatus: string | null | undefined;
  watchableId: number | null;
  sendBackNoteBody: string;
  sendBackAttachments: { name: string; file: File; signedUrl: string | null }[];
  modelType: 'application' | 'programRequest' | null;
  fileToSubmit: Partial<{
    name: string;
    file: File;
    fundingProgramDocumentId?: number | null;
  }>;
  uploadDocumentType: 'applicationDocuments' | 'programRequestDocuments' | null;
  lenderUnread: boolean;
  edoUnread: boolean;
  confirmStatusVisible: boolean;
  approvedInputFields: Record<string, string | number | Date>;
  approvedInputFieldsErrors: Record<string, string | boolean>;
  approvedInputFieldsDirty: Record<string, boolean>;
  requestedAmount: number;
  applicationConfig: Record<string, unknown>;
  suggestedDocumentNames: string[];
  additionalDocumentNames: string[];
  additionalDocumentsToUpload: { name: string; file: File; type: string }[];
  formErrors: Record<string, boolean>;
  approvalAttachmentsToUpload: { name: string; file: File }[];
  approvalAttachments: ApplicationDocument[];
  approvalChainTemplate: Pick<ApprovalChainTemplate, 'id' | 'steps'> | null;
  approvalChainTemplates: ApprovalChainTemplate[];
  approvalChainConfirmations: ApplicationApprovalChainConfirmation[];
  fileUploadConfirmationModalVisible: boolean;
  fileUploadConfirmationType: string;
  removeFileConfirmationModalVisible: boolean;
  removeFileId: number | string | null;
  approvalChainForm: {
    skippedSteps: number[];
    fallbackToAlternatesSteps: Record<string, number[]>;
    keyDates: Record<string, Date>;
  };
  submittedByEdo: boolean;
  approvedOnCommit: boolean;
  approvalChainConfig: ApplicationApprovalChainConfig | null;
  approvalChainUsers: User[];
  blockchain: ApplicationChainblock[];
  disbursements: Disbursement[];
  createdDate: string;
  updatedDate: string;
  edoUnreadDate: string | null;
  hasLinkedBankAccount: boolean;
  usesCaatPayment: boolean;
  disbursementDate: string | null;
  disbursementAmount: number | null;
  approvedAmount: number | null;
  firstRepaymentDate: string | null;
  disbursementConfig: Record<string, number>;
  currentTab: string;
  formData: any;
  rawOcr: Record<string, unknown> | null;
  technicalAssistance: TechnicalAssistance | null;
  revokeApprovalChainApprovalVisible: boolean;
};

const applicationDetailSignal = Signal<ApplicationDetailType>({
  firstRepaymentDate: null,
  approvedAmount: null,
  fundingProgramStats: null,
  fundingProgram: null,
  business: null,
  lender: null,
  visible: false,
  id: null,
  status: '',
  watchListStatus: null,
  ventureCapitalist: false,
  documents: [],
  logs: [],
  notes: [],
  sendBackVisible: false,
  newApplicationStatus: '',
  newWatchListStatus: '',
  watchableId: null,
  sendBackNoteBody: '',
  sendBackAttachments: [],
  modelType: null,
  fileToSubmit: {},
  uploadDocumentType: null,
  lenderUnread: false,
  edoUnread: false,
  confirmStatusVisible: false,
  approvedInputFields: {},
  approvedInputFieldsErrors: {},
  approvedInputFieldsDirty: {},
  requestedAmount: 0,
  applicationConfig: {},
  suggestedDocumentNames: [],
  additionalDocumentNames: [],
  additionalDocumentsToUpload: [],
  disbursementDate: null,
  disbursementAmount: null,
  formErrors: {},
  formData: {},
  approvalAttachmentsToUpload: [],
  approvalAttachments: [],
  approvalChainTemplate: null,
  approvalChainConfirmations: [],
  approvalChainTemplates: [],
  fileUploadConfirmationModalVisible: false,
  fileUploadConfirmationType: '',
  removeFileConfirmationModalVisible: false,
  removeFileId: null,
  approvalChainForm: {
    skippedSteps: [],
    fallbackToAlternatesSteps: {},
    keyDates: {},
  },
  submittedByEdo: false,
  approvedOnCommit: false,
  approvalChainConfig: null,
  approvalChainUsers: [],
  blockchain: [],
  disbursements: [],
  createdDate: '',
  updatedDate: '',
  edoUnreadDate: null,
  hasLinkedBankAccount: false,
  usesCaatPayment: false,
  disbursementConfig: {},
  currentTab: 'general',
  rawOcr: null,
  technicalAssistance: null,
  revokeApprovalChainApprovalVisible: false,
});

export default applicationDetailSignal;
