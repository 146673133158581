import { Badge, Modal, Table } from 'react-bootstrap';
import dateOptions from '../Constant/dateOptions';
import { AmortizationPayment, Loan, LoanPayment } from '../../../types';
import { useState } from 'react';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import CloseModalButton from '../Constant/CloseModalButton';
import { DateTime } from 'luxon';

type Props = {
  loan: Loan | null;
  schedule: AmortizationPayment[];
};

type LoanPaymentModalProps = {
  payments: LoanPayment[];
  onHide: () => any;
};

const LoanPaymentsModal: React.FC<LoanPaymentModalProps> = ({
  payments,
  onHide,
}) => (
  <ModalWrapper
    show={payments.length > 0}
    onHide={onHide}
    size="xl"
    centered
    backdropClassName={null}
    onExit={null}
    onExited={null}
  >
    <Modal.Header className="pb-0 border-0">
      <h3>Payments in term</h3>
      <CloseModalButton onCloseModal={() => onHide()} />
    </Modal.Header>
    <Modal.Body>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Amount</th>
            <th>Payment Date</th>
            <th>Recorded Date</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {payment.amount.toLocaleString('en-US', dateOptions.currencyUS)}
              </td>
              <td>{payment.date.split('T')[0]}</td>
              <td>{payment.createdDate.split('T')[0]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal.Body>
  </ModalWrapper>
);

const AmortizationSchedule: React.FC<Props> = ({ loan, schedule }) => {
  if (!schedule) {
    return null;
  }

  const payments = loan?.payments || [];
  const [modalPayments, setModalPayments] = useState<LoanPayment[]>([]);

  return (
    <>
      <LoanPaymentsModal
        payments={modalPayments}
        onHide={() => setModalPayments([])}
      />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Start Balance</th>
            <th>Interest</th>
            <th>Expected Payment</th>
            <th>Actual Payment</th>
            <th>End Balance</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((payment, index) => {
            const paymentsWithinPeriod = payments.filter((p) => {
              const nextDate = DateTime.fromISO(payment.date)
                .plus({ month: 1 })
                .toJSDate();
              return (
                new Date(payment.date).getTime() <=
                  new Date(p.date).getTime() &&
                new Date(p.date).getTime() < nextDate.getTime()
              );
            });

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{payment.date.split('T')[0]}</td>
                <td>
                  {payment.startBalance.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </td>
                <td>
                  {payment.interest.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </td>
                <td>
                  {payment.monthlyPayment.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </td>
                {paymentsWithinPeriod.length ? (
                  <td>
                    {paymentsWithinPeriod
                      .reduce((acc, p) => acc + p.amount, 0)
                      .toLocaleString('en-US', dateOptions.currencyUS)}
                    <Badge
                      bg="primary"
                      className="ml-2 pointer text-white"
                      onClick={() => setModalPayments(paymentsWithinPeriod)}
                    >
                      {paymentsWithinPeriod.length}
                    </Badge>
                  </td>
                ) : (
                  <td>-</td>
                )}
                <td>
                  {payment.endBalance.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default AmortizationSchedule;
