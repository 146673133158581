import { useEffect } from 'react';
import BreadCrumbWrapper from '../BreadCrumbs/BreadCrumbs';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import edoFundingDisbursementSignal from './Disbursement.signals';
import {
  fetchAndSetDisbursement,
  showAddManualTransactionModal,
} from './Disbursement.helpers';
import DisbursementAddManualTransactionModal from './DisbursementAddManualTransactionModal';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';

const Disbursement = () => {
  const { pathname } = useLocation();
  const { record } = edoFundingDisbursementSignal.value;
  const isReimbursement = !!record?.disbursementId;
  const isPerformancePath = pathname.includes('credit-program');
  const labels = isReimbursement
    ? [
        '',
        `${
          record?.disbursement?.application?.fundingProgramMembership?.lender
            ?.name || ''
        } - ${record?.disbursement?.application?.businessData?.name || ''}`,
        `Reimbursement ${record?.id}`,
      ]
    : [
        '',
        `${
          record?.application?.fundingProgramMembership?.lender?.name || ''
        } - ${record?.application?.businessData?.name || ''}`,
        `Disbursement ${record?.id}`,
      ];

  if (isPerformancePath) {
    labels[0] = 'Program Performance';
    labels.splice(
      1,
      0,
      isReimbursement
        ? record?.disbursement?.application?.fundingProgramMembership
            ?.fundingProgram?.name
        : record?.application?.fundingProgramMembership?.fundingProgram?.name
    );
  }

  useEffect(() => {
    fetchAndSetDisbursement();
  }, []);

  if (!record) {
    return null;
  }

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card className="rounded-0">
        <Card.Body>
          <DisbursementAddManualTransactionModal />
          <h3 className="text-center mb-32">
            {isReimbursement ? 'Reimbursement' : 'Disbursement'}
          </h3>
          <hr />
          <p>
            {isReimbursement ? (
              <strong>Reimbursement ID: </strong>
            ) : (
              <strong>Disbursement ID: </strong>
            )}
          </p>
          <p>{record.id}</p>
          <p>
            <strong>Amount: </strong>
          </p>
          <p>{record.amount.toLocaleString()}</p>

          {!isReimbursement && (
            <>
              <p>
                <strong>Balance: </strong>
              </p>
              <p>
                {(
                  record.amount -
                  record.transactions
                    .map((t) => t.amount)
                    .reduce((acc, amount) => acc + amount, 0)
                ).toLocaleString()}
              </p>
            </>
          )}
          <p>
            <strong>Due date: </strong>
          </p>

          <p>{record.dueDate.split('T')[0]}</p>
          <h5>Banking History</h5>

          {record.transactions.length === 0 ? (
            <Alert variant="info" className="my-24">
              <Alert.Heading>Empty history</Alert.Heading>
              <p>Transactions will show up here</p>
            </Alert>
          ) : (
            <>
              <Card className="rounded-0">
                <Card.Body>
                  {record.transactions.map((t) => (
                    <Row key={t.id}>
                      <Col>
                        <div>
                          <strong>Amount</strong>
                        </div>
                        <div>{t.amount.toLocaleString()}</div>
                      </Col>
                      <Col>
                        <div>
                          <strong>Transferred At</strong>
                        </div>
                        <div>
                          {DateTime.fromISO(t.transferredDate).toFormat(
                            'DD, h:mm a'
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <strong>Memo</strong>
                        </div>
                        <div>{t.memo}</div>
                      </Col>
                    </Row>
                  ))}
                </Card.Body>
              </Card>
            </>
          )}

          <div className="text-right mt-16">
            <Button
              className="rounded"
              onClick={() => {
                showAddManualTransactionModal();
              }}
            >
              Add Manual Transaction
            </Button>
          </div>
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default Disbursement;
