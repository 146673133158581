/* eslint-disable no-unused-vars */
import {
  addIcon,
  dashboardIcon,
  editIcon,
  homeIcon,
  programsIcon,
  reportingIcon,
  settingsIcon,
  userIcon,
  usersIcon,
} from 'components/global/Constant/Icons';

export const edoDashboardButtons = [
  {
    id: 1,
    img: dashboardIcon,
    title: 'Dashboard',
    description: 'Choose a program to see the dashboard to monitor metrics.',
    route: '/edo',
  },
  {
    id: 2,
    img: addIcon,
    title: 'Add New Program',
    description: 'Connect data to visualize, and manage new program',
    route: '/edo/add-program',
  },
  {
    id: 3,
    img: reportingIcon,
    title: 'Reporting',
    description: 'Pull reports',
    route: '/edo/reporting',
  },
];

export const stateDashboardButtons = [
  {
    id: 1,
    img: dashboardIcon,
    title: 'Dashboard',
    description: 'Choose a program to see the dashboard to monitor metrics.',
    route: '/state/dashboard',
  },
  {
    id: 2,
    img: addIcon,
    title: 'Add New Program',
    description: 'Connect data to visualize, and manage new program',
    route: '/state/add-program',
  },
  {
    id: 3,
    img: settingsIcon,
    title: 'Programs',
    description: 'Manage funding programs',
    route: '/state/programs',
  },
  {
    id: 4,
    img: usersIcon,
    title: 'Users',
    description: 'Manage User Accounts',
    route: '/state/users',
  },
  // {
  //   id: 5,
  //   img: migrateIcon,
  //   title: 'Migrate EDO Data',
  //   description: 'Submit qualified application forms',
  //   route: '/state/migrate',
  // },
];

export const edoTopNav = [
  {
    id: 1,
    title: 'Dashboard',
    route: '/edo',
    featureExclude: {},
  },
  {
    id: 2,
    title: 'Reporting',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Treasury',
        route: '/edo/reporting',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Transactions',
        route: '/edo/transactions',
        // featureExclude: { la: { OWNER: true, ADMIN: true, STAFF: true } },
        featureExclude: {},
      },
      {
        id: 3,
        label: 'SEDI',
        route: '/edo/sedi',
        featureExclude: {},
      },
      {
        id: 4,
        label: 'Performance',
        route: '/edo/performance',
        // featureExclude: { la: { OWNER: true, ADMIN: true, STAFF: true } },
        featureExclude: {},
      },
      {
        id: 5,
        label: 'Adhoc Reporting',
        route: '/edo/adhoc-reporting',
        featureExclude: {},
      },
    ],
  },
  {
    id: 3,
    title: 'Tracking',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Applications',
        route: '/edo/portfolio?sort=id-desc',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Funds',
        route: '/edo/funds',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'Lenders',
        route: '/edo/lenders?sort=id-desc',
        featureExclude: {},
      },
      {
        id: 4,
        label: 'VCs',
        route: '/edo/vc?sort=id-desc',
        featureExclude: {},
      },
      {
        id: 5,
        label: 'Businesses',
        route: '/edo/business',
        featureExclude: {},
      },
    ],
  },
  {
    id: 4,
    title: 'System',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Platforms',
        route: '/edo/platforms',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Programs',
        route: '/edo/programs',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'Technical Assistance',
        route: '/edo/technical-assistance',
        featureExclude: {},
      },
      {
        id: 4,
        label: 'Tooltips',
        route: '/edo/tooltips',
        featureExclude: {},
      },
      {
        id: 5,
        label: 'Customize',
        route: '/edo/customize',
        featureExclude: {},
      },
    ],
  },
  {
    id: 5,
    title: 'Settings',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Organization',
        route: '/edo/organization',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Users',
        route: '/edo/users',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'Profile',
        route: '/edo/profile',
        featureExclude: {},
      },
      {
        id: 4,
        label: 'signOut',
        featureExclude: {},
      },
    ],
  },
];

export const stateSideNav = [
  {
    id: 1,
    img: homeIcon,
    title: 'Home',
    route: '/state',
    featureExclude: {},
  },
  {
    id: 2,
    img: dashboardIcon,
    title: 'Dashboard',
    route: '/state/dashboard',
    featureExclude: {},
  },
  {
    id: 3,
    img: userIcon,
    title: 'Users',
    route: '/state/users',
    featureExclude: {},
  },
  {
    id: 4,
    img: usersIcon,
    title: 'Organizations',
    route: '/state/organizations',
    featureExclude: {},
  },
  {
    id: 5,
    img: programsIcon,
    title: 'Programs',
    route: '/state/programs',
    featureExclude: {},
  },
  {
    id: 6,
    img: editIcon,
    title: 'Profile',
    route: '/state/profile',
    featureExclude: {},
  },
];

export const businessTopNav = [
  {
    id: 1,
    title: 'Dashboard',
    route: '/business/dashboard',
    featureExclude: {},
  },
  {
    id: 10,
    title: 'Reporting',
    featureExclude: {},
    dropDownItems: [
      {
        id: 5,
        label: 'Adhoc Reporting',
        route: '/business/adhoc-reporting',
        featureExclude: {},
      },
    ],
  },
  {
    id: 2,
    title: 'Tracking',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Applications',
        route: '/business/applications',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Funds',
        route: '/business/funds',
        featureExclude: {},
      },
    ],
  },
  {
    id: 3,
    title: 'Data Room',
    route: '/business/data-room',
    featureExclude: {},
  },
  {
    id: 4,
    title: 'Funding',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Search Funding',
        route: '/business/search',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Match Now',
        route: '/business/assessment-form',
        featureExclude: {},
      },
    ],
  },
  {
    id: 5,
    title: 'Settings',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Organization',
        route: '/business/organization',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Profile',
        route: '/business/profile',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'signOut',
        featureExclude: {},
      },
    ],
  },
];

export const lenderTopNav = [
  {
    id: 1,
    title: 'Dashboard',
    route: '/lender',
    featureExclude: {},
  },
  {
    id: 10,
    title: 'Reporting',
    featureExclude: {},
    dropDownItems: [
      {
        id: 5,
        label: 'Adhoc Reporting',
        route: '/lender/adhoc-reporting',
        featureExclude: {},
      },
    ],
  },
  {
    id: 2,
    title: 'Businesses',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Search Businesses',
        route: '/lender/search-businesses',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Favorites',
        route: '/lender/search-businesses/business-search-results?favorite=yes',
        featureExclude: {},
      },
    ],
  },
  {
    id: 3,
    title: 'Applications',
    route: '/lender/portfolio?sort=id-desc',
    featureExclude: {},
  },
  {
    id: 4,
    title: 'Programs',
    route: '/lender/program-requests?sort=id-desc',
    featureExclude: {},
  },
  {
    id: 5,
    title: 'Settings',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Organization',
        route: '/lender/organization-information',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Users',
        route: '/lender/users',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'Profile',
        route: '/lender/profile',
        featureExclude: {},
      },
      {
        id: 4,
        label: 'Banking',
        route: '/lender/billing',
        featureExclude: { la: { OWNER: true, ADMIN: true, STAFF: true } },
      },
      {
        id: 5,
        label: 'Data Room',
        route: '/lender/data-room',
        featureExclude: {},
      },
      {
        id: 6,
        label: 'signOut',
        featureExclude: {},
      },
    ],
  },
];

export const vcTopNav = [
  {
    id: 1,
    title: 'Dashboard',
    route: '/vc',
    featureExclude: {},
  },
  {
    id: 10,
    title: 'Reporting',
    featureExclude: {},
    dropDownItems: [
      {
        id: 5,
        label: 'Adhoc Reporting',
        route: '/vc/adhoc-reporting',
        featureExclude: {},
      },
    ],
  },
  {
    id: 2,
    title: 'Businesses',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Search Businesses',
        route: '/vc/search-businesses',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Favorites',
        route: '/vc/search-businesses/business-search-results?favorite=yes',
        featureExclude: {},
      },
    ],
  },
  {
    id: 3,
    title: 'Programs',
    route: '/vc/program-requests?sort=id-desc',
    featureExclude: {},
  },
  {
    id: 4,
    title: 'Portfolio',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Funds',
        route: '/vc/funds',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Portfolio Companies',
        route: '/vc/portfolio-companies',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'Transactions',
        route: '/vc/transactions',
        featureExclude: {},
      },
    ],
  },
  {
    id: 5,
    title: 'Settings',
    featureExclude: {},
    dropDownItems: [
      {
        id: 1,
        label: 'Organization',
        route: '/vc/organization-information',
        featureExclude: {},
      },
      {
        id: 2,
        label: 'Users',
        route: '/vc/users',
        featureExclude: {},
      },
      {
        id: 3,
        label: 'Profile',
        route: '/vc/profile',
        featureExclude: {},
      },
      {
        id: 4,
        label: 'Banking',
        route: '/vc/billing',
        featureExclude: { la: { OWNER: true, ADMIN: true, STAFF: true } },
      },
      {
        id: 5,
        label: 'Data Room',
        route: '/vc/data-room',
        featureExclude: {},
      },
      {
        id: 6,
        label: 'signOut',
        featureExclude: {},
      },
    ],
  },
];

export const executiveTopNav = [
  {
    id: 1,
    title: 'Settings',
    featureExclude: {},
    dropDownItems: [
      {
        id: 6,
        label: 'signOut',
        featureExclude: {},
      },
    ],
  },
];
