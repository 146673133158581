/* eslint-disable no-unused-vars */
import { Col, ProgressBar, Row } from 'react-bootstrap';

const AdminCasesMetrics = ({ low, intermediate, high }) => (
  <>
    <p><span className="h3">{low + intermediate + high}</span> active</p>
    <ProgressBar>
      <ProgressBar max={low + intermediate + high} variant="danger" now={high} key={1} />
      <ProgressBar max={low + intermediate + high} variant="warning" now={intermediate} key={2} />
      <ProgressBar max={low + intermediate + high} variant="success" now={low} key={3} />
    </ProgressBar>
    <Row>
      <Col className="d-flex align-items-center">
        <small className="bg-success border rounded p-1 mr-2" style={{ width: 1, height: 1 }} />
        <small>Low - {low}</small>
      </Col>
    </Row>
    <Row>
      <Col className="d-flex align-items-center">
        <small className="bg-warning border rounded p-1 mr-2" style={{ width: 1, height: 1 }} />
        <small>Intermediate - {intermediate}</small>
      </Col>
    </Row>
    <Row>
      <Col className="d-flex align-items-center">
        <small className="bg-danger border rounded p-1 mr-2" style={{ width: 1, height: 1 }} />
        <small>High - {high}</small>
      </Col>
    </Row>
    <Row>
      <Col />
    </Row>
  </>
);

export default AdminCasesMetrics;
