import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import LargeCheckBox from '../../Constant/LargeCheckBox';
import platformsSignal, {
  editPlatformPaymentOptionsSignal,
} from '../Platforms.signal';
import {
  handleEditPlatformBankAccountCheckboxChange,
  handleEditPlatformCaatAsReconciliationToolChange,
  handleGoToBilling,
  handlePlatformBankAccountLinksSubmission,
} from '../Platforms.helpers';
import loaderSignal from 'signals/Loader.signal';

const EditPlatformPaymentOptionsTab = () => {
  const { bankAccounts } = platformsSignal.value;

  const { selectedBankAccounts, useCaatForPaymentReconciliation } =
    editPlatformPaymentOptionsSignal.value;

  return (
    <>
      <Row className="mt-32 mb-8">
        <Col>
          <p>
            Use <b>CAAT</b> as the payment reconciliation tool?
          </p>
        </Col>
        <Col>
          <LargeCheckBox
            id=""
            size={'sm'}
            centeringClass="text-right"
            label={''}
            checked={useCaatForPaymentReconciliation}
            onClick={() => handleEditPlatformCaatAsReconciliationToolChange()}
          />
        </Col>
      </Row>
      {bankAccounts.length === 0 ? (
        <Row>
          <Col>
            <Alert variant="info">
              <Alert.Heading>Add a bank account</Alert.Heading>
              <p>
                Please add your first bank account to start linking bank
                accounts to platforms.{' '}
                <b>
                  <span
                    onClick={() => handleGoToBilling()}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Click here
                  </span>
                </b>
              </p>
            </Alert>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <Table responsive className="mb-1">
                <thead>
                  <tr className="font-weight-bold">
                    <th>Account Name</th>
                    <th>Account ID</th>
                    <th>Type</th>
                    <th>Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  {bankAccounts.map((bankAccount) => (
                    <tr
                      key={bankAccount.id}
                      className="border border-solid border-bottom-0 border-right-0"
                    >
                      <th>{bankAccount?.bankAccountName}</th>
                      <td>{`********${bankAccount.mask}`}</td>
                      <td>
                        {bankAccount.type
                          ? bankAccount.type.charAt(0).toUpperCase() +
                            bankAccount.type.slice(1)
                          : ''}
                      </td>
                      <td>
                        <LargeCheckBox
                          id=""
                          size={'sm'}
                          disabled={!useCaatForPaymentReconciliation}
                          centeringClass="text-right"
                          label={''}
                          checked={selectedBankAccounts
                            .map((ba) => ba.id)
                            .includes(bankAccount.id)}
                          onClick={() =>
                            handleEditPlatformBankAccountCheckboxChange(
                              bankAccount
                            )
                          }
                        ></LargeCheckBox>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      <hr />
      <Row>
        <Col className="text-right">
          <Button
            variant="outline-primary"
            className="rounded"
            disabled={loaderSignal.value.isContentLoading}
            onClick={() => handlePlatformBankAccountLinksSubmission()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default EditPlatformPaymentOptionsTab;
