import FilePreviewButton from 'components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { titleCaseString } from 'libs/string';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import DownloadButton from '../../utils/FilesAndDocuments/DownloadButton/DownloadButton';
import userAccountSignal from 'signals/UserAccount.signal';
import AttachButton from '../../utils/FilesAndDocuments/AttachButton/AttachButton';
import { downloadApplicationPackage } from '../../ApplicationDetail/ApplicationDetail.helpers';

import {
  handleAttachExistingFile,
  handleAttachNewFile,
  handleDeleteFile,
  handleDetachNewFile,
  handleNewFileNameChange,
  handleRemoveFileClick,
  handleRemoveFileConfirmationModalClose,
  handleUploadTemplateOrSuggestedDocument,
  hideFileUploadConfirmationModal,
  showFileUploadConfirmationModal,
  submitAdditionalFilesPendingUpload,
  updateApplicationDocumentStatus,
} from './ApprovableDocumentsTab.helpers';

import FileDropZoneWrapper from 'components/global/FileDropZone';
import DragAndDrop from 'components/global/DragAndDrop/DragAndDrop';
import { APPLICATION_FORMATS } from '../../Constant/fileFormats';
import { isFileTypeValidForPreview } from 'utils/checkFileTypeForPreview';
import { camelCaseToTitleText } from 'utils/formatTxt';
import { ApplicationDocument, ProgramRequestDocument } from '../../../../types';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import {
  getFundingProgramSuggestedDocuments,
  getFundingProgramTemplates,
  grabIdentifierFromApprovableDocument,
  handleAttachOCRDocClick,
  templateExists,
} from '../Approvable.helpers';
import $appSettings from 'signals/AppSettings.signal';

const DocumentStatusSelect: React.FC<{
  status: string;
  document: ApplicationDocument | ProgramRequestDocument;
}> = ({ document, status }) => {
  const { APPLICATION_STATUS, APPLICATION_DOCUMENT_STATUS } =
    $appSettings.value.constants;

  return (
    <Form.Select
      disabled={
        loaderSignal.value.isContentLoading ||
        APPLICATION_STATUS?.inProgress === status
      }
      className="form-control"
      value={document.status}
      name="newApplicationStatus"
      onChange={(e) =>
        updateApplicationDocumentStatus(document, e.target.value)
      }
    >
      {Object.entries(APPLICATION_DOCUMENT_STATUS).map(([key, value]) => (
        <option value={value} key={key}>
          {camelCaseToTitleText(key)}
        </option>
      ))}
    </Form.Select>
  );
};

const ApprovableDocumentsTab = () => {
  const {
    id,
    status,
    uploadDocumentType,
    documents,
    additionalDocumentsToUpload,
    modelType,
    fileUploadConfirmationModalVisible,
    fileUploadConfirmationType,
    fundingProgram,
  } = applicationDetailSignal.value;
  const { userData } = userAccountSignal.value;
  const {
    APPLICATION_STATUS,
    APPLICATION_DOCUMENT_STATUS,
    PORTAL_TYPES,
    DOCUMENT_TYPES,
  } = $appSettings.value.constants;
  const allDocumentsRead = documents.every(
    (d) => d.status === APPLICATION_DOCUMENT_STATUS?.read
  );

  const fundingProgramTemplates = getFundingProgramTemplates(
    fundingProgram,
    modelType === 'application'
      ? DOCUMENT_TYPES.applicationTemplate
      : DOCUMENT_TYPES.programRequestTemplate
  );
  const fundingProgramSuggestedDocuments = getFundingProgramSuggestedDocuments(
    fundingProgram,
    modelType === 'application'
      ? DOCUMENT_TYPES.applicationSuggested
      : DOCUMENT_TYPES.programRequestSuggested
  );

  const hasApplicationDocument = templateExists(
    documents,
    fundingProgramTemplates
  );

  const additionalDocuments = documents.filter(
    (doc) => !doc.fundingProgramDocumentId
  );

  return (
    <>
      <Row>
        <Col>
          <Alert
            variant="danger"
            hidden={
              !modelType ||
              modelType === 'programRequest' ||
              hasApplicationDocument
            }
          >
            An application document is missing!
          </Alert>
          <Alert
            variant={allDocumentsRead ? 'success' : 'warning'}
            hidden={
              userData.account.portalType !== PORTAL_TYPES.edo ||
              modelType === 'programRequest' ||
              !hasApplicationDocument
            }
          >
            <p className="mb-0">
              {allDocumentsRead
                ? 'All documents have been read.'
                : 'All documents must be read before the application can be submitted for approval.'}
            </p>
          </Alert>
          {modelType === 'programRequest' ? (
            <h5>Program Request Documents</h5>
          ) : (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <h5>Application Documents</h5>
              <small hidden={status !== APPLICATION_STATUS?.inProgress}>
                * Application document actions will take you to the application
                draft page.
              </small>
            </div>
          )}
          <Table className="application-detail-documents">
            <thead>
              <tr>
                <th>Document Name</th>
                <th hidden={userData.account.portalType !== PORTAL_TYPES.edo}>
                  Submitted
                </th>
                <th>Actions</th>
                <th>Preview</th>
                <th hidden={userData.account.portalType === PORTAL_TYPES.edo}>
                  Template
                </th>
              </tr>
            </thead>
            {fundingProgramTemplates?.map((template) => {
              const matchingAlreadyUploadedFile = documents.find(
                (d) => d.fundingProgramDocumentId === template.id
              );
              let fileType = '';
              if (matchingAlreadyUploadedFile) {
                fileType = matchingAlreadyUploadedFile.fileType;
              }

              const previewVisible = isFileTypeValidForPreview(fileType);
              return (
                <tbody key={template.id}>
                  <tr>
                    <td>{titleCaseString(template.name)}</td>
                    {userData.account.portalType !== PORTAL_TYPES.edo ? (
                      <td>
                        {status !== APPLICATION_STATUS?.inProgress ? (
                          <AttachButton
                            id={`attach-${template.id}-btn`}
                            name={template.id.toString()}
                            text={
                              matchingAlreadyUploadedFile ? 'Replace' : 'Attach'
                            }
                            handleAttachFile={(file, e) => {
                              handleAttachExistingFile(file, e, template);
                              if (
                                status === APPLICATION_STATUS?.approved ||
                                status === APPLICATION_STATUS?.denied
                              ) {
                                showFileUploadConfirmationModal(
                                  'templateOrSuggestedDocument'
                                );
                              } else {
                                handleUploadTemplateOrSuggestedDocument();
                              }
                            }}
                          />
                        ) : (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="rounded"
                            onClick={() => handleAttachOCRDocClick(id)}
                          >
                            {matchingAlreadyUploadedFile ? 'Replace' : 'Attach'}
                          </Button>
                        )}
                      </td>
                    ) : (
                      <td>
                        <span hidden={!!matchingAlreadyUploadedFile}>
                          Not submitted
                        </span>
                        <span hidden={!matchingAlreadyUploadedFile}>Yes</span>
                      </td>
                    )}
                    {userData.account.portalType === PORTAL_TYPES.edo &&
                    matchingAlreadyUploadedFile ? (
                      <td>
                        <DocumentStatusSelect
                          status={status}
                          document={matchingAlreadyUploadedFile}
                        />
                      </td>
                    ) : (
                      <td
                        hidden={
                          userData.account.portalType !== PORTAL_TYPES.edo
                        }
                      >
                        None
                      </td>
                    )}
                    {matchingAlreadyUploadedFile ? (
                      <td>
                        <FilePreviewButton
                          render={(submitting, fn) => (
                            <Button
                              hidden={
                                !matchingAlreadyUploadedFile || !previewVisible
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-dark"
                              onClick={() =>
                                matchingAlreadyUploadedFile &&
                                fn(
                                  {
                                    ...matchingAlreadyUploadedFile,
                                    parentId: id,
                                  },
                                  uploadDocumentType!
                                )
                              }
                            >
                              View
                            </Button>
                          )}
                        />
                        <DownloadButton
                          render={(submitting, fn) => (
                            <Button
                              hidden={!matchingAlreadyUploadedFile}
                              size="sm"
                              variant="outline-primary"
                              className="ml-4 rounded"
                              onClick={() =>
                                matchingAlreadyUploadedFile &&
                                fn(
                                  {
                                    ...matchingAlreadyUploadedFile,
                                    parentId: id,
                                  },
                                  uploadDocumentType!,
                                  template.name
                                )
                              }
                            >
                              Download
                            </Button>
                          )}
                        />
                      </td>
                    ) : (
                      <td>None</td>
                    )}
                    <td
                      hidden={userData.account.portalType === PORTAL_TYPES.edo}
                    >
                      <DownloadButton
                        render={(submitting, fn) => (
                          <Button
                            hidden={!template.filePath}
                            size="sm"
                            variant="outline-info"
                            className="rounded"
                            onClick={() =>
                              fn(
                                template,
                                'fundingProgramDocuments',
                                template.name
                              )
                            }
                          >
                            Download Template
                          </Button>
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </Col>
      </Row>
      <Row
        hidden={
          fundingProgramSuggestedDocuments &&
          fundingProgramSuggestedDocuments.length < 1
        }
      >
        <Col>
          <h5>Suggested Documents</h5>
          <Table className="application-detail-documents">
            <thead>
              <tr>
                <th>Document Name</th>
                <th hidden={userData.account.portalType !== PORTAL_TYPES.edo}>
                  Submitted
                </th>
                <th>Actions</th>
                <th>Preview</th>
                <th hidden={userData.account.portalType === PORTAL_TYPES.edo}>
                  Template
                </th>
              </tr>
            </thead>
            {fundingProgramSuggestedDocuments?.map((suggestedDocument) => {
              const matchingAlreadyUploadedFiles = documents.filter(
                (d) => d.fundingProgramDocumentId === suggestedDocument.id
              );
              const matchingAlreadyUploadedFile =
                matchingAlreadyUploadedFiles.length > 0
                  ? matchingAlreadyUploadedFiles[0]
                  : undefined;
              let fileType = '';
              if (matchingAlreadyUploadedFile) {
                fileType = matchingAlreadyUploadedFile.fileType;
              }
              const previewVisible = isFileTypeValidForPreview(fileType);
              return (
                <tbody key={suggestedDocument.id}>
                  <tr>
                    <td>{titleCaseString(suggestedDocument.name)}</td>

                    {userData.account.portalType !== PORTAL_TYPES.edo ? (
                      <td>
                        <AttachButton
                          id={`attach-${suggestedDocument.id}-btn`}
                          name={suggestedDocument.id.toString()}
                          text={
                            matchingAlreadyUploadedFile ? 'Replace' : 'Attach'
                          }
                          handleAttachFile={(file, e) => {
                            handleAttachExistingFile(
                              file,
                              e,
                              suggestedDocument
                            );
                            if (
                              status === APPLICATION_STATUS?.approved ||
                              status === APPLICATION_STATUS?.denied
                            ) {
                              showFileUploadConfirmationModal(
                                'templateOrSuggestedDocument'
                              );
                            } else {
                              handleUploadTemplateOrSuggestedDocument();
                            }
                          }}
                        />
                        <Button
                          hidden={!matchingAlreadyUploadedFile}
                          variant="outline-danger"
                          className="rounded ml-8"
                          size="sm"
                          onClick={() => {
                            if (matchingAlreadyUploadedFile) {
                              handleRemoveFileClick(
                                grabIdentifierFromApprovableDocument(
                                  matchingAlreadyUploadedFile
                                )
                              );
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <span hidden={!!matchingAlreadyUploadedFile}>
                          Not submitted
                        </span>
                        <span hidden={!matchingAlreadyUploadedFile}>Yes</span>
                      </td>
                    )}
                    {userData.account.portalType === PORTAL_TYPES.edo &&
                    matchingAlreadyUploadedFile ? (
                      <td>
                        <DocumentStatusSelect
                          status={status}
                          document={matchingAlreadyUploadedFile}
                        />
                      </td>
                    ) : (
                      <td
                        hidden={
                          userData.account.portalType !== PORTAL_TYPES.edo
                        }
                      >
                        None
                      </td>
                    )}
                    {matchingAlreadyUploadedFile ? (
                      <td>
                        <FilePreviewButton
                          render={(submitting, fn) => (
                            <Button
                              hidden={
                                !matchingAlreadyUploadedFile || !previewVisible
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-dark"
                              onClick={() =>
                                matchingAlreadyUploadedFile &&
                                fn(
                                  {
                                    ...matchingAlreadyUploadedFile,
                                    parentId: id,
                                  },
                                  uploadDocumentType!
                                )
                              }
                            >
                              View
                            </Button>
                          )}
                        />
                        <DownloadButton
                          render={(submitting, fn) => (
                            <Button
                              hidden={!matchingAlreadyUploadedFile}
                              size="sm"
                              variant="outline-primary"
                              className="ml-4 rounded"
                              onClick={() =>
                                matchingAlreadyUploadedFile &&
                                fn(
                                  {
                                    ...matchingAlreadyUploadedFile,
                                    parentId: id,
                                  },
                                  uploadDocumentType!,
                                  suggestedDocument.name
                                )
                              }
                            >
                              Download
                            </Button>
                          )}
                        />
                      </td>
                    ) : (
                      <td>None</td>
                    )}
                    <td
                      hidden={userData.account.portalType === PORTAL_TYPES.edo}
                    >
                      <DownloadButton
                        render={(submitting, fn) => (
                          <Button
                            hidden={!suggestedDocument.filePath}
                            size="sm"
                            variant="outline-info"
                            className="rounded"
                            onClick={() =>
                              fn(
                                suggestedDocument,
                                'fundingProgramDocuments',
                                suggestedDocument.name
                              )
                            }
                          >
                            Download Template
                          </Button>
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Additional Documents</h5>
          {(status === APPLICATION_STATUS?.incomplete ||
            status === APPLICATION_STATUS?.pending) &&
            (userData.account.portalType === PORTAL_TYPES.vc ||
              userData.account.portalType === PORTAL_TYPES.lender) && (
              <FileDropZoneWrapper
                formats={APPLICATION_FORMATS}
                numOfUploadsAllowed={20}
                maxSizeMB={100}
                onUpload={(files: File[]) =>
                  files.forEach((file) => handleAttachNewFile(file))
                }
              >
                <DragAndDrop
                  files={additionalDocumentsToUpload?.map(({ file }) => file)}
                  uploadProgress={0}
                  maxSizeMB={100}
                  preview={false}
                  formats={APPLICATION_FORMATS}
                />
              </FileDropZoneWrapper>
            )}
          <Table className="application-detail-documents mb-0">
            <thead>
              <tr>
                <th>Document Name</th>
                <th hidden={userData.account.portalType !== PORTAL_TYPES.edo}>
                  Submitted
                </th>
                <th>Actions</th>
                <th>Preview</th>
                <th // need this here to align this table's columns with the others. only in the lender/vc portals
                  hidden={userData.account.portalType === PORTAL_TYPES.edo}
                />
              </tr>
            </thead>
            <tbody>
              {additionalDocumentsToUpload?.map(({ name }, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      value={name}
                      onChange={(e) =>
                        handleNewFileNameChange(e.target.value, index)
                      }
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="rounded ml-2"
                      variant="outline-secondary"
                      onClick={() => handleDetachNewFile(index)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}

              {additionalDocuments?.map((additionalDocument) => {
                const previewVisible = isFileTypeValidForPreview(
                  additionalDocument.fileType
                );

                return (
                  <tr
                    key={grabIdentifierFromApprovableDocument(
                      additionalDocument
                    )}
                  >
                    <td>{titleCaseString(additionalDocument.name)}</td>
                    <td>
                      <Button
                        variant="outline-danger"
                        className="rounded"
                        size="sm"
                        onClick={() =>
                          handleRemoveFileClick(
                            grabIdentifierFromApprovableDocument(
                              additionalDocument
                            )
                          )
                        }
                      >
                        Delete
                      </Button>
                    </td>
                    {userData.account.portalType === PORTAL_TYPES.edo &&
                    additionalDocument.filePath ? (
                      <td>
                        <DocumentStatusSelect
                          status={status}
                          document={additionalDocument}
                        />
                      </td>
                    ) : (
                      <td
                        hidden={
                          userData.account.portalType !== PORTAL_TYPES.edo
                        }
                      >
                        None
                      </td>
                    )}
                    {additionalDocument.filePath ? (
                      <td>
                        <FilePreviewButton
                          render={(submitting, fn) => (
                            <Button
                              hidden={
                                !additionalDocument.filePath || !previewVisible
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-dark"
                              onClick={() =>
                                additionalDocument.filePath &&
                                fn(
                                  { ...additionalDocument, parentId: id },
                                  uploadDocumentType!
                                )
                              }
                            >
                              View
                            </Button>
                          )}
                        />
                        <DownloadButton
                          render={(submitting, fn) => (
                            <Button
                              hidden={!additionalDocument.filePath}
                              size="sm"
                              variant="outline-primary"
                              className="ml-4 rounded"
                              onClick={() =>
                                additionalDocument.filePath &&
                                fn(
                                  { ...additionalDocument, parentId: id },
                                  uploadDocumentType!,
                                  additionalDocument.name!
                                )
                              }
                            >
                              Download
                            </Button>
                          )}
                        />
                      </td>
                    ) : (
                      <td>None</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div
        className="text-right"
        hidden={
          modelType === 'programRequest' ||
          userData.account.portalType !== PORTAL_TYPES.edo
        }
      >
        <Button
          className="rounded mt-16"
          variant="outline-primary"
          onClick={() => downloadApplicationPackage()}
          disabled={
            APPLICATION_STATUS.importing === status ||
            APPLICATION_STATUS.inProgress === status ||
            loaderSignal.value.isContentLoading
          }
        >
          Download Application Package
        </Button>
        <p className="font-weight-light text-muted">
          <small>
            Package can be downloaded once application submission is complete
          </small>
        </p>
      </div>
      <Row className="mb-8">
        <Col xs={{ span: 3, offset: 9 }} className="p-0 text-right">
          <Button
            hidden={additionalDocumentsToUpload.length === 0}
            className="rounded"
            variant="outline-primary"
            onClick={() => {
              if (
                status === APPLICATION_STATUS?.approved ||
                status === APPLICATION_STATUS?.denied
              ) {
                showFileUploadConfirmationModal('additionalDocuments');
              } else {
                submitAdditionalFilesPendingUpload();
              }
            }}
            disabled={loaderSignal.value.isContentLoading}
          >
            Upload Additional Documents
          </Button>
        </Col>
      </Row>
      <ModalWrapper
        show={fileUploadConfirmationModalVisible}
        centered
        size="lg"
        backdropClassName={null}
        onExit={null}
        onExited={null}
        onHide={null}
      >
        <Modal.Header>
          <h5>Confirm File Upload</h5>
        </Modal.Header>
        <Modal.Body>
          <p>
            This application is no longer pending and the documents may no
            longer be reviewed.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded"
            variant="secondary-outline"
            onClick={() => hideFileUploadConfirmationModal()}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              hideFileUploadConfirmationModal();
              fileUploadConfirmationType === 'additionalDocuments'
                ? submitAdditionalFilesPendingUpload()
                : handleUploadTemplateOrSuggestedDocument();
            }}
            className="rounded"
          >
            Upload
          </Button>
        </Modal.Footer>
      </ModalWrapper>
      <RemoveFileConfirmationModal />
    </>
  );
};

const RemoveFileConfirmationModal = () => {
  const { removeFileConfirmationModalVisible, removeFileId } =
    applicationDetailSignal.value;

  return (
    <ModalWrapper
      show={removeFileConfirmationModalVisible}
      centered
      size="lg"
      backdropClassName={null}
      onExit={null}
      onExited={null}
      onHide={handleRemoveFileConfirmationModalClose}
    >
      <Modal.Header>
        <h5>Delete File</h5>
      </Modal.Header>
      <Modal.Body>
        This file will be permanently removed. Do you wish to continue?
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="rounded"
          variant="secondary-outline"
          onClick={handleRemoveFileConfirmationModalClose}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (removeFileId) {
              handleDeleteFile(removeFileId);
              handleRemoveFileConfirmationModalClose();
            }
          }}
          className="rounded"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ApprovableDocumentsTab;
