import loaderSignal from 'signals/Loader.signal';
import api from '../../../api/api';
import fundApplicationSignal from './FundApplication.signals';
import alertSignal from 'signals/Alert.signal';
import attachDocumentsModalSignal from '../AttachDocumentsModal/attachDocumentsModal.signal';
import { ATTACH_DOCUMENT_MODAL_TYPE } from '../AttachDocumentsModal/attachDocumentsModal.helpers';
import { organizationDetailsSignal } from '../OrganizationsList/OrganizationsList.signal';
import $capTable from '../CapTable/capTable.signal';

export const FUND_APPLICATION_MODALS = {
  singleDocument: 'SINGLE_DOCUMENT',
  additionalDocuments: 'ADDITIONAL_DOCUMENTS',
};

const FUND_APPLICATION_INCLUDE = {
  vcPortfolioCompany: {
    include: {
      fund: true,
      business: true,
    },
  },
  documents: {
    orderBy: {
      //order by descending so fund documents are matched to the most recent document submitted
      id: 'desc',
    },
    include: {
      businessDocument: true,
    },
  },
};

export const fetchFundApplication = async (id, showLoader = true) => {
  try {
    loaderSignal.update({ isContentLoading: showLoader });
    const fundApplication = await api.get({
      path: '/fundApplications',
      options: {
        where: {
          id,
        },
        include: FUND_APPLICATION_INCLUDE,
      },
    });


    $capTable.update({ capTable: fundApplication.vcPortfolioCompany?.business?.capTable?.tableData || [] });
    return fundApplicationSignal.update({
      fundApplication,
    });
  } catch (error) {
  } finally {
    loaderSignal.reset();
  }
};

export const getDocumentArray = (documentType) => {
  const { fundApplication } = fundApplicationSignal.value;
  const fundDocuments =
    fundApplication?.vcPortfolioCompany?.fund?.documents || [];
  const filteredDocuments = fundDocuments.filter(
    (fd) => fd.documentType === documentType
  );
  const filteredDocumentsWithUploadedDocuments = filteredDocuments.map((rd) => {
    const matchingDocument = fundApplication.documents.find(
      (doc) => doc.fundDocumentId === rd.id
    );



    return {
      ...rd,
      fundApplicationDocumentId: matchingDocument ? matchingDocument.id : null,
      status: matchingDocument ? matchingDocument.status : '',
      uploadedDocument: matchingDocument
        ? matchingDocument.businessDocument
        : null,
    };
  });

  return filteredDocumentsWithUploadedDocuments;
};

export const handleDocumentStatusChange = async (e, doc) => {
  const { value: status } = e;
  const { fundApplication } = fundApplicationSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/fundApplicationDocuments',
      body: {
        where: {
          id: doc.fundApplicationDocumentId,
        },
        data: {
          status,
        },
      },
    });

    await fetchFundApplication(fundApplication.id);
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Document status successfully updated.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleAttachSingleDocumentClick = (fundDocument) => {
  attachDocumentsModalSignal.update({
    selectedTemplateDocument: fundDocument,
    modalVisible: ATTACH_DOCUMENT_MODAL_TYPE.singleDocument,
  });
};

export const handleAttachAdditionalDocumentsClick = () => {
  attachDocumentsModalSignal.update({
    modalVisible: ATTACH_DOCUMENT_MODAL_TYPE.multipleDocuments,
  });
};

export const handleAttachSingleDocumentSelectClick = (document) => {
  const { selectedBusinessDocuments } = fundApplicationSignal.value;

  fundApplicationSignal.update({
    selectedBusinessDocuments: selectedBusinessDocuments.find(
      (doc) => doc.id === document.id
    )
      ? []
      : [document],
  });
};

export const handleMultipleDocumentSelectClick = (document) => {
  const { selectedBusinessDocuments } = fundApplicationSignal.value;

  fundApplicationSignal.update({
    selectedBusinessDocuments: selectedBusinessDocuments.find(
      (doc) => doc.id === document.id
    )
      ? selectedBusinessDocuments.filter((doc) => doc.id !== document.id)
      : [...selectedBusinessDocuments, document],
  });
};

export const handleAttachDocumentModalClose = () => {
  fundApplicationSignal.update({
    modalVisible: null,
    selectedFundDocument: null,
    selectedBusinessDocuments: [],
  });
};

export const handleFundApplicationDocumentSubmit = async () => {
  const { fundApplication } = fundApplicationSignal.value;
  const { selectedDataRoomDocuments, selectedTemplateDocument } =
    attachDocumentsModalSignal.value;
  if (!selectedDataRoomDocuments.length) {
    return alertSignal.update({
      type: 'notification',
      message: 'Please selected a document.',
    });
  }

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.post({
      path: '/fundApplicationDocuments',
      body: {
        data: selectedDataRoomDocuments.map((doc) => ({
          fundApplicationId: fundApplication.id,
          businessDocumentId: doc.id,
          fundDocumentId: selectedTemplateDocument
            ? selectedTemplateDocument.id
            : undefined,
          name: doc.name,
        })),
      },
    });

    await fetchFundApplication(fundApplication.id);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Your document has been attached!',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const removeAdditionalDocument = async (doc) => {
  const { fundApplication } = fundApplicationSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.delete({
      path: '/fundApplicationDocuments',
      body: {
        where: {
          id: doc.fundApplicationDocumentId,
        },
      },
    });

    await fetchFundApplication(fundApplication.id);

    alertSignal.update({
      type: 'notification',
      message: 'Document successfully removed',
      variant: 'success',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleViewFundApplicationModal = (fundApplication) => {
  fundApplicationSignal.update({
    fundApplication,
  });

  organizationDetailsSignal.update({
    selectedOrganization: fundApplication.vcPortfolioCompany.business,
  });
};

export const handleCloseFundApplicationModal = () => {
  fundApplicationSignal.update({
    fundApplication: null,
  });

  organizationDetailsSignal.update({
    selectedOrganization: null,
  });
};

export const handleToggleSensitveInformationPermission = async () => {
  const { fundApplication } = fundApplicationSignal.value;
  try {
    alertSignal.update({
      type: 'notification',
      message: 'Updating permissions...'
    });

    const updatedPortfolioCompany = await api.patch({
      path: '/vcPortfolioCompanies',
      body: {
        action: 'toggleInformationPermission',
        where: {
          id: fundApplication.vcPortfolioCompany.id,
        },
      },
    });

    fundApplicationSignal.update({
      fundApplication: {
        ...fundApplication,
        vcPortfolioCompany: {
          ...fundApplication.vcPortfolioCompany,
          sensitiveInformationPermission: updatedPortfolioCompany.sensitiveInformationPermission,
        },
      },
    });

    alertSignal.update({
      type: 'notification',
      message: 'Permissions have been updated.',
      variant: 'success',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  }
};
