import Signal from 'libs/signals';
import { AmortizationPayment, Application, BankAccount, MakeForm } from 'types';

type LedgerSignal = {
  application: Application | null;
  bankAccounts: BankAccount[];
  createReimbursementModalOpen: boolean;
  configureLoanFormModalOpen: boolean;
  createLossClaimFormModalOpen: boolean;
  acceptLossClaimFormModalOpen: boolean;
  submitBorrowerPaymentModalOpen: boolean;
  schedule: AmortizationPayment[] | null;
  loadingSchedule: boolean;
};

const ledgerSignal = Signal<LedgerSignal>({
  application: null,
  bankAccounts: [],
  createReimbursementModalOpen: false,
  configureLoanFormModalOpen: false,
  createLossClaimFormModalOpen: false,
  acceptLossClaimFormModalOpen: false,
  submitBorrowerPaymentModalOpen: false,
  schedule: null,
  loadingSchedule: false,
});

type LedgerAddReimbursementSignal = {
  amount: string;
  dueDate: Date | undefined;
};

export const ledgerAddReimbursementSignal =
  Signal<LedgerAddReimbursementSignal>({
    amount: '',
    dueDate: undefined,
  });

type LedgerSubmitBorrowerPaymentSignal = MakeForm<{
  amount: string;
  date: Date | undefined;
}>;

export const ledgerBorrowerPaymentSignal =
  Signal<LedgerSubmitBorrowerPaymentSignal>({
    amount: { value: '', dirty: false, errors: [] },
    date: { value: undefined, dirty: false, errors: [] },
  });

type LedgerLossClaimSignal = MakeForm<{
  liquidationProceeds: string;
}>;

export const ledgerLossClaimSignal = Signal<LedgerLossClaimSignal>({
  liquidationProceeds: { value: '', dirty: false, errors: [] },
});

type LedgerUpdateLossClaimSignal = MakeForm<{
  coveredAmount: string;
  recycledAmount: string;
}>;

export const ledgerAcceptLossClaimSignal = Signal<LedgerUpdateLossClaimSignal>({
  coveredAmount: { value: '', dirty: false, errors: [] },
  recycledAmount: { value: '', dirty: false, errors: [] },
});

type LedgerLoanPerformanceSignal = MakeForm<{
  subsequentPrivateFinancing: string;
  ssbciFundsLost: string;
  equityInvestmentGains: string;
}>;

export const $ledgerLoanPerformance = Signal<LedgerLoanPerformanceSignal>({
  equityInvestmentGains: {
    value: '',
    dirty: false,
    errors: [],
  },
  ssbciFundsLost: {
    value: '',
    dirty: false,
    errors: [],
  },
  subsequentPrivateFinancing: {
    value: '',
    dirty: false,
    errors: [],
  },
});

type LedgerConfigureLoanSignal = {
  loadingSchedulePreview: boolean;
  schedulePreview: null | AmortizationPayment[];
  attachments: {
    fileType: string;
    filePath: string;
    name: string;
  }[];
} & MakeForm<{
  interestRate: string;
  years: string;
  firstRepaymentDate: string;
  gracePeriodDays: string;
}>;

export const ledgerConfigureLoanSignal = Signal<LedgerConfigureLoanSignal>({
  loadingSchedulePreview: false,
  schedulePreview: null,
  gracePeriodDays: {
    value: '',
    dirty: false,
    errors: [],
  },
  interestRate: {
    value: '',
    dirty: false,
    errors: [],
  },
  years: {
    value: '',
    dirty: false,
    errors: [],
  },
  firstRepaymentDate: {
    value: '',
    dirty: false,
    errors: [],
  },
  attachments: [],
});

export default ledgerSignal;
