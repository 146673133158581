import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { completedLoanIcon, searchIcon } from "components/global/Constant/Icons";
import userSignal from "signals/User.signal";
import businessDashboardSignal from "signals/BusinessDashboard.signal";
import {
  fetchAndSetDashboardData,
  getApplicationsMetrics,
  handleBusinessDashboardSortChange,
  handleRemoveSavedOpportunityFromDashboard,
  hideProgramsOfferedModal,
  loanChartOptions,
  resetBusinessDashboardFilterInputs,
  setBusinessDashboardAppliedFilters,
  setBusinessDashboardDropdownFilter,
  showProgramsOfferedModal
} from "./BusinessDashboard.helpers";
import BusinessApplicationProgress from "../BusinessApplication/components/BusinessApplicationProgress";
import SimpleTable from "../../../global/SimpleTable";
import BusinessWrapper from "../components/BusinessWrapper";
import ProgramsOfferedModal from "../components/ProgramsOfferedModal";
import LargeCheckBox from "components/global/Constant/LargeCheckBox";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { uniqById } from "utils/array";
import { Doughnut, Pie } from "react-chartjs-2";
import { generalDoughnutChartWhiteTxtOptions } from "components/global/ChartJS/chartPresetsAndStyles";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ReactGA from "react-ga4";
import $appSettings from "signals/AppSettings.signal";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ApplicationsList = () => (
  <>
    {businessDashboardSignal.value.loanData?.length > 0 ? (
      <>
        {businessDashboardSignal.value.loanData
          .filter(
            (loan) => loan.status === 'PENDING' || loan.status === 'INCOMPLETE'
          )
          .map((loan) => (
            <BusinessApplicationProgress key={loan.id} application={loan} />
          ))}
        <Row>
          <Col md={6} lg={4}>
            <Link
              to="/business/search"
              className="text-decoration-none text-dark"
            >
              <Card className="mt-32 p-16">
                <Row>
                  <Col>
                    <h5 className="text-dark">New Loan</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-between">
                    <p className="small m-0 mr-8 text-secondary">
                      See all opportunities available that match your criteria
                    </p>
                    <FontAwesomeIcon
                      className="text-dark"
                      fontSize={48}
                      icon={searchIcon}
                    />
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col md={6} lg={4}>
            <Card className="mt-32 p-16">
              <Row>
                <Col>
                  <h5>Closed Loans</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={3} className="d-flex align-items-end mr-8">
                  <h5 className="m-0 mr-8">{userSignal.value.closedLoans}</h5>
                  <p className="m-0 text-secondary text-nowrap">Loans</p>
                </Col>
                <Col className="d-flex align-items-end justify-content-between pr-0 mr-16 text-nowrap">
                  <Link className="mr-8" to="/business/myloans">
                    View all past loans
                  </Link>
                  <FontAwesomeIcon fontSize={48} icon={completedLoanIcon} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    ) : (
      <Row className="text-center mt-16">
        <Col>
          <p>
            Once a lender uploads your application it will link with your
            account and you can see any updates here
          </p>
        </Col>
      </Row>
    )}
  </>
);

const BusinessDashboard = () => {
  const history = useHistory();
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const {
    favoriteRecords,
    dropdownFilters,
    sort,
    applicationsMetrics,
    loanData,
    loanChartObj,
  } = businessDashboardSignal.value;
  const { PROVIDER_TYPES } = $appSettings.value.constants;
  const lenders = favoriteRecords
    .filter((fb) => !!fb.lender)
    .map((fb) => fb.lender);
  const ventureCapitalFirms = favoriteRecords
    .filter((fb) => !!fb.ventureCapitalFirm)
    .map((fb) => fb.ventureCapitalFirm);

  const uniqLenders = uniqById(lenders);
  const uniqVcfs = uniqById(ventureCapitalFirms);

  const init = useCallback(async () => {
    await fetchAndSetDashboardData();
  });

  useEffect(() => {
    init();
  }, [history.location.search]);

  useEffect(() => {
    getApplicationsMetrics();
  }, [loanData]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: history.location.pathname,
      title: 'Business Dashboard',
    });
  }, []);

  return (
    <BusinessWrapper>
        <Row className="px-8 py-16 mb-32 rounded">
          <Col lg={6} className="text-center text-light mr-8">
            <Card className="p-16" style={{ backgroundColor: '#294A6A' }}>
              <h5>Applications</h5>
              <Pie
                style={{ maxHeight: 300 }}
                data={applicationsMetrics}
                options={generalDoughnutChartWhiteTxtOptions}
                plugins={[ChartDataLabels]}
              />
            </Card>
          </Col>
          <Col className="text-center">
            <Card className="p-16 bg-white text-center h-100">
              <h5>Loans</h5>
              <Doughnut
                style={{ maxHeight: 300 }}
                data={loanChartObj}
                options={loanChartOptions}
                plugins={[ChartDataLabels]}
              />
            </Card>
          </Col>
        </Row>
        <Row className="mb-40">
          <Col>
            <Card className="p-24 bg-light">
              <>
                <h4 className="mb-32">Saved opportunities</h4>
                <div className="mb-16">
                <SimpleTableFilterDropdown
                  filtersState={dropdownFilters}
                  onChange={({ name, value }) =>
                    setBusinessDashboardDropdownFilter({ name, value })
                  }
                  onApply={(filters) =>
                    setBusinessDashboardAppliedFilters(filters)
                  }
                  onClearFilterInputs={resetBusinessDashboardFilterInputs}
                  filters={[
                    {
                      type: 'integer',
                      label: 'ID',
                      key: 'id',
                    },
                    {
                      type: 'select',
                      label: 'Organization Name',
                      key: 'lenderName',
                      options: uniqLenders
                        .map((lender) => ({
                          value: `${lender.id}-lender`,
                          label: lender.name,
                        }))
                        .concat(
                          uniqVcfs.map((vcf) => ({
                            value: `${vcf.id}-vc`,
                            label: vcf.name,
                          }))
                        ),
                    },
                    {
                      type: 'select',
                      label: 'Lender/VC',
                      key: 'organizationType',
                      options: [
                        {
                          label: 'Lender',
                          value: PORTAL_TYPES.lender,
                        },
                        { label: 'VC', value: PORTAL_TYPES.vc },
                      ],
                    },
                    {
                      type: 'select',
                      label: 'Lender Type',
                      key: 'lenderType',
                      options: Object.values(PROVIDER_TYPES).map(
                        (lenderType) => ({
                          value: lenderType.value,
                          label: lenderType.annualReportLabel,
                        })
                      ),
                    },
                    {
                      type: 'select',
                      label: 'Requires Business Plan',
                      key: 'requiresBusinessPlan',
                      options: [
                        {
                          value: 'true',
                          label: 'Yes',
                        },
                        {
                          value: 'false',
                          label: 'No',
                        },
                      ],
                    },
                    {
                      type: 'select',
                      label: 'Funds Startups',
                      key: 'fundsStartups',
                      options: [
                        {
                          value: 'true',
                          label: 'Yes',
                        },
                        {
                          value: 'false',
                          label: 'No',
                        },
                      ],
                    },
                  ]}
                />
                </div>
                <SimpleTable
                  headers={[
                    {
                      key: 'id',
                      title: 'ID',
                      sortable: true,
                    },
                    {
                      key: 'lenderName',
                      title: 'Organization Name',
                      sortable: true,
                    },
                    {
                      key: 'organizationType',
                      title: 'Lender/VC',
                    },
                    {
                      key: 'lenderType',
                      title: 'Lender Type',
                      sortable: true,
                    },
                    {
                      key: 'requiresBusinessPlan',
                      title: 'Requires Business Plan',
                      sortable: true,
                    },
                    {
                      key: 'fundsStartups',
                      title: 'Funds Startups',
                      sortable: true,
                    },
                    {
                      key: 'programName',
                      title: 'Programs',
                    },
                    {
                      key: 'action',
                      title: 'Action',
                    },
                  ]}
                  rows={favoriteRecords.map((favoriteRecord) => ({
                    id: favoriteRecord.id,
                    lenderName:
                      favoriteRecord.lender?.name ||
                      favoriteRecord.ventureCapitalFirm?.name,
                    organizationType: favoriteRecord.lender ? 'Lender' : 'VC',
                    lenderType:
                      Object.values(PROVIDER_TYPES).find(
                        (lenderType) =>
                          (favoriteRecord.lender?.lenderType ||
                            favoriteRecord.ventureCapitalFirm?.lenderType) ===
                          lenderType.value
                      )?.annualReportLabel || '',
                    requiresBusinessPlan: ({ key }) => (
                      <td key={key}>
                        {favoriteRecord.lender?.requiresBusinessPlan ||
                        favoriteRecord.ventureCapitalFirm
                          ?.requiresBusinessPlan ? (
                          <LargeCheckBox
                            checked
                            disabled
                            size="sm"
                            centeringClass="text-left"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="text-muted ml-8"
                            icon={faTimes}
                          />
                        )}
                      </td>
                    ),
                    fundsStartups: ({ key }) => (
                      <td key={key}>
                        {favoriteRecord.lender?.fundsStartups ||
                        favoriteRecord.ventureCapitalFirm?.fundsStartups ? (
                          <LargeCheckBox
                            checked
                            disabled
                            size="sm"
                            centeringClass="text-left"
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="text-muted ml-8"
                            icon={faTimes}
                          />
                        )}
                      </td>
                    ),
                    programName: ({ key }) => (
                      <td key={key}>
                        <Button
                          size="sm"
                          className="rounded"
                          onClick={() =>
                            showProgramsOfferedModal(
                              favoriteRecord.lender ||
                                favoriteRecord.ventureCapitalFirm
                            )
                          }
                        >
                          View
                        </Button>
                      </td>
                    ),
                    action: ({ key }) => (
                      <td key={key} align="right">
                        <div className="d-flex flex-row justify-content-end">
                          <Button
                            size="sm"
                            onClick={() =>
                              handleRemoveSavedOpportunityFromDashboard(
                                favoriteRecord.id
                              )
                            }
                            className="rounded mr-8"
                            variant="outline-primary"
                          >
                            Unsave
                          </Button>
                          <Button
                            variant="outline-dark"
                            size="sm"
                            className="rounded"
                            onClick={() =>
                              history.push(
                                `${history.location.pathname}/${
                                  favoriteRecord.lender ? 'lenders' : 'vc'
                                }/${
                                  favoriteRecord.lender
                                    ? favoriteRecord.lenderId
                                    : favoriteRecord.ventureCapitalFirmId
                                }`
                              )
                            }
                          >
                            Details
                          </Button>
                        </div>
                      </td>
                    ),
                  }))}
                  onSort={(sort) => handleBusinessDashboardSortChange(sort)}
                  sort={sort}
                />
              </>
              {/*<ApplicationsList />*/}
            </Card>
          </Col>
        </Row>
        <ProgramsOfferedModal
          onHide={hideProgramsOfferedModal}
          selectedOpportunity={businessDashboardSignal.value.selectedOpportunity}
          show={businessDashboardSignal.value.programsOfferedModalVisible}
        />
    </BusinessWrapper>
  );
};

export default BusinessDashboard;
