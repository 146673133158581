import Signal from 'libs/signals';
import { VcPortfolioCompany } from 'types';

type VCDealFlowsSignal = {
  removeVcPortfolioCompany: boolean;
  showDetailsModal: boolean;
  vcPortfolioCompanies: VcPortfolioCompany[];
  selectedVCPortfolioCompany: VcPortfolioCompany | null;
};

const VCDealFlowsSignal = Signal<VCDealFlowsSignal>({
  removeVcPortfolioCompany: false,
  showDetailsModal: false,
  vcPortfolioCompanies: [],
  selectedVCPortfolioCompany: null,
});

export default VCDealFlowsSignal;
