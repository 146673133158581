import { useMsal } from '@azure/msal-react';
import { performMsalLogoutViaRedirect } from '../../../libs/functions/global.functions';

const SignOutButton = ({ className }) => {
  const { accounts, instance } = useMsal();

  return (
    <div
      id="sign-out-btn"
      className={className}
      onClick={async () => performMsalLogoutViaRedirect(instance, accounts[0])}
    >
      Sign Out
    </div>
  );
};

export default SignOutButton;
