import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import $capitalCallPortion from '../capitalCallPortion.signal';
import {
  CAPITAL_CALL_PORTION_MODAL,
  handleConfirmFundsClick,
  handleModalClose,
} from '../capitalCallPortion.helpers';
import { Button, Modal } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import Field from 'components/global/Field';

const ConfirmFundsModal = () => {
  const { modalVisible } = $capitalCallPortion.value;

  return (
    <ModalWrapper
      show={modalVisible === CAPITAL_CALL_PORTION_MODAL.confirm}
      centered
      onHide={handleModalClose}
    >
      <Modal.Header>
        <h5>Confirm Funds have been Received</h5>
        <CloseModalButton
          onCloseModal={handleModalClose}
        />
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to set the funds as received?</p>
        <Field.TextArea
          label="Note"
          labelClassName="font-weight-bold"
          value={$capitalCallPortion.value.note}
          onChange={(e) => $capitalCallPortion.update({ note: e.value })}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          className="mr-16"
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button onClick={handleConfirmFundsClick}>Confirm</Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ConfirmFundsModal;
