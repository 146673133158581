import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import userRequestsSignal from './UserRequests.signal';
import loaderSignal from 'signals/Loader.signal';
import history from '../../../history';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetUserRequests = async (signalKey) => {
  const include = {
    userInvite: {
      include: {
        toAccount: {
          include: {
            edo: true,
            business: true,
            lender: true,
            ventureCapitalFirm: true,
          },
        },
      },
    },
  };
  loaderSignal.update({ isContentLoading: true });
  try {
    const userRequests = await api.get({
      path: '/signUpRequests',
      options: {
        where: xFormAppliedFiltersToWhereClause(signalKey),
        include,
        orderBy: xFormSortToOrderByClause(),
      },
    });
    if (signalKey) {
      return userRequestsSignal.update({
        [signalKey]: userRequests,
        enableApplyButton: false,
      });
    } else {
      return userRequestsSignal.update({
        userRequests,
        enableApplyButton: false,
      });
    }
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: 'Unable to fetch user Invites',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return [{ id: 'desc' }];
  }

  const [key, dir] = sort.split('-');

  if (key === 'name') {
    return [{ lastName: dir }];
  } else if (key === 'status') {
    return [{ userInvite: { [key]: dir } }];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = (signalKey) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const where = {};

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.firstName) {
    where.firstName = { contains: params.firstName, mode: 'insensitive' };
  }

  if (params.lastName) {
    where.lastName = { contains: params.lastName, mode: 'insensitive' };
  }

  if (params.email) {
    where.email = { contains: params.email, mode: 'insensitive' };
  }

  if (params.portal) {
    where.portal = params.portal;
  }

  switch (signalKey) {
    case 'lenderUserRequests':
      where.portal = PORTAL_TYPES.lender;
      break;
    case 'vcUserRequests':
      where.portal = PORTAL_TYPES.vc;
      break;
    case 'businessUserRequests':
      where.portal = PORTAL_TYPES.business;
      break;
    default:
      break;
  }

  if (params.organizationName) {
    where.organizationName = {
      contains: params.organizationName,
      mode: 'insensitive',
    };
  }

  return where;
};
