import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

export const PaginationButton = ({ onClick, increment = true, hidden, disabled }) => (
  <Button
    disabled={disabled}
    className="m-auto"
    onClick={onClick}
    hidden={hidden}
  >
    <FontAwesomeIcon icon={increment ? faChevronRight : faChevronLeft} />
  </Button>
);

export default PaginationButton;
