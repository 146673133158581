/* eslint-disable no-shadow */
import Currency from './FieldComponents/Currency';
import CurrencyDecimal from './FieldComponents/CurrencyDecimal';
import Country from './FieldComponents/Country';
import TextInput from './FieldComponents/TextInput';
import DateSelect from './FieldComponents/DateSelect';
import Email from './FieldComponents/Email';
import Ein from './FieldComponents/Ein';
import Integer from './FieldComponents/Integer';
import Percent from './FieldComponents/Percent';
import Phone from './FieldComponents/Phone';
import MultiSelect from './FieldComponents/MultiSelect';
import State from './FieldComponents/State';
import TextArea from './FieldComponents/TextArea';
import ToggleSwitch from './FieldComponents/ToggleSwitch';
import Radio from './FieldComponents/Radio';
import Select from './FieldComponents/Select';
import YesNo from './FieldComponents/YesNo';
import Zip from './FieldComponents/Zip';
import SectionHeader from './FieldComponents/SectionHeader';
import Text from './FieldComponents/Text';
import FieldTable from './FieldComponents/Table';
import Title from './FieldComponents/Title';
import Checkbox from './FieldComponents/Checkbox';
import FieldButton from './FieldComponents/Button';
import AutoComplete from './FieldComponents/AutoComplete';

export const handleInputChange = (e, onChange) => {
  onChange({ name: e.target.name, value: e.target.value });
};

export const handleSelectChange = ({ name, value }) => {
  onChange({ name, value });
};

export const Field = {
  Checkbox,
  Country,
  Currency,
  CurrencyDecimal,
  DateSelect,
  Ein,
  Email,
  Integer,
  MultiSelect,
  Percent,
  Phone,
  Radio,
  Select,
  State,
  TextArea,
  TextInput,
  ToggleSwitch,
  YesNo,
  Zip,
  SectionHeader,
  Text,
  FieldTable,
  Title,
  FieldButton,
  AutoComplete,
};

export const FieldComponent = ({ type, ...props }) => {
  const components = {
    checkbox: <Field.Checkbox {...props} />,
    country: <Field.Country {...props} />,
    currency: <Field.Currency {...props} />,
    'currency-decimal': <Field.CurrencyDecimal {...props} />,
    date: <DateSelect {...props} />,
    ein: <Ein {...props} />,
    email: <Email {...props} />,
    integer: <Integer {...props} />,
    'multi-select': <MultiSelect {...props} />,
    percent: <Percent {...props} />,
    phone: <Phone {...props} />,
    radio: <Radio {...props} />,
    select: <Select {...props} />,
    state: <State {...props} />,
    'text-area': <TextArea {...props} />,
    'text-input': <TextInput {...props} />,
    'toggle-switch': <ToggleSwitch {...props} />,
    'yes-no': <YesNo {...props} />,
    zip: <Zip {...props} />,
    'section-header': <SectionHeader {...props} />,
    text: <Text {...props} />,
    table: <FieldTable {...props} />,
    title: <Title {...props} />,
    button: <FieldButton {...props} />,
    'auto-complete': <AutoComplete {...props} />,
  };

  return components[type] || null;
};

export default Field;
