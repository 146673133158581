import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import { edoProgramRequestCursor } from '../signals/EdoVCProgramRequest.signal';

const LIMIT = 20;

export const fetchEdoVCProgramRequests = async (limit = LIMIT, status = '') => {
  let options = {};
  if (status) {
    options = {
      take: limit,
      where: {
        status,
        lender: null,
      },
      include: {
        documents: true,
        ventureCapitalFirm: true,
        fundingProgram: {
          include: {
            stats: true,
            edo: true,
            supportingDocuments: true,
            referenceProgram: {
              include: {
                programType: true,
              },
            },
            bankAccountProgramAccesses: {
              include: {
                bankAccount: true,
              },
            },
            platform: true,
            stats: true,
          },
        },
        notes: true,
        watchables: true,
        logs: {
          include: {
            user: {
              select: {
                firstName: true,
                lastName: true,
              },
            },
          },
        },
      },
    };
  } else {
    options = {
      take: limit,
      where: {
        lender: null,
      },
      include: {
        documents: true,
        ventureCapitalFirm: true,
        fundingProgram: {
          include: {
            stats: true,
            edo: true,
            supportingDocuments: true,
            referenceProgram: {
              include: {
                programType: true,
              },
            },
            bankAccountProgramAccesses: {
              include: {
                bankAccount: true,
              },
            },
            platform: true,
            stats: true,
          },
        },
        notes: true,
        watchables: true,
        logs: {
          include: {
            user: {
              select: {
                firstName: true,
                lastName: true,
              },
            },
          },
        },
      },
    };
  }
  try {
    loaderSignal.update({ isContentLoading: true });
    const programRequests = await api.get({
      path: '/programRequests',
      options,
    });
    if (programRequests.length === limit) {
      edoProgramRequestCursor.update(programRequests[limit - 1].id);
    } else {
      edoProgramRequestCursor.update(null);
    }
    return programRequests;
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const paginateEdoVCProgramRequests = async (
  limit = LIMIT,
  status = ''
) => {
  const cursor = edoProgramRequestCursor.value;
  let options = {};
  if (!cursor) return;

  if (status) {
    options = {
      take: limit,
      skip: 1, // skip cursor
      cursor: {
        id: cursor,
      },
      where: {
        status,
        lender: null,
      },
    };
  } else {
    options = {
      take: limit,
      skip: 1,
      cursor: {
        id: cursor,
      },
      where: {
        lender: null,
      },
    };
  }

  try {
    loaderSignal.update({ isContentLoading: true });
    const programRequests = await api.get({
      path: '/programRequests',
      options,
    });

    if (programRequests.length === limit) {
      edoProgramRequestCursor.update(programRequests[limit - 1].id);
    } else {
      edoProgramRequestCursor.update(null);
    }
    return programRequests;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
    return [];
  } finally {
    loaderSignal.reset();
  }
};
