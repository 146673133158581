import { ocrSignal } from "../../OCR/ocr.signal";
import OCRRender from "../../OCR/OCRRender";
import { Alert } from "react-bootstrap";
import React, { useEffect } from "react";
import applicationDetailSignal from "../ApplicationDetail.signal";
import { fetchRawOcrData } from "../ApplicationDetail.helpers";
import { formatOcrDataForSubmit, parseOcrData } from "../../OCR/ocr.helpers";

const ApplicationFormTab = () => {
  const { fundingProgram, rawOcr, formData, applicationConfig } =
    applicationDetailSignal.value;
    
  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      ocrSignal.update({
        formattedOcrData: formData || {},
        sortedCategories:
          (applicationConfig?.sortedCategories as string[]) || [],
        parsedOcrData:
          (applicationConfig?.parsedOcrData as Record<string, unknown>) || {},
        modelId: fundingProgram?.ocrModelKey || '',
      });
    } else if (applicationConfig?.isUsingOcr) {
      fetchRawOcrData();
    }
  }, [formData, applicationConfig]);

  useEffect(() => {
    if (rawOcr) {
      ocrSignal.update({
        modelId: fundingProgram?.ocrModelKey || '',
        rawOcrData: rawOcr,
      });
      parseOcrData();
      formatOcrDataForSubmit();
    }
  }, [rawOcr]);

  if (!Object.keys(ocrSignal.value.formattedOcrData).length) {
    return (
      <div className="pt-40">
        <Alert className="text-center" variant="warning">
          No application form data was found.
        </Alert>
      </div>
    );
  }

  return (
    <div className="pt-40">
      <OCRRender readOnly />
    </div>
  );
};

export default ApplicationFormTab;
