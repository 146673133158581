import notificationsSignal, {
  drawerNotificationsSignal,
  notificationActionIsLoadingSignal,
  notificationCursorSignal,
  notificationIsPaginatingSignal,
  notificationScheduleSignal
} from "signals/Notification.signal";
import {
  fetchNotifications,
  fetchNotificationsForDrawer,
  markNotificationAsRead,
  paginateNotifications
} from "api/notification.api";
import loaderSignal from "signals/Loader.signal";
import userAccountSignal from "signals/UserAccount.signal";
import api from "api/api";
import AlertSignal from "signals/Alert.signal";

export const fetchAndSetNotifications = async (showLoader = true) => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const notifications = await fetchNotifications(showLoader);
    notificationsSignal.update(notifications);
  } finally {
    loaderSignal.reset();
  }
};

export const updateDrawerNotifications = async () => {
  const notifications = await fetchNotificationsForDrawer();
  drawerNotificationsSignal.update(notifications);
};

export const markRead = async (notificationId) => {
  notificationActionIsLoadingSignal.update({ [notificationId]: true });

  try {
    const notificationInteraction = await markNotificationAsRead(
      notificationId
    );
    const notifications = [...notificationsSignal.value];

    var idx = notifications.findIndex((n) => n.id == notificationId);
    if (idx !== -1) {
      notifications[idx].alertInteractions = [notificationInteraction];
      await updateDrawerNotifications();
    }
    notificationsSignal.update([...notifications]);
  } finally {
    notificationActionIsLoadingSignal.update({ [notificationId]: false });
  }
};

export const paginateAndAppendNotifications = async () => {
  if (!notificationCursorSignal.value) return;
  notificationIsPaginatingSignal.update(true);
  try {
    const prevNotifications = notificationsSignal.value;
    const notifications = await paginateNotifications();
    notificationsSignal.update([...prevNotifications, ...notifications]);
  } finally {
    notificationIsPaginatingSignal.update(false);
  }
};

export const fetchAndSetNotificationSchedule = async () => {
  const { instantEmailNotifications } = userAccountSignal.value.userData;
  try {
    loaderSignal.update({ isContentLoading: true });

    let payload = { instantEmailNotifications };

    const notificationSchedules = await api.get({
      path: '/notificationSchedules',
      options: {
        where: {
          userId: userAccountSignal.value.userData.id,
        }
      }
    });

    if (!!notificationSchedules.length) {
      const schedule = notificationSchedules[0];
      payload = {
        ...payload,
        id: schedule.id,
        secondsFromMidnight: schedule.minutesFromMidnight * 60,
        mondayEnabled: schedule.mondayEnabled,
        tuesdayEnabled: schedule.tuesdayEnabled,
        wednesdayEnabled: schedule.wednesdayEnabled,
        thursdayEnabled: schedule.thursdayEnabled,
        fridayEnabled: schedule.fridayEnabled,
        saturdayEnabled: schedule.saturdayEnabled,
        sundayEnabled: schedule.sundayEnabled,
      };
    }

    notificationScheduleSignal.update(payload);
  } catch (error) {
    AlertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleNotificationScheduleDayChange = (day) => {
  notificationScheduleSignal.update({ [day]: !notificationScheduleSignal.value[day] });
};

export const handleInstantaneousChange = () => {
  notificationScheduleSignal.update({ instantEmailNotifications: !notificationScheduleSignal.value.instantEmailNotifications });
};
export const handleNotificationScheduleTimeChange = (time) => {
  notificationScheduleSignal.update({ secondsFromMidnight: time });
};

const createNotificationSchedule = async () => {
  const {
    instantEmailNotifications,
    secondsFromMidnight,
    sundayEnabled,
    mondayEnabled,
    tuesdayEnabled,
    wednesdayEnabled,
    thursdayEnabled,
    fridayEnabled,
    saturdayEnabled,
  } = notificationScheduleSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    const newNotificationSchedule = await api.post({
      path: '/notificationSchedules',
      body: {
        data: {
          instantEmailNotifications,
          minutesFromMidnight: secondsFromMidnight > 0 ? secondsFromMidnight / 60 : 0,
          sundayEnabled,
          mondayEnabled,
          tuesdayEnabled,
          wednesdayEnabled,
          thursdayEnabled,
          fridayEnabled,
          saturdayEnabled,
        }
      }
    });

    notificationScheduleSignal.update({ id: newNotificationSchedule.id });

    AlertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: 'Your notification schedule has been saved.'
    });
  } catch (error) {
    AlertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

const editNotificationSchedule = async () => {
  const {
    id,
    instantEmailNotifications,
    secondsFromMidnight,
    sundayEnabled,
    mondayEnabled,
    tuesdayEnabled,
    wednesdayEnabled,
    thursdayEnabled,
    fridayEnabled,
    saturdayEnabled,
  } = notificationScheduleSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/notificationSchedules',
      body: {
        where: {
          id,
        },
        data: {
          instantEmailNotifications,
          minutesFromMidnight: secondsFromMidnight / 60,
          sundayEnabled,
          mondayEnabled,
          tuesdayEnabled,
          wednesdayEnabled,
          thursdayEnabled,
          fridayEnabled,
          saturdayEnabled,
        }
      }
    });

    AlertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Your notification schedule has been saved.'
    });
  } catch (error) {
    AlertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSaveClick = async () => {
  const { id } = notificationScheduleSignal.value;

  if (id) {
    await editNotificationSchedule();
  } else {
    await createNotificationSchedule();
  }
};

