import { Alert } from '../../../types';
import Signal from '../../../libs/signals';

type PushNotificationsSignal = {
  notification: Alert | null;
};

/*
const FAKE_NOTIFICATION = {
  id: 37,
  key: 'EDO_ADDED_NEW_EQUITY_PROGRAM',
  model: 'FundingProgram',
  modelId: 14,
  createdDate: '2023-06-30T13:40:37.747Z',
  createdBy: null,
  updatedDate: '2023-06-30T13:40:37.747Z',
  updatedBy: null,
  accountId: null,
  alertInteractions: [],
};
 */

const pushNotificationsSignal = Signal<PushNotificationsSignal>({
  notification: null,
});

export default pushNotificationsSignal;
