import {
  applicationsChartPresets, businessDemographicsBarGraphPresets,
} from 'components/global/ChartJS/chartPresetsAndStyles';
import Signal from 'libs/signals';

const vcDashboardSignal = Signal({
  step: 1,
  programRequests: [],
  funds: [],
  portfolioCompanies: [],
  fundingPrograms: [],
  selectedFundingProgramId: '',
  platforms: [],
  selectedPlatformId: '',
  applications: [],
  programRequestMetrics: applicationsChartPresets,
  fundMetrics: applicationsChartPresets,
  vcPortfolioCompanyMetrics: applicationsChartPresets,
  businessDemographicsMetrics: businessDemographicsBarGraphPresets,
});

export default vcDashboardSignal;
