import Signal from 'libs/signals';

type EdoSediSignal = {
  obligated: Record<
    string,
    {
      agg: number;
      sum: number;
    }
  >;
  expended: Record<
    string,
    {
      agg: number;
      sum: number;
    }
  >;
};

const edoSediSignal = Signal<EdoSediSignal>({
  obligated: {},
  expended: {},
});
export default edoSediSignal;
