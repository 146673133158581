import CloseModalButton from "components/global/Constant/CloseModalButton";
import ModalWrapper from "components/global/ModalWrapper/ModalWrapper";
import { Alert, Button, Modal, Table } from "react-bootstrap";
import edoOrganizationSignal from "signals/EdoOrganization.signal";
import { handleHideApplicationDetailsModal } from "../edoOrganization.helpers";
import { useHistory } from "react-router-dom";

const LinkedApplicationDetailsModal = () => {
  const history = useHistory();
  const { selectedBankAccount, applicationDetailsModalVisible } =
    edoOrganizationSignal.value;
  const applications = [];
  selectedBankAccount?.bankAccountProgramAccesses.forEach((bapa) => {
    const bapaApplications = bapa.fundingProgram.applications.map(
      (application) => ({
        ...application,
        fundingProgramName: bapa.fundingProgram.name,
      })
    );
    applications.push(...bapaApplications);
  });

  if (!selectedBankAccount) {
    return null;
  }

  return (
    <ModalWrapper
      show={applicationDetailsModalVisible}
      onHide={handleHideApplicationDetailsModal}
      centered
      size="lg"
    >
      <Modal.Header>
        <h5>
          {selectedBankAccount.bankAccountName} -{' '}
          {selectedBankAccount.account.lender?.name ||
            selectedBankAccount.account.ventureCapitalFirm?.name}
        </h5>
        <CloseModalButton onCloseModal={handleHideApplicationDetailsModal} />
      </Modal.Header>
      <Modal.Body>
        {applications.length ? (
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Program</th>
                <th>Business</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application) => (
                <tr key={application.id}>
                  <td>{application.id}</td>
                  <td>{application.fundingProgramName}</td>
                  <td>{application.business?.name || '-'}</td>
                  <td>
                    <Button
                      size="sm"
                      className="rounded"
                      variant="outline-dark"
                      onClick={() => {
                        edoOrganizationSignal.update({
                          applicationDetailsModalVisible: false,
                        });
                        history.push(
                          `${history.location.pathname}/application/${application.id}`
                        );
                      }}
                    >
                      Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="warning">
            This bank account does not have any associated applications.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={handleHideApplicationDetailsModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default LinkedApplicationDetailsModal;
