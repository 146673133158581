import { applicationsChartPresets } from 'components/global/ChartJS/chartPresetsAndStyles';
import Signal from 'libs/signals';

export default Signal({
  loanData: [],
  favoriteRecords: [],
  selectedOpportunity: null,
  programsOfferedModalVisible: false,
  dropdownFilters: {},
  appliedFilters: {},
  sort: { key: 'id', dir: 'desc' },
  applicationsMetrics: applicationsChartPresets,
  loanChartObj: applicationsChartPresets,
});
