import { useEffect } from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import {
  CAPITAL_CALL_PORTION_MODAL,
  fetchAndSetCapitalCallPortion,
  handleInvestmentPerformanceChange,
  handleSubmitInvestmentPerformance,
} from './capitalCallPortion.helpers';
import $capitalCallPortion from './capitalCallPortion.signal';
import { useLocation } from 'react-router-dom';
import formatMoney from 'utils/formatMoney';
import ConfirmFundsModal from './components/ConfirmFundsModal';
import FundsMissingModal from './components/FundsMissingModal';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';
import { handleCapTableClick } from '../CapTable/capTable.helpers';
import CapTableModal from '../CapTable/CapTableModal';
import Field from '../Field';
import CapitalCallPortionNotesModal from './components/CapitalCallPortionNotesModal';

const CapitalCallPortion = () => {
  const {
    capitalCallPortion,
    equityInvestmentGains,
    ssbciFundsLost,
    subsequentPrivateFinancing,
  } = $capitalCallPortion.value;
  const { PORTAL_TYPES, CAPITAL_CALL_STATUS } = $appSettings.value.constants;
  const location = useLocation();
  const segments = location.pathname.split('/');
  const id = segments[segments.length - 1];
  const statusHasMovedPastPending =
    capitalCallPortion?.capitalCall?.status === CAPITAL_CALL_STATUS.approved ||
    capitalCallPortion?.capitalCall?.status ===
      CAPITAL_CALL_STATUS.submittedForApproval ||
    capitalCallPortion?.capitalCall?.status === CAPITAL_CALL_STATUS.funded ||
    capitalCallPortion?.capitalCall?.status === CAPITAL_CALL_STATUS.disbursed;

  const capTable = statusHasMovedPastPending
    ? capitalCallPortion?.capTable?.tableData
    : capitalCallPortion?.vcPortfolioCompany?.business?.capTable?.tableData;

  useEffect(() => fetchAndSetCapitalCallPortion(id), []);

  return (
    <Card className="rounded-0 mt-40">
      <CapitalCallPortionNotesModal />
      <ConfirmFundsModal />
      <FundsMissingModal />
      <CapTableModal />
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0">Capital Call Transaction</h3>
          <div>
            <Button
              className="mr-16"
              variant="outline-secondary"
              onClick={() =>
                $capitalCallPortion.update({
                  modalVisible: CAPITAL_CALL_PORTION_MODAL.notes,
                })
              }
            >
              View Notes
            </Button>
            <Button
              hidden={
                userAccountSignal.value.userData.account.portalType !==
                PORTAL_TYPES.business
              }
              onClick={() =>
                handleCapTableClick(
                  capTable,
                  () => fetchAndSetCapitalCallPortion(id),
                  null,
                  !statusHasMovedPastPending
                )
              }
            >
              {statusHasMovedPastPending
                ? 'View Cap Table'
                : 'Configure Cap Table'}
            </Button>
          </div>
        </div>
        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.business &&
          capitalCallPortion?.capitalCall?.status ===
            CAPITAL_CALL_STATUS.funded &&
          !capitalCallPortion.fundedConfirmedDate &&
          new Date(capitalCallPortion.fundsPushedToBusinessDate) >
            new Date(capitalCallPortion.fundsMissingSubmittedDate) && (
            <>
              <hr />
              <Alert>
                <h5>Confirm Funds</h5>
                <p>
                  The VC has sent funds to your account. Please confirm funds in
                  the amount listed below have been received.
                </p>
                <div className="d-flex flex-row justify-content-end">
                  <Button
                    className="mr-16"
                    variant="outline-primary"
                    onClick={() =>
                      $capitalCallPortion.update({
                        modalVisible: CAPITAL_CALL_PORTION_MODAL.confirm,
                      })
                    }
                  >
                    Funds Received
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() =>
                      $capitalCallPortion.update({
                        modalVisible: CAPITAL_CALL_PORTION_MODAL.missing,
                      })
                    }
                  >
                    Funds are Missing
                  </Button>
                </div>
              </Alert>
            </>
          )}
        {new Date(capitalCallPortion?.fundsMissingSubmittedDate) >
          new Date(capitalCallPortion?.fundsPushedToBusinessDate) &&
          !capitalCallPortion.fundedConfirmedDate && (
            <Alert variant="warning" className="mt-8">
              <h5>Missing funds claim has been submitted.</h5>
              <p className="mb-0">
                Claim was submitted{' '}
                {new Date(
                  $capitalCallPortion.value.capitalCallPortion.fundsMissingSubmittedDate
                ).toLocaleDateString()}
              </p>
            </Alert>
          )}
        {capitalCallPortion?.fundedConfirmedDate && (
          <Alert variant="success">
            <h5>Funds have been received.</h5>
          </Alert>
        )}
        <hr />
        <Row>
          <Col>
            <p className="font-weight-bold">Program</p>
            <p>
              {capitalCallPortion &&
                capitalCallPortion.capitalCall?.fund.fundingProgram.name}
            </p>
          </Col>
          <Col>
            <p className="font-weight-bold">Fund</p>
            <p>
              {capitalCallPortion &&
                capitalCallPortion.capitalCall?.fund.fundName}
            </p>
          </Col>
          {userAccountSignal.value.userData.account.portalType !==
            PORTAL_TYPES.vc && (
            <Col>
              <p className="font-weight-bold">Venture Capitalist</p>
              <p>
                {capitalCallPortion &&
                  capitalCallPortion.capitalCall?.fund.fundingProgramMembership
                    .ventureCapitalFirm.name}
              </p>
            </Col>
          )}
          {userAccountSignal.value.userData.account.portalType !==
            PORTAL_TYPES.business && (
            <Col>
              <p className="font-weight-bold">Business</p>
              <p>
                {capitalCallPortion &&
                  capitalCallPortion.vcPortfolioCompany.business.name}
              </p>
            </Col>
          )}
          <Col>
            <p className="font-weight-bold">Date</p>
            <p>
              {capitalCallPortion &&
                new Date(
                  capitalCallPortion.capitalCall?.createdDate
                ).toLocaleDateString()}
            </p>
          </Col>
          <Col>
            <p className="font-weight-bold">Amount</p>
            <p>{formatMoney(capitalCallPortion?.amount)}</p>
          </Col>
        </Row>
        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.vc &&
          capitalCallPortion?.capitalCall?.status ===
            CAPITAL_CALL_STATUS.funded && (
            <>
              <hr />
              <h5>Investment Performance</h5>
              <Row>
                <Col>
                  <Field.Currency
                    label="Subsequent Private Financing"
                    labelClassName="font-weight-bold"
                    name="subsequentPrivateFinancing"
                    value={subsequentPrivateFinancing}
                    onChange={handleInvestmentPerformanceChange}
                  />
                </Col>
                <Col>
                  <Field.Currency
                    label="SSBCI Funds Lost"
                    labelClassName="font-weight-bold"
                    name="ssbciFundsLost"
                    value={ssbciFundsLost}
                    onChange={handleInvestmentPerformanceChange}
                  />
                </Col>
                <Col>
                  <Field.Currency
                    label="Equity Investment Gains"
                    labelClassName="font-weight-bold"
                    name="equityInvestmentGains"
                    value={equityInvestmentGains}
                    onChange={handleInvestmentPerformanceChange}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-24">
                <Button onClick={handleSubmitInvestmentPerformance}>
                  Submit Investment Performance
                </Button>
              </div>
            </>
          )}
      </Card.Body>
    </Card>
  );
};

export default CapitalCallPortion;
