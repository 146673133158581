/* eslint-disable no-unreachable */
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import expressInterestSignal from './ExpressInterest.signal';
import loaderSignal from 'signals/Loader.signal';

export const handleExpressInterestFormChange = (e) =>
  expressInterestSignal.update({ [e.target.name]: e.target.value });

export const handleExpressInterestSubmission = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting request...',
  });
  try {
    const { organizationName, firstName, lastName, portal, email } =
      expressInterestSignal.value;
    const body = {
      data: {
        firstName,
        lastName,
        portal,
        email,
        organizationName,
      },
    };

    await api.post({
      path: '/signUpRequests',
      isUnAuthenticatedReq: true,
      body,
    });
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully sent request.',
    });
    expressInterestSignal.reset();
    return expressInterestSignal.update({ portal });
  } catch (error) {
    if (error.status === 409) {
      return alertSignal.update({
        type: 'notification',
        message: error.message,
        error,
      });
    }
    return alertSignal.update({
      type: 'notification',
      message: 'Unable to send request. See console for errors',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};
