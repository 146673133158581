import { Button, Card, Col, Row, Table } from "react-bootstrap";
import platformsSignal from "./Platforms.signal";
import { useCallback, useEffect } from "react";
import { fetchAndSetPlatformData, showEditPlatformModal, showNewPlatformModal } from "./Platforms.helpers";
import AddPlatformModal from "./components/AddPlatformModal";
import EditPlatformModal from "./components/EditPlatformModal";

const Platforms = () => {
  const { platforms } = platformsSignal.value;

  const init = useCallback(async () => {
    await fetchAndSetPlatformData();
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Card className="rounded-0">
        <Card.Body>
          <h3 className="text-center">Platforms</h3>
          <hr />
          <Row className="justify-content-between mb-16">
            <Col className="d-flex justify-content-end">
              <Button
                className="rounded"
                variant="primary"
                onClick={() => showNewPlatformModal()}
              >
                New Platform
              </Button>
            </Col>
          </Row>
          <Table responsive>
            <thead>
              <tr className="font-weight-bold">
                <th>ID</th>
                <th>Name</th>
                <th>Platform Type</th>
                <th>Transaction Leverage Ratio</th>
                <th>Money Received</th>
                <th>Money Expected</th>
                <th>Linked Programs</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {platforms.map((platform) => {
                const { fundingPrograms } = platform;
                const programString =
                  fundingPrograms.length > 0
                    ? `${fundingPrograms[0].name} ${
                        fundingPrograms.length > 1
                          ? `and ${fundingPrograms.length - 1} more...`
                          : ''
                      }`
                    : 'No programs';
                return (
                  <tr key={platform.id}>
                    <td>{platform.id}</td>
                    <td>{platform.name}</td>
                    <td>{platform.platformType}</td>
                    <td>
                      {platform.stats.loanAmount > 0
                        ? (
                            platform.stats.borrowerLoanAmount /
                            platform.stats.loanAmount
                          ).toLocaleString()
                        : '-'}
                      <small>{` (${platform.targetTransactionLeverageRatio})`}</small>
                    </td>
                    <td>${platform.stats.burned.toLocaleString()}</td>
                    <td>${platform.stats.allocated.toLocaleString()}</td>
                    <td>{programString}</td>
                    <td>
                      <Button
                        size="sm"
                        className="rounded"
                        onClick={() => showEditPlatformModal(platform)}
                        variant="outline-dark"
                      >
                        Details
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <AddPlatformModal />
      <EditPlatformModal />
    </>
  );
};

export default Platforms;
