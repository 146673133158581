import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Modal } from 'react-bootstrap';
import { FUND_DETAIL_MODALS, handleRequestAccessModalClose, handleSubmitFundRequestClick } from '../fundDetails.helpers';
import fundDetailsSignal from '../fundDetails.signal';

const RequestFundAccessModal = () => {
  const { modalVisible } = fundDetailsSignal.value;

  return (
    <ModalWrapper show={modalVisible === FUND_DETAIL_MODALS.requestAccess} centered onHide={handleRequestAccessModalClose}>
      <Modal.Header className="d-flex flex-row justify-content-between">
        <h5 className="mb-0">Request Access to Fund</h5>
        <CloseModalButton onCloseModal={handleRequestAccessModalClose} />
      </Modal.Header>
      <Modal.Body>
        <p>
          The VC will be notified of your interested in this fund. Do you want to
          submit your request?
        </p>
        <div className="d-flex flex-row justify-content-end">
          <Button className="rounded text-right" onClick={handleSubmitFundRequestClick}>Submit Request</Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-end">
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={handleRequestAccessModalClose}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
}


export default RequestFundAccessModal;
