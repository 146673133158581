import Signal from 'libs/signals';

const searchBusinessesSignal = Signal({
  searchForm: {
    industry: '',
    location: '',
    fundingRange: '',
  },
  searchFormErrors: {
    location: false,
  },
  savedSearches: [],
});

export default searchBusinessesSignal;
