import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import CloseModalButton from "../../Constant/CloseModalButton";
import { hideAddAccountModal } from "../../AddAccountModal/AddAccountModal.helpers";
import {
  handleQuarterlyReportUpload,
  handleQuarterReportUploadSelectChange,
  handleUploadQuarterlyReport,
  hideUploadQuarterlyReportModal
} from "../Reporting.helpers";
import Field from "../../Field";
import { quarterlyReportUploadQuarterOptions, quarterlyReportUploadYearOptions } from "../reportConstants";
import { useRef } from "react";
import { reportUploadSignal } from "../Reporting.signals";

const UploadQuarterlyReportModal = () => {
  const fileInputRef = useRef(null);
  const { modalVisible, quarter, year, files } = reportUploadSignal.value;

  return (
    <ModalWrapper
    className="border border-dark rounded upper-modal"
    backdropClassName="upper-modal-backdrop"
    show={modalVisible}
    size="lg"
    centered
  >
    <Modal.Header>
      <h3>Upload Quarterly Report</h3>
      <CloseModalButton onCloseModal={hideAddAccountModal} />
    </Modal.Header>
    <Modal.Body className="p-16">
      <Row>
        <Col>
          <Field.Select
            label="Quarter"
            labelClassName="font-weight-bold mb-8"
            inputClassName="form-control"
            value={quarter}
            name="quarter"
            onChange={handleQuarterReportUploadSelectChange}
            options={quarterlyReportUploadQuarterOptions}
          />
        </Col>
        <Col>
          <Field.Select
            label="Year"
            labelClassName="font-weight-bold mb-8"
            inputClassName="form-control"
            value={year}
            name="year"
            onChange={handleQuarterReportUploadSelectChange}
            options={quarterlyReportUploadYearOptions}
          />

        </Col>
      </Row>
      <Row>
        <Col className="mt-16">
          <Form.Label className="font-weight-bold">File</Form.Label>
      <div
        className="border px-8 py-1 overflow-hidden"
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleQuarterlyReportUpload}
          accept=".xls,.xlsx"
        />
      </div>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer className="justify-content-end">
      <Button
        type="button"
        onClick={() => hideUploadQuarterlyReportModal()}
        variant="outline-secondary"
        className="mr-8 rounded"
      >
        Dismiss
      </Button>
      <Button
        className="rounded"
        id="add-account-modal-submit-btn"
        variant="primary"
        onClick={() => handleUploadQuarterlyReport(fileInputRef)}
        disabled={!quarter || !files.length || !year}
      >
        Upload
      </Button>
    </Modal.Footer>
  </ModalWrapper>);
}

export default UploadQuarterlyReportModal;
