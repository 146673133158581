import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  searchProgramsDocumentPreviewURLSignal,
  searchProgramsResultsFilesSignal,
  searchProgramsResultsModalStateSignal,
} from 'components/global/SearchProgramResults/SearchProgramsResults.signal';

const ProgramRequestDocumentPreview = () => {
  const files = searchProgramsResultsFilesSignal.value;
  const url = searchProgramsDocumentPreviewURLSignal.value;

  useEffect(() => {
    const reader = new FileReader();
    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
    }

    reader.addEventListener('load', () => {
      searchProgramsDocumentPreviewURLSignal.update(reader.result);
    }, false);
  }, [searchProgramsResultsFilesSignal.value]);

  return (
    <>
      <Row>
        <Col>
          <iframe
            src={url}
            title={files[0]?.name}
            width="100%"
            height={800}
          />
        </Col>
      </Row>
      <Row className="mx-16 mb-16 justify-content-end">
        <Button
          className="rounded"
          variant="secondary"
          onClick={() => searchProgramsResultsModalStateSignal.update({ step: 2 })}
        >
          Back
        </Button>
      </Row>
    </>
  );
};

export default ProgramRequestDocumentPreview;
