import applicationDetailSignal, {
  ApplicationDetailType,
} from './ApplicationDetail.signal';
import { Signal } from '@preact/signals-react';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import {
  Application,
  ApplicationApprovalChainConfirmation,
  FundingProgram,
} from 'types';
import loaderSignal from 'signals/Loader.signal';
import { PROGRAM_KEYS } from '../Constant/constants';
import userAccountSignal from 'signals/UserAccount.signal';
import { downloadFromUrl } from 'libs/functions/file';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import {
  handleCapitalAccessChange,
  handleCollateralSupportChange,
  handleLoanGuarantyChange,
  handleLoanParticipationChange,
  SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS,
  validateCapitalAccessApplication,
  validateCollateralSupportApplication,
  validateLoanGuarantyApplication,
  validateLoanParticipationApplication,
} from 'libs/applications';
import { refreshListAndModal } from '../Approvable/Approvable.helpers';
import ledgerSignal from '../Ledger/Ledger.signals';
import { DateTime } from 'luxon';
import $appSettings from 'signals/AppSettings.signal';

export const txApplicationToApplicationDetailsModal = (
  application: Application,
  ventureCapitalist: boolean
): Partial<ApplicationDetailType> => {
  const {
    business,
    fundingProgramMembership,
    applicationConfig,
    formData,
    supportingDocuments,
    edoUnreadDate,
    fundingProgram,
    technicalAssistance,
  } = application;
  ledgerSignal.update({ application });
  const lender = fundingProgramMembership?.lender;

  const linkedBankAccount = fundingProgram.bankAccountProgramAccesses?.find(
    (access) => access?.bankAccount?.accountId === lender?.accountId
  );

  return {
    id: application.id,
    status: application.status,
    watchListStatus:
      application.watchables.length !== 0
        ? application.watchables[0].status
        : null,
    ventureCapitalist,
    disbursementDate: application.disbursementDate,
    disbursementAmount: application.disbursementAmount,
    firstRepaymentDate: application.disbursements.length
      ? application.loan?.firstRepaymentDate
      : null,
    disbursements: application.disbursements,
    disbursementConfig: application.disbursementConfig,
    approvedAmount: application.approvedAmount,
    fundingProgramStats: application.fundingProgram.stats,
    fundingProgram: application.fundingProgram,
    business: {
      id: business?.id,
      name:
        business?.name ||
        formData?.borrowerInformation_businessName ||
        formData?.businessInformation_businessName ||
        undefined,
      contactName:
        business?.contactName ||
        formData?.borrowerInformation_contactName ||
        formData?.businessInformation_contactName ||
        undefined,
      email:
        business?.contactEmail ||
        formData?.borrowerInformation_email ||
        formData?.businessInformation_email ||
        formData?.businessInformation_emailAddress ||
        undefined,
      phoneNumber:
        business?.phoneNumber ||
        formData?.borrowerInformation_contactPhone ||
        formData?.businessInformation_contactPhone ||
        formData?.businessInformation_phone ||
        undefined,
    },
    lender: lender
      ? {
        id: lender.id,
        name: lender.name,
        contactName: lender.contactName,
        email: lender.email,
        phoneNumber: lender.phoneNumber,
      }
      : null,
    notes: application.notes,
    documents: application.supportingDocuments.sort(
      (a, b) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    ),
    watchableId:
      application.watchables.find(
        (w) =>
          w.applicationId === application.id &&
          userAccountSignal.value.userData.id
      )?.id || null,
    modelType: 'application',
    uploadDocumentType: 'applicationDocuments',
    lenderUnread: application.lenderUnread,
    edoUnread: application.edoUnread,
    newWatchListStatus:
      application.watchables.length !== 0
        ? application.watchables[0].status
        : null,
    applicationConfig,
    formData,
    approvalAttachments: supportingDocuments.filter(
      (ad) => ad.approvalAttachment
    ),
    approvalChainTemplate:
      application.approvalChainConfig?.approvalChainTemplate,
    approvalChainTemplates: [
      ...application.fundingProgram.approvalChainTemplates.sort(
        (a, b) => b.id - a.id
      ),
    ],
    approvalChainConfirmations: application.approvalChainConfirmations,
    requestedAmount: application.askedAmount,
    submittedByEdo: application.submittedByEdo,
    approvedOnCommit: application.approvedOnCommit,
    approvalChainConfig: application.approvalChainConfig,
    createdDate: application.createdDate,
    updatedDate: application.updatedDate,
    edoUnreadDate,
    hasLinkedBankAccount: !!linkedBankAccount,
    usesCaatPayment: fundingProgram.platform.caatForPaymentReconciliation,
    technicalAssistance,
  };
};

export const showApplicationDetailsModal = (
  payload: ApplicationDetailType
): Signal<ApplicationDetailType> =>
  applicationDetailSignal.update({
    ...payload,
    visible: true,
  });

export const hideApplicationDetailsModal = (): undefined =>
  applicationDetailSignal.reset();

export const handleWatchListStatusDropdownChange = (
  e: React.ChangeEvent<HTMLSelectElement>
): Signal<ApplicationDetailType> => {
  const { value } = e.target;

  applicationDetailSignal.update({
    formErrors: {
      ...applicationDetailSignal.value.formErrors,
      watchListStatusInvalid: false,
    },
  });
  return applicationDetailSignal.update({ newWatchListStatus: value });
};

const validateApplication = (): boolean => {
  const { newApplicationStatus, approvalChainForm, approvalChainTemplates } =
    applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  const approvalChainTemplate =
    approvalChainTemplates.length > 0 ? approvalChainTemplates[0] : null;

  if (!approvalChainTemplate) {
    alertSignal.update({
      type: 'notification',
      message: 'There is no approval chain template. Aborting.',
    });
    return false;
  }

  if (newApplicationStatus === APPLICATION_STATUS.submittedForApproval) {
    if (
      approvalChainForm.skippedSteps.length ===
      approvalChainTemplate.steps.length
    ) {
      alertSignal.update({
        type: 'notification',
        message: 'Cannot skip every step in the approval chain.',
      });
      return false;
    }

    return validateApprovedInputFields();
  }

  return true;
};

const validateApprovedInputFields = () => {
  const { fundingProgramStats, fundingProgram, approvedInputFields } =
    applicationDetailSignal.value;

  const {
    referenceProgram: { key },
    programConfig,
  } = fundingProgram as FundingProgram;

  let validationFn;

  switch (key) {
    case PROGRAM_KEYS.loanParticipation:
    case PROGRAM_KEYS.microLoan:
      validationFn = validateLoanParticipationApplication;
      break;
    case PROGRAM_KEYS.collateralSupport:
      validationFn = validateCollateralSupportApplication;
      break;
    case PROGRAM_KEYS.capitalAccess:
      validationFn = validateCapitalAccessApplication;
      break;
    case PROGRAM_KEYS.loanGuaranty:
      validationFn = validateLoanGuarantyApplication;
      break;
    default:
      alertSignal.update({
        type: 'notification',
        message: `Did not find submit for approval configuration for program key ${key}`,
      });
      return false;
  }

  const errors = validationFn(
    approvedInputFields,
    fundingProgramStats!.remaining,
    programConfig
  );

  if (Object.keys(errors).length > 0) {
    applicationDetailSignal.update({
      approvedInputFieldsErrors: { ...errors },
    });
    return false;
  } else {
    applicationDetailSignal.update({
      approvedInputFieldsErrors: {},
    });
    return true;
  }
};

export const updateApplicationStatus = async (): Promise<
  Signal<unknown> | boolean
> => {
  const valid = validateApplication();
  if (!valid) {
    return false;
  }

  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating application status...',
  });
  // const { fundingProgram, newApplicationStatus, id, approvedInputFields } = applicationDetailsModalSignal.value;
  // const { approvedAmount, ...approvedApplicationConfig } = approvedInputFields;

  const {
    newApplicationStatus,
    id,
    approvedInputFields,
    approvalChainTemplates,
    status,
    approvalChainConfig,
  } = applicationDetailSignal.value;
  const { borrowerEquity, approvedAmount, disbursementDate } =
    approvedInputFields;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  let data: Record<string, any> = {
    status: newApplicationStatus,
  };

  if (
    newApplicationStatus === APPLICATION_STATUS?.submittedForApproval ||
    (status === APPLICATION_STATUS?.approved && !approvalChainConfig)
  ) {
    const approvalChainTemplate = approvalChainTemplates[0];
    const { approvalChainForm } = applicationDetailSignal.value;

    const approvedInputFields =
      applicationDetailSignal.value.approvedInputFields;

    const cleanDisbursementConfig = Object.entries(approvedInputFields).reduce(
      (acc, [key, value]) => {
        if (value instanceof Date) {
          return { ...acc, [key]: value.toISOString().split('T')[0] };
        } else if (isNaN(Number(value))) {
          return { ...acc, [key]: value };
        } else {
          return { ...acc, [key]: Number(value) };
        }
      },
      {}
    );

    data = {
      ...data,
      borrowerEquity: Number(borrowerEquity),
      approvedAmount: Number(approvedAmount),
      disbursementDate: (disbursementDate as Date).toISOString().split('T')[0],
      disbursementConfig: {
        ...cleanDisbursementConfig,
      },
      approvalChainConfig: {
        approvalChainTemplate: {
          id: approvalChainTemplate.id,
        },
        config: {
          keyDates: Object.entries(approvalChainForm.keyDates).reduce(
            (acc, [key, date]) => ({
              ...acc,
              [key]: DateTime.fromJSDate(date).toISODate(),
            }),
            {}
          ),
          skippedSteps: approvalChainForm.skippedSteps,
          fallbackToAlternatesSteps:
            approvalChainForm.fallbackToAlternatesSteps,
        },
      },
    };

    if (status === APPLICATION_STATUS?.approved && !approvalChainConfig) {
      delete data.status;
      data.action = 'retroactiveApproval';
    }
  }

  try {
    await api.patch({
      path: '/applications',
      body: {
        where: {
          id,
        },
        data,
      },
    });

    await refreshListAndModal(
      id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({ confirmStatusVisible: false });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated status.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const getWatchableType = (modelType: ApplicationDetailType['modelType']) => {
  const { WATCHABLE_TYPES } = $appSettings.value.constants;
  if (modelType === 'application') {
    return WATCHABLE_TYPES?.application;
  }

  return WATCHABLE_TYPES?.programRequest;
};

export const createOrUpdateApplicationToWatchlist = async (): Promise<
  Signal<unknown> | undefined
> => {
  const { newWatchListStatus, modelType, formErrors } =
    applicationDetailSignal.value;
  const isApplication = modelType === 'application';
  if (!newWatchListStatus && isApplication) {
    applicationDetailSignal.update({
      formErrors: {
        ...formErrors,
        watchListStatusInvalid: true,
      },
    });
    return;
  }

  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Updating watchlist...',
    });
    const { id, ventureCapitalist } = applicationDetailSignal.value;
    await api.put({
      path: '/watchables',
      body: {
        ...(isApplication
          ? { applicationId: id, status: newWatchListStatus }
          : { programRequestId: id }),
        watchableType: getWatchableType(modelType),
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await refreshListAndModal(id!, modelType, ventureCapitalist);
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated watchlist',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const removeApplicationFromWatchlist = async (
  watchableId: number
): Promise<Signal<unknown>> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Removing application from watchlist...',
  });

  try {
    const applicationId = applicationDetailSignal.value.id;
    await api.delete({
      path: '/watchables',
      body: {
        where: {
          id: watchableId,
        },
      },
    });
    await refreshListAndModal(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      applicationId!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({
      newWatchListStatus: null,
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully removed from Watchlist',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleApprovedInputFieldChange = (
  e: React.ChangeEvent<HTMLInputElement>
): Signal<unknown> | boolean => {
  const { name } = e.target || e;
  const { value }: { value: string | number } = e.target || e;

  const { fundingProgram } = applicationDetailSignal.value;

  const {
    referenceProgram: { key },
  } = fundingProgram as FundingProgram;

  let changeFn = null;

  switch (key) {
    case PROGRAM_KEYS.loanParticipation:
    case PROGRAM_KEYS.microLoan:
      changeFn = handleLoanParticipationChange;
      break;
    case PROGRAM_KEYS.collateralSupport:
      changeFn = handleCollateralSupportChange;
      break;
    case PROGRAM_KEYS.capitalAccess:
      changeFn = handleCapitalAccessChange;
      break;
    case PROGRAM_KEYS.loanGuaranty:
      changeFn = handleLoanGuarantyChange;
      break;

    default:
      return alertSignal.update({
        type: 'notification',
        message: `Did not find submit for approval configuration for program key ${key}`,
      });
  }

  applicationDetailSignal.update({
    approvedInputFields: changeFn(
      name,
      value,
      applicationDetailSignal.value.approvedInputFields
    ),
    approvedInputFieldsDirty: {
      ...applicationDetailSignal.value.approvedInputFieldsDirty,
      [name]: true,
    },
  });

  return validateApprovedInputFields();
};

export const handleApprovalChainConfirmation = async (
  confirmation: ApplicationApprovalChainConfirmation,
  note: string,
  file: File | undefined,
  confirmedDate: Date | undefined = undefined
): Promise<Signal<unknown>> => {
  const { id, status, submittedByEdo } = applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  if (
    !confirmedDate &&
    submittedByEdo &&
    status === APPLICATION_STATUS?.approved
  ) {
    return alertSignal.update({
      type: 'notification',
      message: 'A confirmation date is needed.',
    });
  }

  if (note === '') {
    return alertSignal.update({
      type: 'notification',
      message: 'A note is needed.',
    });
  }

  // if (!file) {
  //   return alertSignal.update({
  //     type: 'notification',
  //     message: 'An attachment is needed.',
  //   });
  // }
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting confirmation...',
  });

  let signedUrls = [];

  if (file) {
    signedUrls = await uploadFilesToStorage(
      [file],
      'approvalChainConfirmationAttachment'
    );
  }

  try {
    const body = {
      data: {
        confirmedDate,
        confirmationBundle: {
          attachments:
            signedUrls.length > 0
              ? [
                {
                  fileType: file!.type,
                  filePath: signedUrls[0],
                },
              ]
              : [],
          note,
        },
        action: 'assert',
      },
    };

    await api.patch({
      path: `/applications/${id}/confirmations/${confirmation.uuid}`,
      body,
    });
    await refreshListAndModal(
      applicationDetailSignal.value.id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({
      approvalAttachmentsToUpload: [],
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully approved application.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleApprovalChainTemplateChange = (
  skippedSteps: number[],
  fallbackToAlternatesSteps: Record<string, number[]>,
  keyDates: Record<string, Date>
): Signal<unknown> =>
  applicationDetailSignal.update({
    approvalChainForm: {
      skippedSteps,
      fallbackToAlternatesSteps,
      keyDates,
    },
  });

export const downloadApplicationPackage = async (): Promise<unknown> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Downloading application package...',
  });
  try {
    const body = {
      where: {
        applicationId: applicationDetailSignal.value.id,
      },
      data: {
        status,
      },
    };

    const url = await api.put({
      path: `/applications/packages`,
      body,
    });

    await downloadFromUrl(
      url as string,
      `application-${applicationDetailSignal.value.id}-package.pdf`
    );

    return undefined;
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: `An unexpected error occurred when creating the application package. ${error.message}`,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const mapOcrValuesToConfirmStatusInputValues = (): void => {
  const { formData, fundingProgram } = applicationDetailSignal.value;
  const {
    referenceProgram: { key },
  } = fundingProgram as FundingProgram;
  const approvedInputFields = {};

  let ocrLoanAmountKey = '';
  switch (key) {
    case PROGRAM_KEYS.microLoan:
    case PROGRAM_KEYS.capitalAccess:
      ocrLoanAmountKey = 'loanInformation_totalLoanAmount';
      break;
    case PROGRAM_KEYS.loanGuaranty:
      ocrLoanAmountKey = 'loanInformation_loanAmount';
      break;
    default:
      break;
  }

  SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS[key].forEach((field) => {
    if (field.name === 'amountOfCollateralAvailable') {
      approvedInputFields[field.name] =
        formData[field.ocrFieldKey] && formData[field.ocrFieldKey][1]?.Value
          ? Number(formData[field.ocrFieldKey][1]?.Value.replace(/\D+/g, ''))
          : 0;
    } else if (field.name === 'requiredCollateralAmount') {
      approvedInputFields[field.name] =
        formData[field.ocrFieldKey] && formData[field.ocrFieldKey][2]?.Value
          ? Number(formData[field.ocrFieldKey][2]?.Value.replace(/\D+/g, ''))
          : 0;
    } else if (field.ocrFieldKey) {
      approvedInputFields[field.name] = formData[field.ocrFieldKey].value
        ? formData[field.ocrFieldKey].value
        : 0;
    }
  });

  if (key === PROGRAM_KEYS.collateralSupport) {
    approvedInputFields.approvedAmount =
      formData['collateralAnalysis_collateralAnalysisTableB'] &&
        formData['collateralAnalysis_collateralAnalysisTableB'][0]?.Value
        ? Number(
          formData[
            'collateralAnalysis_collateralAnalysisTableB'
          ][0].Value.replace(/\D+/g, '')
        )
        : '';
    if (
      approvedInputFields.approvedAmount &&
      approvedInputFields.approvedAmount > 0
    ) {
      approvedInputFields.percentageCoverageCollateralLenderLoanApproval =
        approvedInputFields.requiredCollateralAmount /
        approvedInputFields.approvedAmount;
    }
    approvedInputFields.amountOfCollateralSupportRequested =
      approvedInputFields.requiredCollateralAmount -
      approvedInputFields.amountOfCollateralAvailable;
  } else {
    approvedInputFields.approvedAmount = formData[ocrLoanAmountKey]
      ? Number(formData[ocrLoanAmountKey].replace(/\D+/g, ''))
      : 0;
  }

  applicationDetailSignal.update({ approvedInputFields });
};

const FETCH_APPLICATION_INCLUDE = {
  watchables: true,
  fundingProgram: {
    include: {
      stats: true,
      bankAccountProgramAccesses: {
        include: {
          bankAccount: true,
        },
      },
      supportingDocuments: true,
      referenceProgram: {
        include: {
          programType: true,
        },
      },
      platform: true,
      approvalChainTemplates: {
        include: {
          steps: {
            include: {
              primaryApprover: true,
              alternateApprovers: {
                include: {
                  user: true,
                },
              },
            },
          },
        },
      },
    },
  },
  fundingProgramMembership: {
    include: {
      lender: true,
      fundingProgram: {
        include: {
          stats: true,
          platform: true,
          edo: true,
          referenceProgram: {
            include: {
              programType: true,
            },
          },
          supportingDocuments: true,
          approvalChainTemplates: {
            include: {
              steps: {
                include: {
                  primaryApprover: true,
                  alternateApprovers: {
                    include: {
                      user: true,
                    },
                  },
                },
              },
            },
          },
          bankAccountProgramAccesses: {
            include: {
              bankAccount: true,
            },
          },
        },
      },
    },
  },
  disbursements: {
    include: {
      reimbursements: {
        include: {
          transactions: {
            include: {
              creator: true,
            },
          },
        },
      },
      originBankAccount: true,
      destinationBankAccount: true,
      transactions: {
        include: {
          creator: true,
        },
      },
    },
  },
  lossClaim: true,
  loan: {
    include: {
      payments: true,
      fees: true,
    },
  },
  technicalAssistance: true,
};

export const fetchApplication = async (
  id: string | number
): Promise<Signal<unknown>> => {
  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Loading application...',
    });
    const application = await api.get({
      path: '/applications',
      options: {
        where: {
          id,
        },
        include: FETCH_APPLICATION_INCLUDE,
      },
    });

    return applicationDetailSignal.update(
      txApplicationToApplicationDetailsModal(application, false)
    );
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: `An unexpected error occurred when loading the application. ${error.message}`,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetApprovalChainUsers = async (): Promise<
  Signal<unknown>
> => {
  try {
    const approvalChainConfirmations =
      applicationDetailSignal.value.approvalChainConfirmations;

    const approvalChainUsers = await api.get({
      path: '/users',
      options: {
        where: {
          id: {
            in: approvalChainConfirmations
              .map((acc) => acc.approverIds)
              .flatMap((id) => id),
          },
        },
      },
    });

    return applicationDetailSignal.update({
      approvalChainUsers,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: `An unexpected error occurred when loading the approval chain users. ${error.message}`,
    });
  }
};

export const fetchApplicationBlockchain = async (): Promise<
  Signal<unknown>
> => {
  try {
    const approvalChainConfirmations =
      applicationDetailSignal.value.approvalChainConfirmations;
    const { id } = applicationDetailSignal.value;

    const blockchain = await api.get({
      path: `/applications/${id}/chainblocks`,
      options: {
        where: {
          id: {
            in: approvalChainConfirmations
              .map((acc) => acc.approverIds)
              .flatMap((id) => id),
          },
        },
      },
    });

    return applicationDetailSignal.update({
      blockchain,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: `An unexpected error occurred when loading the application blockchain. ${error.message}`,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleUpdateDisbursementConfig = async (): Promise<
  Signal<unknown> | boolean
> => {
  const { approvedInputFields, id } = applicationDetailSignal.value;
  const { borrowerEquity, approvedAmount, disbursementDate } =
    approvedInputFields;

  const cleanDisbursementConfig = Object.entries(approvedInputFields).reduce(
    (acc, [key, value]) => {
      if (value instanceof Date) {
        return { ...acc, [key]: value.toISOString().split('T')[0] };
      } else if (isNaN(Number(value))) {
        return { ...acc, [key]: value };
      } else {
        return { ...acc, [key]: Number(value) };
      }
    },
    {}
  );
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/applications',
      body: {
        where: {
          id,
        },
        data: {
          borrowerEquity: Number(borrowerEquity),
          approvedAmount: Number(approvedAmount),
          disbursementDate: (disbursementDate as Date)
            .toISOString()
            .split('T')[0],
          disbursementConfig: {
            ...cleanDisbursementConfig,
          },
          action: 'updateDisbursementConfig',
        },
      },
    });

    await refreshListAndModal(
      id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated disbursement information.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleRevokeApproval = async (
  confirmation: ApplicationApprovalChainConfirmation
): Promise<Signal<unknown> | boolean> => {
  const { id } = applicationDetailSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });
    await api.patch({
      path: `/applications/${id}/confirmations/${confirmation.uuid}`,
      body: {
        data: {
          action: 'revoke',
        },
      },
    });

    await refreshListAndModal(
      id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );

    applicationDetailSignal.update({ revokeApprovalChainApprovalVisible: false });

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully revoked approval.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchRawOcrData = async (): Promise<
  Signal<unknown> | undefined
> => {
  const { id } = applicationDetailSignal.value;

  if (!id) {
    return applicationDetailSignal;
  }

  try {
    const rawOcr = await api.get({
      path: `/applications/${id}/rawOcr`,
    });

    if (rawOcr) {
      return applicationDetailSignal.update({
        rawOcr: rawOcr.data,
      });
    }
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  }
};
