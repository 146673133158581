import { Col, Container, Form, Row } from 'react-bootstrap';
import {
  handleSelectionChange,
} from '../VCDashboard.helpers';
import vcDashboardSignal from 'signals/VCDashboard.signal';

const ChooseProgram = () => {
  const { selectedFundingProgramId, fundingPrograms } = vcDashboardSignal.value;

  const getProgramSelections = () =>
    fundingPrograms.map((prg) => (
      <option value={prg.id} key={prg.id}>
        {prg.name}
      </option>
    ));

  return (
    <Container className="p-16 bg-light">
      <Row>
        <Col lg={4}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Program
            </Form.Label>
            <Form.Select
              className="form-control"
              name="program"
              value={selectedFundingProgramId}
              onChange={handleSelectionChange}
            >
              <option value="">All programs</option>
              {getProgramSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default ChooseProgram;
