import { Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const EdoLenderBusinessDetail = () => {
  const { pathname } = useLocation();
  const segments = pathname.split('/');
  const organizationId = segments[segments.length - 1];
  const fundingProgramMembershipId = segments[segments.length - 3];
  const { selectedOrganization } = organizationDetailsSignal.value;
  const application = selectedOrganization?.applications?.find(app => app.fundingProgramMembershipId === Number(fundingProgramMembershipId));
  let labels = [];

  if (!!application) {
    const { fundingProgramMembership } = application;
    const { fundingProgram, lender } = fundingProgramMembership;
    labels = [
        '',
        !!lender ? lender.name : '',
        !!fundingProgram ? `Businesses enrolled in ${fundingProgram.name}` : '',
        selectedOrganization.name,
    ];
  }

  const init = useCallback(async () => {
    await fetchOrganization(organizationId, '/businesses');
  });

  useEffect(() => {
    init();
  }, [organizationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card>
        <Card.Body>
          <OrganizationDetails />
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default EdoLenderBusinessDetail;
