import { Button } from 'react-bootstrap';
import { useRef } from 'react';

type Props = {
  multiple?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
};

const AddAttachmentsButton: React.FC<Props> = ({
  onChange,
  multiple,
  text,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const id = new Date().getTime().toString();

  return (
    <>
      <input
        ref={fileInputRef}
        name="attachments"
        type="file"
        hidden
        id={id}
        multiple={multiple}
        onChange={(e) => {
          onChange(e);
          fileInputRef.current!.type = 'file';
          fileInputRef.current!.value = '';
        }}
      />
      <Button
        size="sm"
        className="rounded"
        variant="outline-primary"
        onClick={() => document.getElementById(id)!.click()}
      >
        {text || 'Add +'}
      </Button>
    </>
  );
};

export default AddAttachmentsButton;
