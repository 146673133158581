import CloseModalButton from 'components/global/Constant/CloseModalButton';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
  searchProgramsResultsModalStateSignal,
  searchProgramsResultsRequestedAmountSignal,
  searchProgramsResultsSelectedProgram,
} from 'components/global/SearchProgramResults/SearchProgramsResults.signal';
import ProgramRequestDocumentPreview from './ProgramRequestDocumentPreview';
import ProgramRequestUploadDocument from './ProgramRequestUploadDocument';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import {
  handleRequestedAmountChange,
  isRequestedAmountValid,
  resetProgramInfoModal,
  toggleProgramInfoModal,
} from '../FundingProgramDetail.helpers';
import Field from 'components/global/Field/Field';

const ProgramRequestSubmissionModal = () => {
  const selectedProgram = searchProgramsResultsSelectedProgram.value;
  const modalState = searchProgramsResultsModalStateSignal.value;
  const { requestedAmount, requestedAmountError } =
    searchProgramsResultsRequestedAmountSignal.value;
  const { pathname } = useLocation();

  return (
    <ModalWrapper
      show={modalState.show}
      size="lg"
      centered
      onHide={toggleProgramInfoModal}
      onExited={resetProgramInfoModal}
    >
      <Modal.Header className="justify-content-end border-bottom-0 pb-0">
        <CloseModalButton onCloseModal={toggleProgramInfoModal} />
      </Modal.Header>
      {modalState.step === 1 && (
        <Modal.Body className="pt-0">
          <h5>
            {selectedProgram.name} -{' '}
            {selectedProgram.referenceProgram.programName}
          </h5>
          <Row className="mb-16" hidden={pathname.split('/')[1] !== 'vc'}>
            <Col>
              <Field.Integer
                label="Requested Amount"
                labelClassName="font-weight-bold"
                name="requestedAmount"
                value={requestedAmount}
                onChange={handleRequestedAmountChange}
                onBlur={isRequestedAmountValid}
              />
              <small className="text-danger" hidden={!requestedAmountError}>
                {requestedAmountError}
              </small>
            </Col>
          </Row>
          <Row className="mt-8 px-16 justify-content-end">
            <Button
              variant="outline-secondary"
              className="rounded mr-8"
              onClick={toggleProgramInfoModal}
            >
              Close
            </Button>
            <Button
              className="rounded"
              disabled={!!requestedAmountError}
              onClick={() =>
                searchProgramsResultsModalStateSignal.update({ step: 2 })
              }
            >
              Next
            </Button>
          </Row>
        </Modal.Body>
      )}
      {modalState.step === 2 && <ProgramRequestUploadDocument />}
      {modalState.step === 3 && <ProgramRequestDocumentPreview />}
    </ModalWrapper>
  );
};

export default ProgramRequestSubmissionModal;
