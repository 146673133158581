import CloseModalButton from "components/global/Constant/CloseModalButton";
import ModalWrapper from "components/global/ModalWrapper/ModalWrapper";
import { Alert, Button, Modal, Table } from "react-bootstrap";

const LinkedPlatformDetailsModal = ({ selectedBankAccount, linkedPlatforms, show, onHide }) => {
  if (!selectedBankAccount) {
    return null;
  }

  return (
    <ModalWrapper
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header>
        <h5>{selectedBankAccount.bankAccountName}</h5>
        <CloseModalButton onCloseModal={onHide} />
      </Modal.Header>
      <Modal.Body>
        {linkedPlatforms.length ? 
        (
        <Table>
          <thead>
            <tr>
              <th>Linked Platform</th>
            </tr>
          </thead>
          <tbody>
            {linkedPlatforms.map(platform => (
              <tr key={platform.id}>
                <td>{platform.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        ) :
        (
          <Alert variant="warning">This bank account does not have any linked platforms.</Alert>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={onHide}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default LinkedPlatformDetailsModal;
