import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavItem,
  Row,
} from 'react-bootstrap';
import caatLogo from 'assets/images/caatSimple.png';
import userAccountSignal from 'signals/UserAccount.signal';
import SignOutButton from './SignOut';
import { COLORS, PORTAL_TYPE_HOME } from '../Constant/constants';
import { notificationDrawerSignal } from 'signals/Notification.signal';
import { notificationBellIcon, searchIcon, userIcon } from '../Constant/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../../history';
import { onTestMode } from '../../../libs/functions/global.functions';
import {
  businessTopNav,
  edoTopNav,
  executiveTopNav,
  lenderTopNav,
  vcTopNav,
} from '../Constant/componentProps';
import { getConfig } from 'config/config';
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import $appSettings from 'signals/AppSettings.signal';
import api from 'api/api';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { handleGlobalSearch, redirectToResource } from 'libs/searchNavigation';

const filterBy = () => true;

const SearchAutocomplete = () => {
  const [, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const inputRef = useRef(null);

  const handleSearch = (query) => {
    setIsLoading(true);

    return api
      .get({
        path: '/search',
        options: {
          searchQuery: query,
        },
      })
      .then((items) => setOptions(items));
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleGlobalSearch(inputRef.current.state.text);
      inputRef.current.blur();
    }
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <AsyncTypeahead
        ref={inputRef}
        id={'search-input'}
        isLoading={false}
        filterBy={filterBy}
        minLength={3}
        labelKey="title"
        placeholder={'Search...'}
        options={options}
        onSearch={handleSearch}
        onKeyDown={handleEnterKeyPress}
        renderMenuItemChildren={(option) => (
          <div onClick={() => redirectToResource(option)}>
            <div className="font-weight-bold">{option.title}</div>
            <div className="text-secondary">{option.content}</div>
          </div>
        )}
      />
      <Button
        className="search-button rounded-0"
        variant="none"
        onClick={() => handleGlobalSearch(inputRef.current.state.text)}
      >
        <FontAwesomeIcon
          className="text-white"
          style={{ fontSize: 24 }}
          icon={searchIcon}
        />
      </Button>
    </div>
  );
};

const NavBarItem = ({ navObject }) => {
  const role = userAccountSignal.value.userData?.role;
  const { appSettings } = $appSettings.value;
  const headerBackgroundColor =
    appSettings?.whiteLabelConfig?.headerBackgroundColor || COLORS.PRIMARY;
  const appConfig = getConfig('APP_CONFIG');
  let { dropDownItems } = navObject;

  if (dropDownItems) {
    dropDownItems = dropDownItems.filter(
      (item) => item.featureExclude[appConfig.appId]?.[role] !== true
    );
  }

  return (
    <Dropdown as={ButtonGroup}>
      {navObject.title === 'Settings' ? (
        <Dropdown.Toggle
          split
          variant="dark"
          id="navbar-dropdown"
          className="px-16 hide-caret mx-8"
        >
          <span className="mr-8">
            <FontAwesomeIcon className="mr-8" icon={userIcon} />
            {userAccountSignal.value.userData?.displayName ||
              userAccountSignal.value.userData?.firstName}
          </span>
        </Dropdown.Toggle>
      ) : (
        <Dropdown.Toggle
          style={{ backgroundColor: headerBackgroundColor }}
          className="px-16 border-0 rounded hide-caret"
          {...(navObject.route
            ? { as: Link, to: navObject.route }
            : { href: null })}
        >
          {navObject.route ? (
            <Button
              className="border-0"
              style={{ backgroundColor: headerBackgroundColor }}
            >
              <span className="text-white text-decoration-none font-weight-bold">
                {navObject.title}
              </span>
            </Button>
          ) : (
            <span className="font-weight-bold">{navObject.title}</span>
          )}
        </Dropdown.Toggle>
      )}
      {!!dropDownItems && (
        <Dropdown.Menu
          className="border-0"
          style={{ backgroundColor: headerBackgroundColor }}
        >
          {dropDownItems.map((item) => (
            <Dropdown.Item
              key={item.id}
              className="top-nav-item"
              {...(item.route ? { as: Link, to: item.route } : { href: null })}
            >
              {item.route ? (
                <span className="text-decoration-none dropdown-hover">
                  {item.label}
                </span>
              ) : (
                <SignOutButton className="border-0 p-0 dropdown-hover" />
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const Header = () => {
  const role = userAccountSignal.value.userData?.role;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const { appSettings } = $appSettings.value;
  const headerBackgroundColor = appSettings?.whiteLabelConfig
    ?.headerBackgroundColor
    ? appSettings.whiteLabelConfig.headerBackgroundColor
    : COLORS.PRIMARY;
  const logo = appSettings?.whiteLabelConfig?.logoBase64String
    ? appSettings.whiteLabelConfig.logoBase64String
    : caatLogo;
  const tagline =
    appSettings?.whiteLabelConfig?.tagline === null ||
    appSettings?.whiteLabelConfig?.tagline === undefined
      ? 'Powered by MStreetX'
      : appSettings.whiteLabelConfig.tagline;
  const taglineColor = appSettings?.whiteLabelConfig?.taglineColor
    ? appSettings.whiteLabelConfig.taglineColor
    : 'white';

  if (!role) {
    return null;
  }

  const appConfig = getConfig('APP_CONFIG');
  let headerTitle = '';
  let navBar = [];
  switch (userAccountSignal?.value?.userData?.account?.portalType) {
    case PORTAL_TYPES.state:
      headerTitle = 'State Portal';
      navBar = edoTopNav;
      break;
    case PORTAL_TYPES.lender:
      headerTitle = 'Capital Lender Portal';
      navBar = lenderTopNav;
      break;
    case PORTAL_TYPES.business:
      headerTitle = 'Business Portal';
      navBar = businessTopNav;
      break;
    case PORTAL_TYPES.edo:
      headerTitle = 'Economic Development Office Portal';
      navBar = edoTopNav;
      break;
    case PORTAL_TYPES.vc:
      headerTitle = 'Venture Capital';
      navBar = vcTopNav;
      break;
    case PORTAL_TYPES.executive:
      navBar = executiveTopNav;
    default:
      headerTitle = 'Welcome to CAAT';
      break;
  }

  navBar = navBar.filter(
    (item) => item.featureExclude[appConfig.appId]?.[role] !== true
  );

  return (
    <Navbar
      className="shadow"
      expand="lg"
      style={{ backgroundColor: headerBackgroundColor }}
    >
      <Container fluid>
        <Navbar.Brand target="#">
          <Row>
            <Col className="px-lg-0 col-auto">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push(
                    PORTAL_TYPE_HOME[
                      userAccountSignal.value?.userData?.account.portalType
                    ]
                  )
                }
                src={logo}
                alt="CAAT logo"
                height="60"
              />
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col xs={12} sm className="text-wrap">
                  <h5 className="mb-0 text-white">{headerTitle}</h5>
                </Col>
                {onTestMode() && (
                  <Col className="col-auto">
                    <span
                      className="bg-success rounded p-1 ml-lg-3 text-white"
                      style={{ fontSize: '16px', marginBottom: '4px' }}
                    >
                      Test mode
                    </span>
                  </Col>
                )}
              </Row>
              <p style={{ fontSize: 9, color: taglineColor }} className="mb-0">
                {tagline}
              </p>
            </Col>
          </Row>
        </Navbar.Brand>
        <Navbar.Toggle />
        <div className="d-flex flex-column">
          <Navbar.Collapse className="justify-content-end text-white align-items-end">
            <Nav className="w-100 justify-content-end d-flex">
              {navBar.length &&
                navBar.map((navBarItem) => (
                  <NavItem key={navBarItem.id}>
                    <NavBarItem navObject={navBarItem} />
                  </NavItem>
                ))}
            </Nav>
          </Navbar.Collapse>
          {userAccountSignal?.value?.userData?.account?.portalType !==
            PORTAL_TYPES.executive && (
            <div className="d-flex flex-row justify-content-end mt-8">
              {/* 
            // Keeping this in case we decide we want to collapse the search bar
            
            {searchBarCollapsed ? (
              <Button
                variant="none"
                onClick={() => setSearchBarCollapsed(false)}
              >
                <FontAwesomeIcon
                  className="text-white"
                  style={{ fontSize: 24 }}
                  icon={searchIcon}
                />
              </Button>
            ) : ( */}
              <SearchAutocomplete />
              {/* )} */}
              <Button
                variant="none"
                onClick={() =>
                  notificationDrawerSignal.update({
                    isOpen: !notificationDrawerSignal.value.isOpen,
                  })
                }
              >
                <FontAwesomeIcon
                  className="text-white"
                  style={{ fontSize: 24 }}
                  icon={notificationBellIcon}
                />
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
