import Signal from 'libs/signals';
import { Account, Business, Fund, FundingProgram, Lender, VentureCapitalFirm } from 'types';

type OrganizationsListSignal = {
  accounts: Account[];
  fundingProgram: FundingProgram | null;
  fund: Fund | null;
};

const organizationsListSignal = Signal<OrganizationsListSignal>({
  accounts: [],
  fundingProgram: null,
  fund: null,
});

type OrganizationDetailsSignal = {
  modalVisible: boolean;
  selectedOrganization: {
    id: number;
  };
};

export const organizationDetailsSignal = Signal<OrganizationDetailsSignal>({
  modalVisible: false,
  selectedOrganization: {
    id: 0,
  },
});

type ShareableOrganization = (Lender | VentureCapitalFirm) & {
  account: Pick<Account, 'portalType'>;
};

type ShareOrganizationSignal = {
  modalVisible: boolean;
  organizationName: string;
  fetching: boolean;
  organizations: Array<ShareableOrganization>;
  selectedOrganization: ShareableOrganization | null;
  selectedBusiness: Business | null;
};

export const shareOrganizationSignal = Signal<ShareOrganizationSignal>({
  modalVisible: false,
  organizationName: '',
  fetching: false,
  organizations: [],
  selectedOrganization: null,
  selectedBusiness: null,
});

export default organizationsListSignal;
