import { camelCaseToTitleText } from 'utils/formatTxt';
import { ocrSignal } from './ocr.signal';

export const OCR_SELECT_OPTIONS = {
  requiredDocuments: [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'N/A', value: 'N/A' },
  ],
  demographicsRelatedData: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
    { label: 'Prefer not to respond', value: 'decline' },
  ],
  ethnicity: [
    { label: 'Hispanic or Latino', value: 'yes' },
    { label: 'Not Hispanic or Latino', value: 'no' },
    { label: 'Prefer not to respond', value: 'decline' },
  ],
  ancestry: [
    {
      label: 'Middle Eastern or North African',
      value: 'yes',
    },
    {
      label: 'Not Middle Eastern or North African',
      value: 'no',
    },
    { label: 'Prefer not to respond', value: 'decline' },
  ],
  gender: [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Nonbinary', value: 'nonBinary' },
    { label: 'Prefer not to respond', value: 'decline' },
  ],
  sexualOrientation: [
    { label: 'Gay or Lesbian', value: 'gayOrLesbian' },
    { label: 'Bisexual', value: 'bisexual' },
    { label: 'Straight', value: 'straight' },
    { label: 'Something else', value: 'somethingElse' },
    { label: 'Prefer not to respond', value: 'decline' },
  ],
  veteranStatus: [
    { label: 'Veteran', value: 'yes' },
    { label: 'Non Veteran', value: 'no' },
    { label: 'Prefer not to respond', value: 'decline' },
  ],
};

// This can be condensed once we retrain the OCR models and use more consistent naming
// Not labeled === CSP Programs
const OCR_DATA_TO_SELECT_INPUT = {
  // SSBCI Demographics
  ssbciDemographicsRelatedData_minorityOwnedBusinessYes: {
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographicsRelatedData_minorityOwnedBusinessNo: {
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'no',
  },
  ssbciDemographicsRelatedData_minorityOwnedBusinessPreferNotToRespond: {
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographics_minorityOwnedOrControlledYes: {
    //sblgp
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographics_minorityOwnedOrControlledNo: {
    //sblgp
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'no',
  },
  ssbciDemographics_minorityOwnedOrControlledNotRespond: {
    //sblgp
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographics_minorityOwnedBusinessYes: {
    // mlp
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographics_minorityOwnedBusinessNo: {
    // mlp
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'no',
  },
  ssbciDemographics_minorityOwnedBusinessNA: {
    // mlp
    fieldName: 'ssbciDemographics_minorityOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographicsRelatedData_womenOwnedBusinessYes: {
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographicsRelatedData_womenOwnedBusinessNo: {
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'no',
  },
  ssbciDemographicsRelatedData_womenOwnedBusinessPreferNotToRespond: {
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographics_womenOwnedOrControlledYes: {
    //sblgp
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographics_womenOwnedOrControlledNo: {
    //sblgp
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'no',
  },
  ssbciDemographics_womenOwnedOrControlledNotRespond: {
    //sblgp
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographics_WomenOwnedBusinessYes: {
    // mlp
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographics_WomenOwnedBusinessNo: {
    // mlp
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'no',
  },
  ssbciDemographics_WomenOwnedBusinessNA: {
    // mlp
    fieldName: 'ssbciDemographics_womenOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographicsRelatedData_veteranOwnedBusinessYes: {
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographicsRelatedData_veteranOwnedBusinessNo: {
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'no',
  },
  ssbciDemographicsRelatedData_veteranOwnedBusinessPreferNotToRespond: {
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographics_veteranOwnedOrControlledYes: {
    //sblgp
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographics_veteranOwnedOrControlledNo: {
    //sblgp
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'no',
  },
  ssbciDemographics_veteranOwnedOrControlledNotRespond: {
    //sblgp
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'decline',
  },
  ssbciDemographics_veteranOwnedBusinessYes: {
    // mlp
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'yes',
  },
  ssbciDemographics_veteranOwnedBusinessNo: {
    // mlp
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'no',
  },
  ssbciDemographics_veteranOwnedBusinessNA: {
    // mlp
    fieldName: 'ssbciDemographics_veteranOwnedBusiness',
    value: 'decline',
  },
  // Ethnicity
  ethnicity_hispanicOrLatino: {
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'yes',
  },
  ethnicity_notHispanicOrLatino: {
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'no',
  },
  ethnicity_notRespond: {
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'decline',
  },
  ethnicity_preferNotToRespond: {
    //sblgp
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'decline',
  },
  ssbciDemographics_ethnicityHispanicLatino: {
    // mlp
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'yes',
  },
  ssbciDemographics_ethnicityNotHispanicLatino: {
    // mlp
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'no',
  },
  ssbciDemographics_ethnicityNotRespond: {
    // mlp
    fieldName: 'ethnicity_hispanicOrLatino',
    value: 'decline',
  },
  // Ancestry
  ancestry_middleEasternOrNorthAfrican: {
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'yes',
  },
  ancestry_notMiddleEasternOrNorthAfrican: {
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'no',
  },
  ancestry_preferNotToRespond: {
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'decline',
  },
  middleEastern_middleEasternOrnothAfrican: {
    //sblgp
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'yes',
  },
  middleEastern_notMiddleEasternNorth: {
    // sblgp
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'no',
  },
  middleEastern_preferNotRespond: {
    // sblgp
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'decline',
  },
  ssbciDemographics_ancestryMiddleEasterNorthAfrican: {
    // mlp
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'yes',
  },
  ssbciDemographics_ancestryNotMiddleEsternNorthAfrican: {
    // mlp
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'no',
  },
  ssbciDemographics_ancestryNotRespond: {
    // mlp
    fieldName: 'ancestry_middleEasternOrNorthAfrican',
    value: 'decline',
  },
  // Gender
  gender_female: {
    fieldName: 'gender_gender',
    value: 'female',
  },
  gender_male: {
    fieldName: 'gender_gender',
    value: 'male',
  },
  gender_nonbinary: {
    fieldName: 'gender_gender',
    value: 'nonBinary',
  },
  gender_preferNotToRespond: {
    fieldName: 'gender_gender',
    value: 'decline',
  },
  gender_preferNotRespond: {
    //sblgp
    fieldName: 'gender_gender',
    value: 'decline',
  },
  ssbciDemographics_genderFemale: {
    // mlp
    fieldName: 'gender_gender',
    value: 'female',
  },
  ssbciDemographics_genderMale: {
    // mlp
    fieldName: 'gender_gender',
    value: 'male',
  },
  ssbciDemographics_genderNonBinary: {
    // mlp
    fieldName: 'gender_gender',
    value: 'nonBinary',
  },
  ssbciDemographics_genderNotRespond: {
    // mlp
    fieldName: 'gender_gender',
    value: 'decline',
  },
  // Sexual Orientation
  sexualOrientation_gayOrLesbian: {
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'gayOrLesbian',
  },
  sexualOrientation_bisexual: {
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'bisexual',
  },
  sexualOrientation_straight: {
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'straight',
  },
  sexualOrientation_somethingElse: {
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'somethingElse',
  },
  sexualOrientation_preferNotToRespond: {
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'decline',
  },
  sexualOrientation_gayLesbian: {
    // sblgp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'gayOrLesbian',
  },
  sexualOrientation_straightNotGayLesbianBisexual: {
    // sblgp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'straight',
  },
  sexualOrientation_preferNotRespond: {
    // sblgp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'decline',
  },
  ssbciDemographics_sexOrientationGayLesbian: {
    // mlp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'gayOrLesbian',
  },
  ssbciDemographics_sexOrientationBisexual: {
    // mlp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'bisexual',
  },
  ssbciDemographics_sexOrientationStraight: {
    // mlp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'straight',
  },
  ssbciDemographics_sexOrientationSomethingElse: {
    // mlp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'somethingElse',
  },
  ssbciDemographics_sexOrientationNotRespond: {
    // mlp
    fieldName: 'sexualOrientation_sexualOrientation',
    value: 'decline',
  },
  // Veteran Status
  veteranStatus_veteran: {
    fieldName: 'veteranStatus_veteranStatus',
    value: 'yes',
  },
  veteranStatus_nonVeteran: {
    fieldName: 'veteranStatus_veteranStatus',
    value: 'no',
  },
  veteranStatus_preferNotToRespond: {
    fieldName: 'veteranStatus_veteranStatus',
    value: 'decline',
  },
  veteranStatus_preferNotRespond: {
    // sblgp
    fieldName: 'veteranStatus_veteranStatus',
    value: 'decline',
  },
  ssbciDemographics_veteranStatusVeteran: {
    // mlp
    fieldName: 'veteranStatus_veteranStatus',
    value: 'yes',
  },
  ssbciDemographics_veteranStatusNonVeteran: {
    // mlp
    fieldName: 'veteranStatus_veteranStatus',
    value: 'no',
  },
  ssbciDemographics_veteranStatusNotRespond: {
    // mlp
    fieldName: 'veteranStatus_veteranStatus',
    value: 'decline',
  },
};

export const parseOcrData = () => {
  const { rawOcrData, modelId } = ocrSignal.value;
  if (!rawOcrData.fields) {
    return {};
  }

  let sortedNamesByPageNumber = [];

  if (modelId === 'louisiana-collateral-support-program-model-c') {
    sortedNamesByPageNumber = Object.keys(rawOcrData.fields).sort((a, b) => {
      const numA = parseInt(a.split('_').pop());
      const numB = parseInt(b.split('_').pop());
      return numA - numB;
    });
  } else {
    const originalFieldNames = Object.keys(rawOcrData.fields).sort();

    sortedNamesByPageNumber = originalFieldNames.sort((fieldA, fieldB) => {
      if (
        !rawOcrData.fields[fieldA].boundingRegions &&
        rawOcrData.fields[fieldB].boundingRegions
      ) {
        return 1;
      }

      if (
        rawOcrData.fields[fieldA].boundingRegions &&
        !rawOcrData.fields[fieldB].boundingRegions
      ) {
        return -1;
      }

      if (
        rawOcrData.fields[fieldA].boundingRegions &&
        rawOcrData.fields[fieldB].boundingRegions
      ) {
        if (
          rawOcrData.fields[fieldA]?.boundingRegions[0]?.pageNumber ===
          rawOcrData.fields[fieldB]?.boundingRegions[0]?.pageNumber
        ) {
          return (
            rawOcrData.fields[fieldA]?.boundingRegions[0]?.polygon[0]?.y -
            rawOcrData.fields[fieldB]?.boundingRegions[0]?.polygon[0]?.y
          );
        }

        return (
          rawOcrData.fields[fieldA]?.boundingRegions[0]?.pageNumber -
          rawOcrData.fields[fieldB]?.boundingRegions[0]?.pageNumber
        );
      }

      return 1;
    });
  }

  let parsedOcrData = {};
  let sortedCategories = [];

  sortedNamesByPageNumber.forEach((field) => {
    let type = 'text-input';
    let value =
      rawOcrData.fields[field].value || rawOcrData.fields[field].values;
    let fieldName = field;
    let label = '';
    let fieldObject = {};
    const fieldCategoryAndName = fieldName.split('_');
    if (fieldCategoryAndName.length > 2) {
      const lastIndex = field.lastIndexOf('_');
      fieldName = field.slice(0, lastIndex);
    }
    const category = camelCaseToTitleText(
      fieldCategoryAndName.length > 1 ? fieldCategoryAndName[0] : 'Section 1'
    );

    if (Object.keys(OCR_DATA_TO_SELECT_INPUT).includes(fieldName)) {
      if (value === 'selected') {
        value = OCR_DATA_TO_SELECT_INPUT[fieldName].value;
      }
      fieldName = OCR_DATA_TO_SELECT_INPUT[fieldName].fieldName;
      type = 'select';
      label = camelCaseToTitleText(fieldName.split('_')[1]);

      if (fieldName.includes('ssbciDemographics')) {
        fieldObject.options = OCR_SELECT_OPTIONS.demographicsRelatedData;
      } else if (fieldName.includes('ethnicity')) {
        fieldObject.options = OCR_SELECT_OPTIONS.ethnicity;
      } else if (
        fieldName.includes('ancestry') ||
        fieldName.includes('middleEastern')
      ) {
        fieldObject.options = OCR_SELECT_OPTIONS.ancestry;
      } else if (fieldName.includes('gender')) {
        fieldObject.options = OCR_SELECT_OPTIONS.gender;
      } else if (fieldName.includes('sexualOrientation')) {
        fieldObject.options = OCR_SELECT_OPTIONS.sexualOrientation;
      } else if (fieldName.includes('veteranStatus')) {
        fieldObject.options = OCR_SELECT_OPTIONS.veteranStatus;
      } else {
        fieldObject.options = [];
      }
    } else {
      label = camelCaseToTitleText(
        fieldCategoryAndName.length > 1 ? fieldCategoryAndName[1] : field
      );
      switch (rawOcrData.fields[field].kind) {
        case 'string':
          type = 'text-input';
          break;
        case 'selectionMark':
          type = 'checkbox';
          if (value === 'selected') {
            value = true;
          } else {
            value = false;
          }
          break;
        case 'array':
          type = 'table';
          value = formatTableData(value, fieldName);
          break;
        default:
          break;
      }
    }

    fieldObject = {
      ...fieldObject,
      name: fieldName,
      label,
      value,
      type,
    };

    if (!Object.keys(parsedOcrData).includes(category)) {
      parsedOcrData[category] = { sortedFieldNames: [] };
      sortedCategories = [...sortedCategories, category];
    }

    if (parsedOcrData[category].sortedFieldNames.includes(fieldName)) {
      fieldObject = {
        ...fieldObject,
        value:
          parsedOcrData[category][fieldName].value !== 'unselected'
            ? parsedOcrData[category][fieldName].value
            : value,
      };
    } else {
      parsedOcrData[category].sortedFieldNames.push(fieldName);
    }
    parsedOcrData[category] = {
      ...parsedOcrData[category],
      [fieldName]: fieldObject,
    };
  });
  ocrSignal.update({ parsedOcrData, sortedCategories });
};

export const onOcrFieldChange = (e, category) => {
  const { name, value } = e;
  const { parsedOcrData } = ocrSignal.value;

  ocrSignal.update({
    parsedOcrData: {
      ...parsedOcrData,
      [category]: {
        ...parsedOcrData[category],
        [name]: {
          ...parsedOcrData[category][name],
          value,
        },
      },
    },
    formattedOcrData: {},
  });
};

export const onOcrTableFieldChange = (e, category, tableName, rowIndex) => {
  let { value } = e;
  const { name } = e;

  const { parsedOcrData } = ocrSignal.value;
  const tableArr = parsedOcrData[category][tableName].value.rows;
  tableArr[rowIndex][name] = value;

  ocrSignal.update({
    parsedOcrData: {
      ...parsedOcrData,
      [category]: {
        ...parsedOcrData[category],
        [tableName]: {
          ...parsedOcrData[category][tableName],
          value: {
            ...parsedOcrData[category][tableName].value,
            rows: tableArr,
          },
        },
      },
    },
    formattedOcrData: {},
  });
};

export const onOcrCheckboxChange = (e, category) => {
  const { name } = e;
  let { value } = e;
  const { parsedOcrData } = ocrSignal.value;
  if (value.toString() === 'true') {
    value = false;
  } else {
    value = true;
  }
  ocrSignal.update({
    parsedOcrData: {
      ...parsedOcrData,
      [category]: {
        ...parsedOcrData[category],
        [name]: {
          ...parsedOcrData[category][name],
          value,
        },
      },
    },
    formattedOcrData: {},
  });
};

export const formatOcrDataForSubmit = () => {
  const { parsedOcrData } = ocrSignal.value;
  const formattedOcrData = {};

  Object.keys(parsedOcrData).forEach((category) => {
    Object.keys(parsedOcrData[category]).forEach((key) => {
      if (key !== 'sortedFieldNames') {
        if (key.includes('email')) {
          return (formattedOcrData[key] = parsedOcrData[category][key]?.value
            ?.toLowerCase()
            .replace(/\s/g, ''));
        }
        if (parsedOcrData[category][key].type === 'table') {
          const headers = parsedOcrData[category][key]?.value?.headers;
          const tableArr = parsedOcrData[category][key]?.value?.rows?.map(
            (tableRow) => {
              const trimmedRowObj = {};
              headers.forEach((key) => {
                let cellValue = tableRow[key];

                if (key.includes('requiredDocuments')) {
                  cellValue = cellValue ? true : false;
                }

                trimmedRowObj[key] = cellValue;
              });

              return trimmedRowObj;
            }
          );
          return (formattedOcrData[key] = tableArr);
        }
        return (formattedOcrData[key] = parsedOcrData[category][key].value);
      }
    });
  });
  ocrSignal.update({ formattedOcrData });
};

export const displayHeader = (header) => {
  if (
    header === 'Item' ||
    header === 'Value' ||
    header.includes('COLUMN') ||
    header === 'Provided'
  ) {
    return false;
  }

  return true;
};

export const columnIsInput = (header) => {
  if (
    header === 'Item' ||
    header === 'Maximum Collateral Support Available' ||
    header === 'Documents' ||
    header === 'Investment Cost'
  ) {
    return false;
  }
  return true;
};

const formatTableData = (data, fieldName) => {
  if (!data) {
    return;
  }

  let headers = Object.keys(data[0].properties);
  let rows = [];

  if (fieldName.includes('requiredDocuments')) {
    rows = data.map((d) => {
      const rowObj = {};
      rowObj['Provided'] = '';
      rowObj['Documents'] = d.properties['Documents'].value || '';
      headers.filter(h => h !== 'Documents').forEach((header) => {
        if (d.properties[header]?.value) {
          rowObj['Provided'] = header;
        }
      });
      return rowObj;
    });
    headers = ['Documents', 'Provided'];
  } else {
    rows = data.map((d) => {
      const rowObj = {};
      headers.forEach((header) => {
        rowObj[header] = d.properties[header].value || '';
      });
      return rowObj;
    });
  }

  return { headers, rows };
};

export const getCustomLabel = (fieldName) => {
  let label = '';

  switch (fieldName) {
    case 'borrowerCertificationDisadvantageIndividuals_membershipOfGroup':
    case 'ssbciBorrowerCertificationBusinessEnterprises_membershipOfAGroupThatHasBeenSubjectedToRacialOrEthnicPrejudice':
    case 'ssbciBorrowerRelatedToBusinessOwned_membershipOfGroup':
      label =
        'membership of a group that has been subjected to racial or ethnic prejudice or cultural bias within American society';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_gender':
    case 'ssbciBorrowerCertificationBusinessEnterprises_gender':
    case 'ssbciBorrowerRelatedToBusinessOwned_gender':
      label = 'gender';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_veteran':
    case 'ssbciBorrowerCertificationBusinessEnterprises_veteranStatus':
    case 'ssbciBorrowerRelatedToBusinessOwned_veteran':
      label = 'veteran status';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_limitedEnglish':
    case 'ssbciBorrowerCertificationBusinessEnterprises_limitedEnglishProficiency':
    case 'ssbciBorrowerRelatedToBusinessOwned_limitedEnglish':
      label = 'limited English proficiency';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_disability':
    case 'ssbciBorrowerCertificationBusinessEnterprises_disability':
    case 'ssbciBorrowerRelatedToBusinessOwned_disability':
      label = 'disability';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_longtermResidence':
    case 'ssbciBorrowerCertificationBusinessEnterprises_longTermResidenceInAnEnvironmentIsolatedFromMainstreamAmericanSociety':
    case 'ssbciBorrowerRelatedToBusinessOwned_longtermResidencyIsolated':
      label =
        'long-term residence in an environment isolated from the mainstream of American society';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_membershipOfAFederallyOrStateRecognizedIndianTribe':
    case 'ssbciBorrowerCertificationBusinessEnterprises_membershipOfAFederallyOrStateRecognizedIndianTribe':
    case 'ssbciBorrowerRelatedToBusinessOwned_membershipFederallyIndianTribe':
      label = 'membership of a federally or state-recognized Indian Tribe';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_longtermResidenceInARuralCommunity':
    case 'ssbciBorrowerCertificationBusinessEnterprises_longTermResidenceInARuralCommunity':
    case 'ssbciBorrowerRelatedToBusinessOwned_longTermRuralCommunity':
      label = 'long-term residence in a rural community';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_residenceUS':
    case 'ssbciBorrowerCertificationBusinessEnterprises_residenceInAUSTerritory':
    case 'ssbciBorrowerRelatedToBusinessOwned_residencyInUsTerritory':
      label = 'residence in a U.S. territory';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_residenceInACommunityUndergoingEconomicTransition':
    case 'ssbciBorrowerCertificationBusinessEnterprises_residenceInACommunityUndergoingEconomicTransitions':
    case 'ssbciBorrowerRelatedToBusinessOwned_residencecommunityEconomic':
      label =
        'residence in a community undergoing economic transitions (including communities impacted by the shift towards a net-zero economy or deindustrialization)';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_membershipOfAnotherUnderservedCommunity':
    case 'ssbciBorrowerCertificationBusinessEnterprises_membershipOfAnotherUnderservedCommunity':
    case 'ssbciBorrowerRelatedToBusinessOwned_membershipUnderservedCommunity':
      label = 'membership of another underserved community';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_businessOwnedAreasCFR':
    case 'ssbciBorrowerCertificationBusinessEnterprises_businessEnterpriseThatIsOwnedAndControlledByIndividualsInCDFIAreas':
    case 'ssbciBorrowerRelatedToBusinessOwned_businessOwnedByIndividualsCDFIAreas':
      label =
        '2. Business enterprise that is owned and controlled by individuals whose residences are in CDFI Investment Areas, as defined in 12 C.F.R. ';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_businessOwnedAreasIndividualAddress':
    case 'ssbciBorrowerCertificationBusinessEnterprises_individualAddress':
    case 'ssbciBorrowerRelatedToBusinessOwned_individualAddressCDFIInvestment':
      label = "Individual(s)' Address(es) in CDFI Investment Areas:";
      break;
    case 'borrowerCertificationDisadvantageIndividuals_businessEnterprisesThatWillBuildCDFI':
    case 'ssbciBorrowerCertificationBusinessEnterprises_businessEnterpriseThatWillBuildOpenOrOperateALocationInACDFIArea':
    case 'ssbciBorrowerRelatedToBusinessOwned_businessEnterpriseOperateCDFIArea':
      label =
        '3. Business enterprise that will build, open, or operate a location in a CDFI Investment Area, as defined in 12 C.F.R.';
      break;
    case 'borrowerCertificationDisadvantageIndividuals_businessAddressInCdfiArea':
    case 'ssbciBorrowerCertificationBusinessEnterprises_businessAddressInCDFIArea':
    case 'ssbciBorrowerRelatedToBusinessOwned_businessAddressInCDFIArea':
      label = 'Business Address In CDFI Area';
      break;
    case 'borrowerInformation_monthYearStablished':
      label = 'Month/Year Established';
    default:
      break;
  }

  return label;
};

export const renderAdditionalText = (fieldName) => {
  switch (fieldName) {
    case 'borrowerCertificationDisadvantageIndividuals_membershipOfGroup':
    case 'ssbciBorrowerCertificationBusinessEnterprises_membershipOfAGroupThatHasBeenSubjectedToRacialOrEthnicPrejudice':
    case 'ssbciBorrowerRelatedToBusinessOwned_membershipOfGroup':
      return (
        <>
          <p className="mt-16">
            The borrower hereby certifies to the lender that it is a:
          </p>
          <p>
            1. Business enterprise that is owned and controlled by individuals
            who have had their access to credit on reasonable terms diminished
            as compared to others in comparable economic circumstances, due to
            their:
          </p>
        </>
      );
    case 'borrowerCertificationDisadvantageIndividuals_businessOwnedAreasCFR':
    case 'ssbciBorrowerCertificationBusinessEnterprises_businessEnterpriseThatIsOwnedAndControlledByIndividualsInCDFIAreas':
    case 'ssbciBorrowerRelatedToBusinessOwned_businessOwnedByIndividualsCDFIAreas':
    case 'borrowerCertificationDisadvantageIndividuals_businessEnterprisesThatWillBuildCDFI':
    case 'ssbciBorrowerCertificationBusinessEnterprises_businessEnterpriseThatWillBuildOpenOrOperateALocationInACDFIArea':
    case 'ssbciBorrowerRelatedToBusinessOwned_businessEnterpriseOperateCDFIArea':
    case 'borrowerCertificationDisadvantageIndividuals_signature':
    case 'ssbciBorrowerCertificationBusinessEnterprises_signature':
    case 'ssbciBorrowerRelatedToBusinessOwned_signature':
      return <hr />;
    default:
      return null;
  }
};

export const getCustomCategory = (category) => {
  let customCategory = category;

  switch (category) {
    case ' Section 1':
    case 'Section 1':
    case 'Generalnfo':
      customCategory = 'Application Checklist';
      break;
    default:
      break;
  }

  return customCategory;
}
