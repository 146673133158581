import alertSignal from "signals/Alert.signal";
import api from "api/api";
import { SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS } from "../Constant/businessCompanyInfoDropDowns";
import { Chart as ChartJS } from "chart.js";
// @ts-ignore
import pdfMake from "pdfmake";
// @ts-ignore
import Papa from "papaparse";
import { enumCaseToTabCase } from "libs/string";
import { camelCaseToTitleText, pascalCaseToTitleCase } from "utils/formatTxt";
import { AdhocReportQuery } from "types";
import $appSettings from "signals/AppSettings.signal";

const apiFetch = async (options: any) => api.get({ path: '/query', options });

type ApiParams = {
  model: string;
  fn: string;
  count: string | Record<string, string[]>;
  periodicity: string;
  startOfTime: string;
};

export async function fetchAdhocDataset(params: ApiParams): Promise<any> {
  try {
    const { fn, model } = params;
    const queryConfig = FILTERING_CONFIG[fn][model];
    return apiFetch({ ...params, model: model.split('.')[0], sum: queryConfig.sum || undefined, join: queryConfig.join || undefined });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: 'An error occurred while fetching the dataset.',
    });
  }
}

export const MODELS = [
  {
    label: 'Application',
    value: 'Application',
  },
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'Fund',
    value: 'Fund',
  },
  {
    label: 'Lender',
    value: 'Lender',
  },
  {
    label: 'Program Request',
    value: 'ProgramRequest',
  },
  {
    label: 'Venture Capitalist',
    value: 'VentureCapitalFirm',
  },
  {
    label: 'Obligated Funds',
    value: 'MoneyDistribution.Obligated'
  },
  {
    label: 'Expended Funds',
    value: 'MoneyDistribution.Expended'
  }
];

export const getModels = (
  portalType: string
): { value: string; label: string }[] => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const models = [...MODELS];
  if (portalType === PORTAL_TYPES.lender) {
    const exclude = ['Fund', 'VentureCapitalFirm', 'Lender', 'MoneyDistribution.Obligated', 'MoneyDistribution.Expended'];
    return models.filter(({ value }) => !exclude.includes(value));
  }

  if (portalType === PORTAL_TYPES.vc) {
    const exclude = ['Application', 'VentureCapitalFirm', 'Lender', 'MoneyDistribution.Obligated', 'MoneyDistribution.Expended'];
    return models.filter(({ value }) => !exclude.includes(value));
  }

  if (portalType === PORTAL_TYPES.business) {
    const exclude = ['Business', 'ProgramRequest', 'Fund', 'MoneyDistribution.Obligated', 'MoneyDistribution.Expended'];
    return models.filter(({ value }) => !exclude.includes(value));
  }

  return models;
};

const businessCountByOrGroupBy = {
  'sediCertification->>selfCertifiedSEDIDemographicsRelatedBusinessStatus':
    'sediCertification->>selfCertifiedSEDIDemographicsRelatedBusinessStatus',
  'demographics->>gender':
    'demographics->>gender',
  'demographics->>veteranStatus':
    'demographics->>veteranStatus',
  'demographics->>sexualOrientation':
    'demographics->>sexualOrientation',
  'minorityStatus->>isWomanOwned':
    'minorityStatus->>isWomanOwned',
  'minorityStatus->>isMinorityOwned':
    'minorityStatus->>isMinorityOwned',
  'minorityStatus->>isVeteranOwned':
    'minorityStatus->>isVeteranOwned',
  "demographics->'ethnicity'->>hispanicOrLatino":
    "demographics->'ethnicity'->>hispanicOrLatino",
  'zipCode': 'zipCode',
  'censusTract': 'censusTract'
}

export const REPORT_TYPES = [
  {
    label: 'Sum By',
    value: 'sumBy',
  },
  {
    label: 'Count By',
    value: 'groupBy',
  },
  {
    label: 'Time Series',
    value: 'timeSeries',
  },
];

export const filteringBaseOptions = {
  status: 'status',
  fundingProgramId: 'fundingProgramId',
  lenderId: 'lenderId',
};

export const FILTERING_CONFIG = {
  timeSeries: {
    Application: {
      createdDate: 'createdDate',
    },
    ProgramRequest: {
      createdDate: 'createdDate',
    },
    Lender: {
      createdDate: 'createdDate',
    },
    Business: {
      createdDate: 'createdDate',
    },
    VentureCapitalFirm: {
      createdDate: 'createdDate',
    },
    Fund: {
      createdDate: 'createdDate',
    },
  },
  sumBy: {
    ['MoneyDistribution.Obligated']: {
      sum: 'obligated',
      ...businessCountByOrGroupBy,
    },
    ['MoneyDistribution.Expended']: {
      sum: 'expended',
      ...businessCountByOrGroupBy,
    },
  },
  groupBy: {
    Fund: {
      ventureCapitalFirmId: 'ventureCapitalFirmId',
    },
    Application: {
      ...filteringBaseOptions,
      sediBusiness: 'sediBusiness',
      ...businessCountByOrGroupBy,
    },
    ProgramRequest: {
      ...filteringBaseOptions,
      ventureCapitalFirmId: 'ventureCapitalFirmId',
    },
    Lender: {
      name: 'name',
      lenderType: 'lenderType',
    },
    VentureCapitalFirm: {
      name: 'name',
      lenderType: 'lenderType',
    },
    Business: {
      ...businessCountByOrGroupBy
    },
  },
};

export const GRAPH_LABEL_CONFIG = {
  status: ({ status }: { status: string }): string => enumCaseToTabCase(status),
  businessId: ({ name }: { name: string }): string => name,
  lenderId: ({ name }: { name: string }): string => name,
  ventureCapitalFirmId: ({ name }: { name: string }): string => name,
  fundingProgramId: ({ name }: { name: string }): string => name,
  sediBusiness: (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row.sediBusiness;
    return countValue === true ? 'SEDI' : 'non SEDI';
  },
  'minorityStatus->>isVeteranOwned': (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      yes: 'Veteran Owned',
      no: 'Not Veteran Owned',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  'minorityStatus->>isMinorityOwned': (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      yes: 'Minority Owned',
      no: 'Not Minority Owned',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  'minorityStatus->>isWomanOwned': (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      yes: 'Woman Owned',
      no: 'Not Woman Owned',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  "demographics->'ethnicity'->>hispanicOrLatino": (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      yes: 'Hispanic or Latino',
      no: 'Non Hispanic or Latino',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  'demographics->>veteranStatus': (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      yes: 'Veteran',
      no: 'Non Veteran',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  'demographics->>sexualOrientation': (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      lesbianOrGay: 'Gay or Lesbian',
      gayOrLesbian: 'Gay or Lesbian',
      bisexual: 'Bisexual',
      straight: 'Straight',
      somethingElse: 'Something else',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  'demographics->>gender': (
    row: Record<string, string> & { sum: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = {
      nonBinary: 'Non Binary',
      male: 'Male',
      female: 'Female',
      decline: 'Declined'
    }

    return labelValuePairs[countValue] || countValue;
  },
  'sediCertification->>selfCertifiedSEDIDemographicsRelatedBusinessStatus': (
    row: Record<string, string> & { count: string }
  ): string => {
    const countValue = row['?column?'];
    if (!countValue) {
      return 'Unknown';
    }

    const labelValuePairs = Object.values(
      SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS
    );

    const matchingPair = labelValuePairs.find(
      ({ value }) => countValue.includes(value)
    );
    if (!matchingPair) {
      return countValue;
    }

    return matchingPair.label;
  },
};

export const getFilteringOptions = (
  portalType: string
): Record<string, any> => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const filteringOptions = {
    sumBy: { ...FILTERING_CONFIG.sumBy },
    groupBy: { ...FILTERING_CONFIG.groupBy },
    timeSeries: { ...FILTERING_CONFIG.timeSeries },
  };

  if (portalType !== PORTAL_TYPES.edo) {
    delete filteringOptions.groupBy.Lender.lenderType;
    delete filteringOptions.groupBy.VentureCapitalFirm.lenderType;
    delete filteringOptions.groupBy.ProgramRequest.lenderId;
    delete filteringOptions.groupBy.ProgramRequest.ventureCapitalFirmId;
    delete filteringOptions.sumBy['MoneyDistribution.Obligated'];
    delete filteringOptions.sumBy['MoneyDistribution.Expended'];
  }

  return {
    sumBy: Object.fromEntries(
      Object.entries(filteringOptions.sumBy).map(([key, value]) => [
        key,
        Object.keys(value),
      ])
    ),
    groupBy: Object.fromEntries(
      Object.entries(filteringOptions.groupBy).map(([key, value]) => [
        key,
        Object.keys(value),
      ])
    ),
    timeSeries: Object.fromEntries(
      Object.entries(filteringOptions.timeSeries).map(([key, value]) => [
        key,
        Object.keys(value),
      ])
    ),
  };
};

export function generateChartImagePdf(chart: ChartJS): any {
  const canvas = chart.canvas;
  const imageURL = canvas.toDataURL('image/png');
  const docDefinition = {
    content: [
      {
        image: imageURL,
        width: 480,
        height: 480,
      },
    ],
  };

  const pdf = pdfMake.createPdf(docDefinition);
  return pdf.download();
}

export function exportChartData(data: Record<string, string>[]): any {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const csvURL = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = csvURL;
  downloadLink.setAttribute('test', 'test.csv');
  downloadLink.click();
}

export function mapColumnIdToLabel(value: string): string {
  if (value === 'createdDate') {
    return 'Number of';
  }

  if (value === 'sediBusiness') {
    return 'SEDI Business';
  }

  if (value === 'fundingProgramId') {
    return 'Program';
  }

  if (value === 'lenderId') {
    return 'Lender';
  }

  if (value === 'businessId') {
    return 'Business';
  }

  if (value === 'ventureCapitalFirmId') {
    return 'Venture Capitalist';
  }

  if (value.includes('SEDIDemographics')) {
    return 'SEDI Demographics';
  }

  if (value.includes('demographics') && value.includes('gender')) {
    return 'Demographics - Gender';
  }

  if (value.includes('demographics') && value.includes('veteranStatus')) {
    return 'Demographics - Veteran Status';
  }

  if (value.includes('demographics') && value.includes('sexualOrientation')) {
    return 'Demographics - Sexual Orientation';
  }

  if (value.includes('minorityStatus') && value.includes('isWomanOwned')) {
    return 'Minority Status - Woman Owned';
  }

  if (value.includes('minorityStatus') && value.includes('isMinorityOwned')) {
    return 'Minority Status - Minority Owned';
  }

  if (value.includes('minorityStatus') && value.includes('isVeteranOwned')) {
    return 'Minority Status - Veteran Owned';
  }

  if (value.includes('demographics') && value.includes('hispanicOrLatino')) {
    return 'Demographics - Hispanic or Latino (Ethnicity)';
  }

  return camelCaseToTitleText(value);
}

export const mapQueryToWords = ({
  model,
  fn,
  count,
}: AdhocReportQuery): string => {
  if (fn === 'sumBy') {
    if (model.includes('Obligated')) {
      return `Obligated Funds by ${mapColumnIdToLabel(count)}`
    }

    if (model.includes('Expended')) {
      return `Expended Funds by ${mapColumnIdToLabel(count)}`
    }

    return `Funds by ${mapColumnIdToLabel(count)}`
  }

  if (fn === 'groupBy') {
    return `${pascalCaseToTitleCase(model)} by ${mapColumnIdToLabel(count)}`;
  }

  if (fn === 'timeSeries') {
    return `${pascalCaseToTitleCase(model)} Time Series`;
  }

  return '';
};

export const PERIODICITY_OPTIONS = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

export const START_OF_TIME_OPTIONS = {
  day: [2, 3, 5, 7, 15, 30].map((e) => ({
    value: `${e - 1} day`,
    label: `${e} days`,
  })),
  week: [2, 4, 8, 12].map((e) => ({
    value: `${e - 1} week`,
    label: `${e} weeks`,
  })),
  month: [2, 3, 6, 9, 12].map((e) => ({
    value: `${e - 1} month`,
    label: `${e} months`,
  })),
};
