import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const CurrencyDecimal = ({
  value,
  label,
  name,
  onChange,
  className,
  labelClassName,
  inputClassName,
}) => {
  const handleChange = (e) => {
    const eTmp = { ...e };
    if (e.target.value.length === 1) {
      eTmp.value = `0${e.target.value}`;
    } else {
      eTmp.value = e.target.value;
    }
    handleInputChange(eTmp, onChange);
  };

  return (
    <div className={className}>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <div className="d-flex align-items-center">
        <h5 className="mb-0 me-8">.</h5>
        <div>
          <Form.Control
            name={name}
            type="number"
            value={value}
            id="inputCurrencyDecimal"
            className={inputClassName}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CurrencyDecimal;
