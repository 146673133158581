import {
  faArrowRight,
  faArrowsAlt,
  faBell,
  faBook,
  faBriefcase,
  faCaretDown,
  faCaretRight,
  faChartLine,
  faCheck,
  faClipboardCheck,
  faCommentDollar,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faFolderOpen,
  faGlobe,
  faGripLines,
  faHistory,
  faHome,
  faInfoCircle,
  faLaptop,
  faLocationArrow,
  faMapMarkerAlt,
  faMoneyBill,
  faMoneyCheckAlt,
  faNetworkWired,
  faPaperPlane,
  faPhone,
  faPlusCircle,
  faSearch,
  faShieldAlt,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
  faUpload,
  faUser,
  faUsers,
  faWindowClose,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';

export const dealFlowIcon = faGripLines;
export const editIcon = faEdit;
export const usersIcon = faUsers;
export const userIcon = faUser;
export const programsIcon = faFolderOpen;
export const billingIcon = faMoneyCheckAlt;
export const settingsIcon = faWrench;
export const apiConnectIcon = faNetworkWired;
export const securityIcon = faShieldAlt;
export const activityIcon = faArrowsAlt;
export const checkmarkIcon = faCheck;
export const caretRightIcon = faCaretRight;
export const caretDownIcon = faCaretDown;
export const searchIcon = faSearch;
export const infoIcon = faInfoCircle;
export const dashboardIcon = faLaptop;
export const capitalOpportunitiesIcon = faCommentDollar;
export const phoneIcon = faPhone;
export const mapLocationIcon = faMapMarkerAlt;
export const arrowRightIcon = faArrowRight;
export const emailIcon = faEnvelope;
export const websiteIcon = faGlobe;
export const completedLoanIcon = faClipboardCheck;
export const reportingIcon = faBook;
export const portfolioIcon = faBriefcase;
export const lenderIcon = faFolderOpen;
export const vcIcon = faFolderOpen;
export const businessIcon = faFolderOpen;
export const transactionIcon = faMoneyBill;
export const migrateIcon = faPaperPlane;
export const addIcon = faPlusCircle;
export const homeIcon = faHome;
export const groupIcon = faUsers;
export const academyIcon = faFolderOpen;
export const prospectsIcon = faChartLine;
export const historyIcon = faHistory;
export const complianceIcon = faClipboardCheck;
export const myLoanIcon = faFolderOpen;
export const downloadIcon = faDownload;
export const missingIcon = faExclamation;
export const inviteIcon = faEnvelope;
export const warningIcon = faExclamationTriangle;
export const deleteIcon = faTrash;
export const platformsIcon = faSitemap;
export const notificationBellIcon = faBell;
export const readIcon = faEye;
export const locationArrowIcon = faLocationArrow;
export const fundIcon = faMoneyBill;

export const sortIcon = faSort;
export const ascSortIcon = faSortUp;
export const descSortIcon = faSortDown;
export const watchlistIcon = faEye;
export const uploadIcon = faUpload;

export const closeIcon = faWindowClose;
