const SectionHeader = ({
  label,
  className,
  labelClassName,
}) => (
  <div className={className}>
    <h5 className={labelClassName}>{label}</h5>
    <hr />
  </div>
);

export default SectionHeader;
