import { Card, Col, Container, Row } from "react-bootstrap";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { useCallback, useEffect } from "react";
import { dashboardSignal } from "signals/DashboardSignal";
import {
  fetchAndSetDashboardData,
  getAllocationOfFundsMetrics,
  getApplicationsMetrics,
  getBusinessDemographicsMetrics,
  getFundMetrics,
  getLoanAmountMetrics,
  getPortfolioCompanyBusinessDemographicsMetrics,
  getProgramMetrics,
  getProgramRequestMetrics,
  getSubmissionsPerDayMetrics,
  getVcPortfolioCompanyMetrics
} from "./Dashboard.helpers";
import DashboardChooseProgram from "./components/DashboardChooseProgram";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  allocatedFundsChartOptions,
  barGraphOptions,
  generalDoughnutChartOptions,
  generalDoughnutChartWhiteTxtOptions,
  lineGraphOptions
} from "components/global/ChartJS/chartPresetsAndStyles";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import userAccountSignal from "signals/UserAccount.signal";
import $appSettings from "signals/AppSettings.signal";

const Dashboard = () => {
  const location = useLocation();
  const { PORTAL_TYPES } = $appSettings.value.constants;

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title:
        userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo
          ? 'EDO Dashboard'
          : 'Lender Dashboard',
    });
  }, []);

  return (
    <>
      <DashboardChooseProgram />
      <Metrics />
    </>
  );
};

const Metrics = () => {
  const {
    applications,
    applicationsMetrics,
    submissionsPerDayMetrics,
    businessDemographicsMetrics,
    loanAmountMetrics,
    selectedPlatformId,
    selectedFundingProgramId,
    fundingPrograms,
    funds,
    fundMetrics,
    programRequestMetrics,
    vcPortfolioCompanyMetrics,
    portfolioCompanyDemographicsMetrics,
    programRequests,
    portfolioCompanies,
    programMetrics,
    allocationOfFundsMetrics,
    platforms,
  } = dashboardSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const fundingProgram = fundingPrograms.find(
    (fp) => fp.id === selectedFundingProgramId
  );
  const isEquity =
    fundingProgram?.referenceProgram?.programType?.programTypeName === 'Equity';
  const init = useCallback(async () => {
    await fetchAndSetDashboardData();
  });

  useEffect(() => {
    init();
  }, [selectedPlatformId, selectedFundingProgramId]);

  useEffect(() => {
    if (portalType === PORTAL_TYPES.edo) {
      getProgramMetrics();
      getAllocationOfFundsMetrics();
      if (!selectedFundingProgramId || isEquity) {
        getFundMetrics();
        getProgramRequestMetrics();
        getVcPortfolioCompanyMetrics();
        getPortfolioCompanyBusinessDemographicsMetrics();
      }
    }
    if (!selectedFundingProgramId || !isEquity) {
      getApplicationsMetrics();
      getSubmissionsPerDayMetrics();
      getBusinessDemographicsMetrics();
      getLoanAmountMetrics();
    }
  }, [applications, funds, programRequests, portfolioCompanies]);

  return (
    <Container className="p-16">
      {portalType === PORTAL_TYPES.edo && (
        <>
          <h3 hidden={selectedPlatformId || selectedFundingProgramId}>All Platforms</h3>
          <h3 hidden={selectedFundingProgramId}>{platforms.find(p => p.id === selectedPlatformId)?.name}</h3>
          <h3 hidden={!selectedFundingProgramId}>{fundingPrograms.find(fp => fp.id === selectedFundingProgramId)?.name}</h3>
          <Row className="px-8 py-16 mb-32 rounded h-100">
            {!selectedFundingProgramId && (
              <Col className="text-center">
                <Card className="p-16 bg-white text-center rounded-0">
                  <h5 className="mb-24">
                    Distribution of Allocated Funds ($M)
                  </h5>
                  <Pie
                    style={{ maxHeight: 500 }}
                    data={allocationOfFundsMetrics}
                    options={allocatedFundsChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </Card>
              </Col>
            )}
            <Col className="text-center">
              <Card className="p-16 bg-white text-center rounded-0 h-100">
                <h5>Distribution of Funds by Status ($M)</h5>
                <Pie
                  style={{ maxHeight: 500 }}
                  data={programMetrics}
                  options={allocatedFundsChartOptions}
                  plugins={[ChartDataLabels]}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
      {(!selectedFundingProgramId || !isEquity) && (
        <>
          <h3
            hidden={
              selectedFundingProgramId || portalType === PORTAL_TYPES.lender
            }
          >
            Credit Programs Overview
          </h3>
          <Row className="px-8 py-16 mb-32 rounded">
            <Col className="text-center text-light mr-8">
              <Card
                className="p-16 rounded-0"
                style={{ backgroundColor: '#294A6A' }}
              >
                <h5>Applications</h5>
                <Doughnut
                  style={{ maxHeight: 300 }}
                  data={applicationsMetrics}
                  options={generalDoughnutChartWhiteTxtOptions}
                  plugins={[ChartDataLabels]}
                />
              </Card>
            </Col>
            <Col hidden className="text-center">
              <Card className="p-16 bg-white text-center rounded-0">
                <h5>$28,543,234.56</h5>
                <p>Total allocation requested</p>
                <h5>$28,543,234.56</h5>
                <p>Total allocation approved</p>
              </Card>
            </Col>
            <Col>
              <Card className="p-16 bg-white text-center h-100 rounded-0">
                <h5>Submissions per day (last 7 days)</h5>
                <Line
                  options={lineGraphOptions}
                  data={submissionsPerDayMetrics}
                  plugins={[ChartDataLabels]}
                />
              </Card>
            </Col>
          </Row>
          <Row className="mb-32">
            <Col className="mr-8">
              <Card className="p-16 bg-white text-center h-100 rounded-0">
                <h5>Business Demographics</h5>
                <Bar
                  options={barGraphOptions(businessDemographicsMetrics.labels)}
                  data={businessDemographicsMetrics}
                  plugins={[ChartDataLabels]}
                />
              </Card>
            </Col>
            <Col className="mr-8">
              <Card className="p-16 bg-white text-center rounded-0">
                <h5>Transaction Amount Breakdown</h5>
                <Pie
                  style={{ maxHeight: 300 }}
                  data={loanAmountMetrics}
                  options={generalDoughnutChartOptions}
                  plugins={[ChartDataLabels]}
                />
              </Card>
            </Col>
          </Row>
          {!selectedFundingProgramId && <hr />}
        </>
      )}
      {portalType === PORTAL_TYPES.edo &&
        (!selectedFundingProgramId || isEquity) && (
          <>
            <h3 hidden={selectedFundingProgramId}>Equity Programs Overview</h3>
            <Row className="px-8 py-16 mb-32 rounded">
              <Col lg={6} className="text-center text-light">
                <Card
                  className="p-16 rounded-0"
                  style={{ backgroundColor: '#294A6A' }}
                >
                  <h5>Program Requests</h5>
                  <Doughnut
                    style={{ maxHeight: 300 }}
                    data={programRequestMetrics}
                    options={generalDoughnutChartWhiteTxtOptions}
                    plugins={[ChartDataLabels]}
                  />
                </Card>
              </Col>
              <Col lg={6} className="text-center">
                <Card className="p-16 rounded-0">
                  <h5>Funds</h5>
                  <Doughnut
                    style={{ maxHeight: 300 }}
                    data={fundMetrics}
                    options={generalDoughnutChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </Card>
              </Col>
            </Row>
            <Row className="mb-32">
              <Col lg={6} className="text-center">
                <Card className="p-16 rounded-0">
                  <h5>Portfolio Companies</h5>
                  <Doughnut
                    style={{ maxHeight: 300 }}
                    data={vcPortfolioCompanyMetrics}
                    options={generalDoughnutChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </Card>
              </Col>
              <Col lg={6} className="text-center">
                <Card className="p-16 h-100 rounded-0">
                  <h5>Business Demographics</h5>
                  <Bar
                    options={barGraphOptions(
                      portfolioCompanyDemographicsMetrics.labels
                    )}
                    data={portfolioCompanyDemographicsMetrics}
                    plugins={[ChartDataLabels]}
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      <Row>
        {/* <Col>
          <Card className="p-16 bg-white text-center">
            <h5>Submissions By Parish</h5>
            <ChartJsStateMap stateName={states.louisiana} />
          </Card>
        </Col>
        <Col>
          <Card className="p-16 bg-white text-center" hidden>
            <h5>Approval Rates</h5>
            <Bar options={horizontalBarGraphOptions} data={topThreeChannelMetrics} />
            <Bar options={horizontalBarGraphOptions} data={topThreeCampaignMetrics} />
          </Card>
        </Col> */}
      </Row>
      {/*<Row className="mb-32">*/}
      {/*  <Col className="mr-8">*/}
      {/*    <Card className="p-16 bg-white text-center">*/}
      {/*      <h5>SEDI Demographics Tracker</h5>*/}
      {/*      <Pie*/}
      {/*        style={{ maxHeight: 300 }}*/}
      {/*        data={sediDemographicsData}*/}
      {/*        options={generalDoughnutChartOptions}*/}
      {/*        plugins={[ChartDataLabels]}*/}
      {/*      />*/}
      {/*    </Card>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Container>
  );
};

export default Dashboard;
