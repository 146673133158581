import dateOptions from 'components/global/Constant/dateOptions';
import { Col, Row, Table } from 'react-bootstrap';
import platformsSignal from '../Platforms.signal';

const PlatformTreasuryAllocation = () => {
  const { treasuryAllocation } = platformsSignal.value;
  return (
    <>
      <Row className="mt-32">
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  Main Capital (Employment-Based) Allocation (Section 3003(b))
                </th>
                <th>Very Small Business Allocation (Section 3003(f))</th>
                <th>SEDI Allocation (Section 3003(d))</th>
                <th>
                  Initial Eligible Amounts ($800 million of incentive funding
                  <sup>2</sup>) (Section 3003(e))
                </th>
                <th>Total Potential Funding Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {Number(
                    treasuryAllocation?.mainCapitalAllocation
                  )?.toLocaleString('en-US', dateOptions.currencyUSNoDecimal)}
                </td>
                <td>
                  {Number(
                    treasuryAllocation?.verySmallBusinessAllocation
                  )?.toLocaleString('en-US', dateOptions.currencyUSNoDecimal)}
                </td>
                <td>
                  {Number(treasuryAllocation?.sediAllocation)?.toLocaleString(
                    'en-US',
                    dateOptions.currencyUSNoDecimal
                  )}
                </td>
                <td>
                  {Number(
                    treasuryAllocation?.initialEligibleAmounts
                  )?.toLocaleString('en-US', dateOptions.currencyUSNoDecimal)}
                </td>
                <td>
                  {Number(
                    treasuryAllocation?.totalPotentialFundingAmount
                  )?.toLocaleString('en-US', dateOptions.currencyUSNoDecimal)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default PlatformTreasuryAllocation;
