import loaderSignal from "signals/Loader.signal";
import { zipcodeRegex } from "../Constant/regularExpression";
import saveBusinessSearchSignal from "./saveBusinessSearchModal.signal";
import alertSignal from "signals/Alert.signal";
import api from "api/api";

export const SAVE_BUSINESS_SEARCH_MODAL_TYPES = {
  edit: 'edit',
  create: 'create',
};

export const handleSaveSearchInputChange = (e) => {
  const { formErrors } = saveBusinessSearchSignal.value;
  const { name, value } = e;
  let newFormErrors = {};

  if (name === 'location') {
    newFormErrors = {
      location: false,
    };
  }

  if (name !== 'time') {
    newFormErrors = {
      ...newFormErrors,
      parameterRequired: false,
    };
  } else {
    newFormErrors = {
      ...newFormErrors,
      time: false,
    };
  }

  saveBusinessSearchSignal.update({
    [name]: value,
    formErrors: {
      ...formErrors,
      ...newFormErrors,
    },
  });
};

export const handleDayOfTheWeekChange = (e) => {
  const { name } = e;
  const { formErrors } = saveBusinessSearchSignal.value;

  saveBusinessSearchSignal.update({
    [name]: !saveBusinessSearchSignal.value[name],
    formErrors: {
      ...formErrors,
      days: false,
    },
  });
};

export const handleShowSaveSearchCreateModal = () => {
  saveBusinessSearchSignal.reset();

  saveBusinessSearchSignal.update({ modalVisible: true, modalType: SAVE_BUSINESS_SEARCH_MODAL_TYPES.create });
};

export const handleShowSaveSearchFromResultsCreateModal = (currentSearchTerms) => {
  saveBusinessSearchSignal.reset();

  const stateToUpdate = currentSearchTerms.reduce((result, item) => {
    result = {
      ...result,
      [item.label]: item.value,
    }
    return result;
  }, {});

  saveBusinessSearchSignal.update({
    modalVisible: true,
    modalType: SAVE_BUSINESS_SEARCH_MODAL_TYPES.create,
    ...stateToUpdate,
  });
};

export const handleShowSaveSearchEditModal = ({ industry, fundingRange, location, search }) => {
  saveBusinessSearchSignal.reset();

  saveBusinessSearchSignal.update({
    modalVisible: true,
    modalType: SAVE_BUSINESS_SEARCH_MODAL_TYPES.edit,
    time: search.minutesFromMidnight > 0 ? search.minutesFromMidnight / 60 : 0,
    industry,
    fundingRange,
    location,
    selectedSearchId: search.id,
    sundayEnabled: search.sundayEnabled,
    mondayEnabled: search.mondayEnabled,
    tuesdayEnabled: search.tuesdayEnabled,
    wednesdayEnabled: search.wednesdayEnabled,
    thursdayEnabled: search.thursdayEnabled,
    fridayEnabled: search.fridayEnabled,
    saturdayEnabled: search.saturdayEnabled,
  });
};

export const handleCloseSaveSearchModal = () => {
  saveBusinessSearchSignal.reset();
};

export const handleSaveSearchZipCodeInputBlur = (e) => {
  const { formErrors } = saveBusinessSearchSignal.value;
  const { value } = e.target;

  saveBusinessSearchSignal.update({
    formErrors: {
      ...formErrors,
      location: value ? !zipcodeRegex.test(value) : false,
    },
  });
};

const validateSaveSearchInputs = () => {
  const { industry, location, fundingRange, formErrors, time, sundayEnabled, mondayEnabled, tuesdayEnabled, wednesdayEnabled, thursdayEnabled, fridayEnabled, saturdayEnabled } = saveBusinessSearchSignal.value;
  let newFormErrors = {};

  if (!industry && !location && !fundingRange) {
    newFormErrors = { parameterRequired: true };
  }

  if (location) {
    newFormErrors = {
      ...newFormErrors,
      location: !zipcodeRegex.test(location),
    };
  }

  if (!time) {
    newFormErrors = {
      ...newFormErrors,
      time: true,
    };
  }

  if (!sundayEnabled && !mondayEnabled && !tuesdayEnabled && !wednesdayEnabled && !thursdayEnabled && !fridayEnabled && !saturdayEnabled) {
    newFormErrors = {
      ...newFormErrors,
      days: true,
    };
  }

  saveBusinessSearchSignal.update({
    formErrors: {
      ...formErrors,
      ...newFormErrors,
    }
  });

  if (Object.values(saveBusinessSearchSignal.value.formErrors).includes(true)) {
    return false;
  }

  return true;
};

const buildSearchQuery = () => {
  const { industry, location, fundingRange } = saveBusinessSearchSignal.value;
  const where = {};

  if (industry) {
    where.assistance = {
      path: ['industrySelection'],
      array_contains: industry,
    };
  }

  if (location) {
    where.address = {
      path: ['postalCode'],
      equals: location,
    };
  }

  if (fundingRange) {
    const fundingRangeSplit = fundingRange
      .split('-')
      .map((r) => r.trim().replaceAll(/[$+]/g, ''));

    let min, max;

    if (fundingRangeSplit.length === 1) {
      min = Number(fundingRangeSplit[0]);
      max = 10000000;
    } else {
      min = Number(fundingRangeSplit[0]);
      max = Number(fundingRangeSplit[1]);
    }

    where.OR = [
      {
        assistance: {
          path: ['seekingMin'],
          gte: min,
          lte: max,
        },
      },
      {
        assistance: {
          path: ['seekingMax'],
          gte: min,
          lte: max,
        },
      },
    ];
  }

  return { where };
}

export const saveSearch = async (callback) => {
  const {
    time,
    sundayEnabled,
    mondayEnabled,
    tuesdayEnabled,
    wednesdayEnabled,
    thursdayEnabled,
    fridayEnabled,
    saturdayEnabled,
  } = saveBusinessSearchSignal.value;
  const formIsValid = validateSaveSearchInputs();

  if (!formIsValid) {
    return;
  }

  const query = buildSearchQuery();

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.post({
      path: '/savedSearches',
      body: {
        data: {
          query,
          minutesFromMidnight: Number(time) * 60,
          sundayEnabled,
          mondayEnabled,
          tuesdayEnabled,
          wednesdayEnabled,
          thursdayEnabled,
          fridayEnabled,
          saturdayEnabled,
        },
      },
    });

    callback && callback();

    handleCloseSaveSearchModal();

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully saved search.',
    });

  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const saveSearchChanges = async (callback) => {
  const {
    time,
    selectedSearchId,
    sundayEnabled,
    mondayEnabled,
    tuesdayEnabled,
    wednesdayEnabled,
    thursdayEnabled,
    fridayEnabled,
    saturdayEnabled,
  } = saveBusinessSearchSignal.value;
  const formIsValid = validateSaveSearchInputs();

  if (!formIsValid) {
    return;
  }

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/savedSearches',
      body: {
        where: {
          id: selectedSearchId,
        },
        data: {
          minutesFromMidnight: Number(time) * 60,
          sundayEnabled,
          mondayEnabled,
          tuesdayEnabled,
          wednesdayEnabled,
          thursdayEnabled,
          fridayEnabled,
          saturdayEnabled,
        },
      },
    });

    callback && callback();

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully saved search changes.',
    });

  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const deleteSavedSearch = async (callback) => {
  const { selectedSearchId } = saveBusinessSearchSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.delete({
      path: '/savedSearches',
      body: {
        where: {
          id: selectedSearchId,
        },
      },
    });

    callback && callback();

    handleCloseSaveSearchModal();

    alertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: 'Your search has been deleted',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};
