import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import CapitalCallPortion from "components/global/CapitalCallPortionDetail";

const EdoCapitalCallPortion = () => {
  const labels = [
    '',
    'Capital Call Portion',
  ];

  return (
    <BreadCrumbWrapper labels={labels}>
      <CapitalCallPortion />
    </BreadCrumbWrapper>
  )
};

export default EdoCapitalCallPortion;
