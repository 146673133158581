import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Row } from "react-bootstrap";
import { warningIcon } from "../Constant/Icons";

const FeatureNotAvailable = () => (
  <Alert variant="warning">
    <Row className="justify-content-center">
      <h1><FontAwesomeIcon icon={warningIcon} /></h1>
      <div className="ml-32">
        <h5>Sorry, this feature is not available.</h5>
        <p>Please contact your administrator if you require access.</p>
      </div>
    </Row>
  </Alert>
);

export default FeatureNotAvailable;
