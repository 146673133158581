import Field from 'components/global/Field/Field';
import FilePreviewButton from 'components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { Button, Table } from 'react-bootstrap';
import { isFileTypeValidForPreview } from 'utils/checkFileTypeForPreview';

const DocumentTableWithInputs = ({
  filesToUpload,
  onDocumentNameChange,
  onRemoveDocument,
  onSubmitDocuments,
  submitButtonLabel,
  className,
}) => (
  <div className={className}>
    <Table>
      <thead>
        <tr>
          <th>Document Name</th>
          <th>Preview</th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        {filesToUpload.map((fileBundle, index) => (
          <tr key={`${fileBundle.file.name}-${index}`}>
            <td>
              <Field.TextInput
                value={fileBundle.name}
                name={`${fileBundle.file.name}-${index}`}
                onChange={(e) => onDocumentNameChange(e, index)}
              />
            </td>
            <td>
              {isFileTypeValidForPreview(fileBundle.type) ? (
                <FilePreviewButton
                  render={(submitting, fn) => (
                    <Button
                      size="sm"
                      className="rounded"
                      variant="outline-primary"
                      onClick={() => fn(fileBundle.file)}
                    >
                      View
                    </Button>
                  )}
                />
              ) : (
                <p>No preview available</p>
              )}
            </td>
            <td>
              <Button
                size="sm"
                className="rounded sm"
                variant="outline-danger"
                onClick={() => onRemoveDocument(fileBundle.file.name, index)}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <div className="d-flex flex-row justify-content-end">
      <div>
        <Button
          className="rounded text-right"
          variant="outline-primary"
          onClick={onSubmitDocuments}
        >
          {submitButtonLabel}
        </Button>
      </div>
    </div>
  </div>
);

export default DocumentTableWithInputs;
