import { useEffect } from 'react';
import {
  fetchFundApplication,
  getDocumentArray,
  handleAttachAdditionalDocumentsClick,
  handleDocumentStatusChange,
  handleFundApplicationDocumentSubmit,
  handleToggleSensitveInformationPermission,
  removeAdditionalDocument,
} from './FundApplication.helpers';
import DocumentTable from '../DataRoom/components/DocumentTable';
import DocumentButtons from '../DataRoom/components/DocumentButtons';
import userAccountSignal from 'signals/UserAccount.signal';
import AttachDocumentModal from '../AttachDocumentsModal/AttachDocumentModal';
import fundApplicationSignal from './FundApplication.signals';
import SimpleTable from '../SimpleTable';
import { enumCaseToTabCase } from 'libs/string';
import { Alert, Dropdown } from 'react-bootstrap';
import formatMoney from 'utils/formatMoney';
import { useHistory } from 'react-router-dom';
import $appSettings from 'signals/AppSettings.signal';
import CapTableModal from '../CapTable/CapTableModal';
import { handleCapTableClick } from '../CapTable/capTable.helpers';
import Field from '../Field';
import { getStatusStyles } from 'libs/functions/global.functions';
import $capTable from '../CapTable/capTable.signal';
import CapTable from '../CapTable';

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'round',
    title: 'Round',
  },
  {
    key: 'amount',
    title: 'Amount',
  },
  {
    key: 'createdDate',
    title: 'Created Date',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FundApplication = ({
  viewType,
  requiredDocumentDescription,
  suggestedDocumentDescription,
  additionalDocumentDescription,
  onAttachSingleDocument = () => null,
  downloadParentId,
  isModalView,
}) => {
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const split = pathname.split('/');
  const id = split[split.length - 1];
  const { fundApplication } = fundApplicationSignal.value;
  const { FUND_DOCUMENT_TYPES, FUND_APPLICATION_DOCUMENT_STATUS } =
    $appSettings.value.constants;
  const requiredDocuments = getDocumentArray(FUND_DOCUMENT_TYPES.required);
  const suggestedDocuments = getDocumentArray(FUND_DOCUMENT_TYPES.suggested);
  const additionalDocuments = fundApplication
    ? fundApplication.documents
        .filter((doc) => !doc.fundDocumentId)
        .map((d) => ({
          ...d.businessDocument,
          fundApplicationDocumentId: d.id,
        }))
    : [];
  const { PORTAL_TYPES, CAPITAL_CALL_STATUS } = $appSettings.value.constants;

  useEffect(() => {
    if (!isModalView) {
      fetchFundApplication(id);
    }

    return () => {
      fundApplicationSignal.reset();
      $capTable.reset();
    };
  }, [id]);

  return (
    <div className="bg-white">
      {userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.business && (
        <>
          <CapTableModal />
          <AttachDocumentModal
            overArchingDocumentType="businessDocuments"
            onDocumentsSubmit={handleFundApplicationDocumentSubmit}
            dataRoomDocuments={
              fundApplication?.vcPortfolioCompany?.business
                ?.businessDocuments || []
            }
          />
          {!!fundApplication?.vcPortfolioCompany?.capitalCallPortions
            ?.length && (
            <>
              <h3>Capital Call Rounds</h3>
              <div className="extend-table-container">
                <SimpleTable
                  headers={HEADERS}
                  rows={fundApplication.vcPortfolioCompany.capitalCallPortions.map(
                    (ccp, index) => {
                      const statusHasMovedPastPending =
                        ccp.capitalCall.status ===
                          CAPITAL_CALL_STATUS.approved ||
                        ccp.capitalCall.status ===
                          CAPITAL_CALL_STATUS.submittedForApproval ||
                        ccp.capitalCall.status === CAPITAL_CALL_STATUS.funded ||
                        ccp.capitalCall.status ===
                          CAPITAL_CALL_STATUS.disbursed;

                      const capTable = statusHasMovedPastPending
                        ? ccp.capTable?.tableData
                        : fundApplication?.vcPortfolioCompany?.business
                            ?.capTable?.tableData;
                      return {
                        status: () => (
                          <td
                            className={`${
                              getStatusStyles(ccp.capitalCall.status).class
                            }`}
                          >
                            {enumCaseToTabCase(ccp.capitalCall.status)}
                          </td>
                        ),
                        round: index + 1,
                        amount: formatMoney(ccp.amount),
                        createdDate: new Date(
                          ccp.capitalCall.createdDate
                        ).toLocaleDateString(),
                        action: () => (
                          <td align="right">
                            <Dropdown>
                              <Dropdown.Toggle
                                size="sm"
                                variant="outline-primary"
                              >
                                Actions
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    history.push(
                                      `${pathname}/capital-call-portion/${ccp.id}`
                                    )
                                  }
                                >
                                  Detail
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleCapTableClick(capTable, () =>
                                      fetchFundApplication(id),
                                      null,
                                      !statusHasMovedPastPending,
                                    )
                                  }
                                >
                                  {statusHasMovedPastPending
                                    ? 'View Cap Table'
                                    : 'Configure Cap Table'}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        ),
                      };
                    }
                  )}
                />
              </div>
            </>
          )}
        </>
      )}
      <h3 hidden={isModalView} className="mb-40">
        Fund Application
      </h3>
      {requiredDocuments.every(
        (doc) => doc.status === FUND_APPLICATION_DOCUMENT_STATUS.approved
      ) && (
        <Alert variant="success">
          <h5>Approved</h5>
          <p>
            All required documents have been reviewed and approved for this
            application.
          </p>
        </Alert>
      )}
      {userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.business && (
        <div className="mb-24">
          <Field.Checkbox
            label="Allow the VC and EDO to view your Cap Table?"
            labelClassName="font-weight-bold mb-0"
            value={
              fundApplication?.vcPortfolioCompany
                ?.sensitiveInformationPermission
            }
            onChange={handleToggleSensitveInformationPermission}
          />
          <small>
            * This is required in order for your application to be approved.
          </small>
        </div>
      )}
      <DocumentButtons
        title="Required Documents"
        description={requiredDocumentDescription}
        documents={requiredDocuments}
        documentBucket="businessDocuments"
        onSelectChange={handleDocumentStatusChange}
        viewType={viewType}
        onAttachSingleDocument={onAttachSingleDocument}
        downloadParentId={downloadParentId}
      />
      <DocumentButtons
        className="mt-40"
        title="Suggested Documents"
        description={suggestedDocumentDescription}
        documents={suggestedDocuments}
        documentBucket="businessDocuments"
        onSelectChange={handleDocumentStatusChange}
        viewType={viewType}
        onAttachSingleDocument={onAttachSingleDocument}
        downloadParentId={downloadParentId}
      />
      <DocumentTable
        headerButton={
          userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.business
            ? {
                label: 'Add Additional Documents',
                className: 'rounded',
                variant: 'outline-primary',
                onClick: handleAttachAdditionalDocumentsClick,
              }
            : null
        }
        className="mt-56"
        tableTitle="Additional Documents"
        tableDescription={additionalDocumentDescription}
        documents={additionalDocuments}
        documentBucket="businessDocuments"
        viewType={viewType}
        removeDocument={
          userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.business
            ? removeAdditionalDocument
            : null
        }
      />
      {userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.edo ||
        (userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.vc && (
          <div className="mt-56">
            <h5>Cap Table</h5>
            {fundApplication?.vcPortfolioCompany?.sensitiveInformationPermission ? (
              <CapTable />
            ) : (
              <Alert variant="warning">
                <h5>Missing Permission</h5>
                <p>
                  The business has not granted you access to view their
                  sensitive information.
                </p>
              </Alert>
            )}
          </div>
        ))}
    </div>
  );
};

export default FundApplication;
