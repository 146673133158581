import Signal from 'libs/signals';

export const PROGRAM_INFO_INIT_STATE = {
  id: null,
  step: 1,
  name: '',
  platformId: '',
  referenceProgramId: '',
  programTypeId: '',
  administratorId: '',
  overview: '',
  eligibility: '',
  programRules: '',
  website: '',
  ceiling: 0,
  fundsAlreadyAllocated: 0,
  programRequestTemplates: [],
  programRequestSuggestedDocuments: [],
  applicationTemplates: [],
  applicationSuggestedDocuments: [],
  maxCollateralSupportPercentage: '',
  minCollateralSupportPercentage: '',
  programClassification: '',
  fundsUnderCustodyAt: '',
  ineligibleBusinesses: '',
  minGuarantyPercentage: '',
  maxGuarantyPercentage: '',
  fundType: '',
  approvalChainTemplateSteps: [
    {
      primaryApproverId: '',
      role: '',
      alternateApprovers: [],
    },
  ],
};

const ProgramSignal = Signal({
  administrators: [],
  programs: [],
  programTypes: [],
  platforms: [],
  programInfo: PROGRAM_INFO_INIT_STATE,
  dropdownFilters: {},
  sort: { key: 'id', dir: 'desc' },
  bundle: {},
  appliedFilters: {},
});

export default ProgramSignal;

export const fundingProgramsSignal = Signal([]);

export const selectedFundingProgramSignal = Signal({
  programInfo: null,
  programTypes: [],
  enrolledOrganizations: [],
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
  recordFetchType: '',
});
export const bankAccountsSignal = Signal([]);

export const userAccessSignal = Signal({
  filter: '',
  adminAndStaffUsers: [],
  usersInView: [],
  filteredUsers: [],
  currentPage: 0,
});

export const fundingProgramFormErrorsSignal = Signal({});
