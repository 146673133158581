import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { securityIcon } from 'components/global/Constant/Icons';
import TwoStepVerificationModal from 'components/global/TwoStepVerificationModal';
import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

const LenderSecurity = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col className="pl-0">
          <Card className="p-16 text-secondary">
            <Row className="mb-32">
              <Col>
                <h5><FontAwesomeIcon icon={securityIcon} /></h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="rounded"
                  onClick={() => setShowModal(true)}
                >
                  Two-Step Verification
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <TwoStepVerificationModal
        show={showModal}
        onToggleModal={() => setShowModal(false)}
      />
    </Container>
  );
};

export default LenderSecurity;
