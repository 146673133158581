import { Button, Row, Tab, Tabs } from 'react-bootstrap';
import ApplicationGeneralTab from '../Approvable/components/ApprovableGeneralInformationTab';
import ApprovableSendBackModal from '../Approvable/components/ApprovableSendBackModal';
import applicationDetailSignal from './ApplicationDetail.signal';
import {
  hideApplicationDetailsModal,
} from './ApplicationDetail.helpers';
import ApplicationWatchlistTab from './components/ApplicationWatchlistTab';
import userAccountSignal from 'signals/UserAccount.signal';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import ApplicationApprovalChainTab from './components/ApplicationApprovalChainTab/ApplicationApprovalChainTab';
import loaderSignal from 'signals/Loader.signal';
import ApplicationBlockchainTab from './components/ApplicationBlockchainTab';
import { ocrSignal } from '../OCR/ocr.signal';
import applicationComplianceSignal from '../ApplicationCompliance/ApplicationCompliance.signal';
import ApprovableLinkBankAccountAlert from '../Approvable/components/ApprovableLinkBankAccountAlert';
import { showSendBackApplicationModal } from '../Approvable/components/ApprovableSendBackModal.helpers';
import { AmortizationScheduleTab, LedgerTab } from '../Ledger/Ledger';
import ApplicationComplianceTab from './components/ApplicationComplianceTab';
import ApprovableStatusTab from '../Approvable/components/ApprovableStatusTab';
import ApprovableDocumentsTab from '../Approvable/components/ApprovableDocumentsTab';
import ApplicationFormTab from './components/ApplicationFormTab';
import $appSettings from 'signals/AppSettings.signal';

type Props = {
  isModal: boolean;
};

const ApplicationDetail: React.FC<Props> = ({ isModal }) => {
  const userData = userAccountSignal.value.userData;
  const {
    edoUnread,
    lenderUnread,
    status,
    edoUnreadDate,
    ventureCapitalist,
    currentTab,
  } = applicationDetailSignal.value;
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;
  const { isContentLoading } = loaderSignal.value;
  const { pathname, search } = useLocation();
  const activeTab = new URLSearchParams(search).get('tab');
  const topRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = () => {
    if (isModal) {
      topRef.current?.scrollIntoView({ block: 'start' });
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(
    () => () => {
      ocrSignal.reset();
      applicationComplianceSignal.reset();
      applicationDetailSignal.reset();
    },
    []
  );

  return (
    <div ref={topRef}>
      <ApprovableLinkBankAccountAlert />
      <Tabs
        defaultActiveKey={activeTab || 'general'}
        onSelect={(e) =>
          applicationDetailSignal.update({ currentTab: e as string })
        }
      >
        <Tab eventKey="general" title="General">
          <div
            className={
              isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
            }
          >
            <ApplicationGeneralTab isModal={isModal} />
          </div>
        </Tab>
        <Tab eventKey="documents" title="Documents">
          <div
            className={
              isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
            }
          >
            <ApprovableDocumentsTab />
          </div>
        </Tab>
        <Tab eventKey="ocrInformation" title="OCR Document">
          <div
            className={
              isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
            }
          >
            <ApplicationFormTab />
          </div>
        </Tab>
        <Tab
          hidden={pathname.split('/')[2] !== 'portfolio'}
          tabClassName={pathname.split('/')[2] !== 'portfolio' ? 'd-none' : ''}
          eventKey="compliance"
          title="Compliance"
        >
          <div
            className={
              isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
            }
          >
            <ApplicationComplianceTab />
          </div>
        </Tab>
        <Tab eventKey="status" title="Status">
          <div
            className={
              isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
            }
          >
            <ApprovableStatusTab />
          </div>
        </Tab>
        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.edo && (
          <Tab eventKey="blockchain" title="Blockchain">
            <div
              className={
                isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
              }
            >
              <ApplicationBlockchainTab />
            </div>
          </Tab>
        )}
        {applicationDetailSignal.value.status ===
          APPLICATION_STATUS.approved && (
          <Tab eventKey="amortizationSchedule" title="Amortization Schedule">
            <div
              className={
                isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
              }
            >
              <AmortizationScheduleTab />
            </div>
          </Tab>
        )}
        {applicationDetailSignal.value.status ===
          APPLICATION_STATUS.approved && (
          <Tab eventKey="ledger" title="Ledger">
            <div
              className={
                isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
              }
            >
              <LedgerTab isModal={isModal} />
            </div>
          </Tab>
        )}
        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.lender && (
          <Tab eventKey="watchlist" title="Watchlist">
            <div
              className={
                isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
              }
            >
              <ApplicationWatchlistTab />
            </div>
          </Tab>
        )}
        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.edo && (
          <Tab eventKey="approvalChain" title="Approval Chain">
            <div
              className={
                isModal ? 'p-32' : 'bg-white border border-gray p-32 shadow-sm'
              }
            >
              <ApplicationApprovalChainTab />
            </div>
          </Tab>
        )}
      </Tabs>
      <Row
        className={`mt-16 px-16 ${
          currentTab === 'compliance'
            ? 'justify-content-between'
            : 'justify-content-end'
        }`}
      >
        <Button
          hidden={currentTab !== 'compliance'}
          onClick={scrollToTop}
          className="rounded"
          variant="outline-secondary"
        >
          Back to Top
        </Button>
        <div>
          <Button
            hidden={pathname.split('/').length > 3}
            variant="outline-secondary"
            className="rounded mr-8"
            onClick={hideApplicationDetailsModal}
          >
            Close
          </Button>
          <Button
            className="rounded"
            title={
              edoUnread || lenderUnread
                ? 'Please wait for the application to be sent back.'
                : ''
            }
            disabled={
              isContentLoading ||
              (userData.account.portalType === PORTAL_TYPES.edo
                ? lenderUnread
                : edoUnread)
            }
            hidden={
              userData.account.portalType === PORTAL_TYPES.edo
                ? status !== APPLICATION_STATUS.incomplete &&
                  status !== APPLICATION_STATUS.pending
                : status !== APPLICATION_STATUS.incomplete
            }
            onClick={() => showSendBackApplicationModal()}
          >
            {userData.account.portalType === PORTAL_TYPES.edo
              ? status !== APPLICATION_STATUS.incomplete
                ? `Send back to ${ventureCapitalist ? 'VC' : 'Lender'}`
                : `Send back to ${ventureCapitalist ? 'VC' : 'Lender'} ${
                    edoUnreadDate
                      ? new Date(edoUnreadDate).toLocaleDateString()
                      : ''
                  }`
              : 'Send back to EDO'}
          </Button>
        </div>
      </Row>
      <ApprovableSendBackModal />
    </div>
  );
};

export default ApplicationDetail;
