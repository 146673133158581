import { Button, Dropdown, Tab, Tabs } from 'react-bootstrap';
import portfolioApplicationsSignal from 'signals/EdoPortfolio.signal';
import { useEffect } from 'react';
import {
  handleDownloadApplicationsData,
  handleEdoPortfolioAppliedFiltersChange,
  handleEdoPortfolioFilterChange,
  resetEdoPortfolioFilterInputs,
  setAndFetchEdoApplications,
} from './EdoPortfolio.helpers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ApplicationDetailModal from 'components/global/ApplicationDetail/ApplicationDetailModal';
import SimpleTable from '../../../global/SimpleTable';
import dateOptions from '../../../global/Constant/dateOptions';
import SimpleTableFilterDropdown from 'components/global/SimpleTableFilterDropdown';
import { enumCaseToTabCase, titleCaseString } from '../../../../libs/string';
import {
  getStatusStyles,
  handleTabChange,
} from 'libs/functions/global.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { watchlistIcon } from 'components/global/Constant/Icons';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import PaginationItems from 'components/global/Pagination';
import capitalizeFirst from 'utils/capitalizeFirst';
import SendTechnicalAssistanceModal from '../EdoTechnicalAssistance/components/SendTechnicalAssistanceModal';
import { handleSendTechnicalAssistanceClick } from '../EdoTechnicalAssistance/edoTechnicalAssistance.helpers';
import $appSettings from 'signals/AppSettings.signal';

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'createdDate',
    title: 'Date Submitted',
  },
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'businessName',
    title: 'Business Name',
  },
  {
    key: 'sediBusiness',
    title: 'SEDI',
    sortable: true,
  },
  {
    key: 'contactName',
    title: 'Contact Name',
  },
  {
    key: 'askedAndApprovedAmount',
    title: 'Asked & Approved Amount',
    options: { isCurrency: true },
  },
  {
    key: 'approvalChain',
    title: 'Approval Chain',
    options: { emptyDisplay: '---' },
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const getApprovalChainText = (application) => {
  const approvalChainConfig = application.approvalChainConfig;

  if (!approvalChainConfig) {
    return 'Not started';
  }

  const confirmations = approvalChainConfig.confirmations || [];

  if (confirmations.length > 0 && confirmations.some((c) => !c.confirmedDate)) {
    const sortedConfirmations = [...confirmations.sort((a, b) => a.id - b.id)];
    const index = sortedConfirmations.findIndex((c) => !c.confirmedDate);
    return `Step ${index + 1}`;
  } else {
    return 'Complete';
  }
};

const Table = ({ currentPage }) => {
  const { dropdownFilters, applications, bundle, totalPages } =
    portfolioApplicationsSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  const history = useHistory();

  return (
    <div className="bg-white p-32 border border-gray shadow-sm">
      <div className="d-flex flex-row justify-content-between mb-16">
        <SimpleTableFilterDropdown
          filtersState={dropdownFilters}
          onChange={({ name, value }) =>
            handleEdoPortfolioFilterChange(name, value)
          }
          onApply={(filters) => {
            handleEdoPortfolioAppliedFiltersChange(filters);
            setAndFetchEdoApplications(currentPage);
          }}
          onClearFilterInputs={resetEdoPortfolioFilterInputs}
          filters={[
            {
              type: 'select',
              label: 'Status',
              key: 'status',
              options: Object.entries(APPLICATION_STATUS).map(([, value]) => ({
                label:
                  value === APPLICATION_STATUS.inProgress
                    ? 'DRAFT'
                    : titleCaseString(value),
                value,
              })),
            },
            {
              type: 'integer',
              label: 'ID',
              key: 'id',
            },
            {
              type: 'select',
              label: 'Program Name',
              key: 'programName',
              options: bundle.fundingPrograms?.map((fundingProgram) => ({
                label: fundingProgram.name,
                value: fundingProgram.id,
              })),
            },
            {
              type: 'select',
              label: 'SEDI',
              key: 'sediBusiness',
              options: [
                {
                  label: 'Yes',
                  value: 'Yes',
                },
                {
                  label: 'No',
                  value: 'No',
                },
              ],
            },
          ]}
        />
        <div>
          <Button
            variant="outline-secondary"
            className="rounded mr-16"
            onClick={handleDownloadApplicationsData}
          >
            Download
          </Button>
          <Link
            className="rounded btn btn-outline-info mr-16"
            to={`${location.pathname}/bulk-import`}
          >
            Bulk Import
          </Link>
          <Link
            className="rounded btn btn-primary"
            to="/edo/portfolio/submit-applications"
          >
            New Application
          </Link>
        </div>
      </div>
      <div className="extend-table-container">
        <SimpleTable
          headers={HEADERS}
          // onClick={(index) => {
          // const application = applications[index];
          // showApplicationDetailsModal(
          //   txApplicationToApplicationDetailsModal(
          //     application,
          //     !!application.ventureCapitalFirm
          //   )
          // );
          onSort={(sort) => {
            handleTabChange(sort);
            setAndFetchEdoApplications(currentPage);
          }}
          rows={applications.map((application) => ({
            status: () => (
              <td className={`${getStatusStyles(application.status).class}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-0">
                    {application.status === APPLICATION_STATUS.inProgress
                      ? 'Draft'
                      : capitalizeFirst(application.status.replace(/_/g, ' '))}
                  </p>
                  <FontAwesomeIcon
                    className={
                      application.watchables?.length
                        ? 'text-right ml-16'
                        : 'd-none'
                    }
                    icon={watchlistIcon}
                  />
                </div>
              </td>
            ),
            createdDate: new Date(application.createdDate).toLocaleString(
              'en-US',
              dateOptions.date
            ),
            id: application.id,
            programName: application.fundingProgram.name,
            businessName: application.business?.name,
            sediBusiness: application.sediBusiness ? 'Yes' : 'No',
            contactName: application.business?.contactName,
            askedAndApprovedAmount: `${application.askedAmount.toLocaleString()} / ${
              application.approvedAmount
                ? '$' + application.approvedAmount?.toLocaleString()
                : '-'
            }`,
            approvalChain: getApprovalChainText(application),
            action: ({ key }) => (
              <td key={key}>
                <div className="d-flex">
                  {application.status === APPLICATION_STATUS.denied ? (
                    <Dropdown>
                      <Dropdown.Toggle size="sm" variant="outline-dark">
                        Actions
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            const path = `/edo/portfolio/${application.id}`;
                            history.push(path);
                          }}
                        >
                          Detail
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleSendTechnicalAssistanceClick(
                              application.id,
                              application.technicalAssistanceId
                            )
                          }
                        >
                          Send Technical Assistance
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Button
                      className="rounded"
                      size="sm"
                      variant="outline-dark"
                      onClick={() => {
                        const path = `/edo/portfolio/${application.id}`;
                        history.push(path);
                      }}
                    >
                      Detail
                    </Button>
                  )}
                </div>
              </td>
            ),
          }))}
        />
      </div>
      {totalPages > 1 && (
        <PaginationItems
          className="justify-content-center mt-24"
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

const Portfolio = () => {
  const location = useLocation();
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const bundle = portfolioApplicationsSignal.value.bundle;
  const { tally, numWatchlist } = bundle;
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const activeKey = searchParams.status;
  const currentPage = searchParams.page ? Number(searchParams.page) : 1;

  useEffect(() => {
    setAndFetchEdoApplications(currentPage);
  }, [location.search]);

  return (
    <BreadCrumbWrapper>
      <SendTechnicalAssistanceModal
        onSendCallback={() => setAndFetchEdoApplications(currentPage)}
      />
      <Tabs
        activeKey={activeKey || 'all'}
        defaultActiveKey="all"
        mountOnEnter
        onSelect={(status) => {
          if (status === 'watchlist') {
            handleTabChange(null);
          } else {
            handleTabChange(status);
          }

          if (status === 'watchlist') {
            handleEdoPortfolioAppliedFiltersChange({
              ...portfolioApplicationsSignal.value.appliedFilters,
              watchables: true,
            });
            setAndFetchEdoApplications(currentPage);
          } else {
            handleEdoPortfolioAppliedFiltersChange({
              ...portfolioApplicationsSignal.value.appliedFilters,
              watchables: false,
            });
            setAndFetchEdoApplications(currentPage);
          }
        }}
      >
        <Tab eventKey="all" title={`All\n${bundle.count}`}>
          <Table currentPage={currentPage} />
        </Tab>
        {tally &&
          Object.values(APPLICATION_STATUS).map((status) => {
            const matchingTally = tally.find((t) => t.status === status);
            return (
              <Tab
                key={status}
                eventKey={status}
                title={`${
                  status === 'IN_PROGRESS' ? 'Draft' : enumCaseToTabCase(status)
                } ${matchingTally?._count.status || 0}`}
              >
                <Table currentPage={currentPage} />
              </Tab>
            );
          })}
        <Tab eventKey="watchlist" title={`Watchlist ${numWatchlist || 0}`}>
          <Table currentPage={currentPage} />
        </Tab>
      </Tabs>
      <ApplicationDetailModal currentTab={activeKey} />
    </BreadCrumbWrapper>
  );
};

export default Portfolio;
