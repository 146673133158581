import { getConfig } from 'config/config';
import { Route } from 'react-router-dom';
import { checkPortalPermissions } from '../../../libs/functions/global.functions';
import FeatureNotAvailable from '../FeatureNotAvailable';
import PrivateContainer from './PrivateContainer';
import userAccountSignal from 'signals/UserAccount.signal';

const withPrivateRoute =
  (allowedPortal) =>
  // eslint-disable-next-line react/display-name
  ({ component: Component, ...rest }) => {
    const role = userAccountSignal.value.userData?.role;
    const appConfig = getConfig('APP_CONFIG');

    const excludeFeature = rest.featureExclude[appConfig.appId]?.[role];

    if (!role) {
      return null;
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          checkPortalPermissions(allowedPortal) ? (
            <PrivateContainer portal={allowedPortal}>
              {excludeFeature ? (
                <FeatureNotAvailable />
              ) : (
                <Component {...props} />
              )}
            </PrivateContainer>
          ) : (
            <div>Not permitted</div>
          )
        }
      />
    );
  };

export default withPrivateRoute;
