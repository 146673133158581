import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import userActivationSignal from 'signals/UserActivation.signal';
import loaderSignal from 'signals/Loader.signal';
import $appSettings from 'signals/AppSettings.signal';

export const activateUser = async (onFetchAndSetUsers) => {
  const { USER_STATUS } = $appSettings.value.constants;
  runUserActivationOrDeactivation(USER_STATUS.active, onFetchAndSetUsers);
};

export const deactivateUser = async (onFetchAndSetUsers) => {
  const { USER_STATUS } = $appSettings.value.constants;
  runUserActivationOrDeactivation(USER_STATUS.inactive, onFetchAndSetUsers);
};

const runUserActivationOrDeactivation = async (
  userStatus,
  onFetchAndSetUsers
) => {
  const { selectedUser } = userActivationSignal.value;
  const { USER_STATUS } = $appSettings.value.constants;
  loaderSignal.update({ isContentLoading: true, message: 'Updating user status...' });
  try {
    await api.patch({
      path: '/users',
      body: {
        where: {
          id: selectedUser.id,
        },
        data: {
          userStatus,
        },
      },
    });
    await onFetchAndSetUsers();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: `Successfully ${userStatus === USER_STATUS.active ? 'activated' : 'deactivated'
        } user.`,
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
    toggleActivationUserModal();
  }
};

export const toggleActivationUserModal = ({ user, mode } = {}) => {
  if (user) {
    return userActivationSignal.update({
      modalVisible: !userActivationSignal.value.modalVisible,
      selectedUser: user,
      mode,
    });
  } else {
    return userActivationSignal.reset();
  }
};
