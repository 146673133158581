import { handleTechnicalAssistanceInputChange } from '../edoTechnicalAssistance.helpers';
import edoTechnicalAssistanceSignal from '../edoTechnicalAssistance.signal';
import { Col, Row } from 'react-bootstrap';
import Field from 'components/global/Field';
import RichTextEditor from 'components/global/Field/FieldComponents/RichTextEditor';
import $appSettings from 'signals/AppSettings.signal';
import { enumCaseToTabCase } from 'libs/string';

const TechnicalAssistanceInputs = () => {
  const { technicalAssistanceErrors, title, type, supportingText } =
    edoTechnicalAssistanceSignal.value;
  const { TECHNICAL_ASSISTANCE_TYPES } =
    $appSettings.value.constants;

  return (
    <>
      <Row>
        <Col xs={12} lg>
          <Field.TextInput
            label="Title"
            labelClassName="font-weight-bold"
            inputClassName="form-control"
            name="title"
            value={title}
            onChange={handleTechnicalAssistanceInputChange}
          />
          <small
            hidden={!technicalAssistanceErrors.title}
            className="text-danger"
          >
            Please provide a title.
          </small>
        </Col>
        <Col xs={12} lg className="mt-16 mt-lg-0">
          <Field.Select
            label="Type"
            labelClassName="font-weight-bold"
            inputClassName="form-control"
            name="type"
            value={type}
            onChange={handleTechnicalAssistanceInputChange}
            options={Object.values(TECHNICAL_ASSISTANCE_TYPES).map((type) => ({
              label: enumCaseToTabCase(type),
              value: type,
            }))}
          />
          <small
            hidden={!technicalAssistanceErrors.type}
            className="text-danger"
          >
            Please provide a type.
          </small>
        </Col>
      </Row>
      <p className="font-weight-bold mt-16 mb-8">Supporting Text</p>
      <RichTextEditor
        onChange={(value, name) =>
          handleTechnicalAssistanceInputChange({ name, value })
        }
        value={supportingText}
        name="supportingText"
      />
      <small
        hidden={!technicalAssistanceErrors.supportingText}
        className="text-danger"
      >
        Please provide supporting text.
      </small>
    </>
  );
};

export default TechnicalAssistanceInputs;
