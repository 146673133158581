import loaderSignal from 'signals/Loader.signal';
import Loader from '../Loader';

const Content = ({ children }) => (
  <div className="mt-16 mt-lg-32 min-vh-100 w-100 justify-content-center align-items-center flex-grow-1 px-lg-32 overflow-auto">
    <div
      className={
        !loaderSignal.value.isContentLoading
          ? 'd-none'
          : 'min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1'
      }
    >
      <Loader message={loaderSignal.value.message || 'Loading...'} />
    </div>
    <div className={loaderSignal.value.isContentLoading ? 'd-none' : 'w-100'}>
      {children}
    </div>
  </div>
);

export default Content;
