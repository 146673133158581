import api from 'api/api';
import {
  einRegex,
  naicsCodeRegex,
} from 'components/global/Constant/regularExpression';
import alertSignal from 'signals/Alert.signal';
import businessOnboardingSignal from 'signals/BusinessOnboarding.signal';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import { businessSettingsDirtySignal } from '../../../../signals/BusinessSettings.signal';
import { submitBusiness } from '../BusinessSettings/BusinessSettings.helpers';

export function handlePrevious(e) {
  e.preventDefault();
  // return submitBusiness(e).then(() =>
  return businessOnboardingSignal.update({
    onboardingStep: businessOnboardingSignal.value.onboardingStep - 1,
  });
  // );
}

export function handleNext(e) {
  return submitBusiness(e).then(() =>
    businessOnboardingSignal.update({
      onboardingStep: businessOnboardingSignal.value.onboardingStep + 1,
    })
  );
}

export function handleSectionChange(index) {
  businessOnboardingSignal.update({ onboardingStep: index + 1 });
  businessSettingsDirtySignal.reset();
}

export const hideOrShowOnboarding = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const business = await api.get({
      path: '/businesses',
      options: {
        include: {
          applications: true,
        },
      },
    });

    if (!business.name || !business.ein || !business.naicsCode) {
      businessOnboardingSignal.update({
        onboardingStep: 1,
        showOnboarding: true,
        onboardingFields: {
          name: business.name || '',
          ein: business.ein || '',
          naicsCode: business.naicsCode || '',
        },
      });
    } else {
      businessOnboardingSignal.reset();
    }
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleOnboardingFieldChange = (e) => {
  const { name, value } = e.target;

  businessOnboardingSignal.update({
    onboardingFields: {
      ...businessOnboardingSignal.value.onboardingFields,
      [name]: value,
    },
    onboardingFieldErrors: {
      ...businessOnboardingSignal.value.onboardingFieldErrors,
      [name]: '',
    },
  });
};

export const handleOnboardingSubmit = async () => {
  const { name, ein, naicsCode } =
    businessOnboardingSignal.value.onboardingFields;

  const keys = Object.keys(businessOnboardingSignal.value.onboardingFields);
  keys.forEach((key) =>
    validateOnboardingInputs({
      name: key,
      value: businessOnboardingSignal.value.onboardingFields[key],
    })
  );

  if (
    !Object.values(businessOnboardingSignal.value.onboardingFieldErrors).some(
      (v) => v === true
    )
  ) {
    try {
      loaderSignal.update({ isContentLoading: true });
      await api.patch({
        path: '/businesses',
        body: {
          where: {
            id: userAccountSignal.value.userData.account.business.id,
          },
          data: {
            name,
            ein,
            naicsCode,
          },
        },
      });
      businessOnboardingSignal.update({ onboardingStep: 2 });
      alertSignal.update({
        variant: 'success',
        type: 'notification',
        message: 'Successfully updated business information.',
      });
    } catch (error) {
      alertSignal.update({
        type: 'notification',
        error,
        message: error.message,
      });
    } finally {
      loaderSignal.update({ isContentLoading: false });
    }
  }
};

export const validateOnboardingInputs = (e) => {
  const { name, value } = e;

  if (!value) {
    return businessOnboardingSignal.update({
      onboardingFieldErrors: {
        ...businessOnboardingSignal.value.onboardingFieldErrors,
        [name]: true,
      },
    });
  }

  if (name === 'ein' && !einRegex.test(value)) {
    return businessOnboardingSignal.update({
      onboardingFieldErrors: {
        ...businessOnboardingSignal.value.onboardingFieldErrors,
        [name]: true,
      },
    });
  }

  if (name === 'naicsCode' && !naicsCodeRegex.test(value)) {
    return businessOnboardingSignal.update({
      onboardingFieldErrors: {
        ...businessOnboardingSignal.value.onboardingFieldErrors,
        [name]: true,
      },
    });
  }
};
