import api from "api/api";
import alertSignal from "signals/Alert.signal";
import loaderSignal from "signals/Loader.signal";
import $capitalCallPortion from "./capitalCallPortion.signal";
import userAccountSignal from "signals/UserAccount.signal";
import $appSettings from "signals/AppSettings.signal";

export const CAPITAL_CALL_PORTION_MODAL = {
  confirm: 'confirm',
  missing: 'missing',
  notes: 'notes',
};

export const fetchAndSetCapitalCallPortion = async (id) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  try {
    loaderSignal.update({ isContentLoading: true });

    const capitalCallPortion = await api.get({
      path: '/capitalCallPortions',
      options: {
        where: {
          id: Number(id),
        },
        include: {
          vcPortfolioCompany: {
            include: {
              business: userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.business ? {
                include: {
                  capTable: true,
                },
              } : true,
            },
          },
          capitalCall: {
            include: {
              fund: {
                include: {
                  fundingProgram: true,
                  fundingProgramMembership: {
                    include: {
                      ventureCapitalFirm: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    });

    const { historicTransactionData } = capitalCallPortion;
    const mostRecentHistoricTransactionData = !!historicTransactionData.length ? historicTransactionData[historicTransactionData.length - 1] : null;

    $capitalCallPortion.update({
      capitalCallPortion,
      equityInvestmentGains: mostRecentHistoricTransactionData ? mostRecentHistoricTransactionData.equityInvestmentGains : 0,
      ssbciFundsLost: mostRecentHistoricTransactionData ? mostRecentHistoricTransactionData.ssbciFundsLost : 0,
      subsequentPrivateFinancing: mostRecentHistoricTransactionData ? mostRecentHistoricTransactionData.subsequentPrivateFinancing : 0,
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleConfirmFundsClick = async () => {
  const { capitalCallPortion } = $capitalCallPortion.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/capitalCallPortions',
      body: {
        where: {
          id: capitalCallPortion.id,
        },
        action: 'confirmFundsReceived',
        data: {
          note: $capitalCallPortion.value.note,
        },
      },
    });

    await fetchAndSetCapitalCallPortion(capitalCallPortion.id);

    $capitalCallPortion.update({ modalVisible: null });
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully confirmed that funds have been received',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleMissingFundsClick = async () => {
  const { capitalCallPortion } = $capitalCallPortion.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/capitalCallPortions',
      body: {
        where: {
          id: capitalCallPortion.id,
        },
        action: 'nudgeAboutMissingFunds',
        data: {
          note: $capitalCallPortion.value.note,
        },
      },
    });

    await fetchAndSetCapitalCallPortion(capitalCallPortion.id);

    $capitalCallPortion.update({ modalVisible: null });

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully claimed funds are missing',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleInvestmentPerformanceChange = e => {
  const { name, value } = e;

  $capitalCallPortion.update({ [name]: value });
};

export const handleSubmitInvestmentPerformance = async () => {
  const { equityInvestmentGains, ssbciFundsLost, subsequentPrivateFinancing, capitalCallPortion } = $capitalCallPortion.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/capitalCallPortions',
      body: {
        action: 'updateInvestmentPerformance',
        where: {
          id: capitalCallPortion.id,
        },
        data: {
          historicTransactionData: {
            equityInvestmentGains,
            ssbciFundsLost,
            subsequentPrivateFinancing,
          },
        },
      },
    });

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully saved Investment Performance.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleModalClose = () => $capitalCallPortion.update({ modalVisible: null, note: '' });
