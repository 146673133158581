import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import DashboardButton from 'components/global/DashboardButton';
import {stateDashboardButtons} from 'components/global/Constant/componentProps';

const Home = () => (
  <Container fluid>
    <h4 className="my-32">What would you like to work on?</h4>
    <Row>
      {stateDashboardButtons.map(btn => (
        <Col key={btn.title}>
          <Link to={`${btn.route}`} className="text-decoration-none text-secondary">
            <DashboardButton
              title={btn.title}
              description={btn.description}
              faIcon={btn.img}
            />
          </Link>
        </Col>
      ))}
    </Row>
  </Container>
);

export default Home;
