import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import billingSignal from '../Billing.signal';
import BillingLinkBankAccountToFundingProgram from './BillingLinkBankAccountToFundingProgram';
import { handleBackToLinkedProgramsClick } from '../Billing.helpers';

const LinkedProgramDetailsModal = ({
  selectedBankAccount,
  linkedPrograms,
  linkedProgramsDetailModalState,
  onHide,
  editable
}) => {

  if (!selectedBankAccount) {
    return null;
  }

  return (
    <ModalWrapper size="lg" show={linkedProgramsDetailModalState} onHide={onHide} centered>
      <Modal.Header>
        <h5>{selectedBankAccount.bankAccountName}</h5>
        <div>
          {linkedProgramsDetailModalState === 'VIEW' ? (
            <Button
              hidden={!editable}
              className="mr-16 rounded"
              onClick={() =>
                billingSignal.update({ linkedProgramsDetailModalState: 'EDIT' })
              }
            >
              Link Programs
            </Button>
          ) : (
            <Button
              hidden={!editable}
              className="mr-16 rounded"
              variant="outline-secondary"
              onClick={handleBackToLinkedProgramsClick}
            >
              Back to Linked Programs
            </Button>
          )}
          <CloseModalButton onCloseModal={onHide} />
        </div>
      </Modal.Header>
      {linkedProgramsDetailModalState === 'VIEW' ? (
        <Modal.Body>
          {linkedPrograms.length ? (
            <Table>
              <thead>
                <tr>
                  <th>Linked Program</th>
                  <th>Linked Platform</th>
                </tr>
              </thead>
              <tbody>
                {linkedPrograms.map((program) => (
                  <tr key={program.id}>
                    <td>{program.name}</td>
                    <td>{program.platform.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="warning">
              This bank account does not have any linked programs.
            </Alert>
          )}
        </Modal.Body>
      ) : (
        <BillingLinkBankAccountToFundingProgram />
      )}
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={onHide}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default LinkedProgramDetailsModal;
