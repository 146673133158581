import Signal from 'libs/signals';

const userRequestsSignal = Signal({
  userRequests: [],
  lenderUserRequests: [],
  vcUserRequests: [],
  businessUserRequests: [],
  selectedUserRequest: null,
  action: '', // approve || deny
  enableApplyButton: false,
  dropdownFilters: {},
  appliedFilters: {},
});

export default userRequestsSignal;
