import { useCallback, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { dashboardSignal } from 'signals/DashboardSignal';
import {
  fetchAndSetPlatforms,
  getPlatformSelections,
  handleSelectionChange,
  updateProgramSelectionOptions,
} from '../Dashboard.helpers';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';

const DashboardChooseProgram = () => {
  const { fundingPrograms, selectedFundingProgramId, selectedPlatformId } =
    dashboardSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const init = useCallback(async () => {
    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo
    ) {
      await fetchAndSetPlatforms();
    }
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    updateProgramSelectionOptions();
  }, [selectedPlatformId]);

  return (
    <Container className="p-16 bg-light">
      <Row>
        <Col lg={4}>
          <Form.Group
            className="form-group"
            hidden={
              userAccountSignal.value.userData.account.portalType !==
              PORTAL_TYPES.edo
            }
          >
            <Form.Label className="font-weight-bold text-secondary">
              Select a Platform
            </Form.Label>
            <Form.Select
              className="form-control"
              name="selectedPlatformId"
              value={selectedPlatformId}
              onChange={handleSelectionChange}
            >
              <option value="">All</option>
              {getPlatformSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Select a Program
            </Form.Label>
            <Form.Select
              className="form-control"
              name="selectedFundingProgramId"
              value={selectedFundingProgramId}
              onChange={handleSelectionChange}
            >
              <option value="">All</option>
              {fundingPrograms.map((fundingProgram) => (
                <option key={fundingProgram.id} value={fundingProgram.id}>
                  {fundingProgram.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardChooseProgram;
