/* eslint-disable no-unused-vars */
import api from "api/api";
import { applicationsChartPresets } from "components/global/ChartJS/chartPresetsAndStyles";
import { PORTFOLIO_COMPANY_STATUS } from "components/global/Constant/constants";
import alertSignal from "signals/Alert.signal";
import $appSettings from "signals/AppSettings.signal";
import loaderSignal from "signals/Loader.signal";
import userAccountSignal from "signals/UserAccount.signal";
import vcDashboardSignal from "signals/VCDashboard.signal";
import capitalizeFirst from "utils/capitalizeFirst";
import { getIndustryType } from "utils/getIndustryType";

export const fetchAndSetDashboardData = async () => {
  loaderSignal.update({ isContentLoading: true });
  const { selectedFundingProgramId } = vcDashboardSignal.value;

  const where = {
    ventureCapitalFirmId:
      userAccountSignal.value.userData.account.ventureCapitalFirm.id,
  };

  if (selectedFundingProgramId) {
    where.fundingProgramId = selectedFundingProgramId;
  }

  try {
    const [
      fundingProgramMemberships,
      programRequests,
      funds,
      portfolioCompanies,
    ] = await Promise.all([
      api.get({
        path: '/fundingProgramMemberships',
        options: {
          where: {
            ventureCapitalFirmId:
              userAccountSignal.value.userData.account.ventureCapitalFirm.id,
          },
          include: {
            fundingProgram: true,
          },
        },
      }),
      api.get({
        path: '/programRequests',
        options: {
          where,
          include: {
            fundingProgram: true,
            ventureCapitalFirm: true,
          },
        },
      }),
      api.get({
        path: '/funds',
        options: {
          where: {
            fundingProgramMembership: where,
          },
          include: {
            vcPortfolioCompanies: true,
            capitalCalls: true,
          },
        },
      }),
      api.get({
        path: '/vcPortfolioCompanies',
        options: {
          where: {
            fund: {
              fundingProgramMembership: where,
            },
          },
          include: {
            business: true,
            fund: {
              include: {
                fundingProgramMembership: {
                  include: {
                    ventureCapitalFirm: true,
                  },
                },
                capitalCalls: {
                  include: {
                    capitalCallPortions: true,
                  },
                },
              },
            },
          },
        },
      }),
    ]);

    const fundingPrograms = fundingProgramMemberships.map(
      (fpm) => fpm.fundingProgram
    );

    vcDashboardSignal.update({
      fundingPrograms,
      programRequests,
      funds,
      portfolioCompanies,
    });
  } catch (e) {
    alertSignal.update({
      message: e.message,
      //'Failed to retrieve dashboard data. Please try again',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSelectionChange = (e) => {
  const { value } = e.target;
  vcDashboardSignal.update({
    selectedFundingProgramId: Number(value),
  });
};

export const getProgramRequestMetrics = () => {
  const { APPLICATION_STATUS, PROGRAM_REQUEST_STATUS } =
    $appSettings.value.constants;
  const { programRequests } = vcDashboardSignal.value;
  const programRequestsMetrics = applicationsChartPresets;

  const numApprovedRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.approved
  ).length;
  const numPendingRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.pending
  ).length;
  const numIncompleteRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.incomplete
  ).length;
  const numDeniedRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.denied
  ).length;
  const numSubmittedForApprovalRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.submittedForApproval
  ).length;

  const newProgramRequestMetrics = {
    ...programRequestsMetrics,
    labels: Object.values(PROGRAM_REQUEST_STATUS).map((status) =>
      capitalizeFirst(status).replace(/_/g, ' ')
    ),
    datasets: programRequestsMetrics.datasets.map((dataset) => ({
      ...dataset,
      data: [
        numApprovedRequests,
        numPendingRequests,
        numIncompleteRequests,
        numDeniedRequests,
        numSubmittedForApprovalRequests,
      ],
    })),
  };

  vcDashboardSignal.update({ programRequestMetrics: newProgramRequestMetrics });
};

export const getFundMetrics = () => {
  const { funds } = vcDashboardSignal.value;
  const labelOrder = Object.keys(PORTFOLIO_COMPANY_STATUS);

  const tally = funds.reduce((accumulator, currentValue) => {
    const { capitalCalls } = currentValue;
    if (!capitalCalls.length) {
      // unfunded if no capital calls
      accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] =
        (accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] || 0) + 1;
    } else {
      const isFunded = capitalCalls.every(
        (cc) => new Date(cc.suggestedDate) <= new Date()
      );
      if (isFunded) {
        // funded if all capital calls are in past
        accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] || 0) + 1;
      } else {
        // committed if some capital calls have not been fulfilled
        accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] || 0) + 1;
      }
    }
    return accumulator;
  }, {});

  const orderedTally = labelOrder.map((label) => tally[label] || 0);

  const fundMetrics = {
    ...applicationsChartPresets,
    labels: Object.keys(PORTFOLIO_COMPANY_STATUS).map((status) =>
      capitalizeFirst(status)
    ),
    datasets: applicationsChartPresets.datasets.map((dataset) => ({
      ...dataset,
      data: orderedTally,
    })),
  };

  vcDashboardSignal.update({ fundMetrics });
};

export const getVcPortfolioCompanyMetrics = () => {
  const { portfolioCompanies } = vcDashboardSignal.value;
  const labelOrder = Object.keys(PORTFOLIO_COMPANY_STATUS);

  const tally = portfolioCompanies.reduce((accumulator, currentValue) => {
    const { fund } = currentValue;
    const capitalCalls = fund.capitalCalls;
    let capitalCallPortions = capitalCalls.length
      ? capitalCalls[0].capitalCallPortions
      : [];
    if (!capitalCallPortions.length) {
      // unfunded if no capital call portions
      accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] =
        (accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] || 0) + 1;
    } else {
      const isFunded = capitalCalls.every(
        (cc) => new Date(cc.suggestedDate) <= new Date()
      );
      if (isFunded) {
        // funded if all capital calls are in past
        accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] || 0) + 1;
      } else {
        // committed if some capital calls have not been fulfilled
        accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] || 0) + 1;
      }
    }
    return accumulator;
  }, {});

  const orderedTally = labelOrder.map((label) => tally[label] || 0);

  const vcPortfolioCompanyMetrics = {
    ...applicationsChartPresets,
    labels: Object.keys(PORTFOLIO_COMPANY_STATUS).map((status) =>
      capitalizeFirst(status)
    ),
    datasets: applicationsChartPresets.datasets.map((dataset) => ({
      ...dataset,
      data: orderedTally,
    })),
  };

  vcDashboardSignal.update({ vcPortfolioCompanyMetrics });
};

export const getBusinessDemographicsMetrics = () => {
  const { portfolioCompanies } = vcDashboardSignal.value;
  const uniquePortfolioCompanies = portfolioCompanies.filter((pc, index, self) =>
    index === self.findIndex((o) => o.businessId === pc.businessId));

  const naicCodes = uniquePortfolioCompanies.map((pc) => pc.business.naicsCode);

  const industryValues = naicCodes.map((naicsCode) =>
    getIndustryType(naicsCode)
  );

  const uniqIndustryValues = [...new Set(industryValues)].sort((a, b) =>
    a.localeCompare(b)
  );

  const valueCount = uniqIndustryValues.map(
    (value) => industryValues.filter((v) => v === value).length
  );

  return vcDashboardSignal.update({
    businessDemographicsMetrics: {
      labels: uniqIndustryValues,
      datasets: [
        {
          data: valueCount,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    },
  });
};
