import { useRef } from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  id: string;
  name: string;
  text: string;
  handleAttachFile: (file: File, e: React.ChangeEvent<HTMLInputElement>) => any;
  hidden?: boolean;
};

const AttachButton: React.FC<Props> = ({
  text,
  name,
  id,
  handleAttachFile,
  hidden = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleResetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.type = 'file';
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        name={name}
        id={id}
        // @ts-ignore
        onChange={(e) => {
          handleAttachFile(e.target.files![0], e);
          handleResetFileInput();
        }}
      />
      <Button
        hidden={hidden}
        size="sm"
        className="rounded"
        variant="outline-primary"
        onClick={() => document.getElementById(id)?.click()}
      >
        {text}
      </Button>
    </>
  );
};

export default AttachButton;
