import dateOptions from "components/global/Constant/dateOptions";
import { Button } from "react-bootstrap";
import { lenderProgramRequestsFilterSignal, lenderProgramRequestsSignal } from "signals/LenderPrograms.signal";
import {
  fetchAndSetProgramRequests,
  handleProgramRequestsAppliedFiltersChange,
  handleProgramRequestsFilterChange,
  handleProgramRequestsSortChange,
  resetProgramRequestFilters
} from "../Programs.helpers";
import { showApplicationDetailsModal } from "../../ApplicationDetail/ApplicationDetail.helpers";
import { Link, useHistory, useLocation } from "react-router-dom";
import userAccountSignal from "../../../../signals/UserAccount.signal";
import SimpleTable from "components/global/SimpleTable";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { titleCaseString } from "libs/string";
import { txProgramRequestToApplicationDetailsModal } from "../../ProgramRequestDetail/ProgramRequestDetail.helpers";
import { getStatusStyles } from "../../../../libs/functions/global.functions";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'requestId',
    title: 'Request ID',
  },
  {
    key: 'createdDate',
    title: 'Date Submitted',
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'edoName',
    title: 'EDO Name',
  },
  {
    key: 'contactName',
    title: 'Contact Name',
  },
  {
    key: 'approvedAmount',
    title: 'Approved Amount',
    options: { isCurrency: true },
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const MyProgramRequestsTable = () => {
  const programRequests = lenderProgramRequestsSignal.value;
  const { bundle, sort, dropdownFilters } =
    lenderProgramRequestsFilterSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const history = useHistory();
  const location = useLocation();
  const portal = location.pathname.split('/')[1];
  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-16">
        <SimpleTableFilterDropdown
          filtersState={dropdownFilters}
          onChange={({ name, value }) =>
            handleProgramRequestsFilterChange(name, value)
          }
          onApply={(filters) => {
            handleProgramRequestsAppliedFiltersChange(filters);
            fetchAndSetProgramRequests();
          }}
          onClearFilterInputs={resetProgramRequestFilters}
          filters={[
            {
              type: 'select',
              label: 'Status',
              key: 'status',
              options: Object.entries(APPLICATION_STATUS).map(([, value]) => ({
                label: titleCaseString(value),
                value,
              })),
            },
            {
              type: 'integer',
              label: 'ID',
              key: 'id',
            },
            {
              type: 'select',
              label: 'Program Name',
              key: 'programName',
              options: bundle.fundingPrograms?.map((fundingProgram) => ({
                label: fundingProgram.name,
                value: fundingProgram.id,
              })),
            },
          ]}
        />
        <Link
          className="rounded btn btn-primary"
          to={`/${portal}/program-requests/all-programs`}
        >
          View Programs
        </Link>
      </div>
      <SimpleTable
        headers={HEADERS}
        sort={sort}
        onClick={(index) => {
          const programRequest = programRequests[index];
          showApplicationDetailsModal(
            txProgramRequestToApplicationDetailsModal(
              programRequest,
              !!programRequest.ventureCapitalFirm
            )
          );
        }}
        onSort={(sort) => {
          handleProgramRequestsSortChange(sort);
          fetchAndSetProgramRequests();
        }}
        rows={programRequests.map((programRequest) => ({
          status: () => (
            <td className={`${getStatusStyles(programRequest.status).class}`}>
              {programRequest.status.replace(/_/g, ' ')}
            </td>
          ),
          id: programRequest.id,
          createdDate: new Date(programRequest.createdDate).toLocaleString(
            'en-US',
            dateOptions.date
          ),
          programName: programRequest.fundingProgram.name,
          lenderName: programRequest.fundingProgram.edo.name,
          contactName: programRequest.fundingProgram.edo.contactName,
          approvedAmount: programRequest.approvedAmount,
          action: ({ key }) => (
            <td key={key} className="text-right">
              <Button
                size="sm"
                variant="outline-dark"
                className="rounded"
                onClick={() =>
                  history.push(
                    `/${userAccountSignal.value.userData.account.portalType.toLowerCase()}/program-requests/${
                      programRequest.id
                    }`
                  )
                }
              >
                Detail
              </Button>
            </td>
          ),
        }))}
      />
    </>
  );
};

export default MyProgramRequestsTable;
