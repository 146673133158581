import alertSignal from "signals/Alert.signal";
import businessSearchSignal, {
  businessSearchAssistanceTypesSignal,
  businessSearchServicesSignal
} from "signals/BusinessSearch.signal";
import { businessSettingsSubmittingSignal } from "signals/BusinessSettings.signal";
import { removeSpecialChars } from "utils/formatSearch";
import history from "../../../../history";
import loaderSignal from "signals/Loader.signal";

export const handleInputChange = (e) =>
  businessSearchSignal.update({
    query: {
      ...businessSearchSignal.value.query,
      [e.target.name]: e.target.value,
    },
  });

export const fetchAndSetBusinessSearchData = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    businessSearchAssistanceTypesSignal.update([]);
    businessSearchServicesSignal.update([]);
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: 'An error occurred while loading organizations.',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSelectChange = (e) => {
  const { name, value } = e.target;

  businessSearchSignal.update({
    query: {
      ...businessSearchSignal.value.query,
      [name]: value,
    },
  });

  // let newMultipleSelectArr = [];
  //
  // if (businessSearchSignal.value.query?.[name]) {
  //   newMultipleSelectArr = [...businessSearchSignal.value.query[name]];
  // }
  //
  // if (newMultipleSelectArr.includes(value)) {
  //   newMultipleSelectArr = newMultipleSelectArr.filter(
  //     (item) => item !== value
  //   );
  // } else {
  //   newMultipleSelectArr.push(value);
  // }
  //
  // businessSearchSignal.update({
  //   query: {
  //     ...businessSearchSignal.value.query,
  //     [name]: newMultipleSelectArr,
  //   },
  // });
};

function xFormSearchFormIntoQueryString(payload) {
  return Object.entries(payload).reduce((acc, [key, value]) => {
    if (!value || value.length === 0) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});
}

const isFormValid = () => {
  const min = Number(businessSearchSignal.value.query.seekingMin);
  const max = Number(businessSearchSignal.value.query.seekingMax);

  if ((min !== 0 || max !== 0) && min > max) return false;

  return true;
};

export const handleSearchFormSubmit = async () => {
  if (isFormValid()) {
    businessSearchSignal.update({
      query: {
        ...businessSearchSignal.value.query,
        location: removeSpecialChars(businessSearchSignal.value.query.location),
      },
    });
    const { query } = businessSearchSignal.value;
    const q = xFormSearchFormIntoQueryString(query);

    try {
      if (Object.keys(q).length === 0) {
        return history.push('/business/search/search-results');
      }
      return history.push(`/business/search/search-results?${new URLSearchParams(q)}`);
    } catch (error) {
      return alertSignal.update({
        type: 'alert',
        error,
        message: 'An error occurred while building search params.',
      });
    } finally {
      businessSettingsSubmittingSignal.update({ submitting: false });
    }
  } else {
    return alertSignal.update({
      type: 'alert',
      message: 'Seeking min value must be less than seeking max value',
    });
  }
};
