import { Col, Row } from 'react-bootstrap';
import AttachmentsWithTemplatesForm from 'components/global/AttachmentsWithTemplatesForm/AttachmentsWithTemplatesForm';
import { fundingProgramFormErrorsSignal } from 'signals/Program.signal';
import $appSettings from 'signals/AppSettings.signal';

export const EdoAddProgramDocumentForm = ({
  handleFileChange,
  handleFileRemoval,
  programInfo,
  creatingProgram,
  handleDocumentUploadInputFocusOut,
  handleAddSuggestedDocument,
  handleInlineSave,
}) => {
  const {
    programRequestTemplates,
    applicationTemplates,
    applicationSuggestedDocuments,
    programRequestSuggestedDocuments,
  } = programInfo;
  const { DOCUMENT_TYPES } = $appSettings.value.constants;

  return (
    <div className="text-secondary">
      <Row hidden={creatingProgram} className="px-16">
        <h5>Program Document Templates</h5>
      </Row>
      <Row hidden={!creatingProgram} className="px-16">
        <h5>Program Forms</h5>
      </Row>
      <Row className="px-16">
        <p>
          Add program forms that have to be filled-out by hand or
          electronically.
        </p>
      </Row>
      <Row>
        <Col>
          <AttachmentsWithTemplatesForm
            handleInlineSave={
              handleInlineSave
                ? (index, fileBundle) => handleInlineSave(fileBundle, index)
                : undefined
            }
            maxDocumentCount={1}
            handleFileRemoval={(index) =>
              handleFileRemoval(index, 'programRequestTemplates')
            }
            handleFilenameChange={(index, name) =>
              handleFileChange(
                index,
                { name },
                'programRequestTemplates',
                DOCUMENT_TYPES.programRequestTemplate
              )
            }
            handleAttachFile={(index, file) =>
              handleFileChange(
                index,
                { file },
                'programRequestTemplates',
                DOCUMENT_TYPES.programRequestTemplate
              )
            }
            handleAddEmptyFileSlot={() =>
              handleAddSuggestedDocument(
                'programRequestTemplates',
                DOCUMENT_TYPES.programRequestTemplate
              )
            }
            handleDocumentUploadInputFocusOut={(e, index) =>
              handleDocumentUploadInputFocusOut(
                e,
                index,
                'programRequestTemplates'
              )
            }
            error={(index) =>
              fundingProgramFormErrorsSignal.value[
                `${DOCUMENT_TYPES.programRequestTemplate}${index}`
              ]
            }
            title={'Program Request Template'}
            fileBundles={programRequestTemplates || []}
          />
        </Col>
      </Row>
      <Row className="mt-32">
        <Col>
          <AttachmentsWithTemplatesForm
            handleInlineSave={
              handleInlineSave
                ? (index, fileBundle) => handleInlineSave(fileBundle, index)
                : undefined
            }
            nameBuilder={(index) =>
              `${DOCUMENT_TYPES.programRequestSuggested}${index}`
            }
            handleAddEmptyFileSlot={() =>
              handleAddSuggestedDocument(
                'programRequestSuggestedDocuments',
                DOCUMENT_TYPES.programRequestSuggested
              )
            }
            handleFileRemoval={(index) =>
              handleFileRemoval(index, 'programRequestSuggestedDocuments')
            }
            handleFilenameChange={(index, name) =>
              handleFileChange(
                index,
                { name },
                'programRequestSuggestedDocuments',
                DOCUMENT_TYPES.programRequestSuggested
              )
            }
            handleAttachFile={(index, file) =>
              handleFileChange(
                index,
                { file },
                'programRequestSuggestedDocuments',
                DOCUMENT_TYPES.programRequestSuggested
              )
            }
            handleDocumentUploadInputFocusOut={(e, index) =>
              handleDocumentUploadInputFocusOut(
                e,
                index,
                'programRequestSuggestedDocuments'
              )
            }
            error={(index) =>
              fundingProgramFormErrorsSignal.value[
                `${DOCUMENT_TYPES.programRequestSuggested}${index}`
              ]
            }
            title={'Suggested Program Request Documents'}
            fileBundles={programRequestSuggestedDocuments || []}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <AttachmentsWithTemplatesForm
            handleInlineSave={
              handleInlineSave
                ? (index, fileBundle) => handleInlineSave(fileBundle, index)
                : undefined
            }
            maxDocumentCount={1}
            handleFileRemoval={(index) =>
              handleFileRemoval(index, 'applicationTemplates')
            }
            handleFilenameChange={(index, name) =>
              handleFileChange(
                index,
                { name },
                'applicationTemplates',
                DOCUMENT_TYPES.applicationTemplate
              )
            }
            handleAttachFile={(index, file) =>
              handleFileChange(
                index,
                { file },
                'applicationTemplates',
                DOCUMENT_TYPES.applicationTemplate
              )
            }
            handleDocumentUploadInputFocusOut={(e, index) =>
              handleDocumentUploadInputFocusOut(
                e,
                index,
                'applicationTemplates'
              )
            }
            handleAddEmptyFileSlot={() =>
              handleAddSuggestedDocument(
                'applicationTemplates',
                DOCUMENT_TYPES.applicationTemplate
              )
            }
            error={(index) =>
              fundingProgramFormErrorsSignal.value[
                `${DOCUMENT_TYPES.applicationTemplate}${index}`
              ]
            }
            title={'Application Template'}
            fileBundles={applicationTemplates || []}
          />
        </Col>
      </Row>
      <Row className="mt-32">
        <Col>
          <AttachmentsWithTemplatesForm
            handleInlineSave={
              handleInlineSave
                ? (index, fileBundle) => handleInlineSave(fileBundle, index)
                : undefined
            }
            nameBuilder={(index) =>
              `${DOCUMENT_TYPES.applicationSuggested}${index}`
            }
            handleAddEmptyFileSlot={() =>
              handleAddSuggestedDocument(
                'applicationSuggestedDocuments',
                DOCUMENT_TYPES.applicationSuggested
              )
            }
            handleFileRemoval={(index) =>
              handleFileRemoval(index, 'applicationSuggestedDocuments')
            }
            handleFilenameChange={(index, name) =>
              handleFileChange(
                index,
                { name },
                'applicationSuggestedDocuments',
                DOCUMENT_TYPES.applicationSuggested
              )
            }
            handleAttachFile={(index, file) =>
              handleFileChange(
                index,
                { file },
                'applicationSuggestedDocuments',
                DOCUMENT_TYPES.applicationSuggested
              )
            }
            handleDocumentUploadInputFocusOut={(e, index) =>
              handleDocumentUploadInputFocusOut(
                e,
                index,
                'applicationSuggestedDocuments'
              )
            }
            error={(index) =>
              fundingProgramFormErrorsSignal.value[
                `${DOCUMENT_TYPES.applicationSuggested}${index}`
              ]
            }
            title={'Suggested Application Documents'}
            fileBundles={applicationSuggestedDocuments || []}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EdoAddProgramDocumentForm;
