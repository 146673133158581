import Signal from "libs/signals";

export const $edoCustomize = Signal({
  activeWhiteLabelConfig: {
    headerBackgroundColor: null,
    bodyBackgroundColor: null,
    logoBase64String: null,
    tagline: null,
    taglineColor: null,
  },
  newWhiteLabelConfig: {
    headerBackgroundColor: null,
    bodyBackgroundColor: null,
    logoBase64String: null,
    tagline: null,
    taglineColor: null,
  },
  changesPreviewed: true,
});