import {
  lenderPortfolioActionModalStateSignal,
  lenderPortfolioFilterSignal,
  lenderPortfolioIncompleteApplicationDocumentCheckSignal,
  lenderPortfolioIncompleteApplicationDocumentUploadErrorSignal,
  lenderPortfolioIncompleteApplicationsUploadSignal,
  lenderPortfolioSelectedApplicationSignal,
  lenderPortfolioSignal,
  lenderProgramsIncompleteApplicationSelectedFileSignal,
  lenderProgramsIncompleteApplicationsNoteSignal,
} from 'signals/LenderPortfolio.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import history from '../../../history';
import $appSettings from 'signals/AppSettings.signal';

export const STATUS_OPTIONS = [
  'COMPLETED APPLICATION',
  'PENDING',
  'APPROVED',
  'AWARDED',
];

export const MODAL_STATE_VIEWS = {
  approvedOrPending: 'APPROVED OR PENDING',
  incomplete: 'INCOMPLETE',
  denied: 'DENIED',
  previewDocument: 'PREVIEW DOCUMENT',
};

export const fetchAndSetApplications = async (page) => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  const { recordsPerPage } = lenderPortfolioSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });
    const bundle = await api.get({
      path: '/tally/applications',
    });
    lenderPortfolioSignal.update({ bundle });
    const data = await api.get({
      path: '/applications',
      options: {
        getCount: true,
        take: recordsPerPage,
        skip: page ? (page - 1) * recordsPerPage : 0,
        orderBy: xFormSortToOrderByClause(),
        where: xFormAppliedFiltersToWhereClause(),
        include: {
          business: {
            include: {
              account: true,
            },
          },
          watchables: true,
          fundingProgram: {
            include: {
              bankAccountProgramAccesses: {
                include: {
                  bankAccount: true,
                },
              },
              supportingDocuments: true,
              referenceProgram: {
                include: {
                  programType: true,
                },
              },
              platform: true,
              approvalChainTemplates: {
                include: {
                  steps: {
                    include: {
                      primaryApprover: true,
                      alternateApprovers: {
                        include: {
                          user: true,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          fundingProgramMembership: {
            include: {
              lender: true,
              ventureCapitalFirm: true,
              fundingProgram: {
                include: {
                  bankAccountProgramAccesses: {
                    include: {
                      bankAccount: true,
                    },
                  },
                  supportingDocuments: true,
                  referenceProgram: {
                    include: {
                      programType: true,
                    },
                  },
                  platform: true,
                  approvalChainTemplates: {
                    include: {
                      steps: {
                        include: {
                          primaryApprover: true,
                          alternateApprovers: {
                            include: {
                              user: true,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          disbursements: {
            include: {
              reimbursements: {
                include: {
                  transactions: {
                    include: {
                      creator: true,
                    },
                  },
                },
              },
              originBankAccount: true,
              destinationBankAccount: true,
              transactions: {
                include: {
                  creator: true,
                },
              },
            },
          },
          lossClaim: true,
          loan: {
            include: {
              payments: true,
              fees: true,
            },
          },
        },
      },
    });

    return lenderPortfolioSignal.update({
      applications: data.applications,
      numSubmittedForApprovalLoans: data?.applications?.filter(
        (a) => a.status === APPLICATION_STATUS.submittedForApproval
      ).length,
      totalPages: data.count ? Math.ceil(data.count / recordsPerPage) : 1,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const togglePortfolioDetailsModal = () => {
  lenderPortfolioActionModalStateSignal.update({
    visible: !lenderPortfolioActionModalStateSignal.value.visible,
  });
};

export const handlePortfolioTableActionClick = (application) => {
  lenderPortfolioSelectedApplicationSignal.update(application);
  togglePortfolioDetailsModal();
};

export const getModalStateView = () => {
  const { status } = lenderPortfolioSelectedApplicationSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  switch (status) {
    case APPLICATION_STATUS.approved || APPLICATION_STATUS.pending:
      lenderPortfolioActionModalStateSignal.update({
        currentView: MODAL_STATE_VIEWS.approvedOrPending,
      });
      break;
    case APPLICATION_STATUS.incomplete:
      lenderPortfolioActionModalStateSignal.update({
        currentView: MODAL_STATE_VIEWS.incomplete,
      });
      break;
    case APPLICATION_STATUS.denied:
      lenderPortfolioActionModalStateSignal.update({
        currentView: MODAL_STATE_VIEWS.denied,
      });
      break;
    default:
      lenderPortfolioActionModalStateSignal.update({
        currentView: MODAL_STATE_VIEWS.approvedOrPending,
      });
  }
};

export const resetModalData = () => {
  lenderPortfolioActionModalStateSignal.reset();
  lenderPortfolioSelectedApplicationSignal.reset();
  lenderPortfolioIncompleteApplicationDocumentCheckSignal.reset();
  lenderPortfolioIncompleteApplicationDocumentUploadErrorSignal.reset();
  lenderPortfolioIncompleteApplicationsUploadSignal.update([]);
  lenderProgramsIncompleteApplicationSelectedFileSignal.reset();
  lenderProgramsIncompleteApplicationsNoteSignal.reset();
};

export const handleLenderPortfolioFilterChange = (newFilter) => {
  lenderPortfolioFilterSignal.update(newFilter);
};

export const handleUpload = (fileIn) => {
  const files = lenderPortfolioIncompleteApplicationsUploadSignal.value;
  const documentCheck =
    lenderPortfolioIncompleteApplicationDocumentCheckSignal.value;
  const newFile = {
    file: fileIn[0],
    documentCheck,
  };
  if (
    lenderPortfolioIncompleteApplicationDocumentCheckSignal.value.length > 0
  ) {
    files.push(newFile);
    lenderPortfolioIncompleteApplicationsUploadSignal.update([...files]);
    lenderPortfolioIncompleteApplicationDocumentCheckSignal.update('');
  } else {
    lenderPortfolioIncompleteApplicationDocumentUploadErrorSignal.update(true);
  }
};

export const handleFileCancel = (file) => {
  const files = lenderPortfolioIncompleteApplicationsUploadSignal.value;
  const newFiles = files.filter((f) => f.file.name !== file.file.name);
  lenderPortfolioIncompleteApplicationsUploadSignal.update(newFiles);
};

export const handleDocumentCheckChange = (e) => {
  const { value } = e.target;

  lenderPortfolioIncompleteApplicationDocumentCheckSignal.update(value);
  lenderPortfolioIncompleteApplicationDocumentUploadErrorSignal.update(false);
};

export const handleViewPreview = (file) => {
  lenderProgramsIncompleteApplicationSelectedFileSignal.update({
    file: file.file,
  });
  lenderPortfolioActionModalStateSignal.update({
    currentView: MODAL_STATE_VIEWS.previewDocument,
  });
};

export const handleUploadDocumentNoteChange = (e) => {
  const { value } = e.target;

  return lenderProgramsIncompleteApplicationsNoteSignal.update(value);
};

export const handleSubmitApplicationDocuments = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting documents...',
  });
  try {
    const files = lenderPortfolioIncompleteApplicationsUploadSignal.value;
    const formData = new FormData();
    const selectedApplication = lenderPortfolioSelectedApplicationSignal.value;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append(`documents[${i}]`, file.file, file.file.name);
      formData.append(`documentsTypes[${i}]`, file.documentCheck);
      formData.append('applicationId', selectedApplication.id);
    }
    await api.post({ path: '/applicationDocuments', body: formData });
    await refreshPortfolioData();
    // reset is not working?
    lenderPortfolioIncompleteApplicationsUploadSignal.update([]);
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully submitted documents.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const sendApplicationBackToEdoForReview = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Sending back to EDO...',
  });
  try {
    const application = lenderPortfolioSelectedApplicationSignal.value;
    const body = {
      where: {
        id: application.id,
      },
      data: {
        action: 'sendBack',
      },
    };
    await api.patch({ path: '/applications', body });
    await refreshPortfolioData();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully deleted account',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSendNote = async () => {
  loaderSignal.update({ isContentLoading: true, message: 'Sending note...' });
  try {
    const content = lenderProgramsIncompleteApplicationsNoteSignal.value;
    const applicationId = lenderPortfolioSelectedApplicationSignal.value.id;
    await api.post({
      path: `/applications/${applicationId}/notes`,
      body: { content },
    });
    await refreshPortfolioData();
    lenderProgramsIncompleteApplicationsNoteSignal.update('');
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully deleted account',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const refreshPortfolioData = async () => {
  await fetchAndSetApplications();
  const applicationId = lenderPortfolioSelectedApplicationSignal.value.id;
  const { applications } = lenderPortfolioSignal.value;

  return lenderPortfolioSelectedApplicationSignal.update(
    applications.find((app) => app.id === applicationId)
  );
};

export const handleToggleWatchlist = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating watchlist...',
  });
  const body = {
    where: {
      id: lenderPortfolioSelectedApplicationSignal.value.id,
    },
    data: {
      watched: !lenderPortfolioSelectedApplicationSignal.value.watched,
    },
  };
  try {
    await api.patch({ path: '/applications', body });
    return await refreshPortfolioData();
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleApplicationsFilterChange = (key, value) => {
  const dropdownFilters = lenderPortfolioSignal.value.dropdownFilters;
  return lenderPortfolioSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

export const handleApplicationsAppliedFiltersChange = (appliedFilters) =>
  lenderPortfolioSignal.update({
    appliedFilters,
  });

const xFormSortToOrderByClause = () => {
  const sort = Object.fromEntries(
    new URLSearchParams(history.location.search)
  ).sort;

  if (!sort) {
    return undefined;
  }

  const key = sort.split('-')[0];
  const dir = sort.split('-')[1];

  if (key === 'programName') {
    return [
      {
        fundingProgramMembership: {
          fundingProgram: {
            name: dir,
          },
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    isActive: true,
    fundingProgramMembership: {
      is: {
        lenderId: userAccountSignal.value.userData.account.lender.id,
      },
    },
  };

  let params = Object.fromEntries(new URLSearchParams(history.location.search));

  params = { ...params, ...lenderPortfolioSignal.value.appliedFilters };

  if (params.programName) {
    where.fundingProgramMembership.is.fundingProgramId = Number(
      params.programName
    );
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.status) {
    where.status = params.status;
  }

  if (params.watchables) {
    where.watchables = {
      some: {},
    };
  }

  return where;
};

export const resetPortfolioFilterInputs = () =>
  lenderPortfolioSignal.update({ dropdownFilters: {} });
