import api from 'api/api';
import userAccountSignal from 'signals/UserAccount.signal';
import loaderSignal from 'signals/Loader.signal';
import fundingProgramDetailSignal from './FundingProgramDetail.signal';
import {
  searchProgramsAdditionalFilesToUploadSignal,
  searchProgramsResultsFilesErrorSignal,
  searchProgramsResultsFilesSignal,
  searchProgramsResultsModalStateSignal,
  searchProgramsResultsRequestedAmountSignal,
  searchProgramsResultsSelectedProgram,
  searchProgramsResultsSignal,
  searchProgramsSuggestedFilesToUploadSignal,
} from '../SearchProgramResults/SearchProgramsResults.signal';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import alertSignal from 'signals/Alert.signal';
import $appSettings from 'signals/AppSettings.signal';

export const fetchFundingProgram = async (fundingProgramId) =>
  api.get({
    path: '/fundingPrograms',
    options: {
      where: {
        id: fundingProgramId,
      },
      include: { stats: true },
    },
  });

export const fetchAndSetProgramDetail = async (fundingProgramId) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  loaderSignal.update({
    isContentLoading: true,
  });

  try {
    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.business
    ) {
      const [fundingProgram] = await Promise.all([
        fetchFundingProgram(fundingProgramId),
      ]);

      return fundingProgramDetailSignal.update({
        fundingProgram,
      });
    }

    const [fundingProgram, programRequests, fundingProgramMemberships] =
      await Promise.all([
        fetchFundingProgram(fundingProgramId),
        api.get({
          path: '/programRequests',
          options: {
            where: {
              lenderId: userAccountSignal.value.userData.account.lender?.id,
              ventureCapitalFirmId:
                userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
            },
            include: {
              fundingProgram: true,
              ventureCapitalFirm: true,
              lender: true,
            },
          },
        }),
        api.get({
          path: '/fundingProgramMemberships',
          options: {
            where: {
              lenderId: userAccountSignal.value.userData.account.lender?.id,
              ventureCapitalFirmId:
                userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
            },
          },
        }),
      ]);

    return fundingProgramDetailSignal.update({
      fundingProgram,
      programRequests,
      fundingProgramMemberships,
    });
  } catch (error) {
  } finally {
    loaderSignal.reset();
  }
};

export const submitRequestApproval = async () => {
  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Submitting...',
    });
    const filesMap = searchProgramsSuggestedFilesToUploadSignal.value;
    const additionalFilesList =
      searchProgramsAdditionalFilesToUploadSignal.value;
    const fundingProgramId = searchProgramsResultsSelectedProgram.value.id;
    const { requestedAmount } =
      searchProgramsResultsRequestedAmountSignal.value;

    const filesAsEntries = Object.entries(filesMap);
    const filesSignedUrls = await uploadFilesToStorage(
      filesAsEntries.map((entry) => entry[1]),
      'programRequestDocument'
    );

    const additionalFiles = additionalFilesList.map((bundle) => bundle.file);
    const additionalFilesListSignedUrls = await uploadFilesToStorage(
      additionalFiles,
      'programRequestDocument'
    );

    const body = {
      data: {
        allocationAsk: Number(requestedAmount || 0),
        fundingProgram: {
          connect: {
            id: fundingProgramId,
          },
        },
        documents: {
          create: filesAsEntries
            .map(([key, file], index) => ({
              fundingProgramDocumentId: Number(key),
              filePath: filesSignedUrls[index],
              fileType: file.type,
            }))
            .concat(
              additionalFiles.map((file, index) => ({
                name: additionalFiles[index].name,
                filePath: additionalFilesListSignedUrls[index],
                fileType: file.type,
              }))
            ),
        },
      },
    };

    const response = await api.post({ path: '/programRequests', body });
    alertSignal.update({
      type: 'notification',
      message: 'Your program request has been submitted.',
      variant: 'success',
    });
    searchProgramsResultsModalStateSignal.update({ show: false });
    await fetchAndSetProgramDetail(fundingProgramId);
    return response;
  } catch (error) {
    searchProgramsResultsFilesErrorSignal.update(true);
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.update({
      isContentLoading: false,
    });
  }
};

export const handleDownloadDocument = async (id) => {
  const downloadUrl = await api.get({
    path: `/uploadPreviews?id=${id}&model=fundingProgramDocuments`,
  });
  window.open(downloadUrl, '_blank');
  return true;
};

export const handleAttachSuggestedFile = (name, file) => {
  searchProgramsSuggestedFilesToUploadSignal.update({ [name]: file });
};

export const handleCancelSuggestedFile = (fileBundle) => {
  const copiedValue = searchProgramsSuggestedFilesToUploadSignal.value;
  delete copiedValue[fileBundle.id];
  return searchProgramsSuggestedFilesToUploadSignal.update(copiedValue);
};

export const handleAttachAdditionalFile = (file) => {
  const { value } = searchProgramsAdditionalFilesToUploadSignal;
  searchProgramsAdditionalFilesToUploadSignal.update(
    value.concat({ name: file.name, file })
  );
};

export const handleChangeAdditionalFileName = (newName, index) => {
  const { value } = searchProgramsAdditionalFilesToUploadSignal;

  return searchProgramsAdditionalFilesToUploadSignal.update(
    value.map((fileBundle, i) => {
      if (index === i) {
        return { ...fileBundle, name: newName };
      }

      return fileBundle;
    })
  );
};

export const handleCancelAdditionalFile = (index) => {
  const { value } = searchProgramsAdditionalFilesToUploadSignal;
  return searchProgramsAdditionalFilesToUploadSignal.update(
    value.filter((_, i) => i !== index)
  );
};

export const resetProgramInfoModal = () => {
  searchProgramsResultsFilesSignal.reset();
  searchProgramsResultsModalStateSignal.reset();
  searchProgramsResultsSelectedProgram.reset();
};

export const handleRequestedAmountChange = (e) => {
  const { name, value } = e;

  searchProgramsResultsRequestedAmountSignal.update({
    [name]: value,
    requestedAmountError: '',
  });
};

export const isRequestedAmountValid = () => {
  const { fundingProgram } = fundingProgramDetailSignal.value;
  const { requestedAmount } = searchProgramsResultsRequestedAmountSignal.value;

  if (requestedAmount <= 0) {
    return searchProgramsResultsRequestedAmountSignal.update({
      requestedAmountError: 'Please enter an amount greater than 0.',
    });
  }

  if (requestedAmount > fundingProgram.stats.remaining) {
    return searchProgramsResultsRequestedAmountSignal.update({
      requestedAmountError: `The requested amount cannot exceed the program balance of ${fundingProgram.stats.remaining}.`,
    });
  }

  return searchProgramsResultsRequestedAmountSignal.update({
    requestedAmountError: '',
  });
};

export const setSelectedProgram = (programId) => {
  const selectedProgram = searchProgramsResultsSignal.value.find(
    (program) => program.id === programId
  );

  searchProgramsResultsSelectedProgram.update(selectedProgram);
};

export const toggleProgramInfoModal = () => {
  const modalState = searchProgramsResultsModalStateSignal.value;
  searchProgramsResultsModalStateSignal.update({ show: !modalState.show });
  searchProgramsResultsRequestedAmountSignal.reset();
};
