import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import history from '../../../../history';
import userAccountSignal from 'signals/UserAccount.signal';
import fundsPortfolioSignal from 'components/global/FundsPortfolio/fundsPortfolio.signal';

export const fetchAndSetFunds = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const [funds, bundle] = await Promise.all([
      api.get({
        path: '/funds',
        options: {
          orderBy: xFormSortToOrderByClause(),
          where: xFormAppliedFiltersToWhereClause(),
          include: {
            capitalCalls: {
              include: {
                capitalCallPortions: true,
              },
            },
            fundingProgramMembership: {
              include: {
                ventureCapitalFirm: true,
                fundingProgram: true,
              },
            },
          },
        },
      }),
      api.get({
        path: '/tally/funds',
      }),
    ]);

    fundsPortfolioSignal.update({ funds, bundle });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleEdoFundsFilterChange = (key, value) => {
  const dropdownFilters = fundsPortfolioSignal.value.dropdownFilters;
  return fundsPortfolioSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

export const handleEdoFundsAppliedFiltersChange = (appliedFilters) =>
  fundsPortfolioSignal.update({
    appliedFilters: {
      ...appliedFilters,
    },
  });

export const resetEdoFundsFilterInputs = () =>
  fundsPortfolioSignal.update({ dropdownFilters: {} });

const xFormSortToOrderByClause = () => {
  const sort = Object.fromEntries(
    new URLSearchParams(history.location.search)
  ).sort;

  if (!sort) {
    return undefined;
  }

  const key = sort.split('-')[0];
  const dir = sort.split('-')[1];

  if (key === 'vc') {
    return [
      {
        fundingProgramMembership: {
          ventureCapitalFirm: {
            name: dir,
          },
        },
      },
    ];
  }

  if (key === 'programName') {
    return [
      {
        fundingProgramMembership: {
          fundingProgram: {
            name: dir,
          },
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    fundingProgramMembership: {
      fundingProgram: {
        is: {
          edoId: userAccountSignal.value.userData.account.edo.id,
        },
      },
    },
  };

  let params = Object.fromEntries(new URLSearchParams(history.location.search));

  params = { ...params, ...fundsPortfolioSignal.value.appliedFilters };

  if (params.programName) {
    where.fundingProgramMembership.fundingProgram.is.id = Number(
      params.programName
    );
  }

  // if (params.status) {
  //   where.status = params.status;
  // }

  if (params.vc) {
    where.fundingProgramMembership = {
      ...where.fundingProgramMembership,
      ventureCapitalFirm: {
        name: {
          contains: params.vc,
          mode: 'insensitive',
        },
      },
    };
  }

  return where;
};
