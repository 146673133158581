import loaderSignal from 'signals/Loader.signal';
import fundDetailsSignal from './fundDetails.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import userAccountSignal from 'signals/UserAccount.signal';
import addPortfolioCompanySignal from '../AddPortfolioCompany/addPortfolioCompany.signal';
import $appSettings from 'signals/AppSettings.signal';

export const FUND_DETAIL_MODALS = {
  createCapitalCall: 'CREATE_CAPITAL_CALL',
  capitalCallRoundDetails: 'CAPITAL_CALL_ROUND_DETAILS',
  requestAccess: 'REQUEST_ACCESS',
};

export const fetchAndSetFund = async (id) => {
  const { APPLICATION_STATUS, PORTAL_TYPES, FUND_DOCUMENT_TYPES } =
    $appSettings.value.constants;

  try {
    loaderSignal.update({ isContentLoading: true });
    let fund = {};

    const [funds, bankAccounts] = await Promise.all([
      api.get({
        path: '/funds',
        options: {
          where: {
            id: Number(id),
          },
          include: {
            documents: true,
            capitalCalls: {
              include: {
                bankAccount:
                  userAccountSignal.value.userData.account.portalType ===
                  PORTAL_TYPES.vc,
                documents: {
                  orderBy: {
                    id: 'desc',
                  },
                },
                approvalChainMemberships: {
                  include: {
                    user: true,
                  },
                },
                capitalCallPortions: {
                  include: {
                    vcPortfolioCompany: {
                      include: {
                        business: true,
                      },
                    },
                  },
                },
              },
            },
            vcPortfolioCompanies: {
              include: {
                fundApplication: {
                  include: {
                    documents: true,
                  },
                },
                business: true,
              },
            },
            fundingProgramMembership: {
              include: {
                ventureCapitalFirm: true,
                fundingProgram: {
                  include: {
                    approvalChainTemplates: {
                      include: {
                        steps: {
                          include: {
                            primaryApprover: true,
                            alternateApprovers: {
                              include: {
                                user: true,
                              },
                            },
                          },
                        },
                      },
                    },
                    referenceProgram: {
                      include: {
                        programType: true,
                      },
                    },
                  },
                },
              },
            },
            fundRequests: {
              include: {
                business: true,
              },
            },
          },
        },
      }),
      userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.vc
        ? api.get({
          path: '/bankAccounts',
          options: {
            where: {
              accountId: userAccountSignal.value.userData.account.id,
            },
          },
        })
        : [],
    ]);

    if (funds.length) {
      fund = funds[0];
    }

    fundDetailsSignal.update({
      approvalChainTemplates: fund
        ? [
          ...fund.fundingProgramMembership.fundingProgram.approvalChainTemplates.sort(
            (a, b) => a.id - b.id
          ),
        ]
        : [],
      portfolioCompanies: fund?.vcPortfolioCompanies,
      capitalCalls: !!fund?.capitalCalls?.length ? fund.capitalCalls : [],
      fundingProgram: fund?.fundingProgramMembership?.fundingProgram,
      name: fund?.fundName,
      maxFundSize: fund?.maxFundSize,
      id: fund?.id,
      newCapitalCallPortions: fund.vcPortfolioCompanies?.map((vcpc) => ({
        vcPortfolioCompanyId: vcpc.id,
        amount: 0,
      })),
      ventureCapitalFirm: fund?.fundingProgramMembership?.ventureCapitalFirm,
      documents: fund?.documents,
      fundRequests: fund?.fundRequests.filter(
        (fr) => fr.status !== APPLICATION_STATUS.approved
      ),
      makeCapitalCallDocuments: fund?.documents.filter(
        (doc) => doc.documentType === FUND_DOCUMENT_TYPES.capitalCall
      ),
      bankAccounts,
    });

    addPortfolioCompanySignal.update({
      funds: [fund],
      newVcPortfolioCompanyFundId: fund.id,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleCreateCapitalCallModalClose = () => {
  const { portfolioCompanies } = fundDetailsSignal.value;
  fundDetailsSignal.update({
    newCapitalCallPortions: portfolioCompanies?.map((vcpc) => ({
      vcPortfolioCompanyId: vcpc.id,
      amount: 0,
    })),
    suggestedDate: new Date(),
    modalVisible: null,
    selectedBankAccountId: null,
  });
};

export const handleCapitalCallRoundDetailsClick = (
  selectedCapitalCall,
  index
) => {
  fundDetailsSignal.update({
    selectedCapitalCall: {
      ...selectedCapitalCall,
      round: index + 1,
    },
    modalVisible: FUND_DETAIL_MODALS.capitalCallRoundDetails,
    newCapitalCallStatus: '',
    skippedSteps: [],
    fallbackToAlternatesSteps: {},
    keyDates: {},
  });
};

export const handleRequestAccessClick = () => {
  fundDetailsSignal.update({ modalVisible: FUND_DETAIL_MODALS.requestAccess });
};

export const handleRequestAccessModalClose = () => {
  fundDetailsSignal.update({ modalVisible: null });
};

export const handleSubmitFundRequestClick = async () => {
  const { id } = fundDetailsSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.post({
      path: '/fundRequests',
      body: {
        data: {
          fundId: id,
        },
      },
    });

    await fetchAndSetFund(id);

    handleRequestAccessModalClose();

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Your request has been sent!',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleAddNewCapitalCallDocument = () =>
  fundDetailsSignal.update({
    newCapitalCallRequestDocuments: {
      ...fundDetailsSignal.value.newCapitalCallRequestDocuments,
      value:
        fundDetailsSignal.value.newCapitalCallRequestDocuments.value.concat({
          name: '',
          file: null,
        }),
    },
  });

export const handleRemoveCapitalCallDocument = (index) =>
  fundDetailsSignal.update({
    newCapitalCallRequestDocuments: {
      ...fundDetailsSignal.value.newCapitalCallRequestDocuments,
      value:
        fundDetailsSignal.value.newCapitalCallRequestDocuments.value.filter(
          (_, i) => i !== index
        ),
    },
  });

export const handleCapitalCallDocumentNameChange = (index, name) =>
  fundDetailsSignal.update({
    newCapitalCallRequestDocuments: {
      ...fundDetailsSignal.value.newCapitalCallRequestDocuments,
      value: fundDetailsSignal.value.newCapitalCallRequestDocuments.value.map(
        (b, i) => (index === i ? { ...b, name } : b)
      ),
    },
  });

export const handleCapitalCallDocumentAttachFile = (index, file) =>
  fundDetailsSignal.update({
    newCapitalCallRequestDocuments: {
      ...fundDetailsSignal.value.newCapitalCallRequestDocuments,
      value: fundDetailsSignal.value.newCapitalCallRequestDocuments.value.map(
        (b, i) => (index === i ? { ...b, file } : b)
      ),
    },
  });

export const submitCapitalCallDocuments = async () => {
  const { newCapitalCallRequestDocuments, id } = fundDetailsSignal.value;

  if (newCapitalCallRequestDocuments.value.find((v) => !v.file)) {
    return alertSignal.update({
      type: 'notification',
      message: 'Please attach all files before submitting',
    });
  }

  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Uploading documents...',
    });

    const signedUrls = await uploadFilesToStorage(
      newCapitalCallRequestDocuments.value
        .filter((v) => !!v.file)
        .map((v) => v.file),
      'fundDocument'
    );

    await api.put({
      path: '/funds',
      body: {
        id,
        documents: {
          create: newCapitalCallRequestDocuments.value
            .filter((v) => !!v.file)
            .map((e, i) => ({
              fileType: e.file.type,
              filePath: signedUrls[i],
              name: e.name,
              documentType: 'CAPITAL_CALL',
            })),
        },
      },
    });

    fundDetailsSignal.update({
      newCapitalCallRequestDocuments: {
        value: [],
        dirty: false,
        errors: [],
      },
    });

    await fetchAndSetFund(id);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Documents successfully uploaded',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSelectCapitalCallFile = (file) => {
  const { makeCapitalCallDocuments, selectedCapitalCallTemplate } =
    fundDetailsSignal.value;

  const newMakeCapitalCallDocuments = makeCapitalCallDocuments.map((doc) => {
    if (doc.id === selectedCapitalCallTemplate.id) {
      return {
        ...doc,
        uploadedDocument: file,
      };
    }

    return doc;
  });

  fundDetailsSignal.update({
    makeCapitalCallDocuments: newMakeCapitalCallDocuments,
    selectedCapitalCallTemplate: null,
  });
};

export const handleSelectBankAccount = (id) => {
  const { selectedBankAccountId } = fundDetailsSignal.value;
  fundDetailsSignal.update({
    selectedBankAccountId: selectedBankAccountId === id ? null : id,
  });
};

export const handleNewPortfolioCompanyClick = () => {
  addPortfolioCompanySignal.update({ modalVisible: true });
};
