import attachDocumentsModalSignal from "./attachDocumentsModal.signal"

export const ATTACH_DOCUMENT_MODAL_TYPE = {
  singleDocument: 'SINGLE_DOCUMENT',
  multipleDocuments: 'MULTIPLE_DOCUMENTS',
};

export const handleAttachDocumentModalClose = () => {
  attachDocumentsModalSignal.update({
    modalVisible: null,
    selectedDataRoomDocuments: [],
    selectedTemplateDocument: null,
  });
};

export const handleAttachSingleDocumentSelectClick = (document) => {
  const { selectedDataRoomDocuments } = attachDocumentsModalSignal.value;

  attachDocumentsModalSignal.update({
    selectedDataRoomDocuments: selectedDataRoomDocuments.find(doc => doc.id === document.id) ?
      []
      :
      [document],
  });
};

export const handleMultipleDocumentSelectClick = (document) => {
  const { selectedDataRoomDocuments } = attachDocumentsModalSignal.value;

  attachDocumentsModalSignal.update({
    selectedDataRoomDocuments: selectedDataRoomDocuments.find(doc => doc.id === document.id) ?
      selectedDataRoomDocuments.filter(doc => doc.id !== document.id) :
      [...selectedDataRoomDocuments, document],
  });
};