import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const TextArea = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  disabled,
}) => (
  <div className={className}>
    <Form.Label className={labelClassName}>{label}</Form.Label>
    <div>
      <Form.Control
        disabled={disabled}
        name={name}
        id="textArea"
        as="textarea"
        value={value}
        className={inputClassName}
        onChange={(e) => handleInputChange(e, onChange)}
      />
    </div>
  </div>
);

export default TextArea;
