import {
  lenderProgramRequestsFilterSignal,
  lenderProgramRequestsSignal,
  lenderProgramSelectedApplicationSignal,
} from 'signals/LenderPrograms.signal';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import history from '../../../history';

const FETCH_PROGRAM_REQUEST_INCLUDE = {
  notes: {
    include: {
      log: {
        include: {
          user: true,
        },
      },
    },
  },
  logs: {
    include: {
      user: true,
    },
  },
  watchables: true,
  documents: true,
  fundingProgram: {
    include: {
      platform: true,
      stats: true,
      edo: true,
      referenceProgram: {
        include: {
          programType: true,
        },
      },
      supportingDocuments: true,
      bankAccountProgramAccesses: {
        include: {
          bankAccount: true,
        },
      },
    },
  },
  fundingProgramMembership: true,
  ventureCapitalFirm: true,
  lender: true,
};

export const fetchAndSetProgramRequests = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const bundle = await api.get({
      path: '/tally/programRequests',
    });

    lenderProgramRequestsFilterSignal.update({ bundle });

    const programRequests = await api.get({
      path: '/programRequests',
      options: {
        orderBy: xFormSortToOrderByClause(),
        include: FETCH_PROGRAM_REQUEST_INCLUDE,
        where: xFormAppliedFiltersToWhereClause(),
      },
    });
    return lenderProgramRequestsSignal.update(programRequests);
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const closeProgramModal = () =>
  lenderProgramSelectedApplicationSignal.update(null);
export const openProgramModal = (selectedProgram) =>
  lenderProgramSelectedApplicationSignal.update(selectedProgram);

export const handleProgramRequestsFilterChange = (key, value) => {
  const dropdownFilters =
    lenderProgramRequestsFilterSignal.value.dropdownFilters;
  return lenderProgramRequestsFilterSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

export const handleProgramRequestsSortChange = (sort) =>
  lenderProgramRequestsFilterSignal.update({
    sort,
  });
export const handleProgramRequestsAppliedFiltersChange = (appliedFilters) =>
  lenderProgramRequestsFilterSignal.update({
    appliedFilters,
  });

export const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  if (key === 'programName') {
    return [
      {
        fundingProgram: {
          name: dir,
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    lenderId: userAccountSignal.value.userData.account.lender?.id,
    ventureCapitalFirmId:
      userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
    fundingProgram: {
      is: {},
    },
  };

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.programName) {
    where.fundingProgram.is.id = Number(params.programName);
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.status) {
    where.status = params.status;
  }

  return where;
};

export const resetProgramRequestFilters = () =>
  lenderProgramRequestsFilterSignal.update({ dropdownFilters: {} });
