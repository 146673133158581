import { PROGRAM_KEYS } from '../components/global/Constant/constants';
import { DateTime } from 'luxon';

export const handleLoanParticipationChange = (key, value, state) => {
  const { approvedAmount } = state;

  if (key === 'disbursementDate' || key === 'borrowerEquity') {
    return { ...state, [key]: value };
  }

  if (key === 'approvedAmount') {
    return {
      ...state,
      approvedAmount: value,
      edoParticipationPercentage: '',
      edoParticipationAmount: '',
    };
  }

  if (key === 'edoParticipationPercentage') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      edoParticipationPercentage: value,
      edoParticipationAmount: approvedAmount * (value / 100),
    };
  }

  if (key === 'edoParticipationAmount') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      edoParticipationPercentage: (value / approvedAmount) * 100,
      edoParticipationAmount: value,
    };
  }

  throw new Error(`Invalid key: ${key}`);
};

export const handleCollateralSupportChange = (key, value, state) => {
  const { approvedAmount } = state;

  if (key === 'disbursementDate' || key === 'borrowerEquity') {
    return { ...state, [key]: value };
  }

  if (key === 'approvedAmount') {
    return {
      ...state,
      approvedAmount: value,
      collateralSupportAmount: '',
      collateralSupportPercentage: '',
    };
  }

  if (key === 'collateralSupportPercentage') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      collateralSupportPercentage: value,
      collateralSupportAmount: approvedAmount * (value / 100),
    };
  }

  if (key === 'collateralSupportAmount') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      collateralSupportPercentage: (value / approvedAmount) * 100,
      collateralSupportAmount: value,
    };
  }

  throw new Error(`Invalid key: ${key}`);
};

export const handleCapitalAccessChange = (key, value, state) => {
  const { approvedAmount } = state;

  if (key === 'disbursementDate' || key === 'borrowerEquity') {
    return { ...state, [key]: value };
  }

  if (key === 'approvedAmount') {
    return {
      ...state,
      approvedAmount: value,
      contributionToLoanLossReserveFundAmount: '',
      contributionToLoanLossReserveFundPercentage: '',
    };
  }

  if (key === 'contributionToLoanLossReserveFundPercentage') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      contributionToLoanLossReserveFundPercentage: value,
      contributionToLoanLossReserveFundAmount: approvedAmount * (value / 100),
    };
  }

  if (key === 'contributionToLoanLossReserveFundAmount') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      contributionToLoanLossReserveFundPercentage:
        (value / approvedAmount) * 100,
      contributionToLoanLossReserveFundAmount: value,
    };
  }

  throw new Error(`Invalid key: ${key}`);
};

export const handleLoanGuarantyChange = (key, value, state) => {
  const { approvedAmount } = state;

  if (
    key === 'disbursementDate' ||
    key === 'backedByCashAmount' ||
    key === 'borrowerEquity'
  ) {
    return { ...state, [key]: value };
  }

  if (key === 'approvedAmount') {
    return {
      ...state,
      approvedAmount: value,
      guarantyAmount: '',
      guarantyPercentage: '',
      backedByCashAmount: '',
    };
  }

  if (key === 'guarantyPercentage') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      guarantyPercentage: value,
      guarantyAmount: approvedAmount * (value / 100),
    };
  }

  if (key === 'guarantyAmount') {
    if (!approvedAmount) {
      return { ...state, [key]: value };
    }

    return {
      ...state,
      guarantyPercentage: (value / approvedAmount) * 100,
      guarantyAmount: value,
    };
  }

  throw new Error(`Invalid key: ${key}`);
};

export const validateAnticipatedDisbursementDate = (key, value, errors) => {
  if (!value) {
    return { ...errors, [key]: 'Disbursement date cannot be blank' };
  }

  const date = DateTime.fromISO(value.toISOString().split('T')[0]).toJSDate();
  const today = DateTime.now().startOf('day').toJSDate();

  if (date.getTime() <= today.getTime()) {
    return { ...errors, [key]: 'Disbursement date must be in the future' };
  }

  return errors;
};

export const validateBorrowerEquity = (key, value, errors, approvedAmount) => {
  if (value === '') {
    return { ...errors, [key]: 'Borrower equity cannot be blank.' };
  }

  if (value > approvedAmount) {
    return {
      ...errors,
      [key]: 'Borrower equity cannot exceed the loan amount.',
    };
  }

  return errors;
};

export const validateLoanParticipationApplication = (state, availableFunds) => {
  const {
    disbursementDate,
    approvedAmount,
    borrowerEquity,
    edoParticipationAmount,
    edoParticipationPercentage,
  } = state;

  let errors = {};

  errors = validateBorrowerEquity(
    'borrowerEquity',
    borrowerEquity,
    errors,
    approvedAmount
  );

  errors = validateAnticipatedDisbursementDate(
    'disbursementDate',
    disbursementDate,
    errors
  );

  if (!approvedAmount) {
    errors.approvedAmount = 'Required';
  }

  if (!edoParticipationAmount) {
    errors.edoParticipationAmount = 'Required';
  }

  if (edoParticipationAmount > availableFunds) {
    errors.edoParticipationAmount = 'Not enough funds';
  }

  if (edoParticipationAmount >= approvedAmount) {
    errors.edoParticipationAmount = 'Must be less than the approved amount';
  }

  if (!edoParticipationPercentage) {
    errors.edoParticipationPercentage = 'Required';
  }

  if (edoParticipationPercentage > 99 || edoParticipationPercentage < 1) {
    errors.edoParticipationPercentage = 'Must be between 1% and 99%';
  }

  return errors;
};

export const validateCollateralSupportApplication = (
  state,
  availableFunds,
  programConfig
) => {
  const {
    disbursementDate,
    approvedAmount,
    borrowerEquity,
    collateralSupportPercentage,
    collateralSupportAmount,
  } = state;

  let errors = {};

  errors = validateBorrowerEquity(
    'borrowerEquity',
    borrowerEquity,
    errors,
    approvedAmount
  );

  errors = validateAnticipatedDisbursementDate(
    'disbursementDate',
    disbursementDate,
    errors
  );

  if (!approvedAmount) {
    errors.approvedAmount = 'Required';
  }

  if (!collateralSupportAmount) {
    errors.collateralSupportAmount = 'Required';
  }

  if (collateralSupportAmount > availableFunds) {
    errors.collateralSupportAmount = 'Not enough funds';
  }

  if (collateralSupportAmount >= approvedAmount) {
    errors.collateralSupportAmount = 'Must be less than the approved amount';
  }

  if (!collateralSupportPercentage) {
    errors.collateralSupportPercentage = 'Required';
  }

  const { maxCollateralSupportPercentage, minCollateralSupportPercentage } =
    programConfig;

  if (
    collateralSupportPercentage > maxCollateralSupportPercentage ||
    collateralSupportPercentage < minCollateralSupportPercentage
  ) {
    errors.collateralSupportPercentage = `Must be between ${minCollateralSupportPercentage}% and ${maxCollateralSupportPercentage}%`;
  }

  return errors;
};

export const validateCapitalAccessApplication = (state, availableFunds) => {
  const {
    disbursementDate,
    approvedAmount,
    borrowerEquity,
    contributionToLoanLossReserveFundAmount,
    contributionToLoanLossReserveFundPercentage,
  } = state;

  let errors = {};

  errors = validateBorrowerEquity(
    'borrowerEquity',
    borrowerEquity,
    errors,
    approvedAmount
  );

  errors = validateAnticipatedDisbursementDate(
    'disbursementDate',
    disbursementDate,
    errors
  );

  if (!approvedAmount) {
    errors.approvedAmount = 'Required';
  }

  if (!contributionToLoanLossReserveFundAmount) {
    errors.contributionToLoanLossReserveFundAmount = 'Required';
  }

  if (contributionToLoanLossReserveFundAmount > availableFunds) {
    errors.contributionToLoanLossReserveFundAmount = 'Not enough funds';
  }

  if (contributionToLoanLossReserveFundAmount >= approvedAmount) {
    errors.contributionToLoanLossReserveFundAmount =
      'Must be less than the approved amount';
  }

  if (!contributionToLoanLossReserveFundPercentage) {
    errors.contributionToLoanLossReserveFundPercentage = 'Required';
  }

  if (
    contributionToLoanLossReserveFundPercentage > 99 ||
    contributionToLoanLossReserveFundPercentage < 1
  ) {
    errors.contributionToLoanLossReserveFundPercentage =
      'Must be between 1% and 99%';
  }

  return errors;
};

export const validateLoanGuarantyApplication = (
  state,
  availableFunds,
  programConfig
) => {
  const {
    disbursementDate,
    approvedAmount,
    guarantyPercentage,
    borrowerEquity,
    guarantyAmount,
    backedByCashAmount,
  } = state;

  let errors = {};

  errors = validateBorrowerEquity(
    'borrowerEquity',
    borrowerEquity,
    errors,
    approvedAmount
  );

  errors = validateAnticipatedDisbursementDate(
    'disbursementDate',
    disbursementDate,
    errors
  );

  if (!approvedAmount) {
    errors.approvedAmount = 'Required';
  }

  if (!backedByCashAmount) {
    errors.backedByCashAmount = 'Required';
  }

  if (backedByCashAmount > availableFunds) {
    errors.backedByCashAmount = 'Not enough funds';
  }

  if (backedByCashAmount >= approvedAmount) {
    errors.collateralSupportAmount = 'Must be less than the approved amount';
  }

  if (!guarantyAmount) {
    errors.guarantyAmount = 'Required';
  }

  if (guarantyAmount > availableFunds) {
    errors.guarantyAmount = 'Not enough funds';
  }

  if (guarantyAmount < backedByCashAmount) {
    errors.guarantyAmount =
      'Guaranty amount must be greater than the backed-by-cash amount';
  }

  if (!guarantyPercentage) {
    errors.guarantyPercentage = 'Required';
  }

  const { maxGuarantyPercentage, minGuarantyPercentage } = programConfig;

  if (
    guarantyPercentage > maxGuarantyPercentage ||
    guarantyPercentage < minGuarantyPercentage
  ) {
    errors.guarantyPercentage = `Must be between ${minGuarantyPercentage}% and ${maxGuarantyPercentage}%`;
  }

  return errors;
};

const PROGRAM_SUBMIT_FOR_APPROVAL_CONFIGURATION = {
  [PROGRAM_KEYS.loanParticipation]: [
    {
      label: 'EDO Participation Percentage (P%)',
      name: 'edoParticipationPercentage',
      type: 'integer',
    },
    {
      label: 'EDO Portion',
      name: 'edoParticipationAmount',
      type: 'currency',
    },
  ],
  [PROGRAM_KEYS.microLoan]: [
    {
      label: 'EDO Participation Percentage (P%)',
      name: 'edoParticipationPercentage',
      type: 'integer',
    },
    {
      label: 'EDO Portion',
      name: 'edoParticipationAmount',
      type: 'currency',
    },
  ],
  [PROGRAM_KEYS.loanGuaranty]: [
    {
      label: 'Guaranty Percentage',
      name: 'guarantyPercentage',
      type: 'integer',
    },
    {
      label: 'Guaranty amount',
      name: 'guarantyAmount',
      type: 'currency',
    },
    {
      label: 'Amount backed by cash',
      name: 'backedByCashAmount',
      type: 'currency',
    },
  ],
  [PROGRAM_KEYS.collateralSupport]: [
    {
      label: 'Percentage of Collateral Support',
      name: 'collateralSupportPercentage',
      type: 'integer',
    },
    {
      label: 'Collateral Support Amount',
      name: 'collateralSupportAmount',
      type: 'currency',
    },
  ],
  [PROGRAM_KEYS.capitalAccess]: [
    {
      label: 'Percentage of Contribution towards Loan Loss Reserve Funds',
      name: 'contributionToLoanLossReserveFundPercentage',
      type: 'integer',
    },
    {
      label: 'Contribution towards Loan Loss Reserve Funds',
      name: 'contributionToLoanLossReserveFundAmount',
      type: 'currency',
    },
  ],
};

export const SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS = Object.entries(
  PROGRAM_SUBMIT_FOR_APPROVAL_CONFIGURATION
).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: [
      ...[
        {
          label: 'Approved Amount',
          name: 'approvedAmount',
          type: 'currency',
        },
      ],
      ...value,
      ...[
        {
          label: 'Anticipated Disbursement Date',
          name: 'disbursementDate',
          type: 'date',
        },

        {
          label: 'Borrower Equity',
          name: 'borrowerEquity',
          type: 'currency',
        },
      ],
    ],
  }),
  {}
);
