import dateOptions from '../Constant/dateOptions';

export const sortLogsByDate = (data) => {
  if (!data) {
    return [];
  }

  const sortedData = [];

  data.forEach((item) => {
    const itemDate = new Date(item.createdDate).toLocaleString(
      'en-US',
      dateOptions.date
    );
    const userName = `${item.user?.firstName} ${item.user?.lastName}`;
    if (!sortedData.find((obj) => obj.createdDate === itemDate)) {
      sortedData.push({
        createdDate: itemDate,
        users: [
          {
            id: item.userId,
            userName,
            items: [item],
          },
        ],
      });
    } else {
      sortedData.forEach((obj) => {
        if (itemDate === obj.createdDate) {
          const index = obj.users.findIndex((user) => user.id === item.userId);
          if (index >= 0) {
            obj.users[index].items.push(item);
          } else {
            obj.users.push({
              id: item.userId,
              userName,
              items: [item],
            });
          }
        }
      });
    }
  });
  return sortedData;
};

export const sortNotesByDate = (data) => {
  if (!data) {
    return [];
  }

  const sortedData = [];
  data.forEach((item) => {
    const itemDate = new Date(item.createdDate).toLocaleString(
      'en-US',
      dateOptions.date
    );
    const userName = `${item.log?.user.firstName} ${item.log?.user.lastName}`;
    if (!sortedData.find((obj) => obj.createdDate === itemDate)) {
      sortedData.push({
        createdDate: itemDate,
        users: [
          {
            id: item.log?.userId,
            userName,
            items: [
              {
                content: item.content,
                createdDate: item.createdDate,
              },
            ],
          },
        ],
      });
    } else {
      sortedData.forEach((obj) => {
        if (itemDate === obj.createdDate) {
          const index = obj.users.findIndex(
            (user) => user.id === item.log?.userId
          );
          if (index >= 0) {
            obj.users[index].items.push({
              content: item.content,
              createdDate: item.createdDate,
            });
          } else {
            obj.users.push({
              id: item.log?.userId,
              userName,
              items: [
                {
                  content: item.content,
                  createdDate: item.createdDate,
                },
              ],
            });
          }
        }
      });
    }
  });
  return sortedData;
};

export const getLogDescription = (eventType) => {
  switch (eventType) {
    case 'ADDED_NOTE':
      return 'New note added';
    case 'CHANGED_TO_APPROVED':
      return 'Status changed to approved';
    case 'CHANGED_TO_DENIED':
      return 'Status changed to denied';
    case 'CHANGED_TO_INCOMPLETE':
      return 'Status changed to incomplete';
    case 'CHANGED_TO_PENDING':
      return 'Status changed to pending';
    case 'CREATED':
      return 'Application created';
    case 'UPLOADED_DOCUMENT':
      return 'Application sent to EDO';
    case 'SENT_TO_LENDER':
      return 'Application sent to lender';
    case 'REMOVED_FROM_WATCHLIST':
      return 'Removed from watchlist';
    case 'BYPASS_APPROVAL_CHAIN_ACTIVATED':
      return 'Bypass approval chain activated';
    case 'BYPASS_APPROVAL_CHAIN_DEACTIVATED':
      return 'Bypass approval chain deactivated';
    case 'APPROVAL_CHAIN_MEMBER_APPROVED':
      return 'Approved the application';
    case 'CHANGED_TO_SUBMITTED_FOR_APPROVAL':
      return 'Submitted application for approval';
    default:
      return '';
  }
};

export default {};
