import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { usersIcon } from 'components/global/Constant/Icons';
import stateBillingSignal from 'signals/StateBilling.signal';
import BillingTableRow from './components/BillingTableRow';
import DeleteAccountModal from './components/DeleteAccountModal';
import LinkAccountModal from './components/LinkAccountModal';
import { fetchAndSetPaymentAccounts } from './StateBilling.helpers';

const Billing = () => {
  const [showModals, setShowModals] = useState({
    deleteAccountModal: false,
    linkAccountModal: false,
  });

  useEffect(() => {
    fetchAndSetPaymentAccounts();
  }, []);

  const toggleModal = (modal) => {
    const newModalState = !showModals[modal];

    setShowModals(prev => ({
      ...prev,
      [modal]: newModalState,
    }));
  };

  return (
    <Card className="p-3 mb-4 text-secondary">
      <Row className="mb-4">
        <Col>
          <h5><FontAwesomeIcon icon={usersIcon} /> Billing</h5>
        </Col>
      </Row>
      <Row className="my-8">
        <Col className="d-flex justify-content-end">
          <Button size="sm" className="rounded" onClick={() => toggleModal('linkAccountModal')}>
            Link Account
          </Button>
        </Col>
      </Row>
      <div className="p-8">
        <Table className="mb-1">
          <thead>
            <tr className="font-weight-bold">
              <th> </th>
              <th className="text-nowrap">Account Name</th>
              <th className="text-nowrap">Account ID</th>
              <th className="text-nowrap">Status</th>
              <th className="text-nowrap">Linked Programs</th>
              <th className="text-nowrap">Actions</th>
            </tr>
          </thead>
          <tbody>
            {stateBillingSignal.value.paymentAccounts?.map(accountInfo => (
              <BillingTableRow key={accountInfo.id} accountInfo={accountInfo} onToggleModal={() => toggleModal('deleteAccountModal')} />
            ))}
          </tbody>
        </Table>
        {stateBillingSignal.value.paymentAccounts?.length < 1 &&
          (
            <p>You don&apos;t have any accounts saved. Click the link account button to add one.</p>
          )}
      </div>
      <DeleteAccountModal show={showModals.deleteAccountModal} onToggleModal={() => toggleModal('deleteAccountModal')} />
      <LinkAccountModal show={showModals.linkAccountModal} onToggleModal={() => toggleModal('linkAccountModal')} />
    </Card>
  );
};

export default Billing;
