import Select from "components/global/Field/FieldComponents/Select";
import { Col, Row, Table } from "react-bootstrap";
import { reportDeadlineSignal } from "components/global/Reporting/Reporting.signals";
import { deadlineSelectOptions } from "../reportConstants";
import { handleDeadlineScheduleSelect } from "../Reporting.helpers";

const DeadlineSchedules = () => {
  const deadlineSchedule = reportDeadlineSignal.value;

  return (
    <>
      <Row>
        <Col lg={4}>
          <Select
            options={deadlineSelectOptions}
            label="Schedule type"
            labelClassName="font-weight-bold"
            inputClassName="form-control"
            onChange={handleDeadlineScheduleSelect}
            lineBreak
          />
        </Col>
      </Row>
      <hr />
      {deadlineSchedule && (
        <Row>
          <Col>
          <Table style={{ maxWidth: 800 }} size="sm" bordered className="mt-16">
            <thead>
              <tr>
                <th>Reporting Period</th>
                <th>Submission Deadline</th>
              </tr>
            </thead>
            <tbody>
              {deadlineSchedule.map((deadline) => (
                <tr key={deadline.reportingPeriod}>
                  <td>{deadline.reportingPeriod}</td>
                  <td>{deadline.submissionDeadline}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DeadlineSchedules;
