import { Button, Col, Form, Row } from 'react-bootstrap';
import submitApplicationSignal from 'signals/SubmitApplication.signal';
import {
  handleInputChange,
  prevStep,
  submitApplicationAndNextStep,
} from '../SubmitApplications.helpers';
import loaderSignal from 'signals/Loader.signal';

const SubmitApplicationsNote = () => {
  const { note } = submitApplicationSignal.value;

  return (
    <>
      <Row className="mb-32">
        <Col>
          <h5>Additional Remarks</h5>
          <p>Complete the application with clarifying remarks, if any</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="note">
              Note
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              type="text"
              className="form-control"
              id="note"
              name="note"
              aria-describedby="note"
              value={note}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-64">
        <Col className="text-center">
          <Button
            disabled={loaderSignal.value.isContentLoading}
            onClick={prevStep}
          >
            Prev Page
          </Button>
        </Col>
        <Col className="text-center">
          <Button
            disabled={loaderSignal.value.isContentLoading}
            onClick={submitApplicationAndNextStep}
          >
            {loaderSignal.value.isContentLoading
              ? 'Submitting application...'
              : 'Submit'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SubmitApplicationsNote;
