import { Button, Col, Form, Row } from "react-bootstrap";
import { fetchAndSetPlatforms, handleNextClick, handlePlatformSelection } from "./selectPlatform.helpers";
import { useCallback, useEffect } from "react";

const SelectPlatform = ({ signal, nextForm = 'mainForm' }) => {
  const { platforms, selectedPlatform } = signal.value;
  const platformOptions = platforms.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  const init = useCallback(async () => {
    fetchAndSetPlatforms(signal);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div>
      <Row className="mb-32 justify-content-center">
        <Col lg={3} className="d-flex flex-column">
          <Form.Label className="mb-8 font-weight-bold text-center">
            Select a Platform
          </Form.Label>
          <Form.Select
            type="select"
            className="form-control"
            name="platform"
            options={[
              { label: 'All Platforms', value: 'allPlatforms' },
              ...platformOptions,
            ]}
            value={selectedPlatform.id || selectedPlatform || ''}
            onChange={(e) => handlePlatformSelection(e, signal)}
          >
            <option disabled value="">
              Select an Option
            </option>
            {platforms.map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div>
          <Button
            className="rounded"
            disabled={!selectedPlatform}
            onClick={() => handleNextClick(signal, nextForm)}
          >
            Next
          </Button>
        </div>
      </Row>
    </div>
  );
};
export default SelectPlatform;
