import programRequestDetailSignal, {
  $programRequestApprovalChainConfirmationForm,
  $programRequestApprovalChainMemberships,
} from './ProgramRequestDetail.signals';
import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import applicationDetailSignal, {
  ApplicationDetailType,
} from '../ApplicationDetail/ApplicationDetail.signal';
import {
  ProgramRequest,
  ProgramRequestApprovalChainMembership,
} from '../../../types';
import userAccountSignal from '../../../signals/UserAccount.signal';
import { Signal } from '@preact/signals-react';
import { refreshListAndModal } from '../Approvable/Approvable.helpers';
import { uploadFilesToStorage } from '../../../libs/functions/global.functions';
import $appSettings from 'signals/AppSettings.signal';

export const txProgramRequestToApplicationDetailsModal = (
  programRequest: ProgramRequest,
  ventureCapitalist: boolean
): Partial<ApplicationDetailType> => {
  const fundingProgram = programRequest?.fundingProgram;
  const lender = programRequest?.lender;
  const ventureCapitalFirm = programRequest?.ventureCapitalFirm;
  const linkedBankAccount = fundingProgram.bankAccountProgramAccesses?.find(
    (access) =>
      access.bankAccount.accountId === lender?.accountId ||
      ventureCapitalFirm?.accountId
  );

  return {
    id: programRequest.id,
    status: programRequest.status,
    ventureCapitalist,
    approvedAmount: programRequest.approvedAmount,
    fundingProgramStats: fundingProgram.stats,
    fundingProgram: programRequest.fundingProgram,
    lender: {
      name: (lender?.name || ventureCapitalFirm?.name) as string,
      contactName: (lender?.contactName ||
        ventureCapitalFirm?.contactName) as string,
      email: (lender?.email || ventureCapitalFirm?.email) as string,
      phoneNumber: (lender?.phoneNumber ||
        ventureCapitalFirm?.phoneNumber) as string,
    },
    logs: programRequest.logs,
    notes: programRequest.notes,
    documents: programRequest.documents.sort(
      (a, b) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    ),
    watchableId:
      programRequest.watchables?.find(
        (w) =>
          w.programRequestId === programRequest.id &&
          userAccountSignal.value.userData.id
      )?.id || null,
    modelType: 'programRequest',
    uploadDocumentType: 'programRequestDocuments',
    lenderUnread: programRequest.lenderUnread,
    edoUnread: programRequest.edoUnread,
    requestedAmount: programRequest.allocationAsk,
    submittedByEdo: false,
    hasLinkedBankAccount: !!linkedBankAccount,
    usesCaatPayment: fundingProgram.platform.caatForPaymentReconciliation,
    approvalChainTemplates: fundingProgram?.approvalChainTemplates,
  };
};

export const showProgramDetailInlineModal = (): Signal<unknown> =>
  programRequestDetailSignal.update({
    inlineModalVisible: true,
  });

export const hideProgramDetailInlineModal = (): Signal<unknown> =>
  programRequestDetailSignal.update({
    inlineModalVisible: false,
  });

export const showConfirmStatusModal = (): Signal<unknown> =>
  programRequestDetailSignal.update({
    confirmStatusVisible: true,
  });

export const hideConfirmStatusModal = (): Signal<unknown> =>
  programRequestDetailSignal.update({
    confirmStatusVisible: false,
  });

const FETCH_PROGRAM_REQUEST_INCLUDE = {
  approvalChainMemberships: {
    include: {
      user: true,
    },
  },
  notes: {
    include: {
      log: {
        include: {
          user: true,
        },
      },
    },
  },
  logs: {
    include: {
      user: true,
    },
  },
  watchables: true,
  documents: {
    orderBy: {
      id: 'desc',
    },
  },
  fundingProgram: {
    include: {
      approvalChainTemplates: {
        orderBy: {
          id: 'desc',
        },
        include: {
          steps: {
            orderBy: {
              id: 'asc',
            },
            include: {
              primaryApprover: true,
              alternateApprovers: {
                include: { user: true },
              },
            },
          },
        },
      },
      stats: true,
      edo: true,
      referenceProgram: {
        include: {
          programType: true,
        },
      },
      supportingDocuments: true,
      platform: true,
      bankAccountProgramAccesses: {
        include: {
          bankAccount: true,
        },
      },
    },
  },
  fundingProgramMembership: true,
  ventureCapitalFirm: true,
  lender: true,
};

export const fetchProgramRequest = async (
  id: string
): Promise<Signal<unknown>> => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const programRequest = (await api.get({
      path: '/programRequests',
      options: {
        where: {
          id,
        },
        include: FETCH_PROGRAM_REQUEST_INCLUDE,
      },
    })) as ProgramRequest;

    $programRequestApprovalChainMemberships.update({
      records: [
        ...programRequest.approvalChainMemberships.sort((a, b) => a.id - b.id),
      ],
    });

    return applicationDetailSignal.update(
      txProgramRequestToApplicationDetailsModal(
        programRequest,
        !!programRequest.ventureCapitalFirm
      )
    );
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: `An unexpected error occurred when loading the program request. ${error.message}`,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const updateProgramRequestStatus = async (): Promise<
  Signal<unknown>
> => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating application status...',
  });

  const {
    id,
    newApplicationStatus,
    ventureCapitalist,
    approvalChainTemplates,
  } = applicationDetailSignal.value;

  const { approvedAmount } = programRequestDetailSignal.value;
  const { skippedSteps, fallbackToAlternatesSteps, keyDates } =
    $programRequestApprovalChainConfirmationForm.value;

  const data: Record<string, number | string | { create: any[] }> = {
    status: newApplicationStatus,
  };

  if (
    data.status === APPLICATION_STATUS.submittedForApproval &&
    ventureCapitalist
  ) {
    data.approvedAmount = Number(approvedAmount);

    const approvalChainTemplate = approvalChainTemplates[0];

    data.approvalChainMemberships = {
      create: approvalChainTemplate.steps
        .filter((step) => !skippedSteps.includes(step.id))
        .map((step) => {
          const data: Record<string, string | number | boolean | Date> = {
            role: step.role,
            templateId: approvalChainTemplate.id,
            boardRepresentative: step.boardRepresentative,
          };

          if (
            Object.keys(fallbackToAlternatesSteps).includes(step.id.toString())
          ) {
            data.userId = step.alternateApprovers[0].userId;
            data.alternate = true;
          } else {
            data.userId = step.primaryApproverId;
            data.alternate = false;
          }

          if (data.boardRepresentative) {
            data.keyDate = keyDates[step.id.toString()] || null;
          }

          return data;
        }),
    };
  }

  const body = {
    data,
    where: {
      id,
    },
  };

  try {
    await api.patch({
      path: '/programRequests',
      body,
    });

    await refreshListAndModal(id!, 'programRequest', ventureCapitalist);

    applicationDetailSignal.update({
      newApplicationStatus: '',
    });

    programRequestDetailSignal.update({
      approvedAmount: '',
      approvedAmountError: '',
      confirmStatusVisible: false,
    });

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated status.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const isApprovedAmountValid = (): Signal<unknown> => {
  const { fundingProgramStats, requestedAmount } =
    applicationDetailSignal.value;

  const { approvedAmount } = programRequestDetailSignal.value;

  const castApprovedAmount = Number(approvedAmount);

  if (isNaN(castApprovedAmount)) {
    return programRequestDetailSignal.update({
      approvedAmountError: 'Please enter a valid number.',
    });
  }

  if (castApprovedAmount <= 0) {
    return programRequestDetailSignal.update({
      approvedAmountError: 'Please enter a value greater than 0.',
    });
  }

  if (castApprovedAmount > Number(requestedAmount)) {
    return programRequestDetailSignal.update({
      approvedAmountError:
        'Please enter a value less than or equal to the requested amount.',
    });
  }

  if (castApprovedAmount > Number(fundingProgramStats?.remaining)) {
    return programRequestDetailSignal.update({
      approvedAmountError:
        'Please enter a value less than or equal to the available amount.',
    });
  }

  return programRequestDetailSignal.update({
    approvedAmountError: '',
  });
};

export const handleApprovedAmountChange = (
  approvedAmount: string
): Signal<unknown> => {
  programRequestDetailSignal.update({
    approvedAmount,
  });

  return isApprovedAmountValid();
};

export const handleNoteChange = (note: string): Signal<unknown> =>
  $programRequestApprovalChainConfirmationForm.update({
    note,
  });

export const handleFileChange = (file: File): Signal<unknown> =>
  $programRequestApprovalChainConfirmationForm.update({
    file,
  });

export const handleApprovalChainConfirmation = async (
  confirmation: ProgramRequestApprovalChainMembership
): Promise<Signal<unknown>> => {
  const { note, file } = $programRequestApprovalChainConfirmationForm.value;

  if (note === '') {
    return alertSignal.update({
      type: 'notification',
      message: 'A note is needed.',
    });
  }

  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting confirmation...',
  });

  let signedUrls = [];

  if (file) {
    signedUrls = await uploadFilesToStorage(
      [file],
      'approvalChainConfirmationAttachment'
    );
  }

  try {
    const body = {
      data: {
        confirmationBundle: {
          attachments:
            signedUrls.length > 0
              ? [
                {
                  fileType: file!.type,
                  filePath: signedUrls[0],
                },
              ]
              : [],
          note,
        },
        action: 'assert',
      },
    };

    const id = applicationDetailSignal.value.id;

    await api.patch({
      path: `/programRequests/${id}/approvalChainMemberships/${confirmation.id}`,
      body,
    });
    await refreshListAndModal(
      applicationDetailSignal.value.id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );

    await fetchProgramRequest(id!.toString());

    $programRequestApprovalChainConfirmationForm.reset();

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully submitted.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleApprovalChainTemplateChange = (
  skippedSteps: number[],
  fallbackToAlternatesSteps: Record<string, number[]>,
  keyDates: Record<string, Date>
): Signal<unknown> =>
  $programRequestApprovalChainConfirmationForm.update({
    skippedSteps,
    fallbackToAlternatesSteps,
    keyDates,
  });
