import Signal from 'libs/signals';

const TransactionSignal = Signal({
  allTransactionMetrics: {},
  transactionLeverageMetrics: {},
  selectedPlatform: '',
  platforms: [],
  form: 'selectPlatform',
});

export default TransactionSignal;
