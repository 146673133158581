import Signal from "libs/signals";

const dataRoomSignal = Signal({
  filesToUpload: [],
  organizationDocuments: [],
  suggestedDocuments: [],
  documentBucket: '',
  capTableRecord: {},
});

export default dataRoomSignal;
