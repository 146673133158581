import {
  PROGRAM_KEYS,
} from 'components/global/Constant/constants';
import VALIDATION_MESSAGES from 'components/global/Constant/validationMessages';
import $appSettings from 'signals/AppSettings.signal';
import validateUrl from 'utils/validateUrl';

const PROGRAM_CHAR_MIN = 2;

const SELECTION_INPUTS = [
  'platformId',
  'programTypeId',
  'referenceProgramId',
  'programClassification',
  'fundType',
  'administratorId',
  'fundsUnderCustodyAt',
];

const RICH_TEXT_INPUTS = [
  'overview',
  'eligibility',
  'ineligibleBusinesses',
  'programRules',
  'rebalancingFrequency',
];

const castNumber = (n) => {
  if (typeof n === 'number') {
    return n;
  }

  return parseInt(n.replaceAll('$', '').replaceAll(',', ''), 10);
};

export const validateFileBundle = (key, bundle) => {
  const { DOCUMENT_TYPES } = $appSettings.value.constants;
  if (!bundle.name) {
    return 'You must enter a name for the file.';
  }

  if (
    key.includes(DOCUMENT_TYPES.applicationTemplate) ||
    key.includes(DOCUMENT_TYPES.programRequestTemplate)
  ) {
    if (!bundle.file) {
      return 'You must attach a file.';
    }
  }

  return '';
};

export const validateFundingProgramField = (
  key,
  value,
  form,
  platform,
  delta = 0
) => {
  console.log('key', key);
  console.log('value', value);
  console.log('form', form);
  console.log('platform', platform);

  if (key === 'name' && value.length < PROGRAM_CHAR_MIN) {
    return 'You must enter in a name for the program (2 characters or more).';
  }

  if (SELECTION_INPUTS.includes(key) && !value) {
    return 'Please select an option.';
  }

  if (RICH_TEXT_INPUTS.includes(key) && !value) {
    return 'Please enter a suitable description.';
  }

  if (key === 'ceiling') {
    if (!platform) {
      return 'A platform must be selected first.';
    }

    if (castNumber(value) < 1) {
      return 'Total program funding must be greater than 0.';
    }

    if (
      castNumber(value) - delta >
      platform.stats.burned - platform.stats.funded
    ) {
      return "Total program funding cannot exceed the platform's available funds.";
    }
  }

  if (key === 'fundsAlreadyAllocated') {
    if (!form.ceiling) {
      return 'Ceiling must be entered first.';
    }

    if (castNumber(value) > castNumber(form.ceiling)) {
      return 'Funds already allocated cannot exceed the ceiling.';
    }
  }

  if (key === 'minGuarantyPercentage') {
    if (!value) {
      return 'The minimum value cannot be empty.';
    }

    if (value < 1) {
      return 'The minimum value must be greater than 0.';
    }
  }

  if (key === 'maxGuarantyPercentage') {
    if (!value) {
      return 'The maximum value cannot be empty.';
    }

    if (value < 1) {
      return 'The maximum value must be greater than 0.';
    }

    if (value <= form.minGuarantyPercentage) {
      return 'The maximum value must be greater than the minimum value.';
    }
  }

  if (key === 'website' && value) {
    if (!validateUrl(value)) {
      return VALIDATION_MESSAGES.website;
    }
  }
};

export const MAIN_PROGRAM_INPUTS = {
  general: {
    basicProgramInfo: [
      'name',
      'platformId',
      'programTypeId',
      'referenceProgramId',
      'administratorId',
      'overview',
      'programRules',
      'ineligibleBusinesses',
      'eligibility',
      'ceiling',
      'fundsAlreadyAllocated',
    ],
  },
  [PROGRAM_KEYS.microLoan]: {
    basicProgramInfo: ['programClassification'],
  },
  [PROGRAM_KEYS.loanParticipation]: {
    basicProgramInfo: ['programClassification'],
  },
  [PROGRAM_KEYS.loanGuaranty]: {
    basicProgramInfo: [
      'minGuarantyPercentage',
      'maxGuarantyPercentage',
      'guarantyAmountBackedByCashPercentage',
    ],
  },
  [PROGRAM_KEYS.fundInvestment]: {
    basicProgramInfo: ['fundType'],
  },
  [PROGRAM_KEYS.directInvestment]: {
    basicProgramInfo: [],
  },
  [PROGRAM_KEYS.capitalAccess]: {
    basicProgramInfo: [],
  },
  [PROGRAM_KEYS.collateralSupport]: {
    basicProgramInfo: [
      'minCollateralSupportPercentage',
      'maxCollateralSupportPercentage',
    ],
  },
};
