import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import addUserModalSignal from './AddUserModal.signal';
import loaderSignal from 'signals/Loader.signal';
import validateEmailUtil from 'utils/validateEmail';
import $appSettings from 'signals/AppSettings.signal';

export const handleAddUserModalSubmit = async (e, opts) => {
  e.preventDefault();
  loaderSignal.update({ isContentLoading: true, message: 'Sending invite...' });

  const { firstName, lastName, email, role, invitationProgramAccesses } =
    addUserModalSignal.value;

  try {
    await api.post({
      path: '/userInvites',
      body: {
        firstName,
        lastName,
        email,
        role,
        portal: opts.portal,
        invitationProgramAccesses: {
          create: invitationProgramAccesses,
        },
      },
    });
    addUserModalSignal.reset();
    if (opts.successCallback) {
      await opts.successCallback();
    }
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully invited user.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleAddUserModalChange = (e) => {
  const { USER_ROLES } = $appSettings.value.constants;
  const { name, value } = e.target;
  const data = {
    [name]: value,
  };

  if (name === 'role' && value === USER_ROLES.admin) {
    data.invitationProgramAccesses = [];
  }

  if (name === 'email') {
    data.emailError = false;
  }

  addUserModalSignal.update(data);
};

export const showAddInternalUserModal = () =>
  addUserModalSignal.update({ modalVisible: true });
export const hideAddInternalUserModal = () =>
  addUserModalSignal.update({ modalVisible: false });

export const handleInvitationProgramAccessClick = (fundingProgramId) => {
  const { USER_ROLES } = $appSettings.value.constants;
  const { invitationProgramAccesses } = addUserModalSignal.value;
  let newInvitationProgramAccesses = [];

  if (
    invitationProgramAccesses.find(
      (ipa) => ipa.fundingProgramId === fundingProgramId
    )
  ) {
    newInvitationProgramAccesses = invitationProgramAccesses.filter(
      (programAccess) => programAccess.fundingProgramId !== fundingProgramId
    );
  } else {
    newInvitationProgramAccesses = [
      ...invitationProgramAccesses,
      { fundingProgramId, role: USER_ROLES.staff },
    ];
  }

  addUserModalSignal.update({
    invitationProgramAccesses: newInvitationProgramAccesses,
  });
};

export const validateEmail = () => {
  const { email } = addUserModalSignal.value;

  const isError = !validateEmailUtil(email);
  return addUserModalSignal.update({ emailError: isError });
};
