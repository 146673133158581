/* eslint-disable no-unreachable */
import api from "api/api";
import userAccountSignal from "signals/UserAccount.signal";
import $appSettings from "signals/AppSettings.signal";
import tokenSignal from "signals/Token.signal";
import { getConfig } from "config/config";
import jwtDecode from "jwt-decode";
import history from "../../history";
import Papa from "papaparse";
import loaderSignal from "../../signals/Loader.signal";
import alertSignal from "../../signals/Alert.signal";
import { blobToArrayBuffer } from "./file";
import sleep from "../sleep";

const appConfig = getConfig('APP_CONFIG');

export const onTestMode = () => $appSettings.value.appSettings?.testMode;

export const redirectToMsalLogin = async (instance) => {
  localStorage.removeItem('systemUseAgreement');
  instance.loginRedirect({
    redirectUri: getConfig('BASE_URL'),
  });
}

export const performMsalLogoutViaRedirect = async (instance, account) => {
  localStorage.removeItem('systemUseAgreement');
  instance.logoutRedirect({
    account,
    postLogoutRedirectUri: '/',
  });
}

export const checkPortalPermissions = (currentPortal) => {
  const { USER_ROLES } = $appSettings.value.constants;

  return currentPortal === userAccountSignal.value.userData?.account?.portalType ||
    userAccountSignal.value.userData?.role === USER_ROLES.superAdmin;
};

export const fetchAndSaveMsalAccountAndAuthToken = async (
  instance,
  account
) => {
  // may need to adjust cache policy in the future
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
  const request = {
    account,
    scopes: [appConfig.scopes],
  };

  const tokenResponse = await instance.acquireTokenSilent(request);
  return tokenSignal.update(tokenResponse);
};

export const extendSessionByGettingNewToken = async (instance, account) => {
  const tokenResponse = await instance.acquireTokenSilent({
    account,
    scopes: [getConfig('REACT_APP_MSAL_CLIENT_ID')],
  });

  return tokenSignal.update(tokenResponse);
};

export const currentTokenExpiringInSeconds = () => {
  if (!tokenSignal.value.accessToken) {
    return null;
  }

  const decodedJwt = jwtDecode(tokenSignal.value.accessToken);
  const tokenExpirationTime = decodedJwt.exp;

  const currentTime = Math.floor(Date.now() / 1000);
  const remainingTimeInSeconds = tokenExpirationTime - currentTime;
  return Math.floor(remainingTimeInSeconds);
};

export const getMsalLoggedInAccount = async (instance) => {
  const accounts = await instance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    return accounts[0];
  } else {
    return null;
  }
};

export const fetchAndSetCaatAccount = async (
  instance,
  msalAccount,
  retries = 0
) => {
  try {
    await sleep((retries) * (retries) * 80);

    const [userData, appSettingsResponse] = await Promise.all([
      api.get({ path: '/users/current' }, 1000),
      api.get({ path: '/appSettings' }, 1000),
    ]);

    if (!sessionStorage.getItem('lastSeenAt')) {
      sessionStorage.setItem('lastSeenAt', 'true');
      try {
        api.post({ path: '/users/current' });
      } catch (err) {
        console.error('Failed to save latest login date.', err.message);
      }
    }

    $appSettings.update(appSettingsResponse);

    return userAccountSignal.update({
      auth: msalAccount,
      userData,
      loaded: true,
    });
  } catch (error) {
    console.log(error.message);
    console.log(error.status);
    if (error.status === 401) {
      if (error.message.includes('jwt')) {
        return performMsalLogoutViaRedirect(instance, instance.getActiveAccount());
        // return alertSignal.update({
        //   message: 'Your session has terminated. Please log back in.',
        //   type: 'alert',
        //   error,
        //   onClose: () =>
        //     performMsalLogoutViaRedirect(instance, instance.getActiveAccount()),
        //   overrideCloseMessage: 'Log in',
        // });
      } else {
        return alertSignal.update({
          message: error.message,
          type: 'alert',
          error,
        });
      }
    } else {
      if (retries < 4) {
        return fetchAndSetCaatAccount(instance, msalAccount, retries + 1);
      }

      return alertSignal.update({
        message: 'We\'re experiencing technical difficulties with our backend systems right now. We apologize for any inconvenience this may cause.\n\nPlease bear with us, and we\'ll have things back to normal as soon as possible. Thank you for your patience and understanding.',
        type: 'alert',
        error,
        overrideCloseMessage: 'Refresh Page',
        onClose: () => location.reload(),
      });
    }
  }
};

// eslint-disable-next-line no-alert
export const showPendingImplementation = () =>
  alert('Feature currently being implemented / worked on');

export const restrictStaffAccess = () => {
  const { USER_ROLES } = $appSettings.value.constants;
  if (
    userAccountSignal.value.userData.role !== USER_ROLES.admin &&
    userAccountSignal.value.userData.role !== USER_ROLES.owner &&
    userAccountSignal.value.userData.role !== USER_ROLES.superAdmin
  ) {
    return true;
  }

  return false;
};

// TODO: parallelize this function using Promise.all
export const uploadFilesToStorage = async (files, model, fullUrl = false) => {
  if (files.some((f) => !f.type)) {
    throw new Error('One or more files have an invalid extension. Aborting');
  }

  const signedUrls = await api.post({
    path: '/fileUploads',
    body: {
      uploads: files.map((f) => ({
        fileType: f.type,
        model,
      })),
    },
  });

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    const body = await blobToArrayBuffer(file);

    const headers = new Headers();
    headers.append('Content-Length', body.byteLength.toString());
    headers.append('x-ms-blob-type', 'BlockBlob');
    headers.append('x-ms-blob-content-type', file.type);

    await fetch(signedUrls[i], {
      method: 'PUT',
      body,
      headers,
    });
  }

  if (fullUrl) {
    return signedUrls;
  }

  return signedUrls.map(getPathFromSignedUrl);
};

export const getPathFromSignedUrl = (url) =>
  url.split('/').slice(4).join('/').split('?')[0];

export const handleTabChange = (status) => {
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.delete('page');

  const params = Object.fromEntries(searchParams);

  if (!status || status === 'all') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status, ...withoutStatusParams } = params;
    history.push(
      `${history.location.pathname}?${new URLSearchParams(withoutStatusParams)}`
    );
  } else {
    history.push(
      `${history.location.pathname}?${new URLSearchParams({
        ...params,
        status,
      })}`
    );
  }
};

export const parseCSVFile = async (file, opts = {}) =>
  new Promise((res) =>
    Papa.parse(file, {
      complete: (result) => {
        // 'result.data' contains the parsed CSV data as an array of arrays
        res(result.data);
      },
      header: true, // Set to true if the first row contains headers
      skipEmptyLines: true, // Skip empty lines in the CSV file
      ...opts,
    })
  );

export const locationSearchToObject = (search) =>
  Object.fromEntries(new URLSearchParams(search).entries());

export function randomHexColor() {
  const red = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');
  const green = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');
  const blue = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');

  return `#${red}${green}${blue}`;
}

export const fetchSavedSearchResultAndRedirectToWithinToRefetch = async (
  id
) => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const [savedSearchResult] = await Promise.all([
      api.get({
        path: '/savedSearchResults',
        options: {
          where: {
            id,
          },
        },
      }),
    ]);

    const {
      matches: { new: newIds },
    } = savedSearchResult;

    history.replace(`${history.location.pathname}?id=${newIds.join(',')}`);
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getStatusStyles = (status) => {
  const { APPLICATION_STATUS, CAPITAL_CALL_STATUS } = $appSettings.value.constants;

  switch (status) {
    case APPLICATION_STATUS.approved:
    case CAPITAL_CALL_STATUS.approved:
      return { class: 'bg-success text-white' };
    case APPLICATION_STATUS.incomplete:
      return { class: 'bg-orange text-white' };
    case APPLICATION_STATUS.pending:
    case CAPITAL_CALL_STATUS.pending:
      return { class: 'bg-warning text-white' };
    case APPLICATION_STATUS.denied:
    case CAPITAL_CALL_STATUS.denied:
      return { class: 'bg-danger text-white' };
    case APPLICATION_STATUS.submittedForApproval:
    case CAPITAL_CALL_STATUS.submittedForApproval:
    case CAPITAL_CALL_STATUS.funded:
      return { class: 'bg-primary text-white' };
    case APPLICATION_STATUS.default:
      return { class: 'bg-dark text-white' };
    case APPLICATION_STATUS.inProgress:
      return { class: 'bg-secondary text-white' };
    default:
      return { class: 'bg-secondary text-white' };
  }
};

export function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const base64Data = event.target.result.split(',')[1]; // Extract base64 data
      resolve(base64Data);
    };

    reader.onerror = function (event) {
      reject(event.target.error);
    };

    reader.readAsDataURL(file);
  });
}
