import Signal from "libs/signals";

export const reportingSignals = Signal({
  selectedPlatform: '',
  platforms: [],
});

export const reportDeadlineSignal = Signal(null);

export const annualReportingSignal = Signal({
  downloadingReport: false,
  // selectedOption: '',
  selectedTab: '',
  fetchingTableData: false,
  reportData: null,
});

export const QUARTERLY_REPORT_INIT_STATE = {
  id: null,
  startDate: '',
  endDate: '',
  submissionDeadline: '',
  participationJurisdiction: '',
  totalPotentialAllocation: 0,
  cumulativeFundsDisbursedToParticipatingJurisdiction: 0,
  programInformationTableData: [],
  contractedEntityTableData: [],
  fundsEOTForAdministrativeCostsTableData: {
    directCumulativeAsOfPriorQuarterEnd: 0,
    directForAdministrativeCostsAdjustment: 0,
    directForAdministrativeCostsCurrentQuarter: 0,
    directCumulativeAsOfCurrentQuarter: 0,
    indirectCumulativeAsOfPriorQuarterEnd: 0,
    indirectForAdministrativeCostsAdjustment: 0,
    indirectForAdministrativeCostsCurrentQuarter: 0,
    indirectCumulativeAsOfCurrentQuarter: 0,
  },
  otherRequiredInformation: {
    programIncomeCumulativeAsOfPriorQuarterEnd: 0,
    programIncomeAdjustment: 0,
    programIncomeCurrentQuarter: 0,
    programIncomeCumulativeAsOfCurrentQuarter: 0,
    capChargeOffsCumulativeAsOfPriorQuarterEnd: 0,
    capChargeOffsAdjustment: 0,
    capChargeOffsCurrentQuarter: 0,
    capChargeOffsCumulativeAsOfCurrentQuarter: 0,
  },
  programAllocatedFundsCumulativePriorQuarterEndTotal: 0,
  programAllocatedFundsAdjustmentTotal: 0,
  programAllocatedFundsCurrentQuarterTotal: 0,
  programAllocatedFundsCumulativeCurrentQuarterTotal: 0,
  recycledFundsCumulativePriorQuarterEndTotal: 0,
  recycledFundsAdjustmentTotal: 0,
  recycledFundsCurrentQuarterTotal: 0,
  recycledFundsCumulativeCurrentQuarterTotal: 0,
  fundsEotcumulativeAsOfPriorQuarterEnd: 0,
  fundsEotAdjustmentTotal: 0,
  fundsEotCurrentQuarterTotal: 0,
  fundsEotCumulativeAsOfCurrentQuarter: 0,
  percentageCumulativeFundsDisbursedToParticipatingJurisdiction: 0,
  percentageCumulativeProgramAllocatedFundsEotAsOfCurrentQuarter: 0,
  otherRequiredInformationAdjustmentTotal: 0,
  otherRequiredInformationCurrentQuarterTotal: 0,
  otherRequiredInformationCumulativeAsOfPriorQuarterEnd: 0,
  otherRequiredInformationCumulativeAsOfCurrentQuarter: 0,
  contractedEntityBudgetedAmountTotal: 0,
  additionalInformation: '',
};

export const quarterlyReportSignal = Signal({
  quarterlyReportId: '',
  quarterlyReport: QUARTERLY_REPORT_INIT_STATE,
  platform: '',
  reportingPeriod: '',
  editing: false,
  addContractedEntityInProgress: false,
  addContractedEntityName: '',
});

export const reportUploadSignal = Signal({
  files: [],
  quarter: '',
  year: '',
  modalVisible: false,
});

export default reportingSignals;
