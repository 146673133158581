import { Col, Row } from "react-bootstrap";
import VCDealFlowsSignal from "../VCDealFlows.signal";
import dateOptions from "components/global/Constant/dateOptions";

const FundInformation = ({ hideOrganizationName = false }) => {
  const { selectedVCPortfolioCompany } = VCDealFlowsSignal.value;

  if (!selectedVCPortfolioCompany) {
    return null;
  }

  return (
    <>
      <h5 hidden={hideOrganizationName}>{selectedVCPortfolioCompany.business.name}</h5>
      <Row className="mt-16">
        <Col>
          <p className="font-weight-bold mb-0">Fund Name</p>
          <p>{selectedVCPortfolioCompany.fund.fundName}</p>
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Funding Status</p>
          <p>{selectedVCPortfolioCompany.status}</p>
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Approved Amount</p>
          <p>{selectedVCPortfolioCompany.approvedAmount?.toLocaleString('en-US', dateOptions.currencyUSNoDecimal)}</p>
        </Col>
      </Row>
    </>
  );
};

export default FundInformation;
