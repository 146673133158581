import userSignal from 'signals/User.signal';
import businessSearchResultsSignal from 'signals/BusinessSearchResults.signal';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import history from '../../../../history';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    OR: [
      {
        ventureCapitalFirm: {},
      },
      {
        lender: {},
      },
    ],
  };

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.id) {
    if (params.id.includes(',')) {
      where.id = { in: params.id.split(',').map((id) => Number(id.trim())) };
    } else {
      where.id = { in: [Number(params.id)] };
    }
  }

  if (params.programName) {
    where.fundingProgramId = { in: [Number(params.programName)] };
  }

  if (params.lenderType) {
    where.OR[0].ventureCapitalFirm.lenderType = params.lenderType;
    where.OR[1].lender.lenderType = params.lenderType;
  }

  if (params.requiresBusinessPlan) {
    where.OR[0].ventureCapitalFirm.requiresBusinessPlan =
      params.requiresBusinessPlan === 'true';
    where.OR[1].lender.requiresBusinessPlan =
      params.requiresBusinessPlan === 'true';
  }

  if (params.fundsStartups) {
    where.OR[0].ventureCapitalFirm.fundsStartups =
      params.fundsStartups === 'true';
    where.OR[1].lender.fundsStartups = params.fundsStartups === 'true';
  }

  if (params.lenderName) {
    const rawId = params.lenderName;
    const id = rawId.split('-')[0];
    if (rawId.includes('lender')) {
      where.lender = {
        is: {
          id: Number(id),
        },
      };
    } else {
      where.ventureCapitalFirm = {
        is: {
          id: Number(id),
        },
      };
    }
  }

  if (Object.keys(where.OR[0].ventureCapitalFirm).length === 0) {
    delete where.OR;
  } else if (Object.keys(where.OR[0].ventureCapitalFirm).length === 0) {
    delete where.OR;
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  if (key === 'lenderName') {
    return [
      {
        lender: {
          name: dir,
        },
      },
      {
        ventureCapitalFirm: {
          name: dir,
        },
      },
    ];
  } else if (
    key === 'lenderType' ||
    key === 'fundsStartups' ||
    key === 'requiresBusinessPlan'
  ) {
    return [
      {
        lender: {
          [key]: dir,
        },
      },
      {
        ventureCapitalFirm: {
          [key]: dir,
        },
      },
    ];
  } else if (key === 'programName') {
    return [
      {
        fundingProgram: {
          name: dir,
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

export const fetchAndSetLenderData = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const [fundingProgramMemberships, businessFavorites] = await Promise.all([
      api.get({
        path: '/fundingProgramMemberships',
        options: {
          where: xFormAppliedFiltersToWhereClause(),
          orderBy: xFormSortToOrderByClause(),
          include: {
            fundingProgram: true,
            lender: {
              include: {
                fundingProgramMemberships: {
                  include: {
                    fundingProgram: true,
                  },
                },
                account: {
                  select: {
                    portalType: true,
                  },
                },
              },
            },
            ventureCapitalFirm: {
              include: {
                fundingProgramMemberships: {
                  include: {
                    fundingProgram: true,
                    funds: true,
                  },
                },
                account: {
                  select: {
                    portalType: true,
                  },
                },
              },
            },
          },
        },
      }),
      api.get({
        path: '/businessFavorites',
      }),
    ]);

    businessSearchResultsSignal.update({
      fundingProgramMemberships,
      businessFavorites,
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
  return false;
};

export function openLink(link) {
  window.open(link);
}

export function sendEmail(emailAddress) {
  window.open(`mailto:${emailAddress}`);
}

export function handleSave() {
  let newSavedCapitalOpportunities = [];
  const date = new Date();
  if (userSignal.value.savedCapitalOpportunities) {
    newSavedCapitalOpportunities = [
      ...userSignal.value.savedCapitalOpportunities,
    ];
  }

  newSavedCapitalOpportunities.push({
    ...businessSearchResultsSignal.value.currentFundingProgramMembership,
    savedDate: date,
  });
  userSignal.update({
    savedCapitalOpportunities: newSavedCapitalOpportunities,
  });

  return businessSearchResultsSignal.update({ opportunitySaved: true });
}

export function handleNavigateToDashboard() {
  return businessSearchResultsSignal.update({ modalOpen: false });
}

export function hideModal() {
  return businessSearchResultsSignal.update({ modalOpen: false });
}

export function toggleModal(currentFundingProgramMembership) {
  organizationDetailsSignal.update({
    selectedOrganization:
      currentFundingProgramMembership.lender ||
      currentFundingProgramMembership.ventureCapitalFirm,
    organizationObject: {
      fundingProgramMembershipId: currentFundingProgramMembership.id,
      lenderId: currentFundingProgramMembership.lender?.id,
      ventureCapitalFirmId:
        currentFundingProgramMembership.ventureCapitalFirm?.id,
    },
  });

  return businessSearchResultsSignal.update({
    opportunitySaved: false,
    modalOpen: true,
  });
}

export const setDropdownFilter = ({ name, value }) =>
  businessSearchResultsSignal.update({
    dropdownFilters: {
      ...businessSearchResultsSignal.value.dropdownFilters,
      [name]: value,
    },
  });

export const setAppliedFilters = (appliedFilters) =>
  businessSearchResultsSignal.update({ appliedFilters });

export const handleSortChange = (sort) =>
  businessSearchResultsSignal.update({
    sort,
  });

export const saveFinancingOpportunity = async ({
  lenderId,
  ventureCapitalFirmId,
}) => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Saving business opportunity...',
  });
  try {
    await api.post({
      path: '/businessFavorites',
      body: {
        data: {
          businessId: userAccountSignal.value.userData.account.business.id,
          ventureCapitalFirmId,
          lenderId,
        },
      },
    });
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully saved opportunity.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const resetBusinessSearchResultsFilterInputs = () =>
  businessSearchResultsSignal.update({ dropdownFilters: {} });

export const removeSavedOpportunity = async (favoriteId) => {
  try {
    loaderSignal.update({ isContentLoading: true });
    await api.delete({
      path: '/businessFavorites',
      body: {
        where: {
          id: favoriteId,
        },
      },
    });
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully unsaved opportunity.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleSaveOpportunityClickFromDetailView = async (
  organizationObj,
  organizationId,
  path
) => {
  try {
    await saveFinancingOpportunity(organizationObj);
    await fetchOrganization(organizationId, path);
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const handleRemoveSavedOpportunityClickFromDetailView = async (
  favoriteId,
  organizationId,
  path
) => {
  try {
    await removeSavedOpportunity(favoriteId);
    await fetchOrganization(organizationId, path);
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const handleSaveOpportunityClickFromModalView = async () => {
  try {
    const { organizationObject } = organizationDetailsSignal.value;
    await saveFinancingOpportunity(organizationObject);
    await fetchAndSetLenderData();
    const { fundingProgramMembershipId } = organizationObject;
    const fundingProgramMembership =
      businessSearchResultsSignal.value.fundingProgramMemberships?.find(
        (fpm) => fpm.id === fundingProgramMembershipId
      );
    if (fundingProgramMembership) {
      organizationDetailsSignal.update({
        selectedOrganization:
          fundingProgramMembership.lender ||
          fundingProgramMembership.ventureCapitalFirm,
      });
    }
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const handleRemoveSavedOpportunityClickFromModalView = async (
  favoriteId
) => {
  try {
    await removeSavedOpportunity(favoriteId);
    await fetchAndSetLenderData();
    const { organizationObject } = organizationDetailsSignal.value;
    const { fundingProgramMembershipId } = organizationObject;
    const fundingProgramMembership =
      businessSearchResultsSignal.value.fundingProgramMemberships?.find(
        (fpm) => fpm.id === fundingProgramMembershipId
      );
    if (fundingProgramMembership) {
      organizationDetailsSignal.update({
        selectedOrganization:
          fundingProgramMembership.lender ||
          fundingProgramMembership.ventureCapitalFirm,
      });
    }
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const handleSaveOpportunityFromSearchResults = async (
  organizationIds
) => {
  try {
    await saveFinancingOpportunity(organizationIds);
    await fetchAndSetLenderData();
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleRemoveSavedOpportunityFromSearchResults = async (
  favoriteId
) => {
  try {
    await removeSavedOpportunity(favoriteId);
    await fetchAndSetLenderData();
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};
