import alertSignal from "signals/Alert.signal";
import api from "api/api";
import loaderSignal from "signals/Loader.signal";
import history from "../../../../../history";
import edoPerformanceLoansSignal from "./EdoPerformanceLoans.signals";
import userAccountSignal from "../../../../../signals/UserAccount.signal";

export const fetchAndSetApplications = async () => {
  const pathname = history.location.pathname;
  const split = pathname.split('/');
  const fundingProgramId = Number(split[split.length - 1]);
  loaderSignal.update({ isContentLoading: true });
  try {
    const [fundingProgram, applications] = await Promise.all([
      api.get({
        path: '/fundingPrograms',
        options: {
          where: {
            id: fundingProgramId,
          },
          include: {
            stats: true,
          },
        },
      }),
      api.get({
        path: '/applications',
        options: {
          where: {
            status: 'APPROVED',
            fundingProgramMembership: {
              is: {
                fundingProgram: {
                  is: {
                    id: fundingProgramId,
                    edoId: userAccountSignal.value.userData.account.edo.id,
                  },
                },
              },
            },
          },
          orderBy: [{ id: 'desc' }],
          include: {
            stats: true,
            business: true,
            fundingProgramMembership: {
              include: {
                lender: true,
                fundingProgram: true,
              },
            },
            disbursements: {
              include: {
                transactions: true,
              },
            },
          },
        },
      }),
    ]);

    return edoPerformanceLoansSignal.update({ applications, fundingProgram });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleDownloadLoansPerformanceProgramData = async () => {
  const { fundingProgram } = edoPerformanceLoansSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });
    await api.get({
      path: '/applications/stats/download',
      options: {
        where: {
          fundingProgramMembership: {
            is: {
              fundingProgram: {
                is: {
                  id: fundingProgram.id,
                  edoId: userAccountSignal.value.userData.account.edo.id,
                },
              },
            },
          },
        },
        orderBy: [{ id: 'desc' }],
        include: {
          stats: true,
          business: true,
          fundingProgramMembership: {
            include: {
              lender: true,
              fundingProgram: true,
            },
          },
          disbursements: {
            include: {
              transactions: true,
            },
          },
        },
      },
      isFileDownload: true,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};
