import { Button, Col, Dropdown, Form, Modal, Row, Table } from 'react-bootstrap';
import platformsSignal, {
  editPlatformTranchesSignal,
} from '../Platforms.signal';
import {
  handleAddTranch,
  handleEditTrancheSubmit,
  handleNewTrancheChange,
  handleRemoveTrancheSubmit,
  handleTrancheAmountChange,
  handleTrancheDateChange,
  toggleEditTranche,
  toggleRemoveTrancheConfirmModal,
  validateTrancheEditIsWithinGracePeriod,
} from '../Platforms.helpers';
import dateOptions from 'components/global/Constant/dateOptions';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import { getConfig } from 'config/config';
import $appSettings from 'signals/AppSettings.signal';

const EditPlatformTranchesTab = () => {
  const {
    tranches,
    newTranche,
    editTrancheId,
    removeTrancheConfirmModalVisible,
  } = editPlatformTranchesSignal.value;
  const { TRANCHE_GRACE_PERIODS } = $appSettings.value.constants;
  const config = getConfig('APP_CONFIG');
  const appId: string = config.appId;
  const gracePeriod = (TRANCHE_GRACE_PERIODS as Record<string, number>)[appId] || TRANCHE_GRACE_PERIODS.dev;

  const { platformBeingEdited } = platformsSignal.value;

  // @ts-ignore
  return (
    <>
      <h5 className="ml-16 ml-lg-64 mt-32">Create a New Tranche</h5>
      <p className="px-16 px-lg-64 mt-16">
        <b>Remaining funds</b>: $
        {platformBeingEdited?.stats.remaining.toLocaleString()}
      </p>
      <Row className="my-3 px-16 px-lg-64">
        <Col xs={12} lg>
          <Form.Group className="form-group mb-0">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              value={newTranche.amount.toLocaleString(
                //@ts-ignore
                'en-US',
                //@ts-ignore
                dateOptions.currencyUSNoDecimal
              )}
              name="amount"
              onChange={(e) =>
                handleNewTrancheChange(e.target.name, e.target.value)
              }
            />
          </Form.Group>
        </Col>
        <Col className="mt-16 mt-lg-0">
          {/* guarantees same height
          <p>
            <b>Remaining funds</b>: $
            {platformBeingEdited?.stats.remaining.toLocaleString()}
          </p> */}
          <Form.Group className="form-group mb-0">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={newTranche.date}
              name="date"
              onChange={(e) =>
                handleNewTrancheChange(e.target.name, e.target.value)
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="px-32 px-lg-80 justify-content-end">
        <Button
          disabled={loaderSignal.value.isContentLoading}
          variant="outline-primary"
          className="rounded"
          onClick={() => handleAddTranch()}
        >
          Save Tranche
        </Button>
      </Row>
      <Row className="mt-16 px-16 px-lg-64">
        <Col>
        <p className="small">* Any modifications must be done within {gracePeriod} hours after creation</p>
          <Table responsive>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Date</th>
                <th> </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {tranches.map((tranche) => {
                const remainingTimeToModify = validateTrancheEditIsWithinGracePeriod(tranche.createdDate);
                if (editTrancheId === tranche.id) {
                  return (
                    <tr key={tranche.id}>
                      <td>
                        <Form.Group className="form-group mb-0">
                          <Form.Control
                            value={tranche.amount}
                            onChange={(e) =>
                              handleTrancheAmountChange(
                                e.target.value,
                                tranche.id
                              )
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="form-group mb-0">
                          <Form.Control
                            type="date"
                            disabled={true}
                            value={
                              new Date(tranche.date).toISOString().split('T')[0]
                            }
                            onChange={(e) =>
                              handleTrancheDateChange(
                                e.target.value,
                                tranche.id
                              )
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Button
                          className="rounded mr-8"
                          size="sm"
                          variant="outline-primary"
                          onClick={handleEditTrancheSubmit}
                          disabled={!remainingTimeToModify}
                        >
                          Save Changes
                        </Button>
                        <Button
                          className="rounded"
                          size="sm"
                          variant="outline-secondary"
                          onClick={() => toggleEditTranche()}
                          disabled={!remainingTimeToModify}
                        >
                          Cancel
                        </Button>
                      </td>
                      <td>{remainingTimeToModify ? `${remainingTimeToModify} left to edit` : ""}</td>
                    </tr>
                  );
                }
                return (
                  <tr key={tranche.id}>
                    <td>
                      {Number(tranche.amount).toLocaleString(
                        'en-US',
                        dateOptions.currencyUS
                      )}
                    </td>
                    <td>
                      {new Date(tranche.date).toLocaleDateString('en-US', {
                        timeZone: 'UTC',
                      })}
                    </td>
                    <td align="right">
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-primary" size="sm">Actions</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => toggleEditTranche(tranche.id)}
                            disabled={!remainingTimeToModify}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              toggleRemoveTrancheConfirmModal(tranche.id)
                            }
                            disabled={!remainingTimeToModify}
                          >
                            Remove
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>{remainingTimeToModify ? `${remainingTimeToModify} hours left to edit` : ""}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ModalWrapper
        centered
        show={removeTrancheConfirmModalVisible}
        onHide={toggleRemoveTrancheConfirmModal}
        className="upper-modal"
        backdropClassName="upper-modal-backdrop"
        onExit={null}
        onExited={null}
        size={null}
      >
        <Modal.Header className="justify-content-end pb-0 border-bottom-0">
          <CloseModalButton
            onCloseModal={() => toggleRemoveTrancheConfirmModal()}
          />
        </Modal.Header>
        <Modal.Body className="pt-0">
          <p>Are you sure you want to remove this tranche?</p>
          <Row className="px-16 justify-content-end">
            <Button
              className="rounded mr-8"
              variant="outline-secondary"
              size="sm"
              onClick={() => toggleRemoveTrancheConfirmModal()}
            >
              Cancel
            </Button>
            <Button
              className="rounded"
              size="sm"
              onClick={handleRemoveTrancheSubmit}
            >
              Confirm
            </Button>
          </Row>
        </Modal.Body>
      </ModalWrapper>
    </>
  );
};

export default EditPlatformTranchesTab;
