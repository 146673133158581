import AddNewProgramImport from 'components/views/Edo/EdoAddProgram';
import $appSettings from 'signals/AppSettings.signal';

const AdminAddProgram = () => {
const { PORTAL_TYPES } = $appSettings.value.constants;

return <AddNewProgramImport portalType={PORTAL_TYPES.state} />
};

export default AdminAddProgram;
