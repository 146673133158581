import Signal from 'libs/signals';
import {
  Application,
  Business,
  BusinessDemographics,
  FundingProgram,
  ProgramType,
} from '../types';

type SubmitApplicationSignal = {
  business: {
    business: Business;
    demographics: BusinessDemographics;
  } | null;
  businessEin: string;
  lenderEin: string;
  askedAmount: string;
  files: any[];
  fundingProgramId: string;
  fundingPrograms: FundingProgram[];
  fundingProgram: FundingProgram | null;
  filesMap: Record<string, { file?: File; signedUrl: string | null }>;
  additionalFilesList: {
    name: string;
    file?: File;
    signedUrl: string | null;
  }[];
  referenceProgramId: string;
  programTypeId: string;
  programTypes: ProgramType[];
  step: number;
  applicationDocumentType: string;
  applicationDocumentTypes: any[];
  note: string;
  documentIsBeingRead: boolean;
  ocrModalVisible: boolean;
  initialStatus: string;
  createdApplication: Application | null;
  ocrFile: File | null;
  ocrFileSignedUrl: string;
  ocrFileType: string;
  ocrFileName: string;
  backingUpAdditionalFiles: boolean;
  couldNotProcessOcrAlertVisible: boolean;
  isUsingOcr: boolean;
  applicationFormWarningModalVisible: boolean;
};

const submitApplicationSignal = Signal<SubmitApplicationSignal>({
  business: null,
  businessEin: '',
  lenderEin: '',
  askedAmount: '',
  files: [],
  fundingProgramId: '-1',
  fundingPrograms: [],
  fundingProgram: null,
  filesMap: {},
  additionalFilesList: [],
  referenceProgramId: '-1',
  programTypeId: '-1',
  programTypes: [],
  step: 1,
  applicationDocumentType: '',
  applicationDocumentTypes: [],
  note: '',
  documentIsBeingRead: false,
  ocrModalVisible: false,
  initialStatus: 'PENDING',
  createdApplication: null,
  ocrFile: null,
  ocrFileSignedUrl: '',
  ocrFileType: '',
  ocrFileName: '',
  backingUpAdditionalFiles: false,
  couldNotProcessOcrAlertVisible: false,
  isUsingOcr: true,
  applicationFormWarningModalVisible: false,
});

export const applicationDraftSignal = Signal({
  applicationDrafts: [],
  selectedDraft: {},
  showDraftModal: false,
  draftModalState: 'chooseDraft',
  removeAccessModalVisible: false,
  removeAccessUser: null,
  draftPendingRequest: {},
});

export const submitApplicationErrorsSignal = Signal({
  businessEin: false,
  lenderEin: false,
  businessEinDirty: false,
  lenderEinDirty: false,
});

type SubmitApplicationBusinessSummarySignal = {
  name: string;
  ein: string;
  industry: string;
  address: string;
  type: string;
  details: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  demographics: {
    isWomanOwned: boolean;
    isMinorityOwned: boolean;
    isVeteranOwned: boolean;
    ethnicityPreferNotToRespond: boolean;
    ethnicityHispanicOrLatino: boolean;
    racePreferNotToRespond: boolean;
    raceAmericanIndianOrAlaskaNative: boolean;
    raceBlackOrAfricanAmerican: boolean;
    raceAsianChinese: boolean;
    raceAsianFilipino: boolean;
    raceAsianIndian: boolean;
    raceAsianJapanese: boolean;
    raceAsianKorean: boolean;
    raceAsianVietnamese: boolean;
    raceAsianOther: boolean;
    raceGuamanianOrChamorro: boolean;
    raceNativeHawaiian: boolean;
    raceSamoan: boolean;
    racePacificIslanderOther: boolean;
    raceWhite: boolean;
    // menaf => middle eastern or north african ancestry
    menafPreferNotToRespond: boolean;
    menaf: boolean;
    genderPreferNotToRespond: boolean;
    genderFemale: boolean;
    genderMale: boolean;
    genderNonBinary: boolean;
    genderSelfDescribed: string | null;
    sexualOrientationPreferNotToRespond: boolean;
    sexualOrientationBisexual: boolean;
    sexualOrientationLesbianOrGay: boolean;
    sexualOrientationStraight: boolean;
    sexualOrientationOther: boolean;
    veteranStatusPreferNotToRespond: boolean;
    veteranStatus: boolean;
  };
};

type lenderSubmitApplicationLenderSummarySignal = {
  name: string;
  phoneNumber: string;
  email: string;
  contactName: string;
};

export const submitApplicationBusinessSummarySignal =
  Signal<SubmitApplicationBusinessSummarySignal | null>(null);

export const submitApplicationLenderSummarySignal =
  Signal<lenderSubmitApplicationLenderSummarySignal | null>(null);

export default submitApplicationSignal;
