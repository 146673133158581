import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Modal } from 'react-bootstrap';
import { $edoCustomize } from '../edoCustomize.signal';
import { setAllToDefault, submitWhiteLabelChanges } from '../edoCustomize.helpers';

const ResetToDefaultModal = ({ fileInputRef }) => {
  const { modalVisible } = $edoCustomize.value;

  return (
    <ModalWrapper
      show={modalVisible === 'resetToDefault'}
      centered
      onHide={() => $edoCustomize.update({ modalVisible: null })}
    >
      <Modal.Header>
        <h5>Reset to Default</h5>
        <CloseModalButton
          onCloseModal={() => $edoCustomize.update({ modalVisible: null })}
        />
      </Modal.Header>
      <Modal.Body>
        All customization options will be reset to default. This change will
        automaticallty be saved. Are you sure you want to proceed?
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="mr-16"
          variant="outline-secondary"
          onClick={() => $edoCustomize.update({ modalVisible: null })}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setAllToDefault();
            submitWhiteLabelChanges();
            fileInputRef.current.value = null;
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ResetToDefaultModal;
