import { selectedFundingProgramSignal } from "signals/Program.signal";
import {
  fetchAndSetEnrolledOrganizations,
  handleEnrolledOrganizationsAppliedFiltersChange,
  handleEnrolledOrganizationsFilterChange,
  handleEnrolledOrganizationsSortChange,
  handleOrganizationDetailClick,
  resetEnrolledOrganizationsFilterInputs
} from "../EdoProgramDetail.helpers";
import OrganizationDetailsModal from "components/global/OrganizationsList/components/OrganizationDetailsModal";
import SimpleTable from "components/global/SimpleTable";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { Button } from "react-bootstrap";
import { showOrganizationDetailsModal } from "components/global/OrganizationsList/OrganizationsList.helpers";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'contactName',
    title: 'Contact Name',
    sortable: true,
  },
  {
    key: 'email',
    title: 'Contact Email',
    sortable: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FILTERS = [
  {
    type: 'integer',
    label: 'ID',
    key: 'id',
  },
  {
    type: 'text',
    label: 'Name',
    key: 'name',
  },
  {
    type: 'text',
    label: 'Contact Name',
    key: 'contactName',
  },
  {
    type: 'text',
    label: 'Contact Email',
    key: 'email',
  },
];

const EnrolledOrganizationsList = ({ modalView }) => {
  const { enrolledOrganizations, dropdownFilters, sort } =
    selectedFundingProgramSignal.value;

  return (
    <>
      <OrganizationDetailsModal showLinkToPrograms={false} />
      <div className="d-flex justify-content-between mb-16">
        <h5 className="text-secondary">Enrolled Organizations</h5>
        {!modalView && (
          <SimpleTableFilterDropdown
            filtersState={dropdownFilters}
            onChange={({ name, value }) =>
              handleEnrolledOrganizationsFilterChange(name, value)
            }
            onApply={(filters) => {
              handleEnrolledOrganizationsAppliedFiltersChange(filters);
              fetchAndSetEnrolledOrganizations();
            }}
            filters={FILTERS}
            onClearFilterInputs={resetEnrolledOrganizationsFilterInputs}
          />
        )}
      </div>
      <SimpleTable
        headers={HEADERS}
        sort={sort}
        onClick={
          modalView
            ? null
            : (index) => {
                const record = enrolledOrganizations[index];
                showOrganizationDetailsModal(record);
              }
        }
        onSort={(sort) => {
          handleEnrolledOrganizationsSortChange(sort);
          fetchAndSetEnrolledOrganizations();
        }}
        rows={enrolledOrganizations.map((organization) => ({
          id: organization.id,
          organizationName: organization.name,
          contactName: organization.contactName,
          contactEmail: organization.email,
          action: ({ key }) => (
            <td align="right" key={key}>
              <Button
                size="sm"
                variant="outline-dark"
                className="rounded"
                onClick={() =>
                  handleOrganizationDetailClick(organization, modalView)
                }
              >
                Detail
              </Button>
            </td>
          ),
        }))}
      />
    </>
  );
};

export default EnrolledOrganizationsList;
