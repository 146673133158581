import Signal from "libs/signals";

const $capitalCallPortion = Signal({
  capitalCallPortion: null,
  modalVisible: null,
  subsequentPrivateFinancing: 0,
  ssbciFundsLost: 0,
  equityInvestmentGains: 0,
  note: '',
});

export default $capitalCallPortion;
