import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import userInvitationsSignal from './UserInvitations.signal';
import loaderSignal from 'signals/Loader.signal';
import history from '../../../history';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetUserInvites = async () => {
  loaderSignal.update({ isContentLoading: true });
  const include = {
    toAccount: {
      include: {
        edo: true,
        business: true,
        lender: true,
        ventureCapitalFirm: true,
      },
    },
  };
  try {
    const userInvitations = await api.get({
      path: '/userInvites',
      options: {
        orderBy: xFormSortToOrderByClause(),
        include,
        where: xFormAppliedFiltersToWhereClause(),
      },
    });

    return userInvitationsSignal.update({ userInvitations });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: 'Unable to fetch user Invites',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return [{ id: 'desc' }];
  }

  const [key, dir] = sort.split('-');

  if (key === 'name') {
    return { lastName: dir };
  } else {
    return { [key]: dir };
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const { id: accountId } = userAccountSignal.value.userData.account;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const where = {
    inviter: {
      accountId,
    },
    NOT: {
      status: 'REQUEST',
    },
  };

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.firstName) {
    where.firstName = { contains: params.firstName, mode: 'insensitive' };
  }

  if (params.lastName) {
    where.lastName = { contains: params.lastName, mode: 'insensitive' };
  }

  if (params.email) {
    where.email = { contains: params.email, mode: 'insensitive' };
  }

  if (params.status) {
    where.status = params.status;
  }

  // fixed values for these screens
  if (history.location.pathname.includes('/edo/vc')) {
    where.portal = PORTAL_TYPES.vc;
  } else if (history.location.pathname.includes('/edo/lenders')) {
    where.portal = PORTAL_TYPES.lender;
  } else if (history.location.pathname.includes('/edo/business')) {
    where.portal = PORTAL_TYPES.business;
  } else if (
    !params.portal &&
    history.location.pathname.includes('/vc/users')
  ) {
    where.portal = {
      in: [PORTAL_TYPES.business, PORTAL_TYPES.vc],
    };
  } else if (
    !params.portal &&
    history.location.pathname.includes('/lender/users')
  ) {
    where.portal = {
      in: [PORTAL_TYPES.business, PORTAL_TYPES.lender],
    };
  } else if (
    !params.portal &&
    history.location.pathname.includes('/business/users')
  ) {
    where.portal = PORTAL_TYPES.business;
  } else if (params.portal) {
    where.portal = params.portal;
  }

  if (params.organizationName) {
    where.organizationName = {
      contains: params.organizationName,
      mode: 'insensitive',
    };
  }

  return where;
};

export const resetUserInviteFilterInputs = () =>
  userInvitationsSignal.update({ dropdownFilters: {} });
