export const formatMoney = (num, currency = '$') => {
  if (isNaN(num)) {
    return '-'
  }
  return `${currency}${Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
}

export const formatCurrencyWithCentsInput = value => {
  if (typeof value === 'number') {
    value = value.toFixed(2);
  } else if (typeof value !== 'string') {
    value = value.toString();
  }

  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `$${value}`;
};

export default formatMoney;
