import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import { isLetterSyllable } from 'utils/formatTxt';
import { exitAndReset, reset } from '../StateMigrate.helpers';
import $appSettings from 'signals/AppSettings.signal';

const AdminMigrateSuccessPage = ({ portalType }) => {
  const { program, state } = AdminMigrateSignal.value[portalType].form;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const history = useHistory();

  return (
    <Container className="py-64">
      <Row>
        <Col sm={2}>
          <FontAwesomeIcon className="h1" icon={faThumbsUp} />
        </Col>
        <Col>
          {
            portalType === PORTAL_TYPES.state
              ? (
                <>
                  <p>You&apos;ve uploaded a{isLetterSyllable(program.title.charAt(0)) && 'n'} {program.title} application for {state}.</p>
                  <p>
                    Click the button below to upload more, otherwise select exit.
                  </p>
                </>
              ) : (
                <>
                  <p>You&apos;ve uploaded a{isLetterSyllable(program.title.charAt(0)) && 'n'} {program.title} application.</p>
                  <p>
                    Click the button below to upload more, otherwise select exit.
                  </p>
                </>
              )
          }
        </Col>
      </Row>
      <Row className="mt-64 mb-32 justify-content-center">
        <Col className="d-flex justify-content-center">
          <Button onClick={reset}>Upload More</Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <Button onClick={() => exitAndReset(history)}>Exit</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminMigrateSuccessPage;
