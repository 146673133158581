import { Button, Modal } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import stateBillingSignal from 'signals/StateBilling.signal';
import { deleteAccount } from '../StateBilling.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';

const DeleteAccountModal = ({ show, onToggleModal }) => {
  const { selectedAccount } = stateBillingSignal.value;

  const handleDeleteClick = async () => {
    onToggleModal();
    await deleteAccount(selectedAccount.id);
  };

  return (
    <ModalWrapper centered className="border border-dark rounded" show={show} onHide={() => onToggleModal()}>
      <Modal.Header className="pb-0 pl-32 bg-light border-0" closeLabel="Close">
        <h5>Delete EDO</h5>
        <CloseModalButton onToggleModal={onToggleModal} />
      </Modal.Header>
      <Modal.Body className="bg-light rounded pt-0 px-32">
        <p>Are you sure you want to delete the {selectedAccount.accountName} account?</p>
      </Modal.Body>
      <Modal.Footer className="border-0 bg-light">
        <Button variant="secondary rounded" onClick={onToggleModal}>
          Cancel
        </Button>
        <Button variant="danger rounded" onClick={handleDeleteClick}>
          Delete Account
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default DeleteAccountModal;
