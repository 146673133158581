import { useCallback, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  lenderSettingsAddressSearchSignal,
  lenderSettingsBusinessNewIndustrySignal,
  lenderSettingsIndustryDataSignal,
  lenderSettingsOrganizationInfoErrorsSignal,
  lenderSettingsOrganizationInfoSignal,
} from 'signals/LenderSettingsUsers.signal';
import VALIDATION_MESSAGES from 'components/global/Constant/validationMessages';
import $appSettings from 'signals/AppSettings.signal';
import { SEEKING_FUNDING_PAYMENT_RANGES } from 'components/global/Constant/constants';
import {
  fetchAndSetLender,
  getAddressOptions,
  handleAddNewIndustryTag,
  handleAddressBlur,
  handleAddressSearchChange,
  handleAddressSearchFocus,
  handleInputBlur,
  handleNewIndustryChange,
  handleOrganizationInfoChange,
  handleOrganizationInfoIndustrySelectionChange,
  handleOrganizationInfoSubmit,
  handleSelectAddress,
} from './lenderOrganizationInformation.helpers';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import Field from 'components/global/Field/Field';

const LenderOrganizationInfo = () => {
  const { PROVIDER_TYPES } = $appSettings.value.constants;
  const organizationInfo = lenderSettingsOrganizationInfoSignal.value;
  const organizationInfoErrors =
    lenderSettingsOrganizationInfoErrorsSignal.value;
  const { addressSearch, addressOptions } =
    lenderSettingsAddressSearchSignal.value;

  const init = useCallback(async () => {
    await fetchAndSetLender();
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getAddressOptions();
  }, [addressSearch]);

  return (
    <div className="mb-32">
      <div className="border border-gray shadow-sm bg-white p-16">
        <h3>Organization Information</h3>
        <hr />
        <Form onSubmit={handleOrganizationInfoSubmit}>
          <Row>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="name">
                  Organization Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  aria-describedby="name"
                  value={organizationInfo.name}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="ein">
                  Organization EIN
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="ein"
                  name="ein"
                  aria-describedby="ein"
                  value={organizationInfo.ein}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                  isInvalid={organizationInfoErrors.ein}
                  onBlur={handleInputBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {VALIDATION_MESSAGES.ein}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="regulatoryId">
                  Organization Regulatory ID
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="regulatoryId"
                  name="regulatoryId"
                  aria-describedby="regulatoryId"
                  value={organizationInfo.regulatoryId}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                  isInvalid={organizationInfoErrors.regulatoryId}
                />
                <Form.Control.Feedback type="invalid">
                  {VALIDATION_MESSAGES.regulatoryId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="otherOrganizationId"
                >
                  Other Organization ID
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="otherOrganizationId"
                  name="otherOrganizationId"
                  aria-describedby="otherOrganizationId"
                  value={organizationInfo.otherOrganizationId}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3} xl={3}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="lenderType" className="font-weight-bold">
                  Organization Type
                </Form.Label>
                <br />
                <Form.Select
                  required
                  className="form-control"
                  type="text"
                  id="lenderType"
                  name="lenderType"
                  value={organizationInfo.lenderType}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                >
                  <option value={''}>Select a type</option>
                  {Object.values(PROVIDER_TYPES).map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.annualReportLabel}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="otherLenderType"
                >
                  Other Organization Type
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="otherLenderType"
                  name="otherLenderType"
                  aria-describedby="otherLenderType"
                  value={organizationInfo.otherLenderType}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="contactName">
                  Contact Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="contactName"
                  name="contactName"
                  aria-describedby="contactName"
                  value={organizationInfo.contactName}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="email">
                  Organization Email
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="email"
                  isInvalid={organizationInfoErrors.email}
                  value={organizationInfo.email}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="phoneNumber">
                  Organization Phone Number
                </Form.Label>
                <PhoneInput
                  international={false}
                  addInternationalOption={false}
                  countries={['US']}
                  id="phoneNumber"
                  numberInputProps={{
                    className: `form-control${
                      organizationInfoErrors.phoneNumber ? ' border-danger' : ''
                    }`,
                    name: 'phoneNumber',
                  }}
                  country="US"
                  defaultCountry="US"
                  className="border-danger"
                  value={
                    organizationInfo?.phoneNumber
                      ? formatPhoneNumber(organizationInfo?.phoneNumber)
                      : ''
                  }
                  countryCallingCodeEditable={false}
                  limitMaxLength
                  onChange={(value) =>
                    handleOrganizationInfoChange({
                      target: { name: 'phoneNumber', value },
                    })
                  }
                  onBlur={() =>
                    handleInputBlur({
                      target: {
                        name: 'phoneNumber',
                        value: organizationInfo.phoneNumber,
                      },
                    })
                  }
                />
                <small
                  className="text-danger"
                  hidden={!organizationInfoErrors.phoneNumber}
                >
                  Please enter a valid phone number.
                </small>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="website">
                  Organization Website
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="website"
                  name="website"
                  aria-describedby="website"
                  value={organizationInfo.website}
                  isInvalid={organizationInfoErrors.website}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                  onBlur={handleInputBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {VALIDATION_MESSAGES.website}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="description">
                  Organization Description
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  aria-describedby="description"
                  value={organizationInfo.description}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Field.AutoComplete
                  label="Full Address"
                  labelClassName="font-weight-bold"
                  options={addressOptions}
                  optionFormatter={(option) => option.formattedAddress}
                  onChange={(e) => handleAddressSearchChange(e.target.value)}
                  onSelect={(e) => handleSelectAddress(e)}
                  value={
                    organizationInfo?.address?.formattedAddress || addressSearch
                  }
                  name="address"
                  isInvalid={organizationInfoErrors.address}
                  onBlur={handleAddressBlur}
                  onFocus={handleAddressSearchFocus}
                />
                <small
                  className="text-danger"
                  hidden={!organizationInfoErrors.address}
                >
                  Please select a valid address from the drop down.
                </small>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-16">
            <Col sm={6} xl={4}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="loanRangeMin">
                  Loan Range Min
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  className="form-control"
                  id="loanRangeMin"
                  name="loanRangeMin"
                  aria-describedby="loanRangeMin"
                  value={organizationInfo.loanRangeMin}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={4}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="loanRangeMax">
                  Loan Range Max
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  className="form-control"
                  id="loanRangeMax"
                  name="loanRangeMax"
                  aria-describedby="loanRangeMax"
                  value={organizationInfo.loanRangeMax}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                  isInvalid={organizationInfoErrors.seekingRange}
                />
                <Form.Control.Feedback type="invalid">
                  Seeking max must be greater than seeking min.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={3} xl={4}>
              <Form.Group className="form-group">
                <Form.Label
                  htmlFor="downPaymentRange"
                  className="font-weight-bold"
                >
                  Down Payment Range
                </Form.Label>
                <br />
                <Form.Select
                  required
                  className="form-control"
                  type="text"
                  id="downPaymentRange"
                  name="downPaymentRange"
                  value={organizationInfo.downPaymentRange}
                  onChange={(e) => handleOrganizationInfoChange(e)}
                >
                  {SEEKING_FUNDING_PAYMENT_RANGES.map((downPaymentRange) => (
                    <option key={downPaymentRange} value={downPaymentRange}>
                      {downPaymentRange}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xl={3}>
              <Form.Group className="mt-8">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="fundsStartups"
                >
                  Do you fund Startups?
                </Form.Label>
                <br />
                <Form.Check
                  required
                  inline
                  checked={organizationInfo.fundsStartups === true}
                  label="Yes"
                  name="fundsStartups"
                  type="radio"
                  id="fundsStartupsYes"
                  value="yes"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Check
                  required
                  inline
                  checked={organizationInfo.fundsStartups === false}
                  label="No"
                  name="fundsStartups"
                  type="radio"
                  id="fundsStartupsNo"
                  value="no"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="mt-8">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="requiresBusinessPlan"
                >
                  Do you require a business plan?
                </Form.Label>
                <br />
                <Form.Check
                  required
                  inline
                  checked={organizationInfo.requiresBusinessPlan === true}
                  label="Yes"
                  name="requiresBusinessPlan"
                  type="radio"
                  id="requiresBusinessPlanYes"
                  value="yes"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Check
                  required
                  inline
                  checked={organizationInfo.requiresBusinessPlan === false}
                  label="No"
                  name="requiresBusinessPlan"
                  type="radio"
                  id="requiresBusinessPlanNo"
                  value="no"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="mt-8">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="minorityDepositoryInstitution"
                >
                  Minority Depository Institution?
                </Form.Label>
                <br />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.minorityDepositoryInstitution === true
                  }
                  label="Yes"
                  name="minorityDepositoryInstitution"
                  type="radio"
                  id="minorityDepositoryInstitutionYes"
                  value="yes"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.minorityDepositoryInstitution === false
                  }
                  label="No"
                  name="minorityDepositoryInstitution"
                  type="radio"
                  id="minorityDepositoryInstitutionNo"
                  value="no"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xl={3}>
              <Form.Group className="mt-8">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="fundAsSourceOfPrivateCapital"
                >
                  Fund as Source of Private Capital?
                </Form.Label>
                <br />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.fundAsSourceOfPrivateCapital === true
                  }
                  label="Yes"
                  name="fundAsSourceOfPrivateCapital"
                  type="radio"
                  id="fundAsSourceOfPrivateCapitalYes"
                  value="yes"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.fundAsSourceOfPrivateCapital === false
                  }
                  label="No"
                  name="fundAsSourceOfPrivateCapital"
                  type="radio"
                  id="fundAsSourceOfPrivateCapitalNo"
                  value="no"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="mt-8">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="lenderAsSourceOfPrivateCapital"
                >
                  Lender as Source of Private Capital?
                </Form.Label>
                <br />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.lenderAsSourceOfPrivateCapital === true
                  }
                  label="Yes"
                  name="lenderAsSourceOfPrivateCapital"
                  type="radio"
                  id="lenderAsSourceOfPrivateCapitalYes"
                  value="yes"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.lenderAsSourceOfPrivateCapital === false
                  }
                  label="No"
                  name="lenderAsSourceOfPrivateCapital"
                  type="radio"
                  id="lenderAsSourceOfPrivateCapitalNo"
                  value="no"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group className="mt-8">
                <Form.Label
                  className="font-weight-bold"
                  htmlFor="incubationAndEarlyStageInvestmentCallOptions"
                >
                  Incubation and Early-Stage Investment Call Options?
                </Form.Label>
                <br />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.incubationAndEarlyStageInvestmentCallOptions ===
                    true
                  }
                  label="Yes"
                  name="incubationAndEarlyStageInvestmentCallOptions"
                  type="radio"
                  id="incubationAndEarlyStageInvestmentCallOptionsYes"
                  value="yes"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
                <Form.Check
                  required
                  inline
                  checked={
                    organizationInfo.incubationAndEarlyStageInvestmentCallOptions ===
                    false
                  }
                  label="No"
                  name="incubationAndEarlyStageInvestmentCallOptions"
                  type="radio"
                  id="incubationAndEarlyStageInvestmentCallOptionsNo"
                  value="no"
                  onChange={(e) => handleOrganizationInfoChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="font-weight-bold mb-0">Industry(s)</p>
              <small
                className="text-danger"
                hidden={!organizationInfoErrors.industrySelection}
              >
                Please select at least one
              </small>
              <Row className="px-8">
                {lenderSettingsIndustryDataSignal.value.map((industry) => (
                  <Button
                    key={industry}
                    className={`rounded m-8 ${
                      organizationInfo.industrySelection.includes(industry)
                        ? 'btn-info'
                        : 'btn-outline-dark text-dark bg-white'
                    }`}
                    onClick={() =>
                      handleOrganizationInfoIndustrySelectionChange(industry)
                    }
                  >
                    {industry}
                  </Button>
                ))}
              </Row>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="newIndustry">
                  Add Another Industry Tag
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="newIndustry"
                  name="newIndustry"
                  value={lenderSettingsBusinessNewIndustrySignal.value}
                  onChange={(e) => handleNewIndustryChange(e)}
                />
              </Form.Group>
              <Button
                className="rounded text-bottom"
                onClick={handleAddNewIndustryTag}
              >
                Add Industry Tag
              </Button>
            </Col>
          </Row>
          <Row className="px-16 justify-content-end">
            <Button className="rounded" type="submit">
              Save
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default LenderOrganizationInfo;
