import { Container } from 'react-bootstrap';
import Content from '../Content';
import DocumentPreviewModal from '../DocumentPreviewModal';
import $appSettings from 'signals/AppSettings.signal';

const PrivateContainer = ({ children }) => {
  const { appSettings } = $appSettings.value;
  const backgroundColor = appSettings
    ? appSettings.whiteLabelConfig.bodyBackgroundColor
    : null;

  return (
    <Container
      style={{ backgroundColor: backgroundColor }}
      fluid
      className="px-0 d-flex"
    >
      <Content>{children}</Content>
      <DocumentPreviewModal />
    </Container>
  );
};

export default PrivateContainer;
