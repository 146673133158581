import api from 'api/api';
import userAccountSignal from 'signals/UserAccount.signal';
import alertSignal from 'signals/Alert.signal';
import userManagementSignal from './UserManagement.signal';
import loaderSignal from 'signals/Loader.signal';
import queryAddresses from 'utils/queryAddresses';

export const fetchAndSetUsersAndPrograms = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const [users, userProgramAccesses, fundingProgramMemberships] =
      await Promise.all([
        api.get({
          path: '/users',
          options: {
            where: {
              accountId: userAccountSignal.value.userData.accountId,
            },
            orderBy: xFormSortToOrderByClause(),
          },
        }),
        api.get({
          path: '/userProgramAccesses',
          options: {},
        }),
        api.get({
          path: '/fundingProgramMemberships',
          options: {},
        }),
      ]);

    const fundingPrograms = fundingProgramMemberships.map(
      (mpm) => mpm.fundingProgram
    );

    return userManagementSignal.update({
      fundingProgramMemberships,
      userProgramAccesses,
      users,
      fundingPrograms,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleUserManagementUserDetailFormChange = ({
  target: { name, value },
}) =>
  userManagementSignal.update({
    ...userManagementSignal.value,
    userDetailForm: {
      ...userManagementSignal.value.userDetailForm,
      [name]: value,
    },
  });

export const showUserManagementUserDetailModal = (detailModalUser) => {
  const userDetailForm = {
    firstName: detailModalUser.firstName,
    lastName: detailModalUser.lastName,
    email: detailModalUser.email,
    phoneNumber: detailModalUser.phoneNumber,
    address: detailModalUser.address,
  };

  return userManagementSignal.update({ detailModalUser, userDetailForm });
};

export const showUserManagementUserDeleteModal = (deleteModalUser) =>
  userManagementSignal.update({ deleteModalUser });

const hideUserManagementModals = () =>
  userManagementSignal.update({ detailModalUser: null, deleteModalUser: null });

export const hideUserManagementDeleteModal = () => hideUserManagementModals();
export const hideUserManagementDetailModal = () => hideUserManagementModals();

export const handleUserManagementUserUpdateSubmit = async () => {
  loaderSignal.update({ isContentLoading: true, message: 'Updating user...' });
  const { firstName, lastName, phoneNumber, address, city, state, zipCode } =
    userManagementSignal.value.userDetailForm;

  const { detailModalUser } = userManagementSignal.value;

  try {
    await api.patch({
      path: '/users',
      body: {
        where: { id: detailModalUser.id },
        data: {
          firstName,
          lastName,
          phoneNumber,
          address,
          city,
          state,
          zipCode,
        },
      },
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated user',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'alert',
      error,
      message: 'Unable to save changes',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleUserManagementUserProgramAccessChangeSubmit = async (
  data
) => {
  loaderSignal.update({ isContentLoading: true, messsage: 'Updating program access...' });
  try {
    const { id, fundingProgramMembershipId, role, userId } = data;
    if (id) {
      const body = {
        id,
      };
      await api.delete({ path: '/userProgramAccesses', body });
    } else {
      const body = {
        fundingProgramMembershipId,
        role,
        userId,
      };
      await api.put({ path: '/userProgramAccesses', body });
    }
    const userProgramAccesses = await api.get({ path: '/userProgramAccesses' });
    userManagementSignal.update({ userProgramAccesses });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated permissions to program.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'alert',
      error,
      message: 'Failed to update program permissions.',
    });
  } finally {
    loaderSignal.reset();
  }
};

const xFormSortToOrderByClause = () => {
  const sort = userManagementSignal.value.sort;

  if (!sort) {
    return undefined;
  }

  if (sort.key === 'name') {
    return [
      {
        lastName: sort.dir,
      },
    ];
  } else {
    return [{ [sort.key]: sort.dir }];
  }
};

export const handleUserSortChange = (sort) =>
  userManagementSignal.update({
    sort,
  });

export const handleAddressSearchChange = (input) => {
  userManagementSignal.update({
    userDetailForm: {
      ...userManagementSignal.value.userDetailForm,
      address: {},
    },
    addressSearch: input,
    errors: {
      ...userManagementSignal.value.errors,
      address: false,
    },
  });
};

export const handleSelectAddress = (option) => {
  userManagementSignal.update({
    userDetailForm: {
      ...userManagementSignal.value.userDetailForm,
      address: option,
    },
    addressSearch: option.formattedAddress,
    errors: {
      ...userManagementSignal.value.errors,
      address: false,
    },
  })
};

export const getAddressOptions = async () => {
  const bingAddresses = await queryAddresses(
    userManagementSignal.value.addressSearch
  );

  userManagementSignal.update({
    addressOptions: bingAddresses.map((ba) => ba),
  });
};

export const handleAddressBlur = () => {
  if (!Object.keys(userManagementSignal.value?.userDetailForm?.address)?.length) {
    userManagementSignal.update({
      errors: {
        ...userManagementSignal.value.errors,
        address: true,
      },
    });
  }
};

export const handleAddressSearchFocus = () => {
  userManagementSignal.update({
    addressSearch: userManagementSignal.value.userDetailForm?.address?.formattedAddress || userManagementSignal.value.addressSearch
  });
};
