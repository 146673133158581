import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackButton from "components/global/BackButton";
import CloseModalButton from "components/global/Constant/CloseModalButton";
import ProgramAccess from "components/global/ProgramAccess/ProgramAccess";
import { Button, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import edoUsersSignal from "signals/EdoUsers.signal";
import {
  handleAddUserModalChange,
  handleAddUserModalSubmit,
  handleInvitationProgramAccessClick,
  hideAddInternalUserModal,
  validateEmail
} from "./AddUserModal.helpers";
import addUserModalSignal from "./AddUserModal.signal";
import ModalWrapper from "components/global/ModalWrapper/ModalWrapper";
import loaderSignal from "signals/Loader.signal";
import $appSettings from "signals/AppSettings.signal";

const AddUserModal = ({ onSubmitSuccess, portal }) => {
  const {
    firstName,
    lastName,
    email,
    role,
    modalVisible,
    activeTab,
    invitationProgramAccesses,
    emailError,
  } = addUserModalSignal.value;
  const { fundingPrograms } = edoUsersSignal.value;
  const { USER_ROLES } = $appSettings.value.constants;

  return (
    <ModalWrapper
      centered
      className="border border-dark rounded"
      show={modalVisible}
      onExited={() => addUserModalSignal.reset()}
    >
      <Modal.Header className="pb-0 pl-32 border-0">
        <h5>Add User</h5>
        <CloseModalButton onCloseModal={() => hideAddInternalUserModal()} />
      </Modal.Header>
      <Form
        onSubmit={(e) =>
          handleAddUserModalSubmit(e, {
            successCallback: onSubmitSuccess,
            portal,
          })
        }
      >
        <Modal.Body className="rounded pt-0 px-32">
          <Tabs activeKey={activeTab}>
            <Tab eventKey="addUserForm">
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="firstName">
                  First Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => handleAddUserModalChange(e)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="lastName">
                  Last Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => handleAddUserModalChange(e)}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="email">Role</Form.Label>
                <Form.Select
                  required
                  type="role"
                  className="form-control"
                  id="role"
                  name="role"
                  value={role}
                  onChange={(e) => handleAddUserModalChange(e)}
                >
                  <option value="">Select Role</option>
                  <option value="ADMIN">Admin</option>
                  <option value="STAFF">Staff</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a role
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="email">Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => handleAddUserModalChange(e)}
                  isInvalid={emailError}
                  onBlur={validateEmail}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email address. Email usernames should be at least 2 characters and begin with a letter. The only special characters allowed are ._+-. There cannot be more than one occurence of the top level domain - ex. .com
                </Form.Control.Feedback>
                <small id="emailHelp" className="form-text text-muted">
                  We&apos;ll never share your email with anyone else.
                </small>
              </Form.Group>
            </Tab>
            <Tab eventKey="programAccess">
              <ProgramAccess
                displayCheck
                programAccesses={invitationProgramAccesses}
                fundingPrograms={fundingPrograms}
                onProgramAccessUpdate={handleInvitationProgramAccessClick}
              />
            </Tab>
          </Tabs>
          <Row
            className={`px-16 mt-8 ${
              role === USER_ROLES.staff
                ? 'justify-content-between'
                : 'justify-content-end'
            }`}
          >
            <Button
              variant="link"
              className="text-muted text-left px-0"
              hidden={
                role !== USER_ROLES.staff || activeTab === 'programAccess'
              }
              onClick={() =>
                addUserModalSignal.update({ activeTab: 'programAccess' })
              }
            >
              Provide Program Access <FontAwesomeIcon icon={faChevronRight} />
            </Button>
            <BackButton
              onClick={() =>
                addUserModalSignal.update({ activeTab: 'addUserForm' })
              }
              buttonText="Back"
              buttonClassName="text-left"
              hidden={activeTab === 'addUserForm'}
            />
            <div className="d-flex flex-row">
              <Button
                className="mr-8 rounded text-right"
                variant="outline-secondary"
                onClick={() => hideAddInternalUserModal()}
              >
                Close
              </Button>
              <Button
                type="submit"
                id="add-user-modal-submit-btn"
                className="rounded text-right"
                disabled={
                  role === '' ||
                  firstName === '' ||
                  lastName === '' ||
                  email === '' ||
                  loaderSignal.value.isContentLoading ||
                  emailError
                }
              >
                Send Invitation
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Form>
    </ModalWrapper>
  );
};

export default AddUserModal;
