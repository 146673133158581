import CloseModalButton from 'components/global/Constant/CloseModalButton';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import documentPreviewModalSignal from './DocumentPreviewModal.signal';
import { hideDocumentPreviewModal } from './DocumentPreviewModal.helpers';

const DocumentPreviewModal = () => {
  const { dataUrl, filename } = documentPreviewModalSignal.value;

  return (
    <Modal size="xl" show={!!dataUrl}>
      <Modal.Header className="justify-content-between border-bottom-0 pb-0">
        <h5>{filename}</h5>
        <CloseModalButton onCloseModal={hideDocumentPreviewModal} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <iframe
              src={dataUrl || ''}
              title={filename || 'File Preview'}
              width="100%"
              height={800}
            />
          </Col>
        </Row>
        <Row className="px-16 justify-content-end">
          <Button
            className="rounded"
            variant="outline-secondary"
            onClick={hideDocumentPreviewModal}
          >
            Dismiss
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DocumentPreviewModal;
