import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  ProgressBar,
  Row,
} from 'react-bootstrap';
import businessOnboardingSignal from 'signals/BusinessOnboarding.signal';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import BusinessBasicInfo from '../BusinessSettings/components/BusinessBasicInfo';
import BusinessFinance from '../BusinessSettings/components/BusinessFinance';
import BusinessAssistance from '../BusinessSettings/components/BusinessAssistance';
import BusinessDemographics from '../BusinessSettings/components/BusinessDemographics';
import { fetchAndSetBusiness } from '../BusinessSettings/BusinessSettings.helpers';
import {
  handleNext,
  handlePrevious,
  handleSectionChange,
} from './BusinessOnboarding.helpers';
import { useHistory } from 'react-router';
import BusinessMinorityStatus from '../BusinessSettings/components/BusinessMinorityStatus';

const STEPS = [
  {
    title: 'Business',
  },
  {
    title: 'SEDI & Minority Status',
  },
  {
    title: 'Finance',
  },
  {
    title: 'Assistance',
  },
  {
    title: 'Demographics',
  },
  {
    title: 'Complete',
  },
];

const BusinessOnboarding = () => {
  const [showAlert, setShowAlert] = useState(true);
  const history = useHistory();
  const { onboardingStep } = businessOnboardingSignal.value;

  const init = useCallback(() => {
    fetchAndSetBusiness();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const lastStep = STEPS.length === onboardingStep;

  return (
    <Card className="border rounded-0 p-16 mb-40">
      <Alert variant="info" className="my-24" hidden={!showAlert}>
        <Alert.Heading>
          Funding opportunities tailored to your needs.
        </Alert.Heading>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <p>
            Match with an organization sponsoring a government funding program.
          </p>
          <Button onClick={() => setShowAlert(false)} className="rounded" variant="outline-info">Dismiss</Button>
        </div>
      </Alert>
      <h3 className="text-center">Intake Assessment Form</h3>
      <hr />

      <div className="bg-light p-4">
        <div className="d-flex justify-content-between bg-light p-4">
          {STEPS.map((step, index) => (
            <Fragment key={index}>
              <div
                className="pointer"
                onClick={() => handleSectionChange(index)}
              >
                <div className={'text-center'}>
                  <h5
                    style={{
                      opacity: index === onboardingStep - 1 ? '100%' : '25%',
                    }}
                  >
                    <Badge className="px-2 py-1 text-white">{index + 1}</Badge>
                  </h5>
                  <p className="font-weight-bold text-muted">{step.title}</p>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        <ProgressBar now={(onboardingStep - 1) * 20} />
      </div>
      <Row className="mt-16">
        <Col className="offset-1" xs={10}>
          <Form>
            {onboardingStep === 1 && <BusinessBasicInfo />}
            {onboardingStep === 2 && <BusinessMinorityStatus />}
            {onboardingStep === 3 && <BusinessFinance abridged={true} />}
            {onboardingStep === 4 && <BusinessAssistance />}
            {onboardingStep === 5 && <BusinessDemographics />}
            {onboardingStep === 6 && <OnboardingCompleted />}
            <hr />
            <div className="d-flex mt-32 justify-content-between">
              <Button
                disabled={onboardingStep === 1}
                size="lg"
                onClick={(e) => handlePrevious(e)}
                variant="outline-primary"
                className="rounded"
              >
                Previous
              </Button>

              <Button
                size="lg"
                className="rounded"
                onClick={(e) => {
                  if (lastStep) {
                    history.push('/business/search/search-results');
                  } else {
                    handleNext(e);
                  }
                }}
              >
                {lastStep ? 'See Results' : 'Next'}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

const OnboardingCompleted = () => (
  <Alert variant="success" className="my-24">
    <Alert.Heading>Assessment Complete</Alert.Heading>
    <p>We can now show you relevant results tailored to your specific needs.</p>
  </Alert>
);

export default BusinessOnboarding;
