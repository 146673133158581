const validateUrl = (url) => {
  if (!url) {
    return false;
  }

  if (!url.startsWith('https://')) {
    return false;
  }

  const commonTLDs = ["com", "org", "net", "gov", "edu", "io", "co", "uk", "de", "jp"];
  const tldRegex = new RegExp(`\\.(?:${commonTLDs.join("|")})(?:/.*)?(#.*)?$`, "i");

  if (tldRegex.test(url)) {
    // Check for multiple occurrences of the TLD with a mandatory dot before it
    const tldMatches = url.match(new RegExp(`\\.(${commonTLDs.join("|")})\\b`, "ig"));
    return tldMatches && tldMatches.length === 1;
  }

  return false;
};

export default validateUrl;
