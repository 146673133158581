import {
  Alert,
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import ledgerSignal, { ledgerBorrowerPaymentSignal } from './Ledger.signals';
import {
  handleAddBorrowerPaymentChange,
  handleSubmitBorrowerPayment,
  hideSubmitBorrowerPaymentModal,
} from './Ledger.helpers';
import { getAmortizationScheduleCurrentTerm } from '../../../libs/loans';
import dateOptions from '../Constant/dateOptions';
import CloseModalButton from '../Constant/CloseModalButton';

const LedgerAddBorrowerPaymentModal = () => {
  const { submitBorrowerPaymentModalOpen, schedule } = ledgerSignal.value;
  const { amount, date } = ledgerBorrowerPaymentSignal.value;
  const currentTerm = getAmortizationScheduleCurrentTerm(schedule);

  return (
    <ModalWrapper
      show={submitBorrowerPaymentModalOpen}
      centered
      size="xl"
      onHide={hideSubmitBorrowerPaymentModal}
    >
      <ModalHeader>
        <ModalTitle>Add Borrower Payment</ModalTitle>
        <CloseModalButton onCloseModal={hideSubmitBorrowerPaymentModal} />
      </ModalHeader>
      <ModalBody>
        <Alert variant="info">
          <ul>
            <li>
              Next payment amount:{' '}
              {currentTerm?.monthlyPayment.toLocaleString(
                'en-US',
                dateOptions.currencyUS
              )}
            </li>
            <li>
              Contributing towards the Principal:{' '}
              {currentTerm &&
                (
                  currentTerm.monthlyPayment - currentTerm.interest
                ).toLocaleString('en-US', dateOptions.currencyUS)}
            </li>
            <li>
              Interest alone:{' '}
              {currentTerm?.interest.toLocaleString(
                'en-US',
                dateOptions.currencyUS
              )}
            </li>
          </ul>
        </Alert>
        <Row>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="amount"
                placeholder="Enter the amount"
                value={amount.value}
                onChange={handleAddBorrowerPaymentChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Date
              </Form.Label>
              <Form.Control
                type="date"
                value={date?.value}
                name="date"
                onChange={handleAddBorrowerPaymentChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideSubmitBorrowerPaymentModal()}
        >
          Dismiss
        </Button>
        <Button
          className="rounded"
          disabled={[amount, date].some((e) => !e)}
          onClick={() => handleSubmitBorrowerPayment()}
        >
          Submit
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default LedgerAddBorrowerPaymentModal;
