import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import ledgerSignal, { ledgerAcceptLossClaimSignal } from './Ledger.signals';
import {
  handleEvaluateLossClaimChange,
  handleSubmitAcceptLossClaim,
  hideAcceptLossClaimFormModalOpen,
} from './Ledger.helpers';
import dateOptions from '../Constant/dateOptions';
import CloseModalButton from '../Constant/CloseModalButton';

const LedgerEvaluateLossClaimFormModal = () => {
  const { acceptLossClaimFormModalOpen, application } = ledgerSignal.value;
  const lossClaim = application?.lossClaim;
  const { coveredAmount, recycledAmount } = ledgerAcceptLossClaimSignal.value;

  return (
    <ModalWrapper show={acceptLossClaimFormModalOpen} centered size="xl" onHide={hideAcceptLossClaimFormModalOpen}>
      <ModalHeader>
        <ModalTitle>Loss Claim Evaluation</ModalTitle>
        <CloseModalButton onCloseModal={hideAcceptLossClaimFormModalOpen} />
      </ModalHeader>
      <ModalBody>
        <h5>
          {application?.fundingProgramMembership.fundingProgram.name} {' - '}
          {
            application?.fundingProgramMembership.fundingProgram
              .referenceProgram.programName
          }
        </h5>
        <p>
          Confirm the amount that will be covered and enter any excess funds
          that will be recycled.
        </p>
        <Row>
          <Col xs={{ span: 6, offset: 3 }}>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Liquidation Proceeds
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="askedAmount"
                placeholder="Enter the amount"
                value={lossClaim?.liquidationProceeds.toLocaleString(
                  'en-US',
                  dateOptions.currencyUSNoDecimal
                )}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Covered Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="coveredAmount"
                placeholder="Enter the amount"
                value={Number(coveredAmount.value).toLocaleString(
                  'en-US',
                  dateOptions.currencyUSNoDecimal
                )}
                onChange={handleEvaluateLossClaimChange}
                isInvalid={!!coveredAmount.errors.length && coveredAmount.dirty}
              />
              <Form.Control.Feedback type="invalid">
                {coveredAmount.errors.length ? coveredAmount.errors[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Recycled Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="recycledAmount"
                placeholder="Enter the amount"
                value={Number(recycledAmount.value).toLocaleString(
                  'en-US',
                  dateOptions.currencyUSNoDecimal
                )}
                onChange={handleEvaluateLossClaimChange}
                isInvalid={
                  !!recycledAmount.errors.length && recycledAmount.dirty
                }
              />
              <Form.Control.Feedback type="invalid">
                {recycledAmount.errors.length ? recycledAmount.errors[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className="rounded w-100"
              disabled={recycledAmount.value === '' || !coveredAmount.value}
              onClick={() => handleSubmitAcceptLossClaim()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideAcceptLossClaimFormModalOpen()}
        >
          Dismiss
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default LedgerEvaluateLossClaimFormModal;
