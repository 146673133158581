import Signal from 'libs/signals';
import { CapitalCall, CapitalCallPortion, FundDocument } from 'types';

type CapitalCallSignal = {
  capitalCall: CapitalCall | null;
  newStatus: string;
  capitalCallTemplate: FundDocument | null;
  skippedSteps: number[];
  fallbackToAlternatesSteps: Record<string, number[]>;
  keyDates: Record<string, Date>;
  approvalChainNote: string;
  approvalChainFile: File | null;
  disbursementDate: string;
};

const $capitalCall = Signal<CapitalCallSignal>({
  capitalCall: null,
  newStatus: '',
  skippedSteps: [],
  fallbackToAlternatesSteps: {},
  keyDates: {},
  capitalCallTemplate: null,
  approvalChainNote: '',
  approvalChainFile: null,
  disbursementDate: '',
});

type CapitalCallTransactionTermsSignal = CapitalCallPortion | null;

export const $capitalCallPortionBeingEdited =
  Signal<CapitalCallTransactionTermsSignal>(null);

type CapitalCallSendFundsSignal = {
  capitalCallPortion: CapitalCallPortion | null;
  attachments: { name: string; file: File | null }[];
  note: string;
};

export const $capitalCallSendFunds = Signal<CapitalCallSendFundsSignal>({
  capitalCallPortion: null,
  note: '',
  attachments: [
    {
      name: 'Proof of Investment',
      file: null,
    },
    {
      name: 'Executed Investment Documents',
      file: null,
    },
    {
      name: 'Investment Chart/CAP Table',
      file: null,
    },
    {
      name: 'Terms of Incubation',
      file: null,
    },
  ],
});

export default $capitalCall;
