import { useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { businessMyLoansSignal } from "signals/BusinessMyLoans.signal";
import fetchAndSetMyLoans from "./businessMyLoans.helpers";
import MyLoansTable from "./components/MyLoansTable";
import BusinessWrapper from "../components/BusinessWrapper";
import TechnicalAssistanceModal from "components/global/TechnicalAssistance/TechnicalAssistanceModal";
import $appSettings from "signals/AppSettings.signal";
import { useHistory } from "react-router";
import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import { handleTabChange } from "../../../../libs/functions/global.functions";

const BusinessMyLoans = () => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const history = useHistory();
  const applications = businessMyLoansSignal.value.applications;

  const searchParams = Object.fromEntries(new URLSearchParams(history.location.search));
  const activeKey = searchParams.status;

  useEffect(() => {
    fetchAndSetMyLoans();
  }, [history.location.search]);

  return (
    <BusinessWrapper>
      <BreadCrumbWrapper labels={['Applications']}>
        <TechnicalAssistanceModal />
        <Container fluid>
          <Tabs
            activeKey={activeKey || 'all'}
            defaultActiveKey="all"
            mountOnEnter
            onSelect={(status) => {
              handleTabChange(status);
            }}
          >
            <Tab eventKey="all" title={`All ${applications.length}`}>
              <MyLoansTable />
            </Tab>
            <Tab
              eventKey="APPROVED"
              title={`Approved ${
                applications.filter(
                  (a) => a.status === APPLICATION_STATUS.approved
                ).length
              }`}
            >
              <MyLoansTable />
            </Tab>
            <Tab
              eventKey="PENDING"
              title={`Pending ${
                applications.filter(
                  (a) => a.status === APPLICATION_STATUS.pending
                ).length
              }`}
            >
              <MyLoansTable />
            </Tab>
            <Tab
              eventKey="INCOMPLETE"
              title={`Incomplete ${
                applications.filter(
                  (a) => a.status === APPLICATION_STATUS.incomplete
                ).length
              }`}
            >
              <MyLoansTable />
            </Tab>
            <Tab
              eventKey="DENIED"
              title={`Denied ${
                applications.filter(
                  (a) => a.status === APPLICATION_STATUS.denied
                ).length
              }`}
            >
              <MyLoansTable />
            </Tab>
          </Tabs>
        </Container>
      </BreadCrumbWrapper>
    </BusinessWrapper>
  );
};

export default BusinessMyLoans;
