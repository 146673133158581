import { Button } from 'react-bootstrap';
import {
  showUserApproveRequestModal,
  showUserDenyRequestModal,
} from './components/ReinviteUserModal/SendInviteUserModal.helpers';
import userRequestsSignal from './UserRequests.signal';
import SendInviteUserModal from './components/ReinviteUserModal';
import SimpleTable from '../SimpleTable';
import { fetchAndSetUserRequests } from './UserRequests.helpers';
import SimpleTableFilterDropdown from '../SimpleTableFilterDropdown';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  getOrganizationId,
  mapInvitationOrRequestStatus,
} from 'utils/organization';
import { enumCaseToTabCase } from '../../../libs/string';
import $appSettings from 'signals/AppSettings.signal';

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'organizationName',
    title: 'Organization',
    sortable: true,
  },
  {
    key: 'name',
    title: 'Contact Name',
    sortable: true,
  },
  {
    key: 'email',
    title: 'Email',
    sortable: true,
  },
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'portal',
    title: 'Portal',
    sortable: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FILTERS = [
  {
    type: 'text',
    label: 'First Name',
    key: 'firstName',
  },
  {
    type: 'text',
    label: 'Last Name',
    key: 'lastName',
  },
  {
    type: 'text',
    label: 'Email',
    key: 'email',
  },
  {
    type: 'text',
    label: 'Organization',
    key: 'organizationName',
  },
];

const ALL_REQUEST_FILTERS = [
  ...FILTERS,
  {
    type: 'select',
    label: 'Portal',
    key: 'portal',
    options: [
      {
        label: $appSettings.value.constants.PORTAL_TYPES.edo,
        value: $appSettings.value.constants.PORTAL_TYPES.edo,
      },
      {
        label:
          $appSettings.value.constants.PORTAL_TYPES.lender,
        value:
          $appSettings.value.constants.PORTAL_TYPES.lender,
      },
      {
        label: $appSettings.value.constants.PORTAL_TYPES.vc,
        value: $appSettings.value.constants.PORTAL_TYPES.vc,
      },
      {
        label: $appSettings.value.constants.PORTAL_TYPES.business,
        value: $appSettings.value.constants.PORTAL_TYPES.business,
      },
    ],
  },
];

const UserRequests = ({ refetchingFilter = undefined }) => {
  const location = useLocation();
  const path = location.pathname.split('/');
  const page = path[path.length - 1];
  const signalKey =
    page === 'lenders'
      ? 'lenderUserRequests'
      : page === 'vc'
      ? 'vcUserRequests'
      : page === 'business'
      ? 'businessUserRequests'
      : 'userRequests';
  const userRequests = userRequestsSignal.value[signalKey];

  const init = useCallback(() => {
    if (refetchingFilter) {
      if (refetchingFilter(location)) {
        return fetchAndSetUserRequests(signalKey);
      } else {
        return undefined;
      }
    }

    return fetchAndSetUserRequests(signalKey);
  }, [location.search]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <SendInviteUserModal />
      <div className="mb-16">
        <SimpleTableFilterDropdown
          filters={signalKey === 'userRequests' ? ALL_REQUEST_FILTERS : FILTERS}
        />
      </div>
      <SimpleTable
        headers={HEADERS}
        onClick={(index) => {
          const userRequest = userRequestsSignal.value[signalKey][index];
          showUserApproveRequestModal(userRequest);
        }}
        tableProps={{
          responsive: true,
        }}
        rows={userRequests.map((userRequest) => ({
          id: getOrganizationId(userRequest.userInvite.toAccount),
          organizationName: userRequest.organizationName,
          name: `${userRequest.firstName} ${userRequest.lastName}`,
          email: userRequest.email,
          status: mapInvitationOrRequestStatus(userRequest.userInvite.status),
          portal: enumCaseToTabCase(userRequest.portal),
          action: ({ key }) =>
            userRequest.userInvite.status === 'REQUEST' ? (
              <td key={key}>
                <div className="d-flex flex-nowrap justify-content-end">
                  <Button
                    variant="outline-danger"
                    className="btn btn-sm rounded mr-8"
                    onClick={() => showUserDenyRequestModal(userRequest)}
                  >
                    Deny
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn btn-sm rounded"
                    onClick={() => showUserApproveRequestModal(userRequest)}
                  >
                    Approve
                  </Button>
                </div>
              </td>
            ) : (
              <td className="text-right" key={key}>
                -
              </td>
            ),
        }))}
      />
    </>
  );
};

export default UserRequests;
