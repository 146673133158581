import loaderSignal from "signals/Loader.signal";
import history from "../../../../../history";
import api from "api/api";
import alertSignal from "signals/Alert.signal";
import $edoPerformanceCapitalCallsSignal from "./EdoPerformanceCapitalCalls.signals";
import $appSettings from "signals/AppSettings.signal";

export const fetchAndSetCapitalCalls = async () => {
  const pathname = history.location.pathname;
  const split = pathname.split('/');
  const fundingProgramId = Number(split[split.length - 1]);

  try {
    loaderSignal.update({ isContentLoading: true });

    const [fundingProgram, capitalCalls] = await Promise.all([
      api.get({
        path: '/fundingPrograms',
        options: {
          where: {
            id: fundingProgramId,
          },
          include: {
            stats: true,
          },
        },
      }),
      api.get({
        path: '/capitalCalls',
        options: {
          where: {
            fund: {
              fundingProgramMembership: {
                is: {
                  fundingProgramId
                }
              },
            },
          },
          include: {
            capitalCallPortions: true,
            fund: {
              include: {
                fundingProgramMembership: {
                  include: {
                    ventureCapitalFirm: true
                  }
                }
              }
            }
          },
          action: 'fetchMany',
        },
      }),
    ]);

    const funds =
      capitalCalls.map(cp => cp.fund).reduce((acc, fund) => {
        const funIds = acc.map(f => f.id);
        if (funIds.includes(fund.id)) {
          return acc;
        } else {
          acc.push(fund);
          return acc;
        }
      }, []);

    const fundingProgramMemberships = funds.map(f => f.fundingProgramMembership).reduce((acc, fpm) => {
      const fpmIds = acc.map(fpm => fpm.id);
      if (fpmIds.includes(fpm.id)) {
        return acc;
      } else {
        acc.push(fpm);
        return acc;
      }
    }, []);

    $edoPerformanceCapitalCallsSignal.update({ fundingProgram, funds, capitalCalls, fundingProgramMemberships });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getCapitalCallStats = (capitalCall) => {
  const { CAPITAL_CALL_STATUS } = $appSettings.value.constants;
  const stats = {
    obligated: 0,
    expended: 0,
    remaining: 0,
    recycled: 0,
  };

  if (
    capitalCall.status === CAPITAL_CALL_STATUS.approved ||
    capitalCall.status === CAPITAL_CALL_STATUS.disbursed ||
    capitalCall.status === CAPITAL_CALL_STATUS.funded
  ) {
    stats.approved = capitalCall.capitalCallAmount || 0;
    stats.obligated = capitalCall.capitalCallAmount || 0;
    stats.expended = 0;
  }

  if (capitalCall.status === CAPITAL_CALL_STATUS.funded || capitalCall.status === CAPITAL_CALL_STATUS.disbursed) {
    if (!!capitalCall.fundedConfirmedDate) {
      stats.expended = stats.obligated;
      stats.obligated = 0;
    }
  }

  return stats;
};
