import Signal from 'libs/signals';

const edoVCProgramRequestsSignal = Signal({
  programRequests: [],
  selectedProgramRequest: {
    id: null,
    dateSubmitted: '',
    program: null,
    applicationId: null,
    lenderName: '',
    contactName: '',
    allocationAsk: '',
    approvedAmount: '',
    status: '',
    note: [],
    applicationUpdatedDate: '',
    updatedDate: '',
    documents: [],
  },
  modalVisible: false,
  isPaginating: false,
  updateApplicationStatus: '',
  bundle: {},
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
});

export default edoVCProgramRequestsSignal;

export const edoVCApplicationDocumentTypesSignal = Signal([]); // need
export const edoVCApplicationsNoteSignal = Signal(''); // need
export const edoVCFilterSignal = Signal({
  filter: '',
  enableApplyButton: false,
});
export const edoProgramRequestCursor = Signal(null);
