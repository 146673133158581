import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { securityIcon } from 'components/global/Constant/Icons';
import TwoStepVerificationModal from 'components/global/TwoStepVerificationModal';

const AdminSettingsSecurity = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Card className="p-16 text-secondary">
      <Row className="mb-4">
        <Col>
          <h5><FontAwesomeIcon icon={securityIcon} /> Security</h5>
          <Button
            className="rounded mt-32"
            onClick={() => setShowModal(true)}
          >
            Two-Step Verification
          </Button>
        </Col>
      </Row>
      <TwoStepVerificationModal
        show={showModal}
        onToggleModal={() => setShowModal(false)}
      />
    </Card>
  );
};

export default AdminSettingsSecurity;
