import stateUsersSignal from 'signals/StateUsers.signal';
import stateAddNoteSignal from 'signals/StateAddNote.signal';
import loaderSignal from 'signals/Loader.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import stateAddUserFormSignal from 'signals/StateAddUser.signal';
import {
  emailRegex,
  phoneRegex,
  zipcodeRegex,
} from 'components/global/Constant/regularExpression';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetStateUsers = async () => {
  const { USER_STATUS } = $appSettings.value.constants;
  loaderSignal.update({ isContentLoading: true });
  try {
    const users = await api.get({
      path: '/users',
      options: {
        include: {
          account: {
            include: {
              business: true,
              edo: true,
              lender: true,
            },
          },
          ActivityLogs: true,
        },
      },
    });
    const activeUsers = users.filter(
      (user) => user.userStatus === USER_STATUS.active
    );
    const sortedUsers = [
      ...activeUsers.sort((u, w) => u.accountId > w.accountId),
    ];
    stateUsersSignal.update({ stateUsers: sortedUsers });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const addUser = async () => {
  loaderSignal.update({
    isLoadingContent: true,
  });
  try {
    // await api.post({
    //   path: '/users',
    //   body: stateAddUserFormSignal.value.addAdminUser,
    // });
    await fetchAndSetStateUsers();
    stateAddUserFormSignal.reset();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully added user',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: 'Unable to save changes',
    });
  } finally {
    loaderSignal.update({
      isLoadingContent: false,
    });
  }
};

export const updateUser = async () => {
  loaderSignal.update({
    isLoadingContent: true,
  });
  try {
    // await api.patch({
    //   path: 'admin/user',
    //   body: stateUsersSignal.value.selectedUser,
    // });
    await fetchAndSetStateUsers();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated user',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: 'Unable to save changes',
    });
  } finally {
    loaderSignal.update({
      isLoadingContent: false,
    });
  }
};

export const deactivateUser = async (toggleModal) => {
  const { selectedUser } = stateUsersSignal.value;
  loaderSignal.update({ isContentLoading: true });
  try {
    await api.patch({
      path: '/users',
      body: {
        where: {
          id: selectedUser.id,
        },
        data: {
          userStatus: USER_STATUS.inactive,
        },
      },
    });
    await fetchAndSetStateUsers();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully deactivated user',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
    toggleModal('deactivateUserModal');
  }
};

export const addAccountSettings = async () => {
  loaderSignal.update({
    isLoadingContent: true,
  });
  try {
    // await api.post({
    //   path: '/accountSettings',
    //   body: stateUsersSignal.value.accountDetails,
    // });
    // await fetchAndSetAccountDetails();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully added company information',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: 'Unable to add company information',
    });
  } finally {
    loaderSignal.update({
      isLoadingContent: false,
    });
  }
};

export const handleSelectStateUser = (userId) => {
  const user = stateUsersSignal.value.stateUsers.find((u) => u.id === userId);
  stateUsersSignal.update({
    selectedUser: {
      ...user,
      notes: user?.notes ? JSON.parse(JSON.stringify(user?.notes)) : [],
    },
  });
};

export const displayUserDetails = (userId, toggleModal, modalName) => {
  handleSelectStateUser(userId);
  toggleModal(modalName);
};

export const handleAddUserFormChange = (e) => {
  const { name, value } = e.target;

  stateAddUserFormSignal.update({
    addAdminUser: {
      ...stateAddUserFormSignal.value.addAdminUser,
      [name]: value,
    },
  });

  handleClearAddUserErrors(name);
};

export const handleUserFormRoleChange = (e) => {
  const { value } = e.target;
  let AdminType = {
    id: 3,
    name: 'User',
  };

  switch (Number(value)) {
    case 1:
      AdminType = {
        id: 1,
        name: 'Full Admin',
      };
      break;
    case 2:
      AdminType = {
        id: '2',
        name: 'Account Admin',
      };
      break;
    case 3:
      AdminType = {
        id: 3,
        name: 'User',
      };
      break;
    default:
      break;
  }

  stateUsersSignal.update({
    addAdminUser: {
      ...stateUsersSignal.value.addAdminUser,
      AdminType,
    },
  });
};

export const handleSetAddUserErrors = () => {
  const newErrors = {
    email: false,
    confirmPassword: false,
  };

  if (!emailRegex.test(stateAddUserFormSignal.value.addAdminUser.email)) {
    newErrors.email = true;
  }
  if (
    stateAddUserFormSignal.value.addAdminUser.password !==
    stateAddUserFormSignal.value.addAdminUser.confirmPassword
  ) {
    newErrors.confirmPassword = true;
  }

  stateAddUserFormSignal.update({
    addAdminUserErrors: newErrors,
  });
};

export const handleClearAddUserErrors = (name) => {
  if (
    name === 'email' &&
    emailRegex.test(stateAddUserFormSignal.value.addAdminUser.email)
  ) {
    stateAddUserFormSignal.update({
      addAdminUserErrors: {
        ...stateAddUserFormSignal.value.addAdminUserErrors,
        email: false,
      },
    });
  }

  if (
    (name === 'password' || 'confirmPassword') &&
    stateAddUserFormSignal.value.addAdminUser.password ===
    stateAddUserFormSignal.value.addAdminUser.confirmPassword
  ) {
    stateAddUserFormSignal.update({
      addAdminUserErrors: {
        ...stateAddUserFormSignal.value.addAdminUserErrors,
        confirmPassword: false,
      },
    });
  }
};

export const resetForm = () => {
  stateAddUserFormSignal.reset();
};

export const handleUserDetailsChange = (e) => {
  const { name, value } = e.target;

  stateUsersSignal.update({
    selectedUser: {
      ...stateUsersSignal.value.selectedUser,
      [name]: value,
    },
  });

  handleClearUserDetailErrors(name);
};

export const handleUserDetailsAddressChange = (e) => {
  const { name, value } = e.target;

  stateUsersSignal.update({
    selectedUser: {
      ...stateUsersSignal.value.selectedUser,
      address: {
        ...stateUsersSignal.value.selectedUser.address,
        [name]: value,
      },
    },
  });

  handleClearUserDetailErrors(name);
};

export const handleAdminRoleChange = (adminTypeId) => {
  const adminTypes = [
    {
      id: 1,
      name: 'Full Admin',
    },
    {
      id: 2,
      name: 'Account Admin',
    },
    {
      id: 3,
      name: 'User',
    },
  ];

  const newAdminType = adminTypes.find((type) => type.id === adminTypeId);

  stateUsersSignal.update({
    selectedUser: {
      ...stateUsersSignal.value.selectedUser,
      AdminType: newAdminType,
    },
  });
};

export const handleSetUserDetailErrors = () => {
  const newErrors = {
    email: false,
    phone: false,
    zipcode: false,
  };

  if (!emailRegex.test(stateUsersSignal.value.selectedUser.email)) {
    newErrors.email = true;
  }
  if (!phoneRegex.test(stateUsersSignal.value.selectedUser.phone)) {
    newErrors.phone = true;
  }
  if (!zipcodeRegex.test(stateUsersSignal.value.selectedUser.address.zipcode)) {
    newErrors.zipcode = true;
  }

  stateUsersSignal.update({
    selectedUserErrors: newErrors,
  });
};

export const handleClearUserDetailErrors = (name) => {
  if (
    name === 'email' &&
    emailRegex.test(stateUsersSignal.value.selectedUser.email)
  ) {
    stateUsersSignal.update({
      selectedUserErrors: {
        ...stateUsersSignal.value.selectedUserErrors,
        email: false,
      },
    });
  }

  if (
    name === 'phone' &&
    phoneRegex.test(stateUsersSignal.value.selectedUser.phone)
  ) {
    stateUsersSignal.update({
      selectedUserErrors: {
        ...stateUsersSignal.value.selectedUserErrors,
        phone: false,
      },
    });
  }

  if (
    name === 'zipcode' &&
    zipcodeRegex.test(stateUsersSignal.value.selectedUser.address.zipcode)
  ) {
    stateUsersSignal.update({
      selectedUserErrors: {
        ...stateUsersSignal.value.selectedUserErrors,
        zipcode: false,
      },
    });
  }
};

export const handleResetUserDetailsErrors = () => {
  stateUsersSignal.update({
    selectedUserErrors: {
      email: false,
      phone: false,
      zipcode: false,
    },
  });
};

export const cancelEditing = (userId, onSetEditing) => {
  handleSelectStateUser(userId);
  handleResetUserDetailsErrors();
  onSetEditing(false);
};

export const resetAddNoteForm = () => {
  stateAddNoteSignal.reset();
};

export const handleAddNoteFormChange = (e) => {
  const { value } = e.target;

  stateAddNoteSignal.update({
    note: value,
  });
};

export const handleAddNote = (onSetAddNote) => {
  if (stateAddNoteSignal.value.note.length > 0) {
    const newNote = {
      ...stateAddNoteSignal.value,
      date: new Date(),
    };

    stateUsersSignal.value.selectedUser.notes.push({ ...newNote });
    onSetAddNote(false);
    stateAddNoteSignal.reset();
  }
};
