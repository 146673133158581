import CloseModalButton from 'components/global/Constant/CloseModalButton';
import { Button, Modal } from 'react-bootstrap';
import { fundsSignals } from 'components/global/Funds/Funds.signals';
import {
  fetchProgramRequests,
  handleFundDetailsChange,
  isMaxFundSizeValid,
} from '../../../global/Funds/Funds.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { useEffect } from 'react';
import NoFundingProgramsAlert from '../../../global/Funds/components/NoFundingProgramsAlert';
import Field from 'components/global/Field/Field';

const FundDetailsModal = () => {
  const {
    fundDetails,
    detailsModalVisible,
    approvedProgramRequests,
    maxFundSizeError,
  } = fundsSignals.value;

  useEffect(() => {
    fetchProgramRequests();
  }, [detailsModalVisible]);

  return (
    <ModalWrapper
      size="lg"
      centered
      show={detailsModalVisible}
      onHide={() => fundsSignals.update({ detailsModalVisible: false })}
    >
      <Modal.Header>
        <h5>{fundDetails.fundName ? 'Fund Details' : 'Create a New Fund'}</h5>
        <CloseModalButton
          onCloseModal={() =>
            fundsSignals.update({ detailsModalVisible: false })
          }
        />
      </Modal.Header>
      <Modal.Body>
        {!!approvedProgramRequests.length ? (
          <>
            <Field.Select
              disabled={fundDetails.id}
              label="Select Approved Program"
              inputClassName="w-100 form-control"
              name="programRequestId"
              lineBreak
              value={fundDetails.programRequestId}
              options={approvedProgramRequests.map((programRequest) => ({
                value: programRequest.id,
                label: programRequest.fundingProgram.name,
              }))}
              onChange={handleFundDetailsChange}
            />
            <Field.TextInput
              disabled={!fundDetails.programRequestId || fundDetails.id}
              label="Name of Fund"
              labelClassName="mt-8"
              value={fundDetails.fundName}
              name="fundName"
              onChange={handleFundDetailsChange}
            />
            <Field.Integer
              disabled={!fundDetails.programRequestId || fundDetails.id}
              label="Max Fund Size"
              labelClassName="mt-8"
              value={fundDetails.maxFundSize}
              name="maxFundSize"
              onChange={handleFundDetailsChange}
              onBlur={isMaxFundSizeValid}
            />
            <small hidden={!maxFundSizeError} className="text-danger">
              {maxFundSizeError}
            </small>
            <div className="text-right mt-8">
              <Button
                className="rounded"
                variant="outline-secondary"
                size="sm"
                onClick={() =>
                  fundsSignals.update({ detailsModalVisible: false })
                }
              >
                Dismiss
              </Button>
            </div>
          </>
        ) : (
          <NoFundingProgramsAlert />
        )}
      </Modal.Body>
    </ModalWrapper>
  );
};

export default FundDetailsModal;
