import searchBusinessesSignal from './searchBusinesses.signal';
import { PORTAL_TYPE_BASE } from '../Constant/constants';
import userAccountSignal from 'signals/UserAccount.signal';
import { zipcodeRegex } from '../Constant/regularExpression';
import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import history from '../../../history';
import {
  deleteSavedSearch,
  saveSearch,
  saveSearchChanges,
} from '../SearchBusinessSaveModal/saveBusinessSearchModal.helpers';

export const handleBusinessSearchFormChange = (e) => {
  const { name, value } = e.target;

  searchBusinessesSignal.update({
    searchForm: {
      ...searchBusinessesSignal.value.searchForm,
      [name]: value,
    },
    searchFormErrors: {
      ...searchBusinessesSignal.value.searchFormErrors,
      [name]: false,
    },
  });
};

export const handleBusinessSearchSubmit = (params) => {
  const { searchFormErrors } = searchBusinessesSignal.value;

  if (Object.values(searchFormErrors).includes(true)) {
    return alertSignal.update({
      type: 'notification',
      message:
        'Please enter a valid Zip Code or leave the Zip Code field empty.',
    });
  }

  const cleanFilters = Object.fromEntries(
    Object.entries(params).filter(([, value]) => !!value)
  );

  const newPath = `${
    PORTAL_TYPE_BASE[userAccountSignal.value.userData.account.portalType]
  }/search-businesses/business-search-results?${new URLSearchParams(
    cleanFilters
  )}`;
  return history.push(newPath);
};

export const handleSearchBusinessInputBlur = (e) => {
  const { name } = e.target;
  const { searchForm } = searchBusinessesSignal.value;
  if (
    name === 'location' &&
    searchForm.location &&
    !zipcodeRegex.test(searchForm.location)
  ) {
    searchBusinessesSignal.update({
      searchFormErrors: {
        ...searchBusinessesSignal.value.searchFormErrors,
        [name]: true,
      },
    });
  }
};

export const fetchAndSetSavedSearches = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const savedSearches = await api.get({
      path: '/savedSearches',
      options: {
        where: {
          userId: userAccountSignal.value.userData.id,
        },
      },
    });

    searchBusinessesSignal.update({
      savedSearches: savedSearches.filter((ss) => ss.status === 'ACTIVE'),
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSaveSearchClick = async () => {
  await saveSearch(fetchAndSetSavedSearches);
};

export const handleSaveSearchChangesClick = async () => {
  await saveSearchChanges(fetchAndSetSavedSearches);
};

export const handleDeleteSaveSearchClick = async () => {
  await deleteSavedSearch(fetchAndSetSavedSearches);
};
