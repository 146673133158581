import ModalWrapper from "components/global/ModalWrapper/ModalWrapper";
import VCDealFlowsSignal from "../VCDealFlows.signal";
import CloseModalButton from "components/global/Constant/CloseModalButton";
import { handleConfirmRemoveClick, handleHideRemovePortfolioCompanyModal } from "../VCDealFlows.helpers";
import { Alert, Button, Modal } from "react-bootstrap";

const RemovePortfolioCompanyModal = () => {
  const  { removeVcPortfolioCompany, selectedVCPortfolioCompany } = VCDealFlowsSignal.value;

  if (!selectedVCPortfolioCompany) {
    return null;
  }

  return (
    <ModalWrapper
      show={removeVcPortfolioCompany}
      centered
      onHide={handleHideRemovePortfolioCompanyModal}
    >
      <Modal.Header className="justify-content-between">
        <Modal.Title>Remove Portfolio Company</Modal.Title>
        <CloseModalButton onCloseModal={handleHideRemovePortfolioCompanyModal} />
      </Modal.Header>
      <Modal.Body>
        {selectedVCPortfolioCompany?.capitalCalls?.length ?
          (
            <Alert variant="warning">
              <p>This portfolio company cannot be removed because it has capital calls associated with it.</p>
              <p className="mb-0">If you need further assistance please contact support.</p>
            </Alert>
          ) :
          (
            <p>Are you sure you want to remove {selectedVCPortfolioCompany.business.name} from your portfolio?</p>
          )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
        variant="secondary"
        className="rounded"
        size="sm"
        onClick={handleHideRemovePortfolioCompanyModal}
        >
          Cancel
        </Button>
        <Button
          disabled={selectedVCPortfolioCompany?.capitalCalls?.length}
          variant="primary"
          className="rounded"
          size="sm"
          onClick={handleConfirmRemoveClick}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default RemovePortfolioCompanyModal;