import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import edoApplicationBulkImportSignal from './EdoApplicationBulkImport.signals';
import { parseCSVFile } from '../../../../libs/functions/global.functions';
import applicationComplianceSignal from '../../../global/ApplicationCompliance/ApplicationCompliance.signal';

export const setAndFetchBulkImportTasks = async (page) => {
  const { recordsPerPage } = edoApplicationBulkImportSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    const [data] = await Promise.all([
      api.get({
        path: '/applications/bulkImports',
        options: {
          getCount: true,
          skip: page ? (page - 1) * recordsPerPage : 0,
          take: recordsPerPage,
          orderBy: [{ id: 'desc' }],
          include: {
            application: {
              include: {
                fundingProgram: true,
              },
            },
            user: true,
          },
        },
      }),
    ]);

    const { bulkImportTasks, count } = data;

    return edoApplicationBulkImportSignal.update({
      bulkImportTasks,
      totalPages: Math.ceil(count / recordsPerPage),
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleBulkImportSubmission = async () => {
  loaderSignal.update({ isContentLoading: true });

  const { importData } = edoApplicationBulkImportSignal.value;
  const { complianceChecklist } = applicationComplianceSignal.value;

  try {
    const body = {
      data: importData.map((row) => ({
        ocrModelKey: row[2],
        mainDocumentPath: row[0],
        supportingDocumentPaths: row[1],
      })),
      complianceChecklist,
    };

    await api.post({
      path: '/applications/bulkImports',
      body,
    });

    edoApplicationBulkImportSignal.update({
      formModalVisible: false,
      importData: false,
    });

    applicationComplianceSignal.reset();

    await setAndFetchBulkImportTasks();

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully created bulk import.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showProcessBulkImportFileModal = () =>
  edoApplicationBulkImportSignal.update({
    formModalVisible: true,
  });
export const hideProcessBulkImportFileModal = () => {
  applicationComplianceSignal.reset();
  edoApplicationBulkImportSignal.update({
    formModalVisible: false,
  });
};

export const handleAttachBulkImportFile = async (file) => {
  const result = await parseCSVFile(file);
  const importData = result.map((row) => Object.values(row));
  return edoApplicationBulkImportSignal.update({
    importData,
  });
};
