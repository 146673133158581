import Signal from '../../../libs/signals';
import {
  FundingProgram,
  FundingProgramMembership,
  ProgramRequest,
} from '../../../types';

type FundingProgramDetailSignal = {
  fundingProgram: FundingProgram | null;
  fundingProgramStats: FundingProgram['stats'] | null;
  programRequests: ProgramRequest[];
  fundingProgramMemberships: FundingProgramMembership[];
};

const fundingProgramDetailSignal = Signal<FundingProgramDetailSignal>({
  fundingProgram: null,
  fundingProgramStats: null,
  programRequests: [],
  fundingProgramMemberships: [],
});
export default fundingProgramDetailSignal;
