import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import RichTextEditor from 'components/global/Field/FieldComponents/RichTextEditor';
import capitalizeFirst from 'utils/capitalizeFirst';
import edoTooltipsSignal from '../edoTooltips.signal';
import {
  TOOLTIP_MODALS,
  deleteTooltip,
  handleCloseTooltipsModal,
  handleTooltipSaveClick,
  handleTooltipsInputChange,
} from '../edoTooltips.helpers';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const TooltipsModal = () => {
  const { title, supportingText, type, modalType, deleteTooltipPromptVisible, tooltipErrors } =
    edoTooltipsSignal.value;
  const { TOOLTIP_TYPES } = $appSettings.value.constants;

  return (
    <ModalWrapper
      centered
      show={modalType}
      size="lg"
      onHide={handleCloseTooltipsModal}
    >
      <Modal.Header>
        <h5>New Tooltip</h5>
        <CloseModalButton onCloseModal={handleCloseTooltipsModal} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} lg>
            <Field.TextInput
              label="Title"
              labelClassName="font-weight-bold"
              inputClassName="form-control"
              name="title"
              value={title}
              onChange={handleTooltipsInputChange}
              isInvalid={tooltipErrors.title}
            />
            <small hidden={!tooltipErrors.title} className="text-danger">Please provide a title.</small>
          </Col>
          <Col xs={12} lg className="mt-16 mt-lg-03">
            <Field.Select
              label="Type"
              labelClassName="font-weight-bold"
              inputClassName="form-control"
              name="type"
              value={type}
              onChange={handleTooltipsInputChange}
              options={Object.values(TOOLTIP_TYPES).map((type) => ({
                label: capitalizeFirst(type),
                value: type,
              }))}
              isInvalid={tooltipErrors.type}
            />
            <small hidden={!tooltipErrors.type} className="text-danger">Please provide a type.</small>
          </Col>
        </Row>
        <p className="font-weight-bold mt-16 mb-8">Supporting Text</p>
        <RichTextEditor
          onChange={(value, name) =>
            handleTooltipsInputChange({ name, value })
          }
          value={supportingText}
          name="supportingText"
        />
        <small hidden={!tooltipErrors.supportingText} className="text-danger">Please provide supporting text.</small>
        {deleteTooltipPromptVisible ? (
          <div>
            <p className="text-right mb-8 mt-24">Are you want to delete this tooltip?</p>
            <div className="mt-24 d-flex flex-row justify-content-end">
              <Button
                variant="outline-secondary"
                className="rounded mr-8"
                onClick={() =>
                  edoTooltipsSignal.update({
                    deleteTooltipPromptVisible: false,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                className="rounded"
                variant="danger"
                onClick={deleteTooltip}
              >
                Confirm
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-24 d-flex flex-row justify-content-end">
            <Button
              hidden={modalType === TOOLTIP_MODALS.create}
              variant="outline-danger"
              className="rounded mr-8"
              onClick={() =>
                edoTooltipsSignal.update({ deleteTooltipPromptVisible: true })
              }
            >
              Delete
            </Button>
            <Button className="rounded" onClick={handleTooltipSaveClick}>
              Save
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={handleCloseTooltipsModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default TooltipsModal;
