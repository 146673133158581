import { DateTime } from 'luxon';

export const getAmortizationScheduleCurrentTerm = (schedule) => {
  if (!schedule) {
    return null;
  }

  return schedule.find(
    ({ date }) =>
      new Date().getTime() <=
      DateTime.fromISO(date).plus({ month: 1 }).toJSDate().getTime()
  );
};
