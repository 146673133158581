import EdoOrganizationSummary from '../shared/EdoOrganizationSummary';
import $appSettings from 'signals/AppSettings.signal';

const EdoBusiness = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;

  return (
    <EdoOrganizationSummary portalType={PORTAL_TYPES.business} />
  );
}


export default EdoBusiness;
