import Signal, { updateSignal } from 'libs/signals';

const alertSignal = Signal({
  notifications: [],
  nextNotificationId: 1,
});


alertSignal.update = (payload) => {
  if (payload.type === 'notification') {
    const id = payload.id ?? alertSignal.value.nextNotificationId;
    if (!alertSignal.value.notifications.find(notification => notification.id === id)) {

      alertSignal.value = {
        ...alertSignal.value,
        notifications: [
          ...alertSignal.value.notifications,
          {
            ...payload,
            id: id,
          }
        ],
        nextNotificationId: id + 1,
      }

      if (payload.error) {
        console.error(payload.error);
      }

      setTimeout(() => {
        alertSignal.value = {
          ...alertSignal.value,
          notifications: alertSignal.value.notifications.filter(notification => notification.id !== id)
        };
        // Timeout is increased incrementally by the length of the list of notifications
      }, payload.timeout ?? 5000 + ((alertSignal.value.notifications.length - 1) * 1000))
    }
  } else {
    return updateSignal(alertSignal, payload);
  }
}

export default alertSignal;
