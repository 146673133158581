import { Container, Navbar } from "react-bootstrap";
import mstreetxLogo from "assets/images/blackLogo240x240.png";
import expressInterestSignal from "../ExpressInterest/ExpressInterest.signal";
import $appSettings from "signals/AppSettings.signal";

const ExpressInterestHeader = () => {
  let headerTitle = '';
  const { portal } = expressInterestSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  switch (portal.toLocaleUpperCase()) {
    case PORTAL_TYPES.lender:
      headerTitle = 'Capital Lender Express Interest';
      break;
    case PORTAL_TYPES.business:
      headerTitle = 'Business Express Interest';
      break;
    case PORTAL_TYPES.vc:
      headerTitle = 'Venture Capitalist Express Interest';
      break;
    default:
      headerTitle = 'Welcome to CAAT';
      break;
  }

  return (
    <Navbar bg="primary" expand="lg" variant="outline-dark">
      <Container fluid>
        <Navbar.Brand className="navbar-brand" href="/">
          <h4 className={"text-white-50"}>{headerTitle}</h4>
          <p style={{ fontSize: 18 }} className="text-body">Powered by <img src={mstreetxLogo} alt="MstreetX logo" height="30" /></p>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default ExpressInterestHeader;
