import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import stateOrganizationsSignal from 'signals/StateOrganizations.signal';

export const fetchAndSetStateOrganizationsData = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const accounts = await api.get({
      path: '/accounts/private',
      options: {
        where: {
          NOT: {
            portalType: 'NONE',
          },
        },
        include: {
          account: {
            include: {
              edo: true,
              lender: true,
              business: true,
            },
          },
        },
      },
    });
    return stateOrganizationsSignal.update({ accounts });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showOrganizationsOrganizationDetailModal = (selectedAccount) =>
  stateOrganizationsSignal.update({ selectedAccount });

export const hideOrganizationsOrganizationDetailModal = () =>
  stateOrganizationsSignal.update({ selectedAccount: null });
