import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import LargeCheckBox from 'components/global/Constant/LargeCheckBox';
import userManagementSignal from '../UserManagement.signal';
import { USER_PROGRAM_ACCESS_OPTIONS } from '../../Constant/constants';
import {
  getAddressOptions,
  handleAddressBlur,
  handleAddressSearchChange,
  handleAddressSearchFocus,
  handleSelectAddress,
  handleUserManagementUserDetailFormChange,
  handleUserManagementUserProgramAccessChangeSubmit,
  handleUserManagementUserUpdateSubmit,
  hideUserManagementDetailModal,
} from '../UserManagement.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import { useEffect } from 'react';
import Field from 'components/global/Field/Field';

const UserManagementDetailModal = () => {
  const {
    detailModalUser,
    fundingProgramMemberships,
    userProgramAccesses,
    fundingPrograms,
    userDetailForm,
    addressSearch,
    addressOptions,
    errors,
  } = userManagementSignal.value;

  useEffect(() => {
    getAddressOptions();
  }, [addressSearch]);

  return (
    <ModalWrapper
      centered
      show={!!userManagementSignal.value.detailModalUser}
      size="xl"
      className="border border-dark rounded"
    >
      <Modal.Header className="pb-0 pl-32">
        <h5>User Details</h5>
        <CloseModalButton onCloseModal={hideUserManagementDetailModal} />
      </Modal.Header>
      <Modal.Body className="rounded px-32">
        <Form>
          <Row>
            <Col xl={6}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label className="col-form-label" htmlFor="firstName">
                      First Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={userDetailForm.firstName}
                      onChange={(e) =>
                        handleUserManagementUserDetailFormChange(e)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a first name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label className="col-form-label" htmlFor="lastName">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={userDetailForm.lastName}
                      onChange={(e) =>
                        handleUserManagementUserDetailFormChange(e)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      isInvalid={false}
                      value={userDetailForm.email}
                      onChange={(e) =>
                        handleUserManagementUserDetailFormChange(e)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email address
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="phoneNumber">Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      className="form-control"
                      isInvalid={false}
                      value={userDetailForm.phoneNumber}
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={(e) =>
                        handleUserManagementUserDetailFormChange(e)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid 10-digit phone number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Field.AutoComplete
                      label="Full Address"
                      labelClassName="font-weight-bold"
                      options={addressOptions}
                      optionFormatter={(option) => option.formattedAddress}
                      onChange={(e) =>
                        handleAddressSearchChange(e.target.value)
                      }
                      onSelect={(e) => handleSelectAddress(e)}
                      value={
                        userDetailForm?.address?.formattedAddress ||
                        addressSearch
                      }
                      name="address"
                      isInvalid={errors.address}
                      onBlur={handleAddressBlur}
                      onFocus={handleAddressSearchFocus}
                    />
                    <small className="text-danger" hidden={!errors.address}>
                      Please select a valid address from the drop down.
                    </small>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={6} className="mt-16 mt-xl-0">
              <p className="mb-0">Program Access</p>
              <Card className="rounded-0 mt-8 px-16 py-8">
                <Table size="sm" borderless>
                  <thead>
                    <tr>
                      <th> </th>
                      {USER_PROGRAM_ACCESS_OPTIONS.map((type) => (
                        <th key={type.id} className="text-center">
                          {type.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {fundingPrograms.map((fundingProgram) => (
                      <tr key={fundingProgram.id} className="border-bottom">
                        <th>{fundingProgram.name}</th>
                        {USER_PROGRAM_ACCESS_OPTIONS.map((type) => {
                          const userProgramAccess = userProgramAccesses.find(
                            (upa) =>
                              upa.userId === detailModalUser?.id &&
                              upa.role === type.value &&
                              upa.fundingProgramMembership.fundingProgramId ===
                                fundingProgram.id
                          );
                          const checked = !!userProgramAccess;
                          return (
                            <td key={type.id} align="center">
                              <LargeCheckBox
                                size="sm"
                                checked={checked}
                                disabled={loaderSignal.value.isContentLoading}
                                onClick={() =>
                                  handleUserManagementUserProgramAccessChangeSubmit(
                                    {
                                      id: userProgramAccess?.id,
                                      fundingProgramMembershipId:
                                        fundingProgramMemberships.find(
                                          (mpm) =>
                                            mpm.fundingProgramId ===
                                            fundingProgram.id
                                        ).id,
                                      role: type.value,
                                      userId: detailModalUser?.id,
                                    }
                                  )
                                }
                              />
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xs={12}>
              <Button
                className="rounded mr-8 mt-16 mt-xl-0"
                onClick={() => handleUserManagementUserUpdateSubmit()}
                disabled={loaderSignal.value.isContentLoading}
              >
                Save
              </Button>
            </Col>
          </Row>
          <Row className="px-16 mt-16 justify-content-end">
            <Button
              className="rounded mr-8"
              variant="outline-secondary"
              onClick={hideUserManagementDetailModal}
            >
              Close
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default UserManagementDetailModal;
