import Signal from 'libs/signals';

export const INDUSTRY_DATA_INIT_STATE = [
  'Hospitality',
  'Food Services',
  'Construction',
  'Retail Trade',
  'Health Care and Social Assistance',
  'Educational Services',
  'Wholesale Trade',
  'Agriculture, Forestry, Fishing, and Hunting',
  'Manufacturing',
  'Real Estate',
  'Arts and Entertainment',
  'Mining, Quarrying, and Oil and Gas Extraction',
  'Outdoor Recreation',
  'Transportation and Warehousing',
  'Professional Services',
  'Technology',
];

export const vcOrganizationInfoSignal = Signal({
  name: '',
  lenderType: '',
  website: '',
  contactName: '',
  email: '',
  phoneNumber: '',
  description: '',
  loanRangeMin: undefined,
  loanRangeMax: undefined,
  downPaymentRange: '$0 - $5000',
  fundsStartups: false,
  requiresBusinessPlan: false,
  industrySelection: [],
  ein: '',
  regulatoryId: '',
  otherOrganizationId: '',
  otherLenderType: '',
  targetFundSize: undefined,
  minorityDepositoryInstitution: false,
  fundAsSourceOfPrivateCapital: false,
  lenderAsSourceOfPrivateCapital: false,
  incubationAndEarlyStageInvestmentCallOptions: false,
});

export const vcOrganizationInfoErrorsSignal = Signal({
  industrySelection: false,
  email: false,
  phoneNumber: false,
  zipCode: false,
  ein: false,
  regulatoryId: false,
  website: false,
});

export const vcIndustryDataSignal = Signal(INDUSTRY_DATA_INIT_STATE);

export const vcSettingsAddressSearchSignal = Signal({
  addressSearch: '',
  addressOptions: [],
});

export const vcBusinessNewIndustrySignal = Signal('');
