import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import edoTechnicalAssistanceSignal from '../edoTechnicalAssistance.signal';
import {
  SEND_TECHNICAL_ASSISTANCE_MODAL_STATE,
  TECHNICAL_ASSISTANCE_MODALS,
  fetchAndSetTechnicalAssistance,
  handleCloseTechnicalAssistanceModal,
  sendExistingTechnicalAssistance,
  sendNewTechnicalAssistance,
} from '../edoTechnicalAssistance.helpers';
import TechnicalAssistanceInputs from './TechnicalAssistanceInputs';
import TechnicalAssistancesTable from './TechnicalAssistancesTable';
import TechnicalAssistance from 'components/global/TechnicalAssistance';
import { useEffect } from 'react';

const SendTechnicalAssistanceModal = ({ onSendCallback }) => {
  const {
    modalType,
    sentTechnicalAssistanceId,
    sendTechnicalAssistanceModalState,
  } = edoTechnicalAssistanceSignal.value;

  const handleSubmit = () => {
    if (
      sendTechnicalAssistanceModalState ===
      SEND_TECHNICAL_ASSISTANCE_MODAL_STATE.useNew
    ) {
      sendNewTechnicalAssistance(onSendCallback);
    }
    if (
      sendTechnicalAssistanceModalState ===
      SEND_TECHNICAL_ASSISTANCE_MODAL_STATE.useExisting
    ) {
      sendExistingTechnicalAssistance(onSendCallback);
    }
  };

  useEffect(() => {
    fetchAndSetTechnicalAssistance();
  }, []);

  return (
    <ModalWrapper
      centered
      show={modalType === TECHNICAL_ASSISTANCE_MODALS.send}
      size="xl"
      onHide={handleCloseTechnicalAssistanceModal}
    >
      <Modal.Header>
        <h4>Send Technical Assistance</h4>
        <CloseModalButton onCloseModal={handleCloseTechnicalAssistanceModal} />
      </Modal.Header>
      <Modal.Body>
        {sentTechnicalAssistanceId && (
          <>
            <h5>Active Technical Assistance</h5>
            <TechnicalAssistance />
            <hr />
          </>
        )}
        <div className="mb-32">
          <p className="font-weight-semi-bold">
            Create or use existing technical assistance to send to the business.
          </p>
        </div>
        <Tabs
          className="scroll-tabs"
          defaultActiveKey={SEND_TECHNICAL_ASSISTANCE_MODAL_STATE.useNew}
          onSelect={(e) => console.log(e) ||
            edoTechnicalAssistanceSignal.update({
              sendTechnicalAssistanceModalState:
                e,
            })
          }
        >
          <Tab
            title="Create New Technical Assistance"
            eventKey={SEND_TECHNICAL_ASSISTANCE_MODAL_STATE.useNew}
          >
            <div className="p-16">
              <TechnicalAssistanceInputs />
            </div>
          </Tab>
          <Tab
            title="Use Existing Technical Assistance"
            eventKey={SEND_TECHNICAL_ASSISTANCE_MODAL_STATE.useExisting}
          >
            <TechnicalAssistancesTable isModal />
          </Tab>
        </Tabs>
        <div className="d-flex flex-row justify-content-end mt-24">
          <Button onClick={handleSubmit}>Send Technical Assistance</Button>
        </div>
        {sentTechnicalAssistanceId && (
          <div className="text-right">
            <small>
              Note: The active technical assistance will be replaced.
            </small>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={handleCloseTechnicalAssistanceModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default SendTechnicalAssistanceModal;
