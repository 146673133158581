import CloseModalButton from "components/global/Constant/CloseModalButton";
import ModalWrapper from "components/global/ModalWrapper/ModalWrapper";
import { Button, Modal } from "react-bootstrap";
import SimpleTable from "components/global/SimpleTable";
import FilePreviewButton from "components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton";
import attachDocumentsModalSignal from "./attachDocumentsModal.signal";
import {
  ATTACH_DOCUMENT_MODAL_TYPE,
  handleAttachDocumentModalClose,
  handleAttachSingleDocumentSelectClick,
  handleMultipleDocumentSelectClick
} from "./attachDocumentsModal.helpers";
import Field from "../Field/Field";

const HEADERS = [
  {
    key: 'fileName',
    title: 'File Name',
  },
  {
    key: 'view',
    title: 'View',
  },
  {
    key: 'selected',
    title: 'Selected',
  },
];

const AttachDocumentModal = ({
  overArchingDocumentType,
  onDocumentsSubmit,
  dataRoomDocuments = [],
}) => {
  const {
    selectedDataRoomDocuments,
    modalVisible,
    selectedTemplateDocument,
  } = attachDocumentsModalSignal.value;

  return (
    <ModalWrapper
      show={
        modalVisible === ATTACH_DOCUMENT_MODAL_TYPE.singleDocument ||
        modalVisible === ATTACH_DOCUMENT_MODAL_TYPE.multipleDocuments
      }
      centered
      size="lg"
      onHide={handleAttachDocumentModalClose}
    >
      <Modal.Header>
        <h3>
          {modalVisible === ATTACH_DOCUMENT_MODAL_TYPE.singleDocument
            ? `Select a Document - ${selectedTemplateDocument?.name}`
            : 'Additional Documents'}
        </h3>
        <CloseModalButton onCloseModal={handleAttachDocumentModalClose} />
      </Modal.Header>
      <Modal.Body>
        <SimpleTable
          headers={HEADERS}
          rows={dataRoomDocuments.map((doc) => ({
            fileName: doc.name,
            view: () => (
              <td>
                <FilePreviewButton
                  render={(submitting, fn) => (
                    <Button
                      className="rounded"
                      size="sm"
                      variant="outline-dark"
                      onClick={() => fn(doc, overArchingDocumentType)}
                    >
                      View
                    </Button>
                  )}
                />
              </td>
            ),
            selected: () => (
              <td align="right">
                <Field.Checkbox
                  value={selectedDataRoomDocuments.find(
                    (selectedDoc) => selectedDoc.id === doc.id
                  )}
                  onChange={() =>
                    modalVisible === ATTACH_DOCUMENT_MODAL_TYPE.singleDocument
                      ? handleAttachSingleDocumentSelectClick(doc)
                      : handleMultipleDocumentSelectClick(doc)
                  }
                />
              </td>
            ),
          }))}
        />
        <div className="d-flex flex-row justify-content-end">
          <Button className="rounded" onClick={onDocumentsSubmit}>
            Submit
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={handleAttachDocumentModalClose}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default AttachDocumentModal;
