import { useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import searchBusinessResultsSignal from "./searchBusinessResults.signal";
import {
  fetchAndSetSearchResults,
  getRelevantSearchParams,
  handleSaveSearchClick,
  resetBusinessSearchFilters,
  saveSearchButtonIsDisabled,
  setDropdownFilter,
  toggleModal
} from "./searchBusinessResults.helpers";
import { useHistory } from "react-router-dom";
import SimpleTableFilterDropdown from "../SimpleTableFilterDropdown";
import SimpleTable from "../SimpleTable";
import userAccountSignal from "../../../signals/UserAccount.signal";
import { INDUSTRY_LIST, SEEKING_FUNDING_PAYMENT_RANGES } from "../Constant/constants";
import SearchBusinessResultsModal from "./components/SearchBusinessResultsModal";
import BreadCrumbWrapper from "../BreadCrumbs/BreadCrumbs";
import dateOptions from "../Constant/dateOptions";
import SaveBusinessSearchModal from "../SearchBusinessSaveModal/SaveBusinessSearchModal";
import { handleShowSaveSearchFromResultsCreateModal } from "../SearchBusinessSaveModal/saveBusinessSearchModal.helpers";
import { camelCaseToTitleText } from "utils/formatTxt";
import {
  fetchSavedSearchResultAndRedirectToWithinToRefetch,
  locationSearchToObject
} from "../../../libs/functions/global.functions";
import $appSettings from "signals/AppSettings.signal";

const SearchBusinessResults = () => {
  const { dropdownFilters, businesses } = searchBusinessResultsSignal.value;
  const history = useHistory();
  const { location } = history;
  const currentSearchParameters = getRelevantSearchParams();
  const { PORTAL_TYPES } = $appSettings.value.constants;

  useEffect(() => {
    const search = locationSearchToObject(location.search);
    if (search.savedSearchResultId) {
      fetchSavedSearchResultAndRedirectToWithinToRefetch(
        search.savedSearchResultId
      );
    } else {
      fetchAndSetSearchResults();
    }
  }, [location.search]);

  return (
    <BreadCrumbWrapper>
      <SaveBusinessSearchModal handleSaveSearchClick={handleSaveSearchClick} />
      <div className="bg-white p-32 border border-gray shadow-sm">
        <h3 className="text-center">Business Search Results</h3>
        <hr />
        <div className="d-flex flex-row justify-content-between mb-8">
          <SimpleTableFilterDropdown
            filtersState={dropdownFilters}
            onChange={({ name, value }) => setDropdownFilter({ name, value })}
            onApply={() => {
              fetchAndSetSearchResults();
            }}
            onClearFilterInputs={resetBusinessSearchFilters}
            filters={[
              {
                type: 'integer',
                label: 'ID',
                key: 'id',
              },
              {
                type: 'select',
                label: 'Business Name',
                key: 'name',
                options: businesses
                  .filter((business) => !!business.name)
                  .map((business) => ({
                    value: business.id,
                    label: business.name,
                  })),
              },
              {
                type: 'select',
                label: 'Industry',
                key: 'industry',
                options: INDUSTRY_LIST.map((industry) => ({
                  value: industry,
                  label: industry,
                })),
              },
              {
                type: 'text',
                label: 'Location',
                key: 'location',
              },
              {
                type: 'select',
                label: 'Funding Range',
                key: 'fundingRange',
                options: SEEKING_FUNDING_PAYMENT_RANGES.map((fundingRange) => ({
                  value: fundingRange,
                  label: fundingRange,
                })),
              },
              {
                type: 'select',
                label: 'Favorites',
                key: 'favorite',
                options: [
                  {
                    value: 'yes',
                    label: 'Favorites',
                  },
                  {
                    value: 'no',
                    label: 'Not favorites',
                  },
                ],
              },
            ]}
          />

          <Button
            className="rounded"
            onClick={() =>
              handleShowSaveSearchFromResultsCreateModal(
                currentSearchParameters
              )
            }
            disabled={saveSearchButtonIsDisabled()}
          >
            Save this Search
          </Button>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center mb-8 bg-light p-8">
          <div>
            <h5>Searching By</h5>
            <div className="d-flex flex-row">
              {currentSearchParameters.map((searchItem) => (
                <p key={searchItem.label} className="mb-0 mr-16">
                  <b>{camelCaseToTitleText(searchItem.label)}:</b>{' '}
                  {searchItem.value}
                </p>
              ))}
            </div>
          </div>
        </div>
        <SimpleTable
          headers={[
            {
              key: 'id',
              title: 'ID',
              sortable: true,
            },
            {
              key: 'name',
              title: 'Business Name',
              sortable: true,
            },
            {
              key: 'businessType',
              title: 'Business Type',
            },
            {
              key: 'location',
              title: 'Location',
            },
            {
              key: 'industry',
              title: 'Industry',
            },
            {
              key: 'fundingRange',
              title: 'Funding Range',
            },
            {
              key: 'favorite',
              title: 'Favorite',
            },
            {
              key: 'action',
              title: 'Action',
            },
          ]}
          rows={businesses.map((business) => ({
            id: business.id,
            name: business.name || '-',
            businessType: business.businessType || '-',
            location: business.address?.formattedAddress || '-',
            industry:
              business.assistance.industrySelection?.length > 0
                ? business.assistance.industrySelection.join(', ')
                : '-',
            fundingRange: `${
              business.assistance?.seekingMin?.toLocaleString(
                'en-US',
                dateOptions.currencyUSNoDecimal
              ) || '?'
            } - ${
              business.assistance?.seekingMax?.toLocaleString(
                'en-US',
                dateOptions.currencyUSNoDecimal
              ) || '?'
            }`,
            connected: () => {
              const favorites =
                userAccountSignal.value.userData.account.portalType ===
                PORTAL_TYPES.lender
                  ? business.favoritedByLenders
                  : business.favoritedByVcs;
              const account = userAccountSignal.value.userData.account;
              const matchingFavorite = favorites.find(
                (c) =>
                  c.ventureCapitalFirmId === account.ventureCapitalFirm?.id ||
                  c.lenderId === account.lender?.id
              );
              if (matchingFavorite) {
                return (
                  <td
                    className="text-center text-white"
                    onClick={() => toggleModal(business)}
                  >
                    <Badge className="p-1" bg="success">
                      Favorite
                    </Badge>
                  </td>
                );
              }

              return (
                <td
                  className="text-center"
                  onClick={() => toggleModal(business)}
                >
                  -
                </td>
              );
            },
            action: ({ key }) => (
              <td key={key} className="text-right">
                <Button
                  variant="outline-dark"
                  size="sm"
                  className="rounded"
                  onClick={() => {
                    const path = `${location.pathname}/${business.id}`;
                    history.push(path);
                  }}
                >
                  Details
                </Button>
              </td>
            ),
          }))}
          onSort={() => {
            fetchAndSetSearchResults();
          }}
          onClick={(index) => toggleModal(businesses[index])}
        />
      </div>
      <SearchBusinessResultsModal />
    </BreadCrumbWrapper>
  );
};

export default SearchBusinessResults;
