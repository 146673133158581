import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Alert, Button, Modal } from 'react-bootstrap';
import { $edoCustomize } from '../edoCustomize.signal';
import { submitWhiteLabelChanges } from '../edoCustomize.helpers';

const SaveChangesModal = () => {
  const { changesPreviewed, modalVisible } = $edoCustomize.value;

  return (
    <ModalWrapper
      show={modalVisible === 'saveChanges'}
      centered
      onHide={() => $edoCustomize.update({ modalVisible: null })}
    >
      <Modal.Header>
        <h5>Save Changes</h5>
        <CloseModalButton
          onCloseModal={() => $edoCustomize.update({ modalVisible: null })}
        />
      </Modal.Header>
      <Modal.Body>
        {!changesPreviewed && (
          <Alert variant="warning">
            You have changes that you have not previewed. Close this window and click &quot;Preview Changes&quot; to view your changes.
          </Alert>
        )}
        Are you sure you want to save these changes?
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="mr-16"
          variant="outline-secondary"
          onClick={() => $edoCustomize.update({ modalVisible: null })}
        >
          Cancel
        </Button>
        <Button onClick={submitWhiteLabelChanges}>Save Changes</Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default SaveChangesModal;
