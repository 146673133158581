import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateOptions from "components/global/Constant/dateOptions";
import { checkmarkIcon } from "../../../../global/Constant/Icons";
import { Doughnut } from "react-chartjs-2";
import { generalDoughnutChartOptions } from "components/global/ChartJS/chartPresetsAndStyles";
import { businessMyLoansSignal } from "signals/BusinessMyLoans.signal";
import { getBusinessSummaryData } from "../../BusinessMyLoans/businessMyLoans.helpers";
import { useEffect } from "react";
import $appSettings from "signals/AppSettings.signal";

const BusinessApplicationProgress = ({ application }) => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const { businessSummaryChart, amountPaidBack } = businessMyLoansSignal.value;
  let progress = 0;

  useEffect(() => {
    getBusinessSummaryData(application);
  }, [application]);

  switch (application.status) {
    case APPLICATION_STATUS.approved:
      progress = 100;
      break;
    case APPLICATION_STATUS.submittedForApproval:
      progress = 67;
      break;
    case APPLICATION_STATUS.pending:
      progress = 33;
      break;
    default:
      break;
  }

  return (
    <>
      <Card className="mt-32 p-16">
        <Row>
          <Col>
            <p className="small m-0">Status</p>
            <h5>
              {application.status[0]}
              {application.status.slice(1).replace(/_/g, ' ').toLowerCase()}
            </h5>
          </Col>
          <Col>
            <p className="small m-0">Application ID</p>
            <h5>{application.id}</h5>
          </Col>
          <Col>
            <p className="small m-0">
              {application.status === APPLICATION_STATUS.approved
                ? 'Approved Amount'
                : 'Requested Amount'}
            </p>
            <h5>
              {application.status === APPLICATION_STATUS.approved
                ? application.approvedAmount?.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )
                : application.askedAmount?.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
            </h5>
          </Col>
          <Col hidden={!application.firstDisbursementDate}>
            <p className="small m-0">Expected Disbursement Date</p>
            <h5>
              {new Date(application.firstDisbursementDate).toLocaleDateString()}
            </h5>
          </Col>
        </Row>
        <hr className="mt-0" />
        <Row className="overflow-auto">
          <Col xs={12}>
            <p className="small">Progress</p>
            <Row className="flex-nowrap no-gutters mb-8">
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ flex: '1 1 30%' }}
              >
                <p className="mb-0 small">Completed Application</p>
                {progress > 0 && (
                  <FontAwesomeIcon
                    fontSize="small"
                    className="rounded-circle text-white bg-info ml-8 align-self-center"
                    icon={checkmarkIcon}
                  />
                )}
              </div>
              <div className="d-flex flex-row" style={{ flex: '1 1 30%' }}>
                <p className="mb-0 small">Submitted</p>
                {progress > 33 && (
                  <FontAwesomeIcon
                    fontSize="small"
                    className="rounded-circle text-white bg-info ml-8 align-self-center"
                    icon={checkmarkIcon}
                  />
                )}
              </div>
              <div className="d-flex flex-row" style={{ flex: '1 1 30%' }}>
                <p className="mb-0 small">In Review</p>
                {progress > 67 && (
                  <FontAwesomeIcon
                    fontSize="small"
                    className="rounded-circle text-white bg-info ml-8 align-self-center"
                    icon={checkmarkIcon}
                  />
                )}
              </div>
              <div className="d-flex flex-row justify-content-end">
                <p className="mb-0 small">Approved</p>
                {progress > 67 && (
                  <FontAwesomeIcon
                    fontSize="small"
                    className="rounded-circle text-white bg-info ml-8 align-self-center"
                    icon={checkmarkIcon}
                  />
                )}
              </div>
            </Row>
            <ProgressBar
              variant={
                application.status === APPLICATION_STATUS.approved
                  ? 'success'
                  : 'warning'
              }
              now={progress}
            />
          </Col>
        </Row>
        <hr />
        <Row className="align-items-end">
          <Col sm={6} lg={4} xl={3}>
            <p className="small mb-8">Details</p>
            <p className="small m-0">Submitted</p>
            <p className="small m-0">
              {new Date(application.committedDate).toLocaleString(
                'en-US',
                dateOptions.dateTime
              )}
            </p>
          </Col>
          <Col>
            <p className="small m-0">Last Updated</p>
            <p className="small m-0">
              {new Date(application.updatedDate).toLocaleString(
                'en-US',
                dateOptions.dateTime
              )}
            </p>
          </Col>
        </Row>
      </Card>
      <Row
        hidden={application.status !== APPLICATION_STATUS.approved}
        className="mt-16"
      >
        <Col>
          <Card>
            <h5 className="text-center">Summary</h5>
            <Row className="text-center">
              <Col>
                <p>
                  <b>Total Borrowing:</b>{' '}
                  {application.approvedAmount?.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </p>
              </Col>
              <Col>
                <p>
                  <b>Total Outstanding:</b>{' '}
                  {(application.approvedAmount - amountPaidBack).toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </p>
              </Col>
            </Row>
            <Doughnut
              style={{ maxHeight: 300 }}
              data={businessSummaryChart}
              options={generalDoughnutChartOptions}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BusinessApplicationProgress;
