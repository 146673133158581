import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import ProgramSignal, {
  fundingProgramFormErrorsSignal,
  fundingProgramsSignal,
} from 'signals/Program.signal';
import alertSignal from 'signals/Alert.signal';
import { selectedFundingProgramSignal } from '../../../../signals/Program.signal';
import { uploadFilesToStorage } from '../../../../libs/functions/global.functions';
import { fetchAndSetPrograms } from '../EdoPrograms/EdoPrograms.helpers';
import prevNextTabButtonsSignal from '../../../../signals/prevNextTabButtons.signal';
import {
  EDIT_PROGRAM_MODAL_EVENT_LIST,
  EDIT_PROGRAM_MODAL_EVENT_MAP,
} from '../../../global/Constant/tabEventKeys';
import history from '../../../../history';
import {
  validateFileBundle,
  validateFundingProgramField,
} from '../../../../libs/fundingProgram';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetProgram = async (programId) => {
  loaderSignal.update({ isContentLoading: true });
  if (!programId) return;
  try {
    const [fundingProgram, programTypes] = await Promise.all([
      api.get({
        path: '/fundingPrograms',
        options: {
          where: {
            id: programId,
          },
          include: {
            stats: true,
            administrator: true,
            platform: {
              include: {
                stats: true,
              },
            },
            supportingDocuments: true,
            referenceProgram: {
              include: {
                programType: true,
              },
            },
            bankAccountProgramAccesses: {
              include: {
                bankAccount: true,
              },
            },
            edoUserProgramAccesses: true,
            approvalChainTemplates: {
              orderBy: {
                id: 'desc',
              },
              include: {
                steps: {
                  orderBy: {
                    id: 'asc',
                  },
                  include: {
                    primaryApprover: true,
                    alternateApprovers: {
                      include: { user: true },
                    },
                  },
                },
              },
            },
          },
        },
      }),
      api.get({
        path: '/programtypes',
      }),
    ]);

    selectFundingProgramForEditing(fundingProgram);
    ProgramSignal.update({ programTypes });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const selectFundingProgramForEditing = (
  fundingProgram,
  activeKey = EDIT_PROGRAM_MODAL_EVENT_MAP.basicInfo
) => {
  const { DOCUMENT_TYPES } = $appSettings.value.constants;
  const { programConfig } = fundingProgram;

  prevNextTabButtonsSignal.update({
    activeKey,
    eventKeys: EDIT_PROGRAM_MODAL_EVENT_LIST,
  });
  return selectedFundingProgramSignal.update({
    programInfo: {
      ...fundingProgram,
      programTypeId: fundingProgram.referenceProgram.programTypeId,
      programRequestTemplates: fundingProgram.supportingDocuments.filter(
        (doc) => doc.documentType === DOCUMENT_TYPES.programRequestTemplate
      ),
      programRequestSuggestedDocuments:
        fundingProgram.supportingDocuments.filter(
          (doc) => doc.documentType === DOCUMENT_TYPES.programRequestSuggested
        ),
      applicationTemplates: fundingProgram.supportingDocuments.filter(
        (doc) => doc.documentType === DOCUMENT_TYPES.applicationTemplate
      ),
      applicationSuggestedDocuments: fundingProgram.supportingDocuments.filter(
        (doc) => doc.documentType === DOCUMENT_TYPES.applicationSuggested
      ),
      fundType: programConfig.fundType,
      maxCollateralSupportPercentage:
        programConfig.maxCollateralSupportPercentage,
      minCollateralSupportPercentage:
        programConfig.minCollateralSupportPercentage,
      programClassification: programConfig.programClassification,
      edoParticipationPercentage: programConfig.edoParticipationPercentage,
      guarantyAmountBackedByCashPercentage:
        programConfig.guarantyAmountBackedByCashPercentage,
      ineligibleBusinesses: fundingProgram.ineligibleBusinesses,
      approvalChainTemplates: fundingProgram.approvalChainTemplates,
      minGuarantyPercentage: programConfig.minGuarantyPercentage,
      maxGuarantyPercentage: programConfig.maxGuarantyPercentage,
      fundsUnderCustodyAt: programConfig.fundsUnderCustodyAt,
      rebalancingFrequency: programConfig.rebalancingFrequency,
    },
  });
};

export const updateFundingProgramBasicInfo = () =>
  updateFundingProgram(
    {
      ceiling: selectedFundingProgramSignal.value.programInfo.ceiling,
      ineligibleBusinesses:
        selectedFundingProgramSignal.value.programInfo.ineligibleBusinesses,
      programRules: selectedFundingProgramSignal.value.programInfo.programRules,
      eligibility: selectedFundingProgramSignal.value.programInfo.eligibility,
      fundsAlreadyAllocated:
        selectedFundingProgramSignal.value.programInfo.fundsAlreadyAllocated,
      name: selectedFundingProgramSignal.value.programInfo.name,
      overview: selectedFundingProgramSignal.value.programInfo.overview,
      referenceProgramId:
        selectedFundingProgramSignal.value.programInfo.referenceProgramId,
      website: selectedFundingProgramSignal.value.programInfo.website,
      programConfig: {
        fundsUnderCustodyAt:
          selectedFundingProgramSignal.value.programInfo.fundsUnderCustodyAt,
        rebalancingFrequency:
          selectedFundingProgramSignal.value.programInfo.rebalancingFrequency,
        maxCollateralSupportPercentage:
          selectedFundingProgramSignal.value.programInfo
            .maxCollateralSupportPercentage,
        minCollateralSupportPercentage:
          selectedFundingProgramSignal.value.programInfo
            .minCollateralSupportPercentage,
        guarantyAmountBackedByCashPercentage:
          selectedFundingProgramSignal.value.programInfo
            .guarantyAmountBackedByCashPercentage,
        edoParticipationPercentage:
          selectedFundingProgramSignal.value.programInfo
            .edoParticipationPercentage,
        programClassification:
          selectedFundingProgramSignal.value.programInfo.programClassification,
        minGuarantyPercentage:
          selectedFundingProgramSignal.value.programInfo.minGuarantyPercentage,
        maxGuarantyPercentage:
          selectedFundingProgramSignal.value.programInfo.maxGuarantyPercentage,
      },
    },
    EDIT_PROGRAM_MODAL_EVENT_MAP.basicInfo
  );

const updateFundingProgram = async (payload, activeTab) => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating program...',
  });
  try {
    await api.patch({
      path: '/fundingPrograms',
      body: {
        where: {
          id: selectedFundingProgramSignal.value.programInfo.id,
        },
        data: payload,
      },
    });
    await fetchAndSetPrograms();
    const updatedSelectedFundingProgram = fundingProgramsSignal.value.find(
      (fundingProgram) =>
        fundingProgram.id === selectedFundingProgramSignal.value.programInfo.id
    );
    selectFundingProgramForEditing(updatedSelectedFundingProgram, activeTab);
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully updated program.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleInputChange = (e) => {
  const { name, value } = e.target;
  const { programInfo } = selectedFundingProgramSignal.value;

  if (name === 'programTypeId') {
    selectedFundingProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: Number(value),
        referenceProgramId: null,
      },
    });
  } else if (name === 'referenceProgramId') {
    selectedFundingProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: Number(value),
      },
    });
  } else {
    selectedFundingProgramSignal.update({
      programInfo: {
        ...programInfo,
        [name]: value,
      },
    });
  }
};

export const removeFundingProgramDocument = async (id) => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Removing document...',
  });
  try {
    await api.delete({
      path: '/fundingProgramDocuments',
      body: {
        where: { id },
      },
    });
    await handleRefresh();
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully removed document.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleEditProgramFileUpdate = (
  index,
  payload,
  name,
  documentType
) => {
  const uploadedFiles = selectedFundingProgramSignal.value.programInfo[
    name
  ].map((f, i) => {
    if (i === index) {
      return { ...f, ...payload };
    }
    return f;
  });

  fundingProgramFormErrorsSignal.update({ [`${documentType}${index}`]: false });

  return selectedFundingProgramSignal.update({
    programInfo: {
      ...selectedFundingProgramSignal.value.programInfo,
      [name]: uploadedFiles,
    },
  });
};

export const handleEditProgramFileRemoval = async (index, documentTypeName) => {
  const uploadedFiles = selectedFundingProgramSignal.value.programInfo[
    documentTypeName
  ].filter((f, i) => i !== index);

  const file =
    selectedFundingProgramSignal.value.programInfo[documentTypeName][index];

  if (file.id) {
    return removeFundingProgramDocument(file.id);
  } else {
    fundingProgramFormErrorsSignal.update({
      [`${file.documentType}${index}`]: false,
    });
    return selectedFundingProgramSignal.update({
      programInfo: {
        ...selectedFundingProgramSignal.value.programInfo,
        [documentTypeName]: uploadedFiles,
      },
    });
  }
};

export const submitNewFundingProgramDocuments = async (fileBundle, idx) => {
  const { DOCUMENT_TYPES } = $appSettings.value.constants;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting document(s)...',
  });
  try {
    if (!fileBundle.name) {
      return fundingProgramFormErrorsSignal.update({
        [`${fileBundle.documentType}${idx}`]: 'A name is required.',
      });
    }

    const documentType = fileBundle.documentType;
    if (
      documentType === DOCUMENT_TYPES.programRequestTemplate ||
      documentType === DOCUMENT_TYPES.applicationTemplate
    ) {
      if (!fileBundle.file) {
        return fundingProgramFormErrorsSignal.update({
          [`${fileBundle.documentType}${idx}`]: 'A file is required.',
        });
      }
    }

    const uploadedFiles = [fileBundle];
    const signedUrls = await uploadFilesToStorage(
      uploadedFiles
        .filter((bundle) => bundle.file)
        .map((bundle) => bundle.file),
      'fundingProgramDocument'
    );

    const body = {
      data: uploadedFiles.map((uploadedFile, i) => ({
        fileType: uploadedFile.file ? uploadedFile.file.type : null,
        filePath: uploadedFile.file ? signedUrls[i] : null,
        documentType: uploadedFile.documentType,
        name: uploadedFile.name,
        fundingProgramId: selectedFundingProgramSignal.value.programInfo.id,
      })),
    };

    await api.post({ path: '/fundingProgramDocuments', body });

    await handleRefresh();
    selectedFundingProgramSignal.update({
      programInfo: {
        ...selectedFundingProgramSignal.value.programInfo,
        uploadedFiles: [],
      },
    });

    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully added new documents.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const handleRefresh = async () => {
  await fetchAndSetPrograms();
  await selectFundingProgramForEditing(
    fundingProgramsSignal.value.find(
      (mp) => mp.id === selectedFundingProgramSignal.value.programInfo.id
    ),
    EDIT_PROGRAM_MODAL_EVENT_MAP.applicationDocuments
  );
};

export const handleInputFocusOut = (e) => {
  const { programInfo } = selectedFundingProgramSignal.value;
  const error = validateFundingProgramField(
    e.target.name,
    e.target.value,
    programInfo,
    programInfo.platform,
    programInfo.ceiling - programInfo.fundsAlreadyAllocated
  );

  fundingProgramFormErrorsSignal.update({
    [e.target.name]: error,
  });
};

export const handleDocumentUploadInputFocusOut = (e, idx, documentTypeName) => {
  const { name } = e.target;
  const uploadedFiles =
    selectedFundingProgramSignal.value.programInfo[documentTypeName];
  const file = uploadedFiles[idx];
  const error = validateFileBundle(name, file);
  fundingProgramFormErrorsSignal.update({
    [name]: error,
  });
};

export const handleAddSuggestedDocument = (documentTypeName, documentType) => {
  selectedFundingProgramSignal.update({
    programInfo: {
      ...selectedFundingProgramSignal.value.programInfo,
      [documentTypeName]: [
        ...selectedFundingProgramSignal.value.programInfo[documentTypeName],
        {
          name: '',
          documentType,
        },
      ],
    },
  });
};

export const fetchAndSetEnrolledOrganizations = async () => {
  const { programInfo } = selectedFundingProgramSignal.value;
  if (!programInfo) return;

  const path =
    programInfo.referenceProgram.programType.programTypeName === 'Credit'
      ? '/lenders'
      : '/ventureCapitalFirms';

  try {
    loaderSignal.update({ isContentLoading: true });
    const enrolledOrganizations = await api.get({
      path,
      options: {
        where: {
          ...xFormAppliedFiltersToWhereClause(),
          fundingProgramMemberships: {
            some: {
              fundingProgramId: programInfo.id,
            },
          },
        },
        orderBy: xFormSortToOrderByClause(),
        include: {
          fundingProgramMemberships: {
            include: {
              fundingProgram: true,
              funds: true,
            },
          },
        },
      },
    });

    selectedFundingProgramSignal.update({ enrolledOrganizations });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const resetEnrolledOrganizationsFilterInputs = () =>
  selectedFundingProgramSignal.update({ dropdownFilters: {} });

export const handleEnrolledOrganizationsFilterChange = (key, value) => {
  const dropdownFilters = selectedFundingProgramSignal.value.dropdownFilters;
  return selectedFundingProgramSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

export const handleEnrolledOrganizationsAppliedFiltersChange = (
  appliedFilters
) =>
  selectedFundingProgramSignal.update({
    appliedFilters,
  });

const xFormAppliedFiltersToWhereClause = () => {
  const where = {};

  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  if (params.name) {
    where.name = { contains: params.name, mode: 'insensitive' };
  }

  if (params.email) {
    where.email = { contains: params.email, mode: 'insensitive' };
  }

  if (params.contactName) {
    where.contactName = { contains: params.contactName, mode: 'insensitive' };
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } = selectedFundingProgramSignal.value;

  if (!sort) {
    return undefined;
  }

  return [{ [sort.key]: sort.dir }];
};

export const handleEnrolledOrganizationsSortChange = (sort) =>
  selectedFundingProgramSignal.update({
    sort,
  });

export const handleOrganizationDetailClick = (organization, modalView) => {
  const { programInfo } = selectedFundingProgramSignal.value;
  const fundingProgramMembership = organization.fundingProgramMemberships.find(
    (fpm) => fpm.fundingProgramId === programInfo.id
  );

  if (programInfo.referenceProgram.programType.programTypeName === 'Credit') {
    if (!modalView) {
      history.push(
        `${history.location.pathname}/lender/program-membership/${fundingProgramMembership.id}`
      );
    } else {
      history.push(
        `${history.location.pathname}/${programInfo.id}/lender/program-membership/${fundingProgramMembership.id}`
      );
    }
  } else {
    if (!modalView) {
      history.push(
        `${history.location.pathname}/vc/program-membership/${fundingProgramMembership.id}`
      );
    } else {
      history.push(
        `${history.location.pathname}/${programInfo.id}/vc/program-membership/${fundingProgramMembership.id}`
      );
    }
  }
};
