import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import dateOptions from 'components/global/Constant/dateOptions';
import {
  hideUserRequestModal,
  sendOrDenyUserRequest,
  updateUserInvitePortal,
} from './SendInviteUserModal.helpers';
import userRequestsSignal from '../../UserRequests.signal';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';

const SendInviteUserModal = () => {
  const { action } = userRequestsSignal.value;
  const isApproveModal = action === 'approve';

  return (
    <ModalWrapper
      centered
      size="lg"
      show={!!userRequestsSignal.value.selectedUserRequest}
    >
      <ModalHeader className="border-0 d-flex justify-content-between">
        <h5>{isApproveModal ? 'Approve' : 'Deny'} User Request</h5>
        <CloseModalButton onCloseModal={hideUserRequestModal} />
      </ModalHeader>
      <ModalBody className="px-16">
        <Row>
          <Col xs={12} lg>
            <p className="fw-bold">
              Name:
              <span className="fw-italic">
                {' '}
                {userRequestsSignal.value?.selectedUserRequest?.firstName}{' '}
                {userRequestsSignal.value?.selectedUserRequest?.lastName}
              </span>
            </p>
          </Col>
          <Col xs={12} lg>
            <p className="fw-bold">
              Organization:
              <span className="fw-italic">
                {' '}
                {
                  userRequestsSignal.value?.selectedUserRequest
                    ?.organizationName
                }
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg>
            <p>{`Email: ${userRequestsSignal.value?.selectedUserRequest?.email}`}</p>
          </Col>
          <Col xs={12} lg>
            {userRequestsSignal.value?.selectedUserRequest?.portal !==
            'NONE' ? (
              <p>{`Portal Type: ${userRequestsSignal.value?.selectedUserRequest?.portal}`}</p>
            ) : (
              <>
                <Form.Select
                  name="portal"
                  onChange={(e) => {
                    updateUserInvitePortal(e);
                  }}
                >
                  <option> Please Select Portal</option>
                  <option value="EDO">EDO</option>
                  <option value="BUSINESS">Business</option>
                  <option value="STATE">State</option>
                  <option value="LENDER">Lender</option>
                  <option value="VC">VC</option>
                </Form.Select>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{`Request Date: ${new Date(
              userRequestsSignal.value?.selectedUserRequest?.createdDate
            ).toLocaleString('en-US', dateOptions.dateTime)}`}</p>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <h6>Notes:</h6>
            <div className="bg-light d-block p-4">
              {userRequestsSignal.value.selectedUserRequest?.notes}
            </div>
          </Col>
        </Row> */}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button size="sm" variant="secondary" onClick={hideUserRequestModal}>
          Cancel
        </Button>
        <Button
          size="sm"
          variant={isApproveModal ? 'dark' : 'danger'}
          disabled={loaderSignal.value.isContentLoading}
          onClick={sendOrDenyUserRequest}
        >
          {isApproveModal ? 'Approve' : 'Deny'} Request
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default SendInviteUserModal;
