import formatMoney from 'utils/formatMoney';
import SimpleTable from '../SimpleTable';
import { enumCaseToTabCase } from 'libs/string';
import $capTable from './capTable.signal';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkmarkIcon, closeIcon, editIcon } from '../Constant/Icons';
import {
  calculateTransactionValue,
  handleAddRowClick,
  handleCancelFieldEdit,
  handleCapTableCellClick,
  handleCapTableInputChange,
  handleConfirmEditClick,
  handleEnterKeyPress,
  handleRemoveSelectedClick,
  handleRowCheckboxClick,
  handleSaveCapTableClick,
  handleTabKeyPress,
} from './capTable.helpers';
import { Alert, Button } from 'react-bootstrap';
import { camelCaseToTitleText } from 'utils/formatTxt';
import { FieldComponent } from '../Field/Field';
import { SECURITY_TYPE } from '../Constant/constants';

const BASE_HEADERS = [
  {
    key: 'security',
    title: 'Security',
  },
  {
    key: 'stakeholder',
    title: 'Stakeholder',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'rounds',
    title: 'Rounds',
  },
  {
    key: 'shares',
    title: 'Shares',
  },
  {
    key: 'sharePrice',
    title: 'Price/Share',
  },
  {
    key: 'transactionalValue',
    title: 'Transactional Value',
  },
  {
    key: 'fullyDiluted',
    title: 'Fully Diluted',
  },
  {
    key: 'ownership',
    title: 'Ownership',
  },
];

const EDITING_HEADERS = [
  {
    key: 'check',
    title: ' ',
  },
  ...BASE_HEADERS,
];

const roundsOptions = [
  { label: 'Pre-seed funding', value: 'Pre-seed funding' },
  { label: 'Seed funding', value: 'Seed funding' },
  { label: 'Series A funding', value: 'Series A funding' },
  { label: 'Series B funding', value: 'Series B funding' },
  { label: 'Series C funding', value: 'Series C funding' },
];

const statusOptions = [
  { label: 'Outstanding', value: 'OUTSTANDING' },
  { label: 'Sent', value: 'SENT' },
];

const CapTableCell = ({ rowIndex, field, value, fieldType, align }) => {
  const [hovering, setHovering] = useState(false);
  const inputRef = useRef(null);
  const { editingRowIndex, editingField, canEdit } = $capTable.value;
  const onMouseOver = () => setHovering(true);
  const onMouseOut = () => setHovering(false);

  useEffect(() => {
    if (editingRowIndex === rowIndex && editingField === field) {
      inputRef?.current?.focus();
    }
  }, [editingRowIndex, editingField]);

  if (canEdit && editingRowIndex === rowIndex && editingField === field) {
    let options = [];
    if (field === 'rounds') {
      options = roundsOptions;
    } else if (field === 'status') {
      options = statusOptions;
    } else if (field === 'security') {
      options = Object.values(SECURITY_TYPE).map((type) => ({
        label: type,
        value: type,
      }));
    }

    return (
      <td className="align-middle">
        <div
          className={`d-flex flex-row flex-nowrap align-items-center ${
            align === 'right' ? 'justify-content-end' : ''
          }`}
        >
          <FieldComponent
            type={fieldType}
            value={value}
            name={field}
            options={options}
            onChange={handleCapTableInputChange}
            inputClassName="form-control cap-table-input"
            allowCents={fieldType === 'currency'}
            inputRef={inputRef}
            onBlur={
              field === 'shares' || field === 'sharePrice'
                ? () => calculateTransactionValue(rowIndex)
                : () => null
            }
          />
          <div className="d-flex flex-column ml-8">
            <FontAwesomeIcon
              icon={checkmarkIcon}
              className="text-success pointer mb-8"
              onClick={handleConfirmEditClick}
            />
            <FontAwesomeIcon
              icon={closeIcon}
              className="text-danger pointer"
              onClick={handleCancelFieldEdit}
            />
          </div>
        </div>
      </td>
    );
  }

  if (!canEdit || editingRowIndex !== rowIndex || editingField !== field) {
    let formattedValue = value;
    switch (field) {
      case 'status':
        formattedValue = enumCaseToTabCase(value);
        break;
      case 'sharePrice':
      case 'transactionalValue':
        formattedValue = value ? formatMoney(value) : '';
        break;
      case 'shares':
      case 'fullyDiluted':
        formattedValue = Number(value).toLocaleString();
        break;
      case 'ownership':
        formattedValue = value ? `${value}%` : '';
        break;
      default:
        break;
    }

    return (
      <td
        className={`${canEdit ? 'pointer' : ''}`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={() => {
          if (canEdit) {
            handleCapTableCellClick(rowIndex, field, value);
            setHovering(false);
          }
        }}
      >
        <div
          className={`d-flex flex-row flex-nowrap align-items-center ${
            align === 'right' ? 'justify-content-end' : ''
          }`}
        >
          <p className="text-nowrap mb-0 text-right">
            {formattedValue ? (
              formattedValue
            ) : (
              <i className="text-muted">{camelCaseToTitleText(field)}</i>
            )}
          </p>
          {canEdit && (
            <FontAwesomeIcon
              className="ml-8"
              visibility={hovering ? '' : 'hidden'}
              icon={editIcon}
            />
          )}
        </div>
      </td>
    );
  }
};

const CapTable = () => {
  const { capTable, selectedRows, canEdit } = $capTable.value;

  const handleKeyPress = (e) => {
    if (e.key === 'Tab') {
      handleTabKeyPress();
    } else if (e.key === 'Enter') {
      handleEnterKeyPress();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div>
      {!capTable.length && (
        <>
          {canEdit ? (
            <Alert>
              <h5>Configure Cap Table</h5>
              <p>
                This Cap Table has not been configured. Add a row by clicking
                the Add + button.
              </p>
            </Alert>
          ) : (
            <Alert variant="warning">
              <h5>Cap Table Missing</h5>
              <p>
                The Cap Table has not been configured by the business owner.
              </p>
            </Alert>
          )}
        </>
      )}
      {canEdit && (
        <div className="d-flex justify-content-end mb-16">
          <Button
            disabled={!selectedRows.length}
            size="sm"
            variant="outline-danger"
            className="mr-16"
            onClick={handleRemoveSelectedClick}
          >
            Remove Selected
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={handleAddRowClick}
          >
            Add +
          </Button>
        </div>
      )}
      {!!capTable.length && (
        <SimpleTable
          headers={canEdit ? EDITING_HEADERS : BASE_HEADERS}
          rows={capTable.map((capData, index) => ({
            check: () =>
              canEdit ? (
                <td
                  valign="middle"
                  align="center"
                  className="sticky-column bg-light"
                >
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(index)}
                    onChange={() => handleRowCheckboxClick(index)}
                  />
                </td>
              ) : null,
            security: () => (
              <CapTableCell
                field="security"
                rowIndex={index}
                value={capData.security}
                fieldType="select"
                canEdit={canEdit}
              />
            ),
            stakeholder: () => (
              <CapTableCell
                field="stakeholder"
                rowIndex={index}
                value={capData.stakeholder}
                fieldType="text-input"
                canEdit={canEdit}
              />
            ),
            status: () => (
              <CapTableCell
                field="status"
                rowIndex={index}
                value={capData.status}
                fieldType="select"
                canEdit={canEdit}
              />
            ),
            rounds: () => (
              <CapTableCell
                field="rounds"
                rowIndex={index}
                value={capData.rounds}
                fieldType="select"
                canEdit={canEdit}
              />
            ),
            shares: () => (
              <CapTableCell
                field="shares"
                rowIndex={index}
                value={capData.shares}
                fieldType="integer"
                canEdit={canEdit}
              />
            ),
            sharePrice: () => (
              <CapTableCell
                field="sharePrice"
                rowIndex={index}
                value={capData.sharePrice}
                fieldType="currency"
                canEdit={canEdit}
              />
            ),
            transactionalValue: formatMoney(capData.transactionalValue || 0),
            fullyDiluted: () => (
              <CapTableCell
                field="fullyDiluted"
                rowIndex={index}
                value={capData.fullyDiluted}
                fieldType="integer"
                canEdit={canEdit}
              />
            ),
            ownership: () => (
              <CapTableCell
                field="ownership"
                rowIndex={index}
                value={capData.ownership}
                fieldType="percent"
                align="right"
                canEdit={canEdit}
              />
            ),
          }))}
        />
      )}
      {canEdit && (
        <div className="text-right mt-24">
          <Button onClick={handleSaveCapTableClick}>Save Changes</Button>
          <br />
          <small>
            * These changes will not be applied to any capital calls that have
            moved past the pending status, but they will apply to your current
            Cap Table and any capital calls in the pending status.
          </small>
        </div>
      )}
    </div>
  );
};

export default CapTable;
