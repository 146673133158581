import { Button, Col, Form, Row } from 'react-bootstrap';
import FilePreviewButton from '../utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import AddAttachmentsButton from '../AddAttachmentsButton';

type AttachmentListFormAttachment = {
  name: string;
  file: File | null;
};

type Props = {
  attachments: AttachmentListFormAttachment[];
  onRemove: (index: number) => any;
  onReplace?: (index: number, file: File) => any;
  onChange: (index: number, name: string) => any;
};

const AttachmentListForm: React.FC<Props> = ({
  onRemove,
  onChange,
  onReplace,
  attachments,
  // eslint-disable-next-line
}) => {
  return (
    <>
      {attachments.map((attachment, index) => {
        const name = attachment.name;
        return (
          <Row key={index} className="mt-1">
            <Col xs={6} lg={9}>
              <Form.Control
                type="text"
                className="form-control"
                name={`attachment[${index}]`}
                placeholder="Enter attachment name"
                value={name}
                onChange={({ target: { value } }) => onChange(index, value)}
              />
            </Col>
            <Col xs={6} lg={3} className="text-right">
              <Button
                size="sm"
                className="mr-8"
                variant="outline-danger"
                onClick={() => onRemove(index)}
              >
                Remove
              </Button>
              <FilePreviewButton
                render={(_submitting, fn) => (
                  <Button
                    size="sm"
                    disabled={!attachment.file}
                    className="rounded"
                    variant="outline-info"
                    onClick={() => fn(attachment.file!, '')}
                  >
                    Preview
                  </Button>
                )}
              />
              {onReplace && (
                <AddAttachmentsButton
                  multiple={false}
                  // @ts-ignore
                  onChange={(e) => onReplace(index, e.target.files[0])}
                  text={attachment.file ? 'Replace' : 'Attach'}
                />
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default AttachmentListForm;
