import withPrivateRoute from './withPrivateRoute';
import PublicRouteImport from './PublicRoute';
import $appSettings from 'signals/AppSettings.signal';

const { PORTAL_TYPES } = $appSettings.value.constants;
export const BusinessRoute = withPrivateRoute(PORTAL_TYPES.business);
export const EdoRoute = withPrivateRoute(PORTAL_TYPES.edo);
export const LenderRoute = withPrivateRoute(PORTAL_TYPES.lender);
export const PublicRoute = PublicRouteImport;
export const StateRoute = withPrivateRoute(PORTAL_TYPES.state);
export const VCRoute = withPrivateRoute(PORTAL_TYPES.vc);
export const ExecutiveRoute = withPrivateRoute(PORTAL_TYPES.executive);
