import React, { useState } from 'react';
import dateOptions from 'components/global/Constant/dateOptions';
import { Alert, Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import capitalizeFirst from 'utils/capitalizeFirst';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import {
  handleApplicationStatusDropdownChange,
  handleSendNoteSubmission,
} from './ApprovableStatusTab.helpers';
import userAccountSignal from 'signals/UserAccount.signal';
import ConfirmStatusModal from '../../ApplicationDetail/components/ApplicationConfirmStatusModal/ApplicationConfirmStatusModal';
import loaderSignal from 'signals/Loader.signal';
import FilePreviewButton from '../../utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { showConfirmStatusModal } from '../../ProgramRequestDetail/ProgramRequestDetail.helpers';
import {
  getFundingProgramTemplates,
  templateExists,
} from '../Approvable.helpers';
import { ApplicationNote, ProgramRequestNote } from '../../../../types';
import $appSettings from 'signals/AppSettings.signal';
import { handleSendTechnicalAssistanceClick } from 'components/views/Edo/EdoTechnicalAssistance/edoTechnicalAssistance.helpers';

const ApprovableStatusTab = () => {
  const {
    id,
    newApplicationStatus,
    notes,
    status,
    modelType,
    approvalChainTemplates,
    documents,
    fundingProgram,
    watchableId,
    usesCaatPayment,
    hasLinkedBankAccount,
    uploadDocumentType,
    ventureCapitalist,
    technicalAssistance,
  } = applicationDetailSignal.value;
  const { userData } = userAccountSignal.value;
  const { APPLICATION_STATUS, APPLICATION_DOCUMENT_STATUS, PORTAL_TYPES, DOCUMENT_TYPES } = $appSettings.value.constants;
  const { isContentLoading } = loaderSignal.value;
  const allDocumentsRead = documents.every(
    (d) => d.status === APPLICATION_DOCUMENT_STATUS?.read
  );

  const fundingProgramTemplates = getFundingProgramTemplates(
    fundingProgram,
    modelType === 'application'
      ? DOCUMENT_TYPES.applicationTemplate
      : DOCUMENT_TYPES.programRequestTemplate
  );

  const hasApplicationDocument = templateExists(
    documents,
    fundingProgramTemplates
  );

  const [addNoteFormVisible, setAddFormVisible] = useState<boolean>(false);
  const [noteBody, setNoteBody] = useState<string>('');

  return (
    <>
      <Row className="mt-16">
        <Col>
          <h5>
            Current Status:{' '}
            {(status && status === APPLICATION_STATUS?.inProgress
              ? 'Draft'
              : capitalizeFirst(status).replace(/_/g, ' ')) || ''}{' '}
            {watchableId ? '(watching)' : ''}
          </h5>
          {status &&
            status === APPLICATION_STATUS?.denied &&
            userData.account.portalType === PORTAL_TYPES.edo && (
              <Button
                variant="outline-primary"
                onClick={() =>
                  handleSendTechnicalAssistanceClick(
                    id,
                    technicalAssistance?.id
                  )
                }
              >
                Send Technical Assistance
              </Button>
            )}
        </Col>
        <Col
          hidden={userData.account.portalType !== PORTAL_TYPES.edo}
        >
          <Alert
            variant="danger"
            hidden={
              !modelType ||
              modelType === 'programRequest' ||
              hasApplicationDocument
            }
          >
            An application document is missing!
          </Alert>
          <Alert
            variant={allDocumentsRead ? 'success' : 'warning'}
            hidden={
              userData.account.portalType !== PORTAL_TYPES.edo ||
              modelType === 'programRequest' ||
              approvalChainTemplates.length < 1 ||
              !hasApplicationDocument
            }
          >
            <p className="mb-0">
              {allDocumentsRead
                ? 'All documents have been read.'
                : 'All documents must be read before the application can be submitted for approval.'}
            </p>
          </Alert>
          <Alert
            variant="warning"
            hidden={
              userData.account.portalType !== PORTAL_TYPES.edo ||
              modelType === 'programRequest' ||
              approvalChainTemplates.length > 0
            }
          >
            <p className="mb-0">
              There are no approval chain templates for this program. Approval
              chain templates can be added in the programs tab.
            </p>
          </Alert>
          <Alert
            variant="danger"
            hidden={
              userData.account.portalType !== PORTAL_TYPES.edo ||
              modelType === 'programRequest' ||
              !usesCaatPayment ||
              hasLinkedBankAccount ||
              status === APPLICATION_STATUS?.approved ||
              status === APPLICATION_STATUS?.submittedForApproval
            }
          >
            The lender has not linked a bank account to this program. A bank
            account must be linked before an application can be submitted for
            approval.
          </Alert>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold">Update Status</Form.Label>
            <br />
            <Form.Select
              disabled={
                (modelType === 'application' &&
                  approvalChainTemplates.length < 1) ||
                status === APPLICATION_STATUS?.denied ||
                (modelType === 'application' &&
                  status === APPLICATION_STATUS?.submittedForApproval) ||
                status === APPLICATION_STATUS?.approved ||
                status === APPLICATION_STATUS?.inProgress
              }
              className="form-control"
              value={newApplicationStatus}
              name="newApplicationStatus"
              onChange={handleApplicationStatusDropdownChange}
            >
              <option value="">Select New Status</option>
              {modelType === 'programRequest' &&
                (ventureCapitalist ? (
                  status === APPLICATION_STATUS?.submittedForApproval ? (
                    <option value="APPROVED">Approved</option>
                  ) : (
                    <option value="SUBMITTED_FOR_APPROVAL">
                      Submit for Approval
                    </option>
                  )
                ) : (
                  <option value="APPROVED">Approved</option>
                ))}
              {modelType === 'application' && (
                <option value="SUBMITTED_FOR_APPROVAL">
                  Submit for Approval
                </option>
              )}
              <option value="DENIED">Denied</option>
            </Form.Select>
          </Form.Group>
          <Row className="px-16 justify-content-end">
            <Button
              disabled={
                (modelType === 'application' &&
                  approvalChainTemplates.length < 1) ||
                newApplicationStatus === '' ||
                status === APPLICATION_STATUS?.denied ||
                (modelType === 'application' &&
                  status === APPLICATION_STATUS?.submittedForApproval) ||
                status === APPLICATION_STATUS?.approved ||
                status === APPLICATION_STATUS?.inProgress ||
                isContentLoading ||
                (modelType === 'application' && !hasApplicationDocument) ||
                (modelType === 'application' &&
                  usesCaatPayment &&
                  !hasLinkedBankAccount)
              }
              className="rounded"
              onClick={() => {
                if (modelType === 'application') {
                  applicationDetailSignal.update({
                    confirmStatusVisible: true,
                  });
                } else {
                  showConfirmStatusModal();
                }
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="mt-16">
        <Col>
          <h5>Notes</h5>
          <Card
            style={{ height: 200, overflowY: 'scroll' }}
            className="px-16 py-8 rounded-0"
          >
            {notes.map((note) => (
              <div
                className="bg-light rounded p-1 mb-4"
                key={
                  (note as ProgramRequestNote).id ||
                  (note as ApplicationNote).uuid
                }
              >
                <p>
                  {new Date(note.createdDate).toLocaleString(
                    'en-US',
                    // @ts-ignore
                    dateOptions.date.year
                  )}{' '}
                  - {note.content}
                </p>
                {(note.attachments || []).map((attachment, index) => (
                  // @ts-ignore
                  <React.Fragment key={attachment.uuid}>
                    <FilePreviewButton
                      render={(submitting, fn) => (
                        <span
                          className="text-secondary mr-2"
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() =>
                            fn(
                              { ...attachment, parentId: id },
                              uploadDocumentType!
                            )
                          }
                        >
                          {`See attachment ${index}`}
                        </span>
                      )}
                    />
                  </React.Fragment>
                ))}
              </div>
            ))}
          </Card>
          <Collapse in={addNoteFormVisible}>
            <div>
              <p className="my-8 font-weight-bold">New Note</p>
              <Form.Control
                as="textarea"
                value={noteBody}
                onChange={(e) => setNoteBody(e.target.value)}
              />
            </div>
          </Collapse>
          {!addNoteFormVisible && (
            <Row className="justify-content-end pr-16">
              <Button
                disabled={
                  status === APPLICATION_STATUS?.inProgress ||
                  status === APPLICATION_STATUS?.denied ||
                  isContentLoading
                }
                variant="outline-primary"
                className="rounded mt-8"
                onClick={() => setAddFormVisible(true)}
              >
                New Note
              </Button>
            </Row>
          )}
          {addNoteFormVisible && (
            <Row className="justify-content-end pr-16">
              <Button
                variant="outline-secondary"
                className="rounded mt-8 mr-8"
                onClick={() => setAddFormVisible(false)}
              >
                Close Section
              </Button>
              <Button
                disabled={
                  status === APPLICATION_STATUS?.denied ||
                  isContentLoading ||
                  !noteBody
                }
                variant="outline-primary"
                className="rounded mt-8"
                onClick={() =>
                  handleSendNoteSubmission(noteBody).then(() => setNoteBody(''))
                }
              >
                Send Note
              </Button>
            </Row>
          )}
        </Col>
      </Row>
      <ConfirmStatusModal />
    </>
  );
};

export default ApprovableStatusTab;
