import { Button, Col, Row } from 'react-bootstrap';
import edoUsersSignal from 'signals/EdoUsers.signal';
import AddUserModal from 'components/global/UserManagement/components/AddUserModal/AddUserModal';
import { fetchAndSetUserInvites } from 'components/global/UserInvitations/UserInvitations.helpers';
import { showAddInternalUserModal } from 'components/global/UserManagement/components/AddUserModal/AddUserModal.helpers';
import UserActivationModal from 'components/global/UserActivationModal/UserActivationModal';
import { showAddAccountModal } from 'components/global/AddAccountModal/AddAccountModal.helpers';
import AddAccountModal from 'components/global/AddAccountModal';
import EdoSettingsUserDetailsModal from './EdoUserDetailsModal';
import {
  fetchAndSetUsersData,
  handleEdoManageUsersAppliedFiltersChange,
  handleEdoManageUsersFilterChange,
  handleUserSortChange,
  resetEdoManageUsersFilterInputs,
  setEdoSettingsUserDetailUser,
} from '../EdoUsers.helpers';
import SimpleTable from 'components/global/SimpleTable';
import { toggleActivationUserModal } from 'components/global/UserActivationModal/userActivationModal.helpers';
import userAccountSignal from '../../../../../signals/UserAccount.signal';
import SimpleTableFilterDropdown from 'components/global/SimpleTableFilterDropdown';
import capitalizeFirst from 'utils/capitalizeFirst';
import $appSettings from 'signals/AppSettings.signal';

const HEADERS = [
  {
    key: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'email',
    title: 'Email',
  },
  {
    key: 'role',
    title: 'Role',
    sortable: true,
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'lastSeenAt',
    title: 'Last Signed In',
  },
  {
    key: 'createdDate',
    title: 'Created',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const EdoSettingsManageUsers = () => {
  const { USER_ROLES, USER_STATUS } = $appSettings.value.constants;

  return (
    <>
      <Row className="justify-content-between mb-8">
        <Col className="d-flex justify-content-between mb-16">
          <SimpleTableFilterDropdown
            filtersState={edoUsersSignal.value.dropdownFilters}
            onChange={({ name, value }) =>
              handleEdoManageUsersFilterChange(name, value)
            }
            onApply={() => {
              handleEdoManageUsersAppliedFiltersChange();
              fetchAndSetUsersData();
            }}
            onClearFilterInputs={resetEdoManageUsersFilterInputs}
            filters={[
              {
                type: 'text',
                label: 'First Name',
                key: 'firstName',
              },
              {
                type: 'text',
                label: 'Last Name',
                key: 'lastName',
              },
              {
                type: 'text',
                label: 'Email',
                key: 'email',
              },
              {
                type: 'select',
                label: 'Role',
                key: 'role',
                options: [
                  { label: USER_ROLES.admin, value: USER_ROLES.admin },
                  { label: USER_ROLES.owner, value: USER_ROLES.owner },
                  { label: USER_ROLES.staff, value: USER_ROLES.staff },
                ],
              },
            ]}
          />
          <div>
            <Button
              className="rounded mr-8"
              variant="outline-primary"
              onClick={() => showAddInternalUserModal()}
            >
              Invite EDO Staff
            </Button>
            <Button
              variant="primary"
              className="rounded"
              onClick={() => showAddAccountModal()}
            >
              Invite Organization
            </Button>
          </div>
        </Col>
      </Row>
      <SimpleTable
        headers={HEADERS}
        sort={edoUsersSignal.value.sort}
        onSort={(sort) => {
          handleUserSortChange(sort);
          fetchAndSetUsersData();
        }}
        onClick={(index) => {
          const user = edoUsersSignal.value.users[index];
          setEdoSettingsUserDetailUser(user);
        }}
        rows={edoUsersSignal.value.users.map((user) => ({
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role: capitalizeFirst(user.role),
          status: capitalizeFirst(user.status),
          lastSeenAt: user.lastSeenAt
            ? new Date(user.lastSeenAt).toLocaleDateString()
            : '-',
          createdDate: new Date(user.createdDate).toLocaleDateString(),
          action: ({ key }) => (
            <td key={key} align="right">
              <Button
                hidden={user.userStatus === USER_STATUS.active}
                size="sm"
                className="ml-8 mb-8 rounded"
                variant="success"
                onClick={() =>
                  toggleActivationUserModal({ user, mode: 'activation' })
                }
              >
                Activate
              </Button>
              {userAccountSignal.value.userData.id !== user.id && (
                <Button
                  hidden={user.userStatus === USER_STATUS.inactive}
                  size="sm"
                  className="ml-8 mb-8 rounded"
                  variant="outline-danger"
                  onClick={() =>
                    toggleActivationUserModal({
                      user,
                      mode: 'deactivation',
                    })
                  }
                >
                  Deactivate
                </Button>
              )}
            </td>
          ),
        }))}
      />
      <AddAccountModal />
      <AddUserModal
        portal={$appSettings.value.constants.PORTAL_TYPES.edo}
        onSubmitSuccess={async () => fetchAndSetUserInvites()}
      />
      <UserActivationModal onFetchAndSetUsers={fetchAndSetUsersData} />
      <EdoSettingsUserDetailsModal />
    </>
  );
}

export default EdoSettingsManageUsers;
