import { useCallback, useEffect } from 'react';
import $capitalCallPortionsList from './capitalCallPortionsList.signal';
import { fetchAndSetCapitalCallPortions } from './capitalCallPortionsList.helpers';
import SimpleTable from '../SimpleTable';
import formatMoney from 'utils/formatMoney';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SimpleTableFilterDropdown from '../SimpleTableFilterDropdown';

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'fundName',
    title: 'Fund Name',
    sortable: true,
    bold: true,
  },
  {
    key: 'businessName',
    title: 'Business',
    sortable: true,
    bold: true,
  },
  {
    key: 'amount',
    title: 'Amount',
    sortable: true,
  },
  {
    key: 'createdDate',
    title: 'Created Date',
    sortable: true,
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FILTERS = [
  {
    type: 'integer',
    label: 'ID',
    key: 'id',
  },
  {
    type: 'text',
    label: 'Fund Name',
    key: 'fundName',
  },
  {
    type: 'text',
    label: 'Business Name',
    key: 'businessName',
  },
];

const CapitalCallPortionList = () => {
  const { capitalCallPortions } = $capitalCallPortionsList.value;
  const history = useHistory();
  const { pathname } = history.location;

  const init = useCallback(() => {
    fetchAndSetCapitalCallPortions();
  }, [history.location.search]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="border border-gray p-16 shadow-sm bg-white">
      <h3>Capital Call Transactions</h3>
      <hr />
      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-between mb-16">
            <SimpleTableFilterDropdown filters={FILTERS} />
          </div>
          <SimpleTable
            filters={FILTERS}
            headers={HEADERS}
            rows={capitalCallPortions.map((ccp) => ({
              id: ccp.id,
              fundName: ccp.capitalCall.fund.fundName,
              businessName: ccp.vcPortfolioCompany.business?.name,
              amount: formatMoney(ccp.amount),
              createdData: new Date(ccp.createdDate).toLocaleDateString(),
              programName: ccp.capitalCall.fund.fundingProgram.name,
              action: () => (
                <td align="right">
                  <Button
                    size="sm"
                    variant="outline-dark"
                    onClick={() => history.push(`${pathname}/${ccp.id}`)}
                  >
                    Detail
                  </Button>
                </td>
              ),
            }))}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CapitalCallPortionList;
