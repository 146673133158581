import { UploadedFile } from '../PreviewButton/FilePreviewButton';
import { Sygnal } from 'libs/signals';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import { downloadFromUrl } from 'libs/functions/file';
import loaderSignal from 'signals/Loader.signal';

const UPLOAD_PREVIEW_URL = '/uploadPreviews';

type GenerateFileDownloadFnParams = {
  uploadedFile: UploadedFile;
  model: string;
  signal: Sygnal<boolean> | null | undefined;
  downloadName: string;
};

export const generateFileDownload = async ({
  uploadedFile,
  model,
  signal,
  downloadName,
}: GenerateFileDownloadFnParams): Promise<unknown> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Downloading document...',
  });

  try {
    if (signal) {
      signal.update(true);
    }
    let path;
    if (
      model === 'applicationDocuments' ||
      model === 'capitalCallPortionDocuments'
    ) {
      path = `${UPLOAD_PREVIEW_URL}?uuid=${uploadedFile.uuid}&model=${model}&parentId=${uploadedFile.parentId}`;
    } else {
      path = `${UPLOAD_PREVIEW_URL}?id=${uploadedFile.id}&model=${model}`;
    }
    const url = await api.get({ path, options: undefined });
    const fileExtension = uploadedFile.filePath.split('.')[1];
    return await downloadFromUrl(url, `${downloadName}.${fileExtension}`);
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      //@ts-ignore
      message: error.message,
    });
  } finally {
    if (signal) {
      signal.update(false);
    }
    loaderSignal.reset();
  }
};
