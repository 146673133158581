import { businessSettingsSignal } from '../../../../../signals/BusinessSettings.signal';
import { Col, Form, Row } from 'react-bootstrap';
import { handleBusinessFormChange } from '../BusinessSettings.helpers';
import BusinessUnsavedChangesAlert from './BusinessUnsavedChangesAlert';

const BusinessDiscovery = () => {
  const businessForm = businessSettingsSignal.value;

  return (
    <>
      <Row className="align-items-center px-16">
        <h4 className="mb-0">Discovery</h4>
        <BusinessUnsavedChangesAlert />
      </Row>
      <hr />
      <Row>
        <Col sm={8}>
          <Form.Group className="mt-4">
            <Form.Label className="font-weight-bold" htmlFor="isDiscoverable">
              Do you want your business to appear in search results?
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={businessForm.isDiscoverable === 'yes'}
              label="Yes"
              name="isDiscoverable"
              type="radio"
              id="isDiscoverable_yes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm.isDiscoverable === 'no'}
              label="No"
              name="isDiscoverable"
              type="radio"
              id="isDiscoverable"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default BusinessDiscovery;
