import Signal from "libs/signals";

const $capTable = Signal({
  capTable: [],
  newRowForm: {
    security: '',
    stakeholder: '',
    status: '',
    rounds: '',
    shares: '',
    sharePrice: '',
    transactionalValue: '',
    fullyDiluted: '',
    ownership: '',
  },
  editingRowIndex: null,
  editingField: null,
  initialFieldValue: null,
  selectedRows: [],
  businessName: null,
  // capitalCallPortionId: null,
  fetchRecord: () => null,
  modalVisible: false,
  canEdit: false,
});

export default $capTable;