import { SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS } from 'libs/applications';
import applicationDetailSignal from '../../ApplicationDetail.signal';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import dateOptions from 'components/global/Constant/dateOptions';
import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  caretDownIcon,
  caretRightIcon,
} from 'components/global/Constant/Icons';
import {
  handleApprovedInputFieldChange,
  handleUpdateDisbursementConfig,
} from '../../ApplicationDetail.helpers';
import userAccountSignal from 'signals/UserAccount.signal';
import { FieldComponent } from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const DisbursementDetails = () => {
  const [show, setShow] = useState(false);
  const [editing, setEditing] = useState(false);
  const {
    fundingProgram,
    disbursementConfig,
    requestedAmount,
    fundingProgramStats,
    approvedInputFieldsErrors,
    approvedInputFieldsDirty,
    currentTab,
  } = applicationDetailSignal.value;
  const {
    referenceProgram: { key },
  } = fundingProgram;
  const { role } = userAccountSignal.value.userData;
  const { USER_ROLES } = $appSettings.value.constants;

  const resetData = () => {
    applicationDetailSignal.update({
      approvedInputFields: {
        ...disbursementConfig,
        disbursementDate: new Date(disbursementConfig.disbursementDate),
      },
      approvedInputFieldsDirty: {},
      approvedInputFieldsErrors: {},
    });
    setEditing(false);
  };

  useEffect(() => resetData(), [currentTab === 'approvalChain']);

  return (
    <div className="border-bottom">
      <div
        onClick={() => setShow(!show)}
        className="bg-light mb-0 py-8 px-16 pointer"
      >
        <h5>
          Disbursement Details{' '}
          <FontAwesomeIcon
            className="ml-16"
            icon={show ? caretDownIcon : caretRightIcon}
          />
        </h5>
      </div>
      <Collapse in={show}>
        <div>
          <div className="py-24 px-32">
            <Row>
              <Col>
                <p className="mt-16 mb-8">
                  <b>Requested Amount</b>
                </p>
                <p>
                  {requestedAmount?.toLocaleString(
                    'en-US',
                    dateOptions.currencyUSNoDecimal
                  ) || '-'}
                </p>
              </Col>
              <Col>
                <p className="mt-16 mb-8">
                  <b>Remaining Funds</b>
                </p>
                <p>
                  {fundingProgramStats?.remaining.toLocaleString(
                    'en-US',
                    dateOptions.currencyUSNoDecimal
                  ) || '-'}
                </p>
              </Col>
            </Row>
            <Row>
              {SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS[key]?.map(
                (field) => (
                  <Fragment key={field.name}>
                    <Col lg={6}>
                      <FieldComponent
                        label={field.label}
                        labelClassName="my-8 font-weight-bold"
                        className={field.className}
                        inputClassName={field.inputClassName}
                        variant={field.variant}
                        name={field.name}
                        value={
                          // we're allowing this field to be non-negative for now
                          field.name === 'borrowerEquity'
                            ? field.value ||
                              applicationDetailSignal.value.approvedInputFields[
                                field.name
                              ]
                            : field.value ||
                              applicationDetailSignal.value.approvedInputFields[
                                field.name
                              ] ||
                              ''
                        }
                        type={field.type}
                        disabled={!editing}
                        onChange={(e) =>
                          handleApprovedInputFieldChange(e, field.isCurrency)
                        }
                        onClick={field.onClick}
                        onBlur={field.onBlur}
                        isInvalid={approvedInputFieldsErrors[field.name]}
                      />
                      <small className="text-danger">
                        {approvedInputFieldsDirty[field.name] &&
                          approvedInputFieldsErrors[field.name]}
                      </small>
                    </Col>
                    {
                      // put the approved amount field by itself if odd amount of fields
                      SUBMITTED_FOR_APPROVAL_APPLICATION_INPUT_FIELDS[key]
                        ?.length %
                        2 !==
                        0 &&
                        field.name === 'approvedAmount' && <Col lg={6} />
                    }
                  </Fragment>
                )
              )}
            </Row>
            {role !== USER_ROLES.staff && (
              <div className="d-flex flex-row justify-content-end mt-32">
                {editing ? (
                  <>
                    <Button
                      className="mr-16 rounded"
                      variant="secondary"
                      onClick={resetData}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="rounded"
                      variant="primary"
                      onClick={() => {
                        handleUpdateDisbursementConfig();
                        setEditing(false);
                      }}
                    >
                      Save Changes
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="secondary"
                    className="rounded"
                    onClick={() => setEditing(true)}
                  >
                    Edit Disbursement Details
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default DisbursementDetails;
