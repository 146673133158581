import BackButton from 'components/global/BackButton';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { DATA_ROOM_COMPONENT_VIEW_TYPES } from 'components/global/Constant/constants';
import FundApplication from 'components/global/FundApplication/FundApplication';
import fundApplicationSignal from 'components/global/FundApplication/FundApplication.signals';
import { useHistory } from 'react-router-dom';

const VCFundApplication = () => {
  const history = useHistory();
  const { fundApplication } = fundApplicationSignal.value;
  const labels = [
    '',
    fundApplication?.vcPortfolioCompany?.fund?.fundName,
    fundApplication?.vcPortfolioCompany?.business?.name,
  ];

  return (
    <>
      <BackButton onClick={() => history.goBack()} buttonText="Back" />
      <BreadCrumbWrapper labels={labels}>
        <div className="border border-gray p-24 bg-white shadow-sm">
          <FundApplication
            viewType={DATA_ROOM_COMPONENT_VIEW_TYPES.updateStatus}
            requiredDocumentDescription="View and update the status of required documents"
            suggestedDocumentDescription="View and update the status of suggested documents"
            additionalDocumentDescription="View additional documents submitted by the business"
          />
        </div>
      </BreadCrumbWrapper>
    </>
  );
};

export default VCFundApplication;
