import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { useLocation } from 'react-router-dom';
import { fetchVCPortfolioCompany } from '../VCDealFlows.helpers';
import { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import FundInformation from './FundInformation';
import BreadCrumbWrapper from '../../../../global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const VCPortfolioCompanyDetails = () => {
  const { pathname } = useLocation();
  const organizationId = pathname.split('/')[pathname.split('/').length - 1];
  const { selectedOrganization } = organizationDetailsSignal.value;
  const labels = ['Portfolio Companies', selectedOrganization?.name];

  useEffect(() => {
    fetchVCPortfolioCompany(organizationId);
  }, [organizationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Tabs>
        <Tab eventKey="businessDetails" title="Business Details">
          <div className="bg-white px-32 py-16 border border-gray rounded-0 shadow-sm">
            <OrganizationDetails />
          </div>
        </Tab>
        <Tab eventKey="fundDetails" title="Fund Details">
          <div className="bg-white px-32 py-16 border border-gray rounded-0 shadow-sm">
            <FundInformation />
          </div>
        </Tab>
      </Tabs>
    </BreadCrumbWrapper>
  );
};

export default VCPortfolioCompanyDetails;
