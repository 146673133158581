import SimpleTable from "../SimpleTable";
import organizationsListSignal from "./OrganizationsList.signal";
import { Button } from "react-bootstrap";
import OrganizationDetailsModal from "./components/OrganizationDetailsModal";
import {
  fetchAndSetOrganizations,
  getRow,
  handleDetailClick,
  showShareOrganizationModal
} from "./OrganizationsList.helpers";
import SimpleTableFilterDropdown from "../SimpleTableFilterDropdown";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import ShareOrganizationModal from "./components/ShareOrganizationModal";
import { Location } from "history";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'contactName',
    title: 'Contact Name',
    sortable: true,
  },
  {
    key: 'contactEmail',
    title: 'Contact Email',
    sortable: true,
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'need',
    title: 'Need',
  },
  {
    key: 'createdDate',
    title: 'Created Date',
    sortable: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FILTERS = [
  {
    type: 'integer',
    label: 'ID',
    key: 'id',
  },
  {
    type: 'text',
    label: 'Name',
    key: 'name',
  },
  {
    type: 'text',
    label: 'Contact Name',
    key: 'contactName',
  },
  {
    type: 'text',
    label: 'Contact Email',
    key: 'contactEmail',
  },
];

type Props = {
  programMembershipOrFundId?: number;
  newPath: string;
  tableTitle?: string;
  refetchingFilter?: (location: Location) => boolean;
};

const OrganizationsList: React.FC<Props> = ({
  tableTitle,
  newPath,
  refetchingFilter,
}) => {
  const location = useLocation();
  const records = organizationsListSignal.value.accounts;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const init = useCallback(() => {
    if (refetchingFilter) {
      if (refetchingFilter(location)) {
        return fetchAndSetOrganizations();
      } else {
        return undefined;
      }
    }

    return fetchAndSetOrganizations();
  }, [location.search]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <OrganizationDetailsModal showLinkToPrograms={undefined} />
      <ShareOrganizationModal />
      <div
        className={`mb-16 d-flex ${
          tableTitle ? 'justify-content-between' : 'justify-content-start'
        }`}
      >
        <h5>{tableTitle ? tableTitle : ''}</h5>
        <SimpleTableFilterDropdown filters={FILTERS} />
      </div>
      <SimpleTable
        headers={HEADERS}
        rows={records.map((record) => ({
          ...getRow(record),
          action: ({ key }: { key: string }) => (
            <td className="text-right" key={key}>
              {record.portalType === PORTAL_TYPES.business && (
                <Button
                  className="rounded mr-8"
                  size="sm"
                  variant="outline-primary"
                  onClick={() => showShareOrganizationModal(record.business)}
                >
                  Share
                </Button>
              )}
              <Button
                className="rounded"
                size="sm"
                variant="outline-dark"
                onClick={() => handleDetailClick(record, newPath)}
              >
                Detail
              </Button>
            </td>
          ),
        }))}
      />
    </>
  );
};

export default OrganizationsList;
