import { Alert, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { warningIcon } from '../../Constant/Icons';
import { Link } from 'react-router-dom';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import $appSettings from 'signals/AppSettings.signal';

const ApprovableLinkBankAccountAlert = () => {
  const {
    ventureCapitalist,
    usesCaatPayment,
    hasLinkedBankAccount,
    modelType,
  } = applicationDetailSignal.value;
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;

  return (
    <Alert
      variant="warning"
      hidden={
        !usesCaatPayment ||
        (modelType === 'programRequest' &&
          status !== APPLICATION_STATUS.approved) ||
        (modelType === 'application' &&
          status !== APPLICATION_STATUS.pending &&
          status !== APPLICATION_STATUS.incomplete) ||
        userData.account.portalType === PORTAL_TYPES.edo ||
        hasLinkedBankAccount
      }
    >
      <Row>
        <Col lg={8}>
          <FontAwesomeIcon icon={warningIcon} /> There is no bank account linked
          to this program. You can still submit applications for this program,
          but you must link a bank account before they can be approved.
        </Col>
        <Col lg={4} className="text-right">
          <div>
            <Link
              className="btn btn-primary rounded"
              to={ventureCapitalist ? '/vc/billing' : '/lender/billing'}
            >
              Link a bank account
            </Link>
          </div>
        </Col>
      </Row>
    </Alert>
  );
};

export default ApprovableLinkBankAccountAlert;
