import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  fetchAndSetSavedSearches,
  handleBusinessSearchFormChange,
  handleBusinessSearchSubmit,
  handleDeleteSaveSearchClick,
  handleSaveSearchClick,
  handleSearchBusinessInputBlur
} from "./searchBusiness.helpers";
import searchBusinessesSignal from "./searchBusinesses.signal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { searchIcon } from "../Constant/Icons";
import { INDUSTRY_LIST, SEEKING_FUNDING_PAYMENT_RANGES } from "../Constant/constants";
import BreadCrumbWrapper from "../BreadCrumbs/BreadCrumbs";
import { useEffect } from "react";
import SimpleTable from "../SimpleTable";
import SaveBusinessSearchModal from "../SearchBusinessSaveModal/SaveBusinessSearchModal";
import saveBusinessSearchSignal from "../SearchBusinessSaveModal/saveBusinessSearchModal.signal";
import {
  handleShowSaveSearchCreateModal,
  handleShowSaveSearchEditModal
} from "../SearchBusinessSaveModal/saveBusinessSearchModal.helpers";

const HEADERS = [
  {
    key: 'industry',
    title: 'Industry',
  },
  {
    key: 'zipCode',
    title: 'Zip Code',
  },
  {
    key: 'amountInterestedIn',
    title: 'Amount Interested In',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

export const SearchBusinesses = () => {
  const { searchForm, searchFormErrors, savedSearches } =
    searchBusinessesSignal.value;

  useEffect(() => {
    fetchAndSetSavedSearches();

    return () => saveBusinessSearchSignal.reset();
  }, []);

  return (
    <BreadCrumbWrapper>
      <SaveBusinessSearchModal
        handleSaveSearchClick={handleSaveSearchClick}
        handleDeleteSaveSearchClick={handleDeleteSaveSearchClick}
      />
      <Card className="px-24 px-lg-48 pb-32 pt-16 rounded-0">
        <h3 className="text-center mb-16">Search Businesses</h3>
        <hr />
        <Form className="mb-32">
          <Row>
            <Col>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="industry">
                  Industry
                </Form.Label>
                <Form.Select
                  type="text"
                  formNoValidate
                  className="form-control"
                  id="industry"
                  name="industry"
                  aria-describedby="industry"
                  value={searchForm.industry}
                  onChange={handleBusinessSearchFormChange}
                >
                  <option value="">Select an option</option>
                  {INDUSTRY_LIST.map((industry) => (
                    <option key={industry} value={industry}>
                      {industry}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="location">
                  Zip Code
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="location"
                  name="location"
                  aria-describedby="location"
                  value={searchForm.location}
                  isInvalid={searchFormErrors.location}
                  onChange={handleBusinessSearchFormChange}
                  onBlur={handleSearchBusinessInputBlur}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid Zip Code
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold" htmlFor="fundingRange">
                  Amount Interested In
                </Form.Label>
                <Form.Select
                  type="number"
                  className="form-control"
                  id="fundingRange"
                  name="fundingRange"
                  aria-describedby="fundingRange"
                  value={searchForm.fundingRange}
                  onChange={handleBusinessSearchFormChange}
                >
                  <option value="">Select an option</option>
                  {SEEKING_FUNDING_PAYMENT_RANGES.map((range) => (
                    <option key={range} value={range}>
                      {range}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-end px-16">
            <Button
              className="rounded"
              onClick={() => handleBusinessSearchSubmit(searchForm)}
            >
              <FontAwesomeIcon icon={searchIcon} />
              <span className="ml-8">Search</span>
            </Button>
          </Row>
        </Form>
        <hr />
        <div className="mt-32">
          <h4>Saved Searches</h4>
          <div className="d-flex flex-row justify-content-end align-items-center mb-16">
            <Button
              className="rounded"
              onClick={handleShowSaveSearchCreateModal}
            >
              Save a Search
            </Button>
          </div>
          {!!savedSearches.length ? (
            <SimpleTable
              headers={HEADERS}
              rows={savedSearches.map((search) => {
                const { query } = search;
                const { where } = query;
                const industry = where?.assistance?.array_contains;
                const zipCode = where?.address?.equals;
                let amountInterestedIn = '';

                if (query.where?.OR?.length) {
                  const { OR } = where;
                  const min = OR[0].assistance?.gte;
                  const max = OR[0].assistance?.lte;

                  if (min) {
                    amountInterestedIn = `$${min} - $${max}`;
                  } else {
                    amountInterestedIn = '$100001+';
                  }
                }

                return {
                  industry: industry || '-',
                  zipCode: zipCode || '-',
                  amountInterestedIn: amountInterestedIn || '-',
                  action: ({ key }) => (
                    <td key={key} className="text-right">
                      <Button
                        size="sm"
                        className="rounded mr-8"
                        onClick={() =>
                          handleBusinessSearchSubmit({
                            industry,
                            zipCode,
                            amountInterestedIn,
                          })
                        }
                      >
                        View Results
                      </Button>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        className="rounded"
                        onClick={() =>
                          handleShowSaveSearchEditModal({
                            industry,
                            fundingRange: amountInterestedIn,
                            location: zipCode,
                            search,
                          })
                        }
                      >
                        Details
                      </Button>
                    </td>
                  ),
                };
              })}
            />
          ) : (
            <Alert>
              You do not have any saved searches. By saving a search, you will
              get search result notifications sent to you via email. Each time
              the query is run, you will receive notifications for new
              businesses (if any) that fit your search criteria.
            </Alert>
          )}
        </div>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default SearchBusinesses;
