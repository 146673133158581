import { enumCaseToTabCase } from '../libs/string';

export const getOrganizationId = (account) => {
  if (account.lender) {
    return account.lender.id;
  }

  if (account.ventureCapitalFirm) {
    return account.ventureCapitalFirm.id;
  }

  if (account.business) {
    return account.business.id;
  }

  if (account.edo) {
    return account.edo.id;
  }

  return null;
};

export const mapInvitationOrRequestStatus = (status) => {
  if (status === 'PENDING') {
    return 'Invited';
  }

  return enumCaseToTabCase(status);
};
