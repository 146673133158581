export const EDIT_PROGRAM_MODAL_EVENT_MAP = {
  basicInfo: 'basicInfo',
  applicationDocuments: 'applicationDocuments',
  approvalChain: 'approvalChain',
  enrolledOrganizations: 'enrolledOrganizations',
};

export const EDIT_PROGRAM_MODAL_EVENT_LIST = Object.values(
  EDIT_PROGRAM_MODAL_EVENT_MAP
);
