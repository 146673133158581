import { Alert, Button, Col, Row } from 'react-bootstrap';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import loaderSignal from '../../../../signals/Loader.signal';
import SimpleTable from '../../SimpleTable';
import {
  handleApprovalChainConfirmation,
  handleFileChange,
  handleNoteChange,
} from '../CapitalCall.helpers';
import $capitalCall from '../CapitalCall.signals';
import Field from 'components/global/Field/Field';

const AlertExplanation = ({ currentConfirmation }) => (
  <Alert variant="info">
    <h3>Traversing Approval Chain</h3>
    {currentConfirmation &&
    currentConfirmation.userId === userAccountSignal.value.userData.id ? (
      <p>It is your turn to give your approval.</p>
    ) : (
      <p>
        Equity program access must be approved by designated approvers once
        submitted for approval.
      </p>
    )}
  </Alert>
);

const APPROVERS_HEADERS = [
  {
    key: 'turn',
    title: 'Turn',
  },
  {
    key: 'approver',
    title: 'Approver',
  },
  {
    key: 'role',
    title: 'Role',
  },
  {
    key: 'alternate',
    title: 'Alternate',
  },
  {
    key: 'status',
    title: 'Status',
  },
];

function buildApproversTableColumns(handledByBoard) {
  if (handledByBoard) {
    return [
      ...APPROVERS_HEADERS.slice(0, 4),
      ...[{ key: 'boardMeetingDate', title: 'Meeting Date' }],
      ...APPROVERS_HEADERS.slice(5),
    ];
  } else {
    return APPROVERS_HEADERS;
  }
}

function computeStatus(approvalChainMembership) {
  const { confirmedDate, revokedDate } = approvalChainMembership;

  if (confirmedDate) {
    return `Confirmed on ${confirmedDate}`;
  }

  if (revokedDate) {
    return `Revoked on ${revokedDate}`;
  }

  return 'Pending';
}

const CapitalCallApprovalChain = () => {
  const { capitalCall } = $capitalCall.value;
  const approvalChainMemberships = capitalCall.approvalChainMemberships;
  const currentConfirmation = approvalChainMemberships.find(
    (acm) => !acm.confirmedDate
  );

  return (
    <div className="mt-16">
      <AlertExplanation currentConfirmation={currentConfirmation} />
      {currentConfirmation &&
        currentConfirmation.userId === userAccountSignal.value.userData.id && (
          <>
            <div className="bg-light py-8 px-16 mb-16 mt-48">
              <h5>Give Approval</h5>
            </div>
            <div>
              <Row>
                <Col xs={6} className="offset-3">
                  <Field.TextArea
                    label="Note"
                    labelClassName="font-weight-bold"
                    value={$capitalCall.value.approvalChainNote}
                    onChange={(e) => handleNoteChange(e.value)}
                  />
                  <div className="mt-16">
                    <label className="font-weight-bold">File</label>
                    <div className="border p-8">
                      <input
                        type="file"
                        // @ts-ignore
                        onChange={(e) => handleFileChange(e.target.files[0])}
                        accept=".xls,.xlsx,.pdf,.jpg,.jpeg"
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      disabled={loaderSignal.value.isContentLoading}
                      onClick={() =>
                        handleApprovalChainConfirmation(currentConfirmation)
                      }
                      variant="outline-primary"
                      className="rounded mt-32 w-100"
                    >
                      Approve
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      <div className="bg-white p-16">
        <div>
          <h5>Approvers</h5>
        </div>
        <SimpleTable
          headers={buildApproversTableColumns(false)}
          rows={approvalChainMemberships.map((acm, index) => ({
            turn: index + 1,
            approver: `${acm.user.firstName} ${acm.user.lastName}`,
            role: acm.role,
            alternate: acm.alternate ? 'Yes' : 'No',
            status: computeStatus(acm),
          }))}
        />
      </div>
    </div>
  );
};

export default CapitalCallApprovalChain;
