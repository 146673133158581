import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const Percent = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
}) => (
  <div className={className}>
    {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
    <div className="d-flex align-items-center">
      <div>
        <Form.Control
          name={name}
          type="number"
          value={value}
          id="inputPercent"
          className={inputClassName}
          onChange={(e) => handleInputChange(e, onChange)}
        />
      </div>
      <h5 className="mb-0 ms-8">%</h5>
    </div>
  </div>
);

export default Percent;
