import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Collapse, Row } from 'react-bootstrap';
import SimpleTable from 'components/global/SimpleTable';
import { useHistory } from 'react-router-dom';
import ledgerSignal, { $ledgerLoanPerformance } from './Ledger.signals';
import {
  fetchAmortizationSchedule,
  handleLoanPerformanceChange,
  handleSubmitLoanPerformance,
  showAcceptLossClaimFormModalOpen,
  showAddReimbursementModal,
  showConfigureLoanModalOpen,
  showLossClaimFormModalOpen,
  showSubmitBorrowerPaymentModal,
} from './Ledger.helpers';
import EdoAddReimbursementModal from './LedgerAddRecycledFundsModal';
import { camelCaseToTitleText } from '../../../utils/formatTxt';
import AmortizationSchedule from '../AmortizationSchedule/AmortizationSchedule';
import dateOptions from '../Constant/dateOptions';
import LedgerLossClaimFormModal from './LedgerCreateLossClaimFormModal';
import userAccountSignal from 'signals/UserAccount.signal';
import LedgerAddBorrowerPaymentModal from './LedgerAddBorrowerPaymentModal';
import { getAmortizationScheduleCurrentTerm } from '../../../libs/loans';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { caretDownIcon, caretRightIcon } from '../Constant/Icons';
import FilePreviewButton from '../utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import LedgerEvaluateLossClaimFormModal from './LedgerEvaluateLossClaimFormModal';
import LedgerConfigureLoanFormModal from './LedgerConfigureLoanFormModal';
import { DateTime } from 'luxon';
import $appSettings from 'signals/AppSettings.signal';
import Field from '../Field';

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'amount',
    title: 'Amount',
  },
  {
    key: 'kind',
    title: 'Kind',
  },
  {
    status: 'status',
    title: 'Status',
  },
  {
    transactions: 'transactions',
    title: 'Transactions',
  },
  {
    key: 'date',
    title: 'Date',
  },
];

function renderLoanDetailValue(value) {
  try {
    if (typeof value === 'string') {
      return new Date(Date.parse(value)).toISOString().split('T')[0];
    } else {
      if (1 <= value && value <= 99) {
        return `${value}%`;
      } else {
        return value.toLocaleString('en-US', dateOptions.currencyUSNoDecimal);
      }
    }
  } catch (e) {
    return '?';
  }
}

export const AmortizationScheduleTab = () => {
  const { application, schedule, loadingSchedule } = ledgerSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const loan = application?.loan;
  const lossClaim = application?.lossClaim;

  useEffect(() => {
    if (application && application.loan) {
      fetchAmortizationSchedule();
    }
  }, [application]);

  if (!loan) {
    return (
      <>
        <LedgerConfigureLoanFormModal />
        <Alert variant="info d-flex flex-row justify-content-between">
          <p>
            Loan information has to entered by the Lender to see the
            Amortization Schedule.
          </p>
          <Button
            disabled={!application?.disbursements?.length}
            hidden={
              !!loan ||
              userAccountSignal.value.userData.account.portalType ===
                PORTAL_TYPES.edo
            }
            className="rounded"
            variant={'primary'}
            onClick={() => {
              showConfigureLoanModalOpen();
            }}
          >
            Configure Loan
          </Button>
        </Alert>
      </>
    );
  }

  if (loadingSchedule) {
    return <p>Loading...</p>;
  }

  if (!schedule) {
    return null;
  }

  return (
    <div>
      <LoanPaymentInformation />
      <LedgerLossClaimFormModal />
      <LedgerAddBorrowerPaymentModal />
      <div className="d-flex flex-row justify-content-end pb-24">
        <Button
          hidden={
            !lossClaim ||
            lossClaim?.status === 'APPROVED' ||
            userAccountSignal.value.userData.account.portalType ===
              PORTAL_TYPES.lender
          }
          className="rounded"
          variant={'outline-primary'}
          onClick={() => {
            showAcceptLossClaimFormModalOpen();
          }}
        >
          Handle Loss Claim
        </Button>
        <Button
          hidden={
            !loan ||
            !!lossClaim ||
            userAccountSignal.value.userData.account.portalType ===
              PORTAL_TYPES.edo
          }
          className="rounded"
          variant={'outline-primary'}
          onClick={() => {
            showLossClaimFormModalOpen();
          }}
        >
          Start Loss Claim
        </Button>
        <Button
          disabled={!application?.disbursements?.length}
          hidden={
            !loan ||
            lossClaim?.status === 'APPROVED' ||
            userAccountSignal.value.userData.account.portalType ===
              PORTAL_TYPES.edo
          }
          className="rounded ml-2"
          onClick={() => {
            showSubmitBorrowerPaymentModal();
          }}
        >
          {' '}
          Enter Borrower Payment
        </Button>
      </div>
      <Row>
        <Col xs={8}>
          <AmortizationSchedule schedule={schedule} loan={loan} />
        </Col>
        <Col>
          <Overview />
        </Col>
      </Row>
    </div>
  );
};

export const LedgerTab = ({ isModal }) => {
  const { application } = ledgerSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const loan = application?.loan;
  const lossClaim = application?.lossClaim;
  const disbursements = application?.disbursements || [];
  const disbursement = disbursements?.length > 0 ? disbursements[0] : undefined;
  const reimbursements = disbursement?.reimbursements
    ? disbursement?.reimbursements
    : [];
  const history = useHistory();
  const { subsequentPrivateFinancing, ssbciFundsLost, equityInvestmentGains } =
    $ledgerLoanPerformance.value;

  return (
    <div>
      <EdoAddReimbursementModal />
      <LedgerEvaluateLossClaimFormModal />
      <h5>Loan Performance</h5>
      <Row>
        <Col>
          <Field.Currency
            label="Subsequent Private Financing"
            labelClassName="font-weight-bold"
            name="subsequentPrivateFinancing"
            value={subsequentPrivateFinancing.value}
            onChange={handleLoanPerformanceChange}
          />
        </Col>
        <Col>
          <Field.Currency
            label="SSBCI Funds Lost"
            labelClassName="font-weight-bold"
            name="ssbciFundsLost"
            value={ssbciFundsLost.value}
            onChange={handleLoanPerformanceChange}
          />
        </Col>
        <Col>
          <Field.Currency
            label="Equity Investment Gains"
            labelClassName="font-weight-bold"
            name="equityInvestmentGains"
            value={equityInvestmentGains.value}
            onChange={handleLoanPerformanceChange}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end mt-24">
        <Button onClick={handleSubmitLoanPerformance}>
          Submit Loan Performance
        </Button>
      </div>
      <hr />
      <div className="d-flex flex-row justify-content-end mb-16">
        <Button
          disabled={!application?.disbursements?.length}
          hidden={!loan || lossClaim?.status === 'APPROVED'}
          className="rounded ml-2"
          variant={
            userAccountSignal.value.userData.account.portalType ===
            PORTAL_TYPES.edo
              ? 'primary'
              : 'outline-primary'
          }
          onClick={() => {
            showAddReimbursementModal();
          }}
        >
          Add Recycled Funds
        </Button>
      </div>
      <Row>
        <Col xs={8}>
          <SimpleTable
            headers={HEADERS}
            onClick={(index) => {
              if (index === 0) {
                let path = `${history.location.pathname}/disbursement/${disbursements[0].id}`;
                if (isModal) {
                  path = `${history.location.pathname}/${application.id}/disbursement/${disbursements[0].id}`;
                }
                history.push(path);
              } else {
                let path = `${history.location.pathname}/reimbursement/${
                  disbursements[0].reimbursements[index - 1].id
                }`;
                if (isModal) {
                  path = `${history.location.pathname}/${
                    application.id
                  }/reimbursement/${
                    disbursements[0].reimbursements[index - 1].id
                  }`;
                }
                history.push(path);
              }
            }}
            rows={
              !disbursement
                ? []
                : [
                    {
                      id: 1,
                      amount: `-$${disbursement?.amount.toLocaleString()}`,
                      kind: 'Disbursement',
                      status:
                        disbursement?.transactions?.length > 0
                          ? 'Fulfilled'
                          : 'Queued',

                      transactions: `${
                        disbursement?.transactions?.length
                      } ($${disbursement?.transactions
                        ?.map((t) => t.amount)
                        .reduce((acc, t) => acc + t, 0)
                        .toLocaleString()})`,
                      date: disbursement?.dueDate.split('T')[0],
                    },
                  ].concat(
                    reimbursements?.map((reimbursement, index) => ({
                      id: index + 2,
                      amount: reimbursement.amount.toLocaleString(),
                      kind: 'Recycled Funds',
                      status: 'Fulfilled',
                      transactions: `${
                        reimbursement.transactions.length
                      } ($${reimbursement.transactions
                        .map((t) => t.amount)
                        .reduce((acc, t) => acc + t, 0)
                        .toLocaleString()})`,
                      date: reimbursement.dueDate.split('T')[0],
                    }))
                  )
            }
          />
        </Col>
        <Col>
          <Overview />
        </Col>
      </Row>
    </div>
  );
};

const LoanPaymentInformation = () => {
  const { schedule, application } = ledgerSignal.value;
  const loan = application?.loan;

  if (!schedule || !loan) {
    return null;
  }

  console.log('schedule', schedule);

  const currentTerm = getAmortizationScheduleCurrentTerm(schedule);

  return (
    <Alert className="d-flex flex-row justify-content-between" type="info">
      <ul>
        <li>
          Next payment is due on{' '}
          {DateTime.fromISO(currentTerm.date)
            .plus({ day: loan.gracePeriodDays })
            .toFormat('DD')}{' '}
          amounting to{' '}
          {currentTerm.monthlyPayment.toLocaleString(
            'en-US',
            dateOptions.currencyUS
          )}
          .
        </li>
      </ul>
    </Alert>
  );
};

const Overview = () => {
  const [show, setShow] = useState({
    totals: true,
    loanDetails: false,
    lenderDetails: false,
    businessDetails: false,
    attachments: false,
  });
  const { application } = ledgerSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const loan = application?.loan;
  const disbursements = application?.disbursements || [];
  const disbursement = disbursements.length > 0 ? disbursements[0] : undefined;
  const reimbursements = disbursement?.reimbursements
    ? disbursement.reimbursements
    : [];

  const toggleShow = (section) => {
    setShow((prev) => ({ ...prev, [section]: !show[section] }));
  };

  return (
    <Card className="rounded-0">
      <Card.Body className="px-0">
        <div className="px-24">
          <h4>Overview</h4>
        </div>
        <OverviewDropDownHeader
          section="totals"
          onToggleShow={toggleShow}
          show={show}
          className="border-top border-bottom"
        >
          Totals
        </OverviewDropDownHeader>
        <Collapse in={show.totals}>
          <div className="px-24 pt-8 border-bottom border-gray">
            <h5 className="font-600">Approved</h5>
            <p>{'$' + (application?.approvedAmount || 0).toLocaleString()}</p>
            <h5 className="font-600">Disbursed</h5>
            <p>
              {'$' + (application?.disbursementAmount || 0).toLocaleString()}
            </p>
            <h5 className="font-600">Obligated</h5>
            <p>{'$' + application?.stats.obligated.toLocaleString()}</p>
            <h5 className="font-600">Expended</h5>
            <p>{'$' + application?.stats.expended.toLocaleString()}</p>
            <h5 className="font-600">Recycled</h5>
            <p>
              {reimbursements.length > 0
                ? '$' +
                  reimbursements
                    .reduce((acc, r) => acc + r.amount, 0)
                    .toLocaleString()
                : '$' + '0'.toLocaleString()}
            </p>
          </div>
        </Collapse>
        <OverviewDropDownHeader
          section="loanDetails"
          onToggleShow={toggleShow}
          show={show}
          className="border-bottom"
        >
          Loan Details
        </OverviewDropDownHeader>
        <Collapse in={show.loanDetails}>
          <div className="px-24 pt-8 border-bottom border-gray">
            {Object.entries(application?.disbursementConfig || {}).map(
              ([key, value]) => (
                <p key={key}>
                  <strong>{camelCaseToTitleText(key)}:</strong>{' '}
                  {renderLoanDetailValue(value)}
                </p>
              )
            )}
            <p>
              <strong>First repayment date:</strong>{' '}
              {loan?.firstRepaymentDate?.split('T')[0] || '-'}
            </p>
            <p>
              <strong>Interest rate:</strong> {loan?.interestRate}%
            </p>
            <p>
              <strong>Loan years:</strong> {loan?.years || '-'}
            </p>
            <p>
              <strong>Grace period:</strong>{' '}
              {loan?.gracePeriodDays ? `${loan.gracePeriodDays} days` : '-'}
            </p>
          </div>
        </Collapse>
        {userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.edo ? (
          <>
            <OverviewDropDownHeader
              section="lenderDetails"
              onToggleShow={toggleShow}
              show={show}
              className="border-bottom"
            >
              Lender Details
            </OverviewDropDownHeader>
            <Collapse in={show.lenderDetails}>
              <div
                className={`px-24 pt-8 ${
                  !!loan && 'border-bottom border-gray'
                }`}
              >
                <p>
                  <strong>Name:</strong>{' '}
                  {application?.fundingProgramMembership?.lender?.name}
                </p>
                <p>
                  <strong>Contact Name:</strong>{' '}
                  {application?.fundingProgramMembership?.lender?.contactName}
                </p>
                <p>
                  <strong>Email:</strong>{' '}
                  <a
                    href={`mailto:${application?.fundingProgramMembership?.lender?.email}`}
                  >
                    {application?.fundingProgramMembership?.lender?.email}
                  </a>
                </p>
                <p>
                  <strong>Phone Number:</strong>{' '}
                  <a
                    href={`tel:${application?.fundingProgramMembership?.lender?.phoneNumber}`}
                  >
                    {application?.fundingProgramMembership?.lender?.phoneNumber}
                  </a>
                </p>
              </div>
            </Collapse>
          </>
        ) : (
          <>
            <OverviewDropDownHeader
              section="businessDetails"
              onToggleShow={toggleShow}
              show={show}
              className="border-bottom"
            >
              Business Details
            </OverviewDropDownHeader>
            <Collapse in={show.businessDetails}>
              <div
                className={`px-24 pt-8 ${
                  !!loan && 'border-bottom border-gray'
                }`}
              >
                <p>
                  <strong>Name:</strong> {application?.business?.name}
                </p>
                <p>
                  <strong>Contact Name:</strong>{' '}
                  {application?.business?.contactName}
                </p>
                <p>
                  <strong>Email:</strong>{' '}
                  <a href={`mailto:${application?.business?.contactEmail}`}>
                    {application?.business?.contactEmail}
                  </a>
                </p>
                <p>
                  <strong>Phone Number:</strong>{' '}
                  <a href={`tel:${application?.business?.phoneNumber}`}>
                    {application?.business?.phoneNumber}
                  </a>
                </p>
              </div>
            </Collapse>
          </>
        )}
        {!!loan && (
          <>
            <OverviewDropDownHeader
              section="attachments"
              onToggleShow={toggleShow}
              show={show}
              className="border-bottom"
            >
              Closing Documents
            </OverviewDropDownHeader>
            <Collapse in={show.attachments}>
              <div className="px-24">
                {!!loan?.attachments?.length ? (
                  <>
                    {loan.attachments.map((attachment) => (
                      <Row
                        key={attachment.filePath}
                        className="py-8 border-bottom border-gray align-items-center"
                      >
                        <Col xs={6} className="overflow-hidden">
                          <p className="mb-0">{attachment.fileTitle}</p>
                        </Col>
                        <Col>
                          <FilePreviewButton
                            render={(submitting, fn) => (
                              <Button
                                hidden={!attachment.filePath}
                                size="sm"
                                className="rounded"
                                onClick={() =>
                                  fn(
                                    { ...attachment, parentId: loan.id },
                                    'loanDocuments'
                                  )
                                }
                              >
                                View
                              </Button>
                            )}
                          />
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Alert>
                    There were no documents submitted when this loan was
                    configured.
                  </Alert>
                )}
              </div>
            </Collapse>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

const OverviewDropDownHeader = ({
  children,
  section,
  onToggleShow,
  show,
  className,
}) => (
  <div
    onClick={() => onToggleShow(section)}
    className={`bg-light mb-0 py-8 px-24 pointer border-gray ${className}`}
  >
    <div className="d-flex flex-row justify-content-between align-items-center">
      <h5 className="font-600">{children}</h5>
      <FontAwesomeIcon
        className="h5"
        icon={show[section] ? caretDownIcon : caretRightIcon}
      />
    </div>
  </div>
);

// const Ledger = () => {
//   const { application } = ledgerSignal.value;
//   const loan = application?.loan;
//   const disbursements = application?.disbursements || [];
//   const disbursement = disbursements.length > 0 ? disbursements[0] : undefined;
//   const lossClaim = application?.lossClaim;
//   const reimbursements = disbursement?.reimbursements
//     ? disbursement.reimbursements
//     : [];
//   const history = useHistory();

//   useEffect(() => {
//     fetchAndSetApplication();
//   }, [history.location.search]);

//   return (
//     <BreadCrumbWrapper>
//       <EdoAddReimbursementModal />
//       <LedgerLossClaimFormModal />
//       <LedgerConfigureLoanFormModal />
//       <LedgerEvaluateLossClaimFormModal />
//       <LedgerAddBorrowerPaymentModal />
//       <Row className="mb-40">
//         <Col xs={9}>
//           <Card>
//             <Card.Body>
//               <h3 className="text-center mb-32">
//                 {userAccountSignal.value.userData.account.portalType ===
//                 PORTAL_TYPES.lender
//                   ? 'Loan'
//                   : 'Ledger'}
//               </h3>
//               <div className="text-right my-8">
//                 <Button
//                   hidden={
//                     !lossClaim ||
//                     lossClaim?.status === 'APPROVED' ||
//                     userAccountSignal.value.userData.account.portalType ===
//                       PORTAL_TYPES.lender
//                   }
//                   className="rounded"
//                   variant={'outline-primary'}
//                   onClick={() => {
//                     showAcceptLossClaimFormModalOpen();
//                   }}
//                 >
//                   Handle Loss Claim
//                 </Button>
//                 <Button
//                   hidden={
//                     !loan ||
//                     !!lossClaim ||
//                     userAccountSignal.value.userData.account.portalType ===
//                       .EDO
//                   }
//                   className="rounded"
//                   variant={'outline-primary'}
//                   onClick={() => {
//                     showLossClaimFormModalOpen();
//                   }}
//                 >
//                   Start Loss Claim
//                 </Button>
//                 <Button
//                   disabled={!application?.disbursements?.length}
//                   hidden={
//                     !!loan ||
//                     userAccountSignal.value.userData.account.portalType ===
//                       PORTAL_TYPES.edo
//                   }
//                   className="rounded"
//                   variant={'primary'}
//                   onClick={() => {
//                     showConfigureLoanModalOpen();
//                   }}
//                 >
//                   Configure Loan
//                 </Button>
//                 <Button
//                   disabled={!application?.disbursements?.length}
//                   hidden={!loan || lossClaim?.status === 'APPROVED'}
//                   className="rounded ml-2"
//                   variant={
//                     userAccountSignal.value.userData.account.portalType ===
//                     PORTAL_TYPES.edo
//                       ? 'primary'
//                       : 'outline-primary'
//                   }
//                   onClick={() => {
//                     showAddReimbursementModal();
//                   }}
//                 >
//                   Add Recycled Funds
//                 </Button>
//                 <Button
//                   disabled={!application?.disbursements?.length}
//                   hidden={
//                     !loan ||
//                     lossClaim?.status === 'APPROVED' ||
//                     userAccountSignal.value.userData.account.portalType ===
//                       PORTAL_TYPES.edo
//                   }
//                   className="rounded ml-2"
//                   onClick={() => {
//                     showSubmitBorrowerPaymentModal();
//                   }}
//                 >
//                   {' '}
//                   Enter Borrower Payment
//                 </Button>
//               </div>

//               {application?.status === 'DEFAULT' && (
//                 <Alert variant="danger">Loan has defaulted.</Alert>
//               )}
//               <LoanPaymentInformation />
//               {userAccountSignal.value.userData.account.portalType ===
//               PORTAL_TYPES.edo ? (
//                 <Tabs>
//                   <Tab key="ledger" eventKey="ledger" title="Ledger">
//                     <LedgerTab
//                       disbursements={disbursements}
//                       disbursement={disbursement}
//                       reimbursements={reimbursements}
//                     />
//                   </Tab>
//                   <Tab
//                     key="amortizationSchedule"
//                     eventKey="amortizationSchedule"
//                     title="Amortization Schedule"
//                   >
//                     <AmortizationScheduleTab />
//                   </Tab>
//                 </Tabs>
//               ) : (
//                 <Tabs>
//                   <Tab
//                     key="amortizationSchedule"
//                     eventKey="amortizationSchedule"
//                     title="Amortization Schedule"
//                   >
//                     <AmortizationScheduleTab />
//                   </Tab>
//                   <Tab key="ledger" eventKey="ledger" title="Ledger">
//                     <LedgerTab
//                       disbursements={disbursements}
//                       disbursement={disbursement}
//                       reimbursements={reimbursements}
//                     />
//                   </Tab>
//                 </Tabs>
//               )}
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col>
//           <Overview />
//         </Col>
//       </Row>
//     </BreadCrumbWrapper>
//   );
// };

// export default Ledger;
