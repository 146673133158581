import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import dateOptions from '../Constant/dateOptions';
import { caretDownIcon, caretRightIcon } from '../Constant/Icons';
import { getLogDescription } from './displayNotesOrLogs.helpers';

const NestedDropDown = ({ userObj, displayType }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div role="none" onClick={() => setVisible(!visible)}>
        <p className="mb-8 ml-16">
          {userObj.userName}&ensp;
          <FontAwesomeIcon size="lg" icon={visible ? caretDownIcon : caretRightIcon} />
        </p>
      </div>
      <Collapse in={visible}>
        {displayType === 'notes' ?
          (
            <div>
              {userObj.items?.map(item => (
                <p key={item.id} className="ml-32 mb-0">&#x2022; {item.content} - {new Date(item.createdDate).toLocaleString('en-US', dateOptions.time)}</p>
              ))}
            </div>
          ) :
          (
            <div>
              {userObj.items?.map(item => (
                <p key={item.id} className="ml-32 mb-0">&#x2022; {getLogDescription(item.eventType)} - {new Date(item.createdDate).toLocaleString('en-US', dateOptions.time)}</p>
              ))}
            </div>
          )}
      </Collapse>
    </>
  );
};

export default NestedDropDown;
