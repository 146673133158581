import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import Funds from 'components/global/Funds';
import { fundsSignals } from 'components/global/Funds/Funds.signals';
import { useLocation } from 'react-router-dom';

const EdoVcProgramMembershipDetail = () => {
  const location = useLocation();
  const segments = location.pathname.split('/');
  let labels = [];
  const { fundingProgramMembership } = fundsSignals.value;
  if (!!fundingProgramMembership) {
    const { ventureCapitalFirm, fundingProgram } = fundingProgramMembership;
    if (location.pathname.includes('programs')) {
      labels = [
        '',
        !!fundingProgram ? fundingProgram.name : '',
        !!fundingProgram ? `Funds in ${fundingProgram.name}` : '',
      ];
    } else {
      labels = [
        segments[2] === 'vc' ? 'Venture Capitalists' : '',
        !!ventureCapitalFirm ? ventureCapitalFirm.name : '',
        !!fundingProgram ? `Funds in ${fundingProgram.name}` : '',
      ];
    }
  }

  return (
    <BreadCrumbWrapper labels={labels}>
      <Funds />
    </BreadCrumbWrapper>
  );
};

export default EdoVcProgramMembershipDetail;
