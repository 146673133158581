import Signal from '../../../libs/signals';
import $appSettings from 'signals/AppSettings.signal';

const { APPROVAL_CHAIN_AMOUNT_BOUNDS } = $appSettings.value.constants;

export const APPROVAL_CHAIN_TEMPLATE_STEP_INIT_STATE = [
  {
    primaryApproverId: '',
    alternateApprovers: [],
    role: '',
    boardRepresentative: false,
  },
];

const approvalChainTemplatingSignal = Signal({
  amount: '',
  oneThresholdActive: 'false',
  thresholdsMatrix: [
    [
      APPROVAL_CHAIN_AMOUNT_BOUNDS.min,
      APPROVAL_CHAIN_AMOUNT_BOUNDS.max,
    ],
  ],
  approvalChainTemplateStepsMatrix: [APPROVAL_CHAIN_TEMPLATE_STEP_INIT_STATE],
  users: [],
});

export default approvalChainTemplatingSignal;
