import Signal from 'libs/signals';
import { ProgramRequestApprovalChainMembership } from '../../../types';

type ProgramRequestDetailSignal = {
  confirmStatusVisible: boolean;
  approvedAmount: string;
  approvedAmountError: string;
  inlineModalVisible: boolean;
};

const programRequestDetailSignal = Signal<ProgramRequestDetailSignal>({
  approvedAmount: '',
  approvedAmountError: '',
  confirmStatusVisible: false,
  inlineModalVisible: false,
});

type ApprovalChainMembershipsSignal = {
  records: ProgramRequestApprovalChainMembership[];
};

export const $programRequestApprovalChainMemberships =
  Signal<ApprovalChainMembershipsSignal>({
    records: [],
  });

type ProgramRequestApprovalChainConfirmationFormSignal = {
  note: string;
  file: null | File;
  skippedSteps: number[];
  fallbackToAlternatesSteps: Record<string, number[]>;
  keyDates: Record<string, Date>;
};

export const $programRequestApprovalChainConfirmationForm =
  Signal<ProgramRequestApprovalChainConfirmationFormSignal>({
    note: '',
    file: null,
    skippedSteps: [],
    fallbackToAlternatesSteps: {},
    keyDates: {},
  });

export default programRequestDetailSignal;
