import Signal from 'libs/signals';

export const lenderProgramsSignal = Signal([]);

export const lenderProgramRequestsSignal = Signal([]);

export const lenderFundingProgramMembershipsSignal = Signal([]);

export const lenderProgramSelectedApplicationSignal = Signal(null);

export const lenderProgramRequestsStatusCountSignal = Signal({
  numApprovedRequests: 0,
  numPendingRequests: 0,
  numIncompleteRequests: 0,
  numDeniedRequests: 0,
  allRequests: 0,
});

export const lenderProgramRequestsFilterSignal = Signal({
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  bundle: {},
  appliedFilters: {},
});
