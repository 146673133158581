import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import SimpleTable from '../../SimpleTable';
import {
  $programRequestApprovalChainConfirmationForm,
  $programRequestApprovalChainMemberships,
} from '../ProgramRequestDetail.signals';
import dateOptions from '../../Constant/dateOptions';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import {
  handleApprovalChainConfirmation,
  handleFileChange,
  handleNoteChange,
} from '../ProgramRequestDetail.helpers';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const AlertExplanation = ({ currentConfirmation }) => {
  const { fundingProgram, status } = applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  if (
    fundingProgram?.referenceProgram.programType.programTypeName === 'Credit'
  ) {
    if (status === APPLICATION_STATUS.approved) {
      return (
        <Alert variant="success">
          <h3>Approved</h3>
          <p>Already approved.</p>
        </Alert>
      );
    } else {
      return (
        <Alert variant="info">
          <h3>Approval Pending</h3>
          <p>
            Any admin can approve this program request without the need for an
            approval chain.
          </p>
        </Alert>
      );
    }
  }

  if (
    fundingProgram?.referenceProgram.programType.programTypeName === 'Equity'
  ) {
    if (status === APPLICATION_STATUS.pending) {
      return (
        <Alert variant="info">
          <h3>To be configured</h3>
          <p>Approval chain has not been configured.</p>
        </Alert>
      );
    } else if (status === APPLICATION_STATUS.approved) {
      return (
        <Alert variant="success">
          <h3>Approved</h3>
          <p>
            This program request when through the corresponding approval
            process.
          </p>
        </Alert>
      );
    } else {
      return (
        <Alert variant="info">
          <h3>Traversing Approval Chain</h3>
          {currentConfirmation &&
          currentConfirmation.userId === userAccountSignal.value.userData.id ? (
            <p>It is your turn to give your approval.</p>
          ) : (
            <p>
              Equity program access must be approved by designated approvers
              once submitted for approval.
            </p>
          )}
        </Alert>
      );
    }
  }

  return null;
};

const APPROVERS_HEADERS = [
  {
    key: 'turn',
    title: 'Turn',
  },
  {
    key: 'approver',
    title: 'Approver',
  },
  {
    key: 'role',
    title: 'Role',
  },
  {
    key: 'alternate',
    title: 'Alternate',
  },
  {
    key: 'status',
    title: 'Status',
  },
];

function buildApproversTableColumns(handledByBoard) {
  if (handledByBoard) {
    return [
      ...APPROVERS_HEADERS.slice(0, 4),
      ...[{ key: 'boardMeetingDate', title: 'Meeting Date' }],
      ...APPROVERS_HEADERS.slice(5),
    ];
  } else {
    return APPROVERS_HEADERS;
  }
}

function computeStatus(approvalChainMembership) {
  const { confirmedDate, revokedDate } = approvalChainMembership;

  if (confirmedDate) {
    return `Confirmed on ${confirmedDate}`;
  }

  if (revokedDate) {
    return `Revoked on ${revokedDate}`;
  }

  return 'Pending';
}

const ProgramRequestApprovalChainTab = () => {
  const { records: approvalChainMemberships } =
    $programRequestApprovalChainMemberships.value;
  const { approvedAmount, requestedAmount, status } =
    applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const currentConfirmation = approvalChainMemberships.find(
    (acm) => !acm.confirmedDate
  );

  return (
    <div className="mt-16">
      <AlertExplanation currentConfirmation={currentConfirmation} />
      {status === APPLICATION_STATUS.submittedForApproval && (
        <>
          <div className="bg-light py-8 px-16 mb-16">
            <h5>Approved Amount</h5>
          </div>
          <div className="px-16">
            <Row>
              <Col xs={6} className="offset-3">
                <p>
                  <b>Requested Amount: </b>
                  {requestedAmount.toLocaleString(
                    'en-US',
                    dateOptions.currencyUSNoDecimal
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="offset-3">
                <div className="d-flex">
                  <Field.Currency
                    className="flex-grow-1"
                    allowCents={false}
                    label="Approved Amount"
                    value={approvedAmount || 0}
                    name="approvedAmount"
                    onChange={() => {
                      console.log('canetta');
                    }}
                  />
                  <Button
                    className="rounded mt-32 ml-16"
                    variant="outline-primary"
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
      {currentConfirmation &&
        currentConfirmation.userId === userAccountSignal.value.userData.id && (
          <>
            <div className="bg-light py-8 px-16 mb-16 mt-48">
              <h5>Give Approval</h5>
            </div>
            <div>
              <Row>
                <Col xs={6} className="offset-3">
                  <Field.TextArea
                    label="Note"
                    labelClassName="font-weight-bold"
                    value={
                      $programRequestApprovalChainConfirmationForm.value.note
                    }
                    onChange={(e) => handleNoteChange(e.value)}
                  />
                  <div className="mt-16">
                    <label className="font-weight-bold">File</label>
                    <div className="border p-8">
                      <input
                        type="file"
                        // @ts-ignore
                        onChange={(e) => handleFileChange(e.target.files[0])}
                        accept=".xls,.xlsx,.pdf,.jpg,.jpeg"
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      disabled={loaderSignal.value.isContentLoading}
                      onClick={() =>
                        handleApprovalChainConfirmation(currentConfirmation)
                      }
                      variant="outline-primary"
                      className="rounded mt-32 w-100"
                    >
                      Approve
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      <div className="bg-light py-8 px-16 mb-16 mt-48">
        <h5>Approvers</h5>
      </div>
      <SimpleTable
        headers={buildApproversTableColumns(false)}
        rows={approvalChainMemberships.map((acm, index) => ({
          turn: index + 1,
          approver: `${acm.user.firstName} ${acm.user.lastName}`,
          role: acm.role,
          alternate: acm.alternate ? 'Yes' : 'No',
          status: computeStatus(acm),
        }))}
      />
    </div>
  );
};

export default ProgramRequestApprovalChainTab;
