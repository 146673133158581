import CloseModalButton from 'components/global/Constant/CloseModalButton';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';

import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import dateOptions from '../../Constant/dateOptions';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import programRequestDetailSignal, {
  $programRequestApprovalChainConfirmationForm,
} from '../ProgramRequestDetail.signals';
import {
  handleApprovalChainTemplateChange,
  handleApprovedAmountChange,
  hideConfirmStatusModal,
  updateProgramRequestStatus,
} from '../ProgramRequestDetail.helpers';
import { titleCaseString } from '../../../../libs/string';
import ApprovalChainSelectForm from '../../ApprovalChainSelectForm/ApprovalChainSelectForm';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const ConfirmStatusProgramRequestFields = () => {
  const {
    newApplicationStatus,
    requestedAmount,
    ventureCapitalist,
    fundingProgramStats,
  } = applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  const { approvedAmount, approvedAmountError } =
    programRequestDetailSignal.value;

  if (
    newApplicationStatus === APPLICATION_STATUS.submittedForApproval ||
    newApplicationStatus === APPLICATION_STATUS.approved
  ) {
    if (ventureCapitalist) {
    } else {
    }
  } else {
    return (
      <p>
        Are you sure you want to update the status to{' '}
        <b>{titleCaseString(newApplicationStatus)}</b>?
      </p>
    );
  }

  if (!ventureCapitalist) {
    return (
      <div>
        <p>
          Upon approving the lender to the program, they will be able to submit
          loan applications.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          To finish approving this program request, please fill out the details
          below and select confirm.
        </p>

        <p>
          <b>Available Amount: </b>
          {Number(fundingProgramStats.remaining).toLocaleString(
            'en-US',
            dateOptions.currencyUSNoDecimal
          )}
        </p>

        <p>
          <b>Requested Amount: </b>
          {Number(requestedAmount).toLocaleString(
            'en-US',
            dateOptions.currencyUSNoDecimal
          )}
        </p>

        <Tabs defaultActiveKey="form" className="mt-24">
          <Tab eventKey="form" title="Loan Details">
            <div className="mt-16">
              {newApplicationStatus ===
                APPLICATION_STATUS.submittedForApproval && (
                <>
                  <Field.TextInput
                    label={'Approved Amount'}
                    labelClassName="my-8"
                    name={'approvedAmount'}
                    value={approvedAmount}
                    onChange={(e) => handleApprovedAmountChange(e.value)}
                  />
                  <small className="text-danger" hidden={!approvedAmountError}>
                    {approvedAmountError}
                  </small>
                </>
              )}
            </div>
          </Tab>
          <Tab eventKey="approvalChain" title="Approval Chain">
            <ApprovalChainSelectForm
              amount={approvedAmount === '' ? '0' : approvedAmount}
              approvalChainTemplate={
                applicationDetailSignal.value.approvalChainTemplates &&
                applicationDetailSignal.value.approvalChainTemplates[0]
              }
              onFieldChange={handleApprovalChainTemplateChange}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
};

const ProgramRequestConfirmStatusModal = () => {
  const { newApplicationStatus, approvalChainTemplates } =
    applicationDetailSignal.value;
  const { confirmStatusVisible, approvedAmount, approvedAmountError } =
    programRequestDetailSignal.value;
  const { skippedSteps } = $programRequestApprovalChainConfirmationForm.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  const skippingEveryStep =
    (skippedSteps || []).length ===
    (approvalChainTemplates && approvalChainTemplates.length > 0
      ? approvalChainTemplates[0].steps.length
      : -1);

  const errorInAmount =
    newApplicationStatus === APPLICATION_STATUS.submittedForApproval
      ? !!approvedAmountError || !approvedAmount
      : false;

  return (
    <ModalWrapper
      size="lg"
      className="upper-modal"
      backdropClassName="upper-modal-backdrop"
      centered
      show={confirmStatusVisible}
      onHide={() => hideConfirmStatusModal()}
      onExited={() =>
        applicationDetailSignal.update({
          approvedInputFieldsErrors: {},
          approvedInputFieldsDirty: {},
        })
      }
    >
      <Modal.Header>
        {newApplicationStatus === APPLICATION_STATUS.submittedForApproval && (
          <h4>Submit for approval</h4>
        )}
        {newApplicationStatus === APPLICATION_STATUS.approved && (
          <h4>Approve</h4>
        )}

        {newApplicationStatus !== APPLICATION_STATUS.approved &&
          newApplicationStatus !== APPLICATION_STATUS.submittedForApproval && (
            <h4>Update Status</h4>
          )}
        <CloseModalButton onCloseModal={() => hideConfirmStatusModal()} />
      </Modal.Header>
      <Modal.Body>
        <ConfirmStatusProgramRequestFields />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          className="rounded mr-8"
          onClick={() => hideConfirmStatusModal()}
        >
          Cancel
        </Button>
        <Button
          className="rounded"
          disabled={errorInAmount || skippingEveryStep}
          onClick={() => updateProgramRequestStatus()}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ProgramRequestConfirmStatusModal;
