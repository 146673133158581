import Loader from '../Loader';

const Loadable = ({ isLoading, children }) =>
  console.log(isLoading) || (
    <div className="position-relative">
      {isLoading && (
        <div
          style={{ zIndex: 50 }}
          className="position-absolute top-0 left-0 bg-white w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <Loader message="Loading..." />
        </div>
      )}
      {children}
    </div>
  );

export default Loadable;
