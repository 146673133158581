import { useCallback, useEffect } from 'react';
import pushNotificationsSignal from './PushNotifications.signal';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import {
  dismissPushNotification,
  getNotificationsToken,
} from './PushNotifications.helpers';
import { buildNotificationParams } from '../../../libs/notifications';
import { useHistory } from 'react-router-dom';
import userAccountSignal from '../../../signals/UserAccount.signal';
import { markRead } from '../NotificationDrawer/NotificationDrawer.helpers';

const PushNotifications = () => {
  const { notification } = pushNotificationsSignal.value;
  const history = useHistory();

  const init = useCallback(async () => {
    await getNotificationsToken();
  }, []);

  useEffect(() => {
    init();
  }, []);

  const notificationParams = notification
    ? buildNotificationParams(
        notification,
        history,
        userAccountSignal.value.userData.account.portalType,
        markRead
      )
    : null;

  return (
    <ToastContainer
      position="bottom-end"
      className="position-fixed"
      style={{ zIndex: 100000, bottom: '20px', right: '20px' }}
    >
      <Toast show={!!notification}>
        <Toast.Header closeButton={false}>
          <strong>Incoming Notification</strong>
        </Toast.Header>
        <Toast.Body>
          <p>{notificationParams?.notificationTitle}</p>
          <p className="text-right">
            <Button
              size="sm"
              className="rounded"
              variant="outline-primary"
              onClick={() => {
                dismissPushNotification();
              }}
            >
              Dismiss
            </Button>
            {notificationParams?.actionTitle &&
              notificationParams?.endpointName && (
                <Button
                  size="sm"
                  className="ml-8 rounded"
                  variant="primary"
                  onClick={() => {
                    notificationParams?.handleNavigation();
                    dismissPushNotification();
                  }}
                >
                  Inspect
                </Button>
              )}
          </p>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default PushNotifications;
