import { Alert, Button, Col, Collapse, Modal, Row } from 'react-bootstrap';
import applicationDetailSignal from '../../ApplicationDetail.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import {
  ApplicationApprovalChainConfig,
  ApplicationApprovalChainConfirmation,
  User,
} from 'types';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchAndSetApprovalChainUsers,
  handleApprovalChainConfirmation,
  handleRevokeApproval,
} from '../../ApplicationDetail.helpers';
import loaderSignal from 'signals/Loader.signal';
import DisbursementDetails from './DisbursementDetails';
import { DateTime } from 'luxon';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import CloseModalButton from 'components/global/Constant/CloseModalButton';

const ApplicationApprovalChainTab = () => {
  const {
    approvalChainConfirmations,
    status,
    submittedByEdo,
    approvalChainConfig,
    approvalChainUsers,
    approvedOnCommit,
    revokeApprovalChainApprovalVisible,
  } = applicationDetailSignal.value;
  const totalNumberOfConfirmations = approvalChainConfirmations.length;
  const currentNumberOfConfirmations = approvalChainConfirmations.filter(
    (c) => !!c.confirmedDate
  ).length;
  const approvalChainComplete =
    totalNumberOfConfirmations === currentNumberOfConfirmations;
  const currentPersonToApproveIndex: number | null = approvalChainComplete
    ? null
    : currentNumberOfConfirmations;
  const { APPLICATION_STATUS } = $appSettings.value.constants;


  const handledByBoard =
    approvalChainConfirmations.length === 1 &&
    approvalChainConfirmations[0].boardRepresentative;

  const userAlreadyApprovedConfirmation = approvalChainConfirmations.find(
    (confirmation) =>
      confirmation.confirmedById === userAccountSignal.value.userData.id
  );

  const approvalChainUsersFetcher = useCallback(() => {
    fetchAndSetApprovalChainUsers();
  }, [status]);

  useEffect(() => {
    approvalChainUsersFetcher();
  }, [approvalChainUsersFetcher]);

  return (
    <>
      <ModalWrapper
        centered
        show={revokeApprovalChainApprovalVisible}
        onHide={() => applicationDetailSignal.update({ revokeApprovalChainApprovalVisible: false })}
        backdropClassName={null}
        onExit={null}
        onExited={null}
        size="md"
        className={undefined}
      >
        <Modal.Header>
          <h5>Revoke Approval</h5>
          <CloseModalButton onCloseModal={() => applicationDetailSignal.update({ revokeApprovalChainApprovalVisible: false })} />
        </Modal.Header>
        <Modal.Body>
        This action cannot be undone. Are you sure you want to revoke approval?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => applicationDetailSignal.update({ revokeApprovalChainApprovalVisible: false })} variant="outline-secondary">
            Cancel
          </Button>
          <Button onClick={() => handleRevokeApproval(userAlreadyApprovedConfirmation)}>
            Confirm
          </Button>
        </Modal.Footer>
      </ModalWrapper>
      <Row>
        <Col>
          <ApprovalChainExplanationAlertAndApprovalForm
            approvalChainConfirmations={approvalChainConfirmations}
            currentPersonToApproveIndex={currentPersonToApproveIndex}
            applicationStatus={status}
            submittedByEdo={submittedByEdo}
            approvalChainConfig={approvalChainConfig}
            approvalChainUsers={approvalChainUsers}
            approvedOnCommit={approvedOnCommit}
          />
          <div className="bg-light py-8 px-16 mb-16">
            <h5>Approvers</h5>
          </div>
          <div className="overflow-auto px-32">
            {!!userAlreadyApprovedConfirmation &&
              !userAlreadyApprovedConfirmation.revokedDate && (
                <div className="d-flex flex-row justify-content-end">
                  <Button
                    onClick={() => applicationDetailSignal.update({ revokeApprovalChainApprovalVisible: true })}
                  >
                    Revoke Approval
                  </Button>
                </div>
              )}
            <Row className="font-weight-bold border-bottom pb-2 mx-2 text-nowrap">
              <Col xs={1}></Col>
              {handledByBoard ? (
                <>
                  <Col xs={4}>Board representative</Col>
                  <Col xs={4}>Meeting Date</Col>
                </>
              ) : (
                <>
                  <Col xs={3}>Approver</Col>
                  <Col xs={3}>Role</Col>
                  <Col xs={2}>Is Alternate?</Col>
                </>
              )}
              <Col
                xs={
                  submittedByEdo && status === APPLICATION_STATUS.approved
                    ? 2
                    : 3
                }
              >
                Status
              </Col>
              {submittedByEdo && status === APPLICATION_STATUS.approved && (
                <Col xs={1}>Actions</Col>
              )}
            </Row>
            {approvalChainConfirmations.map(
              (approvalChainConfirmation, index) => (
                <ApprovalChainConfirmationRow
                  key={approvalChainConfirmation.uuid}
                  status={status}
                  handledByBoard={!!handledByBoard}
                  submittedByEdo={submittedByEdo}
                  index={index}
                  approvalChainConfirmation={approvalChainConfirmation}
                  currentNumberOfConfirmations={currentNumberOfConfirmations}
                  approvalChainUsers={approvalChainUsers}
                  approvedOnCommit={approvedOnCommit}
                />
              )
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const ApprovalChainConfirmationRow: React.FC<{
  approvalChainConfirmation: ApplicationApprovalChainConfirmation;
  index: number;
  submittedByEdo: boolean;
  handledByBoard: boolean;
  status: string;
  currentNumberOfConfirmations: number;
  approvalChainUsers: User[];
  approvedOnCommit: boolean;
}> = ({
  approvalChainConfirmation,
  index,
  submittedByEdo,
  handledByBoard,
  status,
  currentNumberOfConfirmations,
  approvalChainUsers,
  approvedOnCommit,
}) => {
  const { alternatesFallback, approverIds } = approvalChainConfirmation;
  const approver = approvalChainUsers.find(
    (user) => user.id === approverIds[0]
  )!;

  const [showCollapsible, setShowCollapsible] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [confirmedDate, setConfirmedDate] = useState<Date>();

  if (approvalChainUsers.length === 0) {
    return null;
  }

  return (
    <>
      <Row className="py-2 mx-2">
        <Col xs={1}>{index + 1}</Col>
        <Col xs={handledByBoard ? 4 : 3}>
          <b>
            {approver.firstName} {approver.lastName}
          </b>
        </Col>
        {handledByBoard ? (
          <Col xs={4}>
            {DateTime.fromISO(approvalChainConfirmation.keyDate!).toFormat(
              'DD'
            )}
          </Col>
        ) : (
          <>
            <Col xs={3}>{approvalChainConfirmation.role}</Col>
            <Col xs={2}>{alternatesFallback ? 'Yes' : 'No'}</Col>
          </>
        )}
        <ChainLinkStatusCell
          approvedOnCommit={approvedOnCommit}
          onConfigureSubmit={() => setShowCollapsible(!showCollapsible)}
          submittedByEdo={submittedByEdo}
          index={index}
          status={status}
          approvalChainConfirmation={approvalChainConfirmation}
          currentNumberOfConfirmations={currentNumberOfConfirmations}
        />
      </Row>
      <Collapse in={showCollapsible}>
        <Row className="justify-content-center border-top pt-3 py-2 mx-2">
          <Col xs={2}>
            <Field.DateSelect
              label="Confirmation date"
              labelClassName="font-weight-bold mb-8"
              // @ts-ignore
              value={confirmedDate}
              name={undefined}
              // @ts-ignore
              onChange={(e) => setConfirmedDate(e.value)}
              className={undefined}
              inputClassName="form-control"
              placeholder={'Enter date'}
              disabled={false}
              isInvalid={undefined}
            />
          </Col>
          <Col xs={5}>
            <Field.TextArea
              label="Add a note:"
              labelClassName="font-weight-bold"
              name={undefined}
              value={note}
              onChange={(e: any) => setNote(e.value)}
              className={undefined}
              inputClassName={undefined}
              disabled={false}
            />
          </Col>
          <Col xs={4}>
            <label className="font-weight-bold">Attach a file:</label>
            <div className="border p-8">
              <input
                type="file"
                // @ts-ignore
                onChange={(e) => setFile(e.target.files[0])}
                accept=".xls,.xlsx,.pdf,.jpg,.jpeg"
              />
            </div>
          </Col>
          <Col xs={1}>
            <div className="pt-32">
              <Button
                disabled={loaderSignal.value.isContentLoading}
                onClick={() => {
                  handleApprovalChainConfirmation(
                    approvalChainConfirmation,
                    note,
                    file,
                    confirmedDate
                  );
                }}
                variant="outline-info"
                className="rounded"
                size="sm"
              >
                Update
              </Button>
            </div>
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

const ChainLinkStatusCell: React.FC<{
  approvedOnCommit: boolean;
  submittedByEdo: boolean;
  approvalChainConfirmation: ApplicationApprovalChainConfirmation;
  currentNumberOfConfirmations: number;
  status: string;
  index: number;
  onConfigureSubmit: () => any;
}> = ({
  onConfigureSubmit,
  index,
  approvalChainConfirmation,
  currentNumberOfConfirmations,
  status,
  approvedOnCommit,
}) => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  if (approvedOnCommit) {
    return (
      <>
        <Col xs={2}>
          {approvalChainConfirmation.confirmedDate
            ? DateTime.fromISO(
                approvalChainConfirmation.confirmedDate
              ).toFormat('DD')
            : 'Pending configuration'}
        </Col>
        <Col xs={1}>
          <Button
            onClick={() => onConfigureSubmit()}
            variant="outline-info"
            className="rounded btn-sm"
          >
            Configure
          </Button>
        </Col>
      </>
    );
  }

  if (approvalChainConfirmation.revokedDate) {
    return (
      <Col xs={3}>
        <i>Revoked on {approvalChainConfirmation.revokedDate}</i>
      </Col>
    );
  }

  if (approvalChainConfirmation.confirmedDate) {
    return (
      <Col xs={3}>
        Approved on{' '}
        {new Date(approvalChainConfirmation.confirmedDate).toLocaleDateString()}
      </Col>
    );
  }

  if (status !== APPLICATION_STATUS.submittedForApproval) {
    return <Col xs={3}>-</Col>;
  }

  // if (
  //   userAccountSignal.value.userData.id ===
  //   approvalChainConfirmation.approverIds[0]
  // ) {
  //   return (
  //     <td>
  //       <Button
  //         onClick={() => handleApprovalChainLinkApproval()}
  //         disabled={submitting}
  //         variant="outline-info small"
  //         className="rounded"
  //         size="sm"
  //       >
  //         Approve
  //       </Button>
  //     </td>
  //   );
  // }

  return (
    <Col xs={3}>
      {currentNumberOfConfirmations === index
        ? 'Waiting for approval'
        : 'Queued up'}
    </Col>
  );
};

const ApprovalChainExplanationAlertAndApprovalForm: React.FC<{
  currentPersonToApproveIndex: number | null;
  approvalChainConfirmations: ApplicationApprovalChainConfirmation[];
  applicationStatus: string | null;
  submittedByEdo: boolean;
  approvalChainConfig: ApplicationApprovalChainConfig | null;
  approvalChainUsers: User[];
  approvedOnCommit: boolean;
}> = ({
  approvalChainConfirmations,
  currentPersonToApproveIndex,
  applicationStatus,
  approvalChainConfig,
  approvedOnCommit,
}) => {
  const [file, setFile] = useState<File>();
  const [note, setNote] = useState<string>('');
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  if (!approvalChainConfig && approvedOnCommit) {
    return (
      <Alert variant="info" className="my-24">
        <Alert.Heading>Retroactive Approval Chain Submission</Alert.Heading>
        <p>
          Even though this application is approved it is missing its approval
          chain configuration.
        </p>
        <hr />
        <Row>
          <Col className="text-right">
            <Button
              onClick={() =>
                applicationDetailSignal.update({ confirmStatusVisible: true })
              }
              variant="outline-info"
              className="rounded mt-32"
            >
              Configure
            </Button>
          </Col>
        </Row>
      </Alert>
    );
  } else {
    if (applicationStatus === APPLICATION_STATUS.approved) {
      return (
        <Alert variant="success" className="my-24">
          <Alert.Heading>Application Approved</Alert.Heading>
          <p>The application has been approved.</p>
        </Alert>
      );
    }

    if (applicationStatus !== APPLICATION_STATUS.submittedForApproval) {
      return (
        <Alert variant="info" className="my-24">
          <Alert.Heading>Waiting on Submission for Approval.</Alert.Heading>
          <p>
            This application must be submitted for approval by an administrator
            before traversing the Approval Chain.
          </p>
        </Alert>
      );
    }
    const currentConfirmation =
      approvalChainConfirmations[currentPersonToApproveIndex!];

    if (!currentConfirmation) {
      return null;
    }

    return (
      <>
        <Alert variant="info" className="my-24">
          <Alert.Heading>Traversing the Approval Chain</Alert.Heading>

          {currentConfirmation.approverIds[0] ===
          userAccountSignal.value.userData.id ? (
            <p>It is your turn to give your approval.</p>
          ) : (
            <p>{"It is someone else's turn to give their approval"}.</p>
          )}
        </Alert>
        <DisbursementDetails />
        {currentConfirmation.approverIds[0] ===
          userAccountSignal.value.userData.id && (
          <div className="border-bottom pb-24">
            <div className="bg-light py-8 px-16 mb-16">
              <h5>Actions</h5>
            </div>
            <Row className="justify-content-center px-32">
              <Col lg={5}>
                <Field.TextArea
                  label="Add a note:"
                  labelClassName="font-weight-bold"
                  name={undefined}
                  value={note}
                  onChange={(e: any) => setNote(e.value)}
                  className={undefined}
                  inputClassName={undefined}
                  disabled={false}
                />
              </Col>
              <Col lg={5}>
                <label className="font-weight-bold">Attach a file:</label>
                <div className="border p-8">
                  <input
                    type="file"
                    // @ts-ignore
                    onChange={(e) => setFile(e.target.files[0])}
                    accept=".xls,.xlsx,.pdf,.jpg,.jpeg"
                  />
                </div>
              </Col>
              <Col lg={2}>
                <div className="text-center">
                  <Button
                    disabled={loaderSignal.value.isContentLoading}
                    onClick={() =>
                      handleApprovalChainConfirmation(
                        currentConfirmation,
                        note,
                        file
                      )
                    }
                    variant="outline-info"
                    className="rounded mt-32"
                  >
                    Approve
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
};

export default ApplicationApprovalChainTab;
