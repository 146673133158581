export const TRIBAL_GOVERNMENT_PROGRAM_TRANSACTION_TYPE = [
  'In-jurisdiction transaction with business on tribal lands',
  'In-jurisdiction transaction with business in states where the Tribe is physically located or within which the Tribe exercises jurisdiction',
  'In-jurisdiction transaction with Tribal enterprise-operated business, business owned by Tribal members, or business in a state in which Tribal members reside',
  'Out-of-jurisdiction transaction - transaction which does not fit into any of the categories above',
  'N/A',
];

export const TRANSACTION_SOURCE_OF_PRIVATE_CAPITAL = [
  'CDFI bank, thrift, credit union, or depository institution holding company',
  'Non-CDFI community bank',
  'Other bank, thrift, or depository institution holding company',
  'CDFI loan fund',
  'Non-CDFI credit union',
  'Nonbank lender or nonbank payment services provider',
  'Non-CDFI debt or loan fund',
  'Growth equity capital fund',
  'Angel investor or angel fund',
  'Accelerator or incubator fund',
  'For-profit venture capital fund',
  'Nonprofit venture capital fund or venture/entrepreneurial development organization',
  'Corporate venture capital fund',
  'CDFI venture capital fund',
  'Tribal enterprise new funds',
  'Other for-profit investor',
  'Other non-profit investor',
];

export const PURPOSE_OF_LOAN_OR_INVESTMENT = [
  'Marketing, market research, and commercialization expenses',
  'Research and development',
  'Technology integration in physical production, e.g., manufacturing or supply chain',
  'Technology integration of nonphysical production, e.g., accounting, customers',
  'Acquire land',
  'Purchase existing building',
  'Convert, expand, or renovate buildings - energy efficiency',
  'Convert, expand, or renovate buildings - other',
  'Construct new buildings',
  'Acquire and install fixed assets',
  'Acquire inventory',
  'Purchase supplies and raw materials',
  'Leasehold improvements',
  'Working capital - wages, salaries, and benefits of employees',
  'Refinance outstanding debt',
  'Support employee stock ownership plan (ESOP) transactions',
  'Other',
];

export const SEDI_SELF_CERTIFIED = [
  'Self-certified',
  'The business did not certify',
  'N/A',
];

export const SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS = {
  race: {
    label: 'Race',
    value:
      'Self-certified due to membership of a group that has been subjected to racial or ethnic prejudice or cultural bias within American society',
  },
  gender: {
    label: 'Gender',
    value: 'Self-certified due to gender',
  },
  veteranStatus: {
    label: 'Veteran Status',
    value: 'Self-certified due to veteran status',
  },
  limitedEnglishProficiency: {
    label: 'Limited English Proficiency',
    value: 'Self-certified due to limited English proficiency',
  },
  disability: {
    label: 'Disability',
    value: 'Self-certified due to disability',
  },
  isolatedLongTermResidence: {
    label: 'Isolated Long-Term Residence',
    value:
      'Self-certified due to long-term residence in an environment isolated from the mainstream of American society',
  },
  indianTribe: {
    label: 'Indian Tribe',
    value:
      'Self-certified due to membership of a Federally or state recognized Indian tribe',
  },
  ruralCommunityLongTermResidence: {
    label: 'Rural Community Long-Term Residence',
    value: 'Self-certified due to long-term residence in a rural community',
  },
  residenceUSTerritory: {
    label: 'Residence in a US territory',
    value: 'Self-certified due to residence in a US territory',
  },
  residenceInCommunityUndergoingTransitions: {
    label: 'Residence in a community undergoing transitions',
    value:
      'Self-certified due to residence in a community undergoing economic transitions',
  },
  membershipOfAnotherUnderservedCommunity: {
    label: 'Membership of another underserved community',
    value: 'Self-certified due to membership of another underserved community',
  },
  'n/a': {
    label: 'N/A',
    value: 'N/A',
  },
};
