import { Col, Row } from 'react-bootstrap';
import { organizationDetailsSignal } from '../OrganizationsList.signal';
import { Link, useLocation } from 'react-router-dom';
import { handleProgramMembershipLinkClick } from '../OrganizationsList.helpers';
import { addHttpsToUrl } from 'libs/string';
import { createGoogleMapsLink } from 'utils/queryAddresses';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import BusinessDetails from './BusinessDetails';
import $appSettings from 'signals/AppSettings.signal';

const OrganizationDetails = ({
  hideOrganizationName = false,
  showLinkToPrograms = true,
  headerButton = null,
}) => {
  const { selectedOrganization } = organizationDetailsSignal.value;
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;
  const { pathname } = useLocation();

  if (selectedOrganization.sediCertification) {
    return (
      <BusinessDetails
        hideOrganizationName={hideOrganizationName}
        headerButton={headerButton}
      />
    );
  }

  return (
    <div className="pb-16">
      <h3 hidden={hideOrganizationName} className="mb-16">
        {selectedOrganization.name}
      </h3>
      <hr hidden={hideOrganizationName} />
      <Row>
        <Col>
          <p className="font-weight-bold mb-0">Contact Name</p>
          {selectedOrganization.contactName}
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Email</p>
          <a
            href={`mailto:${
              selectedOrganization.email || selectedOrganization.contactEmail
            }`}
          >
            {selectedOrganization.email || selectedOrganization.contactEmail}
          </a>
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Phone Number</p>
          <a href={`tel:${selectedOrganization.phoneNumber}`}>
            {selectedOrganization.phoneNumber}
          </a>
        </Col>
        <Col xs={3} className="text-overflow-ellipsis">
          <p className="font-weight-bold mb-0">Website</p>
          <a
            href={
              selectedOrganization?.website
                ? addHttpsToUrl(selectedOrganization.website)
                : ''
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {selectedOrganization.website}
          </a>
        </Col>
      </Row>
      <Row className="mt-40">
        <Col xs={3}>
          <p className="font-weight-bold mb-0">Address</p>
          <p className="mb-0">
            <a
              href={
                selectedOrganization.address?.formattedAddress
                  ? createGoogleMapsLink(
                      selectedOrganization.address?.formattedAddress
                    )
                  : null
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedOrganization.address?.formattedAddress}
            </a>
          </p>
        </Col>
        <Col xs={3}>
          <p className="font-weight-bold mb-0">EIN</p>
          {selectedOrganization.ein}
        </Col>
        <Col hidden={!selectedOrganization.naicsCode}>
          <p className="font-weight-bold mb-0">NAICS Code</p>
          {selectedOrganization.naicsCode}
        </Col>
      </Row>
      <Row className="mt-40">
        <Col>
          <p className="font-weight-bold mb-0">Description</p>
          {selectedOrganization.description}
        </Col>
      </Row>
      <Row
        className="mt-40"
        hidden={!selectedOrganization.fundingProgramMemberships}
      >
        <Col xs={3}>
          <p className="font-weight-bold mb-0">Program Membership(s)</p>
          {selectedOrganization.fundingProgramMemberships?.map((fpm) => {
            let url;
            if (
              userAccountSignal.value.userData.account.portalType ===
              PORTAL_TYPES.edo
            ) {
              url = `${pathname}/program-membership/${fpm.id}`;
            } else if (
              userAccountSignal.value.userData.account.portalType ===
              PORTAL_TYPES.business
            ) {
              url = `/business/funding-programs/${fpm.fundingProgramId}`;
            }

            return (
              <div key={fpm.id}>
                {showLinkToPrograms ? (
                  <Link
                    to={url}
                    className="mb-0"
                    onClick={() =>
                      handleProgramMembershipLinkClick(fpm, url, pathname)
                    }
                  >
                    {fpm.fundingProgram.name}
                  </Link>
                ) : (
                  <p className="mb-0">{fpm.fundingProgram.name}</p>
                )}
              </div>
            );
          })}
        </Col>
        <Col xs={3}>
          <p className="font-weight-bold mb-0">Pending Program Membership(s)</p>
          {selectedOrganization.programRequests
            ?.filter(
              (pr) =>
                pr.status !== APPLICATION_STATUS.approved &&
                pr.status !== APPLICATION_STATUS.denied
            )
            .map((pr) => (
              <p key={pr.id} className="mb-0">
                {pr.fundingProgram.name}
              </p>
            ))}
        </Col>
        <Col
          hidden={
            userAccountSignal.value.userData.account.portalType !==
              PORTAL_TYPES.business ||
            selectedOrganization?.account?.portalType !==
              PORTAL_TYPES.vc
          }
        >
          <p className="font-weight-bold mb-0">Funds</p>
          {selectedOrganization.fundingProgramMemberships?.map((fpm) => (
            <div key={fpm.id}>
              {fpm.funds.map((fund) => (
                <div key={fund.id}>
                  {showLinkToPrograms ? (
                    <Link
                      to={`${location.pathname}/funds/${fund.id}`}
                      className="mb-0"
                    >
                      {fund.fundName}
                    </Link>
                  ) : (
                    <p className="mb-0">{fund.fundName}</p>
                  )}
                </div>
              ))}
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationDetails;
