import { COLORS } from 'components/global/Constant/constants';
import { Button, Card, Dropdown, DropdownItem, Form } from 'react-bootstrap';
import {
  allCustomizationFieldsAreDefault,
  edoCustomizeCleanUp,
  handleBodyBackgroundChange,
  handleDiscardChangesClick,
  handleInputChange,
  handleLogoChange,
  handlePreviewChangesClick,
  handleSingleFieldDiscardClick,
  allCustomizationFieldsHaveNotChanged,
  singleCustomizationFieldHasNotChanged,
  setInitialCustomizeViewData,
} from './edoCustomize.helpers';
import { $edoCustomize } from './edoCustomize.signal';
import { useEffect, useRef } from 'react';
import Field from 'components/global/Field';
import SaveChangesModal from './components/SaveChangesModal';
import ResetToDefaultModal from './components/ResetToDefaultModal';

const BACKGROUND_OPTIONS = [
  {
    label: 'Light Gray',
    value: '#f0f0f0',
  },
  {
    label: 'Gray',
    value: '#d3d3d3',
  },
  {
    label: 'Beige',
    value: '#eae5e5',
  },
  {
    label: 'Off-White',
    value: '#f5f5f5',
  },
  {
    label: 'Taupe',
    value: '#a29b9b',
  },
];

const DiscardSingleChangeButton = ({ name, fileInputRef = null }) => (
  <div>
    <Button
      onClick={() => {
        handleSingleFieldDiscardClick(name);
        if (fileInputRef) {
          fileInputRef.current.value = null;
        }
      }}
      className="mr-16"
      variant="outline-secondary"
      disabled={singleCustomizationFieldHasNotChanged(name)}
    >
      Discard Change
    </Button>
  </div>
);

const EdoCustomize = () => {
  const { newWhiteLabelConfig } = $edoCustomize.value;
  const fileInputRef = useRef(null);
  const backgroundColorBundle = newWhiteLabelConfig?.bodyBackgroundColor
    ? BACKGROUND_OPTIONS.find(
        (option) => option.value === newWhiteLabelConfig?.bodyBackgroundColor
      )
    : { label: 'Off-White', value: '#f5f5f5' };

  useEffect(() => {
    setInitialCustomizeViewData();
    return () => edoCustomizeCleanUp();
  }, []);

  return (
    <Card>
      <SaveChangesModal />
      <ResetToDefaultModal fileInputRef={fileInputRef} />
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h3>Customize</h3>
          <div>
            <Button
              onClick={() =>
                $edoCustomize.update({ modalVisible: 'resetToDefault' })
              }
              variant="outline-info"
              disabled={allCustomizationFieldsAreDefault()}
            >
              Reset All to Default
            </Button>
          </div>
        </div>
        <hr />
        <div className="d-flex flex-row justify-content-between align-items-end">
          <div>
            <Form.Label className="font-weight-bold">
              Header Background Color
            </Form.Label>
            <Form.Control
              type="color"
              className="form-control"
              name="headerBackgroundColor"
              value={
                newWhiteLabelConfig?.headerBackgroundColor || COLORS.PRIMARY
              }
              onChange={handleInputChange}
            />
          </div>
          <DiscardSingleChangeButton name="headerBackgroundColor" />
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <Form.Label className="font-weight-bold">
              Body Background Color
            </Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                className="border-dark text-dark"
                style={{ backgroundColor: backgroundColorBundle?.value }}
              >
                {backgroundColorBundle?.label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {BACKGROUND_OPTIONS.map((option) => (
                  <DropdownItem
                    key={option.value}
                    className="py-16"
                    style={{ backgroundColor: option.value }}
                    onClick={() => handleBodyBackgroundChange(option.value)}
                  >
                    {option.label}
                  </DropdownItem>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <DiscardSingleChangeButton name="bodyBackgroundColor" />
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <p className="font-weight-bold">Logo</p>
            <input ref={fileInputRef} type="file" onChange={handleLogoChange} />
          </div>
          <DiscardSingleChangeButton
            fileInputRef={fileInputRef}
            name="logoBase64String"
          />
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-end">
          <div className="d-flex">
            <Field.TextInput
              label="Tagline"
              labelClassName="font-weight-bold"
              name="tagline"
              value={newWhiteLabelConfig?.tagline || ''}
              onChange={handleInputChange}
            />
          </div>
          <DiscardSingleChangeButton name="tagline" />
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <Form.Label className="font-weight-bold">
              Tagline Font Color
            </Form.Label>
            <Form.Control
              type="color"
              className="form-control"
              name="taglineColor"
              value={newWhiteLabelConfig?.taglineColor || '#ffffff'}
              onChange={handleInputChange}
            />
          </div>
          <DiscardSingleChangeButton name="taglineColor" />
        </div>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            size="lg"
            className="mr-16"
            onClick={handleDiscardChangesClick}
            disabled={allCustomizationFieldsHaveNotChanged()}
            variant="outline-secondary"
          >
            Cancel
          </Button>
          <Button
            size="lg"
            className="mr-16"
            onClick={handlePreviewChangesClick}
            variant="secondary"
          >
            Preview Changes
          </Button>
          <Button
            size="lg"
            onClick={() =>
              $edoCustomize.update({ modalVisible: 'saveChanges' })
            }
          >
            Save Changes
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default EdoCustomize;
