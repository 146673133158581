import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import {
  fetchAndSetFunds,
  handleEdoFundsAppliedFiltersChange,
  handleEdoFundsFilterChange,
  resetEdoFundsFilterInputs,
} from './edoFundsPortfolio.helpers';
import FundsPortfolio from 'components/global/FundsPortfolio/FundsPortfolio';

const EdoFundsPortfolio = () => {
  const labels = ['Funds'];

  return (
    <BreadCrumbWrapper labels={labels}>
      <FundsPortfolio
        onFilterChange={handleEdoFundsFilterChange}
        onAppliedFilterChange={handleEdoFundsAppliedFiltersChange}
        onResetFilterInputs={resetEdoFundsFilterInputs}
        fetchAndSetFunds={fetchAndSetFunds}
      />
    </BreadCrumbWrapper>
  );
};

export default EdoFundsPortfolio;
