import DisplayNotesOrLogs from 'components/global/DisplayNotesOrLogs';
import { Col, Row } from 'react-bootstrap';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';

const ProgramRequestLogsTab = () => {
  const { logs } = applicationDetailSignal.value;

  return (
    <Row className="mt-16">
      <Col>
        <DisplayNotesOrLogs
          data={logs}
          displayType="logs"
          height={200}
          newNote={undefined}
          onSubmitNewNote={undefined}
          onNoteChange={undefined}
        />
      </Col>
    </Row>
  );
};

export default ProgramRequestLogsTab;
