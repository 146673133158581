import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import SearchProgramResults from 'components/global/SearchProgramResults';

const VCSearchProgramResults = () => {
  const labels = ['Program Requests', 'Funding Programs'];

  return (
    <BreadCrumbWrapper labels={labels}>
      <SearchProgramResults />
    </BreadCrumbWrapper>
  );
};

export default VCSearchProgramResults;
