import CloseModalButton from 'components/global/Constant/CloseModalButton';
import TextArea from 'components/global/Field/FieldComponents/TextArea';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import AttachmentListForm from '../../AttachmentsListForm';
import AddAttachmentsButton from '../../AddAttachmentsButton';
import {
  handleAddSendBackModalAttachments,
  handleChangeSendBackModalAttachmentName,
  handleRemoveSendBackModalAttachment,
  hideSendBackApplicationModal,
  sendApplicationToOppositeParty,
} from './ApprovableSendBackModal.helpers';
import { handleApplicationSendBackNoteChange } from './ApprovableStatusTab.helpers';
import $appSettings from 'signals/AppSettings.signal';

const ApprovableSendBackModal = () => {
  const { sendBackNoteBody, sendBackAttachments } =
    applicationDetailSignal.value;
  const { isContentLoading } = loaderSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const title =
    userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo
      ? 'What would you like to request?'
      : 'Is there anything you would like to mention?';

  return (
    <ModalWrapper
      show={applicationDetailSignal.value.sendBackVisible}
      centered
      backdropClassName={null}
      onExit={null}
      onExited={null}
      onHide={null}
      size={'xl'}
    >
      <Modal.Header className="pb-0 border-0 justify-content-end">
        <CloseModalButton onCloseModal={hideSendBackApplicationModal} />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <h5>{title}</h5>
        <TextArea
          disabled={false}
          label="Note"
          value={sendBackNoteBody}
          onChange={handleApplicationSendBackNoteChange}
          name={undefined}
          className={undefined}
          labelClassName={undefined}
          inputClassName={undefined}
        />
        <Row className="mt-8">
          <Col className="text-left">
            <h6>Attachments</h6>
          </Col>
          <Col className="text-right">
            <AddAttachmentsButton
              multiple={true}
              onChange={handleAddSendBackModalAttachments}
            />
          </Col>
        </Row>
        <Row className="mt-8">
          <Col>
            <AttachmentListForm
              attachments={sendBackAttachments}
              onRemove={handleRemoveSendBackModalAttachment}
              onChange={handleChangeSendBackModalAttachmentName}
            />
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-end px-16 mt-16">
          <Button
            disabled={isContentLoading}
            className="rounded"
            onClick={() => sendApplicationToOppositeParty()}
          >
            Send
          </Button>
        </Row>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default ApprovableSendBackModal;
