import { Button, ModalHeader, ModalBody, ModalFooter, Row, Col, Form } from 'react-bootstrap';
import userInvitesSignal from 'signals/UserInvites.signal';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import pullOrganizationNameFromAccount from 'libs/pullOrganizationNameFromAccount';
import dateOptions from 'components/global/Constant/dateOptions';
import { hideUserInvitationModal, updateUserInvitePortal, resendUserInvitation, handleReinviteUserNoteChange } from './ReinviteUserModal.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import loaderSignal from 'signals/Loader.signal';
import capitalizeFirst from 'utils/capitalizeFirst';

const ReinviteUserModal = () => (
  <ModalWrapper centered size="lg" show={!!userInvitesSignal.value.selectedUserInvitation}>
    <ModalHeader className="d-flex justify-content-between">
      <h5 className="mb-0">Invite User</h5>
      <CloseModalButton onCloseModal={hideUserInvitationModal} />
    </ModalHeader>
    <ModalBody className="px-16">
      <Row className="mb-32">
        <Col>
          <h5>{`Approval Status: ${capitalizeFirst(userInvitesSignal.value?.selectedUserInvitation?.status)}`}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg>
          <p className="fw-bold">Name:<span className="fw-italic"> {userInvitesSignal.value?.selectedUserInvitation?.firstName} {userInvitesSignal.value?.selectedUserInvitation?.lastName}</span></p>
        </Col>
        <Col xs={12} lg>
          <p className="fw-bold">Organization:<span className="fw-italic"> {pullOrganizationNameFromAccount(userInvitesSignal.value?.selectedUserInvitation?.account)}</span></p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg>
          <p>{`Email: ${userInvitesSignal.value?.selectedUserInvitation?.email}`}</p>
        </Col>
        <Col xs={12} lg>
          {userInvitesSignal.value?.selectedUserInvitation?.portal !== 'NONE' ? <p>{`Portal Type: ${capitalizeFirst(userInvitesSignal.value?.selectedUserInvitation?.portal)}`}</p> : (
            <>
              <Form.Select
                name="portal"
                onChange={(e) => { updateUserInvitePortal(e); }}
              >
                <option> Please Select Portal</option>
                <option value="EDO">EDO</option>
                <option value="BUSINESS">Business</option>
                <option value="STATE">State</option>
                <option value="LENDER">Lender</option>
                <option value="VC">VC</option>
              </Form.Select>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{`Invitation Date: ${new Date(userInvitesSignal.value?.selectedUserInvitation?.createdDate).toLocaleString('en-US', dateOptions.dateTime)}`}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Notes:</h6>
          <Form.Control
            as="textarea"
            value={userInvitesSignal.value.selectedUserInvitation?.notes}
            onChange={handleReinviteUserNoteChange}
          />
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter className="d-flex justify-content-end">
      <Button
        className="rounded"
        variant="outline-secondary"
        onClick={hideUserInvitationModal}
      >
        Cancel
      </Button>
      <Button
        className="rounded"
        disabled={loaderSignal.value.isContentLoading}
        onClick={resendUserInvitation}
      >
        Send Invitation
      </Button>
    </ModalFooter>
  </ModalWrapper>
);

export default ReinviteUserModal;
