import { Alert } from 'react-bootstrap';
import { onTestMode } from 'libs/functions/global.functions';

const TestModeBanner = () => {
  if (!onTestMode()) {
    return null;
  }

  return (
    <Alert variant="info" className="my-24">
      <p>
        Some data may have been populated in advance for demonstration purposes
        while in test mode. It may differ from production data.
      </p>
    </Alert>
  );
};

export default TestModeBanner;
