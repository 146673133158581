import SimpleTable from "components/global/SimpleTable";
import { Button } from "react-bootstrap";
import businessFundsSignal from "signals/BusinessFunds.signal";
import capitalizeFirst from "utils/capitalizeFirst";
import { getFundStatus } from "../businessFunds.helpers";
import { useHistory } from "react-router-dom";
import userAccountSignal from "signals/UserAccount.signal";
import { getStatusStyles } from "../../../../../libs/functions/global.functions";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'fundName',
    title: 'Fund Name',
  },
  {
    key: 'vc',
    title: 'VC',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const BusinessFundsTable = () => {
  const history = useHistory();
  const { funds } = businessFundsSignal.value;

  return (
    <>
      <SimpleTable
        headers={HEADERS}
        rows={funds.map((fund) => {
          const vcPortfolioCompany = fund.vcPortfolioCompanies.find(
            (vcpc) =>
              vcpc.businessId ===
              userAccountSignal.value.userData.account.business.id
          );
          const { fundApplication } = vcPortfolioCompany;
          return {
            status: () => {
              const status = getFundStatus(fund);
              return (
                <td className={`${getStatusStyles(status).class}`}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      {capitalizeFirst(status.replace(/_/g, ' '))}
                    </p>
                  </div>
                </td>
              );
            },
            fundName: fund.fundName,
            vc: fund.fundingProgramMembership.ventureCapitalFirm.name,
            action: () => (
              <td align="right">
                <Button
                  size="sm"
                  className="rounded"
                  variant="outline-dark"
                  onClick={() =>
                    history.push(
                      `${history.location.pathname}/${fundApplication.id}`
                    )
                  }
                >
                  Detail
                </Button>
              </td>
            ),
          };
        })}
      />
    </>
  );
};

export default BusinessFundsTable;
