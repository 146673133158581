import { Card, Modal } from 'react-bootstrap';
import { BasicFile } from 'components/global/DragAndDrop/File';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import { handleClearSelectMigratedApplication } from '../StateMigrate.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';

const MigratedApplicationDetailModal = ({ show, onToggleModal }) => {
  const { selectedMigratedData } = AdminMigrateSignal.value;

  const handleCloseModal = () => {
    handleClearSelectMigratedApplication();
    onToggleModal();
  };

  return (
    <ModalWrapper size="lg" centered className="border border-dark rounded" show={show} onHide={handleCloseModal}>
      <Modal.Header className="pb-0 pl-32 bg-light border-0" closeLabel="Close">
        <h5>{selectedMigratedData.programTitle}</h5>
      </Modal.Header>
      <Modal.Body className="bg-light rounded pt-0 px-16 px-md-32">
        <Card style={{ height: 500 }} className="px-16 px-md-32 py-16 overflow-auto rounded-0">
          <p className="mb-8 font-weight-bold">Files</p>
          {
            selectedMigratedData.files?.map(file => <BasicFile key={file.name} file={file} />)
          }
        </Card>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default MigratedApplicationDetailModal;
