import Field from 'components/global/Field';
import { Col, Row } from 'react-bootstrap';
import {
  CHART_OPTIONS,
  COLOR_MAP,
  PERIOD_OPTIONS,
  STATIC_LABELS,
  handleFilterRecordChange,
  handleQueryChange,
} from '../executiveDashboard.helpers';
import { Line } from 'react-chartjs-2';
import $executiveDashboard from '../executiveDashboard.signal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { camelCaseToTitleText } from 'utils/formatTxt';

const EntityLineChart = ({ entity }) => (
  <div className="p-24">
    <h5 className="border-bottom d-inline-flex">
      {camelCaseToTitleText(entity)}
    </h5>
    <Row>
      <Col className={entity === 'submissions' ? 'col-lg-4' : 'col-12'}>
        <Field.Select
          label="Created in the last"
          labelClassName="font-weight-bold"
          inputClassName="form-control"
          options={Object.values(PERIOD_OPTIONS).map(({ label, value }) => ({
            label,
            value,
          }))}
          value={$executiveDashboard.value[entity].query}
          onChange={(e) => handleQueryChange(e, entity)}
          noDefaultOption
        />
      </Col>
      <Col className={entity === 'submissions' ? 'border-left' : 'mt-16'}>
        <Row>
          {Object.keys($executiveDashboard.value[entity].filters).map(
            (keyName) => (
              <Col
                key={keyName}
                className={entity === 'submissions' ? 'col-lg-4' : 'col-6'}
              >
                <Field.Checkbox
                  label={STATIC_LABELS[keyName]}
                  labelClassName="font-weight-bold"
                  name={keyName}
                  value={$executiveDashboard.value[entity].filters[keyName]}
                  onChange={(e) => handleFilterRecordChange(e, entity)}
                />
              </Col>
            )
          )}
        </Row>
      </Col>
    </Row>
    <hr />
    <Line
      style={{ maxHeight: 450 }}
      data={{
        labels: $executiveDashboard.value[entity].labels,
        datasets: Object.keys($executiveDashboard.value[entity].tallies)
          .map((keyName, index) => ({
            label: STATIC_LABELS[keyName],
            data: $executiveDashboard.value[entity].tallies[keyName],
            backgroundColor: COLOR_MAP[index],
            borderColor: COLOR_MAP[index],
            borderWidth: 1,
            filter: $executiveDashboard.value[entity].filters[keyName],
          }))
          .filter((dataset) => dataset.filter),
      }}
      options={CHART_OPTIONS}
      plugins={[ChartDataLabels]}
    />
  </div>
);

export default EntityLineChart;
