import { Button, Container } from "react-bootstrap";
import SimpleTable from "components/global/SimpleTable";
import { setAndFetchBulkImportTasks, showProcessBulkImportFileModal } from "./EdoApplicationBulkImport.helpers";
import edoApplicationBulkImportSignal from "./EdoApplicationBulkImport.signals";
import { useEffect } from "react";
import EdoApplicationBulkImportProcessFileModal from "./EdoApplicationBulkImportProcessFileModal";
import { useHistory } from "react-router";
import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import PaginationItems from "components/global/Pagination";
import { DateTime } from "luxon";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'createdDate',
    title: 'Date Submitted',
  },
  {
    key: 'updatedDate',
    title: 'Last updated at',
  },
  {
    key: 'programName',
    title: 'Program Name',
  },
  {
    key: 'application',
    title: 'Application',
  },
];

function getStatusString(bulkImportTask){
  if (bulkImportTask.status === 'ERROR'){

    if (bulkImportTask.retries < 2) {
      return `ERROR - to be retried`
    } else {
      return 'ERROR - please inspect'
    }
  }

  return bulkImportTask.status;
}

const EdoApplicationBulkImport = () => {
  const { bulkImportTasks, totalPages } = edoApplicationBulkImportSignal.value;
  const history = useHistory();
  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );
  const currentPage = params.page ? Number(params.page) : 1;

  useEffect(() => {
    setAndFetchBulkImportTasks(currentPage);
  }, [history.location.search]);

  return (
    <BreadCrumbWrapper>
      <Container fluid>
        <EdoApplicationBulkImportProcessFileModal />
        <div className="bg-white p-32 border border-gray shadow-sm">
          <div className="d-flex flex-row justify-content-end">
            <div>
              <Button
                variant="primary"
                className="mb-16 rounded"
                onClick={() => showProcessBulkImportFileModal()}
              >
                Import Applications
              </Button>
            </div>
          </div>
          <SimpleTable
            headers={HEADERS}
            rows={bulkImportTasks.map((bulkImportTask, index) => ({
              id: bulkImportTask.id,
              status: getStatusString(bulkImportTask),
              createdDate: DateTime.fromISO(
                bulkImportTask.createdDate
              ).toFormat('DD HH:mm:ss'),
              updatedDate: DateTime.fromISO(
                bulkImportTask.updatedDate
              ).toFormat('DD HH:mm:ss'),
              programName:
                bulkImportTask.application?.fundingProgram?.name || 'Unknown',
              action: ({ key }) =>
                bulkImportTask.application ? (
                  <td key={key} className="text-right">
                    <Button
                      size="sm"
                      className="rounded"
                      variant="outline-dark"
                      onClick={() =>
                        history.push(
                          `/edo/portfolio/${bulkImportTasks[index].application.id}`
                        )
                      }
                    >
                      Detail
                    </Button>
                  </td>
                ) : (
                  <td> - </td>
                ),
            }))}
          />
          {!!bulkImportTasks.length && totalPages > 1 && (
            <PaginationItems
              totalPages={totalPages}
              className="justify-content-center mt-24"
            />
          )}
        </div>
      </Container>
    </BreadCrumbWrapper>
  );
};

export default EdoApplicationBulkImport;
