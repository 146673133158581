import dateOptions from 'components/global/Constant/dateOptions';
import { Form } from 'react-bootstrap';
import { formatCurrencyWithCentsInput } from 'utils/formatMoney';

const currencyWithCentsRegex = /[^0-9.]/g;

const Currency = ({
  label,
  name,
  value = 0,
  onChange,
  className,
  labelClassName,
  inputClassName,
  disabled,
  onBlur,
  isInvalid,
  feedbackMessage,
  allowCents,
  inputRef,
}) => {
  const handleCurrencyChange = (e) => {
    const { name, value: inputValue } = e.target;
    const cleanAmount = inputValue.replace(/[^0-9]/g, "");

    return onChange({ name, value: Number(cleanAmount) });
  };

  const handleCurrencyWithCentsChange = (e) => {
    const { name, value: inputValue } = e.target;
    let cleanAmount = inputValue;

    
    cleanAmount = cleanAmount.replace(currencyWithCentsRegex, ''); // only allow numbers
    
    const firstDotIndex = cleanAmount.indexOf('.');

    if (firstDotIndex !== -1) {
      // remove second dot and everything after if it exists
      const secondDotIndex = cleanAmount.indexOf('.', firstDotIndex + 1);

      if (secondDotIndex !== -1) {
        cleanAmount = cleanAmount.substring(0, secondDotIndex);
      }

      if (cleanAmount.substring(firstDotIndex + 1).length > 2) {
        // only allow for two decimal places
        cleanAmount = cleanAmount.substring(0, firstDotIndex + 3);
      }
    }
    return onChange({ name, value: cleanAmount });
  };

  const handleBlurWithCents = (e) => {
    const { name, value: inputValue } = e.target;
    const cleanAmount = Number(inputValue.replace(currencyWithCentsRegex, ''));
    onChange({ name, value: Number(cleanAmount.toFixed(2)) });
    onBlur && onBlur();
  };

  return (
    <div className={className}>
    {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <Form.Control
        ref={inputRef}
        placeholder='$'
        disabled={disabled}
        name={name}
        value={
          allowCents ?
          formatCurrencyWithCentsInput(value) :
          value.toLocaleString('en-US', dateOptions.currencyUSNoDecimal)
        }
        id="inputCurrency"
        className={inputClassName}
        onChange={allowCents ? handleCurrencyWithCentsChange : handleCurrencyChange}
        onBlur={allowCents ? handleBlurWithCents : onBlur}
        isInvalid={isInvalid}
      />
      <Form.Control.Feedback type="invalid">
        {feedbackMessage}
      </Form.Control.Feedback>
    </div>
  );
}

export default Currency;
