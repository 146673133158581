import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import twoStepVerificationFormSignal from 'signals/TwoStepVerification.signal';
import { sendEmailCode, sendSMSCode, update2FAEmail, update2FAPhone, handleEmailChange, handleSMSChange } from './TwoStepVerification.helpers';

const TwoStepVerificationModal = ({ show, onToggleModal }) => {
  const [step, setStep] = useState(1);

  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();
    await update2FAPhone();
    setStep(2);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    await update2FAEmail();
    setStep(2);
  };

  const handleSMSSubmit = async (e) => {
    e.preventDefault();
    await sendSMSCode();
    onToggleModal();
  };

  const onEmailCodeSubmit = async (e) => {
    e.preventDefault();
    await sendEmailCode();
    onToggleModal();
  };

  const handleReset = () => {
    twoStepVerificationFormSignal.reset();
    setStep(1);
  };

  return (
    <Modal centered show={show} size="lg" onHide={() => onToggleModal()}>
      <Modal.Header className="pb-0 pl-32 border-0" closeLabel="Close">
        <h5>Two-Step Verification</h5>
        <CloseModalButton onToggleModal={onToggleModal} />
      </Modal.Header>
      <Tabs className="mx-2" defaultActiveKey="sms" onSelect={handleReset}>
        <Tab eventKey="sms" title="SMS">
          {twoStepVerificationFormSignal.value.sms.active ?
            (
              <>
                <Modal.Body>
                  <Row>
                    <Col>
                      <p>Your SMS verification is active.</p>
                      <Button className="rounded" onClick={handleReset}>Reset SMS Verification</Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="rounded" variant="secondary" onClick={onToggleModal}>Close</Button>
                </Modal.Footer>
              </>
            ) :
            (
              <>
                {step === 1 ?
                  (
                    <>
                      <Modal.Body>
                        <Form id="phoneNumberForm" onSubmit={handlePhoneNumberSubmit}>
                          <h5>Enter your phone number</h5>
                          <Form.Group className="form-group">
                            <Form.Label className="col-form-label" htmlFor="phoneNumber" hidden>Phone Number</Form.Label>
                            <Form.Control required type="tel" className="form-control" id="phoneNumber" name="phoneNumber" onChange={handleSMSChange} />
                            <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="rounded" variant="secondary" onClick={() => onToggleModal()}>Close</Button>
                        <Button className="rounded" type="submit" form="phoneNumberForm">Send</Button>
                      </Modal.Footer>
                    </>
                  ) :
                  (
                    <>
                      <Modal.Body>
                        <Form id="smsCodeForm" onSubmit={handleSMSSubmit}>
                          <Form.Group className="form-group">
                            <Form.Label className="col-form-label" htmlFor="smsCode" hidden>SMS Code</Form.Label>
                            <Form.Control required type="text" className="form-control" id="smsCode" name="smsCode" onChange={handleSMSChange} />
                          </Form.Group>
                        </Form>
                        <Row>
                          <Col>
                            <p>Didn&apos;t recieve a verification code?</p>
                          </Col>
                          <Col>
                            <Button className="rounded" onClick={handlePhoneNumberSubmit}>Resend Code</Button>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="rounded" variant="secondary" onClick={onToggleModal}>Close</Button>
                        <Button className="rounded" type="submit" form="smsCodeForm">Submit</Button>
                      </Modal.Footer>
                    </>
                  )}
              </>
            )}
        </Tab>
        <Tab eventKey="email" title="Email">
          {twoStepVerificationFormSignal.value.email.active ?
            (
              <>
                <Modal.Body>
                  <Row>
                    <Col>
                      <Button className="rounded" onClick={handleReset}>Reset Email Verification</Button>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="rounded" variant="secondary" onClick={onToggleModal}>Close</Button>
                </Modal.Footer>
              </>
            ) :
            (
              <>
                {step === 1 ?
                  (
                    <>
                      <Modal.Body>
                        <Form id="emailForm" onSubmit={handleEmailSubmit}>
                          <h5>Enter your email address</h5>
                          <Form.Group className="form-group">
                            <Form.Label className="col-form-label" htmlFor="email" hidden>Email</Form.Label>
                            <Form.Control required type="email" className="form-control" id="email" name="email" onChange={handleEmailChange} />
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="rounded" variant="secondary" onClick={() => onToggleModal()}>Close</Button>
                        <Button className="rounded" type="submit" form="emailForm">Send</Button>
                      </Modal.Footer>
                    </>
                  ) :
                  (
                    <>
                      <Modal.Body>
                        <Form id="emailCodeForm" onSubmit={(e) => onEmailCodeSubmit(e)}>
                          <Form.Group className="form-group">
                            <Form.Label className="col-form-label" htmlFor="emailCode" hidden>SMS Code</Form.Label>
                            <Form.Control required type="text" className="form-control" id="emailCode" name="emailCode" onChange={handleEmailChange} />
                          </Form.Group>
                        </Form>
                        <Row>
                          <Col>
                            <p>Didn&apos;t recieve a verification code?</p>
                          </Col>
                          <Col>
                            <Button className="rounded" onClick={handleEmailSubmit}>Resend Code</Button>
                          </Col>
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="rounded" variant="secondary" onClick={() => onToggleModal()}>Close</Button>
                        <Button className="rounded" type="submit" form="emailCodeForm">Submit</Button>
                      </Modal.Footer>
                    </>
                  )}
              </>
            )}
        </Tab>
      </Tabs>
    </Modal>
  );
};

export default TwoStepVerificationModal;
