import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import FundingProgramDetail from 'components/global/FundingProgramDetail';
import fundingProgramDetailSignal from 'components/global/FundingProgramDetail/FundingProgramDetail.signal';

const LenderFundingProgramDetail = () => {
  const { fundingProgram } = fundingProgramDetailSignal.value;
  const labels = [
    'Program Requests',
    'Funding Programs',
    fundingProgram?.name || '',
  ];

  return (
    <BreadCrumbWrapper labels={labels}>
      <FundingProgramDetail />
    </BreadCrumbWrapper>
  );
};
export default LenderFundingProgramDetail;
