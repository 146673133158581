import SimpleTable from "components/global/SimpleTable";
import { useCallback, useEffect } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import formatMoney from "utils/formatMoney";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { FUND_STATUS } from "components/global/Constant/constants";
import { titleCaseString } from "libs/string";
import { handleTabChange } from "libs/functions/global.functions";
import fundsPortfolioSignal from "./fundsPortfolio.signal";
import { useHistory } from "react-router-dom";
import { capitalCallsTotalAmount } from "../../../libs/capitalCalls";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'fundName',
    title: 'Fund Name',
    sortable: true,
    bold: true,
  },
  {
    key: 'maxFundSize',
    title: 'Fund Size',
    sortable: true,
  },
  {
    key: 'remaining',
    title: 'Remaining',
  },
  {
    key: 'vc',
    title: 'VC',
    sortable: true,
  },
  {
    key: 'capitalCall',
    title: 'Capital Calls',
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const Table = ({
  onFilterChange,
  onAppliedFilterChange,
  fetchAndSetFunds,
  onResetFilterInputs,
}) => {
  const history = useHistory();
  const { funds, bundle, dropdownFilters } = fundsPortfolioSignal.value;

  return (
    <div>
      <div className="d-flex justify-content-between mb-16">
        <SimpleTableFilterDropdown
          filtersState={dropdownFilters}
          onChange={({ name, value }) => onFilterChange(name, value)}
          onApply={(filters) => {
            onAppliedFilterChange(filters);
            fetchAndSetFunds();
          }}
          onClearFilterInputs={onResetFilterInputs}
          filters={[
            {
              type: 'select',
              label: 'Status',
              key: 'status',
              options: Object.entries(FUND_STATUS).map(([, value]) => ({
                label: titleCaseString(value),
                value,
              })),
            },
            {
              type: 'text',
              label: 'VC',
              key: 'vc',
            },
            {
              type: 'select',
              label: 'Program Name',
              key: 'programName',
              options: bundle.fundingPrograms?.map((fundingProgram) => ({
                label: fundingProgram.name,
                value: fundingProgram.id,
              })),
            },
          ]}
        />
        <Button
          onClick={() => history.push(`${history.location.pathname}/new`)}
        >
          Load in Fund
        </Button>
      </div>
      <SimpleTable
        headers={HEADERS}
        onSort={(sort) => {
          handleTabChange(sort);
          fetchAndSetFunds();
        }}
        rows={funds.map((fund) => ({
          id: fund.id,
          fundName: fund.fundName,
          maxFundSize: formatMoney(fund.maxFundSize),
          remaining: formatMoney(
            fund.maxFundSize - capitalCallsTotalAmount(fund.capitalCalls)
          ),
          vc: fund.fundingProgramMembership.ventureCapitalFirm.name,
          capitalCall:
            fund.capitalCalls.length === 0
              ? '-'
              : fund.capitalCalls.length,
          programName: fund.fundingProgramMembership.fundingProgram.name,
          action: () => (
            <td align="right">
              <Button
                className="rounded"
                size="sm"
                variant="outline-dark"
                onClick={() =>
                  history.push(`${history.location.pathname}/${fund.id}`)
                }
              >
                Detail
              </Button>
            </td>
          ),
        }))}
      />
    </div>
  );
};

const FundsPortfolio = ({
  onFilterChange,
  onAppliedFilterChange,
  fetchAndSetFunds,
  onResetFilterInputs,
}) => {
  const { bundle } = fundsPortfolioSignal.value;
  const init = useCallback(async () => await fetchAndSetFunds(), []);

  useEffect(() => init(), []);

  return (
    <Tabs>
      <Tab eventKey="all" title={`All ${bundle.count}`}>
        <div className="border border-gray p-32 bg-white shadow-sm">
          <Table
            fetchAndSetFunds={fetchAndSetFunds}
            onFilterChange={onFilterChange}
            onAppliedFilterChange={onAppliedFilterChange}
            onResetFilterInputs={onResetFilterInputs}
          />
        </div>
      </Tab>
    </Tabs>
  );
};

export default FundsPortfolio;
