import loaderSignal from "signals/Loader.signal";
import { PORTAL_TYPE_BASE, SECURITY_TYPE } from "../Constant/constants";
import createCapitalCallSignal from "./createCapitalCall.signal";
import api from "api/api";
import userAccountSignal from "signals/UserAccount.signal";
import alertSignal from "signals/Alert.signal";
import { uploadFilesToStorage } from "libs/functions/global.functions";
import history from "../../../history";
import { handleViewFundApplicationModal } from "../FundApplication/FundApplication.helpers";
import $appSettings from "signals/AppSettings.signal";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const COI_CHECKLIST_CONTAINER_CLASS = 'coi-checklist-container';
export const COI_CHECKLIST_FIELD_CLASS = 'coi-checklist-field';

export const fetchAndSetFund = async (id) => {
  const { APPLICATION_STATUS, PORTAL_TYPES, FUND_DOCUMENT_TYPES } =
    $appSettings.value.constants;

  try {
    loaderSignal.update({ isContentLoading: true });
    let fund = {};

    const [funds, bankAccounts] = await Promise.all([
      api.get({
        path: '/funds',
        options: {
          where: {
            id: Number(id),
          },
          include: {
            documents: true,
            capitalCalls: {
              include: {
                bankAccount:
                  userAccountSignal.value.userData.account.portalType ===
                  PORTAL_TYPES.vc,
                documents: {
                  orderBy: {
                    id: 'desc',
                  },
                },
                approvalChainMemberships: {
                  include: {
                    user: true,
                  },
                },
                capitalCallPortions: {
                  include: {
                    vcPortfolioCompany: {
                      include: {
                        business: true,
                      },
                    },
                  },
                },
              },
            },
            vcPortfolioCompanies: {
              include: {
                fundApplication: {
                  include: {
                    documents: {
                      include: {
                        businessDocument: true,
                      },
                    },
                  },
                },
                fund: {
                  include: {
                    documents: true,
                  },
                },
                business: {
                  include: {
                    businessDocuments: true,
                  },
                },
              },
            },
            fundingProgramMembership: {
              include: {
                ventureCapitalFirm: true,
                fundingProgram: {
                  include: {
                    approvalChainTemplates: {
                      include: {
                        steps: {
                          include: {
                            primaryApprover: true,
                            alternateApprovers: {
                              include: {
                                user: true,
                              },
                            },
                          },
                        },
                      },
                    },
                    referenceProgram: {
                      include: {
                        programType: true,
                      },
                    },
                  },
                },
              },
            },
            fundRequests: {
              include: {
                business: true,
              },
            },
          },
        },
      }),
      userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.vc
        ? api.get({
          path: '/bankAccounts',
          options: {
            where: {
              accountId: userAccountSignal.value.userData.account.id,
            },
          },
        })
        : [],
    ]);

    if (funds.length) {
      fund = funds[0];
    }

    createCapitalCallSignal.update({
      portfolioCompanies: fund?.vcPortfolioCompanies,
      capitalCalls: !!fund?.capitalCalls?.length ? fund.capitalCalls : [],
      fundingProgram: fund?.fundingProgramMembership?.fundingProgram,
      name: fund?.fundName,
      maxFundSize: fund?.maxFundSize,
      id: fund?.id,
      newCapitalCallPortions: fund.vcPortfolioCompanies?.map((vcpc) => ({
        vcPortfolioCompanyId: vcpc.id,
        amount: 0,
        transactionTerms: {
          stageOfInvestment: '',
          securityType: '',
          otherSecurityOffered: '',
          ssbciOwnershipPercentage: '',
          conversionDiscount: '',
          valuationCap: '',
          typeOfValuationCap: '',
        },
      })),
      ventureCapitalFirm: fund?.fundingProgramMembership?.ventureCapitalFirm,
      documents: fund?.documents,
      fundRequests: fund?.fundRequests.filter(
        (fr) => fr.status !== APPLICATION_STATUS.approved
      ),
      makeCapitalCallDocuments: fund?.documents.filter(
        (doc) => doc.documentType === FUND_DOCUMENT_TYPES.capitalCall
      ),
      bankAccounts,
      status: null,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleCapitalCallAmountChange = (e, vcPortfolioCompanyId) => {
  const { value } = e;
  const { newCapitalCallPortions } = createCapitalCallSignal.value;

  const newArr = newCapitalCallPortions.map((capitalCallPortion) => {
    if (capitalCallPortion.vcPortfolioCompanyId === vcPortfolioCompanyId) {
      return {
        ...capitalCallPortion,
        amount: value,
      };
    }

    return capitalCallPortion;
  });

  createCapitalCallSignal.update({
    newCapitalCallPortions: newArr,
  });
};

export const handleCapitalCallTransactionTermsChange = (
  e,
  vcPortfolioCompanyId
) => {
  const { value, name } = e;
  const { newCapitalCallPortions } = createCapitalCallSignal.value;

  const newArr = newCapitalCallPortions.map((capitalCallPortion) => {
    if (capitalCallPortion.vcPortfolioCompanyId === vcPortfolioCompanyId) {
      return {
        ...capitalCallPortion,
        transactionTerms: {
          ...capitalCallPortion.transactionTerms,
          [name]: value,
        },
      };
    }

    return capitalCallPortion;
  });

  createCapitalCallSignal.update({
    newCapitalCallPortions: newArr,
  });
};

export const handleCapitalCallInputChange = (e) => {
  const { name, value } = e;

  createCapitalCallSignal.update({
    [name]: value,
    errors: {
      [name]: false,
    },
  });
};

const validateFundApplications = () => {
  const { portfolioCompanies, documents } = createCapitalCallSignal.value;
  const { FUND_DOCUMENT_TYPES, FUND_APPLICATION_DOCUMENT_STATUS } =
    $appSettings.value.constants;
  const requiredDocuments = documents.filter(
    (doc) => doc.documentType === FUND_DOCUMENT_TYPES.required
  );

  if (!portfolioCompanies.length) {
    throw new Error('There are no portfolio companies for this fund');
  }

  const allPortfolioCompaniesAreApproved = portfolioCompanies.every((pc) => {
    if (!pc.fundApplication.documents.length) {
      throw new Error('Please upload all fund application documents');
    }

    const allRequiredDocumentsAreApproved = requiredDocuments.every(
      (requiredDoc) => {
        const fundApplicationDocuments = [
          ...pc.fundApplication.documents.sort((a, b) => b.id - a.id),
        ];

        const matchingDocument = fundApplicationDocuments.find(
          (fundAppDoc) => fundAppDoc.fundDocumentId === requiredDoc.id
        );

        if (!matchingDocument) {
          throw new Error('Missing a required fund application document');
        }

        if (
          matchingDocument.status !== FUND_APPLICATION_DOCUMENT_STATUS.approved
        ) {
          throw new Error('All fund application documents must be approved');
        }

        return true;
      }
    );

    return allRequiredDocumentsAreApproved;
  });

  return allPortfolioCompaniesAreApproved;
};

const validateAndBuildCapitalCallPortions = () => {
  const { newCapitalCallPortions, portfolioCompanies } =
    createCapitalCallSignal.value;

  const capitalCallPortionTotal = newCapitalCallPortions.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );
  if (capitalCallPortionTotal <= 0) {
    throw new Error(
      'Please provide an amount for at least one portfolio company. All amounts must be greater or equal to 0'
    );
  }

  const validCapitalCallPortions = newCapitalCallPortions.map((ccp) => {
    const portfolioCompany = portfolioCompanies.find(
      (pc) => pc.id === ccp.vcPortfolioCompanyId
    );
    const { transactionTerms } = ccp;
    const trimmedTransactionTerms = {};

    if (ccp.amount < 0) {
      throw new Error(
        `The amount for ${portfolioCompany.business.name} cannot be less than 0`
      );
    }

    if (!transactionTerms.stageOfInvestment) {
      throw new Error(
        `Please provide a Stage of Investment for ${portfolioCompany.business.name}`
      );
    }

    if (!transactionTerms.securityType) {
      throw new Error(
        `Please provide a Security Type for ${portfolioCompany.business.name}`
      );
    }

    if (transactionTerms.securityType === SECURITY_TYPE.other) {
      if (!transactionTerms.otherSecurityTypeOffered) {
        throw new Error(
          `Please provide a description for Other Security Type for ${portfolioCompany.business.name}`
        );
      }

      trimmedTransactionTerms.otherSecurityOffered =
        transactionTerms.otherSecurityTypeOffered;
    }

    if (
      transactionTerms.securityType === SECURITY_TYPE.commonStock ||
      transactionTerms.securityType === SECURITY_TYPE.preferredStock
    ) {
      if (!transactionTerms.ssbciOwnershipPercentage) {
        throw new Error(
          `Please provide a SSBCI Ownership Percentage for ${portfolioCompany.business.name}`
        );
      }

      trimmedTransactionTerms.ssbciOwnershipPercentage =
        transactionTerms.ssbciOwnershipPercentage;
    }

    if (
      transactionTerms.securityType === SECURITY_TYPE.convertibleDebt ||
      transactionTerms.securityType === SECURITY_TYPE.standardAgreement
    ) {
      if (!transactionTerms.conversionDiscount) {
        throw new Error(
          `Please provide a Conversion Discount for ${portfolioCompany.business.name}`
        );
      }

      if (!transactionTerms.valuationCap) {
        throw new Error(
          `Please provide a Valuation Cap for ${portfolioCompany.business.name}`
        );
      }

      if (!transactionTerms.typeOfValuationCap) {
        throw new Error(
          `Please provide a Type of Valuation Cap for ${portfolioCompany.business.name}`
        );
      }

      trimmedTransactionTerms.conversionDiscount =
        transactionTerms.conversionDiscount;
      trimmedTransactionTerms.valuationCap = transactionTerms.valuationCap;
      trimmedTransactionTerms.typeOfValuationCap =
        transactionTerms.typeOfValuationCap;
    }

    return {
      ...ccp,
      transactionTerms: {
        stageOfInvestment: ccp.transactionTerms.stageOfInvestment,
        securityType: ccp.transactionTerms.securityType,
        ...trimmedTransactionTerms,
      },
    };
  });

  return validCapitalCallPortions;
};

export const handleCreateCapitalCallSubmission = async () => {
  const {
    id,
    suggestedDate,
    makeCapitalCallDocuments,
    selectedBankAccountId,
    status,
    portfolioCompanies,
  } = createCapitalCallSignal.value;

  const { PORTAL_TYPES } = $appSettings.value.constants;
  // const portal = PORTAL_TYPES_BASE[userAccountSignal.value.account.portalType];
  const portal =
    userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.edo
      ? PORTAL_TYPE_BASE.EDO
      : PORTAL_TYPE_BASE.VC;

  try {
    loaderSignal.update({ isContentLoading: true });
    const coisContainers = document.getElementsByClassName(
      COI_CHECKLIST_CONTAINER_CLASS
    );

    let coisBlobs = [];
    for (let i = 0; i < coisContainers.length; i++) {
      const coisContainer = coisContainers[i];
      const innerHTML = coisContainer.innerHTML;
      // Regular expression to match input tags
      const inputRegex = /<input[^>]*value="([^"]*)"[^>]*>/g;
      const match = innerHTML.match(inputRegex);
      const inputValues = match
        .map((inputHtml) => {
          const parser = new DOMParser();
          const input = parser
            .parseFromString(`${inputHtml.replace('>', '/>')}`, 'text/html')
            .querySelector('input');

          return input.value;
        })
        .reverse();

      const [fullname, ...initials] = inputValues;

      const business = portfolioCompanies[i].business;
      if (fullname === '') {
        throw new Error(
          `SSBCI Conflict of interest list signer's name for ${business.name} is missing.`
        );
      }

      if (initials.some((i) => !i)) {
        throw new Error(
          `At least one SSBCI Conflict of interest list is missing its initials for ${business.name}.`
        );
      }

      if ([...new Set(initials)].length !== 1) {
        throw new Error(
          `SSBCI Conflict of interest list initials do not match for ${business.name}.`
        );
      }

      // Remove the auto fill button tags
      const removeButtonTagsHtml = innerHTML.replace(
        '<button type="button" class="mr-16 py-0 btn btn-primary btn-sm">Auto Fill</button>',
        '',
      );

      // Replace input tags with div tags containing the input values
      // because inputs wont be rendered in the PDF
      const replacedHtml = removeButtonTagsHtml.replace(
        inputRegex,
        `<span>&nbsp;</span><span style="text-decoration: underline;">$1</span><span>&nbsp;</span>`
      );

      const docDefinition = {
        content: [htmlToPdfmake(replacedHtml)],
      };
      const pdf = pdfMake.createPdf(docDefinition);

      const pdfBlobPromise = new Promise((resolve) => {
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      });

      const blob = await pdfBlobPromise;
      coisBlobs.push(blob);
    }

    validateFundApplications();
    const validCapitalCallPortions = validateAndBuildCapitalCallPortions();

    if (makeCapitalCallDocuments.find((bundle) => !bundle.uploadedDocument)) {
      throw new Error(
        'Please upload all documents before making a capital call'
      );
    }

    if (!suggestedDate) {
      throw new Error('Please provide a date');
    }

    const capitalCallDocumentSignedUrls = await uploadFilesToStorage(
      makeCapitalCallDocuments
        .filter((bundle) => !!bundle.uploadedDocument)
        .map((bundle) => bundle.uploadedDocument),
      'capitalCallDocument'
    );

    const coisBlobsSignedUrls = await uploadFilesToStorage(
      coisBlobs,
      'capitalCallDocument'
    );

    await api.post({
      path: '/capitalCalls',
      body: {
        data: {
          status,
          fundId: id,
          capitalCallPortions: {
            create: validCapitalCallPortions.map((ccp, i) => ({
              ...ccp,
              ssbciCOIChecklist: {
                fileType: coisBlobs[i].type,
                filePath: coisBlobsSignedUrls[i],
              },
            })),
          },
          suggestedDate,
          capitalCallDocuments: makeCapitalCallDocuments
            .filter((bundle) => !!bundle.uploadedDocument)
            .map((bundle, i) => ({
              fundDocumentId: bundle.id,
              name: bundle.uploadedDocument.name,
              fileType: bundle.uploadedDocument.type,
              filePath: capitalCallDocumentSignedUrls[i],
            })),
          bankAccountId: selectedBankAccountId,
        },
      },
    });

    history.push(`${portal}/funds/${id}`);
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully created a capital call',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSelectBankAccount = (id) => {
  const { selectedBankAccountId } = createCapitalCallSignal.value;
  createCapitalCallSignal.update({
    selectedBankAccountId: selectedBankAccountId === id ? null : id,
  });
};

export const handleSelectCapitalCallFile = (file) => {
  const { makeCapitalCallDocuments, selectedCapitalCallTemplate } =
    createCapitalCallSignal.value;

  const newMakeCapitalCallDocuments = makeCapitalCallDocuments.map((doc) => {
    if (doc.id === selectedCapitalCallTemplate.id) {
      return {
        ...doc,
        uploadedDocument: file,
      };
    }

    return doc;
  });

  createCapitalCallSignal.update({
    makeCapitalCallDocuments: newMakeCapitalCallDocuments,
    selectedCapitalCallTemplate: null,
  });
};

export const handlePortfolioCompanyNameClick = (portfolioCompany) => {
  const { fundApplication, ...rest } = portfolioCompany;
  fundApplication.vcPortfolioCompany = rest;

  handleViewFundApplicationModal(fundApplication);
};

export const handleStatusChange = (e) => {
  const { value } = e;

  createCapitalCallSignal.update({ status: value });
};

export const checkApplicationDocuments = (portfolioCompany) => {
  const { FUND_DOCUMENT_TYPES, FUND_APPLICATION_DOCUMENT_STATUS , CAPITAL_CALL_STATUS } = $appSettings.value.constants;
  const requiredFundDocuments = portfolioCompany.fund.documents.filter(doc => doc.documentType === FUND_DOCUMENT_TYPES.required);

  if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPE_BASE.EDO && createCapitalCallSignal.value.status === CAPITAL_CALL_STATUS.funded) {
    return { background: 'success', message: 'Approved' }
  }

  const mostRecentSubmittedApplicationDocuments = requiredFundDocuments.map(requiredDoc =>
    portfolioCompany.fundApplication.documents.find(applicationDoc =>
      applicationDoc.fundDocumentId === requiredDoc.id) || null);

  if (!mostRecentSubmittedApplicationDocuments.every(submittedDocument => !!submittedDocument)) {
    return { background: 'danger', message: 'Application is missing documents' };
  }

  const allSubmittedDocumentsApproved = mostRecentSubmittedApplicationDocuments.every(submittedDocument => submittedDocument.status === FUND_APPLICATION_DOCUMENT_STATUS.approved);

  if (!allSubmittedDocumentsApproved) {
    return { background: 'warning', message: 'Application documents need approval' };
  }

  return { background: 'success', message: 'Approved' }
};
