import CloseModalButton from 'components/global/Constant/CloseModalButton';
import { Button, Modal } from 'react-bootstrap';
import { selectedFundingProgramSignal } from 'signals/Program.signal';
import { handleCloseModal } from '../EdoPrograms.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import EdoProgramDetail from '../../EdoProgramDetail/EdoProgramDetail';

const EditProgramModal = () => {
  const { programInfo } = selectedFundingProgramSignal.value;

  if (!programInfo) {
    return null;
  }

  return (
    <ModalWrapper
      show={!!programInfo}
      size="xl"
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header className="text-center">
        <h4>{programInfo?.name}</h4>
        <CloseModalButton onCloseModal={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <EdoProgramDetail modalView hideBreadCrumbs />
      </Modal.Body>
      <Modal.Footer>
        <Button className="rounded" variant="outline-secondary" onClick={handleCloseModal}>
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default EditProgramModal;
