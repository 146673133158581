import { Button, Col, Row } from 'react-bootstrap';
import applicationDetailSignal from '../../ApplicationDetail/ApplicationDetail.signal';
import { useHistory } from 'react-router-dom';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';

const ApprovableGeneralInformationTab = ({ isModal }) => {
  const {
    business,
    lender,
    fundingProgram,
    modelType,
    ventureCapitalist,
    approvedAmount,
    disbursementAmount,
    disbursementDate,
    id,
  } = applicationDetailSignal.value;
  const history = useHistory();
  const { pathname } = history.location;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  return (
    <>
      {modelType === 'application' && (
        <Row className="mb-24">
          <Col xs={9}>
            <h5>Loan Information</h5>
            <Row>
              <Col>
                <p className="font-weight-bold mb-8">Lump Sum</p>
                {approvedAmount ? '$' + approvedAmount.toLocaleString() : '-'}
              </Col>
              <Col>
                <p className="font-weight-bold mb-8">Disbursement Amount</p>
                {disbursementAmount
                  ? '$' + disbursementAmount.toLocaleString()
                  : '-'}
              </Col>
              <Col>
                <p className="font-weight-bold mb-8">Disbursement Date</p>
                {disbursementDate
                  ? new Date(disbursementDate).toLocaleDateString()
                  : '-'}
              </Col>
            </Row>
          </Col>
          <Col className="mx-16" />
        </Row>
      )}
      <hr />
      <Row>
        <Col xs={9}>
          <h5>Program Information</h5>
          <Row>
            <Col>
              <p className="font-weight-bold mb-8">Main Program</p>
              {fundingProgram?.name}
            </Col>
            <Col>
              <p className="font-weight-bold mb-8">Program Type</p>
              {fundingProgram?.referenceProgram.programType.programTypeName}
            </Col>
            <Col>
              <p className="font-weight-bold mb-8">Program</p>
              {fundingProgram?.name}
            </Col>
          </Row>
        </Col>
        <Col className="mx-16" />
      </Row>
      <hr />
      <div className="d-flex justify-content-between align-items-center my-16">
        <h5 className="my-16">
          {ventureCapitalist ? 'VC' : 'Lender'} Information
        </h5>
        <div>
          <Button hidden={modelType !== 'application' || userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.lender} variant="outline-primary" onClick={() => {
              if (isModal) {
                history.push(`${pathname}/${id}/lenders/${lender.id}`);
              } else {
                history.push(`${pathname}/lenders/${lender.id}`);
              }
            }}
          >
            View Lender Details
          </Button>
        </div>
      </div>
      <Row>
        <Col>
          <p className="font-weight-bold mb-8">Organization Name</p>
          {lender?.name}
        </Col>
        <Col>
          <p className="font-weight-bold mb-8">Contact Name</p>
          {lender?.contactName}
        </Col>
        <Col>
          <p className="font-weight-bold mb-8">Email</p>
          <a href={`mailto:${lender?.email}`}>{lender?.email}</a>
        </Col>
        <Col>
          <p className="font-weight-bold mb-8">Phone Number</p>
          <a href={`tel:${lender?.phoneNumber}`}>{lender?.phoneNumber}</a>
        </Col>
      </Row>
      {(business?.name ||
        business?.contactName ||
        business?.email ||
        business?.phoneNumber) && (
        <>
        <hr />
        <div className="d-flex justify-content-between align-items-center my-16">
          <h5>Business Information</h5>
          <div>
            <Button hidden={modelType !== 'application'} variant="outline-primary" onClick={() => {
              if (isModal) {
                history.push(`${pathname}/${id}/business/${business.id}`);
              } else {
                history.push(`${pathname}/business/${business.id}`);
              }
              }}
            >
              View Business Details
            </Button>
          </div>
        </div>
          <Row>
            <Col>
              <p className="font-weight-bold mb-8">Company Name</p>
              {business?.name}
            </Col>
            <Col>
              <p className="font-weight-bold mb-8">Contact Name</p>
              {business?.contactName}
            </Col>
            <Col>
              <p className="font-weight-bold mb-8">Email</p>
              <a href={`mailto:${business?.email}`}>{business?.email}</a>
            </Col>
            <Col>
              <p className="font-weight-bold mb-8">Phone Number</p>
              <a href={`tel:${business?.phoneNumber}`}>
                {business?.phoneNumber}
              </a>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ApprovableGeneralInformationTab;
