import { Form } from 'react-bootstrap';
import { handleSelectChange } from '../Field';

const ToggleSwitch = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
}) => {
  const handleChange = (e) => {
    handleSelectChange({ name: e.target.name, value: !value }, onChange);
  };
  return (
    <div className={className}>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <div>
        <div className="d-flex align-items-center">
          <Form.Check
            name={name}
            type="switch"
            id="custom-switch"
            checked={value}
            className={inputClassName}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
