import Signal from "libs/signals";

const edoTooltipsSignal = Signal({
  tooltips: [],
  title: '',
  supportingText: '',
  type: '',
  modalType: null,
  id: null,
  deleteTooltipPromptVisible: false,
  totalPages: 1,
  recordsPerPage: 20,
  tooltipErrors: {},
});

export default edoTooltipsSignal;
