import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import GeneralFundDetails from "components/global/FundDetails/components/GeneralDetails";
import { fetchAndSetFund, handleRequestAccessClick } from "components/global/FundDetails/fundDetails.helpers";
import fundDetailsSignal from "components/global/FundDetails/fundDetails.signal";
import { useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import RequestFundAccessModal from "components/global/FundDetails/components/RequestFundAccessModal";
import userAccountSignal from "signals/UserAccount.signal";

const BusinessFundDetail = () => {
  const location = useLocation();
  const segments = location.pathname.split('/');
  const fundId = segments[segments.length - 1];
  const { name, ventureCapitalFirm } = fundDetailsSignal.value;
  const labels = [ventureCapitalFirm?.name || 'VC', name || 'Fund'];
  const { fundRequests } = fundDetailsSignal.value;
  const fundRequestSubmitted = fundRequests.find(
    (fr) =>
      fr.businessId === userAccountSignal.value.userData.account.business.id
  );

  useEffect(() => fetchAndSetFund(fundId), []);

  return (
    <BreadCrumbWrapper labels={labels}>
      <RequestFundAccessModal />
      <div className="border border-gray p-24 pb-24 shadow-sm bg-white">
        <GeneralFundDetails />
        <hr />
        <div className="d-flex flex-row justify-content-center">
          {fundRequestSubmitted ? (
            <Alert>A request for this fund has been submitted.</Alert>
          ) : (
            <Button
              size="lg"
              className="rounded"
              onClick={handleRequestAccessClick}
            >
              Request Access
            </Button>
          )}
        </div>
      </div>
    </BreadCrumbWrapper>
  );
};

export default BusinessFundDetail;
