import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { caretDownIcon, caretRightIcon } from 'components/global/Constant/Icons';
import { useState } from 'react';
import { Button, Collapse, Container, Row } from 'react-bootstrap';
import { hideEinNumber } from 'utils/formatTxt';
import { handleDeleteMigration, handleSelectMigratedApplication } from '../StateMigrate.helpers';
import $appSettings from 'signals/AppSettings.signal';

const MigratedApplicationRow = ({ portalType, data, onToggleModal }) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const [showButtons, setShowButtons] = useState(false);
  const handleShowModalClick = () => {
    handleSelectMigratedApplication(data.id);
    onToggleModal();
  };

  return (
    <>
      <tr className="border border-solid border-bottom-0 border-right-0">
        <td style={{ minWidth: '2rem' }} className="align-middle">
          <Container className="p-0 text-center" onClick={() => setShowButtons(!showButtons)}>
            <FontAwesomeIcon size="lg" icon={showButtons ? caretDownIcon : caretRightIcon} />
          </Container>
        </td>
        <th className="py-0 align-middle text-nowrap">{data.program.title}</th>
        {portalType === PORTAL_TYPES.state && <td className="py-0 align-middle text-nowrap">{data.state}</td>}
        <td className="py-0 align-middle text-nowrap">{data.year}</td>
        <td className="py-0 align-middle text-nowrap">{hideEinNumber(data.ein)}</td>
        <td><Button size="sm" onClick={handleShowModalClick}>Details</Button></td>
      </tr>
      <tr className="border border-top-0 border-right-0">
        <td colSpan={4} className="p-0">
          <Container>
            <Collapse in={showButtons}>
              <Row>
                <Button size="sm" className="ml-8 mb-8" variant="danger" onClick={() => handleDeleteMigration(data)}>Delete</Button>
              </Row>
            </Collapse>
          </Container>
        </td>
      </tr>
    </>
  );
};

export default MigratedApplicationRow;
