import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Modal } from 'react-bootstrap';
import { organizationDetailsSignal } from '../OrganizationsList.signal';
import { hideOrganizationDetailsModal } from '../OrganizationsList.helpers';
import OrganizationDetails from './OrganizationDetails';
import CloseModalButton from 'components/global/Constant/CloseModalButton';

const OrganizationDetailsModal = ({ showLinkToPrograms }) => (
  <ModalWrapper
    show={organizationDetailsSignal.value.modalVisible}
    onHide={hideOrganizationDetailsModal}
    size="xl"
    centered
    backdropClassName={null}
    className={null}
    onExit={null}
    onExited={null}
  >
    <Modal.Header className="pb-0 border-0 justify-content-end">
      <CloseModalButton onCloseModal={hideOrganizationDetailsModal} />
    </Modal.Header>
    <Modal.Body className="pt-0">
      <OrganizationDetails showLinkToPrograms={showLinkToPrograms} />
    </Modal.Body>
    <Modal.Footer className="justify-content-end">
      <Button
        onClick={hideOrganizationDetailsModal}
        className="rounded"
        variant="outline-secondary"
      >
        Dismiss
      </Button>
    </Modal.Footer>
  </ModalWrapper>
);

export default OrganizationDetailsModal;
