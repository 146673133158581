import { Button, Card } from "react-bootstrap";
import VCDealFlowsSignal from "./VCDealFlows.signal";
import { useCallback, useEffect } from "react";
import {
  fetchAndSetVcDealFlowData,
  handlePortfolioCompanyDetailClick,
  handlePortfolioCompanyRowClick,
  handleRemovePortfolioCompanyClick,
  showNewVcPortfolioCompanyModal
} from "./VCDealFlows.helpers";
import AddPortfolioCompanyModal from "../../../global/AddPortfolioCompany/AddPortfolioCompanyModal";
import SimpleTable from "components/global/SimpleTable";
import RemovePortfolioCompanyModal from "./components/RemovePortfolioCompanyModal";
import VCPortfolioCompanyDetailsModal from "./components/VCPortfolioCompanyDetailsModal";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import BreadCrumbWrapper from "../../../global/BreadCrumbs/BreadCrumbs";
import NoFundsAlert from "./components/NoFundsAlert";
import { PORTFOLIO_COMPANY_MODAL_PARENTS } from "components/global/AddPortfolioCompany/addPortfolioCompany.helpers";
import addPortfolioCompanySignal from "components/global/AddPortfolioCompany/addPortfolioCompany.signal";
import { useLocation } from "react-router";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'businessName',
    title: 'Business Name',
    sortable: true,
  },
  {
    key: 'ein',
    title: 'EIN',
  },
  {
    key: 'fund',
    title: 'Fund',
    sortable: true,
  },
  {
    key: 'actions',
    title: 'Actions',
  },
];

const VCDealFlows = () => {
  const { vcPortfolioCompanies } = VCDealFlowsSignal.value;
  const { funds } = addPortfolioCompanySignal.value;
  const location = useLocation();

  const init = useCallback(() => {
    fetchAndSetVcDealFlowData();
    addPortfolioCompanySignal.update({
      parentView: PORTFOLIO_COMPANY_MODAL_PARENTS.DEAL_FLOW,
    });
  }, [location.search]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <BreadCrumbWrapper>
      {!funds.length && <NoFundsAlert />}
      <Card className="rounded-0">
        <Card.Body>
          <h3 className="text-center">Portfolio Companies</h3>
          <hr />
          <div className="d-flex justify-content-between mb-16">
            <SimpleTableFilterDropdown
              filters={[
                {
                  type: 'text',
                  label: 'Business Name',
                  key: 'businessName',
                },
                {
                  type: 'text',
                  label: 'Fund',
                  key: 'fundName',
                },
              ]}
            />
            <Button
              className="rounded"
              variant="primary"
              onClick={() => showNewVcPortfolioCompanyModal()}
            >
              New Portfolio Company
            </Button>
          </div>
          <SimpleTable
            headers={HEADERS}
            onClick={(index) => {
              const vcPortfolioCompany = vcPortfolioCompanies[index];
              handlePortfolioCompanyRowClick(vcPortfolioCompany);
            }}
            rows={vcPortfolioCompanies.map((vcPortfolioCompany) => ({
              id: vcPortfolioCompany.id,
              businessName: vcPortfolioCompany.business.name,
              ein: vcPortfolioCompany.business.ein,
              fundName: vcPortfolioCompany.fund.fundName,
              action: ({ key }: { key: string }) => (
                <td key={key} align="right">
                  <Button
                    size="sm"
                    className="ml-8 mb-8 rounded"
                    variant="outline-danger"
                    onClick={() =>
                      handleRemovePortfolioCompanyClick(vcPortfolioCompany)
                    }
                  >
                    Remove
                  </Button>
                  <Button
                    size="sm"
                    className="ml-8 mb-8 rounded"
                    variant="outline-dark"
                    onClick={() =>
                      handlePortfolioCompanyDetailClick(vcPortfolioCompany.id)
                    }
                  >
                    Details
                  </Button>
                </td>
              ),
            }))}
          />
        </Card.Body>
      </Card>
      <AddPortfolioCompanyModal />
      <RemovePortfolioCompanyModal />
      <VCPortfolioCompanyDetailsModal />
    </BreadCrumbWrapper>
  );
};

export default VCDealFlows;
