import Signal from 'libs/signals';

export const fundsSignals = Signal({
  funds: [],
  approvedProgramRequests: [],
  detailsModalVisible: false,
  capitalCallModalVisible: false,
  fundDetails: {
    id: '',
    fundingProgramId: '',
    maxFundSize: '',
    fundName: '',
  },
  capitalCall: {
    fundId: '',
    suggestedDate: '',
    allocationAmount: '',
    portfolioCompanies: [],
  },
  maxFundSizeError: '',
  capitalCallAmountError: '',
});
