/* eslint-disable react/destructuring-assignment */
import { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  currentTokenExpiringInSeconds,
  extendSessionByGettingNewToken,
  fetchAndSaveMsalAccountAndAuthToken,
  fetchAndSetCaatAccount,
  getMsalLoggedInAccount,
  performMsalLogoutViaRedirect,
  redirectToMsalLogin
} from "libs/functions/global.functions";
import Loader from "components/global/Loader";
import Header from "components/global/Header";
import { useHistory } from "react-router-dom";
import NotificationDrawer from "../NotificationDrawer/NotificationDrawer";
import PushNotifications from "../PushNotifications";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import loaderSignal from "signals/Loader.signal";
import $appSettings from "signals/AppSettings.signal";
import SystemUseNotification from "../SystemUseNotification/SystemUseNotification";
import $systemUseNotification from "../SystemUseNotification/systemUseNotification.signal";

const TIMEOUT_REMINDER_THRESHOLD_IN_SECS = 300;

function timeRemainingMessage(timeRemainingInSeconds) {
  let message = `Your session will timeout in `;
  if (timeRemainingInSeconds <= 60) {
    message += `${Math.floor(timeRemainingInSeconds)} secs`;
  } else {
    message += `${Math.floor(
      timeRemainingInSeconds / 60
    )} mins and ${Math.floor(timeRemainingInSeconds % 60)} secs`;
  }

  return `${message} and you will be automatically logged out.`;
}

const SessionReminder = () => {
  const { instance } = useMsal();
  const [outExpiringInSecs, setOutExpiringInSecs] = useState(null);
  const [dismissed, setDismissed] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const expiringInSecs = currentTokenExpiringInSeconds();
      setOutExpiringInSecs(expiringInSecs);

      return undefined;
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (outExpiringInSecs && outExpiringInSecs < 2) {
      performMsalLogoutViaRedirect(instance, instance.getActiveAccount());
    }
  }, [outExpiringInSecs]);

  const extendSession = async () => {
    setUpdating(true);
    const account = await getMsalLoggedInAccount(instance);
    await extendSessionByGettingNewToken(instance, account);
    setUpdating(false);
    setDismissed(false);
  };

  return (
    <ToastContainer
      position="bottom-end"
      className="position-fixed"
      style={{ zIndex: 100000, top: '20px', right: '20px' }}
    >
      <Toast
        show={
          !dismissed &&
          outExpiringInSecs &&
          0 < outExpiringInSecs &&
          outExpiringInSecs <= TIMEOUT_REMINDER_THRESHOLD_IN_SECS
        }
      >
        <Toast.Header closeButton={false}>
          <strong>Session expiring</strong>
          <span> </span>
        </Toast.Header>
        <Toast.Body>
          <p>{timeRemainingMessage(outExpiringInSecs)}</p>
          <p>Refresh the view or click on Extend to keep your session alive.</p>
          <p className="text-right">
            <Button
              size="sm"
              className="rounded"
              disable={updating}
              variant="outline-primary"
              onClick={() => {
                setDismissed(true);
              }}
            >
              Dismiss
            </Button>
            <Button
              size="sm"
              className="ml-8 rounded"
              variant="primary"
              disabled={updating}
              onClick={() => extendSession()}
            >
              {updating ? 'Submitting...' : 'Extend'}
            </Button>
          </p>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

const AppContainer = (props) => {
  const { instance } = useMsal();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line consistent-return
  const init = useCallback(async () => {
    let msalAccount = null;
    let userAccountSignal = null;

    try {
      msalAccount = await getMsalLoggedInAccount(instance);
      if (!msalAccount) {
        throw new Error('Could not find msal account');
      }
    } catch (err) {
      // if there's no account we can simply redirect to the login view
      return redirectToMsalLogin(instance);
    }

    try {
      await fetchAndSaveMsalAccountAndAuthToken(instance, msalAccount);
    } catch (error) {
      // token expired so we logout the user anyway
      // which will then redirect to login
      return performMsalLogoutViaRedirect(instance, msalAccount);
    }

    try {
      userAccountSignal = await fetchAndSetCaatAccount(instance, msalAccount);
    } catch (error) {
      setIsLoading(false);
    }

    if (!userAccountSignal) {
      return;
    }

    const { PORTAL_TYPES } = $appSettings.value.constants;
    switch (userAccountSignal.value.userData?.account?.portalType) {
      case PORTAL_TYPES.state:
        if (!history.location.pathname.startsWith('/state')) {
          history.replace('/state');
        }
        break;
      case PORTAL_TYPES.lender:
        if (!history.location.pathname.startsWith('/lender')) {
          history.replace('/lender');
        }
        break;
      case PORTAL_TYPES.business:
        if (!history.location.pathname.startsWith('/business')) {
          history.replace('/business/dashboard');
        }
        break;
      case PORTAL_TYPES.edo:
        if (!history.location.pathname.startsWith('/edo')) {
          history.replace('/edo');
        }
        break;
      case PORTAL_TYPES.vc:
        if (!history.location.pathname.startsWith('/vc')) {
          history.replace('/vc');
        }
        break;
        case PORTAL_TYPES.executive:
        if (!history.location.pathname.startsWith('/executive')) {
          history.replace('/executive');
        }
        break;
      default:
        break;
    }

    const systemUseAgreement = localStorage.getItem('systemUseAgreement');
    $systemUseNotification.update({ show: systemUseAgreement !== 'true' });

    setIsLoading(false);
  }, []);

  useEffect(() => {
    init();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
        <Loader message={loaderSignal.value.message || 'Loading...'} />
      </div>
    );
  }

  return (
    <>
      <SessionReminder />
      <SystemUseNotification />
      <PushNotifications />
      <Header />
      {props.children}
      <NotificationDrawer />
    </>
  );
};
export default AppContainer;
