/* eslint-disable no-unused-vars */
import api from "api/api";
import {
  applicationsChartPresets,
  loanAmountPieChartPresets,
  submissionsLineGraphPresets
} from "components/global/ChartJS/chartPresetsAndStyles";
import dateOptions from "components/global/Constant/dateOptions";
import alertSignal from "signals/Alert.signal";
import { dashboardSignal } from "signals/DashboardSignal";
import loaderSignal from "signals/Loader.signal";
import userAccountSignal from "../../../signals/UserAccount.signal";
import $appSettings from "signals/AppSettings.signal";
import { getIndustryType } from "utils/getIndustryType";
import { COLORS, PORTFOLIO_COMPANY_STATUS } from "../Constant/constants";
import capitalizeFirst from "utils/capitalizeFirst";
import { extractTextInParentheses } from "utils/formatTxt";

function pullNaicsCodeFromApplication(application) {
  if (application.business && application.business.naicsCode) {
    return application.business.naicsCode;
  }

  const formData = application.formData;

  return (
    formData.borrowerInformation_naics ||
    formData.businessInformation_naics ||
    formData.businessInformation_naicsCode
  );
}

export const handleSelectionChange = (e) => {
  const { value, name } = e.target;

  if (name === 'selectedPlatformId') {
    return dashboardSignal.update({
      [name]: Number(value),
      selectedFundingProgramId: '',
    });
  }

  return dashboardSignal.update({
    [name]: Number(value),
  });
};
export const fetchAndSetPlatforms = async () => {
  loaderSignal.update({ isContentLoading: true });

  try {
    const platforms = await api.get({
      path: '/platforms',
      options: {
        include: {
          stats: true,
          fundingPrograms: {
            include: {
              stats: true,
              referenceProgram: {
                include: {
                  programType: true,
                },
              },
            },
          },
        },
      },
    });
    dashboardSignal.update({ platforms });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getPlatformSelections = () =>
  dashboardSignal.value.platforms.map((platform) => (
    <option value={platform.id} key={platform.id}>
      {platform.name}
    </option>
  ));

export const updateProgramSelectionOptions = async () => {
  const { PROGRAM_REQUEST_STATUS, PORTAL_TYPES } = $appSettings.value.constants;
  if (
    userAccountSignal.value.userData.account.portalType ===
    PORTAL_TYPES.edo
  ) {
    const { platforms, selectedPlatformId } = dashboardSignal.value;

    const platform = platforms.find((p) => p.id === selectedPlatformId);

    return dashboardSignal.update({
      fundingPrograms: platform ? platform.fundingPrograms : [],
    });
  } else {
    try {
      loaderSignal.update({ isContentLoading: true });
      const programRequests = await api.get({
        path: '/programRequests',
        options: {
          include: {
            fundingProgram: true,
          },
          where: {
            lenderId: userAccountSignal.value.userData.account.lender.id,
          },
        },
      });

      const fundingPrograms = programRequests
        .filter((vl) => vl.status === PROGRAM_REQUEST_STATUS.approved)
        .map((vl) => vl.fundingProgram);

      return dashboardSignal.update({
        fundingPrograms,
      });
    } catch (error) {
      alertSignal.update({
        type: 'notification',
        error,
        message: error.message,
      });
    } finally {
      loaderSignal.reset();
    }
  }
};

export const getApplicationsMetrics = () => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const { applications } = dashboardSignal.value;
  const applicationsMetrics = applicationsChartPresets;

  const numApprovedApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.approved
  ).length;
  const numPendingApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.pending
  ).length;
  const numIncompleteApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.incomplete
  ).length;
  const numDeniedApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.denied
  ).length;
  const numSubmittedForApprovalApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.submittedForApproval
  ).length;
  const numOfInProgressApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.inProgress
  ).length;
  const numOfDefaultApplications = applications.filter(
    (app) => app.status === APPLICATION_STATUS.default
  ).length;

  const newApplicationMetrics = {
    ...applicationsMetrics,
    datasets: applicationsMetrics.datasets.map((dataset) => ({
      ...dataset,
      data: [
        numApprovedApplications,
        numSubmittedForApprovalApplications,
        numPendingApplications,
        numIncompleteApplications,
        numDeniedApplications,
        numOfInProgressApplications,
        numOfDefaultApplications,
      ],
    })),
  };

  dashboardSignal.update({ applicationsMetrics: newApplicationMetrics });
};

export const getSubmissionsPerDayMetrics = () => {
  const { applications } = dashboardSignal.value;
  const submissionsPerDayMetrics = submissionsLineGraphPresets;
  const lastSevenDays = [];

  for (let i = 6; i >= 0; i--) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - i);
    lastSevenDays.push(currentDate);
  }

  const labels = lastSevenDays.map((day) =>
    day.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })
  );
  const data = new Array(lastSevenDays.length).fill(0);

  applications.forEach((app) => {
    lastSevenDays.forEach((day, index) => {
      if (
        new Date(app.committedDate).toLocaleString(
          'en-US',
          dateOptions.date
        ) === day.toLocaleString('en-US', dateOptions.date)
      ) {
        data[index] += 1;
      }
    });
  });

  const newSubmissionsPerDayMetrics = {
    ...submissionsPerDayMetrics,
    labels,
    datasets: submissionsPerDayMetrics.datasets.map((dataset) => ({
      ...dataset,
      data,
    })),
  };

  dashboardSignal.update({
    submissionsPerDayMetrics: newSubmissionsPerDayMetrics,
  });
};

export const getBusinessDemographicsMetrics = () => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const { applications } = dashboardSignal.value;
  const approvedApplications = applications.filter(
    (a) => a.status === APPLICATION_STATUS.approved
  );

  const naicCodes = approvedApplications.map((application) =>
    pullNaicsCodeFromApplication(application)
  );

  const industryValues = naicCodes.map((naicsCode) =>
    getIndustryType(naicsCode)
  );

  const uniqIndustryValues = [...new Set(industryValues)].sort((a, b) =>
    a.localeCompare(b)
  );

  const valueCount = uniqIndustryValues.map(
    (value) => industryValues.filter((v) => v === value).length
  );

  return dashboardSignal.update({
    businessDemographicsMetrics: {
      labels: !!uniqIndustryValues.length ? uniqIndustryValues : [' '],
      datasets: [
        {
          data: valueCount,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    },
  });
};

export const getLoanAmountMetrics = () => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const { applications } = dashboardSignal.value;
  const data = new Array(loanAmountPieChartPresets.labels.length).fill(0);

  applications.forEach((app) => {
    if (app.status === APPLICATION_STATUS.approved && app.approvedAmount) {
      if (app.approvedAmount < 250000) {
        data[0] += 1;
      } else if (app.approvedAmount >= 250000 && app.approvedAmount < 500000) {
        data[1] += 1;
      } else if (app.approvedAmount >= 500000 && app.approvedAmount < 1000000) {
        data[2] += 1;
      } else if (app.approvedAmount >= 1000000) {
        data[3] += 1;
      }
    }
  });

  const newLoanAmountMetrics = {
    ...loanAmountPieChartPresets,
    datasets: loanAmountPieChartPresets.datasets.map((dataset) => ({
      ...dataset,
      data,
    })),
  };

  dashboardSignal.update({ loanAmountMetrics: newLoanAmountMetrics });
};

export const fetchAndSetDashboardData = async () => {
  loaderSignal.update({ isContentLoading: true });
  const { lender, portalType } = userAccountSignal.value.userData.account;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const { selectedPlatformId, selectedFundingProgramId, fundingPrograms } =
    dashboardSignal.value;
  const fundingProgram = fundingPrograms.find(fp => fp.id === selectedFundingProgramId);
  const isEquity = fundingProgram?.referenceProgram?.programType?.programTypeName === 'Equity';

  const options = {
    where: {
      fundingProgramMembership: {
        is: {
          fundingProgram:
            portalType === PORTAL_TYPES.edo
              ? {
                is: {
                  edoId: userAccountSignal.value.userData.account.edo.id,
                },
              }
              : {
                is: {}, // Lender conditions are added below
              },
        },
      },
    },
    include: {
      business: true,
      fundingProgramMembership: {
        include: {
          fundingProgram: true,
        },
      },
    },
  };

  if (selectedFundingProgramId) {
    options.where.fundingProgramMembership.is.fundingProgram.is.id =
      selectedFundingProgramId;
  } else if (selectedPlatformId) {
    options.where.fundingProgramMembership.is.fundingProgram.is.platformId =
      selectedPlatformId;
  }

  // Get applications where program memberships' lender ID matches user's lender ID
  if (portalType === PORTAL_TYPES.lender) {
    options.where.fundingProgramMembership.is.lenderId = lender.id;
  }

  try {
    const [
      applications,
      programRequests,
      funds,
      fundingProgram
    ] = await Promise.all([
      (!selectedFundingProgramId || !isEquity) ? api.get({
        path: '/applications',
        options,
      }) : [],
      (portalType === PORTAL_TYPES.edo && (!selectedFundingProgramId || isEquity)) ? api.get({
        path: '/programRequests',
        options: {
          where: selectedFundingProgramId ? {
            fundingProgramId: selectedFundingProgramId,
          } : selectedPlatformId ? {
            fundingProgram: {
              platformId: selectedPlatformId,
              referenceProgram: {
                programType: {
                  programTypeName: 'Equity',
                },
              },
            },
          } : {
            fundingProgram: {
              referenceProgram: {
                programType: {
                  programTypeName: 'Equity',
                },
              },
            },
          },
          include: {
            fundingProgram: true,
            ventureCapitalFirm: true,
          },
        },
      }) : [],
      (portalType === PORTAL_TYPES.edo && (!selectedFundingProgramId || isEquity)) ? api.get({
        path: '/funds',
        options: {
          ...options,
          include: {
            fundingProgramMembership: {
              include: {
                ventureCapitalFirm: true,
              }
            },
            vcPortfolioCompanies: {
              include: {
                business: true,
              }
            },
            capitalCalls: {
              include: {
                capitalCallPortions: true
              }
            }
          },
        },
      }) : [],
      (portalType === PORTAL_TYPES.edo && selectedFundingProgramId) ? api.get({
        path: '/fundingPrograms',
        options: {
          where: {
            id: selectedFundingProgramId,
          },
          include: {
            stats: true,
          },
        },
      }) : null,
    ]);

    return dashboardSignal.update({
      applications,
      funds,
      programRequests,
      portfolioCompanies: funds.map(f => {
        return f.vcPortfolioCompanies.map(vcpc => ({...vcpc, fund: f}))
      }).flatMap(a => a),
      fundingProgram,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

// export const getSediDemographicsData = () => {
//   const { applications } = lenderDashboardSignal.value;
//   const data = new Array(SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS.length).fill(
//     0
//   );
//
//   applications.map((app) => {
//     if (app.companyInfoId) {
//       SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS.forEach((status, index) => {
//         if (app.companyInfo.selfCertifiedSediDemographicsRelated === status) {
//           data[index] += 1;
//         }
//       });
//     }
//   });
//
//   const newSediDemographicsData = {
//     ...applicationsChartPresets,
//     labels: SEDI_DEMOGRAPHICS_RELATED_BUSINESS_STATUS.map((status) => {
//       const trimmed = status.replace('Self-certified due to ', '');
//       return `${trimmed[0].toUpperCase()}${trimmed.slice(1)}`;
//     }),
//     datasets: applicationsChartPresets.datasets.map((dataset) => ({
//       ...dataset,
//       data,
//     })),
//   };
//
//   lenderDashboardSignal.update({
//     sediDemographicsData: newSediDemographicsData,
//   });
// };

export const getFundMetrics = () => {
  const { funds } = dashboardSignal.value;
  const labelOrder = Object.keys(PORTFOLIO_COMPANY_STATUS);
  const tally = funds.reduce((accumulator, currentValue) => {
    const { capitalCalls } = currentValue;
    if (!capitalCalls.length) {
      // unfunded if no capital calls
      accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] =
        (accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] || 0) + 1;
    } else {
      const isFunded = capitalCalls.every(
        (cc) => new Date(cc.suggestedDate) <= new Date()
      );
      if (isFunded) {
        // funded if all capital calls are in past
        accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] || 0) + 1;
      } else {
        // committed if some capital calls have not been fulfilled
        accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] || 0) + 1;
      }
    }
    return accumulator;
  }, {});

  const orderedTally = labelOrder.map((label) => tally[label] || 0);

  const fundMetrics = {
    ...applicationsChartPresets,
    labels: Object.keys(PORTFOLIO_COMPANY_STATUS).map((status) =>
      capitalizeFirst(status)
    ),
    datasets: applicationsChartPresets.datasets.map((dataset) => ({
      ...dataset,
      data: orderedTally,
    })),
  };

  dashboardSignal.update({ fundMetrics });
};

export const getProgramRequestMetrics = () => {
  const { APPLICATION_STATUS, PROGRAM_REQUEST_STATUS } =
    $appSettings.value.constants;
  const { programRequests } = dashboardSignal.value;
  const programRequestsMetrics = applicationsChartPresets;

  const numApprovedRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.approved
  ).length;
  const numPendingRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.pending
  ).length;
  const numIncompleteRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.incomplete
  ).length;
  const numDeniedRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.denied
  ).length;
  const numSubmittedForApprovalRequests = programRequests.filter(
    (req) => req.status === APPLICATION_STATUS.submittedForApproval
  ).length;

  const newProgramRequestMetrics = {
    ...programRequestsMetrics,
    labels: Object.values(PROGRAM_REQUEST_STATUS).map((status) =>
      capitalizeFirst(status).replace(/_/g, ' ')
    ),
    datasets: programRequestsMetrics.datasets.map((dataset) => ({
      ...dataset,
      data: [
        numIncompleteRequests,
        numApprovedRequests,
        numDeniedRequests,
        numPendingRequests,
        numSubmittedForApprovalRequests,
      ],
      backgroundColor: [
        COLORS.ORANGE,
        COLORS.SUCCESS,
        COLORS.DANGER,
        COLORS.WARNING,
        COLORS.PRIMARY,
      ],
      borderColor: [
        COLORS.ORANGE,
        COLORS.SUCCESS,
        COLORS.DANGER,
        COLORS.WARNING,
        COLORS.PRIMARY,
      ]
    })),
  };

  dashboardSignal.update({ programRequestMetrics: newProgramRequestMetrics });
};

export const getVcPortfolioCompanyMetrics = () => {
  const { portfolioCompanies } = dashboardSignal.value;
  const labelOrder = Object.keys(PORTFOLIO_COMPANY_STATUS);

  const tally = portfolioCompanies.reduce((accumulator, currentValue) => {
    const { fund } = currentValue;
    const capitalCalls = fund.capitalCalls;
    let capitalCallPortions = capitalCalls.length
      ? capitalCalls[0].capitalCallPortions
      : [];
    if (!capitalCallPortions.length) {
      // unfunded if no capital call portions
      accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] =
        (accumulator[PORTFOLIO_COMPANY_STATUS.UNFUNDED] || 0) + 1;
    } else {
      const isFunded = capitalCalls.every(
        (cc) => new Date(cc.suggestedDate) <= new Date()
      );
      if (isFunded) {
        // funded if all capital calls are in past
        accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.FUNDED] || 0) + 1;
      } else {
        // committed if some capital calls have not been fulfilled
        accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] =
          (accumulator[PORTFOLIO_COMPANY_STATUS.COMMITTED] || 0) + 1;
      }
    }
    return accumulator;
  }, {});

  const orderedTally = labelOrder.map((label) => tally[label] || 0);

  const vcPortfolioCompanyMetrics = {
    ...applicationsChartPresets,
    labels: Object.keys(PORTFOLIO_COMPANY_STATUS).map((status) =>
      capitalizeFirst(status)
    ),
    datasets: applicationsChartPresets.datasets.map((dataset) => ({
      ...dataset,
      data: orderedTally,
    })),
  };

  dashboardSignal.update({ vcPortfolioCompanyMetrics });
};

export const getPortfolioCompanyBusinessDemographicsMetrics = () => {
  const { portfolioCompanies } = dashboardSignal.value;

  const naicCodes = portfolioCompanies.map((pc) => pc.business.naicsCode);

  const industryValues = naicCodes.map((naicsCode) =>
    getIndustryType(naicsCode)
  );

  const uniqIndustryValues = [...new Set(industryValues)].sort((a, b) =>
    a.localeCompare(b)
  );

  const valueCount = uniqIndustryValues.map(
    (value) => industryValues.filter((v) => v === value).length
  );

  return dashboardSignal.update({
    portfolioCompanyDemographicsMetrics: {
      labels: uniqIndustryValues,
      datasets: [
        {
          data: valueCount,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    },
  });
};

export const getProgramMetrics = () => {
  const { platforms, fundingProgram, selectedPlatformId } = dashboardSignal.value;

  const stats = {
    unallocated: undefined,
    obligated: undefined,
    expended: undefined,
  };

  if (fundingProgram) {
    stats.unallocated = fundingProgram.stats.allocated - fundingProgram.stats.obligated - fundingProgram.stats.expended;
    stats.obligated = fundingProgram.stats.obligated;
    stats.expended = fundingProgram.stats.expended;

  } else {
    let selectedPlatforms = platforms;
    if (selectedPlatformId) {
      selectedPlatforms = platforms.filter(platform => platform.id === selectedPlatformId);
    }

    stats.unallocated = selectedPlatforms
      .map((p) => p.stats.funded - p.stats.obligated - p.stats.expended)
      .reduce((acc, amount) => acc + amount, 0);
    stats.obligated = selectedPlatforms
      .map((p) => p.stats.obligated)
      .reduce((acc, amount) => acc + amount, 0);
    stats.expended = selectedPlatforms
      .map((p) => p.stats.expended)
      .reduce((acc, amount) => acc + amount, 0);
  }


  return dashboardSignal.update({
    programMetrics: {
      labels: ['Unallocated', 'Obligated', 'Expended'],
      datasets: applicationsChartPresets.datasets.map((dataset) => ({
        ...dataset,
        data: [...Object.values(stats).map(value => value)],
      })),
    }
  });
};

export const getAllocationOfFundsMetrics = () => {
  const { platforms, selectedPlatformId } = dashboardSignal.value;
  let selectedPlatforms = platforms;

  if (selectedPlatformId) {
    selectedPlatforms = platforms.filter(platform => platform.id === selectedPlatformId);
  }

  const allPrograms = selectedPlatforms.flatMap(platform => platform.fundingPrograms);

  return dashboardSignal.update({
    allocationOfFundsMetrics: {
      labels: allPrograms.map(program => program.name.includes('(') ? extractTextInParentheses(program.name) : program.name),
      datasets: applicationsChartPresets.datasets.map((dataset) => ({
        ...dataset,
        data: allPrograms.map(program => program.stats.allocated),
      })),
    }
  });
};
