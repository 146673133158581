import { Col, Row } from 'react-bootstrap';

const GeneralInformationTab = ({ fundingProgram, lender }) => (
  <>
    <h5 className="mt-16">Program Information</h5>
    <Row>
      <Col lg={3}>
        <p>
          <b>Program Name</b>
        </p>
        <p>{fundingProgram.name}</p>
      </Col>
      <Col lg={3}>
        <p>
          <b>Program Type</b>
        </p>
        <p>{fundingProgram.referenceProgram.programType?.programTypeName}</p>
      </Col>
      <Col lg={3}>
        <p>
          <b>Program</b>
        </p>
        <p>{fundingProgram.referenceProgram.programName}</p>
      </Col>
    </Row>
    <h5>Lender Information</h5>
    <Row>
      <Col>
        <p>
          <b>Organization Name</b>
        </p>
        <p>{lender.name}</p>
      </Col>
      <Col>
        <p>
          <b>Contact Name</b>
        </p>
        <p>{lender.contactName}</p>
      </Col>
      <Col>
        <p>
          <b>Email</b>
        </p>
        <p>
          <a href={`mailto:${lender.email}`}>{lender.email}</a>
        </p>
      </Col>
      <Col>
        <p>
          <b>Phone Number</b>
        </p>
        <p>
          <a href={`tel:${lender.phoneNumber}`}>
            {lender.phoneNumber}
          </a>
        </p>
      </Col>
    </Row>
  </>
);

export default GeneralInformationTab;
