import SimpleTable from "components/global/SimpleTable";
import edoTechnicalAssistanceSignal from "../edoTechnicalAssistance.signal";
import HTMLRenderer from "components/global/HTMLRenderer";
import {
  handleShowEditTechnicalAssistanceModal,
  handleTechnicalAssistanceCheckboxChange
} from "../edoTechnicalAssistance.helpers";
import { Alert, Button } from "react-bootstrap";
import { ensureHttpsInHrefs, enumCaseToTabCase } from "libs/string";

const HEADERS = [
  {
    key: 'title',
    title: 'Title',
  },
  {
    key: 'supportingText',
    title: 'Supporting Text',
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const TechnicalAssistancesTable = ({ isModal }) => {
  const { technicalAssistances, selectedTechnicalAssistanceId } =
    edoTechnicalAssistanceSignal.value;

  if (!technicalAssistances.length) {
    return (
      <Alert>
        <h5>No technical assistance has been created.</h5>
      </Alert>
    );
  }
  
  return (
    <SimpleTable
      headers={HEADERS}
      rows={technicalAssistances.map((ta) => ({
        title: ta.title,
        supportingText: (key) => (
          <td key={key}>
            <HTMLRenderer
              className="mb-0"
              htmlString={ensureHttpsInHrefs(ta.supportingText)}
              htmlStringClassName="mb-0"
              maxLength={50}
            />
          </td>
        ),
        type: enumCaseToTabCase(ta.type),
        action: (key) => (
          <td key={key} align="right">
            <Button
              hidden={isModal}
              className="rounded"
              size="sm"
              variant="outline-dark"
              onClick={() => handleShowEditTechnicalAssistanceModal(ta)}
            >
              Details
            </Button>
            {isModal && (
              <input
                type="checkbox"
                checked={selectedTechnicalAssistanceId === ta.id}
                onChange={() => handleTechnicalAssistanceCheckboxChange(ta.id)}
              />
            )}
          </td>
        ),
      }))}
    />
  );
};

export default TechnicalAssistancesTable;
