import { Card, Modal } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import dateOptions from 'components/global/Constant/dateOptions';
import stateUsersSignal from 'signals/StateUsers.signal';
import LogDropDown from '../../StateSettings/components/LogDropDown';

const UserLogsModal = ({ show, onToggleModal }) => {
  const { selectedUser } = stateUsersSignal.value;
  const { ActivityLogs: logs } = selectedUser;

  return (
    <Modal
      size="lg"
      centered
      className="border border-dark rounded"
      show={show}
      onHide={onToggleModal}
    >
      <Modal.Header
        className="pb-0 pl-32 bg-light border-0"
        closeLabel="Close"
      >
        <h5>Logs - {selectedUser.firstName} {selectedUser.lastName}</h5>
        <CloseModalButton onCloseModal={onToggleModal} />
      </Modal.Header>
      <Modal.Body className="bg-light rounded pt-0 px-16 px-md-32">
        <Card
          style={{ height: 500 }}
          className="px-16 px-md-32 py-16 overflow-auto rounded-0"
        >
          <p className="mb-8">
            Account created - {new Date(selectedUser.createdAt)?.toLocaleString('en-US', dateOptions.dateTime)}
          </p>
          {logs?.map(log => (
            <LogDropDown key={log.date.toLocaleString()} data={log} indent={1}>
              {log.changes.map(change => (
                <p
                  key={change.time.toLocaleString()}
                  className="ml-16 mb-8"
                >
                  {change.description} {change.time.toLocaleString('en-US', dateOptions.time)}
                </p>
              ))}
            </LogDropDown>
          ))}
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default UserLogsModal;
