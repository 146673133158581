import { Alert, Card, Col, Row } from 'react-bootstrap';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import {
  generalBarChartOptions,
  generalPieChartOptions,
} from '../reportConstants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  SEEKING_FUNDING_PAYMENT_RANGES,
  YEARS_BUSINESS_OPENED_RANGES,
} from '../../Constant/constants';
import {
  getFormOfBusinessTally,
  getLoanTermTally,
  getLoanTypeTally,
  getMinorityDepositoryTally,
  getProviderTypeTally,
  getSecurityTypeTally,
  getStageOfInvestmentTally,
  getTargetFundSizeTally,
  getValuationCapTally,
  getYearBusinessOpenedTally,
} from '../Reporting.helpers';

export const baseGraphData = {
  providerType: {
    labels: [],
    datasets: [
      {
        label: 'Provider Type',
        data: [],
        backgroundColor: [
          '#00688E',
          '#FF7300',
          '#FFAF00',
          '#1AAA2F',
          '#99066D',
          '#5FB7D4',
          '#FF99CE',
          '#2DCB75',
          '#9D0A0F',
          '#1B5ADD',
          '#C758D0',
          '#26D7AF',
          '#D5F30B',
          '#5F1671',
          '#FF8F08',
          '#7E170F',
          '#5623A9',
        ],
      },
    ],
  },
  minorityDepository: {
    labels: ['Yes', 'No'],
    datasets: [
      {
        label: 'Minority Depository',
        data: [],
        backgroundColor: ['#00688E', '#FF7300'],
      },
    ],
  },
  targetFundSize: {
    labels: SEEKING_FUNDING_PAYMENT_RANGES,
    datasets: [
      {
        data: [],
        backgroundColor: ['#99066D'],
      },
    ],
  },
  yearOpened: {
    labels: YEARS_BUSINESS_OPENED_RANGES,
    datasets: [
      {
        data: [],
        backgroundColor: ['#00688E'],
      },
    ],
  },
  formOfBusinessOrganization: {
    labels: [],
    datasets: [
      {
        label: 'Provider Type',
        data: [],
        backgroundColor: [
          '#00688E',
          '#FF7300',
          '#FFAF00',
          '#1AAA2F',
          '#99066D',
          '#5FB7D4',
          '#FF99CE',
          '#2DCB75',
          '#9D0A0F',
        ],
      },
    ],
  },
  loanType: {
    labels: [
      'Term with a specified repayment schedule and a fixed or floating interest rate',
      'Revenue-based, income-based, or other performance contingent payments',
      'Line of credit',
      'Bridge, venture debt or other debt whose repayment is contingent on event (with or without interim interest payments)',
      'Other',
    ],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#00688E',
          '#FF7300',
          '#FFAF00',
          '#1AAA2F',
          '#99066D',
        ],
      },
    ],
  },
  loanTerm: {
    labels: ['0-5', '6-10', '11-15', '16-20', '21-25', '26-30', '30+'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#1AAA2F'],
      },
    ],
  },
  stageOfInvestment: {
    labels: [
      'Pre-Seed',
      'Seed',
      'Early Stage',
      'Later Stage',
      'Growth Equity',
      'Other',
    ],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#00688E',
          '#FF7300',
          '#FFAF00',
          '#1AAA2F',
          '#99066D',
          '#5FB7D4',
        ],
      },
    ],
  },
  securityType: {
    labels: [
      'Common stock',
      'Preferred stock',
      'Convertible debt',
      'SAFE or other unpriced equity-like securities',
      'Other',
    ],
    datasets: [
      {
        data: [45, 13, 76, 87, 30],
        backgroundColor: [
          '#00688E',
          '#FF7300',
          '#FFAF00',
          '#1AAA2F',
          '#99066D',
        ],
      },
    ],
  },
  valuationType: {
    labels: ['Pre-Money', 'Post-Money'],
    datasets: [
      {
        label: 'Type of Valuation Cap',
        data: [45, 60],
        backgroundColor: ['#99066D', '#00688E'],
      },
    ],
  },
};

const NoDataAlert = () => (
  <Alert variant="warning">No relevant data has been collected.</Alert>
);

export const ProviderData = ({ bundle }) => {
  const providerType = getProviderTypeTally(bundle.data);
  const minorityDepository = getMinorityDepositoryTally(bundle.data);
  const targetFundSize = getTargetFundSizeTally(bundle.data);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Provider Type</h5>
            {!!providerType.datasets[0].data?.some(value => value > 0) ? (
              <Pie
                style={{ maxHeight: 500 }}
                data={providerType}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Minority Depository</h5>
            {!!minorityDepository.datasets[0].data?.some(value => value > 0) ? (
              <Doughnut
                style={{ maxHeight: 500 }}
                data={minorityDepository}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
      </Row>
      <Row className="mt-16">
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Target Fund Size</h5>
            {!!targetFundSize.datasets[0].data?.some(value => value > 0) ? (
              <Bar
                data={targetFundSize}
                options={generalBarChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export const LoanAndInvestment = ({ bundle }) => {
  const yearOpened = getYearBusinessOpenedTally(bundle.data);
  const formOfBusinessOrganization = getFormOfBusinessTally(bundle.data);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Year Business Opened</h5>
            {!!yearOpened.datasets[0].data?.some(value => value > 0) ? (
              <Bar
                data={yearOpened}
                options={generalBarChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Form of Business Organization</h5>
            {!!formOfBusinessOrganization.datasets[0].data?.some(value => value > 0) ? (
              <Pie
                style={{ maxHeight: 500 }}
                data={formOfBusinessOrganization}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export const TransactionTermsCredit = ({ bundle }) => {
  const loanType = getLoanTypeTally(bundle.data);
  const loanTerm = getLoanTermTally(bundle.data);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Loan Type</h5>
            {!!loanType.datasets[0].data?.some(value => value > 0) ? (
              <Pie
                style={{ maxHeight: 500 }}
                data={loanType}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Loan Term</h5>
            {!!loanTerm.datasets[0].data?.some(value => value > 0) ? (
              <Bar
                data={loanTerm}
                options={generalBarChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export const TransactionEquity = ({ bundle }) => {
  const stageOfInvestment = getStageOfInvestmentTally(bundle.data);
  const securityType = getSecurityTypeTally(bundle.data);
  const valuationType = getValuationCapTally(bundle.data);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Stage of Investment</h5>
            {!!stageOfInvestment.datasets[0].data?.some(value => value > 0) ? (
              <Pie
                style={{ maxHeight: 500 }}
                data={stageOfInvestment}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Security Type</h5>
            {!!securityType.datasets[0].data?.some(value => value > 0) ? (
              <Pie
                style={{ maxHeight: 500 }}
                data={securityType}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
      </Row>
      <Row className="mt-16">
        <Col lg={6}>
          <Card className="p-16 rounded-0">
            <h5 className="text-center">Type of Valuation Cap</h5>
            {!!valuationType.datasets[0].data?.some(value => value > 0) ? (
              <Doughnut
                style={{ maxHeight: 500 }}
                data={valuationType}
                options={generalPieChartOptions}
                plugins={[ChartDataLabels]}
              />
            ) : (
              <NoDataAlert />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};
