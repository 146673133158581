import Signal from 'libs/signals';
import { BankAccount, Platform, Tranch, User } from 'types';

type PlatformSignal = {
  platforms: Platform[];
  createPlatformModalVisible: boolean;
  newPlatformName: string;
  platformBeingEdited: Platform | null;
  bankAccounts: BankAccount[];
  fetchingBankAccounts: boolean;
  users: User[];
  treasuryAllocation: Record<string, unknown>;
  newPlatformType: string;
  newPlatformTargetTransactionLeverageRatio: string;
};

const platformsSignal = Signal<PlatformSignal>({
  platforms: [],
  createPlatformModalVisible: false,
  newPlatformName: '',
  platformBeingEdited: null,
  bankAccounts: [],
  fetchingBankAccounts: false,
  users: [],
  treasuryAllocation: {},
  newPlatformType: '',
  newPlatformTargetTransactionLeverageRatio: '10',
});

type EditPlatformPaymentOptionsSignal = {
  useCaatForPaymentReconciliation: boolean;
  selectedBankAccounts: BankAccount[];
};

export const editPlatformPaymentOptionsSignal =
  Signal<EditPlatformPaymentOptionsSignal>({
    useCaatForPaymentReconciliation: false,
    selectedBankAccounts: [],
  });

export type FormTranch = {
  amount: number | string;
  date: string;
};

type EditPlatformTranchesSignal = {
  tranches: Tranch[];
  newTranche: FormTranch;
  editTrancheId: number | null;
  trancheIdToRemove: number | null;
  removeTrancheConfirmModalVisible: boolean;
};

export const editPlatformTranchesSignal = Signal<EditPlatformTranchesSignal>({
  tranches: [],
  newTranche: { amount: '', date: '' },
  editTrancheId: null,
  trancheIdToRemove: null,
  removeTrancheConfirmModalVisible: false,
});

export type ProgramRequestApproverForm = {
  userId: string;
};

export default platformsSignal;
