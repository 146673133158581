import { Pagination } from 'react-bootstrap';
import {
  handleNextPageClick,
  handlePageClick,
  handlePreviousPageClick,
} from './pagination.helpers';
import { useLocation } from 'react-router-dom';

const PaginationItems = ({ className, totalPages }) => {
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const currentPage = searchParams.page ? Number(searchParams.page) : 1;

  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(
      <Pagination.Item
        key={i}
        active={currentPage === i}
        activeLabel={null}
        onClick={() => handlePageClick(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className={className}>
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => handlePageClick(1)}
        title="First"
      />
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={handlePreviousPageClick}
      />
      {pages}
      <Pagination.Next
        className="pagination-next"
        disabled={currentPage === totalPages}
        onClick={() => handleNextPageClick(totalPages)}
      />
      <Pagination.Last
        className="pagination-next"
        disabled={currentPage === totalPages}
        onClick={() => handlePageClick(totalPages)}
        title="Last"
      />
    </Pagination>
  );
};

export default PaginationItems;
