import { useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AllTransactions from './components/AllTransactions';
import {
  fetchAndSetTransactionMetrics,
  goBackToPlatformSelect,
} from './EdoTransactions.helpers';
import SelectPlatform from 'components/global/SelectPlatform/SelectPlatform';
import TransactionSignal from 'signals/Transaction.signal';
import BackButton from 'components/global/BackButton';
import TestModeBanner from '../../../global/utils/TestModeBanner';

const Transactions = () => {
  const { form } = TransactionSignal.value;

  const init = useCallback(async () => {
    await fetchAndSetTransactionMetrics();
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <Container fluid>
      <BackButton
        buttonClassName="mb-8"
        onClick={goBackToPlatformSelect}
        buttonText="Back to Select Platform"
        hidden={form === 'selectPlatform'}
      />
      <Row className="justify-content-center border border-gray py-24 mb-32 bg-white shadow-sm">
        <Col>
          <h3
            className={
              form === 'selectPlatform' ? 'mb-24 text-center' : 'text-center'
            }
          >
            Transactions
          </h3>
          <hr />
          {form === 'selectPlatform' ? (
            <SelectPlatform
              signal={TransactionSignal}
              nextForm="transactions"
            />
          ) : (
            <div className="bg-white">
              <TestModeBanner />
              <AllTransactions />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Transactions;
