import { Button } from "react-bootstrap";

const FieldButton = ({
  variant,
  className,
  onClick,
  label,
  inputClassName
}) => (
  <div className={className}>
    <Button
      variant={variant}
      className={inputClassName}
      onClick={onClick}
    >
      {label}
    </Button>
  </div>
);

export default FieldButton;
