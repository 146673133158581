import DownloadButton from '../utils/FilesAndDocuments/DownloadButton/DownloadButton';
import { Card, Col, Row } from 'react-bootstrap';

type DownloadableDocument = {
  id: number;
  name: string;
  filePath: string | null;
  fileType: string | null;
  header: string;
};

type Props = {
  overarchingDocumentType: string;
  documents: DownloadableDocument[];
};

const DownloadableDocumentsRow: React.FC<Props> = ({
  documents,
  overarchingDocumentType,
}) => (
  <Row>
    {(documents || []).map((document) => (
      <Col key={document.id} className="col-auto mb-8">
        <DownloadButton
          render={(_, fn) => (
            <Card
              onClick={() =>
                document.filePath &&
                document.fileType &&
                // @ts-ignore
                fn(document, overarchingDocumentType, document.name)
              }
              bg={'secondary'}
              style={{ width: '18rem' }}
              className="pointer rounded border-0 mb-2 mr-4 h-100"
            >
              <Card.Header className="text-white">
                {document.header}
              </Card.Header>
              <Card.Body className="h-100">
                <Card.Title className="text-white">{document.name}</Card.Title>
                {document.fileType && (
                  <Card.Text className="text-white">
                    {document.fileType.split('/')[1].toUpperCase()}
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          )}
        />
      </Col>
    ))}
  </Row>
);

export default DownloadableDocumentsRow;
