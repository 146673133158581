/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ascSortIcon, descSortIcon, sortIcon } from '../Constant/Icons';
import { useHistory } from 'react-router-dom';
import { SimpleTableHeader } from '../../../types';
import { Table, TableProps } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

function renderValue({
  value,
  headers,
  colIndex,
}: {
  value: string;
  headers: SimpleTableHeader[];
  colIndex: number;
}) {
  if (headers[colIndex] && headers[colIndex].currency) {
    return `$${value.toLocaleString()}`;
  }

  return value;
}

const SimpleTableColumn = ({
  onClick,
  value,
  headers,
  rowIndex,
  colIndex,
}: {
  onClick?: (rowIndex: number) => any;
  value: unknown;
  headers: SimpleTableHeader[];
  rowIndex: number;
  colIndex: number;
}) => {
  if (typeof value === 'function') {
    return value({ value, headers, colIndex, rowIndex });
  }

  return (
    <td
      style={{ cursor: onClick ? 'pointer' : '' }}
      onClick={() => onClick && onClick(rowIndex)}
      className={`${colIndex === headers.length - 1 ? 'text-right' : ''} ${
        headers[colIndex].bold ? 'font-weight-bold' : ''
      }`}
    >
      {renderValue({ value: value as string, headers, colIndex })}
    </td>
  );
};

type Props = {
  headers: SimpleTableHeader[];
  rows: any[];
  tableProps?: React.Component<TableProps>;
  onSort?: (sort: { key: string; dir: string } | undefined) => any;
  isLoading?: boolean;
  onClick?: (rowIndex: number) => any;
};

const SimpleTable: React.FC<Props> = ({
  onSort,
  headers,
  rows,
  tableProps,
  isLoading,
  onClick,
}) => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const { sort: stringSort, ...params } = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  const sort = stringSort
    ? {
        key: stringSort.split('-')[0],
        dir: stringSort.split('-')[1],
      }
    : {};

  return (
    <Table {...tableProps} responsive>
      <thead>
        <tr>
          {headers.map((header, idx) => {
            let sortingIcon = sortIcon;
            if (header.key === sort?.key) {
              if (sort.dir === 'asc') {
                sortingIcon = ascSortIcon;
              }

              if (sort.dir === 'desc') {
                sortingIcon = descSortIcon;
              }
            }

            return (
              <th
                style={header.sortable ? { cursor: 'pointer' } : {}}
                onClick={() => {
                  let newSort = undefined;

                  if (!header.sortable) {
                    return;
                  }

                  if (sort.key === header.key) {
                    if (sort.dir === 'asc') {
                      newSort = { key: header.key, dir: 'desc' };
                    } else {
                      newSort = undefined;
                    }
                  } else {
                    newSort = { key: header.key, dir: 'asc' };
                  }

                  if (onSort) {
                    onSort(newSort);
                  }

                  if (newSort) {
                    return history.push(
                      `${pathname}?${new URLSearchParams({
                        ...params,
                        ...(newSort.key
                          ? { sort: `${newSort.key}-${newSort.dir}` }
                          : {}),
                      })}`
                    );
                  } else {
                    return history.push(
                      `${pathname}?${new URLSearchParams({
                        ...params,
                      })}`
                    );
                  }
                }}
                key={idx}
                className={`${
                  idx === headers.length - 1 ? 'text-right' : ''
                } text-nowrap`}
              >
                {header.title}{' '}
                {header.sortable && (
                  <FontAwesomeIcon icon={sortingIcon as IconProp} />
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      {isLoading ? (
        <Loader />
      ) : (
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {Object.entries(row).map(([key, value], jndex) => (
                <SimpleTableColumn
                  onClick={onClick}
                  rowIndex={index}
                  colIndex={jndex}
                  headers={headers}
                  key={key}
                  value={value}
                />
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </Table>
  );
};

export default SimpleTable;
