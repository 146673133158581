import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import $edoPerformanceCapitalCallsSignal from "./EdoPerformanceCapitalCalls.signals";
import { Button, Card, Col, Row } from "react-bootstrap";
import { fetchAndSetCapitalCalls, getCapitalCallStats } from "./EdoPerformanceCapitalCalls.helpers";
import { useEffect } from "react";
import SimpleTable from "components/global/SimpleTable";
import { enumCaseToTabCase, titleCaseString } from "libs/string";
import { useHistory } from "react-router-dom";
import { camelCaseToTitleText } from "utils/formatTxt";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'vc',
    title: 'VC',
  },
  {
    key: 'fund',
    title: 'Fund',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'obligated',
    title: 'Obligated',
    currency: true,
  },
  {
    key: 'expended',
    title: 'Expended',
    currency: true,
  },
  {
    key: 'recycled',
    title: 'Recycled',
    currency: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const EdoPerformanceCapitalCalls = () => {
  const { capitalCalls, fundingProgram , funds, fundingProgramMemberships } = $edoPerformanceCapitalCallsSignal.value;
  const history = useHistory();

  console.log('fundingProgramMemberships', fundingProgramMemberships);

  useEffect(() => {
    fetchAndSetCapitalCalls();
  }, []);

  return (
    <BreadCrumbWrapper labels={['Program Performance', fundingProgram?.name]}>
      <Row>
        <Col xs={9}>
          <Card className="rounded-0">
            <Card.Body>
              <h3 className="text-center">Capital Calls</h3>
              <hr />
              <SimpleTable
                tableProps={{ responsive: true }}
                headers={HEADERS}
                onClick={(index) => {
                  const path = `${history.location.pathname}/capital-calls/${capitalCalls[index].id}`;
                  history.push(path);
                }}
                rows={capitalCalls.map((capitalCall) => {
                  const stats = getCapitalCallStats(capitalCall);
                  return {
                  id: capitalCall.id,
                  vc:
                    capitalCall.fund.fundingProgramMembership?.ventureCapitalFirm?.name || '?',
                  fund: capitalCall.fund?.fundName || '?',
                  status: enumCaseToTabCase(capitalCall.status),
                  obligated: stats?.obligated,
                  expended: stats?.expended,
                  recycled: stats?.recycled,
                  action: ({ key }) => (
                    <td key={key} className="text-right">
                      <Button
                        size="sm"
                        className="rounded text-nowrap"
                        variant="outline-dark"
                        onClick={() => {
                          const path = `${history.location.pathname}/capital-calls/${capitalCall.id}`;
                          history.push(path);
                        }}
                      >
                        Capital Call
                      </Button>
                    </td>
                  ),
                }
              })}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="rounded-0">
            <Card.Body>
              <h4>{fundingProgram?.name}</h4>
              <hr />
              <h5 className="font-600">Program Funds</h5>
              <p>
                {'$' +
                  Number(
                    fundingProgram?.ceiling -
                      fundingProgram?.fundsAlreadyAllocated
                  ).toLocaleString()}
              </p>
              <h5 className="font-600">Obligated at Program Approval</h5>
              <p>{'$' + fundingProgramMemberships.reduce((acc, fpm) => fpm.approvedAmount + acc, 0).toLocaleString()}</p>
              <h5 className="font-600">Obligated not yet expended</h5>
              <p>{'$' + fundingProgram?.stats.obligated.toLocaleString()}</p>
              <h5 className="font-600">Obligated in Capital Calls</h5>
              <p>{'$' + capitalCalls.map(cc => getCapitalCallStats(cc).obligated).reduce((acc, amount) => acc + amount, 0).toLocaleString()}</p>
              <h5 className="font-600">Expended</h5>
              <p>{'$' + fundingProgram?.stats.expended.toLocaleString()}</p>
              <h5 className="font-600">Remaining</h5>
              <p>{'$' + fundingProgram?.stats.remaining.toLocaleString()}</p>
              <h5 className="font-600">Recycled</h5>
              <p>{'$' + fundingProgram?.stats.recycled.toLocaleString()}</p>
              <hr />
              <h5 className="font-600">Program Config</h5>
              {Object.entries(fundingProgram?.programConfig || {}).map(
                ([key, value]) => (
                  <p key={key}>
                    <strong>{camelCaseToTitleText(key)}:</strong>{' '}
                    {isNaN(Number(value)) ? titleCaseString(value) : value}
                  </p>
                )
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </BreadCrumbWrapper>
  );
};

export default EdoPerformanceCapitalCalls;
