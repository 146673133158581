import { Col, Row } from 'react-bootstrap';
import fundDetailsSignal from '../fundDetails.signal';
import formatMoney from 'utils/formatMoney';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';
import { capitalCallsTotalAmount } from '../../../../libs/capitalCalls';

const GeneralDetails = () => {
  const {
    fundingProgram,
    name,
    maxFundSize,
    ventureCapitalFirm,
    capitalCalls,
  } = fundDetailsSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const totalCapitalCallAmount = capitalCallsTotalAmount(capitalCalls);

  return (
    <div className="mt-16">
      <h5 className="mt-32 mb-8">Fund</h5>
      <Row>
        <Col>
          <p className="font-weight-bold mb-0">Fund Name</p>
          {name}
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Fund Size</p>
          {formatMoney(maxFundSize)}
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Remaining Funds</p>
          {formatMoney(maxFundSize - totalCapitalCallAmount)}
        </Col>
        <Col />
      </Row>
      <h5 className="mt-32 mb-8">Program</h5>
      <Row>
        <Col>
          <p className="font-weight-bold mb-0">Main Program</p>
          {fundingProgram?.name}
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Program Type</p>
          {fundingProgram?.referenceProgram?.programType?.programTypeName}
        </Col>
        <Col>
          <p className="font-weight-bold mb-0">Program</p>
          {fundingProgram?.referenceProgram?.programName}
        </Col>
        <Col />
      </Row>
      <div hidden={portalType === PORTAL_TYPES.vc}>
        <h5 className="mt-32 mb-8">VC Information</h5>
        <Row>
          <Col>
            <p className="font-weight-bold mb-0">Organization Name</p>
            {ventureCapitalFirm?.name}
          </Col>
          <Col>
            <p className="font-weight-bold mb-0">Contact Name</p>
            {ventureCapitalFirm?.contactName}
          </Col>
          <Col>
            <p className="font-weight-bold mb-0">Email</p>
            {ventureCapitalFirm?.email}
          </Col>
          <Col>
            <p className="font-weight-bold mb-0">Phone Number</p>
            {ventureCapitalFirm?.phoneNumber}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GeneralDetails;
