import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import { msalConfig } from './authConfig';
import * as Sentry from '@sentry/react';
import { getConfig } from './config/config';

const appConfig = getConfig();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment:
      appConfig.APP_CONFIG.appId === 'dev' ||
      appConfig.APP_CONFIG.appId === 'qa'
        ? 'qa'
        : 'production',
    dsn: 'https://9f899f379416a18e7a6ff01baf72a9fd@o4506004240400384.ingest.sentry.io/4506005398290432',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'https://caat.azurewebsites.net/api',
          'https://caat-development.azurewebsites.net/api',
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate:
      appConfig.APP_CONFIG.appId === 'dev' ||
      appConfig.APP_CONFIG.appId === 'qa'
        ? 1.0
        : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
