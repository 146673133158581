import ApplicationCompliance from "components/global/ApplicationCompliance/ApplicationCompliance";
import applicationDetailSignal from "../ApplicationDetail.signal";

const ApplicationComplianceTab = () => (
  <ApplicationCompliance
    referenceProgramId={
      applicationDetailSignal.value.fundingProgram?.referenceProgramId
    }
    complianceChecklist={
      applicationDetailSignal.value.applicationConfig?.complianceChecklist || {}
    }
  />
);

export default ApplicationComplianceTab;
