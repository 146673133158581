const SYLLABLES = ['a', 'e', 'i', 'o', 'u'];

export function humanize(s) {
  return `${s[0]}${s.slice(1).toLowerCase()}`.replaceAll('_', ' ');
}

export function pascalCaseToTitleCase(str) {
  const words = str.split(/(?=[A-Z])/);

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const camelCaseToTitleText = (camelCaseTxt) => {
  const spacedTxt = camelCaseTxt.replace(/([A-Z])/g, ' $1');
  let result = spacedTxt.charAt(0).toUpperCase() + spacedTxt.slice(1);
  if (result.includes('Ssbci')) {
    result = result.replace(/Ssbci/g, 'SSBCI');
  }
  if (result.includes('Vc')) {
    result = result.replace(/Vc/g, 'VC');
  }
  return result;
};

export const isLetterSyllable = (letter) =>
  SYLLABLES.includes(letter.toLowerCase());

export const hideEinNumber = (ein) => {
  if (!Number.isNaN(ein)) {
    const einStr = `${ein}`;
    return `xx-xxx${einStr.slice(-4)}`;
  }
  return `xx-xxx${ein.slice(-4)}`;
};

export const formatEIN = (value) => {
  if (!value) return value;
  const ein = value.replace(/[^\d]/g, '');
  if (ein.length <= 2) return ein;
  if (ein.length === 10) return '';
  return `${ein.slice(0, 2)}-${ein.slice(2)}`;
};

export const extractTextInParentheses = (inputString) => {
  const regex = /\(([^)]+)\)/;
  const match = regex.exec(inputString);
  return match ? match[1] : null;
};
