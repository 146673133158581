import stateOrganizationsSignal from 'signals/StateOrganizations.signal';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import pullOrganizationNameFromAccount from 'libs/pullOrganizationNameFromAccount';
import { hideOrganizationsOrganizationDetailModal } from '../StateOrganizations.helpers';

const StateOrganizationDetailsModal = () => {
  const { selectedAccount } = stateOrganizationsSignal.value;
  const account = selectedAccount;
  return (
    <Modal show={!!selectedAccount} centered size="lg">
      <Modal.Header>
        <Modal.Title>Organization Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6} xl={4}>
            <p className="font-weight-bold">Name</p>
            <p>{pullOrganizationNameFromAccount(account)}</p>
          </Col>
          <Col sm={6} xl={4}>
            <p className="font-weight-bold">Type</p>
            <p>{account?.portalType}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <p className="font-weight-bold">Contact Name</p>
            <p>
              {account?.edo?.contactName || account?.lender?.contactName || '-'}
            </p>
          </Col>
          <Col sm={4}>
            <p className="font-weight-bold">Contact Email</p>
            <p>{account?.edo?.contactEmail || account?.lender?.email || '-'}</p>
          </Col>
          <Col sm={4}>
            <p className="font-weight-bold">Phone Number</p>
            <p>
              {account?.edo?.phoneNumber ||
                account?.lender?.phoneNumber ||
                account?.business?.phoneNumber}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className="font-weight-bold">Address</p>
            <p>
              {account?.edo?.address ||
                account?.lender?.address ||
                account?.business?.address}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <p className="font-weight-bold">City</p>
            <p>
              {account?.edo?.city ||
                account?.lender?.city ||
                account?.business?.city}
            </p>
          </Col>
          <Col sm={4}>
            <p className="font-weight-bold">State</p>
            <p>
              {account?.edo?.state ||
                account?.lender?.state ||
                account?.business?.state?.abbreviation}
            </p>
          </Col>
          <Col sm={4}>
            <p className="font-weight-bold">Zip Code</p>
            <p>
              {account?.edo?.zipCode ||
                account?.lender?.zipCode ||
                account?.business?.zipCode}
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-sm rounded"
          variant="secondary"
          onClick={() => hideOrganizationsOrganizationDetailModal()}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StateOrganizationDetailsModal;
