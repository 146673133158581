import { Button } from "react-bootstrap";
import edoProgramRequestsSignal from "signals/EdoProgramRequests.signal";
import { useCallback, useEffect } from "react";
import {
  fetchAndSetEdoProgramRequests,
  handleDownloadLenderProgramRequestsData,
  handleEdoLenderProgramRequestDetailClick
} from "../../EdoLenders.helpers";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { titleCaseString } from "libs/string";
import SimpleTable from "components/global/SimpleTable";
import dateOptions from "components/global/Constant/dateOptions";
import { showApplicationDetailsModal } from "components/global/ApplicationDetail/ApplicationDetail.helpers";
import {
  txProgramRequestToApplicationDetailsModal
} from "components/global/ProgramRequestDetail/ProgramRequestDetail.helpers";
import { useLocation } from "react-router";
import capitalizeFirst from "utils/capitalizeFirst";
import { getStatusStyles } from "../../../../../../libs/functions/global.functions";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'createdDate',
    title: 'Date Submitted',
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'lenderName',
    title: 'Lender Name',
  },
  {
    key: 'contactName',
    title: 'Contact Name',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const EdoProgramRequests = ({ refetchingFilter = undefined }) => {
  const { programRequests, bundle } = edoProgramRequestsSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const location = useLocation();

  const init = useCallback(() => {
    if (refetchingFilter) {
      if (refetchingFilter(location)) {
        return fetchAndSetEdoProgramRequests();
      } else {
        return undefined;
      }
    }

    return fetchAndSetEdoProgramRequests();
  }, [location.search]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <div className="bg-white">
        <div className="d-flex flex-row justify-content-between mb-16">
          <SimpleTableFilterDropdown
            filters={[
              {
                type: 'select',
                label: 'Status',
                key: 'status',
                options: Object.entries(APPLICATION_STATUS).map(
                  ([, value]) => ({
                    label: titleCaseString(value),
                    value,
                  })
                ),
              },
              {
                type: 'integer',
                label: 'ID',
                key: 'id',
              },
              {
                type: 'select',
                label: 'Program Name',
                key: 'programName',
                options: bundle.fundingPrograms?.map((fundingProgram) => ({
                  label: fundingProgram.name,
                  value: fundingProgram.id,
                })),
              },
            ]}
          />
          <div>
            <Button
              variant="outline-secondary"
              className="rounded"
              onClick={handleDownloadLenderProgramRequestsData}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <SimpleTable
        headers={HEADERS}
        onClick={(index) => {
          const programRequest = programRequests[index];
          showApplicationDetailsModal(
            txProgramRequestToApplicationDetailsModal(
              programRequest,
              !!programRequest.ventureCapitalFirm
            )
          );
        }}
        rows={programRequests.map((programRequest) => ({
          status: () => (
            <td className={`${getStatusStyles(programRequest.status).class}`}>
              {capitalizeFirst(programRequest.status.replace(/_/g, ' '))}
            </td>
          ),
          id: programRequest.id,
          createdDate: new Date(programRequest.createdDate).toLocaleString(
            'en-US',
            dateOptions.date
          ),
          programName: programRequest.fundingProgram.name,
          lenderName:
            programRequest.lender?.name ||
            programRequest.ventureCapitalFirm?.name,
          contactName:
            programRequest.lender?.contactName ||
            programRequest.ventureCapitalFirm?.contactName,
          action: ({ key }) => (
            <td className="text-right" key={key}>
              <Button
                className="rounded"
                size="sm"
                variant="outline-dark"
                onClick={() =>
                  handleEdoLenderProgramRequestDetailClick(programRequest.id)
                }
              >
                Detail
              </Button>
            </td>
          ),
        }))}
      />
    </>
  );
};

export default EdoProgramRequests;
