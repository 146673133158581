import { Account } from '../types';

type AccountWithUserCount = Account & {
  _count: {
    users: number;
  };
};

export const getStatus = (account: AccountWithUserCount): string => {
  const { _count, receivedUserInvites } = account;

  if (_count.users > 0) {
    return 'Registered';
  }

  if (receivedUserInvites.length === 0) {
    return 'Registered';
    // return 'IMPORTED';
  } else {
    if (receivedUserInvites.some((invite) => invite.status === 'APPROVED')) {
      return 'Approved';
    }

    if (receivedUserInvites.some((invite) => invite.status === 'PENDING')) {
      return 'Invited';
    }

    if (receivedUserInvites.some((invite) => invite.status === 'DENIED')) {
      return 'Denied';
    }

    return 'Request';
  }
};
