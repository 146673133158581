import Signal from 'libs/signals';
import {
  Fund,
  FundingProgram,
  FundingProgramMembership,
  MakeForm,
  VentureCapitalFirm,
} from 'types';

type NewFundFundingProgramMembershipsSignal = {
  fundingProgramMemberships: Array<
    FundingProgramMembership & { funds: Fund[] }
  >;
};

export const $newFundFundingProgramMembershipsSignal =
  Signal<NewFundFundingProgramMembershipsSignal>({
    fundingProgramMemberships: [],
  });

type NewFundFundingProgramsSignal = {
  fundingPrograms: Array<FundingProgram>;
  vcs: Array<VentureCapitalFirm>;
};

export const $newFundFundingProgramsSignal =
  Signal<NewFundFundingProgramsSignal>({
    fundingPrograms: [],
    vcs: [],
  });

type NewFundSignal = MakeForm<{
  fundName: string;
  fundingProgramMembershipId: string;
  fundingProgramId: string;
  maxFundSize: string;
  requiredDocuments: any[];
  suggestedDocuments: any[];
  ventureCapitalFirm: VentureCapitalFirm | null;
  vcName: string;
  ein: string;
  firstName: string;
  lastName: string;
  email: string;
  createNewVc: boolean;
  query: string;
  phoneNumber: string;
}>;

const $newFundSignal = Signal<NewFundSignal>({
  fundName: {
    value: '',
    dirty: false,
    errors: [],
  },
  maxFundSize: {
    value: '',
    dirty: false,
    errors: [],
  },
  fundingProgramMembershipId: {
    value: '',
    dirty: false,
    errors: [],
  },
  fundingProgramId: {
    value: '',
    dirty: false,
    errors: [],
  },
  requiredDocuments: {
    value: [],
    dirty: false,
    errors: [],
  },
  suggestedDocuments: {
    value: [],
    dirty: false,
    errors: [],
  },
  ventureCapitalFirm: {
    value: null,
    dirty: false,
    errors: [],
  },
  vcName: {
    value: '',
    dirty: false,
    errors: [],
  },
  ein: {
    value: '',
    dirty: false,
    errors: [],
  },
  firstName: {
    value: '',
    dirty: false,
    errors: [],
  },
  lastName: {
    value: '',
    dirty: false,
    errors: [],
  },
  email: {
    value: '',
    dirty: false,
    errors: [],
  },
  createNewVc: {
    value: false,
    dirty: false,
    errors: [],
  },
  query: {
    value: '',
    dirty: false,
    errors: [],
  },
  phoneNumber: {
    value: '',
    dirty: false,
    errors: [],
  },
});

export default $newFundSignal;
