import { Button, Modal } from 'react-bootstrap';
import userActivationSignal from 'signals/UserActivation.signal';
import CloseModalButton from '../Constant/CloseModalButton';
import {
  activateUser,
  deactivateUser,
  toggleActivationUserModal,
} from './userActivationModal.helpers';
import ModalWrapper from '../ModalWrapper/ModalWrapper';

const UserActivationModal = ({ onFetchAndSetUsers }) => {
  const { mode, selectedUser, modalVisible } = userActivationSignal.value;

  if (!selectedUser) {
    return <></>;
  }

  return (
    <ModalWrapper
      centered
      className="border border-dark rounded"
      show={modalVisible}
      onExited={() => userActivationSignal.update({ selectedUser: null })}
      onHide={toggleActivationUserModal}
    >
      <Modal.Header className="pb-0 pl-32" closeLabel="Close">
        {mode === 'activation' && <h5>Activate User</h5>}

        {mode === 'deactivation' && <h5>Deactivate User</h5>}
        <CloseModalButton onCloseModal={toggleActivationUserModal} />
      </Modal.Header>
      <Modal.Body className="rounded p-32">
        {mode === 'activation' && (
          <p>
            Are you sure you want to activate {selectedUser.firstName}{' '}
            {selectedUser.lastName}?
          </p>
        )}

        {mode === 'deactivation' && (
          <p>
            Are you sure you want to deactivate {selectedUser.firstName}{' '}
            {selectedUser.lastName}?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="px-16 justify-content-end">
        <Button
          className="mr-8 rounded"
          variant="outline-secondary"
          onClick={toggleActivationUserModal}
        >
          Cancel
        </Button>
        <Button
          hidden={mode === 'deactivation'}
          className="rounded"
          variant="success"
          onClick={() => activateUser(onFetchAndSetUsers)}
        >
          Activate User
        </Button>
        <Button
          hidden={mode === 'activation'}
          className="rounded"
          variant="danger"
          onClick={() => deactivateUser(onFetchAndSetUsers)}
        >
          Deactivate User
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default UserActivationModal;
