import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import history from '../../../history';
import edoFundingDisbursementSignal, {
  edoFundingTransactionAddTransactionFormSignal,
} from './Disbursement.signals';
import userAccountSignal from 'signals/UserAccount.signal';
import { uploadFilesToStorage } from 'libs/functions/global.functions';

const DISBURSEMENT_INCLUDE = {
  originBankAccount: true,
  destinationBankAccount: true,
  application: {
    include: {
      fundingProgramMembership: {
        include: {
          fundingProgram: true,
          lender: true,
        },
      },
    },
  },
  transactions: true,
};

const REIMBURSEMENT_INCLUDE = {
  disbursement: {
    include: {
      application: {
        include: {
          fundingProgramMembership: {
            include: {
              fundingProgram: true,
              lender: true,
            },
          },
        },
      },
      transactions: true,
    },
  },
  transactions: true,
};

export const fetchAndSetDisbursement = async () => {
  const pathname = history.location.pathname;
  const split = pathname.split('/');
  const id = Number(split[split.length - 1]);
  loaderSignal.update({ isContentLoading: true });

  const isReimbursement = history.location.pathname.includes('reimbursement');

  try {
    const [record] = await Promise.all([
      api.get({
        path: isReimbursement ? '/reimbursements' : '/disbursements',
        options: {
          where: {
            id,
          },
          include: isReimbursement
            ? REIMBURSEMENT_INCLUDE
            : DISBURSEMENT_INCLUDE,
        },
      }),
    ]);

    return edoFundingDisbursementSignal.update({
      record,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch record.',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showAddManualTransactionModal = () =>
  edoFundingDisbursementSignal.update({
    createTransactionModalOpen: true,
  });

export const hideAddManualTransactionModal = () =>
  edoFundingDisbursementSignal.update({
    createTransactionModalOpen: false,
  });

export const handleSubmitTransaction = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting transaction...',
  });
  try {
    const { memo, attachments, amount, date, time } =
      edoFundingTransactionAddTransactionFormSignal.value;
    const { record } = edoFundingDisbursementSignal.value;

    const filesSignedUrls = await uploadFilesToStorage(
      attachments.map((a) => a.file),
      'transaction'
    );

    const body = {
      data: {
        memo,
        amount: Number(amount),
        transferredDate: `${date} ${convertSecondsToHHMM(time)}`,
        disbursementId: record.id,
        creatorId: userAccountSignal.value.userData.id,
        attachments: attachments.map((a, i) => ({
          fileTitle: a.name,
          filePath: filesSignedUrls[i],
          fileType: a.file.type,
        })),
      },
    };

    await api.post({
      path: '/transactions',
      body,
    });

    await fetchAndSetDisbursement();
    edoFundingDisbursementSignal.update({
      createTransactionModalOpen: false,
    });
    edoFundingTransactionAddTransactionFormSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully created a transaction.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

function convertSecondsToHHMM(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  // const secs = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  // const formattedSeconds = secs.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:00`;
}

export const handleAddTransactionFormChange = (e) => {
  const { value, name } = e.target;

  if (name === 'attachments') {
    let attachments = [];
    for (let i = 0; i < value.length; i++) {
      attachments.push({ name: value[i].name.split('.')[0], file: value[i] });
    }
    return edoFundingTransactionAddTransactionFormSignal.update({
      [name]: [
        ...edoFundingTransactionAddTransactionFormSignal.value.attachments,
        ...attachments,
      ],
    });
  }

  return edoFundingTransactionAddTransactionFormSignal.update({
    [name]: value,
  });
};

export const handleRemoveAttachment = (index) => {
  const { attachments } = edoFundingTransactionAddTransactionFormSignal.value;
  return edoFundingTransactionAddTransactionFormSignal.update({
    attachments: attachments.filter((_, i) => i !== index),
  });
};

export const handleChangeAttachmentName = (index, name) => {
  const { attachments } = edoFundingTransactionAddTransactionFormSignal.value;
  return edoFundingTransactionAddTransactionFormSignal.update({
    attachments: attachments.map((a, i) => {
      if (i === index) {
        return { ...a, name };
      }

      return a;
    }),
  });
};
