/* eslint-disable no-unused-vars */
import loaderSignal from "signals/Loader.signal";
import alertSignal from "signals/Alert.signal";
import api from "api/api";
import submitApplicationSignal, {
  applicationDraftSignal,
  submitApplicationBusinessSummarySignal,
  submitApplicationErrorsSignal,
  submitApplicationLenderSummarySignal
} from "signals/SubmitApplication.signal";
import { einRegex } from "components/global/Constant/regularExpression";
import userAccountSignal from "../../../signals/UserAccount.signal";
import { ocrSignal } from "../OCR/ocr.signal";
import applicationComplianceSignal from "../ApplicationCompliance/ApplicationCompliance.signal";
import { uploadFilesToStorage } from "libs/functions/global.functions";
import { parseOcrData } from "../OCR/ocr.helpers";
import { PROGRAM_TYPES } from "../Constant/lenderOrganizationInfoConstants";
import $appSettings from "signals/AppSettings.signal";

export const NUM_OF_STEPS = 6;

export const fetchAndSetFundingProgramsAndProgramTypes = async () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  loaderSignal.update({ isContentLoading: true });
  try {
    const [fundingPrograms, programTypes] = await Promise.all([
      api.get({
        path: '/candidatePrograms',
      }),
      api.get({
        path: '/programTypes',
      }),
    ]);

    let programTypeId = '-1';
    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.lender
    ) {
      programTypeId = programTypes.find(
        (pt) => pt.programTypeName === PROGRAM_TYPES.credit
      )?.id;
    }

    return submitApplicationSignal.update({
      fundingPrograms,
      programTypes,
      programTypeId,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

let lockNotificationListenerWsClient = null;

export const setLockNotificationListener = async () => {
  const { url } = await api.post({
    path: '/pubsub',
    body: {
      data: {
        hub: 'applicationLocks',
      },
    },
  });

  lockNotificationListenerWsClient = new WebSocket(url);
  lockNotificationListenerWsClient.onmessage = (message) => {
    if (message.data) {
      const json = JSON.parse(message.data);
      const { applicationId, user } = json;
      if (applicationId === applicationDraftSignal.value.selectedDraft?.id) {
        applicationDraftSignal.update({
          removeAccessModalVisible: true,
          removeAccessUser: user,
        });
      }
    }
  };
};

export const tearDownLockNotificationListener = () => {
  if (lockNotificationListenerWsClient) {
    return lockNotificationListenerWsClient.close();
  }
  return null;
};

export const fetchAndSetApplicationDrafts = async (setLoader) => {
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;

  const where = {
    status: APPLICATION_STATUS.inProgress,
    isActive: true,
  };

  if (
    userAccountSignal.value.userData.account.portalType ===
    PORTAL_TYPES.edo
  ) {
    where.submittedByEdo = true;
  } else {
    where.fundingProgramMembership = {
      lender: {
        accountId: userAccountSignal.value.userData.accountId,
      },
    };
  }

  try {
    setLoader && loaderSignal.update({ isContentLoading: true });
    const applicationDrafts = await api.get({
      path: '/applications',
      options: {
        where,
        include: {
          fundingProgram: {
            include: {
              supportingDocuments: true,
              referenceProgram: true,
            },
          },
        },
      },
    });

    applicationDraftSignal.update({ applicationDrafts });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSelectionChange = (e) => {
  const { name, value } = e.target;

  if (name === 'initialStatus') {
    return submitApplicationSignal.update({
      initialStatus: value,
    });
  }

  if (name === 'programType') {
    return submitApplicationSignal.update({
      programTypeId: parseInt(value, 10),
      referenceProgramId: '-1',
      fundingProgramId: '-1',
    });
  }

  if (name === 'referenceProgram') {
    const referenceProgramId = parseInt(value, 10);
    return submitApplicationSignal.update({
      referenceProgramId,
      fundingProgramId: '-1',
    });
  }

  if (name === 'fundingProgram') {
    const fundingProgramId = parseInt(value, 10);
    const fundingPrograms = submitApplicationSignal.value.fundingPrograms || [];
    return submitApplicationSignal.update({
      fundingProgramId,
      fundingProgram: fundingPrograms.find((mp) => mp.id === fundingProgramId),
    });
  }

  return null;
};

export const handleInputDirty = (e) => {
  const { name } = e.target;

  return submitApplicationErrorsSignal.update({ [`${name}Dirty`]: true });
};

export const handleInputChange = (e) => {
  const { name, value } = e.target;

  submitApplicationSignal.update({
    [name]: value,
  });

  if (name === 'lenderEin' || name === 'businessEin') {
    submitApplicationErrorsSignal.update({
      [name]: !einRegex.test(value),
    });
  }
};

export const handleAttachFile = (name, file) => {
  submitApplicationSignal.update({
    filesMap: {
      ...submitApplicationSignal.value.filesMap,
      [name]: { file, filePath: null, name: file.name, fileType: file.type },
    },
  });
};

export const handleFileCancel = (name) => {
  const { filesMap } = submitApplicationSignal.value;
  const copiedFilesMap = { ...filesMap };
  delete copiedFilesMap[name];

  submitApplicationSignal.update({
    filesMap: copiedFilesMap,
  });
};

export const submitApplicationAndNextStep = async () => {
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;

  loaderSignal.update({
    isContentLoading: true,
    message: 'Creating Application...',
  });
  try {
    const { note, initialStatus } = submitApplicationSignal.value;

    const body = {
      where: {
        id: applicationDraftSignal.value.selectedDraft.id,
      },
      data: {
        status: APPLICATION_STATUS.pending,
        notes: [],
        action: 'commit',
      },
    };

    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo
    ) {
      body.data.status = initialStatus;
    }

    if (note && note.length > 0) {
      body.data.notes = [
        {
          content: note,
        },
      ];
    }

    const createdApplication = await api.patch({
      path: '/applications',
      body,
    });

    submitApplicationSignal.update({
      createdApplication,
    });
    applicationDraftSignal.update({ selectedDraft: {} });
    applicationComplianceSignal.reset();

    return nextStep();
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const generateSignedUrlsForAdditionalDocument = async () => {
  submitApplicationSignal.update({
    backingUpAdditionalFiles: true,
  });

  const { filesMap, additionalFilesList } = submitApplicationSignal.value;

  // key is programDocumentId
  for (const key in filesMap) {
    if (filesMap.hasOwnProperty(key)) {
      if (filesMap[key].filePath) {
        continue;
      }

      const [signedUrl] = await uploadFilesToStorage(
        [filesMap[key].file],
        'applicationDocument'
      );
      submitApplicationSignal.update({
        filesMap: {
          ...submitApplicationSignal.value.filesMap,
          [key]: {
            ...submitApplicationSignal.value.filesMap[key],
            filePath: signedUrl,
          },
        },
      });
    }
  }

  for (let i = 0; i < additionalFilesList.length; i++) {
    const additionalFile = additionalFilesList[i];
    if (additionalFile.filePath) {
      continue;
    }

    const [signedUrl] = await uploadFilesToStorage(
      [additionalFile.file],
      'applicationDocument'
    );
    submitApplicationSignal.update({
      additionalFilesList: additionalFilesList.map((fileBundle, j) => {
        if (j === i) {
          return {
            ...fileBundle,
            filePath: signedUrl,
          };
        }

        return fileBundle;
      }),
    });
  }

  submitApplicationSignal.update({
    backingUpAdditionalFiles: false,
  });
};

export const nextStep = async () => {
  const { step, fundingProgram } = submitApplicationSignal.value;
  if (step < NUM_OF_STEPS) {
    const nextStep = step + 1;
    if (nextStep === 3) {
      const { formattedOcrData } = ocrSignal.value;

      if (!fundingProgram.ocrModelKey) {
        return submitApplicationSignal.update({ step: step + 1 });
      }

      if (Object.keys(formattedOcrData).length === 0) {
        return alert(
          'Please confirm OCR form data before proceeding. If you do not see any form data below your uploaded document, please verify that you are using the correct application template and try again. If you are  still running into issues please contact support.'
        );
      }

      submitApplicationBusinessSummarySignal.update({
        name:
          formattedOcrData.borrowerInformation_businessName ||
          formattedOcrData.businessInformation_businessName,
        businessEin: submitApplicationSignal.value.businessEin,
        lenderEin: submitApplicationSignal.value.lenderEin,
        industry: formattedOcrData.borrowerInformation_industry,
        address: `${formattedOcrData.borrowerInformation_businessAddressMailing} ${formattedOcrData.borrowerInformation_city}, ${formattedOcrData.borrowerInformation_state} ${formattedOcrData.borrowerInformation_zipcode}`,
        type: formattedOcrData.borrowerInformation_businessDescription,
        contactName:
          formattedOcrData.borrowerInformation_contactName ||
          formattedOcrData.businessInformation_contactName ||
          formattedOcrData.borrowerInformation_name,
        contactPhone:
          formattedOcrData.borrowerInformation_contactPhone ||
          formattedOcrData.businessInformation_phone ||
          formattedOcrData.businessInformation_contactPhone ||
          formattedOcrData.businessInformation_phoneNumber,
        contactEmail:
          formattedOcrData.borrowerInformation_contactEmail ||
          formattedOcrData.borrowerInformation_email ||
          formattedOcrData.businessInformation_email ||
          formattedOcrData.businessInformation_emailAddress,
        naicsCode:
          formattedOcrData.borrowerInformation_naicsCode ||
          formattedOcrData.businessInformation_naics ||
          formattedOcrData.businessInformation_naicsCode ||
          formattedOcrData.borrowerInformation_naics,
        businessDescription: '',
        website: formattedOcrData.businessInformation_businessWebsite || '',
        demographics: {
          isWomanOwned:
            formattedOcrData.ssbciDemographics_minorityOwnedBusiness,
          isMinorityOwned:
            formattedOcrData.ssbciDemographics_minorityOwnedBusiness,
          isVeteranOwned:
            formattedOcrData.ssbciDemographics_veteranOwnedBusiness,
          ethnicityHispanicOrLatino:
            formattedOcrData.ethnicity_hispanicOrLatino,
          racePreferNotToRespond:
            !!formattedOcrData.ssbciDemographics_raceNotRespond,
          raceAmericanIndianOrAlaskaNative:
            !!formattedOcrData.ssbciDemographics_raceAmericanIndianAlaska,
          raceBlackOrAfricanAmerican:
            !!formattedOcrData.ssbciDemographics_raceBlankAfricanAmerican,
          raceAsianChinese: !!formattedOcrData.ssbciDemographics_raceChinese,
          raceAsianFilipino: !!formattedOcrData.ssbciDemographics_raceFilipino,
          raceAsianIndian: !!formattedOcrData.ssbciDemographics_raceAsianIndian,
          raceAsianJapanese: !!formattedOcrData.ssbciDemographics_raceJapanese,
          raceAsianKorean: !!formattedOcrData.ssbciDemographics_raceKorean,
          raceAsianVietnamese:
            !!formattedOcrData.ssbciDemographics_raceVietnamese,
          raceAsianOther: !!formattedOcrData.ssbciDemographics_raceAsianOther,
          raceGuamanianOrChamorro:
            !!formattedOcrData.ssbciDemographics_raceGuamanianChamorro,
          raceNativeHawaiian:
            !!formattedOcrData.ssbciDemographics_raceNativeHawaiian,
          raceSamoan: !!formattedOcrData.ssbciDemographics_raceSamoan,
          racePacificIslanderOther:
            !!formattedOcrData.ssbciDemographics_racePacificIslander,
          raceWhite: !!formattedOcrData.ssbciDemographics_raceWhite,
          menaf: formattedOcrData.ancestry_middleEasternOrNorthAfrican,
          gender: formattedOcrData.gender_gender,
          genderSelfDescribed:
            formattedOcrData.ssbciDemographics_genderPreferSelfDescribeDescription ||
            '',
          sexualOrientation:
            formattedOcrData.sexualOrientation_sexualOrientation,
          veteranStatus: formattedOcrData.veteranStatus_veteranStatus,
        },
      });

      submitApplicationLenderSummarySignal.update({
        name:
          formattedOcrData.lenderInformation_lenderName ||
          formattedOcrData.lenderInformation_participatingLender ||
          formattedOcrData.financialInstitution_name,
        contactName:
          formattedOcrData.lenderInformation_contactName ||
          formattedOcrData.lenderInformation_loanContactName ||
          formattedOcrData.financialInstitution_officerContactPerson,
        email:
          formattedOcrData.lenderInformation_email ||
          formattedOcrData.lenderInformation_loanContactEmail ||
          formattedOcrData.financialInstitution_emailAddress ||
          formattedOcrData.lenderInformation_emailAddress,
        phoneNumber:
          formattedOcrData.lenderInformation_phoneNumber ||
          formattedOcrData.lenderInformation_contactPhone ||
          formattedOcrData.financialInstitution_phoneNumbers,
      });
    }

    if (nextStep === 4) {
      await generateSignedUrlsForAdditionalDocument();
    }


    if (step < 5) {
      // Save a draft up to the notes page
      handleSaveApplicationDraft(nextStep);
    }

    submitApplicationSignal.update({ step: nextStep });
  }
};

export const saveApplicationDraft = async (nextStep = null) => {
  // this is where the majority of the fields get saved to the application
  const { APPLICATION_STATUS, DOCUMENT_TYPES } = $appSettings.value.constants;
  const { step } = submitApplicationSignal.value;

  try {
    const {
      note,
      ocrFileType,
      ocrFileSignedUrl,
      fundingProgram,
    } = submitApplicationSignal.value;
    const data = {};
    const applicationConfig = {
      step: nextStep ? nextStep : step,
    };

    alertSignal.update({
      type: 'notification',
      message: 'Saving draft...',
    });

    if (step === 1) { // First Page
      const { askedAmount, initialStatus } = submitApplicationSignal.value;

      data.askedAmount = Number(askedAmount);
      applicationConfig.approvedOnCommit = initialStatus === APPLICATION_STATUS.approved;
    }

    if (step === 2) { // Application Form
      data.formData = ocrSignal.value.formattedOcrData;
      data.businessData = submitApplicationBusinessSummarySignal.value || {};

      applicationConfig.isUsingOcr = submitApplicationSignal.value.isUsingOcr;
      applicationConfig.sortedCategories = ocrSignal.value.sortedCategories;
      applicationConfig.parsedOcrData = ocrSignal.value.parsedOcrData;
      applicationConfig.lenderData = submitApplicationLenderSummarySignal.value || {};
    }

    if (step === 3) { // Suggested/Additional Documents
      await generateSignedUrlsForAdditionalDocument();
    }

    // Supporting documents array is dependent on OCR file
    // Building the array must happen on both steps 2 and 3
    if (step === 2 || step === 3) {
      const { filesMap, additionalFilesList } = submitApplicationSignal.value;

      const suggestedFilesByKey = Object.entries(filesMap);
      const supportingDocuments = [];
      for (let i = 0; i < suggestedFilesByKey.length; i++) {
        const suggestedFile = suggestedFilesByKey[i][1];
        const file = suggestedFile.file;
        supportingDocuments.push({
          fundingProgramDocumentId: Number(suggestedFilesByKey[i][0]),
          name: file?.name || `supportingDocument${i}`,
          fileType: suggestedFile.fileType,
          filePath: suggestedFile.filePath,
          documentType: 'applicationDocuments',
        });
      }

      const additionalDocuments = [];
      for (let i = 0; i < additionalFilesList.length; i++) {
        const additionalFile = additionalFilesList[i];
        const file = additionalFile.file;
        additionalDocuments.push({
          displayName: additionalFile.name,
          name: file?.name || `Additional document ${i + 1}`,
          fileType: additionalFile.fileType,
          filePath: additionalFile.filePath,
          documentType: 'applicationDocuments',
        });
      }

      const combinedDocuments = [
        ...supportingDocuments,
        ...additionalDocuments,
      ];

      if (ocrFileType && ocrFileSignedUrl) {
        combinedDocuments.push(
          {
            documentType: 'applicationDocuments',
            fileType: ocrFileType,
            filePath: ocrFileSignedUrl,
            fundingProgramDocumentId: fundingProgram?.supportingDocuments?.find(
              (sd) => sd.documentType === DOCUMENT_TYPES.applicationTemplate
            ).id,
          }
        );
      }

      data.supportingDocuments = combinedDocuments;
    }

    if (step === 4) { // Compliance Checklist
      applicationConfig.complianceChecklist = applicationComplianceSignal.value.complianceChecklist;
    }

    if (step === 5 && note && note.length > 0) { // Notes
      data.notes = [
        {
          content: note,
        },
      ];
    }

    const payload = {
      data: {
        ...data,
        applicationConfig,
      },
    }

    const updatedDraft = await api.patch({
      path: '/applications',
      body: {
        where: {
          id: applicationDraftSignal.value.selectedDraft?.id,
        },
        data: {
          ...payload.data,
          action: 'updateDraft',
        },
      },
    }, 10);

    // only rehydrate on page change
    if (nextStep) {
      hydrateApplicationDataFromDraft(updatedDraft);
    }

    applicationDraftSignal.update({ selectedDraft: updatedDraft });
    await fetchAndSetApplicationDrafts(false);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Saved draft',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  }
};

export const prevStep = () => {
  const { step } = submitApplicationSignal.value;
  if (step > 1) {
    handleSaveApplicationDraft(step - 1);
    submitApplicationSignal.update({ step: step - 1 });
  }
};

export const nextStepUnlessApplicationAlreadyExists = async () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  loaderSignal.update({ isContentLoading: true });
  try {
    const {
      businessEin,
      lenderEin,
      referenceProgramId: referenceProgramIdInForm,
    } = submitApplicationSignal.value;

    const {
      account: { portalType, lender },
    } = userAccountSignal.value.userData;

    const where =
      portalType === PORTAL_TYPES.lender
        ? {
          fundingProgramMembership: {
            lenderId: lender.id,
          },
        }
        : {};

    const [applications] = await Promise.all([
      api.get({
        path: '/applications',
        options: {
          where,
          include: {
            business: true,
            fundingProgramMembership: {
              include: {
                lender: true,
                fundingProgram: {
                  include: {
                    referenceProgram: true,
                  },
                },
              },
            },
          },
        },
      }),
    ]);

    if (
      portalType === PORTAL_TYPES.lender &&
      applications.some((a) => {
        const referenceProgram =
          a.fundingProgramMembership.fundingProgram.referenceProgram;

        return (
          a.business?.ein === businessEin &&
          referenceProgram.id === referenceProgramIdInForm
        );
      })
    ) {
      throw new Error(
        'An application for this business has already been submitted.'
      );
    }

    if (
      portalType === PORTAL_TYPES.edo &&
      applications.some((a) => {
        try {
          // there could be applications without a fundingProgramMembership
          // because they're in the in-progress (draft) state
          const fundingProgramMembership = a.fundingProgramMembership;
          //
          if (!fundingProgramMembership) {
            return false;
          }

          if (fundingProgramMembership.ventureCapitalFirmId) {
            return false;
          }

          const referenceProgram =
            a.fundingProgramMembership.fundingProgram.referenceProgram;

          return (
            fundingProgramMembership.lender.ein === lenderEin &&
            a.business.ein === businessEin &&
            referenceProgram.id === referenceProgramIdInForm
          );
        } catch (error) {
          // get rid of this catch block
          // only added in development to handle bad data
          return false;
        }
      })
    ) {
      throw new Error(
        'An application for this particular lender and business combination has already been submitted.'
      );
    }

    submitApplicationSignal.update({ applications });
    return nextStep();
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const reset = async () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  submitApplicationSignal.update({
    businessEin: '',
    lenderEin: '',
    askedAmount: '',
    files: [],
    fundingProgramId: '-1',
    referenceProgramId: '-1',
    programTypeId:
      userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.edo
        ? '-1'
        : submitApplicationSignal.value.programTypeId,
    step: 1,
    applicationDocumentType: '',
    applicationDocumentTypes: [],
    note: '',
    filesMap: {},
    ocrModalVisible: false,
    ocrRenderInView: false,
    createdApplication: null,
    additionalFilesList: [],
    ocrFile: null,
    ocrFileName: '',
    ocrFileSignedUrl: '',
    ocrFileType: '',
    isUsingOcr: true,
  });
  ocrSignal.reset();
  applicationComplianceSignal.reset();
  if (applicationDraftSignal.value.selectedDraft?.id) {
    await handleClearLock();
  }
  applicationDraftSignal.update({
    showDraftModal: false,
  });
};

export const exitAndReset = (history) => {
  reset();
  const portal = history.location.pathname.split('/')[1];
  return history.push(`/${portal}`);
};

export const handleAttachFileForOcr = async (name, file, retry = 0) => {
  const { ocrFile, fundingProgram } = submitApplicationSignal.value;
  const { OCR_CONFIDENCE_THRESHOLD } = $appSettings.value.constants;
  if (ocrFile) {
    ocrSignal.reset();
  }

  submitApplicationSignal.update({
    ocrFileIsProcessing: true,
    ocrRenderInView: false,
    ocrFile: file,
  });

  let wsClient = null;

  try {
    const { url } = await api.post({
      path: '/pubsub',
      body: {
        data: {
          hub: 'ocr',
        },
      },
    });

    wsClient = new WebSocket(url);

    // this should never happen but sanity check nonetheless
    if (!fundingProgram.ocrModelKey) {
      return alertSignal.update({
        type: 'notification',
        message:
          'OCR for this program is coming soon. Your application document will still be saved when the application is submitted.',
      });
    }

    const [[signedUrl], [applicationDocumentSignedUrl]] = await Promise.all([
      uploadFilesToStorage([file], 'ocr'),
      uploadFilesToStorage([file], 'applicationDocument'),
    ]);

    submitApplicationSignal.update({
      ocrFileSignedUrl: applicationDocumentSignedUrl,
      ocrFileType: ocrFile.type,
      ocrFileName: ocrFile.name,
    });

    wsClient.onmessage = async (message) => {
      if (message.data) {
        const json = JSON.parse(message.data);
        const id = json.id;

        if (signedUrl.includes(id)) {
          submitApplicationSignal.update({
            ocrFileIsProcessing: false,
            ocrRenderInView: true,
            couldNotProcessOcrAlertVisible: json.data.confidence < OCR_CONFIDENCE_THRESHOLD,
          });
          wsClient?.close();
          ocrSignal.update({
            rawOcrData: json.data,
            modelId: fundingProgram.ocrModelKey,
          });
          parseOcrData();
          saveApplicationDraft();
        }
      }
    };

    const body = {
      data: {
        applicationId: applicationDraftSignal.value.selectedDraft.id,
        url: signedUrl,
        key: fundingProgram.ocrModelKey,
      },
    };

    return await api.post({ path: '/ocr', body });
  } catch (error) {
    wsClient?.close();

    if (retry < 10) {
      return setTimeout(() => {
        handleAttachFileForOcr(name, file, retry + 1);
      }, 1000 * Math.pow(1.35, retry + 1));
    }

    submitApplicationSignal.update({
      ocrFileIsProcessing: false,
    });

    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  }
};

export const navigateToApplication = (history) => {
  const portal = history.location.pathname.split('/')[1];
  const createdApplication = submitApplicationSignal.value.createdApplication;
  reset();

  return history.push(`/${portal}/portfolio/${createdApplication.id}`);
};

export const handleDownloadDocument = async (id) => {
  const downloadUrl = await api.get({
    path: `/uploadPreviews?id=${id}&model=fundingProgramDocuments`,
  });
  window.open(downloadUrl, '_blank');
  return true;
};

export const handleAttachAdditionalFile = (file) => {
  const {
    value: { additionalFilesList },
  } = submitApplicationSignal;
  submitApplicationSignal.update({
    additionalFilesList: additionalFilesList.concat({
      name: file.name,
      file,
      fileType: file.type,
      filePath: null,
    }),
  });
};

export const handleChangeAdditionalFileName = (newName, index) => {
  const {
    value: { additionalFilesList },
  } = submitApplicationSignal;

  return submitApplicationSignal.update({
    additionalFilesList: additionalFilesList.map((fileBundle, i) => {
      if (index === i) {
        return { ...fileBundle, name: newName };
      }

      return fileBundle;
    }),
  });
};

export const handleCancelAdditionalFile = (index) => {
  const {
    value: { additionalFilesList },
  } = submitApplicationSignal;
  return submitApplicationSignal.update({
    additionalFilesList: additionalFilesList.filter((_, i) => i !== index),
  });
};

export const getAssociatedReferencePrograms = () => {
  const { fundingPrograms, programTypes, programTypeId } =
    submitApplicationSignal.value;
  if (!programTypeId) {
    return [];
  }

  const referenceProgramIds = fundingPrograms.map(
    (mp) => mp.referenceProgramId
  );
  const referenceProgramIdsUnique = referenceProgramIds.filter(
    (programId, index) => referenceProgramIds.indexOf(programId) === index
  );

  const programsInProgramType = programTypes.find(
    (pType) => pType.id === programTypeId
  );
  const programSelection = programsInProgramType?.referencePrograms.filter(
    (program) => referenceProgramIdsUnique.find((pId) => pId === program.id)
  );
  return programSelection?.sort((a, b) => {
    if (a.programName < b.programName) return -1;
    if (a.programName > b.programName) return 1;
    return 0;
  });
};

export const getAssociatedFundingPrograms = () => {
  const { fundingPrograms, referenceProgramId } = submitApplicationSignal.value;

  if (!referenceProgramId) {
    return [];
  }

  const associatedFundingPrograms = fundingPrograms.filter(
    (mp) => mp.referenceProgramId === referenceProgramId
  );
  return associatedFundingPrograms;
};

export const hydrateApplicationDataFromDraft = (payload) => {
  const { APPLICATION_STATUS, DOCUMENT_TYPES } = $appSettings.value.constants;
  const { selectedDraft } = applicationDraftSignal.value;
  const { step } = submitApplicationSignal.value;

  applicationDraftSignal.update({
    selectedDraft: payload,
    draftPendingRequest: {},
  });
  if (!payload.fundingProgram) {
    alertSignal.update({
      type: 'notification',
      message:
        'This draft cannot be used because the program cannot be found, or it no longer exists.',
    });

    return applicationDraftSignal.update({ showDraftModal: false });
  }

  let filesMap = {};
  let additionalFilesList = [];
  for (let i = 0; i < payload.supportingDocuments?.length; i++) {
    const fileInfo = payload.supportingDocuments[i];
    const isApplicationTemplate =
      payload.fundingProgram.supportingDocuments.find(
        (sd) =>
          sd.id === fileInfo.fundingProgramDocumentId &&
          sd.documentType === DOCUMENT_TYPES.applicationTemplate
      );

    if (isApplicationTemplate) {
      continue;
    }

    if (fileInfo.fundingProgramDocumentId) {
      filesMap = {
        ...filesMap,
        [fileInfo.fundingProgramDocumentId]: {
          name: fileInfo.name,
          fileType: fileInfo.fileType,
          filePath: fileInfo.filePath,
          documentType: fileInfo.documentType,
          uuid: fileInfo.uuid,
        },
      };
    } else {
      additionalFilesList.push(fileInfo);
    }
  }

  const fundingProgramDocumentId =
    payload.fundingProgram?.supportingDocuments?.find(
      (sd) => sd.documentType === DOCUMENT_TYPES.applicationTemplate
    ).id;

  const ocrSupportingDocument = payload?.supportingDocuments.find(
    (sd) => sd.fundingProgramDocumentId === fundingProgramDocumentId
  );
  const ocrFileSignedUrl = ocrSupportingDocument?.filePath;
  const ocrFileType = ocrSupportingDocument?.fileType;
  const ocrFileName = ocrSupportingDocument?.name;
  const ocrFileUuid = ocrSupportingDocument?.uuid;

  submitApplicationSignal.update({
    ...submitApplicationSignal.value,
    note: payload.notes[0]?.content || '',
    lenderEin: payload.lenderEin,
    businessEin: payload.businessEin,
    askedAmount: payload.askedAmount,
    fundingProgramId: payload.fundingProgramId,
    referenceProgramId: payload.fundingProgram?.referenceProgramId,
    programTypeId: payload.fundingProgram?.referenceProgram?.programTypeId,
    step: selectedDraft?.id === payload?.id ? step : payload.applicationConfig.step || 1,
    fundingProgram: payload.fundingProgram,
    filesMap,
    additionalFilesList,
    ocrFileSignedUrl,
    ocrFileType,
    ocrFileName,
    ocrFileUuid,
    initialStatus: payload.approvedOnCommit
      ? APPLICATION_STATUS.approved
      : APPLICATION_STATUS.pending,
    isUsingOcr: payload.applicationConfig.isUsingOcr,
  });

  submitApplicationBusinessSummarySignal.update(payload.businessData);

  ocrSignal.update({
    parsedOcrData: payload.applicationConfig.parsedOcrData || {},
    formattedOcrData: payload.formData || {},
    sortedCategories: payload.applicationConfig.sortedCategories || {},
  });

  applicationComplianceSignal.update({
    complianceChecklist: payload.applicationConfig?.complianceChecklist,
  });

  applicationDraftSignal.update({ showDraftModal: false });
};

export const terminateApplicationDraft = async (draft) => {
  loaderSignal.update({ isContentLoading: true });
  try {
    await api.patch({
      path: '/applications',
      body: {
        where: {
          id: draft.id,
        },
        data: {
          action: 'deactivate',
        },
      },
    });
    applicationDraftSignal.reset();
    await fetchAndSetApplicationDrafts();
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleSaveApplicationDraft = (nextStep = null) => {
  const { fundingProgramId, businessEin, askedAmount, lenderEin } =
    submitApplicationSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  if (
    !fundingProgramId ||
    !businessEin ||
    !askedAmount ||
    (userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo &&
      !lenderEin)
  ) {
    return alertSignal.update({
      type: 'alert',
      message: `Please provide the following fields before saving a draft: funding program, business EIN,${userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.edo
        ? ' lender EIN'
        : ''
        } and asked amount.`,
    });
  }

  if (applicationDraftSignal.value.selectedDraft.id) {
    saveApplicationDraft(nextStep);
  } else {
    createApplication();
  }
};

const createApplication = async () => {
  const {
    fundingProgramId,
    lenderEin,
    businessEin,
    askedAmount,
    initialStatus,
  } = submitApplicationSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  try {
    loaderSignal.update({ isContentLoading: true });
    const application = await api.post({
      path: '/applications',
      body: {
        data: {
          fundingProgram: {
            connect: {
              id: fundingProgramId,
            },
          },
          businessEin,
          lenderEin,
          askedAmount: Number(askedAmount),
          approvedOnCommit: initialStatus === APPLICATION_STATUS.approved,
          applicationConfig: {
            complianceChecklist:
              applicationComplianceSignal.value.complianceChecklist,
          },
        },
      },
    });

    await fetchAndSetApplicationDrafts();
    applicationDraftSignal.update({ selectedDraft: application });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleAccessRevoked = async () => {
  applicationDraftSignal.update({
    selectedDraft: {},
    removeAccessModalVisible: false,
  });
  reset();
  await fetchAndSetApplicationDrafts(true);
};

export const handleContinueApplicationDraftClick = async (draft) => {
  if (
    draft.lockUserId &&
    draft.lockUserId !== userAccountSignal.value.userData.id
  ) {
    applicationDraftSignal.update({
      draftModalState: 'requestAccess',
      draftPendingRequest: draft,
    });
  } else {
    applicationDraftSignal.update({ draftPendingRequest: draft });
    await handleTakeDraftAccess();
  }
};

export const handleTakeDraftAccess = async () => {
  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Obtaining access...',
    });

    if (applicationDraftSignal.value.selectedDraft?.id) {
      await handleClearLock();
    }

    const application = await api.patch({
      path: '/applications',
      body: {
        where: {
          id: applicationDraftSignal.value.draftPendingRequest?.id,
        },
        data: {
          action: 'grabLock',
        },
      },
    });

    await fetchAndSetApplicationDrafts();
    hydrateApplicationDataFromDraft(application);
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleClearLock = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/applications',
      body: {
        where: {
          id: applicationDraftSignal.value.selectedDraft?.id,
        },
        data: {
          action: 'clearLock',
        },
      },
    });

    applicationDraftSignal.update({ selectedDraft: {} });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const validateAllComplianceItems = (referenceProgramId) => {
  const { STANDARD_COMPLIANCE_ITEM_KEYS, CAPITAL_ACCESS_COMPLIANCE_KEYS } = $appSettings.value.constants;
  const { complianceChecklist } = applicationComplianceSignal.value;
  const complianceItems =
    referenceProgramId === 4
      ? CAPITAL_ACCESS_COMPLIANCE_KEYS
      : STANDARD_COMPLIANCE_ITEM_KEYS;

  return !complianceItems.some((item) => complianceChecklist[item] === null);
};

export const handleNavigateToOcrFromApplicationDetailView = async () => {
  await handleTakeDraftAccess();
  submitApplicationSignal.update({ step: 2 });
};

export const fetchAndSetDigitalForm = async () => {
  const { fundingProgram } = submitApplicationSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    const digitalForm = await api.get({
      path: '/applications/digitalForms',
      options: {
        data: {
          referenceProgramKey: fundingProgram.referenceProgram.key,
        },
      },
    });

    ocrSignal.update({
      rawOcrData: digitalForm,
      parsedOcrData: {},
      formattedOcrData: {},
    });

    parseOcrData();
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleChangeToDigitalForm = async () => {
  await fetchAndSetDigitalForm();

  submitApplicationSignal.update({
    isUsingOcr: false,
    applicationFormWarningModalVisible: false,
    ocrFile: null,
    ocrFileSignedUrl: '',
    ocrFileType: '',
    ocrFileName: '',
  });

  saveApplicationDraft();
};

export const handleChangeToDocumentUpload = () => {
  submitApplicationSignal.update({ isUsingOcr: true, applicationFormWarningModalVisible: false });
  ocrSignal.update({ parsedOcrData: {}, rawOcrData: {} });

  saveApplicationDraft();
};

export const toggleApplicationFormTypeWarningModal = () => {
  const { applicationFormWarningModalVisible } = submitApplicationSignal.value;
  submitApplicationSignal.update({ applicationFormWarningModalVisible: !applicationFormWarningModalVisible })
};
