export function flattenNestedObject(obj, parentKey = '') {
  return Object.keys(obj).reduce((acc, key) => {
    const nestedKey = parentKey ? `${parentKey}.${key}` : key;

    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      key !== 'address' &&
      !Array.isArray(obj[key])
    ) {
      const nestedObj = flattenNestedObject(obj[key], nestedKey);
      return { ...acc, ...nestedObj };
    }

    return { ...acc, [nestedKey]: obj[key] };
  }, {});
}

export function expandFlatObject(obj) {
  const expandedObj = {};

  for (const key in obj) {
    const keyParts = key.split('.');
    let currentObj = expandedObj;

    for (let i = 0; i < keyParts.length; i++) {
      const currentKey = keyParts[i];

      if (!currentObj[currentKey]) {
        if (i === keyParts.length - 1) {
          currentObj[currentKey] = obj[key];
        } else {
          currentObj[currentKey] = {};
        }
      }

      currentObj = currentObj[currentKey];
    }
  }

  return expandedObj;
}
