const mockApiCall = async (mockData, loadTime = 700, shouldFail = false) => new Promise((resolve, reject) => {
  if (shouldFail) {
    setTimeout(() => {
      reject(new Error('This is just to test an error case.'));
    }, loadTime);
  } else {
    setTimeout(() => {
      resolve(mockData);
    }, loadTime);
  }
});

export default mockApiCall;
