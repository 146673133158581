import AttachmentsWithTemplatesForm from 'components/global/AttachmentsWithTemplatesForm/AttachmentsWithTemplatesForm';
import fundDetailsSignal from '../fundDetails.signal';
import {
  handleAddNewCapitalCallDocument,
  handleCapitalCallDocumentAttachFile,
  handleCapitalCallDocumentNameChange,
  handleRemoveCapitalCallDocument,
  submitCapitalCallDocuments,
} from '../fundDetails.helpers';
import userAccountSignal from 'signals/UserAccount.signal';
import { Button } from 'react-bootstrap';
import DownloadableDocumentsRow from 'components/global/DownloadableDocumentsRow';
import { enumCaseToTabCase } from 'libs/string';
import $appSettings from 'signals/AppSettings.signal';

const FundDocuments = () => {
  const { newCapitalCallRequestDocuments, documents } = fundDetailsSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES, FUND_DOCUMENT_TYPES } = $appSettings.value.constants;
  const capitalCallDocuments = documents.filter(
    (doc) => doc.documentType === FUND_DOCUMENT_TYPES.capitalCall
  );
  const requiredDocuments = documents.filter(
    (doc) => doc.documentType === FUND_DOCUMENT_TYPES.required
  );
  const suggestedDocuments = documents.filter(
    (doc) => doc.documentType === FUND_DOCUMENT_TYPES.suggested
  );

  return (
    <div className="mt-16">
      {portalType === PORTAL_TYPES.edo && (
        <div className="mb-32">
          <AttachmentsWithTemplatesForm
            title="Capital Call Documents"
            description="Add templates for the VC to upload when requesting a capital call"
            handleFileRemoval={(index) =>
              handleRemoveCapitalCallDocument(index)
            }
            handleFilenameChange={(index, name) =>
              handleCapitalCallDocumentNameChange(index, name)
            }
            handleAttachFile={(index, file) =>
              handleCapitalCallDocumentAttachFile(index, file)
            }
            handleAddEmptyFileSlot={() => handleAddNewCapitalCallDocument()}
            error={() => ''}
            fileBundles={newCapitalCallRequestDocuments.value}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="rounded"
              onClick={submitCapitalCallDocuments}
              hidden={!newCapitalCallRequestDocuments.value.length}
            >
              Submit Capital Call Documents
            </Button>
          </div>
        </div>
      )}
      {portalType !== PORTAL_TYPES.business && (
        <>
          <div
            className="mb-16"
            hidden={portalType === PORTAL_TYPES.edo}
          >
            <h5>Capital Call Documents</h5>
            <p>
              Templates provided by the EDO that are to be uploaded when
              requesting a capital call
            </p>
          </div>
          <DownloadableDocumentsRow
            overarchingDocumentType="fundDocuments"
            documents={(capitalCallDocuments || []).map((document) => ({
              filePath: document.filePath,
              fileType: document.fileType,
              header: enumCaseToTabCase(document.documentType),
              id: document.id,
              name: document.name,
            }))}
          />
          <div className="mb-16 mt-40">
            <h5>Required Fund Application Documents</h5>
            <p>
            Templates provided by the VC that are required to be submitted by the business when applying to a fund
            </p>
          </div>
          <DownloadableDocumentsRow
            overarchingDocumentType="fundDocuments"
            documents={(requiredDocuments || []).map((document) => ({
              filePath: document.filePath,
              fileType: document.fileType,
              header: enumCaseToTabCase(document.documentType),
              id: document.id,
              name: document.name,
            }))}
          />
          <div className="mb-16 mt-40">
            <h5>Suggested Fund Application Documents</h5>
            <p>
              Templates provided by the VC that are suggested to be submitted by the business when applying to a fund
            </p>
          </div>
            <DownloadableDocumentsRow
              overarchingDocumentType="fundDocuments"
              documents={(suggestedDocuments || []).map((document) => ({
                filePath: document.filePath,
                fileType: document.fileType,
                header: enumCaseToTabCase(document.documentType),
                id: document.id,
                name: document.name,
              }))}
            />
        </>
      )}
    </div>
  );
};

export default FundDocuments;
