import { Signal } from '@preact/signals-react';
import applicationDetailSignal, {
  ApplicationDetailType,
} from '../../ApplicationDetail/ApplicationDetail.signal';
import loaderSignal from 'signals/Loader.signal';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import {
  ApplicationDocument,
  FundingProgramDocument,
  ProgramRequestDocument,
} from 'types';
import { refreshListAndModal } from '../Approvable.helpers';

export const handleAttachNewFile = (
  file: File
): Signal<ApplicationDetailType> => {
  const { additionalDocumentsToUpload } = applicationDetailSignal.value;
  return applicationDetailSignal.update({
    additionalDocumentsToUpload: additionalDocumentsToUpload.concat({
      file,
      name: file.name,
      type: file.type,
    }),
  });
};

export const handleNewFileNameChange = (
  newName: string,
  index: number
): Signal<ApplicationDetailType> => {
  const { additionalDocumentsToUpload } = applicationDetailSignal.value;
  return applicationDetailSignal.update({
    additionalDocumentsToUpload: additionalDocumentsToUpload.map((d, i) => {
      if (index === i) {
        return { ...d, name: newName };
      }

      return d;
    }),
  });
};

export const handleDetachNewFile = (
  index: number
): Signal<ApplicationDetailType> => {
  const { additionalDocumentsToUpload } = applicationDetailSignal.value;
  return applicationDetailSignal.update({
    additionalDocumentsToUpload: additionalDocumentsToUpload.filter(
      (d, i) => i !== index
    ),
  });
};

export const showFileUploadConfirmationModal = (
  fileUploadConfirmationType: string
): Signal<unknown> =>
  applicationDetailSignal.update({
    fileUploadConfirmationModalVisible: true,
    fileUploadConfirmationType,
  });

export const hideFileUploadConfirmationModal = (): Signal<unknown> =>
  applicationDetailSignal.update({
    fileUploadConfirmationModalVisible: false,
    fileUploadConfirmationType: '',
  });

export const submitAdditionalFilesPendingUpload = async (): Promise<
  Signal<unknown>
> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting documents...',
  });
  try {
    const { id, modelType, additionalDocumentsToUpload } =
      applicationDetailSignal.value;

    const additionalDocumentFiles = additionalDocumentsToUpload.map(
      (fileBundle) => fileBundle.file
    );

    const additionalDocumentsSignedUrls = await uploadFilesToStorage(
      additionalDocumentFiles,
      modelType === 'application'
        ? 'applicationDocument'
        : 'programRequestDocument'
    );

    const basePayload =
      modelType === 'application'
        ? {}
        : {
          programRequest: {
            connect: {
              id: applicationDetailSignal.value.id,
            },
          },
        };

    const body = {
      // data: files
      //   .map((file, index) => ({
      //     ...basePayload,
      //     filePath: filesSignedUrls[index],
      //     fileType: file.type,
      //     fundingProgramDocumentId: Number(Object.keys(filesToSubmit)[index]),
      //   }))
      data: [].concat(
        //@ts-ignore
        additionalDocumentsToUpload.map((document, index) => ({
          ...basePayload,
          filePath: additionalDocumentsSignedUrls[index],
          fileType: document.file.type,
          name: document.name,
        }))
      ),
    };

    if (modelType === 'application') {
      await api.post({
        path: `/applications/${id}/documents`,
        body,
      });
    } else {
      await api.post({
        path: `/${applicationDetailSignal.value.uploadDocumentType}`,
        body,
      });
    }

    await refreshListAndModal(
      applicationDetailSignal.value.id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({
      additionalDocumentsToUpload: [],
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully uploaded documents.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const updateApplicationDocumentStatus = async (
  document: ApplicationDocument | ProgramRequestDocument,
  status: string
): Promise<Signal<unknown>> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating document status...',
  });
  try {
    const data = {
      status,
    };

    if (applicationDetailSignal.value.modelType === 'programRequest') {
      await api.patch({
        path: `/programRequestDocuments`,
        body: {
          where: {
            // @ts-ignore
            id: document.id,
          },
          data,
        },
      });
    } else {
      await api.patch({
        path: `/applications/${applicationDetailSignal.value.id}/documents/${(document as ApplicationDocument).uuid
          }`,
        body: {
          data,
        },
      });
    }

    await refreshListAndModal(
      applicationDetailSignal.value.id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({
      approvalAttachmentsToUpload: [],
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated document status.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleDeleteFile = async (
  documentId: number | string
): Promise<Signal<unknown>> => {
  const { id, modelType, uploadDocumentType } = applicationDetailSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });
    await api.delete({
      path:
        modelType === 'programRequest'
          ? `/${uploadDocumentType}`
          : `/applications/${id}/documents/${documentId}`,
      body:
        modelType === 'programRequest'
          ? {
            where: {
              id: documentId,
            },
          }
          : {},
    });

    await refreshListAndModal(
      applicationDetailSignal.value.id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully deleted document.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleRemoveFileClick = (
  removeFileId: string | number
): Signal<unknown> =>
  applicationDetailSignal.update({
    removeFileConfirmationModalVisible: true,
    removeFileId,
  });

export const handleRemoveFileConfirmationModalClose = (): Signal<unknown> =>
  applicationDetailSignal.update({
    removeFileConfirmationModalVisible: false,
    removeFileId: null,
  });

export const handleAttachExistingFile = (
  file: File,
  e: React.ChangeEvent<HTMLInputElement>,
  fundingProgramDocument: FundingProgramDocument
): Signal<ApplicationDetailType> =>
  applicationDetailSignal.update({
    fileToSubmit: {
      name: fundingProgramDocument.name,
      fundingProgramDocumentId: fundingProgramDocument.id,
      file,
    },
  });

export const handleUploadTemplateOrSuggestedDocument = async (): Promise<
  Signal<unknown>
> => {
  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Uploading document...',
    });
    const { modelType, fileToSubmit } = applicationDetailSignal.value;

    const { file } = fileToSubmit;
    const filesSignedUrls = await uploadFilesToStorage(
      [file],
      modelType === 'application'
        ? 'applicationDocument'
        : 'programRequestDocument'
    );

    const basePayload =
      modelType === 'application'
        ? {
          applicationId: applicationDetailSignal.value.id,
          name: fileToSubmit.name,
          fundingProgramDocumentId: Number(
            fileToSubmit.fundingProgramDocumentId
          ),
        }
        : {
          programRequest: {
            connect: {
              id: applicationDetailSignal.value.id,
            },
          },
          fundingProgramDocument: {
            connect: {
              id: Number(fileToSubmit.fundingProgramDocumentId),
            },
          },
        };

    const body = {
      data: [
        {
          ...basePayload,
          filePath: filesSignedUrls[0],
          fileType: file!.type,
        },
      ],
    };

    await api.post({
      path:
        modelType === 'programRequest'
          ? `/${applicationDetailSignal.value.uploadDocumentType}`
          : `/applications/${applicationDetailSignal.value.id}/documents`,
      body,
    });

    await refreshListAndModal(
      applicationDetailSignal.value.id!,
      applicationDetailSignal.value.modelType,
      applicationDetailSignal.value.ventureCapitalist
    );
    applicationDetailSignal.update({
      fileToSubmit: {},
    });
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully uploaded document.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: `An unexpected error occurred when loading the approval chain users. ${error.message}`,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};
