import { APPLICATION_FORMATS } from 'components/global/Constant/fileFormats';
import DragAndDrop from 'components/global/DragAndDrop/DragAndDrop';
import FileDropZoneWrapper from 'components/global/FileDropZone';
import { Button, Col, Container, Row } from 'react-bootstrap';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import { handleFileCancel, handleUpload, prevStep } from '../StateMigrate.helpers';

const AdminMigrateApplications = ({ portalType }) => {
  const { form: { files } } = AdminMigrateSignal.value[portalType];

  const isBtnDisabled = () => files.length === 0;

  return (
    <Container className="p-16 bg-light">
      <Row className="mb-32">
        <Col className="mb-16">
          <h5>Migrate Applications</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Upload Forms</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <FileDropZoneWrapper
            formats={APPLICATION_FORMATS}
            numOfUploadsAllowed={50}
            maxSizeMB={100}
            onUpload={handleUpload}
          >
            <DragAndDrop
              files={files}
              uploadProgress={0}
              onFileCancel={handleFileCancel}
              maxSizeMB={100}
            />
          </FileDropZoneWrapper>
        </Col>
      </Row>
      <Row className="my-64">
        <Col className="text-center">
          <Button onClick={prevStep}>Prev Page</Button>
        </Col>
        <Col className="text-center">
          <Button type="submit" disabled={isBtnDisabled()}>Next Page</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminMigrateApplications;
