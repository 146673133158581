import React, { useEffect, useState } from "react";
import { fetchApplicationBlockchain } from "../ApplicationDetail.helpers";
import applicationDetailSignal from "../ApplicationDetail.signal";
import { Button } from "react-bootstrap";
import ReactDiffViewer from "react-diff-viewer";
import { enumCaseToTabCase } from "../../../../libs/string";

const ApplicationBlockchainTab = () => {
  const { id, blockchain, updatedDate } = applicationDetailSignal.value;
  const [visibleIndex, setVisibleIndex] = useState<number>();

  useEffect(() => {
    if (id) {
      fetchApplicationBlockchain();
    }
  }, [id, updatedDate]);

  return (
    <div className="p-8">
      {blockchain.map((chainblock, index) => {
        const firstBlock = index === 0;
        return (
          <React.Fragment key={chainblock.id}>
            <div className="border-secondary mb-32">
              <p>
                <b>ID:</b> {chainblock.id}
              </p>
              <p>
                <b>Previous block ID:</b> {chainblock.previousBlockId || '-'}
              </p>
              <p>
                <b>Action:</b>{' '}
                {enumCaseToTabCase(chainblock.action).replace('Ocr', 'OCR')}
              </p>
              <p>
                <b>Timestamp:</b> {chainblock.createdDate}
              </p>
              <p>
                <b>HMAC Signature:</b> {chainblock.hmacTag}
              </p>
              <p>
                <b>User:</b> {chainblock.user.firstName}{' '}
                {chainblock.user.lastName} - {chainblock.user.email}
              </p>
              <div className="text-right">
                <Button
                  variant="outline-primary"
                  className="rounded"
                  size="sm"
                  onClick={() => {
                    if (visibleIndex === index) {
                      return setVisibleIndex(undefined);
                    }

                    setVisibleIndex(index);
                  }}
                >
                  {visibleIndex === index ? 'Hide diff' : 'Show diff'}
                </Button>
              </div>
            </div>
            {visibleIndex === index && (
              <ReactDiffViewer
                oldValue={JSON.stringify(
                  firstBlock ? {} : blockchain[index - 1].state,
                  null,
                  2
                )}
                newValue={JSON.stringify(blockchain[index].state, null, 2)}
                splitView={true}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ApplicationBlockchainTab;
