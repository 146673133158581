import { useState, useEffect, useCallback } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import { fetchAndSetStates, fetchAndSetYears, fetchAndSetPrograms,  handleFilterInputChange, handleSearchPrevMigrations } from '../StateMigrate.helpers'; // eslint-disable-line
import $appSettings from 'signals/AppSettings.signal';

const ChooseFiltersPrevApplications = ({ portalType = 'EDO' }) => {
  const { filters: { state, program, year } } = AdminMigrateSignal.value[portalType];
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const [programs, setPrograms] = useState([]);
  const [states, setStates] = useState([]);
  const [years, setYears] = useState([]);

  const init = useCallback(async () => {
    if (portalType === PORTAL_TYPES.state) {
      await fetchAndSetStates(setStates);
    }
    await Promise.all([
      fetchAndSetYears(setYears),
      fetchAndSetPrograms(setPrograms),
    ]);
  });

  useEffect(() => {
    init();
  }, []);

  const getYearSelections = () => years.map(y => <option value={y} key={y}>{y}</option>);
  const getStateSelections = () => states.map(name => <option value={name} key={name}>{name}</option>);
  const getProgramSelections = () => programs.map(prg => <option value={prg.id} key={prg.id}>{prg.title}</option>);

  const isBtnDisabled = () => {
    if (portalType === PORTAL_TYPES.state) {
      return !program.id || !year || !state;
    }
    return !program.id || !year;
  };

  return (
    <Container className="p-16 bg-light">
      <Row className="mb-32">
        <Col>
          <h5>Select Options</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">Year</Form.Label>
            <Form.Select
              className="form-control"
              name="year"
              value={year}
              onChange={handleFilterInputChange}
            >
              {getYearSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
        {
          portalType === PORTAL_TYPES.state
          && (
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">State</Form.Label>
              <Form.Select
                className="form-control"
                name="state"
                value={state}
                onChange={handleFilterInputChange}
              >
                {getStateSelections()}
              </Form.Select>
            </Form.Group>
          </Col>
          )
        }
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">Program</Form.Label>
            <Form.Select
              className="form-control"
              name="program"
              value={program.id}
              onChange={handleFilterInputChange}
            >
              {getProgramSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center p-16">
        <Button onClick={handleSearchPrevMigrations} disabled={isBtnDisabled()}>Search</Button>
      </Row>
    </Container>
  );
};

export default ChooseFiltersPrevApplications;
