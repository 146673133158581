/* eslint-disable no-constant-condition */
import React, { useCallback, useEffect } from 'react';
import { Button, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import BusinessBasicInfo from './components/BusinessBasicInfo';
import BusinessDemographics from './components/BusinessDemographics';
import {
  fetchAndSetBusiness,
  handleTabChange,
  submitBusiness,
} from './BusinessSettings.helpers';
import BusinessFinance from './components/BusinessFinance';
import BusinessAssistance from './components/BusinessAssistance';
import BusinessDiscovery from './components/BusinessDiscovery';
import BusinessMinorityStatus from './components/BusinessMinorityStatus';

function SectionWrapper({ children }) {
  return (
    <div className="bg-white p-32 border border-gray shadow-sm">
      <Form onSubmit={submitBusiness}>
        {children}
        <hr />
        <Row className="px-16 justify-content-end">
          <Button size="lg" type="submit" className="rounded">
            Save
          </Button>
        </Row>
      </Form>
    </div>
  );
}

const BusinessSettings = () => {
  const { search } = useLocation();
  const tab = new URLSearchParams(search).get('tab') || 'company-info';

  const init = useCallback(() => {
    fetchAndSetBusiness();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Tabs
      defaultActiveKey="company-info"
      activeKey={tab}
      animation="false"
      id="noanim-tab-example"
      onSelect={handleTabChange}
    >
      <Tab eventKey="company-info" title="General Info">
        <SectionWrapper>
          <BusinessBasicInfo />
        </SectionWrapper>
      </Tab>
      <Tab eventKey="sediAndMinorityStatus" title="SEDI & Minority Status">
        <SectionWrapper>
          <BusinessMinorityStatus />
        </SectionWrapper>
      </Tab>
      <Tab eventKey="finance" title="Finance">
        <SectionWrapper>
          <BusinessFinance />
        </SectionWrapper>
      </Tab>
      <Tab eventKey="assistance" title="Assistance">
        <SectionWrapper>
          <BusinessAssistance />
        </SectionWrapper>
      </Tab>
      <Tab eventKey="demographics" title="Demographics">
        <SectionWrapper>
          <BusinessDemographics />
        </SectionWrapper>
      </Tab>
      <Tab eventKey="discovery" title="Discovery">
        <SectionWrapper>
          <BusinessDiscovery />
        </SectionWrapper>
      </Tab>
    </Tabs>
  );
};

export default BusinessSettings;
