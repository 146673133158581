import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { lenderPortfolioSignal } from 'signals/LenderPortfolio.signal';
import PortfolioTable from './components/PortfolioTable';
import {
  fetchAndSetApplications,
  handleApplicationsAppliedFiltersChange,
} from './Applications.helpers';
import ApplicationDetailModal from '../ApplicationDetail/ApplicationDetailModal';
import { enumCaseToTabCase } from 'libs/string';
import { useLocation } from 'react-router';
import { handleTabChange } from 'libs/functions/global.functions';
import BreadCrumbWrapper from '../BreadCrumbs/BreadCrumbs';
import $appSettings from 'signals/AppSettings.signal';

const Applications = () => {
  const location = useLocation();
  const { appliedFilters, bundle } = lenderPortfolioSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const [currentTab] = useState('general');
  const { tally, numWatchlist } = bundle;
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const currentPage = searchParams.page ? Number(searchParams.page) : 1;
  let activeKey = 'all';
  if (appliedFilters.watchables) {
    activeKey = 'watchlist';
  } else if (searchParams.status) {
    activeKey = searchParams.status;
  }
  useEffect(() => {
    fetchAndSetApplications(currentPage);
  }, [location.search]);

  return (
    <BreadCrumbWrapper>
      <Tabs
        activeKey={activeKey || 'all'}
        defaultActiveKey="all"
        mountOnEnter
        onSelect={(status) => {
          if (status === 'watchlist') {
            handleTabChange(null);
          } else {
            handleTabChange(status);
          }
          const appliedFilters = lenderPortfolioSignal.value.appliedFilters;
          if (status === 'watchlist') {
            handleApplicationsAppliedFiltersChange({
              ...appliedFilters,
              watchables: true,
            });
            fetchAndSetApplications(currentPage);
          } else {
            handleApplicationsAppliedFiltersChange({
              ...appliedFilters,
              watchables: false,
            });
            fetchAndSetApplications(currentPage);
          }
        }}
      >
        <Tab eventKey="all" title={`All\n${bundle.count}`}>
          <div className="bg-white px-32 pb-32 pt-16 border border-gray shadow-sm">
            <PortfolioTable currentPage={currentPage} />
          </div>
        </Tab>
        {tally &&
          Object.values(APPLICATION_STATUS).map((status) => {
            const matchingTally = tally.find((t) => t.status === status);
            return (
              <Tab
                key={status}
                eventKey={status}
                title={`${enumCaseToTabCase(status)} ${
                  matchingTally?._count.status || 0
                }`}
              >
                <div className="bg-white px-32 pb-32 pt-16 border border-gray shadow-sm">
                  <PortfolioTable currentPage={currentPage} />
                </div>
              </Tab>
            );
          })}
        <Tab eventKey="watchlist" title={`Watchlist ${numWatchlist || 0}`}>
          <div className="bg-white px-32 pb-32 pt-16 border border-gray shadow-sm">
            <PortfolioTable currentPage={currentPage} />
          </div>
        </Tab>
      </Tabs>
      <ApplicationDetailModal currentTab={currentTab} />
    </BreadCrumbWrapper>
  );
};

export default Applications;
