import alertSignal from "signals/Alert.signal";
import api from "api/api";
import { notificationCursorSignal } from "signals/Notification.signal";
import userAccountSignal from "signals/UserAccount.signal";
import loaderSignal from "signals/Loader.signal";

const DRAWER_LIMIT = 5;
const NOTIFICATION_LIMIT = 20;

export const fetchNotification = async (id) => {
  try {
    const alert = await api.get({
      path: '/alerts',
      options: {
        where: {
          id,
        },
      },
    });

    return alert;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });

    return undefined;
  }
};

export const fetchNotificationsForDrawer = async (showLoader = true) => {
  if (showLoader) {
    loaderSignal.update({ isContentLoading: true });
  }
  try {
    const alerts = await api.get({
      path: '/alerts',
      options: {
        take: DRAWER_LIMIT,
        where: {
          accountId: userAccountSignal.value.userData.account.id,
        },
        orderBy: [
          {
            id: 'desc',
          },
        ],
        include: {
          account: true,
          alertInteractions: {
            where: {
              userId: userAccountSignal.value.userData.id,
            },
          },
        },
      },
    });
    return alerts;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
    return [];
  } finally {
    loaderSignal.reset();
  }
};

export const fetchNotifications = async (showLoader = true) => {
  if (showLoader) {
    loaderSignal.update({ isContentLoading: true });
  }
  const limit=  NOTIFICATION_LIMIT;
  try {
    const alerts = await api.get({
      path: '/alerts',
      options: {
        take: limit,
        orderBy: [
          {
            createdDate: 'desc',
          },
        ],
        where: {
          accountId: userAccountSignal.value.userData.account.id,
        },
        include: {
          account: true,
          alertInteractions: {
            where: {
              userId: userAccountSignal.value.userData.id,
            },
          },
        },
      },
    });

    if (alerts.length === limit) {
      notificationCursorSignal.update(alerts[limit - 1].id);
    } else {
      notificationCursorSignal.update(null);
    }
    return alerts;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
    return [];
  } finally {
    loaderSignal.reset();
  }
};

export const paginateNotifications = async (limit = NOTIFICATION_LIMIT) => {
  loaderSignal.update({ isContentLoading: true });
  const cursor = notificationCursorSignal.value;
  if (!cursor) return;

  try {
    const alerts = await api.get({
      path: '/alerts',
      options: {
        take: limit,
        skip: 1, // skip cursor
        cursor: {
          id: cursor,
        },
        orderBy: [
          {
            createdDate: 'desc',
          },
        ],
        include: {
          account: true,
          alertInteractions: {
            where: {
              userId: userAccountSignal.value.userData.id,
            },
          },
        },
      },
    });

    if (alerts.length === limit) {
      notificationCursorSignal.update(alerts[limit - 1].id);
    } else {
      notificationCursorSignal.update(null);
    }
    return alerts;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
    return [];
  } finally {
    loaderSignal.reset();
  }
};

export const markNotificationAsRead = async (alertId) => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating alerts...',
  });
  const body = {
    alertId,
    read: true,
    readAt: new Date(),
  };

  try {
    const alertInteraction = await api.post({
      path: '/alertInteractions',
      body,
    });
    return alertInteraction;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
    return null;
  } finally {
    loaderSignal.reset();
  }
};