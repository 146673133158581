import $appSettings from "signals/AppSettings.signal";

const { PORTAL_TYPES } = $appSettings.value.constants;

export const PORTAL_TYPE_HOME = {
  STATE: '/state',
  EDO: '/edo',
  LENDER: '/lender',
  BUSINESS: '/business/dashboard',
  VC: '/vc',
};

export const PORTAL_TYPE_BASE = {
  STATE: '/state',
  EDO: '/edo',
  LENDER: '/lender',
  BUSINESS: '/business',
  VC: '/vc',
};

export const SEEKING_FUNDING_PAYMENT_RANGES = [
  '$0 - $5000',
  '$5001 - $10000',
  '$10001 - $15000',
  '$15001 - $20000',
  '$20001 - 50000',
  '$50001 - 100000',
  '$100001+',
];

export const WATCHLIST_STATUS = {
  watching: 'WATCHING',
  nonPerforming: 'NON_PERFORMING',
};

export const USER_PROGRAM_ACCESS_OPTIONS = [
  { id: '1', value: 'ADMIN', name: 'Admin', label: 'Admin' },
  {
    id: '2',
    value: 'STAFF',
    name: 'Staff',
    label: 'Staff',
  },
];

export const WATCHABLE_TYPES = {
  application: 'Application',
  programRequest: 'ProgramRequest',
};

export const PROGRAM_DOCUMENT_SUBMITTABLE_TYPES = [
  'Articles of Incorporation',
  'Balance Sheet',
  'Bank Statements',
  'Business Credit Report',
  'Business License',
  'Business Plan',
  'Cash Flow',
  'Financial Statement',
  'Income Statement',
  'Income Tax Return',
  'Resume',
];

export const PROGRAM_KEYS = {
  loanParticipation: 'loanParticipationProgram',
  loanGuaranty: 'loanGuarantyProgram',
  collateralSupport: 'collateralSupportProgram',
  capitalAccess: 'capitalAccessProgram',
  microLoan: 'microLoanProgram',
  fundInvestment: 'fundInvestmentProgram',
  directInvestment: 'directInvestmentProgram',
};

export const PROGRAM_CLASSIFICATIONS = {
  proRata: 'Pro Rata',
  filo: 'FILO (Subordinate)',
};

export const FUNDS_UNDER_CUSTODY_OPTIONS = {
  edo: 'EDO',
  lender: 'LENDER',
};

export const ALERT_KEYS = {
  savedSearchResult: {
    execution: 'SAVED_SEARCH_RESULT_EXECUTION',
  },
  application: {
    documentChangedToRead: 'APPLICATION_DOCUMENT_CHANGED_TO_READ',
    documentChangedToNotRead: 'APPLICATION_DOCUMENT_CHANGED_TO_NOT_READ',
    documentChangedToNeedMoreInfo:
      'APPLICATION_DOCUMENT_CHANGED_TO_NEED_MORE_INFO',
    documentUploaded: 'APPLICATION_DOCUMENT_UPLOADED',
    changedToIncomplete: 'APPLICATION_CHANGED_TO_INCOMPLETE',
    changedToApproved: 'APPLICATION_CHANGED_TO_APPROVED',
    changedToSubmittedForApproval:
      'APPLICATION_CHANGED_TO_SUBMITTED_FOR_APPROVAL',
    changedToApprovedAndDisbursementDateSet:
      'APPLICATION_CHANGED_TO_APPROVED_AND_DISBURSEMENT_DATE_SET',
    changedToDenied: 'APPLICATION_CHANGED_TO_DENIED',
    newSubmission: 'NEW_APPLICATION_SUBMITTED',
    committed: 'APPLICATION_COMMITTED',
    sentBackForReview: 'APPLICATION_SENT_BACK_FOR_REVIEW',
    liquidationAmountSent: 'APPLICATION_LIQUIDATION_AMOUNT_SENT',
    proceedsCalculated: 'APPLICATION_PROCEEDS_CALCULATED',
    proceedsSent: 'APPLICATION_PROCEEDS_SENT',
    lenderConfirmedReceivedDisbursement:
      'APPLICATION_LENDER_CONFIRMED_RECEIVED_DISBURSEMENT',
    edoWatchableApplicationNonPerformingChanged:
      'EDO_WATCHABLE_APPLICATION_NON_PERFORMING_CHANGED',
    edoWatchableApplicationWatchingChanged:
      'EDO_WATCHABLE_APPLICATION_WATCHING_CHANGED',
    lenderCreatedLossClaim: 'LENDER_CREATED_LOSS_CLAIM',
    edoApprovedLossClaim: 'EDO_APPROVED_LOSS_CLAIM',
    updatedDisbursementConfig: 'UPDATED_DISBURSEMENT_CONFIG',
    edoAddedTechnicalAssistance: 'EDO_ADDED_TECHNICAL_ASSISTANCE',
  },
  fundingProgram: {
    lenderProgramRequestApproved: 'LENDER_PROGRAM_REQUEST_APPROVED',
    lenderProgramRequestIncomplete: 'LENDER_PROGRAM_REQUEST_INCOMPLETE',
    lenderProgramRequestDenied: 'LENDER_PROGRAM_REQUEST_DENIED',
    lenderProgramRequestSendBack: 'LENDER_PROGRAM_REQUEST_SEND_BACK',
    vcProgramRequestApproved: 'VC_PROGRAM_REQUEST_APPROVED',
    vcProgramRequestIncomplete: 'VC_PROGRAM_REQUEST_INCOMPLETE',
    vcProgramRequestDenied: 'VC_PROGRAM_REQUEST_DENIED',
    vcProgramRequestSendBack: 'VC_PROGRAM_REQUEST_SEND_BACK',
    edoAddedNewCreditProgram: 'EDO_ADDED_NEW_CREDIT_PROGRAM',
    edoAddedNewEquityProgram: 'EDO_ADDED_NEW_EQUITY_PROGRAM',
    notifyBusinessLenderProgramRequestApproved:
      'NOTIFY_BUSINESS_LENDER_PROGRAM_REQUEST_APPROVED',
    notifyBusinessVcProgramRequestApproved:
      'NOTIFY_BUSINESS_VC_PROGRAM_REQUEST_APPROVED',
  },
  programRequest: {
    lenderRequestedAccessToCreditProgram:
      'LENDER_REQUESTED_ACCESS_TO_CREDIT_PROGRAM',
    vcRequestedAccessToCreditProgram: 'VC_REQUESTED_ACCESS_TO_EQUITY_PROGRAM',
    vcRequestedCapitalCall: 'VC_REQUESTED_CAPITAL_CALL',
  },
  note: {
    edoAddedNoteToProgramRequest: 'EDO_ADDED_NOTE_TO_PROGRAM_REQUEST',
    lenderAddedNoteToProgramRequest: 'LENDER_ADDED_NOTE_TO_PROGRAM_REQUEST',
    vcAddedNoteToProgramRequest: 'VC_ADDED_NOTE_TO_PROGRAM_REQUEST',
    edoAddedNoteToApplication: 'EDO_ADDED_NOTE_TO_APPLICATION',
    lenderAddedNoteToApplication: 'LENDER_ADDED_NOTE_TO_APPLICATION',
    vcAddedNoteToApplication: 'VC_ADDED_NOTE_TO_APPLICATION',
  },
  userRequestedAccess: 'USER_REQUESTED_ACCESS',
  lenderExpressedInterest: 'LENDER_EXPRESSED_INTEREST',
  vcExpressedInterest: 'VC_EXPRESSED_INTEREST',
  businessExpressedInterest: 'BUSINESS_EXPRESSED_INTEREST',
  lenderConfirmDisbursementReminder: 'LENDER_CONFIRM_DISBURSEMENT_REMINDER',
  edoConfirmDisbursementReminder: 'EDO_CONFIRM_DISBURSEMENT_REMINDER',
  edoSharedBusiness: 'EDO_SHARED_BUSINESS',
  fund: {
    businessAddedToFund: 'BUSINESS_ADDED_TO_FUND',
    businessSubmittedFundRequest: 'BUSINESS_SUBMITTED_FUND_REQUEST',
  },
  capitalCall: {
    capitalCallDocumentApproved: 'CAPITAL_CALL_DOCUMENT_APPROVED',
    capitalCallDocumentPending: 'CAPITAL_CALL_DOCUMENT_PENDING',
    capitalCallDocumentResubmission: 'CAPITAL_CALL_DOCUMENT_RESUBMISSION',
    capitalCallApproved: 'CAPITAL_CALL_APPROVED',
    capitalCallDenied: 'CAPITAL_CALL_DENIED',
    capitalCallPending: 'CAPITAL_CALL_PENDING',
    capitalCallFunded: 'CAPITAL_CALL_FUNDED',
    capitalCallSubmittedForApproval: 'CAPITAL_CALL_SUBMITTED_FOR_APPROVAL',
    confirmFundsReceived: 'CAPITAL_CALL_CONFIRM_FUNDS_RECEIVED',
    overdueFundsNotYetReceived:
      'CAPITAL_CALL_FUNDS_NOT_YET_RECEIVED',
    edoUpdatedTransactionTerms: 'EDO_UPDATED_TRANSACTION_TERMS',
    vcUpdatedTransactionTerms: 'VC_UPDATED_TRANSACTION_TERMS',
    capitalCallCreated: 'CAPITAL_CALL_CREATED',
  },
  capitalCallPortion: {
    fundsAwarded: 'CAPITAL_CALL_PORTION_FUNDS_AWARDED',
    confirmFundsReceived: 'CAPITAL_CALL_PORTION_CONFIRM_FUNDS_RECEIVED',
    overdueFundsNotYetReceived: 'CAPITAL_CALL_PORTION_FUNDS_NOT_YET_RECEIVED',
  },
  fundApplication: {
    documentApproved: 'FUND_APPLICATION_DOCUMENT_APPROVED',
    documentPending: 'FUND_APPLICATION_DOCUMENT_PENDING',
    documentResubmission: 'FUND_APPLICATION_DOCUMENT_RESUBMISSION',
  },
};

export const NOTIFICATION_INFO = {
  [ALERT_KEYS.fund.businessAddedToFund]: {
    actionTitle: 'Go to fund',
    actionLocation: (id) => `/funds/${id}`,
  },
  [ALERT_KEYS.savedSearchResult.execution]: {
    actionTitle: 'Go to results',
    actionLocation: (id, portalType) => {
      if (portalType === PORTAL_TYPES.business) {
        return `/search-results?savedSearchResultId=${id}`;
      }

      return `/search-businesses/business-search-results?savedSearchResultId=${id}`;
    },
  },
  [ALERT_KEYS.application.documentChangedToRead]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=documents`,
  },
  [ALERT_KEYS.application.documentChangedToNotRead]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=documents`,
  },
  [ALERT_KEYS.application.documentChangedToNeedMoreInfo]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=documents`,
  },
  [ALERT_KEYS.application.documentUploaded]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=documents`,
  },
  [ALERT_KEYS.application.changedToIncomplete]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.application.changedToSubmittedForApproval]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.application.changedToApproved]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.application.changedToApprovedAndDisbursementDateSet]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.application.changedToDenied]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.application.newSubmission]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.committed]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.sentBackForReview]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.userRequestedAccess]: {
    actionTitle: 'Go to Users',
    actionLocation: '/users?tab=manageRequests',
  },
  [ALERT_KEYS.lenderExpressedInterest]: {
    actionTitle: 'Go to Lenders',
    actionLocation: '/lenders?tab=expressed-interest',
  },
  [ALERT_KEYS.vcExpressedInterest]: {
    actionTitle: 'Go to VC',
    actionLocation: '/vc?tab=expressed-interest',
  },
  [ALERT_KEYS.businessExpressedInterest]: {
    actionTitle: 'Go to Business',
    actionLocation: '/business?tab=expressed-interest',
  },
  [ALERT_KEYS.lenderConfirmDisbursementReminder]: {
    actionLocation: '/',
  },
  [ALERT_KEYS.edoConfirmDisbursementReminder]: {
    actionLocation: '/',
  },
  [ALERT_KEYS.application.liquidationAmountSent]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=liquidation`,
  },
  [ALERT_KEYS.application.proceedsCalculated]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.proceedsSent]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.edoWatchableApplicationWatchingChanged]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.application.edoWatchableApplicationNonPerformingChanged]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.lenderProgramRequestApproved]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.lenderProgramRequestIncomplete]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.lenderProgramRequestDenied]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.edoAddedNewCreditProgram]: {
    actionTitle: 'Go to Program',
    actionLocation: (id) => `/program-requests/all-programs/${id}`,
  },
  [ALERT_KEYS.fundingProgram.edoAddedNewEquityProgram]: {
    actionTitle: 'Go to Program',
    actionLocation: (id) => `/programs/${id}`,
  },
  [ALERT_KEYS.programRequest.lenderRequestedAccessToCreditProgram]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/lenders/program-requests/${id}`,
  },
  [ALERT_KEYS.programRequest.vcRequestedAccessToCreditProgram]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/vc/program-requests/${id}`,
  },
  [ALERT_KEYS.fundingProgram.vcProgramRequestApproved]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.vcProgramRequestIncomplete]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.vcProgramRequestDenied]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/lenders/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.lenderProgramRequestSendBack]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/vc/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.fundingProgram.vcProgramRequestSendBack]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.note.edoAddedNoteToProgramRequest]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.note.lenderAddedNoteToProgramRequest]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/lenders/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.note.vcAddedNoteToProgramRequest]: {
    actionTitle: 'Go to Program Request',
    actionLocation: (id) => `/vc/program-requests/${id}?tab=status`,
  },
  [ALERT_KEYS.note.edoAddedNoteToApplication]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.note.lenderAddedNoteToApplication]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=status`,
  },
  [ALERT_KEYS.programRequest.vcRequestedCapitalCall]: {
    // actionTitle: 'Go to application',
    // actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.lenderConfirmedReceivedDisbursement]: {
    // actionTitle: 'Go to Lenders',
    // actionLocation: '/lender',
  },
  [ALERT_KEYS.fundingProgram.notifyBusinessLenderProgramRequestApproved]: {
    actionTitle: 'Go to Lender',
    actionLocation: (id) => `/lender/${id}`,
  },
  [ALERT_KEYS.fundingProgram.notifyBusinessVcProgramRequestApproved]: {
    actionTitle: 'Go to VC',
    actionLocation: (id) => `/vc/${id}`,
  },
  [ALERT_KEYS.application.lenderCreatedLossClaim]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.edoApprovedLossClaim]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}`,
  },
  [ALERT_KEYS.application.updatedDisbursementConfig]: {
    actionTitle: 'Go to application',
    actionLocation: (id) => `/portfolio/${id}?tab=approvalChain`,
  },
  [ALERT_KEYS.edoSharedBusiness]: {
    actionTitle: 'See Business',
    actionLocation: (id) => console.log('id', id) || `/businesses/${id}`,
  },
  [ALERT_KEYS.fund.businessSubmittedFundRequest]: {
    actionTitle: 'View Fund',
    actionLocation: (id) => `/funds/${id}?tab=portfolioCompanyRequests`,
  },
  [ALERT_KEYS.fundApplication.documentApproved]: {
    actionTitle: 'View Fund Application',
    actionLocation: (id) => `/funds/${id}`,
  },
  [ALERT_KEYS.fundApplication.documentPending]: {
    actionTitle: 'View Fund Application',
    actionLocation: (id) => `/funds/${id}`,
  },
  [ALERT_KEYS.fundApplication.documentResubmission]: {
    actionTitle: 'View Fund Application',
    actionLocation: (id) => `/funds/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallDocumentApproved]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallDocumentPending]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallDocumentResubmission]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallApproved]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallDenied]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallPending]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallFunded]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.capitalCallSubmittedForApproval]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.confirmFundsReceived]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-call-portion/${id}`,
  },
  [ALERT_KEYS.capitalCall.overdueFundsNotYetReceived]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.edoUpdatedTransactionTerms]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCall.vcUpdatedTransactionTerms]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.fundApplication.vcUpdatedTransactionTerms]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCallPortion.overdueFundsNotYetReceived]: {
    actionTitle: 'Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCallPortion.confirmFundsReceived]: {
    actionTitle: 'Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
  [ALERT_KEYS.capitalCallPortion.fundsAwarded]: {
    actionTitle: 'Capital Call',
    actionLocation: (id) => `/capital-call-portion/${id}`,
  },
  [ALERT_KEYS.application.edoAddedTechnicalAssistance]: {
    actionTitle: 'Technical Assistance',
    actionLocation: () => '/applications',
  },
  [ALERT_KEYS.capitalCall.capitalCallCreated]: {
    actionTitle: 'View Capital Call',
    actionLocation: (id) => `/capital-calls/${id}`,
  },
};

// Can probably file for deletion
export const DASHBOARD_DEMOGRAPHICS_LABELS = [
  'Food Services',
  'Hospitality',
  'Construction',
  'Health Care and Social Assistance',
  'Educational Services',
  'Technology',
];

export const INDUSTRY_TYPES_BY_NAICS_CODE = {
  '11': 'Agriculture, Forestry, Fishing and Hunting',
  '21': 'Mining, Quarrying, and Oil and Gas Extraction',
  '22': 'Utilities',
  '23': 'Construction',
  '31': 'Manufacturing',
  '32': 'Manufacturing',
  '33': 'Manufacturing',
  '42': 'Wholesale Trade',
  '44': 'Retail Trade',
  '45': 'Retail Trade',
  '48': 'Transportation and Warehousing',
  '49': 'Transportation and Warehousing',
  '51': 'Information',
  '52': 'Finance and Insurance',
  '53': 'Real Estate and Rental and Leasing',
  '54': 'Professional, Scientific, and Technical Services',
  '55': 'Management of Companies and Enterprises',
  '56': 'Administrative and Support and Waste Management and Remediation Services',
  '61': 'Educational Services',
  '62': 'Health Care and Social Assistance',
  '71': 'Arts, Entertainment, and Recreation',
  '72': 'Accommodation and Food Services',
  '81': 'Other Services (except Public Administration)',
  '92': 'Public Administration',
};

export const INDUSTRY_LIST = [
  'Hospitality',
  'Food Services',
  'Construction',
  'Retail Trade',
  'Health Care and Social Assistance',
  'Educational Services',
  'Wholesale Trade',
  'Agriculture, Forestry, Fishing, and Hunting',
  'Manufacturing',
  'Real Estate',
  'Arts and Entertainment',
  'Mining, Quarrying, and Oil and Gas Extraction',
  'Outdoor Recreation',
  'Transportation and Warehousing',
  'Professional Services',
  'Technology',
];

export const COLORS = {
  PRIMARY: '#29649b',
  SECONDARY: '#636971',
  SUCCESS: '#4D9E41',
  WARNING: '#F0C428',
  INFO: '#4B92AE',
  DANGER: '#BE403F',
  ORANGE: '#D57921',
  DARK: '#343a40',
  allocated: '#135466',
  obligated: '#345499',
  expended: '#5554CC',
};

export const YEARS_BUSINESS_OPENED_RANGES = [
  '1990-1994',
  '1995-1999',
  '2000-2004',
  '2005-2009',
  '2010-2014',
  '2015-2019',
  '2020-present',
];

export const LOAN_TERM_RANGES = [
  '0-1',
  '2-3',
  '4-5',
  '5-6',
  '6-7',
  '8-9',
  '10+',
];

export const PORTFOLIO_COMPANY_STATUS = {
  FUNDED: 'FUNDED',
  COMMITTED: 'COMMITTED',
  UNFUNDED: 'UNFUNDED',
};

export const SAVED_SEARCH_TIME = {
  '12AM': 0,
  '1AM': 1,
  '2AM': 2,
  '3AM': 3,
  '4AM': 4,
  '5AM': 5,
  '6AM': 6,
  '7AM': 7,
  '8AM': 8,
  '9AM': 9,
  '10AM': 10,
  '11AM': 11,
  '12PM': 12,
  '1PM': 13,
  '2PM': 14,
  '3PM': 15,
  '4PM': 16,
  '5PM': 17,
  '6PM': 18,
  '7PM': 19,
  '8PM': 20,
  '9PM': 21,
  '10PM': 22,
  '11PM': 23,
};

export const DAYS_OF_THE_WEEK = {
  sundayEnabled: 'Sunday',
  mondayEnabled: 'Monday',
  tuesdayEnabled: 'Tuesday',
  wednesdayEnabled: 'Wednesday',
  thursdayEnabled: 'Thursday',
  fridayEnabled: 'Friday',
  saturdayEnabled: 'Saturday',
};

export const FUND_STATUS = {
  approved: 'APPROVED',
  incomplete: 'INCOMPLETE',
  denied: 'DENIED',
  submittedForApproval: 'SUBMITTED_FOR_APPROVAL',
};

export const DATA_ROOM_COMPONENT_VIEW_TYPES = {
  dataRoom: 'DATA_ROOM',
  upload: 'UPLOAD',
  updateStatus: 'UPDATE_STATUS',
  viewOnly: 'VIEW_ONLY',
};

export const STAGES_OF_INVESTMENT = {
  preSeed:
    'Pre-Seed - developing technology or business model for product/service',
  seed: 'Seed - proof-of-concept and developed business model',
  earlyStage: 'Early Stage - product/service launch and market traction',
  laterStage: 'Later Stage - scale-up operations and growing revenue',
  growthEquity: 'Growth Equity - mature business seeking growth opportunities',
  other: 'Other',
};

export const SECURITY_TYPE = {
  commonStock: 'Common stock',
  preferredStock: 'Preferred stock',
  convertibleDebt:
    'Convertible debt (debt with automatic conversion to equity in a qualified priced round)',
  standardAgreement:
    'Standard agreement for future equity (SAFE) or other unpriced equity-like securities',
  other: 'Other',
};

export const SSBCI_OWNERSHIP_PERCENTAGE = {
  '0-to-5': '0% to 5%',
  '5-to-10': '5% to 10%',
  '10-to-20': '10% to 20%',
  '20-to-50': '20% to 50%',
  '50-to-greater': 'Greater than 50%',
};

export const TYPE_OF_VALUATION_CAP = {
  preMoney: 'Pre-money',
  postMoney: 'Post-money',
};
