import { Alert, AlertHeading } from 'react-bootstrap';
import HTMLRenderer from '../HTMLRenderer';
import { ensureHttpsInHrefs } from 'libs/string';
import $technicalAssistance from './technicalAssistance.signal';

const TechnicalAssistance = () => {
  const { technicalAssistance } = $technicalAssistance.value;

  return (
    <Alert className={`technical-assistance-${technicalAssistance.type}`}>
      <AlertHeading>{technicalAssistance.title}</AlertHeading>
      <HTMLRenderer
        className="mb-0"
        htmlString={ensureHttpsInHrefs(technicalAssistance.supportingText)}
      />
    </Alert>
  );
}


export default TechnicalAssistance;
