/* eslint-disable react/display-name */
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { COI_CHECKLIST_CONTAINER_CLASS } from '../CreateCapitalCall/createCapitalCall.helpers';
import userAccountSignal from 'signals/UserAccount.signal';
import { Button } from 'react-bootstrap';

const SectionWithInitials = ({
  children,
  onChange,
  index,
  value,
  onAutofill = null,
  defaultFocused = false,
}) => (
  <div className="mb-16 align-items-center">
    <input
      className="initalsInput"
      style={{
        display: 'inline-block',
        maxWidth: '60px',
        marginRight: '4px',
      }}
      onChange={(e) => onChange(e.target.value, index)}
      value={value}
      autoFocus={defaultFocused}
    />
    <Button hidden={!onAutofill} size="sm" className="mr-16 py-0" onClick={onAutofill}>Auto Fill</Button>
    <span>{children}</span>
  </div>
);

const VCConflictOfInterestChecklist = ({ business }) => {
  const [fullname, setFullname] = useState('');
  const [initialsValues, setInitialsValues] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
  });

  const handleInitialsValuesChange = (value, index) => {
    setInitialsValues({
      ...initialsValues,
      [index]: value,
    });
  };

  const handleAutofill = () => {
    const autofilledInitials = {};
    Object.keys(initialsValues).forEach(
      (index) =>
        (autofilledInitials[
          index
        ] = initialsValues[1])
    );
    setInitialsValues(autofilledInitials);
  };

  useEffect(() => {
    handleInitialsValuesChange(
      `${userAccountSignal.value.userData.firstName
        .slice(0, 1)
        .toUpperCase()}${userAccountSignal.value.userData.lastName
        .slice(0, 1)
        .toUpperCase()}`,
      1
    );
  }, []);

  return (
    <div className={COI_CHECKLIST_CONTAINER_CLASS}>
      <h4 className="text-center mb-24">
        Venture Capital Programs: Draft Conflict-of-Interest Standards
      </h4>
      <p>
        <b>Purpose:</b> <br /> To ensure compliance with U.S. Department of
        Treasury State Small Business Credit Initiative (SSBCI) Guidance Section
        VIII.f page 27, 12 U.S.C. § 5705(f)(2), Venture Capital Programs:
        Conflict of Interest Standards, <b>{business.name}</b> has developed
        this Venture Capital Programs: Draft Conflict of Interest Standards.
      </p>
      <p>
        Please thoroughly review this document, initial each blank and attest by
        signature to the following checklist:{' '}
      </p>
      <SectionWithInitials
        index={1}
        value={initialsValues[1]}
        onChange={handleInitialsValuesChange}
        onAutofill={handleAutofill}
      >
        I acknowledge my understanding that funds from this SSBCI venture
        capital program MUST NOT be used to make an investment in a business in
        which an SSBCI insider has a personal financial interest.
      </SectionWithInitials>
      <p>
        The following definitions apply to the conflict-of-interest standards
        for venture capital programs:{' '}
      </p>
      <SectionWithInitials
        index={2}
        value={initialsValues[2]}
        onChange={handleInitialsValuesChange}
      >
        I acknowledge that I am not an “SSBCI insider”. I understand that a
        SSBCI insider of an SSBCI venture capital program is a person who, in
        the 12-month period preceding the date on which SSBCI support for a
        specific investment in a venture capital fund or company is closed or
        completed and that person was a:
      </SectionWithInitials>
      <ul>
        <li>
          a manager or staff member, whether by employment or contract, in the
          state’s SSBCI venture capital program{' '}
        </li>
        <li>
          a government official with direct oversight or jurisdiction over an
          SSBCI venture capital program, or such an official’s immediate
          supervisor
        </li>
        <li>
          {' '}
          a member of the board of directors or similar body for a
          state-sponsored non profit entity who, through such membership, has
          authority to vote on decisions to invest SSBCI funds or has authority
          over the employment or compensation of staff managing processes
          related to the investment of SSBCI funds{' '}
        </li>
        <li>
          a member of the board of directors or similar body for an independent
          non-profit or for-profit entity that operates an SSBCI venture capital
          program <br />
          Or <br />
        </li>
        <li>
          an employee, volunteer, or contractor on an investment committee or
          similar body that recommends or approves SSBCI investments under the
          SSBCI venture capital program <br />
          Or <br />
        </li>
        <li>
          <p className="m-0 p-0">
            Exercised a controlling influence on state decisions regarding:
          </p>
          <ul>
            <li>
              The allocation of SSBCI funds among approved state venture capital
              programs{' '}
            </li>
            <li>
              Eligibility criteria for the state’s SSBCI venture capital
              programs;
            </li>
            <li>
              or The processes for approving investments of SSBCI funds under
              the state’s SSBCI venture capital program.
            </li>
          </ul>
        </li>{' '}
      </ul>
      <SectionWithInitials
        index={3}
        value={initialsValues[3]}
        onChange={handleInitialsValuesChange}
      >
        I acknowledge that I am not a “business partner” of an SSBCI insider and
        that I do not own 10% or more of any class of equity interest, on a
        fully diluted basis, in any private entity in which an SSBCI insider
        also owns 10 percent or more of any class of equity interest on a fully
        diluted basis.{' '}
      </SectionWithInitials>
      <SectionWithInitials
        index={4}
        value={initialsValues[4]}
        onChange={handleInitialsValuesChange}
      >
        I acknowledge that I am not a “family member” of an SSBCI insider
        including:{' '}
        <ul>
          <li>
            Such person’s spouse, domestic partner, parents, grandparents,
            children, grandchildren, brothers, sisters, stepbrothers, and
            stepsisters;{' '}
          </li>
          <li>
            Any other relatives who live in the same household as the SSBCI
            insider.
          </li>
        </ul>{' '}
      </SectionWithInitials>
      <SectionWithInitials
        index={5}
        value={initialsValues[5]}
        onChange={handleInitialsValuesChange}
      >
        I acknowledge that I am not an “independent non-profit entity” or a
        state sponsored non-profit entity.{' '}
      </SectionWithInitials>
      <SectionWithInitials
        index={6}
        value={initialsValues[6]}
        onChange={handleInitialsValuesChange}
      >
        I acknowledge that I do not have a “personal financial interest” in this
        SSBCI. Personal financial interest is defined as any financial interest
        derived from ownership or right to ownership of, or lending to or other
        investment in, a private, for-profit entity that may receive an SSBCI
        investment (including any financial interest derived from ownership or
        right to ownership of, or investment in, a venture capital fund).{' '}
      </SectionWithInitials>
      <SectionWithInitials
        index={7}
        value={initialsValues[7]}
        onChange={handleInitialsValuesChange}
      >
        I acknowledge that I am not a “state-sponsored non-profit entity”
        defined as a non-profit entity created by state legislation to pursue
        policies of the state government and over which state officials exercise
        a controlling influence through budgetary decisions or other legislative
        action or direction.
      </SectionWithInitials>{' '}
      <p>
        <b>General Prohibition:</b> <br />
        Subject to the exceptions described below, SSBCI funds may not be used
        by SSBCI venture capital programs to make or support investments in a
        company or venture capital fund if an SSBCI insider, or a family member
        or business partner of an SSBCI insider, has a personal financial
        interest in the company or venture capital fund. A prohibited conflict
        of interest is deemed to exist even if the conflict is disclosed, or the
        relevant individuals recuse themselves from participating in the
        investment. Further, accepting a role as an SSBCI insider does not
        require a person to divest financial interests in a company or venture
        capital fund resulting from previous employment or personal investment
        activity. However, if a person is an SSBCI insider, any company or
        venture capital fund in which the insider has a personal financial
        interest is prohibited from receiving investments or financial support
        from SSBCI funds.
      </p>
      <p>Exceptions to the general prohibition are as follows: </p>
      <ul>
        <li>
          A governmental entity or a state-sponsored non-profit entity may use
          SSBCI funds for follow-on investments in companies or venture capital
          funds if the entity has an existing ownership or voting interest
          resulting from a prior investment of SSBCI funds or non-SSBCI funds.
          Furthermore, in this circumstance, the entity may authorize
          investments if an SSBCI insider serves on the board of directors of
          the company or venture capital fund, if an SSBCI insider does not have
          a personal financial interest in the company or venture capital fund
          and the entity’s prior financial interest is in compliance with all
          applicable state laws and rules.{' '}
        </li>
        <li>
          An independent non-profit or for-profit entity managing or investing
          SSBCI funds for an SSBCI venture capital program is not precluded from
          authorizing follow-on investments using SSBCI funds in a company or
          venture capital fund in which the entity previously invested SSBCI
          funds or the entity has previously appointed a representative to serve
          on the board of directors in stewardship of the investment. However,
          such independent non-profit or for-profit entity may not authorize (or
          seek approval from the participating state for) an investment of SSBCI
          funds in a company or venture capital fund in which the entity holds
          any type of financial interest resulting from an investment made with
          non-SSBCI funds.
        </li>
      </ul>{' '}
      <p>
        I certify my complete understanding of the Venture Capital Programs:
        Draft Conflict-of Interest Standards. I also acknowledge my ability to
        seek legal council to ensure that complete understanding.
      </p>
      <div className="mb-16">Date: {DateTime.now().toISODate()}</div>
      <div>
        Name:
        <input
          style={{
            display: 'inline-block',
            maxWidth: '240px',
            marginLeft: '4px',
          }}
          onChange={(e) => setFullname(e.target.value)}
          value={fullname}
        />
      </div>
    </div>
  );
};

export default VCConflictOfInterestChecklist;
