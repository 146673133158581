import applicationDetailSignal, {
  ApplicationDetailType,
} from '../ApplicationDetail/ApplicationDetail.signal';
import { Signal } from '@preact/signals-react';
import userAccountSignal from '../../../signals/UserAccount.signal';
import { setAndFetchEdoApplications } from '../../views/Edo/EdoPortfolio/EdoPortfolio.helpers';
import { fetchAndSetApplications } from '../Applications/Applications.helpers';
import { fetchApplication } from '../ApplicationDetail/ApplicationDetail.helpers';
import edoVCProgramRequestsSignal from '../../../signals/EdoVCProgramRequest.signal';
import { fetchEdoVCProgramRequests } from '../../../api/vc.api';
import {
  ApplicationDocument,
  FundingProgram,
  FundingProgramDocument,
  ProgramRequest,
  ProgramRequestDocument,
} from '../../../types';
import { fetchEdoProgramRequests } from '../../../api/lender.api';
import edoProgramRequestsSignal from '../../../signals/EdoProgramRequests.signal';
import { fetchAndSetProgramRequests } from '../Programs/Programs.helpers';
import { lenderProgramRequestsSignal } from '../../../signals/LenderPrograms.signal';
import { txProgramRequestToApplicationDetailsModal } from '../ProgramRequestDetail/ProgramRequestDetail.helpers';
import history from '../../../history';
import { applicationDraftSignal } from 'signals/SubmitApplication.signal';
import $appSettings from 'signals/AppSettings.signal';

export const refreshListAndModal = async (
  activeId: number,
  modelType: ApplicationDetailType['modelType'],
  ventureCapitalist: boolean
): Promise<Signal<ApplicationDetailType> | undefined> => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  let programRequests: any = [];
  if (modelType === 'programRequest') {
    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo
    ) {
      if (ventureCapitalist) {
        const vcProgramRequests = await fetchEdoVCProgramRequests();
        edoVCProgramRequestsSignal.update({
          programRequests: vcProgramRequests,
        });
        programRequests = edoVCProgramRequestsSignal.value
          .programRequests as unknown as ProgramRequest[];
      } else {
        const lenderProgramRequests = await fetchEdoProgramRequests();
        edoProgramRequestsSignal.update({
          programRequests: lenderProgramRequests,
        });
        programRequests = edoProgramRequestsSignal.value
          .programRequests as unknown as ProgramRequest[];
      }
    } else {
      await fetchAndSetProgramRequests();
      programRequests =
        lenderProgramRequestsSignal.value as unknown as ProgramRequest[];
    }

    const programRequest = programRequests.find(
      (programRequest: ProgramRequest) => programRequest.id === activeId
    )!;

    return applicationDetailSignal.update(
      txProgramRequestToApplicationDetailsModal(
        programRequest,
        !!programRequest?.ventureCapitalFirm
      )
    );
  } else {
    if (
      userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo
    ) {
      await setAndFetchEdoApplications();
    } else {
      await fetchAndSetApplications();
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await fetchApplication(activeId);
  }
};
export const getModelRoute = (
  modelType: ApplicationDetailType['modelType']
): string | undefined => {
  if (modelType === 'application') {
    return '/applications';
  }

  if (modelType === 'programRequest') {
    return '/programRequests';
  }
};

export const getNotesRoute = (
  modelType: ApplicationDetailType['modelType'],
  id: number | undefined | null = undefined
): string | undefined => {
  if (modelType === 'application') {
    return `/applications/${id}/notes`;
  }

  if (modelType === 'programRequest') {
    return '/programRequestNotes';
  }
};

export const getFundingProgramTemplates = (
  fundingProgram: FundingProgram | null | undefined,
  documentType: string
): FundingProgramDocument[] | undefined =>
  fundingProgram?.supportingDocuments.filter(
    (d) => d.documentType === documentType
  );
export const getFundingProgramSuggestedDocuments = (
  fundingProgram: FundingProgram | null | undefined,
  documentType: string
): FundingProgramDocument[] | undefined =>
  fundingProgram?.supportingDocuments.filter(
    (d) => d.documentType === documentType
  );

export const templateExists = (
  documents: Array<ApplicationDocument | ProgramRequestDocument>,
  fundingProgramTemplates: FundingProgramDocument[] | undefined
): boolean =>
  documents.some((doc) =>
    fundingProgramTemplates?.some((d) => doc.fundingProgramDocumentId === d.id)
  );

export function grabIdentifierFromApprovableDocument(
  document: ApplicationDocument | ProgramRequestDocument
): string | number {
  if ((document as ApplicationDocument).uuid) {
    return (document as ApplicationDocument).uuid;
  }

  return (document as ProgramRequestDocument).id;
}

export const handleAttachOCRDocClick = (id: number | null): void => {
  applicationDraftSignal.update({ draftPendingRequest: { id } });
  return history.push('/lender/portfolio/submit-applications');
};
