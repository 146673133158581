/* eslint-disable no-unused-vars */
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {
  getPageNumbers,
} from '../StateDashboard.helpers';

const AdminApplicationStateTable = ({ statesData }) => {
  const [page, setPage] = useState(0);
  const [progressBarMax, setProgressBarMax] = useState(0);
  const recordsPerPage = 4;
  const stateNames = Object.keys(statesData);
  const totalNumOfPages = Math.ceil(stateNames.length / recordsPerPage);

  const getRows = () => stateNames.filter((stateName, idx) => {
    const start = page * recordsPerPage;
    const end = (page + 1) /* 2 */ * recordsPerPage; /* 5 */ //= 10
    return idx >= start && idx < end;
  }).map((stateName) => {
    const stateValue = statesData[stateName];
    if (stateValue > progressBarMax) {
      setProgressBarMax(prev => prev + stateValue);
    }
    return (
      <>
        <Row key={stateName} className="mb-8">
          <Col>
            <ApplicationStateProgress statesData={{ name: stateName, value: stateValue }} max={progressBarMax} />
          </Col>
        </Row>
      </>
    );
  });

  return (
    <>
      {
        getRows()
      }
      <Row className="justify-content-center align-items-center">
        {
          getPageNumbers(totalNumOfPages, page).map((pageNumber) => (
            <Col sm={2} key={pageNumber.toString()}>
              <Button variant={page === pageNumber ? 'primary' : 'white'} onClick={() => setPage(pageNumber)}>{pageNumber + 1}</Button>
            </Col>
          ))
        }
        {
          !(page < totalNumOfPages && page > totalNumOfPages - 3)
          && <Col sm={2}><Button variant="white">...</Button></Col>
        }
      </Row>
    </>

  );
};

const ApplicationStateProgress = ({ statesData, max }) => (
  <>
    <div className="d-flex">
      <p>{statesData.name} <Link to="/"><FontAwesomeIcon icon={faChevronRight} /></Link></p>
      <p className="ml-auto">{statesData.value}</p>
    </div>
    <ProgressBar now={statesData.value} max={max} />
  </>
);

export default AdminApplicationStateTable;
