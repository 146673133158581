import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import stateProgramsSignal from 'signals/StatePrograms.signal';

export const fetchAndSetStateProgramsData = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const fundingPrograms = await api.get({
      path: '/fundingPrograms',
      options: {
        orderBy: [
          {
            id: 'desc',
          },
        ],
        include: {
          program: {
            include: {
              programType: true,
            },
          },
        },
      },
    });
    return stateProgramsSignal.update({ fundingPrograms });
  } catch (error) {
    return alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getFundingProgramField = ({ fundingProgram, key }) => {
  const programFieldMap = {
    address: {
      label: 'Address',
      value: fundingProgram.address,
    },
    amount: {
      label: 'Amount',
      value: fundingProgram.amount,
    },
    assistanceTypes: {
      label: 'Assistance Types',
      value: fundingProgram.assistanceTypes,
    },
    assumedLoss: {
      label: 'Assumed Loss',
      value: fundingProgram.assumedLoss,
    },
    benefits: {
      label: 'Benefits',
      value: fundingProgram.benefits,
    },
    cashReserve: {
      label: 'Cash Reserve',
      value: fundingProgram.cashReserve,
    },
    ceiling: {
      label: 'Ceiling',
      value: fundingProgram.ceiling,
    },
    complianceCheck: {
      label: 'Compliance Check',
      value: fundingProgram.complianceCheck,
    },
    eligibility: {
      label: 'Eligibility',
      value: fundingProgram.eligibility,
    },
    fundsAlreadyAllocated: {
      label: 'Funds Already Allocated',
      value: fundingProgram.fundsAlreadyAllocated,
    },
    fundsStartUps: {
      label: 'Funds StartUps',
      value: fundingProgram.fundsStartUps,
    },
    lenderTypes: {
      label: 'Lender Types',
      value: fundingProgram.lenderTypes,
    },
    loanGuaranty: {
      label: 'Loan Guaranty',
      value: fundingProgram.loanGuaranty,
    },
    loanTenure: {
      label: 'Loan Tenure',
      value: fundingProgram.loanTenure,
    },
    name: {
      label: 'Main Program Name',
      value: fundingProgram.name,
    },
    maximumLeverageRatio: {
      label: 'Maximum Leverage Ratio',
      value: fundingProgram.maximumLeverageRatio,
    },
    multiple: {
      label: 'Multiple',
      value: fundingProgram.multiple,
    },
    overview: {
      label: 'Overview',
      value: fundingProgram.overview,
    },
    portfolioPercentage: {
      label: 'Portfolio Percentage',
      value: fundingProgram.portfolioPercentage,
    },
    programApplication: {
      label: 'Program Application',
      value: fundingProgram.programApplication,
    },
    programId: {
      label: 'Program Id',
      value: fundingProgram.programId,
    },
    recycling: {
      label: 'Recycling',
      value: fundingProgram.recycling,
    },
    requiresBusinessPlan: {
      label: 'Requires Business Plan',
      value: fundingProgram.requiresBusinessPlan,
    },
    serviceTypes: {
      label: 'Service Types',
      value: fundingProgram.serviceTypes,
    },
    timeHorizon: {
      label: 'Time Horizon',
      value: fundingProgram.timeHorizon,
    },
    website: {
      label: 'Website',
      value: fundingProgram.website,
    },
  };
  return programFieldMap[key];
};
