import { Button, Modal } from 'react-bootstrap';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import CloseModalButton from '../Constant/CloseModalButton';
import TechnicalAssistance from './TechnicalAssistance';
import { handleCloseTechnicalAssistanceModal } from './technicalAssistance.helpers';
import $technicalAssistance from './technicalAssistance.signal';

const TechnicalAssistanceModal = () => {
  const { technicalAssistance } = $technicalAssistance.value;

  if (!technicalAssistance) {
    return null;
  }

  return (
    <ModalWrapper centered show={!!technicalAssistance} size="lg" onHide={handleCloseTechnicalAssistanceModal}>
      <Modal.Header>
        <h5>Techical Assistance</h5>
        <CloseModalButton onCloseModal={handleCloseTechnicalAssistanceModal} />
      </Modal.Header>
      <Modal.Body>
        <TechnicalAssistance />
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          onClick={handleCloseTechnicalAssistanceModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default TechnicalAssistanceModal;
