import { emailRegex } from "components/global/Constant/regularExpression";

const validateEmailUtil = (email) => {
  if (!email || !emailRegex.test(email)) {
    return false;
  } else {
    const domain = `@${email.split('@')[1]}`;
    const tld = domain.split('.').pop();
    if ((domain.match(new RegExp(`\\@${tld}\\.`, 'g')) || []).length) { // if TLD occurs right after @
      return false;
    }

    const tldCount = (domain.match(new RegExp(`\\.${tld}`, 'g')) || []).length;

    if (tldCount > 1) {
      return false;
    } else {
      return true;
    }
  }
}

export default validateEmailUtil;
