import { useCallback, useEffect } from "react";
import { fetchAndSetVcFundsData } from "./Funds.helpers";
import FundDetailsModal from "../../views/VC/VCFunds/FundDetailsModal";
import { Button, Col, Row } from "react-bootstrap";
import SimpleTableFilterDropdown from "../SimpleTableFilterDropdown";
import SimpleTable from "../SimpleTable";
import { fundsSignals } from "./Funds.signals";
import { useHistory } from "react-router";
import userAccountSignal from "../../../signals/UserAccount.signal";
import dateOptions from "../Constant/dateOptions";
import NoFundingProgramsAlert from "./components/NoFundingProgramsAlert";
import { DateTime } from "luxon";
import $appSettings from "signals/AppSettings.signal";
import formatMoney from "../../../utils/formatMoney";
import { capitalCallsTotalAmount } from "../../../libs/capitalCalls";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'fundName',
    title: 'Fund Name',
    sortable: true,
    bold: true,
  },
  {
    key: 'createdDate',
    title: 'Created Date',
    sortable: true,
  },
  {
    key: 'maxFundSize',
    title: 'Fund Size',
    sortable: true,
  },
  {
    key: 'remaining',
    title: 'Remaining',
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'vcPortfolioCompany',
    title: 'Portfolio Companies',
  },
  {
    key: 'capitalCall',
    title: 'Capital Calls',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FILTERS = [
  {
    type: 'text',
    label: 'Fund Name',
    key: 'fundName',
  },
  {
    type: 'integer',
    label: 'Fund Size',
    key: 'maxFundSize',
  },
  {
    type: 'text',
    label: 'Program Name',
    key: 'programName',
  },
];

const Funds = () => {
  const history = useHistory();
  const { funds, approvedProgramRequests } = fundsSignals.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const init = useCallback(() => {
    fetchAndSetVcFundsData();
  }, [history.location.search]);

  useEffect(() => {
    init();
  }, [init]);

  const isVc =
    userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.vc;

  return (
    <>
      {isVc && !approvedProgramRequests.length && <NoFundingProgramsAlert />}
      <Row className="bg-white">
        <Col>
          <div className="border border-gray p-16 shadow-sm">
            <h3>Funds</h3>
            <hr />
            <div className="d-flex flex-row justify-content-between mb-16">
              <SimpleTableFilterDropdown filters={FILTERS} />
              {isVc && (
                <div>
                  <Button
                    className="rounded mb-8"
                    onClick={() => history.push('/vc/funds/new')}
                  >
                    New Fund
                  </Button>
                </div>
              )}
            </div>
            <SimpleTable
              headers={HEADERS}
              onClick={() => {
                // if (!isVc) {
                //   return;
                // }
                //
                // const fund = funds[index];
                // handleDetailsOrCreateButtonClick(fund.id);
              }}
              rows={funds.map((fund) => ({
                id: fund.id,
                fundName: fund.fundName,
                createdDate: DateTime.fromISO(fund.createdDate).toFormat(
                  'DD, H:mm a'
                ),
                fundSize: fund.maxFundSize.toLocaleString(
                  'en-US',
                  dateOptions.currencyUSNoDecimal
                ),
                remaining: formatMoney(
                  fund.maxFundSize - capitalCallsTotalAmount(fund.capitalCalls)
                ),
                programName: fund.fundingProgramMembership.fundingProgram.name,
                vcPortfolioCompany:
                  fund.vcPortfolioCompanies.length === 0
                    ? '-'
                    : fund.vcPortfolioCompanies.length,
                capitalCall:
                  fund.capitalCalls.length === 0
                    ? '-'
                    : fund.capitalCalls.length,
                action: ({ key }) =>
                  isVc ? (
                    <td key={key} align="right">
                      <Button
                        variant="outline-dark"
                        size="sm"
                        className="rounded"
                        onClick={() => {
                          const pathname = history.location.pathname;
                          history.push(`${pathname}/${fund.id}`);
                        }}
                      >
                        Detail
                      </Button>
                    </td>
                  ) : (
                    <td key={key} align="right">
                      <Button
                        className="rounded"
                        variant="outline-dark"
                        size="sm"
                        onClick={() => {
                          const pathname = history.location.pathname;
                          history.push(`${pathname}/fund/${fund.id}`);
                        }}
                      >
                        Detail
                      </Button>
                    </td>
                  ),
              }))}
            />
          </div>
        </Col>
      </Row>
      <FundDetailsModal />
    </>
  );
};

export default Funds;
