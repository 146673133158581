import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';

export const fetchAndSetPlatforms = async (signal) => {
  loaderSignal.update({ isContentLoading: true });

  try {
    const platforms = await api.get({
      path: '/platforms',
      options: {
        include: {
          fundingPrograms: {
            include: {
              referenceProgram: true,
            },
          },
          tranches: true,
        },
      },
    });
    signal.update({ platforms });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handlePlatformSelection = (e, signal) => {
  const { value } = e.target;
  const { platforms } = signal.value;

  if (value === 'allPlatforms') {
    return signal.update({ selectedPlatform: 'allPlatforms' });
  }

  return signal.update({
    selectedPlatform: platforms.find((p) => p.id === Number(value)),
  });
};

export const handleNextClick = (signal, nextForm) => {
  const { selectedPlatform } = signal.value.selectedPlatform;
  if (selectedPlatform === 'allPlatforms') {
    return signal.update({
      form: nextForm,
      selectedPlatform: '',
    });
  }

  return signal.update({ form: nextForm });
};
