import api from 'api/api';
import AlertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import { fundsSignals } from 'components/global/Funds/Funds.signals';
import userAccountSignal from '../../../signals/UserAccount.signal';
import history from '../../../history';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetVcFundsData = async () => {
  loaderSignal.update({ isContentLoading: true });
  const { pathname } = history.location;
  let fundingProgramMembershipId = null;

  if (pathname.includes('program-membership')) {
    const pathSplit = pathname.split('/');
    fundingProgramMembershipId = pathSplit[pathSplit.length - 1];
  }

  try {
    const [funds, fundingProgramMembership] = await Promise.all([
      api.get({
        path: '/funds',
        options: {
          orderBy: xFormSortToOrderByClause(),
          where: xFormAppliedFiltersToWhereClause(),
          include: {
            stats: true,
            fundingProgramMembership: {
              include: {
                fundingProgram: {
                  include: {
                    stats: true,
                  },
                },
              },
            },
            vcPortfolioCompanies: {
              include: {
                business: {
                  include: {
                    businessDocuments: true,
                  },
                },
                fundApplication: {
                  include: {
                    documents: true,
                  },
                },
              },
            },
            capitalCalls: {
              include: {
                capitalCallPortions: true,
              },
            },
            documents: true,
          },
        },
      }),
      fundingProgramMembershipId
        ? api.get({
          path: '/fundingProgramMemberships',
          options: {
            where: {
              id: Number(fundingProgramMembershipId),
            },
            include: {
              fundingProgram: true,
              ventureCapitalFirm: true,
            },
          },
        })
        : null,
    ]);

    fundsSignals.update({ funds, fundingProgramMembership });
  } catch (error) {
    AlertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleFundDetailsChange = (e) => {
  const { name, value } = e;

  if (name === 'maxFundSize') {
    return fundsSignals.update({
      fundDetails: {
        ...fundsSignals.value.fundDetails,
        [name]: value,
      },
      maxFundSizeError: '',
    });
  }

  return fundsSignals.update({
    fundDetails: {
      ...fundsSignals.value.fundDetails,
      [name]: value,
    },
  });
};

export const fetchProgramRequests = async () => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  try {
    const programRequests = await api.get({
      path: '/programRequests',
      options: {
        where: {
          ventureCapitalFirmId:
            userAccountSignal.value.userData.account.ventureCapitalFirm.id,
        },
        include: {
          fundingProgram: true,
        },
      },
    });

    return fundsSignals.update({
      approvedProgramRequests: programRequests.filter(
        (pr) => pr.status === APPLICATION_STATUS.approved
      ),
    });
  } catch (error) {
  } finally {
  }
};

export const isMaxFundSizeValid = () => {
  const { fundDetails } = fundsSignals.value;
  const { maxFundSize } = fundDetails;

  if (maxFundSize <= 0) {
    return fundsSignals.update({
      maxFundSizeError: 'Please provide an amount greater than 0',
    });
  }

  fundsSignals.update({ maxFundSizeError: '' });
};

export const handleCapitalCallDetailsChange = (e) => {
  const { name, value } = e;
  if (name.slice(1) === 'portfolioCompany') {
    const newArr = fundsSignals.value.capitalCall.portfolioCompanies;
    newArr[name[0]] = Number(value);

    return fundsSignals.update({
      capitalCall: {
        ...fundsSignals.value.capitalCall,
        portfolioCompanies: newArr,
      },
      capitalCallAmountError: '',
    });
  }

  if (name === 'capitalCallAmount') {
    return fundsSignals.update({
      capitalCall: {
        ...fundsSignals.value.capitalCall,
        [name]: value,
      },
      capitalCallAmountError: '',
    });
  }

  return fundsSignals.update({
    capitalCall: {
      ...fundsSignals.value.capitalCall,
      [name]: value,
    },
  });
};

export const handlePlusButtonClick = () => {
  fundsSignals.update({
    capitalCall: {
      ...fundsSignals.value.capitalCall,
      portfolioCompanies: [
        ...fundsSignals.value.capitalCall.portfolioCompanies,
        '',
      ],
    },
  });
};

export const handleMinusButtonPush = (index) => {
  if (fundsSignals.value.capitalCall.portfolioCompanies.length > 1) {
    const newArr = fundsSignals.value.capitalCall.portfolioCompanies;
    newArr.splice(index, 1);

    fundsSignals.update({
      capitalCall: {
        ...fundsSignals.value.capitalCall,
        portfolioCompanies: newArr,
      },
    });
  }
};

export const isCapitalCallAmountValid = () => {
  const { capitalCallAmount } = fundsSignals.value.capitalCall;
  // Placeholder value
  const sumOfVcPortfolioInvestments = 2147483647;
  if (capitalCallAmount > sumOfVcPortfolioInvestments) {
    return fundsSignals.update({
      capitalCallAmountError:
        'The capital call amount cannot exceed 2147483647',
    });
  }

  return fundsSignals.update({ capitalCallAmountError: '' });
};

export const createCapitalCall = async () => {
  const { capitalCall } = fundsSignals.value;
  const { suggestedDate, fundId, capitalCallAmount, portfolioCompanies } =
    capitalCall;
  const vcPortfolioCompanies = portfolioCompanies.map((pcId) => ({ id: pcId }));
  loaderSignal.update({
    isContentLoading: true,
    message: 'Creating capital call...',
  });

  try {
    await api.post({
      path: '/capitalCalls',
      body: {
        data: {
          suggestedDate,
          fundId,
          capitalCallAmount: Number(capitalCallAmount),
          vcPortfolioCompanies: {
            connect: vcPortfolioCompanies,
          },
        },
      },
    });
    await fetchAndSetVcFundsData();
    fundsSignals.update({ capitalCallModalVisible: false });
    AlertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Capital call has been successfully created',
    });
  } catch (error) {
    AlertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const xFormSortToOrderByClause = () => {
  const { sort } = Object.fromEntries(
    new URLSearchParams(history.location.search).entries()
  );

  if (!sort) {
    return undefined;
  }

  const [key, dir] = sort.split('-');

  if (key === 'programName') {
    return [
      {
        fundingProgramMembership: {
          fundingProgram: {
            name: dir,
          },
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const { pathname, search } = history.location;

  const where = {
    fundingProgramMembership: {
      is: {},
    },
  };

  if (pathname.includes('program-membership')) {
    const pathSplit = pathname.split('/');
    const fundingProgramMembershipId = pathSplit[pathSplit.length - 1];
    where.fundingProgramMembership = {
      is: {
        id: Number(fundingProgramMembershipId),
      },
    };
  } else {
    where.fundingProgramMembership.is.ventureCapitalFirmId =
      userAccountSignal.value.userData.account.ventureCapitalFirm.id;
  }

  const params = Object.fromEntries(new URLSearchParams(search)) || {};

  if (params.programName) {
    where.fundingProgramMembership.is = {
      ...where.fundingProgramMembership.is,
      fundingProgram: {
        name: { contains: params.programName, mode: 'insensitive' },
      },
    };
  }

  if (params.fundName) {
    where.fundName = { contains: params.fundName, mode: 'insensitive' };
  }

  if (params.maxFundSize) {
    where.maxFundSize = Number(params.maxFundSize);
  }

  return where;
};
