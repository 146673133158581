import { Col, Container, Row } from 'react-bootstrap';
import $appSettings from 'signals/AppSettings.signal';
import dateOptions from 'components/global/Constant/dateOptions';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect } from 'react';
import {
  fetchAndSetSediPerformanceData,
  getLastTwelveMonths,
} from './EdoSedi.helpers';
import edoSediSignal from './EdoSedi.signals';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'SEDI Target and Performance',
    },
  },
};

const labels = getLastTwelveMonths();
export const dataBuilder = () => ({
  labels,
  datasets: [
    {
      label: 'SEDI Target',
      data: labels.map(
        () =>
          ($appSettings.value.appSettings.sediObjectives *
            $appSettings.value.appSettings.initialEligibleAmounts) /
          100
      ),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Expended',
      data: labels.map((month, index) => {
        const expendedRecords = edoSediSignal.value.expended;
        console.log('expendedRecords', expendedRecords);
        return (index + 6) * (index + 9) * 10000;
      }),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      label: 'Obligated',
      data: labels.map((month, index) => {
        const obligatedRecords = edoSediSignal.value.obligated;
        console.log('obligatedRecords', obligatedRecords);
        return (index + 12) * (index + 15) * 10000;
        // return obligatedRecords[month];
      }),
      borderColor: 'rgb(235, 162, 54)',
      backgroundColor: 'rgba(235, 162, 54, 0.5)',
    },
  ],
});

const EdoSedi = () => {
  const appSettings = $appSettings.value.appSettings;

  useEffect(() => {
    fetchAndSetSediPerformanceData();
  }, []);

  return (
    <Container fluid className="mb-32">
      <div className="bg-white px-32 py-24 border border-gray shadow-sm">
        <h3 className="text-center">SEDI Target and Performance</h3>
        <hr />
        <Row>
          <Col>
            <p>
              <b>Initial Eligible Amounts:</b>{' '}
              {appSettings.initialEligibleAmounts.toLocaleString(
                'en-US',
                dateOptions.currencyUSNoDecimal
              )}
            </p>
          </Col>
          <Col>
            <p>
              <b>SEDI Target:</b> {appSettings.sediObjectives}%
            </p>
          </Col>
          <Col>
            <p>
              <b>Target Amount: </b>{' '}
              {(
                (appSettings.sediObjectives / 100) *
                appSettings.initialEligibleAmounts
              ).toLocaleString('en-US', dateOptions.currencyUSNoDecimal)}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Line options={options} data={dataBuilder()} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default EdoSedi;
