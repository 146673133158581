import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

const DashboardButton = ({ title, description, faIcon, fullHeight = true }) => (
  <Card className={`text-center pt-4 ${fullHeight && 'h-100'}`}>
    <FontAwesomeIcon icon={faIcon} style={{ fontSize: 40 }} />
    <Card.Body>
      <Card.Title style={{ fontSize: 15 }}>{title}</Card.Title>
      <Card.Text style={{ fontSize: 10 }}>{description}</Card.Text>
    </Card.Body>
  </Card>
);

export default DashboardButton;
