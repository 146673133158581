import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import FundApplication from '../FundApplication';
import { DATA_ROOM_COMPONENT_VIEW_TYPES } from 'components/global/Constant/constants';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import fundApplicationSignal from '../FundApplication.signals';
import { handleCloseFundApplicationModal } from '../FundApplication.helpers';

const FundApplicationModal = ({  }) => {
  const { fundApplication } = fundApplicationSignal.value;

  if (!fundApplication) {
    return null;
  }

  return (
    <ModalWrapper
      centered
      size="xl"
      show={fundApplication}
      onHide={handleCloseFundApplicationModal}
    >
      <Modal.Header>
        <h3>{fundApplication?.vcPortfolioCompany?.business?.name}</h3>
        <CloseModalButton onCloseModal={handleCloseFundApplicationModal} />
      </Modal.Header>
      <Modal.Body>
        <Tabs>
          <Tab className="p-24" eventKey="fundApplication" title="Fund Application">
            <FundApplication
              isModalView
              viewType={DATA_ROOM_COMPONENT_VIEW_TYPES.viewOnly}
            />
          </Tab>
          <Tab className="p-24" eventKey="businessDetails" title="Business Details">
            <OrganizationDetails hideOrganizationName />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          onClick={handleCloseFundApplicationModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default FundApplicationModal;
