import { Fragment } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { ocrSignal } from './ocr.signal';
import {
  columnIsInput,
  displayHeader,
  formatOcrDataForSubmit,
  getCustomCategory,
  getCustomLabel,
  OCR_SELECT_OPTIONS,
  onOcrCheckboxChange,
  onOcrFieldChange,
  onOcrTableFieldChange,
  renderAdditionalText,
} from './ocr.helpers';
import { FieldComponent } from '../Field/Field';

const OCRRender = ({ readOnly = false }) => {
  const { parsedOcrData, sortedCategories, formattedOcrData } = ocrSignal.value;
  const changesAreSaved = Object.keys(formattedOcrData).length > 0;

  return (
    <>
      <Container
        className="border px-16 py-32 bg-white"
        style={{ maxHeight: 800, overflowY: 'scroll' }}
      >
        <Row>
          <Col>
            {sortedCategories.map(
              (category) => (
                  <Fragment key={category}>
                    <FieldComponent
                      type="section-header"
                      label={getCustomCategory(category)}
                      labelClassName={'mt-32'}
                    />
                    {parsedOcrData[category]?.sortedFieldNames?.map(
                      (fieldName) => {
                        const customLabel = getCustomLabel(fieldName);
                        if (
                          parsedOcrData[category][fieldName].type === 'table'
                        ) {
                          return (
                            <OCRTextInputTable
                              key={fieldName}
                              category={category}
                              fieldName={fieldName}
                              parsedOcrData={parsedOcrData}
                              readOnly={readOnly}
                              formattedOcrData={formattedOcrData}
                            />
                          );
                        } else {
                          return (
                            <div key={fieldName}>
                              {renderAdditionalText(fieldName)}
                              <FieldComponent
                                disabled={readOnly}
                                type={parsedOcrData[category][fieldName].type}
                                label={
                                  customLabel ||
                                  parsedOcrData[category][fieldName].label
                                }
                                value={
                                  readOnly
                                    ? formattedOcrData[fieldName]
                                    : parsedOcrData[category][fieldName].value
                                }
                                name={parsedOcrData[category][fieldName].name}
                                options={
                                  parsedOcrData[category][fieldName].options
                                }
                                inputClassName={
                                  parsedOcrData[category][fieldName].type ===
                                    'select' && 'form-control w-25'
                                }
                                className={`mt-16 ${parsedOcrData[category][fieldName].className}`}
                                tableData={
                                  parsedOcrData[category][fieldName].tableData
                                }
                                onChange={
                                  parsedOcrData[category][fieldName].type ===
                                  'checkbox'
                                    ? (e) => onOcrCheckboxChange(e, category)
                                    : (e) => onOcrFieldChange(e, category)
                                }
                              />
                            </div>
                          );
                        }
                      }
                    )}
                  </Fragment>
                )
            )}
          </Col>
        </Row>
      </Container>
      <div className="text-right mt-16" hidden={readOnly}>
        <Button
          className="sm rounded"
          disabled={changesAreSaved}
          onClick={() => formatOcrDataForSubmit()}
        >
          {changesAreSaved ? 'Saved!' : 'Confirm'}
        </Button>
      </div>
    </>
  );
};

const OCRTextInputTable = ({
  category,
  fieldName,
  parsedOcrData,
  readOnly,
  formattedOcrData,
}) => (
  <div className="border p-16 mt-16">
    <b>{parsedOcrData[category][fieldName].label}</b>
    <Table>
      {!parsedOcrData[category][fieldName].value?.headers?.every(
        (header) => header === 'Item' || header === 'Value'
      ) && (
        <thead>
          <tr>
            {parsedOcrData[category][fieldName].value?.headers?.map(
              (header) => {
                if (displayHeader(header)) {
                  return <th key={header}>{header}</th>;
                } else {
                  return <th key={header}> </th>;
                }
              }
            )}
          </tr>
        </thead>
      )}
      <tbody>
        {parsedOcrData[category][fieldName].value?.rows?.map(
          (rowObj, index) => (
            <tr key={`${fieldName}-${index}`}>
              {parsedOcrData[category][fieldName].value?.headers?.map(
                (header) => {
                  if (columnIsInput(header)) {
                    return (
                      <td key={`${header}-${index}`} style={{ minWidth: 100 }}>
                        <FieldComponent
                          disabled={readOnly}
                          inputClassName="form-control"
                          type={
                            fieldName.includes('requiredDocuments')
                              ? 'select'
                              : 'text-input'
                          }
                          labelClassName="d-none"
                          value={
                            readOnly
                              ? formattedOcrData[fieldName][index][header] || ''
                              : rowObj[header] || ''
                          }
                          options={
                            fieldName.includes('requiredDocuments')
                              ? OCR_SELECT_OPTIONS.requiredDocuments
                              : []
                          }
                          name={header}
                          onChange={(e) =>
                            onOcrTableFieldChange(e, category, fieldName, index)
                          }
                        />
                      </td>
                    );
                  } else {
                    return (
                      <td key={`${header}-${index}`}>{rowObj[header] || ''}</td>
                    );
                  }
                }
              )}
            </tr>
          )
        )}
      </tbody>
    </Table>
  </div>
);

export default OCRRender;
