import { useCallback, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { fetchAndSetFunds } from './businessFunds.helpers';
import BusinessFundsTable from './components/BusinessFundsTable';
import BusinessFundRequests from './components/BusinessFundRequests';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';

const BusinessFunds = () => {
  const init = useCallback(async () => await fetchAndSetFunds());

  useEffect(() => init(), [init]);

  return (
    <BreadCrumbWrapper labels={['Funds']}>
      <Tabs>
        <Tab eventKey="funds" title="Funds">
          <div className="border border-gray p-32 shadow-sm bg-white">
            <BusinessFundsTable />
          </div>
        </Tab>
        <Tab eventKey="fundRequests" title="Fund Requests">
          <div className="border border-gray p-32 shadow-sm bg-white">
            <BusinessFundRequests />
          </div>
        </Tab>
      </Tabs>
    </BreadCrumbWrapper>
  );
};

export default BusinessFunds;
