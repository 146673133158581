import $appSettings from 'signals/AppSettings.signal';

export const capitalCallsTotalAmount = (capitalCalls) => {
  const {
    constants: { CAPITAL_CALL_STATUS },
  } = $appSettings.value;

  return capitalCalls
    .filter(
      (cc) =>
        cc.status === CAPITAL_CALL_STATUS.approved ||
        cc.status === CAPITAL_CALL_STATUS.funded ||
        cc.status === CAPITAL_CALL_STATUS.disbursed
    )
    .reduce((acc, curr) => {
      if (!!curr.capitalCallPortions.length) {
        const portionTotal = curr.capitalCallPortions.reduce(
          (portionAcc, portion) => portionAcc + portion.amount,
          0
        );

        return acc + portionTotal;
      }
      return acc;
    }, 0);
};
