import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import {
  DAYS_OF_THE_WEEK,
  INDUSTRY_LIST,
  SAVED_SEARCH_TIME,
  SEEKING_FUNDING_PAYMENT_RANGES,
} from '../Constant/constants';
import saveBusinessSearchSignal from './saveBusinessSearchModal.signal';
import CloseModalButton from '../Constant/CloseModalButton';
import {
  SAVE_BUSINESS_SEARCH_MODAL_TYPES,
  handleCloseSaveSearchModal,
  handleDayOfTheWeekChange,
  handleSaveSearchInputChange,
  handleSaveSearchZipCodeInputBlur,
} from './saveBusinessSearchModal.helpers';
import { handleSaveSearchChangesClick } from '../SearchBusinesses/searchBusiness.helpers';
import Field from '../Field/Field';

const SaveBusinessSearchModal = ({
  handleSaveSearchClick,
  handleDeleteSaveSearchClick = () => null,
}) => {
  const {
    industry,
    location,
    fundingRange,
    time,
    modalVisible,
    formErrors,
    modalType,
    deleteSearchPromptVisible,
  } = saveBusinessSearchSignal.value;

  return (
    <ModalWrapper
      size="lg"
      centered
      show={modalVisible}
      onHide={handleCloseSaveSearchModal}
    >
      <Modal.Header>
        <h5>Save a Search</h5>
        <CloseModalButton onCloseModal={handleCloseSaveSearchModal} />
      </Modal.Header>
      <Modal.Body className="px-32">
        <Alert>
          By saving a search, you will get search result notifications sent to
          you via email. Each time the query is run, you will receive
          notifications for new businesses (if any) that fit your search
          criteria.
        </Alert>
        <small className="text-danger" hidden={!formErrors.parameterRequired}>
          * At least one search parameter is required.
        </small>
        <Row>
          <Col xs={12} lg>
            <Field.Select
              label="Industry"
              name="industry"
              disabled={modalType === SAVE_BUSINESS_SEARCH_MODAL_TYPES.edit}
              labelClassName="font-weight-bold"
              inputClassName="form-control"
              value={industry}
              options={[
                { label: 'Select an option', value: '' },
                ...INDUSTRY_LIST.map((industry) => ({
                  label: industry,
                  value: industry,
                })),
              ]}
              onChange={handleSaveSearchInputChange}
            />
          </Col>
          <Col xs={12} lg className="mt-16 mt-lg-0">
            <Field.TextInput
              label="Location (Zip Code)"
              labelClassName="font-weight-bold"
              inputClassName="form-control"
              disabled={modalType === SAVE_BUSINESS_SEARCH_MODAL_TYPES.edit}
              name="location"
              value={location}
              onChange={handleSaveSearchInputChange}
              onBlur={handleSaveSearchZipCodeInputBlur}
            />
            <small className="text-danger" hidden={!formErrors?.location}>
              Please enter a valid Zip Code.
            </small>
          </Col>
        </Row>
        <Field.Select
          className="mt-16"
          disabled={modalType === SAVE_BUSINESS_SEARCH_MODAL_TYPES.edit}
          label="Amount Interested In"
          labelClassName="font-weight-bold"
          inputClassName="form-control"
          name="fundingRange"
          value={fundingRange}
          options={[
            { label: 'Select an option', value: '' },
            ...SEEKING_FUNDING_PAYMENT_RANGES.map((range) => ({
              label: range,
              value: range,
            })),
          ]}
          onChange={handleSaveSearchInputChange}
        />
        <hr />
        <p className="font-weight-bold">
          When would you like to get these search results?
        </p>
        <Row className="mt-16">
          <Col xs={12} lg>
            <p className="mb-8 font-weight-bold">
              Day(s) (select all that apply)
            </p>
            {Object.keys(DAYS_OF_THE_WEEK).map((day) => (
              <Field.Checkbox
                key={day}
                labelClassName="mb-0"
                label={DAYS_OF_THE_WEEK[day]}
                value={saveBusinessSearchSignal.value[day]}
                name={day}
                onChange={handleDayOfTheWeekChange}
              />
            ))}
            <small className="text-danger" hidden={!formErrors?.days}>
              At least one day is required.
            </small>
          </Col>
          <Col xs={12} lg className="d-flex flex-column mt-16 mt-lg-0">
            <Field.Select
              label="Time"
              labelClassName="font-weight-bold"
              inputClassName="form-control"
              name="time"
              value={time}
              options={[
                { label: 'Select an option', value: '' },
                ...Object.keys(SAVED_SEARCH_TIME).map((time) => ({
                  label: time,
                  value: SAVED_SEARCH_TIME[time],
                })),
              ]}
              onChange={handleSaveSearchInputChange}
            />
            <small className="text-danger" hidden={!formErrors?.time}>
              A time is required.
            </small>
            <div className="mt-auto d-flex justify-content-end">
              {modalType === SAVE_BUSINESS_SEARCH_MODAL_TYPES.create && (
                <Button
                  className="text-right rounded mt-16 mt-lg-0"
                  onClick={handleSaveSearchClick}
                >
                  Save Search
                </Button>
              )}
              {modalType === SAVE_BUSINESS_SEARCH_MODAL_TYPES.edit && (
                <>
                  {!deleteSearchPromptVisible ? (
                    <div className="mt-16 mt-lg-0">
                      <Button
                        hidden={
                          modalType ===
                            SAVE_BUSINESS_SEARCH_MODAL_TYPES.create ||
                          deleteSearchPromptVisible
                        }
                        variant="outline-danger"
                        className="rounded mr-8"
                        onClick={() =>
                          saveBusinessSearchSignal.update({
                            deleteSearchPromptVisible: true,
                          })
                        }
                      >
                        Delete this Search
                      </Button>
                      <Button
                        className="text-right rounded"
                        onClick={handleSaveSearchChangesClick}
                      >
                        Save Changes
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-16 mt-lg-0">
                      <p>Are you sure you want to delete this search?</p>
                      <div className="d-flex flex-row justify-content-end">
                        <Button
                          className="mr-8 rounded"
                          variant="secondary"
                          onClick={() =>
                            saveBusinessSearchSignal.update({
                              deleteSearchPromptVisible: false,
                            })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          className="rounded"
                          variant="danger"
                          onClick={handleDeleteSaveSearchClick}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={handleCloseSaveSearchModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default SaveBusinessSearchModal;
