import twoStepVerificationFormSignal from 'signals/TwoStepVerification.signal';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
// import api from 'api/api';

export const handleSMSChange = (e) => {
  twoStepVerificationFormSignal.update({
    sms: {
      ...twoStepVerificationFormSignal.value.sms,
      [e.target.name]: e.target.value,
    },
  });
};

export const handleEmailChange = (e) => {
  twoStepVerificationFormSignal.update({
    email: {
      ...twoStepVerificationFormSignal.value.email,
      [e.target.name]: e.target.value,
    },
  });
};

export const update2FAPhone = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    // await api.patch({
    //   path: '/',
    //   body: twoStepVerificationFormSignal.value.sms,
    // });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
    alertSignal.update({
      type: 'notification',
      message: 'Please enter the code sent to your phone number.',
    });
  }
};

export const update2FAEmail = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    // await api.patch({
    //   path: '/',
    //   body: twoStepVerificationFormSignal.value.email,
    // });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
    alertSignal.update({
      type: 'notification',
      message: 'Please enter the code sent to your email address.',
    });
  }
};

export const sendSMSCode = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    // await api.patch({
    //   path: '/',
    //   body: twoStepVerificationFormSignal.value.sms,
    // });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();

    twoStepVerificationFormSignal.update({
      sms: {
        active: true,
        phoneNumber: '',
        smsCode: '',
      },
    });
    alertSignal.update({
      type: 'notification',
      message: 'SMS verification is now active.',
    });
  }
};

export const sendEmailCode = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    // await api.patch({
    //   path: '/',
    //   body: twoStepVerificationFormSignal.value.email,
    // });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();

    twoStepVerificationFormSignal.update({
      email: {
        active: true,
        email: '',
        emailCode: '',
      },
    });
    alertSignal.update({
      type: 'notification',
      message: 'Email verification is now active.',
    });
  }
};
