import Signal from "libs/signals";

const saveBusinessSearchSignal = Signal({
  industry: '',
  location: '',
  fundingRange: '',
  time: '',
  mondayEnabled: false,
  tuesdayEnabled: false,
  wednesdayEnabled: false,
  thursdayEnabled: false,
  fridayEnabled: false,
  saturdayEnabled: false,
  sundayEnabled: false,
  selectedSearchId: null,
  modalVisible: false,
  modalType: null,
  formErrors: {
    location: false,
    parameterRequired: false,
  },
  deleteSearchPrompt: false,
});

export default saveBusinessSearchSignal;
