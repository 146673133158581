import { Tab, Tabs } from 'react-bootstrap';
import UserInvitations from 'components/global/UserInvitations';
import { useCallback, useEffect } from 'react';
import { fetchAndSetUserInvites } from 'components/global/UserInvitations/UserInvitations.helpers';
import UserManagement from 'components/global/UserManagement';
import resetSearchParams from 'utils/resetSearchParams';

export const VCUsers = () => {
  const init = useCallback(async () => {
    await fetchAndSetUserInvites();
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <Tabs>
      <Tab
        key="manageUsers"
        eventKey="manageUsers"
        title="Manage Users"
        onSelect={() => resetSearchParams()}
      >
        <div className="bg-white p-32 border border-gray shadow-sm">
          <UserManagement />
        </div>
      </Tab>
      <Tab
        key="userInvitations"
        eventKey="userInvitations"
        title="User Invitations"
      >
        <div className="bg-white p-32 border border-gray shadow-sm">
          <UserInvitations />
        </div>
      </Tab>
    </Tabs>
  );
};

export default VCUsers;
