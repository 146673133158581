import SimpleTable from "components/global/SimpleTable";
import { Button } from "react-bootstrap";
import businessFundsSignal from "signals/BusinessFunds.signal";

const HEADERS = [
  {
    key: 'fundName',
    title: 'Fund Name',
  },
  {
    key: 'vc',
    title: 'VC',
  },
  {
    key: 'dateRequested',
    title: 'Date Requested',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const BusinessFundRequests = () => {
  const { fundRequests } = businessFundsSignal.value;

  return (
    <SimpleTable
      headers={HEADERS}
      rows={fundRequests.map(fr => ({
        fundName: fr.fund.fundName,
        vc: fr.fund.fundingProgramMembership.ventureCapitalFirm.name,
        dateRequested: new Date(fr.createdDate).toLocaleDateString(),
        action: () => (
          <td align="right">
            <Button
              className="rounded"
              size="sm"
              variant="outline-dark"
            >
              Detail
            </Button>
          </td>
        )
      }))}
    />
  );
}


export default BusinessFundRequests;
