import reportingSignals, {
  annualReportingSignal,
  quarterlyReportSignal,
  reportDeadlineSignal,
  reportUploadSignal
} from "components/global/Reporting/Reporting.signals";
import { annualReportDeadlines, quarterlyReportDeadlines } from "./reportConstants";
import alertSignal from "signals/Alert.signal";
import loaderSignal from "signals/Loader.signal";
import api from "api/api";
import {
  LOAN_TERM_RANGES,
  SECURITY_TYPE,
  SEEKING_FUNDING_PAYMENT_RANGES,
  STAGES_OF_INVESTMENT,
  TYPE_OF_VALUATION_CAP,
  YEARS_BUSINESS_OPENED_RANGES
} from "../Constant/constants";
import $appSettings from "signals/AppSettings.signal";
import { baseGraphData } from "./components/ReportingChartsAndTables";
import { readFileAsBase64 } from "../../../libs/functions/global.functions";

export const fetchAndSetQuarterlyReportData = async (e) => {
  loaderSignal.update({ isContentLoading: true });

  const quarterlyReportId = e.target.value;
  try {
    const reportingPeriod = quarterlyReportDeadlines.find(qrd => qrd.id === quarterlyReportId)
    const [year, quarter] = quarterlyReportId.split('-');

    if (!year || !quarter) {
      return false;
    }

    let path = '/reports/quarterly';
    if (!findMatchingStaticQuarterlyReport(reportingPeriod, reportingSignals.value.selectedPlatform)) {
      path = '/reports/quarterly/caat';
    }

    const quarterlyReport = await api.get({
      path,
      options: {
        where: {
          year_quarter_platformId: {
            platformId: reportingSignals.value.selectedPlatform.id,
            quarter: Number(quarter),
            year: Number(year),
          }
        }
      },
    });

    return quarterlyReportSignal.update({
      quarterlyReport,
      quarterlyReportId
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleDeadlineScheduleSelect = (e) => {
  const { value } = e;

  if (value === 'QUARTERLY_SCHEDULE') {
    return reportDeadlineSignal.update(quarterlyReportDeadlines);
  }
  if (value === 'ANNUAL_SCHEDULE') {
    return reportDeadlineSignal.update(annualReportDeadlines);
  }

  return reportDeadlineSignal.update(null);
};

export const fetchAndSetAnnualReport = async () => {
  try {
    annualReportingSignal.update({
      fetchingTableData: true,
    });

    const reportData = await api.get({
      path: '/annualReportDrafts',
    });

    return annualReportingSignal.update({
      selectedTab: reportData[0].name,
      fetchingTableData: false,
      reportData,
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });

    annualReportingSignal.update({
      fetchingTableData: false,
    });
  }
};

// Fetch OLD report based on report options
// export const handleReportingTemplateSelectChange = async (e) => {
//   try {
//     const selectedOption = e.value;

//     annualReportingSignal.update({
//       fetchingTableData: true,
//     });

//     const reportData = await api.get({
//       path: '/annualReportDrafts',
//       options: {
//         report: selectedOption,
//       },
//     });

//     return annualReportingSignal.update({
//       selectedOption,
//       selectedTab: reportData[0].name,
//       fetchingTableData: false,
//       reportData,
//     });
//   } catch (error) {
//     alertSignal.update({
//       type: 'notification',
//       error,
//       message: error.message,
//     });

//     annualReportingSignal.update({
//       fetchingTableData: false,
//     });
//   }
// };


export const fetchAndSetPlatforms = async () => {
  loaderSignal.update({ isContentLoading: true });

  try {
    const platforms = await api.get({
      path: '/platforms',
      options: {
        include: {
          fundingPrograms: {
            include: {
              referenceProgram: true,
            },
          },
          tranches: true,
          quarterlyReports: {
            select: {
              id: true,
              year: true,
              quarter: true,
            }
          }
        },
      },
    });
    reportingSignals.update({ platforms });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetPotentialAllocation = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const preliminaryAllocation = await api.get({
      path: '/allocations',
      options: {
        where: {
          jurisdiction: 'Louisiana',
        },
      },
    });
    const totalPotentialAllocation =
      preliminaryAllocation[0].totalPotentialFundingAmount;
    return Number(totalPotentialAllocation);
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleDownloadAnnualReport = async () => {
  annualReportingSignal.update({ downloadingReport: true });
  loaderSignal.update({ isContentLoading: true });
  try {
    await api.get({
      path: '/annualReportDrafts/download',

      // Provide an option for OLD reporting
      // options: {
      //   report: option,
      // },
      isFileDownload: true,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    annualReportingSignal.update({ downloadingReport: false });
    loaderSignal.reset();
  }
};

export const downloadQuarterlyReport = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message:
      'Generating Quarterly Report File for Download. This may take several minutes.',
  });
  try {
    const { quarterlyReport, quarterlyReportId } = quarterlyReportSignal.value;
    const reportingPeriod = quarterlyReportDeadlines.find(qrd => qrd.id === quarterlyReportId);
    const options = {};
    let path = '/reports/quarterly/download'

    if (findMatchingStaticQuarterlyReport(reportingPeriod, reportingSignals.value.selectedPlatform)) {
      options.where = {
        id: quarterlyReport.id
      }
    } else {
      const [year, quarter] = quarterlyReportId.split('-');
      if (!year || !quarter) {
        return false;
      }

      path = '/reports/quarterly/download/caat'
      options.where = {
        year_quarter_platformId: {
          platformId: reportingSignals.value.selectedPlatform.id,
          quarter: Number(quarter),
          year: Number(year),
        }
      }
    }

    await api.get({
      path,
      isFileDownload: true,
      options,
    });
    return alertSignal.update({
      type: 'notification',
      message:
        'Successfully Generated Quarterly Report file. Check your downloads folder',
      variant: 'success',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleQuarterlyReportUpload = (e) => {
  const { files } = e.target;

  reportUploadSignal.update({ files });
};

export const handleUploadQuarterlyReport = async (fileInputRef) => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Uploading report...',
  });

  const base64 = await readFileAsBase64(reportUploadSignal.value.files[0]);
  const body = {
    data: {
      platformId: reportingSignals.value.selectedPlatform.id,
      quarter: Number(reportUploadSignal.value.quarter),
      year: Number(reportUploadSignal.value.year),
      base64,
    }
  }

  try {
    await api.post({
      path: '/reports/quarterly',
      body,
    });

    await fetchAndSetPlatforms();
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully uploaded report!',
    });
    reportUploadSignal.reset();
    fileInputRef.current = null;
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: `Quarterly report upload requirements are strict. Please make sure you are using the correct template. If you need further guidance, please contact support. ERROR: ${error.message}`,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleQuarterReportUploadSelectChange = (e) => {
  const { name, value } = e;

  reportUploadSignal.update({
    [name]: value,
  });
};

export const getProviderTypeTally = (data) => {
  const { PROVIDER_TYPES } = $appSettings.value.constants;
  const { providerType } = baseGraphData;
  const labelOrder = Object.values(PROVIDER_TYPES).map((value) => value.value);

  const tally = data.reduce((accumulator, currentValue) => {
    const providerType = currentValue.providerType.value;
    if (labelOrder.includes(providerType)) {
      accumulator[providerType] = (accumulator[providerType] || 0) + 1;
    } else {
      accumulator['Other'] = (accumulator['Other'] || 0) + 1;
    }
    return accumulator;
  }, {});

  const labels = Object.values(PROVIDER_TYPES).map((value) => value.annualReportLabel);

  return {
    ...providerType,
    labels,
    datasets: [{
      ...providerType.datasets[0],
      data: [...labelOrder.map((label) => tally[label] || 0), tally['Other'] || 0],
    }]
  };
};

export const getMinorityDepositoryTally = (data) => {
  const { minorityDepository } = baseGraphData;
  const tally = data.reduce((accumulator, currentValue) => {
    const status = currentValue.minorityDepositoryInstitution.value ? 'Yes' : 'No';
    accumulator[status] = (accumulator[status] || 0) + 1;
    return accumulator;
  }, {});

  return {
    ...minorityDepository,
    datasets: [
      {
        ...minorityDepository.datasets[0],
        data: [tally.Yes || 0, tally.No || 0],
      }
    ],
  };
};

export const getTargetFundSizeTally = (data) => {
  const { targetFundSize } = baseGraphData;
  const fundSizeRanges = SEEKING_FUNDING_PAYMENT_RANGES.map((range) => {
    const [min, max] = range.replace(/\$/g, '').split(' - ');
    return {
      min: parseInt(min),
      max: max ? parseInt(max) : null,
      label: range,
    };
  });

  const tally = {};
  fundSizeRanges.forEach((range) => (tally[range.label] = 0));

  data.forEach((currentValue) => {
    const targetFundSize = currentValue.targetFundSize.value;

    if (!targetFundSize) {
    } else {
      if (parseInt(targetFundSize) > 100000) {
        tally[fundSizeRanges[fundSizeRanges.length - 1].label] =
          tally[fundSizeRanges[fundSizeRanges.length - 1].label] + 1;
      } else {
        const fundRange = fundSizeRanges.find(
          (range) => targetFundSize >= range.min && targetFundSize <= range.max
        );
        tally[fundRange.label] = tally[fundRange.label] + 1;
      }
    }
  });

  return {
    ...targetFundSize,
    datasets: [
      {
        ...targetFundSize.datasets[0],
        data: [...fundSizeRanges.map(fundSizeRange => tally[fundSizeRange.label])],
      },
    ],
  };
};

export const getYearBusinessOpenedTally = (data) => {
  const { yearOpened } = baseGraphData;
  const yearRanges = YEARS_BUSINESS_OPENED_RANGES.map((range) => {
    const [min, max] = range.split('-');
    return {
      min: parseInt(min),
      max: max ? parseInt(max) : null,
      label: range,
    };
  });

  const tally = {};
  yearRanges.forEach((range) => (tally[range.label] = 0));

  data.forEach((currentValue) => {
    const yearBusinessOpened = currentValue.yearBusinessOpened.value;

    if (!yearBusinessOpened) {
    } else {
      if (yearBusinessOpened > 2020) {
        tally[yearRanges[yearRanges.length - 1].label] =
          tally[yearRanges[yearRanges.length - 1].label] + 1;
      } else {
        const yearRange = yearRanges.find(
          (range) =>
            yearBusinessOpened >= range.min && yearBusinessOpened <= range.max
        );
        if (!yearRange) {
          return;
        }
        tally[yearRange.label] = tally[yearRange.label] + 1;
      }
    }
  });

  return {
    ...yearOpened,
    datasets: [
      {
        ...yearOpened.datasets[0],
        data: [...yearRanges.map(range => tally[range.label])],
      }
    ]
  }
};

export const getFormOfBusinessTally = (data) => {
  const { formOfBusinessOrganization } = baseGraphData;
  const { FORM_OF_BUSINESS_ORGANIZATION } = $appSettings.value.constants;
  const enumValues = Object.values(FORM_OF_BUSINESS_ORGANIZATION).map(type => type.value)
  const labelOrder = Object.values(FORM_OF_BUSINESS_ORGANIZATION).map(type => type.annualReportLabel);
  const tally = data.reduce((accumulator, currentValue) => {
    const formOfBusinessOrganization =
      currentValue.formOfBusinessOrganization.value;

    if (formOfBusinessOrganization) {
      accumulator[formOfBusinessOrganization] =
        (accumulator[formOfBusinessOrganization] || 0) + 1;
    }
    return accumulator;
  }, {});

  return {
    ...formOfBusinessOrganization,
    labels: labelOrder,
    datasets: [
      {
        ...formOfBusinessOrganization.datasets[0],
        data: [...enumValues.map((label) => tally[label] || 0)],
      },
    ],
  };
};

export const getLoanTypeTally = (data) => {
  const { loanType } = baseGraphData;
  const { LOAN_TYPES } = $appSettings.value.constants;
  const enumValues = Object.values(LOAN_TYPES).map(type => type.value);
  const labelOrder = Object.values(LOAN_TYPES).map(type => type.annualReportLabel);

  const tally = data.reduce((accumulator, currentValue) => {
    const loanType = currentValue.loanType.value;

    if (loanType) {
      accumulator[loanType] = (accumulator[loanType] || 0) + 1;
    }
    return accumulator;
  }, {});

  return {
    ...loanType,
    labels: labelOrder,
    datasets: [
      {
        ...loanType.datasets[0],
        data: [...enumValues.map((label) => tally[label] || 0)],
      },
    ],
  };
};

export const getLoanTermTally = (data) => {
  const { loanTerm } = baseGraphData;
  const loanTermRanges = LOAN_TERM_RANGES.map((range) => {
    const [min, max] = range.split('-');
    return {
      min: parseInt(min),
      max: max ? parseInt(max) : null,
      label: range,
    };
  });

  const tally = {};
  loanTermRanges.forEach((range) => (tally[range.label] = 0));

  data.forEach((currentValue) => {
    const loanTerm = currentValue.loanTerm.value ? currentValue.loanTerm.value / 12 : null;

    if (!loanTerm) {

    } else {
      if (loanTerm > 10) {
        tally[loanTermRanges[loanTermRanges.length - 1].label] =
          tally[loanTermRanges[loanTermRanges.length - 1].label] + 1;
      } else {
        const loanTermRange = loanTermRanges.find(
          (range) => loanTerm >= range.min && loanTerm <= range.max
        );
        tally[loanTermRange.label] = tally[loanTermRange.label] + 1;
      }
    }
  });

  return {
    ...loanTerm,
    datasets: [
      {
        ...loanTerm.datasets[0],
        data: [...loanTermRanges.map(range => tally[range.label])],
      }
    ]
  }
};

export const getStageOfInvestmentTally = (data) => {
  const { stageOfInvestment } = baseGraphData;
  const labelOrder = Object.values(STAGES_OF_INVESTMENT).map(value => value);

  const tally = data.reduce((accumulator, currentValue) => {
    const stageOfInvestmentValue = currentValue.stageOfInvestment.value;
    if (stageOfInvestmentValue) {
      accumulator[stageOfInvestmentValue] = (accumulator[stageOfInvestmentValue] || 0) + 1;
    }
    return accumulator;
  }, {});

  return {
    ...stageOfInvestment,
    datasets: [
      {
        ...stageOfInvestment.datasets[0],
        data: [...labelOrder.map((label) => tally[label] || 0)],
      },
    ],
  }
};

export const getSecurityTypeTally = (data) => {
  const { securityType } = baseGraphData;
  const labelOrder = Object.values(SECURITY_TYPE).map(value => value);

  const tally = data.reduce((accumulator, currentValue) => {
    const securityTypeValue = currentValue.securityType.value;
    if (securityTypeValue) {
      accumulator[securityTypeValue] = (accumulator[securityTypeValue] || 0) + 1;
    }
    return accumulator;
  }, {});

  return {
    ...securityType,
    datasets: [
      {
        ...securityType.datasets[0],
        data: [...labelOrder.map((label) => tally[label] || 0)],
      },
    ],
  }
};

export const getValuationCapTally = (data) => {
  const { valuationType } = baseGraphData;
  const labelOrder = Object.values(TYPE_OF_VALUATION_CAP).map(value => value);

  const tally = data.reduce((accumulator, currentValue) => {
    const valuationCapType = currentValue.typeOfValuationCap.value;
    if (valuationCapType) {
      accumulator[valuationCapType] = (accumulator[valuationCapType] || 0) + 1;
    }
    return accumulator;
  }, {});

  return {
    ...valuationType,
    datasets: [
      {
        ...valuationType.datasets[0],
        data: [...labelOrder.map((label) => tally[label] || 0)],
      },
    ],
  }
};

export const handlePlatformSelection = (e) => {
  const { platforms } = reportingSignals.value;
  const { value } = e.target;

  return reportingSignals.update({
    selectedPlatform: platforms.find((p) => p.id === Number(value)),
  });
};

export const showUploadQuarterlyReportModal = () => reportUploadSignal.update({
  modalVisible: true
})
export const hideUploadQuarterlyReportModal = () => reportUploadSignal.update({
  modalVisible: false
})

export const reportingPeriodIsCurrentOrPast = (period) => {
  const [start, end] = period.split(' to ');
  const currentDate = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate < currentDate && currentDate < endDate) {
    return 'current';
  } else if (endDate < currentDate) {
    return 'past';
  }
  return false;
}

export function findMatchingStaticQuarterlyReport(quarterlyReportDeadline, platform) {
  if (!platform || !quarterlyReportDeadline) {
    return false;
  }

  return platform.quarterlyReports.find(
    (qr) => `${qr.year}-${qr.quarter}` === quarterlyReportDeadline.id
  );
}