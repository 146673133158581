import LinkedPlatformDetailsModal from "components/global/Billing/components/LinkedPlatformDetailsModal";
import LinkedProgramDetailsModal from "components/global/Billing/components/LinkedProgramDetails";
import SimpleTable from "components/global/SimpleTable";
import { Button } from "react-bootstrap";
import edoOrganizationSignal from "signals/EdoOrganization.signal";
import {
  fetchAndSetDestinationBankAccounts,
  handleDestinationBankAccountAppliedFiltersChange,
  handleDestinationBankAccountFilterChange,
  handleHideLinkedPlatformDetailsModal,
  handleHideLinkedProgramDetailsModal,
  handleOrganizationsListSortChange,
  handleShowApplicationDetails,
  handleShowLinkedPlatformDetails,
  handleShowLinkedProgramDetails,
  resetDestinationBankAccountFilterInputs
} from "../edoOrganization.helpers";
import LinkedApplicationDetailsModal from "./LinkedApplicationDetailsModal";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'organizationName',
    title: 'Organization Name',
  },
  {
    key: 'organizationType',
    title: 'Organization Type',
    sortable: true,
  },
  {
    key: 'mask',
    title: 'Account ID',
  },
  {
    key: 'linkedPrograms',
    title: 'Link Programs',
  },
  {
    key: 'linkedPlatforms',
    title: 'Linked Platforms',
  },
  {
    key: 'applications',
    title: 'Applications',
  },
];

const FILTERS = [
  {
    type: 'text',
    label: 'Organization Name',
    key: 'organizationName',
  },
  {
    type: 'select',
    label: 'OrganizationType',
    key: 'organizationType',
    options: [
      { label: 'Lender', value: $appSettings.value.constants.PORTAL_TYPES.lender },
      { label: 'VC', value: $appSettings.value.constants.PORTAL_TYPES.vc },
    ],
  },
];

const DestinationBankAccounts = () => {
  const {
    destinationBankAccounts,
    selectedBankAccount,
    linkedPlatforms,
    linkedPrograms,
    linkedPlatformsDetailModalVisible,
    linkedProgramsDetailModalState,
    dropdownFilters,
  } = edoOrganizationSignal.value;

  return (
    <div className="border border-gray p-32 bg-white shadow-sm">
      <div className="mb-16">
        <SimpleTableFilterDropdown
          filtersState={dropdownFilters}
          onChange={({ name, value }) =>
            handleDestinationBankAccountFilterChange(name, value)
          }
          onApply={(filters) => {
            handleDestinationBankAccountAppliedFiltersChange(filters);
            fetchAndSetDestinationBankAccounts();
          }}
          filters={FILTERS}
          onClearFilterInputs={resetDestinationBankAccountFilterInputs}
        />
      </div>
      <SimpleTable
        headers={HEADERS}
        onSort={(sort) => {
          handleOrganizationsListSortChange(sort);
          fetchAndSetDestinationBankAccounts();
        }}
        rows={destinationBankAccounts.map((destinationBankAccount) => ({
          organizationName:
            destinationBankAccount.account.lender?.name ||
            destinationBankAccount.account.ventureCapitalFirm?.name,
          organizationType: destinationBankAccount.account.lender
            ? 'Lender'
            : 'VC',
          mask: `********${destinationBankAccount.mask}`,
          linkedPrograms: ({ key }) => (
            <td key={key}>
              <Button
                size="sm"
                className="rounded mr-16"
                variant="outline-dark"
                onClick={() =>
                  handleShowLinkedProgramDetails(destinationBankAccount)
                }
              >
                View
              </Button>
            </td>
          ),
          linkedPlatforms: ({ key }) => (
            <td key={key}>
              <Button
                size="sm"
                className="rounded"
                variant="outline-dark"
                onClick={() =>
                  handleShowLinkedPlatformDetails(destinationBankAccount)
                }
              >
                View
              </Button>
            </td>
          ),
          applications: ({ key }) => (
            <td key={key} align="right">
              <Button
                size="sm"
                className="rounded"
                variant="outline-dark"
                onClick={() =>
                  handleShowApplicationDetails(destinationBankAccount)
                }
              >
                Details
              </Button>
            </td>
          ),
        }))}
      />
      <LinkedProgramDetailsModal
        linkedProgramsDetailModalState={linkedProgramsDetailModalState}
        selectedBankAccount={selectedBankAccount}
        linkedPrograms={linkedPrograms}
        onHide={handleHideLinkedProgramDetailsModal}
        editable={false}
      />
      <LinkedPlatformDetailsModal
        selectedBankAccount={selectedBankAccount}
        show={linkedPlatformsDetailModalVisible}
        linkedPlatforms={linkedPlatforms}
        onHide={handleHideLinkedPlatformDetailsModal}
      />
      <LinkedApplicationDetailsModal />
    </div>
  );
};

export default DestinationBankAccounts;
