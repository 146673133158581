import api from 'api/api';
import {
  einRegex,
  phoneRegex,
  regulatoryIdRegex,
} from 'components/global/Constant/regularExpression';
import AlertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import {
  vcBusinessNewIndustrySignal,
  vcIndustryDataSignal,
  vcOrganizationInfoErrorsSignal,
  vcOrganizationInfoSignal,
  vcSettingsAddressSearchSignal,
} from 'signals/VCSettingsUsers.signal';
import replaceNullsWithEmptyStrings from 'utils/replaceNullsWithEmptyStrings';
import validateUrl from 'utils/validateUrl';
import validateEmailUtil from 'utils/validateEmail';
import { formatPhoneNumber } from 'react-phone-number-input';
import queryAddresses from 'utils/queryAddresses';

export const fetchAndSetVc = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const vc = await api.get({
      path: '/ventureCapitalFirms',
      options: {
        where: {
          accountId: userAccountSignal.value.userData.accountId,
        },
      },
    });

    if (vc) {
      const vcInfo = formatAndMapOrganizationDataToSignal(vc);
      vcOrganizationInfoSignal.update({
        ...vcInfo,
        phoneNumber: formatPhoneNumber(vcInfo.phoneNumber),
      });
      const staticIndustryTags = vcIndustryDataSignal.value;
      const customIndustryTags = vc.industrySelection.filter(
        (industry) => !staticIndustryTags.includes(industry)
      );
      vcIndustryDataSignal.update([
        ...vcIndustryDataSignal.value,
        ...customIndustryTags,
      ]);
    }
  } catch (error) {
    AlertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const formatAndMapOrganizationDataToSignal = (fetchedOrganization) =>
  replaceNullsWithEmptyStrings(fetchedOrganization);

export const handleOrganizationInfoChange = (e) => {
  const { type, name } = e.target;
  let { value } = e.target;

  if (type === 'radio') {
    if (value === 'yes') value = true;
    if (value === 'no') value = false;
  }

  vcOrganizationInfoSignal.update({ [name]: value });
  vcOrganizationInfoErrorsSignal.update({ [name]: false });
};

export const handleOrganizationInfoIndustrySelectionChange = (value) => {
  const organizationInfo = vcOrganizationInfoSignal.value;
  const organizationInfoErrors = vcOrganizationInfoErrorsSignal.value;
  let newIndustryError = organizationInfoErrors.industrySelection;
  let newIndustrySelectionArr = [...organizationInfo.industrySelection];
  if (newIndustrySelectionArr.includes(value)) {
    newIndustrySelectionArr = newIndustrySelectionArr.filter(
      (industry) => industry !== value
    );
  } else {
    newIndustrySelectionArr.push(value);
  }

  if (
    organizationInfoErrors.industrySelection &&
    newIndustrySelectionArr.length > 0
  ) {
    newIndustryError = false;
  }

  vcOrganizationInfoSignal.update({
    industrySelection: newIndustrySelectionArr,
  });
  vcOrganizationInfoErrorsSignal.update({
    industrySelection: newIndustryError,
  });
};

export const setOrganizationInfoErrors = () => {
  const organizationInfo = vcOrganizationInfoSignal.value;
  const newErrors = {
    industrySelection: false,
    email: false,
    phoneNumber: false,
    ein: false,
    regulatoryId: false,
    website: false,
  };

  if (organizationInfo.industrySelection.length <= 0) {
    newErrors.industrySelection = true;
  }
  if (!validateEmailUtil(organizationInfo.email)) {
    newErrors.email = true;
  }
  if (!phoneRegex.test(organizationInfo.phoneNumber)) {
    newErrors.phoneNumber = true;
  }
  if (!einRegex.test(organizationInfo.ein)) {
    newErrors.ein = true;
  }
  if (!regulatoryIdRegex.test(organizationInfo.regulatoryId)) {
    newErrors.regulatoryId = true;
  }
  if (!validateUrl(organizationInfo.website)) {
    newErrors.website = true;
  }
  if (!Object.keys(organizationInfo.address).length) {
    newErrors.address = true;
  }

  vcOrganizationInfoErrorsSignal.update(newErrors);
};

export const formatAndMapSignalToOrganizationData = (organizationInfo) => ({
  ...organizationInfo,
  lenderType: organizationInfo.lenderType ? organizationInfo.lenderType : null,
  loanRangeMin: Number(organizationInfo.loanRangeMin),
  loanRangeMax: Number(organizationInfo.loanRangeMax),
  targetFundSize: Number(organizationInfo.targetFundSize),
});

export const handleOrganizationInfoSubmit = async (e) => {
  e.preventDefault();
  const organizationInfo = vcOrganizationInfoSignal.value;
  setOrganizationInfoErrors();

  if (!Object.values(vcOrganizationInfoErrorsSignal.value).includes(true)) {
    const body = formatAndMapSignalToOrganizationData(organizationInfo);
    try {
      loaderSignal.update({
        isContentLoading: true,
        message: 'Submitting organization information...',
      });
      await api.patch({ path: '/ventureCapitalFirms', body });
      AlertSignal.update({
        variant: 'success',
        type: 'notification',
        message: 'Successfully updated organization.',
      });
    } catch (error) {
      AlertSignal.update({
        type: 'alert',
        error,
        message: 'Failed to save organization.',
      });
    } finally {
      loaderSignal.reset();
    }
  }
};

export const handleNewIndustryChange = (e) => {
  const { value } = e.target;

  vcBusinessNewIndustrySignal.update(value);
};

export const handleAddNewIndustryTag = () => {
  const newIndustry = vcBusinessNewIndustrySignal.value;
  const industryData = vcIndustryDataSignal.value;
  if (newIndustry.length > 0 && !industryData.includes(newIndustry)) {
    vcIndustryDataSignal.update([...industryData, newIndustry]);
    vcBusinessNewIndustrySignal.reset();
  }
};

export const handleInputBlur = (e) => {
  const { name } = e.target;
  const organizationInfo = vcOrganizationInfoSignal.value;

  if (name === 'ein' && !einRegex.test(organizationInfo.ein)) {
    vcOrganizationInfoErrorsSignal.update({ ein: true });
  }

  if (name === 'website' && !validateUrl(organizationInfo.website)) {
    vcOrganizationInfoErrorsSignal.update({ website: true });
  }

  if (
    name === 'phoneNumber' &&
    !phoneRegex.test(organizationInfo.phoneNumber)
  ) {
    vcOrganizationInfoErrorsSignal.update({ phoneNumber: true });
  }
};

export const handleAddressSearchChange = (input) => {
  vcOrganizationInfoSignal.update({ address: {} });
  vcSettingsAddressSearchSignal.update({ addressSearch: input });
  vcOrganizationInfoErrorsSignal.update({ address: false });
};

export const handleSelectAddress = (option) => {
  vcOrganizationInfoSignal.update({ address: option });
  vcSettingsAddressSearchSignal.update({
    addressSearch: option.formattedAddress,
  });
  vcOrganizationInfoErrorsSignal.update({ address: false });
};

export const getAddressOptions = async () => {
  const bingAddresses = await queryAddresses(
    vcSettingsAddressSearchSignal.value.addressSearch
  );
  vcSettingsAddressSearchSignal.update({
    addressOptions: bingAddresses.map((ba) => ba),
  });
};

export const handleAddressBlur = () => {
  if (!Object.keys(vcOrganizationInfoSignal.value.address).length) {
    vcOrganizationInfoErrorsSignal.update({ address: true });
  }
};

export const handleAddressSearchFocus = () => {
  vcSettingsAddressSearchSignal.update({
    addressSearch:
      vcOrganizationInfoSignal.value.address?.formattedAddress ||
      vcSettingsAddressSearchSignal.value.addressSearch,
  });
};
