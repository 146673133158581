import { Tab, Tabs } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumbWrapper from '../BreadCrumbs/BreadCrumbs';
import ApprovableLinkBankAccountAlert from '../Approvable/components/ApprovableLinkBankAccountAlert';
import ApprovableGeneralInformationTab from '../Approvable/components/ApprovableGeneralInformationTab';
import ApprovableDocumentsTab from '../Approvable/components/ApprovableDocumentsTab';
import ApprovableStatusTab from '../Approvable/components/ApprovableStatusTab';
import ProgramRequestLogsTab from './components/ProgramRequestLogsTab';
import ProgramRequestConfirmStatusModal from './components/ProgramRequestConfirmStatusModal';
import ProgramRequestApprovalChainTab from './components/ProgramRequestApprovalChainTab';
import ApprovableSendBackModal from '../Approvable/components/ApprovableSendBackModal';
import { fetchProgramRequest } from './ProgramRequestDetail.helpers';
import applicationDetailSignal from '../ApplicationDetail/ApplicationDetail.signal';

const ProgramRequestDetail = () => {
  const { search, pathname } = useLocation();
  const programRequestId = pathname.split('/')[pathname.split('/').length - 1];
  const activeTab = new URLSearchParams(search).get('tab');
  const { lender, ventureCapitalist, fundingProgram } =
    applicationDetailSignal.value;

  let labels = [];
  if (pathname.startsWith('/edo')) {
    labels = [
      ventureCapitalist ? 'Venture Capitalists' : '',
      !!lender ? lender.name : '',
    ];
  } else {
    labels = ['', !!fundingProgram ? fundingProgram.name : ''];
  }

  const init = useCallback(async () => {
    await fetchProgramRequest(programRequestId);
  }, []);

  useEffect(() => {
    init();
  }, [programRequestId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <div>
        <ProgramRequestConfirmStatusModal />
        <ApprovableLinkBankAccountAlert />
        <Tabs defaultActiveKey={activeTab || 'general'}>
          <Tab eventKey="general" title="General">
            <div className="p-32 border border-gray shadow-sm bg-white">
              <ApprovableGeneralInformationTab />
            </div>
          </Tab>
          <Tab eventKey="documents" title="Documents">
            <div className="p-32 border border-gray shadow-sm bg-white">
              <ApprovableDocumentsTab />
            </div>
          </Tab>
          <Tab eventKey="status" title="Status">
            <div className="px-32 pb-32 pt-16 border border-gray shadow-sm bg-white">
              <ApprovableStatusTab />
            </div>
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <div className="px-32 pb-32 pt-16 border border-gray shadow-sm bg-white">
              <ProgramRequestLogsTab />
            </div>
          </Tab>
          <Tab eventKey="approval" title="Approval Chain">
            <div className="px-32 pb-32 pt-16 border border-gray shadow-sm bg-white">
              <ProgramRequestApprovalChainTab />
            </div>
          </Tab>
        </Tabs>
        <ApprovableSendBackModal />
      </div>
    </BreadCrumbWrapper>
  );
};

export default ProgramRequestDetail;
