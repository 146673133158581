/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Button, Card, Table } from "react-bootstrap";
import stateProgramsSignal from "signals/StatePrograms.signal";
import { fetchAndSetStateProgramsData } from "./StatePrograms.helpers";

const StatePrograms = () => {
  const { fundingPrograms } = stateProgramsSignal.value;

  useEffect(() => {
    fetchAndSetStateProgramsData();
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <Table>
            <thead>
              <tr className="font-weight-bold">
                <th>ID</th>
                <th>Name</th>
                <th>Type</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {fundingPrograms.map((fundingProgram) => (
                <tr key={fundingProgram.id}>
                  <td>{fundingProgram.id}</td>
                  <td>
                    {fundingProgram.name} - {fundingProgram.program.programName}
                  </td>
                  <td>{fundingProgram.program.programType.programTypeName}</td>
                  <td>
                    <Button
                      size="sm"
                      // onClick={() => {}}
                      variant="outline-dark"
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default StatePrograms;
