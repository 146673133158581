// import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelect = ({
  value,
  label,
  name,
  onChange,
  className,
  labelClassName,
  inputClassName,
  placeholder,
  isInvalid,
  disabled,
}) => {
  const handleChange = (newVal) => {
    onChange({ name, value: newVal });
  };
  return (
    <div className={className}>
      {label && <p className={labelClassName}>{label}</p>}
      <DatePicker
        placeholder={placeholder}
        name={name}
        selected={value}
        onChange={handleChange}
        wrapperClassName="custom-datepicker form-control"
        className={`form-control ${isInvalid ? 'border-danger' : ''} ${inputClassName}`}
        disabled={disabled}
      />
    </div>
  );
};
export default DateSelect;
