
import loaderSignal from 'signals/Loader.signal';
import history from '../../../history';
import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import $globalSearchResults from './globalSearchResults.signal';
import { handleGlobalSearch } from 'libs/searchNavigation';
import { enumCaseToTabCase } from 'libs/string';
import formatMoney from 'utils/formatMoney';

export const RECORD_FIELDS = {
  'Funding Program': (record) =>
    record.fundingProgramMembership?.fundingProgram?.name || record.fundingProgram?.name || record.fund?.fundingProgramMembership?.fundingProgram?.name,
  Lender: (record) => record.fundingProgramMembership?.fundingProgram?.name || record.lender?.name || null,
  Business: (record) => record.business?.name,
  Status: (record) => enumCaseToTabCase(record.status),
  'Approved Amount': (record) => record.approvedAmount ? formatMoney(record.approvedAmount) : null,
  Name: (record) => record.name || record.fundName,
  EIN: (record) => record.ein,
  'Provider Type': (record) => enumCaseToTabCase(record.lenderType),
  'Contact Name': (record) => record.contactName,
  'Email': (record) => record.email || record.contactEmail,
  'Description': (record) =>
    record.description ||
    record.overview ||
    'No description available.',
  'Phone Number': (record) => record.phoneNumber,
  'Fund Size': (record) => record.maxFundSize,
  'VC': (record) => record.fundingProgramMembership?.ventureCapitalFirm?.name || record.ventureCapitalFirm?.name || record.fund?.fundingProgramMembership?.ventureCapitalFirm?.name || null,
  'Asked Amount': (record) => formatMoney(record.allocationAsk),
  'Platform': (record) => record.platform?.name,
  'Program Type': (record) => record.referenceProgram?.programType?.programTypeName,
  'Total Program Funding': (record) => formatMoney(record.ceiling),
  'Fund': (record) => record.fund.fundName,
  'Amount': (record) => formatMoney(record.capitalCallAmount),
};

export const RECORD_FIELDS_TO_RENDER = {
  Application: [
    'Funding Program',
    'Lender',
    'Business',
    'Status',
    'Approved Amount',
  ],
  ProgramRequest: [
    'VC',
    'Lender',
    'Funding Program',
    'Asked Amount',
    'Status',
  ],
  FundingProgram: [
    'Name',
    'Program Type',
    'Platform',
    'Description',
    'Total Program Funding',
  ],
  VentureCapitalFirm: [
    'Name',
    'EIN',
    'Contact Name',
    'Email',
    'Provider Type',
    'Description',
  ],
  Lender: [
    'Name',
    'EIN',
    'Contact Name',
    'Email',
    'Provider Type',
    'Description',
  ],
  Business: [
    'Name',
    'EIN',
    'Contact Name',
    'Email',
    'Phone Number',
    'Description',
  ],
  Fund: [
    'Name',
    'VC',
    'Funding Program',
    'Fund Size',
  ],
  CapitalCall: [
    'Fund',
    'Funding Program',
    'VC',
    'Amount',
    'Status',
  ],
};

export const fetchAndSetSearchResults = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const params = Object.fromEntries(new URLSearchParams(history.location.search));
    const searchResults = await api.get({
      path: '/search',
      options: {
        searchQuery: params.query ?? '',
      },
    });

    $globalSearchResults.update({ searchResults, query: params.query ?? '' });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleGlobalSearchChange = (e) => $globalSearchResults.update({
  query: e.value,
});

export const handleEnterKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleGlobalSearch($globalSearchResults.value.query);
  }
};
