import Signal from "libs/signals";

const edoOrganizationSignal = Signal({
  organizationInfo: {
    name: '',
    contactName: '',
    contactEmail: '',
    phoneNumber: '',
    address: {},
  },
  organizationInfoErrors: {},
  selectedBankAccount: null,
  destinationBankAccounts: [],
  linkedPrograms: [],
  linkedPlatforms: [],
  linkedProgramsDetailModalState: null,
  linkedPlatformsDetailModalVisible: false,
  applicationDetailsModalVisible: false,
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
  addressSearch: '',
  addressOptions: [],
});

export default edoOrganizationSignal;
