import Signal from 'libs/signals';

const businessSearchSignal = Signal({
  query: {
    organizationTypes: [],
    seekingMin: '',
    seekingMax: '',
    serviceTypes: [],
    assistanceTypes: [],
    location: '',
    requiresBusinessPlan: null,
    fundsStartUps: null,
  },
});

export const businessSearchOrganizationsSignal = Signal([]);
export const businessSearchAssistanceTypesSignal = Signal([]);
export const businessSearchServicesSignal = Signal([]);

export default businessSearchSignal;
