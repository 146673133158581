import BackButton from 'components/global/BackButton';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { DATA_ROOM_COMPONENT_VIEW_TYPES } from 'components/global/Constant/constants';
import FundApplication from 'components/global/FundApplication';
import { handleAttachSingleDocumentClick } from 'components/global/FundApplication/FundApplication.helpers';
import fundApplicationSignal from 'components/global/FundApplication/FundApplication.signals';
import { useHistory } from 'react-router-dom';

const BusinessFundApplication = () => {
  const history = useHistory();
  const { fundApplication } = fundApplicationSignal.value;
  const labels = ['', fundApplication?.vcPortfolioCompany?.fund?.fundName];

  return (
    <>
    <BackButton onClick={() => history.goBack()} buttonText="Back" />
    <BreadCrumbWrapper labels={labels}>
      <div className="border border-gray p-32 bg-white shadow-sm">
        <FundApplication
          viewType={DATA_ROOM_COMPONENT_VIEW_TYPES.upload}
          requiredDocumentDescription="Manage required documents for your fund application. Select from the documents in your data room"
          suggestedDocumentDescription="Manage suggested documents for your fund application"
          additionalDocumentDescription="Submit any additional documents that you would like the VC to view"
          onAttachSingleDocument={handleAttachSingleDocumentClick}
          downloadParentId={fundApplication ? fundApplication.fundId : null}
        />
      </div>
    </BreadCrumbWrapper>
    </>
  );
};

export default BusinessFundApplication;
