/* eslint-disable no-unused-vars */
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import {
  businessSettingsAddressSearchSignal,
  businessSettingsDirtySignal,
  businessSettingsErrorsSignal,
  businessSettingsIndustryOptionsSignal,
  businessSettingsSignal,
  businessSettingsSubmittingSignal,
} from 'signals/BusinessSettings.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import history from '../../../../history';
import { expandFlatObject, flattenNestedObject } from 'utils/objects';
import {
  einRegex,
  naicsCodeRegex,
} from '../../../global/Constant/regularExpression';
import { INDUSTRY_LIST } from 'components/global/Constant/constants';
import queryAddresses from 'utils/queryAddresses';
import validateUrl from 'utils/validateUrl';

export function handleTabChange(tab) {
  fetchAndSetBusiness();
  businessSettingsDirtySignal.reset();
  return history.replace(`/business/organization?tab=${tab}`);
}

export const fetchAndSetBusiness = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const business = await api.get({
      path: '/businesses',
    });

    const flattenedBusiness = flattenNestedObject(business);
    const selectedIndustries =
      flattenedBusiness['assistance.industrySelection'] || [];
    const industryOptions = [
      ...new Set(INDUSTRY_LIST.concat(selectedIndustries)),
    ];
    businessSettingsIndustryOptionsSignal.update({
      industries: industryOptions,
    });
    return businessSettingsSignal.update(flattenedBusiness);
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const NON_EMPTY_KEYS = [
  'name',
  'formOfBusinessOrganization',
  'description',
  'address',
];

const NUMERIC_KEYS = [
  'assistance.seekingMin',
  'assistance.seekingMax',
  'assistance.downPayment',
  'employment.numberOfEmployees',
  'employment.numberOfFullTimeEmployees',
  'employment.numberOfPartTimeEmployees',
  'employment.expectedJobsRetained',
  'employment.expectedJobsCreated',
  'yearBusinessOpened',
  'finance.annualRevenue',
  'finance.previousAnnualRevenue',
  'finance.netIncome',
  'finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations',
  'finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations',
  'finance.additionalGovernmentFunds',
  'finance.concurrentPrivateFinancing',
];

const CHECKBOX_KEYS = [
  'demographics.race.americanIndianOrAlaskaNative',
  'demographics.race.blackOrAfricanAmerican',
  'demographics.race.asianIndian',
  'demographics.race.asianChinese',
  'demographics.race.asianFilipino',
  'demographics.race.asianJapanese',
  'demographics.race.asianKorean',
  'demographics.race.asianVietnamese',
  'demographics.race.asianOther',
  'demographics.race.guamanianOrChamorro',
  'demographics.race.nativeHawaiian',
  'demographics.race.samoan',
  'demographics.race.pacificIslanderOther',
  'demographics.race.white',
];

const MULTISELECT_KEYS = [
  'assistance.desiredAssistanceTypes',
  'assistance.assistanceUses',
  'assistance.industrySelection',
];

export function handleBusinessFormChange(e) {
  const { name, value } = e.target;

  handleBusinessFormDirtyChange({ target: { name } });

  if (NON_EMPTY_KEYS.includes(name)) {
    businessSettingsErrorsSignal.update({ [name]: !value });
  }

  if (name === 'assistance.seekingMin') {
    businessSettingsErrorsSignal.update({ [name]: !parseInt(value, 10) });
    const seekingMax = businessSettingsSignal.value['assistance.seekingMax'];
    const invalid =
      seekingMax && parseInt(value, 10) >= parseInt(seekingMax, 10);
    businessSettingsErrorsSignal.update({ ['assistance.seekingMax']: invalid });
  } else if (name === 'assistance.seekingMax') {
    const seekingMin = businessSettingsSignal.value['assistance.seekingMin'];
    const invalid =
      !parseInt(seekingMin, 10) ||
      !parseInt(value, 10) ||
      parseInt(seekingMin, 10) >= parseInt(value, 10);
    businessSettingsErrorsSignal.update({ [name]: invalid });
  } else if (NUMERIC_KEYS.includes(name)) {
    businessSettingsErrorsSignal.update({ [name]: !parseInt(value, 10) });
  }

  if (name === 'ein') {
    businessSettingsErrorsSignal.update({ [name]: !einRegex.test(value) });
  }

  if (name === 'naicsCode') {
    businessSettingsErrorsSignal.update({
      [name]: !naicsCodeRegex.test(value),
    });
  }

  if (name === 'website') {
    if (value) {
      businessSettingsErrorsSignal.update({ [name]: !validateUrl(value) });
    } else {
      businessSettingsErrorsSignal.update({ [name]: false }); // website is not required
    }
  }

  const checkBoxKey = CHECKBOX_KEYS.find((key) => name.startsWith(key));
  const multiselectKey = MULTISELECT_KEYS.find((key) => name.startsWith(key));

  if (name === 'demographics.race.preferNotToRespond') {
    const value = businessSettingsSignal.value[name];
    if (!value) {
      const currentValue = businessSettingsSignal.value;
      Object.keys(currentValue).forEach((key) => {
        if (key.startsWith('demographics.race')) {
          delete currentValue[key];
        }
      });
    }

    businessSettingsSignal.update({
      [name]: !value,
    });
  } else if (checkBoxKey) {
    businessSettingsSignal.update({
      [checkBoxKey]: !businessSettingsSignal.value[checkBoxKey],
      'demographics.race.preferNotToRespond': false,
    });
  } else if (multiselectKey) {
    const options = businessSettingsSignal.value[multiselectKey] || [];
    const newValue = options.includes(value)
      ? {
        [multiselectKey]: options.filter((v) => v !== value),
      }
      : {
        [multiselectKey]: (options || []).concat(value),
      };

    businessSettingsSignal.update(newValue);
  } else {
    return businessSettingsSignal.update({ [name]: value });
  }
}

export function handleBusinessFormDirtyChange(e) {
  const { name } = e.target;
  const key = MULTISELECT_KEYS.find((key) => name.startsWith(key));
  if (key) {
    return businessSettingsDirtySignal.update({ [key]: true });
  }
  return businessSettingsDirtySignal.update({ [name]: true });
}

export function handleNewIndustryChange(e) {
  return businessSettingsIndustryOptionsSignal.update({
    value: e.target.value,
  });
}

export function handleAddAnotherIndustry(newIndustry) {
  const { industries } = businessSettingsIndustryOptionsSignal.value;
  if (newIndustry === '') {
    return alertSignal.update({
      type: 'notification',
      error: {},
      message: 'Industry cannot be empty.',
    });
  } else if (industries.includes(newIndustry)) {
    return alertSignal.update({
      type: 'notification',
      error: {},
      message: 'Industry already exists.',
    });
  } else {
    return businessSettingsIndustryOptionsSignal.update({
      industries: [...industries, ...[newIndustry]],
      value: '',
    });
  }
}

export async function submitBusiness(e) {
  e.preventDefault();

  try {
    loaderSignal.update({ isContentLoading: true, message: 'Updating...' });
    const allDirtyPairs = Object.entries(
      businessSettingsDirtySignal.value
    ).filter(([, value]) => value);

    if (Object.keys(allDirtyPairs).length === 0) {
      return alertSignal.update({
        // client requested to always say successfully saved if no errors present
        variant: 'success',
        type: 'notification',
        message: 'Successfully updated business information.',
      });
    }

    const errorsObject = businessSettingsErrorsSignal.value;
    if (allDirtyPairs.some(([key]) => errorsObject[key])) {
      throw new Error('Cannot submit if there are any errors present.');
    }

    const payload = allDirtyPairs.reduce(
      (acc, [key]) => ({
        ...acc,
        [key]: NUMERIC_KEYS.includes(key)
          ? parseInt(businessSettingsSignal.value[key], 10)
          : businessSettingsSignal.value[key],
      }),
      {}
    );

    businessSettingsSubmittingSignal.update(true);

    await api.patch({
      path: '/businesses',
      body: {
        where: {
          id: userAccountSignal.value.userData.account.business.id,
        },
        data: expandFlatObject(payload),
      },
    });
    await fetchAndSetBusiness();
    businessSettingsDirtySignal.reset();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated business information.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    businessSettingsSubmittingSignal.update(false);
    loaderSignal.update({ isContentLoading: false });
  }
}

export const removeIndustryTag = (value) => {
  businessSettingsIndustryOptionsSignal.update({
    industries: businessSettingsIndustryOptionsSignal.value.industries.filter(
      (industry) => industry !== value
    ),
  });

  if (
    businessSettingsSignal.value['assistance.industrySelection']?.includes(
      value
    )
  ) {
    businessSettingsSignal.update({
      ['assistance.industrySelection']: businessSettingsSignal.value[
        'assistance.industrySelection'
      ].filter((industry) => industry !== value),
    });
  }
};

export const handleAddressSearchChange = (input) => {
  businessSettingsSignal.update({ address: {} });
  businessSettingsAddressSearchSignal.update({ addressSearch: input });
  businessSettingsErrorsSignal.update({ address: false });
};

export const handleSelectAddress = (option) => {
  businessSettingsSignal.update({ address: option });
  businessSettingsAddressSearchSignal.update({
    addressSearch: option.formattedAddress,
  });
  businessSettingsErrorsSignal.update({ address: false });
  businessSettingsDirtySignal.update({ address: true });
};

export const getAddressOptions = async () => {
  const bingAddresses = await queryAddresses(
    businessSettingsAddressSearchSignal.value.addressSearch
  );
  businessSettingsAddressSearchSignal.update({
    addressOptions: bingAddresses.map((ba) => ba),
  });
};

export const handleAddressBlur = () => {
  if (!Object.keys(businessSettingsSignal.value.address).length) {
    businessSettingsErrorsSignal.update({ address: true });
  }
};

export const handleAddressSearchFocus = () => {
  businessSettingsAddressSearchSignal.update({
    addressSearch:
      businessSettingsSignal.value.address?.formattedAddress ||
      businessSettingsAddressSearchSignal.value.addressSearch,
  });
};

export const handleSediDemographicsChange = (e) => {
  const { name } = e;
  const sediDemographics = businessSettingsSignal.value["sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus"] ?? [];

  handleBusinessFormDirtyChange({ target: { name: 'sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus' } })

  if (name === 'N/A' && !sediDemographics.includes('N/A')) {
    businessSettingsSignal.update({
      "sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus": ['N/A'],
    });
  } else {
    businessSettingsSignal.update({
      "sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus": sediDemographics.includes(name) ?
        sediDemographics.filter(sd => sd !== name) :
        [...sediDemographics.filter(sd => sd !== 'N/A'), name],
    });
  }
};
