import Signal from "libs/signals";
import { AppSettings, User } from "../types";

type UserAccountSignal = {
  userData: User;
  appSettings: AppSettings;
  loaded: boolean;
};

const userAccountSignal = Signal<UserAccountSignal>({
  // @ts-ignore
  userData: null,
  loaded: false,
  // @ts-ignore
  appSettings: {
    constants: {
      PORTAL_TYPES: {
        business: 'BUSINESS',
        edo: 'EDO',
        lender: 'LENDER',
        none: 'NONE',
        state: 'STATE',
        vc: 'VC',
      },
      APPLICATION_DOCUMENT_STATUS: null,
      APPLICATION_STATUS: null,
      PROGRAM_REQUEST_STATUS: null,
      USER_ROLES: null,
      USER_STATUS: null,
      WATCHABLE_TYPES: null,
      ALERT_KEYS: null,
      PROGRAM_FUND_TYPES: null,
      APPROVAL_CHAIN_AMOUNT_BOUNDS: {
        min: 0,
        max: 2147483647,
      },
      DOCUMENT_TYPES: null,
      REBALANCING_FREQUENCY_OPTIONS: null,
      STANDARD_COMPLIANCE_ITEM_KEYS: null,
      CAPITAL_ACCESS_COMPLIANCE_KEYS: null,
      TRANCHE_GRACE_PERIODS: {
        dev: 24,
      },
      OCR_CONFIDENCE_THRESHOLD: null,
      TECHNICAL_ASSISTANCE_TYPES: null,
      TOOLTIP_TYPES: null,
      FUND_DOCUMENT_TYPES: null,
      FUND_APPLICATION_DOCUMENT_STATUS: null,
      CAPITAL_CALL_STATUS: null,
    },
  },
});

export default userAccountSignal;
