import { Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const EdoLenderDetail = () => {
  const { pathname } = useLocation();
  const segments = pathname.split('/');
  const organizationId = segments[segments.length - 1];
  const { selectedOrganization } = organizationDetailsSignal.value;
  let labels = [];

  if (pathname.startsWith('/edo/portfolio')) {
    const fundingProgramMembership = selectedOrganization?.fundingProgramMemberships?.find(fpm => fpm.applications.find(app => app.id === Number(segments[3])));
    const application = fundingProgramMembership?.applications?.find(app => app.id === Number(segments[3]));

    labels = [
      '',
      !!application ? `${selectedOrganization?.name} - ${application?.business.name}` : '',
      selectedOrganization?.name || '',
    ];
  } else {
    labels = [
        '',
        selectedOrganization?.name,
      ];
  }

  const init = useCallback(async () => {
    await fetchOrganization(organizationId, '/lenders');
  });

  useEffect(() => {
    init();
  }, [organizationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card>
        <Card.Body>
          <OrganizationDetails showLinkToPrograms={!pathname.startsWith('/edo/portfolio')} />
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default EdoLenderDetail;
