/* eslint-disable no-unreachable */
import api from "api/api";
import alertSignal from "signals/Alert.signal";
import addAccountModalSignal from "./AddAccountModal.signal";
import { fetchAndSetUserInvites } from "../UserInvitations/UserInvitations.helpers";
import loaderSignal from "signals/Loader.signal";
import validateEmailUtil from "utils/validateEmail";

export const handleAddAccountSubmission = async (e) => {
  const { refreshCurrentPageData } = addAccountModalSignal.value;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Sending invitation...',
  });
  e.preventDefault();
  try {
    const {
      organizationName,
      firstName,
      lastName,
      portal,
      email,
      addBusinessToFavorites,
    } = addAccountModalSignal.value;
    const body = {
      firstName,
      lastName,
      portal,
      email,
      organizationName,
      addBusinessToFavorites,
    };

    await api.post({
      path: '/userInvites',
      body,
    });
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully invited user to create account.',
    });
    // TODO: this is closing the modal
    // we should be using signals for this
    fetchAndSetUserInvites();
    if (!!refreshCurrentPageData) await refreshCurrentPageData();
    return addAccountModalSignal.reset();
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      variant: 'warning',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showAddAccountModal = (data) =>
  addAccountModalSignal.update({ modalVisible: true, ...data });

export const hideAddAccountModal = () => addAccountModalSignal.reset();

export const handleAddAccountModalFormChange = (e) => {
  const data = {
    [e.target.name]: e.target.value,
  };

  if (e.target.name === 'email') {
    data.emailError = false;
  }

  addAccountModalSignal.update(data);
};

export const validateEmail = () => {
  const { email } = addAccountModalSignal.value;

  const isError = !validateEmailUtil(email);
  return addAccountModalSignal.update({ emailError: isError });
};

export const handleAddFavoriteBusinessChange = () => {
  const { addBusinessToFavorites } = addAccountModalSignal.value;
  addAccountModalSignal.update({
    addBusinessToFavorites: !addBusinessToFavorites,
  });
};
