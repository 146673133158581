import applicationComplianceSignal from './ApplicationCompliance.signal';

export const COMPLIANCE_CHECK_DATA = {
  complianceItemOne: {
    name: 'complianceItemOne',
    title:
      '1. Loan Purpose Requirements and Prohibitions (How funds will be used)',
    tablesData: [
      {
        id: 1,
        prompt: 'Will the funds be  used for the following purpose(s)?',
        specialInstructions: 'Select all that apply (A-H)',
        keys: [
          {
            name: 'startUpCosts',
            label: 'A. Start-up Costs',
          },
          {
            name: 'workingCapital',
            label: 'B. Working capital',
          },
          {
            name: 'franchiseFees',
            label: 'C. Franchise fees',
          },
          {
            name: 'acquisitionOfEquipment',
            label: 'D. Acquisition of Equipment',
          },
          {
            name: 'inventory',
            label: 'E. Inventory',
          },
          {
            name: 'servicesUsedInTheProduction',
            label: 'F. Services used in the production',
          },
          {
            name: 'manufacturing',
            label: 'G. Manufacturing',
          },
          {
            name: 'deliveryOfBusinessGoodsOrServices',
            label:
              "H. Delivery of a business's goods or services, or in the purchase, construction, renovation or tenant improvements of an eligible place of business that is not for passive estate investment purposes.",
          },
        ],
      },
    ],
  },
  complianceItemTwo: {
    name: 'complianceItemTwo',
    title: '2. Business Purpose: Passive Real Estate Investment Guidance',
    tablesData: [
      {
        id: 1,
        prompt:
          'A. Each Financial Institution lender must obtain the following:',
        specialInstructions: 'Required',
        keys: [
          {
            name: 'borrowerAssurance',
            label:
              'I. Borrower Assurance - eligible business purpose - an eligible business purpose under SSBCI includes the purchase construction, renovation, or tenant improvements of an eligible place of business that is not for passive real estate investment purpose.',
          },
        ],
      },
      {
        id: 2,
        prompt:
          'B. Small business can deliver a borrower assurance:  that the loan is not being used for passive real estate if the small business borrower occupies and uses at least a specific percentage of the building; the percentage varies depending on whether the project involves the construction of a new building or renovation of an existing building',
        specialInstructions: 'Choose only one',
        keys: [
          {
            label:
              'I. Construction of a new building. If SSBCI-supported loan proceeds are used in the construction of a new building, the small business must occupy and use at least 60 percent of the total rentable property following issuance of an occupancy permit or other similar authorization.',
            name: 'constructionOfANewBuilding',
          },
          {
            label:
              'II. Renovation of an existing building. If SSBCI-supported loan proceeds are used in the acquisition, renovation, or reconstruction of an existing building, the borrower may permanently lease up to 49 percent of the rentable property to one or more tenants, if the small business occupies and uses at least 51 percent of the total rentable property within 12 months following the acquisition, renovation, or reconstruction.',
            name: 'renovationOfAnExistingBuilding',
          },
        ],
        notes: [
          'If a small business chooses to lease an allowable portion of the rentable square footage to a tenant, the jurisdiction may rely on lease agreements, blueprints, or similar documentation in assuring the lease of an allowable portion of the rentable square footage is consistent with these guidelines.',
          'SSBCI-supported loan proceeds may not be used to improve or renovate any portion of rentable property that the small business borrower leases to a third party. “Rentable property” means the total square footage of all buildings or facilities used for business operations, which (1) excludes vertical penetrations (e.g., stairways, elevators, and mechanical areas that are designed to transfer people or services vertically between floors) and all outside areas and (2) includes common areas (e.g., lobbies, passageways, vestibules, and bathrooms).',
        ],
      },
      {
        id: 3,
        prompt:
          'C. There are two exceptions to the general prohibition on the use of SSBCI-supported loan proceeds for passive real estate investment. An eligible business purpose may include the financing of real estate investments in either one of the following limited circumstances.',
        promptItem:
          'I. Passive company leasing to operating company. A passive company such as a holding company that acquires real property using an SSBCI-supported loan may have an eligible business purpose where 100 percent of the rentable property is leased to the passive company’s affiliated operating companies that are actively involved in conducting business operations. To meet this exception, the following criteria must also be met:',
        keys: [
          {
            label:
              'a. The passive company must be an eligible small business using the affiliate and employee definitions described above;',
            name: 'passiveCompanyEligible',
          },
          {
            label:
              'b. The operating company must be subject to the same sublease restrictions as the owner affiliate;',
            name: 'operatingCompanySubjectToSameSubleaseRestrictions',
          },
          {
            label:
              'c. The operating company must be a guarantor or co-borrower on the SSBCI-supported loan to the eligible passive company;',
            name: 'operatingCompanyIsGuarantor',
          },
          {
            label:
              'd. Both the passive company and the operating company must execute SSBCI borrower use-of-proceeds certifications and sex-offender certifications covering all principals;',
            name: 'bothCompaniesExecuteCertifications',
          },
          {
            label:
              'e. Each natural person holding an ownership interest constituting at least 20 percent of either the passive company or the operating company must provide a personal guaranty for the SSBCI-supported loan; and',
            name: 'eachPersonProvidesPersonalGuaranty',
          },
          {
            label:
              'f. The passive company and the operating company have a written lease with a term at least equal to the term of the SSBCI-supported loan (which may include options to renew exercisable solely by the operating company)',
            name: 'companiesHaveWrittenLease',
          },
        ],
      },
      {
        id: 4,
        specialInstructions: 'Select all that apply',
        promptItem:
          'II. Construction loan of $500,000 or less. A construction loan with an original principal amount of $500,000 or less may have an eligible business purpose if:',
        promptItemNotes: [
          'Under this exception, loans that automatically convert into permanent financing are excluded from the definition of “eligible business purpose,” unless the converted loans would no longer rely on SSBCI support. The term “construction loan” means a loan secured by real estate made to finance (1) land development (e.g., the process of improving land, such as laying sewers or water pipes) preparatory to erecting new structures or (2) the on-site construction of industrial, commercial, residential, or farm buildings. For purposes of this paragraph, “construction” includes not only construction of new structures, but also additions or alterations to existing structures and the demolition of existing structures to make way for new structures.',
        ],
        keys: [
          {
            label:
              'a. the building will not serve as a residence for the owner, their relatives, or affiliates;',
            name: 'buildingIsNotResidence',
          },
          {
            label: 'b. the building will be put into service immediately;',
            name: 'buildingWillBePutToService',
          },
          {
            label:
              'c. the loan is underwritten and made for the purpose of constructing or refurbishing a structure; and',
            name: 'loanIsUnderwritten',
          },
          {
            label:
              'd. the building has not been and will not be financed by another SSBCI-supported loan',
            name: 'buildingNotFinancedByOtherSsbciLoan',
          },
        ],
      },
    ],
  },
  complianceItemThree: {
    name: 'complianceItemThree',
    title: '3. Prohibited Loan Purposes',
    markAsRead: true,
    tablesData: [
      {
        id: 1,
        noCheckboxes: true,
        keys: [
          {
            label:
              'A. Repay delinquent federal or jurisdiction income taxes unless the borrower has a payment plan in place with the relevant taxing authority;',
          },
          {
            label:
              'B. Repay taxes held in trust or escrow (e.g.,   payroll or sales taxes);',
          },
          {
            label:
              'C. Reimburse funds owed to any owner, including any equity investment or investment of capital for the business’s continuance; or',
          },
          {
            label:
              'D. Purchase any portion of the ownership interest of any owner of the business, except for the purchase of an interest in an employee stock ownership plan qualifying under section 401 of Internal Revenue Code, worker cooperative, employee ownership trust, or related vehicle, provided that the transaction results in broad-based employee ownership for employees in the business and the employee stock ownership plan or other employee-owned entity holds a majority interest (on a fully diluted basis) in the business.',
          },
        ],
      },
    ],
  },
  complianceItemFour: {
    name: 'complianceItemFour',
    title: '4. Borrower Restrictions',
    tablesData: [
      {
        id: 1,
        prompt:
          'A. Each financial institution lender must obtain an assurance from the borrower affirming that the borrower is not:',
        keys: [
          {
            label:
              'I. An executive officer, director, or principal shareholder of the financial institution lender;',
            name: 'borrowerIsNotOfficerDirectorPrincipalShareholder',
          },
          {
            label:
              'II. A member of the immediate family of an executive officer, director, or principal shareholder of the financial institution lender; or',
            name: 'borrowerIsNotFamily',
          },
          {
            label:
              'III. A related interest or immediate family member of such an executive officer, director, or principal shareholder of the financial institution lender',
            name: 'borrowerIsNotRelatedInterestOrImmediateFamily',
          },
        ],
      },
    ],
  },
  complianceItemFive: {
    name: 'complianceItemFive',
    title: '5. Additional Borrower Restrictions',
    tablesData: [
      {
        id: 1,
        prompt:
          'A. Each financial institution lender must obtain an assurance from the borrower affirming that the borrower is not:',
        specialInstructions: 'Indicate if all criteria (i-vi) are met',
        keys: [
          {
            label:
              'I. A business engaged in speculative activities that profit from fluctuations in price, such as wildcatting for oil and dealing in commodities futures, unless those activities are incidental to the regular activities of the business and part of a legitimate risk management strategy to guard against price fluctuations related to the regular activities of the business or through the normal course of trade;',
            name: 'isNotBusinessEngagedInSpeculativeActivites',
          },
          {
            label:
              'II. A business that earns more than half of its annual net revenue from lending activities, unless the business is (1) a CDFI that is not a depository institution or a bank holding company, or (2) a Tribal enterprise lender that is not a depository institution or a bank holding company;',
            name: 'isNotBusinessThatEarnsHalfRevenueFromLendingActivites',
          },
          {
            label:
              'III. A business engaged in pyramid sales, where a participant’s primary incentive is based on the sales made by an ever-increasing number of participants;',
            name: 'isNotBusinessEngagedInPyramidSales',
          },
          {
            label:
              'IV. A business engaged in activities that are prohibited by federal law or, if permitted by federal law, applicable law in the jurisdiction where the business is located or conducted (this includes businesses that make, sell, service, or distribute products or services used in connection with illegal activity, unless such use can be shown to be completely outside of the business’s intended market); this category of businesses includes direct and indirect marijuana businesses.',
            name: 'isNotEngagedInActivitiesProhibitedByLaw',
          },
          {
            label:
              'V. A business deriving more than one-third of gross annual revenue from legal gambling activities, unless the business is a Tribal SSBCI participant, in which case the Tribal SSBCI participant is prohibited from using SSBCI funds for gaming activities, but is not restricted from using SSBCI funds for non-gaming activities merely due to an organizational tie to a gaming business; “gaming activities”.',
            name: 'isNotBusinessDerivingGrossRevenueFromGambling',
          },
          {
            label:
              'VI. Each financial institution lender must also obtain an assurance from the borrower affirming that no principal of the borrowing entity has been convicted of a sex offense against a minor. For purposes of this certification, “principal” is defined as if a sole proprietorship, the proprietor; if a partnership, each partner; if a corporation, limited liability company, association, development company, or other entity, each director, each of the five most highly compensated executives, officers, or employees of the entity, and each direct or indirect holder of 20 percent or more of the ownership stock or stock equivalent of the entity.',
            name: 'assuranceNoSexOffense',
          },
        ],
      },
    ],
  },
  complianceItemSix: {
    name: 'complianceItemSix',
    title: '6. Lender Assurances',
    tablesData: [
      {
        id: 1,
        prompt:
          'A. Each participating jurisdiction must obtain an assurance from the financial institution lender affirming:',
        keys: [
          {
            label:
              'I. The SSBCI-supported loan is not being made in order to place under the protection of the approved program prior debt that is not covered under the approved program and that is or was owed by the borrower to the financial institution lender or to an affiliate of the financial institution lender.',
            name: 'loanNotMadeToPlaceUnderProtection',
          },
          {
            label:
              'II. If the SSBCI-supported loan is a refinancing, it complies with all applicable SSBCI restrictions and requirements regarding refinancing and new extensions of credit, including that the SSBCI-supported loan is not a refinancing of a loan previously made to the borrower by the lender or an affiliate of the lender.',
            name: 'loanIsNotRefinancing',
          },
          {
            label:
              'III. No principal of the financial institution lender has been convicted of a sex offense against a minor. For the purposes of this certification, “principal” is defined as if a sole proprietorship, the proprietor; if a partnership, each partner; if a corporation, limited liability company, association, development company, or other entity, each director, each of the five most highly compensated executives, officers, or employees of the entity, and each direct or indirect holder of 20 percent or more of the ownership stock or stock equivalent of the entity.',
            name: 'noPrincipalConvictedOfSexOffense',
          },
        ],
      },
      {
        id: 2,
        prompt:
          'B. New Extensions of Credit by Existing Lenders. Financial institution lenders are generally prohibited from refinancing an existing outstanding balance or previously made loan, line of credit, extension of credit, or other debt owed by a small business borrower already on the books of the same financial institution (or an affiliate) into the SSBCI-supported program. However, a financial institution lender may use SSBCI funds to support a new extension of credit that repays the amount due on a matured loan or other debt that was previously used for an eligible business purpose when all the following conditions are met:',
        keys: [
          {
            label:
              'I. The amount of the new loan or other debt is at least 150 percent of the outstanding amount of the matured loan or other debt;',
            name: 'amountIsOneHundredFiftyPercent',
          },
          {
            label:
              'II. The new credit supported with SSBCI funding is based on a new underwriting of the small business’s ability to repay the loan and a new approval by the lender;',
            name: 'newCreditedBasedOnAbilityToRepayLoan',
          },
          {
            label:
              'III. The prior loan or other debt has been paid as agreed and the borrower was not in default of any financial covenants under the loan or debt for at least the previous 36 months (or since origination, if shorter); and',
            name: 'priorLoanOrDebtHasBeenPaid',
          },
          {
            label:
              'IV. Proceeds of the transaction are not used to finance an extraordinary dividend or other distribution',
            name: 'proceedsOfTransactionNotUsedToFinance',
          },
        ],
      },
    ],
  },
  complianceItemSeven: {
    name: 'complianceItemSeven',
    title:
      '7. Borrower/Investee and Loan/Investment Size Requirements (other programs such as Loan guaranty, loan participation, Collateral support, Venture Capital and other credit support programs).',
    tablesData: [
      {
        id: 1,
        promptItem: ' ',
        keys: [
          {
            label:
              'A. 1) to target an average borrower or investee size of 500 employees or less, ',
            name: 'targetBorrowerSizeFiveHundredOrLess',
          },
          {
            label:
              'B. (2) not to extend credit or investment support to borrowers or investees that have more than 750 employees,',
            name: 'notExtendCreditMoreThanSevenHundredFifty',
          },
          {
            label:
              'C. (3) to target support towards loans or investments with an average principal or investment amount of $5 million or less,',
            name: 'targetSupportFiveMillionOrLess',
          },
          {
            label:
              'D. (4) not to provide credit or investment support if a given transaction exceeds $20 million.',
            name: 'notProvideSupportIfExceedsTwentyMillion',
          },
          {
            label:
              'E. For loan programs, (3) and (4) apply to the principal amount of the loan directly supported by SSBCI, plus all other loans for the same loan purpose that close on or about the same date. ',
            name: 'applyToPrincipalAmountDirectlySupportedBySsbci',
          },
          {
            label:
              'F. For equity investment programs, (3) and (4) apply to a single investment round that includes an SSBCI-funded investment, including all classes of equity instruments that close on or about the same date. SSBCI support for an investment includes direct equity investments in small businesses made by a jurisdiction or its contractors, as well as investments in small businesses made by venture capital funds in which the jurisdiction invested SSBCI funds. The $20 million restriction cannot be avoided by dividing a larger loan into smaller loans or by creating separate equity instruments within an investment round.',
            name: 'applyToSingleInvestmentRound',
          },
        ],
      },
    ],
  },
  complianceItemEight: {
    name: 'complianceItemEight',
    title: '8. Relationship to Tax Credit Programs',
    markAsRead: true,
    tablesData: [
      {
        id: 1,
        noCheckboxes: true,
        keys: [
          {
            label:
              'A. Fund managers may not combine financing from private tax credit-supported entities (i.e., entities that are funded through the sale of tax credits they received from a state) and SSBCI-supported programs for the same business purpose, or within the same investment or loan fund.  However, jurisdiction agencies and non-profit jurisdiction-sponsored entities that receive funding from the sale of tax credits are permitted to combine these two types of funding for their loans and investments. For these entities, the tax credit funds are still not considered private capital.',
          },
          {
            label:
              'B. An SSBCI-supported transaction cannot be used by an entity to increase the pool of funds that generates New Markets Tax Credits or Historic Preservation Tax Credits. If, however, a transaction supported with SSBCI funds meets program requirements, an entity may use SSBCI funds alongside a transaction that generates tax credits.',
          },
        ],
      },
    ],
  },
  complianceItemNine: {
    name: 'complianceItemNine',
    title: '9. Lender Capital at Risk',
    markAsRead: true,
    tablesData: [
      {
        id: 1,
        headersHidden: true,
        promptItem:
          'A. The borrower and lender together can only contribute up to 7 percent of the loan amount to a reserve fund, and the jurisdiction matches that same contribution with SSBCI funds. ',
        promptItemNotes: [
          'At maximum contribution, the reserve fund only has 14 percent of each loan (7 percent from the borrower and lender together, and 7 percent from SSBCI funds), leaving the lender at risk for 86 percent of the loan.',
        ],
      },
    ],
  },
  complianceItemTen: {
    name: 'complianceItemTen',
    title: '10. Borrower and Loan Size Requirements',
    tablesData: [
      {
        id: 1,
        prompt: ' ',
        keys: [
          {
            label:
              'A. The borrower must have 500 employees or less at the time that the loan is enrolled in the program, and the loan cannot exceed $5 million.',
            name: 'borrowerHasLessThanFiveHundredEmployees',
          },
        ],
      },
    ],
  },
  equityProgramCompliance: {
    name: 'equityProgramCompliance',
    title: '1. Equity/Venture Capital Programs: Conflict-of-Interest Standards',
    markAsRead: true,
    tablesData: [
      {
        id: 1,
        noCheckboxes: true,
        headersHidden: true,
        prompt:
          'A. An “SSBCI insider” of an SSBCI equity/venture capital program is a person who, in the 12-month period preceding the date on which SSBCI support for a specific investment in a venture capital fund or company is closed or completed was:',
        notes: [
          'Exceptions to the general prohibition are as follows:',
          'A governmental entity or a jurisdiction-sponsored non-profit entity may use SSBCI funds for follow-on investments in companies or venture capital funds if the entity has an existing ownership or voting interest resulting from a prior investment of SSBCI funds or non-SSBCI funds. Furthermore, in this circumstance, the entity may authorize investments if an SSBCI insider serves on the board of directors of the company or venture capital fund, if an SSBCI insider does not have a personal financial interest in the company or venture capital fund and the entity’s prior financial interest is in compliance with all applicable laws and rules',
          'An independent non-profit or for-profit entity managing or investing SSBCI funds for an SSBCI equity/venture capital program is not precluded from authorizing follow-on investments using SSBCI funds in a company or venture capital fund in which the entity previously invested SSBCI funds or the entity has previously appointed a representative to serve on the board of directors in stewardship of the investment. However, such independent non-profit or for-profit entity may not authorize (or seek approval from the participating jurisdiction for) an investment of SSBCI funds in a company or venture capital fund in which the entity holds any type of financial interest resulting from an investment made with non-SSBCI funds.',
          'If a business is to receive the benefit of SSBCI funds through an equity/venture capital program, the certification related to sex offenders set forth in Section VII.f above must instead state that no principal of the investor or the investee has been convicted of a sex offense against a minor (as such terms are defined in section 111 of the Sex Offender Registration and Notification Act (42 U.S.C. § 16911)). For the purposes of this certification, “principal” is defined as if a sole proprietorship, the proprietor; if a partnership, each managing partner and each partner who is a natural person and holds 50 percent or more ownership interest of any class of the partnership interests; if a corporation, limited liability company, association, development company, or other entity, each director, each of the five most highly compensated executives or officers of the entity, and each natural person who is a direct or indirect holder of 50 percent or more of any class of equity interest in the entity; and if a partnership where the managing partner is a corporation, limited liability company, association, development company, or other entity, each director and each of the five most highly compensated executives or officers of the entity.',
        ],
        keys: [
          {
            label:
              'I.  a manager or staff member, whether by employment or contract, in the jurisdiction’s SSBCI equity/venture capital program;',
          },
          {
            label:
              'II. a government official with direct oversight or jurisdiction over an SSBCI equity/venture capital program, or such an official’s immediate supervisor;',
          },
          {
            label:
              'III. a member of the board of directors or similar body for a jurisdiction-sponsorednon-profit entity who, through such membership, has authority to vote on decisions to invest SSBCI funds or has authority over the employment or compensation of staff managing processes related to the investment of SSBCI funds;',
          },
          {
            label:
              'IV. a member of the board of directors or similar body for an independent non-profit or for-profit entity that operates an SSBCI equity/venture capital program; or',
          },
          {
            label:
              'V. an employee, volunteer, or contractor on an investment committee or similar body that recommends SSBCI investments for approval or approves SSBCI investments under the SSBCI equity/venture capital program; or',
          },
          {
            label:
              'VI. Exercised a controlling influence on jurisdiction decisions regarding:',
            additionalItems: [
              '1. The allocation of SSBCI funds among approved equity/venture capital programs;',
              '2. Eligibility criteria for the SSBCI equity/venture capital programs; or',
              '3. The processes for approving investments of SSBCI funds under the SSBCI equity/venture capital program',
            ],
          },
        ],
      },
      {
        id: 2,
        noCheckboxes: true,
        headersHidden: true,
        prompt:
          'B. A “business partner” of an SSBCI insider is a person who owns 10 percent or more of any class of equity interest, on a fully diluted basis, in any private entity in which an SSBCI insider also owns 10 percent or more of any class of equity interest on a fully diluted basis.',
      },
      {
        id: 3,
        headersHidden: true,
        noCheckboxes: true,
        prompt: 'C. A “family member” of an SSBCI insider means:',
        keys: [
          {
            label:
              'I. Such person’s spouse, domestic partner, parents, grandparents, children,grandchildren, brothers, sisters, stepbrothers, and stepsisters; and',
          },
          {
            label:
              'II. Any other relatives who live in the same household as the SSBCI insider.',
          },
        ],
      },
      {
        id: 4,
        headersHidden: true,
        prompt:
          'D. An “independent non-profit entity” means any non-profit entity that is not sponsored by the jurisdiction.',
      },
      {
        id: 5,
        headersHidden: true,
        prompt:
          'E. A “personal financial interest” means any financial interest derived from ownership or right to ownership of, or lending to or other investment in, a private, for-profit entity that may receive an SSBCI investment (including any financial interest derived from ownership or right to ownership of, or investment in, a venture capital fund).',
      },
      {
        id: 6,
        headersHidden: true,
        prompt:
          'F. A “jurisdiction-sponsored non-profit entity” is a non-profit entity that is: (1)(i) created by legislation to pursue policies of the jurisdiction’s government; or (ii) created by jurisdiction officials on behalf of the jurisdiction to pursue policies of the jurisdiction’s government; or (iii) privately created as a non-profit entity that is selected by jurisdiction officials pursuant to specific legislation to pursue public policies on behalf of the jurisdiction’s government; and',
      },
      {
        id: 7,
        headersHidden: true,
        prompt:
          'G. (2) over which the jurisdiction’s officials exercise a controlling influence through budgetary decisions or other legislative action or direction.',
      },
    ],
  },
};

export const handleComplianceItemChange = (increment) => {
  const { totalComplianceItems, currentComplianceItem } =
    applicationComplianceSignal.value;
  let newComplianceItem = currentComplianceItem + increment;
  if (newComplianceItem >= totalComplianceItems) {
    newComplianceItem = totalComplianceItems;
  }
  if (newComplianceItem < 1) {
    newComplianceItem = 1;
  }
  applicationComplianceSignal.update({
    currentComplianceItem: newComplianceItem,
  });
};

export const onComplianceChecklistChange = (e) => {
  const { name } = e.target;
  let { value } = e.target;

  if (value === 'true') {
    value = true;
  }
  if (value === 'false') {
    value = false;
  }
  if (value === applicationComplianceSignal.value.complianceChecklist[name]) {
    value = null;
  }
  applicationComplianceSignal.update({
    complianceChecklist: {
      ...applicationComplianceSignal.value.complianceChecklist,
      [name]: value,
    },
  });
};

export const updateNumberOfComplianceItems = (programId) => {
  let numComplianceItems = 8;
  switch (programId) {
    case 4:
      numComplianceItems = 10;
      break;
    case 7:
    case 8:
      numComplianceItems = 1;
      break;
    default:
      break;
  }

  applicationComplianceSignal.update({
    totalComplianceItems: numComplianceItems,
    currentComplianceItem: 1,
  });
};

export const handleMarkAsRead = (complianceItemName) => {
  applicationComplianceSignal.update({
    complianceChecklist: {
      ...applicationComplianceSignal.value.complianceChecklist,
      [complianceItemName]: true,
    },
  });
};

export const handleYesOrNoToAll = (value, complianceItemName, tableId) => {
  const tableData = COMPLIANCE_CHECK_DATA[complianceItemName].tablesData.find(
    (td) => td.id === tableId
  );
  const keys = tableData.keys.map((key) => key.name);
  const updatedChecklist = {
    ...applicationComplianceSignal.value.complianceChecklist,
  };

  if (value === 'yesToAll') {
    keys.forEach((key) => (updatedChecklist[key] = true));
  }

  if (value === 'noToAll') {
    keys.forEach((key) => (updatedChecklist[key] = false));
  }

  applicationComplianceSignal.update({ complianceChecklist: updatedChecklist });
};
