import { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { lenderProgramRequestsFilterSignal } from 'signals/LenderPrograms.signal';
import MyProgramRequestsTable from './components/MyProgramRequestsTable';
import { fetchAndSetProgramRequests } from './Programs.helpers';
import { useLocation } from 'react-router';
import { handleTabChange } from 'libs/functions/global.functions';
import BreadCrumbWrapper from '../BreadCrumbs/BreadCrumbs';
import { enumCaseToTabCase } from '../../../libs/string';
import $appSettings from 'signals/AppSettings.signal';

const Programs = () => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const { bundle } = lenderProgramRequestsFilterSignal.value;
  const { tally } = bundle;
  const location = useLocation();

  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const activeKey = searchParams.status;

  useEffect(() => {
    fetchAndSetProgramRequests();
  }, [location.search]);

  return (
    <BreadCrumbWrapper>
      <Tabs
        activeKey={activeKey || 'all'}
        defaultActiveKey="all"
        mountOnEnter
        onSelect={(status) => {
          handleTabChange(status);
          fetchAndSetProgramRequests();
        }}
      >
        <Tab eventKey="all" title={`All\n${bundle.count}`}>
          <div className="bg-white p-32 border border-gray shadow-sm">
            <MyProgramRequestsTable />
          </div>
        </Tab>
        {tally &&
          Object.values(APPLICATION_STATUS).map((status) => {
            const matchingTally = tally.find((t) => t.status === status);
            return (
              <Tab
                key={status}
                eventKey={status}
                title={`${enumCaseToTabCase(status)} ${
                  matchingTally?._count.status || 0
                }`}
              >
                <div className="bg-white p-32 border border-gray shadow-sm">
                  <MyProgramRequestsTable />
                </div>
              </Tab>
            );
          })}
      </Tabs>
    </BreadCrumbWrapper>
  );
};

export default Programs;
