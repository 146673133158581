import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { notificationDrawerSignal } from 'signals/Notification.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import DrawerNotifications from './components/DrawerNotifications';
import './notificationDrawer.scss';
import { fetchAndSetDrawerNotifications } from './NotificationDrawer.helpers';
import { Link, useLocation } from 'react-router-dom';

export const NotificationDrawer = () => {
  const location = useLocation();
  const portal = location.pathname.split('/')[1];
  const init = useCallback(async () => {
    const userData = userAccountSignal.value.userData;
    if (userData?.account.id) {
      await fetchAndSetDrawerNotifications();
    }
  }, []);

  useEffect(() => {
    init();
  }, [userAccountSignal.value.userData]);

  return (
    <Card
      id="notification-drawer"
      className={`
          ${notificationDrawerSignal.value.isOpen ? 'is-open' : ''}
          border-right-0 border-gray
        `}
    >
      <Card.Header>
        <Row>
          <Button
            className="mt-0 align-self-center"
            variant="none"
            onClick={() => notificationDrawerSignal.update({ isOpen: false })}
          >
            Close <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </Row>
      </Card.Header>
      <Card.Body className="notification-drawer-body border-0">
        <DrawerNotifications />
      </Card.Body>
      <div className="text-center px-24 pb-24">
        <hr />
        <Link
          to={`/${portal}/notifications`}
          onClick={() => notificationDrawerSignal.update({ isOpen: false })}
        >
          See All
        </Link>
      </div>
    </Card>
  );
};

export default NotificationDrawer;
