import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import ledgerSignal, { ledgerLossClaimSignal } from './Ledger.signals';
import {
  handleLossClaimFormChange,
  handleSubmitLossClaim,
  hideLossClaimFormModalOpen,
} from './Ledger.helpers';
import CloseModalButton from '../Constant/CloseModalButton';

const LedgerCreateLossClaimFormModal = () => {
  const { createLossClaimFormModalOpen } = ledgerSignal.value;
  const { liquidationProceeds } = ledgerLossClaimSignal.value;

  return (
    <ModalWrapper
      show={createLossClaimFormModalOpen}
      className="upper-modal"
      backdropClassName="upper-modal-backdrop"
      centered
      size="xl"
      onHide={hideLossClaimFormModalOpen}
    >
      <ModalHeader>
        <ModalTitle>Loss Claim</ModalTitle>
        <CloseModalButton onCloseModal={hideLossClaimFormModalOpen} />
      </ModalHeader>
      <ModalBody>
        <p>
          The EDO will evaluate the loss claim given the liquidation proceeds
          and will release the funds once the claim has been approved.
        </p>
        <Row>
          <Col xs={{ span: 6, offset: 3 }}>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Liquidation Proceeds
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="liquidationProceeds"
                placeholder="Enter the amount"
                value={liquidationProceeds.value}
                onChange={handleLossClaimFormChange}
              />
            </Form.Group>
            <Button
              className="rounded w-100"
              disabled={[liquidationProceeds].some((e) => !e)}
              onClick={() => handleSubmitLossClaim()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideLossClaimFormModalOpen()}
        >
          Dismiss
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default LedgerCreateLossClaimFormModal;
