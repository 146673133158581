import { Modal } from 'react-bootstrap';
import CloseModalButton from '../Constant/CloseModalButton';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import ProgramRequestDetail from './index';
import { hideProgramDetailInlineModal } from './ProgramRequestDetail.helpers';
import programRequestDetailSignal from './ProgramRequestDetail.signals';

const ProgramRequestDetailModal = () => (
  <div>
    <ModalWrapper
      show={programRequestDetailSignal.value.inlineModalVisible}
      onHide={hideProgramDetailInlineModal}
      size="xl"
      centered
      backdropClassName={null}
      className={null}
      onExit={null}
      onExited={null}
    >
      <Modal.Header className="pb-0 border-0 justify-content-end">
        <CloseModalButton onCloseModal={hideProgramDetailInlineModal} />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <ProgramRequestDetail isModal />
      </Modal.Body>
    </ModalWrapper>
  </div>
);

export default ProgramRequestDetailModal;
