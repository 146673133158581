import { useCallback, useEffect } from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import DeadlineSchedules from './components/ReportingDeadlineSchedules';
import {
  fetchAndSetAnnualReport,
  fetchAndSetPlatforms,
  fetchAndSetPotentialAllocation,
  handlePlatformSelection,
} from './Reporting.helpers';
import reportingSignals, {
  annualReportingSignal,
} from 'components/global/Reporting/Reporting.signals';
import QuarterlyReport from './components/QuarterlyReport';
import AnnualReporting from './components/AnnualReporting';

const Reporting = () => {
  const { platforms, selectedPlatform } = reportingSignals.value;

  const init = useCallback(() => {
    fetchAndSetPotentialAllocation();
    fetchAndSetPlatforms();
  }, [fetchAndSetPotentialAllocation]);

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="mb-32">
      <Row>
        <Col>
          <div className="border border-gray p-24 bg-white shadow-sm">
            <div className="d-flex justify-content-between">
              <h3 className="text-center">Treasury Reporting</h3>
              <div>
                <Form.Label className="mb-8 font-weight-bold text-center">
                  Platform
                </Form.Label>
                <Form.Select
                  type="select"
                  className="form-control"
                  name="platform"
                  value={selectedPlatform?.id || ''}
                  onChange={(e) => handlePlatformSelection(e)}
                >
                  <option disabled value="">
                    Select Option
                  </option>
                  {platforms.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <hr />
            {selectedPlatform && (
              <Tabs
                onSelect={(tabKey) =>
                  tabKey === 'annual' && !annualReportingSignal.value.reportData
                    ? fetchAndSetAnnualReport()
                    : null
                }
              >
                <Tab eventKey="quarterly" title="Quarterly">
                  <div className="bg-white px-32 py-24 border border-gray shadow-sm">
                    <QuarterlyReport />
                  </div>
                </Tab>
                <Tab eventKey="annual" title="Annual">
                  <div className="bg-white px-32 py-24 border border-gray shadow-sm">
                    <AnnualReporting />
                  </div>
                </Tab>
                <Tab eventKey="DeadlineSchedules" title="Deadline Schedules">
                  <div className="bg-white px-32 py-24 border border-gray shadow-sm">
                    <DeadlineSchedules />
                  </div>
                </Tab>
              </Tabs>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Reporting;
