import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import {
  handleAttachmentConfigureLoanDocuments,
  handleChangeAttachmentName,
  handleConfigureLoanChange,
  handleRemoveAttachment,
  handleSubmitLoanConfiguration,
  hideConfigureLoanModalOpen,
} from './Ledger.helpers';
import ledgerSignal, { ledgerConfigureLoanSignal } from './Ledger.signals';
import AmortizationSchedule from '../AmortizationSchedule/AmortizationSchedule';
import AddAttachmentsButton from '../AddAttachmentsButton';
import AttachmentListForm from '../AttachmentsListForm';
import CloseModalButton from '../Constant/CloseModalButton';

const LedgerConfigureLoanFormModal = () => {
  const { configureLoanFormModalOpen } = ledgerSignal.value;
  const {
    loadingSchedulePreview,
    schedulePreview,
    gracePeriodDays,
    firstRepaymentDate,
    interestRate,
    years,
    attachments,
  } = ledgerConfigureLoanSignal.value;

  return (
    <ModalWrapper show={configureLoanFormModalOpen} onHide={hideConfigureLoanModalOpen} centered size="xl">
      <ModalHeader className="justify-content-between">
        <ModalTitle>Configure Loan</ModalTitle>
        <CloseModalButton onCloseModal={hideConfigureLoanModalOpen} />
      </ModalHeader>
      <ModalBody>
        <Row className="mb-16">
          <Col xs={12} lg>
            <Form.Group className="form-group mb-0">
              <Form.Label className="font-weight-bold text-secondary">
                First Repayment Date
              </Form.Label>
              <Form.Control
                type="date"
                className="form-control"
                name="firstRepaymentDate"
                value={firstRepaymentDate.value}
                onChange={handleConfigureLoanChange}
                isInvalid={
                  !!firstRepaymentDate.errors.length && firstRepaymentDate.dirty
                }
              />
              <Form.Control.Feedback type="invalid">
                {firstRepaymentDate.errors.length
                  ? firstRepaymentDate.errors[0]
                  : ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group mb-0">
              <Form.Label className="font-weight-bold text-secondary">
                Years
              </Form.Label>
              <Form.Control
                type="number"
                max={50}
                min={1}
                step="1.0"
                className="form-control"
                name="years"
                value={years.value}
                onChange={handleConfigureLoanChange}
                isInvalid={!!years.errors.length && years.dirty}
              />
              <Form.Control.Feedback type="invalid">
                {years.errors.length ? years.errors[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group mb-0">
              <Form.Label className="font-weight-bold text-secondary">
                Interest Rate
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  max={99}
                  min={1}
                  step="1.0"
                  className="form-control"
                  name="interestRate"
                  value={interestRate.value}
                  onChange={handleConfigureLoanChange}
                  isInvalid={!!interestRate.errors.length && interestRate.dirty}
                />
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {interestRate.errors.length ? interestRate.errors[0] : ''}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} lg>
            <Form.Group className="form-group mb-0">
              <Form.Label className="font-weight-bold text-secondary">
                Grace Period
              </Form.Label>
              <Form.Control
                type="gracePeriodDays"
                max={30}
                min={1}
                step="1.0"
                className="form-control"
                name="gracePeriodDays"
                value={gracePeriodDays.value}
                onChange={handleConfigureLoanChange}
                isInvalid={
                  !!gracePeriodDays.errors.length && gracePeriodDays.dirty
                }
              />
              <Form.Control.Feedback type="invalid">
                {gracePeriodDays.errors.length ? gracePeriodDays.errors[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row className="align-items-center">
          <Col>
            <h6 className="font-weight-bold text-secondary">
              Closing Documents
            </h6>
          </Col>
          <Col className="text-right">
            <AddAttachmentsButton
              multiple={true}
              onChange={(e) =>
                handleAttachmentConfigureLoanDocuments({
                  target: { value: e.target.files, name: 'attachments' },
                })
              }
            />
          </Col>
        </Row>
        <Row className="mt-16">
          <Col>
            <AttachmentListForm
              onChange={handleChangeAttachmentName}
              onRemove={handleRemoveAttachment}
              attachments={attachments}
            />
          </Col>
        </Row>
        <div className="d-flex flex-row justify-content-end mt-8">
          <div>
            <Button
              disabled={([gracePeriodDays, interestRate, years, firstRepaymentDate]
                .map((a) => a)
                .some((e) => e.errors.length || !e.value)) || !attachments.length}
              className="rounded w-100"
              variant="primary"
              onClick={() => handleSubmitLoanConfiguration()}
            >
              Submit Loan Configuration
            </Button>
          </div>
        </div>
        <hr />

        <>
          {schedulePreview ? (
            loadingSchedulePreview ? (
              <p>Loading...</p>
            ) : (
              <>
                <h4 className="mb-4">Amortization Schedule Preview</h4>
                <AmortizationSchedule schedule={schedulePreview} loan={null} />
              </>
            )
          ) : (
            <Alert variant={'info'} className="text-center align-items-center">
              <p>Complete the form to see amortization schedule</p>
            </Alert>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideConfigureLoanModalOpen()}
        >
          Dismiss
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default LedgerConfigureLoanFormModal;
