import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import expressInterestSignal from "./ExpressInterest.signal";
import { handleExpressInterestFormChange, handleExpressInterestSubmission } from "./ExpressInterest.helpers";
import loaderSignal from "signals/Loader.signal";

const PORTAL_TYPES = [
  'LENDER',
  'BUSINESS',
  'VC',
];

const AddAccountForm = () => {
  const location = useLocation();

  useEffect(() => {
    const portal = location.pathname.split('/')[1].toUpperCase();
    const isValidPortal = PORTAL_TYPES.includes(portal);
    if (isValidPortal) {
      expressInterestSignal.update({ portal });
    }
  }, []);

  return (
    <>
      <Card className="mx-auto mt-80 " style={{ maxWidth: '500px' }}>
        <Card.Body>
          <Form onSubmit={handleExpressInterestSubmission}>
            <Row className="my-18">
              <Col className="text-center">
                <h4>Request Access To CAAT</h4>
              </Col>
            </Row>
            <p className="font-weight-bold mb-0">Account Information</p>
            <Row className="mb-4">
              <Col>
                <label>Organization Name</label>
                <Form.Control
                  id="organizationName"
                  name="organizationName"
                  required
                  placeholder="Organization Name"
                  value={expressInterestSignal.value.organizationName}
                  className="form-control mb-2"
                  onChange={handleExpressInterestFormChange}
                />
              </Col>
            </Row>
            <p className="font-weight-bold mb-0 mt-32">
              Owner Contact Information
            </p>
            <Row className="mb-4">
              <Col>
                <label>First Name</label>
                <Form.Control
                  id="firstName"
                  placeholder="First Name"
                  required
                  className="form-control"
                  name="firstName"
                  value={expressInterestSignal.value.firstName}
                  onChange={handleExpressInterestFormChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <label>Last Name</label>
                <Form.Control
                  id="lastName"
                  name="lastName"
                  required
                  placeholder="Last Name"
                  className="form-control mb-2"
                  value={expressInterestSignal.value.lastName}
                  onChange={handleExpressInterestFormChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <label>Email</label>
                <Form.Control
                  id="email"
                  name="email"
                  required
                  placeholder="Email"
                  className="form-control mb-2"
                  value={expressInterestSignal.value.email}
                  onChange={handleExpressInterestFormChange}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end pt-16 border-top">
              <Button
                type="submit"
                variant="primary"
                size="sm"
                disabled={loaderSignal.value.isContentLoading}
              >
                Submit Request
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddAccountForm;
