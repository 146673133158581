import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import history from '../../../history';
import ledgerSignal, {
  $ledgerLoanPerformance,
  ledgerAcceptLossClaimSignal,
  ledgerAddReimbursementSignal,
  ledgerBorrowerPaymentSignal,
  ledgerConfigureLoanSignal,
  ledgerLossClaimSignal,
} from './Ledger.signals';
import userAccountSignal from 'signals/UserAccount.signal';
import { uploadFilesToStorage } from 'libs/functions/global.functions';
import applicationDetailSignal from '../ApplicationDetail/ApplicationDetail.signal';

export const fetchAndSetApplication = async () => {
  const pathname = history.location.pathname;
  const split = pathname.split('/');

  let applicationId = Number(split[split.length - 1]);
  if (!applicationId) {
    applicationId = applicationDetailSignal.value.id;
  }

  loaderSignal.update({ isContentLoading: true });
  try {
    const [application] = await Promise.all([
      api.get({
        path: '/applications',
        options: {
          where: {
            id: applicationId,
          },
          include: {
            loan: {
              include: {
                payments: true,
                fees: true,
              },
            },
            lossClaim: true,
            stats: true,
            business: true,
            fundingProgramMembership: {
              include: {
                lender: true,
                fundingProgram: {
                  include: {
                    referenceProgram: true,
                    edo: true,
                  },
                },
              },
            },
            disbursements: {
              include: {
                reimbursements: {
                  include: {
                    transactions: {
                      include: {
                        creator: true,
                      },
                    },
                  },
                },
                originBankAccount: true,
                destinationBankAccount: true,
                transactions: {
                  include: {
                    creator: true,
                  },
                },
              },
            },
          },
        },
      }),
    ]);

    return ledgerSignal.update({
      application,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showAddReimbursementModal = () =>
  ledgerSignal.update({
    createReimbursementModalOpen: true,
  });

export const hideAddReimbursementModal = () =>
  ledgerSignal.update({
    createReimbursementModalOpen: false,
  });

export const handleAddReimbursementFormChange = (e) => {
  const { value, name } = e.target;

  return ledgerAddReimbursementSignal.update({
    [name]: value,
  });
};

export const handleAddBorrowerPaymentChange = (e) => {
  const { value, name } = e.target;

  return ledgerBorrowerPaymentSignal.update({
    [name]: {
      ...ledgerBorrowerPaymentSignal.value[name],
      value,
    },
  });
};

export const handleLossClaimFormChange = (e) => {
  const { value, name } = e.target;

  return ledgerLossClaimSignal.update({
    [name]: value,
  });
};

export const handleSubmitLossClaim = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting Loss Claim...',
  });
  try {
    const { liquidationProceeds } = ledgerLossClaimSignal.value;
    const { application } = ledgerSignal.value;

    const body = {
      data: {
        liquidationProceeds: Number(liquidationProceeds),
        applicationId: application.id,
      },
    };

    await api.post({
      path: '/lossClaims',
      body,
    });

    await fetchAndSetApplication();
    ledgerSignal.update({
      createLossClaimFormModalOpen: false,
    });
    ledgerLossClaimSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully submitted Loss Claim.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSubmitAcceptLossClaim = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating Loss Claim...',
  });
  try {
    const { coveredAmount, recycledAmount } = ledgerAcceptLossClaimSignal.value;
    const {
      application: { lossClaim },
    } = ledgerSignal.value;

    const body = {
      where: {
        id: lossClaim.id,
      },
      data: {
        coveredAmount: Number(coveredAmount.value),
        recycledAmount: Number(recycledAmount.value),
        status: 'APPROVED',
      },
    };

    await api.patch({
      path: '/lossClaims',
      body,
    });

    await fetchAndSetApplication();
    ledgerSignal.update({
      acceptLossClaimFormModalOpen: false,
    });
    ledgerAcceptLossClaimSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated Loss Claim.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSubmitReimbursement = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting reimbursement...',
  });
  try {
    const { amount, dueDate } = ledgerAddReimbursementSignal.value;
    const {
      application: { disbursements },
    } = ledgerSignal.value;

    const body = {
      data: {
        amount: Number(amount),
        dueDate,
        disbursementId: disbursements[0].id,
        creatorId: userAccountSignal.value.userData.id,
      },
    };

    await api.post({
      path: '/reimbursements',
      body,
    });

    await fetchAndSetApplication();
    ledgerSignal.update({
      createReimbursementModalOpen: false,
    });
    ledgerAddReimbursementSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully created a reimbursement.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSubmitBorrowerPayment = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting payment...',
  });
  try {
    const { amount, date } = ledgerBorrowerPaymentSignal.value;
    const { application } = ledgerSignal.value;

    const body = {
      where: {
        id: application.loan.id,
      },
      data: {
        loan: {
          connect: {
            id: application.loan.id,
          },
        },
        amount: Number(amount.value.replaceAll(',', '')),
        date: date.value,
      },
    };

    await api.post({
      path: '/loanPayments',
      body,
    });

    await fetchAndSetApplication();
    ledgerSignal.update({
      submitBorrowerPaymentModalOpen: false,
    });
    ledgerBorrowerPaymentSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully submitted a payment.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showLossClaimFormModalOpen = () =>
  ledgerSignal.update({
    createLossClaimFormModalOpen: true,
  });
export const hideLossClaimFormModalOpen = () =>
  ledgerSignal.update({
    createLossClaimFormModalOpen: false,
  });

export const showAcceptLossClaimFormModalOpen = () =>
  ledgerSignal.update({
    acceptLossClaimFormModalOpen: true,
  });
export const hideAcceptLossClaimFormModalOpen = () =>
  ledgerSignal.update({
    acceptLossClaimFormModalOpen: false,
  });

export const showConfigureLoanModalOpen = () =>
  ledgerSignal.update({
    configureLoanFormModalOpen: true,
  });
export const hideConfigureLoanModalOpen = () =>
  ledgerSignal.update({
    configureLoanFormModalOpen: false,
  });

export const showSubmitBorrowerPaymentModal = () =>
  ledgerSignal.update({
    submitBorrowerPaymentModalOpen: true,
  });
export const hideSubmitBorrowerPaymentModal = () =>
  ledgerSignal.update({
    submitBorrowerPaymentModalOpen: false,
  });

export const handleConfigureLoanChange = (e) => {
  const { value, name } = e.target;
  // eslint-disable-next-line
  const { loadingSchedulePreview, schedulePreview, ...state } =
    ledgerConfigureLoanSignal.value;
  const errors = validateConfigureLoan(
    Object.entries({
      ...state,
      [name]: { ...state[name], value },
    }).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }), {})
  );

  ledgerConfigureLoanSignal.update({
    [name]: {
      ...ledgerConfigureLoanSignal[name],
      value,
      dirty: true,
    },
  });

  Object.entries(errors).forEach(([key, value]) => {
    if (key !== 'attachments') {
      ledgerConfigureLoanSignal.update({
        [key]: { ...ledgerConfigureLoanSignal.value[key], errors: value },
      });
    }
  });

  if (Object.values(errors).every((v) => v.length === 0)) {
    fetchAmortizationSchedulePreview();
  }

  return ledgerConfigureLoanSignal;
};

const validateConfigureLoan = (state) => {
  const { gracePeriodDays, firstRepaymentDate, interestRate, years } = state;

  const errors = Object.keys(state).reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  );

  if (!years) {
    errors['years'].push('Cannot be empty.');
  } else if (Number(years) > 50 || Number(years) < 1) {
    errors['years'].push('Loan term must be between 1 and 50 years.');
  }

  if (!interestRate) {
    errors['interestRate'].push('Cannot be empty.');
  } else if (Number(interestRate) > 99 || Number(interestRate) < 1) {
    errors['interestRate'].push('Interest rate must be between 1 and 99.');
  }

  if (!gracePeriodDays) {
    errors['gracePeriodDays'].push('Cannot be empty.');
  } else if (Number(gracePeriodDays) > 30 || Number(gracePeriodDays) < 1) {
    errors['gracePeriodDays'].push(
      'Grace period must be between 1 and 30 days.'
    );
  }

  if (!firstRepaymentDate) {
    errors['firstRepaymentDate'].push('A date is required.');
  } else if (new Date(firstRepaymentDate).getTime() <= new Date().getTime()) {
    errors['firstRepaymentDate'].push('The date must be in the future.');
  }

  return errors;
};

export const handleEvaluateLossClaimChange = (e) => {
  const { value, name } = e.target;
  const cleanValue = value.replace(/\D+/g, '');

  const state = ledgerAcceptLossClaimSignal.value;
  const errors = validateEvaluateLossClaim(
    Object.entries({
      ...state,
      [name]: { ...state[name], value: cleanValue },
    }).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }), {})
  );

  ledgerAcceptLossClaimSignal.update({
    [name]: {
      ...ledgerAcceptLossClaimSignal[name],
      value: cleanValue,
      dirty: true,
    },
  });

  Object.entries(errors).forEach(([key, value]) => {
    ledgerAcceptLossClaimSignal.update({
      [key]: { ...ledgerAcceptLossClaimSignal.value[key], errors: value },
    });
  });

  return ledgerAcceptLossClaimSignal;
};

const validateEvaluateLossClaim = (state) => {
  const { recycledAmount, coveredAmount } = state;

  const errors = Object.keys(state).reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  );

  if (!coveredAmount) {
    errors['coveredAmount'].push('Covered amount must be greater than 0.');
  }

  if (recycledAmount === '' || Number(recycledAmount) < 0) {
    errors['recycledAmount'].push(
      'Recycled amount must be greater than or equal to zero.'
    );
  }

  return errors;
};

export const handleSubmitLoanConfiguration = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting...',
  });
  try {
    const {
      gracePeriodDays,
      firstRepaymentDate,
      interestRate,
      years,
      attachments,
    } = ledgerConfigureLoanSignal.value;

    const { application } = ledgerSignal.value;

    const filesSignedUrls = await uploadFilesToStorage(
      attachments.map((a) => a.file),
      'loanDocuments'
    );

    const body = {
      data: {
        application: {
          connect: { id: application.id },
        },
        amount: application.approvedAmount,
        firstRepaymentDate: firstRepaymentDate.value,
        interestRate: Number(interestRate.value),
        years: Number(years.value),
        gracePeriodDays: Number(gracePeriodDays.value),
        attachments: attachments.map((a, i) => ({
          fileTitle: a.name,
          filePath: filesSignedUrls[i],
          fileType: a.file.type,
        })),
      },
    };

    await api.post({
      path: '/loans',
      body,
    });

    await fetchAndSetApplication();
    ledgerSignal.update({
      configureLoanFormModalOpen: false,
    });
    ledgerConfigureLoanSignal.reset();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated loan configuration.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const fetchAmortizationSchedulePreview = async () => {
  ledgerConfigureLoanSignal.update({
    loadingSchedulePreview: true,
  });

  try {
    const { application } = ledgerSignal.value;
    const { gracePeriodDays, firstRepaymentDate, interestRate, years } =
      ledgerConfigureLoanSignal.value;

    const options = {
      data: {
        amount: application.approvedAmount,
        firstRepaymentDate: firstRepaymentDate.value,
        interestRate: Number(interestRate.value),
        years: Number(years.value),
        gracePeriodDays: Number(gracePeriodDays.value),
      },
    };

    const bundle = await api.get({
      path: '/loans/schedule/preview',
      options,
    });

    const schedule = bundle.schedule;
    ledgerConfigureLoanSignal.update({
      schedulePreview: schedule,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    ledgerConfigureLoanSignal.update({
      loadingSchedulePreview: false,
    });
  }
};

export const fetchAmortizationSchedule = async () => {
  ledgerSignal.update({
    loadingSchedule: true,
  });

  try {
    const { application } = ledgerSignal.value;

    const options = {
      where: {
        id: application.loan.id,
      },
      data: {
        sliceDate: new Date().toISOString().split('T')[0],
      },
    };

    const bundle = await api.get({
      path: '/loans/schedule',
      options,
    });

    const schedule = bundle.schedule;
    ledgerSignal.update({
      schedule,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    ledgerSignal.update({
      loadingSchedule: false,
    });
  }
};

export const handleAttachmentConfigureLoanDocuments = (e) => {
  const { value, name } = e.target;

  let attachments = [];
  for (let i = 0; i < value.length; i++) {
    attachments.push({ name: value[i].name.split('.')[0], file: value[i] });
  }
  return ledgerConfigureLoanSignal.update({
    [name]: [...ledgerConfigureLoanSignal.value.attachments, ...attachments],
  });
};

export const handleRemoveAttachment = (index) => {
  const { attachments } = ledgerConfigureLoanSignal.value;
  return ledgerConfigureLoanSignal.update({
    attachments: attachments.filter((_, i) => i !== index),
  });
};

export const handleChangeAttachmentName = (index, name) => {
  const { attachments } = ledgerConfigureLoanSignal.value;
  return ledgerConfigureLoanSignal.update({
    attachments: attachments.map((a, i) => {
      if (i === index) {
        return { ...a, name };
      }

      return a;
    }),
  });
};

export const handleLoanPerformanceChange = (e) => {
  const { name, value } = e;

  $ledgerLoanPerformance.update({
    [name]: {
      ...$ledgerLoanPerformance.value[name],
      value,
    },
  });
};

export const handleSubmitLoanPerformance = async () => {
  const { equityInvestmentGains, ssbciFundsLost, subsequentPrivateFinancing } =
    $ledgerLoanPerformance.value;
  const { application } = ledgerSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/loans',
      body: {
        action: 'updateInvestmentPerformance',
        where: {
          id: application.loan.id,
        },
        data: {
          historicTransactionData: {
            equityInvestmentGains,
            ssbciFundsLost,
            subsequentPrivateFinancing,
          },
        },
      },
    });

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully saved Investment Performance.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};
