import api from "api/api";
import alertSignal from "signals/Alert.signal";
import edoOrganizationSignal from "signals/EdoOrganization.signal";
import loaderSignal from "signals/Loader.signal";
import userAccountSignal from "signals/UserAccount.signal";
import validateEmailUtil from "utils/validateEmail";
import { validatePhoneNumber } from "utils/validatePhoneNumber";
import history from "../../../../history";
import queryAddresses from "utils/queryAddresses";

export const fetchAndSetEdo = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const edo = await api.get({
      path: '/edo',
      where: {
        accountId: userAccountSignal.value.userData.account.id,
      },
    });

    return mapEdoDataToSignal(edo[0]);
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const mapEdoDataToSignal = (edo) => {
  edoOrganizationSignal.update({
    organizationInfo: {
      id: edo.id,
      name: edo.name,
      contactName: edo.contactName,
      contactEmail: edo.contactEmail,
      phoneNumber: edo.phoneNumber,
      address: edo.address,
    },
    organizationInfoErrors: {},
  });
};

export const handleInputChange = (e) => {
  const { name, value } = e;

  edoOrganizationSignal.update({
    organizationInfo: {
      ...edoOrganizationSignal.value.organizationInfo,
      [name]: value,
    },
    organizationInfoErrors: {
      ...edoOrganizationSignal.value.organizationInfoErrors,
      [name]: false,
    },
  });
};

export const handleError = (name) => {
  let error = false;
  if (name === 'address') {
    if (
      !Object.keys(edoOrganizationSignal.value.organizationInfo?.address).length
    ) {
      return edoOrganizationSignal.update({
        organizationInfoErrors: {
          ...edoOrganizationSignal.value.organizationInfoErrors,
          address: true,
        },
      });
    }
  } else if (!edoOrganizationSignal.value.organizationInfo[name]) {
    return edoOrganizationSignal.update({
      organizationInfoErrors: {
        ...edoOrganizationSignal.value.organizationInfoErrors,
        [name]: true,
      },
    });
  } else if (name === 'contactEmail') {
    error = !validateEmailUtil(
      edoOrganizationSignal.value.organizationInfo[name]
    );
  } else if (name === 'phoneNumber') {
    error = !validatePhoneNumber(
      edoOrganizationSignal.value.organizationInfo[name]
    );
  }

  return edoOrganizationSignal.update({
    organizationInfoErrors: {
      ...edoOrganizationSignal.value.organizationInfoErrors,
      [name]: error,
    },
  });
};

export const submitOrganizationDetails = async () => {
  const { organizationInfo, organizationInfoErrors } =
    edoOrganizationSignal.value;
  Object.keys(organizationInfo).forEach((key) => {
    if (key !== 'id') {
      handleError(key);
    }
  });

  if (Object.values(organizationInfoErrors).includes(true)) {
    return alertSignal.update({
      type: 'notification',
      message: 'Cannot submit form when there are errors present',
    });
  }
  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Updating organization details...',
    });
    const updatedEdoDetails = await api.put({
      path: '/edo',
      body: {
        where: {
          id: organizationInfo.id,
        },
        data: {
          name: organizationInfo.name,
          contactName: organizationInfo.contactName,
          contactEmail: organizationInfo.contactEmail,
          address: organizationInfo.address,
          phoneNumber: organizationInfo.phoneNumber,
        }
      },
    });

    mapEdoDataToSignal(updatedEdoDetails);

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated organization.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetDestinationBankAccounts = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const destinationBankAccounts = await api.get({
      path: '/bankaccounts',
      options: {
        where: xFormAppliedFiltersToWhereClause(),
        orderBy: xFormSortToOrderByClause(),
        include: {
          account: {
            include: {
              lender: true,
              ventureCapitalFirm: true,
            },
          },
          bankAccountProgramAccesses: {
            include: {
              fundingProgram: {
                include: {
                  platform: true,
                  applications: {
                    include: {
                      business: true,
                    },
                  },
                },
              },
            },
          },
        },
      },
    });

    edoOrganizationSignal.update({ destinationBankAccounts });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleShowLinkedProgramDetails = (selectedBankAccount) =>
  edoOrganizationSignal.update({
    selectedBankAccount,
    linkedPrograms: selectedBankAccount.bankAccountProgramAccesses.map(
      (bapa) => bapa.fundingProgram
    ),
    linkedProgramsDetailModalState: 'VIEW',
  });

export const handleShowLinkedPlatformDetails = (selectedBankAccount) => {
  const linkedPrograms = selectedBankAccount.bankAccountProgramAccesses.map(
    (bapa) => bapa.fundingProgram
  );
  const linkedPlatforms = [];
  linkedPrograms.forEach((program) => {
    if (
      !linkedPlatforms.find((platform) => platform.id === program.platform.id)
    ) {
      linkedPlatforms.push(program.platform);
    }
  });
  edoOrganizationSignal.update({
    selectedBankAccount,
    linkedPlatforms,
    linkedPlatformsDetailModalVisible: true,
  });
};

export const handleHideLinkedProgramDetailsModal = () =>
  edoOrganizationSignal.update({
    selectedBankAccount: null,
    linkedProgramsDetailModalState: null,
  });

export const handleHideLinkedPlatformDetailsModal = () =>
  edoOrganizationSignal.update({
    selectedBankAccount: null,
    linkedPlatforms: [],
    linkedPlatformsDetailModalVisible: false,
  });

export const handleHideApplicationDetailsModal = () =>
  edoOrganizationSignal.update({
    selectedBankAccount: null,
    applicationDetailsModalVisible: false,
  });

export const handleShowApplicationDetails = (selectedBankAccount) =>
  edoOrganizationSignal.update({
    selectedBankAccount,
    applicationDetailsModalVisible: true,
  });

const xFormSortToOrderByClause = () => {
  const { sort } = edoOrganizationSignal.value;

  if (!sort) {
    return undefined;
  }

  if (sort.key === 'organizationType') {
    return [
      {
        account: {
          portalType: sort.dir,
        },
      },
    ];
  } else {
    return [{ [sort.key]: sort.dir }];
  }
};

export const handleOrganizationsListSortChange = (sort) =>
  edoOrganizationSignal.update({
    sort,
  });

export const resetDestinationBankAccountFilterInputs = () =>
  edoOrganizationSignal.update({ appliedFilters: {}, dropdownFilters: {} });

export const handleDestinationBankAccountFilterChange = (key, value) => {
  const dropdownFilters = edoOrganizationSignal.value.dropdownFilters;
  return edoOrganizationSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

export const handleDestinationBankAccountAppliedFiltersChange = (
  appliedFilters
) =>
  edoOrganizationSignal.update({
    appliedFilters,
  });

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    NOT: {
      accountId: userAccountSignal.value.userData.account.id,
    },
    bankAccountProgramAccesses: {
      some: {},
    },
  };

  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  if (params.organizationName) {
    where.account = {
      OR: [
        {
          lender: {
            name: {
              contains: params.organizationName,
              mode: 'insensitive',
            },
          },
        },
        {
          ventureCapitalFirm: {
            name: {
              contains: params.organizationName,
              mode: 'insensitive',
            },
          },
        },
      ],
    };
  }

  if (params.organizationType) {
    where.account = {
      ...where.account,
      portalType: params.organizationType,
    };
  }

  return where;
};

export const handleAddressSearchChange = (input) => {
  edoOrganizationSignal.update({
    organizationInfo: {
      ...edoOrganizationSignal.value.organizationInfo,
      address: {},
    },
    addressSearch: input,
    organizationInfoErrors: {
      ...edoOrganizationSignal.value.organizationInfoErrors,
      address: false,
    },
  });
};

export const handleSelectAddress = (option) => {
  edoOrganizationSignal.update({
    organizationInfo: {
      ...edoOrganizationSignal.value.organizationInfo,
      address: option,
    },
    addressSearch: option.formattedAddress,
    organizationInfoErrors: {
      ...edoOrganizationSignal.value.organizationInfoErrors,
      address: false,
    },
  });
};

export const getAddressOptions = async () => {
  const bingAddresses = await queryAddresses(
    edoOrganizationSignal.value.addressSearch
  );
  edoOrganizationSignal.update({
    addressOptions: bingAddresses.map((ba) => ba),
  });
};

export const handleAddressBlur = () => {
  if (
    !Object.keys(edoOrganizationSignal.value.organizationInfo?.address).length
  ) {
    edoOrganizationSignal.update({
      organizationInfoErrors: {
        ...edoOrganizationSignal.value.organizationInfoErrors,
        address: true,
      },
    });
  }
};

export const handleAddressSearchFocus = () => {
  edoOrganizationSignal.update({
    addressSearch:
      edoOrganizationSignal.value.organizationInfo?.address?.formattedAddress ||
      edoOrganizationSignal.value.addressSearch,
  });
};
