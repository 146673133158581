import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { applicationDraftSignal } from 'signals/SubmitApplication.signal';
import {
  handleContinueApplicationDraftClick,
  handleTakeDraftAccess,
  hydrateApplicationDataFromDraft,
  reset,
  terminateApplicationDraft,
} from '../SubmitApplications.helpers';
import {
  Alert,
  Button,
  Dropdown,
  Modal,
  ProgressBar,
  Table,
} from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import userAccountSignal from 'signals/UserAccount.signal';

const ChooseApplicationDraftModal = () => {
  const { showDraftModal, draftModalState } = applicationDraftSignal.value;

  const modalState = () => {
    let state = <ChooseDraft />;

    switch (draftModalState) {
      case 'chooseDraft':
        state = <ChooseDraft />;
        break;
      case 'requestAccess':
        state = <RequestAccess />;
        break;
      case 'accessRequested':
        state = <AccessRequested />;
        break;
      case 'accessDenied':
        state = <AccessDenied />;
        break;
      case 'accessGranted':
        state = <AccessGranted />;
      default:
        break;
    }
    return state;
  };

  return (
    <ModalWrapper
      size="xl"
      show={showDraftModal}
      centered
      onHide={() => applicationDraftSignal.update({ showDraftModal: false })}
      onExited={() =>
        applicationDraftSignal.update({
          draftModalState: 'chooseApplication',
        })
      }
      onExiting={() => {
        applicationDraftSignal.update({ draftPendingRequest: {} });
      }}
    >
      <Modal.Header className="justify-content-between border-0 pb-0">
        <h3>Application Drafts</h3>
        <CloseModalButton
          onCloseModal={() =>
            applicationDraftSignal.update({ showDraftModal: false })
          }
        />
      </Modal.Header>
      <hr />
      <Modal.Body className="pt-0">{modalState()}</Modal.Body>
    </ModalWrapper>
  );
};

const ChooseDraft = () => {
  const { applicationDrafts } = applicationDraftSignal.value;
  return (
    <>
      <Alert>
        You have a saved application draft. Would you like to continue where you
        left off?
      </Alert>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Business EIN</th>
            <th>Program</th>
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {applicationDrafts.map((draft) => (
            <tr key={draft.id}>
              <td>{draft.id}</td>
              <td>{draft.businessEin}</td>
              <td>{draft.fundingProgram?.name}</td>
              <td className="text-right">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary" siza="sm">Actions</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleContinueApplicationDraftClick(draft)} // should lock this draft at this point
                    >
                      {draft.lockUserId &&
                      draft.lockUserId !== userAccountSignal.value.userData.id
                        ? 'Locked'
                        : 'Continue'}
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={
                        draft.lockUserId &&
                        draft.lockUserId !== userAccountSignal.value.userData.id
                      }
                      className="rounded mr-8"
                      variant="outline-danger"
                      onClick={() => terminateApplicationDraft(draft)}
                    >
                      Terminate
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <hr />
      <div className="d-flex justify-content-end">
        <Button
          className="rounded mr-16"
          variant="outline-secondary"
          onClick={() => {
            applicationDraftSignal.update({ showDraftModal: false });
          }}
        >
          Dismiss
        </Button>
        <Button
          className="rounded"
          onClick={() => {
            applicationDraftSignal.update({
              showDraftModal: false,
              selectedDraft: {},
            });
            reset();
          }}
        >
          {/* Possibly want to delete draft if user says no */}
          Start a new application
        </Button>
      </div>
    </>
  );
};

const RequestAccess = () => {
  const { draftPendingRequest } = applicationDraftSignal.value;

  return (
    <>
      <Alert>
        {draftPendingRequest.lockUser?.firstName}{' '}
        {draftPendingRequest.lockUser?.lastName} began editing this application
        draft at{' '}
        {new Date(draftPendingRequest.lockStartDate)?.toLocaleTimeString()}.
        Would you like to take over editing this application?
      </Alert>
      <div className="d-flex justify-content-end">
        <Button
          variant="secondary"
          className="rounded mr-16"
          onClick={() =>
            applicationDraftSignal.update({
              draftModalState: 'chooseApplication',
              draftPendingRequest: {},
            })
          }
        >
          Go Back
        </Button>
        <Button className="rounded" onClick={handleTakeDraftAccess}>
          Take Access
        </Button>
      </div>
    </>
  );
};

const AccessRequested = () => (
  <>
    <Alert>Requesting access...</Alert>
    <ProgressBar animated now={100} />
    <div className="d-flex justify-content-end mt-16">
      <Button
        variant="secondary"
        className="rounded"
        onClick={() =>
          applicationDraftSignal.update({ draftModalState: 'chooseDraft' })
        }
      >
        Cancel
      </Button>
    </div>
  </>
);

const AccessDenied = () => (
  <>
    <Alert variant="warning">
      You were denied access to edit the selected draft.
    </Alert>
    <div className="d-flex justify-content-end">
      <Button
        variant="secondary"
        className="rounded"
        onClick={() =>
          applicationDraftSignal.update({ draftModalState: 'chooseDraft' })
        }
      >
        Go Back
      </Button>
    </div>
  </>
);

const AccessGranted = () => (
  <>
    <Alert variant="success">
      You were granted access to edit the selected draft.
    </Alert>
    <div className="d-flex justify-content-end">
      <Button
        variant="secondary"
        className="rounded mr-16"
        onClick={() =>
          applicationDraftSignal.update({ draftModalState: 'chooseDraft' })
        }
      >
        Cancel
      </Button>
      <Button
        className="rounded mr-16"
        onClick={() =>
          hydrateApplicationDataFromDraft(
            applicationDraftSignal.value.draftPendingRequest
          )
        }
      >
        Edit Draft
      </Button>
    </div>
  </>
);

export default ChooseApplicationDraftModal;
