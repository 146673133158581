import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProgramsOfferedModal = ({ show, selectedOpportunity, onHide }) => {
  if (!selectedOpportunity) {
    return null;
  }

  return (
    <ModalWrapper size="lg" show={show} onHide={onHide} centered>
      <Modal.Header>
        <h5>{selectedOpportunity.name}</h5>
        <CloseModalButton onCloseModal={onHide} />
      </Modal.Header>
      <Modal.Body>
        {selectedOpportunity.fundingProgramMemberships.length ? (
          <Table>
            <thead>
              <tr>
                <th>Program</th>
              </tr>
            </thead>
            <tbody>
              {selectedOpportunity.fundingProgramMemberships.map((fpm) => (
                <tr key={fpm.fundingProgram.id}>
                  <td><Link to={`/business/funding-programs/${fpm.fundingProgram.id}`}>{fpm.fundingProgram.name}</Link></td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="warning">
            This bank account does not have any linked programs.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          className="rounded"
          onClick={onHide}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ProgramsOfferedModal;