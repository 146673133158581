import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import './searchInput.scss';

const SearchInput = ({ placeholder = 'Search', onChange }) => (
  <Form.Group className="form-group search-input">
    <Form.Control type="text" className="form-control" placeholder={placeholder} onChange={onChange} />
    <FontAwesomeIcon icon={faSearch} />
  </Form.Group>
);

export default SearchInput;
