import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import applicationComplianceSignal from './ApplicationCompliance.signal';
import ComplianceChecklistItem from './ComplianceChecklistItem';
import { COMPLIANCE_CHECK_DATA } from './ApplicationCompliance.helpers';

const ApplicationCompliance = ({ referenceProgramId, complianceChecklist }) => {
  useEffect(() => {
    applicationComplianceSignal.update({
      isApplicationDetailsView: true,
      complianceChecklist,
    });
    
  }, [complianceChecklist]);

  return (
    <Container className="my-16">
      <Row>
        <Col>
          <h5>Compliance</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <ComplianceItems referenceProgramId={referenceProgramId} />
        </Col>
      </Row>
    </Container>
  );
};

const ComplianceItems = ({ referenceProgramId }) => {
  if (
    referenceProgramId === 1 ||
    referenceProgramId === 2 ||
    referenceProgramId === 3 ||
    referenceProgramId === 4 ||
    referenceProgramId === 12
  ) {
    return (
      <>
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemOne}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemTwo}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemThree}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemFour}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemFive}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemSix}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemSeven}
        />
        <ComplianceChecklistItem
          complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemEight}
        />
        {referenceProgramId === 4 && (
          <>
            <ComplianceChecklistItem
              complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemNine}
            />
            <ComplianceChecklistItem
              complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemTen}
            />
          </>
        )}
      </>
    );
  }

  if (referenceProgramId === 7 || referenceProgramId === 8) {
    return (
      <ComplianceChecklistItem
        complianceItemData={COMPLIANCE_CHECK_DATA.equityProgramCompliance}
      />
    );
  }

  return null;
};

export default ApplicationCompliance;
