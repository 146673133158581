import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  businessSettingsErrorsSignal,
  businessSettingsSignal,
} from 'signals/BusinessSettings.signal';
import { handleBusinessFormChange } from '../BusinessSettings.helpers';
import BusinessUnsavedChangesAlert from './BusinessUnsavedChangesAlert';

const GENDER_VALUES = ['male', 'female', 'nonBinary', 'decline'];

const genderPreferToSelfDescribeInitValue = (gender) => {
  if (!gender) {
    return false;
  }

  if (GENDER_VALUES.includes(gender)) {
    return false;
  }

  return true;
};

const BusinessDemographics = () => {
  const businessForm = businessSettingsSignal.value;
  const businessFormErrors = businessSettingsErrorsSignal.value;

  const [genderPreferToSelfDescribe, setGenderPreferToSelfDescribe] = useState(
    genderPreferToSelfDescribeInitValue(businessForm['demographics.gender'])
  );

  useEffect(() => {
    setGenderPreferToSelfDescribe(
      genderPreferToSelfDescribeInitValue(businessForm['demographics.gender'])
    );
  }, []);

  return (
    <>
      <Row className="align-items-center px-16">
        <h4 className="mb-0">Demographics</h4>
        <BusinessUnsavedChangesAlert />
      </Row>
      <hr />
      <Row>
        <Col>
          <small className="text-right">
            * Select at least one from each numbered category
          </small>
          <p className="font-weight-bold">1. Ethnicity</p>
          <Row>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Hispanic or Latino/a"
                name="demographics.ethnicity.hispanicOrLatino"
                type="radio"
                id="demographics.ethnicity.hispanicOrLatino_yes"
                checked={
                  businessForm['demographics.ethnicity.hispanicOrLatino'] ===
                  'yes'
                }
                value={'yes'}
                onChange={(e) => handleBusinessFormChange(e)}
              />
            </Col>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Not Hispanic or Latino/a"
                name="demographics.ethnicity.hispanicOrLatino"
                type="radio"
                id="demographics.ethnicity.hispanicOrLatino_no"
                checked={
                  businessForm['demographics.ethnicity.hispanicOrLatino'] ===
                  'no'
                }
                value={'no'}
                onChange={(e) => handleBusinessFormChange(e)}
              />
            </Col>
          </Row>
          <Form.Check
            className="mb-8"
            label="Prefer not to respond"
            name="demographics.ethnicity.hispanicOrLatino"
            type="radio"
            id="demographics.ethnicity.hispanicOrLatino"
            checked={
              businessForm['demographics.ethnicity.hispanicOrLatino'] ===
              'decline'
            }
            value={'decline'}
            onChange={(e) => handleBusinessFormChange(e)}
          />
          <small hidden={!businessFormErrors.ethnicity} className="text-danger">
            Please select one.
          </small>
          <hr />
          <p className="font-weight-bold">2. Race (select all that apply)</p>
          <Row>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="American Indian or Alaska Native"
                name="demographics.race.americanIndianOrAlaskaNative"
                type="checkbox"
                id="demographics.race.americanIndianOrAlaskaNative"
                checked={
                  businessForm[
                    'demographics.race.americanIndianOrAlaskaNative'
                  ] === true
                }
                onChange={(e) => handleBusinessFormChange(e)}
              />
              <Form.Check
                className="mb-8"
                label="Asian"
                name="asian"
                type="checkbox"
                id="asian"
                readOnly
                checked={[
                  'demographics.race.asianIndian',
                  'demographics.race.asianChinese',
                  'demographics.race.asianFilipino',
                  'demographics.race.asianJapanese',
                  'demographics.race.asianKorean',
                  'demographics.race.asianVietnamese',
                  'demographics.race.asianOther',
                ].some((k) => businessForm[k])}
              />
              <div className="ml-24">
                <Form.Check
                  className="mb-8"
                  label="Indian"
                  name="demographics.race.asianIndian"
                  type="checkbox"
                  id="demographics.race.asianIndian"
                  checked={
                    businessForm['demographics.race.asianIndian'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Chinese"
                  name="demographics.race.asianChinese"
                  type="checkbox"
                  id="demographics.race.asianChinese"
                  checked={
                    businessForm['demographics.race.asianChinese'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Filipino"
                  name="demographics.race.asianFilipino"
                  type="checkbox"
                  id="demographics.race.asianFilipino"
                  checked={
                    businessForm['demographics.race.asianFilipino'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Japanese"
                  name="demographics.race.asianJapanese"
                  type="checkbox"
                  id="demographics.race.asianJapanese"
                  checked={
                    businessForm['demographics.race.asianJapanese'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Korean"
                  name="demographics.race.asianKorean"
                  type="checkbox"
                  id="demographics.race.asianKorean"
                  checked={
                    businessForm['demographics.race.asianKorean'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Vietnamese"
                  name="demographics.race.asianVietnamese"
                  type="checkbox"
                  id="demographics.race.asianVietnamese"
                  checked={
                    businessForm['demographics.race.asianVietnamese'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Asian (Other)"
                  name="demographics.race.asianOther"
                  type="checkbox"
                  id="demographics.race.asianOther"
                  checked={
                    businessForm['demographics.race.asianOther'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
              </div>
            </Col>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Black or African American"
                name="demographics.race.blackOrAfricanAmerican"
                type="checkbox"
                id="demographics.race.blackOrAfricanAmerican"
                checked={
                  businessForm['demographics.race.blackOrAfricanAmerican'] ===
                  true
                }
                onChange={(e) => handleBusinessFormChange(e)}
              />
              <Form.Check
                className="mb-8"
                label="Native Hawaiian or Other Pacific Islander"
                name="hawaiianOrPacificIslander"
                type="checkbox"
                id="hawaiianOrPacificIslander"
                readOnly
                checked={[
                  'demographics.race.guamanianOrChamorro',
                  'demographics.race.nativeHawaiian',
                  'demographics.race.samoan',
                  'demographics.race.pacificIslanderOther',
                ].some((k) => businessForm[k])}
              />
              <div className="ml-24">
                <Form.Check
                  className="mb-8"
                  label="Guamanian or Chamorro"
                  name="demographics.race.guamanianOrChamorro"
                  type="checkbox"
                  id="demographics.race.guamanianOrChamorro"
                  checked={
                    businessForm['demographics.race.guamanianOrChamorro'] ===
                    true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Native Hawaiian"
                  name="demographics.race.nativeHawaiian"
                  type="checkbox"
                  id="demographics.race.nativeHawaiian"
                  checked={
                    businessForm['demographics.race.nativeHawaiian'] === true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Samoan"
                  name="demographics.race.samoan"
                  type="checkbox"
                  id="demographics.race.samoan"
                  checked={businessForm['demographics.race.samoan'] === true}
                  onChange={(e) => handleBusinessFormChange(e)}
                />
                <Form.Check
                  className="mb-8"
                  label="Pacific Islander (Other)"
                  name="demographics.race.pacificIslanderOther"
                  type="checkbox"
                  id="demographics.race.pacificIslanderOther"
                  checked={
                    businessForm['demographics.race.pacificIslanderOther'] ===
                    true
                  }
                  onChange={(e) => handleBusinessFormChange(e)}
                />
              </div>
              <Form.Check
                className="mb-8"
                label="White"
                name="demographics.race.white"
                type="checkbox"
                id="demographics.race.white"
                checked={businessForm['demographics.race.white'] === true}
                onChange={(e) => handleBusinessFormChange(e)}
              />
              <Form.Check
                className="mb-8"
                label="Prefer not to respond"
                name="demographics.race.preferNotToRespond"
                type="checkbox"
                id="demographics.race.racePreferNotToRespond"
                checked={businessForm['demographics.race.preferNotToRespond']}
                onChange={(e) => handleBusinessFormChange(e)}
              />
            </Col>
          </Row>
          <small hidden={!businessFormErrors.race} className="text-danger">
            Please select at least one.
          </small>
          <hr />
          <p className="font-weight-bold">
            3. Middle Eastern or North African Ancestry
          </p>
          <Row>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Middle Eastern or North African"
                name="demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican"
                type="radio"
                id="demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican_yes"
                checked={
                  businessForm[
                    'demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican'
                  ] === 'yes'
                }
                value={'yes'}
                onChange={(e) => handleBusinessFormChange(e)}
              />
            </Col>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Not Middle Eastern or North African"
                name="demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican"
                type="radio"
                id="demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican_no"
                checked={
                  businessForm[
                    'demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican'
                  ] === 'no'
                }
                value={'no'}
                onChange={(e) => handleBusinessFormChange(e)}
              />
            </Col>
          </Row>
          <Form.Check
            className="mb-8"
            label="Prefer not to respond"
            name="demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican"
            type="radio"
            id="demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican_decline"
            checked={
              businessForm[
                'demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican'
              ] === 'decline'
            }
            value={'decline'}
            onChange={(e) => handleBusinessFormChange(e)}
          />
          <hr />
          <Row>
            <Col xl={4} className="border-right">
              <p className="font-weight-bold">4. Gender</p>
              <Form.Check
                className="mb-8"
                label="Female"
                name="demographics.gender"
                type="radio"
                id="demographics.gender_female"
                checked={businessForm['demographics.gender'] === 'female'}
                onChange={(e) => {
                  handleBusinessFormChange(e);
                  setGenderPreferToSelfDescribe(false);
                }}
                value={'female'}
              />
              <Form.Check
                className="mb-8"
                label="Male"
                name="demographics.gender"
                type="radio"
                id="demographics.gender_male"
                checked={businessForm['demographics.gender'] === 'male'}
                onChange={(e) => {
                  handleBusinessFormChange(e);
                  setGenderPreferToSelfDescribe(false);
                }}
                value={'male'}
              />
              <Form.Check
                className="mb-8"
                label="Nonbinary"
                name="demographics.gender"
                type="radio"
                id="demographics.gender_nonBinary"
                checked={businessForm['demographics.gender'] === 'nonBinary'}
                onChange={(e) => {
                  handleBusinessFormChange(e);
                  setGenderPreferToSelfDescribe(false);
                }}
                value={'nonBinary'}
              />
              <Row>
                <Col>
                  <Form.Check
                    className="mb-8"
                    label="Prefer to self-describe"
                    name="genderPreferToSelfDescribe"
                    type="radio"
                    id="genderPreferToSelfDescribe"
                    checked={!!genderPreferToSelfDescribe}
                    onChange={() => {
                      setGenderPreferToSelfDescribe(true);
                      handleBusinessFormChange({
                        target: {
                          name: 'demographics.gender',
                          value: '',
                        },
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="demographics.gender"
                    id="demographics.gender_preferToSelfDescribe"
                    disabled={!genderPreferToSelfDescribe}
                    style={{ height: 32 }}
                    onChange={(e) => {
                      handleBusinessFormChange(e);
                      setGenderPreferToSelfDescribe(false);
                    }}
                  />
                </Col>
              </Row>
              <Form.Check
                className="mb-8"
                label="Prefer not to respond"
                name="demographics.gender"
                type="radio"
                id="demographics.gender_decline"
                checked={businessForm['demographics.gender'] === 'decline'}
                onChange={(e) => {
                  handleBusinessFormChange(e);
                  setGenderPreferToSelfDescribe(false);
                }}
                value={'decline'}
              />
            </Col>
            <Col>
              <p className="font-weight-bold">5. Sexual Orientation</p>
              <Form.Check
                className="mb-8"
                label="Lesbian or Gay"
                name="demographics.sexualOrientation"
                type="radio"
                id="demographics.sexualOrientation_lesbianOrGay"
                checked={
                  businessForm['demographics.sexualOrientation'] ===
                  'lesbianOrGay'
                }
                onChange={(e) => handleBusinessFormChange(e)}
                value={'lesbianOrGay'}
              />
              <Form.Check
                className="mb-8"
                label="Bisexual"
                name="demographics.sexualOrientation"
                type="radio"
                id="demographics.sexualOrientation_bisexual"
                checked={
                  businessForm['demographics.sexualOrientation'] === 'bisexual'
                }
                onChange={(e) => handleBusinessFormChange(e)}
                value={'bisexual'}
              />
              <Form.Check
                className="mb-8"
                label="Straight, that is, not gay, lesbian, or bisexual"
                name="demographics.sexualOrientation"
                type="radio"
                id="demographics.sexualOrientation_straight"
                checked={
                  businessForm['demographics.sexualOrientation'] === 'straight'
                }
                onChange={(e) => handleBusinessFormChange(e)}
                value={'straight'}
              />
              <Form.Check
                className="mb-8"
                label="Something else"
                name="demographics.sexualOrientation"
                type="radio"
                id="demographics.sexualOrientation_somethingElse"
                checked={
                  businessForm['demographics.sexualOrientation'] ===
                  'somethingElse'
                }
                onChange={(e) => handleBusinessFormChange(e)}
                value={'somethingElse'}
              />
              <Form.Check
                className="mb-8"
                label="Prefer not to respond"
                name="demographics.sexualOrientation"
                type="radio"
                id="demographics.sexualOrientation_decline"
                checked={
                  businessForm['demographics.sexualOrientation'] === 'decline'
                }
                onChange={(e) => handleBusinessFormChange(e)}
                value={'decline'}
              />
              <small
                hidden={!businessFormErrors.sexualOrientation}
                className="text-danger"
              >
                Please select one.
              </small>
            </Col>
          </Row>
          <hr />
          <p className="font-weight-bold">6. Veteran Status</p>
          <Row>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Veteran"
                name="demographics.veteranStatus"
                type="radio"
                id="demographics.veteranStatus_yes"
                checked={businessForm['demographics.veteranStatus'] === 'yes'}
                onChange={(e) => handleBusinessFormChange(e)}
                value={'yes'}
              />
            </Col>
            <Col xl={4}>
              <Form.Check
                className="mb-8"
                label="Non-veteran"
                name="demographics.veteranStatus"
                type="radio"
                id="demographics.veteranStatus_no"
                checked={businessForm['demographics.veteranStatus'] === 'no'}
                onChange={(e) => handleBusinessFormChange(e)}
                value={'no'}
              />
            </Col>
          </Row>
          <Form.Check
            className="mb-8"
            label="Prefer not to respond"
            name="demographics.veteranStatus"
            type="radio"
            id="demographics.veteranStatus_decline"
            checked={businessForm['demographics.veteranStatus'] === 'decline'}
            onChange={(e) => handleBusinessFormChange(e)}
            value={'decline'}
          />
        </Col>
      </Row>
    </>
  );
};

export default BusinessDemographics;
