export const allTransactionMetrics = {
  totalVolume: 17513161,
  avgTransaction: 63453,
  monthToDate: {
    paymentVolume: 8209811,
    transactions: 79,
    avgTransactions: 103921,
  },
  yearToDate: {
    paymentVolume: 42871932,
    transactions: 402,
    avgTransactions: 106646,
  },
  charts: {
    transactionBreakDownDataSet: {
      labels: ['Compliant', 'Non Compliant', 'Denied'],
      datasets: [
        {
          label: 'Automated Compliance',
          data: [220, 30, 26],
          backgroundColor: ['#5FA37E', '#EE7811', '#DE4D64'],
        },
      ],
    },
    totalTransactionVolume: {
      labels: [
        '2/15',
        '2/22',
        '3/1',
        '3/15',
        '3/22',
        '3/29',
        '4/5',
        '4/12',
        '4/19',
        '4/26',
        '5/3',
      ],
      datasets: [
        {
          label: '',
          data: [200, 180, 150, 110, 110, 90, 80, 80, 60, 40, 30],
          backgroundColor: ['#5579D4'],
        },
      ],
    },
  },
};
export const transactionLeverageMetrics = {
  main: {
    allocation: {
      projected: 1000000000,
      actual: 42871932,
    },
    matching: {
      projected: 1000000000,
      actual: 58423188,
    },
    aggregate: {
      projected: 2000000000,
      actual: 124646632,
    },
  },
  years: {
    2022: {
      allocation: 1000000,
      matching: 2000000,
      aggregate: 3000000,
    },
    2023: {
      allocation: 100000000,
      matching: 100000000,
      aggregate: 200000000,
    },
  },
};

export default allTransactionMetrics;
