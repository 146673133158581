import { Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';
import fundDetailsSignal from 'components/global/FundDetails/fundDetails.signal';
import { fetchAndSetFund } from 'components/global/FundDetails/fundDetails.helpers';
import { handleAddToPortfolioCompaniesClick } from './vcFundPortfolioCompanyRequestDetail.helpers';

const VCFundPortfolioCompanyRequestDetail = () => {
  const { pathname } = useLocation();
  const fundId = pathname.split('/')[pathname.split('/').length - 3];
  const businessId = pathname.split('/')[pathname.split('/').length - 1];
  const { name, portfolioCompanies } = fundDetailsSignal.value;
  const { selectedOrganization: selectedBusiness } =
    organizationDetailsSignal.value; 
  const isPortfolioCompany = portfolioCompanies.find(pc => pc.businessId === Number(businessId));

  const labels = [
    'Funds',
    name,
    `Portfolio Company Request - ${selectedBusiness?.name}`,
  ];

  const init = useCallback(async () => {
    await fetchAndSetFund(fundId);
    await fetchOrganization(businessId, '/businesses');
  });

  useEffect(() => {
    init();
  }, [businessId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card>
        <Card.Body>
          <OrganizationDetails
            headerButton={{
              label: isPortfolioCompany ? 'Business is a Portfolio Company' : 'Add to Portfolio Companies',
              onClick: () => handleAddToPortfolioCompaniesClick(businessId, fundId),
              disabled: isPortfolioCompany,
            }}
          />
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default VCFundPortfolioCompanyRequestDetail;
