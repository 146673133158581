import ModalWrapper from "components/global/ModalWrapper/ModalWrapper";
import VCDealFlowsSignal from "../VCDealFlows.signal";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import OrganizationDetails from "components/global/OrganizationsList/components/OrganizationDetails";
import FundInformation from "./FundInformation";
import CloseModalButton from "components/global/Constant/CloseModalButton";
import { handleCloseDetailModal } from "../VCDealFlows.helpers";

const VCPortfolioCompanyDetailsModal = () => {
  const { showDetailsModal, selectedVCPortfolioCompany } = VCDealFlowsSignal.value;
  return(
    <ModalWrapper
    show={showDetailsModal}
    size="xl"
    centered
    onHide={handleCloseDetailModal}
    >
      <Modal.Header className="justify-content-between border-bottom-0 pb-0">
        <h5>{selectedVCPortfolioCompany?.business?.name}</h5>
        <CloseModalButton onCloseModal={handleCloseDetailModal} />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Tabs>
          <Tab eventKey="businessDetails" title="Business Details">
            <div className="px-32 pt-32">
              <OrganizationDetails hideOrganizationName />
            </div>
          </Tab>
          <Tab eventKey="fundDetails" title="Fund Details">
            <div className="px-32 pt-16 h-100">
              <FundInformation hideOrganizationName />
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          className="rounded"
          variant="outline-secondary"
          size="sm"
          onClick={handleCloseDetailModal}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default VCPortfolioCompanyDetailsModal;
