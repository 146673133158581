import { Button, Col, Row } from 'react-bootstrap';
import { organizationDetailsSignal } from '../OrganizationsList.signal';
import { addHttpsToUrl, enumCaseToTabCase } from 'libs/string';
import { createGoogleMapsLink } from 'utils/queryAddresses';
import dateOptions from '../../Constant/dateOptions';
import { camelCaseToTitleText } from '../../../../utils/formatTxt';
import { Business } from '../../../../types';
import DownloadableDocumentsRow from '../../DownloadableDocumentsRow';

type Props = {
  hideOrganizationName?: boolean;
  headerButton?: {
    label: string;
    onClick: () => void;
    disabled: boolean;
  } | null;
};

const BusinessDetails: React.FC<Props> = ({
  hideOrganizationName,
  headerButton = null,
}) => {
  const business = organizationDetailsSignal.value
    .selectedOrganization as Business;
  const {
    assistance,
    businessDocuments,
    demographics,
    misc,
    sediCertification,
    employment,
    minorityStatus,
  } = business;

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h3 hidden={hideOrganizationName}>
          {business.name ||
            (business.ein
              ? `Business with EIN: ${business.ein}`
              : `Business with ID: ${business.id}`)}
        </h3>
        {!!headerButton && (
          <div>
            <Button
              disabled={headerButton.disabled}
              className="rounded"
              onClick={headerButton.onClick}
            >
              {headerButton.label}
            </Button>
          </div>
        )}
      </div>
      <hr />
      <Row>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Contact Name</p>
          {business.contactName || 'Unknown'}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Email</p>
          {business.contactEmail ? (
            <a href={`mailto:${business.contactEmail}`}>
              {business.contactEmail}
            </a>
          ) : (
            'Unknown'
          )}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Phone Number</p>
          {business.phoneNumber ? (
            <a href={`tel:${business.phoneNumber}`}>{business.phoneNumber}</a>
          ) : (
            'Unknown'
          )}
        </Col>
        <Col  xs={6} lg className="text-overflow-ellipsis mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Website</p>
          {business.website ? (
            <a
              href={addHttpsToUrl(business.website)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {business.website}
            </a>
          ) : (
            'Unknown'
          )}
        </Col>
      </Row>
      <Row className="mt-32">
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Address</p>
          <p className="mb-0">
            <a
              href={
                business.address?.formattedAddress
                  ? createGoogleMapsLink(business.address?.formattedAddress)
                  : '#'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {business.address?.formattedAddress || 'Unknown'}
            </a>
          </p>
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">EIN</p>
          {business.ein || 'Unknown'}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">NAICS Code</p>
          {business.naicsCode || 'Unknown'}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Census Tract</p>
          {business.censusTract || 'Unknown'}
        </Col>
      </Row>
      <Row className="mt-32">
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Description</p>
          {business.description || 'Unknown'}
        </Col>
        <Col xs={9}  className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Industry</p>
          {business.assistance?.industrySelection &&
          business.assistance?.industrySelection.length > 0
            ? business.assistance.industrySelection.join(', ')
            : 'Unknown'}
        </Col>
      </Row>
      <Row className="mt-32">
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Start Up</p>
          {camelCaseToTitleText(misc.isStartUp || 'Unknown')}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Climate Aligned Investment</p>
          {camelCaseToTitleText(misc.climateAlignedInvestment || 'Unknown')}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">
            Energy or Climate-Impacted Communities
          </p>
          {camelCaseToTitleText(
            misc.energyOrClimateImpactedCommunities || 'Unknown'
          )}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Business plan</p>
          {camelCaseToTitleText(misc.hasBusinessPlan || 'Unknown')}
        </Col>
      </Row>
      <h4 className="mt-48">Assistance</h4>
      <hr />
      <Row>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Funding Range</p>
          {assistance.seekingMin &&
            assistance.seekingMax &&
            `${assistance.seekingMin.toLocaleString(
              'en-US',
              dateOptions.currencyUSNoDecimal
            )} - ${assistance.seekingMax.toLocaleString(
              'en-US',
              dateOptions.currencyUSNoDecimal
            )}`}
          {assistance.seekingMin &&
            !assistance.seekingMax &&
            `${assistance.seekingMin.toLocaleString(
              'en-US',
              dateOptions.currencyUSNoDecimal
            )}`}

          {!assistance.seekingMin &&
            assistance.seekingMax &&
            `${assistance.seekingMax.toLocaleString(
              'en-US',
              dateOptions.currencyUSNoDecimal
            )}`}

          {!assistance.seekingMin && assistance.seekingMax && 'Unknown'}
        </Col>

        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Assistance Needed</p>
          {assistance.desiredAssistanceTypes &&
          assistance.desiredAssistanceTypes.length > 0
            ? assistance.desiredAssistanceTypes
                .map((s) => camelCaseToTitleText(s))
                .join(', ')
            : 'Unknown'}
        </Col>

        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Assistance Use</p>
          {assistance.assistanceUses && assistance.assistanceUses.length > 0
            ? assistance.assistanceUses
                .map((s) => enumCaseToTabCase(s))
                .join(', ')
            : 'Unknown'}
        </Col>
      </Row>
      <h4 className="mt-48">SEDI Certification</h4>
      <hr />
      <Row>
        <Col>
          <p className="font-weight-bold mb-0">Self Certified</p>
          {camelCaseToTitleText(sediCertification.isSediOwned || 'Unknown')}
        </Col>
      </Row>
      <Row className="mt-32">
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">
            SEDI Status by Business Address in CDFI Investment Area
          </p>
          {camelCaseToTitleText(
            sediCertification.sediStatusByBusinessAddressInCDFIInvestmentArea ||
              'Unknown'
          )}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">
            Self-Certified SEDI Owned and Controlled in CDFI Investment Area
          </p>
          {sediCertification.selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea ||
            'Unknown'}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">
            Self-certified SEDI Future Location in CDFI Investment Area
          </p>
          {sediCertification.selfCertifiedSEDIFutureLocationInCDFIInvestmentArea ||
            'Unknown'}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">
            Self-Certified SEDI Demographics-Related Business Status
          </p>
          {sediCertification.selfCertifiedSEDIDemographicsRelatedBusinessStatus ||
            'Unknown'}
        </Col>
      </Row>
      <h4 className="mt-48">Minority Status</h4>
      <hr />
      <Row>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Minority Owned</p>
          {camelCaseToTitleText(minorityStatus.isMinorityOwned || 'Unknown')}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Woman Owned</p>
          {camelCaseToTitleText(minorityStatus.isWomanOwned || 'Unknown')}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Veteran Owned</p>
          {camelCaseToTitleText(minorityStatus.isVeteranOwned || 'Unknown')}
        </Col>
      </Row>
      <h4 className="mt-48">Employment</h4>
      <hr />
      <Row>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Number of Employees</p>
          {employment.numberOfEmployees || 'Unknown'}
        </Col>

        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Number of Full-time Employees</p>
          {employment.numberOfFullTimeEmployees || 'Unknown'}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Number of Part-time Employees</p>
          {employment.numberOfPartTimeEmployees || 'Unknown'}
        </Col>
      </Row>
      <Row className="mt-32">
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Expected Jobs Created</p>
          {employment.expectedJobsCreated || 'Unknown'}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Expected Jobs Retained</p>
          {employment.expectedJobsRetained || 'Unknown'}
        </Col>
      </Row>
      <h4 className="mt-48">Demographics</h4>
      <hr />
      <Row>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Ethnicity</p>
          {renderEthnicityValue(demographics)}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Race</p>
          {renderRaceValue(demographics.race)}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">
            Middle Eastern or North African Ancestry
          </p>
          {renderMiddleEasternOrNorthAfricanAncestryValue(demographics)}
        </Col>
      </Row>
      <Row className="mt-24">
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Gender</p>
          {renderGenderValue(demographics)}
        </Col>
        <Col xs={6} lg>
          <p className="font-weight-bold mb-0">Sexual Orientation</p>
          {renderSexualOrientationValue(demographics)}
        </Col>
        <Col xs={6} lg className="mt-24 mt-lg-0">
          <p className="font-weight-bold mb-0">Veteran Status</p>
          {renderVeteranStatusValue(demographics)}
        </Col>
      </Row>
      <h4 className="mt-48">Documents</h4>
      <hr />
      <DownloadableDocumentsRow
        overarchingDocumentType={'businessDocuments'}
        documents={businessDocuments.map((businessDocument) => ({
          filePath: businessDocument.filePath,
          fileType: businessDocument.fileType,
          header: businessDocument.documentType
            ? 'Suggested Document'
            : 'Additional Document',
          id: businessDocument.id,
          name: businessDocument.name,
        }))}
      />
    </div>
  );
};

function renderSexualOrientationValue(demographics: Business['demographics']) {
  if (!demographics.sexualOrientation) {
    return 'Unknown';
  }

  if (demographics.sexualOrientation === 'decline') {
    return 'Prefer not to respond';
  }

  return camelCaseToTitleText(demographics.sexualOrientation);
}

function renderVeteranStatusValue(demographics: Business['demographics']) {
  if (!demographics.veteranStatus) {
    return 'Unknown';
  }

  if (demographics.veteranStatus === 'decline') {
    return 'Prefer not to respond';
  }

  return camelCaseToTitleText(demographics.veteranStatus);
}

function renderGenderValue(demographics: Business['demographics']) {
  if (!demographics.gender) {
    return 'Unknown';
  }

  if (demographics.gender === 'decline') {
    return 'Prefer not to respond';
  }

  return camelCaseToTitleText(demographics.gender);
}

function renderEthnicityValue(demographics: Business['demographics']) {
  if (!demographics.ethnicity) {
    return 'Unknown';
  }

  if (!demographics.ethnicity.hispanicOrLatino) {
    return 'Unknown';
  }

  if (demographics.ethnicity.hispanicOrLatino === 'decline') {
    return 'Prefer not to respond';
  }

  if (demographics.ethnicity.hispanicOrLatino === 'no') {
    return 'Not Hispanic or Latino';
  } else {
    return 'Hispanic or Latino';
  }
}

function renderMiddleEasternOrNorthAfricanAncestryValue(
  demographics: Business['demographics']
) {
  if (!demographics.middleEasternOrNorthAfricanAncestry) {
    return 'Unknown';
  }

  if (
    !demographics.middleEasternOrNorthAfricanAncestry
      .middleEasternOrNorthAfrican
  ) {
    return 'Unknown';
  }

  if (
    demographics.middleEasternOrNorthAfricanAncestry
      .middleEasternOrNorthAfrican === 'decline'
  ) {
    return 'Prefer not to respond';
  }

  return camelCaseToTitleText(
    demographics.middleEasternOrNorthAfricanAncestry.middleEasternOrNorthAfrican
  );
}

function renderRaceValue(race: Business['demographics']['race']) {
  if (!race) {
    return 'Unknown';
  }

  if (race.preferNotToRespond) {
    return 'Prefer not to respond';
  }

  const races = [];
  if (race.americanIndianOrAlaskaNative) {
    races.push('American Indian or Alaska Native');
  }

  if (race.asianIndian) {
    races.push('Asian Indian');
  }

  if (race.asianChinese) {
    races.push('Asian Chinese');
  }

  if (race.asianFilipino) {
    races.push('Asian Filipino');
  }

  if (race.asianJapanese) {
    races.push('Asian Japanese');
  }

  if (race.asianKorean) {
    races.push('Asian Korean');
  }

  if (race.asianVietnamese) {
    races.push('Asian Vietnamese');
  }

  if (race.asianOther) {
    races.push('Asian Other');
  }

  if (race.blackOrAfricanAmerican) {
    races.push('Black or African American');
  }

  if (race.guamanianOrChamorro) {
    races.push('Guamanian or Chamorro');
  }

  if (race.nativeHawaiian) {
    races.push('Native Hawaiian');
  }

  if (race.samoan) {
    races.push('Samoan');
  }

  if (race.pacificIslanderOther) {
    races.push('Pacific Islander Other');
  }

  if (race.white) {
    races.push('White');
  }

  return races.join(', ');
}

export default BusinessDetails;
