import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { searchIcon } from 'components/global/Constant/Icons';
import businessSearchSignal from 'signals/BusinessSearch.signal';
import { useCallback, useEffect } from 'react';
import {
  fetchAndSetBusinessSearchData,
  handleInputChange,
  handleSearchFormSubmit,
  handleSelectChange,
} from '../BusinessSearch.helpers';
import $appSettings from 'signals/AppSettings.signal';

const BusinessSearchForm = () => {
  const { PROVIDER_TYPES } = $appSettings.value.constants;

  const init = useCallback(async () => {
    await fetchAndSetBusinessSearchData();
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Row className="px-xl-64">
        <Col>
          <Card className="px-24 px-lg-48 py-32 mx-xl-16">
            <h3 className="text-center">Search Opportunities</h3>
            <hr />
            <p className="font-weight-bold">Lender Type</p>
            <Row className="mb-16 px-8">
              {Object.values(PROVIDER_TYPES).map((lenderType) => (
                <Button
                  key={lenderType.value}
                  className={`text-sm-nowrap rounded m-8 ${
                    businessSearchSignal.value.query.lenderType ===
                    lenderType.value
                      ? 'btn-primary'
                      : 'btn-outline-primary text-dark bg-white'
                  }`}
                  name="lenderType"
                  value={lenderType.value}
                  onClick={handleSelectChange}
                >
                  {lenderType.annualReportLabel}
                </Button>
              ))}
            </Row>
            <Row>
              <Col md={12} lg={8}>
                <Form.Group className="form-group">
                  <Form.Label
                    className="font-weight-bold"
                    htmlFor="requiresBusinessPlan"
                  >
                    Requiring business plan?
                  </Form.Label>
                  <br />
                  <Form.Check
                    required
                    inline
                    type="radio"
                    label="Yes"
                    id="requiresBusinessPlanFalse"
                    name="requiresBusinessPlan"
                    value="true"
                    checked={
                      businessSearchSignal.value.query.requiresBusinessPlan ===
                      'true'
                    }
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    required
                    inline
                    type="radio"
                    label="No"
                    id="requiresBusinessPlanFalse"
                    name="requiresBusinessPlan"
                    value="false"
                    checked={
                      businessSearchSignal.value.query.requiresBusinessPlan ===
                      'false'
                    }
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group">
                  <Form.Label
                    className="font-weight-bold"
                    htmlFor="fundsStartups"
                  >
                    Funds Startups?
                  </Form.Label>
                  <br />
                  <Form.Check
                    required
                    inline
                    type="radio"
                    label="Yes"
                    id="fundsStartupsTrue"
                    name="fundsStartups"
                    value="true"
                    checked={
                      businessSearchSignal.value.query.fundsStartups === 'true'
                    }
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    required
                    inline
                    type="radio"
                    label="No"
                    id="fundsStartupsFalse"
                    name="fundsStartups"
                    value="false"
                    checked={
                      businessSearchSignal.value.query.fundsStartups === 'false'
                    }
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="justify-content-end">
              <Button
                id="business-search-submit-btn"
                className="mr-16"
                form="formInfo"
                name="tab"
                value="results"
                variant="primary rounded"
                onClick={handleSearchFormSubmit}
              >
                <span className="mr-8">Search</span>
                <FontAwesomeIcon icon={searchIcon} />
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BusinessSearchForm;
