import alertSignal from "signals/Alert.signal";
import $executiveDashboard from "./executiveDashboard.signal";
import api from "api/api";
import { COLORS } from "components/global/Constant/constants";

export const CHART_OPTIONS = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      onClick: null,
    },
    title: {
      display: true,
    },
    datalabels: {
      labels: {
        value: {
          color: (context) => context.dataset.backgroundColor,
          anchor: 'end',
          align: 'end',
          offset: 4,
          font: {
            weight: 'bold'
          }
        },
      },
      display: () => false,
    },
  },
};

export const COLOR_MAP = [
  COLORS.PRIMARY,
  COLORS.SUCCESS,
  COLORS.WARNING,
  COLORS.DANGER,
  COLORS.INFO,
  COLORS.ORANGE,
  COLORS.SECONDARY,
];

export const STATIC_LABELS = {
  applicationTally: 'Applications',
  programRequestTally: 'Program Requests',
  capitalCallTally: 'Capital Calls',
  fundTally: 'Funds',
  vcTally: 'VCs',
  lenderTally: 'Lenders',
  businessTally: 'Businesses',
  invitationsSent: 'Invitations Sent',
  invitationsAccepted: 'Invitations Accepted',
  expressedInterest: 'Expressed Interest',
};

export const PERIOD_OPTIONS = {
  week: {
    label: 'Week',
    value: 'week',
    getLabels: (date) => getDateLabel(date),
    startOfTime: '7 days',
    periodicity: 'day',
  },
  month: {
    label: 'Month',
    value: 'month',
    getLabels: (date) => getDateLabel(date),
    startOfTime: '4 weeks',
    periodicity: 'week',
  },
  threeMonths: {
    label: 'Three Months',
    value: 'threeMonths',
    getLabels: (date) => getMonthLabel(date),
    startOfTime: '3 months',
    periodicity: 'month',
  },
  sixMonths: {
    label: 'Six months',
    value: 'sixMonths',
    getLabels: (date) => getMonthLabel(date),
    startOfTime: '6 months',
    periodicity: 'month',
  },
  year: {
    label: 'Year',
    value: 'year',
    getLabels: (date) => getMonthLabel(date),
    startOfTime: '12 months',
    periodicity: 'month',
  }
};

const getDateLabel = (dateString) => {
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`
};

const getMonthLabel = (dateString) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const date = new Date(dateString);
  const currentMonth = date.getMonth(); // 0 (January) to 11 (December)
  return monthNames[currentMonth];
};

export const handleFilterRecordChange = (e, entity) => $executiveDashboard.update({
  [entity]: {
    ...$executiveDashboard.value[entity],
    filters: {
      ...$executiveDashboard.value[entity].filters,
      [e.name]: !$executiveDashboard.value[entity].filters[e.name],
    },
  },
});

export const handleQueryChange = (e, entity) => {
  $executiveDashboard.update({
    [entity]: {
      ...$executiveDashboard.value[entity],
      query: e.value,
    },
  });
}

export const fetchAndSetSubmissionsData = async () => {
  try {
    $executiveDashboard.update({
      submissions: {
        ...$executiveDashboard.value.submissions,
        loader: true,
      },
    });

    const [
      applications,
      programRequests,
      capitalCalls,
      funds,
      vcs,
      lenders,
      businesses,
    ] = await Promise.all([
      'Application',
      'ProgramRequest',
      'CapitalCall',
      'Fund',
      'VentureCapitalFirm',
      'Lender',
      'Business',
    ].map(model => (
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.submissions.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.submissions.query].periodicity,
          model: model,
          count: 'createdDate',
        }
      },
      ))));

    const labels = applications.map(record => PERIOD_OPTIONS[$executiveDashboard.value.submissions.query].getLabels(record.date));
    const applicationTally = applications.map(record => record.count);
    const programRequestTally = programRequests.map(record => record.count);
    const capitalCallTally = capitalCalls.map(record => record.count);
    const fundTally = funds.map(record => record.count);
    const vcTally = vcs.map(record => record.count);
    const businessTally = businesses.map(record => record.count);
    const lenderTally = lenders.map(record => record.count);

    $executiveDashboard.update({
      submissions: {
        ...$executiveDashboard.value.submissions,
        labels,
        tallies: {
          applicationTally,
          programRequestTally,
          capitalCallTally,
          fundTally,
          vcTally,
          businessTally,
          lenderTally,
        },
      },
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    $executiveDashboard.update({
      submissions: {
        ...$executiveDashboard.value.submissions,
        loader: false,
      },
    });
  }
};

export const fetchAndSetLenderSignUpData = async () => {
  try {
    $executiveDashboard.update({
      lenderSignUps: {
        ...$executiveDashboard.value.lenderSignUps,
        loader: true,
      },
    });

    const [
      lenderInvitationsSent,
      lenderInvitationsAccepted,
      lenderExpressedInterest,
    ] = await Promise.all([
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].periodicity,
          model: 'UserInvite',
          count: 'createdDate',
          where: {
            portal: 'LENDER',
          },
        },
      }),
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].periodicity,
          model: 'UserInvite',
          count: 'approvedDate',
          where: {
            portal: 'LENDER',
          },
        },
      }),
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].periodicity,
          model: 'SignUpRequest',
          count: 'createdDate',
          where: {
            portal: 'LENDER',
          },
        },
      }),
    ]);

    const labels = lenderInvitationsSent.map(record => PERIOD_OPTIONS[$executiveDashboard.value.lenderSignUps.query].getLabels(record.date));
    const invitationsSent = lenderInvitationsSent.map(record => record.count);
    const invitationsAccepted = lenderInvitationsAccepted.map(record => record.count);
    const expressedInterest = lenderExpressedInterest.map(record => record.count);

    $executiveDashboard.update({
      lenderSignUps: {
        ...$executiveDashboard.value.lenderSignUps,
        labels,
        tallies: {
          invitationsSent,
          invitationsAccepted,
          expressedInterest,
        },
      },
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    $executiveDashboard.update({
      lenderSignUps: {
        ...$executiveDashboard.value.lenderSignUps,
        loader: false,
      },
    });
  }
};

export const fetchAndSetVcSignUpData = async () => {
  try {
    $executiveDashboard.update({
      vcSignUps: {
        ...$executiveDashboard.value.vcSignUps,
        loader: true,
      },
    });

    const [
      vcInvitationsSent,
      vcInvitationsAccepted,
      vcExpressedInterest,
    ] = await Promise.all([
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].periodicity,
          model: 'UserInvite',
          count: 'createdDate',
          where: {
            portal: 'VC',
          },
        },
      }),
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].periodicity,
          model: 'UserInvite',
          count: 'approvedDate',
          where: {
            portal: 'VC',
          },
        },
      }),
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].periodicity,
          model: 'SignUpRequest',
          count: 'createdDate',
          where: {
            portal: 'VC',
          },
        },
      }),
    ]);

    const labels = vcInvitationsSent.map(record => PERIOD_OPTIONS[$executiveDashboard.value.vcSignUps.query].getLabels(record.date));
    const invitationsSent = vcInvitationsSent.map(record => record.count);
    const invitationsAccepted = vcInvitationsAccepted.map(record => record.count);
    const expressedInterest = vcExpressedInterest.map(record => record.count);

    $executiveDashboard.update({
      vcSignUps: {
        ...$executiveDashboard.value.vcSignUps,
        labels,
        tallies: {
          invitationsSent,
          invitationsAccepted,
          expressedInterest,
        },
      },
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    $executiveDashboard.update({
      vcSignUps: {
        ...$executiveDashboard.value.vcSignUps,
        loader: false,
      },
    });
  }
};

export const fetchAndSetBusinessSignUpData = async () => {
  try {
    $executiveDashboard.update({
      businessSignUps: {
        ...$executiveDashboard.value.businessSignUps,
        loader: true,
      },
    });

    const [
      businessInvitationsSent,
      businessInvitationsAccepted,
      businessExpressedInterest,
    ] = await Promise.all([
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].periodicity,
          model: 'UserInvite',
          count: 'createdDate',
          where: {
            portal: 'BUSINESS',
          },
        },
      }),
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].periodicity,
          model: 'UserInvite',
          count: 'approvedDate',
          where: {
            portal: 'BUSINESS',
          },
        },
      }),
      api.get({
        path: '/query',
        options: {
          fn: 'timeSeries',
          startOfTime: PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].startOfTime,
          periodicity: PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].periodicity,
          model: 'SignUpRequest',
          count: 'createdDate',
          where: {
            portal: 'BUSINESS',
          },
        },
      }),
    ]);

    const labels = businessInvitationsSent.map(record => PERIOD_OPTIONS[$executiveDashboard.value.businessSignUps.query].getLabels(record.date));
    const invitationsSent = businessInvitationsSent.map(record => record.count);
    const invitationsAccepted = businessInvitationsAccepted.map(record => record.count);
    const expressedInterest = businessExpressedInterest.map(record => record.count);

    $executiveDashboard.update({
      businessSignUps: {
        ...$executiveDashboard.value.businessSignUps,
        labels,
        tallies: {
          invitationsSent,
          invitationsAccepted,
          expressedInterest,
        },
      },
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    $executiveDashboard.update({
      businessSignUps: {
        ...$executiveDashboard.value.businessSignUps,
        loader: false,
      },
    });
  }
};
