import Signal from 'libs/signals';

export const INDUSTRY_DATA_INIT_STATE = [
  'Hospitality',
  'Food Services',
  'Construction',
  'Retail Trade',
  'Health Care and Social Assistance',
  'Educational Services',
  'Wholesale Trade',
  'Agriculture, Forestry, Fishing, and Hunting',
  'Manufacturing',
  'Real Estate',
  'Arts and Entertainment',
  'Mining, Quarrying, and Oil and Gas Extraction',
  'Outdoor Recreation',
  'Transportation and Warehousing',
  'Professional Services',
  'Technology',
];

export const lenderSettingsUsersSignal = Signal({
  users: [],
  fundingPrograms: [],
  userProgramAccesses: [],
  fundingProgramMemberships: [],
  selectedUser: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    lastSignedIn: new Date(),
    status: false,
    userPrograms: [],
    TwoStepVerification: {
      email: {
        email: '',
        active: false,
      },
      sms: {
        phoneNumber: '',
        active: false,
      },
    },
  },
  selectedUserErrors: {
    email: false,
  },
});

export const lenderSettingsUsersModalsSignal = Signal({
  addUserModal: false,
  deleteUserModal: false,
  userDetailsModal: false,
});

export const lenderSettingsUsersAccountSettingsSignal = Signal({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
});

export const lenderSettingsUsersAccountSettingsErrorsSignal = Signal({
  email: false,
  phone: false,
  zipcode: false,
});

export const lenderSettingsOrganizationInfoSignal = Signal({
  name: '',
  lenderType: 'Bank',
  website: '',
  contactName: '',
  email: '',
  phoneNumber: '',
  description: '',
  loanRangeMin: undefined,
  loanRangeMax: undefined,
  downPaymentRange: '$0 - $5000',
  fundsStartups: false,
  requiresBusinessPlan: false,
  industrySelection: [],

  // Add to schema
  ein: '',
  regulatoryId: '',
  otherOrganizationId: '',
  otherLenderType: '',
  targetFundSize: undefined,
  minorityDepositoryInstitution: false,
  fundAsSourceOfPrivateCapital: false,
  lenderAsSourceOfPrivateCapital: false,
  incubationAndEarlyStageInvestmentCallOptions: false,
});

export const lenderSettingsOrganizationInfoErrorsSignal = Signal({
  industrySelection: false,
  email: false,
  phoneNumber: false,
  seekingRange: false,
  ein: false,
  regulatoryId: false,
  website: false,
});

export const lenderSettingsIndustryDataSignal = Signal(
  INDUSTRY_DATA_INIT_STATE
);

export const lenderSettingsBusinessNewIndustrySignal = Signal('');

export const lenderSettingsAddressSearchSignal = Signal({
  addressSearch: '',
  addressOptions: [],
});

export default lenderSettingsUsersSignal;
