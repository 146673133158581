export function titleCaseString(str) {
  if (!str) {
    return null;
  }

  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
}

export function ensureHttpsInHrefs(htmlString) {
  if (!htmlString) {
    return htmlString;
  }

  const hrefPattern = /href="([^"]*)"/g;
  const hrefMatches = htmlString.match(hrefPattern);

  if (hrefMatches) {
    hrefMatches.forEach(function (match) {
      const url = match.substring(6, match.length - 1);
      htmlString = htmlString.replace(url, addHttpsToUrl(url));
    });
  }

  return htmlString;
}

export function addHttpsToUrl(url) {
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  } else if (!url.startsWith('https://')) {
    return `https://${url}`;
  } else {
    return url;
  }
}

export const enumCaseToTabCase = (string) => {
  if (!string) {
    return string;
  }

  return string
    .replaceAll('_', ' ')
    .split(' ')
    .map((w) => {
      if (w.toLowerCase().includes('cdfi')) {
        return 'CDFI';
      } else if (w.toLowerCase().includes('ssbci')) {
        return 'SSBCI';
      }
      return `${w[0].toUpperCase()}${w.toLowerCase().slice(1)}`;
    })
    .join(' ');
};

export const dashCaseToTitleText = (string) => {
  if (!string) {
    return string;
  }

  return string
    .replaceAll('-', ' ')
    .split(' ')
    .map((w) => `${w[0].toUpperCase()}${w.toLowerCase().slice(1)}`)
    .join(' ');
};
