import { Tab, Tabs } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import {
  fetchAndSetDestinationBankAccounts,
  fetchAndSetEdo,
} from './edoOrganization.helpers';
import resetSearchParams from 'utils/resetSearchParams';
import DetailsTab from './components/DetailsTab';
import EdoBilling from '../EdoBilling';
import DestinationBankAccounts from './components/DestinationBankAccounts';
import { useLocation } from 'react-router';
import { locationSearchToObject } from '../../../../libs/functions/global.functions';

const EdoOrganization = () => {
  const location = useLocation();
  const search = locationSearchToObject(location.search);

  const init = useCallback(async () => {
    await fetchAndSetEdo();
    await fetchAndSetDestinationBankAccounts();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Tabs
      defaultActiveKey={
        search.sourceBankAccounts === '' ? 'sourceBankAccounts' : 'details'
      }
      onSelect={resetSearchParams}
    >
      <Tab eventKey="details" title="Details">
        <DetailsTab />
      </Tab>
      <Tab eventKey="sourceBankAccounts" title="Source Bank Accounts">
        <EdoBilling />
      </Tab>
      <Tab eventKey="destinationBankAccounts" title="Destination Bank Accounts">
        <DestinationBankAccounts />
      </Tab>
    </Tabs>
  );
};

export default EdoOrganization;
