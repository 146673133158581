import React from "react";
import SignOutButton from "components/global/Header/SignOut";

const Home = () => (
  <>
    <div style={{position: 'relative'}} className="vh-100 subheader-auth">
      <div className="pointer bg-dark p-4" style={{position: 'absolute', right: 0, top: 0}}>
      <SignOutButton className="border-0 p-0 text-white dropdown-hover" />
      </div>
    </div>
  </>
);
export default Home;
