import { phoneRegex } from "components/global/Constant/regularExpression";

export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return false;
  }

  if (!phoneRegex.test(phoneNumber)) {
    return false
  }

  return true;
};
