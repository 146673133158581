import Signal from 'libs/signals';

const expressInterestSignal = Signal({
  firstName: '',
  lastName: '',
  email: '',
  organizationName: '',
  portal: '',
});


export default expressInterestSignal;
