import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import "./assets/scss/style.scss";
import { Route, Router, Switch, useLocation } from "react-router-dom";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";

import AppContainer from "components/global/AppContainer";

import Home from "components/views/Home";
import {
  BusinessRoute,
  EdoRoute,
  ExecutiveRoute,
  LenderRoute,
  PublicRoute,
  StateRoute,
  VCRoute
} from "components/global/Routes";

import State from "components/views/State";
import StateDashboard from "components/views/State/StateDashboard/StateDashboard";
import StateAddProgram from "components/views/State/StateAddProgram";
import StateSettings from "components/views/State/StateSettings";
import StateUsers from "components/views/State/StateUsers";
import StateOrganizations from "components/views/State/StateOrganizations";
import StateApiConnect from "components/views/State/StateApiConnect";
import StateBilling from "components/views/State/StateBilling";
import StateSecurity from "components/views/State/StateSecurity";
import StateReporting from "components/views/State/StateReporting";
import StateMigrate from "components/views/State/StateMigrate";
import StateProfile from "components/views/State/StateProfile";
import BusinessDashboard from "components/views/Business/BusinessDashboard";
import BusinessSearch from "components/views/Business/BusinessSearch";
import BusinessSettings from "components/views/Business/BusinessSettings";
import BusinessProfile from "components/views/Business/BusinessProfile";
import BusinessMyLoans from "components/views/Business/BusinessMyLoans";
import BusinessLoanHistory from "components/views/Business/BusinessLoanHistory";

import Dashboard from "components/views/Edo/EdoDashboard";
import EdoAddProgram from "components/views/Edo/EdoAddProgram";
import EdoTransactions from "components/views/Edo/EdoTransactions";
import EdoPortfolio from "components/views/Edo/EdoPortfolio";
import EdoReporting from "components/views/Edo/EdoReporting";
import EdoSecurity from "components/views/Edo/EdoSecurity";
import EdoActivity from "components/views/Edo/EdoActivity";
import EdoPrograms from "components/views/Edo/EdoPrograms";
import EdoUsers from "components/views/Edo/EdoUsers";
import EdoProfile from "components/views/Edo/EdoProfile";

import LenderSearchProgramsResults from "components/views/Lender/LenderSearchProgramResults";
import LenderPrograms from "components/views/Lender/LenderPrograms";
import LenderSecurity from "components/views/Lender/LenderSecurity";
import LenderBilling from "components/views/Lender/LenderBilling";
import LenderProfile from "components/views/Lender/LenderProfile";
import LenderUsers from "components/views/Lender/LenderUsers";
import LenderActivity from "components/views/Lender/LenderActivity";
import EdoAdminMigrate from "components/views/Edo/EdoAdminMigrate/EdoAdminMigrate";

import BusinessSearchResults from "components/views/Business/BusinessSearchResults";
import LenderDashboard from "components/views/Lender/LenderDashboard";
import LenderSubmitApplications from "components/views/Lender/LenderSubmitApplications";
import EdoSubmitApplications from "components/views/Edo/EdoSubmitApplications";
import VCDashboard from "components/views/VC/VCDashboard";
import { useEffect } from "react";
import VCBilling from "components/views/VC/VCBilling";
import history from "./history";
import StatePrograms from "./components/views/State/StatePrograms";
import Loader from "./components/global/Loader";
import VCProfile from "./components/views/VC/VCProfile";
import VCUsers from "./components/views/VC/VCUsers";
import VCSearchProgramResults from "./components/views/VC/VCSearchProgramResults";
import VCSubmitApplications from "./components/views/VC/VCSubmitApplications/VCSubmitApplications";
import LenderPortfolio from "./components/views/Lender/LenderPortfolio/LenderPortfolio";
import VCPrograms from "./components/views/VC/VCPrograms";
import ExpressInterest from "components/global/ExpressInterest";
import PublicAppContainer from "components/global/PublicAppContainer";
import EdoPlatforms from "./components/views/Edo/EdoPlatforms";
import NotificationPage from "components/global/NotificationPage/NotificationPage";
import VCFunds from "components/views/VC/VCFunds";
import EdoLenders from "components/views/Edo/EdoLenders";
import EdoVC from "components/views/Edo/EdoVC";
import EdoBusiness from "components/views/Edo/EdoBusiness";
import VCNotifications from "components/views/VC/VCNotifications";
import LenderNotifications from "components/views/Lender/LenderNotifications";
import VCDealFlows from "./components/views/VC/VCDealFlows";
import VCSearchBusinesses from "components/views/VC/VCSearchBusinesses";
import VCSearchBusinessResults from "components/views/VC/VCSearchBusinessResults";
import LenderOrganizationInfo from "components/views/Lender/LenderOrganizationInformation/LenderOrganizationInfo";
import EdoPortfolioDetail from "./components/views/Edo/EdoPortfolioDetail/EdoPortfolioDetail";
import LenderPortfolioDetail from "./components/views/Lender/LenderPortfolioDetail/LenderPortfolioDetail";
import LenderFundingProgramDetail from "./components/views/Lender/LenderFundingProgramDetail";
import VCFundingProgramDetail from "./components/views/VC/VCFundingProgramDetail";
import EdoProgramRequestDetail from "./components/views/Edo/EdoProgramRequestDetail";
import LenderProgramRequestDetail from "./components/views/Lender/LenderProgramRequestDetail";
import VCProgramRequestDetail from "./components/views/VC/VCProgramRequestDetail";
import EdoProgramDetail from "./components/views/Edo/EdoProgramDetail";
import LenderSearchBusinesses from "./components/views/Lender/LenderSearchBusinesses";
import LenderSearchBusinessResults from "./components/views/Lender/LenderSearchBusinessResults";
import EdoLenderDetail from "components/views/Edo/EdoLenderDetail/EdoLenderDetail";
import EdoVcDetail from "components/views/Edo/EdoVcDetail";
import EdoBusinessDetail from "components/views/Edo/EdoBusinessDetail/EdoBusinessDetail";
import NotFound from "components/global/Routes/NotFound";
import BusinessDataRoom from "components/views/Business/BusinessDataRoom";
import BusinessOrganizationDetails
  from "components/views/Business/BusinessOrganizationDetails/BusinessOrganizationDetails";
import EdoLenderProgramMembershipDetail
  from "components/views/Edo/EdoLenderDetail/components/EdoLenderProgramMembershipDetail";
import EdoVcProgramMembershipDetail from "components/views/Edo/EdoVcDetail/components/EdoVcProgramMembershipDetail";
import EdoVcFundDetail from "components/views/Edo/EdoVcDetail/components/EdoVcFundDetail";
import VCPortfolioCompanyDetails from "components/views/VC/VCDealFlows/components/VCPortfolioCompanyDetails";
import EdoPerformanceDisbursement from "./components/views/Edo/EdoPerformance/EdoPerformanceDisbursement";
import EdoOrganization from "components/views/Edo/EdoOrganization/EdoOrganization";
import LenderRepayment, { LenderDisbursement } from "./components/views/Lender/LenderRepayment";
import VCOrganizationInfo from "components/views/VC/VCUsers/VCOrganizationInfo";
import EdoApplicationBulkImport from "./components/views/Edo/EdoApplicationBulkImport";
import EdoSedi from "./components/views/Edo/EdoSedi";
import LenderBusinessDetail from "components/views/Lender/LenderBusinessDetails";
import VCBusinessDetails from "components/views/VC/VCBusinessDetails";
import EdoPerformance, { EdoPerformanceLoans } from "components/views/Edo/EdoPerformance";
import BusinessOnboarding from "./components/views/Business/components/BusinessOnboarding";
import BusinessFundingProgramDetail from "./components/views/Business/BusinessFundingProgramDetail";
import EdoVcBusinessDetail from "components/views/Edo/EdoVcDetail/components/EdoVcBusinessDetail";
import EdoLenderBusinessDetail from "components/views/Edo/EdoLenderDetail/components/EdoLenderBusinessDetail";
import EdoProgramBusinessDetail from "components/views/Edo/EdoProgramDetail/components/EdoProgramBusinessDetail";
import EdoAdhocReporting from "./components/views/Edo/EdoAdhocReporting";
import LenderAdhocReporting from "./components/views/Lender/LenderAdhocReporting";
import VCAdhocReporting from "./components/views/VC/VCAdhocReporting";
import BusinessAdhocReporting from "./components/views/Business/BusinessAdhocReporting";
import { redirectToMsalLogin } from "./libs/functions/global.functions";
import { fetchNotification } from "./api/notification.api";
import userAccountSignal from "./signals/UserAccount.signal";
import { buildNotificationParams } from "./libs/notifications";
import { useHistory } from "react-router";
import { getConfig } from "config/config";
import ReactGA from "react-ga4";
import EdoTechnicalAssistance from "components/views/Edo/EdoTechnicalAssistance";
import EdoTooltips from "components/views/Edo/EdoTooltips";
import VCFundDetails from "components/views/VC/VCFundDetails";
import EdoFundsPortfolio from "components/views/Edo/EdoFundsPortfolio";
import EdoFundDetail from "components/views/Edo/EdoFundDetail";
import VCFundApplication from "./components/views/VC/VCFundApplication";
import BusinessFundDetail from "components/views/Business/BusinessFundDetail";
import VCFundPortfolioCompanyRequestDetail from "components/views/VC/VCFundPortfolioCompanyRequestDetail";
import BusinessFunds from "components/views/Business/BusinessFunds";
import BusinessFundApplication from "components/views/Business/BusinessFundApplication";
import EdoFundApplication from "components/views/Edo/EdoFundApplication/EdoFundApplication";
import VCCreateCapitalCall from "components/views/VC/VCCreateCapitalCall";
import EdoCapitalCall from "./components/views/Edo/EdoCapitalCall";
import LenderDataRoom from "components/views/Lender/LenderDataRoom";
import VCDataRoom from "components/views/VC/VCDataRoom";
import VCCapitalCall from "./components/views/VC/VCCapitalCall";
import BusinessCapitalCallPortion from "components/views/Business/BusinessCapitalCallPortion";
import VCCapitalCallPortion from "components/views/VC/VCCapitalCallPortion";
import EdoCapitalCallPortion from "components/views/Edo/EdoCapitalCallPortion";
import EdoCreateCapitalCall from "components/views/Edo/EdoCreateCapitalCall/EdoCreateCapitalCall";
import VCNewFund from "components/views/VC/VCNewFund";
import EdoNewFund from "components/views/Edo/EdoNewFund";
import EdoPerformanceCapitalCalls from "components/views/Edo/EdoPerformance/EdoPerformanceCapitalCalls";
import VCCapitalCallPortionsList from "components/views/VC/VCCapitalCallPortionsList";
import EdoCustomize from "components/views/Edo/EdoCustomize/EdoCustomize";
import BusinessApplication from "./components/views/Business/BusinessApplication";
import GlobalSearchResults from "components/global/GlobalSearchResults";
import ExecutiveDashboard from "components/views/Executive/ExecutiveDashboard";
import Alert from "components/global/Alert";

const InitializeGoogleAnalytics = () => {
  useEffect(() => {
    const config = getConfig('APP_CONFIG');

    if (config.googleAnalyticsKey) {
      ReactGA.initialize(config.googleAnalyticsKey);
    }
  }, []);

  return null;
};

const NotificationHandoff = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const notificationId = pathname.split('/')[3];

  useEffect(() => {
    const notificationFetcher = () => fetchNotification(notificationId);
    notificationFetcher(notificationId).then((notification) => {
      if (notification) {
        const { handleNavigation } = buildNotificationParams(
          { ...notification, alertInteractions: [] },
          history,
          userAccountSignal.value.userData.account.portalType,
          () => {
            console.log('placeholder fn');
          }
        );

        return handleNavigation();
      }
    });
  }, [notificationId]);

  return null;
};

const UnauthorizedCatchAllMsalRedirector = () => {
  const { inProgress, instance } = useMsal();
  const { pathname } = useLocation();
  const isPublicRoute =
    pathname === '/business/express-interest' ||
    pathname === '/vc/express-interest' ||
    pathname === '/lender/express-interest';

  // We redirect to login screen upon detecting not being authenticated and if not a public route
  useEffect(() => {
    if (isPublicRoute) {
      return;
    }
    const loginRedirect = async () => {
      // this check is important so as to not
      // generate interaction in progress errors
      if (inProgress === 'none') {
        await redirectToMsalLogin(instance);
      }
    };

    loginRedirect();
  }, [instance, isPublicRoute, inProgress]);

  return (
    <>
      {!isPublicRoute && (
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
          <Alert />
          <Loader message="Loading..." />
        </div>
      )}
    </>
  );
};

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ChartGeo.ChoroplethController,
  ChartGeo.ProjectionScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature
);

const App = () => (
  <div className="vh-100">
    <InitializeGoogleAnalytics />
    <Router history={history}>
      <AuthenticatedTemplate>
        <Alert />
        <AppContainer>
          <Switch>
            {/* HomePage */}
            <PublicRoute
              path="/"
              exact
              component={Home}
              type="public"
              featureExclude={{}}
            />

            {/* State */}
            <StateRoute
              path="/state"
              exact
              component={State}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/dashboard"
              exact
              component={StateDashboard}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/add-program"
              exact
              component={StateAddProgram}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/programs"
              exact
              component={StatePrograms}
              featureExclude={{}}
            />
            <StateRoute
              path="/state"
              exact
              component={StateSettings}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/profile"
              exact
              component={StateProfile}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/users"
              exact
              component={StateUsers}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/organizations"
              exact
              component={StateOrganizations}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/apiconnect"
              exact
              component={StateApiConnect}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/billing"
              exact
              component={StateBilling}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/security"
              exact
              component={StateSecurity}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/reporting"
              exact
              component={StateReporting}
              featureExclude={{}}
            />
            <StateRoute
              path="/state/migrate"
              exact
              component={StateMigrate}
              featureExclude={{}}
            />

            {/* Business */}
            <BusinessRoute
              path={["/business/funding-programs/:id", "/business/search-all/funding-programs/:id"]}
              exact
              component={BusinessFundingProgramDetail}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/dashboard"
              exact
              component={BusinessDashboard}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/adhoc-reporting"
              exact
              component={BusinessAdhocReporting}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/search"
              exact
              component={BusinessSearch}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/search/search-results"
              exact
              component={BusinessSearchResults}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/organization"
              exact
              component={BusinessSettings}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/profile"
              exact
              component={BusinessProfile}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/settings/:tab"
              exact
              component={BusinessSettings}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/assessment-form"
              exact
              component={BusinessOnboarding}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/history"
              exact
              component={BusinessLoanHistory}
              featureExclude={{}}
            />
            <BusinessRoute
              path={["/business/applications/:id", "/business/search-all/applications/:id"]}
              exact
              component={BusinessApplication}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/applications"
              exact
              component={BusinessMyLoans}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/notifications"
              exact
              component={NotificationPage}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/notifications/:id/handoff"
              exact
              component={NotificationHandoff}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/data-room"
              exact
              component={BusinessDataRoom}
              featureExclude={{}}
            />
            <BusinessRoute
              path={[
                '/business/lenders/:id',
                '/business/vc/:id',
                '/business/dashboard/lenders/:id',
                '/business/dashboard/vc/:id',
                '/business/search/search-results/lenders/:id',
                '/business/search/search-results/vc/:id',
              ]}
              exact
              component={BusinessOrganizationDetails}
              featureExclude={{}}
            />
            <BusinessRoute
              path={[
                '/business/dashboard/vc/:id/funds/:fundId',
                '/business/vc/:id/funds/:fundId',
                '/business/search/search-results/vc/:id/funds/:fundId'
              ]}
              exact
              component={BusinessFundDetail}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/funds"
              exact
              component={BusinessFunds}
              featureExclude={{}}
            />
            <BusinessRoute
              path={["/business/funds/:id", "/business/search-all/funds/:id"]}
              exact
              component={BusinessFundApplication}
              featureExclude={{}}
            />
            <BusinessRoute
              path={[
                '/business/capital-call-portion/:id',
                '/business/funds/:id/capital-call-portion/:id',
                '/business/search-all/funds/:id/capital-call-portion/:id',
              ]}
              exact
              component={BusinessCapitalCallPortion}
              featureExclude={{}}
            />
            <BusinessRoute
              path="/business/search-all"
              exact
              component={GlobalSearchResults}
              featureExclude={{}}
            />

            {/* EDO */}
            <EdoRoute
              path="/edo/migrate"
              exact
              component={EdoAdminMigrate}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo"
              exact
              component={Dashboard}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/programs/add-program"
              exact
              component={EdoAddProgram}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/performance"
              exact
              component={EdoPerformance}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/performance/credit-program/:id"
              exact
              component={EdoPerformanceLoans}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/performance/equity-program/:id"
              exact
              component={EdoPerformanceCapitalCalls}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/portfolio/:id/disbursement/:disbursementId',
                '/edo/performance/credit-program/:id/application/:applicationId/disbursement/:disbursementId',
                '/edo/search-all/portfolio/:id/disbursement/:disbursementId',
              ]}
              exact
              component={EdoPerformanceDisbursement}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/portfolio/:id/reimbursement/:reimbursementId',
                '/edo/performance/credit-program/:id/application/:applicationId/reimbursement/:reimbursementId',
                '/edo/search-all/portfolio/:id/reimbursement/:reimbursementId',
              ]}
              exact
              component={EdoPerformanceDisbursement}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/transactions"
              exact
              component={EdoTransactions}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/lenders"
              exact
              component={EdoLenders}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/lenders/:id',
                '/edo/reporting/lenders/:id',
                '/edo/portfolio/:id/lenders/:id',
                '/edo/search-all/portfolio/:id/lenders/:id',
                '/edo/search-all/lenders/:id',
              ]}
              exact
              component={EdoLenderDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/lenders/:id/program-membership/:id',
                '/edo/reporting/lenders/:id/program-membership/:id',
                '/edo/portfolio/:id/lenders/:id/program-membership/:id',
                '/edo/search-all/portfolio/:id/lenders/:id/program-membership/:id',
                '/edo/search-all/lenders/:id/program-membership/:id',
              ]}
              exact
              component={EdoLenderProgramMembershipDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/lenders/:id/program-membership/:id/business/:id',
                '/edo/reporting/lenders/:id/program-membership/:id/business/:id',
                '/edo/search-all/lenders/:id/program-membership/:id/business/:id',
              ]}
              exact
              component={EdoLenderBusinessDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/vc"
              exact
              component={EdoVC}
              featureExclude={{}}
            />
            <EdoRoute
              path={['/edo/vc/:id', '/edo/reporting/vc/:id', '/edo/search-all/vc/:id']}
              exact
              component={EdoVcDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/vc/:id/program-membership/:id',
                '/edo/reporting/vc/:id/program-membership/:id',
                '/edo/search-all/vc/:id/program-membership/:id'
              ]}
              exact
              component={EdoVcProgramMembershipDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/vc/:id/program-membership/:id/fund/:id',
                '/edo/reporting/vc/:id/program-membership/:id/fund/:id',
                '/edo/search-all/vc/:id/program-membership/:id/fund/:id',
              ]}
              exact
              component={EdoVcFundDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/vc/:id/program-membership/:id/fund/:id/business/:id',
                '/edo/reporting/vc/:id/program-membership/:id/fund/:id/business/:id',
                '/edo/search-all/vc/:id/program-membership/:id/fund/:id/business/:id',
              ]}
              exact
              component={EdoVcBusinessDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/adhoc-reporting"
              exact
              component={EdoAdhocReporting}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/sedi"
              exact
              component={EdoSedi}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/business"
              exact
              component={EdoBusiness}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/business/:id',
                '/edo/reporting/business/:id',
                '/edo/portfolio/:id/business/:id',
                '/edo/search-all/portfolio/:id/business/:id',
                '/edo/search-all/business/:id'
              ]}
              exact
              component={EdoBusinessDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/portfolio/bulk-import"
              exact
              component={EdoApplicationBulkImport}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/portfolio/submit-applications"
              exact
              component={EdoSubmitApplications}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/portfolio/:id',
                '/edo/organization/application/:id',
                '/edo/performance/credit-program/:id/application/:id',
                '/edo/reporting/application/:id',
                '/edo/search-all/portfolio/:id',
              ]}
              exact
              component={EdoPortfolioDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/portfolio"
              exact
              component={EdoPortfolio}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/reporting"
              exact
              component={EdoReporting}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/users"
              exact
              component={EdoUsers}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/organization"
              exact
              component={EdoOrganization}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/security"
              exact
              component={EdoSecurity}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/activity"
              exact
              component={EdoActivity}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/profile"
              exact
              component={EdoProfile}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/programs"
              exact
              component={EdoPrograms}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/programs/:id", "/edo/search-all/programs/:id"]}
              exact
              component={EdoProgramDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/programs/:id/lender/program-membership/:id", "/edo/search-all/programs/:id/lender/program-membership/:id"]}
              exact
              component={EdoLenderProgramMembershipDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/programs/:id/lender/program-membership/:id/business/:id", "/edo/search-all/programs/:id/lender/program-membership/:id/business/:id"]}
              exact
              component={EdoProgramBusinessDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/programs/:id/vc/program-membership/:id", "/edo/search-all/programs/:id/vc/program-membership/:id"]}
              exact
              component={EdoVcProgramMembershipDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/programs/:id/vc/program-membership/:id/fund/:id", "/edo/search-all/programs/:id/vc/program-membership/:id/fund/:id"]}
              exact
              component={EdoVcFundDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/programs/:id/vc/program-membership/:id/fund/:id/business/:id", "/edo/search-all/programs/:id/vc/program-membership/:id/fund/:id/business/:id"]}
              exact
              component={EdoProgramBusinessDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/lenders/program-requests/:id',
                '/edo/vc/program-requests/:id',
                '/edo/search-all/lenders/program-requests/:id',
                '/edo/search-all/vc/program-requests/:id',
              ]}
              exact
              component={EdoProgramRequestDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/platforms"
              exact
              component={EdoPlatforms}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/notifications"
              exact
              component={NotificationPage}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/notifications/:id/handoff"
              exact
              component={NotificationHandoff}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/technical-assistance"
              exact
              component={EdoTechnicalAssistance}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/tooltips"
              exact
              component={EdoTooltips}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/funds"
              exact
              component={EdoFundsPortfolio}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/funds/new"
              exact
              component={EdoNewFund}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/funds/:id", "/edo/search-all/funds/:id"]}
              exact
              component={EdoFundDetail}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/funds/:id/applications/:id", "/edo/search-all/funds/:id/applications/:id"]}
              exact
              component={EdoFundApplication}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/capital-calls/:id", "/edo/search-all/capital-calls/:id"]}
              exact
              component={EdoCapitalCall}
              featureExclude={{}}
            />
            <EdoRoute
              path={[
                '/edo/funds/:id/capital-calls/:id',
                '/edo/reporting/capital-call/:id',
                '/edo/performance/equity-program/:id/capital-calls/:id',
                '/edo/search-all/funds/:id/capital-calls/:id',
              ]}
              exact
              component={EdoCapitalCall}
              featureExclude={{}}
            />
            <EdoRoute
              path={["/edo/funds/:id/create-capital-call", "/edo/search-all/funds/:id/create-capital-call"]}
              exact
              component={EdoCreateCapitalCall}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/reporting/capital-call-portion/:id"
              exact
              component={EdoCapitalCallPortion}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/customize"
              exact
              component={EdoCustomize}
              featureExclude={{}}
            />
            <EdoRoute
              path="/edo/search-all"
              exact
              component={GlobalSearchResults}
              featureExclude={{}}
            />

            {/* Lender */}
            {/*
            // Keeping this just in case we want to add program search back in
            <LenderRoute
              path="/lender/search-programs"
              exact
              component={LenderSearchPrograms}
              featureExclude={{}}
            /> */}
            <LenderRoute
              path="/lender/program-requests/all-programs"
              exact
              component={LenderSearchProgramsResults}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender"
              exact
              component={LenderDashboard}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/adhoc-reporting"
              exact
              component={LenderAdhocReporting}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/portfolio/submit-applications"
              exact
              component={LenderSubmitApplications}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/users"
              exact
              component={LenderUsers}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/profile"
              exact
              component={LenderProfile}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/program-requests"
              exact
              component={LenderPrograms}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/billing"
              exact
              component={LenderBilling}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/security"
              exact
              component={LenderSecurity}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/activity"
              exact
              component={LenderActivity}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/program-requests/all-programs/:id"
              exact
              component={LenderFundingProgramDetail}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/program-requests/:id"
              exact
              component={LenderProgramRequestDetail}
              featureExclude={{}}
            />
            <LenderRoute
              path={[
                '/lender/portfolio/:id/disbursement/:id',
                '/lender/portfolio/:id/reimbursement/:id',
                '/lender/search-all/portfolio/:id/disbursement/:id',
                '/lender/search-all/portfolio/:id/reimbursement/:id',
              ]}
              exact
              component={LenderDisbursement}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/repayment/:id"
              exact
              component={LenderRepayment}
              featureExclude={{}}
            />
            <LenderRoute
              path={["/lender/portfolio/:id", "/lender/search-all/portfolio/:id"]}
              exact
              component={LenderPortfolioDetail}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/portfolio"
              exact
              component={LenderPortfolio}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/notifications"
              exact
              component={LenderNotifications}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/notifications/:id/handoff"
              exact
              component={NotificationHandoff}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/search-businesses"
              exact
              component={LenderSearchBusinesses}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/search-businesses/business-search-results"
              exact
              component={LenderSearchBusinessResults}
              featureExclude={{}}
            />
            <LenderRoute
              path={[
                "/lender/businesses/:id",
                "/lender/portfolio/:id/business/:id",
                "/lender/search-all/portfolio/:id/business/:id",
                "/lender/search-all/businesses/:id"
              ]}
              exact
              component={LenderBusinessDetail}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/search-businesses/business-search-results/:id"
              exact
              component={LenderBusinessDetail}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/organization-information"
              exact
              component={LenderOrganizationInfo}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/data-room"
              exact
              component={LenderDataRoom}
              featureExclude={{}}
            />
            <LenderRoute
              path="/lender/search-all"
              exact
              component={GlobalSearchResults}
              featureExclude={{}}
            />

            {/* VC */}
            <VCRoute
              path="/vc/program-requests"
              exact
              component={VCPrograms}
              featureExclude={{}}
            />
            {/*
            // Keeping this just in case we want to add program search back in
            <VCRoute
              path="/vc/search-programs"
              exact
              component={VCSearchPrograms}
              featureExclude={{}}
            /> */}
            <VCRoute
              path="/vc/program-requests/all-programs"
              exact
              component={VCSearchProgramResults}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/program-requests/all-programs/:id"
              exact
              component={VCFundingProgramDetail}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/program-requests/:id", "/vc/search-all/program-requests/:id"]}
              exact
              component={VCProgramRequestDetail}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/submit-applications"
              exact
              component={VCSubmitApplications}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/portfolio-companies"
              exact
              component={VCDealFlows}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/portfolio-companies/:id"
              exact
              component={VCPortfolioCompanyDetails}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc"
              exact
              component={VCDashboard}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/adhoc-reporting"
              exact
              component={VCAdhocReporting}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/billing"
              exact
              component={VCBilling}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/profile"
              exact
              component={VCProfile}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/users"
              exact
              component={VCUsers}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/funds"
              exact
              component={VCFunds}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/funds/new"
              exact
              component={VCNewFund}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/funds/:id", "/vc/search-all/funds/:id"]}
              exact
              component={VCFundDetails}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/funds/:id/applications/:id", "/vc/search-all/funds/:id/applications/:id"]}
              exact
              component={VCFundApplication}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/capital-calls/:id", "/vc/search-all/capital-calls/:id"]}
              exact
              component={VCCapitalCall}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/funds/:id/capital-calls/:id", "/vc/search-all/funds/:id/capital-calls/:id"]}
              exact
              component={VCCapitalCall}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/notifications"
              exact
              component={VCNotifications}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/notifications/:id/handoff"
              exact
              component={NotificationHandoff}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/search-businesses"
              exact
              component={VCSearchBusinesses}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/search-businesses/business-search-results"
              exact
              component={VCSearchBusinessResults}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/search-businesses/business-search-results/:id"
              exact
              component={VCBusinessDetails}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/businesses/:id", "/vc/search-all/businesses/:id"]}
              exact
              component={VCBusinessDetails}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/organization-information"
              exact
              component={VCOrganizationInfo}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/funds/:fundId/portfolio-company-request/:businessId"
              exact
              component={VCFundPortfolioCompanyRequestDetail}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/funds/:id/create-capital-call", "/vc/search-all/funds/:id/create-capital-call"]}
              exact
              component={VCCreateCapitalCall}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/data-room"
              exact
              component={VCDataRoom}
              featureExclude={{}}
            />
            <VCRoute
              path={["/vc/capital-call-portion/:id", '/vc/transactions/:id']}
              exact
              component={VCCapitalCallPortion}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/transactions"
              exact
              component={VCCapitalCallPortionsList}
              featureExclude={{}}
            />
            <VCRoute
              path="/vc/search-all"
              exact
              component={GlobalSearchResults}
              featureExclude={{}}
            />

            <ExecutiveRoute
              path="/executive"
              exact
              component={ExecutiveDashboard}
              featureExclude={{}}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </AppContainer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <PublicAppContainer>
          <PublicRoute
            path="/business/express-interest"
            exact
            component={ExpressInterest}
            type="public"
          />
          <PublicRoute
            path="/vc/express-interest"
            exact
            component={ExpressInterest}
            type="public"
          />
          <PublicRoute
            path="/lender/express-interest"
            exact
            component={ExpressInterest}
            type="public"
          />
        </PublicAppContainer>
        <UnauthorizedCatchAllMsalRedirector />
      </UnauthenticatedTemplate>
    </Router>
  </div>
);

export default App;
