import { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
  businessSettingsAddressSearchSignal,
  businessSettingsDirtySignal,
  businessSettingsErrorsSignal,
  businessSettingsSignal
} from "signals/BusinessSettings.signal";
import {
  getAddressOptions,
  handleAddressBlur,
  handleAddressSearchChange,
  handleAddressSearchFocus,
  handleBusinessFormChange,
  handleSelectAddress
} from "../BusinessSettings.helpers";
import VALIDATION_MESSAGES from "components/global/Constant/validationMessages";
import BusinessUnsavedChangesAlert from "./BusinessUnsavedChangesAlert";
import Field from "components/global/Field/Field";
import $appSettings from "signals/AppSettings.signal";

const BusinessBasicInfo = () => {
  const businessForm = businessSettingsSignal.value;
  const businessFormErrors = businessSettingsErrorsSignal.value;
  const businessFormDirty = businessSettingsDirtySignal.value;
  const { addressSearch, addressOptions } =
    businessSettingsAddressSearchSignal.value;
  const { FORM_OF_BUSINESS_ORGANIZATION } = $appSettings.value.constants;
  const target = useRef(null);
  const [showBusinessPlanHelpTooltip, setShowBusinessPlanHelpTooltip] =
    useState(businessForm['misc.hasBusinessPlan'] === 'no');

  useEffect(() => {
    if (businessForm['misc.hasBusinessPlan'] === 'no') {
      setShowBusinessPlanHelpTooltip(true);
    }
  }, [businessForm]);

  useEffect(() => {
    getAddressOptions();
  }, [addressSearch]);

  return (
    <>
      <Row className="align-items-center px-16">
        <h4 className="mb-0">General Information</h4>
        <BusinessUnsavedChangesAlert />
      </Row>
      <hr />
      <Row>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="companyName">
              Company Name
            </Form.Label>
            <Form.Control
              formNoValidate
              type="text"
              className="form-control"
              id="name"
              name="name"
              isInvalid={businessFormErrors.name && businessFormDirty.name}
              aria-describedby="name"
              value={businessForm.name}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter in a company name
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="ein">
              EIN
            </Form.Label>
            <Form.Control
              formNoValidate
              type="text"
              className="form-control"
              placeholder="XX-XXXXXXX"
              id="ein"
              name="ein"
              isInvalid={businessFormErrors.ein && businessFormDirty.ein}
              value={businessForm.ein}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please use the format: XX-XXXXXXX
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="naicsCode">
              NAICS Code
            </Form.Label>
            <Form.Control
              formNoValidate
              type="text"
              className="form-control"
              id="naicsCode"
              name="naicsCode"
              isInvalid={
                businessFormErrors.naicsCode && businessFormDirty.naicsCode
              }
              value={businessForm.naicsCode}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid NAICS code.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="yearBusinessOpened"
            >
              Year Business Opened
            </Form.Label>
            <Form.Control
              formNoValidate
              type="number"
              className="form-control"
              placeholder="N/A"
              id="yearBusinessOpened"
              name="yearBusinessOpened"
              isInvalid={
                businessFormErrors.yearBusinessOpened &&
                businessFormDirty.yearBusinessOpened
              }
              value={businessForm.yearBusinessOpened}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a year.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="formOfBusinessOrganization"
              className="font-weight-bold"
            >
              Form of Business Organization
            </Form.Label>
            <br />
            <Form.Select
              formNoValidate
              className="form-control"
              type="text"
              id="formOfBusinessOrganization"
              name="formOfBusinessOrganization"
              value={businessForm.formOfBusinessOrganization}
              onChange={(e) => handleBusinessFormChange(e)}
            >
              <option value="">Select an option</option>
              {Object.values(FORM_OF_BUSINESS_ORGANIZATION).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.annualReportLabel}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="businessType">
              Other Type of Business
            </Form.Label>
            <Form.Control
              formNoValidate
              type="text"
              className="form-control"
              id="businessType"
              name="businessType"
              isInvalid={
                businessFormErrors.businessType &&
                businessFormDirty.businessType
              }
              value={businessForm.businessType}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a business type
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="website">
              Census Tract (Optional)
            </Form.Label>
            <Form.Control
              formNoValidate
              type="text"
              className="form-control"
              id="censusTract"
              name="censusTract"
              isInvalid={
                businessFormErrors.censusTract && businessFormDirty.censusTract
              }
              value={businessForm.censusTract}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              {VALIDATION_MESSAGES.website}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="website">
              Website (Optional)
            </Form.Label>
            <Form.Control
              formNoValidate
              type="text"
              className="form-control"
              id="website"
              name="website"
              isInvalid={
                businessFormErrors.website && businessFormDirty.website
              }
              value={businessForm.website}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              {VALIDATION_MESSAGES.website}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold" htmlFor="description">
              Company Description
            </Form.Label>
            <Form.Control
              formNoValidate
              as="textarea"
              className="form-control"
              id="description"
              name="description"
              isInvalid={
                businessFormErrors.description && businessFormDirty.description
              }
              aria-describedby="description"
              value={businessForm.description}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a description
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xl={3}>
          <Form.Group className="mt-4">
            <Form.Label className="font-weight-bold" htmlFor="misc.isStartUp">
              Are you a Start up?
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={businessForm['misc.isStartUp'] === 'yes'}
              label="Yes"
              name="misc.isStartUp"
              type="radio"
              id="misc.isStartUpYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm['misc.isStartUp'] === 'no'}
              label="No"
              name="misc.isStartUp"
              type="radio"
              id="misc.isStartUpNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="misc.climateAlignedInvestment"
            >
              Climate-aligned Investment?
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={businessForm['misc.climateAlignedInvestment'] === 'yes'}
              label="Yes"
              name="misc.climateAlignedInvestment"
              type="radio"
              id="misc.climateAlignedInvestmentYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={businessForm['misc.climateAlignedInvestment'] === 'no'}
              label="No"
              name="misc.climateAlignedInvestment"
              type="radio"
              id="misc.climateAlignedInvestmentNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <Form.Group className="mt-4">
            <Form.Label
              className="font-weight-bold"
              htmlFor="misc.energyOrClimateImpactedCommunities"
            >
              Energy or Climate-Impacted Communities?
            </Form.Label>
            <br />
            <Form.Check
              inline
              checked={
                businessForm['misc.energyOrClimateImpactedCommunities'] ===
                'yes'
              }
              label="Yes"
              name="misc.energyOrClimateImpactedCommunities"
              type="radio"
              id="misc.energyOrClimateImpactedCommunitiesYes"
              value="yes"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              inline
              checked={
                businessForm['misc.energyOrClimateImpactedCommunities'] === 'no'
              }
              label="No"
              name="misc.energyOrClimateImpactedCommunities"
              type="radio"
              id="misc.energyOrClimateImpactedCommunitiesNo"
              value="no"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} xl={3}>
          <div className="d-flex">
            <Form.Group className="mt-4">
              <Form.Label
                className="font-weight-bold"
                htmlFor="misc.hasBusinessPlan"
              >
                Do you have a business plan?
              </Form.Label>
              <br />
              <Form.Check
                inline
                checked={businessForm['misc.hasBusinessPlan'] === 'yes'}
                label="Yes"
                name="misc.hasBusinessPlan"
                type="radio"
                id="misc.hasBusinessPlanYes"
                value="yes"
                onChange={(e) => handleBusinessFormChange(e)}
              />

              <Form.Check
                inline
                ref={target}
                checked={businessForm['misc.hasBusinessPlan'] === 'no'}
                label="No"
                name="misc.hasBusinessPlan"
                type="radio"
                id="misc.hasBusinessPlanNo"
                value="no"
                onChange={(e) => {
                  handleBusinessFormChange(e);
                  setShowBusinessPlanHelpTooltip(true);
                }}
              />
            </Form.Group>
            {showBusinessPlanHelpTooltip &&
              businessForm['misc.hasBusinessPlan'] === 'no' && (
                <Card
                  bg={'info'}
                  text={'white'}
                  style={{ width: '18rem' }}
                  className="border-0 ml-4 mt-24"
                >
                  <Card.Body>
                    <Card.Title>We can help</Card.Title>
                    {window.location.hostname === 'al.caat.io' ? (
                      <Card.Text>
                        To find other resources, including business plan
                        creation, please visit the{' '}
                        <a
                          style={{
                            color: 'white',
                            textDecoration: 'underline',
                          }}
                          rel={'noreferrer'}
                          href="https://www.asbdc.org/office-locations/"
                          target="_blank"
                        >
                          Alabama Small Business Development Center.
                        </a>
                      </Card.Text>
                    ) : (
                      <Card.Text>
                        To find other resources, including business plan
                        creation, please visit the{' '}
                        <a
                          style={{
                            color: 'white',
                            textDecoration: 'underline',
                          }}
                          rel={'noreferrer'}
                          href="https://louisianabizhub.com/resources/?filter5Ids=44791&serviceCategoryIds=3160338&serviceIds=209498&sort_by=&user_sorted=&slrn_submit=1"
                          target="_blank"
                        >
                          {window.location.hostname === 'la.caat.io' ? "Louisiana Business Hub's Resource Navigator" : "Business Hub's Resource Navigator"}
                        </a>
                      </Card.Text>
                    )}
                  </Card.Body>
                </Card>
              )}
          </div>
        </Col>
      </Row>
      <Row className="mt-32">
        <Col>
          <Form.Group>
            <Field.AutoComplete
              label="Full Address"
              labelClassName="font-weight-bold"
              options={addressOptions}
              optionFormatter={(option) => option.formattedAddress}
              onChange={(e) => handleAddressSearchChange(e.target.value)}
              onSelect={(e) => handleSelectAddress(e)}
              value={businessForm?.address?.formattedAddress || addressSearch}
              name="address"
              isInvalid={businessFormErrors.address}
              onBlur={handleAddressBlur}
              onFocus={handleAddressSearchFocus}
            />
            <small className="text-danger" hidden={!businessFormErrors.address}>
              Please select a valid address from the drop down.
            </small>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default BusinessBasicInfo;
