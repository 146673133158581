import { Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const EdoVcBusinessDetail = () => {
  const { pathname } = useLocation();
  const segments = pathname.split('/');
  const organizationId = segments[segments.length - 1];
  const { selectedOrganization } = organizationDetailsSignal.value;
  const vcPortfolioCompany = selectedOrganization?.vcPortfolioCompanies?.find(pc => pc.businessId === selectedOrganization.id);
  const fund = vcPortfolioCompany?.fund;
  const programRequest = fund?.programRequest;
  const fundingProgram = programRequest?.fundingProgram;
  const ventureCapitalFirm = programRequest?.ventureCapitalFirm;
  const labels = [
    segments[2] === 'vc' ? 'Venture Capitalists' : '',
    !!ventureCapitalFirm ? ventureCapitalFirm.name : '',
    !!fundingProgram ? `Funds in ${fundingProgram.name}` : '',
    !!fund ? `${fund.fundName} Businesses` : '',
    !!selectedOrganization ? selectedOrganization.name : '',
  ]

  const init = useCallback(async () => {
    await fetchOrganization(organizationId, '/businesses');
  });

  useEffect(() => {
    init();
  }, [organizationId]);

  return (
    <BreadCrumbWrapper
      labels={labels}
    >
      <Card>
        <Card.Body>
          <OrganizationDetails />
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default EdoVcBusinessDetail;