import loaderSignal from '../../../signals/Loader.signal';
import api from '../../../api/api';
import alertSignal from '../../../signals/Alert.signal';
import { SECURITY_TYPE } from '../Constant/constants';
import { uploadFilesToStorage } from '../../../libs/functions/global.functions';
import $capitalCall, {
  $capitalCallPortionBeingEdited,
  $capitalCallSendFunds,
} from './CapitalCall.signals';
import { handleViewFundApplicationModal } from '../FundApplication/FundApplication.helpers';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetCapitalCall = async (id) => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const capitalCall = await api.get({
      path: '/capitalCalls',
      options: {
        where: {
          id: Number(id),
        },
        include: {
          approvalChainMemberships: {
            include: {
              user: true,
            },
          },
          documents: true,
          capitalCallPortions: {
            include: {
              vcPortfolioCompany: {
                include: {
                  business: {
                    include: {
                      businessDocuments: true,
                      capTable: true,
                    },
                  },
                  fundApplication: {
                    include: {
                      documents: {
                        include: {
                          businessDocument: true,
                        },
                      },
                    },
                  },
                  fund: {
                    include: {
                      documents: true,
                    },
                  },
                },
              },
            },
          },
          fund: {
            include: {
              documents: true,
              capitalCalls: {
                orderBy: {
                  id: 'asc',
                },
              },
              fundingProgramMembership: {
                include: {
                  ventureCapitalFirm: true,
                  fundingProgram: {
                    include: {
                      approvalChainTemplates: {
                        include: {
                          steps: {
                            include: {
                              primaryApprover: true,
                              alternateApprovers: {
                                include: {
                                  user: true,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    });

    return $capitalCall.update({
      capitalCall: {
        ...capitalCall,
        capitalCallPortions: capitalCall.capitalCallPortions.map((ccp) => ({
          ...ccp,
          transactionTerms: {
            stageOfInvestment: ccp.transactionTerms.stageOfInvestment || '',
            securityType: ccp.transactionTerms.securityType || '',
            otherSecurityOffered:
              ccp.transactionTerms.otherSecurityOffered || '',
            ssbciOwnershipPercentage:
              ccp.transactionTerms.ssbciOwnershipPercentage || '',
            conversionDiscount: ccp.transactionTerms.conversionDiscount || '',
            valuationCap: ccp.transactionTerms.valuationCap || '',
            typeOfValuationCap: ccp.transactionTerms.typeOfValuationCap || '',
          },
        })),
      },
    });
  } catch (e) {
    alertSignal.update({
      type: 'notification',
      message: e.message,
      e,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleApprovalChainTemplateChange = (
  skippedSteps,
  fallbackToAlternatesSteps,
  keyDates
) =>
  $capitalCall.update({
    skippedSteps,
    fallbackToAlternatesSteps,
    keyDates,
  });

export const handleCapitalCallStatusChange = (e) => {
  const { value } = e;

  $capitalCall.update({
    newStatus: value,
  });
};

export const handleUpdateCapitalCallDocumentStatus = async (
  e,
  capitalCallDocument
) => {
  const { value: status } = e;
  const { capitalCall } = $capitalCall.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/capitalCallDocuments',
      body: {
        where: {
          id: capitalCallDocument.uploadedDocument?.id,
        },
        data: {
          status,
        },
      },
    });

    await fetchAndSetCapitalCall(capitalCall.id);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully updated status',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleNotifyEdoMissingFunds = async () => {
  const { capitalCall } = $capitalCall.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    const body = {
      where: {
        id: capitalCall.id,
      },
      action: 'nudgeAboutMissingFunds',
    };

    await api.patch({
      path: '/capitalCalls',
      body,
    });

    await fetchAndSetCapitalCall(capitalCall.id);

    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully notified EDO.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleCapitalCallPushFundsToBusiness = async () => {
  const { attachments, capitalCallPortion } = $capitalCallSendFunds.value;
  const { capitalCall } = $capitalCall.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    const signedUrls = await uploadFilesToStorage(
      attachments.map((a) => a.file),
      'capitalCallPortionDocument'
    );

    const body = {
      action: 'fundsPushedToBusiness',
      where: {
        id: capitalCallPortion.id,
      },
      data: {
        fundsPushedAttachments: attachments.map((a, i) => ({
          fileType: a.file.type,
          name: a.name,
          filePath: signedUrls[i],
        })),
        note: $capitalCallSendFunds.value.note,
      },
    };

    await api.patch({
      path: '/capitalCallPortions',
      body,
    });

    $capitalCallSendFunds.reset();
    await fetchAndSetCapitalCall(capitalCall.id);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully submitted documents.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleCapitalCallStatusSubmission = async (
  newCapitalCallStatus
) => {
  const {
    capitalCall,
    skippedSteps,
    fallbackToAlternatesSteps,
    keyDates,
    disbursementDate,
  } = $capitalCall.value;
  const { CAPITAL_CALL_STATUS } = $appSettings.value.constants;
  const approvalChainTemplates =
    capitalCall.fund.fundingProgramMembership.fundingProgram
      .approvalChainTemplates;

  try {
    loaderSignal.update({ isContentLoading: true });

    const body = {
      where: {
        id: capitalCall.id,
      },
      data: {
        status: newCapitalCallStatus,
      },
    };

    if (newCapitalCallStatus === CAPITAL_CALL_STATUS.submittedForApproval) {
      const approvalChainTemplate = approvalChainTemplates[0];

      body.data.disbursementDate = disbursementDate;
      // Update capital call portion disbursement dates to capital call
      // disbursement date
      body.data.capitalCallPortions = {
        updateMany: {
          where: {
            capitalCallId: capitalCall.id,
          },
          data: {
            disbursementDate,
          },
        },
      };
      body.data.approvalChainMemberships = {
        create: approvalChainTemplate.steps
          .filter((step) => !skippedSteps.includes(step.id))
          .map((step) => {
            const data = {
              role: step.role,
              templateId: approvalChainTemplate.id,
              boardRepresentative: step.boardRepresentative,
            };

            if (
              Object.keys(fallbackToAlternatesSteps).includes(
                step.id.toString()
              )
            ) {
              data.userId = step.alternateApprovers[0].userId;
              data.alternate = true;
            } else {
              data.userId = step.primaryApproverId;
              data.alternate = false;
            }

            if (data.boardRepresentative) {
              data.keyDate = keyDates[step.id.toString()] || null;
            }

            return data;
          }),
      };
    }

    await api.patch({
      path: '/capitalCalls',
      body,
    });

    $capitalCall.update({ newStatus: '' });
    await fetchAndSetCapitalCall(capitalCall.id);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully updated capital call status.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleAttachmentCapitalCallFile = async (file) => {
  const { capitalCallTemplate, capitalCall } = $capitalCall.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    const signedUrls = await uploadFilesToStorage(
      [file],
      'capitalCallDocument'
    );

    await api.post({
      path: '/capitalCallDocuments',
      body: {
        data: {
          name: file.name,
          fileType: file.type,
          filePath: signedUrls[0],
          fundDocumentId: capitalCallTemplate.id,
          capitalCallId: capitalCall.id,
        },
      },
    });

    await fetchAndSetCapitalCall(capitalCall.id);

    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Document successfully uploaded',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleNoteChange = (approvalChainNote) =>
  $capitalCall.update({
    approvalChainNote,
  });

export const handleFileChange = (approvalChainFile) =>
  $capitalCall.update({
    approvalChainFile,
  });

export const handleApprovalChainConfirmation = async (confirmation) => {
  const {
    approvalChainFile: file,
    approvalChainNote: note,
    capitalCall,
  } = $capitalCall.value;

  if (note === '') {
    return alertSignal.update({
      type: 'notification',
      message: 'A note is needed.',
    });
  }

  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting confirmation...',
  });

  let signedUrls = [];

  if (file) {
    signedUrls = await uploadFilesToStorage(
      [file],
      'approvalChainConfirmationAttachment'
    );
  }

  try {
    const body = {
      data: {
        confirmationBundle: {
          attachments:
            signedUrls.length > 0
              ? [
                {
                  fileType: file.type,
                  filePath: signedUrls[0],
                },
              ]
              : [],
          note,
        },
        action: 'assert',
      },
    };

    await api.patch({
      path: `/capitalCalls/${capitalCall.id}/approvalChainMemberships/${confirmation.id}`,
      body,
    });

    $capitalCall.update({
      approvalChainNote: '',
      approvalChainFile: null,
    });

    await fetchAndSetCapitalCall(capitalCall.id);

    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully submitted.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSaveTransactionTermsClick = async () => {
  const { capitalCall } = $capitalCall.value;
  const capitalCallPortion = $capitalCallPortionBeingEdited.value;
  const { transactionTerms } = capitalCallPortion;
  try {
    loaderSignal.update({ isContentLoading: true });
    const trimmedTransactionTerms =
      trimAndValidateTransactionTerms(transactionTerms);

    await api.patch({
      path: '/capitalCalls',
      body: {
        where: {
          id: capitalCall.id,
        },
        data: {
          capitalCallPortions: {
            update: {
              where: {
                id: capitalCallPortion.id,
              },
              data: {
                transactionTerms: trimmedTransactionTerms,
              },
            },
          },
        },
      },
    });

    await fetchAndSetCapitalCall(capitalCall.id);

    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully updated transaction terms.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const trimAndValidateTransactionTerms = (transactionTerms) => {
  const trimmedTransactionTerms = {};

  if (!transactionTerms.stageOfInvestment) {
    throw new Error('Please provide a Stage of Investment');
  }

  if (!transactionTerms.securityType) {
    throw new Error('Please provide a Security Type');
  }

  if (transactionTerms.securityType === SECURITY_TYPE.other) {
    if (!transactionTerms.otherSecurityTypeOffered) {
      throw new Error('Please provide a description for Other Security Type');
    }

    trimmedTransactionTerms.otherSecurityOffered =
      transactionTerms.otherSecurityTypeOffered;
  }

  if (
    transactionTerms.securityType === SECURITY_TYPE.commonStock ||
    transactionTerms.securityType === SECURITY_TYPE.preferredStock
  ) {
    if (!transactionTerms.ssbciOwnershipPercentage) {
      throw new Error('Please provide a SSBCI Ownership Percentage');
    }

    trimmedTransactionTerms.ssbciOwnershipPercentage =
      transactionTerms.ssbciOwnershipPercentage;
  }

  if (
    transactionTerms.securityType === SECURITY_TYPE.convertibleDebt ||
    transactionTerms.securityType === SECURITY_TYPE.standardAgreement
  ) {
    if (!transactionTerms.conversionDiscount) {
      throw new Error('Please provide a Conversion Discount');
    }

    if (!transactionTerms.valuationCap) {
      throw new Error('Please provide a Valuation Cap');
    }

    if (!transactionTerms.typeOfValuationCap) {
      throw new Error('Please provide a Type of Valuation Cap');
    }

    trimmedTransactionTerms.conversionDiscount =
      transactionTerms.conversionDiscount;
    trimmedTransactionTerms.valuationCap = transactionTerms.valuationCap;
    trimmedTransactionTerms.typeOfValuationCap =
      transactionTerms.typeOfValuationCap;
  }

  return {
    stageOfInvestment: transactionTerms.stageOfInvestment,
    securityType: transactionTerms.securityType,
    ...trimmedTransactionTerms,
  };
};

export const handleCapitalCallTransactionTermsChange = (e) =>
  $capitalCallPortionBeingEdited.update({
    ...$capitalCallPortionBeingEdited.value,
    transactionTerms: {
      ...$capitalCallPortionBeingEdited.value.transactionTerms,
      ...{ [e.name]: e.value },
    },
  });

export const handlePortfolioCompanyNameClick = (portfolioCompany) => {
  const { fundApplication, ...rest } = portfolioCompany;
  fundApplication.vcPortfolioCompany = rest;

  handleViewFundApplicationModal(fundApplication);
};

export const setCapitalCallPortionToEdit = (capitalCallPortion) =>
  $capitalCallPortionBeingEdited.update(capitalCallPortion);

export const hideCapitalPortionTransactionTerms = () =>
  $capitalCallPortionBeingEdited.update(null);

export const setCapitalCallPortionToSendFundsTo = (capitalCallPortion) =>
  $capitalCallSendFunds.update({ capitalCallPortion });

export const hideCapitalPortionSendFunds = () => $capitalCallSendFunds.reset();

export const handleAddAttachments = (files) => {
  let attachments = [];
  for (let i = 0; i < files.length; i++) {
    attachments.push({ name: files[i].name.split('.')[0], file: files[i] });
  }

  return $capitalCallSendFunds.update({
    attachments: [...$capitalCallSendFunds.value.attachments, ...attachments],
  });
};

export const handleRemoveAttachment = (index) => {
  const { attachments } = $capitalCallSendFunds.value;
  return $capitalCallSendFunds.update({
    attachments: attachments.filter((_, i) => i !== index),
  });
};

export const handleChangeAttachmentName = (index, name) => {
  const { attachments } = $capitalCallSendFunds.value;
  return $capitalCallSendFunds.update({
    attachments: attachments.map((a, i) => {
      if (i === index) {
        return { ...a, name };
      }

      return a;
    }),
  });
};

export const handleReplaceFileInAttachment = (index, file) => {
  const { attachments } = $capitalCallSendFunds.value;
  return $capitalCallSendFunds.update({
    attachments: attachments.map((a, i) => {
      if (i === index) {
        return { ...a, file };
      }

      return a;
    }),
  });
};
export const handleDisbursementDateChange = (e) => {
  const { value } = e;

  $capitalCall.update({ disbursementDate: value });
};

export const handleCloseCapitalCallApprovalChainModal = () =>
  $capitalCall.update({ newStatus: '', disbursementDate: '' });
