import { useState } from 'react';
import { Button, Card, Collapse, Form, Row } from 'react-bootstrap';
import capitalizeFirst from 'utils/capitalizeFirst';
import { sortLogsByDate, sortNotesByDate } from './displayNotesOrLogs.helpers';
import DropDown from './DropDown';

const DisplayNotesOrLogs = ({
  data,
  displayType,
  height,
  newNote,
  onSubmitNewNote,
  onNoteChange,
  newNoteHidden = false,
  newNoteDisabled = false,
}) => {
  const [addNote, setAddNote] = useState(false);
  const sortedData =
    displayType === 'notes' ? sortNotesByDate(data) : sortLogsByDate(data);

  return (
    <>
      <h5>{capitalizeFirst(displayType)}</h5>
      <Card
        style={{ height, overflowY: 'scroll', resize: 'both' }}
        className="px-16 py-8 bg-light rounded-0"
      >
        {sortedData.map((dataObj) => (
          <DropDown
            key={dataObj.createdDate}
            dataObj={dataObj}
            displayType={displayType}
          />
        ))}
      </Card>
      {displayType === 'notes' && !newNoteHidden && (
        <>
          <Collapse in={addNote}>
            <div>
              <p className="my-8 font-weight-bold">New Note</p>
              <Form.Control
                as="textarea"
                value={newNote}
                onChange={onNoteChange}
              />
            </div>
          </Collapse>
          {!addNote && (
            <Row className="justify-content-end pr-16">
              <Button
                disabled={newNoteDisabled}
                className="rounded mt-8"
                onClick={() => setAddNote(true)}
              >
                New Note
              </Button>
            </Row>
          )}
          {addNote && (
            <Row className="justify-content-end pr-16">
              <Button
                variant="secondary"
                className="rounded mt-8 mr-8"
                onClick={() => setAddNote(false)}
              >
                Cancel
              </Button>
              <Button className="rounded mt-8" onClick={onSubmitNewNote}>
                Send Note
              </Button>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default DisplayNotesOrLogs;
