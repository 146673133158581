import loaderSignal from "signals/Loader.signal";
import edoTechnicalAssistanceSignal from "./edoTechnicalAssistance.signal";
import api from "api/api";
import alertSignal from "signals/Alert.signal";
import $technicalAssistance from "components/global/TechnicalAssistance/technicalAssistance.signal";

export const TECHNICAL_ASSISTANCE_MODALS = {
  create: 'create',
  edit: 'edit',
  send: 'send'
};

export const SEND_TECHNICAL_ASSISTANCE_MODAL_STATE = {
  useNew: 'USE_NEW',
  useExisting: 'USE_EXISTING',
};

export const fetchAndSetTechnicalAssistance = async (page) => {
  const { recordsPerPage } = edoTechnicalAssistanceSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    const data = await api.get({
      path: '/technicalAssistances',
      options: {
        getCount: true,
        skip: page ? (page - 1) * recordsPerPage : 0,
        take: recordsPerPage,
        orderBy: {
          id: 'desc',
        },
      },
    });

    edoTechnicalAssistanceSignal.update({
      technicalAssistances: data.technicalAssistances || [],
      totalPages: data.count ? Math.ceil(data.count / recordsPerPage) : 1,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
}

export const handleShowCreateTechnicalAssistanceModal = () => {
  edoTechnicalAssistanceSignal.update({ modalType: TECHNICAL_ASSISTANCE_MODALS.create });
};

export const handleShowEditTechnicalAssistanceModal = (technicalAssistance) => {
  edoTechnicalAssistanceSignal.update({
    modalType: TECHNICAL_ASSISTANCE_MODALS.edit,
    type: technicalAssistance.type,
    title: technicalAssistance.title,
    supportingText: technicalAssistance.supportingText,
    id: technicalAssistance.id,
  });
};

export const handleCloseTechnicalAssistanceModal = () => {
  edoTechnicalAssistanceSignal.update({
    modalType: null,
    title: '',
    supportingText: '',
    type: '',
    id: null,
    deleteTechnicalAssistancePromptVisible: false,
    applicationId: null,
    selectedTechnicalAssistanceId: null,
    sentTechnicalAssistanceId: null,
    sendTechnicalAssistanceModalState: SEND_TECHNICAL_ASSISTANCE_MODAL_STATE.useNew,
  });
  $technicalAssistance.reset();
};

export const handleTechnicalAssistanceInputChange = (e) => {
  const { name, value } = e;
  const { technicalAssistanceErrors } = edoTechnicalAssistanceSignal.value;

  edoTechnicalAssistanceSignal.update({
    [name]: value,
    technicalAssistanceErrors: {
      ...technicalAssistanceErrors,
      [name]: false,
    },
  });
};

const technicalAssistanceInputsAreValid = () => {
  const newErrors = {
    title: false,
    type: false,
    supportingText: false,
  };

  Object.keys(newErrors).forEach(name => {
    if (!edoTechnicalAssistanceSignal.value[name]) {
      newErrors[name] = true;
    }
  });

  edoTechnicalAssistanceSignal.update({ technicalAssistanceErrors: newErrors });

  if (Object.values(edoTechnicalAssistanceSignal.value.technicalAssistanceErrors).includes(true)) {
    return false;
  }

  return true;
};

export const handleTechnicalAssistanceSaveClick = async () => {
  const { modalType } = edoTechnicalAssistanceSignal.value;

  if (!technicalAssistanceInputsAreValid()) {
    return;
  }

  if (modalType === TECHNICAL_ASSISTANCE_MODALS.create) {
    await createTechnicalAssistance();
  } else if (modalType === TECHNICAL_ASSISTANCE_MODALS.edit) {
    await updateTechnicalAssistance();
  }
};

export const createTechnicalAssistance = async () => {
  const { title, type, supportingText } = edoTechnicalAssistanceSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.post({
      path: '/technicalAssistances',
      body: {
        data: {
          title,
          type,
          supportingText,
        },
        action: 'create',
      },
    });

    await fetchAndSetTechnicalAssistance();
    handleCloseTechnicalAssistanceModal();

    alertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: 'New Techical Assistance was successfully created.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const updateTechnicalAssistance = async () => {
  const { title, type, supportingText, id } = edoTechnicalAssistanceSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/technicalAssistances',
      body: {
        where: {
          id,
        },
        data: {
          title,
          type,
          supportingText,
        },
        action: 'update',
      },
    });

    await fetchAndSetTechnicalAssistance();

    alertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: 'New Techical Assistance was successfully updated.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const deleteTechnicalAssistance = async () => {
  const { id } = edoTechnicalAssistanceSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.delete({
      path: '/technicalAssistances',
      body: {
        where: {
          id,
        },
      },
    });

    await fetchAndSetTechnicalAssistance();

    handleCloseTechnicalAssistanceModal();

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully deleted technical assistance',
    })
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleTechnicalAssistanceCheckboxChange = (id) => {
  const { selectedTechnicalAssistanceId } = edoTechnicalAssistanceSignal.value;
  edoTechnicalAssistanceSignal.update({ selectedTechnicalAssistanceId: id === selectedTechnicalAssistanceId ? null : id });
};

export const sendNewTechnicalAssistance = async (callback) => {
  const { title, supportingText, type, applicationId } = edoTechnicalAssistanceSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    if (!applicationId) {
      throw new Error('No application is selected');
    }

    if (!title) {
      throw new Error('Please provide a title');
    }

    if (!supportingText) {
      throw new Error('Please provide supporting text');
    }

    if (!type) {
      throw new Error('Please provide a type');
    }

    await api.post({
      path: '/technicalAssistances',
      body: {
        data: {
          title,
          supportingText,
          type,
          applications: {
            connect: {
              id: applicationId,
            },
          },
        },
        action: 'createAndConnect',
      },
    });

    callback();
    fetchAndSetTechnicalAssistance();
    handleCloseTechnicalAssistanceModal();

    alertSignal.update({
      type: 'notification',
      message: 'Technical assistance has been sent!',
      variant: 'success',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const sendExistingTechnicalAssistance = async (callback) => {
  const { selectedTechnicalAssistanceId, applicationId } = edoTechnicalAssistanceSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    if (!applicationId) {
      throw new Error('No application is selected');
    }

    if (!selectedTechnicalAssistanceId) {
      throw new Error('Please select a technical assistance to send');
    }

    await api.patch({
      path: '/technicalAssistances',
      body: {
        where: {
          id: selectedTechnicalAssistanceId,
        },
        data: {
          applications: {
            connect: {
              id: applicationId,
            },
          },
        },
        action: 'updateAndConnect',
      },
    });

    callback();
    fetchAndSetTechnicalAssistance();
    handleCloseTechnicalAssistanceModal();

    alertSignal.update({
      type: 'notification',
      message: 'Technical assistance has been sent!',
      variant: 'success',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleSendTechnicalAssistanceClick = (applicationId, technicalAssistanceId) => {
  const { technicalAssistances } = edoTechnicalAssistanceSignal.value;
  const technicalAssistance = technicalAssistances.find(ta => ta.id === technicalAssistanceId);

  $technicalAssistance.update({ technicalAssistance });

  edoTechnicalAssistanceSignal.update({
    modalType: TECHNICAL_ASSISTANCE_MODALS.send,
    applicationId,
    sentTechnicalAssistanceId: technicalAssistanceId || null
  });
}
