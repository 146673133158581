import api from "api/api";
import { fetchAndSetFund } from "components/global/FundDetails/fundDetails.helpers";
import alertSignal from "signals/Alert.signal";
import loaderSignal from "signals/Loader.signal";

export const handleAddToPortfolioCompaniesClick = async (businessId, fundId) => {
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.post({
      path: '/vcPortfolioCompanies',
      body: {
        data: {
          businessId: Number(businessId),
          fundId: Number(fundId),
        },
      },
    });

    await fetchAndSetFund(fundId);

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully added portfolio company!',
    })
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};