import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import organizationsListSignal, {
  organizationDetailsSignal,
  shareOrganizationSignal,
} from './OrganizationsList.signal';
import history from '../../../history';
import { camelCaseToTitleText } from 'utils/formatTxt';
import { getStatus } from 'libs/account';
import { titleCaseString } from 'libs/string';
import { DateTime } from 'luxon';
import $appSettings from 'signals/AppSettings.signal';

const BASE_ACCOUNT_INCLUDE = {
  _count: {
    select: {
      users: true,
    },
  },
  receivedUserInvites: true,
};

export const fetchVentureCapitalFirms = async (fundId) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const [fund] = await api.get({
    path: '/funds',
    options: {
      where: {
        id: fundId,
      },
      include: {
        fundingProgramMembership: {
          include: {
            ventureCapitalFirm: true,
            fundingProgram: true,
          },
        },
        vcPortfolioCompanies: {
          orderBy: xFormHistoryToOrderBy(PORTAL_TYPES.business),
          where: xFormHistoryToWhere(PORTAL_TYPES.business),
          include: {
            business: {
              include: {
                account: {
                  include: {
                    ...BASE_ACCOUNT_INCLUDE,
                  },
                },
              },
            },
          },
        },
      },
    },
  });

  const businesses = fund.vcPortfolioCompanies
    .map((vpc) => vpc.business)
    .flatMap((b) => b);

  return {
    businesses: businesses.map((b) => ({ ...b.account, business: b })),
    fund,
  };
};

export const fetchAndSetFundingProgramMemberships = async (
  fundingProgramMembershipId
) => {
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;

  const fundingProgramMembership = await api.get({
    path: '/fundingProgramMemberships',
    options: {
      where: {
        id: Number(fundingProgramMembershipId),
      },
      include: {
        lender: true,
        fundingProgram: true,
        applications: {
          orderBy: xFormHistoryToOrderBy(PORTAL_TYPES.business),
          where: xFormHistoryToWhere(PORTAL_TYPES.business),
          include: {
            business: {
              include: {
                account: {
                  include: {
                    ...BASE_ACCOUNT_INCLUDE,
                  },
                },
              },
            },
          },
        },
      },
    },
  });

  const { fundingProgram, lender } = fundingProgramMembership;
  const businesses = fundingProgramMembership.applications
    .filter((app) => app.status !== APPLICATION_STATUS.inProgress)
    .map((a) => a.business)
    .flatMap((b) => b);

  return {
    businesses: businesses.map((b) => ({ ...b.account, business: b })),
    fundingProgram,
    lender,
  };
};

export const fetchAndSetOrganizations = async () => {
  try {
    const pathname = history.location.pathname;
    const resourceId = pathname.split('/')[pathname.split('/').length - 1];

    let fundingProgram,
      lender,
      fund = {};
    let accounts = [];
    if (pathname.includes('fund')) {
      const data = await fetchVentureCapitalFirms();
      accounts = data?.businesses;
      fund = data?.fund;
    } else if (pathname.includes('program-membership')) {
      const data = await fetchAndSetFundingProgramMemberships(resourceId);
      accounts = data?.businesses;
      fundingProgram = data?.fundingProgram;
      lender = data?.lender;
    } else {
      accounts = await fetchAccounts();
    }

    return organizationsListSignal.update({
      accounts,
      fundingProgram,
      lender,
      fund,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  }
};

export const fetchAccounts = async () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const pathname = history.location.pathname;
  let portalType;
  if (pathname.includes('/edo/business')) {
    portalType = PORTAL_TYPES.business;
  }

  if (pathname.includes('/edo/vc')) {
    portalType = PORTAL_TYPES.vc;
  }

  if (pathname.includes('/edo/lender')) {
    portalType = PORTAL_TYPES.lender;
  }

  const include = {
    business: {
      include: {
        businessDocuments: true,
      },
    },
    lender: {
      include: {
        fundingProgramMemberships: {
          include: {
            fundingProgram: true,
          },
        },
      },
    },
    ventureCapitalFirm: {
      include: {
        fundingProgramMemberships: {
          include: {
            fundingProgram: true,
          },
        },
      },
    },
  };

  let options = {
    include: {
      ...BASE_ACCOUNT_INCLUDE,
      ...include,
    },
    where: { portalType, ...xFormHistoryToWhere(portalType) },
    orderBy: xFormHistoryToOrderBy(portalType),
  };

  return api.get({
    path: '/accounts',
    options,
  });
};

export const fetchOrganization = async (organizationId, path) => {
  let options = {
    where: {
      id: Number(organizationId),
    },
  };

  if (path === '/businesses') {
    options.include = {
      ...options.include,
      businessDocuments: true,
      applications: {
        include: {
          fundingProgramMembership: {
            include: {
              fundingProgram: true,
              lender: true,
            },
          },
        },
      },
      vcPortfolioCompanies: {
        include: {
          fund: {
            include: {
              fundingProgramMembership: {
                include: {
                  ventureCapitalFirm: true,
                  fundingProgram: true,
                },
              },
            },
          },
        },
      },
    };
  }

  if (path === '/ventureCapitalFirms' || path === '/lenders') {
    options.include = {
      ...options.include,
      favoritedByBusinesses: true,
      fundingProgramMemberships: {
        include: {
          applications: {
            include: {
              business: true,
            },
          },
          fundingProgram: true,
          funds: true,
        },
      },
      programRequests: {
        include: {
          fundingProgram: true,
        },
      },
      account: {
        select: {
          portalType: true,
        },
      },
    };
  } else {
    options.include = {
      ...options.include,
      favoritedByLenders: true,
      favoritedByVcs: true,
    };
  }

  loaderSignal.update({ isContentLoading: true });
  try {
    const organization = await api.get({
      path,
      options,
    });

    organizationDetailsSignal.update({ selectedOrganization: organization });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const hideOrganizationDetailsModal = () => {
  organizationDetailsSignal.reset();
};

export const showOrganizationDetailsModal = (organization) => {
  organizationDetailsSignal.update({
    selectedOrganization: organization,
    modalVisible: true,
  });
};

const xFormHistoryToOrderBy = (portalType) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  const { sort } = params;

  const orderBy = {
    organization: {},
  };

  if (sort) {
    const [key, dir] = sort.split('-');
    if (key === 'contactEmail' && portalType !== PORTAL_TYPES.business) {
      orderBy.organization['email'] = dir;
    } else {
      orderBy.organization[key] = dir;
    }
  } else {
    orderBy.organization.id = 'desc';
  }

  if (portalType === PORTAL_TYPES.business) {
    orderBy.business = orderBy.organization;
  } else if (portalType === PORTAL_TYPES.lender) {
    orderBy.lender = orderBy.organization;
  } else if (portalType === PORTAL_TYPES.vc) {
    orderBy.ventureCapitalFirm = orderBy.organization;
  }

  delete orderBy.organization;

  return orderBy;
};

const xFormHistoryToWhere = (portalType) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const where = {
    organization: {},
  };

  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  if (params.name) {
    where.organization.name = { contains: params.name, mode: 'insensitive' };
  }

  if (params.email) {
    where.organization.email = { contains: params.email, mode: 'insensitive' };
  }

  if (params.contactEmail) {
    if (portalType === PORTAL_TYPES.business) {
      where.organization.contactEmail = {
        contains: params.contactEmail,
        mode: 'insensitive',
      };
    } else {
      where.organization.email = {
        contains: params.contactEmail,
        mode: 'insensitive',
      };
    }
  }

  if (params.contactName) {
    where.organization.contactName = {
      contains: params.contactName,
      mode: 'insensitive',
    };
  }

  if (params.id) {
    where.organization.id = { in: [Number(params.id)] };
  }

  if (portalType === PORTAL_TYPES.business) {
    where.business = where.organization;
  } else if (portalType === PORTAL_TYPES.lender) {
    where.lender = where.organization;
  } else if (portalType === PORTAL_TYPES.vc) {
    where.ventureCapitalFirm = where.organization;
  }

  delete where.organization;

  return where;
};

export const handleProgramMembershipLinkClick = () => {
  organizationDetailsSignal.update({
    modalVisible: false,
  });
};

export const getRow = (account) => {
  const record =
    account.lender || account.business || account.ventureCapitalFirm;

  return {
    id: record?.id,
    organizationName: record?.name || '-',
    contactName: record?.contactName || '-',
    contactEmail:
      record?.email?.toLowerCase() ||
      record?.contactEmail?.toLowerCase() ||
      '-',
    status: titleCaseString(getStatus(account)),
    need:
      record?.assistance?.desiredAssistanceTypes
        ?.map((t) => camelCaseToTitleText(t))
        .join(', ') || 'Unknown',
    createdDate: DateTime.fromISO(account.createdDate).toFormat('DD'),
  };
};

export const handleDetailClick = (record, newPath) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  let organization;
  switch (record.portalType) {
    case PORTAL_TYPES.business:
      organization = record.business;
      break;
    case PORTAL_TYPES.lender:
      organization = record.lender;
      break;
    case PORTAL_TYPES.vc:
      organization = record.ventureCapitalFirm;
      break;
    default:
      break;
  }

  history.push(`${newPath}/${organization.id}`);
};

function debounce(func, delay) {
  let timeoutId;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

const fetchOrganizations = async (name) => {
  try {
    shareOrganizationSignal.update({
      fetching: true,
      organizations: [],
      selectedOrganization: null,
    });
    const where = { name: { contains: name, mode: 'insensitive' } };
    const options = {
      where,
      include: {
        account: true,
      },
    };

    const [lenders, ventureCapitalFirms] = await Promise.all([
      api.get({ path: '/lenders', options }),
      api.get({ path: '/ventureCapitalFirms', options }),
    ]);

    const organizations = [...lenders, ...ventureCapitalFirms].sort((a, b) =>
      a.name.localeCompare(b.name.localeCompare())
    );

    return shareOrganizationSignal.update({ organizations });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    shareOrganizationSignal.update({ fetching: false });
  }
};

const debouncedFetchOrganizations = debounce(fetchOrganizations, 800);

export const handleShareModalOrganizationNameChange = (organizationName) => {
  shareOrganizationSignal.update({ organizationName });
  if (organizationName.length > 2) {
    return debouncedFetchOrganizations(organizationName);
  } else {
    return shareOrganizationSignal.update({ organizations: [] });
  }
};

export const showShareOrganizationModal = (selectedBusiness) =>
  shareOrganizationSignal.update({ selectedBusiness, modalVisible: true });

export const hideShareOrganizationModal = () =>
  shareOrganizationSignal.update({
    organizationName: '',
    selectedBusiness: null,
    modalVisible: false,
    organizations: [],
  });

export const handleClickOrganization = (organization) => {
  const { selectedOrganization } = shareOrganizationSignal.value;

  if (
    selectedOrganization?.id === organization.id &&
    selectedOrganization?.account.portalType === organization.account.portalType
  ) {
    shareOrganizationSignal.update({ selectedOrganization: null });
  } else {
    shareOrganizationSignal.update({ selectedOrganization: organization });
  }
};

export const shareBusinessWithOrganization = async () => {
  try {
    loaderSignal.update({ isContentLoading: true, message: 'Submitting ' });
    const { selectedBusiness, selectedOrganization } =
      shareOrganizationSignal.value;
    let body;

    if (selectedOrganization.account.portalType === PORTAL_TYPES.LENDER) {
      body = {
        where: {
          lenderId_businessId: {
            lenderId: selectedOrganization.id,
            businessId: selectedBusiness.id,
          },
        },
        create: {
          lenderId: selectedOrganization.id,
          businessId: selectedBusiness.id,
        },
        update: {
          updatedDate: new Date().toISOString(),
        },
      };
    } else {
      body = {
        where: {
          ventureCapitalFirmId_businessId: {
            ventureCapitalFirmId: selectedOrganization.id,
            businessId: selectedBusiness.id,
          },
        },
        create: {
          ventureCapitalFirmId: selectedOrganization.id,
          businessId: selectedBusiness.id,
        },
        update: {
          updatedDate: new Date().toISOString(),
        },
      };
    }

    await api.put({ path: '/businessSuggestions', body });

    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully shared business',
    });

    return hideShareOrganizationModal();
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};
