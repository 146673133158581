import Select from 'components/global/Field/FieldComponents/Select';
import PaginationButton from 'components/global/PaginationButton/PaginationButton';
import SearchInput from 'components/global/SearchInput';
import { useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { getRows } from 'utils/pagination';
import { USER_PROGRAM_ACCESS_OPTIONS } from '../Constant/constants';
import LargeCheckBox from '../Constant/LargeCheckBox';
import { handleSimpleProgramSearchChange } from './programAccess.helpers';
import { programAccessSignal } from './programAccess.signal';
import loaderSignal from 'signals/Loader.signal';

const ProgramAccess = ({
  programAccesses,
  userRolesPerProgram = [],
  fundingPrograms,
  onProgramAccessUpdate,
  displayCheck = false,
}) => {
  const {
    programsInView,
    programsFilteredBySearch,
    page,
  } = programAccessSignal.value;
  const recordsPerPage = 8;
  const totalNumRecords = programsFilteredBySearch.length;
  const totalNumOfPages = Math.ceil(totalNumRecords / recordsPerPage);

  useEffect(() => {
    programAccessSignal.update({ programsFilteredBySearch: fundingPrograms });
  }, [fundingPrograms]);

  useEffect(() => {
    const newProgramsInView = getRows(
      programsFilteredBySearch,
      page,
      recordsPerPage
    );
    programAccessSignal.update({ programsInView: newProgramsInView });
  }, [page, programsFilteredBySearch]);

  return (
    <>
      <Row>
        <Col lg={6}>
          <SearchInput
            onChange={(e) =>
              handleSimpleProgramSearchChange(e, fundingPrograms)
            }
          />
        </Col>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>Program</th>
              <th>Access</th>
            </tr>
          </thead>
          <tbody>
            {!displayCheck &&
              programsInView.map((fundingProgram) => {
                const programAccess = programAccesses?.find(
                  ({ fundingProgramId }) =>
                    fundingProgramId === fundingProgram.id
                );
                return (
                  <tr key={fundingProgram.id}>
                    <td style={{ verticalAlign: 'middle' }}>
                      <p className="mb-0">{fundingProgram.name}</p>
                    </td>
                    <td>
                      <Select
                        disabled={loaderSignal.value.isContentLoading}
                        name={`user-program-access-program-${fundingProgram.id}`}
                        inputClassName="form-control"
                        value={
                          (userRolesPerProgram &&
                            userRolesPerProgram[fundingProgram.id]) ||
                          ''
                        }
                        options={USER_PROGRAM_ACCESS_OPTIONS}
                        onChange={(e) =>
                          onProgramAccessUpdate(e, {
                            fundingProgram,
                            role: e.target.value,
                            programAccess,
                          })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            {displayCheck &&
              programsInView.map((fundingProgram) => {
                const checked = programAccesses.find(
                  (pa) => pa.fundingProgramId === fundingProgram.id
                );
                return (
                  <tr key={fundingProgram.id}>
                    <td style={{ verticalAlign: 'middle' }}>
                      <p className="mb-0">{fundingProgram.name}</p>
                    </td>
                    <td>
                      <LargeCheckBox
                        name={`user-program-access-program-${fundingProgram.id}`}
                        checked={checked}
                        size="sm"
                        value={fundingProgram.id}
                        onClick={() => onProgramAccessUpdate(fundingProgram.id)}
                        disabled={false}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Row>
      <Row className="text-center">
        <Col>
          <PaginationButton
            increment={false}
            onClick={() => programAccessSignal.update({ page: page - 1 })}
            hidden={page <= 0}
          />
        </Col>
        <Col>
          <p>
            {page + 1}/{totalNumOfPages}
          </p>
        </Col>
        <Col>
          <PaginationButton
            increment
            onClick={() => programAccessSignal.update({ page: page + 1 })}
            hidden={page >= totalNumOfPages - 1}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProgramAccess;
