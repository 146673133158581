import $appSettings from "signals/AppSettings.signal";
import { $edoCustomize } from "./edoCustomize.signal";
import loaderSignal from "signals/Loader.signal";
import api from "api/api";
import alertSignal from "signals/Alert.signal";

export const handleInputChange = (e) => {
  const { value, name } = e.target ? e.target : e;

  $edoCustomize.update({
    newWhiteLabelConfig: {
      ...$edoCustomize.value.newWhiteLabelConfig,
      [name]: value,
    },
    changesPreviewed: false,
  });
};

export const handleBodyBackgroundChange = (color) => $edoCustomize.update({
  newWhiteLabelConfig: {
    ...$edoCustomize.value.newWhiteLabelConfig,
    bodyBackgroundColor: color,
  },
  changesPreviewed: false,
});

export const edoCustomizeCleanUp = () => {
  $appSettings.update({
    appSettings: {
      ...$appSettings.value.appSettings,
      whiteLabelConfig: $edoCustomize.value.activeWhiteLabelConfig,
    },
  });

  $edoCustomize.reset();
};

export const submitWhiteLabelChanges = async () => {
  const { newWhiteLabelConfig } = $edoCustomize.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    const updatedAppSettings = await api.patch({
      path: '/appSettings/update',
      body: {
        data: {
          whiteLabelConfig: newWhiteLabelConfig,
        },
      },
    });

    $appSettings.update({
      appSettings: updatedAppSettings,
    });
    $edoCustomize.update({ activeWhiteLabelConfig: updatedAppSettings.whiteLabelConfig, modalVisible: null });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleLogoChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;

      $edoCustomize.update({
        newWhiteLabelConfig: {
          ...$edoCustomize.value.newWhiteLabelConfig,
          logoBase64String: base64String,
        },
        changesPreviewed: false,
      });
    }

    reader.readAsDataURL(file);
  }
};

export const setAllToDefault = () => {
  $appSettings.update({
    appSettings: {
      ...$appSettings.value.appSettings,
      whiteLabelConfig: {},
    },
  });

  $edoCustomize.update({
    newWhiteLabelConfig: {
      bodyBackgroundColor: null,
      headerBackgroundColor: null,
      logoBase64String: null,
      tagline: 'Powered by MStreetX',
      taglineColor: null,
    },
  });
};

export const handlePreviewChangesClick = () => {
  $appSettings.update({
    appSettings: {
      ...$appSettings.value.appSettings,
      whiteLabelConfig: $edoCustomize.value.newWhiteLabelConfig,
    },
  });
  $edoCustomize.update({
    changesPreviewed: true,
  });
};

export const handleDiscardChangesClick = () => {
  $appSettings.update({
    appSettings: {
      ...$appSettings.value.appSettings,
      whiteLabelConfig: $edoCustomize.value.activeWhiteLabelConfig,
    },
  });
  $edoCustomize.update({
    newWhiteLabelConfig: $edoCustomize.value.activeWhiteLabelConfig,
  });
};

export const handleSingleFieldDiscardClick = (name) => {
  $appSettings.update({
    appSettings: {
      ...$appSettings.value.appSettings,
      whiteLabelConfig: {
        ...$appSettings.value.appSettings.whiteLabelConfig,
        [name]: $edoCustomize.value.activeWhiteLabelConfig[name] || null,
      },
    },
  });

  $edoCustomize.update({
    newWhiteLabelConfig: {
      ...$edoCustomize.value.newWhiteLabelConfig,
      [name]: $edoCustomize.value.activeWhiteLabelConfig[name] || null,
    },
  });
};

export const allCustomizationFieldsAreDefault = () => {
  const { activeWhiteLabelConfig } = $edoCustomize.value;

  if (!activeWhiteLabelConfig) {
    return true;
  }

  return Object.values(activeWhiteLabelConfig).every(value => value === null);
};

export const allCustomizationFieldsHaveNotChanged = () => {
  const { activeWhiteLabelConfig, newWhiteLabelConfig } = $edoCustomize.value;

  if (!activeWhiteLabelConfig && !newWhiteLabelConfig) {
    return true;
  }

  return Object.keys(newWhiteLabelConfig).every(field => newWhiteLabelConfig[field] === activeWhiteLabelConfig[field]);
};

export const singleCustomizationFieldHasNotChanged = (name) => {
  const { activeWhiteLabelConfig, newWhiteLabelConfig } = $edoCustomize.value;

  if (!activeWhiteLabelConfig && !newWhiteLabelConfig) {
    return true;
  }

  return newWhiteLabelConfig[name] === activeWhiteLabelConfig[name];
};

export const setInitialCustomizeViewData = () => $edoCustomize.update({
  activeWhiteLabelConfig: {
    ...$appSettings.value.appSettings.whiteLabelConfig,
    tagline:
      $appSettings.value.appSettings.whiteLabelConfig.tagline === null ||
        $appSettings.value.appSettings.whiteLabelConfig.tagline === undefined
        ? 'Powered by MStreetX'
        : $appSettings.value.appSettings.whiteLabelConfig.tagline,
  },
  newWhiteLabelConfig: {
    ...$appSettings.value.appSettings.whiteLabelConfig,
    tagline:
      $appSettings.value.appSettings.whiteLabelConfig.tagline === null ||
        $appSettings.value.appSettings.whiteLabelConfig.tagline === undefined
        ? 'Powered by MStreetX'
        : $appSettings.value.appSettings.whiteLabelConfig.tagline,
  },
});
