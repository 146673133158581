import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { activityIcon } from 'components/global/Constant/Icons';

const Activity = () => (
  <Card className="p-3 mb-4 text-secondary">
    <Row className="mb-4">
      <Col>
        <h5><FontAwesomeIcon icon={activityIcon} /> Activity</h5>
      </Col>
    </Row>
  </Card>
);

export default Activity;
