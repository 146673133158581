import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import EditProgramModal from "./components/EditProgramModal";
import { fetchAndSetPrograms, handleEdoProgramsFilterChange, resetEdoProgramFilterInputs } from "./EdoPrograms.helpers";
import ProgramSignal, { fundingProgramsSignal } from "signals/Program.signal";
import { selectFundingProgramForEditing } from "../EdoProgramDetail/EdoProgramDetail.helpers";
import SimpleTable from "components/global/SimpleTable";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { useHistory } from "react-router";
import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'programType',
    title: 'Program Type',
  },
  {
    key: 'platformType',
    title: 'Platform Type',
  },
  {
    key: 'transactionLeverageRatio',
    title: 'Transaction Leverage Ratio',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const EdoPrograms = () => {
  const { sort, dropdownFilters } = ProgramSignal.value;
  const history = useHistory();

  useEffect(() => {
    fetchAndSetPrograms();
  }, [history.location.search]);

  return (
    <BreadCrumbWrapper>
      <Card className="rounded-0">
        <Card.Body>
          <h3 className="text-center">Programs</h3>
          <hr />
          <div className="d-flex flex-row justify-content-between mb-16">
            <SimpleTableFilterDropdown
              filtersState={dropdownFilters}
              onChange={({ name, value }) =>
                handleEdoProgramsFilterChange(name, value)
              }
              onApply={() => {
                fetchAndSetPrograms();
              }}
              onClearFilterInputs={resetEdoProgramFilterInputs}
              filters={[
                {
                  type: 'integer',
                  label: 'ID',
                  key: 'id',
                },
              ]}
            />
            <div>
              <Button
                className="rounded"
                onClick={() => history.push('/edo/programs/add-program')}
              >
                New Program
              </Button>
            </div>
          </div>
          <SimpleTable
            headers={HEADERS}
            sort={sort}
            onClick={(index) => {
              const fundingProgram = fundingProgramsSignal.value[index];
              selectFundingProgramForEditing(fundingProgram);
            }}
            onSort={() => {
              fetchAndSetPrograms();
            }}
            rows={fundingProgramsSignal.value.map((fundingProgram) => ({
              id: fundingProgram.id,
              name: fundingProgram.name,
              programType: fundingProgram.referenceProgram.programName,
              platformType: fundingProgram.platform.name,
              transactionLeverageRatio: fundingProgram.stats.loanAmount
                ? (
                    fundingProgram.stats.borrowerLoanAmount /
                    fundingProgram.stats.loanAmount
                  ).toLocaleString()
                : '-',
              action: ({ key }) => (
                <td className="text-right" key={key}>
                  <Button
                    className="rounded"
                    size="sm"
                    variant="outline-dark"
                    onClick={() => {
                      history.push(`/edo/programs/${fundingProgram.id}`);
                    }}
                  >
                    Detail
                  </Button>
                </td>
              ),
            }))}
          />
        </Card.Body>
      </Card>
      <EditProgramModal />
    </BreadCrumbWrapper>
  );
};

export default EdoPrograms;
