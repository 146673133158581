import { Alert, Button, Col, Dropdown, Modal, Row } from 'react-bootstrap';
import formatMoney from 'utils/formatMoney';
import DocumentButtons from 'components/global/DataRoom/components/DocumentButtons';
import { DATA_ROOM_COMPONENT_VIEW_TYPES } from 'components/global/Constant/constants';
import userAccountSignal from 'signals/UserAccount.signal';
import { useEffect, useState } from 'react';
import { enumCaseToTabCase } from 'libs/string';
import CapitalCallApprovalChain from './components/CapitalCallApprovalChain';
import $capitalCall, {
  $capitalCallPortionBeingEdited,
} from './CapitalCall.signals';
import {
  fetchAndSetCapitalCall,
  handleAttachmentCapitalCallFile,
  handleCapitalCallStatusChange,
  handleCapitalCallStatusSubmission,
  handleCapitalCallTransactionTermsChange,
  handleNotifyEdoMissingFunds,
  handlePortfolioCompanyNameClick,
  handleSaveTransactionTermsClick,
  handleUpdateCapitalCallDocumentStatus,
  hideCapitalPortionTransactionTerms,
  setCapitalCallPortionToEdit,
  setCapitalCallPortionToSendFundsTo,
} from './CapitalCall.helpers';
import { useHistory } from 'react-router';
import BreadCrumbWrapper from '../BreadCrumbs/BreadCrumbs';
import { getStatusStyles } from 'libs/functions/global.functions';
import { humanize } from 'utils/formatTxt';
import CapitalCallApprovalChainSubmissionModal from './components/CapitalCallApprovalChainSubmissionModal';
import FundApplicationModal from '../FundApplication/components/FundApplicationModal';
import { DateTime } from 'luxon';
import SimpleTable from '../SimpleTable';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import TransactionTermsForm from '../TransactionTermsForm';
import CapitalCallSendFundsModal from './components/CapitalCallSendFundsModal';
import CapTableModal from '../CapTable/CapTableModal';
import Field from '../Field/Field';
import $appSettings from 'signals/AppSettings.signal';
import { handleCapTableClick } from '../CapTable/capTable.helpers';
import BackButton from '../BackButton';
import { generateFileDownload } from '../utils/FilesAndDocuments/DownloadButton/DownloadButton.helpers';

const CapitalCall = () => {
  const [confirmFunds, setConfirmFunds] = useState(false);
  const [remindNotFunded, setRemindNotFunded] = useState(false);
  const { newStatus, capitalCall } = $capitalCall.value;
  const capitalCallPortionBeingEdited = $capitalCallPortionBeingEdited.value;
  const { PORTAL_TYPES, CAPITAL_CALL_STATUS } = $appSettings.value.constants;
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const split = pathname.split('/');
  const id = split[split.length - 1];

  useEffect(() => {
    fetchAndSetCapitalCall(id);
  }, [id]);

  if (!capitalCall) {
    return null;
  }

  const portalType = userAccountSignal.value.userData.account.portalType;

  const capitalCallTotal = capitalCall.capitalCallPortions.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );

  const fundDocumentsWithMatchingUploaded = capitalCall.fund.documents
    .filter((d) => d.documentType === 'CAPITAL_CALL')
    .map((fundDocument) => {
      const matchingUploaded = capitalCall.documents.find(
        (doc) => doc.fundDocumentId === fundDocument.id
      );

      if (matchingUploaded) {
        return {
          ...fundDocument,
          uploadedDocument: matchingUploaded,
        };
      }

      return fundDocument;
    });

  const fileInput = document.getElementById('capitalCallDetailFileUpload');
  const resetFileInput = () => fileInput && (fileInput.value = '');

  const findIndex = capitalCall.fund.capitalCalls.findIndex(
    (cc) => cc.id === capitalCall.id
  );

  let labels = [];
  if (location.pathname.includes('funds')) {
    labels = [
      '',
      `${capitalCall.fund.fundName}`,
      `Capital Call - Round ${findIndex + 1}`,
    ];
  } else if (location.pathname.includes('equity-program')) {
    labels = [
      'Performance',
      capitalCall.fund?.programRequest?.fundingProgram?.name,
      `Capital Call - Round ${findIndex + 1}`,
    ];
  } else {
    labels = [];
  }

  return (
    <>
      <BackButton onClick={() => history.goBack()} buttonText="Back" />
      <BreadCrumbWrapper labels={labels}>
        <FundApplicationModal />
        <CapitalCallApprovalChainSubmissionModal />
        <CapitalCallSendFundsModal />
        <CapTableModal />
        <ModalWrapper
          show={!!capitalCallPortionBeingEdited}
          size="xl"
          centered={true}
        >
          <Modal.Header>
            <h5>
              Edit Transaction Terms for{' '}
              <i>
                {
                  capitalCallPortionBeingEdited?.vcPortfolioCompany.business
                    .name
                }
              </i>
            </h5>
          </Modal.Header>
          <Modal.Body>
            <TransactionTermsForm
              transactionTerms={
                capitalCallPortionBeingEdited?.transactionTerms || {}
              }
              onChange={handleCapitalCallTransactionTermsChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => hideCapitalPortionTransactionTerms()}
            >
              Dismiss
            </Button>
            <Button onClick={() => handleSaveTransactionTermsClick()}>
              Update
            </Button>
          </Modal.Footer>
        </ModalWrapper>
        <div className="bg-white shadow-sm border border-gray p-32">
          <div className="d-flex align-items-center">
            <h3>Capital Call - Round {findIndex + 1}</h3>
            <p
              className={`${
                getStatusStyles(capitalCall.status).class
              } p-1 rounded ml-2 mt-2`}
            >
              {humanize(capitalCall.status)}
            </p>
          </div>
          <hr />
          <Row className="mt-16">
            <Col>
              <p className="mb-0">
                <b>Program</b>
              </p>
              <p>
                {capitalCall.fund.fundingProgramMembership.fundingProgram.name}
              </p>
            </Col>
            <Col>
              <p className="mb-0">
                <b>Fund</b>
              </p>
              <p>{capitalCall.fund.fundName}</p>
            </Col>
            <Col>
              <p className="mb-0">
                <b>Venture Capitalist</b>
              </p>
              <p>
                {
                  capitalCall.fund.fundingProgramMembership.ventureCapitalFirm
                    .name
                }
              </p>
            </Col>
            <Col>
              <p className="mb-0">
                <b>Date</b>
              </p>
              <p>{new Date(capitalCall.suggestedDate).toLocaleDateString()}</p>
            </Col>
            <Col>
              <p className="mb-0">
                <b>Total</b>
              </p>
              <p>{formatMoney(capitalCallTotal)}</p>
            </Col>
          </Row>
          <hr />
          {portalType === PORTAL_TYPES.edo &&
            capitalCall.status === CAPITAL_CALL_STATUS.submittedForApproval && (
              <div className="bg-light p-32 shadow-sm">
                <CapitalCallApprovalChain />
              </div>
            )}
          {portalType === PORTAL_TYPES.edo &&
            capitalCall.status !== CAPITAL_CALL_STATUS.approved &&
            capitalCall.status !== CAPITAL_CALL_STATUS.funded &&
            capitalCall.status !== CAPITAL_CALL_STATUS.submittedForApproval && (
              <>
                <Row className="bg-light p-32 shadow-sm">
                  <Col className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <h5>Update Status</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <Field.Select
                        value={newStatus}
                        className="mr-16"
                        inputClassName="form-control"
                        onChange={handleCapitalCallStatusChange}
                        options={Object.values(CAPITAL_CALL_STATUS)
                          .filter(
                            (status) =>
                              ![
                                CAPITAL_CALL_STATUS.funded,
                                CAPITAL_CALL_STATUS.approved,
                                CAPITAL_CALL_STATUS.pending,
                                CAPITAL_CALL_STATUS.disbursed,
                              ].includes(status)
                          )
                          .map((status) => ({
                            label: enumCaseToTabCase(status),
                            value: status,
                          }))}
                        disabled={newStatus !== ''}
                      />
                    </div>

                    {newStatus !== CAPITAL_CALL_STATUS.submittedForApproval &&
                      newStatus !== '' && (
                        <div className="d-flex align-items-center">
                          <p className="mb-0 font-weight-bold mr-16">
                            Are you sure you want to update the status to{' '}
                            {enumCaseToTabCase(newStatus)}?
                          </p>
                          <Button
                            variant="secondary"
                            className="mr-16"
                            onClick={() =>
                              handleCapitalCallStatusChange({ value: '' })
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              handleCapitalCallStatusSubmission(newStatus);
                              handleCapitalCallStatusChange({ value: '' });
                            }}
                          >
                            Confirm
                          </Button>
                        </div>
                      )}
                  </Col>
                </Row>
              </>
            )}
          {portalType === PORTAL_TYPES.vc &&
            capitalCall.status === CAPITAL_CALL_STATUS.approved && (
              <Alert variant="info">
                <h5>This capital call has been approved by the EDO</h5>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0">
                    Please confirm that you have received the funds due on{' '}
                    {DateTime.fromISO(capitalCall.disbursementDate).toFormat(
                      'DD'
                    )}
                  </p>
                  <div className="d-flex">
                    <Button
                      variant="outline-info mr-2"
                      disabled={remindNotFunded}
                      onClick={() => {
                        setRemindNotFunded(true);
                        setConfirmFunds(false);
                      }}
                    >
                      Funds Not Received
                    </Button>
                    <Button
                      variant="info"
                      disabled={confirmFunds}
                      onClick={() => {
                        setConfirmFunds(true);
                        setRemindNotFunded(false);
                      }}
                    >
                      Funds Received
                    </Button>
                  </div>
                </div>

                {remindNotFunded && (
                  <>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mt-24">
                      <p className="mb-0">
                        <span className="font-weight-bold">
                          The EDO will be informed about the missing funds.
                        </span>
                        {capitalCall.fundsMissingSubmittedDate && (
                          <span>
                            {' '}
                            The EDO was last reminded on{' '}
                            {DateTime.fromISO(
                              capitalCall.fundsMissingSubmittedDate
                            ).toFormat('DD - h:mm a')}
                          </span>
                        )}
                      </p>

                      <div>
                        <Button
                          className="mr-16"
                          variant="outline-secondary"
                          onClick={() => setRemindNotFunded(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="outline-info"
                          onClick={() => handleNotifyEdoMissingFunds()}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {confirmFunds && (
                  <>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mt-24">
                      <p className="mb-0 font-weight-bold">
                        By selecting confirm, you will set the status of this
                        capital call to funded. Would you like to proceed?
                      </p>
                      <div>
                        <Button
                          className="mr-16"
                          variant="outline-secondary"
                          onClick={() => setConfirmFunds(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="outline-info"
                          onClick={() =>
                            handleCapitalCallStatusSubmission(
                              CAPITAL_CALL_STATUS.funded
                            )
                          }
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Alert>
            )}
          {capitalCall.status === CAPITAL_CALL_STATUS.funded && (
            <Alert variant="success">
              <h5>This capital call has been funded!</h5>
              <p>It is now your turn to push the funds to the businesses.</p>
            </Alert>
          )}
          {capitalCall.capitalCallPortions?.find(
            (ccp) =>
              !ccp.fundedConfirmedDate &&
              new Date(ccp.fundsMissingSubmittedDate) >
                new Date(ccp.fundsPushedToBusinessDate)
          ) && (
            <Alert variant="warning">
              <h5>A business is missing funds!</h5>
              {capitalCall.capitalCallPortions
                ?.filter(
                  (ccp) =>
                    !ccp.fundedConfirmedDate &&
                    new Date(ccp.fundsMissingSubmittedDate) >
                      new Date(ccp.fundsPushedToBusinessDate)
                )
                ?.map((ccp) => (
                  <Row key={ccp.id} className="align-items-center">
                    <Col>
                      <p className="mb-0">
                        {ccp.vcPortfolioCompany.business.name} is missing funds.
                      </p>
                    </Col>
                    <Col className="text-right">
                      <Button
                        variant="outline-secondary"
                        onClick={() => setCapitalCallPortionToSendFundsTo(ccp)}
                      >
                        Send Funds to {ccp.vcPortfolioCompany.business.name}
                      </Button>
                    </Col>
                  </Row>
                ))}
            </Alert>
          )}
          <h5 className="mt-48 mb-16">Funded Businesses</h5>
          <div className="extend-table-container">
            {' '}
            {/* class necessary so action dropdown doesn't get hidden */}
            <SimpleTable
              headers={[
                {
                  key: 'name',
                  title: 'Name',
                  bold: true,
                },
                {
                  key: 'amount',
                  title: 'Amount',
                  currency: true,
                },
                {
                  key: 'stageOfInvestment',
                  title: 'Stage of Investment',
                },
                {
                  key: 'securityType',
                  title: 'Security Type',
                },
                {
                  key: 'ssbciOwnershipPercentage',
                  title: 'SSBCI Ownership %',
                },
                {
                  key: 'conversionDiscount',
                  title: 'Conversion Discount',
                },
                {
                  key: 'valuationCap',
                  title: 'Valuation Cap',
                },
                {
                  key: 'actions',
                  title: 'Actions',
                },
              ]}
              rows={capitalCall.capitalCallPortions.map((ccp) => {
                const statusHasMovedPastPending =
                  capitalCall.status ===
                    CAPITAL_CALL_STATUS.submittedForApproval ||
                  capitalCall.status === CAPITAL_CALL_STATUS.approved ||
                  capitalCall.status === CAPITAL_CALL_STATUS.disbursed ||
                  capitalCall.status === CAPITAL_CALL_STATUS.funded;

                const capTable = statusHasMovedPastPending
                  ? ccp.capTable?.tableData
                  : ccp.vcPortfolioCompany?.business?.capTable?.tableData;

                return {
                  name: ({ key }) => (
                    <td
                      key={key}
                      className="font-weight-bold pointer text-decoration-underline"
                      onClick={() =>
                        handlePortfolioCompanyNameClick(ccp.vcPortfolioCompany)
                      }
                    >
                      {ccp.vcPortfolioCompany.business.name}
                    </td>
                  ),
                  amount: ccp.amount,
                  stageOfInvestment: ccp.transactionTerms.stageOfInvestment
                    ? ccp.transactionTerms.stageOfInvestment.split(' - ')[0]
                    : '-',
                  securityType:
                    (ccp.transactionTerms.otherSecurityTypeOffered
                      ? `${ccp.transactionTerms.otherSecurityTypeOffered} (other)`
                      : ccp.transactionTerms.securityType) || '-',
                  ssbciOwnershipPercentage:
                    ccp.transactionTerms.ssbciOwnershipPercentage || '-',
                  conversionDiscount:
                    ccp.transactionTerms.conversionDiscount || '-',
                  valuationCap: ccp.transactionTerms.valuationCap
                    ? `${ccp.transactionTerms.valuationCap} (${ccp.transactionTerms.typeOfValuationCap})`
                    : '-',
                  actions: ({ key }) => (
                    <td key={key} className="d-flex justify-content-end">
                      <Dropdown>
                        <Dropdown.Toggle size="sm" variant="outline-primary">
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              generateFileDownload({
                                uploadedFile: {
                                  ...ccp.ssbciCOIChecklist,
                                  parentId: ccp.id,
                                },
                                model: 'capitalCallPortionDocuments',
                                signal: undefined,
                                downloadName: 'COI Checklist',
                              })
                            }
                          >
                            See SSBCI COI Checklist
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setCapitalCallPortionToEdit(ccp)}
                          >
                            Edit Transaction Terms
                          </Dropdown.Item>

                          {userAccountSignal.value.userData.account
                            .portalType === PORTAL_TYPES.vc && (
                            <Dropdown.Item
                              disabled={
                                capitalCall?.status !==
                                  CAPITAL_CALL_STATUS.funded
                              }
                              onClick={() =>
                                setCapitalCallPortionToSendFundsTo(ccp)
                              }
                            >
                              {ccp.fundsPushedToBusinessDate
                                ? `Funds pushed on ${DateTime.fromISO(
                                    ccp.fundsPushedToBusinessDate
                                  ).toFormat('DD h:mm a')}`
                                : 'Send funds'}
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            disabled={
                              !ccp.vcPortfolioCompany
                                .sensitiveInformationPermission &&
                              !statusHasMovedPastPending
                            }
                            onClick={() =>
                              handleCapTableClick(
                                capTable,
                                null,
                                ccp.vcPortfolioCompany.business.name
                              )
                            }
                          >
                            {ccp.vcPortfolioCompany
                              .sensitiveInformationPermission ||
                            statusHasMovedPastPending
                              ? 'View Cap Table'
                              : 'Business has not provided Cap Table Permission'}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  ),
                };
              })}
            />
          </div>
          <h5 className="mb-16">Documents</h5>
          <DocumentButtons
            documents={fundDocumentsWithMatchingUploaded}
            documentBucket="capitalCallDocuments"
            viewType={
              portalType === PORTAL_TYPES.vc
                ? DATA_ROOM_COMPONENT_VIEW_TYPES.upload
                : DATA_ROOM_COMPONENT_VIEW_TYPES.updateStatus
            }
            onAttachSingleDocument={(doc) => {
              $capitalCall.update({ capitalCallTemplate: doc });
              document.getElementById('capitalCallDetailFileUpload')?.click();
            }}
            onSelectChange={handleUpdateCapitalCallDocumentStatus}
          />
          <hr />
          <input
            type="file"
            hidden
            id="capitalCallDetailFileUpload"
            onChange={(e) => {
              e.target.files.length &&
                handleAttachmentCapitalCallFile(e.target.files[0]);
              resetFileInput();
            }}
          />
          {capitalCall.bankAccount && (
            <div>
              <hr />
              <p>
                <b>Bank Account:</b> {capitalCall.bankAccount?.bankAccountName}{' '}
                <i>(ending in {capitalCall.bankAccount?.mask})</i>
              </p>
            </div>
          )}
        </div>
      </BreadCrumbWrapper>
    </>
  );
};

export default CapitalCall;
