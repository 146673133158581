import CloseModalButton from 'components/global/Constant/CloseModalButton';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Card, Modal } from 'react-bootstrap';
import $capitalCallPortion from '../capitalCallPortion.signal';
import { CAPITAL_CALL_PORTION_MODAL } from '../capitalCallPortion.helpers';

const CapitalCallPortionNotesModal = () => (
  <ModalWrapper
    centered
    show={
      $capitalCallPortion.value.modalVisible ===
      CAPITAL_CALL_PORTION_MODAL.notes
    }
    onHide={() => $capitalCallPortion.update({ modalVisible: null })}
    size="lg"
  >
    <Modal.Header>
      <h4>Notes</h4>
      <CloseModalButton
        onCloseModal={() => $capitalCallPortion.update({ modalVisible: null })}
      />
    </Modal.Header>
    <Modal.Body>
      <Card
        style={{ height: 200, overflowY: 'scroll' }}
        className="px-16 py-8 rounded-0"
      >
        {$capitalCallPortion.value.capitalCallPortion?.notes?.map((note) => (
          <div className="bg-light rounded p-1 mb-4" key={note.uuid}>
            <p>
              {new Date(note.createdDate).toLocaleDateString()} - {note.content}
            </p>
          </div>
        ))}
      </Card>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="outline-secondary"
        onClick={() => $capitalCallPortion.update({ modalVisible: null })}
      >
        Dismiss
      </Button>
    </Modal.Footer>
  </ModalWrapper>
);

export default CapitalCallPortionNotesModal;
