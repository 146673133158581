import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import NewFund from 'components/global/NewFund';

const VCNewFund = () => (
  <BreadCrumbWrapper labels={['Funds', 'Create New Fund']}>
    <NewFund />
  </BreadCrumbWrapper>
);

export default VCNewFund;
