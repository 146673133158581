import { Button, Card, Col, Row } from 'react-bootstrap';
import {
  RECORD_FIELDS,
  RECORD_FIELDS_TO_RENDER,
  fetchAndSetSearchResults,
  handleEnterKeyDown,
  handleGlobalSearchChange,
} from './globalSearch.helpers';
import { Fragment, useEffect } from 'react';
import $globalSearchResults from './globalSearchResults.signal';
import { useHistory } from 'react-router-dom';
import BreadCrumbWrapper from '../BreadCrumbs/BreadCrumbs';
import {
  handleGlobalSearch,
  redirectToResourceFromGlobalSearch,
} from 'libs/searchNavigation';
import Field from '../Field';
import HTMLRenderer from '../HTMLRenderer';
import { ensureHttpsInHrefs } from 'libs/string';
import { camelCaseToTitleText } from 'utils/formatTxt';

const CardContent = ({ searchResult }) => (
  <Card.Body className="px-40 py-24">
    <Row>
      {RECORD_FIELDS_TO_RENDER[searchResult.recordType].map((field) => (
        <Fragment key={field}>
          {RECORD_FIELDS[field](searchResult.record) !== null && (
            <Col
              className={`col-12 ${
                field === 'Description' ? '' : 'col-sm-6 col-lg-3'
              }`}
            >
              <p className="mb-0 font-weight-bold">{field}:</p>
              {field === 'Description' && searchResult.recordType === 'FundingProgram' ?
                (
                  <HTMLRenderer className="mb-0" htmlString={ensureHttpsInHrefs(RECORD_FIELDS[field](searchResult.record))} />
                ) :
                (
                  <p>{RECORD_FIELDS[field](searchResult.record)}</p>
                )}
            </Col>
          )}
        </Fragment>
      ))}
    </Row>
  </Card.Body>
);

const GlobalSearchResults = () => {
  const history = useHistory();

  useEffect(() => fetchAndSetSearchResults(), [history.location.search]);
  return (
    <BreadCrumbWrapper className="mb-40">
      <Card className="p-32">
        <Row className="align-items-center">
          <Col>
            <h3>Search Results</h3>
          </Col>
          <Col>
            <div className="d-flex align-items-end w-100">
              <Field.TextInput
                labelClassName="font-weight-bold"
                className="w-100"
                value={$globalSearchResults.value.query}
                onChange={handleGlobalSearchChange}
                onKeyDown={handleEnterKeyDown}
              />
              <Button
                className="ml-16"
                onClick={() =>
                  handleGlobalSearch($globalSearchResults.value.query)
                }
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        <hr />
        {!$globalSearchResults.value.searchResults?.length && (
          <p className="font-weight-bold">
            We&apos;re sorry. We were not able to find a match. Please try
            another search.
          </p>
        )}

        {$globalSearchResults.value.searchResults?.map((searchResult) => (
          <Card key={searchResult.id} className="mt-24">
            <Card.Header className="rounded-0 font-weight-bold d-flex justify-content-between align-items-center">
              <h5 className="mb-0">{searchResult.recordType === 'VentureCapitalFirm'
            ? 'VC'
            : camelCaseToTitleText(searchResult.recordType)} - {searchResult.recordId}</h5>
              <p
                className="pointer text-primary mb-0"
                onClick={() => redirectToResourceFromGlobalSearch(searchResult)}
              >
                <u>View</u>
              </p>
            </Card.Header>
            <CardContent searchResult={searchResult} />
          </Card>
        ))}
      </Card>
    </BreadCrumbWrapper>
  );
};

export default GlobalSearchResults;
