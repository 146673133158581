import Signal from 'libs/signals';

const billingSignal = Signal({
  bankAccounts: [],
  selectedBankAccount: null,
  fundingPrograms: [],
  toDeleteBankAccount: null,
  linkedPrograms: [],
  programsInView: [],
  programsFilteredBySearch: [],
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
  linkProgramsModalVisible: false,
  linkedProgramsDetailModalState: null,
  linkedPlatformsDetailModalVisible: false,
  linkedPlatforms: [],
});

export default billingSignal;
