import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import HTMLRenderer from '../HTMLRenderer';
import { addHttpsToUrl, ensureHttpsInHrefs } from 'libs/string';
import dateOptions from '../Constant/dateOptions';
import { useLocation } from 'react-router-dom';
import {
  searchProgramsResultsModalStateSignal,
  searchProgramsResultsSelectedProgram,
} from '../SearchProgramResults/SearchProgramsResults.signal';
import fundingProgramDetailSignal from './FundingProgramDetail.signal';
import { useCallback, useEffect } from 'react';
import { fetchAndSetProgramDetail } from './FundingProgramDetail.helpers';
import ProgramRequestSubmissionModal from './components/LenderProgramInformationAndAccessModal';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';

function eligibilityString(eligibility) {
  if (Array.isArray(eligibility)) {
    return eligibility.map((e) => ensureHttpsInHrefs(e));
  }

  if (typeof eligibility === 'string') {
    return ensureHttpsInHrefs(eligibility);
  }

  return '';
}

const FundingProgramDetail = () => {
  const { pathname } = useLocation();
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const { fundingProgram, programRequests, fundingProgramMemberships } =
    fundingProgramDetailSignal.value;
  const submittedRequest = programRequests.find(
    (programRequest) => programRequest.fundingProgramId === fundingProgram.id
  );
  const existingFundingProgramMembership = fundingProgramMemberships.find(
    (fpm) => fpm.fundingProgramId === fundingProgram.id
  );

  const fundingProgramId = pathname.split('/')[pathname.split('/').length - 1];

  const init = useCallback(() => {
    fetchAndSetProgramDetail(fundingProgramId);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  if (!fundingProgram) {
    return null;
  }

  return (
    <>
      <ProgramRequestSubmissionModal />
      <Card className="rounded-0 mb-32">
        <Card.Body>
          {(submittedRequest || existingFundingProgramMembership) && (
            <Alert variant="success">
              <Alert.Heading>
                {fundingProgram.name} -{' '}
                {fundingProgram.referenceProgram.programName}
              </Alert.Heading>
              {existingFundingProgramMembership ? (
                <p>You are already a part of this program.</p>
              ) : (
                <p>
                  Program access has been requested. You may monitor your status
                  for this request in your Programs tab through the main
                  dashboard.
                </p>
              )}
            </Alert>
          )}
          {!submittedRequest && !existingFundingProgramMembership && (
            <h5>
              {fundingProgram.name} -{' '}
              {fundingProgram.referenceProgram.programName}
            </h5>
          )}
          <hr />
          <p className="font-weight-bold mb-8">Overview</p>
          <Col className="px-16 py-8">
            <HTMLRenderer
              className="mb-0"
              htmlString={ensureHttpsInHrefs(fundingProgram.overview)}
            />
          </Col>
          <p className="font-weight-bold my-8">Eligibility</p>
          <Col className="px-16 py-8">
            <HTMLRenderer
              className="mb-0"
              htmlString={eligibilityString(fundingProgram.eligibility)}
            />
          </Col>
          <p className="font-weight-bold my-8">Ineligible Businesses</p>
          <Col className="px-16 py-8">
            <HTMLRenderer
              className="mb-0"
              htmlString={ensureHttpsInHrefs(
                fundingProgram.ineligibleBusinesses
              )}
            />
          </Col>
          <p className="font-weight-bold my-8">Program Rules</p>
          <Col className="px-16 py-8">
            <HTMLRenderer
              className="mb-0"
              htmlString={ensureHttpsInHrefs(fundingProgram.programRules)}
            />
          </Col>
          <Row>
            <Col>
              <p className="font-weight-bold my-8">Program Funds</p>
              <Col className="px-16 py-8">
                <p className="mb-0">
                  {(
                    fundingProgram.ceiling -
                    fundingProgram.fundsAlreadyAllocated
                  ).toLocaleString('en-US', dateOptions.currencyUS)}
                </p>
              </Col>
            </Col>
            <Col>
              <p className="font-weight-bold my-8">Website</p>
              {fundingProgram.website ? (
                <Col className="px-16 py-8">
                  <a
                    className="mb-0"
                    target="_blank"
                    href={addHttpsToUrl(fundingProgram.website)}
                    rel="noreferrer"
                  >
                    {fundingProgram.website}
                  </a>
                </Col>
              ) : (
                <Col className="px-16 py-8">
                  <p className="mb-0">-</p>
                </Col>
              )}
              <p className="font-weight-bold my-8">Remaining funds</p>
              <Col className="px-16 py-8">
                <p className="mb-0">
                  {fundingProgram.stats.remaining.toLocaleString(
                    'en-US',
                    dateOptions.currencyUS
                  )}
                </p>
              </Col>
            </Col>
          </Row>

          {userAccountSignal.value.userData.account.portalType !==
            PORTAL_TYPES.business && (
            <Row className="mt-8 px-16 justify-content-end">
              {!submittedRequest && !existingFundingProgramMembership && (
                <Button
                  className="rounded"
                  onClick={() => {
                    searchProgramsResultsSelectedProgram.update(fundingProgram);

                    if (
                      userAccountSignal.value.userData.account.portalType ===
                      PORTAL_TYPES.vc
                    ) {
                      searchProgramsResultsModalStateSignal.update({
                        step: 1,
                        show: true,
                      });
                    } else {
                      searchProgramsResultsModalStateSignal.update({
                        step: 2,
                        show: true,
                      });
                    }
                  }}
                >
                  Request Approval
                </Button>
              )}
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FundingProgramDetail;
