/* eslint-disable no-unused-vars */
import stateBillingSignal from 'signals/StateBilling.signal';
import linkAccountFormSignal from 'signals/LinkAccount.signal';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import sleep from 'libs/sleep';
import { paymentAccounts } from 'api/mocks/paymentAccounts.mock';

export const fetchAndSetPaymentAccounts = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    await sleep(1000);
    stateBillingSignal.update({ paymentAccounts });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
  return true;
};

export const deleteAccount = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    await sleep(1000);
    // await api.apiFetch({
    //   path: `/admin/billing/${accountId}`,
    // });
    await fetchAndSetPaymentAccounts();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully deleted account',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
  return true;
};

export const handleSelectAccount = (accountId) => {
  const newSelectedAccount = stateBillingSignal.value.paymentAccounts?.filter(
    (account) => account.id === accountId
  )[0];

  stateBillingSignal.update({
    selectedAccount: newSelectedAccount,
  });
};

export const updateBankInfo = (e) => {
  linkAccountFormSignal.update({
    bankInfo: {
      ...linkAccountFormSignal.value.bankInfo,
      [e.target.name]: e.target.value,
    },
  });
};

export const updateCreditCardInfo = (e) => {
  linkAccountFormSignal.update({
    creditCardInfo: {
      ...linkAccountFormSignal.value.creditCardInfo,
      [e.target.name]: e.target.value,
    },
  });
};

export const handleResetForms = () => {
  linkAccountFormSignal.reset();
};

export const addPaymentAccount = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    await sleep(1000);
    // await api.apiFetch({
    //   path: '/admin/payment-account',
    //   body: linkAccountFormSignal.value || {},
    // });
    await fetchAndSetPaymentAccounts();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully added payment account',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
  return true;
};

export default {};
