import SimpleTable from "components/global/SimpleTable";
import fundDetailsSignal from "../fundDetails.signal";
import capitalizeFirst from "utils/capitalizeFirst";
import { Alert, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'name',
    title: 'Business Name'
  },
  {
    key: 'ein',
    title: 'EIN',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const PortfolioCompanyRequests = () => {
  const history = useHistory();
  const { fundRequests } = fundDetailsSignal.value;

  return (
    <div className="mt-16 px-40">
      {!!fundRequests.length ?
        (
          <SimpleTable
            headers={HEADERS}
            rows={fundRequests.map(fr => ({
              status: capitalizeFirst(fr.status),
              name: fr.business.name,
              ein: fr.business.ein,
              action: () => (
                <td align="right">
                  <Button
                    className="rounded"
                    variant="outline-dark"
                    size="sm"
                    onClick={() => history.push(`${history.location.pathname}/portfolio-company-request/${fr.business.id}`)}
                  >
                    Details
                  </Button>
                </td>
              )
            }))}
          />
        ) :
        (
          <Alert>There are no requests for this fund.</Alert>
        )}
    </div>
  );
};

export default PortfolioCompanyRequests;
