import { Col, Container, Row } from 'react-bootstrap';
import {
  fetchAndSetBusinessSignUpData,
  fetchAndSetLenderSignUpData,
  fetchAndSetSubmissionsData,
  fetchAndSetVcSignUpData,
} from './executiveDashboard.helpers';
import $executiveDashboard from './executiveDashboard.signal';
import { useEffect } from 'react';
import Loadable from 'components/global/Loadable';
import EntityLineChart from './components/EntityLineChart';

const ExecutiveDashboard = () => {
  useEffect(() => {
    fetchAndSetSubmissionsData();
  }, [$executiveDashboard.value.submissions.query]);

  useEffect(() => {
    fetchAndSetLenderSignUpData();
  }, [$executiveDashboard.value.lenderSignUps.query]);

  useEffect(() => {
    fetchAndSetVcSignUpData();
  }, [$executiveDashboard.value.vcSignUps.query]);

  useEffect(() => {
    fetchAndSetBusinessSignUpData();
  }, [$executiveDashboard.value.businessSignUps.query]);

  return (
    <Container className="mb-40">
      <Row className="mt-40">
        <Col xs={12}>
          <div className="bg-white border border-solid">
            <Loadable isLoading={$executiveDashboard.value.submissions.loader}>
              <EntityLineChart entity="submissions" />
            </Loadable>
          </div>
        </Col>
      </Row>
      <Row className="mt-32">
        <Col lg={6}>
          <div className="bg-white border border-solid">
            <Loadable
              isLoading={$executiveDashboard.value.lenderSignUps.loader}
            >
              <EntityLineChart entity="lenderSignUps" />
            </Loadable>
          </div>
        </Col>
        <Col lg={6}>
          <div className="bg-white border border-solid">
            <Loadable
              isLoading={$executiveDashboard.value.vcSignUps.loader}
            >
              <EntityLineChart entity="vcSignUps" />
            </Loadable>
          </div>
        </Col>
        <Col lg={6} className="mt-32">
          <div className="bg-white border border-solid">
            <Loadable
              isLoading={$executiveDashboard.value.businessSignUps.loader}
            >
              <EntityLineChart entity="businessSignUps" />
            </Loadable>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ExecutiveDashboard;
