import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import OrganizationsList from "components/global/OrganizationsList/OrganizationsList";
import organizationsListSignal from "components/global/OrganizationsList/OrganizationsList.signal";
import { useLocation } from "react-router-dom";

const EdoVcFundDetail = () => {
  const location = useLocation();
  const segments = location.pathname.split('/');
  const { fund } = organizationsListSignal.value;
  let labels = [];

  if (!!fund) {
    try {
      const { programRequest } = fund;
      const { ventureCapitalFirm, fundingProgram } = programRequest;
      if (location.pathname.includes('programs')) {
        labels = [
          '',
          !!fundingProgram ? fundingProgram.name : '',
          !!fundingProgram ? `Funds in ${fundingProgram.name}` : '',
          `${fund.fundName} Businesses`,
        ];
      } else {
        labels = [
          segments[2] === 'vc' ? 'Venture Capitalists' : '',
          !!ventureCapitalFirm ? ventureCapitalFirm.name : '',
          !!fundingProgram ? fundingProgram.name : '',
          `${fund.fundName} Businesses`,
        ];
      }
    } catch (e) {}
  }

  return (
    <BreadCrumbWrapper labels={labels}>
      <div className="border border-gray rounded-0 p-40 shadow-sm bg-white">
        <OrganizationsList
          tableTitle="Portfolio Companies"
          newPath={`${location.pathname}/business`}
        />
      </div>
    </BreadCrumbWrapper>
  );
};

export default EdoVcFundDetail;
