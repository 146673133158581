const pullOrganizationNameFromAccount = (account) => {
  let organizationName = '-';

  switch (account?.portalType) {
    case 'EDO':
      organizationName = account.edo?.name;
      break;
    case 'STATE':
      organizationName = 'STATE';
      break;
    case 'BUSINESS':
      organizationName = account.business?.companyName;
      break;
    case 'LENDER':
      organizationName = account.lender?.name;
      break;
    // TODO: Add VC when we get this.
    // case 'VC':
    //   organizationName = user.account.vc.companyName;
    default:
      break;
  }

  return organizationName;
};

export default pullOrganizationNameFromAccount;
