import Signal from 'libs/signals';

export const lenderPortfolioSignal = Signal({
  applications: [],
  bundle: {},
  dropdownFilters: {},
  appliedFilters: {},
  totalPages: 0,
  recordsPerPage: 20,
});

export const lenderPortfolioFilterSignal = Signal('');

export const lenderPortfolioSelectedApplicationSignal = Signal({
  id: 0,
  status: '',
  committedDate: new Date(),
  expectedByDate: new Date(),
  approvedOnDate: new Date(),
  edoContactName: '',
  applicationId: '',
  businessName: '',
  businessContactName: '',
  loanAsk: undefined,
  approvedAmount: undefined,
  previousBalance: undefined,
  newBalance: undefined,
  previousRating: '',
  newRating: '',
  watched: false,
  requiredDocuments: [],
});

export const lenderPortfolioActionModalStateSignal = Signal({
  visible: false,
  currentView: '',
  modalSize: '',
});

export const lenderPortfolioIncompleteApplicationsUploadSignal = Signal([]);

export const lenderPortfolioIncompleteApplicationDocumentCheckSignal =
  Signal('');

export const lenderPortfolioIncompleteApplicationDocumentUploadErrorSignal =
  Signal(false);

export const lenderProgramsIncompleteApplicationSelectedFileSignal = Signal({
  selectedFile: null,
  fileURL: '',
});

export const lenderProgramsIncompleteApplicationsNoteSignal = Signal('');

export const lenderPortfolioApplicationDocumentTypesSignal = Signal([]);
