import { useState, useEffect, useCallback } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import { fetchAndSetPrograms, fetchAndSetYears, fetchAndSetStates, handleInputChange, nextStep, handleSelection } from '../StateMigrate.helpers';
import $appSettings from 'signals/AppSettings.signal';

const ChooseStateAndProgram = ({ portalType = 'EDO' }) => {
  const { form: { state, program, ein, year } } = AdminMigrateSignal.value[portalType];
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const [programs, setPrograms] = useState([]);
  const [programType, setProgramType] = useState('');
  const [states, setStates] = useState([]);
  const [years, setYears] = useState([]);

  const init = useCallback(async () => {
    if (portalType === PORTAL_TYPES.state) {
      await fetchAndSetStates(setStates);
    }
    await Promise.all([
      fetchAndSetYears(setYears),
      fetchAndSetPrograms(setPrograms),
    ]);
  });

  useEffect(() => {
    init();
  }, []);

  const getYearSelections = () => years.map(y => <option value={y} key={y}>{y}</option>);
  const getStateSelections = () => states.map(name => <option value={name} key={name}>{name}</option>);
  const getProgramSelections = () => programs.map(prg => <option value={prg.id} key={prg.id}>{prg.title}</option>);

  const isBtnDisabled = () => {
    if (portalType === PORTAL_TYPES.state) {
      return !program.id || !year || !(ein && ein.length === 10);
    }
    return !program.id || !year || !(ein && ein.length === 10);
  };

  return (
    <Container className="p-16 bg-light">
      <Row className="mb-32">
        <Col>
          <h5>Application Migration</h5>
        </Col>
      </Row>
      <Row>
        <Col sm={10}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">Year</Form.Label>
            <Form.Select
              className="form-control"
              name="year"
              value={year}
              onChange={handleInputChange}
            >
              {getYearSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row hidden={portalType !== PORTAL_TYPES.state}>
        <Col sm={10}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">States connected to your portfolio:</Form.Label>
            <Form.Select
              className="form-control"
              name="state"
              value={state}
              onChange={handleInputChange}
            >
              {getStateSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">Program</Form.Label>
            <Form.Select
              className="form-control"
              name="program"
              value={program.id}
              onChange={e => handleSelection(e, programs, setProgramType)}
            >
              {getProgramSelections()}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group" hidden={!programType}>
            <Form.Label className="font-weight-bold text-secondary">Program Type</Form.Label>
            <p className="font-weight-bold text-info">{programType}</p>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">EIN #</Form.Label>
            <Form.Control
              type="string"
              className="form-control"
              name="ein"
              value={ein}
              onChange={handleInputChange}
            />
            <Form.Control.Feedback type="invalid">Please enter a 9 digit EIN #</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center p-16">
        <Button onClick={nextStep} disabled={isBtnDisabled()}>Continue</Button>
      </Row>
    </Container>
  );
};

export default ChooseStateAndProgram;
