import Signal from 'libs/signals';

export default Signal({
  modalVisible: false,
  emailError: false,
  disablePortalSelect: false,
  portal: '',
  organizationName: '',
  firstName: '',
  lastName: '',
  email: '',
  addBusinessToFavorites: false,
  // eslint-disable-next-line
  refreshCurrentPageData: () => { },
});
