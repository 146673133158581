import Signal from 'libs/signals';

const searchBusinessResultsSignal = Signal({
  businesses: [],
  dropdownFilters: {},
  modalOpen: false,
  selectedBusiness: null,
});

export default searchBusinessResultsSignal;
