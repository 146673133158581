import { Button, Col, Row } from 'react-bootstrap';
import dataRoomSignal from './dataRoom.signal';
import {
  fetchAndSetOrganizationDocuments,
  handleAddFilesFromFileZone,
  handleAdditionalDocumentNameChange,
  handleRemoveAdditionalDocuments,
  removeOrganizationDocument,
  submitFilesForUpload,
} from './dataRoom.helpers';
import { useCallback, useEffect } from 'react';
import { APPLICATION_FORMATS } from '../Constant/fileFormats';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import FileDropZoneWrapper from '../FileDropZone';
import DocumentTable from './components/DocumentTable';
import DocumentButtons from './components/DocumentButtons';
import DocumentTableWithInputs from './components/DocumentTableWithInputs';
import { DATA_ROOM_COMPONENT_VIEW_TYPES } from '../Constant/constants';
import CapTableModal from '../CapTable/CapTableModal';
import $capTable from '../CapTable/capTable.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';
import { handleCapTableClick } from '../CapTable/capTable.helpers';

const DataRoom = ({ documentBucket }) => {
  const {
    organizationDocuments,
    suggestedDocuments,
    filesToUpload,
    capTableRecord,
  } = dataRoomSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const init = useCallback(() => {
    dataRoomSignal.update({ documentBucket });
    fetchAndSetOrganizationDocuments();
  }, []);

  useEffect(() => {
    init();

    return () => $capTable.reset();
  }, [init]);

  return (
    <div className="border border-gray rounded-0 px-32 py-16 mb-32 bg-white shadow-sm">
      <CapTableModal />
      <h3 className="text-center">Data Room</h3>
      <hr />
      <div className="d-flex justify-content-end mb-24">
        <Button
          hidden={
            userAccountSignal.value.userData.account.portalType !==
            PORTAL_TYPES.business
          }
          onClick={() =>
            handleCapTableClick(
              capTableRecord?.tableData,
              fetchAndSetOrganizationDocuments,
              null,
              userAccountSignal.value.userData.account.portalType ===
                PORTAL_TYPES.business
            )
          }
        >
          View/Configure Cap Table
        </Button>
      </div>
      <Row hidden={!organizationDocuments.length} className="mb-32">
        <Col>
          <DocumentTable
            className="border p-16"
            documentBucket={documentBucket}
            documents={organizationDocuments}
            removeDocument={removeOrganizationDocument}
            tableDescription="These are all the files that you have uploaded that are descriptive of your business and its goals."
            tableTitle="Uploaded Files"
            viewType={DATA_ROOM_COMPONENT_VIEW_TYPES.dataRoom}
          />
        </Col>
      </Row>
      <Row className="mt-64">
        <Col lg={6}>
          <DocumentButtons
            documents={suggestedDocuments}
            description="These are documents that we encourage you to upload."
            title="Suggested Documents"
            documentBucket={documentBucket}
            documentType="suggestedDocuments"
            onSubmitFiles={submitFilesForUpload}
            viewType={DATA_ROOM_COMPONENT_VIEW_TYPES.dataRoom}
          />
        </Col>
      </Row>
      <Row className="mt-64">
        <Col>
          <h5>Additional Documents</h5>
          <p>
            You can also upload other documents that would strengthen your
            application.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="px-0">
          <FileDropZoneWrapper
            formats={APPLICATION_FORMATS}
            numOfUploadsAllowed={20}
            maxSizeMB={100}
            onUpload={(files) =>
              files.forEach((file) => handleAddFilesFromFileZone(file))
            }
          >
            <DragAndDrop
              files={[]}
              uploadProgress={0}
              maxSizeMB={100}
              preview={false}
            />
          </FileDropZoneWrapper>
        </Col>
        <Col hidden={!filesToUpload.length}>
          <DocumentTableWithInputs
            filesToUpload={filesToUpload}
            onDocumentNameChange={handleAdditionalDocumentNameChange}
            onRemoveDocument={handleRemoveAdditionalDocuments}
            onSubmitDocuments={() => submitFilesForUpload(filesToUpload)}
            submitButtonLabel="Submit Additional Documents"
            className="border p-16"
          />
        </Col>
      </Row>
    </div>
  );
};

export default DataRoom;
