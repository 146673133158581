import { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import SimpleTable from "components/global/SimpleTable";
import { useHistory } from "react-router-dom";
import { fetchAndSetApplications, handleDownloadLoansPerformanceProgramData } from "./EdoPerformanceLoans.helpers";
import edoPerformanceLoansSignal from "./EdoPerformanceLoans.signals";
import { enumCaseToTabCase, titleCaseString } from "../../../../../libs/string";
import BreadCrumbWrapper from "../../../../global/BreadCrumbs/BreadCrumbs";
import { camelCaseToTitleText } from "../../../../../utils/formatTxt";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'lender',
    title: 'Lender',
  },
  {
    key: 'business',
    title: 'Business',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'approved',
    title: 'Approved',
    currency: true,
  },
  {
    key: 'obligated',
    title: 'Obligated',
    currency: true,
  },
  {
    key: 'expended',
    title: 'Expended',
    currency: true,
  },
  {
    key: 'recycled',
    title: 'Recycled',
    currency: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const EdoPerformanceLoans = () => {
  const { applications, fundingProgram } = edoPerformanceLoansSignal.value;
  const history = useHistory();

  useEffect(() => {
    fetchAndSetApplications();
  }, [history.location.search]);

  return (
    <BreadCrumbWrapper labels={['Program Performance', fundingProgram?.name || '']}>
      <Row>
        <Col xs={9}>
          <Card className="rounded-0">
            <Card.Body>
              <h3 className="text-center">Loans</h3>
              <hr />
              <div className="d-flex flex-row justify-content-end mb-16">
                <Button
                  className="rounded"
                  onClick={handleDownloadLoansPerformanceProgramData}
                >
                  Download
                </Button>
              </div>
              <SimpleTable
                headers={HEADERS}
                onClick={(index) => {
                  const path = `${history.location.pathname}/application/${applications[index].id}`;
                  history.push(path);
                }}
                rows={applications.map((application) => ({
                  id: application.id,
                  lender:
                    application.fundingProgramMembership?.lender?.name || '?',
                  business: application.business?.name || '?',
                  status: enumCaseToTabCase(application.status),
                  approved: application.approvedAmount || 0,
                  obligated: application.stats.obligated,
                  expended: application.stats.expended,
                  recycled: application.stats.recycled,
                  action: ({ key }) => (
                    <td key={key} className="text-right">
                      <Button
                        size="sm"
                        className="rounded"
                        variant="outline-dark"
                        onClick={() => {
                          const path = `${history.location.pathname}/application/${application.id}`;
                          history.push(path);
                        }}
                      >
                        Application
                      </Button>
                    </td>
                  ),
                }))}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="rounded-0">
            <Card.Body>
              <h4>{fundingProgram?.name}</h4>
              <hr />
              {/*<ProgressBar*/}
              {/*    total={fundingProgram?.stats.allocated}*/}
              {/*    segments={[*/}
              {/*      {*/}
              {/*        amount: fundingProgram?.stats.obligated,*/}
              {/*        color: COLORS.obligated,*/}
              {/*        title: 'Obligated',*/}
              {/*      },*/}
              {/*      {*/}
              {/*        amount: fundingProgram?.stats.expended,*/}
              {/*        color: COLORS.expended,*/}
              {/*        title: 'Expended',*/}
              {/*      },*/}
              {/*    ]}*/}
              {/*/>*/}
              <h5 className="font-600">Program Funds</h5>
              <p>
                {'$' +
                  Number(
                    fundingProgram?.ceiling -
                      fundingProgram?.fundsAlreadyAllocated
                  ).toLocaleString()}
              </p>
              <h5 className="font-600">Obligated</h5>
              <p>{'$' + fundingProgram?.stats.obligated.toLocaleString()}</p>
              <h5 className="font-600">Expended</h5>
              <p>{'$' + fundingProgram?.stats.expended.toLocaleString()}</p>
              <h5 className="font-600">Remaining</h5>
              <p>{'$' + fundingProgram?.stats.remaining.toLocaleString()}</p>
              <hr />
              <h5 className="font-600">Program Config</h5>
              {Object.entries(fundingProgram?.programConfig || {}).map(
                ([key, value]) => (
                  <p key={key}>
                    <strong>{camelCaseToTitleText(key)}:</strong>{' '}
                    {isNaN(Number(value)) ? titleCaseString(value) : value}
                  </p>
                )
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </BreadCrumbWrapper>
  );
};

export default EdoPerformanceLoans;
