import Signal from 'libs/signals';

export type DocumentPreviewSignalType = {
  dataUrl: string | null,
  filename: string | null
}

const documentPreviewModalSignal = Signal<DocumentPreviewSignalType>({
  dataUrl: null,
  filename: null,
});

export default documentPreviewModalSignal;
