import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import LargeCheckBox from '../Constant/LargeCheckBox';
import { ApprovalChainTemplate, ApprovalChainTemplateStep } from 'types';
import Field from '../Field/Field';
import $appSettings from 'signals/AppSettings.signal';

type Props = {
  approvalChainTemplate: ApprovalChainTemplate;
  amount: string;
  onFieldChange: (
    skippedSteps: number[],
    fallbackToAlternateSteps: Record<string, number[]>,
    keyDates: Record<string, Date>
  ) => any;
};

const ApprovalChainSelectForm: React.FC<Props> = ({
  onFieldChange,
  approvalChainTemplate,
  amount,
}) => {
  const steps = approvalChainTemplate?.steps || [];
  const [skippedSteps, setSkippedSteps] = useState<number[]>([]);
  const [keyDates, setKeyDates] = useState<Record<string, Date>>({});
  const [fallbackToAlternatesSteps, setFallbackToAlternatesSteps] = useState<
    Record<string, number[]>
  >({});

  const handleBoardDateSelection = (
    step: ApprovalChainTemplateStep,
    date: Date
  ) => {
    setKeyDates({ ...setKeyDates, [step.id.toString()]: date });
  };

  const handleOnSelectedChange = (step: ApprovalChainTemplateStep) => {
    if (skippedSteps.includes(step.id)) {
      setSkippedSteps(skippedSteps.filter((ss) => ss !== step.id));
    } else {
      setSkippedSteps(skippedSteps.concat(step.id));
    }
  };

  const handleUseAlternatesChange = (step: ApprovalChainTemplateStep) => {
    if (fallbackToAlternatesSteps[step.id.toString()]) {
      const copy = { ...fallbackToAlternatesSteps };
      delete copy[step.id.toString()];

      setFallbackToAlternatesSteps(copy);
    } else {
      setFallbackToAlternatesSteps({
        ...fallbackToAlternatesSteps,
        [step.id.toString()]: [step.alternateApprovers[0].id],
      });
    }
  };

  useEffect(() => {
    onFieldChange(skippedSteps, fallbackToAlternatesSteps, keyDates);
  }, [skippedSteps, fallbackToAlternatesSteps, keyDates]);

  const filteredSteps = steps.filter((step) => {
    const { APPROVAL_CHAIN_AMOUNT_BOUNDS } = $appSettings.value.constants;
    if (isNaN(parseInt(amount, 10))) {
      return false;
    }

    if (steps.length === 1) {
      return true;
    }

    if (
      step.min === APPROVAL_CHAIN_AMOUNT_BOUNDS?.min &&
      step.max === APPROVAL_CHAIN_AMOUNT_BOUNDS?.max
    ) {
      return true;
    }

    const intApprovedAmount = parseInt(amount, 10);
    return step.min <= intApprovedAmount && intApprovedAmount < step.max;
  });

  const handledByTheBoard =
    filteredSteps.length === 1 && filteredSteps[0].boardRepresentative;

  if (!approvalChainTemplate) {
    return null;
  }

  return (
    <div>
      <h5 className="mb-0 mt-32">Approval Chain</h5>
      <Table className="mt-16" responsive>
        <thead>
          <tr>
            <th></th>
            <th></th>
            {handledByTheBoard ? (
              <>
                <th>Board Representative</th>
                <th>Board meeting date</th>
              </>
            ) : (
              <>
                <th>Approver</th>
                <th>Alternate</th>
                <th>Role</th>
                <th>Use Alternate?</th>
              </>
            )}
            <th>Will Approve</th>
          </tr>
        </thead>
        <tbody>
          {filteredSteps.map((approvalChainTemplateStep, index) => {
            const { primaryApprover, alternateApprovers } =
              approvalChainTemplateStep;

            const stepIsSkipped = !!skippedSteps.find(
              (ss) => ss === approvalChainTemplateStep.id
            );
            const stepFallingback = !!Object.keys(
              fallbackToAlternatesSteps
            ).find((key) => key === approvalChainTemplateStep.id.toString());

            return (
              <tr key={approvalChainTemplateStep.id + 1}>
                <td>{index + 1}</td>
                <td>
                  <LargeCheckBox
                    label={undefined}
                    size="sm"
                    checked={!stepIsSkipped}
                    disabled={stepFallingback || handledByTheBoard}
                    id={undefined}
                    onClick={() =>
                      handleOnSelectedChange(approvalChainTemplateStep)
                    }
                  />
                </td>
                <td>
                  <b>
                    {primaryApprover.firstName} {primaryApprover.lastName}
                  </b>
                </td>
                {handledByTheBoard ? (
                  <td>
                    <Field.DateSelect
                      // @ts-ignore
                      value={keyDates[approvalChainTemplateStep.id.toString()]}
                      name={undefined}
                      label={undefined}
                      // @ts-ignore
                      onChange={(e) =>
                        handleBoardDateSelection(
                          approvalChainTemplateStep,
                          e.value
                        )
                      }
                      className={undefined}
                      labelClassName={undefined}
                      inputClassName={undefined}
                      placeholder={'Enter date'}
                      disabled={false}
                      isInvalid={undefined}
                    />
                  </td>
                ) : (
                  <>
                    <td>
                      {alternateApprovers.length > 0 ? (
                        `${alternateApprovers[0].user.firstName} ${alternateApprovers[0].user.lastName}`
                      ) : (
                        <i>No alternate</i>
                      )}
                    </td>
                    <td>{approvalChainTemplateStep.role}</td>
                    <td>
                      <LargeCheckBox
                        label={undefined}
                        size="sm"
                        checked={stepFallingback}
                        disabled={
                          stepIsSkipped || alternateApprovers.length === 0
                        }
                        id={undefined}
                        onClick={() =>
                          handleUseAlternatesChange(approvalChainTemplateStep)
                        }
                      />
                    </td>
                  </>
                )}
                {stepIsSkipped ? (
                  <td>
                    <i>Skipped</i>
                  </td>
                ) : (
                  <td>
                    {stepFallingback
                      ? `${alternateApprovers[0].user.firstName} ${alternateApprovers[0].user.lastName}`
                      : `${primaryApprover.firstName} ${primaryApprover.lastName}`}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ApprovalChainSelectForm;
