import CloseModalButton from 'components/global/Constant/CloseModalButton';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import {
  handleApprovalChainTemplateChange,
  updateApplicationStatus,
} from '../../ApplicationDetail.helpers';

import applicationDetailSignal from '../../ApplicationDetail.signal';
import ApplicationConfirmStatusApplicationFields from './ApplicationConfirmStatusApplicationFields';
import ApprovalChainSelectForm from '../../../ApprovalChainSelectForm/ApprovalChainSelectForm';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import $appSettings from 'signals/AppSettings.signal';

const ConfirmationBody = () => {
  const { fundingProgram, newApplicationStatus, status, approvalChainConfig } =
    applicationDetailSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  if (
    newApplicationStatus === APPLICATION_STATUS.submittedForApproval ||
    (status === APPLICATION_STATUS.approved && !approvalChainConfig)
  ) {
    return (
      <>
        <p>
          <strong>Program Type: </strong>
          {fundingProgram.referenceProgram.programName}
        </p>
        <p>
          Please enter the configuration for the funds that will be going out to
          the Lender.
        </p>
        <Tabs defaultActiveKey="form" className="mt-24">
          <Tab eventKey="form" title="Loan Details">
            <ApplicationConfirmStatusApplicationFields />
          </Tab>
          <Tab eventKey="approvalChain" title="Approval Chain">
            <ApprovalChainSelectForm
              amount={
                applicationDetailSignal.value.approvedInputFields[
                  'approvedAmount'
                ] || 0
              }
              approvalChainTemplate={
                applicationDetailSignal.value.approvalChainTemplates &&
                applicationDetailSignal.value.approvalChainTemplates[0]
              }
              onFieldChange={handleApprovalChainTemplateChange}
            />
          </Tab>
        </Tabs>
      </>
    );
  }

  return <ApplicationConfirmStatusApplicationFields />;
};

const ApplicationConfirmStatusModal = () => {
  const { confirmStatusVisible, status, approvalChainConfig } =
    applicationDetailSignal.value;

  return (
    <ModalWrapper
      size="lg"
      className="upper-modal"
      backdropClassName="upper-modal-backdrop"
      centered
      show={confirmStatusVisible}
      onHide={() =>
        applicationDetailSignal.update({ confirmStatusVisible: false })
      }
      onExited={() =>
        applicationDetailSignal.update({
          approvedInputFieldsErrors: {},
          approvedInputFieldsDirty: {},
        })
      }
    >
      <Modal.Header>
        {status && !approvalChainConfig ? (
          <h4>Configure Approval</h4>
        ) : (
          <h4>Update Status</h4>
        )}
        <CloseModalButton
          onCloseModal={() =>
            applicationDetailSignal.update({
              confirmStatusVisible: false,
            })
          }
        />
      </Modal.Header>
      <Modal.Body>
        <ConfirmationBody />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="rounded mr-8"
          onClick={() =>
            applicationDetailSignal.update({
              confirmStatusVisible: false,
            })
          }
        >
          Cancel
        </Button>
        <Button className="rounded" onClick={() => updateApplicationStatus()}>
          Confirm
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ApplicationConfirmStatusModal;
