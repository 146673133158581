import { Col, Row } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import transactionSignal from 'signals/Transaction.signal';

const TransactionMetrics = ({ dateRangeFilter }) => {
  const { allTransactionMetrics } = transactionSignal.value;
  const { charts, totalVolume, avgTransaction } = allTransactionMetrics;

  if (!charts && !totalVolume && !avgTransaction) {
    return <p>There is not enough data.</p>;
  }

  return (
    <>
      <Row className="mb-16">
        <Col>
          <p>Total Volume</p>
          <h5 className="text-success">
            $
            {totalVolume?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) || 0}
          </h5>
        </Col>
        <Col>
          <p>Average Transaction</p>
          <h5 className="text-success">
            $
            {avgTransaction?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) || 0}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col className="bg-white mx-2">
          <h5>Transaction Breakdown</h5>
          <Doughnut
            data={charts?.transactionBreakDownDataSet?.[dateRangeFilter]}
            options={{ plugins: { legend: { position: 'bottom', onClick: null } } }}
          />
        </Col>
        <Col className="bg-white mx-2">
          <h5>Recent Transaction Volume</h5>
          <Bar
            data={charts?.recentTransactionVolume?.[dateRangeFilter]}
            options={{ plugins: { legend: { display: false } } }}
          />
        </Col>
      </Row>
    </>
  );
};

export default TransactionMetrics;
