import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Tab,
  Tabs,
} from 'react-bootstrap';
import platformsSignal, {
  editPlatformTranchesSignal,
} from '../Platforms.signal';
import { hideEditPlatformModal } from '../Platforms.helpers';
import EditPlatformPaymentOptionsTab from './EditPlatformPaymentOptionsTab';
import EditPlatformTranchesTab from './EditPlatformTranchesTab';
import PlatformTreasuryAllocation from './PlatformTreasuryAllocationTab';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import CloseModalButton from 'components/global/Constant/CloseModalButton';

const EditPlatformModal = () => {
  const { platformBeingEdited } = platformsSignal.value;

  return (
    <ModalWrapper
      show={!!platformBeingEdited}
      centered
      size="xl"
      onExited={() => editPlatformTranchesSignal.reset()}
      backdropClassName={null}
      onExit={null}
      onHide={hideEditPlatformModal}
    >
      <ModalHeader>
        <ModalTitle>{platformBeingEdited?.name}</ModalTitle>
        <CloseModalButton onCloseModal={hideEditPlatformModal} />
      </ModalHeader>
      <ModalBody>
        <Tabs className="scroll-tabs" defaultActiveKey="paymentOptions" mountOnEnter>
          <Tab eventKey="paymentOptions" title="Payment Options">
            <EditPlatformPaymentOptionsTab />
          </Tab>
          <Tab eventKey="tranches" title="Tranches">
            <EditPlatformTranchesTab />
          </Tab>
          <Tab eventKey="treasuryAllocation" title="Treasury Allocation">
            <PlatformTreasuryAllocation />
          </Tab>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={hideEditPlatformModal}
        >
          Dismiss
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
};

export default EditPlatformModal;
