import {
  FUNDS_UNDER_CUSTODY_OPTIONS,
  PROGRAM_CLASSIFICATIONS,
  PROGRAM_KEYS,
} from 'components/global/Constant/constants';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { fundingProgramFormErrorsSignal } from 'signals/Program.signal';
import RichTextEditor from 'components/global/Field/FieldComponents/RichTextEditor';
import { enumCaseToTabCase, titleCaseString } from '../../../../../libs/string';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

export const BasicProgramInfo = ({
  creatingProgram,
  administrators,
  handleInputChange,
  platforms,
  programTypes,
  programInfo,
  handleInputFocusOut,
}) => {
  const { PROGRAM_FUND_TYPES, REBALANCING_FREQUENCY_OPTIONS } = $appSettings.value.constants;
  const { programTypeId } = programInfo;
  const referencePrograms =
    programTypes.map((pt) => pt.referencePrograms).flatMap((a) => a) || [];
  const selectedReferenceProgram = referencePrograms.find(
    (rp) => rp.id === Number(programInfo.referenceProgramId)
  );
  const referenceProgramKey = selectedReferenceProgram?.key ?? '';
  let selectedPlatform = null;
  if (creatingProgram) {
    selectedPlatform = (platforms || []).find(
      (p) => p.id === Number(programInfo.platformId)
    );
  } else {
    selectedPlatform = programInfo.platform;
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Name of New Program
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="name"
              value={programInfo.name}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={fundingProgramFormErrorsSignal.value.name}
            />
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Platform
            </Form.Label>
            <Form.Select
              className="form-control"
              name="platformId"
              value={
                creatingProgram
                  ? programInfo.platformId
                  : programInfo.platform?.name
              }
              onChange={(e) => handleInputChange(e)}
              onBlur={handleInputFocusOut}
              isInvalid={fundingProgramFormErrorsSignal.value.platformId}
              disabled={!creatingProgram}
            >
              <option key="" value="">
                {creatingProgram
                  ? 'Select Platform'
                  : programInfo.platform?.name}
              </option>
              {platforms.map((p) => (
                <option value={p.id} key={p.id}>
                  {p.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.platformId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Program Type
            </Form.Label>
            <Form.Select
              className="form-control"
              name="programTypeId"
              disabled={!creatingProgram}
              value={programInfo.programTypeId}
              onChange={(e) => handleInputChange(e)}
              onBlur={handleInputFocusOut}
              isInvalid={fundingProgramFormErrorsSignal.value.programTypeId}
            >
              <option key="" value="">
                Select Program Type
              </option>
              {programTypes.map((pt) => (
                <option value={pt?.id} key={pt?.id}>
                  {pt?.programTypeName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.programTypeId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Program
            </Form.Label>
            <Form.Select
              className="form-control"
              name="referenceProgramId"
              disabled={!creatingProgram}
              value={programInfo.referenceProgramId}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={
                fundingProgramFormErrorsSignal.value.referenceProgramId
              }
            >
              <option key="" value="">
                Select Program
              </option>
              {programTypeId &&
                programTypes &&
                programTypes.length > 0 &&
                programTypes
                  .find((pt) => pt.id === programTypeId)
                  .referencePrograms.sort((referenceProgram) => {
                    if (
                      Object.values(PROGRAM_KEYS).includes(
                        referenceProgram.programName
                      )
                    ) {
                      return -1;
                    }
                    return 1;
                  })
                  .map((referenceProgram) => {
                    if (
                      Object.values(PROGRAM_KEYS).find(
                        (program) => program === referenceProgram.key
                      )
                    ) {
                      return (
                        <option
                          key={referenceProgram.id}
                          value={referenceProgram.id}
                        >
                          {referenceProgram.programName}
                        </option>
                      );
                    }
                    return (
                      <option
                        disabled
                        key={referenceProgram.id}
                        value={referenceProgram.id}
                      >
                        {referenceProgram.programName} (coming soon)
                      </option>
                    );
                  })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.programTypeId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            hidden={
              ![PROGRAM_KEYS.capitalAccess, PROGRAM_KEYS.loanGuaranty].includes(
                referenceProgramKey
              )
            }
            className="form-group"
          >
            <Form.Label className="font-weight-bold text-secondary">
              Funds under custody at
            </Form.Label>
            <Form.Select
              className="form-control"
              name="fundsUnderCustodyAt"
              value={programInfo.fundsUnderCustodyAt}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={
                fundingProgramFormErrorsSignal.value.fundsUnderCustodyAt
              }
            >
              <option key="" value="">
                Select an Option
              </option>
              {Object.values(FUNDS_UNDER_CUSTODY_OPTIONS).map((custody) => (
                <option key={custody} value={custody}>
                  {titleCaseString(custody)}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.fundsUnderCustodyAt}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            hidden={
              [PROGRAM_KEYS.microLoan, PROGRAM_KEYS.loanParticipation].indexOf(
                referenceProgramKey
              ) < 0
            }
            className="form-group"
          >
            <Form.Label className="font-weight-bold text-secondary">
              {referenceProgramKey === PROGRAM_KEYS.microLoan ? 'MLP' : 'LPP'}
            </Form.Label>
            <Form.Select
              className="form-control"
              name="programClassification"
              value={programInfo.programClassification}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={
                fundingProgramFormErrorsSignal.value.programClassification
              }
            >
              <option key="" value="">
                Select an Option
              </option>
              {Object.values(PROGRAM_CLASSIFICATIONS).map((classification) => (
                <option key={classification} value={classification}>
                  {classification}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.programClassification}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            hidden={referenceProgramKey !== PROGRAM_KEYS.fundInvestment}
            className="form-group"
          >
            <Form.Label className="font-weight-bold text-secondary">
              Fund Type
            </Form.Label>
            <Form.Select
              className="form-control"
              name="fundType"
              value={programInfo.fundType}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={fundingProgramFormErrorsSignal.value.fundType}
            >
              <option key="" value="">
                Select an Option
              </option>
              {Object.values(PROGRAM_FUND_TYPES).map((type) => (
                <option key={type} value={type}>
                  {enumCaseToTabCase(type)}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.fundType}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Administrator
            </Form.Label>
            <Form.Select
              className="form-control"
              name="administratorId"
              value={
                creatingProgram
                  ? programInfo.administratorId
                  : `${programInfo.administrator?.firstName} ${programInfo.administrator?.lastName} - ${programInfo.administrator?.email}`
              }
              onChange={(e) => handleInputChange(e)}
              onBlur={handleInputFocusOut}
              isInvalid={fundingProgramFormErrorsSignal.value.administratorId}
              disabled={!creatingProgram}
            >
              <option key="" value="">
                {creatingProgram
                  ? 'Select Administrator'
                  : `${programInfo.administrator?.firstName} ${programInfo.administrator?.lastName} - ${programInfo.administrator?.email}`}
              </option>
              {administrators.map((a) => (
                <option value={a.id} key={a.id}>
                  {a.firstName} {a.lastName} - {a.email}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.administratorId}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col>
          <Form.Label className="font-weight-bold text-secondary">
            Overview
          </Form.Label>
          <RichTextEditor
            onChange={(value, name) =>
              handleInputChange({ target: { name, value } })
            }
            value={programInfo.overview}
            name="overview"
            isInvalid={fundingProgramFormErrorsSignal.value.overview}
            onBlur={(name, value) =>
              handleInputFocusOut({ target: { name, value } })
            }
          />
          <small
            className="text-danger"
            hidden={!fundingProgramFormErrorsSignal.value.overview}
          >
            {fundingProgramFormErrorsSignal.value.overview}
          </small>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Eligibility
            </Form.Label>
            <RichTextEditor
              onChange={(value, name) =>
                handleInputChange({ target: { name, value } })
              }
              value={programInfo.eligibility}
              name="eligibility"
              isInvalid={fundingProgramFormErrorsSignal.value.eligibility}
              onBlur={(name, value) =>
                handleInputFocusOut({ target: { name, value } })
              }
            />
            <small
              className="text-danger"
              hidden={!fundingProgramFormErrorsSignal.value.eligibility}
            >
              {fundingProgramFormErrorsSignal.value.eligibility}
            </small>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col>
          <Form.Label className="font-weight-bold text-secondary">
            Ineligible Businesses and Purposes
          </Form.Label>
          <RichTextEditor
            onChange={(value, name) =>
              handleInputChange({ target: { name, value } })
            }
            value={programInfo.ineligibleBusinesses}
            name="ineligibleBusinesses"
            isInvalid={
              fundingProgramFormErrorsSignal.value.ineligibleBusinesses
            }
            onBlur={(name, value) =>
              handleInputFocusOut({ target: { name, value } })
            }
          />
          <small
            className="text-danger"
            hidden={!fundingProgramFormErrorsSignal.value.ineligibleBusinesses}
          >
            {fundingProgramFormErrorsSignal.value.ineligibleBusinesses}
          </small>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col>
          <Form.Label className="font-weight-bold text-secondary">
            Program Rules
          </Form.Label>
          <RichTextEditor
            onChange={(value, name) =>
              handleInputChange({ target: { name, value } })
            }
            value={programInfo.programRules}
            name="programRules"
            isInvalid={fundingProgramFormErrorsSignal.value.programRules}
            onBlur={(name, value) =>
              handleInputFocusOut({ target: { name, value } })
            }
          />
          <small
            className="text-danger"
            hidden={!fundingProgramFormErrorsSignal.value.programRules}
          >
            {fundingProgramFormErrorsSignal.value.programRules}
          </small>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Website Link? (Optional)
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              name="website"
              value={programInfo.website}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={fundingProgramFormErrorsSignal.value.website}
            />
            <Form.Control.Feedback type="invalid">{fundingProgramFormErrorsSignal.value.website}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            <b>Remaining funds in platform: </b>
            {selectedPlatform
              ? '$' +
                (
                  selectedPlatform?.stats.burned -
                  selectedPlatform?.stats.funded
                ).toLocaleString()
              : '-'}
          </p>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col>
        <Field.Currency
          onChange={(input) => handleInputChange({ target: input })}
          inputClassName="form-control"
          name="ceiling"
          label="Total Program Funding"
          labelClassName="font-weight-bold text-secondary"
          value={programInfo.ceiling}
          onBlur={handleInputFocusOut}
          isInvalid={fundingProgramFormErrorsSignal.value.ceiling}
          feedbackMessage={fundingProgramFormErrorsSignal.value.ceiling}
        />
        </Col>
        <Col>
          <Field.Currency
            onChange={(input) => handleInputChange({ target: input })}
            inputClassName="form-control"
            name="fundsAlreadyAllocated"
            label="Funds Already Allocated"
            labelClassName="font-weight-bold text-secondary"
            value={programInfo.fundsAlreadyAllocated}
            onBlur={handleInputFocusOut}
            isInvalid={fundingProgramFormErrorsSignal.value.fundsAlreadyAllocated}
            feedbackMessage={fundingProgramFormErrorsSignal.value.fundsAlreadyAllocated}
          />
        </Col>
      </Row>
      {referenceProgramKey === PROGRAM_KEYS.loanGuaranty && (
        <AdditionalLoanGuarantyInputs
          programInfo={programInfo}
          handleInputChange={handleInputChange}
          handleInputFocusOut={handleInputFocusOut}
          fundingProgramFormErrorsSignal={fundingProgramFormErrorsSignal}
        />
      )}
      {referenceProgramKey === PROGRAM_KEYS.collateralSupport && (
        <AdditionalCollateralInputs
          programInfo={programInfo}
          handleInputChange={handleInputChange}
          handleInputFocusOut={handleInputFocusOut}
          fundingProgramFormErrorsSignal={fundingProgramFormErrorsSignal}
        />
      )}
      <Row className="mb-16">
        <Col>
          <Form.Group
            hidden={
              ![
                PROGRAM_KEYS.collateralSupport,
                PROGRAM_KEYS.loanGuaranty,
              ].includes(referenceProgramKey)
            }
            className="form-group"
          >
            <Form.Label className="font-weight-bold text-secondary">
              Rebalancing frequency
            </Form.Label>
            <Form.Select
              className="form-control"
              name="rebalancingFrequency"
              value={programInfo.rebalancingFrequency}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={
                fundingProgramFormErrorsSignal.value.rebalancingFrequency
              }
            >
              <option key="" value="">
                Select an Option
              </option>
              {Object.values(REBALANCING_FREQUENCY_OPTIONS).map((frequency) => (
                <option key={frequency} value={frequency}>
                  {enumCaseToTabCase(frequency)}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.rebalancingFrequency}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const AdditionalLoanGuarantyInputs = ({
  programInfo,
  handleInputChange,
  fundingProgramFormErrorsSignal,
  handleInputFocusOut,
}) => (
  <>
    <Row className="mb-16">
      <Col>
        <Form.Group className="form-group mb-0">
          <Form.Label className="font-weight-bold text-secondary">
            Minimum Guaranty Percentage
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              max={99}
              min={0}
              step="1.0"
              className="form-control"
              name="minGuarantyPercentage"
              value={programInfo.minGuarantyPercentage}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={
                fundingProgramFormErrorsSignal.value.minGuarantyPercentage
              }
            />
            <InputGroup.Text>%</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.minGuarantyPercentage}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="form-group mb-0">
          <Form.Label className="font-weight-bold text-secondary">
            Maximum Guaranty Percentage
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              max={99}
              min={0}
              step="1.0"
              className="form-control"
              name="maxGuarantyPercentage"
              value={programInfo.maxGuarantyPercentage}
              onChange={handleInputChange}
              onBlur={handleInputFocusOut}
              isInvalid={
                fundingProgramFormErrorsSignal.value.maxGuarantyPercentage
              }
            />
            <InputGroup.Text>%</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {fundingProgramFormErrorsSignal.value.maxGuarantyPercentage}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  </>
);

const AdditionalCollateralInputs = ({
  programInfo,
  handleInputChange,
  fundingProgramFormErrorsSignal,
  handleInputFocusOut,
}) => (
  <Row className="mb-16">
    <Col>
      <Form.Group className="form-group">
        <Form.Label className="font-weight-bold text-secondary">
          Minimum Collateral Support
        </Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            max={99}
            min={1}
            step="1.0"
            className="form-control"
            name="minCollateralSupportPercentage"
            value={programInfo.minCollateralSupportPercentage}
            onChange={handleInputChange}
            onBlur={handleInputFocusOut}
            isInvalid={
              fundingProgramFormErrorsSignal.value
                .minCollateralSupportPercentage
            }
          />
          <InputGroup.Text>%</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {
              fundingProgramFormErrorsSignal.value
                .minCollateralSupportPercentage
            }
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
    <Col>
      <Form.Group className="form-group mb-8">
        <Form.Label className="font-weight-bold text-secondary">
          Maximum Collateral Support
        </Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            max={99}
            min={1}
            step="1.0"
            className="form-control"
            name="maxCollateralSupportPercentage"
            value={programInfo.maxCollateralSupportPercentage}
            onChange={handleInputChange}
            onBlur={handleInputFocusOut}
            isInvalid={
              fundingProgramFormErrorsSignal.value
                .maxCollateralSupportPercentage
            }
          />
          <InputGroup.Text>%</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {
              fundingProgramFormErrorsSignal.value
                .maxCollateralSupportPercentage
            }
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  </Row>
);

export default BasicProgramInfo;
