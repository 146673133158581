import alertSignal from 'signals/Alert.signal';
import { edoUsersSignal } from 'signals/EdoUsers.signal';
import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import userAccountSignal from 'signals/UserAccount.signal';
import history from '../../../../history';

export const handleEdoSettingsUserDetailsChange = (e) => {
  const { form } = edoUsersSignal.value;
  return edoUsersSignal.update({
    form: {
      ...form,
      [e.target.name]: e.target.value,
    },
  });
};

export const setEdoSettingsUserDetailUser = (selectedUser) => {
  const form = {
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    email: selectedUser.email,
    phoneNumber: selectedUser.phoneNumber,
    role: selectedUser.role,
    edoUserProgramAccesses: selectedUser.edoUserProgramAccesses.reduce(
      (acc, eupa) => ({
        ...acc,
        [eupa.fundingProgramId]: eupa.role,
      }),
      {}
    ),
  };
  return edoUsersSignal.update({ selectedUser, form });
};

export const clearEdoSettingsUserDetailUser = () =>
  edoUsersSignal.update({ selectedUser: null });

export const fetchAndSetUsersData = async (isHideLoader) => {
  loaderSignal.update({ isContentLoading: !isHideLoader });
  try {
    const [users, fundingPrograms] = await Promise.all([
      api.get({
        path: '/users',
        options: {
          where: xFormAppliedFiltersToWhereClause(),
          include: {
            account: true,
            edoUserProgramAccesses: true,
          },
          orderBy: xFormSortToOrderByClause(),
        },
      }),
      api.get({
        path: '/fundingPrograms',
        options: {
          where: {
            edoId: userAccountSignal.value.userData?.account.edoId,
          },
          include: {
            referenceProgram: true,
          },
        },
      }),
    ]);
    return edoUsersSignal.update({ users, fundingPrograms });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleEdoSettingsUserDetailsUpdateUser = async () => {
  loaderSignal.update({ isContentLoading: true, message: 'Updating user details...' });
  const { firstName, lastName, phoneNumber, role } = edoUsersSignal.value.form;

  const { selectedUser } = edoUsersSignal.value;

  try {
    await api.patch({
      path: '/users',
      body: {
        where: { id: selectedUser.id },
        data: {
          firstName,
          lastName,
          phoneNumber,
          role,
        },
      },
    });
    await fetchAndSetUsersData();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated user',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const deleteUser = async (user) => {
  loaderSignal.update({ isContentLoading: true });
  try {
    await api.delete({ path: '/users', body: { user } });
    await fetchAndSetUsersData();
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleProgramAccessUpdate = async (e, payload) => {
  try {
    loaderSignal.update({ isContentLoading: true, message: 'Updating program access...' });

    const { programAccess, fundingProgram, role } = payload;
    const { form } = edoUsersSignal.value;
    const { edoUserProgramAccesses } = form;
    edoUserProgramAccesses[fundingProgram.id] = role;
    edoUsersSignal.update({ form: { ...form, edoUserProgramAccesses } });
    const userId = edoUsersSignal.value.selectedUser.id;
    const fundingProgramId = fundingProgram.id;
    const path = '/edo/user/program/access';
    if (role !== 'ADMIN' && role !== 'STAFF' && role !== 'VIEWER') {
      await api.delete({ path, body: { where: { id: programAccess.id } } });
    } else {
      await api.put({
        path,
        body: {
          where: {
            userId_fundingProgramId: { userId, fundingProgramId },
          },
          create: {
            edo: {
              connect: {
                id: userAccountSignal.value.userData.account?.edo?.id,
              },
            },
            user: {
              connect: {
                id: userId,
              },
            },
            fundingProgram: {
              connect: {
                id: fundingProgramId,
              },
            },
            role,
          },
          update: {
            role,
          },
        },
      });
    }
    await fetchAndSetUsersData();
    setEdoSettingsUserDetailUser(
      edoUsersSignal.value.users.find(
        (u) => edoUsersSignal.value.selectedUser.id === u.id
      )
    );
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated program access',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'alert',
      error,
      message: 'Unable to update program access',
    });
  } finally {
    loaderSignal.reset();
  }
};

const xFormSortToOrderByClause = () => {
  const sort = edoUsersSignal.value.sort;
  if (!sort) {
    return undefined;
  }

  if (sort.key === 'name') {
    return [
      {
        lastName: sort.dir,
      },
    ];
  } else {
    return [{ [sort.key]: sort.dir }];
  }
};

export const handleUserSortChange = (sort) =>
  edoUsersSignal.update({
    sort,
  });

export const handleEdoManageUsersAppliedFiltersChange = (appliedFilters) =>
  edoUsersSignal.update({
    appliedFilters,
  });

export const handleEdoManageUsersFilterChange = (key, value) => {
  const dropdownFilters = edoUsersSignal.value.dropdownFilters;
  return edoUsersSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    accountId: userAccountSignal.value.userData?.accountId,
  };

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.firstName) {
    where.firstName = { contains: params.firstName, mode: 'insensitive' };
  }

  if (params.lastName) {
    where.lastName = { contains: params.lastName, mode: 'insensitive' };
  }

  if (params.email) {
    where.email = { contains: params.email, mode: 'insensitive' };
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.role) {
    where.role = params.role;
  }

  if (params.status) {
    where.status = params.status;
  }

  return where;
};

export const resetEdoManageUsersFilterInputs = () =>
  edoUsersSignal.update({ dropdownFilters: {} });
