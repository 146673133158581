import { useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import ChooseFiltersPrevApplications from './ChooseFiltersPrevApplications';
import MigratedApplicationDetailModal from './MigratedApplicationDetailModal';
import MigratedApplicationRow from './MigratedApplicationRow';
import $appSettings from 'signals/AppSettings.signal';

export const PreviousMigratedApplications = ({ portalType }) => {
  const { prevMigrations } = AdminMigrateSignal.value[portalType];
  return (
    <>
      <Row>
        <Col>
          <ChooseFiltersPrevApplications portalType={portalType} />
        </Col>
      </Row>
      <Row>
        <Col>
          {
            prevMigrations.length !== 0 && <PrevApps portalType={portalType} />
          }
        </Col>
      </Row>
    </>
  );
};

export default PreviousMigratedApplications;

const PrevApps = ({ portalType }) => {
  const { prevMigrations } = AdminMigrateSignal.value[portalType];
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const [showModal, setShowModal] = useState(false);

  return (
    <Card style={{ height: 500 }} className="px-16 px-md-32 py-16 overflow-auto rounded-0">
      <Row>
        <Col className="px-8">
          <Table className="mb-8">
            <thead>
              <tr className="font-weight-bold">
                <th> </th>
                <th>Program</th>
                {portalType === PORTAL_TYPES.state && <th>State</th>}
                <th>Year</th>
                <th>EIN</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {prevMigrations.map(data => (
                <MigratedApplicationRow
                  key={data.id}
                  portalType={portalType}
                  data={data}
                  onToggleModal={() => setShowModal(true)}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <MigratedApplicationDetailModal show={showModal} onToggleModal={() => setShowModal(prev => !prev)} />
    </Card>
  );
};
