import { Modal } from 'react-bootstrap';
import loaderSignal from 'signals/Loader.signal';
import Loader from '../Loader';
import { useEffect, useRef } from 'react';

const ModalWrapper = ({
  children,
  show,
  onExit,
  onExited,
  onHide,
  size,
  centered,
  backdropClassName,
  className = '',
}) => {
  const modalContainerRef = useRef(null);

  useEffect(() => {
    const modalContainer = modalContainerRef.current;

    if (loaderSignal.value.isContentLoading && modalContainer) {
      modalContainer.scrollIntoView({ block: 'start' });
    }
  }, [loaderSignal.value.isContentLoading]);

  return (
    <Modal
      show={show}
      onExit={onExit}
      onExited={onExited}
      onHide={loaderSignal.value.isContentLoading ? null : onHide}
      size={size}
      centered={centered}
      className={className}
      backdropClassName={backdropClassName}
      animation={false}
    >
      {loaderSignal.value.isContentLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader message={loaderSignal.value.message || 'Loading...'} />
        </div>
      )}
      <div
        ref={modalContainerRef}
        style={{
          opacity: loaderSignal.value.isContentLoading ? 0 : 1,
          pointerEvents: loaderSignal.value.isContentLoading ? 'none' : null,
        }}
      >
        {children}
      </div>
    </Modal>
  );
};

export default ModalWrapper;
