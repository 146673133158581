import Signal from 'libs/signals';

const addUserModalSignal = Signal({
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  modalVisible: false,
  activeTab: 'addUserForm',
  invitationProgramAccesses: [],
  emailError: false,
});

export default addUserModalSignal;
