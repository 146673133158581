import { Col, Container, Row } from 'react-bootstrap';
import BusinessSearchForm from './components/BusinessSearchForm';
import BusinessWrapper from '../components/BusinessWrapper';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';

const BusinessSearch = () => (
  <BusinessWrapper>
    <BreadCrumbWrapper labels={['Search Opportunities']}>
      <Container fluid className="py-16">
        <Row>
          <Col>
            <BusinessSearchForm />
          </Col>
        </Row>
      </Container>
    </BreadCrumbWrapper>
  </BusinessWrapper>
);

export default BusinessSearch;
