import { Link, useLocation } from "react-router-dom";

const NotFound = () => {
  const location = useLocation();
  const { pathname } = location;
  const portal = pathname.split('/')[1];

  return (
    <div className="border d-flex flex-column justify-content-center align-items-center p-64">
      <h4 className="text-center">404 Page Not Found</h4>
      <Link to={`/${portal === "business" ? "business/dashboard" : portal}`}>Take me home</Link>
    </div>
  );
};

export default NotFound;
