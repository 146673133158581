import { getConfig } from "../config/config";
import tokenSignal from "../signals/Token.signal";
import sleep from "../libs/sleep";
import alertSignal from "signals/Alert.signal";

const REACT_APP_API_ENDPOINT = getConfig('REACT_APP_API_ENDPOINT');

function base64DecToArr(sBase64, nBlocksSize) {
  var sB64Enc = sBase64.replace(/[^A-Za-z0-9+/]/g, ''),
    nInLen = sB64Enc.length,
    nOutLen = nBlocksSize ? Math.ceil((nInLen * 3 + 1 >> 2) / nBlocksSize) * nBlocksSize : nInLen * 3 + 1 >> 2,
    aBytes = new Uint8Array(nOutLen);

  for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        aBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
      }
      nUint24 = 0;
    }
  }
  return aBytes;
}

function b64ToUint6(nChar) {
  return nChar > 64 && nChar < 91 ? nChar - 65
    : nChar > 96 && nChar < 123 ? nChar - 71
      : nChar > 47 && nChar < 58 ? nChar + 4
        : nChar === 43 ? 62 : nChar === 47 ? 63 : 0;
}

function xFormBody(body) {
  if (body instanceof FormData) {
    return body;
  }

  if (typeof body === 'object') {
    return JSON.stringify(body);
  }

  return body;
}

const apiFetch = async ({
  path,
  body = undefined,
  method = 'GET',
  isUnAuthenticatedReq = false,
  headers = {},
  isFileDownload = false,
}, retries = 0) => {
  if (!tokenSignal.value.accessToken && !isUnAuthenticatedReq) {
    const errorObj = {
      message: 'Auth token not set',
      error: {
        path,
        body,
        method,
      },
    };
    console.error(errorObj);
    return [];
  }

  const methodToUse = method || (body ? 'POST' : 'GET');
  let res;

  try {
    res = await fetch(REACT_APP_API_ENDPOINT + path, {
      'ngrok-skip-browser-warning': '69420',
      method: methodToUse,
      body: xFormBody(body),
      headers: {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        appId: getConfig('APP_CONFIG').appId,
        Authorization:
          localStorage.getItem('basicAuthOverride') ||
          `Bearer ${tokenSignal.value.accessToken}`,
        ...(body instanceof FormData
          ? {
          }
          : {
            'Content-Type': 'application/json',
          }),
        ...headers,
      },
    });
  } catch (error) {
    if (retries < 4) {
      await sleep((retries + 1) * (retries + 1) * 800);

      return apiFetch({
        path,
        body,
        method,
        isUnAuthenticatedReq,
        headers,
        isFileDownload,
      }, retries + 1);
    } else {
      throw error
    }
  }

  if (isFileDownload) {
    const type = res.headers.get('content-type');
    const data = await res.text();
    const blob = new Blob([base64DecToArr(data)], { type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.xlsx');
    document.body.appendChild(link);
    link.click();
    return url;
  }

  let responseBody;
  try {
    responseBody = await res.json();
  } catch (error) {
    await sleep((retries + 1) * (retries + 1) * 800);

    return apiFetch({
      path,
      body,
      method,
      isUnAuthenticatedReq,
      headers,
      isFileDownload,
    }, retries + 1);
  }

  if (!res || res.status !== 200) {
    if (res.status >= 500 && retries < 4) {
      await sleep((retries + 1) * (retries + 1) * 800);

      return apiFetch({
        path,
        body,
        method,
        isUnAuthenticatedReq,
        headers,
        isFileDownload,
      }, retries + 1);
    }

    const error = {
      message:
        responseBody.data?.message ||
        responseBody.data?.error?.message ||
        responseBody.data?.body?.message ||
        responseBody.error?.message,
      status: res.status,
    };
    throw error;
  }

  return responseBody;
};

const get = async ({ path, options, headers = {}, isFileDownload = false }, retries = 0) => {
  const queryString = new URLSearchParams({
    options: JSON.stringify(options),
  }).toString();
  const pathString = options ? `${path}?${queryString}` : path;

  const timer = setTimeout(() => {
    alertSignal.update({
      type: 'notification',
      message: 'We are experiencing longer load times than usual...',
      variant: 'info',
      id: 'slow-load-time',
    });
  }, 2000);

  const { data } = await apiFetch({
    path: pathString,
    headers,
    isFileDownload,
  }, retries);

  clearTimeout(timer);

  return data;
};

const post = async ({ path, body, isUnAuthenticatedReq = false }, retries = 0) => {
  const { data } = await apiFetch({
    path,
    body,
    method: 'POST',
    isUnAuthenticatedReq,
  }, retries);
  return data;
};

const patch = async ({ path, body }, retries = 0) => {
  const { data } = await apiFetch({ path, body, method: 'PATCH' }, retries);
  return data;
};

const put = async ({ path, body }, retries = 0) => {
  const { data } = await apiFetch({ path, body, method: 'PUT' }, retries);
  return data;
};

const deleteFunc = async ({ path, body }, retries = 0) => {
  const { data } = await apiFetch({ path, body, method: 'DELETE' }, retries);
  return data;
};

export default {
  apiFetch,
  get,
  post,
  patch,
  put,
  delete: deleteFunc,
};
