import { Card, Col, Container, Row } from 'react-bootstrap';
import ChooseProgram from './components/ChooseProgram';
import {
  barGraphOptions,
  generalDoughnutChartOptions,
  generalDoughnutChartWhiteTxtOptions,
} from 'components/global/ChartJS/chartPresetsAndStyles';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Doughnut } from 'react-chartjs-2';
import { useCallback, useEffect } from 'react';
import vcDashboardSignal from 'signals/VCDashboard.signal';
import {
  fetchAndSetDashboardData,
  getBusinessDemographicsMetrics,
  getFundMetrics,
  getProgramRequestMetrics,
  getVcPortfolioCompanyMetrics,
} from './VCDashboard.helpers';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const VCDashboard = () => {
  const location = useLocation();
  const {
    programRequestMetrics,
    fundMetrics,
    vcPortfolioCompanyMetrics,
    selectedFundingProgramId,
    programRequests,
    funds,
    portfolioCompanies,
    businessDemographicsMetrics
  } = vcDashboardSignal.value;

  const init = useCallback(async () => {
    await fetchAndSetDashboardData();
  });

  useEffect(() => {
    init();
  }, [selectedFundingProgramId]);

  useEffect(() => {
    getProgramRequestMetrics();
    getFundMetrics();
    getVcPortfolioCompanyMetrics();
    getBusinessDemographicsMetrics();
  }, [programRequests, funds, portfolioCompanies]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "VC Dashboard" });
  }, []);

  return (
    <Container className="p-16">
      <ChooseProgram />
      <Row className="px-8 py-16 mb-32 rounded">
        <Col lg={6} className="text-center text-light">
          <Card className="p-16 rounded-0" style={{ backgroundColor: '#294A6A' }}>
            <h5>Program Requests</h5>
            <Doughnut
              style={{ maxHeight: 300 }}
              data={programRequestMetrics}
              options={generalDoughnutChartWhiteTxtOptions}
              plugins={[ChartDataLabels]}
            />
          </Card>
        </Col>
        <Col lg={6} className="text-center">
          <Card className="p-16 rounded-0">
            <h5>Funds</h5>
            <Doughnut
              style={{ maxHeight: 300 }}
              data={fundMetrics}
              options={generalDoughnutChartOptions}
              plugins={[ChartDataLabels]}
            />
          </Card>
        </Col>
      </Row>
      <Row className="mb-32">
        <Col lg={6} className="text-center">
          <Card className="p-16 rounded-0">
            <h5>Portfolio Companies</h5>
            <Doughnut
              style={{ maxHeight: 300 }}
              data={vcPortfolioCompanyMetrics}
              options={generalDoughnutChartOptions}
              plugins={[ChartDataLabels]}
            />
          </Card>
        </Col>
        <Col lg={6} className="text-center">
          <Card className="p-16 h-100 rounded-0">
            <h5>Business Demographics</h5>
            <Bar
              options={barGraphOptions(businessDemographicsMetrics.labels)}
              data={businessDemographicsMetrics}
              plugins={[ChartDataLabels]}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VCDashboard;
