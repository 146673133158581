import { Modal } from 'react-bootstrap';
import applicationDetailSignal from './ApplicationDetail.signal';
import { hideApplicationDetailsModal } from './ApplicationDetail.helpers';
import CloseModalButton from '../Constant/CloseModalButton';
import ApplicationDetail from './ApplicationDetail';
import ModalWrapper from '../ModalWrapper/ModalWrapper';

const ApplicationDetailModal = () => (
  <div>
    <ModalWrapper
      show={applicationDetailSignal.value.visible}
      onHide={hideApplicationDetailsModal}
      size="xl"
      centered
      backdropClassName={null}
      className="xxl-modal"
      onExit={null}
      onExited={null}
    >
      <Modal.Header className="pb-0 border-0 justify-content-end">
        <CloseModalButton onCloseModal={hideApplicationDetailsModal} />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <ApplicationDetail isModal />
      </Modal.Body>
    </ModalWrapper>
  </div>
);

export default ApplicationDetailModal;
