import ModalWrapper from '../../../../global/ModalWrapper/ModalWrapper';
import { Button, Container, Modal } from 'react-bootstrap';
import businessSearchResultsSignal from 'signals/BusinessSearchResults.signal';
import {
  handleRemoveSavedOpportunityClickFromModalView,
  handleSaveOpportunityClickFromModalView,
  hideModal,
} from '../BusinessSearchResults.helpers';
import CloseModalButton from '../../../../global/Constant/CloseModalButton';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';
import userAccountSignal from 'signals/UserAccount.signal';

const BusinessSearchResultModal = () => {
  const { modalOpen } = businessSearchResultsSignal.value;
  const { selectedOrganization } = organizationDetailsSignal.value;
  const isFavorite = Object.keys(selectedOrganization).length
    ? selectedOrganization?.favoriteBusinesses?.find(
        (fav) =>
          fav.businessId ===
          userAccountSignal.value.userData.account.business.id
      )
    : false;

  return (
    <ModalWrapper
      size="lg"
      className="border border-dark rounded"
      centered
      show={modalOpen}
      onHide={hideModal}
    >
      <Modal.Header className="bg-light" closeLabel="Close">
        <h4>{selectedOrganization?.name}</h4>
        <CloseModalButton onCloseModal={hideModal} />
      </Modal.Header>
      <Modal.Body className="bg-light rounded">
        <Container>
          <OrganizationDetails showLinkToPrograms={false} hideOrganizationName />
          <hr />
          <div className="text-center">
            <Button
              hidden={isFavorite}
              style={{ minWidth: '280px' }}
              className="rounded"
              size="lg"
              onClick={() => handleSaveOpportunityClickFromModalView()}
            >
              Save Opportunity
            </Button>
            <Button
              hidden={!isFavorite}
              style={{ minWidth: '280px' }}
              className="rounded"
              variant="secondary"
              size="lg"
              onClick={() =>
                handleRemoveSavedOpportunityClickFromModalView(isFavorite.id)
              }
            >
              Unsave Opportunity
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default BusinessSearchResultModal;
