import Signal from 'libs/signals';

type FundApplicationSignal = {
  fundApplication: null;
  selectedFundDocument: null
  modalVisible: null;
  selectedBusinessDocuments: Record<string, unknown>[],
};

const fundApplicationSignal = Signal<FundApplicationSignal>({
  fundApplication: null,
  selectedFundDocument: null,
  selectedBusinessDocuments: [],
  modalVisible: null,
});

export default fundApplicationSignal;
