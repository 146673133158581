import loaderSignal from 'signals/Loader.signal';
import searchBusinessResultsSignal from './searchBusinessResults.signal';
import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import history from '../../../history';
import userAccountSignal from '../../../signals/UserAccount.signal';
import { saveSearch } from '../SearchBusinessSaveModal/saveBusinessSearchModal.helpers';
import $appSettings from 'signals/AppSettings.signal';

const xFormAppliedFiltersToWhereClause = () => {
  const where = {};
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.id) {
    if (params.id.includes(',')) {
      where.id = { in: params.id.split(',').map((id) => Number(id.trim())) };
    } else {
      where.id = { in: [Number(params.id)] };
    }
  }

  if (params.name) {
    where.id = { in: [Number(params.name)] };
  }

  if (params.industry) {
    where.assistance = {
      path: ['industrySelection'],
      array_contains: params.industry,
    };
  }

  if (params.location) {
    where.address = {
      path: ['postalCode'],
      equals: params.location,
    };
  }

  if (params.favorite) {
    if (params.favorite === 'yes') {
      if (portalType === PORTAL_TYPES.lender) {
        where.favoritedByLenders = {
          some: {
            lenderId: userAccountSignal.value.userData.account.lender?.id,
          },
        };
      } else if (portalType === PORTAL_TYPES.vc) {
        where.favoritedByVcs = {
          some: {
            ventureCapitalFirmId:
              userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
          },
        };
      }
    } else {
      where.favoritedByLenders = { none: {} };
      where.favoritedByVcs = { none: {} };
    }
  }

  if (params.fundingRange) {
    const fundingRangeSplit = params.fundingRange
      .split('-')
      .map((r) => r.trim().replaceAll('$', ''));

    let min, max;

    if (fundingRangeSplit.length === 1) {
      min = Number(fundingRangeSplit[0]);
      max = 10000000;
    } else {
      min = Number(fundingRangeSplit[0]);
      max = Number(fundingRangeSplit[1]);
    }

    where.OR = [
      {
        assistance: {
          path: ['seekingMin'],
          gte: min,
          lte: max,
        },
      },
      {
        assistance: {
          path: ['seekingMax'],
          gte: min,
          lte: max,
        },
      },
    ];
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  return [{ [sort.split('-')[0]]: sort.split('-')[1] }];
};

export const fetchAndSetSearchResults = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const businesses = await api.get({
      path: '/businesses',
      options: {
        where: xFormAppliedFiltersToWhereClause(),
        orderBy: xFormSortToOrderByClause(),
        include: {
          favoritedByLenders: true,
          favoritedByVcs: true,
        },
      },
    });
    const selectedBusiness = searchBusinessResultsSignal.value.selectedBusiness;

    return searchBusinessResultsSignal.update({
      businesses,
      selectedBusiness: selectedBusiness
        ? businesses.find((b) => b.id === selectedBusiness.id)
        : null,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const setDropdownFilter = ({ name, value }) =>
  searchBusinessResultsSignal.update({
    dropdownFilters: {
      ...searchBusinessResultsSignal.value.dropdownFilters,
      [name]: value,
    },
  });

export function hideModal() {
  return searchBusinessResultsSignal.update({
    selectedBusiness: null,
    modalOpen: false,
  });
}

export function toggleModal(selectedBusiness) {
  return searchBusinessResultsSignal.update({
    selectedBusiness,
    modalOpen: true,
  });
}

export const markBusinessAsFavorite = async (business, callback = null) => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Saving business as favorite...',
  });
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const data = {
    businessId: business.id,
  };

  if (portalType === PORTAL_TYPES.lender) {
    data.lenderId = userAccountSignal.value.userData.account.lender?.id;
  } else if (portalType === PORTAL_TYPES.vc) {
    data.ventureCapitalFirmId =
      userAccountSignal.value.userData.account.ventureCapitalFirm?.id;
  }

  try {
    await api.post({
      path:
        portalType === PORTAL_TYPES.lender
          ? '/lenderFavorites'
          : '/ventureCapitalFirmFavorites',
      body: {
        data,
      },
    });

    if (callback) {
      await callback();
    } else {
      await fetchAndSetSearchResults();
    }
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully favorited business.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const deleteBusinessFavorite = async (
  businessFavorite,
  callback = null
) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Removing business from favorite...',
  });

  const portalType = userAccountSignal.value.userData.account.portalType;
  try {
    await api.delete({
      path:
        portalType === PORTAL_TYPES.lender
          ? '/lenderFavorites'
          : '/ventureCapitalFirmFavorites',
      body: {
        where: {
          id: businessFavorite.id,
        },
      },
    });

    if (callback) {
      await callback();
    } else {
      await fetchAndSetSearchResults();
    }
    return alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully unfavorited business.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const resetBusinessSearchFilters = () =>
  searchBusinessResultsSignal.update({ dropdownFilters: {} });

export const saveSearchButtonIsDisabled = () => {
  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  if (!params.industry && !params.location && !params.fundingRange) {
    return true;
  }

  return false;
};

export const getRelevantSearchParams = () => {
  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  const relevantSearchParamLabels = ['industry', 'location', 'fundingRange'];

  const currentSearchTerms = [];

  relevantSearchParamLabels.forEach((label) => {
    if (!!params[label]) {
      currentSearchTerms.push({ label, value: params[label] });
    }
  });

  return currentSearchTerms;
};

export const handleSaveSearchClick = async () => {
  await saveSearch();
};
