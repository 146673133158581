import { Button, Card, Dropdown } from "react-bootstrap";
import { businessMyLoansSignal } from "signals/BusinessMyLoans.signal";
import SimpleTable from "../../../../global/SimpleTable";
import { getStatusStyles } from "../../../../../libs/functions/global.functions";
import capitalizeFirst from "../../../../../utils/capitalizeFirst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { watchlistIcon } from "../../../../global/Constant/Icons";
import dateOptions from "../../../../global/Constant/dateOptions";
import { FUND_STATUS as APPLICATION_STATUS } from "../../../../global/Constant/constants";
import { handleViewTechnicalAssistanceClick } from "../../../../global/TechnicalAssistance/technicalAssistance.helpers";
import SimpleTableFilterDropdown from "../../../../global/SimpleTableFilterDropdown";
import { titleCaseString } from "../../../../../libs/string";
import { useHistory } from "react-router";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'createdDate',
    title: 'Date Submitted',
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'lenderName',
    title: 'Lender Name',
  },
  {
    key: 'contactName',
    title: 'Contact Name',
  },
  {
    key: 'askedAmount',
    title: 'Asked Amount',
    options: { isCurrency: true },
  },
  {
    key: 'approvedAmount',
    title: 'Approved Amount',
    options: { isCurrency: true },
  },
  {
    key: 'action',
    title: 'Action',
  },
];

export const MyLoansTable = () => {
  const applications = businessMyLoansSignal.value.applications;
  const history = useHistory();

  return (
    <Card className="p-32 border-border-gray bg-white shadow-sm">
      <div className="mb-16">
      <SimpleTableFilterDropdown
        filters={[
          {
            type: 'select',
            label: 'Status',
            key: 'status',
            options: Object.entries(APPLICATION_STATUS).map(([, value]) => ({
              label: titleCaseString(value),
              value,
            })),
          },
          {
            type: 'integer',
            label: 'ID',
            key: 'id',
          },
          {
            type: 'select',
            label: 'Program Name',
            key: 'programName',
            options: applications
              .map(a => a.fundingProgramMembership.fundingProgram)
              .reduce((acc, fundingProgram) => {
                const ids = acc.map(({id }) => id);
                if (ids.includes(fundingProgram.id)) {
                  return acc;
                }

                return acc.concat(fundingProgram);
              }, [])
              .map((fundingProgram) => ({
              label: fundingProgram.name,
              value: fundingProgram.id,
            }))
          },
        ]}
      />
      </div>
      <SimpleTable
        headers={HEADERS}
        rows={applications.map((application) => ({
          status: () => (
            <td className={`${getStatusStyles(application.status).class}`}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0">
                  {application.status === APPLICATION_STATUS.inProgress
                    ? 'Draft'
                    : capitalizeFirst(application.status.replace(/_/g, ' '))}
                </p>
                <FontAwesomeIcon
                  className={
                    application.watchables?.length
                      ? 'text-right ml-16'
                      : 'd-none'
                  }
                  icon={watchlistIcon}
                />
              </div>
            </td>
          ),
          createdDate: new Date(application.createdDate).toLocaleString(
            'en-US',
            dateOptions.date
          ),
          programName: application.fundingProgram.name,
          id: application.id,
          lenderName: application.fundingProgramMembership.lender.name,
          contactName: application.fundingProgramMembership.lender.contactName,
          askedAmount: Number(
                application.askedAmount
        ).toLocaleString('en-US', dateOptions.currencyUS),
          approvedAmount: application.approvedAmount
          ? Number(
          application.approvedAmount
          ).toLocaleString('en-US', dateOptions.currencyUS)
          : '---',
          action: ({ key }) => (
            <td key={key} className="">
              <div className="d-flex justify-content-end">
                {!!application.technicalAssistance ? (
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="outline-dark">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => history.push(`${history.location.pathname}/${application.id}`)}>
                        Details
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleViewTechnicalAssistanceClick(application.technicalAssistance)
                        }
                      >
                        View Technical Assistance
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    size="sm"
                    className="rounded"
                    variant="outline-dark"
                    onClick={() => history.push(`${history.location.pathname}/${application.id}`)}>
                    Detail
                  </Button>
                )}
              </div>
            </td>
          ),
        }))}
      />
    </Card>
  );
};

export default MyLoansTable;
