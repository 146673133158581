import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import transactionSignal from 'signals/Transaction.signal';
import TransactionMetrics from './TransactionMetrics';
import { DateTime } from 'luxon';

const AllTransactions = () => {
  const { monthToDate, yearToDate } =
    transactionSignal.value.allTransactionMetrics;

  return (
    <Container>
      <Row>
        <Col className="bg-white mx-2">
          <h5>Date</h5>
          <h6>{DateTime.now().toFormat('DD')}</h6>
        </Col>
        <Col className="text-nowrap bg-white mx-2">
          <h5 className="mb-16">Month-to-Date</h5>
          <Row className="mb-16">
            <Col>
              <h6>Loans Approved</h6>
            </Col>
            <Col>
              <h6 className="text-success">
                {monthToDate?.paymentVolume
                  ? `$${monthToDate?.paymentVolume?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}`
                  : 0}
              </h6>
            </Col>
          </Row>
          <Row className="mb-16">
            <Col>
              <h6>Loans Closed</h6>
            </Col>
            <Col>
              <h6 className="text-success">{monthToDate?.transactions || 0}</h6>
            </Col>
          </Row>
        </Col>
        <Col className="bg-white mx-2">
          <h5 className="mb-16">Year-to-Date</h5>
          <Row className="mb-16">
            <Col>
              <h6>Loans Approved</h6>
            </Col>
            <Col>
              <h6 className="text-success">
                {yearToDate?.paymentVolume
                  ? `$${yearToDate?.paymentVolume?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}`
                  : 0}
              </h6>
            </Col>
          </Row>
          <Row className="mb-16">
            <Col>
              <h6>Loans Closed</h6>
            </Col>
            <Col>
              <h6 className="text-success">{yearToDate?.transactions || 0}</h6>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="90days" className="my-24">
            <Tab eventKey="30days" title="30 Days">
              <TransactionMetrics dateRangeFilter="thirtyDaysAgo" />
            </Tab>
            <Tab eventKey="90days" title="90 Days">
              <TransactionMetrics dateRangeFilter="ninetyDaysAgo" />
            </Tab>
            <Tab eventKey="12months" title="12 Months">
              <TransactionMetrics dateRangeFilter="twelveMonthsAgo" />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default AllTransactions;
