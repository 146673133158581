import { Button, Container, Modal } from 'react-bootstrap';
import { addHttpsToUrl } from 'libs/string';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import CloseModalButton from '../../Constant/CloseModalButton';
import searchBusinessResultsSignal from '../searchBusinessResults.signal';
import {
  deleteBusinessFavorite,
  hideModal,
  markBusinessAsFavorite,
} from '../searchBusinessResults.helpers';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';

const SearchBusinessResultsModal = () => {
  const { modalOpen, selectedBusiness } = searchBusinessResultsSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  let favoriteRecord = null;
  if (
    userAccountSignal.value.userData.account.portalType ===
    PORTAL_TYPES.lender
  ) {
    favoriteRecord = selectedBusiness?.favoritedByLenders.find(
      (fb) =>
        fb.businessId === selectedBusiness.id &&
        fb.lenderId === userAccountSignal.value.userData.account.lender.id
    );
  } else if (
    userAccountSignal.value.userData.account.portalType ===
    PORTAL_TYPES.vc
  ) {
    favoriteRecord = selectedBusiness?.favoritedByVcs.find(
      (fb) =>
        fb.businessId === selectedBusiness.id &&
        fb.ventureCapitalFirmId ===
          userAccountSignal.value.userData.account.ventureCapitalFirm.id
    );
  }

  return (
    <ModalWrapper
      size="lg"
      className="border border-dark rounded"
      centered
      show={modalOpen}
      onHide={hideModal}
    >
      <Modal.Header className="bg-light" closeLabel="Close">
        <h4>{selectedBusiness?.name}</h4>
        <CloseModalButton onCloseModal={hideModal} />
      </Modal.Header>
      <Modal.Body className="bg-light rounded">
        <Container>
          <p>{selectedBusiness?.description}</p>
          {selectedBusiness?.contactName && (
            <p>
              <b>Contact Name:</b> {selectedBusiness?.contactName}
            </p>
          )}
          {selectedBusiness?.phoneNumber && (
            <p>
              <b>Phone Number:</b>{' '}
              <a href={`tel:${selectedBusiness?.phoneNumber}`}>
                {selectedBusiness?.phoneNumber}
              </a>
            </p>
          )}
          {selectedBusiness?.contactEmail && (
            <p>
              <b>Contact Email:</b>{' '}
              <a href={`mailto:${selectedBusiness?.contactEmail}`}>
                {selectedBusiness?.contactEmail}
              </a>
            </p>
          )}
          {selectedBusiness?.website && (
            <p>
              <b>Website:</b>{' '}
              <a href={addHttpsToUrl(selectedBusiness?.website)}>
                {selectedBusiness?.website}
              </a>
            </p>
          )}

          <hr />
          <div className="text-center">
            {favoriteRecord ? (
              <Button
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() => {
                  deleteBusinessFavorite(favoriteRecord);
                }}
              >
                Remove Favorite
              </Button>
            ) : (
              <Button
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() => {
                  markBusinessAsFavorite(selectedBusiness);
                }}
              >
                Mark as Favorite
              </Button>
            )}
          </div>
        </Container>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default SearchBusinessResultsModal;
