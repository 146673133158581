import lenderSubmitApplication from 'signals/SubmitApplication.signal';
import submitApplicationSignal, {
  applicationDraftSignal,
} from 'signals/SubmitApplication.signal';
import { Alert, Button, Card, Container, Row } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import SubmitApplicationsChooseProgram from './components/SubmitApplicationsChooseProgram';
import SubmitApplicationsOCR from './components/SubmitApplicationsOCR';
import SubmitApplicationsSuccessPage from './components/SubmitApplicationsSuccessPage';
import SubmitApplicationAdditionalDocuments from './components/SubmitApplicationAdditionalDocuments';
import {
  fetchAndSetApplicationDrafts,
  fetchAndSetFundingProgramsAndProgramTypes,
  handleClearLock,
  handleNavigateToOcrFromApplicationDetailView,
  handleSaveApplicationDraft,
  reset,
  saveApplicationDraft,
  setLockNotificationListener,
  tearDownLockNotificationListener,
} from './SubmitApplications.helpers';
import SubmitApplicationsNote from './components/SubmitApplicationsNote';
import SubmitApplicationsComplianceCheck from './components/SubmitApplicationsComplianceCheck';
import BackButton from '../BackButton';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { warningIcon } from '../Constant/Icons';
import applicationComplianceSignal from '../ApplicationCompliance/ApplicationCompliance.signal';
import ChooseApplicationDraftModal from './components/ChooseApplicationDraftModal';
import RemoveAccessModal from './components/RemoveAccessModal';

const SubmitApplications = () => {
  const { draftPendingRequest } = applicationDraftSignal.value;
  const { step } = submitApplicationSignal.value;
  const location = useLocation();
  const portal = location.pathname.split('/')[1];
  const init = useCallback(async () => {
    await fetchAndSetFundingProgramsAndProgramTypes();
    await fetchAndSetApplicationDrafts();
    await setLockNotificationListener();
  });

  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    applicationDraftSignal.update({ selectedDraft: {} });
    reset();

    // Grab and set application draft if coming from application details view
    if (draftPendingRequest.id) {
      handleNavigateToOcrFromApplicationDetailView();
    }

    return () => {
      if (applicationDraftSignal.value.selectedDraft?.id) {
        handleClearLock();
      }
      applicationDraftSignal.update({ draftPendingRequest: {} });
      applicationComplianceSignal.reset();
      tearDownLockNotificationListener();
    };
  }, []);

  useEffect(() => {
    let autosave = undefined;

    if (step > 1 && step < 6) {
      autosave = setInterval(() => {
        saveApplicationDraft();
      }, 15000);
    }

    return () => clearInterval(autosave);
  }, [step]);

  const showStep = () => {
    switch (step) {
      case 1:
        return <SubmitApplicationsChooseProgram />;
      case 2:
        return <SubmitApplicationsOCR />;
      case 3:
        return <SubmitApplicationAdditionalDocuments />;
      case 4:
        return (
          <SubmitApplicationsComplianceCheck
            referenceProgramId={
              lenderSubmitApplication.value.referenceProgramId
            }
          />
        );
      case 5:
        return <SubmitApplicationsNote />;
      case 6:
        return <SubmitApplicationsSuccessPage />;
      default:
        lenderSubmitApplication.reset();
        return null;
    }
  };
  return (
    <Container className="p-16 bg-light">
      <h2 className="text-center">Submit Application</h2>
      <Row className="px-16 justify-content-between mb-16">
        <BackButton
          to={`/${portal}/portfolio`}
          buttonText="Back to Program Requests"
        />
        <Alert
          className="p-8 mb-0"
          hidden={!applicationDraftSignal.value.selectedDraft?.id}
        >
          <p className="mb-0">
            <b>In Progress:</b>{' '}
            {submitApplicationSignal.value.fundingProgram?.name}
            {applicationDraftSignal.value.selectedDraft?.payload?.businessEin &&
              ` for business ${applicationDraftSignal.value.selectedDraft?.payload?.businessEin}`}
          </p>
        </Alert>
        <div>
          <Button
            variant="outline-primary mr-16"
            className="rounded"
            onClick={() => handleSaveApplicationDraft()}
          >
            Save Progress
          </Button>
          <Button
            className="rounded mr-16"
            variant="outline-primary"
            onClick={() =>
              applicationDraftSignal.update({ showDraftModal: true })
            }
          >
            Manage Drafts
          </Button>
          <Button
            variant="outline-secondary"
            className="rounded"
            onClick={reset}
          >
            Reset Progress
          </Button>
        </div>
      </Row>
      <Card
        className="px-24 py-32 rounded-0"
        hidden={
          portal !== 'lender' ||
          submitApplicationSignal.value.fundingPrograms.length
        }
      >
        <Alert variant="warning">
          <FontAwesomeIcon icon={warningIcon} size="lg" />
          <br />
          You must be approved for a program before adding an application. You
          can submit program requests and check the status of active requests{' '}
          <Link to="/lender/program-requests">here</Link>.
        </Alert>
      </Card>
      <Card
        className="px-24 py-32 rounded-0"
        hidden={
          portal === 'lender' &&
          !submitApplicationSignal.value.fundingPrograms.length
        }
      >
        {showStep()}
        <small className="text-right">
          * A draft will be saved when changing pages
        </small>
      </Card>
      <ChooseApplicationDraftModal />
      <RemoveAccessModal />
    </Container>
  );
};

export default SubmitApplications;
