import api from "api/api";
import alertSignal from "signals/Alert.signal";
import portfolioApplicationsSignal from "signals/EdoPortfolio.signal";
import edoPortfolioSignal from "signals/EdoPortfolio.signal";
import loaderSignal from "signals/Loader.signal";
import userAccountSignal from "signals/UserAccount.signal";
import history from "../../../../history";
import $appSettings from "signals/AppSettings.signal";

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    isActive: true,
    fundingProgram: {
      is: {
        edoId: userAccountSignal.value.userData.account.edo.id,
      },
    },
  };

  let params = Object.fromEntries(new URLSearchParams(history.location.search));

  params = { ...params, ...edoPortfolioSignal.value.appliedFilters };

  if (params.programName) {
    where.fundingProgram.is.id = Number(params.programName);
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.status) {
    where.status = params.status;
  }

  if (params.sediBusiness) {
    where.sediBusiness = params.sediBusiness === 'Yes';
  }

  if (params.watchables) {
    where.watchables = {
      some: {},
    };
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const sort = Object.fromEntries(
    new URLSearchParams(history.location.search)
  ).sort;

  if (!sort) {
    return undefined;
  }

  const key = sort.split('-')[0];
  const dir = sort.split('-')[1];

  if (key === 'programName') {
    return [
      {
        fundingProgramMembership: {
          fundingProgram: {
            name: dir,
          },
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

export const setAndFetchEdoApplications = async (page) => {
  const { recordsPerPage } = edoPortfolioSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    const bundle = await api.get({
      path: '/tally/applications',
    });

    edoPortfolioSignal.update({ bundle });

    const [data] = await Promise.all([
      api.get({
        path: '/applications',
        options: {
          getCount: true,
          skip: page ? (page - 1) * recordsPerPage : 0,
          take: recordsPerPage,
          orderBy: xFormSortToOrderByClause(),
          where: xFormAppliedFiltersToWhereClause(),
          include: {
            watchables: true,
            business: {
              include: {
                account: true,
              },
            },
            fundingProgram: {
              include: {
                stats: true,
                approvalChainTemplates: {
                  include: {
                    steps: {
                      include: {
                        primaryApprover: true,
                        alternateApprovers: {
                          include: {
                            user: true,
                          },
                        },
                      },
                    },
                  },
                },
                supportingDocuments: true,
                referenceProgram: {
                  include: {
                    programType: true,
                  },
                },
                platform: true,
                bankAccountProgramAccesses: {
                  include: {
                    bankAccount: true,
                  },
                },
              },
            },
            fundingProgramMembership: {
              include: {
                lender: true,
                ventureCapitalFirm: true,
                fundingProgram: {
                  include: {
                    stats: true,
                    approvalChainTemplates: {
                      include: {
                        steps: {
                          include: {
                            primaryApprover: true,
                            alternateApprovers: {
                              include: {
                                user: true,
                              },
                            },
                          },
                        },
                      },
                    },
                    supportingDocuments: true,
                    referenceProgram: {
                      include: {
                        programType: true,
                      },
                    },
                    platform: true,
                    bankAccountProgramAccesses: {
                      include: {
                        bankAccount: true,
                      },
                    },
                  },
                },
              },
            },
            disbursements: {
              include: {
                reimbursements: {
                  include: {
                    transactions: {
                      include: {
                        creator: true,
                      },
                    },
                  },
                },
                originBankAccount: true,
                destinationBankAccount: true,
                transactions: {
                  include: {
                    creator: true,
                  },
                },
              },
            },
            lossClaim: true,
            loan: {
              include: {
                payments: true,
                fees: true,
              },
            },
          },
        },
      }),
    ]);

    return portfolioApplicationsSignal.update({
      applications: data.applications,
      totalPages: data.count ? Math.ceil(data.count / recordsPerPage) : 1,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

// export const fetchAndSetApprovalChainUsers = application;

export const updateApplicationStatus = async (application, status) => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;

  try {
    loaderSignal.update({
      isContentLoading: true,
      message: 'Updating application status...',
    });
    const body = {
      where: {
        id: application.id,
      },
      data: {
        status,
      },
    };

    if (status === APPLICATION_STATUS.approved) {
      body.data.approvedAmount = 50000;
    }

    await api.patch({ path: '/applications', body });
    await refreshPortfolioData();
    alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully updated applications status.',
    });
    return portfolioApplicationsSignal.update({ confirmStatusVisible: false });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const openPortfolioApplicationModal = (selectedApplication) => {
  portfolioApplicationsSignal.update({
    selectedApplication,
    modalVisible: true,
  });
};

const refreshPortfolioData = async () => {
  await setAndFetchEdoApplications();
  const applicationId =
    portfolioApplicationsSignal.value.selectedApplication.id;
  const { applications } = portfolioApplicationsSignal.value;

  return portfolioApplicationsSignal.update({
    selectedApplication: applications.find((app) => app.id === applicationId),
  });
};

export const handleEdoPortfolioFilterChange = (key, value) => {
  const dropdownFilters = portfolioApplicationsSignal.value.dropdownFilters;
  return portfolioApplicationsSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

export const handleEdoPortfolioAppliedFiltersChange = (appliedFilters) =>
  portfolioApplicationsSignal.update({
    appliedFilters: {
      ...appliedFilters,
    },
  });

export const handleDownloadApplicationsData = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    await api.get({
      path: '/applications/download',
      options: {
        orderBy: xFormSortToOrderByClause(),
        where: xFormAppliedFiltersToWhereClause(),
      },
      isFileDownload: true,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const resetEdoPortfolioFilterInputs = () =>
  portfolioApplicationsSignal.update({ dropdownFilters: {} });
