import BackButton from 'components/global/BackButton';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import NewFund from 'components/global/NewFund';
import { useHistory } from 'react-router-dom';

const EdoNewFund = () => {
  const history = useHistory();

  return (
    <>
    <BackButton onClick={() => history.goBack()} buttonText="Back" />
    <BreadCrumbWrapper labels={['Funds', 'Create New Fund']}>
      <NewFund />
    </BreadCrumbWrapper>
    </>
  );
}


export default EdoNewFund;
