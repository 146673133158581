import { Container, Form } from 'react-bootstrap';
import AdminMigrateSignal from 'signals/StateMigrate.signal';
import { handleApplicationsSubmit } from '../StateMigrate.helpers';
import MigrateApplications from './MigrateApplications';
import MigrateSuccessPage from './MigrateSuccessPage';
import ChooseStateAndProgram from './ChooseStateAndProgram';

const ApplicationMigrationForm = ({ portalType }) => {
  const showStep = () => {
    const { step } = AdminMigrateSignal.value[portalType].form;
    switch (step) {
      case 1:
        return (
          <ChooseStateAndProgram portalType={portalType} />
        );
      case 2:
        return (
          <MigrateApplications portalType={portalType} />
        );
      case 3:
        return (
          <MigrateSuccessPage portalType={portalType} />
        );
      default:
        AdminMigrateSignal.update({ step: 1 });
        return null;
    }
  };

  return (
    <Container className="bg-light">
      <Form onSubmit={handleApplicationsSubmit}>
        {showStep()}
      </Form>
    </Container>
  );
};

export default ApplicationMigrationForm;
