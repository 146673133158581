import { useRef, useState } from 'react';
import { Form, Overlay } from 'react-bootstrap';

const AutoComplete = ({
  options,
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  disabled,
  onBlur,
  isInvalid,
  onSelect,
  onFocus,
  optionFormatter,
}) => {
  const [show, setShow] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const target = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      setActiveOption(activeOption > 0 ? activeOption - 1 : activeOption);
    } else if (e.key === 'ArrowDown') {
      setActiveOption(
        activeOption < options?.length - 1 ? activeOption + 1 : activeOption
      );
    } else if (e.key === 'Enter') {
      if (options?.length) {
        onSelect(options[activeOption]);
        target.current.blur();
      }
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <Overlay target={target.current} show={show} placement="bottom">
        {({ ...props }) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              padding: '2px 10px',
              width: target.current.clientWidth,
              borderWidth: 1,
              borderColor: '#EDEDED',
              borderStyle: 'solid',
              overflow: 'hidden',
              zIndex: 100000,
              ...props.style,
            }}
          >
            {options?.length ? (
              <>
                {options?.map((option, index) => (
                  <DropdownRow
                    optionFormatter={optionFormatter}
                    onSelect={onSelect}
                    option={option}
                    active={activeOption === index}
                    key={index}
                    index={index}
                    setActiveOption={setActiveOption}
                    name={name}
                  />
                ))}
              </>
            ) : (
              <p className="mb-0">No results found</p>
            )}
          </div>
        )}
      </Overlay>
      <div className={className}>
        {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
        <div>
          <Form.Control
            ref={target}
            autoComplete="off"
            disabled={disabled}
            name={name}
            id="input"
            value={value}
            className={inputClassName}
            onChange={onChange}
            onBlur={() => {
              setShow(false);
              onBlur && onBlur();
            }}
            isInvalid={isInvalid}
            onFocus={() => {
              onFocus && onFocus();
              setShow(true);
              setActiveOption(0);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DropdownRow = ({ option, onSelect, active, setActiveOption, index, optionFormatter }) => (
  <p
    className="mb-0 py-1"
    onMouseOver={() => {
      setActiveOption(index);
    }}
    onClick={() => onSelect && onSelect(option)}
    style={{
      backgroundColor: active ? '#EDEDED' : 'white',
      cursor: 'pointer',
    }}
  >
    {optionFormatter(option)}
  </p>
);

export default AutoComplete;
