import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import documentPreviewModalSignal, {
  DocumentPreviewSignalType,
} from '../../../DocumentPreviewModal/DocumentPreviewModal.signal';
import { FileUploadOrBrowserFile } from './FilePreviewButton';
import { Sygnal } from 'libs/signals';
import { Signal } from '@preact/signals-react';
import loaderSignal from '../../../../../signals/Loader.signal';

const UPLOAD_PREVIEW_URL = '/uploadPreviews';

export const buildDataUrlForPreview = async (
  uploadedFile: FileUploadOrBrowserFile,
  model: string,
  signal: Sygnal<boolean>,
  fileType: string | undefined = undefined
): Promise<Signal<unknown>> => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Loading document preview...',
  });

  try {
    signal.update(true);
    if (typeof uploadedFile === 'string') {
      return await showFileLikePreview(uploadedFile, fileType);
    } else if (uploadedFile instanceof File) {
      return await showFileLikePreview(uploadedFile);
    } else {
      let path;
      if (uploadedFile.uuid) {
        path = `${UPLOAD_PREVIEW_URL}?uuid=${uploadedFile.uuid}&model=${model}&parentId=${uploadedFile.parentId}`;
      } else {
        path = `${UPLOAD_PREVIEW_URL}?id=${uploadedFile.id}&model=${model}`;
      }
      const url = await api.get({ path, options: undefined });
      return await showFileLikePreview(url, uploadedFile.fileType);
    }
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      //@ts-ignore
      message: error.message,
    });
  } finally {
    signal.update(false);
    loaderSignal.reset();
  }
};

async function showFileLikePreview(
  url: File | string,
  filetypeAssertion: string | undefined = undefined
): Promise<Signal<DocumentPreviewSignalType>> {
  console.log('url', url);
  console.log('filetypeAssertion', filetypeAssertion);
  let blob;
  if (url instanceof File) {
    blob = url;
  } else {
    const response = await fetch(url);
    blob = await response.blob();
  }

  if (filetypeAssertion) {
    blob = new Blob([blob], { type: filetypeAssertion });
  }

  const dataUrl = URL.createObjectURL(blob);
  console.log('dataUrl', dataUrl);
  documentPreviewModalSignal.update({ dataUrl });
  return documentPreviewModalSignal;
}
