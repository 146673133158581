import $capTable from "components/global/CapTable/capTable.signal";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import CloseModalButton from "../Constant/CloseModalButton";
import CapTable from "./CapTable";
import { Button, Modal } from "react-bootstrap";
import { handleCloseCapTableModal } from "./capTable.helpers";

const CapTableModal = () => {
  const { businessName, modalVisible } = $capTable.value;

  return (
    <ModalWrapper
      show={modalVisible}
      centered
      size="xl"
      onHide={handleCloseCapTableModal}
      className="xxl-modal"
    >
      <Modal.Header>
        <h5>Cap Table {businessName ? ` - ${businessName}` : ''}</h5>
        <CloseModalButton onCloseModal={handleCloseCapTableModal} />
      </Modal.Header>
      <Modal.Body>
        <CapTable />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleCloseCapTableModal}>Dismiss</Button>
      </Modal.Footer>
    </ModalWrapper>
  )
};

export default CapTableModal;