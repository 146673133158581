const HTMLRenderer = ({
  htmlString,
  className,
  maxLength,
  htmlStringClassName,
}) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const pElements = doc.querySelectorAll('p');

  if (maxLength && pElements.length > 0) { // truncates html content if max length is provided
    const firstPElement = pElements[0];

    firstPElement.className = htmlStringClassName;

    if (firstPElement.textContent.length > maxLength) {
      firstPElement.textContent = firstPElement.textContent.substring(0, maxLength) + '...';
    }

    for (let i = 1; i < pElements.length; i++) {
      pElements[i].remove();
    }
  }



  const modifiedHtmlString = new XMLSerializer().serializeToString(doc);

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: modifiedHtmlString }}
    />
  );
};

export default HTMLRenderer;
