import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchAndSetSearchResultsAndProgramRequests,
  resetSearchProgramFilters,
  setDropdownFilter
} from "./SearchProgramResults.helpers";
import SimpleTableFilterDropdown from "../SimpleTableFilterDropdown";
import SimpleTable from "../SimpleTable";
import { searchProgramsResultsSignal } from "./SearchProgramsResults.signal";
import userAccountSignal from "../../../signals/UserAccount.signal";
import { uniqById } from "../../../utils/array";

function inPortfolioString({
  fundingProgram,
  programRequests,
  fundingProgramMemberships,
}) {
  const existingFundingProgramMembership = fundingProgramMemberships.find(
    (fpm) => fpm.fundingProgramId === fundingProgram.id
  );

  if (existingFundingProgramMembership) {
    return 'In Portfolio';
  }

  const existingRequest = programRequests.find(
    (request) => request.fundingProgramId === fundingProgram.id
  );

  if (existingRequest) {
    return 'Request submitted';
  }

  return '-';
}

const LenderSearchProgramsResults = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    programRequests,
    fundingProgramMemberships,
    fundingPrograms,
    dropdownFilters,
  } = searchProgramsResultsSignal.value;

  const uniqReferencePrograms = uniqById(
    fundingPrograms.map((fp) => fp.referenceProgram)
  );

  useEffect(() => {
    fetchAndSetSearchResultsAndProgramRequests();
  }, [location.search]);

  return (
    <>
      <div className="bg-white p-32 border border-gray shadow-sm">
        <h3 className="text-center">Economic Development Opportunities</h3>
        <hr />
        <div className="d-flex flex-row justify-content-between align-items-center mb-16">
          <SimpleTableFilterDropdown
            filtersState={dropdownFilters}
            onChange={({ name, value }) => setDropdownFilter({ name, value })}
            onApply={() => {
              fetchAndSetSearchResultsAndProgramRequests();
            }}
            onClearFilterInputs={resetSearchProgramFilters}
            filters={[
              {
                type: 'integer',
                label: 'ID',
                key: 'id',
              },
              {
                type: 'select',
                label: 'Program Name',
                key: 'name',
                options: fundingPrograms.map((fp) => ({
                  value: fp.id,
                  label: fp.name,
                })),
              },
              {
                type: 'select',
                label: 'Reference Program',
                key: 'referenceProgramName',
                options: uniqReferencePrograms.map((rp) => ({
                  value: rp.id,
                  label: rp.programName,
                })),
              },
              {
                type: 'text',
                label: 'Keywords',
                key: 'keywords',
              },
            ]}
          />
        </div>
        <SimpleTable
          headers={[
            {
              key: 'id',
              title: 'ID',
              sortable: true,
            },
            {
              key: 'name',
              title: 'Program Name',
              sortable: true,
            },
            {
              key: 'referenceProgramName',
              title: 'Reference Program',
              sortable: true,
            },
            {
              key: 'remaining',
              title: 'Remaining Funds',
              currency: true,
            },
            {
              key: 'status',
              title: 'Status',
            },
            {
              key: 'action',
              title: 'Action',
            },
          ]}
          rows={fundingPrograms.map((fundingProgram) => ({
            id: fundingProgram.id,
            programName: fundingProgram.name,
            referenceProgramName: fundingProgram.referenceProgram.programName,
            remaining: fundingProgram.stats.remaining,
            status: inPortfolioString({
              fundingProgram,
              fundingProgramMemberships,
              programRequests,
            }),
            action: ({ key }) => (
              <td className="text-right" key={key}>
                <Button
                  className="rounded"
                  size="sm"
                  variant="outline-dark"
                  onClick={() =>
                    history.push(`/${userAccountSignal.value.userData.account.portalType.toLowerCase()}/program-requests/all-programs/${
                      fundingProgram.id
                    }
                    `)
                  }
                >
                  Detail
                </Button>
              </td>
            ),
          }))}
          onSort={() => {
            fetchAndSetSearchResultsAndProgramRequests();
          }}
        />
      </div>
    </>
  );
};

export default LenderSearchProgramsResults;
