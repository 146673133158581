import ProgramSignal, {
  bankAccountsSignal,
  fundingProgramFormErrorsSignal,
  fundingProgramsSignal,
  selectedFundingProgramSignal,
  userAccessSignal,
} from 'signals/Program.signal';

import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import userAccountSignal from 'signals/UserAccount.signal';
import loaderSignal from 'signals/Loader.signal';
import { showPendingImplementation } from 'libs/functions/global.functions';
import history from '../../../../history';

export const fetchAndSetPrograms = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    if (!userAccountSignal.value.userData.account.edo.id) {
      throw new Error('EDO ID must be defined');
    }
    const [data, programTypes] = await Promise.all([
      api.get({
        path: '/fundingPrograms',
        options: {
          where: xFormAppliedFiltersToWhereClause(),
          orderBy: xFormSortToOrderByClause(),
          include: {
            administrator: true,
            stats: true,
            platform: {
              include: {
                stats: true,
              },
            },
            supportingDocuments: true,
            referenceProgram: {
              include: {
                programType: true,
              },
            },
            bankAccountProgramAccesses: {
              include: {
                bankAccount: true,
              },
            },
            edoUserProgramAccesses: true,
            approvalChainTemplates: {
              orderBy: {
                id: 'desc',
              },
              include: {
                steps: {
                  orderBy: {
                    id: 'asc',
                  },
                  include: {
                    primaryApprover: true,
                    alternateApprovers: {
                      include: { user: true },
                    },
                  },
                },
              },
            },
          },
        },
      }),
      api.get({
        path: '/programtypes',
      }),
    ]);

    fundingProgramsSignal.update([...data]);
    ProgramSignal.update({ programTypes });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchAndSetBankAccounts = async () => {
  const accountId = userAccountSignal.value.userData.account.id;
  try {
    loaderSignal.update({ isContentLoading: true });
    const data = await api.get({
      path: '/bankaccounts',
      options: {
        include: {
          bankAccountProgramAccesses: true,
        },
        where: {
          accountId,
        },
      },
    });
    return bankAccountsSignal.update(data);
  } catch (e) {
    return alertSignal.update({
      message: 'Failed to retrieve Bank Accounts. Please try again.',
      type: 'notification',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleCloseModal = () => {
  bankAccountsSignal.update([]);
  selectedFundingProgramSignal.reset();
  userAccessSignal.reset();
  fundingProgramFormErrorsSignal.reset();
};

export const handleSaveSourceAccess = async () => showPendingImplementation();
// loaderSignal.update({ isContentLoading: true });
// const {
//   id,
//   selectedBankAccountId,
// } = selectedFundingProgramSignal.value;
//
// try {
//   const delRes = await deleteBankAccountAccessForProgram([selectedFundingProgramSignal.value.bankAccountProgramAccesses[0].id]);
//   if (delRes) {
//     const createRes = await createAndConnectBankAccountAccessForProgram({ fundingProgramId: id, bankAccountId: Number(selectedBankAccountId) });
//     if (createRes) {
//       alertSignal.update({
//         className: 'bg-success',
//         type: 'notification',
//         message: 'Successfully updated source asccess data',
//       });
//     }
//   } else {
//     alertSignal.update({
//       className: 'bg-warning',
//       type: 'notification',
//       message: 'Something went wrong trying to connect the bank account',
//     });
//   }
// } catch (error) {
//   alertSignal.update({
//     type: 'notification',
//     error,
//     message: error.message,
//   });
// } finally {
//   loaderSignal.reset();
// }

export const addUserAccessToProgram = async (userId) => {
  const fundingProgram = selectedFundingProgramSignal.value.programInfo;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating user access...',
  });

  try {
    await api.post({
      path: '/edo/user/program/access',
      body: [
        {
          edoId: userAccountSignal.value.userData.account.edo?.id,
          userId,
          fundingProgramId: fundingProgram.id,
          role: 'STAFF',
        },
      ],
    });
    await fetchAndSetPrograms();
    selectedFundingProgramSignal.update({
      programInfo: fundingProgramsSignal.value.find(
        (program) => program.id === fundingProgram.id
      ),
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const removeUserProgramAccess = async (userId) => {
  const fundingProgram = selectedFundingProgramSignal.value.programInfo;
  loaderSignal.update({
    isContentLoading: true,
    message: 'Updating user access...',
  });

  try {
    await api.delete({
      path: '/edo/user/program/access',
      body: {
        where: {
          edoId: userAccountSignal.value.userData.account.edo?.id,
          userId,
          fundingProgramId: fundingProgram.id,
        },
      },
    });
    await fetchAndSetPrograms();
    selectedFundingProgramSignal.update({
      programInfo: fundingProgramsSignal.value.find(
        (program) => program.id === fundingProgram.id
      ),
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleUserProgramAccessClick = (userId, checked) => {
  if (checked) {
    return removeUserProgramAccess(userId);
  }
  return addUserAccessToProgram(userId);
};

export const handleUserAccessFilterChange = (e) => {
  const { value } = e.target;
  const { adminAndStaffUsers } = userAccessSignal.value;
  let filteredUsers = [];

  if (value) {
    filteredUsers = adminAndStaffUsers.filter((user) => user.role === value);
  } else {
    filteredUsers = adminAndStaffUsers;
  }

  userAccessSignal.update({ filteredUsers, filter: value });
};

export const handleUserAccessSearchChange = (e) => {
  const { value } = e.target;
  const { filter, adminAndStaffUsers } = userAccessSignal.value;
  let filteredUsers = [];

  if (filter) {
    filteredUsers = adminAndStaffUsers.filter((user) => user.role === filter);
  } else {
    filteredUsers = adminAndStaffUsers;
  }
  const newUsersFilteredBySearch = filteredUsers.filter((user) => {
    const userFullName = `${user.firstName} ${user.lastName}`;
    return userFullName.toLowerCase().includes(value.toLowerCase());
  });

  userAccessSignal.update({
    filteredUsers: newUsersFilteredBySearch,
    currentPage: 0,
  });
};

export const handleEdoProgramsFilterChange = (key, value) => {
  const dropdownFilters = ProgramSignal.value.dropdownFilters;
  return ProgramSignal.update({
    dropdownFilters: { ...dropdownFilters, [key]: value },
  });
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {};

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.programName) {
    where.id = Number(params.programName);
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  if (key === 'programName') {
    return [
      {
        name: dir,
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

export const resetEdoProgramFilterInputs = () =>
  ProgramSignal.update({ dropdownFilters: {} });
