import history from '../../../history';

export const handlePageClick = (page) => {
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.set('page', page);

  history.push(`${history.location.pathname}?${searchParams.toString()}`);
};

export const handleNextPageClick = (totalPages) => {
  const searchParams = new URLSearchParams(history.location.search);
  const searchEntries = Object.fromEntries(searchParams);
  const page = searchEntries.page ? Number(searchEntries.page) : 1;

  if (page < totalPages) {
    searchParams.set('page', page + 1);
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
  }
};

export const handlePreviousPageClick = () => {
  const searchParams = new URLSearchParams(history.location.search);
  const searchEntries = Object.fromEntries(searchParams);
  const page = searchEntries.page ? Number(searchEntries.page) : 1;

  if (page >= 2) {
    searchParams.set('page', page - 1);
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
  }
};