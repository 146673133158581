import { useState } from 'react';
import { Button, Col, Collapse, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  caretDownIcon,
  caretRightIcon,
} from 'components/global/Constant/Icons';
import pullOrganizationNameFromAccount from 'libs/pullOrganizationNameFromAccount';
import { toggleActivationUserModal } from 'components/global/UserActivationModal/userActivationModal.helpers';
import { displayUserDetails } from '../StateUsers.helpers';

const UsersTableRow = ({ user, onToggleModal }) => {
  const [showButtons, setShowButtons] = useState(false);

  const organizationName = pullOrganizationNameFromAccount(user.account);

  return (
    <>
      <tr className="border border-solid border-bottom-0 border-right-0 border-left-0">
        <td id="user" style={{ minWidth: '2rem' }} className="align-middle">
          <Container
            className="p-0"
            onClick={() => setShowButtons(!showButtons)}
          >
            <FontAwesomeIcon
              size="lg"
              icon={showButtons ? caretDownIcon : caretRightIcon}
            />
          </Container>
        </td>
        <td>{user.account?.id}</td>
        <td id="name" className="py-0 align-middle text-nowrap">
          {user.firstName} {user.lastName}
        </td>
        <td id="status" className="py-0 align-middle text-nowrap">
          {user.status}
        </td>
        <td id="created" className="py-0 align-middle text-nowrap">
          {new Date(user.lastSeenAt).toLocaleDateString() || '-'}
        </td>
        <td id="created" className="py-0 align-middle text-nowrap">
          {new Date(user.createdDate).toLocaleDateString()}
        </td>
        <td id="organization_name" className="py-0 align-middle text-nowrap">
          {organizationName}
        </td>
        <td id="organization_name" className="py-0 align-middle text-nowrap">
          {user.account?.portalType}
        </td>
        <td id="organization_name" className="py-0 align-middle text-nowrap">
          {user.role}
        </td>
      </tr>
      <tr className="border border-top-0 border-right-0 border-left-0">
        <td colSpan={4} className="p-0 border-top-0">
          <Container>
            <Collapse in={showButtons}>
              <Row className="py-8">
                <Col>
                  <Button
                    size="sm"
                    className="mr-8"
                    onClick={() =>
                      displayUserDetails(
                        user.id,
                        onToggleModal,
                        'userLogsModal'
                      )
                    }
                  >
                    Logs
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    className="ml-8"
                    onClick={() => toggleActivationUserModal(user)}
                  >
                    Deactivate
                  </Button>
                </Col>
              </Row>
            </Collapse>
          </Container>
        </td>
      </tr>
    </>
  );
};

export default UsersTableRow;
