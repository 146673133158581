import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { warningIcon } from "components/global/Constant/Icons";
import { Alert, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const NoFundsAlert = () => {
  const history = useHistory();
  
  return (
  <Alert variant="warning" className="d-flex flex-row align-items-center justify-content-between">
    <FontAwesomeIcon className="mr-16" size="2x" icon={warningIcon} />
    <p className="mb-0">You have not created any funds. A fund is required to add a portfolio company.</p>
    <div className="text-right">
      <Button variant="outline-secondary" className="text-nowrap rounded" onClick={() => history.push('/vc/funds')}>Go to Funds</Button>
    </div>
  </Alert>
  );
};

export default NoFundsAlert;