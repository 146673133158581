import { Button, Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import userAccountSignal from 'signals/UserAccount.signal';
import {
  deleteBusinessFavorite,
  markBusinessAsFavorite,
} from 'components/global/SearchBusinessResults/searchBusinessResults.helpers';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const LenderBusinessDetail = () => {
  const { pathname } = useLocation();
  const organizationId = pathname.split('/')[pathname.split('/').length - 1];
  const { selectedOrganization: selectedBusiness } =
    organizationDetailsSignal.value;
  let labels = [];

  if (pathname.includes('search-results')) {
    labels = [
      'Search Businesses',
      'Business Search Results',
      selectedBusiness?.name,
    ];
  } else if (pathname.includes('portfolio')) {
    labels = [
      '',
      `${selectedBusiness?.name} - Application`,
      `${selectedBusiness?.name} - Business Details`
    ];
  } else {
    labels =[
      '',
      `${selectedBusiness?.name} - Business Details`,
    ];
  }

  let favoriteRecord = null;
  favoriteRecord = selectedBusiness?.favoritedByLenders?.find(
    (fb) =>
      fb.businessId === selectedBusiness.id &&
      fb.lenderId === userAccountSignal.value.userData.account.lender.id
  );

  const init = useCallback(async () => {
    await fetchOrganization(organizationId, '/businesses');
  });

  useEffect(() => {
    init();
  }, [organizationId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card className="rounded-0">
        <Card.Body>
          <OrganizationDetails />
          <div className="text-center">
            {favoriteRecord ? (
              <Button
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() => {
                  deleteBusinessFavorite(favoriteRecord, () =>
                    fetchOrganization(organizationId, '/businesses')
                  );
                }}
              >
                Remove Favorite
              </Button>
            ) : (
              <Button
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() => {
                  markBusinessAsFavorite(selectedBusiness, () =>
                    fetchOrganization(organizationId, '/businesses')
                  );
                }}
              >
                Mark as Favorite
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default LenderBusinessDetail;
