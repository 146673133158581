import { useCallback, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserInvitations from 'components/global/UserInvitations';
import { fetchAndSetUserInvites } from 'components/global/UserInvitations/UserInvitations.helpers';
import EdoSettingsManageUsers from './components/EdoManageUsers';
import { fetchAndSetUsersData } from './EdoUsers.helpers';
import UserRequests from 'components/global/UserRequests';
import { fetchAndSetUserRequests } from 'components/global/UserRequests/UserRequests.helpers';
import resetSearchParams from 'utils/resetSearchParams';

export const EdoUsers = () => {
  const init = useCallback(async () => {
    await fetchAndSetUsersData();
    await fetchAndSetUserInvites();
    await fetchAndSetUserRequests();
  });

  useEffect(() => {
    init();
  }, []);

  return (
    <Tabs
      defaultActiveKey="manageUsers"
      mountOnEnter
      onSelect={() => resetSearchParams()}
    >
      <Tab eventKey="manageUsers" title="Current Users">
        <div className="bg-white p-32 border border-gray shadow-sm">
          <EdoSettingsManageUsers />
        </div>
      </Tab>
      <Tab eventKey="manageUserInvites" title="Invitations">
        <div className="bg-white p-32 border border-gray shadow-sm">
          <UserInvitations />
        </div>
      </Tab>
      <Tab eventKey="manageRequests" title="Requests">
        <div className="bg-white p-32 border border-gray shadow-sm">
          <UserRequests />
        </div>
      </Tab>
    </Tabs>
  );
};

export default EdoUsers;
