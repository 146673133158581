import { Table } from "react-bootstrap";
import { FieldComponent } from "../Field";

const FieldTable = ({ tableData, className, label = '' }) => (
  <div className={className}>
    <p hidden={!label}><b>{label}</b></p>
    <Table>
      <thead>
        <tr>
          {tableData?.headers?.map((header, index) => (
            <th key={index}>{header}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.rows?.map((row, index) => (
          <tr key={index}>
            {row.data.map((item, index) => (
              <td key={index}>
                <FieldComponent
                  type={item.type}
                  label={item.label}
                  value={item.value}
                  name={item.name}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default FieldTable;
