import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import dateOptions from 'components/global/Constant/dateOptions';
import { caretDownIcon, caretRightIcon } from 'components/global/Constant/Icons';

const LogDropDown = ({ children, data, indent }) => {
  const [visible, setVisible] = useState(false);
  const createdDate = new Date(data.createdDate);

  return (
    <>
      {indent === 1 ?
        (
          <>
            <div role="none" onClick={() => setVisible(!visible)}>
              <p className="mb-8">
                {createdDate.toLocaleDateString('en-US', dateOptions.date)}&ensp;
                <FontAwesomeIcon size="lg" icon={visible ? caretDownIcon : caretRightIcon} />
              </p>
            </div>
            <Collapse in={visible}>
              <div>{children}</div>
            </Collapse>
          </>
        ) :
        (
          <>
            <div role="none" onClick={() => setVisible(!visible)}>
              <p className="ml-16 mb-8">
                {data.user.firstName} {data.user.lastName}&ensp;
                <FontAwesomeIcon size="lg" icon={visible ? caretDownIcon : caretRightIcon} />
              </p>
            </div>
            <Collapse in={visible}>
              <div>{children}</div>
            </Collapse>
          </>
        )}
    </>
  );
};

export default LogDropDown;
