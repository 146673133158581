import { Application, User } from '../../../../types';
import Signal from '../../../../libs/signals';

type BulkImportTask = {
  id: number;
  createdDate: string;
  updatedDate: string;
  status: string;
  ocrModelKey: string;
  mainDocumentPath: string;
  supportingDocumentPaths: string[];
  hash: string;
  errors: string[];
  userId: string[];
  user: User;
  application: Application;
};

type EdoApplicationBulkImportSignal = {
  bulkImportTasks: BulkImportTask[];
  formModalVisible: boolean;
  importData: [string, string, string][];
  currentPage: number;
  recordsPerPage: number;
  totalPages: number;
};

const edoApplicationBulkImportSignal = Signal<EdoApplicationBulkImportSignal>({
  bulkImportTasks: [],
  formModalVisible: false,
  importData: [],
  currentPage: 1,
  recordsPerPage: 20,
  totalPages: 0,
});

export default edoApplicationBulkImportSignal;
