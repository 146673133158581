import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from 'react-bootstrap';
import { checkmarkIcon, infoIcon } from 'components/global/Constant/Icons';
import applicationComplianceSignal from './ApplicationCompliance.signal';
import {
  handleMarkAsRead,
  handleYesOrNoToAll,
  onComplianceChecklistChange,
} from './ApplicationCompliance.helpers';

const ComplianceChecklistItem = ({ complianceItemData }) => {
  const { isApplicationDetailsView } = applicationComplianceSignal.value;

  return (
    <>
      <p>
        <b>{complianceItemData.title}</b>
      </p>
      <Row className="justify-content-center">
        <Col>
          {complianceItemData.tablesData.map((tableData) => (
            <div key={tableData.id} className="mt-32">
              <p
                hidden={
                  !tableData.specialInstructions || isApplicationDetailsView
                }
                className="text-right mb-0"
              >
                <small>* {tableData.specialInstructions}</small>
              </p>
              <ChecklistItemTable
                tableData={tableData}
                complianceItemName={complianceItemData.name}
              />
            </div>
          ))}
        </Col>
      </Row>
      <Row className="justify-content-end px-16">
        <Button
          hidden={!complianceItemData.markAsRead || isApplicationDetailsView}
          variant={
            applicationComplianceSignal.value.complianceChecklist[
              complianceItemData.name
            ]
              ? 'success'
              : 'outline-success'
          }
          onClick={() => handleMarkAsRead(complianceItemData.name)}
        >
          {applicationComplianceSignal.value.complianceChecklist[
            complianceItemData.name
          ] ? (
            <FontAwesomeIcon icon={checkmarkIcon} />
          ) : (
            'Mark as Read'
          )}
        </Button>
      </Row>
    </>
  );
};

const ChecklistItemTable = ({ tableData, complianceItemName }) => {
  const { isApplicationDetailsView } = applicationComplianceSignal.value;

  // console.log('isApplicationDetailsView', isApplicationDetailsView);
  // console.log('complianceItemName', complianceItemName);
  // console.log('applicationComplianceSignal', applicationComplianceSignal.value);

  const renderNotes = (props) => (
    <Popover
      id={`notes-${tableData.id}`}
      {...props}
      style={{ ...props.style, maxWidth: 500, padding: 8 }}
    >
      {props.popper.state?.options?.notes?.map((note) => (
        <p key={note}>&#8226; {note}</p>
      ))}
    </Popover>
  );

  return (
    <Table borderless>
      <thead hidden={!tableData.prompt && !tableData.promptItem}>
        <tr
          className={`border-top ${
            !tableData.keys ? 'border-bottom-0' : 'border-bottom'
          }`}
        >
          <th>
            <div className="d-flex flex-row">
              <p
                className={`d-flex inline-flex ${
                  !tableData.promptItem && 'mb-0'
                }`}
              ></p>
              {tableData.prompt}
              {tableData.notes && (
                <OverlayTrigger
                  placement="left"
                  popperConfig={{ notes: tableData.notes }}
                  overlay={renderNotes}
                >
                  <div>
                    <FontAwesomeIcon icon={infoIcon} />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            {tableData.promptItem && (
              <div className="d-flex flex-row">
                <p className="font-weight-normal mb-0 d-flex flex-row pl-16"></p>
                {tableData.promptItem && tableData.promptItem}
                {tableData.promptItemNotes && (
                  <OverlayTrigger
                    placement="left"
                    popperConfig={{ notes: tableData.promptItemNotes }}
                    overlay={renderNotes}
                  >
                    <div>
                      <FontAwesomeIcon icon={infoIcon} />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            )}
          </th>
          <th className="text-center" hidden={tableData.headersHidden}>
            Yes
          </th>
          <th className="text-center" hidden={tableData.headersHidden}>
            No
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData.keys &&
          tableData.keys.length > 1 &&
          !tableData.noCheckboxes &&
          !isApplicationDetailsView && (
            <tr className="border-bottom">
              <td colSpan={3} className="text-right">
                <Button
                  size="sm"
                  className="rounded mr-8"
                  variant="outline-primary"
                  onClick={() =>
                    handleYesOrNoToAll(
                      'yesToAll',
                      complianceItemName,
                      tableData.id
                    )
                  }
                >
                  Yes to All
                </Button>
                <Button
                  size="sm"
                  className="rounded"
                  variant="outline-secondary"
                  onClick={() =>
                    handleYesOrNoToAll(
                      'noToAll',
                      complianceItemName,
                      tableData.id
                    )
                  }
                >
                  No to All
                </Button>
              </td>
            </tr>
          )}
        {tableData.keys &&
          tableData.keys.map((key, index) => (
            <tr
              key={key.label}
              className={
                index < tableData.keys.length - 1 ? 'border-bottom' : ''
              }
            >
              <td className={tableData.promptItem ? 'pl-48' : 'pl-32'}>
                {key.label}
                {key.additionalItems &&
                  key.additionalItems.map((item) => (
                    <p
                      key={item}
                      className={`mb-8 ${
                        tableData.promptItem ? 'pl-32' : 'pl-16'
                      }`}
                    >
                      {item}
                    </p>
                  ))}
              </td>
              <td align="center">
                <Form.Check
                  disabled={isApplicationDetailsView}
                  hidden={!key.name}
                  className="pl-24"
                  value={true}
                  name={key.name}
                  id={`${key.name}Yes`}
                  checked={
                    applicationComplianceSignal.value.complianceChecklist[
                      key.name
                    ] === true
                  }
                  onChange={(e) => onComplianceChecklistChange(e)}
                />
              </td>
              <td align="center">
                <Form.Check
                  disabled={isApplicationDetailsView}
                  hidden={!key.name}
                  className="pl-24"
                  value={false}
                  name={key.name}
                  id={`${key.name}No`}
                  checked={
                    applicationComplianceSignal.value.complianceChecklist[
                      key.name
                    ] === false
                  }
                  onChange={(e) => onComplianceChecklistChange(e)}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ComplianceChecklistItem;
