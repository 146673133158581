import { Tab, Tabs } from 'react-bootstrap';
import GeneralDetails from './components/GeneralDetails';
import CapitalCallList from './components/CapitalCallList';
import PortfolioCompanies from './components/PortfolioCompanies';
import PortfolioCompanyRequests from './components/PortfolioCompanyRequests';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FundDocuments from './components/FundDocuments';
import userAccountSignal from 'signals/UserAccount.signal';
import AddPortfolioCompanyModal from '../AddPortfolioCompany';
import $appSettings from 'signals/AppSettings.signal';

const FundDetails = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('general');
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const { PORTAL_TYPES } = $appSettings.value.constants;

  useEffect(() => {
    if (params.tab) {
      setActiveTab(params.tab);
    }
  }, []);

  return (
    <div>
      <AddPortfolioCompanyModal />
      <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e)}>
        <Tab eventKey="general" title="General">
          <GeneralDetails />
        </Tab>
        <Tab eventKey="documents" title="Documents">
          <FundDocuments />
        </Tab>
        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.vc && (
          <Tab
            eventKey="portfolioCompanyRequests"
            title="Portfolio Company Requests"
          >
            <PortfolioCompanyRequests />
          </Tab>
        )}
        <Tab eventKey="portfolioCompanies" title="Portfolio Companies">
          <PortfolioCompanies />
        </Tab>
        <Tab eventKey="capitalCall" title="Capital Calls">
          <CapitalCallList />
        </Tab>
      </Tabs>
    </div>
  );
};

export default FundDetails;
