import { useCallback, useEffect } from 'react';
import { Button, Card, Row, Spinner } from 'react-bootstrap';
import {
  notificationCursorSignal,
  notificationIsPaginatingSignal,
} from 'signals/Notification.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import {
  fetchAndSetNotifications,
  fetchAndSetNotificationSchedule,
  paginateAndAppendNotifications,
} from './NotificationPage.helpers';
import Notifications from './components/Notifications';
import NotificationsSchedule from './components/NotificationsSchedule';

export const NotificationPage = () => {
  const portal =
    userAccountSignal.value.userData?.account?.portalType.toLowerCase();

  const init = useCallback(() => {
    fetchAndSetNotifications();
    fetchAndSetNotificationSchedule();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Card className="overflow-auto" style={{ maxHeight: '80vh' }}>
      <Card.Body>
        <NotificationsSchedule />
        <Row className="my-32">
          <h5 className="mx-auto">History</h5>
        </Row>
        <Notifications />
        <Row>
          <Button
            variant="outline-primary"
            className="mx-auto py-8 mb-32"
            to={`${portal}/notifications`}
            hidden={!notificationCursorSignal.value}
            onClick={paginateAndAppendNotifications}
          >
            Show More{' '}
            {notificationIsPaginatingSignal.value && (
              <Spinner size="sm" animation="border" role="status" />
            )}
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NotificationPage;
