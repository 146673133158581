import formatMoney from 'utils/formatMoney';
import { Table } from 'react-bootstrap';
import reportingSignals, { quarterlyReportSignal } from 'components/global/Reporting/Reporting.signals';
import { DateTime } from 'luxon';
import { quarterlyReportDeadlines } from '../reportConstants';
import { findMatchingStaticQuarterlyReport } from '../Reporting.helpers';

export const OutlinePElement = ({ children, className }) => (
  <p className={`px-8 ${className}`} style={{ outline: '1px solid' }}>
    {children}
  </p>
);

export const QRStatic = ({ name, format = null }) => {
  const value =
    quarterlyReportSignal.value?.quarterlyReport?.data?.static?.[name]?.value;
  if (!value) {
    return '-';
  }
  if (format === 'money') {
    return <>{formatMoney(value)}</>;
  }
  if (format === 'percent') {
    return <>{(value * 100).toFixed(2)}%</>;
  }
  if (format === 'date') {
    return <>{DateTime.fromISO(value).toFormat('DD')}</>;
  }
  return <>{value}</>;
};

export const ContractedEntityTable = () => (
  <Table borderless>
    <thead>
      <tr>
        <td>
          <b>Contracted Entity Name</b>
        </td>
        <td>
          <b>Budgeted Amount</b>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <QRStatic name="contractedEntityName" />
        </td>
        <td>
          <QRStatic format="money" name="budgetedAmount" />
        </td>
      </tr>
      <tr>
        <td>
          <b>Total</b>
        </td>
        <td>
          <QRStatic format="money" name="budgetedAmountTotal" />
        </td>
      </tr>
    </tbody>
  </Table>
);

export const OtherRequiredInfoTable = () => (
  <Table borderless>
    <thead>
      <tr>
        <td></td>
        <td>
          <b>Cumulative as of Prior Quarter End</b>
        </td>
        <td>
          <b>Adjustment</b>
        </td>
        <td>
          <b>Current Quarter</b>
        </td>
        <td>
          <b>Cumulative as of Current Quarter</b>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <b>Program Income</b>
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoProgramIncomeCumulativeAsOfPriorQuarterEnd"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoProgramIncomeAdjustment"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoProgramIncomeCurrentQuarter"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoProgramIncomeCumulativeAsOfCurrentQuarter"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b>CAP Charge-offs</b>
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoCAPChargeOffsCumulativeAsOfPriorQuarterEnd"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoCAPChargeOffsAdjustment"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoCAPChargeOffsCurrentQuarter"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="otherRequiredInfoCAPChargeOffsCumulativeAsOfCurrentQuarter"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={5} />
      </tr>
      <tr>
        <td>Comments</td>
        <td style={{ minHeight: '300px' }}>
          <QRStatic name="additionalInformationComments" />
        </td>
      </tr>
    </tbody>
  </Table>
);

export const FOTTable = () => (
  <Table borderless>
    <thead>
      <tr>
        <td></td>
        <td>
          <b>Cumulative as of Prior Quarter End</b>
        </td>
        <td>
          <b>Adjustment</b>
        </td>
        <td>
          <b>Current Quarter</b>
        </td>
        <td>
          <b>Cumulative as of Current Quarter</b>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <b>Direct Administrative Costs</b>
        </td>
        <td>
          <QRStatic
            format="money"
            name="directAdministrativeCostsCumulativeAsOfPriorQuarterEnd"
          />
        </td>
        <td>
          <QRStatic format="money" name="directAdministrativeCostsAdjustment" />
        </td>
        <td>
          <QRStatic
            format="money"
            name="directAdministrativeCostsCurrentQuarter"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="directAdministrativeCostsCumulativeAsOfCurrentQuarter"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b>Indirect Administrative Costs</b>
        </td>
        <td>
          <QRStatic
            format="money"
            name="indirectAdministrativeCostsCumulativeAsOfPriorQuarterEnd"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="indirectAdministrativeCostsAdjustment"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="indirectAdministrativeCostsCurrentQuarter"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="indirectAdministrativeCostsCumulativeAsOfCurrentQuarter"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b>Total</b>
        </td>
        <td>
          <QRStatic
            format="money"
            name="totalAdministrativeCostsCumulativeAsOfPriorQuarterEnd"
          />
        </td>
        <td>
          <QRStatic format="money" name="totalAdministrativeCostsAdjustment" />
        </td>
        <td>
          <QRStatic
            format="money"
            name="totalAdministrativeCostsCurrentQuarter"
          />
        </td>
        <td>
          <QRStatic
            format="money"
            name="totalAdministrativeCostsCumulativeAsOfCurrentQuarter"
          />
        </td>
      </tr>
      <tr className="border-top">
        <td colSpan={5}></td>
      </tr>
      {/* May still need this for LA */}
      {/* <tr>
        <td colSpan={4}>Administrative Costs Threshold</td>
        <td>
          <QRStatic format="money" name="administrativeCostsThreshold" />
        </td>
      </tr> */}
      <tr>
        <td colSpan={4}>
          Administrative Costs, as a percentage of cumulative funds disbursed to
          Participating Jurisdiction
        </td>
        <td>
          <QRStatic
            format="percent"
            name="administrativeCostsAsAPercentageOfCumulativeFundsDisbursedToParticipatingJurisdiction"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={4}>
          Administrative Costs, as a percentage of cumulative program allocated
          funds EOT as of current quarter
        </td>
        <td>
          <QRStatic
            format="percent"
            name="administrativeCostsAsAPercentageOfCumulativeProgramAllocatedFundsEOTAsOfCurrentQuarter"
          />
        </td>
      </tr>
    </tbody>
  </Table>
);

export const AllocationTable = () => (
  <Table borderless>
    <tbody>
      <tr>
        <td style={{ width: '50%' }}>
          <b>Total Potential Allocation</b>
        </td>
        <td>
          <OutlinePElement className="text-right">
            <QRStatic format="money" name="totalPotentialAllocation" />
          </OutlinePElement>
        </td>
      </tr>
      <tr>
        <td style={{ width: '50%' }}>
          <b>Cumulative Funds Disbursed to Participating Jurisdiction</b>
        </td>
        <td>
          <OutlinePElement className="text-right">
            <QRStatic
              format="money"
              name="cumulativeFundsDisbursedToParticipatingJurisdiction"
            />
          </OutlinePElement>
        </td>
      </tr>
    </tbody>
  </Table>
);

export const SubmissionTable = () => (
  <Table borderless>
    <thead>
      <tr>
        <td></td>
        <td>
          <b>Start Date</b>
        </td>
        <td>
          <b>End Date</b>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <b>Reporting Period</b>
        </td>
        <td>
          <OutlinePElement>
            <QRStatic name="startDate" format="date" />
          </OutlinePElement>
        </td>
        <td>
          <OutlinePElement>
            <QRStatic name="endDate" format="date" />
          </OutlinePElement>
        </td>
      </tr>
      <tr>
        <td>
          <b>Participating Jurisdiction</b>
        </td>
        <td>
          <OutlinePElement>
            <QRStatic name="participatingJurisdiction" />
          </OutlinePElement>
        </td>
      </tr>
    </tbody>
  </Table>
);

const MultiColorTd = ({ uploadedValue, caatValue, bold = false }) => {
  const reportingPeriod = quarterlyReportDeadlines.find(qrd => qrd.id === quarterlyReportSignal.value.quarterlyReportId);

  return (
      <td>
        <p
          hidden={!findMatchingStaticQuarterlyReport(reportingPeriod, reportingSignals.value.selectedPlatform)}
          className={`mb-0 ${bold ? 'font-weight-bold' : ''}`}
        >
          {uploadedValue}
          <span className="text-dark">/</span>
        </p>
        <p className={`text-info mb-0 ${bold ? 'font-weight-bold' : ''}`}>
          {caatValue}
        </p>
      </td>
    );
}


export const ProgramInformationTable = () => (
  <Table responsive>
    <thead>
      <tr>
        <th colSpan="4">Program Information</th>
        <th colSpan="5">Program Allocated Funds EOT</th>
        <th colSpan="4">Recycled Funds EOT</th>
      </tr>
      <tr>
        <th>Approved Program</th>
        <th>Program Type</th>
        <th>Program Administrator</th>
        <th>Program Allocation</th>

        <th>Cumulative as of Prior Quarter End</th>
        <th>Adjustment</th>
        <th>Current Quarter</th>
        <th>Cumulative as of Current Quarter</th>

        <th>Cumulative as of Prior Quarter End</th>
        <th>Adjustment</th>
        <th>Current Quarter</th>
        <th>Cumulative as of Current Quarter</th>
      </tr>
    </thead>
    <tbody>
      {quarterlyReportSignal.value?.quarterlyReport?.data?.programs
        .filter((program) => !!program.programType.value)
        .map((program, idx) => (
          <tr key={`${idx.toString()}_program`}>
            <td>{program.approvedProgram.value}</td>
            <td>{program.programType.value}</td>
            <td>{program.programAdministrator?.value}</td>
            <MultiColorTd
              uploadedValue={formatMoney(program.programAllocation?.value)}
              caatValue={formatMoney(program.programAllocation?.caat)}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programAllocatedFundsEOTCumulativeAsOfPriorQuarterEnd
                  ?.value
              )}
              caatValue={formatMoney(
                program.programAllocatedFundsEOTCumulativeAsOfPriorQuarterEnd
                  ?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programAllocatedFundsEOTAdjustment?.value
              )}
              caatValue={formatMoney(
                program.programAllocatedFundsEOTAdjustment?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programAllocatedFundsEOTCurrentQuarter?.value
              )}
              caatValue={formatMoney(
                program.programAllocatedFundsEOTCurrentQuarter?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programAllocatedFundsEOTCumulativeAsOfCurrentQuarter
                  ?.value
              )}
              caatValue={formatMoney(
                program.programAllocatedFundsEOTCumulativeAsOfCurrentQuarter
                  ?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programRecycledFundsEOTCumulativeAsOfPriorQuarter?.value
              )}
              caatValue={formatMoney(
                program.programRecycledFundsEOTCumulativeAsOfPriorQuarter?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programRecycledFundsEOTAdjustment?.value
              )}
              caatValue={formatMoney(
                program.programRecycledFundsEOTAdjustment?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programRecycledFundsCurrentQuarter?.value
              )}
              caatValue={formatMoney(
                program.programRecycledFundsCurrentQuarter?.caat
              )}
            />
            <MultiColorTd
              uploadedValue={formatMoney(
                program.programRecycledFundsCumulativeAsOfCurrentQuarter?.value
              )}
              caatValue={formatMoney(
                program.programRecycledFundsCumulativeAsOfCurrentQuarter?.caat
              )}
            />
          </tr>
        ))}
      <tr>
        <td colSpan={3}>
          <b>Total:</b>
        </td>
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocation?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocation?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTCumulativeAsOfPriorQuarterEnd
              ?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTCumulativeAsOfPriorQuarterEnd?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTAdjustment?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTAdjustment?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTCurrentQuarter?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTCurrentQuarter?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTCumulativeAsOfCurrentQuarter?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramAllocatedFundsEOTCumulativeAsOfCurrentQuarter?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsEOTCumulativeAsOfPriorQuarter?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsEOTCumulativeAsOfPriorQuarter?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsEOTAdjustment?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsEOTAdjustment?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsCurrentQuarter?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsCurrentQuarter?.caat
          )}
        />
        <MultiColorTd
          bold
          uploadedValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsCumulativeAsOfCurrentQuarter?.value
          )}
          caatValue={formatMoney(
            quarterlyReportSignal.value?.quarterlyReport?.data?.static
              ?.totalProgramRecycledFundsCumulativeAsOfCurrentQuarter?.caat
          )}
        />
      </tr>
    </tbody>
  </Table>
);
