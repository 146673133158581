import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { exitAndReset, resetFormFlow } from '../EdoAddProgram.helpers';

export const SuccessPage = () => {
  const history = useHistory();

  return (
    <Container>
      <Row className="mt-64">
        <Col sm={2}>
          <FontAwesomeIcon className="h1" icon={faThumbsUp} />
        </Col>
        <Col>
          <p>You&apos;ve successfully created a program!</p>
          <p>
            If you need to create another program, selected the option
            below, otherwise feel free to exit.
          </p>
        </Col>
      </Row>
      <Row className="mt-64 mb-32 justify-content-center">
        <Col className="d-flex justify-content-center">
          <Button className="rounded" onClick={resetFormFlow}>Add Another Program</Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <Button className="rounded" variant="outline-secondary" onClick={() => exitAndReset(history)}>Exit</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessPage;
