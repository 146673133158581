import {
  Alert,
  Button,
  Col,
  Modal,
  ProgressBar,
  Row,
  Table,
} from 'react-bootstrap';
import submitApplicationSignal, {
  applicationDraftSignal,
} from 'signals/SubmitApplication.signal';
import {
  handleAttachFileForOcr,
  handleChangeToDigitalForm,
  handleChangeToDocumentUpload,
  handleDownloadDocument,
  nextStep,
  prevStep,
  toggleApplicationFormTypeWarningModal,
} from '../SubmitApplications.helpers';
import OCRRender from 'components/global/OCR/OCRRender';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadIcon, warningIcon } from '../../Constant/Icons';
import { isFileTypeValidForPreview } from 'utils/checkFileTypeForPreview';
import FilePreviewButton from '../../utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { ocrSignal } from '../../OCR/ocr.signal';
import { onTestMode } from '../../../../libs/functions/global.functions';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import $appSettings from 'signals/AppSettings.signal';

const ConfirmApplicationFormVersionModal = () => {
  const { isUsingOcr, applicationFormWarningModalVisible } =
    submitApplicationSignal.value;

    return (
    <ModalWrapper
      show={applicationFormWarningModalVisible}
      centered
      onHide={toggleApplicationFormTypeWarningModal}
    >
      <Modal.Header className="justify-content-end">
        <CloseModalButton
          onCloseModal={toggleApplicationFormTypeWarningModal}
        />
      </Modal.Header>
      <Modal.Body>
        <p>
          Your progress for the application form will be lost. Are you sure you
          want change to{' '}
          {isUsingOcr ? 'a digital form' : 'an uploaded document'}?
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="outline-secondary"
          className="mr-16"
          onClick={toggleApplicationFormTypeWarningModal}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (isUsingOcr) {
              handleChangeToDigitalForm();
            } else {
              handleChangeToDocumentUpload();
            }
          }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export const SubmitApplicationsOCR = () => {
  const {
    fundingProgram,
    ocrFileIsProcessing,
    ocrModalVisible,
    targetDocumentName,
    ocrFile,
    ocrFileSignedUrl,
    ocrFileType,
    ocrFileUuid,
    couldNotProcessOcrAlertVisible,
    isUsingOcr,
  } = submitApplicationSignal.value;
  const { selectedDraft } = applicationDraftSignal.value;
  const { DOCUMENT_TYPES } = $appSettings.value.constants;
  const { supportingDocuments } = fundingProgram;
  const applicationTemplates = supportingDocuments.filter(
    (sd) => sd.documentType === DOCUMENT_TYPES.applicationTemplate
  );

  const { parsedOcrData } = ocrSignal.value;

  return (
    <>
      <ConfirmApplicationFormVersionModal />
      <Row>
        <Col>
          <h4>Application Form</h4>
          <p>
            Choose to upload a completed document or fill out the form
            digitally.
          </p>
        </Col>
      </Row>
      <Row className="mb-32">
        <Col>
          <Button
            className="w-100"
            disabled={isUsingOcr}
            variant={isUsingOcr ? 'secondary' : 'outline-secondary'}
            onClick={toggleApplicationFormTypeWarningModal}
          >
            Upload a Document
          </Button>
        </Col>
        <Col>
          <Button
            className="w-100"
            disabled={!isUsingOcr}
            variant={isUsingOcr ? 'outline-secondary' : 'secondary'}
            onClick={toggleApplicationFormTypeWarningModal}
          >
            Fill out a Digital Form
          </Button>
        </Col>
      </Row>
      {onTestMode() && (
        <Alert variant="info" hidden={!isUsingOcr}>
          <Alert.Heading>OCR is undergoing constant improvement</Alert.Heading>
          <p>
            The more applications are processed, the better the model will be at
            extracting data from the applications. As of right now, checkboxes
            require fine tuning.
          </p>
        </Alert>
      )}
      <Row>
        <Col>
          <Table hidden={applicationTemplates.length <= 0}>
            <thead>
              <tr className="font-weight-bold">
                <th style={{ width: isUsingOcr ? '40%' : '80%' }}>Document</th>
                <th hidden={!isUsingOcr} style={{ width: '20%' }}>
                  Attach
                </th>
                <th hidden={!isUsingOcr} style={{ width: '20%' }}>
                  File
                </th>
                <th style={{ width: '20%' }}>Download</th>
              </tr>
            </thead>
            {applicationTemplates.map((applicationTemplates, index) => (
              <tbody key={index}>
                <tr>
                  <td>{applicationTemplates.name}</td>
                  <td hidden={!isUsingOcr}>
                    <Button
                      disabled={ocrFileIsProcessing}
                      size="sm"
                      className="rounded"
                      variant="outline-primary"
                      onClick={() =>
                        submitApplicationSignal.update({
                          ocrModalVisible: true,
                          targetDocumentName: applicationTemplates.id,
                        })
                      }
                    >
                      {ocrFile || ocrFileSignedUrl
                        ? 'Replace'
                        : 'Attach and Process'}
                    </Button>
                  </td>
                  <td hidden={!isUsingOcr}>
                    {ocrFile || ocrFileSignedUrl ? (
                      <>
                        <FilePreviewButton
                          render={(submitting, f) => (
                            <Button
                              hidden={
                                !isFileTypeValidForPreview(ocrFile?.type) &&
                                !isFileTypeValidForPreview(ocrFileType)
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-dark"
                              onClick={() =>
                                f(
                                  ocrFileSignedUrl
                                    ? {
                                        filePath: ocrFileSignedUrl,
                                        parentId: selectedDraft.id,
                                        uuid: ocrFileUuid,
                                        fileType: ocrFileType,
                                      }
                                    : ocrFile,
                                  'applicationDocuments',
                                  ocrFileType
                                )
                              }
                            >
                              Preview
                            </Button>
                          )}
                        />
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      variant="outline-info"
                      className="rounded"
                      onClick={() =>
                        handleDownloadDocument(applicationTemplates.id)
                      }
                    >
                      Download Template <FontAwesomeIcon icon={downloadIcon} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </Col>
      </Row>
      <Row hidden={!ocrFileIsProcessing}>
        <Col>
          <small>Please wait while your application is processed.</small>
          <ProgressBar animated now={100} />
        </Col>
      </Row>
      {Object.keys(parsedOcrData).length > 0 && (
        <Row>
          <Col>
            <Alert hidden={!couldNotProcessOcrAlertVisible} variant="warning">
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon
                  className="mr-24"
                  size="2x"
                  icon={warningIcon}
                />
                <p className="pr-16 mb-0">
                  We could not process your form. You can fill out the fields
                  below, or try to upload a different document. If you choose to
                  upload a new document, please make sure that your document
                  matches the template provided.
                </p>
                <Button
                  variant="outline-dark"
                  className="rounded"
                  size="sm"
                  onClick={() =>
                    submitApplicationSignal.update({
                      couldNotProcessOcrAlertVisible: false,
                    })
                  }
                >
                  Dismiss
                </Button>
              </div>
            </Alert>
            <OCRRender />
          </Col>
        </Row>
      )}
      <Row className="my-64">
        <Col className="text-center">
          <Button className="rounded" onClick={prevStep}>
            Prev Page
          </Button>
        </Col>
        <Col className="text-center">
          <Button className="rounded" onClick={nextStep}>
            Next Page
          </Button>
        </Col>
      </Row>
      <ModalWrapper
        centered
        size="xl"
        show={ocrModalVisible}
        onHide={() =>
          submitApplicationSignal.update({ ocrModalVisible: false })
        }
      >
        <Modal.Header>
          <Modal.Title>OCR Processing</Modal.Title>
          <CloseModalButton
            onCloseModal={() =>
              submitApplicationSignal.update({ ocrModalVisible: false })
            }
          />
        </Modal.Header>
        <Modal.Body>
          <p>
            We will utilize OCR (optical character recognition) to process and
            render your document. Once it has been processed, you can view and
            edit it below.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mr-8 rounded"
            variant="outline-secondary"
            onClick={() =>
              submitApplicationSignal.update({ ocrModalVisible: false })
            }
          >
            Cancel
          </Button>
          <input
            type="file"
            hidden
            name={targetDocumentName}
            id={`file-uploader-hidden-input-${targetDocumentName}`}
            onChange={(e) => {
              handleAttachFileForOcr(e.target.name, e.target.files[0]);
              submitApplicationSignal.update({
                ocrModalVisible: false,
              });
            }}
          />
          <Button
            className="rounded"
            variant="outline-primary"
            onClick={() =>
              document
                .getElementById(
                  `file-uploader-hidden-input-${targetDocumentName}`
                )
                .click()
            }
          >
            Upload
          </Button>
        </Modal.Footer>
      </ModalWrapper>
    </>
  );
};

export default SubmitApplicationsOCR;
