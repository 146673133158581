import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  businessSettingsDirtySignal,
  businessSettingsErrorsSignal,
  businessSettingsIndustryOptionsSignal,
  businessSettingsSignal,
} from 'signals/BusinessSettings.signal';
import {
  handleAddAnotherIndustry,
  handleBusinessFormChange,
  handleNewIndustryChange,
  removeIndustryTag,
} from '../BusinessSettings.helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { INDUSTRY_LIST } from 'components/global/Constant/constants';
import BusinessUnsavedChangesAlert from './BusinessUnsavedChangesAlert';

const BusinessAssistance = () => {
  const businessForm = businessSettingsSignal.value;
  const businessFormErrors = businessSettingsErrorsSignal.value;
  const businessFormDirty = businessSettingsDirtySignal.value;

  return (
    <>
      <Row className="align-items-center px-16">
        <h4 className="mb-0">Assistance</h4>
        <BusinessUnsavedChangesAlert />
      </Row>
      <hr />
      <Row>
        <Col sm={6} lg={3}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="assistance.seekingMin"
            >
              Seeking Min
            </Form.Label>
            <Form.Control
              type="number"
              formNoValidate
              className="form-control"
              id="assistance.seekingMin"
              name="assistance.seekingMin"
              isInvalid={
                businessFormErrors['assistance.seekingMin'] &&
                businessFormDirty['assistance.seekingMin']
              }
              value={businessForm['assistance.seekingMin']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} lg={3}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="assistance.seekingMax"
            >
              Seeking Max
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              formNoValidate
              id="assistance.seekingMax"
              name="assistance.seekingMax"
              isInvalid={
                businessFormErrors['assistance.seekingMax'] &&
                businessFormDirty['assistance.seekingMax']
              }
              value={businessForm['assistance.seekingMax']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Seeking max must be greater than seeking min.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} lg={3}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="assistance.downPayment"
            >
              Down Payment
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              formNoValidate
              id="assistance.downPayment"
              name="assistance.downPayment"
              isInvalid={
                businessFormErrors['assistance.downPayment'] &&
                businessFormDirty['assistance.downPayment']
              }
              value={businessForm['assistance.downPayment']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="assistance.desiredAssistanceTypes"
            >
              What assistance are you looking for?
            </Form.Label>
            <br />
            {/*<small*/}
            {/*  hidden={!businessFormErrors.assistance?.desiredAssistanceTypes}*/}
            {/*  className="text-danger"*/}
            {/*>*/}
            {/*  Please select at least one.*/}
            {/*</small>*/}
            <Form.Check
              checked={businessForm[
                'assistance.desiredAssistanceTypes'
              ]?.includes('equity')}
              label="Equity"
              name="assistance.desiredAssistanceTypes_equity"
              type="checkbox"
              id="assistance.desiredAssistanceTypes_equity"
              value="equity"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              checked={businessForm[
                'assistance.desiredAssistanceTypes'
              ]?.includes('loan')}
              formNoValidate
              label="Loan"
              name="assistance.desiredAssistanceTypes_loan"
              type="checkbox"
              id="assistance.desiredAssistanceTypes_loan"
              value="loan"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              checked={businessForm[
                'assistance.desiredAssistanceTypes'
              ]?.includes('grant')}
              formNoValidate
              label="Grant"
              name="assistance.desiredAssistanceTypes_grant"
              type="checkbox"
              id="assistance.desiredAssistanceTypes_grant"
              value="grant"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              checked={businessForm[
                'assistance.desiredAssistanceTypes'
              ]?.includes('technicalAssistance')}
              formNoValidate
              label="Technical Assistance"
              name="assistance.desiredAssistanceTypes_technicalAssistance"
              type="checkbox"
              id="assistance.desiredAssistanceTypes_technicalAssistance"
              value="technicalAssistance"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="assistance.assistanceUses"
            >
              How are you going to use the assistance?
            </Form.Label>
            <br />
            {/*<small*/}
            {/*  hidden={!businessFormErrors.assistance?.assistanceUses}*/}
            {/*  className="text-danger"*/}
            {/*>*/}
            {/*  Please select at least one.*/}
            {/*</small>*/}
            <Form.Check
              checked={businessForm['assistance.assistanceUses']?.includes(
                'WORKING_CAPITAL'
              )}
              formNoValidate
              label="Working Capital"
              name="assistance.assistanceUses_workingCapital"
              type="checkbox"
              id="assistance.assistanceUses_workingCapital"
              value="WORKING_CAPITAL"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              checked={businessForm['assistance.assistanceUses']?.includes(
                'REAL_ESTATE'
              )}
              formNoValidate
              label="Real Estate"
              name="assistance.assistanceUses_realEstate"
              type="checkbox"
              id="assistance.assistanceUses_realEstate"
              value="REAL_ESTATE"
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Check
              checked={businessForm['assistance.assistanceUses']?.includes(
                'EQUIPMENT'
              )}
              formNoValidate
              label="Equipment"
              name="assistance.assistanceUses_equipment"
              type="checkbox"
              id="assistance.assistanceUses_equipment"
              value="EQUIPMENT"
              onChange={(e) => handleBusinessFormChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="font-weight-bold mb-0">Industry</p>
          {/*<small*/}
          {/*  hidden={!businessFormErrors.assistance?.industrySelection}*/}
          {/*  className="text-danger"*/}
          {/*>*/}
          {/*  Please select at least one.*/}
          {/*</small>*/}
          <Row>
            {businessSettingsIndustryOptionsSignal.value.industries.map(
              (industry) => (
                <Button
                  key={industry}
                  className={`rounded p-0 m-8 d-flex flex-row ${
                    (
                      businessForm['assistance.industrySelection'] || []
                    ).includes(industry)
                      ? 'btn-info'
                      : 'btn-outline-dark text-dark bg-white'
                  }`}
                >
                  <div
                    className="h-100 py-8 pl-8"
                    onClick={() =>
                      handleBusinessFormChange({
                        target: {
                          name: 'assistance.industrySelection',
                          value: industry,
                        },
                      })
                    }
                    style={{
                      paddingRight: INDUSTRY_LIST.includes(industry) ? 8 : 4,
                    }}
                  >
                    <p className="mb-0">{industry}</p>
                  </div>
                  {!INDUSTRY_LIST.includes(industry) && (
                    <div
                      className="ml-8 h-100 py-8 pr-8"
                      style={{ paddingLeft: 4 }}
                      onClick={() => removeIndustryTag(industry)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                </Button>
              )
            )}
          </Row>
        </Col>
        <Col md={6}>
          <Form.Label className="font-weight-bold" htmlFor="newIndustry">
            New Industry Tag
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              className="form-control"
              id="newIndustry"
              name="newIndustry"
              value={businessSettingsIndustryOptionsSignal.value.value}
              onChange={(e) => handleNewIndustryChange(e)}
            />
            <Button
              variant="outline-primary"
              className="rounded"
              onClick={() =>
                handleAddAnotherIndustry(
                  businessSettingsIndustryOptionsSignal.value.value
                )
              }
            >
              Add Tag
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

export default BusinessAssistance;
