import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SettingsButton = ({ route, title, img, description, activeAmount }) => (
  <Card style={{ height: 152 }} className="text-center mb-16">
    <Link style={{ textDecoration: 'none' }} className="text-secondary pt-16" to={route}>
      <FontAwesomeIcon icon={img} style={{ fontSize: 40 }} />
      <p>{title}</p>
      <p>{description} {activeAmount}</p>
    </Link>
  </Card>
);

export default SettingsButton;
