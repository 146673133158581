const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return;

  let formattedPhoneNumber = phoneNumber;
  formattedPhoneNumber = formattedPhoneNumber.replace('-', '');
  formattedPhoneNumber = formattedPhoneNumber.replace('(', '');
  formattedPhoneNumber = formattedPhoneNumber.replace(')', '');
  formattedPhoneNumber = formattedPhoneNumber.replace(' ', '');

  if (!formattedPhoneNumber.includes('+1')) {
    formattedPhoneNumber = `+1${formattedPhoneNumber}`;
  }

  return formattedPhoneNumber.trim();
}

export default formatPhoneNumber;
