import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const blockPush = (blocks, pathname, label) => [
  ...blocks.concat({ label, pathname }),
];

const SEGMENT_LABELING = {
  portfolio: 'Applications',
  performance: 'Program Performance',
  programs: 'Programs',
  lenders: 'Lenders',
  vc: 'Venture Capitalists',
  business: 'Businesses',
  'search-businesses': 'Search Businesses',
  'program-requests': 'Program Requests',
  'portfolio-companies': 'Portfolio Companies',
  funds: 'Funds',
  'portfolio-company-request': 'Portfolio Company Request',
  'technical-assistance': 'Technical Assistance',
  'capital-call': 'Capital Call',
  tooltips: 'Tooltips',
  'create-capital-call': 'Create Capital Call',
  'capital-call-portion': 'Capital Call',
  reporting: 'Reporting',
  new: 'Create New',
  'credit-program': 'Credit Program',
  'equity-program': 'Equity Program',
  'search-results': 'Search Results',
  'dashboard': 'Dashboard',
  applications: 'Applications',
  transactions: 'Transactions',
  // 'search-all': 'Search Results'
};

const LOOK_AHEAD_SEGMENT_LABELING = {
  disbursement: (id) => `Disbursement ${id}`,
  reimbursement: (id) => `Reimbursement ${id}`,
  portfolio: (id) => `Application ${id}`,
  application: (id) => `Application ${id}`,
  applications: (id) => `Application ${id}`,
  lenders: (id) => `Lender ${id}`,
  vc: (id) => `Venture Capitalist ${id}`,
  performance: (id) => `Program ${id} Performance`,
  'program-membership': (id, pathname) => {
    if (pathname.startsWith('/edo/vc')) {
      return `Funds in Program ${id}`;
    } else {
      return `Businesses enrolled in Program Membership ${id}`;
    }
  },
  fund: (id) => `Fund ${id} Businesses`,
  business: (id) => `Business ${id}`,
  businesses: (id) => `Business ${id}`,
  programs: (id) => `Program ${id}`,
  'program-requests': (id) => `Program Request ${id}`,
  'business-search-results': (id) => `Business ${id}`,
  'portfolio-companies': (id) => `Business ${id}`,
  funds: (id) => `Fund ${id}`,
  'portfolio-company-request': (id) => `Portfolio Company Request ${id}`,
  'all-programs': (id) => `Program ${id}`,
  'create-capital-call': () => 'Create Capital Call',
  'capital-calls': (id) => `Capital Call ${id}`,
  'capital-call-portion': (id) => `Capital Call ${id}`,
  'credit-program': (id) => `Credit Program ${id}`,
  'equity-program': (id) => `Equity Program ${id}`,
  transactions: (id) => `Transaction ${id}`,
};

const getBreadCrumbs = (pathname) => {
  const pathnameSplit = pathname.split('/');
  const segments = [...pathnameSplit].reverse();
  
  return segments
    .reduce((acc, segment, index) => {
      if (segment === '') {
        return acc;
      }

      const isPortalName = index === segments.length - 2;
      const isLeadingSection = index === segments.length - 3;

      if (
        (segment === 'edo' ||
          segment === 'vc' ||
          segment === 'lender' ||
          segment === 'business') &&
        isPortalName
      ) {
        return acc;
      }

      if (
        isLeadingSection &&
        Object.keys(SEGMENT_LABELING).some((v) => v === segment)
      ) {
        if (pathname.startsWith('/business/vc')) {
          return acc;
        }
        return blockPush(
          acc,
          [...segments.slice(index)].reverse().join('/'),
          SEGMENT_LABELING[segment]
        );
      }

      if (segment === 'search-all') {
        const query = localStorage.getItem('query');

        return blockPush(acc, `${[...segments.slice(index)].reverse().join('/')}?query=${query}`, 'Search Results');
      }

      const blockPushCurry = (label) =>
        blockPush(acc, [...segments.slice(index)].reverse().join('/'), label);

      const numericCast = Number(segment);
      if (typeof numericCast === 'number' && numericCast > 0) {
        const nextSegment = segments[index + 1];

        if (
          Object.keys(LOOK_AHEAD_SEGMENT_LABELING).some(
            (v) => v === nextSegment
          )
        ) {
          return blockPushCurry(
            LOOK_AHEAD_SEGMENT_LABELING[nextSegment](numericCast, pathname)
          );
        } else {
          return acc;
        }
      } else if (segment === 'bulk-import') {
        return blockPushCurry(`Bulk Import`);
      } else if (segment === 'business-search-results') {
        return blockPushCurry(`Business Search Results`);
      } else if (segment === 'all-programs') {
        return blockPushCurry('All Programs');
      } else if (segment === 'create-capital-call') {
        return blockPushCurry('Create Capital Call');
      } else if (segment === 'reporting') {
        return blockPushCurry('Reporting');
      } else if (segment === 'new') {
        return blockPushCurry('Create New');
      } else if (segment === 'search') {
        return blockPushCurry('Search');
      } else if (segment === 'search-results') {
        return blockPushCurry('Search Results');
      } else if (segment === 'dashboard') {
        return blockPushCurry('Dashboard');
      } else if (segment === 'search-all') {
        return blockPushCurry('Search Results');
      }

      return acc;
    }, [])
    .map(({ label, pathname }) => ({
      label,
      pathname: pathname.startsWith('/') ? pathname : `/${pathname}`,
    }))
    .reverse();
};

const BreadCrumbWrapper = ({
  children,
  hideBreadCrumbs = false,
  labels = [],
  className = '',
}) => {
  const { pathname } = useLocation();
  const breadCrumbs = getBreadCrumbs(pathname);

  return (
    <div className={`mb-32 ${className}`}>
      <Breadcrumb hidden={hideBreadCrumbs}>
        {breadCrumbs.map((crumb, index) => (
          <BreadcrumbItem
            active={crumb.pathname.split('?')[0] === pathname}
            key={index}
            linkAs={Link}
            linkProps={{ to: crumb.pathname }}
            // onClick={() => removeBreadCrumb(index)}
          >
            {/* use passed in labels or fall back to generic labels */}
            {labels.length > index && !!labels[index] ? labels[index] : crumb.label}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <div>{children}</div>
    </div>
  );
};

export default BreadCrumbWrapper;
