import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadIcon } from 'components/global/Constant/Icons';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import {
  searchProgramsAdditionalFilesToUploadSignal,
  searchProgramsResultsModalStateSignal,
  searchProgramsResultsSelectedProgram,
  searchProgramsSuggestedFilesToUploadSignal,
} from 'components/global/SearchProgramResults/SearchProgramsResults.signal';
import FilePreviewButton from '../../utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import AttachButton from '../../utils/FilesAndDocuments/AttachButton/AttachButton';
import userAccountSignal from 'signals/UserAccount.signal';
import FileDropZoneWrapper from 'components/global/FileDropZone';
import DragAndDrop from 'components/global/DragAndDrop/DragAndDrop';
import { APPLICATION_FORMATS } from '../../Constant/fileFormats';
import { isFileTypeValidForPreview } from 'utils/checkFileTypeForPreview';
import {
  handleAttachAdditionalFile,
  handleAttachSuggestedFile,
  handleCancelAdditionalFile,
  handleCancelSuggestedFile,
  handleChangeAdditionalFileName,
  handleDownloadDocument,
  submitRequestApproval,
  toggleProgramInfoModal,
} from '../FundingProgramDetail.helpers';
import $appSettings from 'signals/AppSettings.signal';

const ProgramRequestUploadDocument = () => {
  const selectedProgram = searchProgramsResultsSelectedProgram.value;
  const filesMap = searchProgramsSuggestedFilesToUploadSignal.value;
  const additionalFilesList = searchProgramsAdditionalFilesToUploadSignal.value;
  const { PORTAL_TYPES, DOCUMENT_TYPES } = $appSettings.value.constants;
  const { supportingDocuments } = selectedProgram;
  const applicationForms = supportingDocuments.filter(
    (mpd) => mpd.documentType === DOCUMENT_TYPES.programRequestTemplate
  );
  const suggestedDocuments = supportingDocuments.filter(
    (mpd) => mpd.documentType === DOCUMENT_TYPES.programRequestSuggested
  );

  return (
    <Modal.Body className="pt-0">
      <h5>
        {selectedProgram.name} - {selectedProgram.referenceProgram.programName}
      </h5>
      <hr />
      <Row>
        <Col>
          <p>
            If you would like to be a part of <b>{selectedProgram.name}</b>,
            please download, fill out and attach its application form(s).
          </p>
          <h5>Program Request Documents</h5>
          <hr />
          <Table hidden={applicationForms.length <= 0}>
            <thead>
              <tr className="font-weight-bold">
                <th>Document</th>
                <th>Attach</th>
                <th>File</th>
                <th>Download</th>
              </tr>
            </thead>
            {applicationForms.map((fundingProgramDocument) => (
              <tbody key={fundingProgramDocument.id}>
                <tr>
                  <td>{fundingProgramDocument.name}</td>
                  <td>
                    <AttachButton
                      id={`file-uploader-hidden-input-${fundingProgramDocument.id}`}
                      name={fundingProgramDocument.id}
                      text={
                        filesMap[fundingProgramDocument.id]
                          ? 'Replace'
                          : 'Attach'
                      }
                      handleAttachFile={(file, e) =>
                        handleAttachSuggestedFile(e.target.name, file)
                      }
                    />
                  </td>
                  <td>
                    {filesMap[fundingProgramDocument.id] ? (
                      <>
                        <FilePreviewButton
                          render={(submitting, f) => (
                            <Button
                              hidden={
                                !isFileTypeValidForPreview(
                                  filesMap[fundingProgramDocument.id].type
                                )
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-info"
                              onClick={() =>
                                f(filesMap[fundingProgramDocument.id], '')
                              }
                            >
                              Preview
                            </Button>
                          )}
                        />
                        <Button
                          size="sm"
                          className="rounded ml-2"
                          variant="outline-secondary"
                          onClick={() =>
                            handleCancelSuggestedFile(fundingProgramDocument)
                          }
                        >
                          Remove
                        </Button>
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td hidden={!fundingProgramDocument.filePath}>
                    <Button
                      size="sm"
                      variant="outline-info"
                      className="rounded"
                      onClick={() =>
                        handleDownloadDocument(fundingProgramDocument.id)
                      }
                    >
                      Download Template <FontAwesomeIcon icon={downloadIcon} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
          <hr />
          <div hidden={suggestedDocuments.length <= 0}>
            <h5>Suggested Documents</h5>
            <p>
              The EDO recommends these documents be uploaded to expedite the
              process.
            </p>
            <Table>
              <thead>
                <tr className="font-weight-bold">
                  <th>Document</th>
                  <th>Attach</th>
                  <th>File</th>
                  <th>Download</th>
                </tr>
              </thead>
              {suggestedDocuments.map((fundingProgramDocument, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{fundingProgramDocument.name}</td>
                    <td>
                      <AttachButton
                        id={`file-uploader-hidden-input-${fundingProgramDocument.name}`}
                        name={fundingProgramDocument.id}
                        text={
                          filesMap[fundingProgramDocument.id]
                            ? 'Replace'
                            : 'Attach'
                        }
                        handleAttachFile={(file, e) => {
                          handleAttachSuggestedFile(e.target.name, file);
                        }}
                      />
                    </td>
                    <td>
                      {filesMap[fundingProgramDocument.id] ? (
                        <>
                          <FilePreviewButton
                            render={(submitting, f) => (
                              <Button
                                hidden={
                                  !isFileTypeValidForPreview(
                                    filesMap[fundingProgramDocument.id].type
                                  )
                                }
                                size="sm"
                                className="rounded"
                                variant="outline-info"
                                onClick={() =>
                                  f(filesMap[fundingProgramDocument.id], '')
                                }
                              >
                                Preview
                              </Button>
                            )}
                          />
                          <Button
                            size="sm"
                            className="rounded ml-2"
                            variant="outline-secondary"
                            onClick={() =>
                              handleCancelSuggestedFile(
                                fundingProgramDocument.name
                              )
                            }
                          >
                            Remove
                          </Button>
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      {fundingProgramDocument.filePath ? (
                        <Button
                          size="sm"
                          variant="outline-info"
                          className="rounded"
                          onClick={() =>
                            handleDownloadDocument(fundingProgramDocument.id)
                          }
                        >
                          Download Template{' '}
                          <FontAwesomeIcon icon={downloadIcon} />
                        </Button>
                      ) : (
                        <p>No template available</p>
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
          <div>
            <h5>Additional documents</h5>
            <p>
              Upload additional documents of your own that support your
              application.
            </p>
            <FileDropZoneWrapper
              formats={APPLICATION_FORMATS}
              numOfUploadsAllowed={20}
              maxSizeMB={100}
              onUpload={(files) =>
                files.forEach((file) => handleAttachAdditionalFile(file))
              }
            >
              <DragAndDrop
                files={additionalFilesList}
                uploadProgress={0}
                // onFileCancel={handleFileCancel}
                maxSizeMB={100}
                preview={false}
              />
            </FileDropZoneWrapper>
            <Table>
              <thead>
                <tr className="font-weight-bold">
                  <th>Document</th>
                  <th>Attach</th>
                  <th>File</th>
                </tr>
              </thead>
              {additionalFilesList.map((fileBundle, index) => (
                <tbody key={index}>
                  <tr>
                    <td>
                      <Form.Control
                        value={fileBundle.name}
                        onChange={(e) =>
                          handleChangeAdditionalFileName(e.target.value, index)
                        }
                      />
                    </td>
                    <td>-</td>
                    <td>
                      <>
                        <FilePreviewButton
                          render={(submitting, f) => (
                            <Button
                              hidden={
                                !isFileTypeValidForPreview(fileBundle.file.type)
                              }
                              size="sm"
                              className="rounded"
                              variant="outline-info"
                              onClick={() => f(fileBundle.file, '')}
                            >
                              Preview
                            </Button>
                          )}
                        />
                        <Button
                          size="sm"
                          className="rounded ml-2"
                          variant="outline-secondary"
                          onClick={() => handleCancelAdditionalFile(index)}
                        >
                          Remove
                        </Button>
                      </>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="px-16 justify-content-end">
        {userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.lender ? (
          <Button
            variant="outline-secondary"
            className="rounded mr-8"
            onClick={toggleProgramInfoModal}
          >
            Close
          </Button>
        ) : (
          <Button
            className="rounded mr-8"
            variant="outline-secondary"
            onClick={() =>
              searchProgramsResultsModalStateSignal.update({ step: 1 })
            }
          >
            Back
          </Button>
        )}
        <Button className="rounded" onClick={submitRequestApproval}>
          Submit
        </Button>
      </Row>
    </Modal.Body>
  );
};

export default ProgramRequestUploadDocument;
