import BackButton from 'components/global/BackButton';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import CreateCapitalCall from 'components/global/CreateCapitalCall/CreateCapitalCall';
import createCapitalCallSignal from 'components/global/CreateCapitalCall/createCapitalCall.signal';
import { useHistory } from 'react-router-dom';

const EdoCreateCapitalCall = () => {
  const history = useHistory();
  const { name } = createCapitalCallSignal.value;
  const labels = ['', name, 'Create Capital Call'];

  return (
    <>
      <BackButton onClick={() => history.goBack()} buttonText="Back" />
      <BreadCrumbWrapper labels={labels}>
        <CreateCapitalCall />
      </BreadCrumbWrapper>
    </>
  );
};

export default EdoCreateCapitalCall;
