import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Alert, Button, Modal } from 'react-bootstrap';
import { applicationDraftSignal } from 'signals/SubmitApplication.signal';
import { handleAccessRevoked } from '../SubmitApplications.helpers';

const RemoveAccessModal = () => {
  const { removeAccessModalVisible, removeAccessUser } =
    applicationDraftSignal.value;
  return (
    <ModalWrapper centered show={removeAccessModalVisible}>
      <Modal.Header>Someone else started editing this application</Modal.Header>
      <Modal.Body>
        <Alert>
          The editing privileges for this application have been taken over by
          {removeAccessUser?.firstName} {removeAccessUser?.lastName} -{' '}
          {removeAccessUser?.email}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="rounded"
          onClick={handleAccessRevoked} // switch access to other user
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default RemoveAccessModal;
