import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import CapitalCallPortion from 'components/global/CapitalCallPortionDetail';
import $capitalCallPortion from 'components/global/CapitalCallPortionDetail/capitalCallPortion.signal';
import { useLocation } from 'react-router-dom';

const VCCapitalCallPortion = () => {
  const { pathname } = useLocation();
  const { capitalCallPortion } = $capitalCallPortion.value
  const labels = ['Transactions', `Capital Call ID: ${capitalCallPortion?.id}`];

  return (
    <BreadCrumbWrapper labels={labels} hideBreadCrumbs={pathname.startsWith('/vc/capital-call-portion')}>
      <CapitalCallPortion />
    </BreadCrumbWrapper>
  );
}


export default VCCapitalCallPortion;
