import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { lenderPortfolioSignal } from "signals/LenderPortfolio.signal";
import SimpleTable from "components/global/SimpleTable";
import {
  fetchAndSetApplications,
  handleApplicationsAppliedFiltersChange,
  handleApplicationsFilterChange,
  resetPortfolioFilterInputs
} from "../Applications.helpers";
import dateOptions from "components/global/Constant/dateOptions";
import userAccountSignal from "signals/UserAccount.signal";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { watchlistIcon } from "components/global/Constant/Icons";
import PaginationItems from "components/global/Pagination";
import capitalizeFirst from "utils/capitalizeFirst";
import { getStatusStyles } from "../../../../libs/functions/global.functions";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'createdDate',
    title: 'Date Submitted',
  },
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'programName',
    title: 'Program Name',
    sortable: true,
  },
  {
    key: 'businessName',
    title: 'Business Name',
  },
  {
    key: 'contactName',
    title: 'Contact Name',
  },
  {
    key: 'liquidationProceeds',
    title: 'Loan Ask',
    currency: true,
  },
  {
    key: 'approvedAmount',
    title: 'Approved Amount',
    currency: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const PortfolioTable = ({ currentPage }) => {
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  let { applications, dropdownFilters, bundle, totalPages } =
    lenderPortfolioSignal.value;
  const history = useHistory();

  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-16">
        <SimpleTableFilterDropdown
          filtersState={dropdownFilters}
          onChange={({ name, value }) =>
            handleApplicationsFilterChange(name, value)
          }
          onApply={(filters) => {
            handleApplicationsAppliedFiltersChange(filters);
            fetchAndSetApplications(currentPage);
          }}
          onClearFilterInputs={resetPortfolioFilterInputs}
          filters={[
            {
              type: 'select',
              label: 'Status',
              key: 'status',
              options: Object.entries(APPLICATION_STATUS).map(([, value]) => ({
                label:
                  value === APPLICATION_STATUS.inProgress
                    ? 'Draft'
                    : capitalizeFirst(value.replace(/_/g, ' ')),
                value,
              })),
            },
            {
              type: 'integer',
              label: 'ID',
              key: 'id',
            },
            {
              type: 'select',
              label: 'Program Name',
              key: 'programName',
              options: bundle.fundingPrograms?.map((fundingProgram) => ({
                label: fundingProgram.name,
                value: fundingProgram.id,
              })),
            },
          ]}
        />
        <Link
          className="rounded btn btn-primary"
          to="/lender/portfolio/submit-applications"
        >
          New Application
        </Link>
      </div>
      <SimpleTable
        headers={HEADERS}
        // onClick={(index) => {
        //   const application = applications[index];
        //   showApplicationDetailsModal(
        //     txApplicationToApplicationDetailsModal(
        //       application,
        //       !!application.ventureCapitalFirm
        //     )
        //   );
        // }}
        onSort={() => {
          fetchAndSetApplications(currentPage);
        }}
        rows={applications.map((application) => ({
          status: () => (
            <td className={`${getStatusStyles(application.status).class}`}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0">
                  {application.status === APPLICATION_STATUS.inProgress
                    ? 'Draft'
                    : capitalizeFirst(application.status.replace(/_/g, ' '))}
                </p>
                <FontAwesomeIcon
                  className={
                    application.watchables?.length
                      ? 'text-right ml-16'
                      : 'd-none'
                  }
                  icon={watchlistIcon}
                />
              </div>
            </td>
          ),
          createdDate: new Date(application.createdDate).toLocaleString(
            'en-US',
            dateOptions.date
          ),
          id: application.id,
          programName: application.fundingProgramMembership.fundingProgram.name,
          businessName: application.business?.name,
          contactName: application.business?.contactName,
          askedAmount: application.askedAmount,
          approvedAmount: application.approvedAmount || '-',
          action: ({ key }) => (
            <td key={key} className="text-right">
              <Button
                size="sm"
                className="rounded"
                variant="outline-dark"
                onClick={() =>
                  history.push(
                    `/${userAccountSignal.value.userData.account.portalType.toLowerCase()}/portfolio/${
                      application.id
                    }`
                  )
                }
              >
                Detail
              </Button>
            </td>
          ),
        }))}
      />
      {totalPages && (
        <PaginationItems
          className="justify-content-center mt-24"
          totalPages={totalPages}
        />
      )}
    </>
  );
};

export default PortfolioTable;
