import Signal from "libs/signals";
import { AppSettings } from "../types";

type AppSettingsSignal = {
  appSettings: AppSettings;
  constants: {
    PROVIDER_TYPES: Record<
      string,
      { value: string; annualReportLabel: string }
    >;
    PORTAL_TYPES: {
      edo: string;
      business: string;
      state: string;
      none: string;
      vc: string;
      lender: string;
      executive: string;
    };
    APPLICATION_DOCUMENT_STATUS: {
      notRead: string;
      read: string;
      moreInfoNeeded: string;
    };
    APPLICATION_STATUS: {
      incomplete: string;
      approved: string;
      denied: string;
      pending: string;
      submittedForApproval: string;
      inProgress: string;
      default: string;
      importing: string;
    };
    PROGRAM_REQUEST_STATUS: {
      incomplete: string;
      approved: string;
      denied: string;
      pending: string;
      submittedForApproval: string;
    };
    USER_ROLES: {
      superAdmin: string;
      admin: string;
      owner: string;
      staff: string;
      viewer: string;
    };
    USER_STATUS: {
      active: string;
      inactive: string;
    };
    WATCHABLE_TYPES: {
      application: string;
      programRequest: string;
    };
    PROGRAM_FUND_TYPES: {
      seedCapitalProgram: string;
      ventureCapitalProgram: string;
    };
    APPROVAL_CHAIN_AMOUNT_BOUNDS: {
      min: number,
      max: number,
    };
    DOCUMENT_TYPES: {
      applicationTemplate: string;
      applicationSuggested: string;
      applicationAdditional: string;
      programRequestTemplate: string;
      programRequestSuggested: string;
      programRequestAdditional: string;
    };
    REBALANCING_FREQUENCY_OPTIONS: {
      quarterly: string;
      biannually: string;
      annually: string;
    };
    STANDARD_COMPLIANCE_ITEM_KEYS: string[];
    CAPITAL_ACCESS_COMPLIANCE_KEYS: string[];
    TRANCHE_GRACE_PERIODS: {
      dev: number,
    };
    OCR_CONFIDENCE_THRESHOLD: number;
    TECHNICAL_ASSISTANCE_TYPES: {
      default: string;
      businessPlanning: string;
      financialPlanning: string;
    };
    TOOLTIP_TYPES: {
      default: string;
    };
    FUND_DOCUMENT_TYPES: {
      required: string;
      suggested: string;
      capitalCall: string;
    };
    FUND_APPLICATION_DOCUMENT_STATUS: {
      approved: string;
      pending: string;
      askedForResubmission: string;
    };
    CAPITAL_CALL_STATUS: {
      approved: string;
      pending: string;
      submittedForApproval: string;
      disbursed: string;
      funded: string;
      denied: string;
    };
    ALERT_KEYS: {
      savedSearchResult: {
        execution: string;
      };
      approvalChainLink: {
        turnToApproveApplication: string;
      };
      application: {
        documentChangedToRead: string;
        documentChangedToNotRead: string;
        documentChangedToNeedMoreInfo: string;
        documentUploaded: string;
        changedToSubmittedForApproval: string;
        changedToIncomplete: string;
        changedToApproved: string;
        changedToApprovedAndDisbursementDateSet: string;
        changedToDenied: string;
        newSubmission: string;
        committed: string;
        sentBackForReview: string;
        liquidationAmountSent: string;
        proceedsCalculated: string;
        proceedsSent: string;
        lenderConfirmedReceivedDisbursement: string;
        edoWatchableApplicationNonPerformingChanged: string;
        edoWatchableApplicationWatchingChanged: string;
        lenderCreatedLossClaim: string;
        edoApprovedLossClaim: string;
        updatedDisbursementConfig: string;
      };
      fundingProgram: {
        lenderProgramRequestApproved: string;
        lenderProgramRequestIncomplete: string;
        lenderProgramRequestDenied: string;
        lenderProgramRequestSendBack: string;
        vcProgramRequestApproved: string;
        vcProgramRequestIncomplete: string;
        vcProgramRequestDenied: string;
        vcProgramRequestSendBack: string;
        edoAddedNewCreditProgram: string;
        edoAddedNewEquityProgram: string;
        notifyBusinessLenderProgramRequestApproved: string;
        notifyBusinessVcProgramRequestApproved: string;
      };
      programRequest: {
        addedToWatchlist: string;
        lenderRequestedAccessToCreditProgram: string;
        vcRequestedAccessToCreditProgram: string;
        vcRequestedCapitalCall: string;
      };
      note: {
        edoAddedNoteToProgramRequest: string;
        lenderAddedNoteToProgramRequest: string;
        vcAddedNoteToProgramRequest: string;
        edoAddedNoteToApplication: string;
        lenderAddedNoteToApplication: string;
      };
      userRequestedAccess: string;
      lenderConfirmDisbursementReminder: string;
      edoConfirmDisbursementReminder: string;
      lenderExpressedInterest: string;
      vcExpressedInterest: string;
      businessExpressedInterest: string;
      edoSharedBusiness: string;
      capitalCall: {
        capitalCallDocumentApproved: string;
        capitalCallDocumentPending: string;
        capitalCallDocumentResubmission: string;
        capitalCallApproved: string;
        capitalCallDenied: string;
        capitalCallPending: string;
        capitalCallFunded: string;
        capitalCallSubmittedForApproval: string;
        capitalCallAssertTransferOfFunds: string;
        capitalCallOverdueFundsNotYetTransferred: string;
        edoUpdatedTransactionTerms: string;
        vcUpdatedTransactionTerms: string;
      };
      fund: {
        businessAddedToFund: string;
        businessSubmittedFundRequest: string;
        fundApplicationDocumentApproved: string;
        fundApplicationDocumentPending: string;
        fundApplicationDocumentResubmission: string;
      };
      fundApplication: {
        fundsAwarded: string;
        assertTransferOfFunds: string;
        overdueFundsNotYetTransferred: string;
      };
    };
    FORM_OF_BUSINESS_ORGANIZATION: {
      nonProfitEntity: {
        annualReportLabel: string;
        value: string;
      },
      soleProprietorIndependentContractor: {
        annualReportLabel: string;
        value: string;
      },
      partnership: { annualReportLabel: string; value: string; },
      llc: { annualReportLabel: string; value: string; },
      corporation: { annualReportLabel: string; value: string; },
      workerCooperative: {
        annualReportLabel: string;
        value: string;
      },
      tribalEnterprise: {
        annualReportLabel: string;
        value: string;
      },
      tribalMemberOwnedBusiness: {
        annualReportLabel: string;
        value: string;
      },
      other: { annualReportLabel: string; value: string; },
    };
    LOAN_TYPES: {
      termSpecified: {
        value: string;
        annualReportLabel: string;
      },
      revenueIncomeBased: {
        value: string;
        annualReportLabel:
        string;
      },
      lineOfCredit: {
        value: string;
        annualReportLabel: string;
      },
      bridgeVentureDebtOtherDebt: {
        value: string;
        annualReportLabel: string;
      },
      other: {
        value: string;
        annualReportLabel: string;
      },
    }
  };
};

const $appSettings = Signal<AppSettingsSignal>({
  // @ts-ignore
  appSettings: null,
  constants: {
    PROVIDER_TYPES: {},
    PORTAL_TYPES: {
      business: 'BUSINESS',
      edo: 'EDO',
      lender: 'LENDER',
      none: 'NONE',
      state: 'STATE',
      vc: 'VC',
      executive: 'EXECUTIVE',
    },
    APPLICATION_DOCUMENT_STATUS: {
      notRead: '',
      read: '',
      moreInfoNeeded: '',
    },
    APPLICATION_STATUS: {
      incomplete: '',
      approved: '',
      denied: '',
      pending: '',
      submittedForApproval: '',
      inProgress: '',
      default: '',
      importing: '',
    },
    PROGRAM_REQUEST_STATUS: {
      incomplete: '',
      approved: '',
      denied: '',
      pending: '',
      submittedForApproval: '',
    },
    USER_ROLES: {
      superAdmin: '',
      admin: '',
      owner: '',
      staff: '',
      viewer: '',
    },
    USER_STATUS: {
      active: '',
      inactive: '',
    },
    WATCHABLE_TYPES: {
      application: '',
      programRequest: '',
    },
    PROGRAM_FUND_TYPES: {
      seedCapitalProgram: '',
      ventureCapitalProgram: '',
    },
    APPROVAL_CHAIN_AMOUNT_BOUNDS: {
      min: 0,
      max: 2147483647,
    },
    DOCUMENT_TYPES: {
      applicationTemplate: '',
      applicationSuggested: '',
      applicationAdditional: '',
      programRequestTemplate: '',
      programRequestSuggested: '',
      programRequestAdditional: '',
    },
    REBALANCING_FREQUENCY_OPTIONS: {
      quarterly: '',
      biannually: '',
      annually: '',
    },
    STANDARD_COMPLIANCE_ITEM_KEYS: [],
    CAPITAL_ACCESS_COMPLIANCE_KEYS: [],
    TRANCHE_GRACE_PERIODS: {
      dev: 24,
    },
    OCR_CONFIDENCE_THRESHOLD: 0.9,
    TECHNICAL_ASSISTANCE_TYPES: {
      default: '',
    },
    TOOLTIP_TYPES: {
      default: '',
    },
    FUND_DOCUMENT_TYPES: {
      required: '',
      suggested: '',
      capitalCall: '',
    },
    FUND_APPLICATION_DOCUMENT_STATUS: {
      approved: '',
      pending: '',
      askedForResubmission: '',
    },
    CAPITAL_CALL_STATUS: {
      approved: '',
      pending: '',
      submittedForApproval: '',
      disbursed: '',
      funded: '',
      denied: '',
    },
    ALERT_KEYS: {
      savedSearchResult: {
        execution: '',
      },
      approvalChainLink: {
        turnToApproveApplication: '',
      },
      application: {
        documentChangedToRead: '',
        documentChangedToNotRead: '',
        documentChangedToNeedMoreInfo: '',
        documentUploaded: '',
        changedToSubmittedForApproval: '',
        changedToIncomplete: '',
        changedToApproved: '',
        changedToApprovedAndDisbursementDateSet: '',
        changedToDenied: '',
        newSubmission: '',
        committed: '',
        sentBackForReview: '',
        liquidationAmountSent: '',
        proceedsCalculated: '',
        proceedsSent: '',
        lenderConfirmedReceivedDisbursement: '',
        edoWatchableApplicationNonPerformingChanged: '',
        edoWatchableApplicationWatchingChanged: '',
        lenderCreatedLossClaim: '',
        edoApprovedLossClaim: '',
        updatedDisbursementConfig: '',
      },
      fundingProgram: {
        lenderProgramRequestApproved: '',
        lenderProgramRequestIncomplete: '',
        lenderProgramRequestDenied: '',
        lenderProgramRequestSendBack: '',
        vcProgramRequestApproved: '',
        vcProgramRequestIncomplete: '',
        vcProgramRequestDenied: '',
        vcProgramRequestSendBack: '',
        edoAddedNewCreditProgram: '',
        edoAddedNewEquityProgram: '',
        notifyBusinessLenderProgramRequestApproved: '',
        notifyBusinessVcProgramRequestApproved: '',
      },
      programRequest: {
        addedToWatchlist: '',
        lenderRequestedAccessToCreditProgram: '',
        vcRequestedAccessToCreditProgram: '',
        vcRequestedCapitalCall: '',
      },
      note: {
        edoAddedNoteToProgramRequest: '',
        lenderAddedNoteToProgramRequest: '',
        vcAddedNoteToProgramRequest: '',
        edoAddedNoteToApplication: '',
        lenderAddedNoteToApplication: '',
      },
      userRequestedAccess: '',
      lenderConfirmDisbursementReminder: '',
      edoConfirmDisbursementReminder: '',
      lenderExpressedInterest: '',
      vcExpressedInterest: '',
      businessExpressedInterest: '',
      edoSharedBusiness: '',
      capitalCall: {
        capitalCallDocumentApproved: '',
        capitalCallDocumentPending: '',
        capitalCallDocumentResubmission: '',
        capitalCallApproved: '',
        capitalCallDenied: '',
        capitalCallPending: '',
        capitalCallFunded: '',
        capitalCallSubmittedForApproval: '',
        capitalCallAssertTransferOfFunds: '',
        capitalCallOverdueFundsNotYetTransferred: '',
        edoUpdatedTransactionTerms: '',
        vcUpdatedTransactionTerms: '',
      },
      fund: {
        businessAddedToFund: '',
        businessSubmittedFundRequest: '',
        fundApplicationDocumentApproved: '',
        fundApplicationDocumentPending: '',
        fundApplicationDocumentResubmission: '',
      },
      fundApplication: {
        fundsAwarded: '',
        assertTransferOfFunds: '',
        overdueFundsNotYetTransferred: '',
      },
    },
    FORM_OF_BUSINESS_ORGANIZATION: {
      nonProfitEntity: {
        annualReportLabel: '',
        value: '',
      },
      soleProprietorIndependentContractor: {
        annualReportLabel: '',
        value: '',
      },
      partnership: { annualReportLabel: '', value: '' },
      llc: { annualReportLabel: '', value: '' },
      corporation: { annualReportLabel: '', value: '' },
      workerCooperative: {
        annualReportLabel: '',
        value: '',
      },
      tribalEnterprise: {
        annualReportLabel: '',
        value: '',
      },
      tribalMemberOwnedBusiness: {
        annualReportLabel: '',
        value: '',
      },
      other: { annualReportLabel: '', value: '' },
    },
    LOAN_TYPES: {
      termSpecified: {
        value: '',
        annualReportLabel:
          '',
      },
      revenueIncomeBased: {
        value: '',
        annualReportLabel:
          '',
      },
      lineOfCredit: {
        value: '',
        annualReportLabel: '',
      },
      bridgeVentureDebtOtherDebt: {
        value: '',
        annualReportLabel:
          '',
      },
      other: {
        value: '',
        annualReportLabel: '',
      },
    }
  },
});

export default $appSettings;
