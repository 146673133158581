import validateEmailUtil from "utils/validateEmail";
import { einRegex } from "../Constant/regularExpression";
import loaderSignal from "signals/Loader.signal";
import alertSignal from "signals/Alert.signal";
import api from "api/api";
import { organizationDetailsSignal } from "../OrganizationsList/OrganizationsList.signal";
import { fetchAndSetVcDealFlowData } from "components/views/VC/VCDealFlows/VCDealFlows.helpers";
import { fetchAndSetFund } from "../FundDetails/fundDetails.helpers";
import fundDetailsSignal from "../FundDetails/fundDetails.signal";
import addPortfolioCompanySignal from "./addPortfolioCompany.signal";

export const PORTFOLIO_COMPANY_MODAL_VIEWS = {
  EIN: 'ein',
  NO_BUSINESS_FOUND: 'noBusinessFound',
  EXISTING_BUSINESS_INFO: 'existingBusinessInfo',
  CREATE_BUSINESS_INFO: 'createBusinessInfo',
  FUND_INFORMATION: 'fundInformation',
  SEDI_INFORMATION: 'sediInformation',
};

export const PORTFOLIO_COMPANY_MODAL_PARENTS = {
  DEAL_FLOW: 'dealFlow',
  FUND_DETAILS: 'fundDetails',
};

export const handleBackToSearchClick = () => {
  const { parentView, newVcPortfolioCompanyFundId } = addPortfolioCompanySignal.value;

  addPortfolioCompanySignal.update({
    newVcPortfolioCompanyBusinessName: '',
    newVcPortfolioCompanyBusinessEin: '',
    newVcPortfolioCompanyFundId: parentView === PORTFOLIO_COMPANY_MODAL_PARENTS.FUND_DETAILS ? newVcPortfolioCompanyFundId : '',
    existingBusinessId: null,
    newVcPortfolioCompanyBusinessEmail: '',
    newVcPortfolioCompanyFirstName: '',
    newVcPortfolioCompanyLastName: '',
    currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.EIN,
    newVcPortfolioCompanySediCertification: {
      isSediOwned: '',
      sediStatusByBusinessAddressInCDFIInvestmentArea: '',
      selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea: '',
      selfCertifiedSEDIFutureLocationInCDFIInvestmentArea: '',
      selfCertifiedSEDIDemographicsRelatedBusinessStatus: [''],
    },
  });

}

export const handleRegisterNewBusinessClick = (
  resetEin
) =>
  addPortfolioCompanySignal.update({
    existingBusinessId: null,
    currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.CREATE_BUSINESS_INFO,
    newVcPortfolioCompanyBusinessEin: resetEin
      ? ''
      : addPortfolioCompanySignal.value.newVcPortfolioCompanyBusinessEin,
  });

export const handleSediDemographicsChange = (e) => {
  const { name } = e;
  const { newVcPortfolioCompanySediCertification } = addPortfolioCompanySignal.value;
  const { selfCertifiedSEDIDemographicsRelatedBusinessStatus } =
    newVcPortfolioCompanySediCertification;

  if (
    name === 'N/A' &&
    !selfCertifiedSEDIDemographicsRelatedBusinessStatus.includes('N/A')
  ) {
    return addPortfolioCompanySignal.update({
      newVcPortfolioCompanySediCertification: {
        ...newVcPortfolioCompanySediCertification,
        selfCertifiedSEDIDemographicsRelatedBusinessStatus: ['N/A'],
      },
    });
  } else {
    return addPortfolioCompanySignal.update({
      newVcPortfolioCompanySediCertification: {
        ...newVcPortfolioCompanySediCertification,
        selfCertifiedSEDIDemographicsRelatedBusinessStatus:
          selfCertifiedSEDIDemographicsRelatedBusinessStatus.includes(name)
            ? selfCertifiedSEDIDemographicsRelatedBusinessStatus.filter(
              (sd) => sd !== name
            )
            : [
              ...selfCertifiedSEDIDemographicsRelatedBusinessStatus.filter(
                (sd) => sd !== 'N/A'
              ),
              name,
            ],
      },
    });
  }
};

export const handleSediInformationChange = (e) => {
  const { name, value } = e.target;

  return addPortfolioCompanySignal.update({
    newVcPortfolioCompanySediCertification: {
      ...addPortfolioCompanySignal.value.newVcPortfolioCompanySediCertification,
      [name]: value,
    },
  });
};

export const handleUseExistingBusinessClick = () => {
  const businessId = organizationDetailsSignal.value.selectedOrganization?.id;

  return addPortfolioCompanySignal.update({
    existingBusinessId: businessId,
    currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.FUND_INFORMATION,
  });
};

export const hideNewVcPortfolioCompanyModal = () => {
  const { parentView, newVcPortfolioCompanyFundId } = addPortfolioCompanySignal.value;

  addPortfolioCompanySignal.update({
    modalVisible: false,
    newVcPortfolioCompanyBusinessName: '',
    newVcPortfolioCompanyBusinessEin: '',
    newVcPortfolioCompanyFundId: parentView === PORTFOLIO_COMPANY_MODAL_PARENTS.FUND_DETAILS ? newVcPortfolioCompanyFundId : '',
    newVcPortfolioCompanyBusinessEmail: '',
    newVcPortfolioCompanyFirstName: '',
    newVcPortfolioCompanyLastName: '',
    existingBusinessId: null,
    currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.EIN,
    newVcPortfolioCompanySediCertification: {
      isSediOwned: '',
      sediStatusByBusinessAddressInCDFIInvestmentArea: '',
      selfCertifiedSEDIOwnedAndControlledInCDFIInvestmentArea: '',
      selfCertifiedSEDIFutureLocationInCDFIInvestmentArea: '',
      selfCertifiedSEDIDemographicsRelatedBusinessStatus: [''],
    },
  });
}

export const searchForBusinessByEin = async () => {
  const { newVcPortfolioCompanyBusinessEin } = addPortfolioCompanySignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    const business = await api.get({
      path: '/businesses',
      options: {
        include: {
          businessDocuments: true,
        },
        where: {
          ein: newVcPortfolioCompanyBusinessEin,
        },
      },
    });

    if (business.length) {
      organizationDetailsSignal.update({ selectedOrganization: business[0] });
      return addPortfolioCompanySignal.update({
        currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.EXISTING_BUSINESS_INFO,
      });
    } else {
      return addPortfolioCompanySignal.update({
        currentView: PORTFOLIO_COMPANY_MODAL_VIEWS.NO_BUSINESS_FOUND,
      });
    }
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const submitNewVcPortfolioCompany = async () => {
  loaderSignal.update({
    isContentLoading: true,
    message: 'Submitting portfolio company...',
  });

  try {
    const {
      newVcPortfolioCompanyBusinessEin,
      newVcPortfolioCompanyBusinessName,
      newVcPortfolioCompanyFundId,
      newVcPortfolioCompanyBusinessEmail,
      existingBusinessId,
      newVcPortfolioCompanySediCertification,
      newVcPortfolioCompanyFirstName,
      newVcPortfolioCompanyLastName,
      parentView,
    } = addPortfolioCompanySignal.value;

    const body = {
      data: {
        businessName: newVcPortfolioCompanyBusinessName,
        businessEin: newVcPortfolioCompanyBusinessEin,
        fundId: Number(newVcPortfolioCompanyFundId),
        businessEmail: newVcPortfolioCompanyBusinessEmail,
        businessId: existingBusinessId,
        businessSediCertification: newVcPortfolioCompanySediCertification,
        businessFirstName: newVcPortfolioCompanyFirstName,
        businessLastName: newVcPortfolioCompanyLastName,
      },
    };

    await api.post({
      path: '/vcPortfolioCompanies',
      body,
    });

    if (parentView === PORTFOLIO_COMPANY_MODAL_PARENTS.DEAL_FLOW) {
      await fetchAndSetVcDealFlowData();
    } else if (parentView === PORTFOLIO_COMPANY_MODAL_PARENTS.FUND_DETAILS) {
      await fetchAndSetFund(fundDetailsSignal.value.id);
    }
    hideNewVcPortfolioCompanyModal();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully created a portfolio company.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const updateNewVcPortfolioCompanyData = (
  e
) =>
  addPortfolioCompanySignal.update({
    [e.target.name]: e.target.value,
  });

export const validEin = () =>
  einRegex.test(addPortfolioCompanySignal.value.newVcPortfolioCompanyBusinessEin);

export const validEmail = () =>
  validateEmailUtil(addPortfolioCompanySignal.value.newVcPortfolioCompanyBusinessEmail);
