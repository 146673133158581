import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import notificationsSignal, {
  notificationActionIsLoadingSignal,
} from 'signals/Notification.signal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  locationArrowIcon,
  notificationBellIcon,
  readIcon,
} from 'components/global/Constant/Icons';
import { markRead } from '../NotificationPage.helpers';
import userAccountSignal from 'signals/UserAccount.signal';
import { buildNotificationParams } from 'libs/notifications';
import { DateTime } from 'luxon';

const Notification = ({ notification }) => {
  const history = useHistory();

  const {
    notificationTitle,
    isRead,
    notificationMessage,
    endpointName,
    actionTitle,
    handleNavigation,
  } = buildNotificationParams(
    notification,
    history,
    userAccountSignal.value.userData.account.portalType,
    markRead
  );

  return (
    <>
      <Alert key={notification.id} variant={`${isRead ? 'secondary' : 'info'}`}>
        <p>
          <FontAwesomeIcon
            className="mr-8"
            icon={notificationBellIcon}
            hidden={isRead}
          />
          {notificationTitle}
        </p>
        <hr />
        <Row>
          <Col>
            <p
              className={`${
                isRead ? 'text-secondary' : 'text-info font-weight-bold'
              }`}
            >
              {notificationMessage}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="small">
              {DateTime.fromISO(notification.createdDate).toFormat(
                'DD, h:mm a'
              )}
            </p>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            className="mr-8"
            size="sm"
            hidden={!endpointName || !actionTitle}
            onClick={handleNavigation}
          >
            <FontAwesomeIcon icon={locationArrowIcon} />
            {actionTitle}
          </Button>
          <Button
            variant="outline-secondary"
            className="mr-8"
            size="sm"
            hidden={isRead}
            onClick={() => markRead(notification.id)}
          >
            <FontAwesomeIcon icon={readIcon} /> Mark as Read{' '}
            {!!notificationActionIsLoadingSignal.value[notification.id] && (
              <Spinner size="sm" animation="border" role="status" />
            )}
          </Button>
        </div>
      </Alert>
    </>
  );
};

const Notifications = () => {
  const notifications = notificationsSignal.value;

  if (!notifications.length) {
    return <p className="mt-32 mx-auto text-secondary">No Alerts</p>;
  }

  return (
    <>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </>
  );
};

export default Notifications;
