import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { annualReportingSignal } from 'components/global/Reporting/Reporting.signals';
import { handleDownloadAnnualReport } from '../Reporting.helpers';
import ReportingTable from './ReportingTable';
import TestModeBanner from '../../utils/TestModeBanner';
import {
  LoanAndInvestment,
  ProviderData,
  TransactionEquity,
  TransactionTermsCredit,
} from './ReportingChartsAndTables';
import Loader from '../../Loader';

const AnnualReporting = () => {
  if (annualReportingSignal.value.fetchingTableData) {
    return <Loader message="Loading report..." />;
  }

  return (
    <>
      {/* 
            // Drop down selection for OLD reporting options
            
            <Row>
              <Col lg={4}>
                <Select
                  label="Reporting Option"
                  labelClassName="mr-8 font-weight-bold text-nowrap"
                  inputClassName="form-control"
                  options={Object.values(ANNUAL_REPORTING_OPTIONS)}
                  onChange={handleReportingTemplateSelectChange}
                />
              </Col>
            </Row>
            <hr /> */}
      <Row>
        <Col className="text-right">
          {annualReportingSignal.value.reportData && (
            <Button
              variant="outline-secondary"
              className="rounded"
              onClick={handleDownloadAnnualReport}
              // Download logic for OLD reporting options

              // onClick={() => {
              //   if (selectedOption === ANNUAL_REPORTING_OPTIONS.optionOne.value) {
              //     return handleDownloadAnnualReport(
              //       ANNUAL_REPORTING_OPTIONS.optionOne.value
              //     );
              //   } else if (
              //     selectedOption === ANNUAL_REPORTING_OPTIONS.optionTwo.value
              //   ) {
              //     return handleDownloadAnnualReport(
              //       ANNUAL_REPORTING_OPTIONS.optionTwo.value
              //     );
              //   }
              // }}
            >
              Download File
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {annualReportingSignal.value.reportData ? (
            <AnnualReportingTabs />
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

const AnnualReportingTabs = () => {
  const { selectedTab, reportData } = annualReportingSignal.value;

  return (
    <>
      <TestModeBanner />
      <Tabs
        className="mt-16"
        activeKey={selectedTab}
        onSelect={(selectedTab) =>
          annualReportingSignal.update({ selectedTab })
        }
      >
        {reportData.map((bundle, index) => (
          <Tab title={bundle.name} eventKey={bundle.name} key={index}>
            <div className="border-top">
              <h4 className="my-24 text-center">{bundle.name}</h4>
              <ReportingTable data={bundle.data} headers={bundle.headers} />
              {bundle.name.toLowerCase().includes('provider') && (
                <ProviderData bundle={bundle} />
              )}
              {bundle.name.toLowerCase().includes('investment') &&
                bundle.name.toLowerCase().includes('info') && (
                  <LoanAndInvestment bundle={bundle} />
                )}
              {bundle.name.toLowerCase().includes('credit') && (
                <TransactionTermsCredit bundle={bundle} />
              )}
              {bundle.name.toLowerCase().includes('equity') && (
                <TransactionEquity bundle={bundle} />
              )}
            </div>
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default AnnualReporting;
