import Signal, { Sygnal } from 'libs/signals';
import { buildDataUrlForPreview } from './PreviewButton.helpers';
import { ReactElement } from 'react';

export type UploadedFile = {
  id?: number;
  uuid?: string;
  fileType: string;
  filePath: string;
  parentId?: number | null;
};
export type FileUploadOrBrowserFile = File | UploadedFile | string;

const previewLoader =
  (signal: Sygnal<boolean>) =>
  (
    fileUploadOrBrowserFile: FileUploadOrBrowserFile,
    model: string,
    fileType: string | undefined = undefined
  ) =>
    buildDataUrlForPreview(fileUploadOrBrowserFile, model, signal, fileType);

type Props = {
  render: (
    submitting: boolean,
    fn: (file: FileUploadOrBrowserFile, documentType: string) => unknown
  ) => ReactElement;
};

const FilePreviewButton: React.FC<Props> = ({ render }) => {
  const loadingSignal = Signal<boolean>(false);
  const previewCurry = previewLoader(loadingSignal);
  return render(loadingSignal.value, previewCurry);
};

export default FilePreviewButton;
