import loaderSignal from "signals/Loader.signal";
import api from "api/api";
import alertSignal from "signals/Alert.signal";
import edoTooltipsSignal from "./edoTooltips.signal";

export const TOOLTIP_MODALS = {
  create: 'create',
  edit: 'edit',
};

export const fetchAndSetTooltips = async (page) => {
  const { recordsPerPage } = edoTooltipsSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    const data = await api.get({
      path: '/tooltips',
      options: {
        getCount: true,
        skip: page ? (page - 1) * recordsPerPage : 0,
        take: recordsPerPage,
        orderBy: {
          id: 'desc',
        },
      },
    });

    edoTooltipsSignal.update({
      tooltips: data.tooltips || [],
      totalPages: data.count ? Math.ceil(data.count / recordsPerPage) : 1,
    });
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
}

export const handleShowCreateTooltipsModal = () => {
  edoTooltipsSignal.update({ modalType: TOOLTIP_MODALS.create });
};

export const handleShowEditTooltipsModal = (tooltip) => {
  edoTooltipsSignal.update({
    modalType: TOOLTIP_MODALS.edit,
    type: tooltip.type,
    title: tooltip.title,
    supportingText: tooltip.supportingText,
    id: tooltip.id,
  });
};

export const handleCloseTooltipsModal = () => {
  edoTooltipsSignal.update({
    modalType: null,
    title: '',
    supportingText: '',
    type: '',
    id: null,
    deleteTooltipPromptVisible: false,
  });
};

export const handleTooltipsInputChange = (e) => {
  const { name, value } = e;
  const { tooltipErrors } = edoTooltipsSignal.value;

  edoTooltipsSignal.update({
    [name]: value,
    tooltipErrors: {
      ...tooltipErrors,
      [name]: false,
    },
  });
};

const tooltipInputsAreValid = () => {
  const newErrors = {
    title: false,
    type: false,
    supportingText: false,
  };

  Object.keys(newErrors).forEach(name => {
    if (!edoTooltipsSignal.value[name]) {
      newErrors[name] = true;
    }
  });

  edoTooltipsSignal.update({ tooltipErrors: newErrors });

  if (Object.values(edoTooltipsSignal.value.tooltipErrors).includes(true)) {
    return false;
  }

  return true;
};

export const handleTooltipSaveClick = async () => {
  const { modalType } = edoTooltipsSignal.value;

  if (!tooltipInputsAreValid()) {
    return;
  }

  if (modalType === TOOLTIP_MODALS.create) {
    await createTooltip();
  } else if (modalType === TOOLTIP_MODALS.edit) {
    await updateTooltip();
  }
};

export const createTooltip = async () => {
  const { title, type, supportingText } = edoTooltipsSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.post({
      path: '/tooltips',
      body: {
        data: {
          title,
          type,
          supportingText,
        },
      },
    });

    await fetchAndSetTooltips();
    handleCloseTooltipsModal();

    alertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: 'New Tooltip was successfully created.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const updateTooltip = async () => {
  const { title, type, supportingText, id } = edoTooltipsSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.patch({
      path: '/tooltips',
      body: {
        where: {
          id,
        },
        data: {
          title,
          type,
          supportingText,
        },
      },
    });

    await fetchAndSetTooltips();

    alertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: 'Tooltip was successfully updated.',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const deleteTooltip = async () => {
  const { id } = edoTooltipsSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });

    await api.delete({
      path: '/tooltips',
      body: {
        where: {
          id,
        },
      },
    });

    await fetchAndSetTooltips();

    handleCloseTooltipsModal();

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully deleted tooltip',
    })
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};
