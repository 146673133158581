import BusinessWrapper from '../../../views/Business/components/BusinessWrapper';
import { Button, ButtonGroup, ButtonToolbar, Col, Row } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import { DAYS_OF_THE_WEEK } from 'components/global/Constant/constants';
import {
  handleInstantaneousChange,
  handleNotificationScheduleDayChange,
  handleNotificationScheduleTimeChange,
  handleSaveClick,
} from '../NotificationPage.helpers';
import { notificationScheduleSignal } from 'signals/Notification.signal';

const NotificationsSchedule = () => {
  const { secondsFromMidnight, instantEmailNotifications } =
    notificationScheduleSignal.value;

  return (
    <BusinessWrapper>
      <Row className="bg-light p-16 text-center">
        <Col>
          <h5>Alerts Schedule</h5>
        </Col>
      </Row>
      <Row className="bg-light p-16">
        <Col xs={3}>
          <p>Enable to receive alerts instantly.</p>
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button
                onClick={handleInstantaneousChange}
                className="rounded"
                variant={
                  instantEmailNotifications ? 'primary' : 'outline-secondary'
                }
              >
                Instantaneous
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
        <Col xs={7}>
          <p>Select days of the week when you want to receive alerts.</p>
          <div className="d-flex">
            <ButtonToolbar>
              <ButtonGroup>
                {Object.keys(DAYS_OF_THE_WEEK).map((day) => (
                  <Button
                    onClick={() => handleNotificationScheduleDayChange(day)}
                    variant={
                      notificationScheduleSignal.value[day]
                        ? 'primary'
                        : 'outline-secondary'
                    }
                    className="rounded mr-2"
                    key={day}
                  >
                    {DAYS_OF_THE_WEEK[day]}
                  </Button>
                ))}
              </ButtonGroup>
            </ButtonToolbar>
            <TimePicker
              value={secondsFromMidnight}
              onChange={(value) => handleNotificationScheduleTimeChange(value)}
            />
          </div>
        </Col>
        <Col xs={1} className="offset-1">
          <p className="invisible">Empty</p>
          <Button
            onClick={handleSaveClick}
            variant={'primary'}
            className="w-100 rounded"
          >
            Save
          </Button>
        </Col>
      </Row>
    </BusinessWrapper>
  );
};

export default NotificationsSchedule;
