import Signal from 'libs/signals';

export const ocrSignal = Signal({
  rawOcrData: {},
  parsedOcrData: {},
  sortedCategories: [''].filter(() => false),
  changesConfirmed: false,
  formattedOcrData: {},
  modelId: '',
});
