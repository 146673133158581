import { Button, Col, Form, Row } from 'react-bootstrap';
import submitApplicationSignal, {
  applicationDraftSignal,
  submitApplicationErrorsSignal,
} from 'signals/SubmitApplication.signal';
import {
  getAssociatedFundingPrograms,
  getAssociatedReferencePrograms,
  handleInputChange,
  handleInputDirty,
  handleSelectionChange,
  nextStepUnlessApplicationAlreadyExists,
} from '../SubmitApplications.helpers';
import { PROGRAM_TYPES } from 'components/global/Constant/lenderOrganizationInfoConstants';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const SubmitApplicationsChooseProgram = () => {
  const {
    askedAmount,
    fundingProgramId,
    programTypeId,
    referenceProgramId,
    programTypes,
    businessEin,
    lenderEin,
    initialStatus,
  } = submitApplicationSignal.value;
  const errors = submitApplicationErrorsSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { APPLICATION_STATUS, PORTAL_TYPES } = $appSettings.value.constants;
  const isBtnDisabled = () =>
    fundingProgramId < 1 ||
    referenceProgramId < 1 ||
    programTypeId < 1 ||
    !(businessEin && businessEin.length === 10) ||
    (portalType === PORTAL_TYPES.edo &&
      !(lenderEin && lenderEin.length === 10)) ||
    askedAmount < 1;
  const creditProgramType = programTypes?.find(
    (pType) => pType.programTypeName === PROGRAM_TYPES.credit
  );

  return (
    <>
      <Row>
        <Col>
          <h4>Application Program</h4>
        </Col>
      </Row>
      <h5 className="mt-32">Program Information</h5>
      <Row>
        <Col xs={6} hidden={portalType !== PORTAL_TYPES.edo}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Program Type
            </Form.Label>
            <Form.Select
              disabled={applicationDraftSignal.value.selectedDraft?.id}
              className="form-control"
              name="programType"
              value={programTypeId}
              onChange={(e) => handleSelectionChange(e)}
            >
              <option value="-1" key="-1">
                Select a Program Type
              </option>
              <option value={creditProgramType?.id}>
                {creditProgramType?.programTypeName}
              </option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Select a Reference Program
            </Form.Label>
            <Form.Select
              disabled={
                (portalType === PORTAL_TYPES.edo &&
                  (programTypeId === -1 || programTypeId === '-1')) ||
                applicationDraftSignal.value.selectedDraft?.id
              }
              className="form-control"
              name="referenceProgram"
              value={referenceProgramId}
              onChange={(e) => handleSelectionChange(e)}
            >
              <option value={-1}>Select a Program</option>
              {getAssociatedReferencePrograms(programTypeId)?.map((program) => (
                <option key={program.id} value={program.id}>
                  {program.programName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Select a Funding Program
            </Form.Label>
            <Form.Select
              disabled={
                referenceProgramId === -1 ||
                referenceProgramId === '-1' ||
                applicationDraftSignal.value.selectedDraft?.id
              }
              className="form-control"
              name="fundingProgram"
              value={fundingProgramId}
              onChange={(e) => handleSelectionChange(e)}
            >
              <option value="-1" key="-1">
                Select a Specific Program
              </option>
              {getAssociatedFundingPrograms()?.map((mp) => (
                <option disabled={!mp.ocrModelKey} value={mp.id} key={mp.id}>
                  {`${mp.name}${
                    mp.ocrModelKey
                      ? ''
                      : ' (OCR key is not setup, please contact support)'
                  }`}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {userAccountSignal.value.userData.account.portalType ===
      PORTAL_TYPES.edo ? (
        <h5 className="mt-32">Business & Lender Information</h5>
      ) : (
        <h5 className="mt-32">Business Information</h5>
      )}
      <Row>
        <Col>
          <Form.Group className="form-group">
            <Form.Label className="font-weight-bold text-secondary">
              Business EIN #
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="XX-XXXXXXX"
              name="businessEin"
              value={businessEin}
              isInvalid={errors.businessEin && errors.businessEinDirty}
              onChange={handleInputChange}
              onBlur={handleInputDirty}
            />
            <Form.Control.Feedback type="invalid">
              Please follow the format: XX-XXXXXXX
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        {userAccountSignal.value.userData.account.portalType ===
          PORTAL_TYPES.edo && (
          <Col>
            <Form.Group className="form-group">
              <Form.Label className="font-weight-bold text-secondary">
                Lender EIN #
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="XX-XXXXXXX"
                name="lenderEin"
                value={lenderEin}
                isInvalid={errors.lenderEin && errors.lenderEinDirty}
                onChange={handleInputChange}
                onBlur={handleInputDirty}
              />
              <Form.Control.Feedback type="invalid">
                Please follow the format: XX-XXXXXXX
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
      </Row>
      <h5 className="mt-32">Loan Amount</h5>
      <Row>
        <Col>
          <Field.Currency
            className="form-group"
            inputClassName="form-control"
            labelClassName="font-weight-bold text-secondary"
            label="Asked Amount"
            name="askedAmount"
            value={askedAmount}
            onChange={(input) => handleInputChange({ target: input })}
          />
        </Col>
      </Row>

      {userAccountSignal.value.userData.account.portalType ===
        PORTAL_TYPES.edo && (
        <>
          <h5 className="mt-32">Initial Status</h5>
          <Row>
            <Col>
              <Form.Group className="form-group">
                <Form.Label className="font-weight-bold text-secondary">
                  Initial Status
                </Form.Label>
                <Form.Select
                  className="form-control"
                  name="initialStatus"
                  value={initialStatus}
                  onChange={(e) => handleSelectionChange(e)}
                >
                  <option
                    value={APPLICATION_STATUS.pending}
                    key={APPLICATION_STATUS.pending}
                  >
                    Pending
                  </option>
                  <option
                    value={APPLICATION_STATUS.approved}
                    key={APPLICATION_STATUS.approved}
                  >
                    Approved
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      <Row className="justify-content-center p-16">
        <Button
          className="rounded"
          onClick={nextStepUnlessApplicationAlreadyExists}
          disabled={isBtnDisabled()}
        >
          Next
        </Button>
      </Row>
    </>
  );
};

export default SubmitApplicationsChooseProgram;
