import { useHistory } from 'react-router-dom';
import { Alert, Col, Row } from 'react-bootstrap';
import { drawerNotificationsSignal } from 'signals/Notification.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import { markRead } from '../NotificationDrawer.helpers';
import { buildNotificationParams } from 'libs/notifications';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkmarkIcon } from 'components/global/Constant/Icons';

const Notification = ({ notification }) => {
  const history = useHistory();

  const { notificationMessage, handleNavigation, isRead } =
    buildNotificationParams(
      notification,
      history,
      userAccountSignal.value.userData.account.portalType,
      markRead
    );

  return (
    <Alert
      key={notification.id}
      onClick={handleNavigation}
      className="mt-8"
      style={{ cursor: 'pointer' }}
      variant={isRead ? 'secondary' : 'info'}
    >
      <div className="d-flex justify-content-between">
        <strong className="me-auto">{notification.account.portalType}</strong>
        <small className="ml-auto">
          {DateTime.fromISO(notification.createdDate).toFormat('DD')}
        </small>
      </div>

      <hr className="mt-0" />
      <Row>
        <Col>
          <p className="font-weight-bold mb-0">{notificationMessage}</p>
          {isRead ? (
            <p className="small mb-0 text-right">
              <FontAwesomeIcon icon={checkmarkIcon} size="sm" />{' '}
              Read
            </p>
          ) : (
            <div className="d-flex justify-content-end">
              <p
                className="small mb-0 text-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  markRead(notification.id);
                }}
              >
                Mark as Read
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Alert>
  );
};

const DrawerNotifications = () => {
  const notifications = drawerNotificationsSignal.value;

  if (!notifications.length) {
    return (
      <>
        <p className="mt-32 text-secondary">No new notifications</p>
      </>
    );
  }

  return (
    <>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </>
  );
};

export default DrawerNotifications;
