import { Button, Card, Col, Collapse, Form, Modal, Row } from 'react-bootstrap';
import CloseModalButton from '../../Constant/CloseModalButton';
import {
  handleAddAttachments,
  handleCapitalCallPushFundsToBusiness,
  handleChangeAttachmentName,
  handleRemoveAttachment,
  handleReplaceFileInAttachment,
  hideCapitalPortionSendFunds,
} from '../CapitalCall.helpers';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import { $capitalCallSendFunds } from '../CapitalCall.signals';
import AddAttachmentsButton from '../../AddAttachmentsButton';
import AttachmentListForm from '../../AttachmentsListForm';
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  caretDownIcon,
  caretRightIcon,
} from 'components/global/Constant/Icons';
import FilePreviewButton from 'components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton';

const CapitalCallSendFundsModal = () => {
  const { capitalCallPortion, attachments } = $capitalCallSendFunds.value;
  const [touched, setTouched] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const fundsNeedSending =
    !capitalCallPortion?.fundsPushedToBusinessDate ||
    new Date(capitalCallPortion.fundsMissingSubmittedDate) >
      new Date(capitalCallPortion.fundsPushedToBusinessDate);
  const error =
    attachments.some((a) => !a.file || !a.name) || attachments.length === 0;

  return (
    <ModalWrapper size="xl" centered show={!!capitalCallPortion}>
      <Modal.Header>
        <h4>
          {fundsNeedSending ? 'Send Funds to ' : ''}
          <i>{capitalCallPortion?.vcPortfolioCompany.business.name}</i>
        </h4>
        <CloseModalButton onCloseModal={() => hideCapitalPortionSendFunds()} />
      </Modal.Header>
      <Modal.Body>
        {fundsNeedSending && (
          <>
            <p>Attach documents showing proof of transfer.</p>
            <Row className="align-items-center">
              <Col>
                <h6 className="font-weight-bold text-secondary">Attachments</h6>
              </Col>
              <Col className="text-right">
                <AddAttachmentsButton
                  multiple={true}
                  onChange={(e) => handleAddAttachments(e.target.files)}
                />
              </Col>
            </Row>
            <Row className="mt-16">
              <Col>
                <AttachmentListForm
                  onChange={handleChangeAttachmentName}
                  onRemove={handleRemoveAttachment}
                  onReplace={handleReplaceFileInAttachment}
                  attachments={attachments}
                />
                {error && touched && (
                  <p className="text-danger">
                    {attachments.length === 0
                      ? 'At least one attachment is required.'
                      : 'Every attachment must have a name and a file associated with it.'}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
          </>
        )}
        <p className="font-weight-bold text-secondary">Notes</p>
        <Card
          style={{ height: 200, overflowY: 'scroll' }}
          className="px-16 py-8 rounded-0"
        >
          {capitalCallPortion?.notes?.map((note) => (
            <div className="bg-light rounded p-1 mb-4" key={note.uuid}>
              <p>
                {new Date(note.createdDate).toLocaleDateString()} -{' '}
                {note.content}
              </p>
            </div>
          ))}
        </Card>
        {fundsNeedSending && (
          <div className="mt-16">
            <p className="font-weight-bold text-secondary">New Note</p>
            <Form.Control
              as="textarea"
              value={$capitalCallSendFunds.value.note}
              onChange={(e) =>
                $capitalCallSendFunds.update({ note: e.target.value })
              }
            />
          </div>
        )}
        {!!Object.keys(capitalCallPortion?.fundsPushedAttachments ?? {})
          .length && (
          <>
            <div
              className="bg-light px-16 py-8 mt-24 pointer"
              onClick={() => setShowHistory(!showHistory)}
            >
              <h5 className="mb-0">
                Uploads{' '}
                <FontAwesomeIcon
                  icon={showHistory ? caretDownIcon : caretRightIcon}
                />
              </h5>
            </div>
            <Collapse in={showHistory}>
              <div>
                <div className="p-16">
                  {Object.keys(capitalCallPortion?.fundsPushedAttachments ?? {})
                    ?.reverse()
                    ?.map((date) => (
                      <Fragment key={date}>
                        <p className="font-weight-bold text-secondary">
                          {new Date(date).toLocaleString()}
                        </p>
                        {capitalCallPortion.fundsPushedAttachments[date]?.map(
                          (doc) => (
                            <Row key={doc.filePath} className="px-24">
                              <Col>
                                <p>{doc.name}</p>
                              </Col>
                              <Col>
                                <FilePreviewButton
                                  render={(_submitting, fn) => (
                                    <Button
                                      size="sm"
                                      disabled={!doc.filePath}
                                      className="rounded"
                                      variant="outline-info"
                                      onClick={() =>
                                        fn(
                                          {
                                            ...doc,
                                            parentId: capitalCallPortion.id,
                                          },
                                          'capitalCallPortionDocuments'
                                        )
                                      }
                                    >
                                      Preview
                                    </Button>
                                  )}
                                />
                              </Col>
                            </Row>
                          )
                        )}
                      </Fragment>
                    ))}
                </div>
              </div>
            </Collapse>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              hideCapitalPortionSendFunds();
            }}
          >
            Dismiss
          </Button>
          <Button
            hidden={!fundsNeedSending}
            disabled={error}
            onClick={() => {
              setTouched(true);
              handleCapitalCallPushFundsToBusiness();
            }}
          >
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default CapitalCallSendFundsModal;
