import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import billingSignal from "components/global/Billing/Billing.signal";
import OpenPlaidButton from "components/providers/PlaidProvider";
import BillingDeleteBankAccountModal from "./components/BillingDeleteBankAccountModal";
import {
  fetchAndSetBillingData,
  handleBillingSortChange,
  handleHideLinkedPlatformDetailsModal,
  handleHideLinkedProgramDetailsModal,
  handleShowDeleteBankAccount,
  handleShowLinkedPlatformDetails,
  handleShowLinkedProgramDetails
} from "./Billing.helpers";
import SimpleTable from "../SimpleTable";
import { useLocation } from "react-router";
import LinkedProgramDetailsModal from "./components/LinkedProgramDetails";
import LinkedPlatformDetailsModal from "./components/LinkedPlatformDetailsModal";

const HEADERS = [
  {
    key: 'bankAccountName',
    title: 'Account Name',
    sortable: true,
  },
  {
    key: 'mask',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'linkedPrograms',
    title: 'Linked Programs',
  },
  {
    key: 'linkedPlatforms',
    title: 'Linked Platforms',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const Billing = () => {
  const {
    bankAccounts,
    linkedProgramsDetailModalState,
    selectedBankAccount,
    linkedPrograms,
    linkedPlatformsDetailModalVisible,
    linkedPlatforms,
  } = billingSignal.value;
  const location = useLocation();

  useEffect(() => {
    fetchAndSetBillingData();
  }, [location.search]);

  return (
    <Card className="p-3 mb-4 text-secondary rounded-0">
      <div className="d-flex justify-content-end mb-16">
        <OpenPlaidButton />
      </div>
      <SimpleTable
        headers={HEADERS}
        onSort={(sort) => {
          handleBillingSortChange(sort);
          fetchAndSetBillingData();
        }}
        rows={bankAccounts.map((bankAccount) => ({
          bankAccountName: bankAccount.bankAccountName,
          mask: `********${bankAccount.mask}`,
          type: bankAccount.type
            ? bankAccount.type.charAt(0).toUpperCase() +
              bankAccount.type.slice(1)
            : '',
          linkedPrograms: ({ key }) => (
            <td key={key}>
              <Button
                size="sm"
                className="rounded mr-16"
                onClick={() => handleShowLinkedProgramDetails(bankAccount)}
              >
                View/Edit
              </Button>
            </td>
          ),
          linkedPlatforms: ({ key }) => (
            <td key={key}>
              <Button
                size="sm"
                className="rounded"
                variant="outline-dark"
                onClick={() => handleShowLinkedPlatformDetails(bankAccount)}
              >
                View
              </Button>
            </td>
          ),
          action: ({ key }) => (
            <td key={key} align="right">
              <Button
                variant="danger mb-8"
                size="sm"
                className="rounded"
                onClick={() => handleShowDeleteBankAccount(bankAccount)}
              >
                Delete
              </Button>
            </td>
          ),
        }))}
      />
      <hr />
      <BillingDeleteBankAccountModal />
      <LinkedProgramDetailsModal
        linkedProgramsDetailModalState={linkedProgramsDetailModalState}
        selectedBankAccount={selectedBankAccount}
        linkedPrograms={linkedPrograms}
        onHide={handleHideLinkedProgramDetailsModal}
        editable
      />
      <LinkedPlatformDetailsModal
        selectedBankAccount={selectedBankAccount}
        show={linkedPlatformsDetailModalVisible}
        linkedPlatforms={linkedPlatforms}
        onHide={handleHideLinkedPlatformDetailsModal}
      />
    </Card>
  );
};

export default Billing;
