import Signal from 'libs/signals';

export const searchProgramsResultsSignal = Signal({
  fundingProgramMemberships: [],
  fundingPrograms: [],
  programRequests: [],
  dropdownFilters: {},
  sort: {},
});

export const searchProgramsResultsSelectedProgram = Signal({
  id: 1,
  name: '',
  edoName: '',
  minAmount: 0,
  maxAmount: 0,
  address: {},
  overview: '',
  detailsBenefits: null,
  stateProgramCeiling: null,
  fundsAlreadyAllocated: null,
  eligibility: [],
  referenceProgram: {},
  documentPath: 'TestDocument.pdf',
});

export const searchProgramsResultsModalStateSignal = Signal({
  show: false,
  step: 1,
});

export const searchProgramsResultsFilesSignal = Signal([]);

export const searchProgramsResultsFilesErrorSignal = Signal(false);

export const searchProgramsDocumentPreviewURLSignal = Signal('');

export const searchProgramsFundingProgramMembershipsSignal = Signal([]);

export const searchProgramsSuggestedFilesToUploadSignal = Signal({});

export const searchProgramsAdditionalFilesToUploadSignal = Signal([]);

export const searchProgramsResultsRequestedAmountSignal = Signal({
  requestedAmount: '',
  requestedAmountError: '',
});
