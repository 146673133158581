export const handleSimpleProgramSearchChange = (e, setCurrentPage, signal) => {
  setCurrentPage(0);
  const { value } = e.target;
  const { fundingPrograms } = signal.value;
  const filteredPrograms = fundingPrograms.filter((program) =>
    program.name.toLowerCase().includes(value.toLowerCase())
  );

  signal.update({ programsFilteredBySearch: filteredPrograms });
};
