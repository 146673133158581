import SimpleTable from "components/global/SimpleTable";
import fundDetailsSignal from "../fundDetails.signal";
import formatMoney from "utils/formatMoney";
import { Alert, Button } from "react-bootstrap";
import userAccountSignal from "signals/UserAccount.signal";
import { enumCaseToTabCase } from "libs/string";
import { useHistory } from "react-router-dom";
import { getStatusStyles } from "../../../../libs/functions/global.functions";
import $appSettings from "signals/AppSettings.signal";

const HEADERS = [
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'round',
    title: 'Round',
  },
  {
    key: 'totalAmount',
    title: 'Total Amount',
  },
  {
    key: 'suggestedDate',
    title: 'Date',
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const CapitalCallList = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const { capitalCalls } = fundDetailsSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  if (!capitalCalls.length) {
    return (
      <Alert className="mt-16 d-flex flex-row justify-content-between align-items-center">
        This fund does not have any capital calls.
        <Button
          className="rounded"
          variant="outline-primary"
          onClick={() => history.push(`${pathname}/create-capital-call`)}
        >
          Create a Capital Call
        </Button>
      </Alert>
    );
  }

  if (!!capitalCalls.length) {
    return (
      <div className="mt-16">
        <div className="d-flex flex-row justify-content-end mb-16">
          <Button
            className="rounded text-right"
            onClick={() => history.push(`${pathname}/create-capital-call`)}
          >
            {portalType === PORTAL_TYPES.vc ? 'New' : 'Load in'} Capital Call
          </Button>
        </div>
        <SimpleTable
          headers={HEADERS}
          rows={capitalCalls?.map((cc, index) => ({
            status: () => (
              <td className={`${getStatusStyles(cc.status).class}`}>
                <p className="mb-0">{enumCaseToTabCase(cc.status)}</p>
              </td>
            ),
            round: index + 1,
            capitalCallAmount: formatMoney(
              cc.capitalCallPortions.reduce(
                (acc, curr) => (acc += curr.amount),
                0
              )
            ),
            suggestedDate: new Date(cc.suggestedDate).toLocaleDateString(),
            action: () => (
              <td align="right">
                <Button
                  className="rounded"
                  variant="outline-dark"
                  size="sm"
                  onClick={() =>
                    history.push(
                      `${pathname}/capital-calls/${cc.id}`
                    )
                  }
                >
                  Details
                </Button>
              </td>
            ),
          }))}
        />
      </div>
    );
  }

  return (
    <div className="mt-16">
      <Alert>A capital call has not been created for this fund.</Alert>
    </div>
  );
};

export default CapitalCallList;
