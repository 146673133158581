export async function dataUrlToFile(
  dataUrl: string,
  fileName: string,
  type: string
): Promise<File> {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type });
}

export async function blobToDataUrl(blob: Blob): Promise<string | null> {
  return new Promise((res) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        res(reader.result);
      } else {
        res(null);
      }
    };
    reader.readAsDataURL(blob);
  });
}

export async function blobToArrayBuffer(
  blob: Blob
): Promise<ArrayBuffer | null> {
  return new Promise((res) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      res(e?.target?.result as ArrayBuffer);
    };

    reader.readAsArrayBuffer(blob);
  });
}

export function downloadFromUrl(
  url: string,
  filename: string
): Promise<unknown> {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}
