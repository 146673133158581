import Signal from "libs/signals";

const fundsPortfolioSignal = Signal({
  funds: [],
  dropdownFilters: {},
  appliedFilters: {},
  bundle: {},
});

export default fundsPortfolioSignal;
