import ApplicationDetail from 'components/global/ApplicationDetail/ApplicationDetail';
import { useCallback, useEffect } from 'react';
import { fetchApplication } from 'components/global/ApplicationDetail/ApplicationDetail.helpers';
import { useLocation } from 'react-router-dom';
import BreadCrumbWrapper from '../../../global/BreadCrumbs/BreadCrumbs';
import applicationDetailSignal from 'components/global/ApplicationDetail/ApplicationDetail.signal';
import SendTechnicalAssistanceModal from '../EdoTechnicalAssistance/components/SendTechnicalAssistanceModal';

const EdoPortfolioDetail = () => {
  const { pathname } = useLocation();
  const segments = pathname.split('/');
  const applicationId = segments[segments.length - 1];
  const { lender, business, fundingProgram } = applicationDetailSignal.value;

  let labels = [];
  if (pathname.includes('credit-program')) {
    labels = [
      '',
      !!fundingProgram ? fundingProgram.name : '',
      !!lender && !!business ? `${lender.name} - ${business.name}` : '',
    ];
  } else {
    labels = [
      '',
      !!lender && !!business ? `${lender.name} - ${business.name}` : '',
    ];
  }

  const init = useCallback(() => {
    fetchApplication(applicationId);
  }, [applicationId]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <SendTechnicalAssistanceModal
        onSendCallback={() => fetchApplication(applicationId)}
      />
      <ApplicationDetail />
    </BreadCrumbWrapper>
  );
};

export default EdoPortfolioDetail;
