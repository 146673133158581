import Signal from "libs/signals";

const $edoPerformanceCapitalCallsSignal = Signal({
  funds: [],
  capitalCalls: [],
  fundingProgram: null,
  fundingProgramMemberships: []
});

export default $edoPerformanceCapitalCallsSignal;
