import { DATA_ROOM_COMPONENT_VIEW_TYPES } from 'components/global/Constant/constants';
import FilePreviewButton from 'components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { Button, Table } from 'react-bootstrap';
import { isFileTypeValidForPreview } from 'utils/checkFileTypeForPreview';

const DocumentTable = ({
  documents,
  documentBucket,
  removeDocument,
  tableDescription,
  tableTitle,
  className,
  headerButton = null,
  viewType
}) => (
  <div className={className}>
    <h5 hidden={!tableTitle} className={viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.dataRoom ? 'text-center' : ''}>
      {tableTitle}
    </h5>
    <div className={`d-flex flex-row align-items-center ${!tableDescription ? 'justify-content-end' : 'justify-content-between'} ${headerButton ? 'mb-8' : ''}`}>
      <p hidden={!tableDescription} className="mb-0">{tableDescription}</p>
      {headerButton && (
        <Button
          className={headerButton.className}
          onClick={headerButton.onClick}
          variant={headerButton.variant}
        >
          {headerButton.label}
        </Button>
      )}
    </div>
    <Table className="mb-0">
      <thead>
        <tr>
          <th>Document Name</th>
          <th>Preview</th>
          <th hidden={!removeDocument}>Remove</th>
        </tr>
      </thead>
      <tbody>
        {documents.map((doc) => (
          <tr key={doc.id}>
            <td>{doc.name}</td>
            <td>
              {isFileTypeValidForPreview(doc.fileType) ? (
                <FilePreviewButton
                  render={(submitting, fn) => (
                    <Button
                      size="sm"
                      className="rounded"
                      variant="outline-primary"
                      onClick={() => fn(doc, documentBucket)}
                    >
                      View
                    </Button>
                  )}
                />
              ) : (
                <p>No preview available</p>
              )}
            </td>
            <td hidden={!removeDocument}>
              <Button
                size="sm"
                className="rounded sm"
                variant="outline-danger"
                onClick={() => removeDocument(doc)}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default DocumentTable;
