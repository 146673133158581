import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import businessSearchResultsSignal from "signals/BusinessSearchResults.signal";
import {
  fetchAndSetLenderData,
  handleRemoveSavedOpportunityFromSearchResults,
  handleSaveOpportunityFromSearchResults,
  handleSortChange,
  resetBusinessSearchResultsFilterInputs,
  setAppliedFilters,
  setDropdownFilter,
  toggleModal
} from "./BusinessSearchResults.helpers";
import SimpleTable from "../../../global/SimpleTable";
import SimpleTableFilterDropdown from "../../../global/SimpleTableFilterDropdown";
import { uniqById } from "utils/array";
import BusinessSearchResultModal from "./components/BusinessSearchResultModal";
import BusinessWrapper from "../components/BusinessWrapper";
import LargeCheckBox from "components/global/Constant/LargeCheckBox";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchSavedSearchResultAndRedirectToWithinToRefetch,
  locationSearchToObject
} from "libs/functions/global.functions";
import $appSettings from "signals/AppSettings.signal";
import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";

const BusinessSearchResults = () => {
  const { PROVIDER_TYPES } = $appSettings.value.constants;
  const {
    fundingProgramMemberships,
    dropdownFilters,
    sort,
    businessFavorites,
  } = businessSearchResultsSignal.value;
  const location = useLocation();
  const history = useHistory();

  const lenders = fundingProgramMemberships
    .filter((fpm) => !!fpm.lender)
    .map((fpm) => fpm.lender);
  const ventureCapitalFirms = fundingProgramMemberships
    .filter((fpm) => !!fpm.ventureCapitalFirm)
    .map((fpm) => fpm.ventureCapitalFirm);

  const uniqLenders = uniqById(lenders);
  const uniqVcfs = uniqById(ventureCapitalFirms);
  const uniqFundingPrograms = uniqById(
    fundingProgramMemberships.map((fpm) => fpm.fundingProgram)
  );

  useEffect(() => {
    const search = locationSearchToObject(location.search);
    if (search.savedSearchResultId) {
      fetchSavedSearchResultAndRedirectToWithinToRefetch(
        search.savedSearchResultId
      );
    } else {
      fetchAndSetLenderData();
    }
  }, [location.search]);

  useEffect(() => businessSearchResultsSignal.update({ modalOpen: false }), []);

  return (
    <BusinessWrapper>
      <BreadCrumbWrapper labels={['Search Opportunities', 'Search Results']}>
        <Container fluid className="py-16">
          <BusinessSearchResultModal />
          <Row>
            <Col>
              <Row>
                <Col className="text-center">
                  <h3 className="mb-16">Funding Opportunities</h3>

                  <Alert variant="info" className="my-24">
                    <p>
                      These are some of the institutions offering financing that
                      match your financial profile.
                    </p>
                  </Alert>
                </Col>
              </Row>

              <div className="bg-white p-32 border border-gray shadow-sm">
                <div className="d-flex mb-16">
                  <SimpleTableFilterDropdown
                    filtersState={dropdownFilters}
                    onChange={({ name, value }) =>
                      setDropdownFilter({ name, value })
                    }
                    onApply={(filters) => {
                      setAppliedFilters(filters);
                      fetchAndSetLenderData();
                    }}
                    onClearFilterInputs={resetBusinessSearchResultsFilterInputs}
                    filters={[
                      {
                        type: 'integer',
                        label: 'ID',
                        key: 'id',
                      },
                      {
                        type: 'select',
                        label: 'Lender Name',
                        key: 'lenderName',
                        options: uniqLenders
                          .map((lender) => ({
                            value: `${lender.id}-lender`,
                            label: lender.name,
                          }))
                          .concat(
                            uniqVcfs.map((vcf) => ({
                              value: `${vcf.id}-vc`,
                              label: vcf.name,
                            }))
                          ),
                      },
                      {
                        type: 'select',
                        label: 'Lender Type',
                        key: 'lenderType',
                        options: Object.values(PROVIDER_TYPES).map(
                          (lenderType) => ({
                            value: lenderType.value,
                            label: lenderType.annualReportLabel,
                          })
                        ),
                      },
                      {
                        type: 'select',
                        label: 'Requires Business Plan',
                        key: 'requiresBusinessPlan',
                        options: [
                          {
                            value: 'true',
                            label: 'Yes',
                          },
                          {
                            value: 'false',
                            label: 'No',
                          },
                        ],
                      },
                      {
                        type: 'select',
                        label: 'Funds Startups',
                        key: 'fundsStartups',
                        options: [
                          {
                            value: 'true',
                            label: 'Yes',
                          },
                          {
                            value: 'false',
                            label: 'No',
                          },
                        ],
                      },
                      {
                        type: 'select',
                        label: 'Program Name',
                        key: 'programName',
                        options: uniqFundingPrograms.map((fundingProgram) => ({
                          value: fundingProgram.id,
                          label: fundingProgram.name,
                        })),
                      },
                    ]}
                  />
                </div>
                <SimpleTable
                  headers={[
                    {
                      key: 'id',
                      title: 'ID',
                      sortable: true,
                    },
                    {
                      key: 'lenderName',
                      title: 'Lender Name',
                      sortable: true,
                    },
                    {
                      key: 'lenderType',
                      title: 'Lender Type',
                      sortable: true,
                    },
                    {
                      key: 'requiresBusinessPlan',
                      title: 'Requires Business Plan',
                      sortable: true,
                    },
                    {
                      key: 'fundsStartups',
                      title: 'Funds Startups',
                      sortable: true,
                    },
                    {
                      key: 'programName',
                      title: 'Program Name',
                      sortable: true,
                    },
                    {
                      key: 'details',
                      title: 'Details',
                    },
                  ]}
                  rows={fundingProgramMemberships.map((fpm) => {
                    let type = 'lenders';
                    if (fpm.ventureCapitalFirm) {
                      type = 'vc';
                    }

                    const organization =
                      type === 'lenders' ? fpm.lender : fpm.ventureCapitalFirm;

                    const isFavorite = businessFavorites?.find((fav) => {
                      if (type === 'lenders') {
                        return fav.lenderId === organization.id;
                      } else {
                        return fav.ventureCapitalFirmId === organization.id;
                      }
                    });

                    return {
                      id: fpm.id,
                      lenderName:
                        fpm.lender?.name || fpm.ventureCapitalFirm?.name,
                      lenderType:
                        Object.values(PROVIDER_TYPES).find(
                          (lenderType) =>
                            (fpm.lender?.lenderType ||
                              fpm.ventureCapitalFirm?.lenderType) ===
                            lenderType.value
                        )?.annualReportLabel || '',
                      requiresBusinessPlan: ({ key }) => (
                        <td key={key}>
                          {fpm.lender?.requiresBusinessPlan ||
                          fpm.ventureCapitalFirm?.requiresBusinessPlan ? (
                            <LargeCheckBox
                              checked
                              disabled
                              size="sm"
                              centeringClass="text-left"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="text-muted ml-8"
                              icon={faTimes}
                            />
                          )}
                        </td>
                      ),
                      fundsStartups: ({ key }) => (
                        <td key={key}>
                          {fpm.lender?.fundsStartups ||
                          fpm.ventureCapitalFirm?.fundsStartups ? (
                            <LargeCheckBox
                              checked
                              disabled
                              size="sm"
                              centeringClass="text-left"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="text-muted ml-8"
                              icon={faTimes}
                            />
                          )}
                        </td>
                      ),
                      programName: fpm.fundingProgram.name,
                      details: ({ key }) => (
                        <td key={key}>
                          <div className="d-flex flex-row justify-content-end">
                            <Button
                              size="sm"
                              hidden={isFavorite}
                              onClick={() =>
                                handleSaveOpportunityFromSearchResults({
                                  lenderId:
                                    type === 'lenders' ? organization.id : null,
                                  ventureCapitalFirmId:
                                    type === 'vc' ? organization.id : null,
                                })
                              }
                              className="rounded mr-8"
                              variant="primary"
                            >
                              Save
                            </Button>
                            <Button
                              hidden={!isFavorite}
                              onClick={() =>
                                handleRemoveSavedOpportunityFromSearchResults(
                                  isFavorite.id
                                )
                              }
                              className="rounded mr-8"
                              variant="outline-primary"
                              size="sm"
                            >
                              Unsave
                            </Button>
                            <Button
                              variant="outline-dark"
                              size="sm"
                              className="rounded"
                              onClick={() =>
                                history.push(
                                  `${location.pathname}/${type}/${
                                    fpm.lender?.id || fpm.ventureCapitalFirm?.id
                                  }`
                                )
                              }
                            >
                              Details
                            </Button>
                          </div>
                        </td>
                      ),
                    };
                  })}
                  onSort={(sort) => {
                    handleSortChange(sort);
                    fetchAndSetLenderData();
                  }}
                  onClick={(index) =>
                    toggleModal(fundingProgramMemberships[index])
                  }
                  sort={sort}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Alert variant="info" className="my-24">
                <p>
                  If you are interested in other resources to help your
                  business, please visit the{' '}
                  {window.location.hostname === 'al.caat.io' ? (
                    <a
                      rel={'noreferrer'}
                      href="https://www.asbdc.org/office-locations/"
                      target="_blank"
                    >
                      Alabama Small Business Development Center.
                    </a>
                  ) : (
                    <a
                      rel={'noreferrer'}
                      href="https://louisianabizhub.com/resources/?filter5Ids=44791&serviceCategoryIds=3160338&serviceIds=209498&sort_by=&user_sorted=&slrn_submit=1"
                      target="_blank"
                    >
                      {window.location.hostname === 'la.caat.io' ? "Louisiana Business Hub's Resource Navigator" : "Business Hub's Resource Navigator"}
                    </a>
                  )}
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      </BreadCrumbWrapper>
    </BusinessWrapper>
  );
};

export default BusinessSearchResults;
