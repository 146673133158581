import userAccountSignal from "signals/UserAccount.signal";
import history from '../history';

const REDIRECTOR = {
  'EDO-Application': (id) => `/edo/portfolio/${id}`,
  'EDO-Business': (id) => `/edo/business/${id}`,
  'EDO-CapitalCall': (id) => `/edo/capital-calls/${id}`,
  'EDO-Fund': (id) => `/edo/funds/${id}`,
  'EDO-FundingProgram': (id) => `/edo/programs/${id}`,
  'EDO-Lender': (id) => `/edo/lenders/${id}`,
  'EDO-ProgramRequest': (id, record) => {
    const idMapping = record.idMapping;
    if (idMapping.lenderId) {
      return `/edo/lenders/program-requests/${id}`;
    } else if (idMapping.ventureCapitalFirmId) {
      return `/edo/vc/program-requests/${id}`;
    } else {
      return `/edo/program-requests/${id}`;
    }
  },
  'EDO-VentureCapitalFirm': (id) => `/edo/vc/${id}`,

  'LENDER-Application': (id) => `/lender/portfolio/${id}`,
  'LENDER-Business': (id) => `/lender/businesses/${id}`,
  'LENDER-ProgramRequest': (id) => `/lender/program-requests/${id}`,

  'VC-Fund': (id) => `/vc/funds/${id}`,
  'VC-CapitalCall': (id) => `/vc/capital-calls/${id}`,
  'VC-Business': (id) => `/vc/businesses/${id}`,
  'VC-ProgramRequest': (id) => `/vc/program-requests/${id}`,

  'BUSINESS-Fund': (id) => `/business/funds/${id}`,
  // 'BUSINESS-CapitalCall': (id) => `/business/capital-calls/${id}`,
  'BUSINESS-Application': (id) => `/business/applications/${id}`,
  'BUSINESS-FundingProgram': (id) => `/business/funding-programs/${id}`,
};

export function redirectToResource(record) {
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { recordId, recordType } = record;
  const key = `${portalType}-${recordType}`;

  return history.push(REDIRECTOR[key](recordId, record));
}

export function redirectToResourceFromGlobalSearch(record) {
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { recordId, recordType } = record;
  const key = `${portalType}-${recordType}`;
  const url = REDIRECTOR[key](recordId, record);
  const urlParts = url.split('/');
  urlParts.splice(2, 0, 'search-all');
  const newUrl = urlParts.join('/');

  return history.push(newUrl);
}

export const handleGlobalSearch = (query) => {
  const portal = history.location.pathname.split('/')[1];
  localStorage.setItem('query', query);
  history.push(`/${portal}/search-all?query=${query}`);
};