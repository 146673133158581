import EdoOrganizationSummary from '../shared/EdoOrganizationSummary';
import $appSettings from 'signals/AppSettings.signal';

const EdoVC = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;

  return (
    <EdoOrganizationSummary portalType={PORTAL_TYPES.vc} />
  );
}


export default EdoVC;
