import edoProgramRequestsSignal from "signals/EdoProgramRequests.signal";
import loaderSignal from "signals/Loader.signal";
import api from "api/api";
import userAccountSignal from "signals/UserAccount.signal";
import history from "../../../../history";
import alertSignal from "signals/Alert.signal";

export const fetchAndSetEdoProgramRequests = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const bundle = await api.get({
      path: '/tally/programRequests',
    });

    edoProgramRequestsSignal.update({ bundle });

    const programRequests = await api.get({
      path: '/programRequests',
      options: {
        orderBy: xProgramRequestFormSortToOrderByClause(),
        where: xProgramRequestFormAppliedFiltersToWhereClause(),
        include: {
          documents: true,
          lender: true,
          ventureCapitalFirm: true,
          fundingProgram: {
            include: {
              platform: true,
              edo: true,
              supportingDocuments: true,
              referenceProgram: {
                include: {
                  programType: true,
                },
              },
              bankAccountProgramAccesses: {
                include: {
                  bankAccount: true,
                },
              },
            },
          },
          notes: true,
          watchables: true,
          logs: {
            include: {
              user: {
                select: {
                  firstName: true,
                  lastName: true,
                },
              },
            },
          },
        },
      },
    });
    edoProgramRequestsSignal.update({ programRequests });
  } finally {
    loaderSignal.reset();
  }
};

const xProgramRequestFormAppliedFiltersToWhereClause = () => {
  const where = {
    fundingProgram: {
      is: {
        edoId: userAccountSignal.value.userData.account.edo.id,
      },
    },
  };

  const pathname = history.location.pathname;
  if (pathname.includes('/edo/vc')) {
    where.lender = null;
  } else {
    where.ventureCapitalFirm = null;
  }

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.programName) {
    where.fundingProgram.is.id = Number(params.programName);
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.status) {
    where.status = params.status;
  }

  return where;
};

const xProgramRequestFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  if (key === 'programName') {
    return [
      {
        fundingProgram: {
          name: dir,
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

export const handleDownloadLenderProgramRequestsData = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    await api.get({
      path: '/programRequests/download',
      options: {
        orderBy: xProgramRequestFormSortToOrderByClause(),
        where: xProgramRequestFormAppliedFiltersToWhereClause(),
      },
      isFileDownload: true,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleEdoLenderProgramRequestDetailClick = (programRequestId) => {
  history.push(
    `${history.location.pathname}/program-requests/${programRequestId}`
  );
};
