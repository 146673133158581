import api from "api/api";
import alertSignal from "signals/Alert.signal";
import VCDealFlowsSignal from "./VCDealFlows.signal";
import { Signal } from "@preact/signals-react";
import userAccountSignal from "signals/UserAccount.signal";
import loaderSignal from "signals/Loader.signal";
import { organizationDetailsSignal } from "components/global/OrganizationsList/OrganizationsList.signal";
import { VcPortfolioCompany } from "types";
import history from "../../../../history";
import addPortfolioCompanySignal from "components/global/AddPortfolioCompany/addPortfolioCompany.signal";

export const fetchAndSetVcDealFlowData = async (): Promise<Signal<unknown>> => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const [funds, vcPortfolioCompanies] = await Promise.all([
      api.get({
        path: '/funds',
        options: {
          where: {
            fundingProgramMembership: {
              is: {
                ventureCapitalFirmId:
                  userAccountSignal.value.userData.account.ventureCapitalFirm
                    ?.id,
              },
            },
          },
        },
      }),
      api.get({
        path: '/vcPortfolioCompanies',
        options: {
          orderBy: xFormSortToOrderByClause(),
          where: xFormAppliedFiltersToWhereClause(),
          include: {
            fund: {
              include: {
                fundingProgramMembership: true,
              },
            },
            business: {
              include: {
                businessDocuments: true,
              },
            },
            capitalCallPortions: true,
          },
        },
      }),
    ]);

    addPortfolioCompanySignal.update({ funds });
    return VCDealFlowsSignal.update({ vcPortfolioCompanies });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const showNewVcPortfolioCompanyModal = (): Signal<unknown> =>
  addPortfolioCompanySignal.update({ modalVisible: true });

export const handleHideRemovePortfolioCompanyModal = (): Signal<unknown> =>
  VCDealFlowsSignal.update({
    removeVcPortfolioCompany: false,
    selectedVCPortfolioCompany: null,
  });

export const handleRemovePortfolioCompanyClick = (
  company: VcPortfolioCompany
): Signal<unknown> =>
  VCDealFlowsSignal.update({
    removeVcPortfolioCompany: true,
    selectedVCPortfolioCompany: company,
  });

export const handleConfirmRemoveClick = async (): Promise<Signal<unknown>> => {
  try {
    loaderSignal.update({ isContentLoading: true });
    await api.delete({
      path: '/vcPortfolioCompanies',
      body: {
        where: {
          id: VCDealFlowsSignal.value.selectedVCPortfolioCompany?.id,
        },
      },
    });

    await fetchAndSetVcDealFlowData();
    handleHideRemovePortfolioCompanyModal();
    return alertSignal.update({
      variant: 'success',
      type: 'notification',
      message: 'Successfully deleted portfolio company.',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchVCPortfolioCompany = async (
  vcPortfolioCompanyId: number
): Promise<Signal<unknown>> => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const vcPortfolioCompany = await api.get({
      path: '/vcPortfolioCompanies',
      options: {
        where: {
          id: Number(vcPortfolioCompanyId),
        },
        include: {
          business: {
            include: {
              businessDocuments: true,
            },
          },
          capitalCallPortions: true,
        },
      },
    });

    organizationDetailsSignal.update({
      selectedOrganization: vcPortfolioCompany[0].business,
    });
    return VCDealFlowsSignal.update({
      selectedVCPortfolioCompany: vcPortfolioCompany[0],
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      // @ts-ignore
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handlePortfolioCompanyDetailClick = (
  vcPorfolioCompanyId: number
): void => history.push(`/vc/portfolio-companies/${vcPorfolioCompanyId}`);

export const handlePortfolioCompanyRowClick = (
  vcPorfolioCompany: VcPortfolioCompany
): Signal<unknown> => {
  organizationDetailsSignal.update({
    selectedOrganization: vcPorfolioCompany.business,
  });
  return VCDealFlowsSignal.update({
    selectedVCPortfolioCompany: vcPorfolioCompany,
    showDetailsModal: true,
  });
};

export const handleCloseDetailModal = (): Signal<unknown> => {
  organizationDetailsSignal.reset();
  return VCDealFlowsSignal.update({
    selectedVCPortfolioCompany: null,
    showDetailsModal: false,
  });
};

const xFormSortToOrderByClause = () => {
  const params = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  const { sort } = params;

  if (!sort) {
    return [
      {
        id: 'desc',
      },
    ];
  }

  const [key, dir] = sort.split('-');
  if (key === 'businessName') {
    return [
      {
        business: {
          name: dir,
        },
      },
    ];
  } else if (key === 'fund') {
    return [
      {
        fund: {
          fundName: dir,
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const where: Record<string, any> = {
    fund: {
      is: {
        fundingProgramMembership: {
          is: {
            ventureCapitalFirmId:
              userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
          },
        },
      },
    },
  };

  const params: Record<string, any> = Object.fromEntries(
    new URLSearchParams(history.location.search)
  );

  if (params.businessName) {
    where.business = {
      name: {
        contains: params.businessName,
        mode: 'insensitive',
      },
    };
  }

  if (params.fundName) {
    where.fund.is = {
      ...where.fund.is,
      fundName: {
        contains: params.fundName,
        mode: 'insensitive',
      },
    };
  }

  if (params.watchables) {
    where.watchables = {
      some: {},
    };
  }

  return where;
};
