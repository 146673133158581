import formatMoney from "utils/formatMoney";
import { COLORS } from "../Constant/constants";

// ====================== Doughnut chart ==========================
export const applicationsChartPresets = {
  labels: [
    'Approved',
    'Submitted for Approval',
    'Pending',
    'Incomplete',
    'Denied',
    'Draft',
    'Default',
  ],
  datasets: [
    {
      label: '# of applications',
      data: [0, 0, 0, 0, 0],
      backgroundColor: [
        COLORS.SUCCESS,
        COLORS.PRIMARY,
        COLORS.WARNING,
        COLORS.ORANGE,
        COLORS.DANGER,
        COLORS.SECONDARY,
        COLORS.DARK,
      ],
      borderColor: [
        COLORS.SUCCESS,
        COLORS.PRIMARY,
        COLORS.WARNING,
        COLORS.ORANGE,
        COLORS.DANGER,
        COLORS.SECONDARY,
        COLORS.DARK,
      ],
      borderWidth: 1,
    },
  ],
};

export const businessSummaryChartPreset = {
  labels: ['Total Borrowing', 'Total Outstanding'],
  datasets: [
    {
      label: 'Total Borrowing vs. Outstanding',
      data: [0, 0],
      backgroundColor: ['#679859', '#B7CA3D'],
      borderColor: ['#44653B', '#94A331'],
      borderWidth: 1,
    },
  ],
};

export const automatedComplianceChartPresets = {
  labels: ['Compliant', 'Failed', 'Manual Verification'],
  datasets: [
    {
      label: 'Automated Compliance',
      data: [40, 8, 4],
      backgroundColor: ['#5FA37E', '#955ECF', '#4F8AD0'],
      borderColor: ['#508A6B', '#6D4597', '#3C689E'],
      borderWidth: 1,
    },
  ],
};

export const generalDoughnutChartOptions = {
  plugins: {
    legend: {
      position: 'bottom',
      onClick: null,
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      display: function (context) {
        return context.dataset.data[context.dataIndex] > 0;
      },
    },
  },
};

export const allocatedFundsChartOptions = {
  layout: {
    padding: {
      top: 24,
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: (context) => context[0].label,
        label: (context) => formatMoney(context.dataset.data[context.dataIndex])
      },
    },
    legend: {
      position: 'bottom',
      onClick: null,
      labels: {
        padding: 24,
        boxWidth: 20,
      },
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
      display: (context) => (context.dataset.data[context.dataIndex] / 1000000).toFixed(2) < 0.01 ? false : 'auto',
      formatter: (value) => typeof value === 'number' ? `$${(value / 1000000).toFixed(2)}M` : '',
      anchor: 'center',
      align: () => 'end',
    },
  },
};

export const generalDoughnutChartWhiteTxtOptions = {
  plugins: {
    legend: {
      position: 'bottom',
      onClick: null,
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      display: function (context) {
        return context.dataset.data[context.dataIndex] > 0;
      },
    },
  },
  color: '#FFFFFF',
};

// ========================= Line Graph =============================
export const submissionsLineGraphPresets = {
  labels: ['1', '2', '3', '4', '5', '6', '7'],
  datasets: [
    {
      label: 'Submissions per day',
      data: [20, 40, 80, 12, 22, 19, 10],
      borderColor: '#5E8FC1',
      backgroundColor: '#7CBDFF',
    },
  ],
};

export const lineGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
      onClick: null,
    },
    title: {
      display: true,
    },
    datalabels: {
      labels: {
        value: {
          color: 'black',
          anchor: 'end',
          align: 'end',
          offset: 4,
        },
      },
      display: function (context) {
        return context.dataset.data[context.dataIndex] > 0;
      },
    },
  },
};

// ========================== Bar Graph ==============================
export const businessDemographicsBarGraphPresets = {
  // labels: DASHBOARD_DEMOGRAPHICS_LABELS,
  datasets: [
    {
      label: '',
      data: [28000, 42000, 3000, 8000, 10000],
      backgroundColor: ['#AE1910', '#E69A29', '#5579D4', '#517527', '#6151A4'],
    },
  ],
};

export const barGraphOptions = (labels) => ({
  responsive: true,
  scales: {
    x: {
      ticks: {
        callback: function (val, index) {
          const label = labels?.[index];
          if (label?.length < 8) {
            return label;
          }

          return label.slice(0, 8) + '...';
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      onClick: null,
    },
    title: {
      display: true,
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
    },
  },
});

// =========================== Pie Graph ================================
export const loanAmountPieChartPresets = {
  labels: ['Under $249,999', '$250-499,999', '$500K-1M', 'Over 1M'],
  datasets: [
    {
      label: 'Loan Amount Breakdown',
      data: [46, 28, 12, 14],
      backgroundColor: ['#679859', '#B7CA3D', '#F28E09', '#CD6D65'],
      borderColor: ['#44653B', '#94A331', '#CC7707', '#AC5B55'],
      borderWidth: 1,
    },
  ],
};

// ========================= Horizontal Bar Graph ============================

export const topThreeChannelsGraphPresets = {
  labels: ['Website', 'Google Search', 'Referral'],
  datasets: [
    {
      data: [38, 22, 41],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

export const topThreeCampaignsPresets = {
  labels: ['SoCalLMI', 'NonCalLMI', 'LMI General'],
  datasets: [
    {
      data: [38, 22, 41],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

export const horizontalBarGraphOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      onClick: null,
    },
    title: {
      display: true,
    },
  },
};

export const generalMixedChartOptions = {
  // If they want a tiered x-axis labels
  // scales: {
  //   x: {
  //     ticks: {
  //       callback: function (label) {
  //         const realLabel = this.getLabelForValue(label)
  //         const quarter = realLabel.split(";")[0];
  //         return quarter;
  //       },
  //     },
  //   },
  //   xAxis2: {
  //     type: "category",
  //     grid: {
  //       drawOnChartArea: false,
  //     },
  //     ticks: {
  //       callback: function (label) {
  //         const realLabel = this.getLabelForValue(label)

  //         const quarter = realLabel.split(";")[0];
  //         const year = realLabel.split(";")[1];
  //         if (quarter === "Q1") {
  //           return year;
  //         } else {
  //           return "";
  //         }
  //       }
  //     }
  //   },
  //   y: {
  //     beginAtZero: true
  //   },
  // },
  layout: {
    padding: {
      top: 30,
    },
  },
  plugins: {
    legend: {
      display: false,
      onClick: null,
    },
    datalabels: {
      labels: {
        value: {
          color: 'black',
          anchor: 'end',
          align: 'end',
          clip: false,
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      display: function (context) {
        return (
          context.dataset.data[context.dataIndex] > 0 &&
          context.dataset.type !== 'line'
        );
      },
    },
  },
  color: '#FFFFFF',
};

export const mixedChartDataPreset = {
  datasets: [
    {
      type: 'line',
      label: 'Line Dataset',
      data: [4, 18, 15, 36],
      borderColor: ['#679859'],
    },
    {
      type: 'bar',
      label: 'Bar Dataset',
      data: [10, 20, 30, 40],
      backgroundColor: ['#294A6A'],
    },
  ],
  labels: ['Q1-2023', 'Q2-2023', 'Q3-2023', 'Q4-2023', 'Q1-2024', 'Q2-2024'],
};
