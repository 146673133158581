import { Button, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import edoUsersSignal from 'signals/EdoUsers.signal';
import {
  clearEdoSettingsUserDetailUser,
  handleProgramAccessUpdate,
} from '../EdoUsers.helpers';
import UserDetailsTab from './UserDetailsTab';
import ProgramAccess from 'components/global/ProgramAccess/ProgramAccess';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';

const EdoSettingsUserDetailsModal = () => {
  const { selectedUser, form, fundingPrograms } = edoUsersSignal.value;

  return (
    <ModalWrapper
      centered
      size="lg"
      show={!!selectedUser}
      className="border border-dark rounded"
      onHide={clearEdoSettingsUserDetailUser}
    >
      <Modal.Header className="pb-0 pl-32 bg-light border-0 justify-content-end">
        <CloseModalButton
          onCloseModal={() => clearEdoSettingsUserDetailUser()}
        />
      </Modal.Header>
      <Modal.Body className="bg-light rounded px-32 pt-0">
        <Tabs className="mb-8">
          <Tab eventKey="userDetails" title="User Details">
            <UserDetailsTab />
          </Tab>
          <Tab eventKey="programAccess" title="Program Access">
            <ProgramAccess
              programAccesses={selectedUser?.edoUserProgramAccesses}
              fundingPrograms={fundingPrograms}
              userRolesPerProgram={form.edoUserProgramAccesses}
              onProgramAccessUpdate={handleProgramAccessUpdate}
            />
          </Tab>
        </Tabs>
        <Row className="px-16 mt-16 justify-content-end">
          <Button
            className="rounded mr-8"
            variant="secondary"
            onClick={clearEdoSettingsUserDetailUser}
          >
            Close
          </Button>
        </Row>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default EdoSettingsUserDetailsModal;
