import states from 'components/global/Constant/usStates';

export const stateSelections = [
  states.newYork,
  states.california,
  states.florida,
  states.newJersey,
];

export const tempDB = [
  {
    id: 'a',
    year: '2023',
    state: states.california,
    program: { id: '2', title: 'SSBCI 2.0' },
    programTitle: 'SSBCI 2.0',
    ein: '323210923',
    files: [
      new File(['Placeholder'], 'File 1.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 2.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 3.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 4.pdf', { type: 'application/pdf' }),
    ],
  },
  {
    id: 'b',
    year: '2023',
    state: states.california,
    program: { id: '2', title: 'SSBCI 2.0' },
    ein: '323211192',
    files: [
      new File(['Placeholder'], 'File 1.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 2.pdf', { type: 'application/pdf' }),
    ],
  },
  {
    id: 'c',
    year: '2023',
    state: states.california,
    program: { id: '2', title: 'SSBCI 2.0' },
    ein: '323214937',
    files: [
      new File(['Placeholder'], 'File 1.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 2.pdf', { type: 'application/pdf' }),
    ],
  },
  {
    id: 'd',
    year: '2023',
    state: states.newYork,
    program: { id: '2', title: 'SSBCI 2.0' },
    ein: '323214937',
    files: [
      new File(['Placeholder'], 'File 1.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 2.pdf', { type: 'application/pdf' }),
    ],
  },
  {
    id: 'e',
    year: '2023',
    state: states.newYork,
    program: { id: '2', title: 'SSBCI 2.0' },
    ein: '323214937',
    files: [
      new File(['Placeholder'], 'File 1.pdf', { type: 'application/pdf' }),
      new File(['Placeholder'], 'File 2.pdf', { type: 'application/pdf' }),
    ],
  },
];
