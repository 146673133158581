import { programAccessSignal } from './programAccess.signal';

export const handleSimpleProgramSearchChange = (e, fundingPrograms) => {
  const { value } = e.target;
  const filteredPrograms = fundingPrograms.filter((program) =>
    program.name.toLowerCase().includes(value.toLowerCase())
  );

  programAccessSignal.update({ programsFilteredBySearch: filteredPrograms });
};

export default {};
