import { billingIcon, securityIcon, userIcon, usersIcon } from 'components/global/Constant/Icons';
import SettingsButton from 'components/global/SettingsButton/SettingsButton';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import stateBillingSignal from 'signals/StateBilling.signal';
import stateOrganizationsSignal from 'signals/StateOrganizations.signal';
import stateUsersSignal from 'signals/StateUsers.signal';
import { fetchAndSetPaymentAccounts } from '../StateBilling/StateBilling.helpers';
import { fetchAndSetStateOrganizationsData } from '../StateOrganizations/StateOrganizations.helpers';
import { fetchAndSetStateUsers } from '../StateUsers/StateUsers.helpers';

const StateSettings = () => {
  const activeUsers = stateUsersSignal.value.stateUsers?.filter(user => user.status).length;
  const activeEdo = stateOrganizationsSignal.value.edoData?.filter(client => client.status).length;
  const activeAccounts = stateBillingSignal.value.paymentAccounts?.length;

  useEffect(() => {
    fetchAndSetStateUsers();
    fetchAndSetStateOrganizationsData();
    fetchAndSetPaymentAccounts();
  }, []);

  return (
    <Row>
      <Col>
        <SettingsButton
          title="Users"
          activeAmount={activeUsers}
          description="Active Users: "
          img={userIcon}
          route="settings/users"
        />
      </Col>
      <Col>
        <SettingsButton
          title="Organizations"
          activeAmount={activeEdo}
          description="Active Organizations: "
          img={usersIcon}
          route="settings/organizations"
        />
      </Col>
      <Col>
        <SettingsButton
          title="Billing"
          activeAmount={activeAccounts}
          description="Active Billing Accounts: "
          img={billingIcon}
          route="settings/billing"
        />
      </Col>
      <Col>
        <SettingsButton
          title="Security"
          img={securityIcon}
          route="settings/security"
        />
      </Col>
    </Row>
  );
};

export default StateSettings;
