import { drawerNotificationsSignal } from "signals/Notification.signal";
import { fetchNotificationsForDrawer, markNotificationAsRead } from "api/notification.api";

export const fetchAndSetDrawerNotifications = async (showLoader = true) => {
  const notifications = await fetchNotificationsForDrawer(showLoader);
  return drawerNotificationsSignal.update(notifications);
};

export const markRead = async (notificationId) => {
  await markNotificationAsRead(notificationId);
  await fetchAndSetDrawerNotifications();
};
