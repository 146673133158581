import Signal from "libs/signals";

const edoTechnicalAssistanceSignal = Signal({
  technicalAssistances: [],
  title: '',
  supportingText: '',
  type: '',
  modalType: null,
  id: null,
  deleteTechnicalAssistancePromptVisible: false,
  totalPages: 1,
  recordsPerPage: 20,
  technicalAssistanceErrors: {},
  selectedTechnicalAssistanceId: null,
  applicationId: null,
  sentTechnicalAssistanceId: null,
  sendTechnicalAssistanceModalState: 'USE_NEW',
});

export default edoTechnicalAssistanceSignal;
