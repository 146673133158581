import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const Select = ({
  label,
  disabled,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
  options,
  lineBreak = false,
  onBlur,
  noDefaultOption,
  inputRef,
}) => (
  <div className={className}>
    {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
    {label && lineBreak && <br />}
    <Form.Select
      ref={inputRef}
      disabled={disabled}
      name={name}
      aria-label={label}
      value={value}
      className={inputClassName}
      onChange={(e) => handleInputChange(e, onChange)}
      onBlur={onBlur}
    >
      {!noDefaultOption && (
        <option defaultChecked value="">
          Select an Option
        </option>
      )}
      {options.map((option, idx) => (
        <option key={name + idx.toString()} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  </div>
);

export default Select;
