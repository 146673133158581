/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { signal, Signal as SignalType } from '@preact/signals-react';

export type Sygnal<T extends Record<string, unknown> | boolean | null> =
  SignalType<T> & {
    update: (payload: Partial<T> | null) => SignalType<T>;
    reset: () => undefined;
  };

export const Signal = <T extends Record<string, unknown> | null | boolean>(
  initState: T
): Sygnal<T> => {
  const rawSignal = signal<T>(initState);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  rawSignal.update = (payload) => updateSignal(rawSignal, payload);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  rawSignal.reset = () => {
    rawSignal.value = initState;
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return rawSignal;
};

// const updateKey = (signalToUpdate, key, newVal) => {
//   if (!signalToUpdate) {
//     throw new Error('Signal is not defined');
//   }
//
//   signalToUpdate.value = { ...signalToUpdate.value, [key]: { ...signalToUpdate.value[key], ...newVal } };
//
//   return signalToUpdate;
// };
//
export const updateSignal = <T extends Record<string, unknown> | null | boolean>(
  signalToUpdate: Sygnal<T>,
  newVal: T
) => {
  if (!signalToUpdate) {
    throw new Error('Signal is not defined');
  }

  if (newVal === null) {
    // @ts-ignore
    signalToUpdate.value = null;
  } else if (Array.isArray(signalToUpdate.value)) {
    // @ts-ignore
    signalToUpdate.value = [...newVal];
  } else if (typeof signalToUpdate.value === 'object') {
    if (signalToUpdate.value === null) {
      signalToUpdate.value = newVal;
    } else {
      signalToUpdate.value = {
        // @ts-ignore
        ...signalToUpdate.value,
        // @ts-ignore
        ...newVal,
      };
    }
  } else {
    signalToUpdate.value = newVal;
  }

  return signalToUpdate;
};

export default Signal;
