import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";
import OrganizationsList from "components/global/OrganizationsList/OrganizationsList";
import organizationsListSignal from "components/global/OrganizationsList/OrganizationsList.signal";
import { useLocation } from "react-router-dom";
import $appSettings from "signals/AppSettings.signal";

const EdoLenderProgramMembershipDetail = () => {
  const location = useLocation();
  const { fundingProgram, lender } = organizationsListSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  let labels = [];

  if (location.pathname.startsWith('/edo/programs')) {
    labels = [
      '',
      !!fundingProgram ? fundingProgram.name : '',
      !!fundingProgram ? `Businesses enrolled in ${fundingProgram.name}` : '',
    ];
  } else {
    labels = [
      '',
      !!lender ? lender.name : '',
      !!fundingProgram ? `Businesses enrolled in ${fundingProgram.name}` : '',
    ];
  }


  return (
    <BreadCrumbWrapper
      labels={labels}
    >
      <div className="border border-gray shadow-sm bg-white p-40">
        <OrganizationsList
          portalType={PORTAL_TYPES.business}
          tableTitle="Enrolled Businesses"
          newPath={`${location.pathname}/business`}
        />
      </div>
    </BreadCrumbWrapper>
  );
}


export default EdoLenderProgramMembershipDetail;
