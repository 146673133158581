import edoOrganizationSignal from 'signals/EdoOrganization.signal';
import 'react-phone-number-input/style.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import {
  getAddressOptions,
  handleAddressBlur,
  handleAddressSearchChange,
  handleAddressSearchFocus,
  handleError,
  handleInputChange,
  handleSelectAddress,
  submitOrganizationDetails,
} from '../edoOrganization.helpers';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { useEffect } from 'react';
import Field from 'components/global/Field/Field';

const DetailsTab = () => {
  const {
    organizationInfo,
    organizationInfoErrors,
    addressSearch,
    addressOptions,
  } = edoOrganizationSignal.value;

  useEffect(() => {
    getAddressOptions();
  }, [addressSearch]);

  return (
    <div className="border border-gray p-32 shadow-sm bg-white">
      <Row>
        <Col>
          <Field.TextInput
            label="Name"
            labelClassName="font-weight-bold"
            name="name"
            value={organizationInfo.name}
            onChange={handleInputChange}
            onBlur={(e) => handleError(e.target.name)}
            isInvalid={organizationInfoErrors.name}
          />
          <small className="text-danger" hidden={!organizationInfoErrors.name}>
            This field is required.
          </small>
        </Col>
        <Col>
          <Field.TextInput
            label="Contact Name"
            labelClassName="font-weight-bold"
            name="contactName"
            value={organizationInfo.contactName}
            onChange={handleInputChange}
            onBlur={(e) => handleError(e.target.name)}
            isInvalid={organizationInfoErrors.contactName}
          />
          <small
            className="text-danger"
            hidden={!organizationInfoErrors.contactName}
          >
            This field is required.
          </small>
        </Col>
        <Col>
          <Field.TextInput
            label="Email"
            labelClassName="font-weight-bold"
            name="contactEmail"
            value={organizationInfo.contactEmail}
            onChange={handleInputChange}
            onBlur={(e) => handleError(e.target.name)}
            isInvalid={organizationInfoErrors.contactEmail}
          />
          <small
            className="text-danger"
            hidden={!organizationInfoErrors.contactEmail}
          >
            Please provide a valid email address. Email usernames should be at
            least 2 characters and begin/end with letters. The only special
            characters allowed are ._+-. There cannot be more than one
            occurrence of the top level domain - ex. .com
          </small>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="font-weight-bold" htmlFor="phoneNumber">
              Phone
            </Form.Label>
            <PhoneInput
              international={false}
              addInternationalOption={false}
              countries={['US']}
              id="phoneNumber"
              numberInputProps={{
                className: `form-control${
                  organizationInfoErrors.phoneNumber && ' border-danger'
                }`,
                name: 'phoneNumber',
              }}
              country="US"
              defaultCountry="US"
              className="border-danger"
              value={
                organizationInfo?.phoneNumber
                  ? formatPhoneNumber(organizationInfo?.phoneNumber)
                  : ''
              }
              countryCallingCodeEditable={false}
              limitMaxLength
              onChange={(value) =>
                handleInputChange({ name: 'phoneNumber', value })
              }
              onBlur={() => handleError('phoneNumber')}
            />
            <small
              className="text-danger"
              hidden={!organizationInfoErrors.phoneNumber}
            >
              Please enter a valid phone number.
            </small>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-32">
        <Col>
          <Form.Group>
            <Field.AutoComplete
              label="Full Address"
              labelClassName="font-weight-bold"
              options={addressOptions}
              optionFormatter={(option) => option.formattedAddress}
              onChange={(e) => handleAddressSearchChange(e.target.value)}
              onSelect={(e) => handleSelectAddress(e)}
              value={
                organizationInfo?.address?.formattedAddress || addressSearch
              }
              name="address"
              isInvalid={organizationInfoErrors.address}
              onBlur={handleAddressBlur}
              onFocus={handleAddressSearchFocus}
            />
            <small
              className="text-danger"
              hidden={!organizationInfoErrors.address}
            >
              Please select a valid address from the drop down.
            </small>
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <div className="d-flex justify-content-end">
        <Button className="rounded" onClick={submitOrganizationDetails}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default DetailsTab;
