import { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import SimpleTable from "components/global/SimpleTable";
import SimpleTableFilterDropdown from "components/global/SimpleTableFilterDropdown";
import { useHistory } from "react-router-dom";
import {
  fetchAndSetPrograms,
  handleDownloadFundingProgramsPerformanceData,
  handleEdoPerformanceFilterChange,
  handlePerformanceClearFilters
} from "./EdoPerformance.helpers";
import edoFundingSignal from "./EdoPerformance.signals";
import BreadCrumbWrapper from "components/global/BreadCrumbs/BreadCrumbs";

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'platform',
    title: 'Platform',
  },
  {
    key: 'programAllocated',
    title: 'Program Funds',
    currency: true,
  },
  {
    key: 'obligated',
    title: 'Obligated',
    currency: true,
  },
  {
    key: 'expended',
    title: 'Expended',
    currency: true,
  },
  {
    key: 'remaining',
    title: 'Remaining',
    currency: true,
  },
  {
    key: 'recycled',
    title: 'Recycled',
    currency: true,
  },
];

const EdoPerformance = () => {
  const { sort, dropdownFilters, platforms, fundingPrograms } =
    edoFundingSignal.value;
  const history = useHistory();

  useEffect(() => {
    fetchAndSetPrograms();
  }, [history.location.search]);

  const activePlatform = dropdownFilters.platformName
    ? platforms.find((p) => p.id === Number(dropdownFilters.platformName))
    : undefined;

  const reducedStats = {
    burned: platforms
      .map((p) => p.stats.burned)
      .reduce((acc, amount) => acc + amount, 0),
    funded: platforms
      .map((p) => p.stats.funded)
      .reduce((acc, amount) => acc + amount, 0),
    obligated: platforms
      .map((p) => p.stats.obligated)
      .reduce((acc, amount) => acc + amount, 0),
    expended: platforms
      .map((p) => p.stats.expended)
      .reduce((acc, amount) => acc + amount, 0),
    remaining: platforms
      .map((p) => p.fundingPrograms)
      .flatMap(a => a)
      .map(fp => fp.stats.remaining)
      .reduce((acc, amount) => acc + amount, 0),
    recycled: platforms
      .map((p) => p.stats.recycled)
      .reduce((acc, amount) => acc + amount, 0),
  };

  return (
    <BreadCrumbWrapper>
      <Row>
        <Col xs={9}>
          <Card className="rounded-0">
            <Card.Body>
              <h3 className="text-center mb-32">Program Performance</h3>
              <hr />
              <div className="d-flex flex-row justify-content-between mb-16">
                <SimpleTableFilterDropdown
                  filtersState={dropdownFilters}
                  onChange={({ name, value }) =>
                    handleEdoPerformanceFilterChange(name, value)
                  }
                  onApply={() => {
                    fetchAndSetPrograms();
                  }}
                  filters={[
                    {
                      type: 'integer',
                      label: 'ID',
                      key: 'id',
                    },
                    {
                      type: 'select',
                      label: 'Platform',
                      key: 'platformName',
                      options: platforms.map((p) => ({
                        label: p.name,
                        value: p.id,
                      })),
                    },
                  ]}
                  onClearFilterInputs={handlePerformanceClearFilters}
                />
                <Button
                  onClick={handleDownloadFundingProgramsPerformanceData}
                  className="rounded"
                >
                  Download
                </Button>
              </div>
              <SimpleTable
                headers={HEADERS}
                sort={sort}
                onClick={(index) => {
                  let programType = 'credit-program';
                  if (fundingPrograms[index].referenceProgram.programType.programTypeName === 'Equity') {
                    programType = 'equity-program';
                  }

                  const path = `/edo/performance/${programType}/${fundingPrograms[index].id}`;
                  history.push(path);
                }}
                onSort={() => {
                  fetchAndSetPrograms();
                }}
                rows={fundingPrograms.map((fundingProgram) => ({
                  id: fundingProgram.id,
                  name: `${fundingProgram.name} - ${fundingProgram.referenceProgram.programName}`,
                  platformType: fundingProgram.platform.platformType,
                  programAllocated:
                    fundingProgram.ceiling -
                    fundingProgram.fundsAlreadyAllocated,
                  obligated: fundingProgram.stats.obligated,
                  expended: fundingProgram.stats.expended,
                  remaining: fundingProgram.stats.remaining,
                  recycled: fundingProgram.stats.recycled,
                }))}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="rounded-0">
            <Card.Body>
              <h4>
                {activePlatform ? activePlatform.platformType : 'All Platforms'}
              </h4>
              <hr />
              {/*<ProgressBar*/}
              {/*  total={*/}
              {/*    activePlatform*/}
              {/*      ? activePlatform.stats.burned*/}
              {/*      : reducedStats.burned*/}
              {/*  }*/}
              {/*  segments={[*/}
              {/*    {*/}
              {/*      amount: activePlatform*/}
              {/*        ? activePlatform.stats.funded*/}
              {/*        : reducedStats.funded,*/}
              {/*      color: COLORS.allocated,*/}
              {/*      title: 'Funded',*/}
              {/*    },*/}
              {/*    {*/}
              {/*      amount: activePlatform*/}
              {/*        ? activePlatform.stats.obligated*/}
              {/*        : reducedStats.obligated,*/}
              {/*      color: COLORS.obligated,*/}
              {/*      title: 'Obligated',*/}
              {/*    },*/}
              {/*    {*/}
              {/*      amount: activePlatform*/}
              {/*        ? activePlatform.stats.expended*/}
              {/*        : reducedStats.expended,*/}
              {/*      color: COLORS.expended,*/}
              {/*      title: 'Expended',*/}
              {/*    },*/}
              {/*  ]}*/}
              {/*/>*/}
              <br />
              <h5 className="font-600">Money Received</h5>
              <p>
                {activePlatform
                  ? '$' + activePlatform.stats?.burned?.toLocaleString()
                  : '$' + reducedStats.burned.toLocaleString()}
              </p>
              <h5 className="font-600">Unfunded</h5>
              <p>
                {activePlatform
                  ? '$' + (activePlatform.stats?.burned - activePlatform.stats?.funded).toLocaleString()
                  : '$' + (reducedStats.burned - reducedStats.funded).toLocaleString()}
              </p>
              <h5 className="font-600">Funded</h5>
              <p>
                {activePlatform
                  ? '$' + activePlatform.stats?.funded?.toLocaleString()
                  : '$' + reducedStats.funded.toLocaleString()}
              </p>
              <h5 className="font-600">Obligated</h5>
              <p>
                {activePlatform
                  ? '$' + activePlatform.stats?.obligated?.toLocaleString()
                  : '$' + reducedStats.obligated.toLocaleString()}
              </p>
              <h5 className="font-600">Expended</h5>
              <p>
                {activePlatform
                  ? '$' + activePlatform.stats?.expended?.toLocaleString()
                  : '$' + reducedStats.expended.toLocaleString()}
              </p>
              <h5 className="font-600">Remaining</h5>
              <p>
                {activePlatform
                  ? '$' + (activePlatform.fundingPrograms.map(fp => fp.stats.remaining).reduce((acc, amount) => amount + acc, 0)).toLocaleString()
                  : '$' + (reducedStats.remaining).toLocaleString()}
              </p>
              <h5 className="font-600">Recycled</h5>
              <p>
                {activePlatform
                  ? '$' + activePlatform.stats?.recycled?.toLocaleString()
                  : '$' + reducedStats.recycled.toLocaleString()}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </BreadCrumbWrapper>
  );
};

export default EdoPerformance;
