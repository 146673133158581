import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { shareOrganizationSignal } from '../OrganizationsList.signal';
import {
  handleClickOrganization,
  handleShareModalOrganizationNameChange,
  hideShareOrganizationModal,
  shareBusinessWithOrganization,
} from '../OrganizationsList.helpers';
import React from 'react';
import CloseModalButton from 'components/global/Constant/CloseModalButton';
import $appSettings from 'signals/AppSettings.signal';

const ShareOrganizationModal = () => {
  const {
    selectedBusiness,
    selectedOrganization,
    fetching,
    organizations,
    organizationName,
    modalVisible,
  } = shareOrganizationSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  return (
    <ModalWrapper
      show={modalVisible}
      onHide={hideShareOrganizationModal}
      size="xl"
      centered
      backdropClassName={null}
      onExit={null}
      onExited={null}
    >
      <Modal.Header className="justify-content-between">
        <Modal.Title>
          Share <i>{selectedBusiness?.name || 'Business'}</i> with another
          Organization
        </Modal.Title>
        <CloseModalButton onCloseModal={hideShareOrganizationModal} />
      </Modal.Header>
      <Modal.Body className="py-32">
        <Row className="justify-content-center">
          <Col xs={12} lg={9}>
            <Form onSubmit={() => console.log('onSubmit')}>
              <Form.Group>
                <Form.Label className="font-weight-bold text-secondary">
                  Organization Name
                </Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Enter Name"
                  name={'organizationName'}
                  value={organizationName}
                  onChange={(e) =>
                    handleShareModalOrganizationNameChange(e.target.value)
                  }
                />
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-center">
              {fetching && <Spinner animation="grow" variant="primary" />}
            </div>
            {!fetching && (
              <Row xs={1} md={2} className="mt-16 g-4">
                {organizations.map((organization, index) => (
                  <Col key={index} className="mb-16">
                    <Card
                      onClick={() => handleClickOrganization(organization)}
                      className="pointer"
                      text={`${
                        selectedOrganization?.id === organization.id &&
                        selectedOrganization?.account.portalType ===
                          organization.account.portalType
                          ? 'white'
                          : 'dark'
                      }`}
                      bg={`${
                        selectedOrganization?.id === organization.id &&
                        selectedOrganization?.account.portalType ===
                          organization.account.portalType
                          ? 'primary'
                          : 'default'
                      }`}
                    >
                      <Card.Header>
                        {organization.account.portalType === PORTAL_TYPES.vc
                          ? 'Venture Capitalist'
                          : 'Lender'}
                      </Card.Header>
                      <Card.Body>
                        <Card.Title>{organization.name}</Card.Title>
                        <Card.Text>{organization.description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
            {organizations.length > 0 && (
              <Button
                onClick={() => shareBusinessWithOrganization()}
                disabled={!selectedOrganization}
                size="lg"
                className="rounded w-100"
                type="submit"
              >
                Share
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="rounded"
          variant="outline-secondary"
          onClick={() => hideShareOrganizationModal()}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

export default ShareOrganizationModal;
