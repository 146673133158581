import api from "api/api";
import { uploadFilesToStorage } from "libs/functions/global.functions";
import loaderSignal from "signals/Loader.signal";
import userAccountSignal from "signals/UserAccount.signal";
import dataRoomSignal from "./dataRoom.signal";
import alertSignal from "signals/Alert.signal";
import $appSettings from "signals/AppSettings.signal";


const SUGGESTED_DOCUMENTS = {
  BUSINESS: [
    "Pitch Deck",
  ],
  VC: [
    "Proof of Org",
    "Proof of Fund according to US Treasury",
    "Secretary of State",
    "Securities and Exchange Commission",
    "RFQ Document",
    "Evaluation Document",
    "Deal Sourcing Network Document",
    "Due Diligence Document",
    "Legal Documentation that you are an Accredited Investor",
  ],
  LENDER: [
    "Proof of Org",
    "Proof of Fund according to US Treasury",
    "Secretary of State",
    "RFQ Document",
    "Deal Sourcing Network Document",
    "Due Diligence Document",
    "Legal Documentation that you are an Accredited Investor",
  ],
};

export const fetchAndSetOrganizationDocuments = async () => {
  const { documentBucket } = dataRoomSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const businessId = userAccountSignal.value.userData.account.business?.id;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const where = {};

  if (portalType === PORTAL_TYPES.business) {
    where.businessId = userAccountSignal.value.userData.account.business.id;
  }

  if (portalType === PORTAL_TYPES.vc) {
    where.ventureCapitalFirmId = userAccountSignal.value.userData.account.ventureCapitalFirm.id;
  }

  if (portalType === PORTAL_TYPES.lender) {
    where.lenderId = userAccountSignal.value.userData.account.lender.id;
  }

  try {
    loaderSignal.update({ isContentLoading: true });
    const organizationDocuments = await api.get({
        path: `/${documentBucket}`,
        options: {
          where,
        },
      });

    let capTableRecord = null;
    if (userAccountSignal.value.userData.account.portalType === PORTAL_TYPES.business) {
      capTableRecord = await api.get({
        path: `/businesses/${businessId}/capTables`,
        options: {
          where,
        }
      })
    }

    const suggestedDocuments = SUGGESTED_DOCUMENTS[portalType].map(suggestedDoc => {
      const uploadedDocument = organizationDocuments.find(bd => bd.documentType && bd.name === suggestedDoc);

      return {
        name: suggestedDoc,
        uploadedDocument: uploadedDocument || null,
      }
    }).sort((a, b) => { // suggested documents without an upload appear first
      if ((a.uploadedDocument && b.uploadedDocument) || (!a.uploadedDocument && !b.uploadedDocument)) {
        return 0;
      }
      if (a.uploadedDocument) {
        return 1;
      }
      return -1;
    });

    dataRoomSignal.update({ organizationDocuments, suggestedDocuments, capTableRecord });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
}

export const submitFilesForUpload = async (files, documentType, suggestedDocumentName) => {
  const { documentBucket } = dataRoomSignal.value;
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const basePayload = {};

  if (portalType === PORTAL_TYPES.business) {
    basePayload.businessId = userAccountSignal.value.userData.account.business.id;
  }

  if (portalType === PORTAL_TYPES.vc) {
    basePayload.ventureCapitalFirmId = userAccountSignal.value.userData.account.ventureCapitalFirm.id;
  }

  if (portalType === PORTAL_TYPES.lender) {
    basePayload.lenderId = userAccountSignal.value.userData.account.lender.id;
  }

  try {
    loaderSignal.update({ isContentLoading: true });
    let filesToUpload = files;
    if (!suggestedDocumentName) { // if uploading additional documents this will be undefined
      filesToUpload = files.map(file => file.file);
    }
    const filesSignedUrls = await uploadFilesToStorage(
      filesToUpload,
      documentBucket,
    );

    const body = {
      data: files
        .map((file, index) => ({
          ...basePayload,
          filePath: filesSignedUrls[index],
          fileType: file.type,
          documentType,
          name: suggestedDocumentName || file.name,
        })),
    };

    await api.post({
      path: `/${documentBucket}`,
      body,
    });

    if (!suggestedDocumentName) {
      dataRoomSignal.update({ filesToUpload: [] });
    }
    await fetchAndSetOrganizationDocuments();
    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Document(s) successfully uploaded',
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const removeOrganizationDocument = async (doc) => {
  const { documentBucket } = dataRoomSignal.value;
  try {
    loaderSignal.update({ isContentLoading: true });
    await api.delete({
      path: `/${documentBucket}`,
      body: {
        where: {
          id: doc.id,
        },
      },
    });
    await fetchAndSetOrganizationDocuments();
    alertSignal.update({
      type: 'notification',
      className: "bg-success",
      message: "Document has been removed.",
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.update({ isContentLoading: false });
  }
};

export const handleAddFilesFromFileZone = (file) => {
  dataRoomSignal.update({
    filesToUpload: [
      ...dataRoomSignal.value.filesToUpload,
      {
        file,
        name: file.name,
        type: file.type,
      },
    ],
  });
};

export const handleRemoveAdditionalDocuments = (fileName, fileIndex) => {
  dataRoomSignal.update({
    filesToUpload: dataRoomSignal.value.filesToUpload.filter((file, index) => file.name !== fileName || index !== fileIndex),
  });
};

export const handleAdditionalDocumentNameChange = (e, index) => {
  const { value } = e;
  const files = dataRoomSignal.value.filesToUpload;
  files[index].name = value;

  dataRoomSignal.update({ filesToUpload: files });
}
