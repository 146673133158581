import api from 'api/api';
import alertSignal from 'signals/Alert.signal';
import loaderSignal from 'signals/Loader.signal';
import $capitalCallPortionsList from './capitalCallPortionsList.signal';
import history from '../../../history';
import userAccountSignal from 'signals/UserAccount.signal';

const xFormSortToOrderByClause = () => {
  const { sort } = Object.fromEntries(
    new URLSearchParams(history.location.search).entries()
  );

  if (!sort) {
    return [{ id: 'desc' }];
  }

  const [key, dir] = sort.split('-');

  if (key === 'programName') {
    return [
      {
        capitalCall: {
          fund: {
            fundingProgram: {
              name: dir,
            },
          },
        },
      },
    ];
  } else if (key === 'businessName') {
    return [
      {
        vcPortfolioCompany: {
          business: {
            name: dir,
          },
        },
      },
    ];
  } else if (key === 'fundName') {
    return [
      {
        capitalCall: {
          fund: {
            fundName: dir,
          },
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const { search } = history.location;
  const params = Object.fromEntries(new URLSearchParams(search)) || {};

  const where = {
    capitalCall: {
      fund: {
        fundingProgramMembership: {
          ventureCapitalFirm: {
            id: userAccountSignal.value.userData.account.ventureCapitalFirm.id,
          },
        },
      },
    },
  };

  if (params.id) {
    where.id = Number(params.id);
  }

  if (params.fundName) {
    where.capitalCall.fund.fundName = {
      contains: params.fundName,
      mode: 'insensitive',
    };
  }

  if (params.businessName) {
    where.vcPortfolioCompany = {
      business: {
        name: {
          contains: params.businessName,
          mode: 'insensitive',
        },
      },
    };
  }

  return where;
};

export const fetchAndSetCapitalCallPortions = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });

    const capitalCallPortions = await api.get({
      path: '/capitalCallPortions',
      options: {
        orderBy: xFormSortToOrderByClause(),
        where: xFormAppliedFiltersToWhereClause(),
        action: 'fetchMany',
        include: {
          capitalCall: {
            include: {
              fund: {
                include: {
                  fundingProgramMembership: true,
                  fundingProgram: true,
                },
              },
            },
          },
          vcPortfolioCompany: {
            include: {
              business: true,
            },
          },
        },
      },
    });

    $capitalCallPortionsList.update({ capitalCallPortions });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};
