const PREVIEW_TYPES = ['pdf', 'jpeg', 'jpg', 'png', 'webp'];

export const isFileTypeValidForPreview = (fileType) => {
  if (!fileType) {
    return false;
  }

  const type = fileType.toLowerCase();
  return PREVIEW_TYPES.some((t) => type.includes(t));
};
