import {
  PURPOSE_OF_LOAN_OR_INVESTMENT,
  TRANSACTION_SOURCE_OF_PRIVATE_CAPITAL,
  TRIBAL_GOVERNMENT_PROGRAM_TRANSACTION_TYPE,
} from 'components/global/Constant/businessCompanyInfoDropDowns';
import { Col, Form, Row } from 'react-bootstrap';
import {
  businessSettingsDirtySignal,
  businessSettingsErrorsSignal,
  businessSettingsSignal,
} from 'signals/BusinessSettings.signal';
import {
  handleBusinessFormChange,
  handleBusinessFormDirtyChange,
} from '../BusinessSettings.helpers';
import BusinessUnsavedChangesAlert from './BusinessUnsavedChangesAlert';

const BusinessFinance = (props) => {
  const abridged = props.abridged;
  const businessForm = businessSettingsSignal.value;
  const businessFormErrors = businessSettingsErrorsSignal.value;
  const businessFormDirty = businessSettingsDirtySignal.value;

  if (abridged) {
    return (
      <>
        <Row className="align-items-center px-16">
          <h4 className="mb-0">Finance</h4>
          <BusinessUnsavedChangesAlert />
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <Form.Group className="form-group">
              <Form.Label
                className="font-weight-bold"
                htmlFor="finance.annualRevenue"
              >
                Annual Revenue
              </Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                id="finance.annualRevenue"
                name="finance.annualRevenue"
                isInvalid={
                  businessFormErrors['finance.annualRevenue'] &&
                  businessFormDirty['finance.annualRevenue']
                }
                value={businessForm['finance.annualRevenue']}
                onChange={(e) => handleBusinessFormChange(e)}
                onBlur={(e) => handleBusinessFormDirtyChange(e)}
              />
              <Form.Control.Feedback type="invalid">
                You must enter an integer.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="form-group">
              <Form.Label
                className="font-weight-bold"
                htmlFor="finance.netIncome"
              >
                Business Net Income
              </Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                id="finance.netIncome"
                name="finance.netIncome"
                isInvalid={
                  businessFormErrors['finance.netIncome'] &&
                  businessFormDirty['finance.netIncome']
                }
                value={businessForm['finance.netIncome']}
                onChange={(e) => handleBusinessFormChange(e)}
                onBlur={(e) => handleBusinessFormDirtyChange(e)}
              />
              <Form.Control.Feedback type="invalid">
                You must enter an integer.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="form-group">
              <Form.Label
                htmlFor="finance.tribalGovernmentProgramTransactionType"
                className="font-weight-bold"
              >
                Are you a Tribally Owned Enterprise, or are you affiliated with
                a tribe?
              </Form.Label>
              <br />
              <Form.Select
                className="form-control"
                formNoValidate
                type="text"
                id="finance.tribalGovernmentProgramTransactionType"
                name="finance.tribalGovernmentProgramTransactionType"
                value={
                  businessForm['finance.tribalGovernmentProgramTransactionType']
                }
                onChange={(e) => handleBusinessFormChange(e)}
                onBlur={(e) => handleBusinessFormDirtyChange(e)}
              >
                <option value="">Select an option</option>
                {TRIBAL_GOVERNMENT_PROGRAM_TRANSACTION_TYPE.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="form-group">
              <Form.Label
                htmlFor="finance.primaryPurposeOfLoanOrInvestment"
                className="font-weight-bold"
              >
                Primary Purpose of Loan Or Investment
              </Form.Label>
              <br />
              <Form.Select
                className="form-control"
                type="text"
                formNoValidate
                id="finance.primaryPurposeOfLoanOrInvestment"
                name="finance.primaryPurposeOfLoanOrInvestment"
                value={businessForm['finance.primaryPurposeOfLoanOrInvestment']}
                onChange={(e) => handleBusinessFormChange(e)}
                onBlur={(e) => handleBusinessFormDirtyChange(e)}
              >
                <option value="">Select an option</option>
                {PURPOSE_OF_LOAN_OR_INVESTMENT.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row className="align-items-center px-16">
        <h4 className="mb-0">Finance</h4>
        <BusinessUnsavedChangesAlert />
      </Row>
      <hr />
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.annualRevenue"
            >
              Annual Revenue
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="finance.annualRevenue"
              name="finance.annualRevenue"
              isInvalid={
                businessFormErrors['finance.annualRevenue'] &&
                businessFormDirty['finance.annualRevenue']
              }
              value={businessForm['finance.annualRevenue']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.previousAnnualRevenue"
            >
              Previous Annual Revenue
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="finance.previousAnnualRevenue"
              name="finance.previousAnnualRevenue"
              isInvalid={
                businessFormErrors['finance.previousAnnualRevenue'] &&
                businessFormDirty['finance.previousAnnualRevenue']
              }
              value={businessForm['finance.previousAnnualRevenue']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.netIncome"
            >
              Business Net Income
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="finance.netIncome"
              name="finance.netIncome"
              isInvalid={
                businessFormErrors['finance.netIncome'] &&
                businessFormDirty['finance.netIncome']
              }
              value={businessForm['finance.netIncome']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="finance.tribalGovernmentProgramTransactionType"
              className="font-weight-bold"
            >
              Are you a Tribally Owned Enterprise, or are you affiliated with a
              tribe?
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              formNoValidate
              type="text"
              id="finance.tribalGovernmentProgramTransactionType"
              name="finance.tribalGovernmentProgramTransactionType"
              value={
                businessForm['finance.tribalGovernmentProgramTransactionType']
              }
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            >
              <option value="">Select an option</option>
              {TRIBAL_GOVERNMENT_PROGRAM_TRANSACTION_TYPE.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations"
            >
              Allocated Funds Expended or Set Aside for Guarantys or Collateral
              Support Obligations
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              isInvalid={
                businessFormErrors[
                  'finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations'
                ] &&
                businessFormDirty[
                  'finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations'
                ]
              }
              id="finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations"
              name="finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations"
              value={
                businessForm[
                  'finance.allocatedFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations'
                ]
              }
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations"
            >
              Recycled Funds Expended or Set Aside for Guarantys or Collateral
              Support Obligations
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              isInvalid={
                businessFormErrors[
                  'finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations'
                ] &&
                businessFormDirty[
                  'finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations'
                ]
              }
              id="finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations"
              name="finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations"
              value={
                businessForm[
                  'finance.recycledFundsExpendedOrSetAsideForGuarantysOrCollateralSupportObligations'
                ]
              }
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.additionalGovernmentFunds"
            >
              Additional Government Funds
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              isInvalid={
                businessFormErrors['finance.additionalGovernmentFunds'] &&
                businessFormDirty['finance.additionalGovernmentFunds']
              }
              id="finance.additionalGovernmentFunds"
              name="finance.additionalGovernmentFunds"
              value={businessForm['finance.additionalGovernmentFunds']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.concurrentPrivateFinancing"
            >
              Concurrent Private Financing
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              isInvalid={
                businessFormErrors['finance.concurrentPrivateFinancing'] &&
                businessFormDirty['finance.concurrentPrivateFinancing']
              }
              id="finance.concurrentPrivateFinancing"
              name="finance.concurrentPrivateFinancing"
              value={businessForm['finance.concurrentPrivateFinancing']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              You must enter an integer.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="finance.primaryTransactionSourceOfPrivateCapital"
              className="font-weight-bold"
            >
              Primary Transaction Source of Private Capital
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              type="text"
              formNoValidate
              id="finance.primaryTransactionSourceOfPrivateCapital"
              name="finance.primaryTransactionSourceOfPrivateCapital"
              value={
                businessForm['finance.primaryTransactionSourceOfPrivateCapital']
              }
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            >
              <option value="">Select an option</option>
              {TRANSACTION_SOURCE_OF_PRIVATE_CAPITAL.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="finance.secondaryTransactionSourceOfPrivateCapital"
              className="font-weight-bold"
            >
              Secondary Transaction Source of Private Capital
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              type="text"
              formNoValidate
              id="finance.secondaryTransactionSourceOfPrivateCapital"
              name="finance.secondaryTransactionSourceOfPrivateCapital"
              value={
                businessForm[
                  'finance.secondaryTransactionSourceOfPrivateCapital'
                ]
              }
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            >
              <option value="">Select an option</option>
              {TRANSACTION_SOURCE_OF_PRIVATE_CAPITAL.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="finance.primaryPurposeOfLoanOrInvestment"
              className="font-weight-bold"
            >
              Primary Purpose of Loan Or Investment
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              type="text"
              formNoValidate
              id="finance.primaryPurposeOfLoanOrInvestment"
              name="finance.primaryPurposeOfLoanOrInvestment"
              value={businessForm['finance.primaryPurposeOfLoanOrInvestment']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            >
              <option value="">Select an option</option>
              {PURPOSE_OF_LOAN_OR_INVESTMENT.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.purposeOfLoanOrInvestmentOther"
            >
              Purpose of Loan or Investment - Other
            </Form.Label>
            <Form.Control
              type="text"
              formNoValidate
              className="form-control"
              id="finance.purposeOfLoanOrInvestmentOther"
              name="finance.purposeOfLoanOrInvestmentOther"
              value={businessForm['finance.purposeOfLoanOrInvestmentOther']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              htmlFor="finance.secondaryPurposeOfLoanOrInvestment"
              className="font-weight-bold"
            >
              Secondary Purpose of Loan Or Investment
            </Form.Label>
            <br />
            <Form.Select
              className="form-control"
              type="text"
              formNoValidate
              id="finance.secondaryPurposeOfLoanOrInvestment"
              name="finance.secondaryPurposeOfLoanOrInvestment"
              value={businessForm['finance.secondaryPurposeOfLoanOrInvestment']}
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            >
              <option value="">Select an option</option>
              {PURPOSE_OF_LOAN_OR_INVESTMENT.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="finance.secondaryPurposeOfLoanOrInvestmentOther"
            >
              Secondary Purpose of Loan or Investment - Other
            </Form.Label>
            <Form.Control
              type="text"
              formNoValidate
              className="form-control"
              id="finance.secondaryPurposeOfLoanOrInvestmentOther"
              name="finance.secondaryPurposeOfLoanOrInvestmentOther"
              value={
                businessForm['finance.secondaryPurposeOfLoanOrInvestmentOther']
              }
              onChange={(e) => handleBusinessFormChange(e)}
              onBlur={(e) => handleBusinessFormDirtyChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <h4 className="mt-48">Employment</h4>
      <hr />
      <Row>
        <Col sm={6} xl={4}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="employment.numberOfEmployees"
            >
              Number of Employees
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="employment.numberOfEmployees"
              name="employment.numberOfEmployees"
              isInvalid={
                businessFormErrors['employment.numberOfEmployees'] &&
                businessFormDirty['employment.numberOfEmployees']
              }
              value={businessForm['employment.numberOfEmployees']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid number
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={4}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="employment.numberOfFullTimeEmployees"
            >
              Number of Full-time Employees
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="employment.numberOfFullTimeEmployees"
              name="employment.numberOfFullTimeEmployees"
              isInvalid={
                businessFormErrors['employment.numberOfFullTimeEmployees'] &&
                businessFormDirty['employment.numberOfFullTimeEmployees']
              }
              value={businessForm['employment.numberOfFullTimeEmployees']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid number
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={4}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="employment.numberOfEmployees"
            >
              Number of Part-time Employees
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="employment.numberOfPartTimeEmployees"
              name="employment.numberOfPartTimeEmployees"
              isInvalid={
                businessFormErrors['employment.numberOfPartTimeEmployees'] &&
                businessFormDirty['employment.numberOfPartTimeEmployees']
              }
              value={businessForm['employment.numberOfPartTimeEmployees']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid number
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xl={4}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="employment.expectedJobsCreated"
            >
              Expected Jobs Created
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="employment.expectedJobsCreated"
              name="employment.expectedJobsCreated"
              isInvalid={
                businessFormErrors['employment.expectedJobsCreated'] &&
                businessFormDirty['employment.expectedJobsCreated']
              }
              value={businessForm['employment.expectedJobsCreated']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid number
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6} xl={4}>
          <Form.Group className="form-group">
            <Form.Label
              className="font-weight-bold"
              htmlFor="employment.expectedJobsRetained"
            >
              Expected Jobs Retained
            </Form.Label>
            <Form.Control
              type="number"
              className="form-control"
              id="employment.expectedJobsRetained"
              name="employment.expectedJobsRetained"
              isInvalid={
                businessFormErrors['employment.expectedJobsRetained'] &&
                businessFormDirty['employment.expectedJobsRetained']
              }
              value={businessForm['employment.expectedJobsRetained']}
              onChange={(e) => handleBusinessFormChange(e)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid number
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default BusinessFinance;
