const dateOptions = {
  dateTime: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  date: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  time: {
    hour: '2-digit',
    minute: '2-digit',
  },
  currencyUS: {
    style: 'currency',
    currency: 'USD',
  },
  currencyUSNoDecimal: {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

export default dateOptions;
