import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import edoUsersSignal from 'signals/EdoUsers.signal';
import {handleEdoSettingsUserDetailsChange, handleEdoSettingsUserDetailsUpdateUser} from '../EdoUsers.helpers';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';

const UserDetailsTab = () => {
  const { userData } = userAccountSignal.value;
  const { form, selectedUser, selectedUserErrors } = edoUsersSignal.value;
  const roleOptions = selectedUser?.role === 'SUPER_ADMIN' || selectedUser?.role === 'OWNER' ? [
    { label: 'SUPER_ADMIN', value: 'SUPER_ADMIN' },
    { label: 'OWNER', value: 'OWNER' },
    { label: 'ADMIN', value: 'ADMIN' },
    { label: 'STAFF', value: 'STAFF' },
  ] : [
    { label: 'ADMIN', value: 'ADMIN' },
    { label: 'STAFF', value: 'STAFF' },
  ];
  const selectedUserIsCurrentUser = selectedUser && selectedUser.id === userData.id;

  return (
    <>
      <Form>
        <Row>
          <Col>
            <Alert show={selectedUserIsCurrentUser} variant="info">
              You can edit your information under the profile tab.
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label className="col-form-label" htmlFor="firstName">First Name</Form.Label>
              <Form.Control
                disabled={selectedUserIsCurrentUser}
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                placeholder={selectedUser?.firstName}
                value={form.firstName}
                onChange={(e) => handleEdoSettingsUserDetailsChange(e)}
              />
              <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label className="col-form-label" htmlFor="lastName">Last Name</Form.Label>
              <Form.Control
                disabled={selectedUserIsCurrentUser}
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                placeholder={selectedUser?.lastName}
                value={form.lastName}
                onChange={(e) => handleEdoSettingsUserDetailsChange(e)}
              />
              <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label htmlFor="email">Email address</Form.Label>
              <Form.Control
                disabled
                type="email"
                className="form-control"
                id="email"
                name="email"
                isInvalid={selectedUserErrors?.email}
                placeholder={selectedUser?.email}
                value={form.email}
                onChange={(e) => handleEdoSettingsUserDetailsChange(e)}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid email address</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label htmlFor="phoneNumber">Phone</Form.Label>
              <Form.Control
                disabled={selectedUserIsCurrentUser}
                type="tel"
                className="form-control"
                placeholder={selectedUser?.phoneNumber}
                value={form.phoneNumber}
                id="phoneNumber"
                name="phoneNumber"
                onChange={(e) => handleEdoSettingsUserDetailsChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="form-group">
              <Form.Label htmlFor="role">Role</Form.Label>
              <Form.Select
                className="form-control"
                disabled={selectedUser?.role === 'SUPER_ADMIN' || selectedUser?.role === 'OWNER'}
                name="role"
                label="Role"
                labelClassName="text-nowrap"
                onChange={(e) => handleEdoSettingsUserDetailsChange(e)}
                id="role"
                value={form.role}
              >
                { roleOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              disabled={loaderSignal.value.isContentLoading || selectedUserIsCurrentUser}
              className="rounded mr-8"
              onClick={() => handleEdoSettingsUserDetailsUpdateUser()}
            >
              Update User
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UserDetailsTab;
