import { getConfig } from 'config/config';

export const msalConfig = {
  auth: {
    clientId: getConfig('REACT_APP_MSAL_CLIENT_ID'),
    authority: getConfig('REACT_APP_MSAL_AUTHORITY'),
    knownAuthorities: [getConfig('REACT_APP_MSAL_KNOWN_AUTHORITIES')],
    redirectUri: getConfig('BASE_URL'),
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export default msalConfig;
