/* eslint-disable no-unused-vars */
import alertSignal from 'signals/Alert.signal';
import { searchProgramsResultsSignal } from 'components/global/SearchProgramResults/SearchProgramsResults.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import history from '../../../history';
import $appSettings from 'signals/AppSettings.signal';

const xFormAppliedFiltersToWhereClause = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const where = {};

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.name) {
    where.id = { in: [Number(params.name)] };
  }

  if (params.referenceProgramName) {
    where.referenceProgram = {
      id: { in: [Number(params.referenceProgramName)] },
    };
  }

  if (!where.referenceProgram) {
    where.referenceProgram = {};
  }

  if (
    userAccountSignal.value.userData.account.portalType ===
    PORTAL_TYPES.lender
  ) {
    where.referenceProgram = {
      ...where.referenceProgram,
      programTypeId: 1, // Credit
    };
  } else {
    where.referenceProgram = {
      ...where.referenceProgram,
      programTypeId: 2, // Equity
    };
  }

  if (params.keywords) {
    where.OR = params.keywords
      .split(' ')
      .map((keyword) => [
        {
          name: {
            contains: keyword,
            mode: 'insensitive',
          },
        },
        {
          overview: {
            contains: keyword,
            mode: 'insensitive',
          },
        },
        {
          eligibility: {
            contains: keyword,
            mode: 'insensitive',
          },
        },
        {
          detailBenefits: {
            contains: keyword,
            mode: 'insensitive',
          },
        },
      ])
      .flatMap((a) => a);
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  let key = sort.split('-')[0];
  let dir = sort.split('-')[1];

  if (key === 'referenceProgramName') {
    return [
      {
        referenceProgram: {
          programName: dir,
        },
      },
    ];
  }

  return [{ [key]: dir }];
};

export const fetchAndSetSearchResultsAndProgramRequests = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const [fundingPrograms, programRequests, fundingProgramMemberships] =
      await Promise.all([
        api.get({
          path: '/fundingPrograms',
          options: {
            where: xFormAppliedFiltersToWhereClause(),
            orderBy: xFormSortToOrderByClause(),
            include: {
              stats: true,
              referenceProgram: {
                include: {
                  programType: true,
                },
              },
            },
          },
        }),
        api.get({
          path: '/programRequests',
          options: {
            where: {
              lenderId: userAccountSignal.value.userData.account.lender?.id,
              ventureCapitalFirmId:
                userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
            },
            include: {
              fundingProgram: {
                include: {
                  edo: true,
                },
              },
              ventureCapitalFirm: true,
              lender: true,
            },
          },
        }),
        api.get({
          path: '/fundingProgramMemberships',
          options: {
            where: {
              lenderId: userAccountSignal.value.userData.account.lender?.id,
              ventureCapitalFirmId:
                userAccountSignal.value.userData.account.ventureCapitalFirm?.id,
            },
          },
        }),
      ]);

    searchProgramsResultsSignal.update({
      fundingPrograms,
      fundingProgramMemberships,
      programRequests,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const setDropdownFilter = ({ name, value }) =>
  searchProgramsResultsSignal.update({
    dropdownFilters: {
      ...searchProgramsResultsSignal.value.dropdownFilters,
      [name]: value,
    },
  });

export const resetSearchProgramFilters = () =>
  searchProgramsResultsSignal.update({ dropdownFilters: {} });
