import { randomHexColor } from "libs/functions/global.functions";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AdhocReportDataset, AdhocReportQuery } from "types";
import {
  exportChartData,
  fetchAdhocDataset,
  FILTERING_CONFIG,
  generateChartImagePdf,
  GRAPH_LABEL_CONFIG,
  mapQueryToWords,
  PERIODICITY_OPTIONS,
  START_OF_TIME_OPTIONS
} from "../AdhocReporting.helpers";
import { Chart as ChartJS } from "chart.js";
import { Chart } from "react-chartjs-2";
import Loader from "../../Loader";
import { DateTime } from "luxon";
import Field from "components/global/Field/Field";
import { enumCaseToTabCase } from "libs/string";

const baseOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
    },
  },
};

const missingAttributes = {
  name: undefined,
  className: undefined,
  labelClassName: undefined,
  onBlur: undefined,
  placeholder: undefined,
  isInvalid: undefined,
  onSelect: undefined,
  onFocus: undefined,
  feedbackMessage: undefined,
  allowCents: undefined,
  disabled: undefined,
  inputRef: undefined,
  lineBreak: undefined,
};

type AdhocReportRowProps = {
  query: AdhocReportQuery;
  index: number;
  onDelete: () => any;
};

type AdhocChart = 'bar' | 'pie' | 'doughnut' | 'line';

function buildChartOptions() {
  return JSON.parse(JSON.stringify(baseOptions));
}

function buildDataSets(fn, data, cachedColors) {
  if (fn === 'sumBy') {
    return  [
      {
        data: data.map((row) => row.sum),
        backgroundColor: cachedColors,
      },
    ]
  }

  if (fn === 'groupBy') {
      return  [
        {
          data: data.map((row) => row.count),
          backgroundColor: cachedColors,
        },
      ]
  }


  if (fn === 'timeSeries') {
    return [
      {
        label: 'Interval Count',
        data: data.map(({ count }) => count),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Cumulative Count',
        data: data.map(({ acc }) => acc),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ];
  }

  throw new Error('A mapping from fn to chart must be defined');
}

function AdhocReportRow(props: AdhocReportRowProps) {
  const { query, onDelete } = props;
  const { fn, model, count } = query;
  const [data, setData] = useState<AdhocReportDataset>([]);
  const [chartType, setChartType] = useState<AdhocChart>('bar');
  const [cachedColors, setCachedColors] = useState<string[]>([]);
  const [periodicity, setPeriodicity] = useState<string>('day');
  const [startOfTime, setStartOfTime] = useState<string>('6 day');
  const [loading, setLoading] = useState<boolean>(false);
  const chartRef = useRef<ChartJS>(null);

  // @ts-ignore
  const config = FILTERING_CONFIG[fn][model];
  const countConfig = config[count];

  useEffect(() => {
    setLoading(true);
    fetchAdhocDataset({
      fn,
      model,
      count: countConfig,
      periodicity,
      startOfTime,
    })
      .then((data: AdhocReportDataset) => {
        setData(data);
        setCachedColors(data.map(() => randomHexColor()));
      })
      .finally(() => setLoading(false));
  }, [query, periodicity, startOfTime]);

  const labels =
    fn === 'groupBy' || fn === 'sumBy'
      ? data.map((row) => {
          const labelFn = GRAPH_LABEL_CONFIG[
            count as keyof typeof GRAPH_LABEL_CONFIG
          ] as (a: any) => string | undefined;
          if (labelFn) {
            return labelFn(row);
          }
          if (countConfig === 'lenderType' && !!row[query.count]) {
            return enumCaseToTabCase(row[query.count]);
          }
          return row[query.count];
        })
      : data.map(({ date }) => DateTime.fromISO(date).toFormat('DD'));

  const chartDatasets = buildDataSets(fn, data, cachedColors);
  const chartData = {
    labels,
    datasets: chartDatasets,
  };

  const options = buildChartOptions();
  options.plugins.title.text = mapQueryToWords(query);

  if (chartType === 'bar' && (query.fn === 'groupBy' || query.fn === 'sumBy')) {
    options.plugins.legend = {
      display: false,
    };
  }

  if (loading) {
    return <Loader />;
  }

  if (data.length === 0) {
    return (
      <div className="mt-16">
        <Row>
          <Col className="offset-6">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                className="rounded ml-8"
                variant="outline-secondary"
                onClick={() => onDelete()}
              >
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="mt-16">
      <Row>
        <Col>
          <div className="d-flex justify-content-end">
            {fn === 'timeSeries' && (
              <Field.Select
                {...missingAttributes}
                label={''}
                inputClassName="form-control mr-8"
                noDefaultOption={true}
                options={PERIODICITY_OPTIONS}
                onChange={(e: { value: any }) => {
                  const { value } = e;
                  setPeriodicity(value);
                  if (periodicity !== value) {
                    switch (value) {
                      case 'day':
                        setStartOfTime('6 day');
                        break;
                      case 'week':
                        setStartOfTime('3 week');
                        break;
                      case 'month':
                        setStartOfTime('1 month');
                        break;
                    }
                  }
                }}
                value={periodicity}
              />
            )}
            {fn === 'timeSeries' && (
              <Field.Select
                {...missingAttributes}
                label={''}
                inputClassName="form-control mr-8"
                noDefaultOption={true}
                options={
                  START_OF_TIME_OPTIONS[
                    periodicity as keyof typeof START_OF_TIME_OPTIONS
                  ]
                }
                onChange={(e: { value: any }) => setStartOfTime(e.value)}
                value={startOfTime}
              />
            )}
            <Field.Select
              {...missingAttributes}
              label={''}
              inputClassName="form-control ml-8"
              noDefaultOption={true}
              options={(fn === 'groupBy' || fn === 'sumBy'
                ? ['Bar', 'Pie', 'Doughnut']
                : ['Bar', 'Line']
              ).map((s) => ({
                label: s,
                value: s.toLowerCase(),
              }))}
              onChange={(e: { value: any }) => setChartType(e.value)}
              value={chartType}
            />
            <Button
              size="sm"
              className="rounded ml-8"
              variant="outline-secondary"
              onClick={() => exportChartData(data)}
            >
              Download
            </Button>

            <Button
              size="sm"
              className="rounded ml-8"
              variant="outline-secondary"
              onClick={() => generateChartImagePdf(chartRef.current!)}
            >
              To PDF
            </Button>
            <Button
              size="sm"
              className="rounded ml-8"
              variant="outline-secondary"
              onClick={() => onDelete()}
            >
              Delete
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-40">
        <Col xs={6} className="offset-3">
          <Chart
            //@ts-ignore
            ref={chartRef}
            options={options}
            type={chartType}
            data={{ ...chartData }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default AdhocReportRow;
