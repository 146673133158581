import { Button, Card } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OrganizationDetails from 'components/global/OrganizationsList/components/OrganizationDetails';
import { fetchOrganization } from 'components/global/OrganizationsList/OrganizationsList.helpers';
import BreadCrumbWrapper from 'components/global/BreadCrumbs/BreadCrumbs';
import userAccountSignal from 'signals/UserAccount.signal';
import {
  deleteBusinessFavorite,
  markBusinessAsFavorite,
} from 'components/global/SearchBusinessResults/searchBusinessResults.helpers';
import { organizationDetailsSignal } from 'components/global/OrganizationsList/OrganizationsList.signal';

const VCBusinessDetails = () => {
  const { pathname } = useLocation();
  const businessId = pathname.split('/')[pathname.split('/').length - 1];
  const { selectedOrganization: selectedBusiness } =
    organizationDetailsSignal.value;

  const labels = [
    '',
    'Business Search Results',
    selectedBusiness?.name,
  ];

  let favoriteRecord = null;
  favoriteRecord = selectedBusiness?.favoritedByVcs?.find(
    (fb) =>
      fb.businessId === selectedBusiness.id &&
      fb.ventureCapitalFirmId ===
        userAccountSignal.value.userData.account.ventureCapitalFirm.id
  );

  const init = useCallback(async () => {
    await fetchOrganization(businessId, '/businesses');
  });

  useEffect(() => {
    init();
  }, [businessId]);

  return (
    <BreadCrumbWrapper labels={labels}>
      <Card>
        <Card.Body>
          <OrganizationDetails />
          <div className="text-center">
            {favoriteRecord ? (
              <Button
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() => {
                  deleteBusinessFavorite(favoriteRecord, () =>
                    fetchOrganization(businessId, '/businesses')
                  );
                }}
              >
                Remove Favorite
              </Button>
            ) : (
              <Button
                style={{ minWidth: '280px' }}
                className="rounded"
                size="lg"
                onClick={() => {
                  markBusinessAsFavorite(selectedBusiness, () =>
                    fetchOrganization(businessId, '/businesses')
                  );
                }}
              >
                Mark as Favorite
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </BreadCrumbWrapper>
  );
};

export default VCBusinessDetails;
