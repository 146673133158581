import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { addPaymentAccount, handleResetForms, updateBankInfo, updateCreditCardInfo } from '../StateBilling.helpers';
import ModalWrapper from 'components/global/ModalWrapper/ModalWrapper';

const LinkAccountModal = ({ show, onToggleModal }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    onToggleModal();
    await addPaymentAccount(e.target.id);
  };

  return (
    <ModalWrapper centered show={show} size="lg" onHide={onToggleModal}>
      <Modal.Header>
        <Modal.Title>Add a Payment Account</Modal.Title>
      </Modal.Header>
      <Tabs className="mx-2" defaultActiveKey="bankAccount" onSelect={handleResetForms}>
        <Tab eventKey="bankAccount" title="Bank Account">
          <Modal.Body>
            <Form id="bankInfo" onSubmit={handleSubmit}>
              <h5>Enter Bank Information</h5>
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="name">Account Name:</Form.Label>
                <Form.Control required type="text" className="form-control" id="accountName" name="accountName" onChange={(e) => updateBankInfo(e)} />
                <Form.Control.Feedback type="invalid">Please provide an account name.</Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="col-form-label" htmlFor="firstName">First Name:</Form.Label>
                    <Form.Control required type="text" className="form-control" id="firstName" name="firstName" onChange={(e) => updateBankInfo(e)} />
                    <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="col-form-label" htmlFor="lastName">Last Name:</Form.Label>
                    <Form.Control required type="text" className="form-control" id="lastName" name="lastName" onChange={(e) => updateBankInfo(e)} />
                    <Form.Control.Feedback type="invalid">Please enter your last name.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="routingNumber">Routing Number:</Form.Label>
                <Form.Control required type="text" inputMode="numeric" minLength={9} maxLength={9} className="form-control" id="routingNumber" name="routingNumber" onChange={(e) => updateBankInfo(e)} />
                <Form.Control.Feedback type="invalid">Please enter the routing number.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="accountNumber">Account Number:</Form.Label>
                <Form.Control required type="text" inputMode="numeric" className="form-control" id="accountNumber" name="accountNumber" onChange={(e) => updateBankInfo(e)} />
                <Form.Control.Feedback type="invalid">Please enter the account number.</Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded" variant="secondary" onClick={() => onToggleModal()}>Cancel</Button>
            <Button className="rounded" type="submit" form="bankInfo">Submit</Button>
          </Modal.Footer>
        </Tab>
        <Tab eventKey="creditCard" title="Credit/Debit Card">
          <Modal.Body>
            <Form id="creditCardInfo" onSubmit={handleSubmit}>
              <h5>Enter Credit Card Information</h5>
              <Form.Group className="form-group">
                <Form.Label htmlFor="cardAccountName">Card Type:</Form.Label>
                <Form.Select required className="form-control" defaultValue="" id="cardAccountName" name="accountName" onChange={(e) => updateCreditCardInfo(e)}>
                  <option disabled value="">Choose a type...</option>
                  <option value="Visa">Visa</option>
                  <option value="Mastercard">MasterCard</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please select a card type.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="nameOnCard">Name on Card:</Form.Label>
                <Form.Control required type="text" className="form-control" id="nameOnCard" name="nameOnCard" onChange={(e) => updateCreditCardInfo(e)} />
                <Form.Control.Feedback type="invalid">Please provide the name on your card.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="col-form-label" htmlFor="cardNumber">Card Number</Form.Label>
                <Form.Control required type="text" minLength={16} maxLength={16} className="form-control" id="cardNumber" name="accountNumber" onChange={(e) => updateCreditCardInfo(e)} />
                <Form.Control.Feedback type="invalid">Please provide your card number.</Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="col-form-label" htmlFor="expirationDate">Expiration Date:</Form.Label>
                    <Form.Control required type="text" inputMode="numeric" minLength={5} maxLength={5} className="form-control" placeholder="MM/YY" id="expirationDate" name="expirationDate" onChange={(e) => updateCreditCardInfo(e)} />
                    <Form.Control.Feedback type="invalid">Please enter the expiration date.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group">
                    <Form.Label className="col-form-label" htmlFor="securityCode">Security Code:</Form.Label>
                    <Form.Control required type="text" inputMode="numeric" minLength={3} maxLength={3} className="form-control" placeholder="***" id="securityCode" name="securityCode" onChange={(e) => updateCreditCardInfo(e)} />
                    <Form.Control.Feedback type="invalid">Please enter the security code.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded" variant="secondary" onClick={() => onToggleModal()}>Cancel</Button>
            <Button className="rounded" type="submit" form="creditCardInfo">Submit</Button>
          </Modal.Footer>
        </Tab>
      </Tabs>
    </ModalWrapper>
  );
};
export default LinkAccountModal;
