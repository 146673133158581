/* eslint-disable react/destructuring-assignment */
import Alert from 'components/global/Alert';
import ExpressInterestHeader from '../ExpressInterestHeader';

const PublicAppContainer = (props) => (
    <div className="vh-100 subheader-express-public">
      <Alert />
      <ExpressInterestHeader />
      {props.children}
    </div>
  );
export default PublicAppContainer;
