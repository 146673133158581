import { Button } from 'react-bootstrap';
import {
  copyInvitationLinkToClipboard,
  showUserInvitationModal,
} from './components/ReinviteUserModal/ReinviteUserModal.helpers';
import userInvitationsSignal from './UserInvitations.signal';
import ReinviteUserModal from './components/ReinviteUserModal';
import SimpleTable from '../SimpleTable';
import {
  fetchAndSetUserInvites,
  resetUserInviteFilterInputs,
} from './UserInvitations.helpers';
import SimpleTableFilterDropdown from '../SimpleTableFilterDropdown';
import AddAccountModal from '../AddAccountModal';
import userAccountSignal from 'signals/UserAccount.signal';
import { enumCaseToTabCase } from 'libs/string';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { showAddAccountModal } from '../AddAccountModal/AddAccountModal.helpers';
import {
  getOrganizationId,
  mapInvitationOrRequestStatus,
} from 'utils/organization';
import capitalizeFirst from 'utils/capitalizeFirst';
import $appSettings from 'signals/AppSettings.signal';

const HEADERS = [
  {
    key: 'id',
    title: 'ID',
    sortable: true,
  },
  {
    key: 'organizationName',
    title: 'Organization',
    sortable: true,
  },
  {
    key: 'name',
    title: 'Contact Name',
    sortable: true,
  },
  {
    key: 'email',
    title: 'Email',
    sortable: true,
  },
  {
    key: 'status',
    title: 'Status',
    sortable: true,
  },
  {
    key: 'portal',
    title: 'Portal',
    sortable: true,
  },
  {
    key: 'action',
    title: 'Action',
  },
];

const FILTERS = [
  {
    type: 'text',
    label: 'Organization',
    key: 'organizationName',
  },
  {
    type: 'text',
    label: 'First Name',
    key: 'firstName',
  },
  {
    type: 'text',
    label: 'Last Name',
    key: 'lastName',
  },
  {
    type: 'text',
    label: 'Email',
    key: 'email',
  },
  {
    type: 'select',
    label: 'Status',
    key: 'status',
    options: [
      { label: 'Approved', value: 'APPROVED' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Denied', value: 'DENIED' },
    ],
  },
];

const buildFilters = (fixedPortalType = undefined) => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  if (fixedPortalType) {
    return [...FILTERS];
  }

  const portalType = userAccountSignal.value.userData.account.portalType;

  // a business can only invite other businesses
  // so we return the same filters
  if (portalType === PORTAL_TYPES.business) {
    return [...FILTERS];
  }

  let searchablePortalTypes;

  switch (portalType) {
    case PORTAL_TYPES.lender:
      searchablePortalTypes = [
        PORTAL_TYPES.business,
        PORTAL_TYPES.lender,
      ];
      break;
    case PORTAL_TYPES.vc:
      searchablePortalTypes = [
        PORTAL_TYPES.business,
        PORTAL_TYPES.vc,
      ];
      break;
    default:
      searchablePortalTypes = [
        PORTAL_TYPES.business,
        PORTAL_TYPES.edo,
        PORTAL_TYPES.lender,
        PORTAL_TYPES.vc,
      ];
  }

  return [
    ...FILTERS,
    {
      type: 'select',
      label: 'Portal',
      key: 'portal',
      options: searchablePortalTypes.map((t) => ({
        value: t,
        label: t,
      })),
    },
  ];
};

const UserInvitations = ({
  organizationType,
  refetchingFilter = undefined,
}) => {
  const location = useLocation();
  const userInvitations = userInvitationsSignal.value.userInvitations;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  const init = useCallback(() => {
    if (refetchingFilter) {
      if (refetchingFilter(location)) {
        return fetchAndSetUserInvites();
      } else {
        return undefined;
      }
    }

    return fetchAndSetUserInvites();
  }, [location.search]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <AddAccountModal />
      <ReinviteUserModal />
      <div className="d-flex justify-content-between mb-16">
        <SimpleTableFilterDropdown
          onClearFilterInputs={resetUserInviteFilterInputs}
          filters={buildFilters(organizationType)}
        />
        <div hidden={!organizationType}>
          <Button
            className="rounded"
            variant="primary"
            onClick={() =>
              showAddAccountModal({
                disablePortalSelect: true,
                portal: organizationType,
              })
            }
          >
            Invite New{' '}
            {organizationType === PORTAL_TYPES.vc
              ? 'VC'
              : capitalizeFirst(organizationType)}
          </Button>
        </div>
      </div>
      <SimpleTable
        headers={HEADERS}
        rows={userInvitations.map(
          (userInvite) =>
            ({
              id: getOrganizationId(userInvite.toAccount),
              organizationName: userInvite.organizationName,
              name: `${userInvite.firstName} ${userInvite.lastName}`,
              email: userInvite.email,
              status: mapInvitationOrRequestStatus(userInvite.status),
              portal:
                userInvite.portal === PORTAL_TYPES.vc
                  ? 'VC'
                  : enumCaseToTabCase(userInvite.portal),
              action: ({ key }) => (
                <td key={key} align="right">
                  {userInvite.status !== 'APPROVED' ? (
                    <>
                      <Button
                        className="rounded mr-8"
                        size="sm"
                        variant="outline-primary"
                        onClick={() =>
                          copyInvitationLinkToClipboard(userInvite.signUpLink)
                        }
                      >
                        Copy Link
                      </Button>
                      <Button
                        className="rounded"
                        size="sm"
                        variant="primary"
                        onClick={() => showUserInvitationModal(userInvite)}
                      >
                        Resend
                      </Button>
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              ),
            })
        )}
      />
    </>
  );
};

export default UserInvitations;
