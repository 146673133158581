import { Form } from 'react-bootstrap';
import { handleInputChange } from '../Field';

const Email = ({
  label,
  name,
  value,
  onChange,
  className,
  labelClassName,
  inputClassName,
}) => (
  <div className={className}>
    <Form.Label className={labelClassName}>{label}</Form.Label>
    <div>
      <Form.Control
        name={name}
        type="email"
        value={value}
        id="inputEmail"
        className={inputClassName}
        onChange={(e) => handleInputChange(e, onChange)}
      />
    </div>
  </div>
);

export default Email;
