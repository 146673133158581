import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Collapse, Container, Row } from 'react-bootstrap';
import { caretDownIcon, caretRightIcon } from 'components/global/Constant/Icons';
import { handleSelectAccount } from '../StateBilling.helpers';

const BillingTableRow = ({ accountInfo, onToggleModal }) => {
  const [showButtons, setShowButtons] = useState(false);

  const handleDeleteClick = () => {
    handleSelectAccount(accountInfo.id);
    onToggleModal();
  };

  return (
    <>
      <tr className="border border-solid border-bottom-0 border-right-0 border-left-0">
        <td style={{ minWidth: '2rem' }} className="align-middle">
          <Container className="p-0" onClick={() => setShowButtons(!showButtons)}>
            <FontAwesomeIcon size="lg" icon={showButtons ? caretDownIcon : caretRightIcon} />
          </Container>
        </td>
        <td className="text-nowrap">{accountInfo.accountName}</td>
        <td className="text-nowrap">{`xxxxxxxx${accountInfo.accountNumber?.slice(-4)}`}</td>
        <td className="text-nowrap">{accountInfo.status}</td>
        <td>
          {accountInfo.linkedPrograms.map((program, index) => {
            if (index === accountInfo.linkedPrograms.length - 1) {
              return program.name;
            }
            return `${program.name}, `;
          })}
        </td>
        <td className="text-nowrap">{accountInfo.actions}</td>
      </tr>
      <tr className="border border-top-0 border-right-0">
        <td colSpan={4} className="p-0 border-top-0 border-left-0">
          <Container>
            <Collapse in={showButtons}>
              <Row>
                <Button size="sm" className="ml-8 mb-8 rounded btn-danger" onClick={handleDeleteClick}>Delete</Button>
              </Row>
            </Collapse>
          </Container>
        </td>
      </tr>
    </>
  );
};

export default BillingTableRow;
