import states from 'components/global/Constant/usStates';

export const statesNumApplications = {
  [states.california]: 4,
  [states.nevada]: 5,
  [states.utah]: 4,
  [states.arizona]: 4,
  [states.newMexico]: 6,
  [states.texas]: 3,
  [states.georgia]: 3,
  [states.virginia]: 3,
  [states.newJersey]: 4,
  [states.alabama]: 8,
  [states.idaho]: 2,
  [states.washington]: 9,
  [states.florida]: 11,
  [states.connecticut]: 2,
  [states.indiana]: 7,
  [states.rhodeIsland]: 2,
  [states.hawaii]: 3,
  [states.newYork]: 4,
  [states.main]: 4,
  [states.maryland]: 4,
  [states.oklahoma]: 4,
  [states.massachusetts]: 4,
  [states.oregon]: 4,
};

export const alerts = [
  { title: 'LED', description: 'Dashboard not showing diagram' },
  { title: 'CPCFA', description: 'Unable to click link on report' },
];

export const users = [
  {
    name: 'Jacob',
    activeApplications: 3,
    alerts: [
      { title: 'CPCFA', numItems: 1 },
      { title: 'NM', numItems: 2 },
    ],
  },
  {
    name: 'Camryn',
    activeApplications: 3,
    alerts: [
      { title: 'NYC', numItems: 1 },
      { title: 'GA', numItems: 3 },
    ],
  },
  {
    name: 'Kiera',
    activeApplications: 3,
    alerts: [
      { title: 'OR', numItems: 3 },
      { title: 'WA', numItems: 2 },
    ],
  },
  {
    name: 'Ash',
    activeApplications: 3,
    alerts: [
      { title: 'LED', numItems: 1 },
      { title: 'USVI', numItems: 2 },
    ],
  },
];

export const notifications = [
  {
    title: 'LED',
    description: 'Message - "Need help with Issues"',
  },
  {
    title: 'Upcoming reporting period due',
    description: '1/16/2023',
  },
  {
    title: '3 New Staes Approved for SSBCI',
    description: '',
  },
  {
    title: 'CAAT Maintenance schedule for 12/23-26',
    description: '',
  },
];

export const caseData = [
  { date: '2022-02-15', cases: 200 },
  { date: '2022-02-22', cases: 180 },
  { date: '2022-03-01', cases: 150 },
  { date: '2022-03-15', cases: 110 },
  { date: '2022-03-22', cases: 110 },
  { date: '2022-03-29', cases: 90 },
  { date: '2022-04-05', cases: 80 },
  { date: '2022-04-12', cases: 80 },
  { date: '2022-04-19', cases: 60 },
  { date: '2022-04-26', cases: 40 },
  { date: '2022-05-03', cases: 30 },
];

export const programs = [];

export const productBreakDownData = [
  { title: 'CAAT TOOl', count: 220 },
  { title: 'Academy', count: 30 },
];

export default {
  statesNumApplications, alerts,
};
