import Signal, { Sygnal } from 'libs/signals';
import { generateFileDownload } from './DownloadButton.helpers';
import { ReactElement } from 'react';
import { UploadedFile } from '../PreviewButton/FilePreviewButton';

const previewLoader =
  (signal: Sygnal<boolean>) =>
  (uploadedFile: UploadedFile, model: string, downloadName: string) =>
    generateFileDownload({
      uploadedFile,
      model,
      signal,
      downloadName,
    });

type Props = {
  render: (
    submitting: boolean,
    fn: (
      file: UploadedFile,
      documentType: string,
      documentName: string
    ) => unknown
  ) => ReactElement;
};

const DownloadButton: React.FC<Props> = ({ render }) => {
  const loadingSignal = Signal<boolean>(false);
  const previewCurry = previewLoader(loadingSignal);
  return render(loadingSignal.value, previewCurry);
};

export default DownloadButton;
