import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import AdhocReportRow from "./components/DatasetRow";
import { AdhocReportQuery } from "types";
import { useHistory } from "react-router-dom";
import { History } from "history";
import {
  FILTERING_CONFIG,
  getFilteringOptions,
  getModels,
  mapColumnIdToLabel,
  mapQueryToWords,
  REPORT_TYPES
} from "./AdhocReporting.helpers";
import userAccountSignal from "signals/UserAccount.signal";
import alertSignal from "signals/Alert.signal";
import Field from "../Field/Field";

const missingAttributes = {
  name: undefined,
  className: undefined,
  labelClassName: undefined,
  onBlur: undefined,
  placeholder: undefined,
  isInvalid: undefined,
  onSelect: undefined,
  onFocus: undefined,
  feedbackMessage: undefined,
  allowCents: undefined,
  disabled: false,
  inputRef: undefined,
  noDefaultOption: undefined,
  lineBreak: false,
};

function injectQueriesIntoUrl(history: History, queries: AdhocReportQuery[]) {
  const { pathname } = history.location;
  return history.push(
    `${pathname}?q=${btoa(encodeURIComponent(JSON.stringify(queries)))}`
  );
}

const AdhocReporting = () => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const [model, setModel] = useState<string>('');
  const [fn, setFn] = useState<AdhocReportQuery['fn'] | ''>('');
  const [count, setCount] = useState<string>('');
  const [queries, setQueries] = useState<AdhocReportQuery[]>([]);
  const [activeKey, setActiveKey] = useState<string>('');

  useEffect(() => {
    const q = new URLSearchParams(search).get('q');
    if (q) {
      setQueries(JSON.parse(decodeURIComponent(atob(q))));
    } else {
      setQueries([]);
    }
  }, [search]);

  return (
    <Card className="rounded-0">
      <Card.Body>
        <h3 className="text-center">Adhoc Reporting</h3>
        <hr />
        <Row>
          <Col>
            <Field.Select
              {...missingAttributes}
              label="Search On"
              inputClassName="form-control"
              options={getModels(
                userAccountSignal.value.userData.account.portalType
              )}
              onChange={(e: { value: any }) => {
                setModel(e.value)
                setFn('');
                setCount('');
              }}
              value={model}
            />
          </Col>
          <br />
          <Col>
            <Field.Select
              {...missingAttributes}
              label="Report Type"
              disabled={model === ''}
              inputClassName="form-control"
              options={REPORT_TYPES.filter(({ value }) => {
                if (!model) {
                  return false;
                }

                return !!FILTERING_CONFIG[value][model]
              })}
              onChange={(e: { value: any }) => {
                if (e.value === fn) {
                  return;
                }

                setFn(e.value);
                setCount('');
              }}
              value={fn}
            />
          </Col>
          <Col>
            <Field.Select
              {...missingAttributes}
              label="Plot"
              inputClassName="form-control"
              disabled={fn === '' || model === ''}
              options={
                !fn || !model
                  ? []
                  : // @ts-ignore
                  getFilteringOptions(
                      userAccountSignal.value.userData.account.portalType
                    )[fn][model]
                      .filter(v => v !== 'sum' && v !== 'join')
                      .map((v) => ({
                      label: mapColumnIdToLabel(v),
                      value: v,
                    }))
              }
              onChange={(e: { value: any }) => setCount(e.value)}
              value={count}
            />
          </Col>
          <Col>
            <p className="m-0 mb-2 invisible">Submit</p>
            <Button
              className="rounded w-100"
              disabled={count === ''}
              onClick={() => {
                if (
                  queries.some(
                    (q) => q.model === model && q.count === count && q.fn === fn
                  )
                ) {
                  return alertSignal.update({
                    type: 'notification',
                    message: 'Report already selected.',
                  });
                }

                injectQueriesIntoUrl(history, [
                  ...queries,
                  ...[{ model, fn, count }],
                ] as AdhocReportQuery[]);
                setActiveKey(queries.length.toString());
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
        <hr />
        <Tabs activeKey={activeKey} onSelect={(tab) => setActiveKey(tab || '')}>
          {queries.map((query, index) => (
            <Tab
              key={index}
              title={mapQueryToWords(query)}
              eventKey={index.toString()}
            >
              <AdhocReportRow
                key={index}
                query={query}
                index={index}
                onDelete={() => {
                  injectQueriesIntoUrl(
                    history,
                    queries.filter((_, j) => index !== j)
                  );
                  setActiveKey((index - 1).toString());
                }}
              />
            </Tab>
          ))}
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default AdhocReporting;
