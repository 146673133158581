import alertSignal from 'signals/Alert.signal';
import api from 'api/api';
import loaderSignal from 'signals/Loader.signal';
import edoSediSignal from './EdoSedi.signals';
import { DateTime } from 'luxon';

export function getLastTwelveMonths() {
  const now = DateTime.now();
  const lastTwelveMonths = [];
  for (let i = 0; i < 12; i++) {
    lastTwelveMonths.push(now.minus({ month: i }));
  }

  return lastTwelveMonths
    .sort((a, b) => a.toJSDate().getTime() - b.toJSDate().getTime())
    .map((luxonMonth) => luxonMonth.toFormat('MMMM'));
}

export const fetchAndSetSediPerformanceData = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const [data] = await Promise.all([
      api.get({
        path: '/metrics',
        options: {
          dataset: 'sedi',
        },
      }),
    ]);

    return edoSediSignal.update({
      obligated: Object.fromEntries(
        Object.entries(data.obligated).map(([key, { agg }]) => [
          DateTime.fromISO(key).toFormat('MMMM'),
          agg,
        ])
      ),
      expended: Object.fromEntries(
        Object.entries(data.expended).map(([key, { agg }]) => [
          DateTime.fromISO(key).toFormat('MMMM'),
          agg,
        ])
      ),
    });
  } catch (error) {
    return alertSignal.update({
      type: 'alert',
      message: error.message || 'Unable to fetch programs',
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};
