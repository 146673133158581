function capitalizeFirst(mySentence) {
  if (!mySentence) {
    return '';
  }

  const finalSentence = mySentence.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  return finalSentence;
}

export default capitalizeFirst;
