import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import submitApplicationSignal from 'signals/SubmitApplication.signal';
import {
  exitAndReset,
  navigateToApplication,
  reset,
} from '../SubmitApplications.helpers';

export const SubmitApplicationsSuccessPage = () => {
  const { fundingProgram } = submitApplicationSignal.value;
  const history = useHistory();

  return (
    <Container>
      <Row className="mt-64">
        <Col sm={2}>
          <FontAwesomeIcon className="h1" icon={faThumbsUp} />
        </Col>
        <Col>
          <p>
            You&apos;ve uploaded an {fundingProgram.name} application for
            review, you will be notified as soon as any decisions are made.
          </p>
          <p>Click the button below to upload more, otherwise select exit.</p>
        </Col>
      </Row>
      <Row className="mt-64 mb-32 justify-content-center">
        <Col className="d-flex justify-content-center">
          <Button onClick={reset}>Submit Another Application</Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <Button onClick={() => navigateToApplication(history)}>
            Go to Application
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <Button onClick={() => exitAndReset(history)}>Exit</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SubmitApplicationsSuccessPage;
