import Signal from 'libs/signals';
import {
  AddPrefixToObjectKeys,
  Business,
  BusinessDemographics,
} from '../types';

// this does not knock out the parent key containing the object
// [K in keyof Business]: Business[K] extends object ? never : K;
export type FlattenedUIBusiness = Omit<
  Business,
  | 'assistance'
  | 'employment'
  | 'finance'
  | 'misc'
  | 'minorityStatus'
  | 'demographics'
  | 'sediCertification'
> &
  AddPrefixToObjectKeys<Business['sediCertification'], 'sediCertification.'> &
  AddPrefixToObjectKeys<Business['assistance'], 'assistance.'> &
  AddPrefixToObjectKeys<Business['employment'], 'employment.'> &
  AddPrefixToObjectKeys<Business['finance'], 'finance.'> &
  AddPrefixToObjectKeys<Business['misc'], 'misc.'> &
  AddPrefixToObjectKeys<Business['minorityStatus'], 'minorityStatus.'> &
  AddPrefixToObjectKeys<
    BusinessDemographics['ethnicity'],
    'demographics.ethnicity.'
  > &
  AddPrefixToObjectKeys<BusinessDemographics['race'], 'demographics.race.'> &
  AddPrefixToObjectKeys<
    BusinessDemographics['middleEasternOrNorthAfricanAncestry'],
    'demographics.middleEasternOrNorthAfricanAncestry.'
  > &
  AddPrefixToObjectKeys<
    BusinessDemographics['gender'],
    'demographics.gender.'
  > &
  AddPrefixToObjectKeys<
    BusinessDemographics['sexualOrientation'],
    'demographics.sexualOrientation.'
  > &
  AddPrefixToObjectKeys<
    BusinessDemographics['veteranStatus'],
    'demographics.veteranStatus.'
  >;

export type BusinessForm = Partial<FlattenedUIBusiness>;

export type BusinessFormErrors = {
  [K in keyof BusinessForm]: boolean;
};

export type BusinessFormDirty = {
  [K in keyof BusinessForm]: boolean;
};

export const businessSettingsSignal = Signal<Partial<FlattenedUIBusiness>>({});

// @ts-ignore
export const businessSettingsErrorsSignal = Signal<BusinessFormErrors>({});

// @ts-ignore
export const businessSettingsDirtySignal = Signal<BusinessFormDirty>({});

export const businessSettingsSubmittingSignal = Signal(false);

export const businessSettingsIndustryOptionsSignal = Signal({
  value: '',
  industries: [],
});

export const businessSettingsAddressSearchSignal = Signal({
  addressSearch: '',
  addressOptions: [],
});
