/* eslint-disable no-unreachable */
import React, { useCallback, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ProgramSignal, {
  fundingProgramFormErrorsSignal,
} from 'signals/Program.signal';
import addProgramImg from 'assets/images/add-program.png';
import EdoAddProgramDocumentForm from './components/EdoAddProgramDocumentForm';
import BasicProgramInfo from './components/BasicProgramInfo';
import {
  addDocumentToBusinessChoices,
  addDocumentToLenderChoices,
  addDocumentToVcChoices,
  fetchAndSetAddProgramData,
  handleAddProgramFileRemoval,
  handleAddProgramFileUpdate,
  handleAddProgramStepSubmission,
  handleAddSuggestedDocument,
  handleAttachFile,
  handleDocumentUploadInputFocusOut,
  handleInputChange,
  handleInputFocusOut,
  NUM_OF_STEPS,
  prevStep,
  removeBusinessDocumentChoice,
  removeLenderDocumentChoice,
  removeVcDocumentChoice,
  resetFormFlow,
} from './EdoAddProgram.helpers';
import SuccessPage from './components/SuccessPage';
import { fetchAndSetPrograms } from '../EdoPrograms/EdoPrograms.helpers';
import { Prompt } from 'react-router-dom';
import ApprovalChainTemplating from 'components/global/ApprovalChainTemplating/ApprovalChainTemplating';
import approvalChainTemplatingSignal from 'components/global/ApprovalChainTemplating/ApprovalChainTemplating.signal';
import loaderSignal from 'signals/Loader.signal';

const STEP_TITLES = [
  '1. Basic Info',
  '2. Program Documents',
  '3. Approval Chain',
];

export const EdoAddProgram = () => {
  const { programInfo } = ProgramSignal.value;

  const init = useCallback(async () => {
    await fetchAndSetAddProgramData();
    await fetchAndSetPrograms();
  });

  useEffect(() => {
    ProgramSignal.reset();
    fundingProgramFormErrorsSignal.reset();
    approvalChainTemplatingSignal.reset();

    init();
  }, []);

  const renderStepContent = () => {
    const { step } = programInfo;
    switch (step) {
      case 1:
        return (
          <BasicProgramInfo
            handleInputChange={handleInputChange}
            programInfo={programInfo}
            programTypes={ProgramSignal.value.programTypes}
            platforms={ProgramSignal.value.platforms}
            administrators={ProgramSignal.value.administrators}
            creatingProgram={true}
            handleInputFocusOut={handleInputFocusOut}
          />
        );
      case 2:
        return (
          <EdoAddProgramDocumentForm
            handleAttachFile={handleAttachFile}
            programInfo={programInfo}
            creatingProgram
            handleFileRemoval={handleAddProgramFileRemoval}
            handleFileChange={handleAddProgramFileUpdate}
            handleAddLenderDocument={addDocumentToLenderChoices}
            handleAddBusinessDocument={addDocumentToBusinessChoices}
            handleAddVcDocument={addDocumentToVcChoices}
            handleRemoveBusinessDocument={removeBusinessDocumentChoice}
            handleRemoveLenderDocument={removeLenderDocumentChoice}
            handleRemoveVcDocument={removeVcDocumentChoice}
            programTypes={ProgramSignal.value.programTypes}
            handleDocumentUploadInputFocusOut={
              handleDocumentUploadInputFocusOut
            }
            handleAddSuggestedDocument={handleAddSuggestedDocument}
          />
        );
      case 3:
        return <ApprovalChainTemplating creatingProgram />;
      case 4:
        return <SuccessPage />;
      default:
        return ProgramSignal.update({ step: 1 });
    }
  };

  return (
    <>
      <Prompt
        when={programInfo.step < 4}
        message="You will lose all of your progress, are you sure you want to leave?"
      />
      <Row>
        <Col className="mt-32">
          <Row className="mb-8">
            <Col>
              <h5 className="text-secondary ml-16">
                {STEP_TITLES[programInfo.step - 1]}
              </h5>
            </Col>
            <Col>
              <Button
                variant="outline-secondary"
                className="float-right mr-16 rounded"
                hidden={programInfo.step > NUM_OF_STEPS}
                onClick={() => resetFormFlow()}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Row className="mb-32">
            <Col>
              <Card className="bg-white mx-16 rounded-0">
                <Card.Body>
                  <Row>
                    <Col xl={8}>
                      <Form onSubmit={() => console.log('onSubmit()')}>
                        {renderStepContent()}
                      </Form>
                      <Row className="my-64" hidden={programInfo.step === 4}>
                        <Col className="text-center">
                          <Button
                            className="rounded"
                            hidden={programInfo.step === 1}
                            onClick={prevStep}
                          >
                            Prev Page
                          </Button>
                        </Col>
                        <Col hidden={programInfo.step > NUM_OF_STEPS}>
                          <p className="text-center">{`${programInfo.step}/${NUM_OF_STEPS}`}</p>
                        </Col>
                        <Col className="text-center">
                          <Button
                            className="rounded"
                            disabled={loaderSignal.value.isContentLoading}
                            onClick={() => handleAddProgramStepSubmission()}
                          >
                            {programInfo.step === 3
                              ? 'Create Program'
                              : 'Next Page'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <img
                        className="img-fluid"
                        src={addProgramImg}
                        alt="MstreetX logo"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EdoAddProgram;
