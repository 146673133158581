import { getConfig } from 'config/config';
import { BingAddress } from '../types';

type BingAddressResponse = {
  resourceSets: [
    {
      estimatedTotal: number;
      resources: [
        {
          __type: string;
          address: BingAddress;
          geocodePoints: Array<{ coordinates: [number, number] }>;
        }
      ];
    }
  ];
};

const bingMapsKey = getConfig('REACT_APP_BING_MAPS_KEY');
const requestUrlBuilder = (input: string) =>
  `https://dev.virtualearth.net/REST/v1/Locations?&maxResults=7&q=${input}&key=${bingMapsKey}`;

const queryAddresses = async (
  potentialAddress: string
): Promise<BingAddress[]> => {
  let addresses: BingAddress[] = [];

  if (!potentialAddress) return addresses;

  const response = await fetch(requestUrlBuilder(potentialAddress));

  const json = await response.json();

  if (json) {
    addresses = (json as BingAddressResponse).resourceSets[0]?.resources?.map(
      (result) => ({
        ...result.address,
        geocodePoints: result.geocodePoints,
      })
    );
  }

  return addresses;
};

export const createGoogleMapsLink = (address: string): string => {
  const encodedAddress = encodeURIComponent(address);

  return `https://maps.google.com?q=${encodedAddress}`;
};

export default queryAddresses;
