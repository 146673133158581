import Signal from 'libs/signals';

const fundDetailsSignal = Signal({
  id: null,
  fundingProgram: {},
  ventureCapitalFirm: {},
  name: '',
  maxFundSize: 0,
  capitalCalls: [],
  selectedCapitalCall: null,
  portfolioCompanies: [],
  newCapitalCallPortions: [],
  suggestedDate: new Date(),
  errors: {},
  modalVisible: null,
  fundRequests: [],
  documents: [],
  newCapitalCallRequestDocuments: {
    value: [],
    dirty: false,
    errors: [],
  },
  makeCapitalCallDocuments: [],
  selectedCapitalCallTemplate: null,
  bankAccounts: [],
  selectedBankAccountId: null,
});

export default fundDetailsSignal;
