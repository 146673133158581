import PaginationButton from 'components/global/PaginationButton/PaginationButton';
import { useEffect } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import submitApplicationSignal from 'signals/SubmitApplication.signal';
import {
  nextStep,
  prevStep,
  validateAllComplianceItems,
} from '../SubmitApplications.helpers';
import applicationComplianceSignal from '../../ApplicationCompliance/ApplicationCompliance.signal';
import ComplianceChecklistItem from '../../ApplicationCompliance/ComplianceChecklistItem';
import {
  COMPLIANCE_CHECK_DATA,
  handleComplianceItemChange,
  updateNumberOfComplianceItems,
} from '../../ApplicationCompliance/ApplicationCompliance.helpers';

const SubmitApplicationsComplianceCheck = ({
  referenceProgramId,
  applicationDetails = false,
}) => {
  const { currentComplianceItem, totalComplianceItems } =
    applicationComplianceSignal.value;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    updateNumberOfComplianceItems(referenceProgramId);
  }, [referenceProgramId]);

  useEffect(() => {
    applicationComplianceSignal.update({
      isApplicationDetailsView: applicationDetails,
    });
  }, []);

  const complianceChecklistIsValid = validateAllComplianceItems(
    submitApplicationSignal.value
  );

  return (
    <>
      <Row className="mb-32">
        <Col>
          <h4>Application Compliance Checklist</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ComplianceItems />
          <Row className="text-center">
            <Col>
              <PaginationButton
                hidden={currentComplianceItem <= 1}
                increment={false}
                onClick={() => {
                  handleComplianceItemChange(-1);
                  scrollToTop();
                }}
              />
            </Col>
            <Col>
              <PaginationButton
                hidden={currentComplianceItem >= totalComplianceItems}
                increment
                onClick={() => {
                  handleComplianceItemChange(1);
                  scrollToTop();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-64">
        <Col className="text-center">
          <Button onClick={prevStep}>Prev Page</Button>
        </Col>
        <Col className="text-center">
          <div className="mb-8">
            <Button disabled={!complianceChecklistIsValid} onClick={nextStep}>
              Next Page
            </Button>
          </div>
          <small className="text-danger" hidden={complianceChecklistIsValid}>
            * All compliance items must be filled out before proceeding.
          </small>
        </Col>
      </Row>
      <div>
        <Button
          onClick={scrollToTop}
          variant="outline-secondary"
          className="rounded"
        >
          Back to Top
        </Button>
      </div>
    </>
  );
};

const ComplianceItems = () => {
  const { currentComplianceItem } = applicationComplianceSignal.value;
  const { referenceProgramId } = submitApplicationSignal.value;

  if (
    referenceProgramId === 1 ||
    referenceProgramId === 2 ||
    referenceProgramId === 3 ||
    referenceProgramId === 4 ||
    referenceProgramId === 12
  ) {
    return (
      <Tabs activeKey={currentComplianceItem} title={null} className="mb-16">
        <Tab eventKey={1}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemOne}
          />
        </Tab>
        <Tab eventKey={2}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemTwo}
          />
        </Tab>
        <Tab eventKey={3}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemThree}
          />
        </Tab>
        <Tab eventKey={4}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemFour}
          />
        </Tab>
        <Tab eventKey={5}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemFive}
          />
        </Tab>
        <Tab eventKey={6}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemSix}
          />
        </Tab>
        <Tab eventKey={7}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemSeven}
          />
        </Tab>
        <Tab eventKey={8}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemEight}
          />
        </Tab>
        <Tab eventKey={9}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemNine}
          />
        </Tab>
        <Tab eventKey={10}>
          <ComplianceChecklistItem
            complianceItemData={COMPLIANCE_CHECK_DATA.complianceItemTen}
          />
        </Tab>
      </Tabs>
    );
  }

  if (referenceProgramId === 7 || referenceProgramId === 8) {
    return (
      <ComplianceChecklistItem
        complianceItemData={COMPLIANCE_CHECK_DATA.equityProgramCompliance}
      />
    );
  }

  return null;
};

export default SubmitApplicationsComplianceCheck;
