import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkmarkIcon, uploadIcon } from 'components/global/Constant/Icons';
import {
  DATA_ROOM_COMPONENT_VIEW_TYPES,
} from 'components/global/Constant/constants';
import Field from 'components/global/Field/Field';
import DownloadButton from 'components/global/utils/FilesAndDocuments/DownloadButton/DownloadButton';
import FilePreviewButton from 'components/global/utils/FilesAndDocuments/PreviewButton/FilePreviewButton';
import { enumCaseToTabCase } from 'libs/string';
import { Button, Col, Row } from 'react-bootstrap';
import $appSettings from 'signals/AppSettings.signal';
import { isFileTypeValidForPreview } from 'utils/checkFileTypeForPreview';
import { camelCaseToTitleText } from 'utils/formatTxt';

const DocumentButtons = ({
  documents,
  documentType,
  documentBucket,
  title,
  description,
  className,
  onSubmitFiles,
  viewType,
  onSelectChange,
  downloadParentId,
  onAttachSingleDocument,
}) => {
  const { FUND_APPLICATION_DOCUMENT_STATUS } = $appSettings.value.constants;

  return (
    <div className={className}>
      <h5 hidden={!title}>{title}</h5>
      <p hidden={!description}>{description}</p>
      {documents.map((doc, index) => {
        const status = doc.status
          ? doc.status
          : doc.uploadedDocument?.status
          ? doc.uploadedDocument.status
          : FUND_APPLICATION_DOCUMENT_STATUS.pending;
        return (
          <Row
            style={{
              backgroundColor: doc.uploadedDocument ? '' : '#EDEDED',
              cursor:
                doc.uploadedDocument ||
                viewType !== DATA_ROOM_COMPONENT_VIEW_TYPES.dataRoom
                  ? ''
                  : 'pointer',
            }}
            key={`doc.name-${index}`}
            className={`border border-gray rounded d-flex flex-row align-items-center justify-content-between px-16 py-8 mt-16${
              doc.uploadedDocument ? ' text-muted' : ''
            }`}
            onClick={() =>
              viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.dataRoom &&
              !doc.uploadedDocument &&
              document.getElementById(`fileUpload-${index}`)?.click()
            }
          >
            <Col>
              <input
                type="file"
                hidden
                id={`fileUpload-${index}`}
                name={doc.name}
                onChange={(e) =>
                  e.target.files.length &&
                  onSubmitFiles([e.target.files[0]], documentType, e.target.name)
                }
              />
              <div className="d-flex flex-row align-items-center">
                <div className="border border-dark rounded px-1 mr-16">
                  <FontAwesomeIcon
                    className="text-success"
                    visibility={doc.uploadedDocument ? '' : 'hidden'}
                    size="sm"
                    icon={checkmarkIcon}
                  />
                </div>
                <p className="mb-0 text-nowrap text-overflow-ellipsis">
                  {doc.name}
                  {doc.uploadedDocument
                    ? ` - ${doc.uploadedDocument.name} ${
                        viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.upload
                          ? `(${enumCaseToTabCase(status)})`
                          : ''
                      }`
                    : ''}
                </p>
              </div>
            </Col>
            {viewType !== DATA_ROOM_COMPONENT_VIEW_TYPES.dataRoom ? (
              <Col>
                <Row className="d-flex flex-row align-items-center justify-content-end">
                  <Col lg={4}>
                    <FilePreviewButton
                      render={(submitting, fn) => (
                        <Button
                          size="sm"
                          disabled={
                            !doc.uploadedDocument ||
                            !isFileTypeValidForPreview(doc.fileType)
                          }
                          variant="outline-dark"
                          className="mr-16 w-100"
                          onClick={() => fn(doc.uploadedDocument, documentBucket)}
                        >
                          {!doc.uploadedDocument
                            ? 'Not Submitted'
                            : isFileTypeValidForPreview(doc.fileType)
                            ? 'View Document'
                            : 'Preview Unavailable'}
                        </Button>
                      )}
                    />
                  </Col>
                  {viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.upload && (
                    <>
                      <Col lg={4}>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          className="mr-16 w-100"
                          onClick={() => onAttachSingleDocument(doc)}
                        >
                          {doc.uploadedDocument
                            ? 'Replace Document'
                            : 'Attach Document'}
                        </Button>
                      </Col>
                      <Col lg={4}>
                        {isFileTypeValidForPreview(doc.fileType) ? (
                          <DownloadButton
                            render={(submitting, fn) => (
                              <Button
                                size="sm"
                                variant="outline-info"
                                className="w-100 text-nowrap"
                                onClick={() =>
                                  fn(
                                    {
                                      ...doc,
                                      parentId: downloadParentId,
                                    },
                                    'fundDocuments',
                                    doc.name
                                  )
                                }
                              >
                                Download Template
                              </Button>
                            )}
                          />
                        ) : (
                          <i>No template provided</i>
                        )}
                      </Col>
                    </>
                  )}
                  {(viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.updateStatus ||
                    viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.viewOnly) && (
                    <Col lg={4}>
                      <Field.Select
                        disabled={
                          !doc.uploadedDocument ||
                          viewType === DATA_ROOM_COMPONENT_VIEW_TYPES.viewOnly
                        }
                        noDefaultOption
                        inputClassName="form-control"
                        value={
                          doc.status ||
                          doc.uploadedDocument?.status ||
                          FUND_APPLICATION_DOCUMENT_STATUS.pending
                        }
                        onChange={(e) => onSelectChange(e, doc)}
                        options={[
                          ...Object.keys(FUND_APPLICATION_DOCUMENT_STATUS).map(
                            (status) => ({
                              label: camelCaseToTitleText(status),
                              value: FUND_APPLICATION_DOCUMENT_STATUS[status],
                            })
                          ),
                        ]}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            ) : (
              <>
                {!doc.uploadedDocument && (
                  <Col>
                    <div className="d-flex flex-row justify-content-end">
                      <FontAwesomeIcon
                        visibility={doc.uploadedDocument ? 'hidden' : ''}
                        icon={uploadIcon}
                      />
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        );
      })}
    </div>
  );
}


export default DocumentButtons;
