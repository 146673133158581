import Signal from 'libs/signals';

const twoStepVerificationForm = Signal({
  sms: {
    active: false,
    phoneNumber: '',
    smsCode: '',
  },
  email: {
    active: false,
    email: '',
    emailCode: '',
  },
});

export default twoStepVerificationForm;
