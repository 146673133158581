import alertSignal from "signals/Alert.signal";
import $capTable from "./capTable.signal";
import loaderSignal from "signals/Loader.signal";
import api from "api/api";
import userAccountSignal from "signals/UserAccount.signal";

export const handleCapTableCellClick = (rowIndex, field, value) => {
  $capTable.update({ editingField: field, editingRowIndex: rowIndex, initialFieldValue: value });
};

export const handleCancelFieldEdit = () => {
  const { editingRowIndex, editingField, capTable, initialFieldValue } = $capTable.value;
  const newArr = capTable.map((data, index) => {
    if (editingRowIndex === index) {
      return {
        ...data,
        [editingField]: initialFieldValue,
      };
    }
    return data;
  });

  $capTable.update({ editingField: null, editingRowIndex: null, initialFieldValue: null, capTable: newArr });
};

export const handleCapTableInputChange = (e) => {
  const { editingField, editingRowIndex, capTable } = $capTable.value;

  const newArr = capTable.map((data, index) => {
    if (editingRowIndex === index) {
      return {
        ...data,
        [editingField]: e.value,
      };
    }
    return data;
  });

  return $capTable.update({ capTable: newArr });
};

export const handleConfirmEditClick = () => $capTable.update({ editingField: null, editingRowIndex: null, initialFieldValue: null });

export const handleAddRowClick = () => {
  const { capTable } = $capTable.value;

  const newRow = {
    security: '',
    stakeholder: '',
    status: '',
    rounds: '',
    shares: '',
    sharePrice: '',
    transactionalValue: '',
    fullyDiluted: '',
    ownership: '',
  };

  $capTable.update({ capTable: [...capTable, newRow] });
};

export const handleRowCheckboxClick = (index) => {
  const { selectedRows } = $capTable.value;

  $capTable.update({
    selectedRows: selectedRows.includes(index) ?
      selectedRows.filter(idx => idx !== index) :
      [...selectedRows, index]
  });
};

export const handleRemoveSelectedClick = () => {
  const { selectedRows, capTable } = $capTable.value;


  const newCapTable = capTable.filter((data, index) => !selectedRows.includes(index));

  $capTable.update({ capTable: newCapTable, selectedRows: [] });
};

export const handleCloseCapTableModal = () => $capTable.reset();


export const handleSaveCapTableClick = async () => {
  const { capTable, fetchRecord } = $capTable.value;
  const businessId = userAccountSignal.value.userData.account.business.id;
  try {
    loaderSignal.update({ isContentLoading: true });

    await api.put({
      path: `/businesses/${businessId}/capTables`,
      body: {
        create: {
          tableData: capTable,
        },
        update: {
          tableData: capTable,
        }
      },
    });

    await fetchRecord();

    $capTable.update({ editingField: null, editingRowIndex: null });

    alertSignal.update({
      type: 'notification',
      variant: 'success',
      message: 'Successfully updated Cap Table',
    })
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const calculateTransactionValue = (rowIndex) => {
  const { capTable } = $capTable.value;

  $capTable.update({
    capTable: capTable.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          transactionalValue: (row.shares || 0) * (row.sharePrice || 0),
        };
      }
      return row;
    }),
  });
};

export const handleTabKeyPress = () => {
  const { editingField, editingRowIndex } = $capTable.value;
  const fieldOrder = [
    'security',
    'stakeholder',
    'status',
    'rounds',
    'shares',
    'sharePrice',
    'fullyDiluted',
    'ownership',
  ];

  if (editingField !== null && editingRowIndex >= 0 && (editingField === 'shares' || editingField === 'sharePrice')) {
    calculateTransactionValue(editingRowIndex);
  }

  if (!!editingField && editingRowIndex > -1) {
    const currentFieldIndex = fieldOrder.findIndex(f => f === editingField);
    if (currentFieldIndex < fieldOrder.length - 1) {
      $capTable.update({ editingField: fieldOrder[currentFieldIndex + 1] });
    } else {
      $capTable.update({ editingField: null, editingRowIndex: null });
    }
  }

  return null;
};

export const handleEnterKeyPress = () => {
  const { editingField, editingRowIndex } = $capTable.value;

  if (editingField !== null && editingRowIndex >= 0 && (editingField === 'shares' || editingField === 'sharePrice')) {
    calculateTransactionValue(editingRowIndex);
  }

  if (!!editingField || editingRowIndex > -1) {
    $capTable.update({ editingField: null, editingRowIndex: null });
  }
};

export const handleCapTableClick = (capTable, fetchRecord, businessName, canEdit) => $capTable.update({
  businessName,
  capTable: capTable || [],
  fetchRecord,
  modalVisible: true,
  canEdit: canEdit || false,
});
