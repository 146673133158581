import { businessMyLoansSignal } from "signals/BusinessMyLoans.signal";
import loaderSignal from "signals/Loader.signal";
import api from "api/api";
import alertSignal from "signals/Alert.signal";
import userAccountSignal from "signals/UserAccount.signal";
import history from "../../../../history";

const xFormSortToOrderByClause = () => {
  const { sort } =
  Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  if (key === 'programName') {
    return [
      {
        fundingProgram: {
          name: dir,
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

const xFormAppliedFiltersToWhereClause = () => {
  const where = {
    businessId: userAccountSignal.value.userData.account.business.id,
    fundingProgram: {
      is: {}
    }
  };

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.programName) {
    where.fundingProgram.is.id = Number(params.programName);
  } else {
    delete where.fundingProgram;
  }

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.status) {
    where.status = params.status;
  }

  return where;
};

export const fetchAndSetMyLoans = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const applications = await api.get({
      path: '/applications',
      options: {
        orderBy: xFormSortToOrderByClause(),
        where: xFormAppliedFiltersToWhereClause(),
        include: {
          technicalAssistance: true,
          loan: {
            include: {
              payments: true,
            },
          },
          fundingProgramMembership: {
            include: {
              programRequest: true,
              lender: true,
              ventureCapitalFirm: true,
              fundingProgram: {
                include: {
                  referenceProgram: true,
                },
              },
            },
          },
        },
      },
    });

    return businessMyLoansSignal.update({
      applications,
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getBusinessSummaryData = (application) => {
  const { businessSummaryChart } = businessMyLoansSignal.value;
  const { approvedAmount } = application;
  let amountPaidBack = 0;
  if (application.loan) {
    amountPaidBack = application.loan.payments?.reduce(
      (acc, p) => acc + p.amount,
      0
    );
  }

  const percentageAmountPaidBack = (amountPaidBack / approvedAmount) * 100;
  const percentageRemainingApprovedAmount = 100 - percentageAmountPaidBack;

  businessMyLoansSignal.update({
    businessSummaryChart: {
      ...businessSummaryChart,
      datasets: businessSummaryChart.datasets.map((dataset) => ({
        ...dataset,
        data: [percentageAmountPaidBack, percentageRemainingApprovedAmount],
      })),
    },
    amountPaidBack,
  });
};

export default fetchAndSetMyLoans;
