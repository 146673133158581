import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange, name, style, onBlur = null, isInvalid }) => {
  const quillRef = useRef(null);

  const handleGetValue = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const text = editor.getText().trim();
      return text;
    }
  };

  const handleGetLength = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const length = editor.getLength();
      return length;
    }
  };

  const handleChange = (newValue) => {
    const length = handleGetLength();

    if (length > 1) {
      return onChange(newValue, name);
    } else {
      return onChange('', name);
    }
  }

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={(newValue) => handleChange(newValue)}
      onBlur={() => onBlur && onBlur(name, handleGetValue())}
      style={{ ...style, border: isInvalid ? 'solid 1px red' : '' }}
      modules={{
        toolbar: [
          [{ size: [ 'small', false, 'large', 'huge' ] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'indent': '+1' }, { 'indent': '-1' }, { 'align': [] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          ['blockquote', 'link'],
        ],
      }}
    />
  );
};

export default RichTextEditor;
