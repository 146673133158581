import history from '../history';

const resetSearchParams = (params = {}) => {
  const { pathname } = history.location;
  if (Object.keys(params).length) {
    history.replace(`${pathname}?${new URLSearchParams(params).toString()}`);
  } else {
    history.replace(pathname);
  }
};

export default resetSearchParams;
