import sleep from 'libs/sleep';
import alertSignal from 'signals/Alert.signal';
import businessDashboardSignal from 'signals/BusinessDashboard.signal';
import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import userSignal from 'signals/User.signal';
import userAccountSignal from '../../../../signals/UserAccount.signal';
import history from '../../../../history';
import { removeSavedOpportunity } from '../BusinessSearchResults/BusinessSearchResults.helpers';
import { applicationsChartPresets } from 'components/global/ChartJS/chartPresetsAndStyles';
import $appSettings from 'signals/AppSettings.signal';

export const loanChartOptions = {
  plugins: {
    legend: {
      position: 'bottom',
      onClick: null,
    },
    datalabels: {
      formatter: (value) => `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      labels: {
        value: {
          color: 'white',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      display: function (context) {
        return context.dataset.data[context.dataIndex] > 0;
      },
    },
  },
};

export const fetchAndSetDashboardData = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    const [favoriteRecords, applications] = await Promise.all([
      api.get({
        path: '/businessFavorites',
        options: {
          where: xFormAppliedFiltersToWhereClause(),
          orderBy: xFormSortToOrderByClause(),
        }
      }),
      api.get({
        path: '/applications',
        options: {
          where: {
            businessId: userAccountSignal.value.userData.account.business.id,
          },
          include: {
            loan: {
              include: {
                payments: true,
              },
            },
          },
        },
      }),
    ]);

    const totalBorrowing = applications.reduce((acc, current) => acc + current.approvedAmount
      , 0);

    const totalPaid = applications.reduce((acc, current) => {
      if (current.loan) {
        const totalPaidForThisLoan = current.loan.payments?.reduce((loanAcc, p) => loanAcc + p.amount, 0);
        return acc + totalPaidForThisLoan;
      }
      return acc;
    }, 0);

    const totalOutstanding = totalBorrowing - totalPaid;

    return businessDashboardSignal.update({
      favoriteRecords,
      loanData: applications,
      loanChartObj: formatLoanChart({ totalBorrowing, totalOutstanding }),
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const deleteOpportunity = async () => {
  loaderSignal.update({
    isContentLoading: true,
  });
  try {
    await sleep(500);
    const [fetchedLoanData] = await Promise.all([]);
    businessDashboardSignal.update({
      loanData: fetchedLoanData,
      loanChartObj: formatLoanChart(fetchedLoanData),
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      message: error.message,
      error,
    });
  } finally {
    loaderSignal.reset();
  }
  return false;
};

export const deleteSavedOpportunity = async (savedOpportunity) => {
  const { savedCapitalOpportunities } = userSignal.value;
  const idx = savedCapitalOpportunities.indexOf(savedOpportunity);
  const newArr = [...savedCapitalOpportunities];
  newArr.splice(idx, 1);
  userSignal.update({
    savedCapitalOpportunities: [...newArr],
  });
};

export const sendEmail = (emailAddress) => {
  window.open(`mailto:${emailAddress}`);
};

export const openLink = (link) => {
  window.open(link);
};

export const handleDeleteOpportunity = (user, savedOpportunity, onSetUser) => {
  const newSavedOppArr = user.savedCapitalOpportunities.filter(
    (opp) => opp.id !== savedOpportunity.id
  );
  onSetUser((prev) => ({ ...prev, savedCapitalOpportunities: newSavedOppArr }));
};

const formatLoanChart = (loanInfo) => ({
  labels: ['Total Repaid', 'Total Outstanding'],
  datasets: [
    {
      data: [
        loanInfo.totalBorrowing - loanInfo.totalOutstanding,
        loanInfo.totalOutstanding,
      ],
      backgroundColor: ['rgb(54, 162, 235)', 'gray'],
    },
  ],
});

export const statusOptions = ['INCOMPLETE', 'PENDING', 'APPROVED', 'AWARDED'];

export const showProgramsOfferedModal = (selectedOpportunity) =>
  businessDashboardSignal.update({ selectedOpportunity, programsOfferedModalVisible: true });

export const hideProgramsOfferedModal = () =>
  businessDashboardSignal.update({ selectedOpportunity: null, programsOfferedModalVisible: false });

export const setBusinessDashboardDropdownFilter = ({ name, value }) =>
  businessDashboardSignal.update({
    dropdownFilters: {
      ...businessDashboardSignal.value.dropdownFilters,
      [name]: value,
    },
  });

export const setBusinessDashboardAppliedFilters = (appliedFilters) =>
  businessDashboardSignal.update({ appliedFilters });

export const handleBusinessDashboardSortChange = (sort) =>
  businessDashboardSignal.update({
    sort,
  });

export const resetBusinessDashboardFilterInputs = () =>
  businessDashboardSignal.update({ dropdownFilters: {} });

const xFormAppliedFiltersToWhereClause = () => {
  const { PORTAL_TYPES } = $appSettings.value.constants;
  const where = {
    OR: [
      {
        ventureCapitalFirm: {},
      },
      {
        lender: {},
      },
    ],
  };

  const params =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (params.id) {
    where.id = { in: [Number(params.id)] };
  }

  if (params.lenderType) {
    where.OR[0].ventureCapitalFirm.lenderType = params.lenderType;
    where.OR[1].lender.lenderType = params.lenderType;
  }

  if (params.requiresBusinessPlan) {
    where.OR[0].ventureCapitalFirm.requiresBusinessPlan =
      params.requiresBusinessPlan === 'true';
    where.OR[1].lender.requiresBusinessPlan =
      params.requiresBusinessPlan === 'true';
  }

  if (params.fundsStartups) {
    where.OR[0].ventureCapitalFirm.fundsStartups =
      params.fundsStartups === 'true';
    where.OR[1].lender.fundsStartups = params.fundsStartups === 'true';
  }

  if (params.lenderName) {
    const rawId = params.lenderName;
    const id = rawId.split('-')[0];
    if (rawId.includes('lender')) {
      where.lender = {
        is: {
          id: Number(id),
        },
      };
    } else {
      where.ventureCapitalFirm = {
        is: {
          id: Number(id),
        },
      };
    }
  }

  if (params.organizationType) {
    if (params.organizationType === PORTAL_TYPES.lender) {
      where.NOT = {
        lender: null,
      };
    } else if (params.organizationType === PORTAL_TYPES.vc) {
      where.NOT = {
        ventureCapitalFirm: null,
      };
    }
  }

  if (Object.keys(where.OR[0].ventureCapitalFirm).length === 0) {
    delete where.OR;
  } else if (Object.keys(where.OR[0].ventureCapitalFirm).length === 0) {
    delete where.OR;
  }

  return where;
};

const xFormSortToOrderByClause = () => {
  const { sort } =
    Object.fromEntries(new URLSearchParams(history.location.search)) || {};

  if (!sort) {
    return undefined;
  }

  const sortSplit = sort.split('-');
  const key = sortSplit[0];
  const dir = sortSplit[1];

  if (key === 'lenderName') {
    return [
      {
        lender: {
          name: dir,
        },
      },
      {
        ventureCapitalFirm: {
          name: dir,
        },
      },
    ];
  } else if (
    key === 'lenderType' ||
    key === 'fundsStartups' ||
    key === 'requiresBusinessPlan'
  ) {
    return [
      {
        lender: {
          [key]: dir,
        },
      },
      {
        ventureCapitalFirm: {
          [key]: dir,
        },
      },
    ];
  } else if (key === 'programName') {
    return [
      {
        fundingProgram: {
          name: dir,
        },
      },
    ];
  } else {
    return [{ [key]: dir }];
  }
};

export const handleRemoveSavedOpportunityFromDashboard = async (favoriteId) => {
  try {
    await removeSavedOpportunity(favoriteId);
    await fetchAndSetDashboardData();
  } catch (error) {
    alertSignal.update({
      type: 'alert',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const getApplicationsMetrics = () => {
  const { loanData } = businessDashboardSignal.value;
  const { APPLICATION_STATUS } = $appSettings.value.constants;
  const applicationsMetrics = applicationsChartPresets;

  const numApprovedApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.approved
  ).length;
  const numPendingApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.pending
  ).length;
  const numIncompleteApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.incomplete
  ).length;
  const numDeniedApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.denied
  ).length;
  const numSubmittedForApprovalApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.submittedForApproval
  ).length;
  const numOfInProgressApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.inProgress
  ).length;
  const numOfDefaultApplications = loanData.filter(
    (app) => app.status === APPLICATION_STATUS.default
  ).length;

  const newApplicationMetrics = {
    ...applicationsMetrics,
    datasets: applicationsMetrics.datasets.map((dataset) => ({
      ...dataset,
      data: [
        numApprovedApplications,
        numSubmittedForApprovalApplications,
        numPendingApplications,
        numIncompleteApplications,
        numDeniedApplications,
        numOfInProgressApplications,
        numOfDefaultApplications,
      ],
    })),
  };

  businessDashboardSignal.update({ applicationsMetrics: newApplicationMetrics });
};

export default {};
