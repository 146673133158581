/* eslint-disable no-unused-vars */
import alertSignal from 'signals/Alert.signal';
import billingSignal from 'components/global/Billing/Billing.signal';
import loaderSignal from 'signals/Loader.signal';
import api from 'api/api';
import plaidSignal from 'signals/PlaidSignal.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import $appSettings from 'signals/AppSettings.signal';

export const fetchAndSetBillingData = async () => {
  const { userData } = userAccountSignal.value;

  loaderSignal.update({ isContentLoading: true });
  try {
    const [bankAccounts, fundingPrograms] = await Promise.all([
      api.get({
        path: '/bankaccounts',
        options: {
          orderBy: xFormSortToOrderByClause(),
          where: {
            accountId: userData.accountId,
          },
          include: {
            bankAccountProgramAccesses: {
              include: {
                bankAccount: true,
                fundingProgram: {
                  include: {
                    platform: true,
                  },
                },
              },
            },
          },
        },
      }),
      fetchPrograms(),
    ]);
    billingSignal.update({
      bankAccounts,
      fundingPrograms,
    });
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const fetchPrograms = async () => {
  const { userData } = userAccountSignal.value;
  const { PORTAL_TYPES } = $appSettings.value.constants;
  try {
    let programs = [];
    if (userData.account.portalType === PORTAL_TYPES.edo) {
      const fundingPrograms = await api.get({
        path: '/fundingPrograms',
        options: {
          where: {
            edoId: userData.account.edo.id,
          },
          include: {
            platform: true,
            bankAccountProgramAccesses: {
              include: {
                bankAccount: true,
              },
            },
          },
        },
      });
      programs = fundingPrograms;
    } else {
      const fundingProgramMemberships = await api.get({
        path: '/fundingProgramMemberships',
        options: {
          where: {
            lenderId: userData.account.lender?.id,
            ventureCapitalFirmId: userData.account.ventureCapitalFirm?.id,
          },
          include: {
            platform: true,
            fundingProgram: {
              include: {
                bankAccountProgramAccesses: true,
              },
            },
          },
        },
      });
      programs = fundingProgramMemberships.map((mpm) => mpm.fundingProgram);
    }

    return programs;
  } catch (error) {
    alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  }
};

export const getPlaidGeneratedToken = async () => {
  try {
    loaderSignal.update({ isContentLoading: true });
    const { userData } = userAccountSignal.value;
    const data = await api.post({ path: '/plaid', body: { id: userData.id } });
    plaidSignal.update({ generatedToken: data.link_token });
  } catch (err) {
    alertSignal.update({
      type: 'notification',
      variant: 'danger',
      message: 'Plaid Service Error, please try again later',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const createBankAccountsFromPlaidToken = async (
  publicToken,
  metadata
) => {
  // eslint-disable-next-line camelcase
  const { userData } = userAccountSignal.value;

  try {
    loaderSignal.update({ isContentLoading: true });
    await api.post({
      path: '/bankAccounts',
      body: { token: publicToken, id: userData.accountId, metadata },
    });
    await fetchAndSetBillingData();
    return alertSignal.update({
      type: 'notification',
      message: 'Successfully Synced Bank Accounts.',
      variant: 'success',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      variant: 'danger',
      message: 'Plaid Service Error, please try again later.',
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleDeleteBankAccount = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const body = {
      where: {
        id: billingSignal.value.toDeleteBankAccount.id,
      },
      include: {
        bankAccountProgramAccesses: true,
      },
    };
    await api.delete({ path: '/bankaccounts', body });
    billingSignal.update({ toDeleteBankAccount: null });
    await fetchAndSetBillingData();
    return alertSignal.update({
      type: 'notification',
      message: 'Successfully Deleted Bank Account',
      variant: 'success',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

export const handleShowBankAccountActionModal = (selectedBankAccount) =>
  billingSignal.update({
    selectedBankAccount,
    linkedPrograms: selectedBankAccount.bankAccountProgramAccesses.map(
      (bapa) => bapa.fundingProgram
    ),
    linkProgramsActionModalVisible: true,
  });

export const handleShowLinkedProgramDetails = (selectedBankAccount) =>
  billingSignal.update({
    selectedBankAccount,
    linkedPrograms: selectedBankAccount.bankAccountProgramAccesses.map(
      (bapa) => bapa.fundingProgram
    ),
    linkedProgramsDetailModalState: 'VIEW',
  });

export const handleShowLinkedPlatformDetails = (selectedBankAccount) => {
  const linkedPrograms = selectedBankAccount.bankAccountProgramAccesses.map(
    (bapa) => bapa.fundingProgram
  );
  const linkedPlatforms = [];
  linkedPrograms.forEach((program) => {
    if (
      !linkedPlatforms.find((platform) => platform.id === program.platform.id)
    ) {
      linkedPlatforms.push(program.platform);
    }
  });
  billingSignal.update({
    selectedBankAccount,
    linkedPlatforms,
    linkedPlatformsDetailModalVisible: true,
  });
};

export const handleShowDeleteBankAccount = (toDeleteBankAccount) =>
  billingSignal.update({
    toDeleteBankAccount,
  });

export const handleHideBankAccountActionModal = () =>
  billingSignal.update({
    selectedBankAccount: null,
    linkProgramsActionModalVisible: false,
  });

export const handleHideLinkedProgramDetailsModal = () =>
  billingSignal.update({
    selectedBankAccount: null,
    linkedProgramsDetailModalVisible: false,
    programsFilteredBySearch: billingSignal.value.fundingPrograms || [],
    linkedProgramsDetailModalState: null,
  });

export const handleHideLinkedPlatformDetailsModal = () =>
  billingSignal.update({
    selectedBankAccount: null,
    linkedPlatforms: [],
    linkedPlatformsDetailModalVisible: false,
  });

export const handleHideBankAccountDelete = () =>
  billingSignal.update({ toDeleteBankAccount: null });

export const handleBankAccountFundingProgramCheckboxClick = (
  fundingProgram
) => {
  const { linkedPrograms } = billingSignal.value;
  const matchingProgram = linkedPrograms.find(
    (lp) => lp.id === fundingProgram.id
  );
  if (matchingProgram) {
    return billingSignal.update({
      linkedPrograms: linkedPrograms.filter(
        (lp) => lp.id !== fundingProgram.id
      ),
    });
  }
  return billingSignal.update({
    linkedPrograms: [...linkedPrograms, fundingProgram],
  });
};

const deleteBankAccountAccessForProgram = async (ids) =>
  api.delete({
    path: '/bankAccountProgramAccess',
    body: {
      where: {
        id: { in: ids },
      },
      include: {
        bankAccount: true,
      },
    },
  });

const createAndConnectBankAccountAccessForProgram = async (data) =>
  api.post({
    path: '/bankAccountProgramAccess',
    body: data,
  });

export const saveLinkedProgramAccessesChange = async () => {
  const { linkedPrograms, selectedBankAccount } = billingSignal.value;
  const linkedProgramsIds = linkedPrograms.map((lp) => lp.id);
  const originalPrograms = selectedBankAccount.bankAccountProgramAccesses.map(
    (bapa) => bapa.fundingProgram
  );
  const programsToDelete = originalPrograms.filter(
    (op) => !linkedProgramsIds.includes(op.id)
  );
  const bankAccountAccessProgramToDelete =
    selectedBankAccount.bankAccountProgramAccesses.filter((bapa) =>
      programsToDelete.map((p) => p.id).includes(bapa.fundingProgramId)
    );

  loaderSignal.update({ isContentLoading: true });

  try {
    if (bankAccountAccessProgramToDelete.length > 0) {
      await deleteBankAccountAccessForProgram(
        bankAccountAccessProgramToDelete.map((p) => p.id)
      );
    }
    const programIdsToCreate = linkedProgramsIds.filter(
      (op) => !originalPrograms.includes(op.id)
    );
    if (programIdsToCreate.length > 0) {
      const createData = programIdsToCreate.map((id) => ({
        fundingProgramId: id,
        bankAccountId: selectedBankAccount.id,
      }));
      await createAndConnectBankAccountAccessForProgram(createData);
    }
    await fetchAndSetBillingData();
    const updatedSelectedAccount = billingSignal.value.bankAccounts.find(
      (bankAccount) => bankAccount.id === selectedBankAccount.id
    );
    billingSignal.update({ selectedBankAccount: updatedSelectedAccount });
    return alertSignal.update({
      type: 'notification',
      message: 'Successfully Updated Program Access.',
      variant: 'success',
    });
  } catch (error) {
    return alertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const xFormSortToOrderByClause = () => {
  const { sort } = billingSignal.value;

  if (!sort) {
    return undefined;
  } else {
    return { [sort.key]: sort.dir };
  }
};

export const handleBillingSortChange = (sort) =>
  billingSignal.update({
    sort,
  });

export const handleBackToLinkedProgramsClick = () => {
  const { selectedBankAccount } = billingSignal.value;
  billingSignal.update({
    linkedProgramsDetailModalState: 'VIEW',
    linkedPrograms: selectedBankAccount.bankAccountProgramAccesses.map(
      (bapa) => bapa.fundingProgram
    ),
  });
}
