import { Col, Form, Row } from 'react-bootstrap';
import userAccountSignal from 'signals/UserAccount.signal';
import addAccountModalSignal from '../AddAccountModal.signal';
import {
  handleAddAccountModalFormChange,
  handleAddFavoriteBusinessChange,
  validateEmail,
} from '../AddAccountModal.helpers';
import Field from 'components/global/Field/Field';
import $appSettings from 'signals/AppSettings.signal';

const AddAccountForm = () => {
  const { portalType } = userAccountSignal.value.userData.account;
  const { PORTAL_TYPES } = $appSettings.value.constants;

  return (
    <Form>
      <p className="font-weight-bold mb-8">Account Information</p>
      <Row className="mb-24">
        <Col lg="6">
          <Form.Group>
            <Form.Label>
              {!!addAccountModalSignal.value.disablePortalSelect
                ? 'Portal Type'
                : 'Choose Portal Type'}
            </Form.Label>
            <Form.Select
              className="form-control"
              aria-label="Portal"
              id="portal"
              name="portal"
              required
              value={addAccountModalSignal.value.portal}
              onChange={(e) => {
                handleAddAccountModalFormChange(e);
              }}
              disabled={!!addAccountModalSignal.value.disablePortalSelect}
            >
              <option value="">Select Portal</option>
              {portalType === 'STATE' && (
                <>
                  <option value="STATE">State</option>
                  <option value="EDO">EDO</option>
                </>
              )}
              <option value="LENDER">Lender</option>
              <option value="VC">VC</option>
              <option value="BUSINESS">Business</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col
          className="mt-16 mt-lg-0"
          lg="6"
          hidden={
            portalType === PORTAL_TYPES.state
              ? addAccountModalSignal.value.portal === PORTAL_TYPES.edo ||
                addAccountModalSignal.value.portal === ''
              : false
          }
        >
          <label>Organization Name</label>
          <Form.Control
            id="organizationName"
            name="organizationName"
            required={
              addAccountModalSignal.value.portal !== PORTAL_TYPES.edo &&
              addAccountModalSignal.value.portal !== ''
            }
            placeholder="Organization Name"
            value={addAccountModalSignal.value.organizationName}
            className="form-control mb-2"
            onChange={(e) => {
              handleAddAccountModalFormChange(e);
            }}
          />
        </Col>
      </Row>
      <p className="font-weight-bold mb-8 mt-32">Owner Contact Information</p>
      <Row className="mb-24">
        <Col lg="6">
          <label>First Name</label>
          <Form.Control
            placeholder="First Name"
            required
            className="form-control"
            id="firstName"
            name="firstName"
            value={addAccountModalSignal.value.firstName}
            onChange={(e) => {
              handleAddAccountModalFormChange(e);
            }}
          />
        </Col>
        <Col lg="6" className="mt-16 mt-lg-0">
          <label>Last Name</label>
          <Form.Control
            id="lastName"
            name="lastName"
            required
            placeholder="Last Name"
            className="form-control mb-2"
            value={addAccountModalSignal.value.lastName}
            onChange={(e) => {
              handleAddAccountModalFormChange(e);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg="6">
          <label>Email</label>
          <Form.Group>
            <Form.Control
              id="email"
              name="email"
              required
              placeholder="Email"
              className="form-control mb-2"
              value={addAccountModalSignal.value.email}
              onChange={(e) => {
                handleAddAccountModalFormChange(e);
              }}
              isInvalid={addAccountModalSignal.value.emailError}
              onBlur={validateEmail}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address. Email usernames should be at
              least 2 characters and begin with a letter. The only special
              characters allowed are ._+-. There cannot be more than one
              occurrence of the top level domain - ex. .com
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {portalType === PORTAL_TYPES.lender ||
          (portalType === PORTAL_TYPES.vc && (
            <Col
              className="d-flex flex-column justify-content-end"
              hidden={
                portalType !== PORTAL_TYPES.lender &&
                portalType !== PORTAL_TYPES.vc
              }
              lg="6"
            >
              <Field.Checkbox
                className="text-right"
                name="addBusinessToFavorites"
                value={addAccountModalSignal.value.addBusinessToFavorites}
                label="Add Business to Favorites"
                onChange={handleAddFavoriteBusinessChange}
              />
            </Col>
          ))}
      </Row>
    </Form>
  );
};

export default AddAccountForm;
