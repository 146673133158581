import Signal from 'libs/signals';

const createCapitalCallSignal = Signal({
  newCapitalCallPortions: [],
  portfolioCompanies: [],
  suggestedDate: new Date(),
  selectedBankAccountId: null,
  errors: {},
  bankAccounts: [],
  makeCapitalCallDocuments: [],
  status: null,
  ssbciCOIChecklistInputEditable: false,
});

export default createCapitalCallSignal;
