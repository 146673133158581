import Signal from 'libs/signals';

const initState = {
  fundingProgramMemberships: [],
  userProgramAccesses: [],
  users: [],
  fundingPrograms: [],
  detailModalUser: null,
  deleteModalUser: null,
  userDetailForm: {
    address: {},
  },
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
  addressSearch: '',
  addressOptions: [],
  errors: {},
};

const userManagementSignal = Signal(initState);

export default userManagementSignal;
