import Signal from 'libs/signals';

export const edoUsersSignal = Signal({
  users: [],
  fundingPrograms: [],
  submittingAddUser: false,
  selectedUser: null,
  form: {},
  programsInView: [],
  programsFilteredBySearch: [],
  sort: { key: 'id', dir: 'desc' },
  dropdownFilters: {},
  appliedFilters: {},
});

export default edoUsersSignal;
