import api from 'api/api';
import {
  einRegex,
  phoneRegex,
  regulatoryIdRegex,
} from 'components/global/Constant/regularExpression';
import AlertSignal from 'signals/Alert.signal';
import {
  lenderSettingsAddressSearchSignal,
  lenderSettingsBusinessNewIndustrySignal,
  lenderSettingsIndustryDataSignal,
  lenderSettingsOrganizationInfoErrorsSignal,
  lenderSettingsOrganizationInfoSignal,
} from 'signals/LenderSettingsUsers.signal';
import loaderSignal from 'signals/Loader.signal';
import userAccountSignal from 'signals/UserAccount.signal';
import replaceNullsWithEmptyStrings from 'utils/replaceNullsWithEmptyStrings';
import validateUrl from 'utils/validateUrl';
import validateEmailUtil from 'utils/validateEmail';
import queryAddresses from 'utils/queryAddresses';

export const fetchAndSetLender = async () => {
  loaderSignal.update({ isContentLoading: true });
  try {
    const lenders = await api.get({
      path: '/lenders',
      options: {
        where: {
          accountId: userAccountSignal.value.userData.accountId,
        },
      },
    });

    if (lenders && lenders.length === 1) {
      const lender = lenders[0];
      const lenderInfo = formatAndMapOrganizationDataToSignal(lender);
      lenderSettingsOrganizationInfoSignal.update(lenderInfo);
      const staticIndustryTags = lenderSettingsIndustryDataSignal.value;
      const customIndustryTags = lender.industrySelection.filter(
        (industry) => !staticIndustryTags.includes(industry)
      );
      lenderSettingsIndustryDataSignal.update([
        ...lenderSettingsIndustryDataSignal.value,
        ...customIndustryTags,
      ]);
    }
  } catch (error) {
    AlertSignal.update({
      type: 'notification',
      error,
      message: error.message,
    });
  } finally {
    loaderSignal.reset();
  }
};

const formatAndMapOrganizationDataToSignal = (fetchedOrganization) =>
  replaceNullsWithEmptyStrings(fetchedOrganization);

export const formatAndMapSignalToOrganizationData = (organizationInfo) => ({
  ...organizationInfo,
  loanRangeMin: Number(organizationInfo.loanRangeMin),
  loanRangeMax: Number(organizationInfo.loanRangeMax),
});

export const handleAddNewIndustryTag = () => {
  const newIndustry = lenderSettingsBusinessNewIndustrySignal.value;
  const industryData = lenderSettingsIndustryDataSignal.value;
  if (newIndustry.length > 0 && !industryData.includes(newIndustry)) {
    lenderSettingsIndustryDataSignal.update([...industryData, newIndustry]);
    lenderSettingsBusinessNewIndustrySignal.reset();
  }
};

export const handleNewIndustryChange = (e) => {
  const { value } = e.target;

  lenderSettingsBusinessNewIndustrySignal.update(value);
};

export const handleOrganizationInfoChange = (e) => {
  const { type, name } = e.target;
  let { value } = e.target;

  if (type === 'radio') {
    if (value === 'yes') value = true;
    if (value === 'no') value = false;
  }

  lenderSettingsOrganizationInfoSignal.update({ [name]: value });
  if (name === 'loanRangeMax' || name === 'loanRangeMin') {
    lenderSettingsOrganizationInfoErrorsSignal.update({ seekingRange: false });
  } else {
    lenderSettingsOrganizationInfoErrorsSignal.update({ [name]: false });
  }
};

export const handleOrganizationInfoIndustrySelectionChange = (value) => {
  const organizationInfo = lenderSettingsOrganizationInfoSignal.value;
  const organizationInfoErrors =
    lenderSettingsOrganizationInfoErrorsSignal.value;
  let newIndustryError = organizationInfoErrors.industrySelection;
  let newIndustrySelectionArr = [...organizationInfo.industrySelection];
  if (newIndustrySelectionArr.includes(value)) {
    newIndustrySelectionArr = newIndustrySelectionArr.filter(
      (industry) => industry !== value
    );
  } else {
    newIndustrySelectionArr.push(value);
  }

  if (
    organizationInfoErrors.industrySelection &&
    newIndustrySelectionArr.length > 0
  ) {
    newIndustryError = false;
  }

  lenderSettingsOrganizationInfoSignal.update({
    industrySelection: newIndustrySelectionArr,
  });
  lenderSettingsOrganizationInfoErrorsSignal.update({
    industrySelection: newIndustryError,
  });
};

export const handleOrganizationInfoSubmit = async (e) => {
  e.preventDefault();
  const organizationInfo = lenderSettingsOrganizationInfoSignal.value;
  setOrganizationInfoErrors();

  if (
    !Object.values(lenderSettingsOrganizationInfoErrorsSignal.value).includes(
      true
    )
  ) {
    const body = formatAndMapSignalToOrganizationData(organizationInfo);
    try {
      loaderSignal.update({
        isContentLoading: true,
        message: 'Updating organization info...',
      });
      await api.patch({ path: '/lenders', body });
      AlertSignal.update({
        variant: 'success',
        type: 'notification',
        message: 'Successfully updated organization.',
      });
    } catch (error) {
      AlertSignal.update({
        type: 'alert',
        error,
        message: 'Failed to save organization.',
      });
    } finally {
      loaderSignal.reset();
    }
  }
};

export const setOrganizationInfoErrors = () => {
  const organizationInfo = lenderSettingsOrganizationInfoSignal.value;
  const newErrors = {
    industrySelection: false,
    email: false,
    phoneNumber: false,
    zipCode: false,
    ein: false,
    regulatoryId: false,
    website: false,
  };

  if (organizationInfo.industrySelection.length <= 0) {
    newErrors.industrySelection = true;
  }
  if (!validateEmailUtil(organizationInfo.email)) {
    newErrors.email = true;
  }
  if (!phoneRegex.test(organizationInfo.phoneNumber)) {
    newErrors.phoneNumber = true;
  }
  if (
    Number(organizationInfo.loanRangeMax) <=
    Number(organizationInfo.loanRangeMin)
  ) {
    newErrors.seekingRange = true;
  }
  if (!einRegex.test(organizationInfo.ein)) {
    newErrors.ein = true;
  }
  if (!regulatoryIdRegex.test(organizationInfo.regulatoryId)) {
    newErrors.regulatoryId = true;
  }
  if (!validateUrl(organizationInfo.website)) {
    newErrors.website = true;
  }
  if (!Object.keys(organizationInfo.address).length) {
    newErrors.address = true;
  }

  lenderSettingsOrganizationInfoErrorsSignal.update(newErrors);
};

export const handleInputBlur = (e) => {
  const { name } = e.target;
  const organizationInfo = lenderSettingsOrganizationInfoSignal.value;

  if (name === 'ein' && !einRegex.test(organizationInfo.ein)) {
    lenderSettingsOrganizationInfoErrorsSignal.update({ ein: true });
  }

  if (name === 'website' && !validateUrl(organizationInfo.website)) {
    lenderSettingsOrganizationInfoErrorsSignal.update({ website: true });
  }

  if (
    name === 'phoneNumber' &&
    !phoneRegex.test(organizationInfo.phoneNumber)
  ) {
    lenderSettingsOrganizationInfoErrorsSignal.update({ phoneNumber: true });
  }
};

export const handleAddressSearchChange = (input) => {
  lenderSettingsOrganizationInfoSignal.update({ address: {} });
  lenderSettingsAddressSearchSignal.update({ addressSearch: input });
  lenderSettingsOrganizationInfoErrorsSignal.update({ address: false });
};

export const handleSelectAddress = (option) => {
  lenderSettingsOrganizationInfoSignal.update({ address: option });
  lenderSettingsAddressSearchSignal.update({
    addressSearch: option.formattedAddress,
  });
  lenderSettingsOrganizationInfoErrorsSignal.update({ address: false });
};

export const getAddressOptions = async () => {
  const bingAddresses = await queryAddresses(
    lenderSettingsAddressSearchSignal.value.addressSearch
  );
  lenderSettingsAddressSearchSignal.update({
    addressOptions: bingAddresses.map((ba) => ba),
  });
};

export const handleAddressBlur = () => {
  if (!Object.keys(lenderSettingsOrganizationInfoSignal.value.address).length) {
    lenderSettingsOrganizationInfoErrorsSignal.update({ address: true });
  }
};

export const handleAddressSearchFocus = () => {
  lenderSettingsAddressSearchSignal.update({
    addressSearch:
      lenderSettingsOrganizationInfoSignal.value.address?.formattedAddress ||
      lenderSettingsAddressSearchSignal.value.addressSearch,
  });
};
