import { Badge, Button, Card, Col, Collapse, Row } from "react-bootstrap";
import formatMoney from "utils/formatMoney";
import DocumentButtons from "components/global/DataRoom/components/DocumentButtons";
import { DATA_ROOM_COMPONENT_VIEW_TYPES } from "components/global/Constant/constants";
import createCapitalCallSignal from "./createCapitalCall.signal";
import {
  checkApplicationDocuments,
  fetchAndSetFund,
  handleCapitalCallAmountChange,
  handleCapitalCallInputChange,
  handleCapitalCallTransactionTermsChange,
  handleCreateCapitalCallSubmission,
  handlePortfolioCompanyNameClick,
  handleSelectBankAccount,
  handleSelectCapitalCallFile,
  handleStatusChange
} from "./createCapitalCall.helpers";
import { useLocation } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";
import FundApplicationModal from "../FundApplication/components/FundApplicationModal";
import fundApplicationSignal from "../FundApplication/FundApplication.signals";
import TransactionTermsForm from "../TransactionTermsForm/TransactionTermsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { caretDownIcon, caretRightIcon } from "../Constant/Icons";
import Field from "../Field/Field";
import userAccountSignal from "signals/UserAccount.signal";
import $appSettings from "signals/AppSettings.signal";
import { enumCaseToTabCase } from "libs/string";
import VCConflictOfInterestChecklist from "../VCConflictOfInterestChecklist/VCConflictOfInterestChecklist";

const CollapsibleCapitalCallForms = ({
  vcPortfolioCompany,
  capitalCallPortion,
  onCapitalCallTransactionTermsChange,
}) => {
  const [transactionTermsVisible, setTransactionTermsVisible] = useState(false);
  const [ssbciCOIChecklistVisible, setSsbciCOIChecklistVisible] =
    useState(false);

  useEffect(() => {
    if (ssbciCOIChecklistVisible) {
      createCapitalCallSignal.update({
        ssbciCOIChecklistInputEditable: true,
      });
    } else {
      createCapitalCallSignal.update({
        ssbciCOIChecklistInputEditable: false,
      });
    }
  }, [ssbciCOIChecklistVisible]);

  return (
    <Fragment>
      <div
        className="d-flex flex-row align-items-center bg-light p-16 pointer"
        onClick={() => setTransactionTermsVisible(!transactionTermsVisible)}
      >
        <p className="mb-0 mr-24">Transaction Terms</p>
        <FontAwesomeIcon
          icon={transactionTermsVisible ? caretDownIcon : caretRightIcon}
        />
      </div>
      <Collapse in={transactionTermsVisible}>
        <div>
          <TransactionTermsForm
            transactionTerms={capitalCallPortion.transactionTerms}
            onChange={(e) => onCapitalCallTransactionTermsChange(e)}
          />
        </div>
      </Collapse>
      <div
        className="d-flex flex-row align-items-center bg-light p-16 pointer mt-2"
        onClick={() => setSsbciCOIChecklistVisible(!ssbciCOIChecklistVisible)}
      >
        <p className="mb-0 mr-24">Conflict of Interest Checklist</p>
        <FontAwesomeIcon
          icon={ssbciCOIChecklistVisible ? caretDownIcon : caretRightIcon}
        />
      </div>
      <Collapse in={ssbciCOIChecklistVisible}>
        <div style={{ margin: '0 auto' }}>
          <div
            className="border p-48 shadow-sm margin-auto my-24"
            style={{ maxWidth: '1024px', margin: '0 auto' }}
          >
            <VCConflictOfInterestChecklist
              business={vcPortfolioCompany.business}
            />
          </div>
        </div>
      </Collapse>
    </Fragment>
  );
};

const CreateCapitalCall = () => {
  const {
    newCapitalCallPortions,
    suggestedDate,
    portfolioCompanies,
    errors,
    id,
    makeCapitalCallDocuments,
    bankAccounts,
    selectedBankAccountId,
  } = createCapitalCallSignal.value;
  const location = useLocation();
  const segments = location.pathname.split('/');
  const fundId = segments[segments.length - 2];
  const newCapitalCallTotal = newCapitalCallPortions.reduce(
    (acc, curr) => (acc += curr.amount),
    0
  );
  const fileInput = document.getElementById('capitalCallFileUpload');
  const portalType = userAccountSignal.value.userData.account.portalType;
  const { PORTAL_TYPES, CAPITAL_CALL_STATUS } = $appSettings.value.constants;

  const resetFileInput = () => (fileInput.value = '');

  const init = useCallback(async () => await fetchAndSetFund(fundId), []);

  useEffect(() => {
    init();

    return fundApplicationSignal.reset();
  }, []);

  return (
    <Card className="rounded-0 mb-40">
      <FundApplicationModal />
      <Card.Body>
        <h3>Create a Capital Call</h3>
        <hr />
        {portalType === PORTAL_TYPES.edo && (
          <>
            <Row>
              <Col lg={6}>
                <Field.Select
                  label="Initial Status"
                  labelClassName="font-weight-bold"
                  inputClassName="form-control"
                  name="status"
                  options={Object.values(CAPITAL_CALL_STATUS)
                    .filter(s => [CAPITAL_CALL_STATUS.funded, CAPITAL_CALL_STATUS.pending].includes(s))
                    .map((status) => ({
                    label: enumCaseToTabCase(status),
                    value: status,
                  }))}
                  lineBreak
                  onChange={handleStatusChange}
                />
              </Col>
            </Row>
            <hr/>
          </>
        )}

        <h5 className="font-weight-bold">Portfolio Companies</h5>
        <p>Enter an amount for each portfolio company.</p>
        {portfolioCompanies.map((pc) => {
          const capitalCallPortion = newCapitalCallPortions.find(
            (nccp) => nccp.vcPortfolioCompanyId === pc.id
          );
          const applicationFeedback = checkApplicationDocuments(pc);

          return (
            <div key={pc.id}>
              <Row className="align-items-center py-8">
                <Col>
                  <a
                    className="mb-0 font-weight-bold pointer"
                    onClick={() => handlePortfolioCompanyNameClick(pc)}
                  >
                    {pc.business.name} <Badge bg={applicationFeedback.background} className="text-white ml-16 py-8">{applicationFeedback.message}</Badge>
                  </a>
                </Col>
                <Col>
                  <Field.Currency
                    name="capitalCallAmount"
                    value={capitalCallPortion.amount}
                    onChange={(e) => handleCapitalCallAmountChange(e, pc.id)}
                  />
                  <small
                    hidden={!errors.capitalCallAmount}
                    className="text-danger"
                  >
                    Please provide an allocation amount.
                  </small>
                </Col>
              </Row>
              <CollapsibleCapitalCallForms
                vcPortfolioCompany={pc}
                capitalCallPortion={capitalCallPortion}
                onCapitalCallTransactionTermsChange={(e) =>
                  handleCapitalCallTransactionTermsChange(e, pc.id)
                }
              />
              <hr />
            </div>
          );
        })}
        <p className="mb-0 text-right mt-16">
          <b>Total:</b> {formatMoney(newCapitalCallTotal)}
        </p>
        <hr className="mx-0" />
        <p className="font-weight-bold">Documents</p>
        <div className="mx-16">
          <DocumentButtons
            documentBucket="vcDocuments"
            documents={makeCapitalCallDocuments}
            downloadParentId={id}
            viewType={DATA_ROOM_COMPONENT_VIEW_TYPES.upload}
            onAttachSingleDocument={(doc) => {
              createCapitalCallSignal.update({
                selectedCapitalCallTemplate: doc,
              });
              document.getElementById('capitalCallFileUpload')?.click();
            }}
          />
          <input
            type="file"
            hidden
            id="capitalCallFileUpload"
            onChange={(e) => {
              e.target.files.length &&
                handleSelectCapitalCallFile(e.target.files[0]);
              resetFileInput();
            }}
          />
        </div>
        {portalType === PORTAL_TYPES.vc && (
          <>
            <hr />
            <p className="font-weight-bold">Select a Bank Account</p>
            {bankAccounts.map((ba) => (
              <Field.Checkbox
                key={ba.id}
                label={
                  <p className="mb-0">
                    {ba.bankAccountName} <i>(ending in {ba.mask})</i>
                  </p>
                }
                value={selectedBankAccountId === ba.id}
                onChange={() => handleSelectBankAccount(ba.id)}
              />
            ))}
          </>
        )}
        <hr />
        <Row className="align-items-end mt-16">
          <Col>
            <Field.DateSelect
              label="Date"
              labelClassName="mb-8 font-weight-bold"
              name="suggestedDate"
              value={suggestedDate}
              onChange={handleCapitalCallInputChange}
            />
            <small hidden={!errors.suggestedDate} className="text-danger">
              Please provide a date.
            </small>
          </Col>
          <Col>
            <div className="d-flex flex-row justify-content-end">
              <Button
                className="rounded"
                onClick={() => {
                  handleCreateCapitalCallSubmission();
                }}
              >
                Make Capital Call
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateCapitalCall;
